import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { login } from 'api';
import { UseQueryAuthority, UseQueryMyInfo, storeToken } from 'auth/common';

import * as S from 'auth/signUp/style';
import WelcomeSideImg from 'constants/icon/roouty_img_signup.png';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';

import { AuthLayout } from 'auth/AuthLayout';

const WelcomePageContent = (type: TWelcomePageType) => {
  switch (type) {
    case 'Free':
      return (
        <Fragment>
          <Text color="RG02" styleName="headline1">
            가입이 완료되었습니다!
          </Text>
          <Text color="RG02" styleName="body2" sx={{ marginTop: '40px' }}>
            지금 바로 루티의 다양한 경로 최적화 기능들을 경험해보세요!
          </Text>
        </Fragment>
      );
    case 'Manager':
      return (
        <Fragment>
          <Text color="RG02" styleName="headline1">
            인증이 완료되었습니다!
          </Text>
          <Text color="RG02" styleName="body2" sx={{ marginTop: '40px' }}>
            매니저님의 이메일 인증이 완료되었습니다.
          </Text>
          <Text color="RG02" styleName="body2" sx={{ marginTop: '8px' }}>
            지금 바로 루티의 다양한 경로 최적화 기능들을 경험해보세요!
          </Text>
        </Fragment>
      );

    default:
  }
};

interface IWelcomePage {
  accountInfo: {
    loginAccount: string;
    password: string;
  };
  welcomePageMode: TWelcomePageType;
  setWelcomePageMode: Dispatch<SetStateAction<TWelcomePageType>>;
}

export const WelcomePage = (props: IWelcomePage) => {
  const navigate = useNavigate();
  const { accountInfo, welcomePageMode, setWelcomePageMode } = props;

  const { mutate: mutateLogin, isSuccess } = useMutation(login, {
    onSuccess: res => storeToken(res),
  });

  UseQueryAuthority({ isSuccess });
  UseQueryMyInfo({ isSuccess, navigate, isStoredId: false, firstLanding: true });

  useEffect(() => {
    return () => setWelcomePageMode('');
  }, []);

  return (
    <AuthLayout cardWidth={846}>
      <S.WelcomePageLayout>
        <Stack align="start" sx={{ width: '413px', margin: '115px 40px auto 88px' }}>
          {WelcomePageContent(welcomePageMode)}
          <Button
            children="시작하기"
            variant="primary"
            fw
            h={40}
            sx={{ marginTop: '40px', padding: '24px' }}
            onClick={() => mutateLogin({ ...accountInfo })}
          />
        </Stack>
        <S.WelcomePageSideImg>
          <img src={WelcomeSideImg} style={{ borderRadius: '0 8px 8px 0' }} alt="WelcomeSideImg" />
        </S.WelcomePageSideImg>
      </S.WelcomePageLayout>
    </AuthLayout>
  );
};
