import { Dispatch, SetStateAction } from 'react';
import { canceler } from 'api/instance';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Highlight, Text } from 'components/Typography';
import ProgressBar from 'components/ProgressBar';
import { usePrompt } from 'hooks/usePrompt';
import strings from '../../util/Localization';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';
import theme from 'constants/theme';
import Lottie from 'lottie-react';
import truck from './lottie/truck.json';

interface IRunOptimizeEqualModalProps {
  info: {
    date: string;
    time?: string;
    workEndTime?: string;
    totalDriver: string;
    name: string;
    totalOrder: string;
    equalizeBy: string;
    distAreaType: string;
  };

  flag?: boolean;
  weight?: number;
  expected?: number;

  completedPercent: number;
  setCompletedPercent: Dispatch<SetStateAction<number>>;
  runEqualOptimizeProgressModal: boolean;
  setRunEqualOptimizeProgressModal: Dispatch<SetStateAction<boolean>>;
}

const RunOptimizeModal = ({
  info,

  flag,
  weight = 1,
  expected = 2.5,
  completedPercent,
  setCompletedPercent,
  runEqualOptimizeProgressModal,
  setRunEqualOptimizeProgressModal,
}: IRunOptimizeEqualModalProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(runEqualOptimizeProgressModal);

  return (
    <Modal
      isModalOpen={runEqualOptimizeProgressModal}
      setIsModalOpen={setRunEqualOptimizeProgressModal}
      plain
      sx={{ padding: '24px' }}
    >
      <Stack sx={{ width: '100%', minWidth: '428px' }}>
        <Stack spacing={12} sx={{ padding: '20px 0' }}>
          {showPrompt && <IconWarning width={24} height={24} />}
          <Text styleName="title1" color="RG02" sx={{ justifyContent: 'center' }}>
            루티 경로 엔진 최적화 중입니다...
          </Text>
        </Stack>
        {showPrompt ? (
          <Stack sx={{ maxWidth: '350px' }}>
            <div style={{ width: '100%' }}>
              <ProgressBar progress={completedPercent} spacing={8} />
            </div>

            <Text styleName="subheadline2" color="RC04" align="start" sx={{ width: '100%', padding: '30px 0 40px' }}>
              다른 페이지로 이동할 경우 배차 계획이 중단됩니다. <br />
              취소하려면 배차 계획 취소 버튼을 눌러주세요.
            </Text>

            <Stack direction="row" spacing={18} sx={{ minWidth: '438px' }}>
              <Button
                variant={'seventh'}
                type={'button'}
                fullWidth
                height={40}
                styleName={'body2'}
                color={'RG03'}
                onClick={() => {
                  canceler();
                  setRunEqualOptimizeProgressModal(false);
                  confirmNavigation();
                }}
              >
                배차 계획 취소
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                height={40}
                styleName={'body1'}
                color={'RG00'}
                onClick={() => {
                  cancelNavigation();
                }}
              >
                이어서 계산하기
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Stack useVerticalScroll style={{ maxHeight: 'calc(100vh - 300px)' }}>
              <Lottie style={{ width: '220px', marginTop: '10px' }} animationData={truck} />
              <Stack
                name="content-area"
                sx={{ padding: '10px', borderRadius: '8px', background: theme.colors.RC03_1 }}
                spacing={8}
                align={'start'}
              >
                <Text styleName="subheadline1" color="RC02">
                  배차 설정
                </Text>
                {Object.keys(info).map(key => {
                  const mapper: { [key in keyof typeof info]: string } = {
                    name: '배차 이름',
                    date: '주행일',
                    time: '주행 시작 시간 지정',
                    workEndTime: '주행 종료 시간 지정',
                    totalDriver: strings.모달_드라이버,
                    totalOrder: strings.모달_주문,
                    distAreaType: '권역 배차',
                    equalizeBy: '균등 배차',
                  };
                  return (
                    <Text styleName="subheadline1" color="RG02" sx={{ textAlign: 'start' }}>
                      <Highlight styleName="subheadline2">{mapper[key as keyof typeof info]}</Highlight> :{' '}
                      {info[key as keyof typeof info]}
                    </Text>
                  );
                })}
              </Stack>

              <Stack
                name="content-area2"
                sx={{ padding: '10px', borderRadius: '8px', marginTop: '20px', background: theme.colors.RG08 }}
                spacing={8}
                align={'start'}
              >
                <Text styleName="subheadline1" color="RG03">
                  배차 결과 보기시 확인해 주세요.
                </Text>
                <Text styleName="subheadline2" color="RG03">
                  1. 상황에 따라 최대 5분까지 소요될 수 있습니다.
                </Text>
                <Text styleName="subheadline2" color="RG03">
                  2. 새로고침 또는 메뉴 이동 시 배차결과가 취소되오니 주의해주세요.
                </Text>
              </Stack>
            </Stack>
            <Button
              variant={'seventh'}
              styleName={'body1'}
              color={'RG03'}
              type={'button'}
              fullWidth
              height={40}
              sx={{ marginTop: '40px' }}
              onClick={() => {
                canceler();
                setRunEqualOptimizeProgressModal(false);
              }}
            >
              배차 계획 취소
            </Button>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default RunOptimizeModal;
