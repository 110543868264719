import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { MANAGER_AUTH_CODE_QUERIES } from './managerAuthCode.queries';
import { HTTPError } from 'ky';

type FetchSendManagerAuthCodeResponse = {
  phone: string;
};

type Variables = {
  phone: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchSendAuthCode>>;
  TError: HTTPError;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MANAGER_AUTH_CODE_QUERIES.SEND_MANAGER_AUTH_CODE>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchSendAuthCode = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchSendManagerAuthCodeResponse | IErrorResponse>();

const useSendManagerAuthCode = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MANAGER_AUTH_CODE_QUERIES.SEND_MANAGER_AUTH_CODE(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchSendAuthCode({
        variables: variables,
        ...MANAGER_AUTH_CODE_QUERIES.SEND_MANAGER_AUTH_CODE()[0],
      }),
    options
  );

export { fetchSendAuthCode, useSendManagerAuthCode };
export type { FetchSendManagerAuthCodeResponse };
