import { ReactElement } from 'react';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

const ModalIconHeader = ({ icon, title }: { icon: ReactElement; title: string }) => {
  return (
    <Stack direction="row" spacing={10}>
      {icon}
      <Text styleName="body1">{title}</Text>
    </Stack>
  );
};
export default ModalIconHeader;
