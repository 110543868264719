import theme from 'constants/theme';

export const ALERT_ERROR_MODAL: { EXCEEDED: TAlertModalErrorType; TIMEOUT: TAlertModalErrorType } = {
  EXCEEDED: {
    messages: ['이메일을 확인하고 인증을 재시도해 주세요.'],
  },
  TIMEOUT: {
    messages: ['인증 번호 입력 시간을 초과하였습니다.'],
  },
};

export const MODE: { key: TSignUpType; title: string }[] = [
  { key: 'CORPORATE', title: '회사 정보 입력' },
  { key: 'PRIVATE', title: '개인 정보 입력' },
];

export const HEADER_LABEL_COLOR = {
  inactive: { font: theme.colors.RG05, background: theme.colors.RG08, btnBackground: 'RG08' },
  active: {
    font: theme.colors.RC02,
    background: theme.colors.RC03_1,
    btnBackground: 'RC03_1',
  },
};

export const TERMS_OF_SERVICE_LIST: TTermsOfServiceList[] = [
  { key: 'ALL', text: '전체 약관에 동의합니다.', checked: false },
  { key: 'SERVICE', text: '(필수) 서비스 이용 약관', etc: 'https://policy.roouty.io/tos', checked: false },
  { key: 'PRIVATE', text: '(필수) 개인정보처리방침', etc: 'https://policy.roouty.io/policy_privacy', checked: false },
  {
    key: 'MARKETING',
    text: '(선택) 마케팅 정보 수신',
    subText: '*신규 업데이트 및 다양한 프로모션 정보들을 보내드립니다.',
    etc: 'https://policy.roouty.io/aggrement_marketing',
    checked: false,
  },
];

export const SIGN_UP_INIT: TSignUpForm = {
  loginAccount: null,
  password: null,
  name: null,
  companyName: null,
  licenseType: 'individual',
  industryType: null,
  businessNumber: null,
  termsOfService: TERMS_OF_SERVICE_LIST,
  marketingConsent: null,
};
