import { useMutation, useQuery } from '@tanstack/react-query';
import { batchModifyingDriverById, getVehicleModel } from 'api';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Input, Select } from 'components/Input';
import { ModifiedMultiSkillsSection } from 'components/ModifiedSection/ModifiedMultiSkillsSection';
import BreakRangePicker from 'components/Popup/BreakRangePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { VehicleModelPicker } from 'components/Popup/VehicleModelPicker';
import { Stack } from 'components/Stack';
import { Swap } from 'components/Swap';
import { FormTextarea } from 'components/Table/TeamManagementTable';
import { Text } from 'components/Typography';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';

import { ReactComponent as IcFlag } from 'constants/icon/ic_flag.svg';
import { ReactComponent as IcPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcTruck } from 'constants/icon/ic_truck.svg';
import theme from 'constants/theme';
import dayjs from 'dayjs';
import { driver_operationType } from 'pages/Setting/resource/Driver';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useForm } from 'react-hook-form';

import Modal from '.';
import genLabel from './common/genLabel';
import ModalIconHeader from './common/ModalIconHeader';
import { DetailModiForm } from './OrderDetailModal';
import { QueryStatusModalProps } from './QueryStatusModal';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';

interface BatchDriverModifyingModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onControl: (
    open: boolean,
    props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>
  ) => void;
  selectedDriver: Array<number>;
  teamId: number;
}

const BatchDriverModifyingModal = ({
  isOpen,
  setIsOpen,
  onControl,
  selectedDriver,
  teamId,
}: BatchDriverModifyingModalProps) => {
  const methods = useForm<any>({ mode: 'all', defaultValues: {} });

  const [workStartTimeTimePickerisOpen, setWorkStartTimeTimePickerisOpen] = useState<boolean>(false);
  const [workEndTimeTimePickerisOpen, setWorkEndTimeTimePickerisOpen] = useState<boolean>(false);
  const [breakRangePickerIsOpen, setBreakRangePickerIsOpen] = useState<boolean>(false);

  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<{
    isOpen: boolean;
    target: string;
  }>({
    isOpen: false,
    target: '',
  });

  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [initializer, setInitializer] = useState<{
    [key: string]: boolean;
  }>({
    skills: false,
    workEndTime: false,
    endAddress: false,
  });

  // legacy : Radio 버튼(출발지와 동일, 설정값 삭제)의 상태값 state
  //const [endAddressOptions, setEndAddressOptions] = useState<'sameAsStart' | 'removeData' | ''>('');

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const renderCounter = useRef(0);

  const { data: vehicleModel } = useQuery(['vehicleModel'], getVehicleModel, {});

  const handlePostcodeComplete = (data: any, name: string) => {
    let address = data.address || data.jibunAddress;
    methods.setValue(name as any, address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(prev => {
      return { ...prev, isOpen: false };
    });
  };

  const { mutate: mutateBatchModifyingDriver, isLoading: mutateBatchModifyingDriverIsLoading } = useMutation(
    batchModifyingDriverById,
    {
      onSuccess: () => {
        onControl(true, {
          status: 'success',
          string: '선택한 드라이버의 정보를 일괄 변경하였습니다.\n변경된 정보는 다음 배차 계획부터 적용됩니다.',
        });
        setIsOpen(false);
      },
    }
  );

  const InitializeButton = (key: string) => {
    return (
      <Stack
        spacing={4}
        direction="row"
        onClick={() => {
          setInitializer(prev => {
            return { ...prev, [key]: !prev[key] };
          });
        }}
        sx={{
          userSelect: 'none',
        }}
      >
        <IcSuccess width={12} height={12} fill={theme.colors[initializer[key] ? 'RC02' : 'RG05']} />
        <Text styleName="caption2" color={initializer[key] ? 'RC02' : 'RG04'}>
          설정값 삭제
        </Text>
      </Stack>
    );
  };

  useEffect(() => {
    methods.reset();
    setInitializer({
      skills: false,
      endAddress: false,
      workEndTime: false,
    });
    setSelectedSkillIds([]);
  }, [isOpen, methods]);

  useEffect(() => {
    if (!methods.getValues('workStartTime') || !methods.getValues('workEndTime')) return;
    if (!(+methods.getValues('workStartTime').replace(':', '') > +methods.getValues('workEndTime').replace(':', ''))) {
      methods.clearErrors('workStartTime');
      methods.clearErrors('workEndTime');
    }
  }, [methods.getValues('workStartTime'), methods.getValues('workEndTime')]);

  useEffect(() => {
    if (selectedSkillIds.length) {
      methods.setValue('skills', selectedSkillIds);
    }
  }, [selectedSkillIds]);

  return (
    <Modal
      isLoadingModal={mutateBatchModifyingDriverIsLoading}
      plain
      width={540}
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
    >
      <OrderDetailModalHeader>
        <Stack direction="row" justify="space-between">
          <Text styleName="subheadline2" color="RG03">
            드라이버 일괄 수정
          </Text>
          <IcClose
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
      </OrderDetailModalHeader>
      <Divider color="RG06" />
      <OrderDetailModalBody useVerticalScroll>
        <form
          onSubmit={methods.handleSubmit(res => {
            console.log(res);
          })}
          style={{ maxHeight: 784 }}
        >
          <DetailModiForm
            title={<ModalIconHeader title="드라이버 기본 정보" icon={<IcPerson width={20} height={20} />} />}
          >
            <Stack spacing={4}>
              {genLabel({ text: '메모' })}
              <FormTextarea
                {...methods.register('memo', {})}
                placeholder={'예시) 매주 월요일 휴무'}
                rows={14}
                useFieldFont={true}
                cols={10}
                wrap="soft"
                maxLength={200}
                onKeyDown={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
                onKeyUp={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
              />
            </Stack>
          </DetailModiForm>
          <Divider color="RG06" />
          <DetailModiForm title={<ModalIconHeader title="차량 · 주행 정보" icon={<IcTruck />} />}>
            <Swap
              defaultIndex={-3}
              allowUncheck
              padding={0}
              gap={0}
              sx={{
                paddingTop: '7px',
              }}
              renderCounter={renderCounter.current}
              name="operationType"
              methods={methods}
              label={genLabel({
                text: '운영 유형',
              })}
              options={driver_operationType}
            />
            <Stack direction="row" spacing={16}>
              <Stack sx={{ position: 'relative' }}>
                {skillPopupIsOpen && (
                  <PopupYIndexFixer>
                    <div></div>
                  </PopupYIndexFixer>
                )}
                <Input
                  name="modelId"
                  label={genLabel({ text: '차종' })}
                  placeholder="차종을 선택해 주세요"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  variant="third"
                  field
                  height={40}
                  errors={methods.formState?.errors?.modelId}
                  hasError={Boolean(methods.formState?.errors?.modelId)}
                  validation={{
                    validate: (res: any) =>
                      !Boolean(res) ||
                      (vehicleModel && vehicleModel.vehicleModelList.filter(d => d.name === res).length > 0) ||
                      '존재하지 않는 차량입니다.',
                  }}
                  onChange={(e: any) => {
                    setSkillPopupIsOpen(true);
                    setSkillSearchValue(e.target.value);

                    vehicleModel?.vehicleModelList &&
                      methods.setValue(
                        'modelId',
                        vehicleModel.vehicleModelList.find(d => d.name === e.target.value)!.name,
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        }
                      );
                  }}
                  onClick={() => {
                    setSkillPopupIsOpen(true);
                  }}
                />

                {skillPopupIsOpen && (
                  <VehicleModelPicker
                    setIsOpen={setSkillPopupIsOpen}
                    methods={methods}
                    name={'modelId'}
                    searchValue={skillSearchValue}
                  />
                )}
              </Stack>
              <Input
                name="maxCapacity"
                label={genLabel({ text: '최대 용적량' })}
                placeholder="정수만 입력해 주세요. 예시) 1000"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{
                  validate: (res: string) => {
                    return Number.isInteger(Number(res)) || '정수만 입력해 주세요.';
                  },
                }}
                variant="third"
                field
                height={40}
                errors={methods.formState?.errors?.maxCapacity}
                hasError={Boolean(methods.formState?.errors?.maxCapacity)}
              />
            </Stack>

            <Stack direction="row" spacing={16} align="start">
              <Stack sx={{ position: 'relative' }}>
                {(workStartTimeTimePickerisOpen || workEndTimeTimePickerisOpen) && (
                  <PopupYIndexFixer>
                    <div />
                  </PopupYIndexFixer>
                )}
                <Input
                  name="workStartTime"
                  label={genLabel({ text: '주행 시작 시간' })}
                  placeholder="주행 시작 시간을 입력해 주세요"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  variant="third"
                  validation={{
                    validate: (res: string) => {
                      if (methods.getValues('workEndTime')) {
                        if (
                          +(methods.getValues('workStartTime') ?? '').replace(':', '') >
                          +(methods.getValues('workEndTime') ?? '').replace(':', '')
                        ) {
                          return '주행 시작 시간이 주행 종료 시간보다 늦을 수 없습니다.';
                        }
                      }

                      return;
                    },
                  }}
                  field
                  readOnly
                  height={40}
                  onClick={() => {
                    setWorkStartTimeTimePickerisOpen(prev => !prev);
                  }}
                  errors={methods.formState?.errors?.workStartTime}
                  hasError={Boolean(methods.formState?.errors?.workStartTime)}
                />
                {workStartTimeTimePickerisOpen && (
                  <MiniTimePicker
                    initial={methods.getValues('workStartTime') || '09:00'}
                    init={methods.getValues('workStartTime')}
                    isOpen={workStartTimeTimePickerisOpen}
                    setIsOpen={setWorkStartTimeTimePickerisOpen}
                    methods={methods}
                    name={'workStartTime'}
                    rSide
                    nm
                  />
                )}
              </Stack>
              <Stack sx={{ position: 'relative' }}>
                <Input
                  name="workEndTime"
                  label={genLabel({ text: '주행 종료 시간' })}
                  placeholder="주행 종료 시간을 입력해 주세요"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  variant={methods.watch('workStartTime') === 0 || !methods.watch('workStartTime') ? 'fourth' : 'third'}
                  field
                  readOnly
                  disabled={!methods.getValues('workStartTime')}
                  height={40}
                  validation={{
                    validate: (res: string) => {
                      if (!methods.getValues('workStartTime') || !methods.getValues('workEndTime')) return;
                      if (
                        +(methods.getValues('workStartTime') ?? '').replace(':', '') >
                        +(methods.getValues('workEndTime') ?? '').replace(':', '')
                      )
                        return '주행 종료 시간이 주행 시작 시간을 앞설 수 없습니다.';
                    },
                  }}
                  onClick={() => {
                    setWorkEndTimeTimePickerisOpen(prev => !prev);
                    if (initializer.workEndTime) {
                      setInitializer(prev => ({
                        ...prev,
                        workEndTime: false,
                      }));
                    }
                  }}
                  errors={methods.formState?.errors?.workEndTime}
                  hasError={Boolean(methods.formState?.errors?.workEndTime)}
                  rightArea={{
                    rightContent: InitializeButton('workEndTime'),
                    rightFunc: () => {
                      methods.setValue('workEndTime', '');
                    },
                  }}
                />
                {workEndTimeTimePickerisOpen && (
                  <MiniTimePicker
                    initial={
                      methods.getValues('workEndTime') && methods.getValues('workEndTime') !== '없음'
                        ? methods.getValues('workEndTime')
                        : '00:00'
                    }
                    init={methods.getValues('workEndTime')}
                    isOpen={workEndTimeTimePickerisOpen}
                    setIsOpen={setWorkEndTimeTimePickerisOpen}
                    methods={methods}
                    name={'workEndTime'}
                    rSide
                    nm
                  />
                )}
              </Stack>
            </Stack>
            <Stack sx={{ position: 'relative' }}>
              <Select
                options={[
                  { key: '없음', value: 0 },
                  { key: '30 분', value: 30 },
                  { key: '60 분', value: 60 },
                  { key: '90 분', value: 90 },
                  { key: '120 분', value: 120 },
                ]}
                defaultValue={methods.getValues('breakDuration')}
                setValue={methods.setValue}
                name="breakDuration"
                label={genLabel({ text: '휴게 시간' })}
                placeholder="휴게 시간을 입력해 주세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                variant="third"
                field
                height={40}
                errors={methods.formState?.errors?.breakDuration}
                hasError={Boolean(methods.formState?.errors?.breakDuration)}
              />
            </Stack>
            <Stack sx={{ position: 'relative' }}>
              <Input
                disabled={methods.watch('breakDuration') === 0 || !methods.watch('breakDuration')}
                name="breakRange"
                placeholder="휴게 시간 구간을 입력해 주세요"
                label={genLabel({ text: '휴게 시간 구간' })}
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                variant={methods.watch('breakDuration') === 0 || !methods.watch('breakDuration') ? 'fourth' : 'third'}
                field
                height={40}
                readOnly
                validation={{
                  validate: {
                    startTimeCannotBeAheadOfWorkTime: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      const sv = dayjs(breakStart, 'HH:mm')
                        ? dayjs(breakStart, 'HH:mm').isBefore(dayjs(methods.getValues('workStartTime'), 'HH:mm'))
                        : true;
                      const ev = dayjs(breakEnd, 'HH:mm')
                        ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(methods.getValues('workStartTime'), 'HH:mm'))
                        : true;

                      console.log(sv, ev);

                      return !(sv || ev) || '휴게 시작시간이 근무시작 시간보다 앞설 수 없습니다.';
                    },
                    endTimeCannotBeAheadOrSame: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      const sv =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? dayjs(breakEnd, 'HH:mm').isSame(dayjs(breakStart, 'HH:mm'))
                          : true;
                      const ev =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(breakStart, 'HH:mm'))
                          : true;

                      console.log(sv, ev);

                      return !(sv || ev) || '휴게 종료 시간을 주행 시작 시간보다 앞서서 설정할 수 없습니다.';
                    },
                    startTime_endTime_range_not_matched: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      if (!breakStart && !breakEnd) return;

                      const sv =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? isNaN(dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm')) ||
                            dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm') + 1 >
                              (methods.getValues('breakDuration') ?? 0)
                          : false;

                      return sv || '휴게 시간 구간은 휴게 시간보다 길어야 합니다.';
                    },
                  },
                }}
                onClick={() => {
                  setBreakRangePickerIsOpen(prev => !prev);
                }}
                hasError={Boolean(methods.formState?.errors?.breakRange)}
              />
              {breakRangePickerIsOpen && (
                <PopupYIndexFixer>
                  <BreakRangePicker
                    name="breakRange"
                    isOpen={breakRangePickerIsOpen}
                    setIsOpen={setBreakRangePickerIsOpen}
                    {...{ methods }}
                  />
                </PopupYIndexFixer>
              )}
            </Stack>
          </DetailModiForm>
          <Divider color="RG06" />
          <ModifiedMultiSkillsSection
            isModified
            teamId={teamId}
            setSelectedSkillIds={setSelectedSkillIds}
            disabledEmptyContainer={!selectedSkillIds.length}
            label={InitializeButton('skills')}
            handleClickInput={() => {
              if (initializer.skills) {
                setInitializer(prev => ({
                  ...prev,
                  skills: false,
                }));
              }
            }}
          />
          <Divider color="RG06" />
          <DetailModiForm title={<ModalIconHeader title="출 · 도착지 정보" icon={<IcFlag />} />}>
            <Stack spacing={12}>
              <Input
                name="startAddress"
                label={genLabel({ text: '출발지 주소' })}
                placeholder="주소를 입력하세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant="third"
                height={40}
                readOnly={true}
                isInject={((methods.getValues('startAddress') as string)?.length ?? 0) > 0}
                removeBtn={(e: MouseEvent) => {
                  e.preventDefault();
                  methods.setValue('startAddress', '');
                }}
                onFocus={() => {
                  methods.clearErrors('startAddress');
                  setAddressPopupIsOpen(prev => {
                    return { target: 'startAddress', isOpen: true };
                  });
                }}
                hasError={Boolean(methods.formState?.errors?.startAddress)}
              />
              <Input
                disabled={!methods.getValues('startAddress')}
                name="startDetailAddress"
                placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant={!methods.getValues('startAddress') ? 'fourth' : 'third'}
                height={40}
                hasError={Boolean(methods.formState?.errors?.startDetailAddress)}
              />
            </Stack>

            <Stack spacing={12}>
              <Input
                name="endAddress"
                label={genLabel({ text: '도착지 주소' })}
                rightArea={{
                  rightContent: InitializeButton('endAddress'),
                  rightFunc: () => {
                    methods.setValue('endAddress', '', { shouldDirty: true, shouldValidate: true });
                    methods.setValue('endDetailAddress', '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  },
                }}
                placeholder="주소를 입력하세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant="third"
                height={40}
                readOnly={true}
                isInject={((methods.getValues('endAddress') as string)?.length ?? 0) > 0}
                removeBtn={(e: MouseEvent) => {
                  e.preventDefault();
                  methods.setValue('endAddress', '');
                }}
                onFocus={() => {
                  if (initializer.endAddress) {
                    setInitializer(prev => ({
                      ...prev,
                      endAddress: false,
                    }));
                  }
                  setAddressPopupIsOpen(prev => {
                    return { target: 'endAddress', isOpen: true };
                  });
                }}
                hasError={Boolean(methods.formState?.errors?.endAddress)}
              />
              <Input
                disabled={!methods.getValues('endAddress')}
                name="endDetailAddress"
                placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant={!methods.getValues('endAddress') ? 'fourth' : 'third'}
                height={40}
                hasError={Boolean(methods.formState?.errors?.endAddress)}
              />
            </Stack>

            <Modal
              isModalOpen={addressPopupIsOpen.isOpen}
              setIsModalOpen={() => {
                setAddressPopupIsOpen(prev => {
                  return { ...prev, isOpen: false };
                });
              }}
              title={'우편번호찾기'}
              width={532}
              ms={30}
              padding={20}
            >
              <DaumPostcodeEmbed
                onComplete={data => handlePostcodeComplete(data, addressPopupIsOpen.target)}
                style={{ height: '500px', marginBottom: '0' }}
              />
            </Modal>
          </DetailModiForm>
        </form>
      </OrderDetailModalBody>
      <Divider color="RG06" />
      <OrderDetailModalFooter>
        <Stack
          name="modal-footer"
          spacing={16}
          direction="row"
          align="center"
          justify="end"
          sx={{ padding: '2px 0 0 0' }}
        >
          <Button
            variant="seventh"
            type="button"
            width={102}
            height={40}
            styleName="body1"
            color="RG03"
            onClick={() => {
              methods.reset();
              setIsOpen(false);
            }}
          >
            닫기
          </Button>
          <Button
            variant="default"
            type="button"
            width={216}
            height={40}
            styleName="body1"
            color="RG00"
            onClick={methods.handleSubmit(res => {
              const br = res.breakRange
                ?.replaceAll('오전', '')
                ?.replaceAll('오후', '')
                ?.replaceAll(' ', '')
                ?.split('~');
              if (br) {
                res.breakStartTime = br[0];
                res.breakEndTime = br[1];
              }
              delete res.breakRange;

              if (isNaN(Number(res.modelId)) && vehicleModel) {
                res.modelId = parseInt(
                  `${vehicleModel.vehicleModelList.find(d => d.name === methods.getValues('modelId'))!.vehicleModelId}`
                );
              }
              if (!res.breakDuration && res.breakDuration !== 0) delete res.breakDuration;
              else {
                res.breakDuration = res.breakDuration * 60;
              }
              if (!res.maxCapacity) delete res.maxCapacity;
              if (!res.modelId) delete res.modelId;
              if (!res.operationType) delete res.operationType;
              if (!res.startAddress) delete res.startAddress;
              if (!res.workStartTime) delete res.workStartTime;
              if (!res.workEndTime) delete res.workEndTime;

              if (!res.endAddress) delete res.endAddress;
              if (!res.startDetailAddress) delete res.startDetailAddress;
              if (!res.endDetailAddress) delete res.endDetailAddress;
              if (!res.breakStartTime) delete res.breakStartTime;
              if (!res.breakEndTime) delete res.breakEndTime;
              if (res.memo === '') delete res.memo;

              if (initializer.skills) res.skills = [];
              // 설정값 삭제 버튼 클릭시 여기의 res.wordEndTime 값을 초기값으로 넣어 "없음" 상태로 만들어야 한다.
              if (initializer.workEndTime) res.workEndTime = '';
              if (initializer.endAddress) res.endAddress = '';

              mutateBatchModifyingDriver({ driverList: selectedDriver, ...res });

              // Object.keys(res).length > 0
              //   ? mutateBatchModifyingDriver({ driverList: selectedDriver, ...res })
              //   : setIsOpen(false);
            })}
          >
            저장하기
          </Button>
        </Stack>
      </OrderDetailModalFooter>
    </Modal>
  );
};

export default BatchDriverModifyingModal;
