import { AddPaymentMethod, EmptyPaymentMethod } from './style';
import { PlusIcon } from '../../../icon/Plus';
import { Text } from '../../../Typography';

export interface PaymentMethodEmptyProps {
  onClick: () => void;
}

export const PaymentMethodEmpty = ({ onClick }: PaymentMethodEmptyProps) => {
  return (
    <EmptyPaymentMethod onClick={onClick}>
      <AddPaymentMethod>
        <PlusIcon />
        <Text styleName="body1" color="RG02">
          결제 수단 등록
        </Text>
      </AddPaymentMethod>
    </EmptyPaymentMethod>
  );
};
