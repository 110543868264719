import { ColorPalette } from 'constants/colorPalette';
import { DriverIdUnSelected, IEqualOptimizeResponses } from 'constants/types';
import polyline from 'google-polyline';
import type { Dispatch, SetStateAction } from 'react';

export function generatePolylineOptimize({
  data,
  setter,
}: {
  data: IEqualOptimizeResponses;
  setter: Dispatch<SetStateAction<any>>;
}) {
  let baseGeoJsonStructure: any = { id: 'polyline', type: 'FeatureCollection', features: [] };

  data.driverList
    .filter(x => x.driverId)
    .forEach(driver => {
      let polys: any[] = [];
      driver.orderList.forEach(order => {
        if (order.route && order.route?.internal?.polyline) {
          let decodedLine = polyline.decode(order.route?.internal?.polyline);
          decodedLine.forEach(line => polys.push(line));
        }
      });
      let featureBase = {
        geometry: {
          coordinates: polys,
          type: 'LineString',
        },
        properties: {
          color: '#FFFFFF',
          width: 8,
          driverId: driver.driverId,
          driverName: driver.name,
          driverStatus: driver.status,
        },
        type: 'Feature',
      };
      baseGeoJsonStructure.features.push(featureBase);
      let feature = {
        geometry: {
          coordinates: polys,
          type: 'LineString',
        },
        properties: {
          color: ColorPalette.getColor(driver.driverId || 0),
          width: 5,
          driverId: driver.driverId,
        },
        type: 'Feature',
      };
      baseGeoJsonStructure.features.push(feature);
    });

  setter(baseGeoJsonStructure);
}

export function generatePolylineFocused({
  data,
  selectedDriver,
  setter,
}: {
  data: IEqualOptimizeResponses;
  selectedDriver: number;
  setter: Dispatch<SetStateAction<any>>;
}) {
  let baseGeoJsonStructure: any = { id: 'polyline', type: 'FeatureCollection', features: [] };

  let driverList = data.driverList;
  if (selectedDriver !== DriverIdUnSelected) {
    driverList = [
      ...driverList.filter(x => x.driverId !== selectedDriver),
      ...driverList.filter(x => x.driverId === selectedDriver),
    ];
  }

  if (driverList)
    driverList
      .filter(x => x.driverId)
      .forEach(driver => {
        let polys: any[] = [];
        driver.orderList.forEach(order => {
          if (order.route && order.route.internal?.polyline) {
            let decodedLine = polyline.decode(order.route.internal?.polyline);
            decodedLine.forEach(line => polys.push(line));
          }
        });

        if (selectedDriver === driver.driverId) {
          let featureBase = {
            geometry: {
              coordinates: polys,
              type: 'LineString',
            },
            properties: {
              opacity: 1,
              color: '#FFFFFF',
              width: 8,
              driverId: driver.driverId,
              driverName: driver.name,
              driverStatus: driver.status,
            },
            type: 'Feature',
          };
          baseGeoJsonStructure.features.push(featureBase);
        }
        let feature = {
          geometry: {
            coordinates: polys,
            type: 'LineString',
          },
          properties: {
            opacity: selectedDriver === driver.driverId ? 1 : 0.4,
            color: ColorPalette.getColor(driver.driverId || 0),
            width: 5,
            driverId: driver.driverId,
          },
          type: 'Feature',
        };
        baseGeoJsonStructure.features.push(feature);
      });

  setter(baseGeoJsonStructure);
}
