import styled from 'styled-components';

export const CardLayout = styled.div`
  // display: flex;
`;

export const SignInResetPasswordLayout = styled.div`
  max-width: 414px;
  margin-bottom: 60px;
  margin-top: 60px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// FIXME: margin 제거하기
export const InputLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin-top: 24px;
`;

export const PasswordInputLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;
