import { ReactNode } from 'react';

type NoticeKeyUnionType = 'areaChange';

type NoticeType = {
  [key in NoticeKeyUnionType]: {
    content: string[] | ReactNode;
  };
};

export const Notice: NoticeType = {
  areaChange: {
    content: [
      '루티를 이용해 주시는 고객님께 감사의 말씀을 드립니다.',
      '11/21 (화) 오후 6시 누락된 권역 정보 보완 업데이트가 예정되어 있습니다.',
      '기존에 등록하신 권역 정보가 변경될 수 있으니',
      '배차 시 이점 참고하여주시길 바랍니다. 감사합니다.',
    ],
  },
};

export type { NoticeType, NoticeKeyUnionType };
