import { Text } from 'components/Typography';
import { TTimelineVariants } from 'constants/types';
import styled from 'styled-components';
import Pickup from 'constants/icon/tooltip_icon_pickup.png';
import Delivery from 'constants/icon/tooltip_icon_delivery.png';
import Timewindow from 'constants/icon/tooltip_icon_timewindow.png';
import Skillid from 'constants/icon/tooltip_icon_skillid.png';
import { ReactElement } from 'react';

interface TooltipProps {
  variant?: TTimelineVariants;
  origin?: any;
  text?: any;
}

export default function ({ ...props }: TooltipProps): ReactElement {
  return (
    <Container>
      {(props.variant === 'start' || props.variant === 'end') && (
        <>
          <Text styleName="caption2" color="RG02">
            {props.variant === 'start' ? '출발' : '도착'}
          </Text>
          <Splitter />
          <Text styleName="caption2" color="RG02">
            {props.text === 'null' ? '-' : props.text}
          </Text>
        </>
      )}
      {props.variant === 'order' && (
        <>
          <Text styleName="caption2" color="RG02">
            {props.text}
          </Text>
          <Splitter />
        </>
      )}
      {props.origin?.shipmentType && props.origin?.shipmentType === 'delivery' && (
        <IconContainer>
          <img src={Delivery} alt="Delivery" />
        </IconContainer>
      )}
      {props.origin?.shipmentType && props.origin?.shipmentType === 'pickup' && (
        <IconContainer>
          <img src={Pickup} alt="Pickup" />
        </IconContainer>
      )}
      {(props.origin?.desiredTimeEnd || props.origin?.desiredTimeStart) && (
        <IconContainer>
          <img src={Timewindow} alt="Timewindow" />
        </IconContainer>
      )}
      {props.origin?.serviceVehicle && (
        <IconContainer>
          <img src={Skillid} alt="Skillid" />
        </IconContainer>
      )}

      {props.variant === 'spacer' && (
        <Text styleName={'tooltip1'} color="RC02">
          잘라낸 주문 붙여넣기
        </Text>
      )}
      {(props.origin?.desiredTimeEnd || props.origin?.desiredTimeStart) && (
        <Text styleName="caption2" color="RG02">
          {props.origin?.desiredTimeStart && props.origin?.desiredTimeStart}
          {(props.origin?.desiredTimeEnd || props.origin?.desiredTimeStart) && ' ~ '}
          {props.origin?.desiredTimeEnd && props.origin?.desiredTimeEnd}
        </Text>
      )}
      {props.variant === 'mibaecha' && (
        <Text styleName="caption2" color="RG02">
          미배차 주문
        </Text>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 2px;
  padding: 0 6px;
  gap: 6px;
  margin-top: 20px;

  ${({ theme }) => ({
    background: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;

const IconContainer = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Splitter = styled.div`
  width: 1px;
  height: 12px;
  ${({ theme }) => ({
    background: theme.colors.RG06,
  })}
`;
