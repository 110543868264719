import { Text } from 'components/Typography';
import { useState, useEffect } from 'react';
import { AlertModal } from 'components/Modal/AlertModal';

import useInterval from 'hooks/useInterval';
import { isTruthy } from 'util/isTruthy';

import { ALERT_ERROR_MODAL } from 'auth/constants';
import * as S from 'auth/signUp/style';

interface IPrivateFormLeftTime {
  resetTimer: boolean;
}

export const PrivateFormLeftTime = (props: IPrivateFormLeftTime) => {
  const { resetTimer } = props;
  const [isError, setIsError] = useState<TErrorType>('');

  const initializeTime = { minutes: 3, seconds: 0 };
  const [time, setTime] = useState(initializeTime);

  useEffect(() => {
    if (resetTimer) {
      setTime(initializeTime);
    }
    return () => {
      setTime(initializeTime);
    };
  }, [resetTimer]);

  const disable = time.minutes === 0 && time.seconds === 0;

  useInterval(
    //TODO: container에 로직 넣기
    () => {
      if (time.minutes === 0 && time.seconds === 1) {
        setIsError('TIMEOUT');
        setTime(Object.assign({}, time, { seconds: time.seconds - 1 }));
        return;
      }
      if (time.minutes > 0 && time.seconds === 0) {
        setTime(Object.assign({}, time, { minutes: time.minutes - 1, seconds: 59 }));
        return;
      }

      if (time.seconds > 0) {
        setTime(Object.assign({}, time, { seconds: time.seconds - 1 }));
        return;
      }
    },
    disable ? null : 1000
  );

  return (
    <S.PrivateEmailValidationModalLeftTime>
      <AlertModal
        isOpen={isTruthy(isError)}
        // setIsOpen={set}
        type="ERROR"
        messages={isError !== '' ? ALERT_ERROR_MODAL[isError].messages : undefined}
        callback={() => setIsError('')}
        buttonName={isError === 'TIMEOUT' ? '확인' : undefined}
      />

      <Text styleName="subheadline2" color="RG03">
        남은시간
      </Text>
      <Text styleName="subheadline1" color="RG02" sx={{ marginLeft: '8px' }}>
        {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
      </Text>
    </S.PrivateEmailValidationModalLeftTime>
  );
};
