import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import GrayBox from 'pages/Setting/common/GrayBox';
import dayjs, { ManipulateType } from 'dayjs';
import { IRooutyPlan } from 'constants/subscribePlans';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getEstimatePayAmount, getTeamList, paymentChange, paymentStart, unsubscribe } from 'api';
import { PlanStatusTag } from 'components/Tag/new/PlanStatus';
import { PaymentTypeTag } from 'components/Tag/new/PaymentType';
import { PaymentTypeUnion, PlanStatusUnion } from 'types/payment/union';
import QueryStatusModal, { TQueryStatus } from 'components/Modal/QueryStatusModal';
import { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { useGNBStore, useSettingStore } from 'store';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { TQueryStatusModalSetterProps } from 'constants/types';
import PaymentAuthModal from '../Plan/PaymentAuth/PaymentAuthModal';
import { useGetPaymentInfo } from '../../../../hooks/query/payment/useGetPaymentInfo';

export type TPaymentPlanActions = 'upgrade' | 'downgrade' | 'free' | 'unsubscribe';

interface PaymentPlanModalProps {
  paymentType: PaymentTypeUnion;
  paymentTarget: IRooutyPlan & { action: TPaymentPlanActions };
  setPaymentTarget: Dispatch<SetStateAction<(IRooutyPlan & { action: TPaymentPlanActions }) | null>>;
}

export const url = (paymentStatus: TQueryStatus, actionType: TPaymentPlanActions) =>
  `http://${window.location.host}/mypage?head=usage-and-plan&paymentStatus=${paymentStatus}&actionType=${actionType}`;

const PaymentPlanModal = ({ paymentType, paymentTarget, setPaymentTarget }: PaymentPlanModalProps) => {
  const { SETSETTINGOPEN } = useGNBStore();
  const { SETCURRENT } = useSettingStore();

  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [callbackModal, setCallbackModal] = useState<TQueryStatusModalSetterProps>({
    open: false,
    props: { status: 'loading' },
  });
  const [authModal, setAuthModal] = useState<boolean>(false);
  const [shouldDeleteTeamBeforeDowngrade, setShouldDeleteTeamBeforeDowngrade] = useState<boolean>(false);
  const [nextPaymentSchedule, setNextPaymentSchedule] = useState<Date | string | undefined>('');

  const { data: paymentMyData, refetch } = useGetPaymentMy();
  const { data: paymentInfoData } = useGetPaymentInfo({ refetchOnWindowFocus: true });

  const { mutateAsync: getTeamListMutate } = useMutation(['getTeamList'], getTeamList, {});
  const { data: estimatePayAmount, isLoading: getEstimatePayAmountIsLoading } = useQuery(
    [],
    () =>
      getEstimatePayAmount({
        interval: paymentType,
        plan: paymentTarget.name,
      }),
    { enabled: paymentTarget.action === 'upgrade' }
  );

  const { mutateAsync: mutatePaymentStart } = useMutation(['payment', 'start'], paymentStart, {});
  const { mutateAsync: mutatePaymentChange, status: mutatePaymentChangeStatus } = useMutation(
    ['payment', 'upgrade', 'downgrade'],
    paymentChange,
    {}
  );
  const { mutateAsync: mutateUnsubscribe } = useMutation(['payment', 'unsubscribe'], unsubscribe, {});

  const oneTeamPlanChecker = async () => {
    const oneTeamPlan: Array<PlanStatusUnion> = ['Free', 'Lite', 'Standard'];
    let isOkay: boolean = true;

    if (oneTeamPlan.includes(paymentTarget.name)) {
      const teamList = await getTeamListMutate({});
      if (teamList.data.length > 1) {
        setIsOpen(false);
        setShouldDeleteTeamBeforeDowngrade(true);

        isOkay = false;
      }
    }
    return isOkay;
  };
  const payment_plan_action_mapper: {
    [key in TPaymentPlanActions]: {
      str: string;
      func: React.MouseEventHandler<HTMLButtonElement>;
    };
  } = {
    upgrade: {
      str: '업그레이드',
      func: async e => {
        const c = await oneTeamPlanChecker();
        if (!c) return;

        mutatePaymentChange({
          plan: paymentTarget.name,
          interval: paymentType,
          successUrl: url('success', 'upgrade'),
          errorUrl: url('error', 'upgrade'),
          cancelUrl: url('warning', 'upgrade'),
        }).then(res => {
          window.localStorage.setItem('orderCode', res.orderCode);

          window.location.href = res!.paymentUrl as string;
        });
      },
    },
    downgrade: {
      str: '다운그레이드',
      func: async e => {
        const c = await oneTeamPlanChecker();
        if (!c) return;

        mutatePaymentChange({ plan: paymentTarget.name, interval: paymentType }).then(() => {
          setIsOpen(false);
          setCallbackModal({
            open: true,
            props: {
              status: 'success',
              string: `요금제 다운그레이드 신청이 완료되었습니다.`,

              callback: async () => {
                setCallbackModal(prev => {
                  return { open: true, props: { status: 'loading' } };
                });
                await refetch().then(() => setPaymentTarget(null));
              },
            },
          });
        });
      },
    },
    free: {
      str: '구매하기',
      func: e => {
        if (paymentInfoData?.name) {
          mutatePaymentStart({
            plan: paymentTarget.name,
            interval: paymentType,
            successUrl: url('success', 'free'),
            errorUrl: url('error', 'free'),
            cancelUrl: url('warning', 'free'),
          }).then(res => {
            window.localStorage.setItem('orderCode', res.orderCode);

            window.location.href = res.paymentUrl;
          });
        } else {
          setIsOpen(false);
          setAuthModal(true);
        }
      },
    },
    unsubscribe: {
      str: '다운그레이드',
      func: async () => {
        const c = await oneTeamPlanChecker();
        if (!c) return;

        mutateUnsubscribe().then(() => {
          setIsOpen(false);
          setCallbackModal({
            open: true,
            props: {
              status: 'success',
              string: `요금제 다운그레이드 신청이 완료되었습니다.`,

              callback: async () => {
                setCallbackModal(prev => {
                  return { open: true, props: { status: 'loading' } };
                });
                await refetch().then(() => setPaymentTarget(null));
              },
            },
          });
        });
      },
    },
  };

  useEffect(() => {
    switch (paymentTarget.action) {
      case 'upgrade':
        setNextPaymentSchedule(
          dayjs()
            .add(-1, 'day')
            .add(1, paymentType.replaceAll('ly', '') as ManipulateType)
            .toDate()
        );
        break;
      case 'downgrade':
        setNextPaymentSchedule(dayjs(paymentMyData?.expiredAt).format('YYYY.MM.DD'));
        break;
      case 'unsubscribe':
        setNextPaymentSchedule(undefined);
    }
  }, [paymentMyData?.expiredAt, paymentTarget.action, paymentType]);

  return (
    <>
      <Modal
        isLoadingModal={
          (paymentTarget.action === 'upgrade' && getEstimatePayAmountIsLoading) ||
          mutatePaymentChangeStatus === 'loading'
        }
        children={
          <Stack spacing={40}>
            <Stack spacing={10} align="start">
              <Text styleName="title2">요금제 구독하기</Text>
              <Stack spacing={8} direction="row">
                <PlanStatusTag text={paymentTarget.name as PlanStatusUnion} />
                <PaymentTypeTag text={paymentType} />
                <Text styleName="body1">요금제를 선택하셨습니다.</Text>
              </Stack>
              <Text styleName="body2">요금제 구독 시 아래의 요금이 결제됩니다.</Text>
            </Stack>
            <Stack spacing={20}>
              {estimatePayAmount && paymentTarget.action === 'upgrade' && (
                <ChargeBox price={estimatePayAmount.estimatePayAmount} />
              )}
              <ChargeBox
                price={paymentTarget.pricing * (paymentType === 'yearly' ? 12 : 1) * 1.1}
                next={nextPaymentSchedule}
              />
              <Stack spacing={20} name="button-area">
                <Button
                  children={`요금제 ${payment_plan_action_mapper[paymentTarget.action].str}`}
                  variant="primary"
                  fw
                  h={40}
                  onClick={payment_plan_action_mapper[paymentTarget.action].func}
                />
                <Button
                  children={'취소'}
                  variant="tertiary"
                  fw
                  h={40}
                  onClick={() => {
                    setPaymentTarget(null);
                  }}
                />
              </Stack>
              <GrayBox spacing={8} padding={10} sx={{ alignItems: 'start' }}>
                <Text styleName="caption1" color="RG03">
                  안내 사항
                </Text>
                {[
                  `요금제를 업그레이드할 경우 선납한 금액에서 차액만 결제한 뒤 바로 사용할 수 있습니다.`,
                  `요금제를 다운그레이드할 경우 다음 결제 예정일에 다운그레이드 요금제 금액이 청구됩니다.`,
                  `환불을 원하시는 경우 support@wemeetmobility.com에 문의해 주세요.`,
                ].map((str, index) => (
                  <Text styleName="caption2">{`${index + 1}. ${str}`}</Text>
                ))}
              </GrayBox>
            </Stack>
          </Stack>
        }
        plain
        width={504}
        padding={24}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
      />

      <QueryStatusModal
        isOpen={callbackModal.open}
        setIsOpen={() => {
          setCallbackModal(p => {
            return {
              open: false,
              props: { ...p.props },
            };
          });
        }}
        {...callbackModal.props}
      />

      <CloseConfirmModal
        plain
        width={504}
        isOpen={shouldDeleteTeamBeforeDowngrade}
        setIsOpen={setShouldDeleteTeamBeforeDowngrade}
        targetSetIsOpen={() => {}}
        icon={<IcWarning width={44} height={44} style={{ marginTop: 20 }} />}
        text={
          <Stack spacing={8}>
            <Text styleName="body2" color="RC04">
              요금제를 {payment_plan_action_mapper[paymentTarget.action].str} 하기 전 팀을 삭제해야 합니다.
            </Text>
            <Text styleName="body2">선택하신 요금제는 팀 개수가 1개로 제한되어 있습니다.</Text>
            <Text styleName="body2">팀 관리로 이동 하시겠습니까?</Text>
          </Stack>
        }
        btnFunctions={{
          LBT: () => {
            setPaymentTarget(null);
          },
          RBT: () => {
            SETCURRENT('team');
            SETSETTINGOPEN(true);
            setPaymentTarget(null);
          },
        }}
        RBT={'팀 관리로 이동'}
        LBT={'취소'}
      />
      <PaymentAuthModal
        isPayment={true}
        authModal={authModal}
        setAuthModal={setAuthModal}
        info={{
          plan: paymentTarget.name,
          interval: paymentType,
        }}
        callback={() => {
          setPaymentTarget(null);
        }}
      />
    </>
  );
};

const ChargeBox = ({ price, next }: { price: number; next?: Date | string }) => {
  const dateFormat = 'YYYY.MM.DD';

  return (
    <GrayBox align="center" spacing={6} padding={20}>
      {next && <Text styleName="caption2" color="RG03">{`다음 결제 예정일 : ${dayjs(next).format(dateFormat)}`}</Text>}
      <Stack align="end" direction="row" justify="center">
        <Text styleName="body1">{`${next ? '정기 결제 금액' : '당월 결제 금액'} : `}</Text>
        <Text styleName="title1" color="RC06">
          {`${price.toLocaleString('kr')} 원`}
        </Text>
        <Text styleName="caption2" color="RG03">{` (부가세 포함)`}</Text>
      </Stack>
    </GrayBox>
  );
};

export default PaymentPlanModal;
