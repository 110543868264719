import type { CSSProperties } from 'react';
import * as S from 'auth/signUp/style';
import { Text } from 'components/Typography';

interface IInputLabel {
  label: string;
  useMarking?: boolean;
  sx?: CSSProperties;
}

export const InputLabel = (props: IInputLabel) => {
  const { useMarking = true, label, sx } = props;
  return (
    <S.InputLable style={{ ...sx }}>
      <Text styleName="subheadline2" color="RG02">
        {label}
      </Text>
      {useMarking && (
        <Text styleName="caption3" color="RC04" sx={{ paddingLeft: '2px' }}>
          *
        </Text>
      )}
    </S.InputLable>
  );
};
