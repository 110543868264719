import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { IRooutyPlan, roouty_plans } from 'constants/subscribePlans';

import * as S from './style';
import theme from 'constants/theme';

import Tag from 'components/Tag/Tag';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Toggle } from 'pages/Setting/common/Toggle';
import { Highlight, Text } from 'components/Typography';

import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { useStore } from 'store';
import { ButtonProps } from 'components/Button/button.interface';
import PaymentPlanModal, { TPaymentPlanActions } from '../Unsubscribe/PaymentPlanModal';
import { PaymentTypeUnion } from 'types/payment/union';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cancelDowngrade } from 'api';
import QueryStatusModal, { QueryStatusModalProps, TQueryStatus } from 'components/Modal/QueryStatusModal';
import { TQueryStatusModalSetterProps } from 'constants/types';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { LoadingModal } from '../../../../components/Modal/LoadingModal';
import { usePaymentConfirm } from '../../../../hooks/query/payment/usePaymentConfirm';
import { FOOTER_PATH } from '../../../../Layout/elements/constant';

export const PlanCard = ({
  children,
  isMobile = false,
  sx,
  ...attrs
}: {
  children: React.ReactNode;
  color: string;
  height: string;
  width?: string;
  emphasis?: boolean;

  isMobile?: boolean;

  sx?: CSSProperties;
}) => {
  return (
    <S.PlanCard {...attrs}>
      <div className="linear" />
      <Stack className="content" sx={isMobile ? { ...sx, padding: '32px 11px 22px' } : { ...sx }}>
        {children}
      </Stack>
    </S.PlanCard>
  );
};

interface PlansProps {
  /** card wrapping parent container */
  wp?: [any, { [key: string]: any }];

  /** cta display option */
  cta?: boolean;

  /** device type  */
  isMobile?: boolean;

  /** used location */
  isUsedLanding?: boolean;
}

export const Plans = ({
  wp: [wp, wpProps] = [null, {}],
  cta,
  isMobile = false,
  isUsedLanding,
  ...props
}: PlansProps) => {
  const navigate = useNavigate();
  const paymentStatus = useMemo(() => window.localStorage.getItem('paymentStatus') as TQueryStatus | null, []);
  const actionType = useMemo(() => window.localStorage.getItem('actionType') as TPaymentPlanActions | null, []);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const {
    mutate: mutatePaymentConfirm,
    isSuccess: isSuccessPaymentConfirm,
    isError: isErrorPaymentConfirm,
  } = usePaymentConfirm();

  const paymentActionPropsMapper: {
    [key in TPaymentPlanActions]: Omit<QueryStatusModalProps, 'status' | 'isOpen' | 'setIsOpen'>;
  } = {
    upgrade: {
      autoClose: 0,
      action: async () => {
        await queryClient.invalidateQueries({ queryKey: ['payment-boundaries'] });
        await queryClient.invalidateQueries({ queryKey: ['usage'] });
        await queryClient.invalidateQueries({ queryKey: [{ group: 'successPayment' }] }).then(() => {
          navigate('/mypage', { state: { head: 'payment' } });
        });
      },
      string: `요금제 결제가 완료되었습니다\n요금명세서는 [결제 관리 > 결제 내역] 에서 확인하실 수 있습니다.`,
    },
    downgrade: {},
    free: {
      autoClose: 0,
      action: async () => {
        await queryClient.invalidateQueries({ queryKey: ['payment-boundaries'] });
        await queryClient.invalidateQueries({ queryKey: ['usage'] });
        await queryClient.invalidateQueries({ queryKey: [{ group: 'successPayment' }] }).then(() => {
          navigate('/mypage', { state: { head: 'payment' } });
        });
      },
      string: `요금제 결제가 완료되었습니다\n요금명세서는 [결제 관리 > 결제 내역] 에서 확인하실 수 있습니다.`,
    },
    unsubscribe: {},
  };

  useEffect(() => {
    if (paymentStatus === 'success' && (actionType === 'upgrade' || actionType === 'free')) {
      setIsLoading(true);

      const orderCode = window.localStorage.getItem('orderCode');

      if (orderCode) {
        mutatePaymentConfirm({
          orderCode,
        });
      }
    }

    if (paymentStatus && actionType) {
      window.localStorage.removeItem('paymentStatus');
      window.localStorage.removeItem('actionType');
    }
  }, [actionType, paymentStatus]);

  useEffect(() => {
    if (isSuccessPaymentConfirm && actionType) {
      setIsLoading(false);

      setCallbackModal({
        open: paymentStatus === 'success',
        props: {
          status: paymentStatus ?? 'warning',
          ...paymentActionPropsMapper[actionType],
        },
      });
    }
  }, [actionType, paymentStatus, isSuccessPaymentConfirm]);

  useEffect(() => {
    if (isErrorPaymentConfirm) {
      setIsLoading(false);
    }
  }, [isErrorPaymentConfirm]);

  const { refetch, data: paymentMyData } = useGetPaymentMy({
    enabled: cta === true,
  });

  const [callbackModal, setCallbackModal] = useState<TQueryStatusModalSetterProps>({
    open: false,
    props: { status: 'loading' },
  });

  const { mutate: cancelDowngradeMutate } = useMutation(['cancel-downgrade'], cancelDowngrade, {
    onMutate: () => {
      setCallbackModal({ open: true, props: { status: 'loading' } });
    },
    onError: () => {
      setCallbackModal({ open: true, props: { status: 'error' } });
    },
    onSuccess: () => {
      setCallbackModal({
        open: true,
        props: { status: 'success', string: '다운그레이드 신청이 취소되었습니다.', callback: refetch },
      });
    },
  });

  const [paymentType, setPaymentType] = useState<PaymentTypeUnion>('yearly');
  const [paymentTarget, setPaymentTarget] = useState<(IRooutyPlan & { action: TPaymentPlanActions }) | null>(null);

  const PayCycle = useMemo((): JSX.Element => {
    const styler = (type: PaymentTypeUnion, color: TThemeColor) => {
      return type === 'yearly' ? color : 'RG04';
    };
    return (
      <Stack spacing={10} direction="row" sx={{ width: 'fit-content' }}>
        <Text styleName="title1" color={styler(paymentType, 'RG02')} broken={false} sx={{ whiteSpace: 'nowrap' }}>
          월 결제
        </Text>
        <Toggle
          isChecked={paymentType === 'yearly'}
          leftBgColor="RG05"
          leftColor="RC02"
          circleColor="RG06"
          setChecked={(checked: boolean) => {
            setPaymentType(checked ? 'yearly' : 'monthly');
          }}
        />
        <Text styleName="title1" color={styler(paymentType, 'RG02')} broken={false} sx={{ whiteSpace: 'nowrap' }}>
          연 결제
          <Highlight color={styler(paymentType, 'RC04')}>{` -30% 할인`}</Highlight>
        </Text>
      </Stack>
    );
  }, [paymentType]);

  const { userGrade: grade } = useStore();

  const child = useMemo(
    (): JSX.Element[] =>
      roouty_plans
        .filter(item => (cta === true ? item.name !== 'Free' : item))
        .map((item, index) => {
          const { primary, name, pricing, volume, features, hasDim, popular } = item;

          const isFree = pricing === 0;

          const actual_pricing = paymentType === 'yearly' ? (pricing * 70) / 100 : pricing;

          const _tier = item.tier + (paymentType === 'monthly' ? 0 : 4);
          const CTAMapper: { action: TPaymentPlanActions } & Partial<ButtonProps> =
            _tier === paymentMyData?.pricingId
              ? { children: '이용중인 요금제', action: 'free' }
              : _tier > (paymentMyData?.pricingId ?? 1)
              ? {
                  children:
                    name === 'Enterprise'
                      ? '별도 문의'
                      : paymentMyData?.name === 'Enterprise'
                      ? '다운그레이드'
                      : '업그레이드',
                  variant: 'primary',
                  action: paymentMyData?.name === 'Enterprise' ? 'downgrade' : 'upgrade',
                }
              : {
                  children: name === 'Enterprise' ? '별도 문의' : '다운그레이드',
                  action: 'downgrade',
                };

          const { action } = CTAMapper;

          console.log('ASDFASDFASDF', name, action);

          const disabled =
            grade !== 1 || _tier === paymentMyData?.pricingId || paymentMyData?.paymentStatus === 'paused';
          // || (paymentType === 'yearly' && paymentMyData?.name === 'Enterprise' && item.name === 'Lite');

          return (
            <PlanCard
              isMobile={isMobile}
              emphasis={_tier === paymentMyData?.pricingId}
              color={primary}
              width={isMobile ? '175px' : '250px'}
              height="100%"
              key={`plan-cards-${index}`}
            >
              <Stack spacing={10} justify="space-between" sx={{ height: '100%' }}>
                <Stack key="plancard-wrapper" name="plancard-wrapper" spacing={isMobile ? 14 : 20}>
                  <Stack key="plan-pricing-and-volume" name="plan-pricing-and-volume" spacing={isMobile ? 21 : 30}>
                    <Stack spacing={isMobile ? 7 : 10} direction="row">
                      <Text
                        styleName="headline1"
                        sx={
                          isMobile
                            ? {
                                fontSize: `calc(${100 * 1}%)`,
                              }
                            : {}
                        }
                      >
                        {capitalize(name)}
                      </Text>
                      {popular && <Tag children="가장 인기있는 요금제" color="RC02" h={24} />}
                    </Stack>
                    <Stack align="start" spacing={isMobile ? 2.8 : 0}>
                      {name === 'Enterprise' ? (
                        <Text
                          styleName="body1"
                          color="RC02"
                          sx={
                            isMobile
                              ? {
                                  fontSize: `calc(${100 * 0.7}%)`,
                                  lineHeight: '16.8px',
                                }
                              : {}
                          }
                        >
                          추가 기능 별도 문의
                        </Text>
                      ) : (
                        <Text
                          styleName="body1"
                          color="RC04"
                          sx={
                            isMobile
                              ? {
                                  fontSize: `calc(${100 * 0.7}%)`,
                                  lineHeight: '16.8px',
                                  visibility: !isFree && paymentType === 'yearly' ? 'visible' : 'hidden',
                                }
                              : { visibility: !isFree && paymentType === 'yearly' ? 'visible' : 'hidden' }
                          }
                        >
                          {`- 30% `}
                          <Highlight
                            color="RG04"
                            sx={{
                              textDecoration: 'line-through',
                            }}
                          >
                            {pricing} 만원
                          </Highlight>
                        </Text>
                      )}

                      <Text
                        styleName="headline1"
                        sx={
                          isMobile
                            ? {
                                fontSize: `calc(${100 * 1}%)`,
                                lineHeight: '26px',
                              }
                            : {}
                        }
                      >
                        {name === 'Enterprise' ? '맞춤형 요금제' : `${actual_pricing} ${isFree ? '원' : '만원'}`}
                      </Text>
                      <Text
                        styleName="subheadline2"
                        color="RG03"
                        sx={
                          isMobile
                            ? {
                                fontSize: `calc(${100 * 0.7}%)`,
                                lineHeight: '16.8px',
                                visibility: !isFree ? 'visible' : 'hidden',
                              }
                            : {
                                visibility: !isFree ? 'visible' : 'hidden',
                              }
                        }
                      >
                        /월 *부가세 별도
                      </Text>
                    </Stack>
                    {(!isUsedLanding || name !== 'Enterprise') && (
                      <Stack spacing={isMobile ? 2.8 : 4} align="start">
                        {Object.entries(volume).map(([key, value]) => (
                          <Text
                            key={key}
                            styleName="body2"
                            sx={
                              isMobile
                                ? {
                                    display: 'flex',
                                    height: 24,
                                    alignItems: 'center',
                                    fontSize: `calc(${100 * 0.7}%)`,
                                    lineHeight: `100%`,
                                  }
                                : {
                                    display: 'flex',
                                    alignItems: 'center',
                                  }
                            }
                          >
                            {key}
                            {' 수 '}
                            <Highlight styleName="headline1" sx={isMobile ? { fontSize: 16.8 } : {}}>
                              {value.toLocaleString('kr')}
                            </Highlight>
                          </Text>
                        ))}
                      </Stack>
                    )}
                  </Stack>
                  {(cta || (name === 'Enterprise' && isUsedLanding)) && (
                    <Button
                      children={null}
                      {...CTAMapper}
                      fw
                      h={40}
                      disabled={isUsedLanding ? name !== 'Enterprise' : disabled}
                      variant={
                        name === 'Enterprise' ? 'primary' : disabled ? 'tertiary' : CTAMapper.variant ?? 'tertiary'
                      }
                      onClick={e => {
                        console.log(
                          'ASDFASDFASDFADS',
                          name,
                          action,
                          ' /////// ',

                          grade !== 1,
                          _tier === paymentMyData?.pricingId,
                          paymentMyData?.paymentStatus === 'paused'
                        );

                        if (name === 'Enterprise' && (action === 'upgrade' || action === 'downgrade')) {
                          window.open(FOOTER_PATH.question.path, '_blank');
                          return;
                        }

                        if (paymentMyData?.interval === 'yearly' && paymentType === 'monthly') {
                          alert('기능 준비중입니다.');
                          return;
                        }

                        setPaymentTarget({
                          ...item,
                          action: paymentMyData?.name === 'Free' ? 'free' : action,
                          pricing: actual_pricing * 10000,
                        });
                      }}
                      sx={{ margin: name === 'Enterprise' && isUsedLanding ? `${isMobile ? 8 : 22}px 0` : '' }}
                    />
                  )}
                  <Stack
                    key="plan-features"
                    name="plan-features"
                    spacing={isMobile ? 12.6 : 18}
                    align="start"
                    sx={{ position: 'relative' }}
                  >
                    {features.map((feature, index) => (
                      <Stack key={`${index}-${feature}-${name}`} direction="row" spacing={10}>
                        <IconSuccess fill={theme.colors.RC03} style={{ width: '16px', height: '16px' }} />
                        <Text
                          styleName="body1"
                          sx={
                            isMobile
                              ? {
                                  fontSize: `calc(${100 * 0.7}%)`,
                                  lineHeight: '100%',
                                }
                              : {}
                          }
                        >
                          {feature}
                        </Text>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>

                {paymentMyData?.downgrade && _tier === paymentMyData?.downgrade.pricingId && (
                  <Stack name="downgrade" spacing={10} justify="center">
                    <Text styleName="subheadline2">{`결제 예정일 : ${dayjs(paymentMyData.downgrade.dueDate).format(
                      'YYYY.MM.DD'
                    )}`}</Text>
                    <Button
                      children={'다운그레이드 취소'}
                      variant="secondary"
                      fw
                      h={32}
                      onClick={() => {
                        cancelDowngradeMutate();
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </PlanCard>
          );
        }),
    [cta, grade, paymentType, paymentMyData, isMobile]
  );

  return (
    <>
      <Stack align={isMobile ? 'center' : 'end'} spacing={20}>
        {PayCycle}
        {wp ? (
          React.createElement(wp, { ...wpProps }, child)
        ) : (
          <Stack children={child} spacing={20} align="start" justify="space-between" direction="row" />
        )}
      </Stack>
      {paymentTarget && <PaymentPlanModal {...{ paymentType, paymentTarget, setPaymentTarget }} />}

      <QueryStatusModal
        autoClose={3000}
        isOpen={callbackModal.open}
        setIsOpen={() => {
          setCallbackModal(p => {
            return {
              open: false,
              props: { ...p.props },
            };
          });
        }}
        {...callbackModal.props}
      />

      <LoadingModal isLoading={isLoading} />
    </>
  );
};

export default Plans;
