import { PAYMENT_QUERIES } from './payment.queries';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PlanStatusUnion } from '../../../types/payment/union';
import { API } from '../../../api/ky';

type FetchGetReceiptResponse = {
  pricing: {
    actualAmount: number;
    name: PlanStatusUnion;
    interval: string;
    type: string;
    cost: number;
    discountAmount?: number;
    paidAmount?: number;
    vat: number;
    floorOfWon: number;
  };
  company: {
    name: string;
    manager: {
      name: string;
    };
    startDateOfUse: string;
    endDateOfUse: string;
    paidDate: string;
    payType: string;
    payMethod: string;
    billCode: string;
  };
  supplier: {
    name: string;
    businessNumber: string;
  };
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetReceipt>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof PAYMENT_QUERIES.GET_RECEIPT>;
};

const fetchGetReceipt = async ({ queryKey: [{ endPoint }] }: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchGetReceiptResponse>();

const useGetReceipt = <QueryReturnType = UseQueryGeneric['TData']>(
  id: number,
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    PAYMENT_QUERIES.GET_RECEIPT(id),
    fetchGetReceipt,
    options
  );

export { fetchGetReceipt, useGetReceipt };
export type { FetchGetReceiptResponse };
