export const splicePathname = (path: string, target: string) => {
  const pathArr = path.split('/').filter(Boolean);
  return pathArr
    .splice(
      0,
      pathArr.findIndex(path => path === target)
    )
    .join('/');
};

export const removeString = (text: string) => {
  const regex = /\d/;
  return text
    .split('')
    .filter(str => regex.test(str))
    .join('');
};

export const validatePassword = (password: string): boolean => {
  const regex = /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,64}$/;
  return regex.test(password);
};
