import { apiErrorHandler } from 'api';
import { baseAPI } from 'api/instance';

export const getMyAccountUserData = async (): Promise<TMyPageAccountUserResponse | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/profile/my';
    const { data } = await baseAPI({ url: URL, headers: { token }, method: 'GET' });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getMyAccountCompanyData = async (): Promise<TMyPageAccountCompanyResponse | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/company';
    const { data } = await baseAPI({ url: URL, headers: { token }, method: 'GET' });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const putUpdateMyAccountUserData = async (props: {
  name: string;
  phone: string;
  marketingConsent: boolean;
}): Promise<void | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/profile/my';
    await baseAPI({ url: URL, headers: { token }, method: 'PUT', data: { ...props } });
    return;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const putUpdateMyAccountCompanyData = async (props: {
  payload: Partial<TPutUpdateCompanyDataRequest>;
}): Promise<void | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/company';
    await baseAPI({ url: URL, headers: { token }, method: 'PUT', data: { ...props.payload } });
    return;
  } catch (error) {
    return apiErrorHandler(error);
  }
};
