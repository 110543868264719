import { ReactElement, cloneElement } from 'react';

import styled from 'styled-components';

import { Text } from 'components/Typography';
import { Stack, StackProps } from 'components/Stack';

export const IconMarker = ({ icon, size }: { icon: ReactElement } & Pick<IconPaletteProps, 'size'>) => {
  return (
    <Marker size={size[0]} align="center" justify="center">
      {cloneElement(icon, {
        width: size[1],
        height: size[1],
      })}
    </Marker>
  );
};

interface IconPaletteProps extends Omit<StackProps, 'children'> {
  /** Svg Icon or any React Element */
  icon: Array<ReactElement> | ReactElement;
  /** diameter [ outer, inner ] */
  size: [number, number];

  /** diameter [ outer, inner ] */
  label?: Array<string | null> | string | null;
}

const IconPalette = ({ icon, size, label, ...porps }: IconPaletteProps) => {
  return (
    <Stack {...porps}>
      {Array.isArray(icon) ? (
        icon.map((pIcon, i) => {
          return (
            <PaletteItemWrapper spacing={6} direction="row">
              <IconMarker icon={pIcon} {...{ size }} />
              {label && <Text styleName="tooltip1">{label[i]}</Text>}
            </PaletteItemWrapper>
          );
        })
      ) : (
        <PaletteItemWrapper spacing={6} direction="row">
          <IconMarker icon={icon} {...{ size }} />
          {label && <Text styleName="tooltip1">{label}</Text>}
        </PaletteItemWrapper>
      )}
    </Stack>
  );
};

export default IconPalette;

const PaletteItemWrapper = styled(Stack)`
  flex-shrink: 0;

  width: max-content;
  white-space: nowrap;
`;

const Marker = styled(Stack)<{ size: number }>`
  flex-shrink: 0;
  display: inline-flex;

  ${({ size, theme }) => ({
    width: `${size}px`,
    height: `${size}px`,

    borderRadius: `calc( ${size}px / 2 )`,

    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;
