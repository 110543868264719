import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';

type FetchDeleteMasterOrderResponse = {};

type Variables = {
  isAll?: string;
  masterOrderIds: number[];
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchDeleteMasterOrder>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.DELETE_MASTER_ORDER>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchDeleteMasterOrder = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.put(endPoint, {
    json: variables,
  }).json<FetchDeleteMasterOrderResponse>();

const useDeleteMasterOrder = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.DELETE_MASTER_ORDER(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchDeleteMasterOrder({
        variables: variables,
        ...MASTER_DATA_QUERIES.DELETE_MASTER_ORDER()[0],
      }),
    options
  );

export { fetchDeleteMasterOrder, useDeleteMasterOrder };
export type { FetchDeleteMasterOrderResponse };
