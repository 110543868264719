import { PAYMENT_QUERIES } from './payment.queries';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HTTPError } from 'ky';

type FetchUsePaymentConfirmResponse = {
  message: string;
};

type Variables = {
  orderCode: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchUsePaymentConfirm>>;
  TError: HTTPError;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof PAYMENT_QUERIES.PAYMENT_CONFIRM>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchUsePaymentConfirm = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchUsePaymentConfirmResponse>();

const usePaymentConfirm = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    PAYMENT_QUERIES.PAYMENT_CONFIRM(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchUsePaymentConfirm({
        variables: variables,
        ...PAYMENT_QUERIES.PAYMENT_CONFIRM()[0],
      }),
    {
      ...options,
    }
  );

export { fetchUsePaymentConfirm, usePaymentConfirm };
export type { FetchUsePaymentConfirmResponse };
