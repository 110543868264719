import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';
import { HTTPError } from 'ky';

type FetchModifySkillResponse = {
  skillId: number;
};

type Variables = {
  name: string;
  description?: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchModifySkill>>;
  TError: HTTPError;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.MODIFY_SKILL>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchModifySkill = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.put(endPoint, {
    json: variables,
  }).json<FetchModifySkillResponse | IErrorResponse>();

const useModifySkill = <TContext = UseMutationGeneric['TData']>(
  id?: number,
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.MODIFY_SKILL(id),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchModifySkill({
        variables: variables,
        ...MASTER_DATA_QUERIES.MODIFY_SKILL(id)[0],
      }),
    options
  );

export { fetchModifySkill, useModifySkill };
export type { FetchModifySkillResponse };
