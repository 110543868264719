import styled from 'styled-components';

interface ProgressProps {
  value: number;
  isError?: boolean;
}

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;
  width: 100%;
`;

export const ProgressContainer = styled.div<{ spacing: number | undefined }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  row-gap: 2px;

  gap: ${({ spacing }) => spacing && `${spacing}px`};
`;

export const ProgressLabel = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  gap: 8px;

  width: 100%;
`;

export const ProgressPlainLabel = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;

  width: 100%;
`;

export const ProgressBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.RG06};

  position: relative;
  width: 100%;
  height: 4px;

  border-radius: 2px;
`;

export const Progress = styled.div<ProgressProps>`
  background-color: ${({ theme, isError }) => (isError ? theme.colors.RC04 : theme.colors.RC03)};

  position: absolute;

  max-width: 100%;
  width: ${({ value }) => (value ? `${value}%` : '0%')};
  height: 100%;

  border-radius: 2px;

  transition: width 0.2s ease-out;
`;
