import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { FOOTER_PATH } from 'Layout/elements/constant';
import * as S from 'Layout/style';

export const Footer = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <S.Footer>
      <S.FooterContents>
        <Stack
          align="start"
          spacing={20}
          sx={{
            width: 'max-content',
          }}
        >
          <Text styleName={isMobile ? 'caption1' : 'body1'} color="RG03">
            © 2023 Wemeet mobility Co., Ltd. All rights reserved.
          </Text>
          <Text styleName={isMobile ? 'subheadline2' : 'body2'} color="RG03" sx={{ lineHeight: '32px' }}>
            (주) 위밋모빌리티
            <br />
            사업자 등록번호 : 806 - 87 - 00694
            <br />
            통신판매신고 : 2023-서울마포-1310호
            <br />
            대표 : 강귀선
            <br />
            대표 전화 : 1533 - 0441
            <br />
            문의 이메일 : sales@wemeetmobility.com
            <br />
            서울특별시 관악구 조원로 5-14 (신림동 1644-1) 4층-6층
          </Text>
        </Stack>
        <Stack
          name="menus"
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 18 : 20}
          align="start"
          justify="start"
          sx={{
            flexWrap: 'wrap',
            width: 'max-content',
          }}
        >
          <Stack align="start" spacing={18} sx={{ width: '196px' }}>
            {FOOTER_PATH.links.map((link, idx) => {
              return (
                <a key={`${link.name}`} href={link.path} target="_blank" rel="noreferrer">
                  <Text styleName="body1" color="RG04" style={{ width: 'fit-content' }}>
                    {link.name}
                  </Text>
                </a>
              );
            })}
          </Stack>
          <a href={FOOTER_PATH.question.path} target="_blank" rel="noreferrer">
            <Text styleName="body1" color="RG04">
              {FOOTER_PATH.question.name}
            </Text>
          </a>
        </Stack>
      </S.FooterContents>
    </S.Footer>
  );
};
