import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { REPORT_QUERIES } from 'hooks/query/report/report.queries';
import { ReportDownloadVariables } from 'types/report/drive';

interface UseMutationGeneric {
  TData: Awaited<ReturnType<typeof fetchDownloadReportDriver>>;
  TError: Error;
  TVariable: ReportDownloadVariables;
  TMutationContext: ReturnType<typeof REPORT_QUERIES.DOWNLOAD_REPORT_DRIVER>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
}

const fetchDownloadReportDriver = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).then(async response => ({
    data: await response.arrayBuffer(),
    title: decodeURIComponent(response.headers.get('Content-Disposition')?.split('filename=')[1] ?? ''),
  }));

const useDownloadReportDriver = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    REPORT_QUERIES.DOWNLOAD_REPORT_DRIVER(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchDownloadReportDriver({
        variables: variables,
        ...REPORT_QUERIES.DOWNLOAD_REPORT_DRIVER()[0],
      }),
    options
  );

export { fetchDownloadReportDriver, useDownloadReportDriver };
