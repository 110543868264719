import { forwardRef, createElement, ReactElement } from 'react';

import { ButtonProps } from './button.interface';

import * as S from './style';

export const Button = forwardRef<any, ButtonProps>(
  ({ unit = 'px', sx, children, icon, ...props }, ref): ReactElement => {
    return (
      <S.Button ref={ref} style={sx} {...{ unit }} {...props}>
        {icon && createElement(Array.isArray(icon) ? icon[0] : icon, { ...(Array.isArray(icon) ? icon[1] : {}) })}
        {children}
      </S.Button>
    );
  }
);
