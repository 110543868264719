import { ReactNode, MouseEvent, useEffect, useRef, useState, CSSProperties, ReactElement } from 'react';
import * as S from './style';

import { Text } from 'components/Typography';
import { Input } from 'components/Input';
import { UseFormReturn } from 'react-hook-form';

export type OptionType<T = unknown> = {
  label: string;
  value: any;
};

export interface SwapProps {
  name: string;
  options: Array<OptionType>;

  allowUncheck?: boolean;

  defaultIndex?: number;
  methods: UseFormReturn<any>;
  maxWidth?: number;
  label?: string | ReactNode;
  sx?: CSSProperties;
  renderCounter?: number;
  padding?: number;
  gap?: number;
  className?: string;

  watch?: any;
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>, current: string, isActive: boolean) => void;
}

export function Swap({ defaultIndex, label, sx, onClick = () => {}, ...props }: SwapProps): ReactElement {
  const renderCounter = useRef(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex ?? 0);
  const [className, setClassName] = useState<string>(props.className ?? '');

  const setColor = (index: number) => {
    return index === selectedIndex ? 'RC02' : 'RG04';
  };

  useEffect(() => {
    console.log(props.watch);
    props.watch && setSelectedIndex(props.options.findIndex(d => d.value === props.methods.getValues(props.name)));
  }, [props.watch]);

  useEffect(() => {
    // do not set dirty at first render with DefaultValues !!!!
    if (typeof defaultIndex === 'number' && selectedIndex >= 0 && renderCounter.current === 0) {
      console.log('in first cage');

      renderCounter.current += 1;
      return props.methods.setValue(props.name, props.options[selectedIndex].value, {
        shouldDirty: defaultIndex === -3 ? true : false,
      });
    } else if (props.renderCounter) {
      console.log('custome cage');

      props.renderCounter += 1;
      return props.methods.setValue(props.name, props.options[selectedIndex].value, {});
    } else {
      if (selectedIndex === -3) {
        console.log('in -3 cage');

        props.methods.setValue(props.name, null, {
          shouldDirty: true,
        });
      } else {
        console.log('in else cage');
        props.methods.setValue(props.name, props.options[selectedIndex].value, {
          shouldDirty: true,
        });
      }
    }

    return () => {};
  }, [selectedIndex, props.methods, props.name, props.options]);

  return (
    <S.SwapContainer spacing={6} style={sx}>
      {label}
      <S.SwapItemsWrapper {...props} className={className}>
        {props.options.map((items, index) => {
          return (
            <S.SwapItems
              isSelected={index === selectedIndex}
              key={`${items.label}-${index}-${items.value}`}
              type={'button'}
              onClick={e => {
                if (index === selectedIndex && props.allowUncheck) setSelectedIndex(-3);
                else setSelectedIndex(index);
                onClick(e as any, items.value, index === selectedIndex);
              }}
            >
              <Text styleName={'caption1'} color={index === selectedIndex ? 'RC02' : 'RG04'}>
                {items.label}
              </Text>
            </S.SwapItems>
          );
        })}
      </S.SwapItemsWrapper>
      <Input
        name={props.name}
        register={props.methods.register}
        watch={props.methods.watch}
        reset={props.methods.reset}
        sx={{ display: 'none' }}
      />
    </S.SwapContainer>
  );
}
