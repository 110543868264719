import { ReactNode, useState } from 'react';
import { getCoreRowModel, getSortedRowModel, PaginationState, useReactTable } from '@tanstack/react-table';

import TableComponent from '.';

interface InternalTableProps<T> {
  data: Array<T>;
  columns: any;

  placeCell?: number;
  placeholder?: ReactNode;
}
const InternalTable = <T,>({ data, columns, placeCell = 6, ...rest }: InternalTableProps<T>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100000,
  });

  const instance = useReactTable({
    data,
    columns,
    state: {},
    initialState: {},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableComponent
      tableType="order"
      table={instance}
      isDataFetching={false}
      placeholder="보류 이력이 없습니다."
      tableHeight={(placeCell + 1) * 42}
      tableRowHeight={42}
      disableOptions={{
        pageSizeSelector: true,
        pagnation: true,
        selectCount: true,
        totalCount: true,
      }}
      {...rest}
      {...{ pagination, setPagination }}
    />
  );
};

export default InternalTable;
