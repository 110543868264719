import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FetchPaymentInfoData } from '../../../hooks/query/payment/useGetPaymentInfo';
import { ManagerInfoInput } from '../../Input/ManagerInfoInput';
import { validateName } from '../../../util/validate/validateName';

export interface ChangeManagerNameProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  setPaymentInfoData: Dispatch<SetStateAction<Partial<FetchPaymentInfoData>>>;
  setIsDisabledSaveButton: Dispatch<SetStateAction<boolean>>;
}

export const ChangeManagerName = ({
  name,
  setName,
  setPaymentInfoData,
  setIsDisabledSaveButton,
}: ChangeManagerNameProps) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setPaymentInfoData(prev => ({
      ...prev,
      name: hasError ? '' : name,
    }));
  }, [hasError, name, setPaymentInfoData]);

  return (
    <ManagerInfoInput
      text={name}
      setText={setName}
      placeholder="이름을 입력해 주세요"
      isCheckedValidateAction={validateName}
      hasError={hasError}
      setHasError={setHasError}
      errorMessage={errorMessage}
      onValidation={(validate, value) => {
        setHasError(!(value.length && validate));
        setIsDisabledSaveButton(!(value.length && validate));

        if (!value.length) {
          setErrorMessage('이름은 필수입력 사항입니다.');
        } else if (!validate) {
          setErrorMessage('이름은  2~20자 사이여야 합니다.');
        } else {
          setErrorMessage('');
        }
      }}
    />
  );
};
