import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';
import { IAddSkill } from 'types/masterData/multiSkill';

type FetchAddSkillResponse = {
  skillId: number;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchAddSkill>>;
  TError: Error;
  TVariable: IAddSkill;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.ADD_SKILL>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchAddSkill = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchAddSkillResponse>();

const useAddSkill = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.ADD_SKILL(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchAddSkill({
        variables: variables,
        ...MASTER_DATA_QUERIES.ADD_SKILL()[0],
      }),
    options
  );

export { fetchAddSkill, useAddSkill };
export type { FetchAddSkillResponse };
