export const ROUTE_QUERIES = {
  SCOPE: [{ scope: 'ROUTE' }] as const,
  DOWNLOAD_ROUTE: () => [
    {
      ...ROUTE_QUERIES.SCOPE[0],
      group: 'route',
      entity: 'download_route',
      endPoint: 'route/download',
    },
  ],
  DOWNLOAD_ROUTE_ITEM: () => [
    {
      ...ROUTE_QUERIES.SCOPE[0],
      group: 'route',
      entity: 'download_route_item',
      endPoint: 'route/download/item',
    },
  ],
};
