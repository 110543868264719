import { useEffect, useRef, useState } from 'react';
import SwiperCore from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Text } from 'components/Typography';
import imageSwiper from './ImageSwiper';
import { CustomSwiper, CustomSwiperSlide, SwiperContainer, SwiperNavButton } from './style';

import { ReactComponent as IcArrowLeft } from 'constants/icon/ic_arrowleft20.svg';
import { ReactComponent as IcArrowRight } from 'constants/icon/ic_arrowright20.svg';

// type Tcontent = Array<TImageSwiper & { blank?: boolean }>;
type Tcontent = Array<any>;

interface SwiperProps {
  content: Tcontent;
  type: 'img';

  per: number;
  spacing: number;

  placeholder?: string;
}

const Mapped = {
  img: imageSwiper,
};

const Swiper = ({ content, placeholder = '컨텐츠가 없습니다.', ...props }: SwiperProps) => {
  const MappedComponent = Mapped[props.type];

  const { spacing, per } = props;
  const swiperRef = useRef<SwiperCore>();
  const [isNavigate, setIsNavigate] = useState<boolean>(content.length <= per ? false : true);
  const [swiperOptions, setSwiperOptions] = useState<any | null>(null);

  const [contents, setContents] = useState<Tcontent>([]);

  useEffect(() => {
    if (Boolean(content.length))
      setContents(
        [...content].concat(
          Array.from({ length: per <= content.length ? 0 : 3 - (content.length % 3) }, () => {
            return { blank: true };
          })
        )
      );

    if (content.length <= per) {
      setIsNavigate(false);
    }
  }, [content, per]);

  useEffect(() => {
    console.log(contents);
  }, [contents]);

  useEffect(() => {
    if (!swiperOptions) {
      setSwiperOptions({
        slidesPerView: per,
        slidesPerGroup: per,
        spaceBetween: spacing,

        onBeforeInit: (swiper: SwiperCore) => {
          swiperRef.current = swiper;
        },
      });
    }
  }, [per, spacing, swiperOptions]);

  return (
    <SwiperContainer>
      {isNavigate && (
        <SwiperNavButton onClick={() => swiperRef.current?.slidePrev()}>
          <IcArrowLeft width={16} height={16} />
        </SwiperNavButton>
      )}
      {swiperOptions && (
        <CustomSwiper {...swiperOptions} {...{ isNavigate }}>
          {Boolean(contents.length) ? (
            contents.map((d, index) => {
              if (d.blank) return <CustomSwiperSlide />;
              return (
                <CustomSwiperSlide>
                  <MappedComponent {...{ d, index }} />
                </CustomSwiperSlide>
              );
            })
          ) : (
            <Text styleName="title2" color="RG04" sx={{ padding: '0 4px' }}>
              {placeholder}
            </Text>
          )}
        </CustomSwiper>
      )}

      {isNavigate && (
        <SwiperNavButton onClick={() => swiperRef.current?.slideNext()}>
          <IcArrowRight width={16} height={16} />
        </SwiperNavButton>
      )}
    </SwiperContainer>
  );
};

export default Swiper;
