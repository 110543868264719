import { useQuery } from '@tanstack/react-query';
import {
  ColumnOrderState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { getAreaList } from 'api';
import { Button } from 'components/Button';
import { AlertModal } from 'components/Modal/AlertModal';
import BatchDriverModifyingModal from 'components/Modal/BatchDriverModifyingModal';
import DriverDetailModal from 'components/Modal/DriverDetailModal';
import { QueryStatusModalProps } from 'components/Modal/QueryStatusModal';
import TableColumnOrderControlModal from 'components/Modal/TableColumnOrderControlModal';

import { TDragablePickerOptions } from 'components/Popup/DragablePicker';

import { Stack } from 'components/Stack';
import { Tag, TTagStatusKeys } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';
import { common_table_selection, setting_involved_driver_management_table_column_content } from 'constants/commons';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcSetting } from 'constants/icon/ic_setting.svg';
import { ReactComponent as IcEdit } from 'constants/icon/icon_edit.svg';

import { IDB_Names } from 'constants/IDB';

import theme from 'constants/theme';
import { IInvolvedDriver, TAccountStatus, TOperationType } from 'constants/types';
import dayjs from 'dayjs';
import setupIndexedDB, { useIndexedDBStore } from 'hooks/useIndexedDB';
import StatusTag from 'pages/Setting/common/StatusTag';
import { TDriverSettingActions } from 'pages/Setting/resource/Driver';
import { PlainTag } from 'pages/Setting/RouteArea';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { isFalsy } from 'util/isFalsy';

import TableComponent from '.';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

const columnHelper = createColumnHelper<IInvolvedDriver>();

const InvolvedDriverManagementTable = ({
  data,
  onControl,

  selectedDriver,
  setSelectedDriver,

  callbackModalSetter,
  setProcessingDriverAlertModalIsOpen,
}: {
  data: Array<IInvolvedDriver>;
  onControl: (
    open: boolean,
    props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>
  ) => void;
  selectedDriver: Array<any>;
  setSelectedDriver: Dispatch<SetStateAction<Array<any>>>;
  setProcessingDriverAlertModalIsOpen: Dispatch<SetStateAction<boolean>>;

  callbackModalSetter: (action: TDriverSettingActions) => void;
}) => {
  const [TableColumnOrderControlModalIsOpen, setTableColumnOrderControlModalIsOpen] = useState<boolean>(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);
  const [batchModifyingDriverModalIsOpen, setBatchModifyingDriverModalIsOpen] = useState<boolean>(false);
  const [isOpenSelectedOtherTeamModal, setIsOpenSelectedOtherTeamModal] = useState<boolean>(false);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0);

  const [detailTarget, setDetailTarget] = useState<IInvolvedDriver | undefined>(undefined);

  const { data: settedAreaList } = useQuery(['getSettedAreaList'], () => getAreaList({}), {});

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const [tableColumnSettingOptions, setTableColumnSettingOptions] = useState<TDragablePickerOptions>(
    setting_involved_driver_management_table_column_content
  );

  const { update, getOneByKey } = useIndexedDBStore(IDB_Names.user.stores[0].name);

  const setColumnOrderInIDB = async (memory: TDragablePickerOptions) => {
    let IDB_CurrentUser = (await getOneByKey('userId', parseInt(window.localStorage.getItem('userId') ?? '-33'))) as {
      [key: string]: any;
    };

    if (!IDB_CurrentUser) {
      return;
    } else {
      IDB_CurrentUser.InvolvedDriverTableColumnSetting = {
        ...IDB_CurrentUser.InvolvedDriverTableColumnSetting,
        memory,
      };

      update({ ...IDB_CurrentUser });
    }
  };

  const getIDBColumn = async () => {
    const targetUserId = window.localStorage.getItem('userId');
    if (!targetUserId) return;
    else {
      let res = await (getOneByKey('userId', parseInt(targetUserId)) as any);

      const updater = (res?.InvolvedDriverTableColumnSetting?.memory as TDragablePickerOptions)?.map((d, i) => {
        if (d.name !== setting_involved_driver_management_table_column_content.find(x => x.key === d.key)?.name) {
          return {
            ...d,
            name: setting_involved_driver_management_table_column_content.find(x => x.key === d.key)?.name,
          };
        } else {
          return d;
        }
      });

      console.log(updater, 'updater');

      if (updater) setTableColumnSettingOptions(updater as TDragablePickerOptions);
    }
  };

  useEffect(() => {
    setupIndexedDB({
      databaseName: IDB_Names.user.DB_Name,
      version: IDB_Names.user.version,
      stores: IDB_Names.user.stores,
    })
      .then(() => {
        console.log('successfully set');
      })
      .catch(e => console.error('error / unsupported', e));
  }, []);

  useEffect(() => {
    getIDBColumn();
  }, []);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedDriver.filter(z => z.driverId === y.driverId).length === 0);
      setSelectedDriver([...selectedDriver, ...orders]);
    } else {
      let orders: Array<any> = selectedDriver;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.driverId !== s.original?.driverId);
      });
      setSelectedDriver(orders);
    }
  }

  useEffect(() => {
    let t: any = {};
    selectedDriver.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.driverId === x.driverId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedDriver, setRowSelection]);

  useEffect(() => {
    let columnVisible: { [key: string]: boolean } = {};

    tableColumnSettingOptions.forEach(d => {
      d.hidden ? (columnVisible[`${d.key}`] = false) : (columnVisible[`${d.key}`] = d.isSelected);
    });
    setColumnVisibility(columnVisible);
    setColumnOrder(['select'].concat(tableColumnSettingOptions.map(d => `${d.key}`)));
  }, [tableColumnSettingOptions]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              console.log('why');
              e.stopPropagation();
            }}
          />
        ),
      }),
      columnHelper.accessor(row => row.teamName, {
        id: 'teamName',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.status, {
        id: 'status',
        header: '상태',
        cell: info => {
          if (info.getValue()) {
            const mapper: { [key in TAccountStatus]: ReactNode } = {
              invited: null,
              accepted: null,
              activated: <StatusTag styleName="caption1" text={'활성'} />,
              deactivated: <StatusTag styleName="caption1" text={'비활성'} color="RG04" borderColor="RG04" />,
            };
            return mapper[info.getValue()];
          } else return null;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: '이름',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.loginAccount, {
        id: 'loginAccount',
        header: '아이디(휴대폰번호)',
        cell: info => {
          return info.getValue()
            ? info
                .getValue()
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
            : null;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.operationType, {
        id: 'operationType',
        header: '운영 유형',
        cell: info => {
          const mapper: { [key in TOperationType]: { string: string; key: TTagStatusKeys } } = {
            backup: { string: '용차', key: 'moving' },
            regular: { string: '고정차', key: 'done' },
          };

          return info.getValue() ? (
            <Tag active status={mapper[info.getValue()].key} sx={{ width: 45, height: 24 }} fit>
              {mapper[info.getValue()].string}
            </Tag>
          ) : null;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: '활성 일자',
        cell: info => dayjs(info.getValue()).format('YYYY.MM.DD'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.model?.name, {
        id: 'modelName',
        header: '차종',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row?.memo, {
        id: 'memo',
        header: '메모',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.maxCapacity, {
        id: 'maxCapacity',
        header: '최대 용적량',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.area, {
        id: 'area',
        header: '담당 권역',
        cell: info => {
          if (info.getValue()) {
            const areaNames = info.getValue().map(d => d.name);
            const tooLong = areaNames.find(x => x?.length && x.length > 15);

            return (
              <Stack spacing={6} direction="row" sx={{ width: 'max-content' }}>
                {tooLong ? (
                  <>
                    <PlainTag> {tooLong}</PlainTag>
                    {areaNames.length - 1 >= 1 && (
                      <Text styleName="caption2" color="RG02">
                        외&nbsp;<Highlight color="RC02">{areaNames.length - 1}</Highlight>&nbsp;권역
                      </Text>
                    )}
                  </>
                ) : (
                  areaNames.map((x, i) => {
                    console.log(x, i);
                    return i < 2 ? (
                      <PlainTag>{x}</PlainTag>
                    ) : (
                      areaNames.length - 1 === i && (
                        <Text styleName="caption2" color="RG02">
                          외&nbsp;<Highlight color="RC02">{i - 1}</Highlight>&nbsp;권역
                        </Text>
                      )
                    );
                  })
                )}
              </Stack>
            );
          } else return null;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.skills?.map(skill => skill.name).join(', '), {
        id: 'skill',
        header: '특수 조건',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.workStartTime, {
        id: 'workStartTime',
        header: '주행 시작 시간',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.workEndTime, {
        id: 'workEndTime',
        header: '주행 종료 시간',
        cell: info => (info.getValue() ? info.getValue() : '없음'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.driverBreak?.duration, {
        id: 'breakDuration',
        header: '휴게시간 (분)',
        cell: info => (info.getValue() ? `${Math.floor((info.getValue() ?? 0) / 60)}분` : null),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row?.driverBreak, {
        id: 'breakRange',
        header: '휴게시간 범위',
        cell: info => {
          return `${info.getValue()?.startTime ?? ''} ~ ${info.getValue()?.endTime ?? ''}`;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.startAddress, {
        id: 'startAddress',
        header: '출발지 주소',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.startDetailAddress, {
        id: 'startDetailAddress',
        header: '출발지 상세주소',
        footer: info => info.column.id,
      }),

      columnHelper.accessor(row => row.endAddress, {
        id: 'endAddress',
        header: '도착지 주소',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.endDetailAddress, {
        id: 'endDetailAddress',
        header: '도착지 상세주소',
        footer: info => info.column.id,
      }),
    ],
    [anchorIsOpen, settedAreaList]
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnOrder,
      columnVisibility,
      pagination,
      rowSelection,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    sortDescFirst: true,
  });

  const isButtonDisable = useMemo(() => {
    return isFalsy(selectedDriver);
  }, [selectedDriver]);

  return (
    <React.Fragment>
      <TableComponent<IInvolvedDriver>
        table={instance}
        isDataFetching={false}
        tableType="driver"
        clearBottom
        onRowClick={(e, row) => {
          setDetailTarget(row.original);
          setDetailModalIsOpen(true);
        }}
        sc={[
          <Button
            children={'드라이버 일괄 수정'}
            variant={'tertiary'}
            h={32}
            icon={[IcEdit, { width: 16, height: 16 }]}
            disabled={isButtonDisable}
            onClick={() => {
              const driverTeamId = new Set(selectedDriver.map(driver => driver.teamId));
              if (driverTeamId.size > 1) {
                setIsOpenSelectedOtherTeamModal(true);

                return;
              }

              setSelectedTeamId(driverTeamId.values().next().value);

              setBatchModifyingDriverModalIsOpen(true);
            }}
          />,
          <Button
            children={'드라이버 삭제'}
            variant={'tertiary'}
            h={32}
            icon={[IcBin, { width: 16, height: 16 }]}
            disabled={isButtonDisable}
            onClick={() => callbackModalSetter('deleteDriver')}
          />,
          <Button
            children={'조회항목 설정'}
            variant={'tertiary'}
            h={32}
            icon={[IcSetting, { width: 16, height: 16, fill: theme.colors.RG04 }]}
            onClick={() => setTableColumnOrderControlModalIsOpen(true)}
          />,
        ]}
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}

      {detailTarget && (
        <DriverDetailModal
          isOpen={detailModalIsOpen}
          setIsOpen={setDetailModalIsOpen}
          {...{ target: detailTarget, onControl, setProcessingDriverAlertModalIsOpen }}
        />
      )}

      <BatchDriverModifyingModal
        {...{
          onControl,
          selectedDriver: selectedDriver.map(d => d.driverId),
          isOpen: batchModifyingDriverModalIsOpen,
          setIsOpen: setBatchModifyingDriverModalIsOpen,
          teamId: selectedTeamId,
        }}
      />

      <TableColumnOrderControlModal
        isOpen={TableColumnOrderControlModalIsOpen}
        setIsOpen={setTableColumnOrderControlModalIsOpen}
        options={tableColumnSettingOptions}
        setOptions={setTableColumnSettingOptions}
        initial={setting_involved_driver_management_table_column_content}
        setColumnOrderInIDB={setColumnOrderInIDB}
      />

      <AlertModal
        type="WARNING"
        isOpen={isOpenSelectedOtherTeamModal}
        messages={
          <Stack sx={{ gap: '8px' }}>
            <Text styleName="body1" color="RC04">
              소속 팀이 다른 드라이버가 선택되었습니다.
            </Text>
            <Text styleName="body2" color="RG02">
              소속 팀이 동일한 드라이버를 선택하신 뒤 다시 시도해주세요.
            </Text>
          </Stack>
        }
        buttonGroup={
          <Button
            variant="primary"
            h={40}
            sx={{ width: '100%', marginTop: '40px' }}
            onClick={() => {
              setIsOpenSelectedOtherTeamModal(false);
            }}
          >
            <Text styleName="body1" color="RG00">
              확인
            </Text>
          </Button>
        }
      />
    </React.Fragment>
  );
};

export default InvolvedDriverManagementTable;
