import Tippy from '@tippyjs/react';
import AltWrapper from 'components/Alt/AltWrapper';
import Divider from 'components/Divider';

import Modal from 'components/Modal';
import { Stack } from 'components/Stack';
import RoutePlanConfirmTable from 'components/Table/RoutePlanConfirmTable';
import { OperationType } from 'components/Table/style';
import { ShowTableView } from 'components/Timeline/components/Clipboard';

import { Highlight, Text } from 'components/Typography';
import { ColorPalette } from 'constants/colorPalette';
import { route_origin_session_key } from 'constants/commons';

import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as LunchTimeIcon } from 'constants/icon/ic_lunchtime.svg';
import theme from 'constants/theme';
import {
  DriverIdUnSelected,
  IEqualOptimizeResponsesDriver,
  ITimelineBlock,
  ITimelineData,
  TThemeColor,
} from 'constants/types';

import dayjs from 'dayjs';
import { createElement, Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { getTotalReqTimeSameDate } from 'util/getDriverSummary';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';

// Driver Value Calculator
export const DVCalculator = (darr: IEqualOptimizeResponsesDriver) => {
  const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!);

  // total order
  const TO = darr.orderList.filter(d => d.type === 'order' && d.orderId).length;
  // total distance
  const TD = darr.orderList.reduce((x, y) => (x += y.route?.internal?.requiredDistance ?? 0), 0);

  let TOTALTIME = getTotalReqTimeSameDate({
    performedDate: origin.performedDate,
    firstOrderETA: darr.orderList.at(0)?.route?.internal?.eta ?? '',
    workStartTime: darr.workStartTime,
    lastOrderETA: darr.orderList.at(-1)?.route?.internal?.eta ?? '',
  });

  const DOC = darr.orderList.reduce((x, y) => x + (y?.capacity ?? 0), 0);
  const DVC = darr?.vehicle?.maxCapacity ?? 0;

  const number_of_orders: number = TO;
  const cargos: number = DOC;
  const hour: number = Math.floor(TOTALTIME / 60 / 60);
  const min: number = Math.floor((TOTALTIME / 60) % 60);
  const distance: string = numberWithCommas(roundDecimal(TD / 1000, 2))?.replace('.00', '') || '0';
  const capacity: number = isFinite(DOC / DVC) ? Math.round((DOC / DVC) * 100) : 0;
  const work_time: number = TOTALTIME;

  return { hour, min, distance, cargos, capacity, number_of_orders, work_time };
};

const TargetDetailTableModal = ({
  target,
  setTarget,
  targetDetailTableModalIsOpen,
  setTargetDetailTableModalIsOpen,
}: {
  target: {
    driverData: IEqualOptimizeResponsesDriver;
    timelineData: {
      id: string;
      vehicle: unknown | null;
      driverId: number | null;
      items: ITimelineBlock[];
      properties: {
        editedItems: string[];
        sortChanged: boolean;
        hiddenEdited: boolean;
      };
    };
  };
  setTarget: Dispatch<
    SetStateAction<{
      driverData: IEqualOptimizeResponsesDriver;
      timelineData: {
        id: string;
        vehicle: unknown | null;
        driverId: number | null;
        items: ITimelineBlock[];
        properties: {
          editedItems: string[];
          sortChanged: boolean;
          hiddenEdited: boolean;
        };
      };
    } | null>
  >;
  targetDetailTableModalIsOpen: boolean;
  setTargetDetailTableModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const unbatched = target.timelineData.id === 'driver-unbatched';
  const { hour, min, distance, capacity, number_of_orders, work_time } = DVCalculator(target.driverData);

  return (
    <Modal
      isModalOpen={targetDetailTableModalIsOpen}
      setIsModalOpen={setTargetDetailTableModalIsOpen}
      closeCallbackFunc={() => {
        setTarget(null);
      }}
      ms={18}
      sx={{ width: '80vw', maxWidth: '1276px', padding: '18px 20px 48px 20px' }}
    >
      <Stack spacing={20}>
        {(target.timelineData?.properties?.editedItems?.length ?? 0) > 0 && (
          <Stack spacing={30} align="start">
            <Text styleName="title1">수정중인 주문</Text>
            <RoutePlanConfirmTable
              data={
                target.timelineData.items
                  .filter(d => target.timelineData?.properties?.editedItems.includes(d.id) && d.originData)
                  .map(d => d.originData) as any
              }
              modifying
            />
          </Stack>
        )}
        <Stack spacing={40} sx={{ maxHeight: 'calc(100vh - 270px)' }}>
          {unbatched ? (
            <Stack name="header" align="start">
              <Text styleName="title1">미배차 주문</Text>

              <Text styleName="subheadline2">주문 {number_of_orders} 개</Text>
            </Stack>
          ) : (
            <Stack name="header" align="start">
              <Text styleName="title1">{target.driverData.name}</Text>
              <Stack
                spacing={8}
                direction="row"
                divider={<Divider color="RG06" vertical style={{ height: 12 }} />}
                sx={{ width: 'max-content' }}
              >
                <Text styleName="subheadline2">주문 {number_of_orders} 개</Text>
                <Text styleName="subheadline2">
                  {dayjs(
                    new Date(
                      0,
                      0,
                      0,
                      Number(target.driverData.workStartTime.split(':')[0]),
                      Number(target.driverData.workStartTime.split(':')[1]),
                      0
                    )
                  ).format('HH:mm')}
                  &nbsp;~&nbsp;
                  {dayjs(
                    new Date(
                      0,
                      0,
                      0,
                      Number(target.driverData.workStartTime.split(':')[0]),
                      Number(target.driverData.workStartTime.split(':')[1]),
                      0
                    )
                  )
                    .add(work_time, 's')
                    .format('HH:mm')}
                </Text>
                <Text styleName="subheadline2">
                  {hour} 시간 {min} 분
                </Text>
                {target.driverData.orderList.find(d => d.type === 'break') && (
                  <Stack name="hasLunch" direction="row" spacing={8} sx={{ width: 'max-content' }}>
                    <LunchTimeIcon width={14} height={14} />
                    <Text styleName="subheadline2">
                      {(() => {
                        const breakOrder = target.driverData.orderList.find(d => d.type === 'break');
                        const requiredTime = breakOrder ? breakOrder.route.internal.requiredTime ?? 0 : 0;
                        const hours = Math.floor(requiredTime / 3600);
                        const minutes = Math.floor((requiredTime % 3600) / 60);

                        return hours > 0 ? `${hours} 시간${minutes > 0 ? ` ${minutes} 분` : ''}` : `${minutes} 분`;
                      })()}
                    </Text>
                  </Stack>
                )}
                <Text styleName="subheadline2">{distance} km</Text>
                <CapacityTag capacity={capacity}>
                  <Text styleName="tooltip1" color="RG00">
                    <Highlight styleName="tooltip3">{capacity}</Highlight> %
                  </Text>
                </CapacityTag>
              </Stack>
            </Stack>
          )}
          <RoutePlanConfirmTable
            data={
              target.timelineData.items
                .filter(
                  d =>
                    (!target.timelineData?.properties?.editedItems.includes(d.id) &&
                      d.originData &&
                      d.type === 'order') ||
                    d.type === 'jumsim' ||
                    (d.type === 'end' && d.originData?.address !== null) ||
                    (d.type === 'mibaecha' && unbatched)
                )
                .map(d => d.originData) as any
            }
            disableOptions={{
              totalCount: true,
              selectCount: true,
              pagnation: false,
              pageSizeSelector: false,
            }}
            iconGuide
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

function DriverSummaryInformationComponent({
  selectedDriver,
  driverData,
  timelineData,
  onMouseUp,
  setSelectedDriver,
}: {
  selectedDriver: number;
  driverData: IEqualOptimizeResponsesDriver[];
  timelineData: ITimelineData;
  onMouseUp: (driverId: number) => void;
  setSelectedDriver: (driverId: number) => void;
}) {
  const [visibleId, setVisibleId] = useState<number>(-1);
  const [target, setTarget] = useState<{
    driverData: IEqualOptimizeResponsesDriver;
    timelineData: {
      id: string;
      vehicle: unknown | null;
      driverId: number | null;
      items: ITimelineBlock[];
      properties: {
        editedItems: string[];
        sortChanged: boolean;
        hiddenEdited: boolean;
      };
    };
  } | null>(null);
  const [targetDetailTableModalIsOpen, setTargetDetailTableModalIsOpen] = useState<boolean>(false);

  return (
    <DriverSummaryAreaWrapper>
      <DriverSummaryAreaColumns>
        {driverData
          // usual drivers feild
          .filter(d => d.driverId && d.name)
          .map((driver, i) => {
            const { hour, min, distance, capacity, number_of_orders } = DVCalculator(driver);
            const edited: boolean = timelineData.columns[`driver-${driver?.driverId}`]?.properties?.hiddenEdited;
            const unbatched: boolean = !(number_of_orders > 0);
            return (
              <Tippy
                content={
                  <ShowTableView
                    setVisibleId={setVisibleId}
                    fnShowTableView={() => {
                      if (driver.driverId) {
                        if (selectedDriver === driver.driverId) setSelectedDriver(DriverIdUnSelected);
                        else setSelectedDriver(driver.driverId);
                      }

                      setTarget({
                        driverData: driver,
                        timelineData: timelineData.columns[`driver-${driver.driverId}`],
                      });

                      setTargetDetailTableModalIsOpen(true);
                    }}
                  />
                }
                visible={visibleId === driver.driverId}
                placement={'bottom'}
                duration={0}
              >
                <SummaryBoxContainer
                  onMouseUp={() => {
                    onMouseUp(driver.driverId ?? -1);
                  }}
                  onContextMenu={e => {
                    e.preventDefault();
                    setVisibleId(driver.driverId ?? -1);
                  }}
                  key={`driver$${i}`}
                  bgColor={selectedDriver === driver.driverId ? 'RC03_1' : unbatched ? 'RG08' : 'RG00'}
                  onClick={() => {
                    if (driver.driverId) {
                      if (selectedDriver === driver.driverId) setSelectedDriver(DriverIdUnSelected);
                      else setSelectedDriver(driver.driverId);
                    }
                  }}
                >
                  <Box key={driver?.driverId}>
                    <SummaryBoxInnerWrapper direction="row" spacing={8} align="center">
                      <Stack
                        name="driver-info-spec"
                        direction="row"
                        spacing={6}
                        className="first-child"
                        sx={{ width: 116, flexShrink: 0 }}
                      >
                        <Circle
                          style={{
                            backgroundColor: ColorPalette.getColor(driver?.driverId ?? 0),
                            width: '14px',
                            height: ' 14px',
                            borderRadius: '8px',
                          }}
                        />
                        {driver.vehicle?.operationType === 'regular' ? (
                          <OperationType>고정차</OperationType>
                        ) : (
                          <OperationType bgColor="RC10">용차</OperationType>
                        )}

                        <Text styleName={'caption1'}>
                          {driver!.name!.length > 4 ? (
                            <div style={{ position: 'relative' }}>
                              <AltWrapper
                                anchorDeactive
                                anchorWeight={{
                                  top: -6,
                                  left: 45,
                                }}
                                altInfo={driver!.name!}
                                children={createElement(
                                  'p',
                                  { key: driver.driverId },
                                  driver!.name!.slice(0, 3) + '...'
                                )}
                              />
                            </div>
                          ) : (
                            driver!.name
                          )}
                        </Text>
                      </Stack>

                      <Stack
                        direction="row"
                        spacing={6}
                        divider={
                          <Divider
                            color="RG06"
                            vertical
                            style={{
                              flexShrink: 0,
                              height: '12px',
                            }}
                          />
                        }
                      >
                        <SummaryBoxTextWrapper width={59} disabled={edited} direction="row" justify="space-between">
                          <Stack direction="row" justify="space-between">
                            <Text styleName={'caption2'}>주문</Text>
                            <Text styleName={'caption1'}>{number_of_orders}</Text>
                          </Stack>

                          <Text styleName={'caption2'}>&nbsp;건</Text>
                        </SummaryBoxTextWrapper>

                        <SummaryBoxTextWrapper width={68} disabled={edited} direction="row">
                          <Text styleName="caption2" align="end" sx={{ flex: '5 0 0%' }}>
                            <Highlight styleName="caption1">{hour}</Highlight>&nbsp;시간
                          </Text>
                          <Text styleName="caption2" align="end" sx={{ flex: '4 0 0%' }}>
                            <Highlight styleName="caption1">{min}</Highlight>&nbsp;분
                          </Text>
                        </SummaryBoxTextWrapper>

                        <SummaryBoxTextWrapper width={52} disabled={edited}>
                          <Text styleName={'caption2'} align="end" sx={{ display: 'flex' }}>
                            <Highlight styleName="caption1" sx={{ textWrap: 'nowrap' }}>
                              {distance}
                            </Highlight>
                            <span style={{ textWrap: 'nowrap' }}> km</span>
                          </Text>
                        </SummaryBoxTextWrapper>

                        <Stack direction="row" spacing={6} className="last-child">
                          <CapacityTag capacity={capacity} disabled={edited}>
                            <Text styleName="tooltip1" color="RG00">
                              <Highlight styleName="tooltip3">{capacity}</Highlight> %
                            </Text>
                          </CapacityTag>
                        </Stack>
                      </Stack>
                    </SummaryBoxInnerWrapper>
                  </Box>
                </SummaryBoxContainer>
              </Tippy>
            );
          })}
      </DriverSummaryAreaColumns>

      {driverData
        .filter(d => d.driverId === null && d.name === null)
        .map((driver, i) => {
          // only for unassigned orders feild

          const edited: boolean = timelineData.columns[`driver-unbatched`]?.properties?.hiddenEdited;

          return (
            <Tippy
              content={
                <ShowTableView
                  setVisibleId={setVisibleId}
                  fnShowTableView={() => {
                    setTarget({
                      driverData: driver,
                      timelineData: timelineData.columns[`driver-unbatched`],
                    });
                    setTargetDetailTableModalIsOpen(true);
                  }}
                />
              }
              visible={visibleId === -2}
              placement={'bottom'}
              duration={0}
            >
              <SummaryBoxContainer
                bgColor="RG08"
                key={`unbatched$${i}`}
                onMouseUp={() => {
                  onMouseUp(-10);
                }}
                onContextMenu={e => {
                  e.preventDefault();
                  setVisibleId(-2);
                }}
                onClick={() => {
                  setSelectedDriver(DriverIdUnSelected);
                }}
                isUnbatched={true}
                style={{
                  position: 'sticky',
                  bottom: 0,
                  borderRight: '1px solid #d8ddea',
                  // borderTop: '1px solid #d8ddea',
                }}
              >
                <Box key={'unallocated-order'}>
                  <SummaryBoxInnerWrapper direction="row" spacing={10} align="center">
                    <Stack direction="row" spacing={6} className="first-child">
                      <IcInfo fill={theme.colors.RG05} width={16} height={16} />
                      <Text styleName={'caption1'}>미배차</Text>
                    </Stack>

                    <SummaryBoxTextWrapper width={59} disabled={edited} direction="row" justify="space-between">
                      <Text styleName={'caption2'}>
                        주문{' '}
                        <Highlight styleName={'caption1'}>{driver.orderList.filter(x => x.address).length}</Highlight>건
                      </Text>
                    </SummaryBoxTextWrapper>
                  </SummaryBoxInnerWrapper>
                </Box>
              </SummaryBoxContainer>
            </Tippy>
          );
        })}
      {target && (
        <TargetDetailTableModal
          {...{ target, setTarget, targetDetailTableModalIsOpen, setTargetDetailTableModalIsOpen }}
        />
      )}
    </DriverSummaryAreaWrapper>
  );
}

export default DriverSummaryInformationComponent;

const SummaryBoxInnerWrapper = styled(Stack)`
  height: 100%;
`;

const SummaryBoxTextWrapper = styled(Stack)<{ width: number; disabled?: boolean }>`
  flex-shrink: 0;

  ${({ width }) => ({
    width: `${width}px`,
  })}

  ${({ theme, disabled }) =>
    disabled
      ? {
          '& > div > p, & > p': {
            color: theme.colors.RG04,
          },
        }
      : {}}
`;

const SummaryBoxContainer = styled(Stack)<{ bgColor?: TThemeColor; isUnbatched?: boolean }>`
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 54px;

  cursor: pointer;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  & > * .first-child {
    padding-left: 10px;
    width: max-content;
  }

  & > * .last-child {
    padding-right: 10px;
  }

  ${({ theme, bgColor, isUnbatched }) => ({
    borderBottom: `1px solid ${theme.colors.RG06}`,
    backgroundColor: bgColor ? theme.colors[bgColor] : 'transparent',

    ':hover': {
      backgroundColor: isUnbatched ? 'rgb(235, 240, 251)' : theme.colors.RC03_1,
    },
  })}
`;

const Box = styled.div`
  flex: 0 0 auto;

  width: 100%;
  height: 100%;

  white-space: nowrap;
  box-sizing: border-box;
`;

export const CapacityTag = styled.div<{ capacity: number; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 16px;
  border-radius: 4px;

  ${({ theme, disabled, capacity }) => ({
    boxShadow: `0 0 0 1px ${!disabled && capacity === 0 ? theme.colors['RC02'] : 'transparent'} inset`,
    backgroundColor: theme.colors[disabled ? 'RG04' : capacity > 100 ? 'RC04' : capacity > 0 ? 'RC03' : 'RG00'],
    '&>p': {
      color: theme.colors[!disabled && capacity === 0 ? 'RC02' : 'RG00'],
    },
  })}
  ${({ theme }) => theme.fontStyle.tooltip1}
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 8px;
`;

const DriverSummaryAreaWrapper = styled.div`
  position: relative;
  width: 414px;
`;

const DriverSummaryAreaColumns = styled.div`
  /* max-width: max-content; */
  width: 414px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  ${({ theme }) => ({
    borderRight: `1px solid ${theme.colors.RG06}`,
  })}
`;
