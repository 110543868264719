import styled from 'styled-components';

export const DropdownContainer = styled.div`
  z-index: 3;
  overflow: auto;
  width: 100%;
  max-height: 252px;
  position: absolute;
  top: 10px;
  left: 0;
  padding: 16px 0;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 8px;
  box-shadow: 0 6px 12px 0 #101e731a;
`;

export const ItemContainer = styled.div`
  width: 100%;
  padding: 8px 10px;

  display: flex;
  flex-direction: column;
  align-items: baseline;

  ${({ theme }) => theme.fontStyle.subheadline2};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.RC03_1};

    ${({ theme }) => theme.fontStyle.subheadline1};
  }
`;
