import { Map, Source, Layer, MapRef, MapGeoJSONFeature } from 'react-map-gl';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import MapImage, {
  clusterLayerProps,
  directionProps,
  polylinesLayerProps,
  unclusteredPointLayerProps,
  unclusteredPointWzLayerProps,
} from './RouteControlLayers';
import { generateMarker2, generatePolyline } from './RouteControlWidgets+Layer';
import {
  DriverIdUnSelected,
  IControlRouteDriverOrderList,
  IGetControlRouteResponses,
  ILocationMqOnStore,
} from 'constants/types';
import clonedeep from 'lodash.clonedeep';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import polyline from 'google-polyline';
import { MapConfig } from './util/config';
import {
  FocusedMarkerInfoWindow,
  FocusedPolylineInfoWindow,
  ICoordIncludedOrder,
  MyLocationMarker,
  OrdersMarker,
  parseCoordIncludedOrder,
  StartEndLocationMarker,
} from './RouteControlWidgets+JSX';
import { ReactComponent as IcPlus } from 'constants/icon/ic_plus_square.svg';
import { ReactComponent as IcMinus } from 'constants/icon/ic_minus_square.svg';
import { ReactComponent as FullScreen } from 'constants/icon/ic_full_screen.svg';
import styled from 'styled-components';
import useInterval from 'hooks/useInterval';

interface RouteControlMapProps {
  data?: IGetControlRouteResponses;
  locationMqResponse?: ILocationMqOnStore[];
  selectedDriver: number;
  isRefetching: boolean;
  showDetail: (orderId: number) => void;
  selectTable: (orderId: number) => void;
  selectedOrder?: IControlRouteDriverOrderList;
  selectDriver: (driverId: number) => void;
  wideMap: boolean;
  setWideMap: Dispatch<SetStateAction<boolean>>;
}

export const RouteControlMapV2 = ({
  data,
  locationMqResponse,
  selectedDriver,
  isRefetching,
  showDetail,
  selectTable,
  selectedOrder,
  selectDriver,
  wideMap,
  setWideMap,
}: RouteControlMapProps) => {
  // Global Polylines
  const [polylinesData, setPolylinesData] = useState<any>({ id: 'polyline', type: 'FeatureCollection', features: [] });
  // Selected Polylines
  const [selectedPolylinesData, setSelectedPolylinesData] = useState<any>({
    id: 'polyline',
    type: 'FeatureCollection',
    features: [],
  });
  // Clustered Markers
  const [markersData, setMarkersData] = useState<any[]>([]);

  const [cursor, setCursor] = useState<string>('default');
  const [showTag, setShowTag] = useState<boolean>(false);
  const [reloadDriver, setReloadDriver] = useState<boolean>(false);

  const [focusedMarker, setFocusedMarker] = useState<ICoordIncludedOrder | null>(null);
  const [focusedPolyline, setFocusedPolyline] = useState<any[] | null>(null);
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (!selectedOrder) return setFocusedMarker(null);
    if (selectedOrder && selectedOrder.coordinate && data) {
      let coordIncludedOrders = parseCoordIncludedOrder({ data, selectedDriver });
      let focusTarget = coordIncludedOrders.filter(
        cOrder => cOrder.overlapped.filter(item => item.orderId === selectedOrder.orderId).length > 0
      );
      if (focusTarget.length > 0) setFocusedMarker(focusTarget[0]);
      mapMoveTo(selectedOrder.coordinate?.coordinates);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (data) {
      if (selectedDriver !== DriverIdUnSelected) {
        let filteredData = clonedeep(data);
        filteredData.driverList = filteredData.driverList.filter(x => x.driverId === selectedDriver);
        generatePolyline({
          data: filteredData,
          setter: setSelectedPolylinesData,
          selectedOrder: focusedMarker?.orderInfo,
        });
      } else {
        generatePolyline({ data, setter: setPolylinesData });
      }
    } else {
      setPolylinesData(null);
    }
  }, [focusedMarker, data, isRefetching]);

  useInterval(() => {
    setReloadDriver(true);
    setReloadDriver(false);
  }, 7000);

  useEffect(() => {
    if (data) generateMarker2({ data, setter: setMarkersData });
    else setMarkersData([]);

    if (focusedMarker && selectedOrder) {
      let coordIncludedOrders = parseCoordIncludedOrder({ data, selectedDriver });
      let focusTarget = coordIncludedOrders.filter(
        cOrder => cOrder.overlapped.filter(item => item.orderId === selectedOrder.orderId).length > 0
      );
      if (focusTarget.length > 0) setFocusedMarker(focusTarget[0]);
    }
  }, [data, isRefetching]);

  useEffect(() => {
    resetFocus();

    if (data && selectedDriver !== DriverIdUnSelected) {
      let filteredData = clonedeep(data);
      filteredData.driverList = filteredData.driverList.filter(x => x.driverId === selectedDriver);
      generatePolyline({ data: filteredData, setter: setSelectedPolylinesData });
    }

    if (data && !isRefetching) {
      if (selectedDriver === DriverIdUnSelected) {
        updateBoundsToFit();
      } else {
        updateBounds();
      }
    }
  }, [selectedDriver]);

  return (
    <MapBoxContainer>
      <Map
        onZoom={e => {
          if (!showTag && e.target.getZoom() > 9) setShowTag(true);
          if (showTag && e.target.getZoom() < 9) setShowTag(false);
        }}
        ref={mapRef}
        localFontFamily={MapConfig.MAP_FONT_FAMILY}
        localIdeographFontFamily={MapConfig.MAP_FONT_FAMILY}
        mapboxAccessToken={MapConfig.MAP_ACCESS_TOKEN}
        mapStyle={MapConfig.MAP_STYLE}
        doubleClickZoom={false}
        cursor={cursor}
        onMouseEnter={() => {
          setCursor('pointer');
        }}
        onMouseLeave={() => {
          setCursor('default');
        }}
        onClick={e => {
          resetFocus();
          if (selectedDriver === DriverIdUnSelected) {
            let selectedItems: MapGeoJSONFeature[] = [];
            e.features?.forEach(feat => {
              if (feat.layer.id === 'lineLayer' && feat.properties && feat.properties.width === 8) {
                let combinedFeat: any = feat;
                combinedFeat.coord = e.lngLat;

                let isDuplicated = false;
                selectedItems.forEach(s => {
                  if (s.properties?.driverId === feat.properties?.driverId) isDuplicated = true;
                });
                if (!isDuplicated) selectedItems.push(feat);
              }
            });

            if (selectedItems.length === 1) {
              selectedItems[0].properties && selectDriver(selectedItems[0].properties.driverId);
            } else {
              setFocusedPolyline(selectedItems);
            }
          } else {
            e.features?.forEach(feat => {
              if (feat.layer.id === 'lineLayerSelected' && feat.properties) {
                selectDriver(DriverIdUnSelected);
              }
            });
          }
        }}
        interactiveLayerIds={['lineLayer', 'lineLayerSelected']}
        maxBounds={
          selectedDriver === DriverIdUnSelected
            ? (MapConfig.MAP_BOUNDS_ZOOMED as LngLatBoundsLike)
            : (MapConfig.MAP_BOUNDS as LngLatBoundsLike)
        }
      >
        {StartEndLocationMarker({ data, selectedDriver })}
        {OrdersMarker({ data, selectedDriver, focusedMarker, selectTable, setFocusedMarker, mapMoveTo })}

        {FocusedMarkerInfoWindow({ order: focusedMarker, showDetail, selectTable })}
        {FocusedPolylineInfoWindow({ feature: focusedPolyline, selectDriver })}

        {!reloadDriver &&
          MyLocationMarker({
            selectedDriver,
            selectDriver,
            locationMqResponse: locationMqResponse || [],
            showTag,
            data,
          })}
        {selectedPolylinesData && (
          <Source id="polyline-selected-src" type="geojson" data={selectedPolylinesData}>
            <Layer
              id={'lineLayerSelected'}
              {...polylinesLayerProps}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
                visibility: selectedDriver !== DriverIdUnSelected ? 'visible' : 'none',
              }}
            />
          </Source>
        )}

        {polylinesData && (
          <Source id="polyline-src" type="geojson" data={polylinesData}>
            <Layer
              id={'lineLayer'}
              {...polylinesLayerProps}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
                visibility: selectedDriver === DriverIdUnSelected ? 'visible' : 'none',
              }}
            />

            <Layer id={'pthLayer'} {...directionProps} />
          </Source>
        )}
        {markersData.map(data => {
          return (
            <Source
              key={`marker-src-${data.id}`}
              id={`marker-src-${data.id}`}
              type="geojson"
              cluster={true}
              data={data}
              clusterMaxZoom={14}
              clusterRadius={15}
              clusterProperties={{
                color: ['string', ['get', 'color']],
              }}
            >
              <Layer
                id={`marker-cluster-${data.id}`}
                layout={{
                  visibility: selectedDriver === DriverIdUnSelected ? 'visible' : 'none',
                }}
                {...clusterLayerProps}
              />
              <Layer
                id={`marker-unclustered-${data.id}`}
                layout={{
                  visibility: selectedDriver === DriverIdUnSelected ? 'visible' : 'none',
                }}
                {...unclusteredPointLayerProps}
              />
              <Layer
                id={`marker-unclustered-wz-${data.id}`}
                layout={{
                  visibility: selectedDriver === DriverIdUnSelected ? 'visible' : 'none',
                }}
                {...unclusteredPointWzLayerProps}
              />
            </Source>
          );
        })}
        <MapImage />
      </Map>

      <UtilBtn bottom={85} br={'2px'}>
        <div
          onClick={() => {
            mapRef?.current?.getMap().getContainer().requestFullscreen();
            setTimeout(() => {
              mapRef?.current?.getMap().resize();
            }, 500);
          }}
        >
          <FullScreen />
        </div>
      </UtilBtn>

      <UtilBtn bottom={49} br={'2px 2px 0 0'}>
        <div
          onClick={() => {
            mapRef?.current?.getMap().zoomIn();
          }}
        >
          <IcPlus />
        </div>
      </UtilBtn>
      <UtilBtn bottom={24} br={'0 0 2px 2px'}>
        <div
          onClick={() => {
            mapRef?.current?.getMap().zoomOut();
          }}
        >
          <IcMinus />
        </div>
      </UtilBtn>
    </MapBoxContainer>
  );

  function updateBounds() {
    let pos: [number, number] | null = null;

    locationMqResponse
      ?.filter(x => parseInt(x.driverId) === selectedDriver)
      .forEach(x => {
        pos = x.locationInfo.location.coordinates;
      });

    if (!pos)
      data?.driverList
        .filter(x => x.driverId === selectedDriver)
        .forEach(x => {
          if (x.vehicle && x.vehicle.startCoordinate) {
            pos = x.vehicle.startCoordinate.coordinates;
          }
        });
    if (pos) mapMoveTo(pos);
  }

  function resetFocus() {
    setFocusedPolyline(null);
    setFocusedMarker(null);
    selectTable(0);
  }

  function getSidebarWidth() {
    let minWidth = 700;
    let currentWidth = parseInt(`${window.innerWidth * 0.7}`) - 288;
    let width = 0;
    width = minWidth > currentWidth ? minWidth : currentWidth;
    return width;
  }

  function mapMoveTo(pos: [number, number]) {
    if (mapRef && mapRef.current) {
      try {
        mapRef.current.easeTo({
          center: pos,
          offset: [getSidebarWidth() / 2, 0],
          duration: 700,
        });
      } catch (e) {
        mapRef.current.easeTo({ center: pos, duration: 300 });
      }
    }
  }

  function updateBoundsToFit() {
    let bounds: any = null;

    data?.driverList.forEach(x => {
      if (selectedDriver === x.driverId || selectedDriver === DriverIdUnSelected) {
        if (x.vehicle && x.vehicle.startCoordinate) {
          let coord = x.vehicle.startCoordinate.coordinates;
          if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
          bounds.extend(coord);
        }
        if (x.vehicle && x.vehicle.endCoordinate) {
          let coord = x.vehicle.endCoordinate.coordinates;
          if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
          bounds.extend(coord);
        }

        x.orderList.forEach(o => {
          if (o.coordinate) {
            let coord = o.coordinate.coordinates;
            if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
            bounds.extend(coord);
          }

          if (o.route && o.route.polyline && bounds) {
            let decodedLine = polyline.decode(o.route.polyline);
            decodedLine.forEach(y => bounds.extend(y));
          }
        });
      }
    });
    if (mapRef && mapRef.current && bounds) {
      setTimeout(() => {
        try {
          mapRef.current?.fitBounds(bounds, {
            padding: {
              top: MapConfig.MAP_FIT_PADDING,
              left: MapConfig.MAP_FIT_PADDING + (selectedDriver === DriverIdUnSelected ? 0 : getSidebarWidth()),
              right: MapConfig.MAP_FIT_PADDING,
              bottom: MapConfig.MAP_FIT_PADDING,
            },
            duration: 700,
          });
        } catch (e) {
          mapRef.current?.fitBounds(bounds, { duration: 300 });
        }
      }, 40);
    }
  }
};

const MapBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & > #upside {
    position: absolute;
    right: 3px;
    top: 5px;
    cursor: pointer;
    z-index: 5;
  }
  & > #downside {
    position: absolute;
    right: 3px;
    top: 37px;
    cursor: pointer;
    z-index: 5;
  }
`;

const UtilBtn = styled.div<{ bottom?: number; br?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  z-index: 5;

  width: 24px;
  height: 24px;

  ${({ theme, bottom, br }) => ({
    borderRadius: br,
    bottom: `${bottom ?? 0}px`,
    backgroundColor: theme.colors.RG00,
    boxShadow: theme.shadows.strong,
  })}

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
