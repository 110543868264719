import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../../api/ky';
import { TUTORIAL_QUERIES } from './tutorial.queries';

type FetchCheckCanTutorialMonitoringResponse = {
  canMonitoring: boolean;
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetCheckCanTutorialMonitoring>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_MONITORING>;
};

const fetchGetCheckCanTutorialMonitoring = async ({
  queryKey: [{ endPoint }],
}: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchCheckCanTutorialMonitoringResponse>();

const useGetCheckCanTutorialMonitoring = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_MONITORING(),
    fetchGetCheckCanTutorialMonitoring,
    options
  );

export { fetchGetCheckCanTutorialMonitoring, useGetCheckCanTutorialMonitoring };
export type { FetchCheckCanTutorialMonitoringResponse };
