export const MANAGER_AUTH_CODE_QUERIES = {
  SCOPE: [{ scope: 'MANAGER_AUTH_CODE' }] as const,
  SEND_MANAGER_AUTH_CODE: () => [
    {
      ...MANAGER_AUTH_CODE_QUERIES.SCOPE[0],
      entity: 'send_manager_auth_code',
      endPoint: `payment/send-manager-auth-code`,
    },
  ],
  VERIFY_MANAGER_AUTH_CODE: () => [
    {
      ...MANAGER_AUTH_CODE_QUERIES.SCOPE[0],
      entity: 'verify_manager_auth_code',
      endPoint: `payment/verify-manager-auth-code`,
    },
  ],
};
