import { LngLatBoundsLike, Map, MapRef, Marker } from 'react-map-gl';
import { IManualExcelRouteRequestOrder } from '../../constants/types';
import { MapConfig } from './util/config';
import styled from 'styled-components';
import { ReactComponent as FullScreen } from '../../constants/icon/map_btn_fullscreen.svg';
import { useEffect, useRef, useState } from 'react';
import { DynamicSvg } from 'components/DynamicSvg';
import { DynamicSvgPresets } from 'components/DynamicSvg/Presets';
import mapboxgl from 'mapbox-gl';

interface RoutePlanSetupMapProps {
  orderList?: IManualExcelRouteRequestOrder[];
  selectedMarkerId: string;
}

export function ManualRoutingMap({ orderList, selectedMarkerId }: RoutePlanSetupMapProps) {
  const [cursor, setCursor] = useState<string>('default');
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    let bounds: any = null,
      isBoundChanged = false;

    orderList
      ?.filter(x => selectedMarkerId === '' || x.mId === selectedMarkerId)
      .forEach(x => {
        if (x.coordinate) {
          let coord = x.coordinate.coordinates;
          if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
          bounds.extend(coord);
          isBoundChanged = true;
        }
      });

    if (mapRef && mapRef.current && bounds && isBoundChanged) mapRef.current?.fitBounds(bounds, { duration: 300 });
  }, [orderList, selectedMarkerId]);

  return (
    <MapBoxContainer>
      <Map
        ref={mapRef}
        localFontFamily={MapConfig.MAP_FONT_FAMILY}
        localIdeographFontFamily={MapConfig.MAP_FONT_FAMILY}
        mapboxAccessToken={MapConfig.MAP_ACCESS_TOKEN}
        mapStyle={MapConfig.MAP_STYLE}
        doubleClickZoom={false}
        cursor={cursor}
        onMouseEnter={() => {
          setCursor('pointer');
        }}
        onMouseLeave={() => {
          setCursor('default');
        }}
        maxBounds={MapConfig.MAP_BOUNDS_ZOOMED as LngLatBoundsLike}
      >
        {orderList &&
          orderList
            .filter(x => x.coordinate && x.coordinate.coordinates && x.coordinate.coordinates.length > 1)
            .map(x => {
              return (
                <Marker
                  longitude={x.coordinate.coordinates[0]}
                  latitude={x.coordinate.coordinates[1]}
                  style={{ zIndex: selectedMarkerId === x.mId ? 5 : 1 }}
                >
                  <MarkerContainer isLarge={selectedMarkerId === x.mId}>
                    <DynamicSvg
                      info={{
                        ...(selectedMarkerId === x.mId ? DynamicSvgPresets.waitingSelected : DynamicSvgPresets.waiting),
                        chip: {
                          stacked: false,
                          completed: false,
                          skill: !!x.skill,
                          time: !!(x.desiredTimeStart || x.desiredTimeEnd),
                          pickup: x.shipmentType === 'pickup',
                          delivery: false,
                        },
                      }}
                    />
                    <MarkerClickListener
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />
                  </MarkerContainer>
                </Marker>
              );
            })}
      </Map>

      <div
        id={'upside'}
        onClick={() => {
          mapRef?.current?.getMap().getContainer().requestFullscreen();
        }}
      >
        <FullScreen />
      </div>
    </MapBoxContainer>
  );
}

const MapBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & > #upside {
    position: absolute;
    right: 3px;
    top: 5px;
    cursor: pointer;
    z-index: 5;
  }
`;

const MarkerContainer = styled.div<{ isLarge: boolean }>`
  width: 0;
  height: 0;
  position: relative;
  pointer-events: none;
  & > svg {
    position: absolute;
    bottom: 0;
    left: ${({ isLarge }) => (isLarge ? '-22px' : '-16.5px')};
  }
`;

const MarkerClickListener = styled.div`
  position: absolute;
  bottom: 0;
  left: -14px;
  width: 28px;
  height: 38px;
  cursor: pointer;
  z-index: 3;
  pointer-events: auto;
`;
