import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './constants/font/font.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'ag-grid-community/styles/ag-grid.css';
import './constants/ag-theme-roouty.css';
import * as Sentry from '@sentry/react';

import { init as initApm } from '@elastic/apm-rum';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

// if the production -> remove console
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.log = () => {};
}

Sentry.init({
  dsn: process.env['REACT_APP_SENTRY_DSN'],
  environment: process.env['REACT_APP_SENTRY_ENV'],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env['REACT_APP_SENTRY_ENV'] === 'production' ? 1 : 0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: window.location.host === 'tms.roouty.io' ? 1.0 : 0,
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

const apiServerUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'roouty-admin-react',

  environment: process.env.REACT_APP_APM_STAGE,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: process.env.REACT_APP_APM_SERVER_URL,

  // Set service version (required for sourcemap feature)
  // serviceVersion: '0.1.0',
  serviceVersion: process.env.REACT_APP_GIT_VERSION,

  // https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/distributed-tracing-guide.html
  distributedTracingOrigins: [apiServerUrl],
});

console.log(`Service Version : ${process.env.REACT_APP_GIT_VERSION}`);

reportWebVitals();

// @ts-ignore
window['__react-beautiful-dnd-disable-dev-warnings'] = true;
