/* eslint-disable @typescript-eslint/no-unused-expressions */
const ENG = 'rRseEfaqQtTdwWczxvgASDFGZXCVkoiOjpuPhynbmlYUIHJKLBNM'; // 33 + 19개
const KOR = 'ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎㅁㄴㅇㄹㅎㅋㅌㅊㅍㅏㅐㅑㅒㅓㅔㅕㅖㅗㅛㅜㅠㅡㅣㅛㅕㅑㅗㅓㅏㅣㅠㅜㅡ'; // 33 + 19개
const 초성 = 'ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ'; // 19개
const 중성 = 'ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ'; // 21개
const 종성 = 'ㄱㄲㄳㄴㄵㄶㄷㄹㄺㄻㄼㄽㄾㄿㅀㅁㅂㅄㅅㅆㅇㅈㅊㅋㅌㅍㅎ'; // 27개

const 가 = 44032;
const 힣 = 55203;
const ㄱ = 12593;
const ㅣ = 12643;

export function translateKoToEng(input: string) {
  let result = '';
  if (input === '' || input === undefined) return result;
  let seperate = [-1, -1, -1, -1, -1];

  for (let i = 0; i < input.length; i++) {
    let korean = input[i];
    let target = korean.charCodeAt(0);
    // 가 ~ 힣 사이에 있는 KOR이라면
    if ((target >= 가 && target <= 힣) || (target >= ㄱ && target <= ㅣ)) {
      seperate = splitKorea(korean);
    }
    // KOR이 아니라면
    else {
      result += korean;
      // 분리 배열 초기화
      seperate = [-1, -1, -1, -1, -1];
    }

    for (let j = 0; j < seperate.length; j++) {
      if (seperate[j] !== -1) result += ENG[seperate[j]];
    }
  }
  return result;
}

// KOR 입력값으로 받아서 초성, 중성, 종성 분리해줌
function splitKorea(korean: string) {
  let spellCode = korean.charCodeAt(0);

  if (spellCode >= 가 && spellCode <= 힣) {
    let 초 = Math.floor((spellCode - 가) / 588);
    let 중 = Math.floor((spellCode - 가 - 초 * 588) / 28);
    let 종 = spellCode - 가 - 초 * 588 - 중 * 28 - 1;
    let 중1 = 중,
      중2 = -1,
      종1 = 종,
      종2 = -1;

    if (중 == 중성.indexOf('ㅘ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅏ'));
    else if (중 == 중성.indexOf('ㅙ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅐ'));
    else if (중 == 중성.indexOf('ㅚ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅣ'));
    else if (중 == 중성.indexOf('ㅝ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅓ'));
    else if (중 == 중성.indexOf('ㅞ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅔ'));
    else if (중 == 중성.indexOf('ㅟ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅣ'));
    else if (중 == 중성.indexOf('ㅢ')) (중1 = KOR.indexOf('ㅡ')), (중2 = KOR.indexOf('ㅣ'));

    if (종 == 종성.indexOf('ㄳ')) (종1 = KOR.indexOf('ㄱ')), (종2 = KOR.indexOf('ㅅ'));
    else if (종 == 종성.indexOf('ㄵ')) (종1 = KOR.indexOf('ㄴ')), (종2 = KOR.indexOf('ㅈ'));
    else if (종 == 종성.indexOf('ㄶ')) (종1 = KOR.indexOf('ㄴ')), (종2 = KOR.indexOf('ㅎ'));
    else if (종 == 종성.indexOf('ㄺ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㄱ'));
    else if (종 == 종성.indexOf('ㄻ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅁ'));
    else if (종 == 종성.indexOf('ㄼ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅂ'));
    else if (종 == 종성.indexOf('ㄽ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅅ'));
    else if (종 == 종성.indexOf('ㄾ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅌ'));
    else if (종 == 종성.indexOf('ㄿ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅍ'));
    else if (종 == 종성.indexOf('ㅀ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅎ'));
    else if (종 == 종성.indexOf('ㅄ')) (종1 = KOR.indexOf('ㅂ')), (종2 = KOR.indexOf('ㅅ'));

    // 복모음이 아니라면
    if (중2 === -1) 중1 = KOR.indexOf(중성[중]);

    // 복자음이 아니라면
    if (종2 === -1) 종1 = KOR.indexOf(종성[종]);

    return [초, 중1, 중2, 종1, 종2];
  } else if (spellCode >= ㄱ && spellCode <= ㅣ) {
    if (초성.indexOf(korean) > -1) {
      let 초 = KOR.indexOf(korean);
      return [초, -1, -1, -1, -1];
    } else if (중성.indexOf(korean) > -1) {
      let 중 = 중성.indexOf(korean);
      let 중1 = 중,
        중2 = -1;
      if (중 == 중성.indexOf('ㅘ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅏ'));
      else if (중 == 중성.indexOf('ㅙ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅐ'));
      else if (중 == 중성.indexOf('ㅚ')) (중1 = KOR.indexOf('ㅗ')), (중2 = KOR.indexOf('ㅣ'));
      else if (중 == 중성.indexOf('ㅝ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅓ'));
      else if (중 == 중성.indexOf('ㅞ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅔ'));
      else if (중 == 중성.indexOf('ㅟ')) (중1 = KOR.indexOf('ㅜ')), (중2 = KOR.indexOf('ㅣ'));
      else if (중 == 중성.indexOf('ㅢ')) (중1 = KOR.indexOf('ㅡ')), (중2 = KOR.indexOf('ㅣ'));

      // 복모음이 아니라면
      if (중2 === -1) 중1 = KOR.indexOf(중성[중]);

      return [-1, 중1, 중2, -1, -1];
    } else if (종성.indexOf(korean) > -1) {
      let 종 = 종성.indexOf(korean);
      let 종1 = 종,
        종2 = -1;
      if (종 == 종성.indexOf('ㄳ')) (종1 = KOR.indexOf('ㄱ')), (종2 = KOR.indexOf('ㅅ'));
      else if (종 == 종성.indexOf('ㄵ')) (종1 = KOR.indexOf('ㄴ')), (종2 = KOR.indexOf('ㅈ'));
      else if (종 == 종성.indexOf('ㄶ')) (종1 = KOR.indexOf('ㄴ')), (종2 = KOR.indexOf('ㅎ'));
      else if (종 == 종성.indexOf('ㄺ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㄱ'));
      else if (종 == 종성.indexOf('ㄻ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅁ'));
      else if (종 == 종성.indexOf('ㄼ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅂ'));
      else if (종 == 종성.indexOf('ㄽ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅅ'));
      else if (종 == 종성.indexOf('ㄾ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅌ'));
      else if (종 == 종성.indexOf('ㄿ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅍ'));
      else if (종 == 종성.indexOf('ㅀ')) (종1 = KOR.indexOf('ㄹ')), (종2 = KOR.indexOf('ㅎ'));
      else if (종 == 종성.indexOf('ㅄ')) (종1 = KOR.indexOf('ㅂ')), (종2 = KOR.indexOf('ㅅ'));
      return [종1, 종2, -1, -1, -1];
    }
  }
  return [-1, -1, -1, -1, -1];
}
