import { Stack } from 'components/Stack';
import dayjs from 'dayjs';
import useInterval from 'hooks/useInterval';
import { useState, memo } from 'react';
import { useStore } from 'store';
import { Text } from 'components/Typography';

const GradeSelector = () => {
  const { userGrade, setUserGrade } = useStore();

  return (
    <Stack align="end" sx={{ position: 'fixed', right: 0, bottom: 0, width: 'fit-content', zIndex: 999 }}>
      {/* <TokenExp /> */}
      <select
        defaultValue={userGrade}
        onChange={e => {
          let res = e.target.value;

          setUserGrade(Number(res) as 1 | 2 | 3 | 4);
        }}
        style={{ width: '150px', height: '30px' }}
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
      </select>
    </Stack>
  );
};

const TokenExp = () => {
  const [ATE, setATE] = useState<number | null>(
    window.localStorage.getItem('ATE') ? dayjs(window.localStorage.getItem('ATE')).diff(Date.now(), 's', true) : null
  );
  const [RTE, setRTE] = useState<number | null>(
    window.localStorage.getItem('RTE') ? dayjs(window.localStorage.getItem('RTE')).diff(Date.now(), 's', true) : null
  );

  useInterval(() => {
    setATE(dayjs(window.localStorage.getItem('ATE')).diff(Date.now(), 's', true));
    setRTE(dayjs(window.localStorage.getItem('RTE')).diff(Date.now(), 's', true));
  }, 100);

  return (
    <div style={{ minWidth: 150 }}>
      at exp :{' '}
      {typeof ATE === 'number' ? (
        ATE < 0 ? (
          <Text styleName="caption1" color="RC04">
            {ATE} <br />
            TOKEN EXPIRED!!!!!!
          </Text>
        ) : (
          ATE
        )
      ) : (
        "can't find token exp"
      )}
      <br />
      rt exp :{' '}
      {typeof RTE === 'number' ? (
        RTE < 0 ? (
          <Text styleName="caption1" color="RC04">
            {RTE} <br />
            TOKEN EXPIRED!!!!!!
          </Text>
        ) : (
          RTE
        )
      ) : (
        "can't find token exp"
      )}
    </div>
  );
};

export default memo(GradeSelector);
