import styled from 'styled-components';
import { Stack, StackProps } from 'components/Stack';

export const Tab = styled.div<{ isActive: boolean; index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  padding: 6px 10px;
  margin-left: ${({ isActive, index }) => index > 0 && !isActive && '-1px'};
  box-sizing: border-box;
  // RT-762
  ${({ theme }) => theme.fontStyle.caption1}
  color: ${({ isActive, theme }) => (isActive ? theme.colors.RC02 : theme.colors.RG04)};
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.RG08 : theme.colors.RG00)};
  border-radius: 6px 6px 0px 0px;
  border: ${({ isActive, theme }) => `1px solid ${theme.colors.RG07}`};
  /* border: ${({ isActive, theme }) => `1px solid ${isActive ? theme.colors.RG08 : theme.colors.RG07}`}; */
  cursor: pointer;
`;

export const FilterBox = styled.form<StackProps>`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ direction, spacing, padding, align, justify }) => ({
    flexDirection: direction || 'column',
    gap: spacing || 0,
    padding: padding || 0,
    alignItems: align || 'center',
    justifyContent: justify || 'start',
  })}
  background: ${({ theme }) => theme.colors.RG08};
  border-radius: 0 8px 8px;
`;

export const SearchbarInputContainer = styled(Stack)<{ isFilled: boolean }>`
  padding: 6px 10px;
  & > input {
    width: 100%;
    background: transparent;
    ::placeholder {
      color: ${({ theme }) => theme.colors.RG04};
    }
  }
  /* & > svg {
      cursor: pointer;
    } */
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.RG04};
  }

  ${({ isFilled }) =>
    isFilled && {
      '& > svg': {
        width: 0,

        transition: 'all 0.4s',
      },
    }}
`;

export const FilterSearchbarWrapper = styled(Stack)<{ width?: string }>`
  ${({ theme }) => theme.fontStyle.caption2}
  width: 502px;
  height: 32px;
  .search-item-select-wrapper {
    width: 90px;
    margin: 0 0 0 16px;
    cursor: pointer;
  }
  .search-item-select {
    color: ${({ theme }) => theme.colors.RG04};
  }
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  background-color: ${({ theme }) => theme.colors.RG00};
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.RC03};
    .search-item-select {
      color: ${({ theme }) => theme.colors.RG02};
    }
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.colors.RC03};
    & > input {
      ::placeholder {
        color: ${({ theme }) => theme.colors.RG00};
      }
    }
  }
  transition: all 0.2s;
  & > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const TableHeader = styled.div`
  background: ${({ theme }) => theme.colors.RG08};
  width: fit-content;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  height: 32px;
  align-items: center;
  gap: 10px;
  padding: 6px;
`;
