import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnOrderState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type PaginationState,
  type RowSelectionState,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ModifySkillModal } from 'components/Modal/ModifySkillModal';
import { common_table_selection } from 'constants/commons';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { useDeleteMultiSkill } from 'hooks/query/masterData/useDeleteMultiSkill';
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import type { IMultiSkillData } from 'types/masterData/multiSkill';
import { Button } from '../../Button';
// import { useModifierMultiSkill } from '../../../hooks/query/masterData/useModifierMultiSkill';
import { AlertModal } from '../../Modal/AlertModal';
import { Stack } from '../../Stack';
import { Text } from '../../Typography';
import TableComponent from '../index';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from '../util/IndeterminateCheckbox';
import TableSelectManager from '../util/TableSelectManager';

const columnHelper = createColumnHelper<IMultiSkillData>();

interface MultiSkillTableProps {
  data?: IMultiSkillData[];
  selectedMultiSkill: IMultiSkillData[];
  setSelectedMultiSkill: Dispatch<SetStateAction<IMultiSkillData[]>>;
}

const MultiSkillTable = ({ data = [], selectedMultiSkill, setSelectedMultiSkill }: MultiSkillTableProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isSuccessDeleteMultiSkillModal, setIsSuccessDeleteMultiSkillModal] = useState<boolean>(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);
  const [detailTarget, setDetailTarget] = useState<IMultiSkillData>();

  const { mutate: mutateDeleteMultiSkill, isSuccess: isSuccessDeleteMultiSkill } = useDeleteMultiSkill();
  // const { mutate: mutateModifierMultiSkill, isSuccess: isSuccessModifierMultiSkill } =
  //   useModifierMultiSkill(positionPickerId);

  const queryClient = useQueryClient();

  useEffect(() => {
    console.log('detailModalIsOpen', detailModalIsOpen);
  }, [detailModalIsOpen]);

  useEffect(() => {
    if (isSuccessDeleteMultiSkill) {
      queryClient.invalidateQueries({ queryKey: [{ entity: 'get_multi_skill_list' }] }).then(() => {
        setIsOpenDeleteModal(false);
        setIsSuccessDeleteMultiSkillModal(true);
      });
    }
  }, [isSuccessDeleteMultiSkill, queryClient]);
  //
  // useEffect(() => {
  //   if (isSuccessModifierMultiSkill) {
  //     setIsSuccessModifierModal(true);
  //     queryClient.invalidateQueries({ queryKey: [{ entity: 'get_multi_skill_list' }] });
  //   }
  // }, [isSuccessModifierMultiSkill]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: '조건 이름',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.description, {
        id: 'description',
        header: '조건 설명',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.team.name, {
        id: 'teamName',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(
        row =>
          row.drivers.length
            ? row.drivers.length > 1
              ? `${row.drivers[0]?.name} 외 ${row.drivers.length - 1}명`
              : row.drivers[0]?.name
            : '',
        {
          id: 'driverName',
          header: '소속 드라이버',
          footer: info => info.column.id,
        }
      ),
    ],
    [anchorIsOpen]
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnOrder,
      pagination,
      rowSelection,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    sortDescFirst: true,
  });

  useEffect(() => {
    let t: any = {};
    selectedMultiSkill.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.skillId === x.skillId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [instance, selectedMultiSkill, setRowSelection]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedMultiSkill.filter(z => z.skillId === y.skillId).length === 0);
      setSelectedMultiSkill([...selectedMultiSkill, ...orders]);
    } else {
      let orders: Array<any> = selectedMultiSkill;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.skillId !== s.original?.skillId);
      });
      setSelectedMultiSkill(orders);
    }
  }

  return (
    <>
      <TableComponent<IMultiSkillData>
        placeholder={
          <Text styleName="title2" color="RG05" align="center">
            불러올 데이터가 없습니다.
            <br />
            조건을 추가해 주세요.
          </Text>
        }
        table={instance}
        isDataFetching={false}
        tableType="multiSkill"
        clearBottom
        onRowClick={(e, row) => {
          setDetailTarget(row.original);
          setDetailModalIsOpen(true);
        }}
        sc={[
          <Button
            children="조건 삭제"
            variant={'tertiary'}
            h={32}
            icon={[IcBin, { width: 16, height: 16 }]}
            disabled={!selectedMultiSkill.length}
            onClick={() => {
              setIsOpenDeleteModal(true);
            }}
          />,
        ]}
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}

      <AlertModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        type="DELETE"
        messages={
          <Stack spacing={8} direction="column">
            <Text styleName="body2" color="RC04">
              선택하신 조건 {selectedMultiSkill.length} 개를 삭제하시겠습니까?
            </Text>
            <Text styleName="body2" color="RG02">
              삭제 이후 복구는 불가하며 다시 추가할 수 있습니다.
            </Text>
          </Stack>
        }
        buttonGroup={
          <Stack direction="row" spacing={20} sx={{ marginTop: '40px' }}>
            <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={() => setIsOpenDeleteModal(false)}>
              취소
            </Button>
            <Button
              variant="primary"
              h={40}
              color="RC02"
              w="100%"
              onClick={() => {
                mutateDeleteMultiSkill({
                  skillIds: selectedMultiSkill.map(item => {
                    return item.skillId;
                  }),
                });
              }}
            >
              삭제
            </Button>
          </Stack>
        }
      />

      <AlertModal
        isOpen={isSuccessDeleteMultiSkillModal}
        setIsOpen={setIsSuccessDeleteMultiSkillModal}
        type="SUCCESS"
        messages="조건 삭제를 완료하였습니다."
        buttonName="확인"
        autoCloseTime={3000}
      />

      {detailModalIsOpen && detailTarget && (
        <ModifySkillModal isOpen={detailModalIsOpen} setIsOpen={setDetailModalIsOpen} data={detailTarget} />
      )}
    </>
  );
};

export { MultiSkillTable };
