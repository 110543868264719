import { useQuery } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/table-core';
import { getOrderList } from 'api';
import TableColumnOrderControlModal from 'components/Modal/TableColumnOrderControlModal';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';

import { Stack } from 'components/Stack';
import OrderManagementTable from 'components/Table/OrderManagementTable';
import { Text } from 'components/Typography';
import {
  order_management_content_by_status,
  order_management_table_column_content,
  order_management_table_column_content_default_settings,
} from 'constants/commons';
import { IDB_Names } from 'constants/IDB';
import theme from 'constants/theme';
import { IGetOrderListResponses, IOrder } from 'constants/types';
import { useIndexedDBStore } from 'hooks/useIndexedDB';
import { OrderManagePageFilter } from 'pages/OrderManagePage';
import { FormValues } from 'pages/RoutePlanSetupPage';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useStore } from 'store';
import { reorderBySelection } from 'util/reorderBySelection';

const OrderSelect = ({
  initOrders,
  initFilter,
  selectedOrder,
  setSelectedOrder,
  pagination,
  setPagination,
  setCurrentStepIndex,
}: {
  methods: UseFormReturn<FormValues>;
  initOrders: Array<IOrder>;
  initFilter: any;

  selectedOrder: Array<IOrder>;
  setSelectedOrder: Dispatch<SetStateAction<Array<IOrder>>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
}) => {
  const { getOrderListProps, setGetOrderListProps, resetGetOrderListProps } = useStore();

  const [initFilterContent, setInitFilterContent] = useState({ ...initFilter, ...getOrderListProps });
  const [rowSelection, setRowSelection] = useState({});

  const [TableColumnOrderControlModalIsOpen, setTableColumnOrderControlModalIsOpen] = useState<boolean>(false);
  const [tableColumnSettingOptions, setTableColumnSettingOptions] = useState<TDragablePickerOptions>(
    reorderBySelection(
      order_management_table_column_content.map(d =>
        order_management_table_column_content_default_settings.unassigned.includes(d.key as string)
          ? { ...d, isSelected: true }
          : { ...d }
      )
    )
  );

  const { data: getOrderListResponses, isFetching: getOrderListResponsesIsFetching } = useQuery<IGetOrderListResponses>(
    ['getOrderList', getOrderListProps],
    () => getOrderList({ ...getOrderListProps, orderStatus: 'unassigned' }),
    {}
  );

  const { update, getOneByKey } = useIndexedDBStore(IDB_Names.user.stores[0].name);

  const setColumnOrderInIDB = async (memory: TDragablePickerOptions) => {
    let IDB_CurrentUser = (await getOneByKey('userId', parseInt(window.localStorage.getItem('userId') ?? '-33'))) as {
      [key: string]: any;
    };

    if (!IDB_CurrentUser) {
      return;
    } else {
      IDB_CurrentUser.orderManageTableColumnsSetting = {
        ...IDB_CurrentUser.orderManageTableColumnsSetting,
        unassigned: memory,
      };

      update({ ...IDB_CurrentUser });
    }
  };

  const getIDBColumn = async () => {
    const targetUserId = window.localStorage.getItem('userId');
    if (!targetUserId) return;
    else {
      let res = await (getOneByKey('userId', parseInt(targetUserId)) as any);

      const updater = (res?.orderManageTableColumnsSetting['unassigned'] as TDragablePickerOptions)?.map((d, i) => {
        if (
          d.name !==
          order_management_content_by_status('auto').unassigned.tableSetting.columns.find(x => x.key === d.key)?.name
        ) {
          return {
            ...d,
            name: order_management_content_by_status('auto').unassigned.tableSetting.columns.find(x => x.key === d.key)
              ?.name,
          };
        } else {
          return d;
        }
      });

      console.log(updater, 'updater');

      if (updater) setTableColumnSettingOptions(updater as TDragablePickerOptions);
    }
  };

  useEffect(() => {
    getIDBColumn();
  }, []);

  useEffect(() => {
    setGetOrderListProps(getOrderListProps);

    if (initFilter) {
      if (initFilter?.orderStatus === 'unassigned') {
        setInitFilterContent(initFilter);
      } else {
        setInitFilterContent(null);
        resetGetOrderListProps();
      }
    }

    if (initOrders) {
      setSelectedOrder(initOrders);
      setCurrentStepIndex(0);
    }
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Stack spacing={8} align="start">
        <Text styleName="title1" color="RG02">
          주문 선택하기
        </Text>
        <Text styleName="caption2" color="RG03">
          배차할 주문을 체크하여 선택합니다.
        </Text>
      </Stack>

      {useMemo(() => {
        return (
          <OrderManagePageFilter
            type="auto"
            pageContent={order_management_content_by_status('auto').unassigned}
            pageStatus={'unassigned'}
            sx={{ margin: '40px 0 30px', borderRadius: 8, border: `1px solid ${theme.colors.RG07}` }}
            onSubmitCallback={v => {
              setSelectedOrder([]);

              if (v) setGetOrderListProps(v);
            }}
            {...{ setPagination, initFilter: initFilterContent }}
          />
        );
      }, [initFilterContent, setGetOrderListProps, setPagination, setSelectedOrder])}

      <OrderManagementTable
        setDeleteOrderModalOpen={function (value: SetStateAction<boolean>): void {
          throw new Error('Function not implemented.');
        }}
        setDelTarget={function (value: SetStateAction<number[]>): void {
          throw new Error('Function not implemented.');
        }}
        placeholder={
          <Text styleName="title2" color="RG04" align="center">
            현재 불러올 주문이 없습니다.
            <br />
            주문 관리로 이동하여 주문을 추가해주세요.
          </Text>
        }
        manual
        data={getOrderListResponses?.orderList ?? []}
        isFetching={getOrderListResponsesIsFetching}
        pageStatus={'unassigned'}
        autoResetSelection={false}
        {...{
          selectedOrder,
          setSelectedOrder,
          rowSelection,
          setRowSelection,
          tableColumnSettingOptions,
          pagination,
          setPagination,
          setTableColumnOrderControlModalIsOpen,
          canDetailModalModi: true,
        }}
      />

      <TableColumnOrderControlModal
        isOpen={TableColumnOrderControlModalIsOpen}
        setIsOpen={setTableColumnOrderControlModalIsOpen}
        options={tableColumnSettingOptions}
        setOptions={setTableColumnSettingOptions}
        initial={order_management_table_column_content.map(d =>
          order_management_table_column_content_default_settings['unassigned'].includes(d.key as string)
            ? { ...d, isSelected: true }
            : { ...d }
        )}
        setColumnOrderInIDB={setColumnOrderInIDB}
      />
    </div>
  );
};

export default OrderSelect;
