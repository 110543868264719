import { getDriverAppList, putSetDriverAppList } from 'pages/Setting/resource/DriverApp/api';
import type { Dispatch, SetStateAction } from 'react';

export const convertSearchField = (type: TDriverAppSearchItem) => {
  switch (type) {
    case 'name':
      return '팀 이름';
    case 'managerName':
      return '소속 매니저 이름';
    default:
      return '소속 드라이버 이름';
  }
};

export const _getDriverAppList = async (params: {
  payload?: Omit<TDriverAppParams, 'placeholder'>;
  _dispatch: Dispatch<SetStateAction<TDriverAppTable[] | [] | null>>;
}) => {
  const { payload, _dispatch } = params;
  const response = await getDriverAppList(payload);
  if (Array.isArray(response)) {
    return _dispatch(response);
  }
  return _dispatch([]);
};

export const switchOption = (params: {
  _dispatch: Dispatch<SetStateAction<TDriverSettingConfigQuery>>;
  _state: TDriverSettingConfigQuery;
  option?: 'geo' | 'pod' | 'step' | 'reset';
}) => {
  const { _dispatch, _state, option } = params;
  const state = Object.assign({}, _state);

  switch (option) {
    case 'geo':
      state.geocoding = !state.geocoding;
      break;
    case 'pod':
      state.podRequired = !state.podRequired;
      break;
    case 'step':
      break;

    case 'reset':
      state.geocoding = false;
      state.podRequired = false;
      state.stepFixed = true;
      state.clear = true;
      break;
    default:
  }
  return _dispatch(state);
};

export const _putSetDriverAppList = async (params: {
  payload: TDriverSettingConfigQuery;
  teamId: number;
  _dispatch: Dispatch<SetStateAction<TDriverAppModal>>;
}) => {
  const { _dispatch } = params;

  const response = await putSetDriverAppList({ ...params });
  const error = response as TErrorResponse;

  if (error?.number) {
    _dispatch({ type: 'Warning', isOpen: true });
    return;
  }

  _dispatch({ type: 'Success', isOpen: true });
  return;
};
