import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

import { NewTable, SearchFilterbar } from 'pages/Setting/common';
import { useStore } from 'store';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { AlertModal } from 'components/Modal/AlertModal';
import { _getDriverAppList, convertSearchField } from 'pages/Setting/resource/DriverApp/container';
import { DRIVER_FILTER_CATEGORY } from 'pages/Setting/resource/DriverApp/constants';
import Layout from 'pages/Setting/Layout';
import PageContentWrapper from 'components/PageContentWrapper';
import { DriverAppColumns } from 'pages/Setting/resource/DriverApp/DriverAppColumns';
import { DriverAppConfigModal } from 'pages/Setting/resource/DriverApp/DriverAppConfigModal';
import { useTracking } from '../../../../hooks/store/useTracking';

export const DriverApp = () => {
  const { isLoading } = useStore();
  const [tableConfig] = DriverAppColumns();
  const [_state, _dispatch] = useState<TDriverAppTable[] | [] | null>(null);
  const [modalType, setModalType] = useState<TDriverAppModal>({ type: '', isOpen: false });
  const [selectedItem, setSelectedItem] = useState<TDriverAppTable | null>(null);
  const { setTitle } = useTracking();

  useEffect(() => {
    _getDriverAppList({ _dispatch });
    return () => _dispatch(null);
  }, []);

  useEffect(() => {
    setTitle('설정>드라이버 앱 관리');
  }, [setTitle]);

  const isFetching = _state === null;

  const { getValues, watch, setValue, register } = useForm<TDriverAppParams>({
    defaultValues: { searchItem: 'name', keyword: '', placeholder: '소속 팀을 입력해주세요.' },
  });

  const drvierAppModals = useMemo(() => {
    const { isOpen } = modalType;
    switch (modalType.type) {
      case 'SetAppConfig':
        return <DriverAppConfigModal selectedItem={selectedItem} setModalType={setModalType} isOpen={isOpen} />;
      case 'Success':
        return (
          <AlertModal
            type="SUCCESS"
            isOpen={isOpen}
            messages={['드라이버 앱 설정 변경을 완료하였습니다.']}
            buttonName="확인"
            autoCloseTime={3000}
            callback={() => {
              setModalType({ type: '', isOpen: false });
              _getDriverAppList({ _dispatch });
            }}
          />
        );
      case 'Warning':
        return (
          <AlertModal
            type="WARNING"
            isOpen={isOpen}
            messages={
              <Stack sx={{ gap: '8px' }}>
                <Text styleName="body2" color="RC02">
                  팀 드라이버가 주행중 입니다.
                </Text>
                <Text styleName="body1" color="RC04">
                  주행을 완료한 뒤 다시 시도해주세요.
                </Text>
                <Text styleName="body2" color="RC02">{`주행 완료는 [모니터링 > 배차 목록 보기 > 주행완료] 에서`}</Text>
                <Text styleName="body2" color="RC02">
                  확인할 수 있습니다.
                </Text>
              </Stack>
            }
            buttonName="확인"
            callback={() => {
              setModalType({ type: '', isOpen: false });
              _getDriverAppList({ _dispatch });
            }}
          />
        );
      default:
        return <Fragment />;
    }
  }, [modalType.type]);

  return (
    <Layout attr="app" title={'드라이버 앱 관리'} desc={'팀별로 드라이버앱 기능을 관리할 수 있습니다.'}>
      {drvierAppModals}
      <LoadingModal isLoading={isLoading} />

      <Stack align={'flex-start'} direction={'row'}>
        <PageContentWrapper>
          <Stack align={'start'} justify={'start'}>
            <Stack align={'start'} sx={{ width: '100%' }}>
              <Stack sx={{ marginBottom: '40px' }}>
                <SearchFilterbar
                  callback={() => {
                    const payload = getValues();

                    _getDriverAppList({ _dispatch, payload });
                  }}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  options={DRIVER_FILTER_CATEGORY}
                  defaultValue={DRIVER_FILTER_CATEGORY[0].value}
                  placeholder={watch('placeholder')}
                  placeholderConvertor={convertSearchField}
                />
              </Stack>
            </Stack>
            <NewTable
              data={_state || []}
              tableRowCount={tableConfig.tableUnit}
              isFetching={isFetching}
              onRowClick={(row: any) => {
                setSelectedItem(row.original);
                setModalType({ type: 'SetAppConfig', isOpen: true });
              }}
              columns={tableConfig.columns}
            />
          </Stack>
        </PageContentWrapper>
      </Stack>
    </Layout>
  );
};
