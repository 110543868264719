import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import Modal from 'components/Modal';
import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { genLabel } from 'components/Modal/SingleOrderForm';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { useForm } from 'react-hook-form';
import { useSendManagerAuthCode } from 'hooks/query/managerAuthCode/useSendManagerAuthCode';

import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcError } from 'constants/icon/ic_error_alert_circle.svg';
import SpinLoader from 'util/SpinLoader';
import GrayBox from 'pages/Setting/common/GrayBox';
import { AuthCodeInput } from './AuthCodeInput';
import dayjs, { Dayjs } from 'dayjs';
import useInterval from 'hooks/useInterval';
import QueryStatusModal from 'components/Modal/QueryStatusModal';
import { DailyAuthCodeLimitModal } from 'components/Modal/warning/authCodeLimit/DailyAuthCodeLimitModal';
import { useSetPaymentVerifyManagerInfo, Variables } from 'hooks/query/payment/useSetPaymentVerifyManagerInfo';
import useFormUtils from 'hooks/useFormUtils';
import { useCheckExistsManagerInfo } from 'hooks/query/exists/useCheckExistsManagerInfo';
import { PaymentTypeUnion, PlanStatusUnion } from 'types/payment/union';
import { url } from '../../Unsubscribe/PaymentPlanModal';

type Tstatus = 'loading' | 'idle' | 'success' | 'error' | 'limit';

interface PaymentAuthModalProps {
  isPayment?: boolean;
  authModal: boolean;
  setAuthModal: Dispatch<SetStateAction<boolean>>;
  callback: (data?: Variables | Omit<Variables, 'code'>) => void;
  info?: {
    plan: Omit<PlanStatusUnion, 'Free'>;
    interval: PaymentTypeUnion;
  };
}

const regexp = /^(\d{2,3})(\d{3,4})(\d{4})$/;
const replaceValue = `$1-$2-$3`;

const PaymentAuthModal = ({ isPayment = false, authModal, setAuthModal, callback, info }: PaymentAuthModalProps) => {
  const limit = useRef(0);

  const [status, setStatus] = useState<Tstatus>('idle');
  const [timer, setTimer] = useState<number>(180);
  const [deadline, setDeadline] = useState<Dayjs>(dayjs(new Date()));

  const [timeoutModal, setTimeoutModal] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const methods = useForm<{
    name: string;
    email: string;
    phone: string;
  }>({ mode: 'onSubmit', defaultValues: {}, shouldFocusError: false, reValidateMode: 'onSubmit' });
  const { ivnm } = useFormUtils(methods);

  const {
    mutateAsync: mutateSendAuthCode,
    status: mutateSendAuthCodeStatus,
    error: mutateSendAuthCodeError,
  } = useSendManagerAuthCode({
    onMutate(vars) {
      setTimer(180);
      setDeadline(dayjs(new Date()).add(3, 'minute'));
    },
  });

  const {
    mutate: mutateCheckExistsManagerInfo,
    error: checkExistsManagerInfoError,
    isSuccess: isSuccessCheckExistsManagerInfo,
    status: mutateCheckExistsManagerInfoStatus,
  } = useCheckExistsManagerInfo();

  const { mutateAsync: setPaymentVerifyManagerInfo, error: setPaymentVerifyManagerInfoError } =
    useSetPaymentVerifyManagerInfo({
      onSuccess: res => {
        if (res?.paymentUrl) {
          window.localStorage.setItem('orderCode', res?.orderCode);
          window.location.href = res.paymentUrl;
        }
      },
    });

  useEffect(() => {
    if (isSuccessCheckExistsManagerInfo) {
      mutateSendAuthCode({ phone: methods.getValues('phone').replaceAll(/\D/g, '') });
    }
  }, [isSuccessCheckExistsManagerInfo, mutateCheckExistsManagerInfoStatus]);

  useEffect(() => {
    (async () => {
      if (checkExistsManagerInfoError) {
        const data = await checkExistsManagerInfoError.response.clone().json();

        if (data.error.name === 'DuplicatedEmail') {
          methods.setError('email', { type: data.error.name, message: data.error.message });
        }

        if (data.error.name === 'DuplicatedPhone') {
          methods.setError('phone', { type: data.error.name, message: data.error.message });
        }
      }
    })();
  }, [checkExistsManagerInfoError, mutateCheckExistsManagerInfoStatus]);

  useInterval(
    () => {
      if (status !== 'success') return;
      setTimer(dayjs(deadline).diff(dayjs(new Date()), 'second'));
    },
    1000,
    status !== 'success' || timer <= 0
  );

  useEffect(() => {
    setStatus(mutateSendAuthCodeStatus);
    return () => {};
  }, [mutateSendAuthCodeStatus]);

  useEffect(() => {
    if (timer < 2) setTimeoutModal(true);
    return () => {};
  }, [timer]);

  useEffect(() => {
    if (limit.current > 3) return setStatus('limit');
    return () => {};
  }, [limit.current]);

  useEffect(() => {
    (async () => {
      if (mutateSendAuthCodeError) {
        const data = await mutateSendAuthCodeError.response.clone().json();
        if (data.error.name === 'RequestLimit') {
          setAuthModal(false);
          setShowErrorAlert(true);
        }
      }
    })();
  }, [mutateSendAuthCodeError]);

  useEffect(() => {
    if (!authModal) {
      methods.reset();
    }
  }, [authModal]);

  return (
    <>
      <Modal plain width={504} isModalOpen={authModal} setIsModalOpen={setAuthModal} padding={24}>
        <Stack sx={{ paddingTop: 20 }}>
          {useMemo(() => {
            switch (status) {
              case 'limit':
                return (
                  <Stack>
                    <IcError width={44} height={44} />
                    <Stack spacing={8} sx={{ padding: '30px 0 16px' }}>
                      <Text styleName="body2">휴대폰 번호를 확인하고 인증을 재시도해 주세요.</Text>
                    </Stack>
                    <Button
                      children={'확인'}
                      variant={'tertiary'}
                      fw
                      h={40}
                      onClick={() => {
                        limit.current = 0;
                        setStatus('idle');
                      }}
                    />
                  </Stack>
                );
              case 'loading':
                return (
                  <Stack>
                    <SpinLoader />
                    <Stack spacing={8} sx={{ padding: '30px 0 16px' }}>
                      <Text styleName="body2">{methods.getValues('phone').replace(regexp, replaceValue)} 으로</Text>
                      <Text styleName="body2">인증 코드를 발송 중입니다. 잠시만 기다려 주세요.</Text>
                    </Stack>
                  </Stack>
                );
              case 'error':
                return (
                  <Stack>
                    <IcWarning width={44} height={44} />
                    <Stack spacing={8} sx={{ padding: '30px 0 40px' }}>
                      <Text styleName="body2">오류가 발생하여 인증 코드 발송을 처리하지 못하였습니다.</Text>
                      <Text styleName="body2">인증 코드를 재발송 하시겠습니까?</Text>
                    </Stack>
                    <Stack direction="row" spacing={20}>
                      <Button
                        children={'닫기'}
                        variant={'tertiary'}
                        fw
                        h={40}
                        onClick={() => {
                          setStatus('idle');
                          setAuthModal(false);
                          callback();
                        }}
                      />
                      <Button
                        children={'재발송'}
                        variant={'primary'}
                        fw
                        h={40}
                        onClick={() => {
                          mutateSendAuthCode({ phone: methods.getValues('phone').replaceAll(/\D/g, '') });
                        }}
                      />
                    </Stack>
                  </Stack>
                );
              case 'success':
                return (
                  <Stack spacing={20} align="start">
                    <Text styleName="title2">휴대폰 인증 코드 입력</Text>

                    <Stack align="start">
                      <Text styleName="body2">
                        <Highlight styleName="body1" color="RC02">
                          {methods.getValues('phone')?.replace(regexp, replaceValue)}
                        </Highlight>
                        {` 번호로 전송된 6자리 인증 코드를 입력해주세요.`}
                      </Text>
                      <Text styleName="subheadline1">
                        <Highlight styleName="subheadline2" color="RG03">
                          {'남은 시간 '}
                        </Highlight>
                        {`${Math.floor(timer / 60)
                          .toString()
                          .padStart(2, '0')} : ${Math.floor(timer % 60)
                          .toString()
                          .padStart(2, '0')}`}
                      </Text>
                    </Stack>

                    <AuthCodeInput
                      isPayment={isPayment}
                      phone={methods.getValues('phone')}
                      successCallback={code => {
                        if (isPayment) {
                          return setPaymentVerifyManagerInfo({
                            ...methods.getValues(),
                            phone: methods.getValues('phone').replaceAll(/\D/g, ''),
                            ...info!,
                            code,

                            successUrl: url('success', 'upgrade'),
                            errorUrl: url('error', 'upgrade'),
                            cancelUrl: url('warning', 'upgrade'),
                          });
                        } else {
                          setStatus('idle');
                          setAuthModal(false);

                          callback({
                            ...methods.getValues(),
                          });
                        }
                      }}
                    />

                    <Stack spacing={12} direction="row" sx={{ marginTop: 10 }}>
                      <Text styleName="subheadline2">코드를 받지 못하셨나요?</Text>
                      <Button
                        children={'인증 코드 재발송'}
                        variant={'secondary'}
                        h={32}
                        onClick={() => {
                          //재발송
                          mutateSendAuthCode({ phone: methods.getValues('phone').replaceAll(/\D/g, '') }).then(() => {
                            limit.current += 1;
                          });
                        }}
                      />
                    </Stack>

                    <GrayBox spacing={8} padding={10} sx={{ alignItems: 'start' }}>
                      <Text styleName="subheadline1" color="RG03">
                        인증번호가 오지 않나요?
                      </Text>
                      {[
                        `스팸차단서비스로 인증번호 수신이 어려울 수 있습니다. `,
                        `[전화 > 더보기 > 설정 > 수신차단 목록] 에서 `,
                        `SMS 인증 발신 번호(1533-0441)를 제외해 주세요.`,
                      ].map((str, index) => (
                        <Text styleName="subheadline2">{str}</Text>
                      ))}
                    </GrayBox>
                    <Button
                      children={'취소'}
                      variant={'tertiary'}
                      fw
                      h={40}
                      onClick={() => {
                        setStatus('idle');
                        setAuthModal(false);
                        callback();
                      }}
                    />
                  </Stack>
                );
              default:
                return (
                  <>
                    <Stack spacing={8} align="start">
                      <Text styleName="title2">결제 담당자 인증</Text>
                      <Text styleName="body2">결제 담당자 정보를 등록해주세요.</Text>
                    </Stack>
                    <form>
                      <Stack spacing={24} sx={{ padding: '30px 0 40px 0' }}>
                        <Input
                          name="name"
                          label={genLabel({ text: '결제 담당자 이름', isRequiredStart: true })}
                          placeholder="이름을 입력해 주세요"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          validation={{
                            required: ivnm.required('이름은'),
                            validate: ivnm.validName,
                          }}
                          variant="third"
                          field
                          height={48}
                          onFocus={() => {
                            methods.clearErrors('name');
                          }}
                          errors={methods.formState.errors?.name}
                          hasError={methods.formState?.errors?.name}
                        />
                        <Input
                          name="email"
                          label={genLabel({ text: '결제 담당자 이메일', isRequiredStart: true })}
                          placeholder="결제 정보를 받을 이메일 주소를 입력해 주세요"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          validation={{
                            required: ivnm.required('이메일은'),
                            validate: ivnm.validEmail,
                          }}
                          variant="third"
                          field
                          height={48}
                          onFocus={() => {
                            methods.clearErrors('email');
                          }}
                          errors={methods.formState.errors?.email}
                          hasError={methods.formState?.errors?.email}
                        />
                        <Input
                          name="phone"
                          label={genLabel({ text: '결제 담당자 휴대폰 번호', isRequiredStart: true })}
                          placeholder="결제 정보를 받을 휴대폰 번호를 입력해 주세요 "
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          validation={{
                            required: ivnm.required('휴대폰 번호 입력은'),
                            validate: ivnm.validPhone,
                          }}
                          variant="third"
                          field
                          height={48}
                          onFocus={() => {
                            methods.clearErrors('phone');
                          }}
                          errors={methods.formState.errors?.phone}
                          hasError={methods.formState.errors?.phone}
                        />
                      </Stack>
                    </form>
                    <Stack spacing={20}>
                      <Button
                        children={'결제 담당자 인증하기'}
                        variant={'primary'}
                        fw
                        h={40}
                        onClick={methods.handleSubmit(form => {
                          mutateCheckExistsManagerInfo({
                            email: form.email,
                            phone: form.phone,
                          });
                        })}
                      />
                      <Button
                        children={'취소'}
                        variant={'tertiary'}
                        fw
                        h={40}
                        onClick={() => {
                          setStatus('idle');
                          setAuthModal(false);
                          callback();
                        }}
                      />
                    </Stack>
                  </>
                );
            }
          }, [
            callback,
            info,
            isPayment,
            ivnm,
            methods,
            mutateCheckExistsManagerInfo,
            mutateSendAuthCode,
            setAuthModal,
            setPaymentVerifyManagerInfo,
            status,
            timer,
          ])}
        </Stack>
      </Modal>

      <DailyAuthCodeLimitModal isOpen={showErrorAlert} setIsOpen={setShowErrorAlert} callback={callback} />

      <QueryStatusModal
        isOpen={timeoutModal}
        setIsOpen={setTimeoutModal}
        status={'error'}
        string={`인증 번호 입력 시간을 초과하였습니다.\n인증 코드 재발송을 눌러 새로운 코드를 받으세요.`}
        callback={() => {
          setTimeoutModal(false);
        }}
      />
    </>
  );
};

export default PaymentAuthModal;
