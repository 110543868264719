import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcArrowCountDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ReactComponent as IcArrowCountUp } from 'constants/icon/ic_arrow_count_up.svg';
import { ReactComponent as IcDot } from 'constants/icon/ic_dot.svg';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';

import theme from 'constants/theme';
import { get } from 'lodash';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { Tampm, Tampmnm } from '../../types/time';

interface BreakRangePickerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  methods: UseFormReturn<any>;
  rSide?: boolean;
}

type Tampm_K = '오전' | '오후' | '익일오전';

const AMPMContents: Record<
  Tampm,
  {
    text: string;
  }
> = {
  AM: { text: '오전' },
  PM: { text: '오후' },
};

const AMPMNMContents: Record<
  Tampmnm,
  {
    text: string;
  }
> = {
  AM: { text: '오전' },
  PM: { text: '오후' },
  NM: { text: '익일오전' },
};

const BreakRangePicker = ({
                            isOpen,
                            setIsOpen,
                            name,
                            methods,
                          }: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  methods: UseFormReturn<any>;
}) => {
  const convert24to12 = (value: string) => {
    let h: string | undefined = value?.replaceAll('오전', '')?.replaceAll('오후', '')?.split(':')[0];
    let m: string | undefined = value?.replaceAll('오전', '')?.replaceAll('오후', '')?.split(':')[1];

    let timefor: Tampmnm = 'AM';

    if (!h) h = undefined;
    else if (parseInt(h) > 11) {
      timefor = 'PM';
      if (h !== '12') h = (parseInt(h) - 12).toString();
    } else {
      if (h === '00') h = '12';
    }

    return { h, m, timefor };
  };

  const [amPm, setAmPm] = useState<Tampmnm>(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[0]).timefor);
  const [amPm2, setAmPm2] = useState<Tampmnm>(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[1]).timefor);
  const [hour, setHour] = useState(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[0]).h ?? '');
  const [hour2, setHour2] = useState(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[1]).h ?? '');
  const [minute, setMinute] = useState(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[0]).m ?? '');
  const [minute2, setMinute2] = useState(convert24to12(methods.getValues(name)?.trim()?.split(`~`)[1]).m ?? '');
  const [nan, setNan] = useState<boolean>(true);
  const [nan2, setNan2] = useState<boolean>(true);

  const error = useMemo(() => get(methods.formState.errors, name), [methods.formState.errors, name]);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };

  useEffect(() => {
    if (!hour) {
      setHour('12');
    }
    if (!hour2) {
      setAmPm2('PM');
      setHour2('12');
    }
  }, []);

  useEffect(() => {
    let trimmedTime = methods.getValues(name)?.trim();
    if (trimmedTime) {
      let sp = trimmedTime.split('~');
      if (sp[0].includes(':')) setNan(false);
      if (sp[1].includes(':')) setNan2(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <Container ref={targetRef}>
      <Stack direction={'row'} spacing={30} divider={<Divider vertical style={{ height: 160 }}/>}>
        <TimePickerSv
          title="휴게 시작 시간"
          nanable
          hasInitial={methods.getValues(name)?.replaceAll('오전', '')?.replaceAll('오후', '')?.trim()?.split(`~`)[0]}
          nanKey="AM"
          hasError={Array.isArray(error?.type) && error?.type?.includes('startTime')}
          {...{ amPm, setAmPm, hour, setHour, minute, setMinute, nan, setNan }}
        />
        <TimePickerSv
          title="휴게 종료 시간"
          nanable
          hasError={Array.isArray(error?.type) && error?.type.includes('endTime')}
          hasInitial={methods.getValues(name)?.replaceAll('오전', '')?.replaceAll('오후', '')?.trim()?.split(`~`)[1]}
          nanKey="PM"
          {...{
            amPm: amPm2,
            setAmPm: setAmPm2,
            hour: hour2,
            setHour: setHour2,
            minute: minute2,
            setMinute: setMinute2,
            nan: nan2,
            setNan: setNan2,
          }}
        />
      </Stack>
      {error && (
        <Stack align="start">
          <Text styleName="caption2" color="RC04">
            <ErrorMarking style={{ marginRight: '6px' }}/>
            {`${error?.message}`}
          </Text>
        </Stack>
      )}
      <Stack direction="row-reverse" spacing={12} sx={{ justifyContent: 'end' }}>
        <Button
          variant="default"
          width={94}
          height={24}
          type="button"
          onClick={() => {
            function hourParser(a: Tampmnm, h: any) {
              let pHour = parseInt(h) || 0;
              if (a === 'PM' && pHour !== 12) pHour += 12;
              else if (a === 'AM' && pHour === 12) pHour = 0;

              return `${pHour}`.length === 1 ? '0' + pHour : pHour;
            }

            methods.setValue(
              name,
              `${nan ? '' : `${hourParser(amPm, hour)}:${minute}`}${!(nan && nan2) ? '~' : ''}${
                nan2 ? '' : `${hourParser(amPm2, hour2)}:${minute2}`
              }`,
              { shouldValidate: true, shouldDirty: true },
            );
            setIsOpen(false);
          }}
        >
          <Text styleName="caption1" color={'RG00'}>
            적용하기
          </Text>
        </Button>

        <Button
          variant={'second'}
          width={94}
          height={24}
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Text styleName="caption1" color={'RG04'}>
            닫기
          </Text>
        </Button>
      </Stack>
    </Container>
  );
};

export const TimePickerSv = ({
                               amPm,
                               setAmPm,
                               hour,
                               setHour,
                               minute,
                               setMinute,
                               nan,
                               setNan,
                               nanKey,

                               title,
                               nanable,
                               hasInitial,
                               hasError,

                               nm,
                             }: {
  amPm: Tampmnm;
  setAmPm: Dispatch<SetStateAction<Tampmnm>>;
  hour: string;
  setHour: Dispatch<SetStateAction<string>>;
  minute: string;
  setMinute: Dispatch<SetStateAction<string>>;
  nan?: boolean;
  setNan?: Dispatch<SetStateAction<boolean>>;
  nanKey?: Tampmnm;

  title: string;
  nanable?: boolean;
  hasInitial?: boolean;
  hasError?: boolean;

  nm?: boolean;
}) => {
  const [isTimeFocused, setIsTimeFocused] = useState(false);
  const [hourInput, setHourInput] = useState<string>('01');

  useEffect(() => {
    if (nan && !hasInitial) {
      // nanKey && setAmPm(nanKey);
      // if (!hasInitial) {
      //   setHour('12');
      //   setMinute('00');
      // }
      setHour('01');
      setMinute('00');
    }
  }, [nan]);
  useEffect(() => {
    setHourInput(hour.length === 1 ? '0' + hour : hour);
  }, [hour]);
  const callback = () => {
    if (nanable && setNan) setNan(false);
  };

  useEffect(() => {
    console.log('amPm', amPm);
  }, [amPm]);

  return (
    <Stack spacing={20}>
      <Stack justify={'space-between'} direction="row">
        <Text styleName="subheadline1" color="RG03">
          {title}
        </Text>

        {nanable && (
          <Stack
            direction="row"
            spacing={4}
            onClick={() => {
              setNan && setNan(prev => !prev);
            }}
            sx={{ width: 'max-content' }}
          >
            <IcSuccess width={16} height={16} fill={theme.colors[nan ? 'RC02' : 'RG05']}/>
            <Text styleName="caption1">없음</Text>
          </Stack>
        )}
      </Stack>
      <Fragment>
        <Stack direction={'row'} sx={{ borderRadius: '6px', overflow: 'hidden', width: '100%' }}>
          {Object.entries(nm ? AMPMNMContents : AMPMContents).map((d, index) => {
            return (
              <AmpmContainer
                key={`${d[0]}_${index}`}
                style={amPm === d[0] ? { backgroundColor: theme.colors.RC03 } : { backgroundColor: theme.colors.RG07 }}
                onClick={() => {
                  console.log('wtf', amPm, d[0]);
                  callback();

                  if (amPm === d[0]) return;

                  console.log('here', d[1].text);

                  setAmPm(d[0] as Tampmnm);
                  // switch (d[1].text) {
                  //   case '오전':
                  //     setAmPm(d[0] as Tampmnm);
                  //     return setDate(dayjs(BASE_DATE).clone());
                  //   case '오후':
                  //     setAmPm(d[0] as Tampmnm);
                  //     return setDate(dayjs(BASE_DATE).clone().add(12, 'hours'));
                  //   case '익일오전':
                  //     setAmPm(d[0] as Tampmnm);
                  //     return setDate(dayjs(BASE_DATE).clone().add(24, 'hours'));
                  // }
                }}
                len={2}
              >
                <Text styleName={'caption3'} color={amPm === d[0] ? 'RG00' : 'RG04'}>
                  {d[1].text}
                </Text>
              </AmpmContainer>
            );
          })}
        </Stack>

        <Stack spacing={4}>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  let h = (isNaN(parseInt(hour)) ? 0 : parseInt(hour)) + 1;
                  if (h === 13) setHour('01');
                  if (h < 13) setHour(`${h > 9 ? '' : '0'}${h}`);
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  let m = (isNaN(parseInt(minute)) ? 0 : parseInt(minute)) + 1;
                  if (m === 60) setMinute('00');
                  if (m < 60) setMinute(`${m > 9 ? '' : '0'}${m}`);
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            align={'center'}
            sx={{
              background: theme.colors[hasError ? 'RC04_1' : isTimeFocused ? 'RC03_1' : 'RG08'],
              border: '1px solid #769DFF',
              borderColor: theme.colors[hasError ? 'RC04' : isTimeFocused ? 'RC03' : 'RG08'],
              borderRadius: '6px',
            }}
          >
            <Stack padding={6}>
              <NumberInput
                style={{ color: theme.colors[nan ? 'RG04' : 'RG02'] }}
                {...{ hasError }}
                onBlur={e => {
                  setIsTimeFocused(false);
                  let parsed = parseInt(e.target.value) || 0;
                  if (parsed < 1) parsed = 1;
                  else if (parsed > 12) parsed = 12;
                  setHour(`${parsed > 9 ? '' : '0'}${parsed}`);
                  setHourInput(hour);
                }}
                onFocus={() => {
                  setIsTimeFocused(true);
                  setHourInput(hour);
                  callback();
                }}
                placeholder="00"
                value={hourInput}
                onChange={e => {
                  setHourInput(e.target.value);
                }}
              />
            </Stack>
            <Stack sx={{ width: '4px', height: '18px' }}>
              <IcDot fill={theme.colors[hasError ? 'RC04' : 'RG04']}/>
            </Stack>
            <Stack padding={6}>
              <NumberInput
                style={{ color: theme.colors[nan ? 'RG04' : 'RG02'] }}
                {...{ hasError }}
                onBlur={() => {
                  setIsTimeFocused(false);
                }}
                onFocus={() => {
                  setIsTimeFocused(true);
                  callback();
                }}
                placeholder="00"
                value={minute}
                onChange={e => {
                  let parsed = parseInt(e.target.value) || 0;
                  if (parsed < 0) parsed = 0;
                  else if (parsed > 59) parsed = 59;
                  setMinute(`${parsed > 9 ? '' : '0'}${parsed}`);
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  let h = (isNaN(parseInt(hour)) ? 1 : parseInt(hour)) - 1;
                  if (h === 0) setHour('12');
                  else if (h >= 0) setHour(`${h > 9 ? '' : '0'}${h}`);
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  let m = (isNaN(parseInt(minute)) ? 0 : parseInt(minute)) - 1;
                  if (m === -1) setMinute('59');
                  if (m >= 0) setMinute(`${m > 9 ? '' : '0'}${m}`);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Fragment>
    </Stack>
  );
};

export default BreakRangePicker;

const Container = styled.div<{
  rSide?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  top: 76px;
  ${({ rSide }) =>
          rSide
                  ? {
                    right: 0,
                  }
                  : {
                    left: 0,
                  }}
  z-index: 3;
`;

const AmpmContainer = styled.div<{
  len: number;
}>`
  ${({ len }) => ({
    width: `calc(100% / ${len})`,
  })}
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NumberInput = styled.input<{
  hasError?: boolean;
}>`
  ${({ theme }) => theme.fontStyle.title2}
  width: 100%;
  text-align: center;
  color: ${({ theme, hasError }) => theme.colors[hasError ? 'RC04' : 'RG02']};

  ::placeholder {
    color: ${({ theme }) => theme.colors.RG04};
  }

  background: transparent;
`;
