// import { PlanStatusTag } from '../../../Tag/new/PlanStatus';
// import { PlanStatusUnion } from '../../../../types/payment/union';
// import { PaymentTypeTag } from '../../../Tag/new/PaymentType';
// import React from 'react';
//
// <PlanStatusTag text={paymentTarget.name as PlanStatusUnion} />
// <PaymentTypeTag text={paymentType} />

import { Dispatch, SetStateAction } from 'react';
import { WarningModal } from '../index';
import { PlanStatusTag } from '../../../Tag/new/PlanStatus';
import { PlanStatusUnion } from '../../../../types/payment/union';
import { Text } from '../../../Typography';
import { Stack } from '../../../Stack';
import { Button } from '../../../Button';
import { useNavigate } from 'react-router-dom';
import { useGNBStore } from '../../../../store';

export interface UpgradeGuideModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  upgradeGuidePlan: PlanStatusUnion[];
  text: string;
}

export const UpgradeGuideModal = ({ isOpen, setIsOpen, upgradeGuidePlan, text }: UpgradeGuideModalProps) => {
  const navigate = useNavigate();
  const { SETSETTINGOPEN } = useGNBStore();

  return (
    <WarningModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      description={
        <>
          <Stack direction="row" justify="center" spacing={8}>
            {upgradeGuidePlan.map((item, index) => (
              <PlanStatusTag key={`${item}-${index}`} text={item} />
            ))}
          </Stack>

          <Text styleName="body2" color="RG02">
            {text}
          </Text>
        </>
      }
      buttonGroup={
        <Stack direction="row" spacing={20} sx={{ marginTop: '40px' }}>
          <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={() => setIsOpen(false)}>
            닫기
          </Button>
          <Button
            variant="primary"
            h={40}
            color="RC02"
            w="100%"
            onClick={() => {
              navigate('/mypage', { state: { head: 'usage-and-plan' } });
              SETSETTINGOPEN(false);
              setIsOpen(false);
            }}
          >
            요금제 보러가기
          </Button>
        </Stack>
      }
    />
  );
};
