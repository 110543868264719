import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { Button } from 'components/Button';
import { Tag, TTagStatusKeys } from 'components/Tag';
import { common_table_selection } from 'constants/commons';

import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcMail } from 'constants/icon/ic_mail.svg';
import { IInvitedDriver, TOperationType } from 'constants/types';
import dayjs from 'dayjs';
import { TDriverSettingActions } from 'pages/Setting/resource/Driver';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { isFalsy } from 'util/isFalsy';

import TableComponent from '.';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

const columnHelper = createColumnHelper<IInvitedDriver>();

const InvitedDriverManagementTable = ({
  data,
  selectedDriver,
  setSelectedDriver,
  callbackModalSetter,
}: {
  data: Array<IInvitedDriver>;
  selectedDriver: Array<any>;
  setSelectedDriver: Dispatch<SetStateAction<Array<any>>>;
  callbackModalSetter: (action: TDriverSettingActions) => void;
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({});

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedDriver.filter(z => z.invitationId === y.invitationId).length === 0);
      setSelectedDriver([...selectedDriver, ...orders]);
    } else {
      let orders: Array<any> = selectedDriver;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.invitationId !== s.original?.invitationId);
      });
      setSelectedDriver(orders);
    }
  }

  useEffect(() => {
    let t: any = {};
    selectedDriver.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.invitationId === x.invitationId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedDriver, setRowSelection]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),

      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: '초대 일자',
        cell: info => dayjs(info.getValue()).format('YYYY.MM.DD'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.expiredAt, {
        id: 'expiredAt',
        header: '만료 일자',
        cell: info => dayjs(info.getValue()).format('YYYY.MM.DD'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: '이름',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.loginAccount, {
        id: 'loginAccount',
        header: '아이디(휴대폰번호)',
        cell: info => {
          return info.getValue()
            ? info
                .getValue()
                .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
            : null;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.operationType, {
        id: 'operationTyped',
        header: '운영 유형',
        cell: info => {
          const mapper: {
            [key in TOperationType]: {
              string: string;
              key: TTagStatusKeys;
            };
          } = {
            backup: { string: '용차', key: 'moving' },
            regular: { string: '고정차', key: 'done' },
          };

          return info.getValue() ? (
            <Tag active status={mapper[info.getValue()].key} sx={{ width: 45, height: 24 }} fit>
              {mapper[info.getValue()].string}
            </Tag>
          ) : null;
        },
        footer: info => info.column.id,
      }),

      columnHelper.accessor(row => row.modelName, {
        id: 'modelName',
        header: '차종',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.skills?.map(skill => skill.name).join(', '), {
        id: 'skill',
        header: '특수 조건',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.teamName, {
        id: 'teamName',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
    ],
    [anchorIsOpen]
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
      rowSelection,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    sortDescFirst: true,
  });

  const isButtonDisable = useMemo(() => {
    return isFalsy(selectedDriver);
  }, [selectedDriver]);

  return (
    <React.Fragment>
      <TableComponent<IInvitedDriver>
        table={instance}
        isDataFetching={false}
        sc={[
          <Button
            children={'초대장 재전송'}
            variant={'tertiary'}
            h={32}
            icon={[IcMail, { width: 16, height: 16 }]}
            disabled={isButtonDisable}
            onClick={() => callbackModalSetter('resend')}
          />,
          <Button
            children={'초대 취소'}
            variant={'tertiary'}
            h={32}
            icon={[IcBin, { width: 16, height: 16 }]}
            disabled={isButtonDisable}
            onClick={() => callbackModalSetter('cancelInvite')}
          />,
        ]}
        tableType="driver"
        clearBottom
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}
    </React.Fragment>
  );
};

export default InvitedDriverManagementTable;
