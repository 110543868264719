import { useEffect, useMemo, useRef, useState, Dispatch, SetStateAction } from 'react';
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Highlight, Text } from 'components/Typography';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import { ReactComponent as ArrowLeft } from '../../constants/icon/timeline_arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../constants/icon/timeline_arrow_right.svg';
import { ReactComponent as ReturnRight } from '../../constants/icon/ic_arrow_return_table_right.svg';
import { ReactComponent as ReturnLeft } from '../../constants/icon/ic_arrow_return_table_left.svg';
import { ReactComponent as IcWide } from '../../constants/icon/ic_arrow_upleft.svg';
import { ReactComponent as IcNarrow } from '../../constants/icon/ic_arrow_downright.svg';
import clonedeep from 'lodash.clonedeep';
import { ItemList } from './components/ItemList';
import DriverSummaryInformationComponent, { DVCalculator } from 'components/DriverSummaryInformation';
import {
  DriverIdUnSelected,
  IEqualOptimizeResponsesDriver,
  ISelectorRequires,
  ITimelineBlock,
  ITimelineData,
} from 'constants/types';
import { Stack } from 'components/Stack';
import theme from 'constants/theme';
import { Button } from 'components/Button/legacy_index';
import AltWrapper from 'components/Alt/AltWrapper';
import EditedCell from './components/EditedCell';
import Selector from './components/Selector';
import { QueueSelectorContainer } from 'pages/Report/utils/ReportFilter';
import orderby from 'lodash.orderby';
import { ISelectedCellFunctions, TCallbackConstructor, TMod } from 'pages/RoutePlanConfirm';
import { is_temp_units_exceeded } from 'constants/commons';
import { useLocation } from 'react-router';
import { getDriverInfo } from 'api';
import { useQuery } from '@tanstack/react-query';

export const UNBATCHED_COLUMN_KEY = 'driver-unbatched';
export const BASE_HOUR = 0;

export function rbdIndexFinder({
  timelineData,
  draggableId,
  orderId,
}: {
  timelineData: ITimelineData;
  draggableId?: string;
  orderId?: number;
}): {
  columnKey: string;
  columnIndex: number;
} {
  for (let k = 0; k < timelineData.columnOrder.length; k++) {
    let key = timelineData.columnOrder[k];
    for (let i = 0; i < timelineData.columns[key].items.length; i++) {
      let item = timelineData.columns[key].items[i];
      if (draggableId && item.id === draggableId)
        return {
          columnKey: key,
          columnIndex: i,
        };
      if (orderId && item.originData?.orderId === orderId)
        return {
          columnKey: key,
          columnIndex: i,
        };
    }
  }
  return {
    columnKey: '',
    columnIndex: 0,
  };
}

function reorderList(list: ITimelineBlock[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function Timeline({
  selectedDriver,
  setSelectedDriver,
  selectedCellFunctions,
  width,
  driverData,
  timelineData,
  setTimelineData,
  setMaxmized,
  maximized,
  showDetail,
  mod,
  setMod = () => {
    console.log('no set function');
  },

  callbackConstructor = () => {},
  resetHistory = () => {},
}: {
  setSelectedDriver: Dispatch<SetStateAction<number>>;
  selectedDriver: number;
  selectedCellFunctions: ISelectedCellFunctions;
  width: number;
  driverData: IEqualOptimizeResponsesDriver[];
  timelineData: ITimelineData;
  setTimelineData: Dispatch<SetStateAction<ITimelineData | undefined>>;
  setMaxmized: Dispatch<SetStateAction<boolean>>;
  maximized: boolean;
  showDetail?: (orderId: number) => void;
  mod?: TMod;
  setMod?: (v: TMod) => void;

  callbackConstructor?: (e?: any, content?: TCallbackConstructor<any>) => void;
  resetHistory?: (e?: any, callback?: Function) => void;
}) {
  const listGlobRef = useRef<any>([]);
  const mibaechaListGlobRef = useRef<any>([]);
  const headerRef = useRef<any>();
  const componentCombinerRef = useRef<any>();
  const { selectedCell, selectedCellGroup, setSelectedCell, setSelectedCellGroup } = selectedCellFunctions;
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [lastEditedTarget, setLastEditedTarget] = useState(0);
  const [startEndChipSelected, setStartEndChipSelected] = useState('');
  const [preventMove, setPreventMove] = useState(false);

  const { data: drivers, isFetching: getDriverListIsFetching } = useQuery(['drivers'], getDriverInfo, {});

  useEffect(() => {
    setTimelineWidth(headerRef.current.offsetWidth);

    window.addEventListener('resize', () => {
      setTimelineWidth(headerRef.current.offsetWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setTimelineWidth(headerRef.current.offsetWidth);
      });
    };
  }, [maximized]);

  const [orderedDriverData, setOrderedDriverData] = useState<Array<IEqualOptimizeResponsesDriver>>(driverData);

  useEffect(() => {
    console.log('orderedDriverData', orderedDriverData);
  }, [orderedDriverData]);

  const [multiplier, setMultiplier] = useState<number>(1);
  const multiplierOptions: Array<{ key: string; value: number }> = useMemo(
    () => [
      {
        key: '60분 단위',
        value: 1,
      },
      {
        key: '30분 단위',
        value: 2,
      },
      {
        key: '10분 단위',
        value: 6,
      },
    ],
    []
  );

  const [lastDragEndedDriver, setLastDragEndedDriver] = useState<number>(-1);
  const [clipboard, setClipboard] = useState<ITimelineBlock | undefined>();
  const [history, setHistory] = useState<{ stack: ITimelineData[]; depth: number }>({ stack: [], depth: 0 });
  const location = useLocation();

  // Multiplier 수정 시, 좌측 padding 조절 (업무 시작시간에 align 맞추기)
  useEffect(() => {
    timelinePositionReset();
  }, [multiplier]);

  // Multiplier 수정 시, 각 셀의 width 업데이트 진행
  useEffect(() => {
    let copiedColumns = timelineData.columns;
    Object.keys(copiedColumns).forEach((cid: string) => {
      let col = [...copiedColumns[cid].items];
      col.forEach((item: ITimelineBlock) => {
        if (!item.fixedSize) {
          item.width = item.originWidth * multiplier;
        }
        item.multiplier = multiplier;
      });
    });

    const newState = {
      ...timelineData,
      columns: {
        ...copiedColumns,
      },
    };

    timelineDataUpdate(newState);

    listGlobRef?.current.forEach((x: any) => {
      x.resetAfterIndex(0);
    });

    mibaechaListGlobRef?.current.forEach((x: any) => {
      x.resetAfterIndex(0);
    });
  }, [multiplier]);

  // 좌측 Group Cell 선택 시 올바른 컬럼을 선택 한 경우, 우측 Cell들이 선택되도록 업데이트
  useEffect(() => {
    if (timelineData.columns[selectedCellGroup]) {
      let res = timelineData.columns[selectedCellGroup].properties.editedItems.map(c => {
        const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, draggableId: c });
        if (columnKey === '') return -1;
        let item = timelineData.columns[columnKey].items[columnIndex];
        if (item.originData?.orderId) return item.originData?.orderId ?? -1;
        return -1;
      });
      let driverId = timelineData.columns[selectedCellGroup].driverId;
      setSelectedDriver(driverId ? driverId : DriverIdUnSelected);
      setSelectedCell?.(res);
    }
  }, [selectedCellGroup]);

  useEffect(() => {
    if (history.stack.length > 1) {
      restoreFromHistory(history.stack[0]);
      setHistory({
        stack: [history.stack[0]],
        depth: 1,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    setClipboard(undefined);
    setMultiplier(1);
    if (history.stack.length > 1) {
      restoreFromHistory(history.stack[0]);
      setHistory({
        stack: [history.stack[0]],
        depth: 1,
      });
    }
    setSelectedCellGroup('');
    setSelectedCell([]);
  }, [mod]);

  useEffect(() => {
    if (lastEditedTarget !== 0) {
      fnOnCellClicked(lastEditedTarget);
      setLastEditedTarget(0);
    }
  }, [timelineData]);

  useEffect(() => {
    if (selectedCell.length > 0) {
      let x = selectedCell[0];
      if (x !== -1) {
        const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, orderId: x });
        if (columnKey !== '') {
          let item = timelineData.columns[columnKey].items[columnIndex];
          timelineGotoItem(item.id);
        }
      }
      setStartEndChipSelected('');
    }
  }, [selectedCell]);

  function scrollToPosition(position: number, type: 'headerRef' | 'combinerRef' | 'mibaechaListGlobRef' = 'headerRef') {
    let startPosition = type === 'headerRef' ? headerRef.current.scrollLeft : componentCombinerRef.current.scrollTop;
    if (type === 'mibaechaListGlobRef') {
      mibaechaListGlobRef?.current.forEach((x: any, index: number) => {
        startPosition = x.state.scrollOffset;
      });
    }

    let distance = position - startPosition;
    let startTime: any = null;

    function animation(currentTime: any) {
      if (startTime === null) startTime = currentTime;
      let timeElapsed = currentTime - startTime;
      let scrollTop = easeInOutQuad(timeElapsed, startPosition, distance, 400);

      if (type === 'headerRef') {
        headerRef.current.scrollLeft = scrollTop;
      } else if (type === 'combinerRef') {
        componentCombinerRef.current.scrollTop = scrollTop;
      } else if (type === 'mibaechaListGlobRef') {
        mibaechaListGlobRef?.current.forEach((x: any, index: number) => {
          x.scrollTo(scrollTop);
          if (timeElapsed > 390) x.resetAfterIndex(0);
        });
      }
      if (timeElapsed < 400) requestAnimationFrame(animation);
    }

    function easeInOutQuad(t: any, b: any, c: any, d: any) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }

  function timelineGotoItem(id: string, position: 'both' | 'x' | 'y' = 'both') {
    if (preventMove) {
      setPreventMove(false);
      return;
    }
    let leftScrollPosition = -50;
    let isScrollPositionSetted = false;
    let targetKey = ``;
    Object.keys(timelineData.columns).map(key => {
      let col = timelineData.columns[key];

      if (col && key !== UNBATCHED_COLUMN_KEY) {
        let hasOrder = col.items.filter((k: ITimelineBlock) => k.id === id).length > 0;
        if (hasOrder) {
          let shutdownFlag = false;
          col.items.map(iz => {
            if (iz.id === id) shutdownFlag = true;
            if (!shutdownFlag) {
              isScrollPositionSetted = true;
              targetKey = key;
              if (iz.fixedSize) leftScrollPosition += iz.width;
              else leftScrollPosition += iz.originWidth * multiplier;
            }
          });
        }
      } else if (col && key === UNBATCHED_COLUMN_KEY) {
        let hasOrder = col.items.filter((k: ITimelineBlock) => k.id === id).length > 0;
        if (hasOrder) {
          let shutdownFlag = false;
          col.items.map(iz => {
            if (iz.id === id) shutdownFlag = true;
            if (!shutdownFlag) {
              isScrollPositionSetted = true;
              targetKey = key;
              if (iz.fixedSize) leftScrollPosition += iz.width;
              else leftScrollPosition += iz.originWidth * multiplier;
            }
          });
        }
      }
    });

    if (isScrollPositionSetted) {
      if (targetKey !== UNBATCHED_COLUMN_KEY) {
        let driverId = parseInt(targetKey.split('-')[1]);
        let yPos = -1;
        orderedDriverData.forEach((x, index) => {
          if (x.driverId === driverId) {
            yPos = index * 54;
          }
        });
        if (yPos > -1 && position != 'x') {
          scrollToPosition(yPos, 'combinerRef');
        }
      }

      if (targetKey === UNBATCHED_COLUMN_KEY) {
        scrollToPosition(leftScrollPosition, 'mibaechaListGlobRef');
      } else scrollToPosition(leftScrollPosition, 'headerRef');
    }
  }
  function timelinePositionReset(isMibaecha = false, customTargetId = '', customOrderId = '') {
    if (isMibaecha) {
      mibaechaListGlobRef?.current.forEach((x: any, index: number) => {
        x.scrollTo(0);
        x.resetAfterIndex(0);
      });
      return;
    }

    let leftScrollPosition = 0;
    let isScrollPositionSetted = false;
    Object.keys(timelineData.columns).map(key => {
      let col = timelineData.columns[key];
      if (col && key !== UNBATCHED_COLUMN_KEY) {
        let hasOrder = col.items.filter((k: ITimelineBlock) => k.type === 'order').length > 0;
        let invItem = col.items.filter((k: ITimelineBlock) => k.type === 'invisible');
        if (customTargetId === '' || customTargetId === key) {
          if (invItem.length > 0 && hasOrder) {
            let startPosition = invItem[0].originWidth;
            if (!isScrollPositionSetted) {
              isScrollPositionSetted = true;
              leftScrollPosition = startPosition;
            } else if (leftScrollPosition > startPosition) {
              leftScrollPosition = startPosition;
            }
          }
          if (customTargetId === key && !isScrollPositionSetted) {
            isScrollPositionSetted = true;
            if (invItem.length > 0) {
              leftScrollPosition = invItem[0].originWidth;
            }
          }
        }
      }
    });
    if (isScrollPositionSetted) scrollToPosition(leftScrollPosition * multiplier, 'headerRef');
  }
  function timelineDataUpdate(timelineData: ITimelineData) {
    const HISTORY_LIMIT = 2;

    let depth = history.depth + 1;
    if (depth > HISTORY_LIMIT) depth = HISTORY_LIMIT;
    setTimelineData(timelineData);
    setHistory({
      stack: [...history.stack.slice(0, HISTORY_LIMIT - 1), timelineData],
      depth,
    });
  }
  useEffect(() => {
    if (startEndChipSelected && startEndChipSelected !== 'reset') {
      setSelectedCellGroup('');
      setSelectedCell([]);

      const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, draggableId: startEndChipSelected });
      if (columnKey !== '' && columnKey !== UNBATCHED_COLUMN_KEY) {
        setSelectedDriver(parseInt(columnKey.split('-')[1]));
      }
    } else if (startEndChipSelected === 'reset') {
      setStartEndChipSelected('');
      setSelectedDriver(DriverIdUnSelected);
    }
  }, [startEndChipSelected]);

  useEffect(() => {
    if (selectedDriver === DriverIdUnSelected && startEndChipSelected !== '') {
      setStartEndChipSelected('');
    }
  }, [selectedDriver]);

  function fnOnCellClicked(orderId: number) {
    if (selectedCellGroup !== '') {
      setSelectedCellGroup('');
    } else if (selectedCell.length === 1 && selectedCell[0] === orderId) {
      setSelectedCell([]);
      setSelectedDriver(DriverIdUnSelected);
      return;
    }
    setPreventMove(true);
    setSelectedCell([orderId]);

    const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, orderId });
    if (columnKey !== '' && columnKey !== UNBATCHED_COLUMN_KEY) {
      setSelectedDriver(parseInt(columnKey.split('-')[1]));
    } else if (columnKey !== '' && columnKey === UNBATCHED_COLUMN_KEY) {
      setSelectedDriver(DriverIdUnSelected);
    }
  }

  function fnShowOrderDetail(orderId: number) {
    showDetail?.(orderId);
  }

  function fnLoadFromClipboard(draggableId: string) {
    const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, draggableId });
    if (columnKey === '' || !clipboard) return;
    let filteredColumn = clonedeep(timelineData.columns[columnKey]);
    filteredColumn.items.splice(columnIndex, 0, clipboard);
    if (`driver-${clipboard.driverId}` !== columnKey) {
      filteredColumn.properties.editedItems.push(clipboard.id);
      filteredColumn.properties.hiddenEdited = true;
    }

    const newState = {
      ...timelineData,
      columns: {
        ...timelineData.columns,
        [columnKey]: filteredColumn,
      },
    };

    let orderId = clipboard.originData?.orderId;
    setLastEditedTarget(orderId ?? 0);
    setClipboard(undefined);
    timelineDataUpdate(newState);
    setTimeout(() => {
      fnOnCellClicked(orderId ?? -1);
    }, 200);
  }

  function fnSaveToClipboard(orderId: number) {
    if (clipboard) {
      return callbackConstructor(null, {
        icon: 'err',
        content: (
          <Stack spacing={10}>
            <Text styleName="subheadline2" color="RG02">
              이미 잘라낸 주문이 있습니다. 타임라인에 붙여넣기 후 시도해주세요.
            </Text>
          </Stack>
        ),
      });
    }

    const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, orderId });
    if (columnKey === '') return;
    let item = timelineData.columns[columnKey].items[columnIndex];
    setClipboard(item);

    let filteredColumn = clonedeep(timelineData.columns[columnKey]);
    filteredColumn.items.splice(columnIndex, 1);
    filteredColumn.properties.editedItems = filteredColumn.properties.editedItems.filter(x => x !== item.id);
    filteredColumn.properties.hiddenEdited = true;
    const newState = {
      ...timelineData,
      columns: {
        ...timelineData.columns,
        [columnKey]: filteredColumn,
      },
    };

    setTimelineData(newState);

    setSelectedCellGroup('');
    setSelectedCell([]);
    setSelectedDriver(DriverIdUnSelected);
  }

  function onScrollHeader(e: any) {
    listGlobRef?.current.forEach((x: any, index: number) => {
      x.scrollTo(e.target.scrollLeft);
      x.resetAfterIndex(0);
    });
  }

  function onWheelUnbatched(e: any) {
    mibaechaListGlobRef?.current.forEach((x: any, index: number) => {
      x.scrollTo(x.state.scrollOffset + (e.deltaX + e.deltaY) / 2);
      x.resetAfterIndex(0);
    });
  }

  function onWheel(e: any) {
    headerRef.current.scrollLeft += (e.deltaX + e.deltaY) / 2;
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    setSelectedCell([]);
    setSelectedCellGroup('');

    if (is_temp_units_exceeded()) {
      return callbackConstructor(null, {
        content: (
          <Stack spacing={10}>
            <Text styleName="title2" color="RG02">
              임시 결과는 5개까지만 생성 가능합니다.
            </Text>
            <Text styleName="subheadline2" color="RG02">
              <Highlight color="RC02">임시결과 4개 중에서 삭제후 다시 주문을 이동할 수 있습니다.</Highlight>
              <br />
              초기결과는 삭제할 수 없습니다.
            </Text>
          </Stack>
        ),
      });
    }
    if (lastDragEndedDriver !== -1) {
      console.log(`[Drag compensation] ${result.destination?.index} -> lastDragEndedDriver`);
      let droppableId = lastDragEndedDriver === -10 ? UNBATCHED_COLUMN_KEY : `driver-${lastDragEndedDriver}`;
      result.destination = { droppableId, index: lastDragEndedDriver === -10 ? 1 : 3 };
    } else if (!result || !result.destination) {
      return;
    }

    const sourceBlock = timelineData.columns[result.source.droppableId].items[result.source.index];
    const destinationBlock = timelineData.columns[result.destination.droppableId].items[result.destination.index];

    // 옮기는 대상이 LeftPadding 혹은 Invisible 일 경우 작업 캔슬
    if (mod === 'manual') {
      if (destinationBlock.type === 'leftPadding' || destinationBlock.type === 'invisible') {
        return;
      } else if (destinationBlock.type === 'start' || destinationBlock.type === 'end') {
        return;
      }
    }
    // 옮기는 대상이 Start 혹은 End 셀 일경우 작업 캔슬
    if (sourceBlock.type === 'jumsim') {
      return;
    }
    if (mod === 'auto') {
      if (sourceBlock.originData?.skill) {
        let skillId = sourceBlock.originData.skill;
        let targetDriver = drivers?.memberList.filter(x => x.vehicle && x.vehicle.licenseNumber === skillId);

        if (
          targetDriver &&
          targetDriver.length > 0 &&
          result.destination.droppableId === `driver-${targetDriver[0].driverId}`
        ) {
          // 특정차량 드라이버 위에 옳게 드랍 했을때
        } else if (result.destination.droppableId === UNBATCHED_COLUMN_KEY) {
          // 미배차에 드랍 했을때
        } else
          return callbackConstructor(null, {
            icon: 'err',
            content: <>최적화 엔진 작동중에 특정 차량 지정 주문은 이동이 불가합니다.</>,
          });
      }
    }

    // 주문 이동 / 순서 변경시 유휴시간 초기화
    sourceBlock.waitingTime = 0;

    if (mod === 'manual') {
      destinationBlock.waitingTime = 0;
      if (timelineData.columns[result.destination.droppableId].items.length > result.destination.index) {
        const destinationBlockAfter =
          timelineData.columns[result.destination.droppableId].items[result.destination.index + 1];
        destinationBlockAfter.waitingTime = 0;
      }
    }

    // 옮길때 간격 shrink 시키는 코드 (요청시 적용예정)
    // else if (destinationBlock.type === 'spacer') {
    //   let destinationWidth = destinationBlock.width;
    //   let sourceWidth = sourceBlock.width;
    //   destinationBlock.width = destinationWidth - sourceWidth > 0 ? destinationWidth - sourceWidth : 0;
    // }

    if (result.source.droppableId === result.destination.droppableId) {
      if (mod === 'auto') {
        return callbackConstructor(null, {
          icon: 'war',
          content: <>자동 최적화 모드에서는 주문 순서를 변경 할 수 없습니다.</>,
        });
      }

      const column = clonedeep(timelineData.columns[result.source.droppableId]);
      const items = reorderList(column.items, result.source.index, result.destination.index);

      column.properties.sortChanged = true;
      const newState = {
        ...timelineData,
        columns: {
          ...timelineData.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };

      if (sourceBlock.type === 'order' || sourceBlock.type === 'mibaecha') {
        if (sourceBlock.originData && sourceBlock.originData.orderId) {
          setLastEditedTarget(sourceBlock.originData?.orderId!);
        }
      }

      setPreventMove(true);
      timelineDataUpdate(newState);

      return;
    }

    const sourceColumn = clonedeep(timelineData.columns[result.source.droppableId]);
    const destinationColumn = clonedeep(timelineData.columns[result.destination.droppableId]);

    /* 좌측에 표시되는 EditedColumns 저장하는 코드 
    Desc: 출발지 Col에서는 옮겨진 Cell 데이터를 지우고, 
    타겟 Cell의 원본 위치가 도착지 Col이 아닐경우에만 Properties에 추가            */

    sourceColumn.properties.editedItems = sourceColumn.properties.editedItems.filter(x => x !== result.draggableId);
    sourceColumn.properties.hiddenEdited = true;
    if ((sourceBlock.driverId ? `driver-${sourceBlock.driverId}` : UNBATCHED_COLUMN_KEY) !== destinationColumn.id) {
      destinationColumn.properties.editedItems.push(result.draggableId);
      destinationColumn.properties.hiddenEdited = true;
    }
    /* */

    const item = sourceColumn.items[result.source.index];

    const newSourceColumn = {
      ...sourceColumn,
      items: [...sourceColumn.items],
    };
    newSourceColumn.items.splice(result.source.index, 1);

    const newDestinationColumn = {
      ...destinationColumn,
      items: [...destinationColumn.items],
    };

    if (mod === 'auto') {
      let stIdx = newDestinationColumn.id === UNBATCHED_COLUMN_KEY ? 1 : 3;
      newDestinationColumn.items.forEach((x: ITimelineBlock, index: number) => {
        if (x.type === 'start') stIdx = index + 1;
      });
      newDestinationColumn.items.splice(stIdx, 0, item);
    } else {
      newDestinationColumn.items.splice(result.destination.index, 0, item);

      newDestinationColumn.items = [
        ...newDestinationColumn.items.filter(x => x.type !== 'end' && x.type !== 'rightPadding'),
        ...newDestinationColumn.items.filter(x => x.type === 'end'),
        ...newDestinationColumn.items.filter(x => x.type === 'rightPadding'),
      ];
      // timelinePositionReset(newDestinationColumn.id === UNBATCHED_COLUMN_KEY, newDestinationColumn.id, sourceBlock.id);
    }

    const newState = {
      ...timelineData,
      columns: {
        ...timelineData.columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestinationColumn.id]: newDestinationColumn,
      },
    };

    if (sourceBlock.type === 'order' || sourceBlock.type === 'mibaecha') {
      if (sourceBlock.originData && sourceBlock.originData.orderId) {
        setLastEditedTarget(sourceBlock.originData?.orderId!);
      }
    }

    timelineDataUpdate(newState);

    if (mod === 'auto') {
      timelinePositionReset(newDestinationColumn.id === UNBATCHED_COLUMN_KEY, newDestinationColumn.id);
    } else {
      timelineGotoItem(destinationBlock.id, 'x');
    }
  }

  function reloadTimeline() {
    // column order 변경후, 강제로 스크롤을 이동해 react-window가 업데이트 되도록 트리거
    let scrollPosition = headerRef.current.scrollLeft;
    headerRef.current.scrollLeft += 1;
    setTimeout(() => {
      headerRef.current.scrollLeft = scrollPosition;
    }, 10);
  }

  function restoreFromHistory(state: ITimelineData) {
    setClipboard(undefined);
    setTimelineData(state);
    reloadTimeline();
  }

  const modContents: Array<ISelectorRequires<TMod>> = useMemo(
    () => [
      { key: '자동 최적화', value: 'auto' },
      { key: '임의 순서 변경', value: 'manual' },
    ],
    []
  );

  type TSortBy = // keyname-orderby

      | 'name-asc'
      | 'order-desc'
      | 'order-asc'
      | 'worktime-desc'
      | 'worktime-asc'
      | 'distance-desc'
      | 'distance-asc'
      | 'cargos-desc'
      | 'cargos-asc';
  const [sortBy, setSortBy] = useState<TSortBy>('name-asc');
  const sortContents: Array<
    ISelectorRequires<TSortBy> & { setter: (arr: Array<IEqualOptimizeResponsesDriver>) => void }
  > = useMemo(
    () => [
      {
        key: '드라이버 이름순',
        value: 'name-asc',
        setter: arr => {
          let temper = arr.map(d => {
            return { ...d, supportKey: DVCalculator(d).number_of_orders > 0 };
          });
          temper = orderby(temper, ['supportKey', 'name'], ['desc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '주문 많은 순',
        value: 'order-desc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).number_of_orders,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'desc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '주문 적은 순',
        value: 'order-asc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).number_of_orders,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'asc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '업무 시간 긴 순',
        value: 'worktime-desc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).work_time,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'desc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '업무 시간 짧은 순',
        value: 'worktime-asc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).work_time,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'asc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '이동 거리 긴 순',
        value: 'distance-desc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).distance,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'desc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '이동 거리 짧은 순',
        value: 'distance-asc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).distance,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'asc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },

      {
        key: '용적량 많은 순',
        value: 'cargos-desc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).cargos,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'desc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
      {
        key: '용적량 적은 순',
        value: 'cargos-asc',
        setter: arr => {
          let temper = arr.map(d => {
            return {
              ...d,
              sortKey: DVCalculator(d).cargos,
              supportKey: DVCalculator(d).number_of_orders > 0,
              lastSortKey: d.name,
            };
          });
          temper = orderby(temper, ['supportKey', 'sortKey', 'lastSortKey'], ['desc', 'asc', 'asc']);

          setOrderedDriverData(temper);
          reloadTimeline();
        },
      },
    ],
    []
  );

  function alreadyHasClipboard() {
    return callbackConstructor(null, {
      icon: 'err',
      content: (
        <Stack spacing={10}>
          <Text styleName="subheadline2" color="RG02">
            잘라낸 주문을 붙여넣은 후, 다시 시도해주세요
          </Text>
        </Stack>
      ),
    });
  }
  // sortBy 가 바뀔 때 마다 해당 contents 의 setter 실행
  useEffect(() => {
    const content = sortContents.find(d => d.value === sortBy);
    if (content) content.setter(driverData);
  }, [driverData, sortBy, sortContents]);

  return (
    <WrapperStack>
      <Stack
        name="header"
        justify="space-between"
        direction="row"
        sx={{ paddingRight: '24px', marginBottom: '12px' }}
        spacing={25}
      >
        <Stack name="driver-ordering-and-mode" spacing={12} direction="row">
          <Selector
            displayKey={sortBy}
            width={123}
            gap={12}
            extension={14}
            onClick={(e, v) => {
              setSortBy(v.value);
            }}
            options={sortContents}
          />

          <Stack spacing={6} direction="row" sx={{ width: 'fit-content' }}>
            <Stack spacing={6} direction="row" sx={{ width: 'max-content' }}>
              <Text styleName="caption2">모드 선택</Text>

              <AltWrapper
                altInfo={`자동 최적화 모드는 루티의 최적화 엔진으로 배차 결과를 제공합니다.\n임의 순서 변경 모드는 주문 순서를 사용자가 임의로 변경 가능하며,\n자동 최적화결과를 제공하지 않습니다.`}
                anchorWeight={{
                  top: -40,
                }}
              >
                <IcInfo width={14} height={14} fill={theme.colors.RG05} style={{ cursor: 'pointer' }} />
              </AltWrapper>
            </Stack>

            <Stack direction={'row'} sx={{ width: 194, borderRadius: 4 }}>
              {modContents.map(d => {
                return (
                  <ModQueueSelector
                    key={d.key}
                    selected={d.value === mod}
                    // disabled={d.value === 'auto'}
                    onClick={() => {
                      setMod(d.value);
                    }}
                    len={2}
                    mod={d.value}
                  >
                    {d.key}
                  </ModQueueSelector>
                );
              })}
            </Stack>
            
          </Stack>
        </Stack>
        <Stack name="timeline-controller" spacing={9} direction="row" justify="end" sx={{ width: 'fit-content' }}>
          <Stack name="action-controller" spacing={6} direction="row" sx={{ width: 'max-content' }}>
            <Button
              variant={'seventh'}
              disabled={!(history.depth > 1)}
              type={'button'}
              styleName="caption1"
              color="RG03"
              width={24}
              height={24}
              onClick={() => {
                if (history.depth > 1) {
                  if (clipboard) {
                    alreadyHasClipboard();
                    return;
                  }
                  restoreFromHistory(history.stack[history.depth - 2]);
                  setHistory({
                    stack: [...history.stack],
                    depth: history.depth - 1,
                  });
                }
              }}
            >
              <ReturnLeft fill={history.depth > 1 ? theme.colors.RG03 : theme.colors.RG05} />
            </Button>
            <Button
              variant={'seventh'}
              disabled={!(history.depth < history.stack.length)}
              type={'button'}
              styleName="caption1"
              color="RG03"
              width={24}
              height={24}
              onClick={() => {
                if (history.depth < history.stack.length) {
                  if (clipboard) {
                    alreadyHasClipboard();
                    return;
                  }
                  restoreFromHistory(history.stack[history.depth]);
                  setHistory({
                    stack: [...history.stack],
                    depth: history.depth + 1,
                  });
                }
              }}
            >
              <ReturnRight fill={history.depth < history.stack.length ? theme.colors.RG03 : theme.colors.RG05} />
            </Button>
            <Button
              variant={'seventh'}
              type={'button'}
              disabled={history.stack.length === 1}
              width={50}
              height={24}
              onClick={e => {
                if (clipboard) {
                  alreadyHasClipboard();
                  return;
                }
                resetHistory(e, () => {
                  setSelectedCell([]);
                  setSelectedCellGroup('');
                  setSelectedDriver(DriverIdUnSelected);
                  restoreFromHistory(history.stack[0]);
                  setHistory({
                    stack: [history.stack[0]],
                    depth: 1,
                  });
                });
              }}
            >
              <Text styleName="caption1" color="RG03">
                초기화
              </Text>
            </Button>
          </Stack>

          <Selector
            displayKey={multiplier}
            width={95}
            gap={10}
            options={multiplierOptions}
            onClick={(e, v) => {
              setMultiplier(v.value);
            }}
          />

          <Button
            variant={'seventh'}
            type={'button'}
            styleName="caption1"
            color="RG03"
            sx={{ padding: 4 }}
            onClick={e => {
              setMaxmized(!maximized);
            }}
          >
            {maximized ? <IcNarrow width={16} height={16} /> : <IcWide width={16} height={16} />}
          </Button>
        </Stack>
      </Stack>
      <div style={{ width: '100%', paddingRight: '24px', display: 'flex' }}>
        <HeaderWrapper style={{ width: '414px' }}>
          <Header style={{ padding: '10px' }}>
            <Text styleName={'caption1'}>드라이버 요약 정보</Text>
          </Header>
        </HeaderWrapper>
        <HeaderWrapper style={{ flex: 1, overflow: 'hidden' }}>
          <Header ref={headerRef} onWheel={onWheel} onScroll={onScrollHeader}>
            {new Array((46 + 24) * multiplier).fill(0).map((x, i) => {
              let baseDate = dayjs(`2000-01-01 0${BASE_HOUR}:00:00`);
              let targetDate = dayjs(`2000-01-01 0${BASE_HOUR}:00:00`).add((30 / multiplier) * i, 'minute');
              let workStartAt = 22;
              orderedDriverData
                .filter(x => x.driverId)
                .forEach(x => {
                  let d = dayjs(`2000-01-01 ${x.workStartTime}:00`);
                  if (workStartAt > d.hour()) {
                    workStartAt = d.hour();
                  }
                });
              let isSkipped = targetDate.hour() < workStartAt;

              if (targetDate.date() > baseDate.date()) isSkipped = false;
              if (isSkipped) return null;
              return (
                <HourWrapper key={`${i}-time-wrapper`}>
                  <Text styleName="caption1" color="RG02">
                    {targetDate.format('HH : mm')}
                  </Text>
                  <HourLiner />
                </HourWrapper>
              );
            })}
          </Header>
          <HeaderControlBtnL
            onClick={() => {
              scrollToPosition(headerRef.current.scrollLeft - 6 * 30);
            }}
          />
          <HeaderControlBtnR
            onClick={() => {
              scrollToPosition(headerRef.current.scrollLeft + 6 * 30);
            }}
          />
        </HeaderWrapper>
      </div>
      <ComponentCombiner ref={componentCombinerRef}>
        <DriverSummaryInformationComponent
          driverData={orderedDriverData}
          timelineData={timelineData}
          selectedDriver={selectedDriver}
          setSelectedDriver={(driverId: number) => {
            setSelectedCellGroup('');
            setSelectedCell([]);
            setSelectedDriver(driverId);
          }}
          onMouseUp={(driverId: number) => {
            setLastDragEndedDriver(driverId);
          }}
        />
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={() => {
            setLastDragEndedDriver(-1);
          }}
        >
          <TimeLineWrapper>
            <Columns>
              {orderedDriverData
                .filter(x => x.driverId)
                .map((column: IEqualOptimizeResponsesDriver, index: number) => {
                  let columnId = `driver-${column.driverId ?? 'unbatched'}`;
                  return (
                    <Column
                      isUnbatched={false}
                      key={columnId}
                      onWheel={onWheel}
                      isSelected={column.driverId === selectedDriver}
                    >
                      <ColumnLiner />
                      <ColumnInfoWrapper isUnbatched={false} isSelected={column.driverId === selectedDriver}>
                        {timelineData.columns[columnId].properties.editedItems.length > 0 ? (
                          <EditedCell
                            edited={timelineData.columns[columnId].properties.editedItems}
                            isSelected={selectedCellGroup === columnId}
                            onClick={() => {
                              if (selectedCellGroup === columnId) {
                                setSelectedCellGroup('');
                                setSelectedCell([]);
                              } else {
                                setSelectedCellGroup(columnId);
                              }
                            }}
                          />
                        ) : timelineData.columns[columnId].properties.sortChanged ? (
                          <EditedCell edited={[]} />
                        ) : null}
                      </ColumnInfoWrapper>
                      <ItemList
                        column={timelineData.columns[columnId]}
                        index={index}
                        width={timelineWidth}
                        listGlobRef={listGlobRef}
                        headerRef={headerRef}
                        fnShowOrderDetail={fnShowOrderDetail}
                        fnSaveToClipboard={fnSaveToClipboard}
                        fnLoadFromClipboard={fnLoadFromClipboard}
                        fnOnCellClicked={fnOnCellClicked}
                        selectedCell={selectedCell}
                        clipboard={clipboard}
                        mod={mod}
                        startEndChipSelected={startEndChipSelected}
                        setStartEndChipSelected={setStartEndChipSelected}
                      />
                    </Column>
                  );
                })}
            </Columns>
            <Columns isUnbatched={true}>
              {orderedDriverData
                .filter(x => !x.driverId)
                .map((column: IEqualOptimizeResponsesDriver, index: number) => {
                  let columnId = `driver-${column.driverId ?? 'unbatched'}`;
                  return (
                    <Column
                      isUnbatched={true}
                      isSelected={false}
                      key={columnId}
                      onWheel={onWheelUnbatched}
                      onMouseUp={() => {
                        setLastDragEndedDriver(-10);
                      }}
                    >
                      <ColumnInfoWrapper isUnbatched={true} isSelected={false}>
                        {timelineData.columns[columnId].properties.editedItems.length > 0 ? (
                          <EditedCell
                            edited={timelineData.columns[columnId].properties.editedItems}
                            isSelected={selectedCellGroup === columnId}
                            onClick={() => {
                              if (selectedCellGroup === columnId) {
                                setSelectedCellGroup('');
                                setSelectedCell([]);
                              } else setSelectedCellGroup(columnId);
                            }}
                          />
                        ) : timelineData.columns[columnId].properties.sortChanged ? (
                          <EditedCell edited={[]} />
                        ) : null}
                      </ColumnInfoWrapper>
                      <ItemList
                        column={timelineData.columns[columnId]}
                        index={index}
                        width={timelineWidth}
                        listGlobRef={mibaechaListGlobRef}
                        headerRef={headerRef}
                        fnShowOrderDetail={fnShowOrderDetail}
                        fnSaveToClipboard={fnSaveToClipboard}
                        fnLoadFromClipboard={fnLoadFromClipboard}
                        fnOnCellClicked={fnOnCellClicked}
                        selectedCell={selectedCell}
                        clipboard={clipboard}
                        mod={mod}
                        startEndChipSelected={startEndChipSelected}
                        setStartEndChipSelected={setStartEndChipSelected}
                      />
                    </Column>
                  );
                })}
            </Columns>
          </TimeLineWrapper>
        </DragDropContext>
      </ComponentCombiner>
    </WrapperStack>
  );
}

const WrapperStack = styled(Stack)`
  height: 100%;
`;
const ComponentCombiner = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  ${({ theme }) => theme.modalScrollStyle.vertical}
`;

const ModQueueSelector = styled(QueueSelectorContainer)<{ mod: string; disabled?: boolean }>`
  flex-grow: 0;

  height: 24px;
  padding: 2px 8px;

  ${({ mod, selected, disabled, theme }) => {
    const modMapper: { [key: string]: string } = {
      auto: 'RC10',
      manual: 'RC06',
    };

    return {
      color: theme.colors[disabled ? 'RG05' : selected ? 'RG00' : 'RG04'],
      background: theme.colors[selected ? modMapper[mod] : 'RG07'],
      border: `1px solid ${theme.colors.RG01_1}`,

      cursor: disabled ? 'not-allowed' : 'pointer',
    };
  }}

  ${({ theme }) => theme.fontStyle.caption1}
`;

export const TimeLineWrapper = styled.div`
  height: 100%;
  min-width: 0;
  flex: 1;
  position: relative;
`;

export const HeaderWrapper = styled.div`
  z-index: 4;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  padding: 0 40px;
  background: #f0f3f9;
  height: 48px;
  box-sizing: border-box;
  border-top: 1px solid #d8ddea;
  border-bottom: 1px solid #d8ddea;
  overflow: scroll;
  user-select: none;
  ::-webkit-scrollbar {
    display: none;
  }
  overscroll-behavior: none;
`;

const HeaderControlBtnR = styled(ArrowRight)`
  position: absolute;
  right: 0;
  background: #f0f3f9;
  z-index: 1;
  top: 1px;
  height: 46px;
  cursor: pointer;
`;

const HeaderControlBtnL = styled(ArrowLeft)`
  position: absolute;
  left: 0;
  top: 1px;
  height: 46px;
  background: #f0f3f9;
  z-index: 1;
  cursor: pointer;
`;

const HourWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  min-width: 48px;
  margin-right: 132px;
`;

const HourLiner = styled.div`
  position: absolute;
  height: 10px;
  width: 1px;
  background: #d8ddea;
  bottom: 0;
`;

export const ColumnInfoWrapper = styled.div<{ isUnbatched: boolean; isSelected: boolean }>`
  position: absolute;
  height: 100%;
  width: 40px;
  border-right: 1px solid #d8ddea;
  z-index: 2;
  background: #fff;

  ${({ isUnbatched, theme }) => isUnbatched && { background: theme.colors.RG08 }};

  ::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    background: ${({ theme, isSelected }) => isSelected && theme.colors.RC03_1};
  }
`;

export const Columns = styled.div<{ isUnbatched?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  ${({ isUnbatched }) =>
    isUnbatched && {
      position: 'sticky',
      bottom: '0px',
      zIndex: 3,
      // borderTop: '1px solid #d8ddea',
    }};
`;

export const ColumnLiner = styled.div`
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
  height: 2px;
  background: #d8ddea;
`;

export const Column = styled.div<{ isUnbatched: boolean; isSelected: boolean }>`
  position: relative;
  height: 54px;
  border-bottom: 1px solid #d8ddea;
  box-sizing: border-box;

  ${({ isUnbatched, theme }) =>
    isUnbatched && {
      background: theme.colors.RG08,
    }};
  ${({ isSelected, theme }) =>
    isSelected && {
      background: theme.colors.RC03_1,
    }};
  :hover {
    background: rgba(118, 157, 255, 0.1);
    ${({ isUnbatched }) =>
      isUnbatched && {
        background: 'rgb(235, 240, 251)',
      }};
  }
  :hover ${ColumnLiner} {
    background: rgba(118, 157, 255, 0.3);
  }

  :hover .waitingborder::after {
    background: rgba(118, 157, 255, 0.1);
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `.waitingborder::after {
      background: rgba(118, 157, 255, 0.1);
    }`};
`;

export default Timeline;
