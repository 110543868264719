import { MouseEvent } from 'react';
import { TActionKeys } from 'constants/types';
import { FOOTER_PATH } from 'Layout/elements/constant';
import { useNavigate } from 'react-router-dom';

type TDevice = 'mobile' | 'pc';
export const useLandingActions = ({ device = 'pc' }: { device?: TDevice }) => {
  const navigate = useNavigate();

  const onClicks: {
    [key in TActionKeys]: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  } = {
    'free-trial': e => {
      if (device === 'mobile') return alert(`해당 기능은 PC에서만 이용하실 수 있습니다. PC에서 다시 시도해주세요.`);
      navigate('/signup');
    },
    contact: e => {
      window.open(FOOTER_PATH.question.path, '_blank');
    },
  };

  return {
    onClicks,
  };
};
