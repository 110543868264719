import Modal from 'components/Modal';
import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
interface ILoadingModal {
  isLoading: boolean;
  text?: string | string[];
}

export const LoadingModal = (props: ILoadingModal) => {
  const { isLoading, text } = props;

  const content = Array.isArray(text) ? (
    <Stack sx={{ gap: '8px 0px' }}>
      {text.map((content, index: number) => {
        return (
          <Text key={`loading_alert_${index}`} styleName="body2" color="RG02">
            {content}
          </Text>
        );
      })}
    </Stack>
  ) : (
    text
  );

  return (
    <Modal
      zIndex={289}
      isModalOpen={isLoading}
      padding={0}
      loadingModalsx={{ margin: '35px auto' }}
      setIsModalOpen={() => {}}
      width={504}
      isLoadingModal={true}
      height={'100%'}
      plain
      ds="strong"
      loadingPlaceholder={text && content}
    ></Modal>
  );
};
