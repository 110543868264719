import styled from 'styled-components';

export const Alt = styled.pre`
  position: absolute;

  text-align: start;
  padding: 6px 10px;
  border-radius: 2px;

  pointer-events: none;
  z-index: 5;

  ${({ theme }) => ({
    color: theme.colors.RC02,
    background: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}

  ${({ theme }) => theme.fontStyle.caption2}
`;
