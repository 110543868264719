import { Stack } from 'components/Stack';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ReactComponent as IcCheck } from 'constants/icon/ic_check.svg';
import { ReactComponent as IcClose } from 'constants/icon/icon_cancle.svg';
import { useGNBStore, useTutorialStore } from 'store';

const Progressbar = ({
  chapters,
  current,
  setCurrent,
  success,
  setExtended,
}: {
  chapters: TChapters;
  current: number;
  setCurrent: Dispatch<SetStateAction<number>>;
  success: Array<number>;
  setExtended: Dispatch<SetStateAction<boolean>>;
}) => {
  const { TUTORIALISOPEN, SETTUTORIALISOPEN } = useTutorialStore();
  const { setShowTutorialTooltip } = useGNBStore();

  return (
    <ProgressContainer spacing={10}>
      <Progress spacing={10}>
        {chapters.map((chapter, index) => {
          const active = index === current;
          console.log(success.includes(index));
          return (
            <Item
              key={index}
              direction="row"
              spacing={6}
              active={active}
              success={success.includes(index)}
              onClick={() => {
                setCurrent(index);
                setExtended(true);
              }}
            >
              <div className="index">{success.includes(index) ? <IcCheck /> : index + 1}</div>
              {chapter.name}
            </Item>
          );
        })}
      </Progress>
      <CloseButton
        onClick={() => {
          SETTUTORIALISOPEN(false);
          setShowTutorialTooltip(true);
        }}
      >
        <IcClose width={9} height={9} />
      </CloseButton>
    </ProgressContainer>
  );
};

export default Progressbar;

const ProgressContainer = styled(Stack)`
  position: absolute;
  top: 72px;

  flex-direction: row;

  width: 100%;
  justify-content: center;

  z-index: 999;

  pointer-events: none;
`;

const Progress = styled(Stack)`
  flex-direction: row;

  width: max-content;

  padding: 12px;
  border-radius: 32px;

  box-shadow: 0px 6px 12px 0px #101e731a;

  pointer-events: all;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    border: `2px solid ${theme.colors.RC03}`,
  })}
`;

const Item = styled(Stack)<{ active: boolean; success: boolean }>`
  flex-wrap: nowrap;
  white-space: nowrap;

  cursor: pointer;

  ${({ theme, active, success }) => ({
    color: theme.colors[active ? 'RC02' : success ? 'RC10' : 'RG04'],
  })}
  .index {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    border-radius: 15px;

    ${({ theme, active, success }) => ({
      color: theme.colors.RG00,
      backgroundColor: theme.colors[active ? 'RC02' : success ? 'RC10' : 'RG04'],

      '& > svg': {
        width: 14,
        height: 14,
        '& > path': {
          stroke: theme.colors.RG00,
        },
      },
    })}
  }

  ${({ theme }) => theme.fontStyle.caption1}
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border-radius: 10px;

  cursor: pointer;

  pointer-events: all;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;
