import { API } from '../../../../api/ky';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UNSUBSCRIBE_QUERIES } from './unsubscribe.queries';
import { FetchGetPaymentMyResponse } from '../useGetPaymentMy';

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchUnsubscribeCancel>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof UNSUBSCRIBE_QUERIES.UNSUBSCRIBE_CANCEL>;
};

const fetchUnsubscribeCancel = async ({
  queryKey: [{ endPoint }],
}: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) => await API.put(endPoint).json<FetchGetPaymentMyResponse>();

const useUnsubscribeCancel = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    UNSUBSCRIBE_QUERIES.UNSUBSCRIBE_CANCEL(),
    fetchUnsubscribeCancel,
    options
  );

export { useUnsubscribeCancel };
