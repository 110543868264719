import { UpgradeGuideModal } from 'components/Modal/warning/paymentPlan/UpgradeGuideModal';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcArrowRight } from 'constants/icon/ic_arrowright.svg';

import { ReactComponent as IcSetting } from 'constants/icon/ic_setting.svg';

import theme from 'constants/theme';
import type { TS3 } from 'constants/types';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { useSetting } from 'hooks/store/useSetting';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { TSettingAttrs } from 'types/setting/union';

type TRequired = {
  isFolded: boolean;
};
type TSectionProps = {
  label: string;
  items: Array<TNavContent>;
};
type TNavContent = {
  label: string;
  key: TSettingAttrs;
  disabled?: boolean;
};
const navWidth: number = 200;
const btnIconWidth: number = 24;

const SettingSection = ({
  label,
  items,
  current,
  setCurrent,
}: {
  current: TSettingAttrs;
  setCurrent: TS3<TSettingAttrs>;
} & TSectionProps) => {
  const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);

  const { data: paymentMyData } = useGetPaymentMy({
    refetchOnWindowFocus: true,
  });

  return (
    <Stack align="start" spacing={10}>
      <Text styleName="caption1" color="RG04">
        {label}
      </Text>
      <Stack spacing={6} align="start">
        {items.map(item => {
          return (
            <NavItem
              isActive={item.key === current}
              disabled={item.disabled}
              onClick={() => {
                if (item.disabled) return;

                if (
                  (item.key === 'app' || item.key === 'masterOrder' || item.key === 'multiSkill') &&
                  paymentMyData?.name === 'Free'
                ) {
                  setIsOpenUpgradeGuideModal(true);

                  return;
                }

                setCurrent(item.key);
              }}
            >
              {item.label}
            </NavItem>
          );
        })}
      </Stack>
      <UpgradeGuideModal
        isOpen={isOpenUpgradeGuideModal}
        setIsOpen={setIsOpenUpgradeGuideModal}
        upgradeGuidePlan={['Lite', 'Standard', 'Pro', 'Enterprise']}
        text="요금제로 업그레이드 하시면 해당 기능을 사용하실 수 있습니다."
      />
    </Stack>
  );
};

const SettingNavigation = ({ current, setCurrent }: { current: TSettingAttrs; setCurrent: TS3<TSettingAttrs> }) => {
  // const [isFolded, setIsFolded] = useState<boolean>(false);

  const [pricing_id, setPricing_id] = useState<number>(1);

  const { isSuccess, data } = useGetPaymentMy({
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (isSuccess && data) {
      setPricing_id(data.pricingId);
    }
  }, [isSuccess, data]);

  const nav_contents = useMemo<{
    [key: string]: TSectionProps;
  }>(() => {
    return {
      organization: {
        label: '조직 관리',
        items: [
          { label: '팀 관리', key: 'team' },
          { label: '매니저 관리', key: 'manager' },
        ],
      },
      resource: {
        label: '리소스 관리',
        items:
          pricing_id === 2
            ? [
                { label: '드라이버 관리', key: 'driver' },
                { label: '드라이버 앱 관리', key: 'app' },
              ]
            : [
                { label: '드라이버 관리', key: 'driver' },
                { label: '권역 관리', key: 'area' },
                { label: '드라이버 앱 관리', key: 'app' },
              ],
      },
      masterData: {
        label: '기준 정보 관리',
        items: [
          { label: '주문지 관리', key: 'masterOrder' },
          { label: '특수 조건 관리', key: 'multiSkill' },
        ],
      },
    };
  }, [pricing_id]);

  const { isFolded, setIsFolded } = useSetting();
  return (
    <NavWrapper {...{ isFolded }}>
      <NavControlButton
        onClick={() => {
          setIsFolded(!isFolded);
        }}
        {...{ isFolded }}
      >
        <IcArrowRight
          width={10}
          style={{
            rotate: isFolded ? '0deg' : '180deg',
          }}
        />
      </NavControlButton>

      {isFolded ? null : (
        //main content here
        <Stack spacing={32} useVerticalScroll sx={{ height: '100%', width: 'calc(100% + 24px)' }}>
          <Stack direction="row" spacing={6}>
            <IcSetting width={16} height={16} fill={theme.colors.RG06} />
            <Text styleName="subheadline1" color="RG03">
              설정
            </Text>
          </Stack>
          {Object.keys(nav_contents).map(key => {
            const { label, items } = nav_contents[key];
            return <SettingSection {...{ label, items, current, setCurrent }} />;
          })}
        </Stack>
      )}
    </NavWrapper>
  );
};

export default SettingNavigation;

const NavWrapper = styled.div<TRequired>`
  position: relative;
  flex-shrink: 0;

  height: 100%;

  ${({ theme, isFolded }) => ({
    width: isFolded ? `24px` : `${navWidth}px`,
    padding: isFolded ? '' : '40px 20px',

    borderLeft: `1px solid ${theme.colors.RG06}`,
    borderRight: `1px solid ${theme.colors.RG06}`,
  })};
`;

const NavControlButton = styled.button<TRequired>`
  position: absolute;
  z-index: 8;

  top: 38px;

  width: ${btnIconWidth}px;
  height: ${btnIconWidth}px;

  ${({ theme, isFolded }) => ({
    left: isFolded ? `${btnIconWidth / 2}px` : `${navWidth - 12}px`,

    background: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
  border-radius: ${btnIconWidth / 2}px;

  cursor: pointer;
`;

const NavItem = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
}>`
  ${({ theme }) => theme.fontStyle.caption2}
  width: calc(100% - 24px);
  height: 100%;

  padding: 6px 8px;
  border-radius: 8px;

  color: ${({ theme }) => theme.colors.RG02};
  background-color: transparent;

  ${({ isActive, theme }) => isActive && theme.fontStyle.caption1};

  ${({ isActive, theme }) =>
    isActive && {
      color: theme.colors.RC02,
      backgroundColor: theme.colors.RC03_1,
    }}
  &.active {
    ${({ theme }) => theme.fontStyle.caption1}
    color: ${({ theme }) => theme.colors.RC02};
    background-color: ${({ theme }) => theme.colors.RC03_1};
  }

  cursor: pointer;

  ${({ disabled, theme }) =>
    disabled && {
      cursor: 'not-allowed',
      color: theme.colors.RG04,
    }}
`;
