import { VALID_DOMAIN } from 'constants/valideDomain';

export const validateLoginAccount = (email: string): boolean => {
  const regex = /^[0-9A-Za-z]([-_.]?[0-9A-Za-z])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (regex.test(email) === false) return false;

  if (email.indexOf('..') !== -1) return false;
  const [domain] = email.split('.').slice(-1);

  return VALID_DOMAIN.includes(domain.toUpperCase());
};
