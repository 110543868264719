import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;

  height: calc(100vh - 72px);

  overflow: hidden;

  /* ${({ theme }) => theme.scrollStyle.both} */
`;
