import { Dispatch, SetStateAction, ReactElement } from 'react';
import styled from 'styled-components';

interface ClipboardProps {
  orderId: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  fnShowOrderDetail?: (orderId: number) => void;
  fnSaveToClipboard?: (orderId: number) => void;
}

interface ShowTableViewProps {
  setVisibleId: Dispatch<SetStateAction<number>>;
  fnShowTableView: () => void;
}

export default function ({ ...props }: ClipboardProps): ReactElement {
  return (
    <>
      <Hider
        onMouseDown={() => {
          props.setIsOpen(false);
        }}
      />
      <Container>
        {props.fnSaveToClipboard && (
          <ClickableSlot
            onClick={() => {
              props.setIsOpen(false);
              props.fnSaveToClipboard?.(props.orderId);
            }}
          >
            주문 잘라내기
          </ClickableSlot>
        )}
        <ClickableSlot
          onClick={() => {
            props.setIsOpen(false);
            props.fnShowOrderDetail?.(props.orderId);
          }}
        >
          주문 상세정보
        </ClickableSlot>
      </Container>
    </>
  );
}

export function ShowTableView({ ...props }: ShowTableViewProps): ReactElement {
  return (
    <>
      <Hider
        onMouseDown={() => {
          props.setVisibleId(0);
        }}
      />
      <Container>
        <ClickableSlot
          onClick={() => {
            props.setVisibleId(0);
            props.fnShowTableView();
          }}
        >
          테이블 뷰로 보기
        </ClickableSlot>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 12px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  padding: 16px 0;

  pointer-events: all;
  ${({ theme }) => ({
    background: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;

const Hider = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 200vw;
  transform: translate(-50%, -50%);
  height: 200vh;
  pointer-events: all;
  z-index: -100;
`;
const ClickableSlot = styled.div`
  height: 32px;
  display: flex;

  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  ${({ theme }) => ({
    color: theme.colors.RG02,
    ':hover': {
      fontWeight: 700,
      backgroundColor: theme.colors.RC03_1,
    },
  })}

  ${({ theme }) => theme.fontStyle.caption2};

  :hover {
    ${({ theme }) => theme.fontStyle.caption1};
  }
`;
