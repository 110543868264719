import { baseAPI } from 'api/instance';
import { apiErrorHandler } from 'api';

export const postRequestVerifyCode = async (props: {
  loginAccount: string;
}): Promise<{ requestCount: number } | TErrorResponse> => {
  try {
    const URL = '/auth/send/code';

    const response = await baseAPI({ url: URL, method: 'POST', data: props });
    const { data } = response;
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getValidateVerifyCode = async (props: {
  loginAccount: string;
  code: string;
}): Promise<void | TErrorResponse> => {
  try {
    const URL = `/auth/check/code?loginAccount=${props.loginAccount}&code=${props.code}`;
    await baseAPI({ url: URL, method: 'GET' });
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getValidateLoginAccount = async (props: {
  loginAccount: string;
}): Promise<{ hasAccount: boolean } | TErrorResponse> => {
  try {
    const URL = `/auth/check/account?loginAccount=${props.loginAccount}`;
    const { data } = await baseAPI({ url: URL, method: 'GET' });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getCheckCompananyName = async (props: {
  name: string;
}): Promise<{ hasCompany: boolean } | TErrorResponse> => {
  try {
    const { name } = props;
    const URL = `/company/check/name?name=${name}`;
    const { data } = await baseAPI({ url: URL, method: 'GET' });

    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getValidateBusinessNumber = async (props: {
  businessNumber: string;
}): Promise<{ isExisting: Boolean } | TErrorResponse> => {
  try {
    const { businessNumber } = props;
    const URL = `/company/validate/businessNumber?businessNumber=${businessNumber}`;
    const { data } = await baseAPI({ url: URL, method: 'GET' });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getCheckBusinessNumber = async (props: {
  businessNumber: string;
}): Promise<TGetCheckBusinessNumberResponse | TErrorResponse> => {
  try {
    const { businessNumber } = props;
    const URL = `/company/check/businessNumber?businessNumber=${businessNumber}`;
    const { data } = await baseAPI({ url: URL, method: 'GET' });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const postSignUp = async (props: TSignUpForm): Promise<TSignUpResponse | TErrorResponse> => {
  const URL = '/auth/signup/owner';
  try {
    const { data } = await baseAPI({ url: URL, method: 'POST', data: { ...props } });
    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const postPaymentTrial = async (): Promise<void | TErrorResponse> => {
  const URL = '/payment/trial';
  const token = localStorage.getItem('accessToken') || '';
  try {
    await baseAPI({ url: URL, method: 'POST', headers: { token } });
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getRequestIndustryType = async (): Promise<
  { industryTypeId: number; name: string; description?: string }[] | TErrorResponse
> => {
  try {
    const URL = `/company/industryType/list`;
    const { data } = await baseAPI({ url: URL, method: 'GET' });
    return data.data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const postSignUpManager = async (
  params: TManagerSignUpQuery
): Promise<{ loginAccount: string } | TErrorResponse> => {
  try {
    const { invitationKey, name, password } = params;
    const URL = `/member/invite/accept/${invitationKey}`;
    const { data } = await baseAPI({ url: URL, method: 'POST', data: { password, name } });

    return data;
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getCheckManagerId = async (invitationKey: string): Promise<void | TErrorResponse> => {
  try {
    const URL = `/member/invite/check/${invitationKey}`;
    await baseAPI({ url: URL, method: 'GET' });
  } catch (error) {
    return apiErrorHandler(error);
  }
};
