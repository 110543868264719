import type { UseFormSetError } from 'react-hook-form';

export const countLoginError = () => {
  const KEY = 'count';
  const preCount = localStorage.getItem(KEY);

  if (preCount === null) {
    return localStorage.setItem(KEY, '1');
  }
  localStorage.setItem(KEY, JSON.parse(preCount) + 1);
  return;
};

export const haveLoginErrorCount = () => {
  const signInErrorCount = localStorage.getItem('count');
  if (signInErrorCount === null) return false;
  return signInErrorCount;
};

export const signInError = (advice: string | undefined, setError: UseFormSetError<TSignInHookForm>) => {
  if (advice === undefined) return;
  if (advice.includes('UserInfo')) {
    return setError('loginAccount', { message: '존재하지 않는 아이디입니다.' });
  }
  if (advice.includes('password')) {
    return setError('password', { message: '잘못된 비밀번호 입니다.' });
  }
  if (advice.includes('authorized')) {
    return setError('loginAccount', { message: '가입 신청이 완료되어 관리자 확인 중인 아이디입니다.' });
  }
  return setError('loginAccount', { message: '관리자에게 문의해주세요.' });
};
