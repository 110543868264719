import { Fragment, useEffect, useState } from 'react';
import { IManualExcelRouteRequestResponse, IManualExcelRouteRequestResponseList } from 'constants/types';

import Modal from 'components/Modal';
import { Stack } from '../components/Stack';
import XlsxLoader from 'components/Modal/XlsxLoader';
import { BoxContainer } from 'components/BoxContainer';
import { Highlight, Text } from '../components/Typography';
import PageContentWrapper from 'components/PageContentWrapper';
import ManualRouteTable from 'components/Table/ManualRouteTable';
import ManualRoutingNav from 'components/SidebarLayout/ManualRoutingNav';
import { usePrompt } from 'hooks/usePrompt';
import { Button } from 'components/Button/legacy_index';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';
import QueryStatusModal from 'components/Modal/QueryStatusModal';

import { v4 as uuidv4 } from 'uuid';
import { useTracking } from '../hooks/store/useTracking';
import { ManualRoutingMap } from 'components/MapboxContainer/ManualRoutingMap';

const ManualRoutingPage = () => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(true, ['/manage/control/activated'], true);

  const [manualExcelValidatedData, setManualExcelValidatedData] = useState<Array<IManualExcelRouteRequestResponseList>>(
    []
  );

  const [targetId, setTargetId] = useState<number>(0);
  const [tabIsOpen, setTabIsOpen] = useState<boolean>(false);
  const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(false);
  const [xlsxUploadIsSuccess, setXlsxUploadIsSuccess] = useState<boolean>(false);
  const [selectedMarkerId, setSelectedMarkerId] = useState<string>('');
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('배차계획>수동 배차');
  }, [setTitle]);

  useEffect(() => {
    if (manualExcelValidatedData.length > 0) return;

    // setXlsxFormIsOpen(true);
  }, [manualExcelValidatedData]);

  useEffect(() => {
    setSelectedMarkerId('');
  }, [targetId]);

  return (
    <Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <ManualRoutingNav
          {...{ tabIsOpen, setTabIsOpen, setXlsxFormIsOpen, manualExcelValidatedData, targetId, setTargetId }}
        />

        <PageContentWrapper>
          <Stack
            useVerticalScroll
            align={'start'}
            sx={{
              maxWidth: `${tabIsOpen ? '1138px' : '0px'}`,
              minWidth: `${tabIsOpen ? '545px' : '0px'}`,

              margin: '48px 0',

              width: '100%',

              transition: 'all .2s',
            }}
          >
            {tabIsOpen && (
              <BoxContainer align={'start'} justify={'start'} sx={{ height: '100%', padding: '0 20px 0' }}>
                <Stack spacing={20} sx={{ height: '100%' }}>
                  <Stack name="header-area" spacing={40} align={'start'}>
                    <Text styleName={'title1'} color={'RG03'}>
                      {manualExcelValidatedData.find(d => d.vehicle.vehicleId === targetId)?.vehicle.licenseNumber}
                    </Text>
                  </Stack>

                  <Stack name="table-area">
                    <ManualRouteTable
                      data={manualExcelValidatedData}
                      {...{ setManualExcelValidatedData, targetId }}
                      onSelect={id => {
                        setSelectedMarkerId(id);
                      }}
                    />
                  </Stack>
                </Stack>
              </BoxContainer>
            )}
          </Stack>

          <Stack sx={{ minWidth: '1px', height: '100%', flex: 1 }}>
            <ManualRoutingMap
              selectedMarkerId={selectedMarkerId}
              orderList={
                manualExcelValidatedData.find(d => d.vehicle.vehicleId === targetId)?.orderList ??
                manualExcelValidatedData
                  .map(d => d.orderList)
                  .reduce((x, a) => {
                    return x.concat(a);
                  }, [])
              }
            />
          </Stack>
        </PageContentWrapper>
      </Stack>

      <Modal padding={24} width={503} isModalOpen={xlsxFormIsOpen} setIsModalOpen={setXlsxFormIsOpen}>
        <XlsxLoader
          scope="roouty_manual"
          {...{
            setXlsxFormIsOpen,
            registerCallbackFunction: data => {
              const res = data as IManualExcelRouteRequestResponse;
              // const vehicles = new Set();
              // res.rows.forEach((row: IMTemporaryExcelRows) => {
              //   vehicles.add(row.service_vehicle);
              // });

              // const data = new Map(
              //   Array.from(vehicles).map(vehicle => {
              //     return [
              //       vehicle,
              //       res.orders.filter(row => {
              //         return row.skill === vehicle;
              //       }),
              //     ];
              //   })
              // );

              // console.log(
              //   Array.from(data, ([name, value]) => ({
              //     vehicle: {
              //       // @ts-ignore
              //       vehicleId: value.find(v => v.skill === name)?.vehicleId,
              //       licenseNumber: name,
              //     },
              //     orderList: value,
              //   })),
              //   'aweofiefwaoiweioafiwaojefiojefw'
              // );
              // setManualExcelValidatedData(
              //   Array.from(data, ([name, value]) => ({
              //     vehicle: {
              //       // @ts-ignore
              //       vehicleId: value.find(v => v.skill === name)?.vehicleId,
              //       licenseNumber: name,
              //     },
              //     orderList: value.map(x => {
              //       let y = x;

              //       // @ts-ignore
              //       y.mId = uuidv4();
              //       return y;
              //     }),
              //   })) as any
              // );

              setManualExcelValidatedData(
                res.vehicleList.map(d => {
                  return {
                    ...d,
                    orderList: d.orderList.map(x => {
                      let y = x;
                      // @ts-ignore
                      y.mId = uuidv4();
                      return y;
                    }),
                  };
                })
              );
              setXlsxUploadIsSuccess(true);
            },
          }}
        />
      </Modal>

      {/* <QueryStatusModal
        autoClose={3000}
        isOpen={xlsxUploadIsSuccess}
        setIsOpen={setXlsxUploadIsSuccess}
        string="주문 목록을 업로드 완료하였습니다."
        status={'success'}
      /> */}

      <Modal plain isModalOpen={showPrompt} setIsModalOpen={() => {}} width={504} padding={24}>
        <Stack>
          <IconWarning width={44} height={44} style={{ marginTop: 20 }} />
          <Text
            styleName="body2"
            sx={{
              lineHeight: '32px',
              padding: '30px 0 40px',
            }}
          >
            배차를 하지 않고 나가시겠습니까?
            <br />
            이전으로 돌아가면 배차결과가 삭제됩니다.
            <br />
            <Highlight color="RC02">
              배차결과를 저장하고 싶다면,
              <br />
              좌측의 '배차 계획 저장' 버튼을 눌러주세요.
            </Highlight>
          </Text>
          <Stack direction="row" spacing={20}>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              styleName="body1"
              color="RG03"
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                cancelNavigation();
              }}
            >
              닫기
            </Button>
            <Button
              variant={'eighth'}
              type={'button'}
              fullWidth
              styleName="body1"
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                confirmNavigation();
              }}
            >
              확인
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Fragment>
  );
};

export default ManualRoutingPage;
