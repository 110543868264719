import styled from 'styled-components';
import arrowIcon from '../../constants/icon/ic_arrow_count_down.svg';
import { SelectProps } from '.';
import { Stack } from 'components/Stack/style';
import { ReactNode } from 'react';

interface LabelAreaProps {
  rightArea?: any;
  label?: string | ReactNode;
}

export const Input = styled.input<any>`
  ${({ theme, field, variant }) => {
    if (variant === 'third' || variant === 'second') return theme.fontStyle.caption2;
    else if (field) return theme.fontStyle.caption3;
    else return theme.fontStyle.subheadline3;
  }}

  ${({ theme, styleName }) => styleName && theme.fontStyle[styleName]}

  display: block;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: transparent;

  ${({ theme, height, hasError, field, xAxisPadding, variant, width, isPlaceholderDefault }) => {
    if (variant === 'second')
      return {
        height: height || '48px',

        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC02,
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        ':focus , :hover': {
          '::placeholder': { color: theme.colors.RC05 },
        },
        textAlign: 'center',
        backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,
        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',
      };
    else if (variant === 'third')
      return {
        height: height || '48px',
        caretColor: hasError ? theme.colors.RC04_2 : theme.colors.RC02,
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        '::placeholder': {
          color: hasError ? theme.colors.RC04 : isPlaceholderDefault ? theme.colors.RG02 : theme.colors.RG04,
        },
        ':placeholder-shown': {
          '::placeholder': {
            color: hasError ? theme.colors.RC04 : isPlaceholderDefault ? theme.colors.RG02 : theme.colors.RG04,
          },
        },

        textAlign: 'left',

        backgroundColor: hasError ? theme.colors.RC04_2 : theme.colors.RG00,
        border: `1px solid ${hasError ? theme.colors.RC04_2 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',
      };
    else if (variant === 'fourth')
      return {
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,
        '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG08,
        border: `1px solid ${field ? theme.colors.RG06 : hasError ? theme.colors.RC04 : theme.colors.RG07}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',

        ':disabled': {
          color: `${field && theme.colors.RG04}`,
          backgroundColor: `${field && theme.colors.RG07}`,
        },

        // input autofill 스타일 webkit-box-shadow 로 강제로 덮어씌움 더 좋은 방법 있으면 수정해주센요 애초에 이걸 갈아버려야되는데
        ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active': {
          backgroundColor: `${
            field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG08
          } !important`,
          '-webkit-box-shadow': `0 0 0 40px ${
            field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG08
          } inset !important`,
        },
      };
    else if (variant === 'fifth')
      return {
        width: width || '100%',
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RC05 },
        },
        ':placeholder-shown': {
          backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
          border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,

          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: field ? theme.colors.RG00 : hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${field ? theme.colors.RG07 : hasError ? theme.colors.RC04 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',

        ':disabled': {
          color: `${field && theme.colors.RG04}`,
          backgroundColor: `${field && theme.colors.RG07}`,
        },
      };
    else if (variant === 'useDisabled')
      return {
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RC05 },
        },
        ':placeholder-shown': {
          backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG08,
          border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG07}`,

          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : '0 16px',

        ':disabled': {
          color: theme.colors.RG04,
          backgroundColor: theme.colors.RG07,
        },
      };
    else
      return {
        height: height || '48px',
        color: hasError ? theme.colors.RC04 : theme.colors.RG02,
        caretColor: hasError ? theme.colors.RC04 : theme.colors.RC03,

        ':focus , :hover': {
          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RC05 },
        },
        ':placeholder-shown': {
          backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG08,
          border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG07}`,

          '::placeholder': { color: hasError ? theme.colors.RC04 : theme.colors.RG05 },
        },

        backgroundColor: field ? theme.colors.RG08 : hasError ? theme.colors.RC04_1 : theme.colors.RG00,
        border: `1px solid ${field ? theme.colors.RG06 : hasError ? theme.colors.RC04 : theme.colors.RG06}`,

        padding: xAxisPadding ? `0 ${xAxisPadding}px` : field ? '10px' : '0 16px',

        ':disabled': {
          color: `${field && theme.colors.RG04}`,
          backgroundColor: `${field && theme.colors.RG07}`,
        },
      };
  }}
  ${({ theme, hasError }) => ({
    backgroundColor: hasError && theme.colors.RC04_1,
    border: `1px solid ${hasError && theme.colors.RC04}`,
  })}
  :disabled {
    pointer-events: none;
  }

  :focus,
  :hover {
    ${({ theme, hasError }) => ({
      backgroundColor: hasError ? theme.colors.RC04_2 : theme.colors.RC03_2,
      border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RC03}`,
    })}
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputAdornment = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-radius: 8px;
  position: relative;

  svg {
    position: absolute;
    //   z-index: 1;
    margin-right: 10px;
  }
`;

// 재설정 링크 외 다른 바리에이션이 필요할 땐 삭제하고 node로 받아 사용하도록 합니다
export const RightArea = styled.div<{ contentType?: string }>`
  ${({ theme }) => theme.fontStyle.caption3}
  ${({ theme }) => ({ color: theme.colors.RC02 })}

  text-decoration: ${({ contentType }) => (contentType === 'string' ? 'underline' : 'auto')};
  cursor: pointer;
`;

export const Label = styled.label`
  ${({ theme }) => theme.fontStyle.caption3}

  color: ${({ theme }) => theme.colors.RG03};
`;

export const InputWrapper = styled(Stack)`
  align-items: flex-start;
  width: 100%;
  text-align: left;
`;

export const LabelArea = styled.div<LabelAreaProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ rightArea, label }) => (rightArea && label ? 'space-between' : label ? 'start' : 'end')};
`;

export const Select = styled.select<SelectProps>`
  ${({ theme }) => theme.fontStyle.caption3}
  ${({ theme, field, height }) =>
    field
      ? {
          height: height || '48px',
          display: 'block',
          width: '100%',
          padding: '10px',
          borderRadius: '8px',
          boxSizing: 'border-box',

          background: `url(${arrowIcon}) no-repeat 99% 50%`,
          appearance: 'none',
          backgroundColor: field ? theme.colors.RG08 : theme.colors.RG00,
          border: `1px solid ${field ? theme.colors.RG07 : theme.colors.RG06}`,
        }
      : {}}
`;

export const SelectBottomLabel = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;

  padding: 6px 20px 0;
`;
