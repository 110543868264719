import { AxiosError } from 'axios';
import { baseAPI } from 'api/instance';
import { apiErrorHandler } from 'api';

export const postSendVerifyLoginAccount = async (props: { loginAccount: string }): Promise<void | TErrorResponse> => {
  const { loginAccount } = props;
  const URL = `/auth/password-reset/${loginAccount}`;
  try {
    await baseAPI({ url: URL, method: 'POST', data: { ...props } });
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const putSendNewPassword = async (props: {
  urlKey: string;
  password: string;
}): Promise<void | TErrorResponse> => {
  const { urlKey } = props;
  const URL = `/member/password/change/${urlKey}`;

  const payload = Object.assign({}, { password: props.password });
  try {
    await baseAPI({ url: URL, method: 'PUT', data: payload });
  } catch (error) {
    return apiErrorHandler(error);
  }
};

export const getCheckUrlKey = async (urlKey: string) => {
  const URL = `/member/password/check/${urlKey}`;
  try {
    return await baseAPI({ url: URL, method: 'GET' });
  } catch (error) {
    if (error instanceof AxiosError) {
      const err = error.response?.data.error as TErrorResponse;
      if (err.advice === undefined) return;
      return err.advice;
    }
    console.log(error, 'error');
  }
};
