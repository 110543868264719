import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlideImg } from './style';
import { Text } from 'components/Typography';
import dayjs from 'dayjs';

export type TImageSwiper = { thumb: string; origin: string; date: string };

const ImageSwiper = ({ d, index }: { d: TImageSwiper; index: number }) => {
  return useMemo(() => {
    return (
      <Link
        to={{ pathname: '/imgViewer' }}
        onClick={() => localStorage.setItem('img_referrer', d.origin)}
        target="_blank"
      >
        <SwiperSlideImg src={d.thumb} alt={`PoD image${index}`} />
        <Text styleName="caption2" color="RG02" align="left">
          {dayjs(d.date).format('YYYY.MM.DD HH : mm')}
        </Text>
      </Link>
    );
  }, [d, index]);
};

export default ImageSwiper;
