import { date_set_range_template } from 'constants/commons';
import { IEqualOptimizeResponsesOnStore, IGetOrderList, TQueryStatusModalSetterProps, TS3 } from 'constants/types';
import dayjs from 'dayjs';
import type { TSettingAttrs } from 'types/setting/union';
import { create } from 'zustand';

interface StoreContentState {
  userGrade: 1 | 2 | 3 | 4;
  setUserGrade: (grade: 1 | 2 | 3 | 4) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  getOrderListProps: IGetOrderList;
  setGetOrderListProps: (data: Partial<IGetOrderList>) => void;
  resetGetOrderListProps: () => void;
  removeTemporaryOptimizeData: () => void;
  temporaryOptimizeData?: IEqualOptimizeResponsesOnStore;

  setTemporaryOptimizeData: ({
    key,
    name,
    originName,
    data,
    disable,
  }: Omit<IEqualOptimizeResponsesOnStore, 'genIndex'>) => void;
  loadTemporaryOptimizeData: ({ key }: Pick<IEqualOptimizeResponsesOnStore, 'key'>) => void;

  selectedOrderIds?: number[];
  setSelectedOrderIds: (ids: number[]) => void;
}

export const useStore = create<StoreContentState>(set => ({
  setSelectedOrderIds: (ids: number[]) => set({ selectedOrderIds: ids }),

  userGrade: (Number(window.localStorage.getItem('roleId')) as 1 | 2 | 3 | 4) ?? 3,
  setUserGrade: grade => set({ userGrade: grade }),

  isLoading: false,
  setIsLoading: isLoading => set({ isLoading: isLoading }),

  removeTemporaryOptimizeData: () => {
    set({ temporaryOptimizeData: undefined });
  },
  setTemporaryOptimizeData: ({ key, name, originName, data, routeOptions, disable = false }) => {
    let unBatched = data.driverList.filter(x => !x.driverId);
    if (unBatched.length === 0)
      data.driverList.push({
        driverId: null,
        name: null,
        orderList: [],
        workStartTime: '',
        hasLunch: false,
      });

    const item = JSON.parse(sessionStorage.getItem(`temp_${key}`)!);
    const sessions = Array.from({ length: sessionStorage.length }, (_, index) => {
      if (sessionStorage.key(index)?.startsWith('temp_')) {
        return true;
      } else return false;
    }).filter(d => d).length;

    let onStoreData: IEqualOptimizeResponsesOnStore = {
      key,
      name,
      originName,
      data,
      disable,
      genIndex: disable ? item.genIndex : sessions,

      routeOptions,
    };
    sessionStorage.setItem(`temp_${key}`, JSON.stringify(onStoreData));
  },

  loadTemporaryOptimizeData: ({ key }) => {
    let item = sessionStorage.getItem(`temp_${key}`);
    if (item) {
      let parsedItem: IEqualOptimizeResponsesOnStore = JSON.parse(item);
      set({ temporaryOptimizeData: parsedItem });
    }
  },

  getOrderListProps: {
    receivedDate: `${date_set_range_template.week.format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
  },

  setGetOrderListProps: data => {
    set({ getOrderListProps: data as any });
  },
  resetGetOrderListProps: () => {
    set({
      getOrderListProps: {
        receivedDate: `${date_set_range_template.week.format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`,
      },
    });
  },
}));

interface IAPISTORE {
  APIALERT: TQueryStatusModalSetterProps;
  SETAPIALERT: TS3<TQueryStatusModalSetterProps>;
  CLEARAPIALERT: () => void;
}

export const useAPIStore = create<IAPISTORE>(set => ({
  APIALERT: {
    open: false,
    props: {
      status: 'warning',
    },
  },
  SETAPIALERT: prop => {
    if (typeof prop === 'function')
      set(state => ({
        APIALERT: prop(state.APIALERT),
      }));
    else set({ APIALERT: prop });
  },
  CLEARAPIALERT: () => {
    set(state => ({
      APIALERT: {
        ...state.APIALERT,
        open: false,
      },
    }));
  },
}));

interface IGNBSTORE {
  SETTINGOPEN: boolean;
  SETSETTINGOPEN: TS3<boolean>;
  showFaqTooltip: boolean;
  setShowFaqTooltip: (prop: boolean) => void;
  showTutorialTooltip: boolean;
  setShowTutorialTooltip: (prop: boolean) => void;
}

export const useGNBStore = create<IGNBSTORE>((set, get) => ({
  SETTINGOPEN: false,
  SETSETTINGOPEN: prop => {
    if (typeof prop === 'function') set(state => ({ SETTINGOPEN: prop(state.SETTINGOPEN) }));
    else set({ SETTINGOPEN: prop });
  },
  showFaqTooltip: false,
  setShowFaqTooltip: prop => set({ showFaqTooltip: prop }),
  showTutorialTooltip: false,
  setShowTutorialTooltip: prop => set({ showTutorialTooltip: prop }),
}));

interface ISETTINGSTORE {
  CURRENT: TSettingAttrs;
  SETCURRENT: TS3<TSettingAttrs>;

  isFatalCorrectionsOnPost: boolean;
  setIsFatalCorrectionsOnPost: TS3<boolean>;
}

export const useSettingStore = create<ISETTINGSTORE>((set, get) => ({
  CURRENT: 'team',
  SETCURRENT: prop => {
    if (typeof prop === 'function') set(state => ({ CURRENT: prop(state.CURRENT) }));
    else set({ CURRENT: prop });
  },
  isFatalCorrectionsOnPost: false,
  setIsFatalCorrectionsOnPost: prop => {
    if (typeof prop === 'function') set(state => ({ isFatalCorrectionsOnPost: prop(state.isFatalCorrectionsOnPost) }));
    else set({ isFatalCorrectionsOnPost: prop });
  },
}));

interface ITUTORIALSTORE {
  TUTORIALISOPEN: boolean;
  SETTUTORIALISOPEN: TS3<boolean>;
}

export const useTutorialStore = create<ITUTORIALSTORE>(set => ({
  TUTORIALISOPEN: false,
  SETTUTORIALISOPEN: prop => {
    if (typeof prop === 'function')
      set(state => ({
        TUTORIALISOPEN: prop(state.TUTORIALISOPEN),
      }));
    else set({ TUTORIALISOPEN: prop });
  },
}));
