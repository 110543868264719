import { ChangeEvent, CSSProperties, KeyboardEvent, useState } from 'react';

import type {
  FieldError,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

import { Text } from 'components/Typography';
import { Input } from 'components/Input';
import { InputLabel } from 'auth/common/InputLabel';

import { isFalsy } from 'util/isFalsy';
import { translateKoToEng } from 'util/translateKoToEn';
import { validatePassword } from 'auth/util';
import * as CS from 'auth/style';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';

interface IInputPassword {
  register: UseFormRegister<any>;
  setError?: UseFormSetError<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  clearErrors?: UseFormClearErrors<any>;
  placeholder?: string;

  sx?: CSSProperties;
  label: string;
  required?: boolean;
  useValidation?: boolean;
  password: { message: FieldError | undefined };
  onKeyUp?: Function;
  disableAutoFill?: boolean;
  callback?: Function;
}

export const InputPassword = (props: IInputPassword) => {
  const {
    register,
    placeholder,
    disableAutoFill = false,
    sx,
    required = false,
    label,
    setError,
    clearErrors,
    getValues,
    password,
    useValidation = true,
    onKeyUp,
    setValue,
    callback,
  } = props;
  const [inputType, setInputType] = useState<TInputType>('password');
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  function _setInputType() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  return (
    <CS.InputLabel>
      <CS.PasswordInputLabel>
        <InputLabel label={label} useMarking={required} />
        <Text
          styleName="subheadline2"
          color={inputType === 'password' ? 'RG04' : 'RC02'}
          sx={{ cursor: 'pointer' }}
          onClick={_setInputType}
        >
          비밀번호 보기
        </Text>
      </CS.PasswordInputLabel>
      {disableAutoFill ? (
        <form autoComplete="off" onSubmit={event => event.preventDefault()}>
          <Input
            removeBtn={() => setValue('password', '')}
            name="password"
            maxLength="64"
            placeholder={placeholder}
            type={inputType}
            variant="fifth"
            register={register}
            sx={sx}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.target.value = translateKoToEng(event.target.value);
              setValue('password', event.target.value);
            }}
            onBlur={() => {
              if (useValidation === false || setError === undefined || clearErrors === undefined) return;
              const { password } = getValues();

              if (isFalsy(password)) return setError('password', { message: '비밀번호 설정은 필수 입력사항 입니다.' });

              if (validatePassword(password) === false) {
                return setError('password', {
                  message:
                    '비밀번호는 총 8자 이상 64자 이하,  영문 / 숫자 / 특수문자(공백 포함) 중 2개 이상을 포함하여야 합니다. ',
                });
              }
              clearErrors('password');
              callback && callback();
            }}
            onKeyUp={onKeyUp}
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
              setIsCapsLockOn(e.getModifierState('CapsLock'));
            }}
            hasError={password.message}
            errors={password.message}
          />
        </form>
      ) : (
        <Input
          name="password"
          removeBtn={() => setValue('password', '')}
          maxLength="64"
          placeholder={placeholder}
          type={inputType}
          variant="fourth"
          autoComplete="on"
          register={register}
          sx={sx}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            event.target.value = translateKoToEng(event.target.value);
            setValue('password', event.target.value);
          }}
          onBlur={() => {
            if (useValidation === false || setError === undefined || clearErrors === undefined) return;
            const { password } = getValues();

            if (isFalsy(password)) return setError('password', { message: '비밀번호 설정은 필수 입력사항 입니다.' });

            if (validatePassword(password) === false) {
              return setError('password', {
                message:
                  '비밀번호는 총 8자 이상 64자 이하,  영문 / 숫자 / 특수문자(공백 포함) 중 2개 이상을 포함하여야 합니다. ',
              });
            }
            clearErrors('password');
            callback && callback();
          }}
          onKeyUp={onKeyUp}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            setIsCapsLockOn(e.getModifierState('CapsLock'));
          }}
          hasError={password.message}
          errors={password.message}
        />
      )}
      {isCapsLockOn && (
        <Text styleName="caption3" color="RC04">
          <ErrorMarking style={{ marginRight: '6px' }} />
          Caps Lock이 켜져 있습니다.
        </Text>
      )}
    </CS.InputLabel>
  );
};
