import { type QueryFunctionContext, useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';
import { type HTTPError } from 'ky';
import { type IMultiSkillData } from 'types/masterData/multiSkill';

type FetchMultiSkillListResponse = {
  totalCount: number;
  data: IMultiSkillData[];
};

type GetMultiSkillListParams = {
  searchItem: string;
  keyword: string;
};

type UseQueryGeneric = {
  TQueryFnData: FetchMultiSkillListResponse;
  TError: HTTPError;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof MASTER_DATA_QUERIES.GET_MULTI_SKILL_LIST>;
};

const fetchMultiSkillList = async ({ queryKey: [{ endPoint }] }: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchMultiSkillListResponse>();

const useGetMultiSkillList = <QueryReturnType = UseQueryGeneric['TData']>(
  params: GetMultiSkillListParams,
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    MASTER_DATA_QUERIES.GET_MULTI_SKILL_LIST(params),
    fetchMultiSkillList,
    options
  );

export { fetchMultiSkillList, useGetMultiSkillList };
export type { FetchMultiSkillListResponse, GetMultiSkillListParams };
