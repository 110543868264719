import styled, { css } from 'styled-components';
import { ReactElement, ReactNode } from 'react';
import { ReactComponent as Restaurants } from '../../../constants/icon/ic_restaurants.svg';
import { TTimelineVariants } from 'constants/types';

interface CellProps {
  variant: TTimelineVariants;
  isCompleted?: boolean;
  isDragging?: boolean;
  isSelected?: boolean;
  isPriority?: boolean;
  children?: ReactNode;
  color?: string;
  waitingTime: number;
  multiplier: number;
}

const CellContainer = styled.div<CellProps>`
  display: flex;
  padding-top: 15px;
  height: 100%;
  ::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: ${({ theme, isSelected }) => isSelected && theme.colors.RC03_3};
  }
  :hover {
    background: ${({ theme }) => theme.colors.RC03_3};
  }
`;

const CellBaseStyle = css<CellProps>`
  display: flex;
  width: 100%;
  height: 24px;
  box-sizing: border-box;
  user-select: none;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 1;
`;

const Start = styled.div<CellProps>`
  ${CellBaseStyle}
  /* background: ${({ theme }) => theme.colors.RC02};
  border: 1px solid ${({ theme }) => theme.colors.RG00}; */
  ::after {
    content: '';
    width: 6px;
    height: 24px;
    right: 0;
    border-radius: 4px;
    box-sizing: border-box;
    position: absolute;
    background: ${({ theme }) => theme.colors.RC02};
    border: 1px solid ${({ theme }) => theme.colors.RG00};
  }
`;

const End = styled.div<CellProps>`
  ${CellBaseStyle}
  background: ${({ theme }) => theme.colors.RG02};
  border: 1px solid ${({ theme }) => theme.colors.RG00};
`;

const StartEndTags = styled.div`
  position: absolute;
  top: 10px;
  right: -2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: ${({ theme }) => theme.colors.RC04} transparent transparent transparent;
`;

const WaitingBorderBaseStyle = css<{ waitingTime: number; multiplier: number }>`
  content: '';
  position: absolute;
  top: calc(50% - 1.5px);
  right: 100%;
  width: ${({ waitingTime, multiplier }) => (waitingTime * multiplier) / 60}px;
  height: 2px;
  pointer-events: none;
`;

const WaitingBorder = styled.div<{ waitingTime: number; multiplier: number }>`
  ::after {
    ${WaitingBorderBaseStyle}
    background: repeating-linear-gradient(
      to right,
      #565b69 0 ${({ multiplier }) => 6 * multiplier}px,
      transparent ${({ multiplier }) => 6 * multiplier}px ${({ multiplier }) => 12 * multiplier}px
    );
  }
`;
const WaitingBorderBackground = styled.div<{ waitingTime: number; multiplier: number }>`
  ::after {
    ${WaitingBorderBaseStyle}
    background: #ffffff;
  }
`;

const Order = styled.div<CellProps>`
  ${CellBaseStyle}

  color: ${({ theme }) => theme.colors.RG00};
  ${({ color }) => color && { background: color }};

  border: 1px solid ${({ theme }) => theme.colors.RG00};

  ::after {
    content: '';
    position: absolute;
    top: 9px;
    width: 6px;
    height: 6px;
    background: #fa1c44;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    opacity: ${({ isPriority }) => (isPriority ? 1 : 0)};
  }
`;

const Mibaecha = styled.div<CellProps>`
  ${CellBaseStyle}
  background: ${({ theme }) => theme.colors.RC05};
  border: 1px solid ${({ theme }) => theme.colors.RG00};
`;

const Jumsim = styled.div<CellProps>`
  ${CellBaseStyle}
  background: #F2E8E2;
  border: 1px solid #a86739;
`;

const Empty = styled.div``;

const MappedStyled = {
  start: Start,
  end: End,
  order: Order,
  mibaecha: Mibaecha,
  jumsim: Jumsim,
  leftPadding: Empty,
  rightPadding: Empty,
  invisible: Empty,
  spacer: Empty,
};

export default function ({ ...props }: CellProps): ReactElement {
  const MappedStyledComponent = MappedStyled[props.variant];

  return (
    <CellContainer {...props}>
      <MappedStyledComponent {...props}>
        {props.waitingTime > 0 && (
          <>
            <WaitingBorderBackground waitingTime={props.waitingTime} multiplier={props.multiplier} />
            <WaitingBorderBackground
              className="waitingborder"
              waitingTime={props.waitingTime}
              multiplier={props.multiplier}
            />
            <WaitingBorder waitingTime={props.waitingTime} multiplier={props.multiplier} />
          </>
        )}

        {props.variant === 'order' && props.children}
        {props.variant === 'jumsim' && <Restaurants />}
        {(props.variant === 'start' || props.variant === 'end') && <StartEndTags />}
      </MappedStyledComponent>
    </CellContainer>
  );
}
