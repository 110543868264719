import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getVehicleModel } from 'api';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import PopupYIndexFixer from './PopupYIndexFixer';
import { UseFormReturn } from 'react-hook-form';

import { ReactComponent as SpinLoader } from 'constants/icon/ellipse_spin_loader.svg';
import { SelectorContainer, SelectorItem } from 'components/Selector/style';

interface VehicleModelPickerProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  methods: UseFormReturn<any>;

  searchValue?: string;
}

export const VehicleModelPicker = ({ setIsOpen, name, methods, searchValue }: VehicleModelPickerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [svResult, setSvResult] = useState<Array<any>>([]);

  const { data: vehicleModel, isSuccess } = useQuery(['vehicleModel'], getVehicleModel, {});
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  useEffect(() => {
    let da = vehicleModel?.vehicleModelList
      .filter(d => d.name !== '기타')
      .filter(d => d.name.includes(searchValue as string));
    setSvResult(da || []);
  }, [searchValue]);

  return (
    <SelectorContainer spacing={44} ref={targetRef}>
      <Stack align="start">
        {isSuccess ? (
          searchValue === undefined ? (
            vehicleModel.vehicleModelList.filter(d => d.name !== '기타').length > 0 ? (
              vehicleModel.vehicleModelList
                .filter(d => d.name !== '기타')
                .map((x, i) => (
                  <SelectorItem
                    key={`${i}-${name}-${x.name}`}
                    onClick={() => {
                      setIsOpen(false);
                      methods.setValue(name, x.name, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  >
                    <Stack spacing={8} direction={'row'}>
                      <Text styleName={'caption3'} color={'RG03'}>
                        {x.name}
                      </Text>
                    </Stack>
                  </SelectorItem>
                ))
            ) : (
              <Text styleName="caption3" color="RG04" sx={{ margin: '0 0 0 20px' }}>
                일치하는 차량이 없습니다.
              </Text>
            )
          ) : svResult.length > 0 ? (
            svResult.map((x, i) => (
              <SelectorItem
                key={`${i}-${name}-${x.vehicleModelId}`}
                onClick={() => {
                  setIsOpen(false);
                  methods.setValue(name, x.name, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              >
                <Stack spacing={8} direction={'row'}>
                  <Text styleName={'caption3'} color={'RG03'}>
                    {x.name}
                  </Text>
                </Stack>
              </SelectorItem>
            ))
          ) : (
            <Text styleName="caption3" color="RG04" sx={{ margin: '0 0 0 20px' }}>
              일치하는 차량이 없습니다.
            </Text>
          )
        ) : (
          <Stack padding={10}>
            <SpinLoader style={{ width: '16px', height: '16px' }} className="infinite_rotating" />
          </Stack>
        )}
      </Stack>
    </SelectorContainer>
  );
};
