import Modal from 'components/Modal';
import { Dispatch, useEffect, useState } from 'react';
import { Highlight, Text } from 'components/Typography';
import { Button } from 'components/Button/legacy_index';
import { Stack } from 'components/Stack';
import StatusTag from 'pages/Setting/common/StatusTag';

import { InputLabel } from 'auth/common';
import { NewSelector } from 'pages/Setting/common';

import { INVOLVED_MANGER, INVITED_MANGER } from 'pages/Setting/organization/Manager/constants';
import * as A from 'pages/Setting/organization/Manager/style';

import { ReactComponent as ArrowDropDownIcon } from 'constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as CloseIcon } from 'constants/icon/ic_close_16.svg';

import { updateModalType, _putChangeTeam, _getTeamList } from 'pages/Setting/organization/Manager/container';
import { SelectItemConvertor } from 'pages/Setting/organization/Manager';
import { BasicTable } from 'components/Table/BasicTable';
import cloneDeep from 'lodash.clonedeep';

function InsertOwnerTag(
  mode: TManagerMenu,
  seletedList: TInvolvedManagerListState[] | TInvitationManagerListState[] | null
) {
  if (mode === 'Involved') {
    const _list = seletedList as TInvolvedManagerListState[];
    const hasOwner = _list.some(user => user.roleId === 1);
    if (hasOwner === false) return _list;

    const _seletedList = cloneDeep(_list);
    _seletedList.map(list => {
      if (list.roleId === 1) {
        list.name = (
          <Stack direction="row" spacing={6}>
            <StatusTag text="오너" variant="small" />
            <Text styleName="caption2" color="RG02">
              {list.name}
            </Text>
          </Stack>
        );
      }
      return list;
    });

    return _seletedList;
  }
  return seletedList;
}

interface IChangeTeamModal {
  _state: TMangerContentState;
  _dispatch: Dispatch<TMangerContentAction>;
}

export const ChangeTeamModal = (props: IChangeTeamModal) => {
  const {
    _state: { isOpen, invitedSelectedList, involvedSelectedList, mode },
    _dispatch,
  } = props;
  const seletedList = involvedSelectedList || invitedSelectedList;
  const [teamList, setTeamList] = useState<TTeamListState[] | null>(null);
  const [teamId, setTeamId] = useState<number | null>(null);

  useEffect(() => {
    _getTeamList(setTeamList);
  }, []);

  const convertedTeamList = teamList && SelectItemConvertor({ list: teamList });
  const getUserList = () => {
    let userList;
    if (mode === 'Involved') {
      userList = involvedSelectedList!.map(list => list.userId);
    } else {
      userList = invitedSelectedList!.map(list => list.invitationId);
    }
    return userList;
  };
  const TABLE_CONFIG = mode === 'Involved' ? INVOLVED_MANGER : INVITED_MANGER;

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={() => {}}
      width={540}
      height={'522'}
      plain
      ds="strong"
      sx={{ minWidth: '504px', overflow: 'visible' }}
    >
      <A.ChangeTeamModalHeader>
        <Text styleName="subheadline2" color="RG02">
          소속 팀 변경
        </Text>
        <CloseIcon cursor="pointer" onClick={() => updateModalType({ type: '', isOpen: false, _dispatch })} />
      </A.ChangeTeamModalHeader>
      <A.ChangeTeamModalLayout>
        <Stack align="start">
          <Text styleName="title2" color="RG02">
            선택한 매니저의 소속팀을 일괄적으로 변경합니다.
          </Text>

          <div style={{ width: '100%', padding: '40px 0 30px 0' }}>
            <Stack align="start" sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text styleName="body2" color="RG02">
                선택한 매니저
              </Text>
              <Text styleName="subheadline2" color="RG03" sx={{ paddingLeft: '10px' }}>
                총
                <Highlight styleName="subheadline1" color="RG03">
                  {seletedList?.length || 0}
                </Highlight>
                명
              </Text>
            </Stack>

            <BasicTable tableWidth="500px" tableConfig={TABLE_CONFIG} data={InsertOwnerTag(mode, seletedList) || []} />
            <InputLabel sx={{ marginTop: '30px' }} label="변경할 소속 팀을 선택" />
            <NewSelector
              sx={{ width: '100%', height: '40px', marginTop: '4px' }}
              anchor="bottom"
              placeholder="팀을 선택해주세요."
              fullWidth={true}
              options={convertedTeamList || []}
              onOptionsClick={item => setTeamId(item.value)}
              icon={<ArrowDropDownIcon />}
            />
          </div>
        </Stack>

        <A.ChangeTeamModalFooter>
          <Button
            variant="seventh"
            height={40}
            width={102}
            type="button"
            styleName="body1"
            color="RH03"
            onClick={() => updateModalType({ type: '', isOpen: false, _dispatch })}
          >
            취소
          </Button>
          <Button
            variant="MRTB"
            height={40}
            width={216}
            type="button"
            disabled={teamId === null}
            onClick={() => {
              const list = getUserList();
              _putChangeTeam({ mode, list, teamId: teamId!, _dispatch });
            }}
          >
            <Text styleName="body1" color="RH03">
              소속 팀 변경
            </Text>
          </Button>
        </A.ChangeTeamModalFooter>
      </A.ChangeTeamModalLayout>
    </Modal>
  );
};
