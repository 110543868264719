import { Text } from 'components/Typography';

import { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';

export const SystemErrorModal = () => {
  return (
    <CloseConfirmModal
      isOpen={true}
      setIsOpen={() => {}}
      width={504}
      plain
      ms={0}
      icon={<IcWarning width={44} height={44} />}
      targetSetIsOpen={undefined}
      text={
        <Text styleName={'body2'}>
          오류가 발생하여 인증 코드 발송을 처리하지 못하였습니다.
          <br />
          인증 코드를 재발송 하시겠습니까?
        </Text>
      }
      btnFunctions={{
        LBT: () => {
          //closer here
        },
        RBT: () => {
          // callback action here
        },
      }}
      RBT={'재발송'}
      LBT={'닫기'}
      sx={{ padding: '44px 24px 24px' }}
    />
  );
};
