import Modal from 'components/Modal';
import { useReducer, Fragment, useEffect, Dispatch, useState } from 'react';
import { useStore } from 'store';
import { Text, Highlight } from 'components/Typography';
import { Button } from 'components/Button/legacy_index';
import { Stack } from 'components/Stack';
import { InputLabel } from 'auth/common';
import { NewSelector } from 'pages/Setting/common';
import { Chip, EditableElement } from 'pages/Setting/organization/Manager/inviteManagerModal';
import {
  inviteManagerInitialState,
  inviteManagerReducer,
  editInputedEmail,
} from 'pages/Setting/organization/Manager/inviteManagerModal';
import {
  deleteInputedEmailByIndex,
  convertKeyName,
  _deleteAllErrorEmails,
  _getTeamList,
  updateTeamId,
  _postInspeetLoginAccount,
  inspectParams,
  initializeInspectedList,
  _postInviteManager,
  convertErrorAlertMessage,
} from 'pages/Setting/organization/Manager/inviteManagerModal/container';
import { updateModalType } from 'pages/Setting/organization/Manager/container';
import * as S from 'pages/Setting/organization/Manager/inviteManagerModal/style';
import { SelectItemConvertor } from 'pages/Setting/organization/Manager';

import { ReactComponent as IcBin } from 'constants/icon/ic_bin_unfilled.svg';
import { ReactComponent as CloseIcon } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as ArrowDropDownIcon } from 'constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { isTruthy } from 'util/isTruthy';

interface IInviteManagerModal {
  parentDispatch: Dispatch<TMangerContentAction>;
  isOpen: boolean;
}

export const InviteManagerModal = (props: IInviteManagerModal) => {
  const { parentDispatch, isOpen } = props;
  const { setIsLoading } = useStore();
  const [_state, _dispatch] = useReducer(inviteManagerReducer, inviteManagerInitialState);
  const { teamList } = _state;

  const convertedTeamList = teamList && SelectItemConvertor({ list: teamList });

  const [isFilled, setIsFilled] = useState<'#8F96A9' | '#FA1C44'>('#8F96A9');

  useEffect(() => {
    _getTeamList({ _dispatch });
    if (isOpen) {
      document.getElementById('manager_keyup')?.focus();
    }
  }, [isOpen]);

  const isActiveButton = inspectParams({ _state });

  return (
    <Modal width={540} plain isModalOpen={isOpen} sx={{ overflow: 'visible' }} setIsModalOpen={() => {}}>
      <S.InviteManagerModalHeader>
        <Text styleName="subheadline2" color="RG02">
          매니저 초대하기
        </Text>
        <CloseIcon
          cursor="pointer"
          onClick={() => updateModalType({ _dispatch: parentDispatch, type: '', isOpen: false })}
        />
      </S.InviteManagerModalHeader>
      <S.InviteManagerModalBody>
        <Stack align="start">
          <Text styleName="title2" color="RG02" sx={{ paddingBottom: '20px' }}>
            초대할 매니저의 이메일 주소를 입력해주세요.
          </Text>

          <InputLabel label="이메일 주소" />
          <S.InviteManagerModalInputFrame
            onClick={() => {
              _state.inspectedEmailList === null && document.getElementById('manager_keyup')!.focus();
            }}
          >
            <S.InviteManagerModalInputLayout>
              <S.InviteManagerModalDisplayChips>
                {_state.inspectedEmailList &&
                  _state.inspectedEmailList.map((list, idx: number) => {
                    const { loginAccount, type } = list;
                    console.log(_state.inspectedEmailList, '_state.inspectedEmailList', idx, 'idx');
                    const lastChipBottomCss = _state.inspectedEmailList!.length - 1 === idx ? '30px' : '';
                    return (
                      <Fragment key={`email_chip_${idx}`}>
                        <Chip
                          id={`email_chip_${idx}`}
                          status={type ? 'ERROR' : 'SUCCESS'}
                          alertMessage={list.type && convertErrorAlertMessage(list.type)}
                          sx={{ marginBottom: lastChipBottomCss }}
                          onClick={() => {}}
                          onKeyUp={(props: TInviteOnKeyUp) => {
                            const { event, value } = props;

                            const eventTrigger = ['Comma', 'Space', 'Enter'].includes(event.code);
                            const deleteEventTrigger = ['Backspace', 'Delete'].includes(event.code);

                            if (eventTrigger) {
                              const element = document.getElementById(`email_chip_${idx}`);
                              editInputedEmail(value, idx, _dispatch, element);
                            }
                            if (deleteEventTrigger && value.length === 1) deleteInputedEmailByIndex(idx, _dispatch);
                          }}
                          cancelEvent={() => deleteInputedEmailByIndex(idx, _dispatch)}
                        >
                          {loginAccount}
                        </Chip>
                      </Fragment>
                    );
                  })}

                <EditableElement
                  id="manager_keyup"
                  onKeyUp={(props: TInviteOnKeyUp) => {
                    const { event, value } = props;
                    const eventTrigger = ['Comma', 'Space', 'Enter'].includes(event.code);

                    if (eventTrigger) {
                      const element = document.getElementById('manager_keyup');
                      _postInspeetLoginAccount({ _dispatch, element, loginAccount: value });
                    }
                  }}
                >
                  <S.InviteManagerModalInput
                    onPaste={e => {
                      const value = e.currentTarget.innerText;
                      if (value.split('@.').length !== 0 || value.split('\n\n\n').length !== 0) {
                        const element = document.getElementById('manager_keyup');
                        _postInspeetLoginAccount({ _dispatch, element, loginAccount: value });
                      }
                    }}
                    hasInvitedMail={isTruthy(_state.inspectedEmailList)}
                    data-text="이메일 주소를 입력해 주세요."
                    id="inviteManagerInput"
                  ></S.InviteManagerModalInput>
                </EditableElement>
              </S.InviteManagerModalDisplayChips>
            </S.InviteManagerModalInputLayout>
          </S.InviteManagerModalInputFrame>
          {_state.currentStatus && (
            <Stack align="start" sx={{ paddingTop: '11px' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  {Object.keys(_state.currentStatus)

                    .filter(key => {
                      if (key === 'errorDescription') return false;
                      if (
                        isTruthy(
                          _state.currentStatus![key as keyof Omit<TManagerEmailCurrentStatus, 'errorDescription'>]
                        )
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .map((key, idx: number) => {
                      const { text, css } = convertKeyName(key as 'total' | 'error' | 'success');
                      const borderLeftOption =
                        idx !== 0 ? { borderLeft: '1px solid #D8DDEA', paddingLeft: '6px' } : undefined;

                      return (
                        <Fragment key={idx}>
                          <Text styleName="subheadline2" color={css} sx={{ paddingRight: '6px', ...borderLeftOption }}>
                            {text}
                            <Highlight styleName="subheadline1" color={css} sx={{ padding: '0 4px 0 4px' }}>
                              {_state.currentStatus![key as keyof Omit<TManagerEmailCurrentStatus, 'errorDescription'>]}
                            </Highlight>
                            건
                          </Text>
                        </Fragment>
                      );
                    })}
                </div>
                {isTruthy(_state.currentStatus.error) && (
                  <Button
                    variant="custom"
                    custom={{
                      init: { borderColor: 'RG06', bgColor: 'RG00', color: 'RG03' },
                      hover: { bgColor: 'RC04_2', borderColor: 'RC04_2', color: 'RC04' },
                    }}
                    type="button"
                    color="RG03"
                    styleName="caption1"
                    sx={{ padding: '2px 10px' }}
                    onMouseOver={event => setIsFilled('#FA1C44')}
                    onMouseLeave={() => setIsFilled('#8F96A9')}
                    onClick={() => _deleteAllErrorEmails(_dispatch)}
                  >
                    <IcBin fill={isFilled} style={{ paddingRight: '5px' }} />
                    오류 이메일 전체 삭제
                  </Button>
                )}
              </div>

              {_state.currentStatus.errorDescription && (
                <Stack align="start" sx={{ paddingTop: '11px' }}>
                  {_state.currentStatus.errorDescription.map((error, index: number) => {
                    const { type, message, count } = error;
                    return (
                      <Fragment key={`${type}_${index}`}>
                        <Text styleName="subheadline2" color="RC04">
                          <ErrorMarking style={{ marginRight: '4px' }} />
                          {message}
                          <Highlight styleName="subheadline1" color="RC04" sx={{ padding: '0 4px 0 4px' }}>
                            {count}
                          </Highlight>
                          건
                        </Text>
                      </Fragment>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          )}
          <InputLabel sx={{ marginTop: '30px' }} label="소속 팀" />
          <NewSelector
            sx={{ width: '100%', height: '40px', marginTop: '4px' }}
            anchor="bottom"
            placeholder="팀을 선택해주세요."
            fullWidth={true}
            selectorGap={20}
            options={convertedTeamList || []}
            onOptionsClick={item => updateTeamId({ _dispatch, teamId: item.value })}
            icon={<ArrowDropDownIcon />}
          />
        </Stack>
      </S.InviteManagerModalBody>
      <S.InviteManagerModalFooter>
        <Button
          variant="seventh"
          height={40}
          width={102}
          type="button"
          styleName="body1"
          color="RH03"
          onClick={() => {
            updateModalType({ _dispatch: parentDispatch, type: '', isOpen: false });
            initializeInspectedList(_dispatch);
          }}
        >
          취소
        </Button>
        <Button
          variant="MRTB"
          height={40}
          width={216}
          type="button"
          onClick={() => {
            setIsLoading(true);
            _postInviteManager({ _dispatch, _state, parentDispatch, setIsLoading });
          }}
          disabled={isActiveButton === false}
        >
          <Text styleName="body1" color="RH03">
            초대하기
          </Text>
        </Button>
      </S.InviteManagerModalFooter>
    </Modal>
  );
};
