import styled from 'styled-components';
import { Stack, StackProps } from 'components/Stack';

const GrayBox = ({ children, align = 'start', ...props }: StackProps) => {
  return <GrayBoxContainer {...props}>{children}</GrayBoxContainer>;
};

export default GrayBox;

const GrayBoxContainer = styled(Stack)`
  gap: 12;
  padding: 10px;
  border-radius: 8px;
  ${({ theme }) => ({
    backgroundColor: theme.colors.RG08,
  })}
`;
