import { Text } from '../../Typography';
import { useSetChangePayMethod } from '../../../hooks/query/payment/useSetChangePayMethod';
import { CSSProperties, useEffect } from 'react';
import { Stack } from '../../Stack';
import { useGetPaymentMy } from '../../../hooks/query/payment/useGetPaymentMy';

export interface ChangePaymentMethodProps {
  content?: string;
  gap?: CSSProperties['gap'];
}

export const ChangePaymentMethod = ({ content, gap }: ChangePaymentMethodProps) => {
  const { mutate: setChangePayMethod, data: changePayMethodData, isSuccess } = useSetChangePayMethod();
  // const [hasDeleteButton, setHasDeleteButton] = useState<boolean>(true);
  const { refetch } = useGetPaymentMy({
    enabled: false,
  });

  useEffect(() => {
    refetch().then(result => {
      // if (!result.isSuccess) return;
      //
      // const { name, paymentStatus } = result.data;
      // if (name !== 'Trial' && paymentStatus === 'activated') {
      //   setHasDeleteButton(true);
      // }
    });
  }, [refetch]);

  useEffect(() => {
    if (changePayMethodData) {
      window.location.href = changePayMethodData.paymentUrl;
    }
  }, [changePayMethodData]);

  return (
    <Stack direction="row" sx={{ gap }}>
      <Text styleName="body1" color="RG02">
        {content}
      </Text>
      {/*{hasDeleteButton && (*/}
      {/*  <Button*/}
      {/*    variant="tertiary"*/}
      {/*    h={32}*/}
      {/*    icon={[IcBin, { width: 16, height: 16 }]}*/}
      {/*    onClick={() => {*/}
      {/*      console.log('delete');*/}
      {/*    }}*/}
      {/*    sx={{ width: 'max-content' }}*/}
      {/*  >*/}
      {/*    삭제*/}
      {/*  </Button>*/}
      {/*)}*/}
      {/*<Button*/}
      {/*  variant="secondary"*/}
      {/*  h={32}*/}
      {/*  onClick={() => {*/}
      {/*    setChangePayMethod({*/}
      {/*      successUrl: `/mypage/?modalType=changePayMethodSuccess`,*/}
      {/*    });*/}
      {/*  }}*/}
      {/*>*/}
      {/*  결제 수단 변경*/}
      {/*</Button>*/}
    </Stack>
  );
};
