import { Button } from 'components/Button';
import Divider from 'components/Divider';
import { AddSkillModal } from 'components/Modal/AddSkillModal';
import Modal from 'components/Modal/index';
import {
  DescriptionTable,
  DescriptionTd,
  DescriptionTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from 'components/Modal/style';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import theme from 'constants/theme';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import type { detailMultiSkill } from 'types/masterData/multiSkill';

interface ModifySkillModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: detailMultiSkill;
}

const ModifySkillModal = ({ isOpen, setIsOpen, data }: ModifySkillModalProps) => {
  const [isModify, setIsModify] = useState<boolean>(false);

  return isModify ? (
    <AddSkillModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isModify
      skillId={data.skillId}
      name={data.name}
      description={data.description}
    />
  ) : (
    <Modal plain width={540} isModalOpen={isOpen} setIsModalOpen={setIsOpen}>
      <OrderDetailModalHeader>
        <Stack direction="row" justify="space-between">
          <Text styleName="subheadline2">특수 조건 상세</Text>
          <IcClose
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
      </OrderDetailModalHeader>
      <Divider color="RG06" />
      <OrderDetailModalBody
        spacing={30}
        style={{
          padding: '30px 20px',
        }}
      >
        <DescriptionTable
          style={{
            borderTop: `1px solid ${theme.colors.RG03}`,
          }}
        >
          <tbody>
            <tr>
              <DescriptionTh colSpan={120}>소속 팀</DescriptionTh>
              <DescriptionTd colSpan={380}>{data.team.name}</DescriptionTd>
            </tr>
            <tr>
              <DescriptionTh colSpan={120}>조건 이름</DescriptionTh>
              <DescriptionTd colSpan={380}>{data.name}</DescriptionTd>
            </tr>
            <tr>
              <DescriptionTh colSpan={120}>조건 설명</DescriptionTh>
              <DescriptionTd colSpan={380} style={{ wordWrap: 'break-word', whiteSpace: 'unset' }}>
                {data.description}
              </DescriptionTd>
            </tr>
            <tr>
              <DescriptionTh colSpan={120}>소속 드라이버 ({data.drivers.length}명)</DescriptionTh>
              <DescriptionTd colSpan={380} broken>
                {data.drivers.length
                  ? data.drivers
                      .map(item => item.name)
                      .toString()
                      .replaceAll(',', ', ')
                  : ''}
              </DescriptionTd>
            </tr>
          </tbody>
        </DescriptionTable>
      </OrderDetailModalBody>

      <Divider color="RG06" />
      <OrderDetailModalFooter>
        <Stack
          name="modal-footer"
          spacing={16}
          direction="row"
          align="center"
          justify="end"
          sx={{ padding: '2px 0 0 0' }}
        >
          <Button
            variant="tertiary"
            type="button"
            h={40}
            sx={{ width: '102px' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG03">
              취소
            </Text>
          </Button>
          <Button
            variant="primary"
            type="button"
            h={40}
            sx={{ width: '216px' }}
            onClick={() => {
              setIsModify(true);
            }}
          >
            <Text styleName="body1" color="RG00">
              수정하기
            </Text>
          </Button>
        </Stack>
      </OrderDetailModalFooter>
    </Modal>
  );
};

export { ModifySkillModal };
