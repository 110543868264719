import styled from 'styled-components';

export const MyPageLayout = styled.div`
  align-items: center;

  display: flex;
  flex-direction: row;
  gap: 20px;
  width: fit-content;

  @media only screen and (min-width: 1300px) {
    width: 100%;
    max-width: 1320px;
  }
`;
