import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactNode } from 'react';
import type { TSettingAttrs } from 'types/setting/union';

//icons
// 설정 > 하위 요소들  ( 팀, 매니저, 드라이버, 권역, 드라이버앱 )
type TLayoutProps = {
  children: ReactNode;

  attr: TSettingAttrs;

  title: ReactNode | string;

  desc: ReactNode | string;

  ts?: ReactNode;
};
export const MyPageLayout = ({ ...props }: TLayoutProps) => {
  return (
    <Stack className="setting-layout-TnD" align="start" sx={{ padding: '50px 24px 20px 20px' }}>
      <Stack className="setting-layout-title setting-layout-sc" direction="row" justify="space-between">
        {props?.ts}
      </Stack>
      <Stack className="setting-layout-desc" align="start">
        {<Text styleName="caption2">{props.desc}</Text>}
      </Stack>
      <Stack align="start">{props.children}</Stack>
    </Stack>
  );
};
