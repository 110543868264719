import { Button } from 'components/Button/legacy_index';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

import { ReactComponent as IcArrowCountDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ReactComponent as IcArrowCountUp } from 'constants/icon/ic_arrow_count_up.svg';
import { ReactComponent as IcDot } from 'constants/icon/ic_dot.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';

import theme from 'constants/theme';
import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

interface TimePickerPopupProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  methods: UseFormReturn<any>;

  rSide?: boolean;
  desired?: boolean;

  initial?: string;
  init?: string;

  clearable?: boolean;
  nanable?: boolean;

  nan?: boolean;
  setNan?: Dispatch<SetStateAction<boolean>>;
  clearOnNan?: boolean;
  /** next morning option */
  nm?: boolean;
}

type Tampm = '오전' | '오후' | '익일오전';

const MiniTimePicker = ({
  isOpen,
  setIsOpen,
  methods,
  name,
  rSide,
  desired,
  initial,
  init,
  clearable,
  nanable,
  nan,
  setNan,
  clearOnNan,
  nm,
}: TimePickerPopupProps) => {
  const BASE_DATE = '2001-01-01';

  const [isTimeFocused, setIsTimeFocused] = useState(false);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [hourInput, setHourInput] = useState<string>('00');
  const [minuteInput, setMinuteInput] = useState<string>('00');

  const amPmArray: Tampm[] = useMemo(() => {
    const origin: Tampm[] = ['오전', '오후', '익일오전'];
    return origin.slice(0, nm ? 3 : 2);
  }, [nm]);

  const amPm: Tampm = useMemo(() => {
    return (date.isAfter(BASE_DATE, 'day') ? '익일오전' : date.format('A')) as Tampm;
  }, [date]);

  const targetRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };

  useEffect(() => {
    const isAfternoon = date.hour() >= 12;

    if (amPm === '익일오전' && isAfternoon) {
      setDate(dayjs(BASE_DATE).clone().set('hour', 0));

      return;
    }

    setHourInput(date.format('hh'));
    setMinuteInput(date.format('mm'));
  }, [date, amPm]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  const initializer = (reset?: boolean) => {
    if ((reset && initial) || nan) {
      setDate(dayjs(BASE_DATE + initial + ':00'));
    } else if (init) {
      if (init !== '없음') setDate(dayjs(BASE_DATE + init + ':00'));
    } else methods.setValue(name, '', { shouldValidate: true, shouldDirty: true });
  };

  useEffect(() => {
    initializer(true);
  }, []);

  const callback = () => {
    if (nanable) setNan?.(false);
  };

  return (
    <TimePickerContainer ref={targetRef} rSide={rSide}>
      <Stack spacing={20} justify={'center'} align={'center'}>
        <Stack name="timePicker_header_area" direction="row" justify="space-between" align="center">
          <Text styleName="caption2" color={'RG04'}>
            시간 설정 {desired && '희망'}
          </Text>

          {clearable && (
            <Button
              variant={'second'}
              sx={{ width: '45px', height: '26px' }}
              type={'button'}
              onClick={() => {
                initializer(true);
              }}
            >
              <Text styleName="caption2" color={'RG04'}>
                초기화
              </Text>
            </Button>
          )}
          {nanable && (
            <Stack
              direction="row"
              spacing={4}
              onClick={() => {
                setNan?.(prev => !prev);
              }}
              sx={{ width: 'max-content' }}
            >
              <IcSuccess width={16} height={16} fill={theme.colors[nan ? 'RC02' : 'RG05']}/>
              <Text styleName="caption1">없음</Text>
            </Stack>
          )}
        </Stack>

        <Stack direction={'row'} sx={{ borderRadius: '6px', overflow: 'hidden', width: '100%' }}>
          {amPmArray.map(v => {
            return (
              <AmpmContainer
                style={amPm === v ? { backgroundColor: theme.colors.RC03 } : { backgroundColor: theme.colors.RG07 }}
                onClick={() => {
                  callback();
                  if (amPm === v) return;

                  switch (v) {
                    case '오전':
                      return setDate(dayjs(BASE_DATE).clone().set('hour', 1));
                    case '오후':
                      return setDate(dayjs(BASE_DATE).clone().set('hour', 13));
                    case '익일오전':
                      return setDate(dayjs(BASE_DATE).clone().add(1, 'd').set('hour', 1));
                  }
                }}
                len={2}
              >
                <Text styleName={'caption3'} color={amPm === v ? 'RG00' : 'RG04'}>
                  {v}
                </Text>
              </AmpmContainer>
            );
          })}
        </Stack>

        <Stack spacing={4}>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  setDate(date.clone().add(1, 'hour'));
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountUp
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  setDate(date.clone().add(1, 'minute'));
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={'row'}
            align={'center'}
            sx={{
              background: isTimeFocused ? theme.colors.RC03_1 : theme.colors.RG08,
              border: '1px solid #769DFF',
              borderColor: isTimeFocused ? theme.colors.RC03 : theme.colors.RG08,
              borderRadius: '6px',
            }}
          >
            <Stack padding={6}>
              <NumberInput
                style={{ color: theme.colors[nan ? 'RG04' : 'RG02'] }}
                onBlur={e => {
                  setIsTimeFocused(false);
                  let h = parseInt(e.target.value) || 0;
                  if (h < 1) h = 1;
                  else if (h > 11) h = 0;

                  if (amPm === '오후') h += 12;
                  setDate(date.clone().set('hour', h));
                }}
                onFocus={() => {
                  callback();
                  setIsTimeFocused(true);
                }}
                placeholder="00"
                value={hourInput}
                onChange={e => {
                  setHourInput(e.target.value);
                }}
              />
            </Stack>
            <Stack sx={{ width: '4px', height: '18px' }}>
              <IcDot fill={theme.colors.RG04}/>
            </Stack>
            <Stack padding={6}>
              <NumberInput
                style={{ color: theme.colors[nan ? 'RG04' : 'RG02'] }}
                onBlur={e => {
                  setIsTimeFocused(false);
                  let m = parseInt(e.target.value) || 0;
                  if (m > 59) m = 59;
                  else if (m < 0) m = 0;
                  setDate(date.clone().set('minute', m));
                }}
                onFocus={() => {
                  callback();
                  setIsTimeFocused(true);
                }}
                placeholder="00"
                value={minuteInput}
                onChange={e => {
                  setMinuteInput(e.target.value);
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} align={'center'}>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  setDate(date.clone().subtract(1, 'hour'));
                }}
              />
            </Stack>
            <Stack>
              <IcArrowCountDown
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  callback();
                  setDate(date.clone().subtract(1, 'minute'));
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={10} direction="row" sx={{ justifyContent: 'end' }}>
          <Button
            variant={'second'}
            fullWidth
            height={26}
            type="submit"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text styleName="caption2" color={'RG04'}>
              닫기
            </Text>
          </Button>
          <Button
            variant="default"
            fullWidth
            height={26}
            type="submit"
            onClick={() => {
              if (clearOnNan && nan)
                methods.setValue(name, null, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              else
                methods.setValue(
                  name,
                  nan
                    ? '없음'
                    : amPm === '익일오전'
                    ? date
                        .format('HH:mm')
                        .split(':')
                        .map((d, index) => (index === 0 ? Number(d) + 24 : d))
                        .toString()
                        .replaceAll(',', ':')
                    : date.format('HH:mm'),
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  }
                );
              setIsOpen(false);
            }}
          >
            <Text styleName="caption2" color={'RG00'}>
              적용하기
            </Text>
          </Button>
        </Stack>
      </Stack>
    </TimePickerContainer>
  );
};

export default MiniTimePicker;

const TimePickerContainer = styled.div<{ rSide?: boolean }>`
  display: flex;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  top: 76px;
  ${({ rSide }) =>
          rSide
                  ? {
                    right: 0,
                  }
                  : {
                    left: 0,
                  }}
  z-index: 3;
`;

const AmpmContainer = styled.div<{ len: number }>`
  ${({ len }) => ({
    width: `calc(100% / ${len})`,
  })}
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NumberInput = styled.input`
  ${({ theme }) => theme.fontStyle.title3}
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.RG03};
  background: transparent;
`;
