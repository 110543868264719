import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

function PopupYIndexFixer({ children }: { children: ReactNode }) {
  const childRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (childRef?.current?.children && childRef?.current?.children.length > 0) {
      let target = childRef?.current?.children[0],
        targetHeight = target.clientHeight,
        targetBounding = target.getBoundingClientRect(),
        pageHeight = window.innerHeight;
      // if (targetBounding.top + targetHeight > pageHeight - 50) {
      childRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  }, []);

  return <FixedContainer ref={childRef}>{children}</FixedContainer>;
}

export default PopupYIndexFixer;

const FixedContainer = styled.div``;
