import { useEffect, useState } from 'react';

import { Text } from 'components/Typography';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { ReactComponent as SuccessMarking } from 'constants/icon/ic_success_alert_circle.svg';
import { _updateVerifyCode, _validateVerifyCode, _resetVerifyCode } from 'auth/signUp/privateTypeForm/container';

import * as S from 'auth/signUp/style';

const INIT_STATE = {
  codes: '',
  isError: false,
  isSuccess: false,
};

interface IPrivateVerificationCodeInput {
  successCallback: Function;
  loginAccount: string;
  reset: boolean;
}

export const PrivateVerificationCodeInput = (props: IPrivateVerificationCodeInput) => {
  const { loginAccount, reset, successCallback } = props;
  const CODE_LENGTH = 6;

  const VARIFICATION_INPUT_LENGTH = Array(CODE_LENGTH);
  const [verifyCodeState, setVerifyCodeState] = useState<TPrivateVerificationCodeInput>(INIT_STATE);
  const { codes, isError, isSuccess } = verifyCodeState;

  useEffect(() => {
    if (reset) {
      _resetVerifyCode(verifyCodeState, setVerifyCodeState);
    }
  }, [reset]);

  useEffect(() => {
    if (codes.length === 6 && isSuccess === false) {
      _validateVerifyCode(codes, loginAccount, verifyCodeState, setVerifyCodeState);
    }
    if (isSuccess) {
      setTimeout(() => {
        successCallback(loginAccount);
      }, 2000);
    }
  }, [codes, isSuccess]);

  return (
    <S.VarificationCodeContainer>
      <S.VarificationCodeBox>
        {VARIFICATION_INPUT_LENGTH.fill(0).map((value: number, index: number) => {
          return <S.VarificationCodeDisplay key={index}>{codes[index]}</S.VarificationCodeDisplay>;
        })}
        <S.VarificationCodeInput
          value={codes}
          onChange={event => _updateVerifyCode(event.target.value, verifyCodeState, setVerifyCodeState)}
          maxLength={CODE_LENGTH}
        />
      </S.VarificationCodeBox>
      {isError && (
        <Text styleName="caption3" color="RC04" sx={{ alignSelf: 'start', marginTop: '10px' }}>
          <ErrorMarking style={{ marginRight: '6px' }} />
          인증번호가 일치하지 않습니다.
        </Text>
      )}

      {isSuccess && (
        <Text styleName="caption3" color="RC02" sx={{ alignSelf: 'start', marginTop: '10px' }}>
          <SuccessMarking style={{ marginRight: '6px', width: '12px', height: '12px' }} />
          인증 번호가 일치합니다.
        </Text>
      )}
    </S.VarificationCodeContainer>
  );
};
