import { AuthLayout } from 'auth/AuthLayout';
import { PrivateTypeForm } from 'auth/signUp/privateTypeForm';
import { CorporationTypeForm } from 'auth/signUp/corporateTypeForm';

import { useState, useEffect, useReducer } from 'react';
import { signUpInitialState, signUpReducerAction } from 'auth/signUp/reducer';
import * as S from 'auth/signUp/style';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { replacePathname } from 'util/replacePathname';

import { ReactComponent as Corporate } from 'constants/icon/ic_number_1.svg';
import { ReactComponent as Private } from 'constants/icon/ic_number_2.svg';

import { MODE, HEADER_LABEL_COLOR } from 'auth/constants';
import { WelcomePage } from 'auth/signUp/WelcomePage';
import { Text } from 'components/Typography';
import { requestIndustryType } from 'auth/signUp/container';

export const SignUp = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [_state, _dispatch] = useReducer(signUpReducerAction, signUpInitialState);
  const { signUpMode } = _state;

  const [welcomePageMode, setWelcomePageMode] = useState<TWelcomePageType>('');

  const title = signUpMode === 'PRIVATE' ? '간단한 개인 정보' : '회사 정보';

  let authToken = localStorage.getItem('accessToken');

  useEffect(() => {
    requestIndustryType(_dispatch);
  }, []);

  if (welcomePageMode !== '') {
    return (
      <WelcomePage
        accountInfo={{ loginAccount: _state.signUpForm.loginAccount!, password: _state.signUpForm.password! }}
        welcomePageMode={welcomePageMode}
        setWelcomePageMode={setWelcomePageMode}
      />
    );
  }

  return authToken ? (
    <Navigate to="/" />
  ) : (
    <S.SignUpLayout>
      <AuthLayout cardWidth={846}>
        <S.SignUpCardLayout>
          <S.SignUpHeaderButton>
            {MODE.map(target => {
              const buttonCss = target.key === signUpMode ? HEADER_LABEL_COLOR.active : HEADER_LABEL_COLOR.inactive;
              const isPrivate = target.key === 'PRIVATE';

              return (
                <S.SignUpHeader background={buttonCss.btnBackground} key={target.key}>
                  {isPrivate ? (
                    <Private fill={buttonCss.font} style={{ marginLeft: '10px' }} />
                  ) : (
                    <Corporate fill={buttonCss.font} style={{ marginLeft: '10px' }} />
                  )}
                  <Text styleName="subheadline1" color={buttonCss.font} sx={{ marginLeft: '10px' }}>
                    {target.title}
                  </Text>
                </S.SignUpHeader>
              );
            })}
          </S.SignUpHeaderButton>
          <S.SignUpBody>
            <Text styleName="headline1" color="RG02" sx={{ paddingTop: '40px' }}>{`${title}를 입력해주세요.`}</Text>
          </S.SignUpBody>
          {signUpMode === 'PRIVATE' ? (
            <PrivateTypeForm _state={_state} _dispatch={_dispatch} setWelcomePageMode={setWelcomePageMode} />
          ) : (
            <CorporationTypeForm _state={_state} _dispatch={_dispatch} />
          )}

          <S.SignUpBottom>
            <Text styleName="body2" color="RG02">
              이미 루티의 회원이신가요?
            </Text>

            <Text
              onClick={() => navigate(replacePathname(pathname, 'signin', 'signup'))}
              sx={{ cursor: 'pointer', paddingLeft: '20px' }}
              styleName="body2"
              color="RC02"
            >
              로그인하기
            </Text>
          </S.SignUpBottom>
        </S.SignUpCardLayout>
      </AuthLayout>
    </S.SignUpLayout>
  );
};
