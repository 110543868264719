import { WarningModal } from '../index';
import { Dispatch, SetStateAction } from 'react';

export interface ClientAuthCodeLimitModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ClientAuthCodeLimitModal = ({ isOpen, setIsOpen }: ClientAuthCodeLimitModalProps) => (
  <WarningModal
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    closeTimer={3000}
    title="재전송 3회 이상으로 처음부터 다시 시도해 주세요."
    description="번호를 정확하게 입력했는지 확인해주세요."
  />
);
