export const UNSUBSCRIBE_QUERIES = {
  SCOPE: [{ scope: 'UNSUBSCRIBE' }] as const,
  UNSUBSCRIBE_CANCEL: () => [
    {
      ...UNSUBSCRIBE_QUERIES.SCOPE[0],
      entity: 'unsubscribe_cancel',
      endPoint: 'payment/unsubscribe/cancel',
    },
  ],
};
