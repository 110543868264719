import { useQuery } from '@tanstack/react-query';

import { getControlRouteListByDate, isRouteNameDuplicated } from 'api';
import AltWrapper from 'components/Alt/AltWrapper';
import GraphicCard from 'components/GraphicCard';

import { Input } from 'components/Input';
import genLabel from 'components/Modal/common/genLabel';
import DatePickerPopup from 'components/Popup/DatePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { ReactComponent as DistrictDedicated } from 'constants/graphics/img_district_dedicated.svg';
import { ReactComponent as DistrictDedicatedActive } from 'constants/graphics/img_district_dedicated_active.svg';
import { ReactComponent as DistrictFlexible } from 'constants/graphics/img_district_flexible.svg';
import { ReactComponent as DistrictFlexibleActive } from 'constants/graphics/img_district_flexible_active.svg';

import { ReactComponent as DistrictNone } from 'constants/graphics/img_district_none.svg';
import { ReactComponent as DistrictNoneActive } from 'constants/graphics/img_district_none_active.svg';
import { ReactComponent as EqualizeWorkLoad } from 'constants/graphics/img_equalize_workload.svg';
import { ReactComponent as EqualizeWorkLoadActive } from 'constants/graphics/img_equalize_workload_active.svg';

import { ReactComponent as EqualizeWorktime } from 'constants/graphics/img_equalize_worktime.svg';
import { ReactComponent as EqualizeWorktimeActive } from 'constants/graphics/img_equalize_worktime_active.svg';
import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import theme from 'constants/theme';
import { TDistAreaType, TEqualizeBy } from 'constants/types';
import dayjs from 'dayjs';
import { FormValues } from 'pages/RoutePlanSetupPage';
import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { TTimePickerHandle } from '../../components/Popup/new/TimePicker';
import { Tampmnm } from '../../types/time';

const ReqTooltip = ({ children }: { children: string | ReactNode }) => {
  return (
    <Text styleName="caption2" color="RG02">
      {children}{' '}
      <Highlight styleName="caption1" color="RC04">
        *
      </Highlight>
    </Text>
  );
};

const OptionsBody = ({
                       title,
                       desc,
                       spacing = 2,
                       type,
                     }: {
  title: string;
  desc: string;
  spacing?: number;
  type: 'equalBy' | 'distAreaType';
}) => {
  const mapper = {
    equalBy: { styleName: 'subheadline1' },
    distAreaType: { styleName: 'caption1' },
  };
  return (
    <Stack spacing={spacing}>
      <Text styleName={mapper[type].styleName as any} color={true ? 'RG02' : 'RG03'}>
        {title}
      </Text>
      <pre>
        <Text styleName="tooltip1" color={true ? 'RG03' : 'RG04'} sx={{ textAlign: 'center' }}>
          {desc}
        </Text>
      </pre>
    </Stack>
  );
};

const Section = ({
                   title,
                   desc,
                   spacing = 16,

                   children,
                 }: {
  title: string;
  desc: string;
  spacing?: number;
  children: ReactNode;
}) => {
  return (
    <BgBox spacing={spacing} align="start">
      <Stack spacing={6} align="start">
        <Text styleName="title1" color="RG02">
          {title}
        </Text>
        <Text styleName="caption2" color="RG03">
          {desc}
        </Text>
      </Stack>
      {children}
    </BgBox>
  );
};

type TOptions<T> = Array<{
  graphic: { origin: ReactNode; evented?: ReactNode };
  key: T;
  body: ReactNode;
}>;

const options_equalize_by: TOptions<TEqualizeBy> = [
  {
    graphic: { origin: <EqualizeWorktime/>, evented: <EqualizeWorktimeActive/> },
    key: 'worktime',
    body: OptionsBody({
      title: '균등 업무 시간',
      desc: `드라이버 업무 시간을\n균등하게 배차합니다.`,
      type: 'equalBy',
    }),
  },
  {
    graphic: { origin: <EqualizeWorkLoad/>, evented: <EqualizeWorkLoadActive/> },
    key: 'workload',
    body: OptionsBody({
      title: '균등 주문 개수',
      desc: `드라이버 주문 개수를\n균등하게 배차합니다.`,
      type: 'equalBy',
    }),
  },
];

const options_dist_area: TOptions<TDistAreaType> = [
  {
    graphic: { origin: <DistrictNone/>, evented: <DistrictNoneActive/> },
    key: 'none',
    body: OptionsBody({
      title: '권역 없이 배차',
      desc: `권역 설정이 되어 있어도\n무시하고 배차합니다.`,
      type: 'distAreaType',
    }),
  },
  {
    graphic: { origin: <DistrictDedicated/>, evented: <DistrictDedicatedActive/> },
    key: 'dedicated',
    body: OptionsBody({ title: '기본 권역 배차', desc: `설정한 권역을 기준으로\n배차합니다.`, type: 'distAreaType' }),
  },
  {
    graphic: { origin: <DistrictFlexible/>, evented: <DistrictFlexibleActive/> },
    key: 'flexible',
    body: OptionsBody({
      title: '유연한 권역 배차',
      desc: `설정한 권역을 기반으로\n인근의 드라이버에게\n효율적으로 배차`,

      spacing: 0,
      type: 'distAreaType',
    }),
  },
];

export const planOptionsSection: Array<{
  key: keyof FormValues;
  title: string;
  desc: string;
  tooltip?: string;
  options?: TOptions<any>;
}> = [
  {
    key: 'name',
    title: '주행 이름·주행 일자 입력',
    desc: '주행 이름과 주행 일자를 설정합니다.',
  },
  {
    key: 'equalizeBy',
    title: '균등 배차 옵션',
    desc: '균등 주문 개수 또는 균등 업무 시간 중 선택하여 배차 최적화를 실행합니다.',
    tooltip: '균등 배차 옵션을 선택해주세요',
    options: options_equalize_by,
  },
  {
    key: 'distAreaType',
    title: '권역 배차 옵션',
    desc: '권역 내의 주문을 담당 드라이버에게 배차합니다. 다음 옵션 중 1개는 필수입니다.',
    tooltip: '권역 배차 옵션을 선택해주세요',

    options: options_dist_area,
  },
];

const PlanOptions = ({ methods }: { methods: UseFormReturn<FormValues> }) => {
  const [performedDatePopupIsOpen, setPerformedDatePopupIsOpen] = useState<boolean>(false);
  const [performedTimePopupIsOpen, setPerformedTimePopupIsOpen] = useState<boolean>(false);
  const [workEndTimePopupIsOpen, setWorkEndTimePopupIsOpen] = useState<boolean>(false);

  const [amPm, setAmPm] = useState<Tampmnm>('AM');
  const [hour, setHour] = useState<string>('12');
  const [minute, setMinute] = useState<string>('00');
  const [nan, setNan] = useState<boolean>(true);
  const [workEndTimeNan, setWorkEndTimeNan] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean | undefined>();

  const options_equalize_by: TOptions<TEqualizeBy> = [
    {
      graphic: { origin: <EqualizeWorktime/>, evented: <EqualizeWorktimeActive/> },
      key: 'worktime',
      body: OptionsBody({
        title: '균등 업무 시간',
        desc: `드라이버 업무 시간을\n균등하게 배차합니다.`,
        type: 'equalBy',
      }),
    },
    {
      graphic: { origin: <EqualizeWorkLoad/>, evented: <EqualizeWorkLoadActive/> },
      key: 'workload',
      body: OptionsBody({
        title: '균등 주문 개수',
        desc: `드라이버 주문 개수를\n균등하게 배차합니다.`,
        type: 'equalBy',
      }),
    },
  ];

  // const options_dist_area: TOptions<TDistAreaType> = [
  //   {
  //     graphic: { origin: <DistrictNone />, evented: <DistrictNoneActive /> },
  //     key: 'none',
  //     body: OptionsBody({
  //       title: '권역 없이 배차',
  //       desc: `권역 설정이 되어 있어도\n무시하고 배차합니다.`,
  //       type: 'distAreaType',
  //     }),
  //   },
  //   {
  //     graphic: { origin: <DistrictDedicated />, evented: <DistrictDedicatedActive /> },
  //     key: 'dedicated',
  //     body: OptionsBody({ title: '기본 권역 배차', desc: `설정한 권역을 기준으로\n배차합니다.`, type: 'distAreaType' }),
  //   },
  //   {
  //     graphic: { origin: <DistrictFlexible />, evented: <DistrictFlexibleActive /> },
  //     key: 'flexible',
  //     body: OptionsBody({
  //       title: '유연한 권역 배차',
  //       desc: `설정한 권역을 기반으로\n인근의 드라이버에게\n효율적으로 배차`,

  //       spacing: 0,
  //       type: 'distAreaType',
  //     }),
  //   },
  // ];

  const performedTimePickerRef = useRef<TTimePickerHandle>(null);
  const workEndTimePickerRef = useRef<TTimePickerHandle>(null);

  const { data: dayRoutes } = useQuery([`${methods.getValues('performedDate')}dailyRoutes`], () =>
    getControlRouteListByDate(dayjs(methods.getValues('performedDate')).format('YYYY-MM-DD')),
  );

  const nameRgx = /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01]) \([월|화|수|목|금|토|일]\) 배차 \#/;

  useEffect(() => {
    if (nameRgx.test(methods.getValues('name')) || methods.getValues('name') === '')
      methods.setValue(
        'name',
        `${dayjs(methods.getValues('performedDate')).format('YYYY.MM.DD (ddd)')} 배차 #${
          methods.getValues('name') === ''
            ? dayRoutes?.equalTimeRoutes.count
              ? dayRoutes?.equalTimeRoutes.count + 1
              : 1
            : methods.getValues('name').split('#')[1].toString()
        }`,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
  }, [methods.getValues('performedDate'), dayRoutes?.equalTimeRoutes.count]);

  useEffect(() => {
    if (methods.getValues('performedTime') === '없음') {
      methods.setValue('workEndTime', '없음');
      setWorkEndTimeNan(true);
    }
  }, [methods.getValues('performedTime')]);

  useEffect(() => {
    if (+methods.getValues('performedTime').replace(':', '') > +methods.getValues('workEndTime').replace(':', '')) {
      methods.setError('workEndTime', { message: '주행 종료 시간이 주행 시작 시간을 앞설 수 없습니다.' });
    } else {
      methods.clearErrors('performedTime');
      methods.clearErrors('workEndTime');
    }
  }, [methods.getValues('workEndTime')]);

  useEffect(() => {
    if (+methods.getValues('performedTime').replace(':', '') > +methods.getValues('workEndTime').replace(':', '')) {
      methods.setError('performedTime', { message: '주행 시작 시간이 주행 종료 시간보다 늦을 수 없습니다.' });
    } else {
      methods.clearErrors('performedTime');
      methods.clearErrors('workEndTime');
    }
  }, [methods.getValues('performedTime')]);

  useEffect(() => {
    performedTimePickerRef.current?.validateTimePicker(true);
    workEndTimePickerRef.current?.validateTimePicker(true);
  }, [performedDatePopupIsOpen]);

  return (
    <Stack spacing={20}>
      <Section title={planOptionsSection[0].title} desc={planOptionsSection[0].desc} spacing={30}>
        <Stack align="start" direction="row" spacing={20} sx={{ maxWidth: 1548, height: 84 }}>
          <Stack sx={{ position: 'relative', width: '240px' }}>
            <Input
              sx={{ gap: 4 }}
              style={{ color: theme.colors[performedDatePopupIsOpen ? 'RG04' : 'RG02'] }}
              label={genLabel({ text: '주행 일자', isRequiredStart: true })}
              name="performedDate"
              styleName="subheadline2"
              placeholder={dayjs().format('YYYY.MM.DD').toString()}
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              height={40}
              maxLength={10}
              readOnly
              onClick={() => {
                setPerformedDatePopupIsOpen(prev => !prev);
              }}
              validation={{
                required: true,
              }}
              hasError={Boolean(methods.formState.errors?.performedDate)}
            />
            {performedDatePopupIsOpen && (
              <DatePickerPopup
                fullWidth
                dayCheck
                allowUncheck={false}
                setIsOpen={setPerformedDatePopupIsOpen}
                setValue={methods.setValue}
                initDate={methods.getValues('performedDate') ?? dayjs().toString()}
                name={'performedDate'}
                banPast
                top="76px"
                format="YYYY.MM.DD"
              />
            )}
          </Stack>
          <Stack sx={{ position: 'relative', width: '240px' }}>
            <Input
              sx={{ gap: 4 }}
              label={
                <Stack direction="row" spacing={6}>
                  {genLabel({ text: '주행 시작 시간 지정' })}
                  <AltWrapper
                    altInfo={`설정한 시간에 맞추어 드라이버가 주행을\n시작합니다.드라이버의 개별 주행 시작 시간과     \n휴게시간은 무시합니다.\n'없음'인 경우 드라이버의 개별 주행 시작 시간과\n휴게시간에 맞추어 배차됩니다.`}
                    anchorWeight={{
                      top: -178,
                      left: 736,
                    }}
                  >
                    <IcInfo className="pointer" fill={theme.colors.RG05} style={{ width: 20, height: 20 }}/>
                  </AltWrapper>
                </Stack>
              }
              name="performedTime"
              styleName="subheadline2"
              placeholder="없음"
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              height={40}
              maxLength={10}
              readOnly
              onClick={() => {
                setPerformedTimePopupIsOpen(prev => !prev);
              }}
              errors={{
                message: methods.formState.errors?.performedTime?.message,
              }}
              hasError={Boolean(methods.formState.errors?.performedTime)}
              validation={{
                required: true,
                validate: (res: string) => {
                  if (methods.getValues('performedTime')) {
                    if (
                      +(methods.getValues('performedTime') ?? '').replace(':', '') >
                      +(methods.getValues('workEndTime') ?? '').replace(':', '')
                    ) {
                      return '주행 시작 시간이 주행 종료 시간보다 늦을 수 없습니다.';
                    }
                  }

                  return;
                },
              }}
            />
            {/*<TimePicker*/}
            {/*  ref={performedTimePickerRef}*/}
            {/*  init={methods.getValues('performedTime')}*/}
            {/*  isOtherDate={dayjs(methods.getValues('performedDate')).diff(dayjs(), 'd', true) > 0}*/}
            {/*  amPm={amPm}*/}
            {/*  setAmPm={setAmPm}*/}
            {/*  hour={hour}*/}
            {/*  setHour={setHour}*/}
            {/*  minute={minute}*/}
            {/*  setMinute={setMinute}*/}
            {/*  nan={nan}*/}
            {/*  setNan={setNan}*/}
            {/*  hasError={hasError}*/}
            {/*  setHasError={setHasError}*/}
            {/*  name="performedTime"*/}
            {/*  isOpen={performedTimePopupIsOpen}*/}
            {/*  setIsOpen={setPerformedTimePopupIsOpen}*/}
            {/*  methods={methods}*/}
            {/*  nm*/}
            {/*/>*/}
            {performedTimePopupIsOpen && (
              <MiniTimePicker
                isOpen={performedTimePopupIsOpen}
                setIsOpen={setPerformedTimePopupIsOpen}
                methods={methods}
                name={'performedTime'}
                initial={
                  methods.getValues('performedTime') && methods.getValues('performedTime') !== '없음'
                    ? methods.getValues('performedTime')
                    : '00:00'
                }
                init={methods.getValues('performedTime')}
                // desired
                nm
                nanable
                nan={nan}
                setNan={setNan}
              />
            )}
          </Stack>
          <Stack sx={{ position: 'relative', width: '240px' }}>
            <Input
              sx={{ gap: 4 }}
              label={
                <Stack direction="row" spacing={6}>
                  {genLabel({ text: '주행 종료 시간 지정' })}
                  <AltWrapper
                    altInfo={`설정한 시간에 맞추어 드라이버가 주행을\n종료합니다. 드라이버의 모든 작업을 끝낸 시간이\n주행 종료 시간이 됩니다.`}
                    anchorWeight={{
                      top: -178,
                      left: 995,
                    }}
                  >
                    <IcInfo className="pointer" fill={theme.colors.RG05} style={{ width: 20, height: 20 }} />
                  </AltWrapper>
                </Stack>
              }
              name="workEndTime"
              styleName="subheadline2"
              placeholder="없음"
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              height={40}
              maxLength={10}
              disabled={methods.getValues('performedTime') === '없음'}
              variant={'useDisabled'}
              readOnly
              onClick={() => {
                setWorkEndTimePopupIsOpen(prev => !prev);
              }}
              validation={{
                required: false,
                validate: (res: string) => {
                  if (
                    +methods.getValues('performedTime').replace(':', '') >
                    +methods.getValues('workEndTime').replace(':', '')
                  )
                    return '주행 종료 시간이 주행 시작 시간을 앞설 수 없습니다.';
                },
              }}
              errors={{
                message: methods.formState.errors?.workEndTime?.message,
              }}
              hasError={Boolean(methods.formState.errors?.workEndTime)}
            />
            {workEndTimePopupIsOpen && (
              <MiniTimePicker
                isOpen={workEndTimePopupIsOpen}
                setIsOpen={setWorkEndTimePopupIsOpen}
                methods={methods}
                name={'workEndTime'}
                initial={
                  methods.getValues('workEndTime') && methods.getValues('workEndTime') !== '없음'
                    ? methods.getValues('workEndTime')
                    : '00:00'
                }
                init={methods.getValues('workEndTime')}
                nm
                nanable
                nan={workEndTimeNan}
                setNan={setWorkEndTimeNan}
              />
            )}
          </Stack>
          <Stack sx={{ position: 'relative', width: '372px' }}>
            <Input
              sx={{ gap: 4 }}
              name="name"
              label={genLabel({ text: '주행 이름', isRequiredStart: true })}
              styleName="subheadline2"
              placeholder={`${dayjs().format('YYYY.MM.DD (ddd)')} 배차 #1`}
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              height={40}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!Boolean(e.target.value) || e.target.value === '') {
                  methods.setError('name', { type: 'required', message: '' });
                } else
                  isRouteNameDuplicated(e.target.value).then(res => {
                    if (res.isDuplicated) {
                      methods.setError('name', { type: 'duplicatedName', message: '중복된 주행 이름입니다.' });
                    } else methods.clearErrors('name');
                  });
              }}
              validation={{
                required: true,
              }}
              errors={methods.formState.errors?.name}
              hasError={Boolean(methods.formState.errors?.name)}
            />
          </Stack>
        </Stack>
      </Section>
      <Section title={planOptionsSection[1].title} desc={planOptionsSection[1].desc}>
        <Stack spacing={6} align="start">
          <ReqTooltip>{planOptionsSection[1]?.tooltip}</ReqTooltip>
          <Stack spacing={16} align="start" direction="row">
            {planOptionsSection[1]?.options?.map(d => {
              return (
                <GraphicCard
                  key={`equalizeBy-${d.key}-graphic-card`}
                  graphic={d.graphic}
                  onClick={e => {
                    if (methods.watch('equalizeBy') === d.key) {
                      return;
                      // methods.setValue('equalizeBy', undefined, { shouldDirty: true });
                    } else methods.setValue('equalizeBy', d.key, { shouldDirty: true });

                    console.log(methods.getValues());
                  }}
                  check
                  isActive={methods.watch('equalizeBy') === d.key}
                >
                  {d.body}
                </GraphicCard>
              );
            })}
          </Stack>
        </Stack>
      </Section>
      <Section title={planOptionsSection[2].title} desc={planOptionsSection[2].desc}>
        <Stack spacing={6} align="start">
          <ReqTooltip>{planOptionsSection[2].tooltip}</ReqTooltip>
          <Stack spacing={16} align="start" direction="row">
            {planOptionsSection[2]?.options?.map(d => {
              return (
                <GraphicCard
                  key={`equalizeBy-${d.key}-graphic-card`}
                  graphic={d.graphic}
                  onClick={e => {
                    if (methods.watch('distAreaType') === d.key) {
                      return;
                      // methods.setValue('distAreaType', undefined, { shouldDirty: true });
                    } else methods.setValue('distAreaType', d.key, { shouldDirty: true });

                    console.log(methods.getValues());
                  }}
                  spacing={8}
                  check
                  isActive={methods.watch('distAreaType') === d.key}
                >
                  {d.body}
                </GraphicCard>
              );
            })}
          </Stack>
        </Stack>
      </Section>
    </Stack>
  );
};

export default PlanOptions;

const BgBox = styled(Stack)`
  padding: 20px;
  border-radius: 8px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RC03_2,
    border: `1px solid ${theme.colors.RC03_1}`,
  })};
`;
