import { CSSProperties, SVGProps, Dispatch, ReactNode, SetStateAction, memo } from 'react';
import styled from 'styled-components';
import { Stack } from 'components/Stack/style';

import { ReactComponent as IcTooltipArrow } from 'constants/icon/arrow_tooltip.svg';

interface SpeechBubbleProps {
  children: ReactNode | string;
  icon?: SVGProps<any>;
  sx?: CSSProperties;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  color?: string;
  bOffset?: number;

  hc?: boolean;

  anchor?: 'top' | 'bottom';
}

const SpeechBubble = ({
  children,
  sx,
  isOpen,
  setIsOpen,
  icon,
  color = 'RG02',
  bOffset = 30,
  hc = true,
  anchor = 'bottom',
}: SpeechBubbleProps) => {
  return (
    <Container
      align="start"
      style={sx}
      onClick={() => setIsOpen(false)}
      color={color}
      onMouseEnter={() => hc && setIsOpen(false)}
    >
      {anchor === 'top' && (
        <IcTooltipArrow className="speechBubbleArrow" style={{ rotate: '180deg', marginLeft: `${bOffset}px` }} />
      )}
      <Bubble direction="row" spacing={10} padding={10} color={color}>
        <>
          {icon && icon}
          {children}
        </>
      </Bubble>
      {anchor === 'bottom' && <IcTooltipArrow className="speechBubbleArrow" style={{ marginLeft: `${bOffset}px` }} />}
    </Container>
  );
};

export default memo(SpeechBubble);

export const Container = styled(Stack)<Partial<SpeechBubbleProps>>`
  width: fit-content;

  position: absolute;
  z-index: 4;

  & > .speechBubbleArrow > path {
    fill: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.RG02)};
  }
`;

export const Bubble = styled(Stack)<Partial<SpeechBubbleProps>>`
  color: ${({ theme }) => theme.colors.RG00};

  border-radius: 8px;
  background-color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.RG02)};
`;
