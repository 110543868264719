import { Button } from '../../Button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthNumberInput } from '../../Input/AuthNumberInput';
import { PhoneNumberInput } from '../../Input/PhoneNumberInput';
import { useSendManagerAuthCode } from '../../../hooks/query/managerAuthCode/useSendManagerAuthCode';
import { FetchPaymentInfoData, useGetPaymentInfo } from '../../../hooks/query/payment/useGetPaymentInfo';
import { DailyAuthCodeLimitModal } from '../../Modal/warning/authCodeLimit/DailyAuthCodeLimitModal';
import { useCheckExistsManagerInfo } from '../../../hooks/query/exists/useCheckExistsManagerInfo';

export interface ChangePhoneNumberProps {
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  setPaymentInfoData: Dispatch<SetStateAction<Partial<FetchPaymentInfoData>>>;
  setIsDisabledSaveButton: Dispatch<SetStateAction<boolean>>;
}

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 20px;
`;

const Section = styled.section`
  display: flex;
  align-items: start;
  column-gap: 20px;
`;

export const ChangePhoneNumber = ({
  phone,
  setPhone,
  setPaymentInfoData,
  setIsDisabledSaveButton,
}: ChangePhoneNumberProps) => {
  const [disabledAuthButton, setDisabledAuthButton] = useState(true);
  const [sendAuthNumber, setSendAuthNumber] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isSuccessVerify, setIsSuccessVerify] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const { mutate, isSuccess, isError, error } = useSendManagerAuthCode();
  const {
    mutate: mutateCheckExistsManagerInfo,
    error: checkExistsManagerInfoError,
    isSuccess: isSuccessCheckExistsManagerInfo,
  } = useCheckExistsManagerInfo();
  const { data: paymentInfoData } = useGetPaymentInfo({
    select: data => ({
      phone: data?.phone,
    }),
  });

  useEffect(() => {
    if (isSuccess) {
      setSendAuthNumber(true);
      setDisabledAuthButton(true);
      setTimestamp(Date.now());

      setHasError(false);
      setErrorMessage('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setHasError(true);
      setErrorMessage('오류가 발생하여 인증 코드 발송을 처리하지 못하였습니다.');
    }
  }, [isError]);

  useEffect(() => {
    (async () => {
      if (error) {
        const data = await error.response.clone().json();
        setShowErrorAlert(data.error.name === 'RequestLimit');
      }
    })();
  }, [error]);

  useEffect(() => {
    if (hasError) {
      setPaymentInfoData(prev => ({
        ...prev,
        phone: '',
      }));
    }
  }, [hasError]);

  useEffect(() => {
    (async () => {
      if (checkExistsManagerInfoError) {
        const data = await checkExistsManagerInfoError.response.clone().json();

        setHasError(true);
        setErrorMessage(data.error.message);
        setDisabledAuthButton(true);
      }
    })();
  }, [checkExistsManagerInfoError]);

  useEffect(() => {
    setDisabledAuthButton(true);
  }, [isSuccessVerify]);

  useEffect(() => {
    if (isSuccessCheckExistsManagerInfo) {
      setIsDisabledSaveButton(true);
    }
  }, [isSuccessCheckExistsManagerInfo]);

  return (
    <Article>
      <Section>
        <PhoneNumberInput
          phone={phone}
          setPhone={setPhone}
          hasError={hasError}
          setHasError={setHasError}
          errorMessage={errorMessage}
          onValidation={(validate, value) => {
            const phoneNumber = phone.replaceAll('-', '');

            setDisabledAuthButton(phoneNumber === paymentInfoData?.phone || !validate);
            setErrorMessage(validate ? '' : '휴대폰 번호 형식에 맞게 입력해 주세요.');

            setSendAuthNumber(false);
            setIsClear(true);

            if (validate) {
              mutateCheckExistsManagerInfo({ phone: phoneNumber });
              setHasError(false);
            } else {
              setHasError(true);
            }
          }}
        />
        <Button
          variant="secondary"
          h={40}
          disabled={disabledAuthButton}
          onClick={e => {
            setIsClear(true);

            mutate({
              phone: phone.replaceAll('-', ''),
            });
          }}
        >
          인증번호 발송하기
        </Button>
      </Section>
      {sendAuthNumber && (
        <Section>
          <AuthNumberInput
            phone={phone}
            count={180}
            setTimestamp={setTimestamp}
            timestamp={timestamp}
            setPaymentInfoData={setPaymentInfoData}
            setIsSuccessVerify={setIsSuccessVerify}
            isClear={isClear}
            setIsClear={setIsClear}
          />
        </Section>
      )}
      <DailyAuthCodeLimitModal isOpen={showErrorAlert} setIsOpen={setShowErrorAlert} />
    </Article>
  );
};
