import styled from 'styled-components';
import { CSSProperties } from 'react';
export const SelectorContainer = styled.div<{
  width?: number | string;
  spacing?: number;
  sx?: CSSProperties;
  anchor?: 'top' | 'bottom';
}>`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  z-index: 3;
  left: 0;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-height: auto;
  max-height: 194px;
  ${({ theme }) => theme.scrollStyle.vertical};

  ${({ anchor, spacing }) =>
    anchor === 'top'
      ? {
          bottom: `${(spacing ?? 0) + 36}px`,
        }
      : {
          top: `${(spacing ?? 0) + 36}px`,
        }}

  ${({ sx }) => sx && { ...sx }}
`;

export const SelectorItem = styled.div<{ activated?: boolean }>`
  width: 100%;
  min-height: 32px;
  padding: 6px 20px;
  display: flex;
  align-items: center;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, activated }) => (activated ? theme.colors.RC03_1 : theme.colors.RG00)};
  ${({ theme }) => theme.fontStyle.caption2};

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};

    ${({ theme }) => theme.fontStyle.caption1};
  }
`;

export const SelectorDisplayBox = styled.div<{ disabledHover: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 6px;

  width: fit-content;
  padding: 7px 10px;

  &:hover {
    background: ${({ theme, disabledHover }) => (disabledHover ? '' : theme.colors.RG07)};
  }
`;
