export const MapConfig = {
  MAP_FONT_FAMILY: 'Pretendard',
  MAP_ACCESS_TOKEN: 'pk.eyJ1Ijoid2VtZWV0cGxhY2UiLCJhIjoiY2s4dHlxdTBqMDVnZTNucHJ5bGc1ZnQ2biJ9.s9zk--dojDtQxjYg4WPSmg',
  MAP_STYLE: 'mapbox://styles/wemeetplace/clc4g2mnm003614k8q0qv1r5v',
  MAP_BOUNDS: [
    [80, 29],
    [154, 45],
  ],
  MAP_BOUNDS_ZOOMED: [
    [109, 29],
    [145, 41],
  ],
  MAP_FIT_PADDING: 80,
};
