import { ThemeProvider } from 'styled-components';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import ConfigRouters from 'ConfigRouters';
import DraggableWrapper from 'components/Table/util/DraggableWrapper';

import theme from 'constants/theme';
import GlobalStyle from 'constants/GlobalStyle';

import useAxiosInstance from 'hooks/useAxiosInstance';
import RouteChangeTracker from './util/tracking/ga';
import QueryStatusModal from 'components/Modal/QueryStatusModal';
import { useAPIStore } from 'store';
import * as Sentry from '@sentry/react';
import Tutorial from 'components/Tutorial';
import { NoticeProvider } from './components/Provider/NoticeProvider';
import { getMyAccountCompanyData } from './pages/MyPage/Account/api';
import { TSentryCompany } from './types/setting/union';

let queryClient: QueryClient;
queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  },
});

function App() {
  if (!window.indexedDB) {
    console.warn('browser not surpport IndexedDB');
    window.alert('일부 기능이 호환되지 않는 브라우저가 발견되었습니다. 브라우저를 최신 버전으로 업데이트 해주세요.');
  }

  useAxiosInstance();

  RouteChangeTracker();

  const { APIALERT, CLEARAPIALERT } = useAPIStore();

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        console.log('~~ERROR~~ => ', error, componentStack, eventId);
        // 컴포넌트 에러 발생 시 에러를 캐치하여 데이터 세팅 후 sentry에 해당 데이터를 같이 전송하는 작업
        const fetchData = async () => {
          let companyInfo: TMyPageAccountCompanyResponse | TErrorResponse;
          let user: Object, company: TSentryCompany;
          if (localStorage.getItem('userId')) {
            companyInfo = await getMyAccountCompanyData();
            let companyId;
            if ('companyId' in companyInfo && companyInfo.companyId !== undefined) {
              // companyId가 존재하고 undefined가 아닌 경우에만 접근
              companyId = companyInfo.companyId;
            }
            let teamObj = JSON.parse(localStorage.getItem('team') ?? '');

            user = {
              id: localStorage.getItem('userId'),
              email: localStorage.getItem('email'),
              username: localStorage.getItem('email')
            }
            company = {
              id: companyId,
              name: companyInfo?.name,
              teamid: teamObj?.teamId,
              teamname: teamObj?.teamName
            }

            console.log('custom', user, company);
          }
          Sentry.withScope(scope => {
            scope.setUser(user);
            scope.setContext('company', company);
          })
        }

        fetchData();
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <DraggableWrapper>
            <NoticeProvider>
              <ConfigRouters />
              {/* API error alert */}
              <QueryStatusModal
                isOpen={APIALERT.open}
                setIsOpen={() => {}}
                action={() => CLEARAPIALERT()}
                {...APIALERT.props}
              />
              {<Tutorial />}
            </NoticeProvider>
          </DraggableWrapper>
        </ThemeProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
