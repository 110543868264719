import { useEffect, useState } from 'react';

import { Text } from 'components/Typography';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { ReactComponent as SuccessMarking } from 'constants/icon/ic_success_alert_circle.svg';

import * as S from 'auth/signUp/style';
import { useVerifyManagerAuthCode } from 'hooks/query/managerAuthCode/useVerifyManagerAuthCode';

const CODE_LENGTH = 6;

interface IAuthCodeInput {
  isPayment?: boolean;
  phone: string;
  successCallback: (code: string) => void;
}

export const AuthCodeInput = (props: IAuthCodeInput) => {
  const { isPayment, phone, successCallback } = props;

  const VARIFICATION_INPUT_LENGTH = Array(CODE_LENGTH);
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [codeIsVerified, setCodeIsVerified] = useState<boolean | null>(null);

  const {
    mutate: mutateVerifyAuthCode,
    data: verifyAuthCodeData,
    isSuccess,
  } = useVerifyManagerAuthCode({
    onSuccess(data) {
      setCodeIsVerified(data.verified);
      if (data.verified) {
        setTimeout(() => {
          successCallback(verifyCode);
        }, 2000);
      }
    },
  });

  useEffect(() => {
    if (isPayment && verifyCode.length === 6) {
      (successCallback as (v?: any) => Promise<any>)(verifyCode).catch(async err => {
        const emm = await err.response.json();
        console.log(emm.error.advice, 'eaoifd');
        if (emm.error.advice === '인증 코드가 일치하지 않습니다.') setCodeIsVerified(false);
      });
    }
  }, [isPayment, verifyCode]);

  useEffect(() => {
    if (verifyCode.length === 6 && !isPayment) {
      mutateVerifyAuthCode({ code: verifyCode, phone: phone });
    }
  }, [phone, verifyCode, isPayment]);

  useEffect(() => {
    verifyCode.length < CODE_LENGTH && setCodeIsVerified(null);
  }, [verifyCode]);

  return (
    <S.VarificationCodeContainer>
      <S.VarificationCodeBox style={{ margin: 0 }}>
        {VARIFICATION_INPUT_LENGTH.fill(0).map((value: number, index: number) => {
          return (
            <S.VarificationCodeDisplay key={index} style={{ width: 62, height: 62 }}>
              {verifyCode[index]}
            </S.VarificationCodeDisplay>
          );
        })}
        <S.VarificationCodeInput
          value={verifyCode}
          onChange={event => setVerifyCode(event.target.value)}
          maxLength={CODE_LENGTH}
        />
      </S.VarificationCodeBox>
      {typeof codeIsVerified === 'boolean' && (
        <Text
          styleName="caption3"
          color={codeIsVerified ? 'RC02' : 'RC04'}
          sx={{ alignSelf: 'start', marginTop: '10px' }}
        >
          {codeIsVerified ? (
            <>
              <SuccessMarking style={{ marginRight: '6px', width: '12px', height: '12px' }} />
              인증 번호가 일치합니다.
            </>
          ) : (
            <>
              <ErrorMarking style={{ marginRight: '6px' }} />
              인증번호가 일치하지 않습니다.
            </>
          )}
        </Text>
      )}
    </S.VarificationCodeContainer>
  );
};
