import { PAYMENT_QUERIES } from './payment.queries';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../../api/ky';

type FetchPaymentInfoData = {
  name: string;
  phone: string;
  email: string;
};

type FetchPaymentInfoResponse = {
  manager: FetchPaymentInfoData;
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetPaymentInfo>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof PAYMENT_QUERIES.GET_PAYMENT_INFO>;
};

const fetchGetPaymentInfo = async ({ queryKey: [{ endPoint }] }: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  (await API.get(endPoint).json<FetchPaymentInfoResponse>()).manager;

const useGetPaymentInfo = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    PAYMENT_QUERIES.GET_PAYMENT_INFO(),
    fetchGetPaymentInfo,
    options
  );

export { fetchGetPaymentInfo, useGetPaymentInfo };
export type { FetchPaymentInfoResponse, FetchPaymentInfoData };
