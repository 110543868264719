import { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import throttle from 'lodash.throttle';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { canceler } from 'api/instance';
import {
  getControlRouteListByDate,
  hasOptimizedDriver as hasOptimizedDriver_query,
  isRouteNameDuplicated,
  manualRouting,
} from 'api';
import { DriverIdUnSelected, IManualExcelRouteRequestResponseList } from 'constants/types';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';

import { Button } from '../Button/legacy_index';
import { Button as NButton } from 'components/Button';
import Modal from 'components/Modal';
import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import DroppableWrapper from '../Droppable/Droppable';
import { Highlight, Text } from 'components/Typography';
import DatePickerPopup from 'components/Popup/DatePicker';

import theme from 'constants/theme';
import strings from '../../util/Localization';
import { ColorChip, DriverItem } from './style';
import { ColorPalette } from '../../constants/colorPalette';

import Lottie from 'lottie-react';
import truck from '../Modal/lottie/truck.json';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcArrowRight } from '../../constants/icon/ic_arrowright20.svg';

import 'dayjs/locale/ko';
import Sidebar from './Sidebar';
import { ReactComponent as IcArrowDown } from 'constants/icon/ic_arrowdown.svg';
import { PlanStatusTag } from 'components/Tag/new/PlanStatus';
import { subscribe_quota } from 'constants/subscribePlans';
import { TPlanMap } from 'pages/RoutePlanSetupPage';
import { useTracking } from '../../hooks/store/useTracking';
import HasOptimizedDriverWarningModal from 'components/Modal/HasOptimizedDriverWarningModal';
import { TUTORIAL_QUERIES } from '../../hooks/query/tutorial/tutorial.queries';

const ManualRoutingNav = ({
  manualExcelValidatedData,
  setXlsxFormIsOpen,
  targetId,
  setTargetId,
  tabIsOpen,
  setTabIsOpen,
}: {
  manualExcelValidatedData: Array<IManualExcelRouteRequestResponseList>;
  setXlsxFormIsOpen: Dispatch<SetStateAction<boolean>>;

  targetId: number;
  setTargetId: Dispatch<SetStateAction<number>>;
  tabIsOpen: boolean;
  setTabIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  type TformProp = {
    name: string;
    date: string;
  };
  let navigate = useNavigate();
  const { state } = useLocation();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      // name: (state as TformProp).name,
      // date: dayjs((state as TformProp).date).format('YYYY.MM.DD (ddd)'),

      name: '',
      date: dayjs().format('YYYY.MM.DD (ddd)'),
    },
  });
  const { control } = methods;

  const [hasOptimizedDriver, setHasOptimizedDriver] = useState<boolean>(false);
  const { id: pricing_id, status: pricing_status } = JSON.parse(localStorage.getItem('pricing')!);

  const planMap = new Map<number, TPlanMap>([
    [1, { grade: 1, key: 'Free' }],
    [5, { grade: 5, key: 'Lite' }],
    [6, { grade: 6, key: 'Standard' }],
    [7, { grade: 7, key: 'Pro' }],
    [8, { grade: 8, key: 'Enterprise' }],
    [9, { grade: 5, key: 'Lite' }],
    [10, { grade: 6, key: 'Standard' }],
    [11, { grade: 7, key: 'Pro' }],
    [12, { grade: 8, key: 'Enterprise' }],
  ]);

  const [subscriptionLimitModal, setSubscriptionLimitModal] = useState<boolean>(false);
  const [subscriptionLimitReason, setSubscriptionLimitReason] = useState<'quota' | 'peronce'>('peronce');
  const [mutateManualRoutingModalIsOpen, setMutateManualRoutingModalIsOpen] = useState<boolean>(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);

  const navigator = useNavigate();

  const [info, setInfo] = useState<{
    date: string;
    totalDriver: string;
    name: string;
    totalOrder: string;
  }>();
  const { data: dayRoutes } = useQuery([`${methods.getValues('date')}dailyRoutes`], () =>
    getControlRouteListByDate(dayjs(methods.getValues('date')).format('YYYY-MM-DD'))
  );

  const { mutate: mutateHasOptimizedDriver } = useMutation(
    (res: TformProp) =>
      hasOptimizedDriver_query(
        dayjs(res.date).format('YYYY-MM-DD'),
        manualExcelValidatedData.map(d => d.vehicle.vehicleId),
        'manual'
      ),
    {
      onSuccess(data, variables, context) {
        data.isOptimized ? setHasOptimizedDriver(true) : onSubmit(variables);
      },
    }
  );

  const {
    data: mutateManualRoutingResponse,
    mutate: mutateManualRouting,
    status: mutateManualRoutingStatus,
    isSuccess: isSuccessManualRouting,
  } = useMutation(manualRouting, {
    onMutate: () => {
      setMutateManualRoutingModalIsOpen(true);
    },
    onError: (e: any) => {
      let eName = e.response?.data.error.name;

      if (eName === 'UsageLimit') {
        setMutateManualRoutingModalIsOpen(false);
        setSubscriptionLimitModal(true);
        setSubscriptionLimitReason('quota');
        return;
      } else if (eName === 'RequestOrderLimit') {
        setMutateManualRoutingModalIsOpen(false);
        setSubscriptionLimitModal(true);
        setSubscriptionLimitReason('peronce');
        return;
      }
    },
    // onSuccess: () => {
    //   navigate('/manage/control/activated', { replace: true });
    // },
  });
  const { setTitle } = useTracking();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccessManualRouting) {
      setTitle('배차계획>수동 배차>임시결과');

      queryClient.invalidateQueries({ queryKey: TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_MONITORING() });
    }
  }, [isSuccessManualRouting, queryClient, setTitle]);

  const uselessRemove = (data: any) => {
    if (typeof data !== 'object') return;

    const removeHeaders = [
      'orderStatus',
      'temporaryOrderId',
      'temporaryOrderOrderId',
      'temporaryOrderRowId',
      'updatedAt',
      'createdAt',
      'vehicleId',
      'priority',
      'service_duration',
      'service_vehicle',
      'service_time',
      'desired_date',
      'desired_after_time',
      'desired_before_time',
      'customer_field_1',
      'customer_field_2',
      'customer_field_3',
      'customer_field_4',
      'customer_field_5',
      'mId',
    ];

    Object.keys(data).forEach(key => {
      if (data[key] === null) delete data[key];
      else if (removeHeaders.includes(key)) delete data[key];
      else uselessRemove(data[key]);
    });
  };

  const onSubmit = throttle((formData: unknown) => {
    if (mutateManualRoutingStatus === 'loading') return;

    const data = formData as TformProp;

    setInfo({
      date: dayjs(data.date).format('YYYY-MM-DD'),
      name: data.name,
      totalDriver: `${manualExcelValidatedData.length} 명`,
      totalOrder: `${manualExcelValidatedData.map(x => x.orderList.length).reduce((s, a) => s + a, 0)} 개`,
    });

    mutateManualRouting({
      performedDate: dayjs(data.date).format('YYYY-MM-DD'),
      name: data.name,
      vehicleList: manualExcelValidatedData.map(d => {
        return {
          ...d,
          orderList: d.orderList.map((x: any) => {
            let xData = {
              ...x,
              consigneePhone: x.consigneePhone ? x.consigneePhone.replaceAll(/[^0-9]/g, '') : null,
              // consigneePhone: x.consigneePhone ? parseInt(x.consigneePhone.replaceAll(/[^0-9]/g, '')) : null,
            };

            uselessRemove(xData);

            return xData;
          }),
        };
      }),
    });
  }, 1000);

  const isXlsxUploaded = (): boolean => manualExcelValidatedData.length > 0;

  useEffect(() => {
    methods.setValue(
      'name',
      `${dayjs(methods.getValues('date')).format('YYYY.MM.DD (ddd)')} 수동배차 #${
        dayRoutes?.manualRoutes.count ? dayRoutes?.manualRoutes.count + 1 : 1
      }`,
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  }, [methods.watch('date'), dayRoutes?.manualRoutes.count]);

  return (
    <Sidebar
      bg="RG00"
      width="280px"
      ds="strong"
      index={2}
      sx={{ minWidth: '280px', padding: '40px 20px', borderRadius: 0, borderRight: `1px solid ${theme.colors.RG06}` }}
    >
      <Stack align="end">
        <Button
          styleName="subheadline1"
          type="button"
          variant={'custom'}
          custom={{
            init: {
              color: 'RG03',
              bgColor: 'RG00',
            },
            hover: {
              bgColor: 'RG07',
            },
          }}
          sx={{
            padding: '5px 0',
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <IcArrowDown width={16} height={16} style={{ marginRight: '8px', rotate: '90deg' }} />
          이전으로 이동
        </Button>
      </Stack>
      <Stack useVerticalScroll sx={{ height: '100%' }}>
        <form onSubmit={methods.handleSubmit(res => mutateHasOptimizedDriver(res))}>
          <Stack spacing={30}>
            <Stack align={'start'} spacing={20}>
              <Text styleName="body2" color="RG02">
                배차 설정
              </Text>

              <Stack name="route-perfomance-setting" spacing={10}>
                <Stack align="start" spacing={4}>
                  <Text styleName="caption2" color="RG03">
                    주행 일자
                  </Text>

                  <Stack direction={'row'} sx={{ position: 'relative' }}>
                    <Input
                      variant={'third'}
                      type="text"
                      name="date"
                      placeholder={`${dayjs(new Date()).locale('ko').format('YYYY.MM.DD (ddd)')}`}
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      height={40}
                      readOnly={true}
                      onFocus={() => {
                        setDatePickerIsOpen(true);
                      }}
                      onClick={() => {
                        setDatePickerIsOpen(true);
                      }}
                      errors={methods.formState.errors.date}
                      hasError={Boolean(methods.formState.errors.date)}
                    />

                    {datePickerIsOpen && (
                      <DatePickerPopup
                        banPast
                        dayCheck
                        allowUncheck={false}
                        setIsOpen={setDatePickerIsOpen}
                        setValue={methods.setValue}
                        name={'date'}
                        initDate={methods.getValues('date')}
                        format={'YYYY.MM.DD (ddd)'}
                      />
                    )}
                  </Stack>
                </Stack>

                <Stack align="start" spacing={4}>
                  <Text styleName="caption2" color="RG03">
                    주행 이름
                  </Text>
                  <Input
                    height={40}
                    name="name"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    variant={'third'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (!Boolean(e.target.value) || e.target.value === '') {
                        methods.setError('name', { type: 'required', message: '주행 이름은 필수 입력 사항입니다.' });
                      } else
                        isRouteNameDuplicated(e.target.value).then(res => {
                          if (res.isDuplicated) {
                            methods.setError('name', { type: 'duplicatedName', message: '중복된 주행 이름입니다.' });
                          } else methods.clearErrors('name');
                        });
                    }}
                    validation={{
                      required: { value: true, message: '주행 이름은 필수 입력 사항입니다.' },
                    }}
                    errors={methods.formState.errors.name}
                    hasError={Boolean(methods.formState.errors?.name)}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack spacing={10}>
              <Stack align={'start'} spacing={8}>
                <Text styleName="body2">수동 배차 요약</Text>
                <Stack
                  direction="row"
                  sx={{ margin: '1px 0 0 0' }}
                  spacing={6}
                  divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                >
                  <Text styleName={'subheadline2'} color={'RG03'}>
                    주문&nbsp;
                    <Highlight styleName={'subheadline1'} color={'RC02'}>
                      {manualExcelValidatedData.map(x => x.orderList.length).reduce((x, a) => x + a, 0) ?? 0}
                    </Highlight>
                    &nbsp;개
                  </Text>
                  <Text styleName={'subheadline2'} color={'RG03'}>
                    드라이버&nbsp;
                    <Highlight styleName={'subheadline1'} color={'RC02'}>
                      {manualExcelValidatedData.length}
                    </Highlight>
                    &nbsp;명
                  </Text>
                </Stack>
              </Stack>

              <Stack sx={{ height: 'fit-content' }}>
                <Stack
                  useVerticalScroll
                  spacing={10}
                  sx={{
                    maxHeight: '430px',
                  }}
                >
                  {manualExcelValidatedData.map(d => {
                    return (
                      <DroppableWrapper id={`driver_${d.vehicle.vehicleId}`} sx={{ width: '100%' }}>
                        <DriverItem
                          spacing={10}
                          padding={10}
                          onClick={() => {
                            if (tabIsOpen && targetId === d.vehicle.vehicleId) {
                              setTabIsOpen(false);
                              setTargetId(DriverIdUnSelected);
                            } else {
                              setTargetId(d.vehicle.vehicleId);
                              setTabIsOpen(true);
                            }
                          }}
                          isActive={d.vehicle.vehicleId === targetId}
                        >
                          <Stack direction={'row'}>
                            <Stack sx={{ flex: 1 }} align={'center'} spacing={4} direction={'row'}>
                              <ColorChip style={{ backgroundColor: ColorPalette.getColor(d.vehicle.vehicleId) }} />
                              <Text styleName={'subheadline1'}>{d.vehicle.licenseNumber}</Text>
                            </Stack>

                            <IcArrowRight />
                          </Stack>
                          <Stack
                            direction={'row'}
                            spacing={6}
                            divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                          >
                            <Text styleName={'caption1'} color={'RG03'}>
                              {strings.주문}&nbsp;
                              <Highlight color={'RC02'}>{d.orderList.length}</Highlight>
                              &nbsp;개
                            </Text>
                          </Stack>
                        </DriverItem>
                      </DroppableWrapper>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>

            <Stack name="button-area" spacing={10}>
              <Button
                type={'button'}
                variant={isXlsxUploaded() ? 'second' : 'eighth'}
                styleName="body1"
                color={isXlsxUploaded() ? 'RG04' : 'RG00'}
                fullWidth
                height={40}
                onClick={() => {
                  setXlsxFormIsOpen(true);
                }}
              >
                {isXlsxUploaded() ? '엑셀 재업로드' : '주문 엑셀 업로드'}
              </Button>
              <Button
                type="submit"
                variant="eighth"
                styleName="body1"
                fullWidth
                height={40}
                disabled={
                  !(manualExcelValidatedData.length ?? 0 > 0) ||
                  mutateManualRoutingStatus === 'loading' ||
                  Boolean(methods.formState.errors?.name)
                }
              >
                배차 계획 저장
              </Button>
            </Stack>
          </Stack>
        </form>
        <Modal isModalOpen={subscriptionLimitModal} setIsModalOpen={setSubscriptionLimitModal} padding={24} width={503}>
          <Stack>
            <Stack>
              <IcWarning width={44} height={44} />
              <Text
                styleName="body1"
                color="RC04"
                sx={{
                  padding: '30px 0 8px 0',
                }}
              >
                {subscriptionLimitReason === 'peronce'
                  ? '1회당 배차 가능한 경유지 수를 초과하였습니다.'
                  : '배차 가능한 누적 경유지 수를 초과하였습니다.'}
              </Text>
              <Stack justify="center" direction="row" spacing={8} sx={{ marginBottom: '40px' }}>
                {pricing_id && <PlanStatusTag text={planMap.get(pricing_id)!.key} />}

                <Text styleName="body2" color="RG02">
                  {subscriptionLimitReason === 'peronce'
                    ? subscribe_quota[planMap.get(pricing_id)!.key].perOnce
                    : subscribe_quota[planMap.get(pricing_id)!.key].quota}
                  개 제한
                </Text>
              </Stack>
            </Stack>
            <NButton
              type={'button'}
              variant="primary"
              h={40}
              sx={{ width: '100%' }}
              onClick={() => {
                navigator('/mypage', { state: { head: 'usage-and-plan' } });
              }}
            >
              <Text styleName="body1" color="RG00">
                요금제 업그레이드 하기
              </Text>
            </NButton>
            <NButton
              type={'button'}
              variant="tertiary"
              h={40}
              sx={{ width: '100%', marginTop: '20px' }}
              onClick={() => {
                setSubscriptionLimitModal(false);
              }}
            >
              <Text styleName="body1" color="RG03">
                확인
              </Text>
            </NButton>
          </Stack>
        </Modal>
        <Modal
          isModalOpen={mutateManualRoutingModalIsOpen}
          padding={24}
          plain
          width={504}
          setIsModalOpen={setMutateManualRoutingModalIsOpen}
        >
          {mutateManualRoutingStatus === 'loading' && (
            <Stack>
              <Stack spacing={12} sx={{ padding: '20px 0' }}>
                <Text styleName="title1" color="RG02" sx={{ justifyContent: 'center' }}>
                  배차 계획을 저장하고 있습니다...
                </Text>
              </Stack>
              <Stack useVerticalScroll style={{ maxHeight: 'calc(100vh - 300px)' }}>
                <Lottie style={{ width: '220px' }} animationData={truck} />
                <Stack
                  name="content-area"
                  sx={{ padding: '10px', borderRadius: '8px', background: theme.colors.RC03_1 }}
                  spacing={8}
                  align={'start'}
                >
                  <Text styleName="subheadline1" color="RC02">
                    배차 설정
                  </Text>
                  {info &&
                    Object.keys(info).map(key => {
                      const mapper: { [key in keyof typeof info]: string } = {
                        name: '배차 이름',
                        date: '주행일',
                        totalDriver: strings.모달_드라이버,
                        totalOrder: strings.모달_주문,
                      };
                      return (
                        <Text styleName="subheadline1" color="RG02" sx={{ textAlign: 'start' }}>
                          <Highlight styleName="subheadline2">{mapper[key as keyof typeof info]}</Highlight> :{' '}
                          {info[key as keyof typeof info]}
                        </Text>
                      );
                    })}
                </Stack>
              </Stack>
            </Stack>
          )}

          {mutateManualRoutingStatus === 'success' && (
            <Fragment>
              <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
              <Text styleName="subheadline2" color="RG02" sx={{ marginTop: '30px' }}>
                배차 계획을 저장하였습니다.
              </Text>
              <Button
                variant={'seventh'}
                styleName={'body1'}
                color={'RG03'}
                type={'button'}
                fullWidth
                height={40}
                sx={{ marginTop: '40px' }}
                onClick={() => {
                  setMutateManualRoutingModalIsOpen(false);
                  navigate('/manage/control/activated', { replace: true });
                }}
              >
                확인
              </Button>
            </Fragment>
          )}

          {mutateManualRoutingStatus === 'error' && (
            <Fragment>
              <IconError fill={theme.colors.RC04} style={{ width: '44px', height: '44px' }} />
              <Text styleName="subheadline2" color="RG02" sx={{ marginTop: '30px' }}>
                배차 계획을 저장하지 못하였습니다.
              </Text>
              <Button
                variant={'seventh'}
                styleName={'body1'}
                color={'RG03'}
                type={'button'}
                fullWidth
                height={40}
                sx={{ marginTop: '40px' }}
                onClick={() => {
                  canceler();
                  setMutateManualRoutingModalIsOpen(false);
                }}
              >
                확인
              </Button>
            </Fragment>
          )}
        </Modal>
      </Stack>

      <HasOptimizedDriverWarningModal
        isOpen={hasOptimizedDriver}
        setIsOpen={setHasOptimizedDriver}
        onClickFunc={methods.handleSubmit(onSubmit)}
      />
    </Sidebar>
  );
};

export default ManualRoutingNav;
