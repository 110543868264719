import { Stack } from 'components/Stack';
import { TMod } from 'pages/RoutePlanConfirm';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { useRef, type Dispatch, type SetStateAction } from 'react';
import Prev from 'components/Button/Prev';

import { Text } from 'components/Typography';
import { ReactComponent as IcSetting } from 'constants/icon/ic_setting.svg';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { Button } from 'components/Button/legacy_index';
import theme from 'constants/theme';
import { is_temp_units_exceeded } from 'constants/commons';
import SpeechBubble from 'components/Popup/SpeechBubble';

const TopbarContent = styled(Stack)`
  width: max-content;
`;

const TopbarContainer = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 60px;
  min-height: 60px;
  max-height: 60px;

  padding: 14px 10px;
  box-sizing: 'border-box';

  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ${({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.RG06}`,
  })};

  & > ${TopbarContent} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Topbar = ({
  mod,
  editedOrders,
  editedDrivers,
  recalc,
  setBTOConfirmModalIsOpen,
  setRouteOptionsChangeModalIsOpen,
  setRouteRegisterConfirmModalIsOpen,
}: {
  mod: TMod;
  editedOrders: string[];
  editedDrivers: {
    driverId: number;
    vehicle: {
      vehicleId: number;
    };
    orderList: [
      {
        orderId: number;
        type?: string;
      }
    ];
  }[];
  recalc: () => void;
  options_methods: UseFormReturn<any>;
  setBTOConfirmModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setRouteOptionsChangeModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setRouteRegisterConfirmModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const recalc_btn_ref = useRef<HTMLButtonElement>(null);
  return (
    <TopbarContainer name="top-bar">
      <TopbarContent>
        <Prev
          gap={6}
          string="배차설정 다시 하기"
          color="RG03"
          styleName="caption1"
          height="100%"
          onClick={() => {
            setBTOConfirmModalIsOpen(true);
          }}
        />
      </TopbarContent>

      <TopbarContent name="action-button-area">
        <Stack spacing={12} direction="row">
          <Button
            ref={recalc_btn_ref}
            variant={'second'}
            type={'button'}
            styleName={'caption1'}
            color={'RG03'}
            width={126}
            height={32}
            disabled={mod !== 'auto' || editedDrivers.length > 0}
            onClick={() => {
              setRouteOptionsChangeModalIsOpen(true);
            }}
          >
            <IcSetting width={16} height={16} fill={theme.colors.RG04} style={{ marginRight: '8px' }} />
            배차 옵션 설정
          </Button>
          {editedDrivers.length > 0 && (
            <SpeechBubble
              anchor="top"
              bOffset={150}
              isOpen={true}
              setIsOpen={() => {}}
              icon={<IconWarning width={20} height={20} />}
              sx={{
                top: recalc_btn_ref.current?.getBoundingClientRect()?.top
                  ? recalc_btn_ref.current?.getBoundingClientRect()?.top - 40
                  : 0,
                left: recalc_btn_ref.current?.getBoundingClientRect()?.left
                  ? recalc_btn_ref.current?.getBoundingClientRect()?.left + 22
                  : 0,
              }}
            >
              <Text
                styleName="caption1"
                color="RG00"
                onClick={() => {
                  console.log(editedDrivers);
                }}
              >
                수정된 주문이 있습니다.
                <br />
                재계산을 눌러주세요.
              </Text>
            </SpeechBubble>
          )}

          <Button
            variant={'custom'}
            type={'button'}
            height={32}
            width={80}
            disabled={editedDrivers.length === 0 || is_temp_units_exceeded()}
            styleName="caption1"
            onClick={() => {
              recalc();
            }}
            custom={{
              init: {
                color: 'RC02',
                bgColor: 'RC03_1',
                borderColor: 'RC03_3',
              },
              hover: {
                color: 'RG00',
                bgColor: 'RC02',
                borderColor: 'RC03_3',
              },
              disabled: {
                color: 'RG05',
                bgColor: 'RG07',
                borderColor: 'RG06',
              },
            }}
            sx={{ padding: '8px 10px' }}
          >
            재계산
          </Button>
          <Button
            variant={'default'}
            type={'button'}
            styleName="caption1"
            color="RG00"
            width={154}
            height={32}
            disabled={editedDrivers.length > 0}
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              setRouteRegisterConfirmModalIsOpen(true);
            }}
          >
            배차 계획 저장
          </Button>
        </Stack>
      </TopbarContent>
    </TopbarContainer>
  );
};
