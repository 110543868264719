import React, { useEffect } from 'react';

import { Stack } from 'components/Stack';

import Usage from './Usage';
import { Plan, Unsubscribe } from './index';
import { useTracking } from '../../../hooks/store/useTracking';

const UsageAndPlan = () => {
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('마이페이지>사용량 및 요금제 관리');
  }, [setTitle]);

  return (
    <Stack spacing={100} align="start" sx={{ marginBottom: 100 }}>
      <Usage />
      <Plan />
      <Unsubscribe />
    </Stack>
  );
};

export default UsageAndPlan;
