import { Dispatch, forwardRef, SetStateAction } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import { Text } from 'components/Typography';

import { signout } from 'api';
import { useStore } from 'store';
import { PlanStatusUnion } from 'types/payment/union';
import { PlanStatusTag } from 'components/Tag/new/PlanStatus';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';

const MyPagePopup = forwardRef(
  ({ setMyPagePopupIsOpen }: { setMyPagePopupIsOpen: Dispatch<SetStateAction<boolean>> }, ref) => {
    const { data: paymentMyData } = useGetPaymentMy();
    const { userGrade } = useStore();

    const navigator = useNavigate();

    const gradeMap = new Map([
      [1, { grade: 1, e: 'owner', k: '오너' }],
      [2, { grade: 2, e: 'manager', k: '매니저', tagStyle: { color: 'RC10', borderColor: 'RC10' } }],
    ]);

    return (
      <Container ref={ref as any}>
        <MyPageCard>
          <Stack divider={<Divider color="RG06" />}>
            <Stack spacing={40} className="content" sx={{ paddingTop: 20, paddingBottom: 40 }}>
              <Stack spacing={10} align="start">
                <Text styleName="title2" align="start" broken>
                  {window.localStorage.getItem('name')}
                </Text>
                <Text styleName="subheadline2" color="RG03" align="start" broken>
                  {window.localStorage.getItem('email')}
                </Text>
                <Stack direction="row" spacing={10}>
                  <MyPageCardTag
                    {...(gradeMap.get(userGrade)?.tagStyle as { color: TThemeColor; borderColor?: TThemeColor })}
                  >
                    {gradeMap.get(userGrade)?.k}
                  </MyPageCardTag>
                  <PlanStatusTag text={paymentMyData?.name as PlanStatusUnion | undefined} />
                </Stack>
              </Stack>

              <Stack>
                <MyPageCardButton
                  onClick={e => {
                    setMyPagePopupIsOpen(false);
                    navigator('/mypage', { state: { head: 'account' } });
                  }}
                >
                  기본 정보 관리
                </MyPageCardButton>
                <MyPageCardButton
                  onClick={e => {
                    setMyPagePopupIsOpen(false);
                    navigator('/mypage', { state: { head: 'usage-and-plan' } });
                  }}
                >
                  결제 정보 관리
                </MyPageCardButton>
                <MyPageCardButton
                  onClick={e => {
                    window.open('https://forms.gle/zZ4MWAJFvhBmp7ZA9', '_blank');
                  }}
                >
                  문의하기
                </MyPageCardButton>
              </Stack>
            </Stack>
            <Stack className="content">
              <MyPageCardButton onClick={() => signout()}>로그아웃</MyPageCardButton>
            </Stack>
          </Stack>
        </MyPageCard>
      </Container>
    );
  }
);

export default MyPagePopup;

const Container = styled.div`
  position: absolute;
  top: 82px;
  right: 15px;

  z-index: 200;
`;

const MyPageCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  flex-direction: column;

  width: 280px;

  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  background-color: ${({ theme }) => theme.colors.RG00};

  transition: 0.3s;
  transition-property: width, height;

  box-shadow: ${({ theme }) => theme.shadows.strong};

  .content {
    padding: 0 20px;

    overflow-y: scroll;
    max-height: calc(90vh - 48px);
  }
`;

const MyPageCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 100%;

  padding: 12px 0;

  ${({ theme }) => theme.fontStyle.body2}
`;

export const MyPageCardTag = styled.div<{ color?: TThemeColor; borderColor?: TThemeColor }>`
  display: flex;
  align-items: center;

  padding: 2px 10px;
  border-radius: 4px;

  ${({ theme, color, borderColor }) => ({
    color: theme.colors[color ?? 'RC02'],
    border: `1px solid ${theme.colors[borderColor ? borderColor : color ?? 'RC02']}`,
    backgroundColor: theme.colors.RG00,
  })}

  ${({ theme }) => theme.fontStyle.caption1};

  pointer-events: none;
`;
