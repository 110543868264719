import { ChangeEvent, ComponentPropsWithRef, Dispatch, SetStateAction } from 'react';
import { Input } from './index';

export interface ManagerInfoInputProps extends ComponentPropsWithRef<'input'> {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  isCheckedValidateAction: (data: string) => boolean;
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  onValidation: (validate: boolean, value: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ManagerInfoInput = ({
  text,
  setText,
  isCheckedValidateAction,
  hasError,
  setHasError,
  errorMessage,
  onValidation,
  onChange,
  ...props
}: ManagerInfoInputProps) => {
  return (
    <Input
      {...props}
      value={text}
      height={40}
      hasError={hasError}
      errors={{ message: errorMessage }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const validate = isCheckedValidateAction(value);

        onValidation(validate, value);

        setText(e.target.value);
        onChange?.(e);
      }}
    />
  );
};
