import type { Dispatch, SetStateAction } from 'react';
import { isFalsy } from 'util/isFalsy';
import { isTruthy } from 'util/isTruthy';

import { storeToken } from 'auth/common';
import theme from 'constants/theme';

import type { UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  getValidateLoginAccount,
  getValidateVerifyCode,
  postPaymentTrial,
  postRequestVerifyCode,
  postSignUp,
} from 'auth/signUp/api';

export const _switchOpen = (state: boolean, _dispatch: Dispatch<SetStateAction<boolean>>) => {
  _dispatch(!state);
};

export const __switchTerm = (params: {
  payload: TTermsOfServiceList;
  termsOfService: TTermsOfServiceList[];
  setValue: UseFormSetValue<TPrivateInfoType>;
}) => {
  const { payload, setValue, termsOfService } = params;

  let _termsOfService = termsOfService!.slice();
  const _payload = Object.assign({}, payload, { checked: !payload.checked });

  _termsOfService = termsOfService!.map(term => {
    if (payload.key === 'ALL') {
      setValue('marketing', _payload.checked);
      if (_payload.checked) setValue('termsOfService', _payload.checked);
      term.checked = _payload.checked;
    } else if (term.key === 'PRIVATE' && _payload.key === 'SERVICE') {
      if (_payload.checked && term.checked) setValue('termsOfService', _payload.checked);
    }

    if (term.key === 'SERVICE' && _payload.key === 'PRIVATE') {
      if (_payload.checked && term.checked) setValue('termsOfService', _payload.checked);
    }

    if (_payload.key === 'MARKETING') setValue('marketing', _payload.checked);

    // 모두 동의 선택 후, 전체중 하나라도 미동의를 할 경우 전체 동의 비활성화
    if (_payload.checked === false && termsOfService![0].checked === true) {
      termsOfService![0].checked = false;
    }

    if (term.key === payload.key) {
      return _payload;
    }
    return term;
  });
  return _termsOfService;
};

export const switchCheck = (check: boolean) => (check ? theme.colors.RC02 : theme.colors.RG05);

export const validateName = (name: string): boolean => {
  const regex = /^[a-z|A-Z|0-9|가-힣|~!@#$%^&*().,_+|<>?:{} /]{2,20}$/;
  return regex.test(name);
};

export const __checkSignupForm = (params: {
  getValues: UseFormGetValues<TPrivateInfoType>;
  errors: TPrivateTypeHookFormError;
  loginAccount: string | null;
}): boolean => {
  const { getValues, errors, loginAccount } = params;

  console.log(errors);

  const values = Object.values(getValues()).slice(0, 4);

  if (values.length === 0) return true;

  const checkValues = values.every(Boolean);

  if (isFalsy(checkValues) || isTruthy(errors)) return true;

  if (isFalsy(loginAccount)) return true;

  return false;
};

export const _validateLoginAccount = async (
  loginAccount: string,
  setErrors: UseFormSetError<TPrivateInfoType>,
  clearErrors: UseFormClearErrors<TPrivateInfoType>,
  setIsDisabledVerifyCodeBtn: Dispatch<SetStateAction<boolean>>
) => {
  const response = await getValidateLoginAccount({ loginAccount });
  const error = response as TErrorResponse;

  const _response = response as { hasAccount: boolean };
  if (error?.advice) {
    return setIsDisabledVerifyCodeBtn(true);
  }

  if (_response.hasAccount) {
    setIsDisabledVerifyCodeBtn(true);
    return setErrors('loginAccount', { message: '이미 가입한 계정입니다.' });
  }
  setIsDisabledVerifyCodeBtn(false);
  clearErrors('loginAccount');
};

export const __updateSignUpForm = async (params: {
  _dispatch: Dispatch<TSignUpAction>;
  payload: Partial<TSignUpForm>;
}) => {
  const { _dispatch, payload } = params;
  _dispatch({ type: 'UPDATE_SIGN_UP_FORM', payload: [payload] });
};

export const __rerequestVerifyCode = async (
  loginAccount: string,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  exceedCallback: Function,
  setIsOverCount: Dispatch<SetStateAction<boolean>>,
  setIsLoading: (isLoading: boolean) => void
) => {
  const payload = { loginAccount: loginAccount };
  const response = await postRequestVerifyCode(payload);

  const _response = response as { requestCount: number };
  const error = response as TErrorResponse;

  if (error.advice) {
    setIsLoading(false);
    exceedCallback();
    return error;
  }

  if (_response.requestCount % 3 === 0) {
    setIsOverCount(true);
  }
  setIsLoading(false);
  return setIsModalOpen(true);
};

export const _updateVerifyCode = (
  code: string,
  state: TPrivateVerificationCodeInput,
  setState: Dispatch<SetStateAction<TPrivateVerificationCodeInput>>
) => {
  const _state = Object.assign({}, state, { codes: code });
  setState(_state);
};

export const _validateVerifyCode = async (
  code: string,
  loginAccount: string,
  state: TPrivateVerificationCodeInput,
  setFormState: Dispatch<SetStateAction<TPrivateVerificationCodeInput>>
) => {
  const payload = { loginAccount: loginAccount, code: code };
  const response = await getValidateVerifyCode(payload);
  const error = response as TErrorResponse;

  if (error?.message) {
    const _state = Object.assign({}, state, { isError: true, codes: '' });
    return setFormState(_state);
  }
  const _state = Object.assign({}, state, { isError: false, isSuccess: true });
  return setFormState(_state);
};

export const _resetVerifyCode = (
  state: TPrivateVerificationCodeInput,
  setFormState: Dispatch<SetStateAction<TPrivateVerificationCodeInput>>
) => {
  const _state = Object.assign({}, state, { isError: false, isSuccess: false, codes: '' });
  return setFormState(_state);
};

export const __switchSignUpModal = (params: {
  _modalDispatch: Dispatch<SetStateAction<TSignUpPrivateTypeFormModalState>>;
  payload: TSignUpPrivateTypeFormModalState | 'off';
}) => {
  const { _modalDispatch, payload } = params;
  let state;
  const initialState = { type: '' as TSignUpPrivateTypeFormModalType, isOpen: false, params: {} };
  if (payload === 'off') {
    state = initialState;
  } else {
    state = payload;
  }
  _modalDispatch(state);
};

export const updatePrivateFormState = (params: {
  getValues: UseFormGetValues<TPrivateInfoType>;
  _dispatch: Dispatch<TSignUpAction>;
}) => {
  const { getValues, _dispatch } = params;
  const { password, name } = getValues();
  _dispatch({ type: 'UPDATE_SIGN_UP_FORM', payload: [{ name }, { password }] });
};

const applyPaymentTrial = async () => {
  const response = await postPaymentTrial();
  const error = response as TErrorResponse;
  if (error?.message) return false;
  return true;
};

export const __signUp = async (params: {
  signUpForm: TSignUpForm;
  getValues: UseFormGetValues<TPrivateInfoType>;
  setIsLoading: (isLoading: boolean) => void;
  setWelcomePageMode: Dispatch<SetStateAction<TWelcomePageType>>;
}) => {
  const {
    signUpForm: { companyName, businessNumber, industryType },
    getValues,
    setIsLoading,
    setWelcomePageMode,
  } = params;
  const { phone, name, loginAccount, password, marketing } = getValues();

  const payload = {
    phone,
    companyName,
    businessNumber,
    industryType,
    name,
    loginAccount,
    password,
    marketingConsent: Boolean(marketing),
  };

  if (!businessNumber) delete payload.businessNumber;

  const response = await postSignUp(payload);
  const error = response as TErrorResponse;
  const _response = response as TSignUpResponse;

  if (error?.message) {
    setIsLoading(false);
    throw new Error('회원가입 서비스 이상 모달 필요');
  }
  // storeToken(_response);

  // if signup/owner && kill this
  // await applyPaymentTrial();
  setIsLoading(false);
  return setWelcomePageMode('Free');
  // return setWelcomePageMode('Beta');
  // if (isPassed) {
  //   setIsLoading(false);
  // return setWelcomePageMode('Trial');
  // }
  // setIsLoading(false);
  // return setWelcomePageMode('DoneTrial');
};
