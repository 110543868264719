import { Stack } from 'components/Stack/style';
import styled, { css } from 'styled-components';
import { SwapProps } from '.';

interface ISwapnIndicator extends SwapProps {
  selectedIndex: number;
}

// export const SwapnWrapper = styled.div`
//   width: 100%;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;

//   border-radius: 6px;

//   ${({ theme }) => ({
//     border: `1px solid ${theme.colors.RG07}`,
//     background: theme.colors.RG00,
//   })}

//   position: relative;
// `;

// export const SwapnItemsWrapper = styled.div<SwapProps>`
//   width: 100%;
//   display: flex;
//   gap: 8px;
//   padding: 7px;
//   max-width: ${({ maxWidth }) => maxWidth || 540}px;
// `;

// export const SwapnItems = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;

//   ${({ theme }) => ({
//     ':hover': {
//       backgroundColor: theme.colors.RG08,
//     },
//   })};

//   border-radius: 4px;
//   height: 26px;
//   box-sizing: border-box;
//   background-clip: content-box;

//   transition: background 0.3s;
// `;

// export const SwapnIndicatorWrapper = styled.div<SwapProps>`
//   pointer-events: none;
//   display: flex;

//   position: absolute;
//   height: 40px;
//   max-width: ${({ maxWidth }) => maxWidth || 540}px;
//   width: 100%;
//   padding: 7px;
// `;

// const setTranslateXPosition = (optionLength: number, index: number) => {
//   if (index === 0) return `0%`;
//   else return `calc(${100 * index}% + var(--padding) / ${optionLength - index})`;
// };

// export const SwapnIndicator = styled.div<ISwapnIndicator>`
//   --padding: ${({ options, maxWidth }) =>
//     `calc(min(100vw, ${maxWidth ?? 540}px) - (100% * ${options.length}) - (8px * ${options.length}))`};

//   ${({ theme }) => ({
//     backgroundColor: theme.colors.RC03_1,
//   })};

//   ${({ selectedIndex, options }) => ({
//     // width: 100 / options.length + '%',
//     width: `calc(${100 / options.length}%  - ${
//       selectedIndex > 0 ? (selectedIndex + 1) * 2 : (selectedIndex + 1) * 4
//     }px)`,

//     // width: `calc(${100 / options.length}%  - ${(selectedIndex + 1) * 8}px)`,
//     transform: `translateX(${setTranslateXPosition(options.length, selectedIndex)})`,
//   })};

//   border-radius: 4px;
//   height: 26px;
//   box-sizing: border-box;
//   background-clip: content-box;

//   transition: transform 0.3s;
// `;

export const SwapContainer = styled(Stack)``;

// export const SwapWrapper = styled.div`
//   width: 100%;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;

//   border-radius: 6px;

//   ${({ theme }) => ({
//     border: `1px solid ${theme.colors.RG07}`,
//     background: theme.colors.RG00,
//   })}

//   position: relative;
// `;

export const SwapItemsWrapper = styled.div<SwapProps>`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => gap || '8px'}px;
  padding: ${({ padding }) => padding || '7px'}px;
  max-width: ${({ maxWidth }) => maxWidth || 540}px;

  button:first-child {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  button:not(:first-child):not(:last-child) {
    border-radius: 0;
    margin-left: -1px;
  }
  button:last-child {
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: -1px;
  }
`;

export const SwapItems = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;

  border-radius: 4px;
  box-sizing: border-box;
  background-clip: content-box;

  ${({ theme, isSelected }) => ({
    zIndex: isSelected ? 2 : 1,
    background: theme.colors[isSelected ? 'RC03_1' : 'RG00'],
    border: `1px solid ${theme.colors[isSelected ? 'RC03_3' : 'RG06']}`,
  })};

  cursor: pointer;
  transition: background 0.3s;
`;

// 리팩터링 예정
export const liabilitiesSwapWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .btn.active {
    background: rgba(118, 157, 255, 0.1);
    color: #406ee2;
  }
`;
export const liabilitiesSwap = styled.button`
  width: 50%;
  max-width: 240px;
  height: 40px;
  background: none;
  border: 1px solid rgba(118, 157, 255, 0.3);
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #8f96a9;
  :hover {
    background: rgba(118, 157, 255, 0.1);
    color: #406ee2;
  }
`;
export const deleteVehicleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 102px;
  height: 32px;
  padding: 9px 28px;
  color: #565b69;
  background: #ffffff;
  border: 1px solid #d8ddea;
  border-radius: 8px;
  :hover {
    background: #f0f3f9;
    border: 1px solid #d8ddea;
  }
`;
