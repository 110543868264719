import theme from 'constants/theme';
import { ReactComponent as ProfileIcon } from 'constants/icon/icon_profile_mono.svg';
import { ReactComponent as TruckIcon } from 'constants/icon/icon_truck_mono.svg';
import { Text } from 'components/Typography';
import * as A from 'pages/Setting/organization/Manager/style';

interface ISelectorConvertor {
  list: TTeamListState[];
}

export const SelectItemConvertor = (props: ISelectorConvertor) => {
  const { list } = props;
  if (list) {
    return list.map(team => {
      return {
        key: team.name,
        value: team.teamId,
        extra: (
          <A.ManagerSelectorItems>
            <TruckIcon />
            <Text styleName="caption2" color="RG02">
              드라이버
            </Text>
            <Text styleName="caption1" color="RG02">{`${team.driverCount} 명`}</Text>

            <ProfileIcon />
            <Text
              styleName="caption2"
              color="RG02"
              sx={{ paddingLeft: '8px', lineHeight: '12px', borderLeft: `1px solid ${theme.colors.RG06}` }}
            >
              매니저
            </Text>
            <Text styleName="caption1" color="RG02">{`${team.managerCount} 명`}</Text>
          </A.ManagerSelectorItems>
        ),
      };
    });
  }
  return [];
};
