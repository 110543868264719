import { InputLabel } from 'auth/common';
import { switchSignUpMode } from 'auth/signUp/container';
import { BusinessNumberInput } from 'auth/signUp/corporateTypeForm/BusinessNumberInput';

import {
  isFilledCorporateForm,
  updateCompananyName,
  updateCorperateInfo,
} from 'auth/signUp/corporateTypeForm/container';
import * as S from 'auth/signUp/style';

import * as CS from 'auth/style';
import { Button } from 'components/Button/legacy_index';
import { DropdownSelector } from 'components/Dropdown/DropdownSelector';

import { Input } from 'components/Input';
import { ReactComponent as IcArrowDropDown } from 'constants/icon/ic_arrow_dropdown.svg';
import { Dispatch, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTracking } from '../../../hooks/store/useTracking';

interface ICorperationInfo {
  _state: TSignUpState;
  _dispatch: Dispatch<TSignUpAction>;
}

export const CorporationTypeForm = (props: ICorperationInfo) => {
  const { _state, _dispatch } = props;

  const [IsDisabled, setIsDisabled] = useState<boolean>(true);
  const { industryTypeList, signUpForm } = _state;

  const {
    register,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<TSignUpForm>();
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('회원가입>회사정보 입력');
  }, [setTitle]);

  useEffect(() => {
    ['companyName', 'businessNumber', 'industryType'].forEach(key => {
      setValue(key, signUpForm[key]);
    });
    isFilledCorporateForm(getValues, errors, setIsDisabled);
  }, []);

  return (
    <Fragment>
      <CS.InputLabel>
        <InputLabel label="회사 이름" />
        <S.InputSection>
          <Input
            name="companyName"
            placeholder="국문 또는 영문 이름을 입력해주세요. 예시) (주)위밋모빌리티"
            variant="fifth"
            register={register}
            onBlur={() => {
              const { companyName } = getValues();
              updateCompananyName({ companyName, setError, clearErrors });
              isFilledCorporateForm(getValues, errors, setIsDisabled);
            }}
            onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
              if (event.key === 'Enter') {
                const { companyName } = getValues();
                updateCompananyName({ companyName, setError, clearErrors });
                isFilledCorporateForm(getValues, errors, setIsDisabled);
              }
            }}
            hasError={errors.companyName}
            errors={errors.companyName}
          />
        </S.InputSection>
      </CS.InputLabel>

      <BusinessNumberInput
        successCallback={() => {
          isFilledCorporateForm(getValues, errors, setIsDisabled);
        }}
        setIsDisabled={setIsDisabled}
        _dispatch={_dispatch}
        _state={_state}
        setError={setError}
        setBusinessNumber={setValue}
      />

      <CS.InputLabel>
        <InputLabel label="산업 분야 선택" />
      </CS.InputLabel>

      <DropdownSelector
        sx={{ width: '100%', height: '48px', marginTop: '8px' }}
        anchor="bottom"
        defaultValue={signUpForm.industryType}
        placeholder="산업 분야를 선택해주세요."
        fullWidth
        maxHeight={160}
        selectorGap={30}
        options={industryTypeList}
        showExtra
        onOptionsClick={item => {
          setValue('industryType', item.value);
          isFilledCorporateForm(getValues, errors, setIsDisabled);
        }}
        icon={<IcArrowDropDown />}
      />
      <Button
        styleName="body1"
        color="RG00"
        variant="default"
        type="button"
        height={48}
        fullWidth
        disabled={IsDisabled}
        sx={{ marginTop: '40px' }}
        onClick={() => {
          updateCorperateInfo({ _dispatch, getValues });
          switchSignUpMode({ _dispatch, mode: 'PRIVATE' });
        }}
      >
        다음 단계로 이동
      </Button>
    </Fragment>
  );
};
