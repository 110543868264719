import { Tag } from 'components/Tag/new/index';
import { TOperationType } from 'constants/types';

interface OperationTypeTagProps {
  type: TOperationType;
}

const OperationTypeTag = ({ type }: OperationTypeTagProps) => {
  const mapper = {
    backup: { string: '용차', color: 'RC10' },
    regular: { string: '고정차', color: 'RC02' },
  };

  return (
    <Tag
      text={mapper[type]?.string}
      variant={'primary'}
      height={24}
      color={mapper[type]?.color}
      sx={{
        display: 'inline-flex',
        border: 'unset',
        padding: '2px 6px',
        whiteSpace: 'nowrap',
      }}
    />
  );
};

export { OperationTypeTag };
export type { OperationTypeTagProps };
