import type { Dispatch, SetStateAction, CSSProperties } from 'react';
import styled from 'styled-components';

import { useStore } from 'store';

import { Stack } from 'components/Stack';
interface IMyPageSidebar {
  currentComponent: TMyPageComponents;
  setCurrentComponent: Dispatch<SetStateAction<TMyPageComponents>>;
  // componentList: { [key in TMyPageComponents]: TMyPageSidebarType };
  componentList: {
    [key: string]: {
      label: string;
      contents: { [key: string]: TMyPageSidebarType };
    };
  };
  sx?: CSSProperties;
}

export const MyPageSidebar = (props: IMyPageSidebar) => {
  const { currentComponent, setCurrentComponent, componentList, sx } = props;

  const { userGrade } = useStore();

  return (
    <NavWrapper>
      <Stack spacing={32} useVerticalScroll sx={sx}>
        {Object.values(componentList).map(component => {
          const { label: sectionLabel, contents } = component;
          return (
            <Stack key={`${sectionLabel}_key`} align="start" spacing={10}>
              <NavLabel>{sectionLabel}</NavLabel>
              {Object.keys(contents)
                .filter(contentKey => (userGrade === 2 ? contentKey !== 'payment' : contentKey))
                .map(contentKey => {
                  const _contentKey = contentKey as TMyPageComponents;
                  const { label, key } = contents[_contentKey];
                  return (
                    <Stack key={`${key}_key`} spacing={6} align="start">
                      <NavItem
                        isActive={key === currentComponent}
                        disabled={false}
                        onClick={() => setCurrentComponent(key)}
                      >
                        {label}
                      </NavItem>
                    </Stack>
                  );
                })}
            </Stack>
          );
        })}
      </Stack>
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};
  border-bottom-style: none;
  border-top-style: none;
`;

const NavLabel = styled.div`
  ${({ theme }) => theme.fontStyle.caption1}
  width: 157px;
  height: 100%;
  margin: 0px 20px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.RG04};
  background-color: transparent;
`;

const NavItem = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  ${({ theme }) => theme.fontStyle.caption2}
  width: 157px;
  height: 100%;
  padding: 6px 8px;
  margin: 0px 20px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.RG02};
  background-color: transparent;

  ${({ disabled }) =>
    disabled && {
      cursor: 'not-allowed',
    }}
  ${({ isActive, theme }) => isActive && theme.fontStyle.caption1};
  ${({ isActive, theme }) =>
    isActive && {
      color: theme.colors.RC02,
      backgroundColor: theme.colors.RC03_1,
    }}
  &.active {
    ${({ theme }) => theme.fontStyle.caption1}
    color: ${({ theme }) => theme.colors.RC02};
    background-color: ${({ theme }) => theme.colors.RC03_1};
  }

  cursor: pointer;
`;
