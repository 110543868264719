import styled, { css } from 'styled-components';
import { TagProps } from '.';

const TagBaseStyle = css<TagProps>`
  ${({ theme, styleName }) => styleName && theme.fontStyle[styleName]}

  min-width: 58px;

  padding: 2px 6px;
  border-radius: 6px;

  outline-offset: -1px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  ${({ fit }) => fit && { minWidth: 'fit-content' }};
`;

const TagTooltipBaseStyle = css<TagProps>`
  ${({ theme, styleName }) => styleName && theme.fontStyle[styleName]}

  min-width: 45px;

  padding: 0px 4px;
  border-radius: 4px;

  outline-offset: -1px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  ${({ fit }) => fit && { minWidth: 'fit-content' }};
`;

export const Done = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ disable, theme, outline }) =>
    disable
      ? {
          color: theme.colors.RG04,
          background: theme.colors.RG07,
          pointerEvents: 'none',
        }
      : {
          color: theme.colors.RG00,
          background: theme.colors.RC02,
          outline: outline ? `1px solid ${theme.colors.RC03_1}` : 'none',
        }}


  ${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          background: theme.colors.RC02,
        }
      : {
          ':hover': {
            color: theme.colors.RG00,
            background: theme.colors.RC02,
          },
        }}
`;

export const DoneTooltip = styled.span<TagProps>`
  ${TagTooltipBaseStyle}

  ${({ theme }) =>
    theme && {
      color: theme.colors.RC02,
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RG06}`,
    }}}  
`;

export const cancel = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ disable, theme, outline }) =>
    disable
      ? {
          color: theme.colors.RG04,
          background: theme.colors.RG07,
          pointerEvents: 'none',
        }
      : {
          color: theme.colors.RG00,
          background: theme.colors.RC04,
          outline: outline ? `1px solid ${theme.colors.RC04_2}` : 'none',
        }}

  ${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          background: theme.colors.RC04,
        }
      : {
          ':hover': {
            color: theme.colors.RG00,
            background: theme.colors.RC04,
          },
        }}
`;

export const Todo = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ disable, theme, outline }) =>
    disable
      ? {
          color: theme.colors.RG04,
          background: theme.colors.RG07,
          pointerEvents: 'none',
        }
      : {
          color: theme.colors.RG00,
          background: theme.colors.RC09,
          outline: outline ? `1px solid ${theme.colors.RC09_2}` : 'none',
        }}


${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          background: theme.colors.RC09,
        }
      : {
          ':hover': {
            color: theme.colors.RG00,
            background: theme.colors.RC09,
          },
        }}
`;

export const Moving = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ disable, theme, outline }) =>
    disable
      ? {
          color: theme.colors.RG04,
          background: theme.colors.RG07,
          pointerEvents: 'none',
        }
      : {
          color: theme.colors.RG00,
          background: theme.colors.RC10,
          outline: outline ? `1px solid ${theme.colors.RC10_2}` : 'none',
        }}



${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          background: theme.colors.RC10,
        }
      : {
          ':hover': {
            color: theme.colors.RG00,
            background: theme.colors.RC10,
          },
        }}
`;

export const MovingTooltip = styled.span<TagProps>`
  ${TagTooltipBaseStyle}

  ${({ theme }) =>
    theme && {
      color: theme.colors.RC10,
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RG06}`,
    }}}  
`;

export const Preparing = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ fit }) => fit && { minWidth: 'fit-content' }};
  ${({ theme }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RG04,
  })}
`;

export const Purple = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ theme, active, outline }) =>
    active
      ? {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC11,
        }
      : {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC11,
          outline: outline ? `1px solid ${theme.colors.RC11_1}` : 'none',

          ':hover': {
            color: theme.colors.RG00,
            backgroundColor: theme.colors.RC11,
          },
        }}
`;

export const PurpleTooltip = styled.span<TagProps>`
  ${TagTooltipBaseStyle}

  ${({ theme }) =>
    theme && {
      color: theme.colors.RC11,
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RG06}`,
    }}}  
`;

export const Skipped = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ theme, outline }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RG04,
    outline: outline ? `1px solid ${theme.colors.RC01_1}` : 'none',
  })}
`;

export const SkippedTooltip = styled.span<TagProps>`
  ${TagTooltipBaseStyle}

  ${({ theme }) =>
    theme && {
      color: theme.colors.RG03,
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RG06}`,
    }}}  
`;

export const EtaGood = styled.span<TagProps>`
  ${TagBaseStyle}
  pointer-events: none;

  padding: 2px 10.5px;

  ${({ theme }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RC10,
  })}
`;

export const EtaCaution = styled.span<TagProps>`
  ${TagBaseStyle}
  pointer-events: none;

  padding: 2px 10.5px;

  ${({ theme }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RC09,
  })}
`;

export const EtaWarning = styled.span<TagProps>`
  ${TagBaseStyle}
  pointer-events: none;

  padding: 2px 10.5px;

  ${({ theme }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RC04,
  })}
`;

export const Driver1 = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ theme, active, outline }) =>
    active
      ? {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC02,
          outline: 'none',
        }
      : {
          color: theme.colors.RC02,
          backgroundColor: theme.colors.RG00,
          outline: `1px solid ${theme.colors.RG06}`,

          ':hover': {
            color: theme.colors.RG00,
            backgroundColor: theme.colors.RC02,
            outline: 'none',
          },
        }}
`;

export const Driver2 = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ theme, active, outline }) =>
    active
      ? {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC10,
          outline: 'none',
        }
      : {
          color: theme.colors.RC10,
          backgroundColor: theme.colors.RG00,
          outline: `1px solid ${theme.colors.RG06}`,

          ':hover': {
            color: theme.colors.RG00,
            backgroundColor: theme.colors.RC10,
            outline: 'none',
          },
        }}
`;

export const Driver3 = styled.span<TagProps>`
  ${TagBaseStyle}

  ${({ theme, active, outline }) =>
    active
      ? {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC11,
          outline: 'none',
        }
      : {
          color: theme.colors.RC11,
          backgroundColor: theme.colors.RG00,
          outline: `1px solid ${theme.colors.RG06}`,

          ':hover': {
            color: theme.colors.RG00,
            backgroundColor: theme.colors.RC11,
            outline: 'none',
          },
        }}
`;

export const Progress = styled.span<TagProps>`
  ${TagBaseStyle}
  ${({ theme, active }) =>
    active
      ? {
          color: theme.colors.RG00,
          backgroundColor: theme.colors.RC03,
          outline: 'none',
        }
      : {
          color: theme.colors.RC03,
          backgroundColor: theme.colors.RC03_1,
          outline: 'none',
        }}
`;
