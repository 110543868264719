import axios, { AxiosError } from 'axios';
import {
  IAddOrder,
  IAddSingleOrderAttr,
  IAddVehicle,
  IAreaProps,
  IAreaResponse,
  IAuthority,
  ICancelRouteOrder,
  ICreateTeamProps,
  IDeactivateMember,
  IDeleteOrder,
  IDeleteUnassignedOrder,
  IDeleteVehicleInfo,
  IDriverUserList,
  IEqualOptimize,
  IEqualOptimizeParams,
  IEqualOptimizeResponses,
  IGetAdminInfoResponses,
  IGetAreaListProps,
  IGetAreaListResponse,
  IGetCompanyInfoResponses,
  IGetControlRouteListByDateResponses,
  IGetControlRouteResponses,
  IGetDownloadOrderList,
  IGetDownloadOrderListResponses,
  IgetDriverInfoResponses,
  IGetDriverListProps,
  IGetDriverListResponse,
  IGetInvestigatorListResponses,
  IGetLocationTopicList,
  IGetMemberList,
  IGetMyInfoResponses,
  IGetNoticeTos,
  IGetOrderDetail,
  IGetOrderDetailResponses,
  IGetOrderList,
  IGetOrderListResponses,
  IGetPaymentBoundaries,
  IGetPaymentInfo,
  IGetRouteDetailResponses,
  IGetRouteListBoundary,
  IGetRouteListBoundaryResponse,
  IGetRouteListResponses,
  IGetTeamDetailById,
  IGetTeamListProps,
  IGetTeamListResponse,
  IGetTeamUsageByBoundary,
  IGetTotalUsageByBoundary,
  IGetUnassignedOrders,
  IGetUnassignedOrdersResponses,
  IGetVehicleInfo,
  IGetVehicleModelResponses,
  IHistoryList,
  IInvitedDriver,
  IInviteMember,
  IInvolvedDriver,
  ILastLocation,
  ILoginResponses,
  ILoginSubmitForm,
  IManualExcelRouteRequestResponse,
  ImanualRouting,
  IModifyingTeamByIdProps,
  IOptimizeDriverUserList,
  IOrderListDownload,
  IOrderListDownloadResponses,
  IPaymentActionProps,
  IRegisterRoute,
  IRunOptimizeFixedArea,
  IRunOptimizeFixedAreaResponse,
  IRunRecalculateOptimize,
  IRunRecalculateOptimizeResponses,
  ISearch,
  ISetAdminInfo,
  IsetBatchOrders,
  ISetCompanyInfo,
  ISetDriverInfo,
  ISetInvestigatorProps,
  ISetMyInfo,
  ISetOrderDetail,
  ISetVehicleInfo,
  ISignupForm,
  ITeam,
  ITemporaryExcel,
  TAccountStatus,
  TChangeableOrderStatus,
  TOperationType,
  TRouteStatus,
} from 'constants/types';
import { TPaymentPlanActions } from 'pages/MyPage/UsageAndPlan/Unsubscribe/PaymentPlanModal';
import { TRoutePlanDriverSelectFilterFormValues } from 'pages/RoutePlanSetup/DriverSelect';
import { PaymentTypeUnion, PlanStatusUnion } from 'types/payment/union';
import {
  IOrderReportDimensionRoute,
  IOrderReportMetrics,
  IReportQuery,
  IReportResponses,
  IRouteReportDimensionRoute,
  IRouteReportMetrics,
} from 'types/report/drive';
import { TBoundary } from '../pages/MyPage/UsageAndPlan/Usage';
import { baseAPI, request } from './instance';

export const pingError = () => {
  return request({ url: '/ping/error' });
};

export const csAlert = (
  // - historyId: error response에 담겨있던 historyId
  // - uri: error가 발생했던 요청 uri
  // - type: 유형
  //     - algorithmError
  props: { historyId: string; uri: string; type: string }
) => {
  return request({ url: '/api/cs', params: { ...props } });
};

export const getPaymentInfo = (): Promise<IGetPaymentInfo> => {
  return request({ url: '/payment/my' });
};

export const getAuthority = (): Promise<IAuthority> => {
  return request({ url: '/company/authority' });
};

// auth API Func
export const login = async (props: ILoginSubmitForm): Promise<ILoginResponses> => {
  const { data } = await baseAPI.post('/auth/signin', { ...props, loginType: 'pc' });
  return data;
};

export const logout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
};

export const signout = () => {
  const item = window.localStorage.getItem('loginAccount');
  request({ url: '/auth/signout', method: 'PUT' });
  window.localStorage.clear();
  item && window.localStorage.setItem('loginAccount', item);
  window.sessionStorage.clear();
  window.location.reload();
};

export const signup = (props: ISignupForm) => {
  return request({ url: '/auth/signup', method: 'POST', data: { ...props } });
};
export const checkSignupPsLink = (urlKey: string) => {
  return request({ url: `/auth/signup/check/${urlKey}` });
};
export const signupPs = (urlKey: string, password: string) => {
  return request({ url: `/auth/signup/${urlKey}`, method: 'PUT', data: { password } });
};
export const resendSignupPsLink = (loginAccount: string) => {
  return request({ url: '/auth/resend', method: 'POST', data: { loginAccount } });
};

export const postPsLink = (userId: number) => {
  return request({ url: `/member/password/reset/${userId}`, method: 'POST' });
};
export const checkPsLink = (urlKey: string) => {
  return request({ url: `/member/password/check/${urlKey}` });
};
export const resetPassword = (urlKey: string, password: string) => {
  return request({ url: `/member/password/change/${urlKey}`, method: 'PUT', data: { password } });
};

//
// orders API Func
export const getUnassignedOrders = (props: IGetUnassignedOrders): Promise<IGetUnassignedOrdersResponses> => {
  return request({ url: '/order/list/optimize', headers: { 'Content-Type': 'url-query' }, params: { ...props } });
};

export const getMemberList = (props: IGetMemberList) => {
  return request({
    url: `/member/list?grade=${props.grade}`,
    headers: { 'Content-Type': 'url-query' },
  });
};

export const addOrder = ({
  config,
  props,
}: {
  config: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
  props: IAddOrder;
}) => {
  return request({
    url: '/order',
    method: 'POST',
    data: { ...props },
    ...config,
  });
};

export const deleteOrder = (props: IDeleteOrder) => {
  return request({ url: '/order/delete', method: 'PUT', data: { ...props } });
};

export const deleteUnassignedOrder = (props: IDeleteUnassignedOrder) => {
  return request({ url: '/order/delete', method: 'PUT', data: { ...props } });
};

export const getDownloadOrderList = (props: IGetDownloadOrderList): Promise<IGetDownloadOrderListResponses> => {
  return request({
    url: '/order/list/download',
    method: 'GET',
    headers: { 'Content-Type': 'url-query' },
    params: { ...props },
  });
};

export const getOrderListDownload = (props: IOrderListDownload): Promise<IOrderListDownloadResponses> => {
  return request({ url: '/order/list/download', method: 'POST', data: { ...props } });
};

export const getOrderList = async (props: IGetOrderList, type?: 'auto' | 'manual'): Promise<IGetOrderListResponses> => {
  return await request({
    url: '/order/list',
    method: 'GET',
    headers: { 'Content-Type': 'url-query' },
    params: { ...props, creationType: type },
  });
};

export const getOrderDetail = ({ target }: IGetOrderDetail): Promise<IGetOrderDetailResponses> => {
  return request({ url: `/order/detail/${target}` });
};

export const setOrderDetail = ({ target, ...props }: ISetOrderDetail) => {
  return request({ url: `/order/${target}`, method: 'PUT', data: { ...props } });
};

export const addSingleOrder = ({
  props,
  config,
}: {
  props: IAddSingleOrderAttr;
  config?: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
}) => {
  return request({
    url: '/order',
    method: 'POST',
    data: { ...props },
    ...config,
  });
};

// export const upsertOrder = ({
//   props,
//   config,
// }: {
//   props: IUpsertOrder;
//   config?: {
//     onUploadProgress: (progressEvent: ProgressEvent) => void;
//     onDownloadProgress: (progressEvent: ProgressEvent) => void;
//   };
// }) => {
//   return request({ url: '/order/upsert', method: 'POST', data: { ...props }, ...config });
// };

export const uploadExcel = ({
  file,
  config,
}: {
  file: any;
  config?: {
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    onDownloadProgress: (progressEvent: ProgressEvent) => void;
  };
}): Promise<{ erroredExcel?: string; failureCount: number; successCount: number }> => {
  return request({
    // url: '/order/excel?wait=false', // TODO: 엑셀 업로드 비동기 처리는 false이고, wait이 없으면 동기 처리이다.
    url: '/order/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
    ...config,
  });
};

export const asyncUploadDriverInvitationExcel: IExcelFunctions['init'] = file => {
  return request({
    url: '/driver/temporary/invitation/excel', // TODO: 엑셀 업로드 비동기 처리는 false이고, wait이 없으면 동기 처리이다.
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
  });
};

export const uploadMExcel: IExcelFunctions['init'] = file => {
  return request({
    url: '/manual/excel',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
  });
};

export const editMTemporaryExcelRowByIndex: IExcelFunctions['set'] = ({ _id, _rowIndex, data }) => {
  return request({
    url: `/manual/excel/${_id}/${_rowIndex}/edit-row`,
    method: 'PUT',
    data: { ...data },
  });
};

export const getTemporaryDriverInvitationExcelById: IExcelFunctions['fetch'] = _id => {
  return request({ url: `/driver/temporary/invitation/${_id}` });
};

export const editTemporaryDriverInvitationExcelRowByIndex: IExcelFunctions['set'] = ({ _id, _rowIndex, data }) => {
  return request({
    url: `/driver/temporary/invitation/${_id}/${_rowIndex}/edit-row`,
    method: 'PUT',
    data: { ...data },
  });
};

export const registerTemporaryDriverInvitationExcelById: IExcelFunctions<{
  id: number;
  status: string;
  totalRowCount: number;
  processedRowCount: number;
}>['register'] = _id => {
  return request({ url: `/driver/temporary/invitation/${_id}/register`, method: 'POST' });
};

export const asyncUploadExcel: IExcelFunctions['init'] = file => {
  return request({
    url: '/order/excel?wait=false', // TODO: 엑셀 업로드 비동기 처리는 false이고, wait이 없으면 동기 처리이다.
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
  });
};

export const getAllTemporaryExcel = (): Promise<Array<ITemporaryExcel>> => {
  return request({ url: `/order/temporary/` });
};

export const getTemporaryExcelById: IExcelFunctions['fetch'] = _id => {
  return request({ url: `/order/temporary/${_id}` });
};

export const editTemporaryExcelRowByIndex: IExcelFunctions['set'] = ({ _id, _rowIndex, data }) => {
  return request({
    url: `/order/temporary/${_id}/${_rowIndex}/editRow`,
    method: 'POST',
    data: { ...data },
  });
};

export const registerTemporaryExcelById: IExcelFunctions['register'] = (_id: any) => {
  return request({ url: `/order/temporary/${_id}/register`, method: 'POST' });
};

export const manualExcelRouteRequest: IExcelFunctions<IManualExcelRouteRequestResponse>['register'] = (_id: number) => {
  return request({ url: `/manual/temporary/${_id}/route-request` });
};

export const manualRouting = (props: ImanualRouting): Promise<{ routeId: number }> => {
  return request({ url: '/manual/route', method: 'POST', data: { ...props } });
};

export const setBatchOrders = (props: IsetBatchOrders) => {
  return request({ url: '/order/batch', method: 'PUT', data: { ...props } });
};

export const skipOrders = (orderList: number[]) => {
  return request({ url: '/order/skip', method: 'PUT', data: { orderList, comment: '관리자 요청으로 보류' } });
};

export const setOrderStatus = (
  targetStatus: TChangeableOrderStatus,
  targetOrderIds: Array<number>,
  comment?: boolean
) => {
  // const options: { [key in TChangeableOrderStatus]: any } = {
  //   skip: '',
  //   delete: '',
  //   unassign: '',
  //   clear: '',
  // };
  return request({
    url: `/order/${targetStatus}`,
    method: 'PUT',
    data: comment ? { orderList: targetOrderIds, comment: '관리자 요청으로 보류' } : { orderList: targetOrderIds },
  });
};

export const getExcelTemplates = () => {
  return request({ url: '/order/excel_template/' });
};
export const getDownloadExcelTemplateFile = (excelTemplateId: string) => {
  return request({ url: `/order/excel_template/${excelTemplateId}/download` });
};
//
// member / info API Funcs
export const getMyInfo = (): Promise<IGetMyInfoResponses> => {
  return request({ url: '/member/profile/my' });
};

export const setMyInfo = (props: ISetMyInfo) => {
  return request({ url: '/member/profile/my', method: 'PUT', data: { ...props } });
};

export const getOptimizeDriver = (
  performedDate: string,
  options: Partial<TRoutePlanDriverSelectFilterFormValues>
): Promise<IgetDriverInfoResponses<IOptimizeDriverUserList>> => {
  // performedDate YYYY-MM-DD
  return request({
    url: '/member/driver/list/optimize',
    params: {
      performedDate,
      ...options,
    },
  });
};

export const getDriverInfo = (): Promise<IgetDriverInfoResponses<IDriverUserList>> => {
  return request({ url: '/member/driver/list' });
};

export const setDriverInfo = ({ target, ...props }: ISetDriverInfo) => {
  return request({ url: `/member/driver/${target}`, method: 'PUT', data: { ...props } });
};

// export const deactivateMember = ({ target }: IDeactivateMember) => {
//   return request({ url: `/member/deactivate/${target}`, method: 'DELETE' });
// };

export const getAdminInfo = (): Promise<IGetAdminInfoResponses> => {
  return request({ url: `/member/admin/list` });
};

export const setAdminInfo = ({ target, ...props }: ISetAdminInfo) => {
  return request({ url: `/member/admin/${target}`, method: 'PUT', data: { ...props } });
};

export const getCompanyInfo = (): Promise<IGetCompanyInfoResponses> => {
  return request({ url: `/company` });
};

export const setCompanyInfo = (props: ISetCompanyInfo) => {
  return request({ url: `/company`, method: 'PUT', data: { ...props } });
};

export const addVehicle = (props: IAddVehicle) => {
  return request({ url: `/vehicle`, method: 'POST', data: { ...props } });
};

export const getVehicleInfo = (): Promise<IGetVehicleInfo> => {
  return request({ url: `/vehicle/list` });
};

export const setVehicleInfo = ({ target, ...props }: ISetVehicleInfo) => {
  return request({ url: `/vehicle/${target}`, method: 'PUT', data: { ...props } });
};

export const deleteVehicleInfo = ({ target }: IDeleteVehicleInfo) => {
  return request({ url: `/vehicle/${target}`, method: 'DELETE' });
};

export const getVehicleModel = (): Promise<IGetVehicleModelResponses> => {
  return request({ url: '/vehicle/model/list' });
};

export const getNoticeTos = (): Promise<IGetNoticeTos> => {
  return request({ url: '/notice/tos' });
};

export const activateMember = (userId: number) => {
  return request({ url: `/member/activate/${userId}`, method: 'PUT' });
};
export const deactivateMember = ({ target }: IDeactivateMember) => {
  return request({ url: `/member/deactivate/${target}`, method: 'DELETE' });
};

export const getInvestigatorList = (): Promise<IGetInvestigatorListResponses> => {
  return request({ url: '/member/investigator/list' });
};
export const setInvestigator = ({ target, ...props }: ISetInvestigatorProps) => {
  return request({ url: `/member/investigator/${target}`, method: 'PUT', data: { ...props } });
};

export const isTeamNameDuplicated = (name: string): Promise<{ hasTeam: boolean }> => {
  return request({ url: '/team/check/name', params: { name: name }, headers: { 'Content-Type': 'url-query' } });
};

export const createTeam = ({ ...props }: ICreateTeamProps): Promise<Pick<ITeam, 'teamId'>> => {
  return request({ url: '/team', method: 'POST', data: { ...props } });
};
export const getTeamList = ({ ...props }: IGetTeamListProps): Promise<IGetTeamListResponse> => {
  return request({ url: `/team/list`, params: { ...props }, headers: { 'Content-Type': 'url-query' } });
};
export const getTeamDetailById = ({ teamId }: Pick<ITeam, 'teamId'>): Promise<IGetTeamDetailById> => {
  return request({ url: `/team/detail/${teamId}` });
};
export const modifyingTeamById = ({ teamId, ...props }: IModifyingTeamByIdProps): Promise<Pick<ITeam, 'teamId'>> => {
  return request({ url: `/team/${teamId}`, method: 'PUT', data: { ...props } });
};
export const deleteTeamById = (teamId: number) => {
  return request({ url: `/team/${teamId}`, method: 'DELETE' });
};

export const getDriverList = (props?: IGetDriverListProps): Promise<IGetDriverListResponse<IInvolvedDriver>> => {
  return request({ url: `/member/list/driver`, params: { ...props }, headers: { 'Content-Type': 'url-query' } });
};

export const getDriverListOnSameTeam = (): Promise<IGetDriverListResponse<IInvolvedDriver>> => {
  return request({ url: `/member/list/driver/team`, headers: { 'Content-Type': 'url-query' } });
};

export const getInvitedDriverList = (props: Partial<ISearch>): Promise<IGetDriverListResponse<IInvitedDriver>> => {
  return request({
    url: `/member/list/driver/invited`,
    params: { ...props },
    headers: { 'Content-Type': 'url-query' },
  });
};
export const cancelDriverInvitation = (invitationList: Array<number>) => {
  return request({
    url: `/member/cancel/driver`,
    data: { ...{ invitationList } },
    method: 'PUT',
  });
};

export const resendDriverInvite = (invitationIds: Array<number>) => {
  return request({
    url: `/member/invite-resend/driver`,
    params: { invitationIds: invitationIds.toString() },
    method: 'POST',
  });
};

export const batchModifyingDriverById = ({
  driverList,
  ...props
}: { driverList: Array<number> } & Partial<{
  memo: string;
  operationType: TOperationType;
  modelId: number;
  maxCapacity: number;
  workStartTime: string;
  workEndTime?: string;
  breakDuration: number;
  breakStartTime: string;
  breakEndTime: string;
  startAddress: string;
  startDetailAddress: string;
  endAddress: string;
  endDetailAddress: string;
}>) => {
  return request({
    url: `/member/batch/driver`,
    data: { driverList, ...props },
    method: 'PUT',
  });
};

export const modifyingDriverById = ({
  driverId,
  ...props
}: { driverId: number } & Partial<{
  name: string;
  memo: string;
  operationType: TOperationType;
  status: TAccountStatus;
  modelId: number;
  maxCapacity: number;
  workStartTime: string;
  workEndTime: string;
  breakDuration: number;
  breakStartTime: string;
  breakEndTime: string;
  startAddress: string;
  startDetailAddress: string;
  endAddress: string;
  endDetailAddress: string;
  skills: number[];
}>) => {
  return request({ url: `/member/update/driver/${driverId}`, data: { ...props }, method: 'PUT' });
};

export const deleteDriver = (driverList: Array<number>) => {
  return request({
    url: `/member/delete/driver`,
    data: { ...{ driverList } },
    method: 'PUT',
  });
};

export const inviteSingleDriver = (props: any) => {
  return request({
    url: `/member/invite/driver`,
    data: { ...props },
    method: 'POST',
  });
};

//
// route API Funcs
export const checkRouteAvailable = (
  date: string,
  array: Array<string>
): Promise<{
  available: boolean;
}> => {
  return request({
    url: '/route/check/available',
    data: {
      performedDate: date,
      driverNames: array,
    },
  });
};

export const getRouteListBoundary = (params: IGetRouteListBoundary): Promise<IGetRouteListBoundaryResponse> => {
  return request({ url: '/route/list/boundary', params: { ...params } });
};

export const getRouteList = (params: {
  status: TRouteStatus;
  name?: string;
  createdBy?: string;
  driver?: string;
  routeCode?: number;
  performedDate?: string; // YYYYMMDD-YYYYMMDD
}): Promise<IGetRouteListResponses> => {
  return request({ url: '/route/list', params: { ...params } });
};

export const cancelRoute = (routeId: number) => {
  return request({ url: `/route/cancel/${routeId}`, method: 'DELETE' });
};

export const routeForceDelete = (routeId: number) => {
  return request({ url: `/route/terminate/${routeId}`, method: 'DELETE' });
};

export const runFixedAreaOptimize = ({
  props,
  config,
}: {
  props: IRunOptimizeFixedArea;
  config: any;
}): Promise<IRunOptimizeFixedAreaResponse> => {
  return request({ url: '/route/optimize/area/fixed', method: 'POST', data: { ...props }, ...config });
};

export const runEqualOptimize = ({
  config,
  props,
  params,
}: {
  config: any;
  props: IEqualOptimize;
  params?: IEqualOptimizeParams;
}): Promise<IEqualOptimizeResponses> => {
  return request({
    url: '/route/optimize/equal',
    method: 'POST',
    data: { ...props },
    params: { ...params },
    ...config,
  });
};

export const runRecalculateOptimize = ({
  props,
  manualRoute,
}: {
  props: IRunRecalculateOptimize;
  manualRoute?: boolean;
}): Promise<IRunRecalculateOptimizeResponses> => {
  return request({
    url: '/route/optimize/edit',
    method: 'POST',
    data: { ...props },
    params: { manualRoute: manualRoute ?? false },
  });
};

export const getRouteDetail = (routeId: number | string): Promise<IGetRouteDetailResponses> => {
  return request({ url: `/route/detail/${routeId}` });
};

export const cancelRouteOrder = ({ routeId, props }: { routeId: number | string; props: ICancelRouteOrder }) => {
  return request({ url: `/order/cancel/${routeId}`, method: 'PUT', data: { ...props } });
};

export const registerRoute = (props: IRegisterRoute): Promise<{ routeId: number }> => {
  return request({
    url: '/route/register',
    method: 'POST',
    data: { ...props },
  });
};

export const routeExcelDownload = ({
  routes,
  routeStatus,
  columnKeys,
}: {
  routeStatus: TRouteStatus;
  routes: Array<number>;
  columnKeys?: Array<string>;
}): Promise<{ url: string }> => {
  return request({
    url: `/route/excel-download/${routeStatus}`,
    params: {
      columnKeys,
      routes: routes.toString(),
    },
  });
};

export const getRouteOrderListDownload = (routeId: number) => {
  return request({ url: `/route/download/${routeId}` });
};

export const getControlRoute = (routeId: number): Promise<IGetControlRouteResponses> => {
  return request({ url: `/route/control/${routeId}` });
};
export const getControlRouteListByDate = (performedDate: string): Promise<IGetControlRouteListByDateResponses> => {
  // performedDate YYYY-MM-DD
  return request({ url: '/route/when', params: { performedDate } });
};

export const getControlRouteHistory = (routeId: number): Promise<{ historyList: IHistoryList[] }> => {
  return request({ url: `/route/history/${routeId}` });
};

export const getControlRouteLastLocation = (routeId: number): Promise<{ data: ILastLocation[] }> => {
  return request({ url: `/route/last-location/${routeId}` });
};

export const isRouteNameDuplicated = (
  name: string
): Promise<{
  isDuplicated: boolean;
}> => {
  return request({ url: '/route/check/name', params: { name: name } });
};

//
// invite API Funcs
export const inviteMember = (props: IInviteMember) => {
  return request({ url: '/member/invite', method: 'POST', data: { ...props } });
};

export const inviteCheck = (urlKey: string) => {
  return request({ url: `/member/invite/check/${urlKey}` });
};

export const inviteAccept = (urlKey: string, password: string) => {
  return request({ url: `/member/invite/accept/${urlKey}`, method: 'PUT', data: { password } });
};

// export const getLocationTopicList = (): Promise<IGetLocationTopicList> => {
export const getLocationTopicList = (): Promise<IGetLocationTopicList> => {
  return axios
    .get(`${process.env.REACT_APP_LOCATION_SERVER_URL}/location/topics/`, {
      headers: { token: window.localStorage.getItem('accessToken') || '' },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
    });
};

// Reporte APIs
export const getRouteReport = (
  type: 'route' | 'driver',
  props: IReportQuery
): Promise<IReportResponses<IRouteReportDimensionRoute, IRouteReportMetrics>> => {
  return request({ url: `/report/${type}`, params: { ...props } });
};
export const getOrderReport = (
  props: IReportQuery
): Promise<IReportResponses<IOrderReportDimensionRoute, Array<IOrderReportMetrics>>> => {
  return request({ url: '/report/order', params: { ...props } });
};

// Area APIs

export const getAreaList = (props: IGetAreaListProps): Promise<IGetAreaListResponse> => {
  return request({ url: '/area/list', params: { ...props } });
};
export const addArea = (props: IAreaProps): Promise<IAreaResponse> => {
  return request({ url: `/area`, method: 'POST', data: { ...props } });
};
export const editArea = ({ props, areaId }: { props: IAreaProps; areaId: number }): Promise<IAreaResponse> => {
  return request({ url: `/area/edit/${areaId}`, method: 'PUT', data: { ...props } });
};
export const deleteArea = (areaId: number) => {
  return request({ url: `/area/${areaId}`, method: 'DELETE' });
};
export const licenseNumberCheck = (props: { license_number: string }): Promise<{ isDuplicated?: boolean }> => {
  return request({
    url: `/vehicle/check/license_number?license_number=${props.license_number}`,
    method: 'GET',
    data: { ...props },
  });
};

export const apiErrorHandler = (err: unknown) => {
  if (err instanceof AxiosError) {
    const { error } = err.response?.data;
    const typedError = error as TErrorResponse;
    return typedError;
  }
  throw new Error('개발자 도구에서 서버 응답값을 확인해주세요.');
};

export const getUsageByBoundary = <T>(props: { boundary: TBoundary }, key?: T) => {
  type ReturnMapper<K> = K extends infer U
    ? U extends 'team'
      ? Promise<IGetTeamUsageByBoundary>
      : Promise<IGetTotalUsageByBoundary>
    : never;

  return request({
    url: `/company/${key ? key + '-' : ''}usage`,
    params: { ...props.boundary },
    data: { ...props },
  }) as ReturnMapper<T>;
};

export const getPaymentBoundaries = (): Promise<IGetPaymentBoundaries> => {
  return request({ url: `/payment/history-boundary` });
};

export const unsubscribe = () => {
  return request({ url: `/payment/unsubscribe`, method: 'POST' });
};

export const getPricingList = () => {
  return request({ url: `/payment/list/pricing` });
};

export const getEstimatePayAmount = (props: {
  plan: Omit<PlanStatusUnion, 'Free'>;
  interval: PaymentTypeUnion;
}): Promise<{ estimatePayAmount: number; cost: number; discount: number }> => {
  return request({ url: `/payment/estimate-pay-amount`, params: { ...props } });
};

export const cancelDowngrade = () => {
  return request({ url: `/payment/downgrade/cancel`, method: 'PUT' });
};

export const paymentStart = (
  props: IPaymentActionProps
): Promise<{
  paymentUrl: string;
  orderCode: string;
}> => {
  return request({ url: `/payment/start-plan`, method: 'POST', data: { ...props } });
};

export const paymentChange = (
  props: IPaymentActionProps
): Promise<{
  type: Omit<TPaymentPlanActions, 'free'>;
  paymentUrl?: string;
  orderCode: string;
}> => {
  return request({ url: `/payment/plan`, method: 'PUT', data: { ...props } });
};

export const hasOptimizedDriver = (
  performedDate: string,
  drivers: Array<number>,
  qt?: 'manual'
): Promise<{ isOptimized: boolean }> => {
  return request({
    url: `/member/driver/isOptimized${qt ? '/' + qt : ''}`,
    method: 'POST',
    data: { performedDate, drivers },
  });
};

export const shouldShowTutorial = (): Promise<{ tutorialChecked: boolean }> => {
  return request({ url: '/auth/tutorial' });
};

const roouty_order_masterExcelInit: IExcelFunctions['init'] = file => {
  return request({
    url: '/excel/masterOrder/upload',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
  });
};

const roouty_order_masterExcelFetch: IExcelFunctions['fetch'] = _id => {
  return request({ url: `/excel/masterOrder/${_id}` });
};

const roouty_order_masterExcelSet: IExcelFunctions['set'] = ({ _id, _rowIndex, data }) => {
  return request({
    url: `/excel/masterOrder/${_id}/${_rowIndex}/editRow`,
    method: 'PUT',
    data: { ...data },
  });
};

const roouty_order_masterExcelRegister: IExcelFunctions['register'] = (_id: any) => {
  return request({ url: `/excel/masterOrder/${_id}/register`, method: 'POST' });
};

/**
 엑셀 관련 함수 네이밍
 ex : roouty_order_masterExcelInit
 필요시 하위 타입 참조
 */
// type NAME_RULE = Readonly<`${ScopeType}Excel${Capitalize<_TExcelActions>}`>;

export const ExcelFunctions: Record<ScopeType, IExcelFunctions> = {
  roouty: {
    init: asyncUploadExcel,
    fetch: getTemporaryExcelById,
    set: editTemporaryExcelRowByIndex,
    register: registerTemporaryExcelById,
  },

  roouty_manual: {
    init: uploadMExcel,
    fetch: getTemporaryExcelById,
    set: editMTemporaryExcelRowByIndex,
    register: manualExcelRouteRequest,
  },

  roouty_driver: {
    init: asyncUploadDriverInvitationExcel,
    fetch: getTemporaryDriverInvitationExcelById,
    set: editTemporaryDriverInvitationExcelRowByIndex,
    register: registerTemporaryDriverInvitationExcelById,
  },

  roouty_order_master: {
    init: roouty_order_masterExcelInit,
    fetch: roouty_order_masterExcelFetch,
    set: roouty_order_masterExcelSet,
    register: roouty_order_masterExcelRegister,
  },
};
