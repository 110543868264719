import { baseAPI } from 'api/instance';
import { apiErrorHandler } from 'api';

export const getDriverAppList = async (
  props?: Omit<TDriverAppParams, 'placeholder'>
): Promise<TDriverAppTable[] | TErrorResponse> => {
  try {
    let URL = '/team/list/setting';
    if (props?.keyword) {
      const { searchItem, keyword } = props;
      URL = URL + `?searchItem=${searchItem}&keyword=${keyword}`;
    }
    const token = window.localStorage.getItem('accessToken') || '';
    const {
      data: { data },
    } = await baseAPI({ url: URL, method: 'GET', headers: { token } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putSetDriverAppList = async (props: {
  payload: TDriverSettingConfigQuery;
  teamId: number;
}): Promise<void | TErrorResponse> => {
  try {
    const { teamId, payload } = props;
    delete payload.clear;
    const URL = `/team/setting/${teamId}`;
    const token = window.localStorage.getItem('accessToken') || '';
    await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { ...payload } });
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};
