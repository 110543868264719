import { AuthLayout } from 'auth/AuthLayout';
import { Input } from 'components/Input';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';
import { useMutation } from '@tanstack/react-query';

import { useForm } from 'react-hook-form';
import { login } from 'api';
import { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';

import { isFalsy } from 'util/isFalsy';
import { InputPassword, storeToken, UseQueryAuthority, UseQueryMyInfo } from 'auth/common';
import { validateLoginAccount } from 'util/validate/validateLoginAccount';

import { useLocation, useNavigate } from 'react-router-dom';
import { replacePathname } from 'util/replacePathname';
import { signInError } from 'auth/signIn/container';

import * as CS from 'auth/style';
import * as S from 'auth/signIn/style';
import { validatePassword } from 'auth/util';
import { useTracking } from '../../hooks/store/useTracking';

export const SignIn = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [storeId, setStoreId] = useState(false);

  const {
    register,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useForm<TSignInHookForm>({ mode: 'onChange' });
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('로그인');
  }, [setTitle]);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) navigate('/', { replace: true });
    const login = localStorage.getItem('loginAccount');
    if (login !== null) {
      setStoreId(true);
      setValue('loginAccount', login);
    }
    const signInErrorCount = localStorage.getItem('count');

    if (signInErrorCount === null) return;
  }, []);

  const { mutate, isSuccess } = useMutation(login, {
    onSuccess: res => storeToken(res),
    onError: (err: any) => {
      const error = err?.response?.data?.error as IErrorResponse;

      if (error.name === 'NotActivatedUser') {
        setError('loginAccount', { message: '존재하지 않는 아이디입니다.' });

        return;
      }

      signInError(error.advice, setError);
    },
  });

  UseQueryAuthority({ isSuccess });
  UseQueryMyInfo({ isSuccess, navigate, isStoredId: storeId });

  return (
    <AuthLayout>
      <CS.SignInResetPasswordLayout>
        <form autoComplete="on" onSubmit={event => event.preventDefault()}>
          <Text styleName="headline1" color="RG02" sx={{ width: '100%', textAlign: 'left' }}>
            로그인
          </Text>
          <CS.InputLabel>
            <Text styleName="subheadline2" color="RG02">
              아이디 (이메일)
            </Text>
            <Input
              sx={{ marginTop: '4px' }}
              autoComplete="on"
              name="loginAccount"
              placeholder="사용하고 있는 이메일 주소를 입력해주세요."
              type="email"
              variant="fourth"
              register={register}
              onBlur={() => {
                const { loginAccount } = getValues();
                if (validateLoginAccount(loginAccount) === false) {
                  return setError('loginAccount', { message: '이메일 형식에 맞지 않습니다. 다시 입력해 주세요.' });
                }
                clearErrors('loginAccount');
              }}
              hasError={errors.loginAccount}
              errors={errors.loginAccount}
            />
          </CS.InputLabel>
          <InputPassword
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            disableAutoFill={false}
            getValues={getValues}
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요"
            sx={{ marginTop: '8px' }}
            password={{ message: errors.password }}
            useValidation={false}
            onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
              clearErrors('password');
              if (event.key === 'Enter') {
                const { loginAccount, password } = getValues();
                if (isFalsy(loginAccount) || validateLoginAccount(loginAccount) === false) {
                  const emailInput = document.querySelector('input[name="loginAccount"]') as HTMLElement;
                  emailInput.focus();
                  return;
                }
                if (isFalsy(password)) {
                  return setError('password', { message: '비밀번호를 입력해주세요.' });
                }
                if (validatePassword(password) === false) {
                  return setError('password', { message: '잘못된 비밀번호 입니다.' });
                }
                mutate(getValues());
              }
            }}
          />

          {/* TODO: 로그아웃 시 localstorage에 있는 모든 데이터 삭제하므로 현재 의미가 없음 */}
          {/* <Recaptcha useRecaptcha={recaptcha} /> */}
          <S.SignInCheckBox>
            <input
              checked={storeId}
              className={'extended-checkbox'}
              type="checkbox"
              onChange={(event: FocusEvent<HTMLInputElement>) => {
                setStoreId(event.target.checked);
              }}
            />
            <Text styleName="subheadline2" color="RG02" sx={{ paddingLeft: '4px' }}>
              아이디 저장
            </Text>
          </S.SignInCheckBox>

          <Button
            styleName="body2"
            color="RG00"
            variant="default"
            type="button"
            height={48}
            fullWidth
            sx={{ marginTop: '24px' }}
            onClick={() => {
              const { loginAccount, password } = getValues();
              if (isFalsy(loginAccount)) {
                const emailInput = document.querySelector('input[name="loginAccount"]') as HTMLElement;
                emailInput.focus();
                return;
              }
              if (isFalsy(password)) {
                return setError('password', { message: '비밀번호를 입력해주세요.' });
              }

              const payload = Object.assign({}, getValues());
              mutate(payload);
            }}
            // disabled={recaptcha}
          >
            로그인
          </Button>
        </form>
        <Button
          styleName="body2"
          color="RG00"
          variant="custom"
          custom={{ init: { color: 'RC02', bgColor: 'RG00' }, hover: { bgColor: 'RG00' } }}
          type="button"
          height={48}
          fullWidth
          sx={{ marginTop: '16px', border: '1px solid #769DFF' }}
          onClick={() => navigate(replacePathname(pathname, 'signup', 'signin'))}
        >
          회원가입하고 루티 무료 체험하기
        </Button>
        <S.SignInCardBottom>
          <Text styleName="body2" color="RG02">
            비밀번호를 잊으셨나요?
          </Text>
          <Text
            onClick={() => navigate(replacePathname(pathname, 'resetpassword', 'signin'))}
            sx={{ cursor: 'pointer', paddingLeft: '20px' }}
            styleName="body2"
            color="RC02"
          >
            비밀번호 찾기
          </Text>
        </S.SignInCardBottom>
      </CS.SignInResetPasswordLayout>
    </AuthLayout>
  );
};
