import ky from 'ky';

const API = ky.create({
  prefixUrl: process.env['REACT_APP_SERVER_URL'],
  retry: 3,
  timeout: 1800000,
  hooks: {
    beforeRequest: [
      async request => {
        request.headers.set('Token', window.localStorage['accessToken']);
      },
    ],
    beforeError: [
      error => {
        const { response } = error;
        // todo: record error with sentry
        console.log(`
          status: ${response.status},
          calledURL: ${response.url},
          ${error}
        `);

        return error;
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 204) {
          return new Response(JSON.stringify({ data: [] }), { status: 204 });
        }

        if (response.status === 401) {
          return new Response(JSON.stringify({ data: [] }));
        }

        if (response.status >= 400) {
          const data = await response.clone().json();

          if (data.message) {
            return new Response(
              JSON.stringify({
                status: 400,
                message: data.message,
                data: data.data,
              }),
            );
          } else if (data.error) {
            return new Response(
              JSON.stringify({
                ...data.error,
              }),
            );
          }
        }

        return response;
      },
    ],
  },
});

export { API };
