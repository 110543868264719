import { useRef, cloneElement, ReactElement, KeyboardEvent } from 'react';

interface IEditableElement {
  onKeyUp?: (props: TInviteOnKeyUp) => void;
  children: ReactElement;
  id?: string;
}

export const EditableElement = (props: IEditableElement) => {
  const { children, onKeyUp, id = undefined } = props;

  const element = useRef<TInviteManagerRef>({ value: '' });

  const onKeyUpEvnet = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = element.current?.value || element.current?.innerText;
    if (onKeyUp && value) {
      const props = { event, value };

      onKeyUp(props);
    }
  };
  return cloneElement(children, {
    contentEditable: true,
    suppressContentEditableWarning: true,
    id: id,
    ref: element,
    onKeyUp: onKeyUpEvnet,
  });
};
