// constant string here

const initialUploadErrorDescriptions: string[] = [
  '파일과 확장자가 맞는지 확인해주세요.',
  '파일 내 칼럼이 정확하게 설정되어 있는지 확인해주세요.',
  '빈 파일은 업로드가 불가합니다. 파일 내 데이터를 확인해주세요.',
];

const string_constants: {
  [key in ScopeType]: {
    [key: string]: any;
  };
} = {
  roouty: {
    type: '주문',
    typeObjectCaseMarker: '을',
    templateName: '주문 업로드',
    fileCallName: '주문 업로드 파일',
    idleTitle: '배차를 진행할 루티 엑셀 파일',
    registerActionString: '등록',
    noRowsTemplate: `오류 주문이 없습니다.\n주문 등록을 눌러 업로드를 완료해 주세요.`,
    confirmMessage: { h: '주문 등록을 취소하시겠습니까?', s: '취소하시면 주문 등록이 되지 않습니다.' },
    uploadErrorDescriptions: [
      ...initialUploadErrorDescriptions,
      '수동배차 주문 엑셀 파일은 주문 관리에서 업로드할 수 없습니다.',
    ],
  },
  roouty_manual: {
    type: '주문',
    typeObjectCaseMarker: '을',
    templateName: '수동배차 주문 업로드',
    fileCallName: '주문 업로드 파일',
    idleTitle: '배차를 진행할 루티 엑셀 파일',
    registerActionString: '확인',
    noRowsTemplate: `오류 주문이 없습니다.\n주문 등록을 눌러 업로드를 완료해 주세요.`,
    confirmMessage: { h: '주문 등록을 취소하시겠습니까?', s: '취소하시면 주문 등록이 되지 않습니다.' },
    uploadErrorDescriptions: [
      ...initialUploadErrorDescriptions,
      '자동배차 주문 엑셀 파일은 수동배차계획에서 업로드할 수 없습니다.',
    ],
  },
  roouty_driver: {
    type: '드라이버',
    typeObjectCaseMarker: '를',
    templateName: '드라이버 업로드',
    fileCallName: '드라이버 초대 업로드 파일',
    idleTitle: '추가할 드라이버 엑셀 파일',
    registerActionString: '초대',
    noRowsTemplate: `오류 드라이버가 없습니다.\n드라이버 초대 버튼을 눌러 초대를 완료해 주세요.`,
    queryCallbackStrings: {
      success: '드라이버 초대를 완료하였습니다.',
      error: '드라이버 초대를 완료하지 못하였습니다.',
    },
    confirmMessage: { h: '드라이버 초대를 취소하시겠습니까?', s: '취소하시면 드라이버 초대가 되지 않습니다.' },
    uploadErrorDescriptions: [...initialUploadErrorDescriptions],
  },
  roouty_order_master: {
    type: '주문지',
    typeObjectCaseMarker: '를',
    templateName: '루티 주문지기준정보등록',
    fileCallName: '주문지 업로드 파일',
    idleTitle: '루티 주문지 엑셀 파일',
    registerActionString: '등록',
    noRowsTemplate: `오류 주문지가 없습니다.\n주문지 등록을 눌러 업로드를 완료해 주세요.`,
    queryCallbackStrings: {
      success: '주문지 등록을 완료하였습니다.',
      error: '주문지 목록을 업로드하지 못하였습니다.',
    },
    confirmMessage: { h: '주문지 등록을 취소하시겠습니까?', s: '취소하시면 주문지 등록이 되지 않습니다.' },

    uploadErrorDescriptions: [...initialUploadErrorDescriptions],
  },
};

export { string_constants };
