import { ChangeEvent, ComponentPropsWithRef, Dispatch, SetStateAction } from 'react';
import { Input } from './index';
import { validatePhoneNumber } from '../../util/validate/validatePhoneNumber';

export interface PhoneNumberInputProps extends ComponentPropsWithRef<'input'> {
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string;
  onValidation: (validate: boolean, value: string) => void;
}

export const PhoneNumberInput = ({
  phone,
  setPhone,
  hasError,
  setHasError,
  errorMessage,
  onValidation,
  ...props
}: PhoneNumberInputProps) => {
  return (
    <Input
      {...props}
      value={phone}
      height={40}
      sx={{ width: '290px' }}
      hasError={hasError}
      errors={{ message: errorMessage }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validate = validatePhoneNumber(value);

        onValidation(validate, value);
        setPhone(value);
      }}
    />
  );
};
