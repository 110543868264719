import { flexRender, PaginationState, Row, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Button } from 'components/Button';
import Divider from 'components/Divider';
import Pagination from 'components/Pagination';
import SpeechBubble from 'components/Popup/SpeechBubble';
import Selector from 'components/Selector';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IconSortDesc } from 'constants/icon/btn_table_sort_down.svg';
import { ReactComponent as IconSortAsc } from 'constants/icon/btn_table_sort_up.svg';
import {
  ReactComponent as IconSortDefault,
  ReactComponent as IcArrowDF,
} from 'constants/icon/ic_arrow_double_faced.svg';
import { ReactComponent as IconCellDragHandle } from 'constants/icon/ic_drag_handle.svg';

import { ReactComponent as IcSetting } from 'constants/icon/ic_setting.svg';

import theme from 'constants/theme';
import { TRouteStatus } from 'constants/types';
import { Dispatch, Fragment, MouseEvent, ReactNode, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import strings from '../../util/Localization';

import * as S from './style';
import DraggableRow from './util/DraggableRow';

type TTableType = 'gnr' | 'order' | 'driver' | `route_${TRouteStatus}` | 'team' | 'masterOrder' | 'multiSkill';

const TableCounter = ({
  table,
  isDataFetching,
  hasSelectColumn,

  disableOptions,
  tableType,
}: {
  table: Table<any>;
  isDataFetching: boolean;
  hasSelectColumn: boolean;

  disableOptions: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };

  tableType: TTableType;
}) => {
  const stringMapper: {
    [key in TTableType]: {
      string: string;
      unit: string;
    };
  } = {
    gnr: { string: '정보', unit: '개' },
    order: { string: '주문', unit: '개' },
    driver: { string: '드라이버', unit: '명' },
    route_processing: { string: '주행중 배차', unit: '건' },
    route_activated: { string: '주행대기 배차', unit: '건' },
    route_completed: { string: '주행종료 배차', unit: '건' },
    team: { string: '팀 수', unit: '개' },
    masterOrder: { string: '주문지', unit: '건' },
    multiSkill: { string: '조건', unit: '건' },
  };

  return (
    <Stack
      spacing={10}
      direction="row"
      divider={<Divider vertical color="RG06" style={{ height: '12px' }} />}
      align="center"
      sx={{
        width: 'fit-content',

        padding: '5px 10px',
        borderRadius: '8px',

        backgroundColor: table.getSelectedRowModel().rows.length > 0 ? theme.colors.RC03_1 : theme.colors.RG08,
      }}
    >
      {hasSelectColumn && !disableOptions.selectCount && (
        <Text
          styleName={'subheadline2'}
          color={table.getSelectedRowModel().rows.length > 0 ? 'RC02' : 'RG03'}
          sx={{ minWidth: 'fit-content' }}
        >
          선택된 {stringMapper[tableType].string}&nbsp;:&nbsp;
          {isDataFetching ? '0'.padStart(2, '0') : table.getSelectedRowModel().rows.length.toString().padStart(2, '0')}
          &nbsp;{stringMapper[tableType].unit}
        </Text>
      )}
      {!disableOptions.totalCount && (
        <Text styleName="subheadline2" color={table.getSelectedRowModel().rows.length > 0 ? 'RC02' : 'RG03'}>
          총 {stringMapper[tableType].string}&nbsp;:&nbsp;
          {`${
            isDataFetching
              ? '00'
              : table
                  .getCoreRowModel()
                  .rows.filter(
                    (d: any) =>
                      d.original?.type !== 'end' && d.original?.type !== 'start' && d.original?.type !== 'break'
                  )
                  .length.toString()
                  .padStart(2, '0') ?? '00'
          }`}
          &nbsp;{stringMapper[tableType].unit}
        </Text>
      )}
    </Stack>
  );
};

const TableComponent = <T,>({
  table,
  isDataFetching,
  pagination,
  setPagination,
  placeholder,
  draggableRow,

  onRowClick,

  disableOptions = {
    totalCount: false,
    selectCount: false,
    pagnation: false,
    pageSizeSelector: false,
  },
  sortDrag,

  report,

  dndTooltip,

  tableType = 'gnr',

  clearBottom = false,
  columnOrderButton = {
    display: false,
    setter: () => {},
  },
  tableHeight,
  tableRowHeight,

  sc,
  hoverSelect,
  useFullHeight = false,
}: {
  sc?: Array<ReactNode>;
  report?: boolean;
  table: Table<any>;
  isDataFetching: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  placeholder?: ReactNode | string;
  draggableRow?: boolean;
  useFullHeight?: boolean;
  disableOptions?: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };

  dndTooltip?: {
    isOpen: boolean;
    content: string;
    height: number;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
  tableType?: TTableType;

  onRowClick?: (e: MouseEvent<HTMLElement>, row: Row<T>) => void;
  sortDrag?: {
    dragFunc: {
      handleDragStart?: () => void;
      handleDragEnd: (result: any) => void;
    };
  };
  tableHeight?: number;
  tableRowHeight?: number;

  clearBottom?: boolean;
  columnOrderButton?: {
    display: boolean;
    setter: Dispatch<SetStateAction<boolean>>;
  };

  hoverSelect?: boolean;
}) => {
  // 테이블 데이터 변경 시 pagination 값을 1로 변경(RP-3271 이슈)
  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, [table.getPageCount()]);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: i => rows[i].index,
    overscan: pagination.pageSize,
  });

  useEffect(() => {
    console.log('asdfafsdaf', table.getHeaderGroups());
  }, [table.getHeaderGroups()]);

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const hasSelectColumn = () => {
    return Boolean(
      table.getAllColumns().find((x: any) => {
        if (x.accessorFn === undefined && x.id?.includes('group')) {
          return x.columns.find((d: any) => d.id === 'select');
        } else return x.id === 'select';
      })
    );
  };

  const paginationSelectorOptions = useMemo(
    () => [
      { key: '10개씩 보기', value: 10 },
      { key: '20개씩 보기', value: 20 },
      { key: '30개씩 보기', value: 30 },
      { key: '40개씩 보기', value: 40 },
      { key: '50개씩 보기', value: 50 },
    ],
    []
  );

  return (
    <Fragment>
      <S.TableWrapper height={tableHeight}>
        <Stack
          justify="space-between"
          direction="row"
          sx={{
            marginBottom:
              (hasSelectColumn() && (!disableOptions.selectCount || !disableOptions.totalCount)) ||
              (!disableOptions.pageSizeSelector && disableOptions.selectCount && disableOptions.totalCount)
                ? '12px'
                : '0px',
          }}
        >
          {hasSelectColumn() && !disableOptions.selectCount && !report && !clearBottom && (
            <Text styleName={'subheadline2'} color={'RG03'} sx={{ minWidth: 'fit-content' }}>
              {strings.선택된주문}&nbsp;:&nbsp;
              {isDataFetching
                ? '0'.padStart(2, '0')
                : table.getSelectedRowModel().rows.length.toString().padStart(2, '0')}
              &nbsp;개
            </Text>
          )}
          {clearBottom && (
            <Stack direction="row" spacing={12} sx={{ flex: 1 }}>
              {!(disableOptions.totalCount && disableOptions.selectCount) && (
                <TableCounter
                  tableType={tableType}
                  hasSelectColumn={hasSelectColumn()}
                  {...{ table, isDataFetching, disableOptions }}
                />
              )}

              {!disableOptions.pageSizeSelector && (
                <Selector
                  anchor="bottom"
                  defaultValue={pagination.pageSize}
                  options={paginationSelectorOptions}
                  onOptionsClick={item =>
                    setPagination(prev => {
                      return { ...prev, pageIndex: 0, pageSize: item.value };
                    })
                  }
                  icon={<IcArrowDF fill={theme.colors.RG04} />}
                />
              )}
            </Stack>
          )}

          <Stack direction="row" spacing={12} sx={{ width: 'fit-content' }}>
            {sc?.map(d => d)}
            {columnOrderButton.display && (
              <Button
                type="button"
                variant={'tertiary'}
                w={126}
                h={32}
                icon={[IcSetting, { width: 16, height: 16, fill: theme.colors.RG04 }]}
                onClick={() => {
                  columnOrderButton.setter(true);
                }}
              >
                조회항목 설정
              </Button>
            )}
          </Stack>
        </Stack>

        <S.TableScrollable
          ref={tableContainerRef}
          isEmpty={table.getCoreRowModel().rows.length === 0 || isDataFetching}
          useFullHeight={useFullHeight}
        >
          <S.CTable rowHeight={tableRowHeight}>
            <thead>
              {table.getHeaderGroups().map((headerGroup: any) => {
                return (
                  <S.CtrColumn
                    key={headerGroup.id}
                    style={
                      headerGroup.headers?.find((d: any) => d.id?.includes('group_inv_')) !== undefined
                        ? {
                            display: 'none',
                          }
                        : {}
                    }
                  >
                    {sortDrag && <S.Cth key="drag-handle-header" />}
                    {headerGroup.headers.map((header: any, index: number) => {
                      let headerType: string = header.column.parent?.id?.split('_')?.at(-1) ?? undefined;

                      const colorShift: {
                        [key: string]: string;
                      } = {
                        dimension: theme.colors.RC10_2,
                        infoMetrics: theme.colors.RG07,
                        metrics: theme.colors.RC03_1,
                        undefined: 'none',
                      };

                      return (
                        <S.Cth
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ backgroundColor: colorShift[headerType] }}
                        >
                          {header.isPlaceholder ? null : (
                            <S.SortContainer
                              justify="start"
                              {...{
                                className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {header.column.getCanSort() && (
                                <S.SortBox>
                                  {{
                                    asc: <IconSortAsc width={10} height={10} />,
                                    desc: <IconSortDesc width={10} height={10} />,
                                  }[header.column.getIsSorted() as string] ?? (
                                    <IconSortDefault fill={theme.colors.RG04} width={10} height={10} />
                                  )}
                                </S.SortBox>
                              )}
                            </S.SortContainer>
                          )}
                        </S.Cth>
                      );
                    })}
                  </S.CtrColumn>
                );
              })}
            </thead>
            {!isDataFetching && (
              <DragDropContext
                onDragStart={sortDrag ? sortDrag.dragFunc.handleDragStart : () => {}}
                onDragEnd={sortDrag ? sortDrag.dragFunc.handleDragEnd : () => {}}
              >
                <Droppable droppableId="table-body">
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {paddingTop > 0 && (
                        <S.CtrRow>
                          <S.Ctd style={{ height: `${paddingTop}px` }} />
                        </S.CtrRow>
                      )}
                      {rowVirtualizer.getVirtualItems().map(virtualRow => {
                        // console.log('v-row', table.getRowModel().rows[virtualRow.index]);

                        if (draggableRow)
                          return (
                            <DraggableRow
                              key={table.getRowModel().rows[virtualRow.index].id}
                              id={`${table.getRowModel().rows[virtualRow.index].original?.orderId || '-1'}`}
                            >
                              {table
                                .getRowModel()
                                .rows[virtualRow.index].getVisibleCells()
                                .map((cell: any) => {
                                  if (cell === undefined) {
                                    console.error(cell, 'cell throw error');
                                  }

                                  return (
                                    <S.Ctd key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </S.Ctd>
                                  );
                                })}
                            </DraggableRow>
                          );
                        else
                          return (
                            <Draggable
                              draggableId={table.getRowModel().rows[virtualRow.index].id}
                              key={table.getRowModel().rows[virtualRow.index].id}
                              index={parseInt(table.getRowModel().rows[virtualRow.index].id)}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <S.CtrRow
                                    ref={provided.innerRef}
                                    isDragging={snapshot.isDragging}
                                    key={table.getRowModel().rows[virtualRow.index].id}
                                    style={onRowClick ? { cursor: 'pointer' } : {}}
                                    onClick={(e: MouseEvent<HTMLElement>) => {
                                      onRowClick && onRowClick(e, table.getRowModel().rows[virtualRow.index]);
                                    }}
                                    // onMouseOver={table.getRowModel().rows[virtualRow.index]}

                                    hoverSelect={
                                      hoverSelect && table.getRowModel().rows[virtualRow.index].getIsSelected()
                                    }
                                    isSelectedRow={table.getRowModel().rows[virtualRow.index].getIsSelected()}
                                    {...provided.draggableProps}
                                  >
                                    {sortDrag && (
                                      <S.Ctd key={'drag-handle'} {...provided.dragHandleProps}>
                                        <IconCellDragHandle />
                                      </S.Ctd>
                                    )}
                                    {table
                                      .getRowModel()
                                      .rows[virtualRow.index].getVisibleCells()
                                      .map((cell: any) => {
                                        if (cell === undefined) {
                                          console.error(cell, 'cell throw error');
                                        }

                                        return (
                                          <S.Ctd key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                          </S.Ctd>
                                        );
                                      })}
                                  </S.CtrRow>
                                );
                              }}
                            </Draggable>
                          );
                      })}
                      {provided.placeholder}
                      {paddingBottom > 0 && (
                        <S.CtrRow>
                          <S.Ctd style={{ height: `${paddingBottom}px` }} />
                        </S.CtrRow>
                      )}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </S.CTable>
          {isDataFetching && (
            <S.TableStateBody align="center" height={tableHeight}>
              <Text styleName="title2" color="RG05">
                테이블 정보를 불러오고 있습니다...
              </Text>
            </S.TableStateBody>
          )}
          {!isDataFetching && table.getCoreRowModel().rows.length === 0 && (
            <S.TableStateBody align="center" height={tableHeight}>
              {placeholder ? (
                typeof placeholder === 'string' ? (
                  <Text styleName="title2" color="RG05">
                    {placeholder}
                  </Text>
                ) : (
                  placeholder
                )
              ) : (
                <Text styleName="title2" color="RG05">
                  불러올 데이터가 없습니다
                </Text>
              )}
            </S.TableStateBody>
          )}
        </S.TableScrollable>

        <Stack direction="row" spacing={20} sx={{ margin: '16px 0 0 0' }}>
          {!clearBottom ? (
            report ? (
              <TableCounter
                tableType={tableType}
                hasSelectColumn={hasSelectColumn()}
                {...{ table, isDataFetching, disableOptions }}
              />
            ) : (
              !disableOptions.totalCount && (
                <Text styleName="subheadline2" color={'RG03'}>
                  {strings.총주문} :{' '}
                  {`${
                    isDataFetching
                      ? '00'
                      : table
                          .getCoreRowModel()
                          .rows.filter(
                            (d: any) =>
                              d.original?.type !== 'end' && d.original?.type !== 'start' && d.original?.type !== 'break'
                          )
                          .length.toString()
                          .padStart(2, '0') ?? '00'
                  }`}
                  &nbsp;개
                </Text>
              )
            )
          ) : null}

          <Stack direction="row" spacing={30} sx={{ flexBasis: 'content' }} name="pagination-area">
            {!disableOptions.pageSizeSelector && !clearBottom && (
              <Selector
                anchor="top"
                defaultValue={pagination.pageSize}
                options={paginationSelectorOptions}
                onOptionsClick={item =>
                  setPagination(prev => {
                    return { ...prev, pageIndex: 0, pageSize: item.value };
                  })
                }
                icon={<IcArrowDF fill={theme.colors.RG04} />}
              />
            )}
            {!disableOptions.pagnation && (
              <Pagination
                pageCount={table.getPageCount()}
                current={table.getState().pagination.pageIndex}
                {...{ setPagination }}
              />
            )}
          </Stack>
        </Stack>

        {dndTooltip && dndTooltip?.isOpen && rows.length > 0 && (
          <SpeechBubble
            color="RC02"
            bOffset={22}
            isOpen={dndTooltip.isOpen}
            setIsOpen={dndTooltip.setIsOpen}
            sx={{ top: `${dndTooltip.height + (virtualRows.length > 2 ? 80 : 16)}px` }}
          >
            <Text styleName="caption1" color="RG00">
              <pre>{dndTooltip?.content}</pre>
            </Text>
          </SpeechBubble>
        )}
      </S.TableWrapper>
    </Fragment>
  );
};

export default TableComponent;
