import dayjs from 'dayjs';
import {
  IEqualOptimizeResponsesDriversOrderList,
  IRunOptimizeEqualResponses,
  ITimelineBlock,
  ITimelineData,
  TTimelineVariants,
} from 'constants/types';
import { v4 as uuidv4 } from 'uuid';
import { ColorPalette } from 'constants/colorPalette';
import { BASE_HOUR, UNBATCHED_COLUMN_KEY } from '.';

const SpacerType: 'byMinute' | 'byBlock' = 'byBlock';

function genTimelineBlock({
  option,
  startTimeFixer,
  index,
  timeSpent,
  originData,
  driverId,
  text,
  waitingTime,
}: {
  option: TTimelineVariants;
  startTimeFixer?: number;
  index?: number;
  timeSpent?: number;
  originData?: IEqualOptimizeResponsesDriversOrderList;
  driverId?: number;
  text?: string;
  waitingTime?: number;
}): ITimelineBlock {
  if (option === 'leftPadding') {
    return {
      id: `locked_${uuidv4()}`,
      type: option,
      width: driverId === -1 ? 40 : 58,
      originWidth: driverId === -1 ? 40 : 58,
      fixedSize: true,
      visible: false,
      preventDragging: true,
    };
  } else if (option === 'rightPadding') {
    let absWidth = 1000000;
    return {
      id: `${uuidv4()}`,
      type: option,
      width: absWidth,
      originWidth: absWidth,
      fixedSize: true,
      visible: false,
      preventDragging: true,
    };
  } else if (option === 'invisible') {
    return {
      id: `locked_${uuidv4()}`,
      type: option,
      width: 6 * (startTimeFixer || 1), // Start Cell 추가로 인해 사이즈 6px 줄이기
      originWidth: 6 * (startTimeFixer || 1), // Start Cell 추가로 인해 사이즈 6px 줄이기
      visible: false,
      preventDragging: true,
    };
  } else if (option === 'spacer') {
    return {
      id: `${uuidv4()}`,
      type: option,
      width: 6 * (timeSpent || 1),
      originWidth: 6 * (timeSpent || 1),
      visible: false,
      preventDragging: true,
    };
  } else if (option === 'start' || option === 'end') {
    return {
      id: `locked_${uuidv4()}`,
      type: option,
      text: `${text}`,
      width: 6,
      originWidth: 6,
      fixedSize: true,
      visible: true,
      preventDragging: true,
      originData,
    };
  }

  let originDataAddedDriverId = originData;
  if (originDataAddedDriverId) {
    originDataAddedDriverId.originDriverId = driverId;
  }

  return {
    id: `${uuidv4()}`,
    text: `${index}`,
    width: 6 * (timeSpent || 1),
    originWidth: 6 * (timeSpent || 1),
    color: ColorPalette.getColor(driverId ?? 0),
    visible: true,
    type: option,
    originData: originDataAddedDriverId,
    driverId,
    waitingTime,
  };
}

export function timelineDataParser(rawData: IRunOptimizeEqualResponses): ITimelineData {
  let data: ITimelineData = {
    columns: {},
    columnOrder: [],
  };
  let workStartAt = 22;
  rawData.driverList
    .filter(x => x.driverId)
    .forEach(x => {
      let d = dayjs(`${rawData.performedDate} ${x.workStartTime}:00`);
      if (workStartAt > d.hour()) {
        workStartAt = d.hour();
      }
    });


  if (workStartAt === 0) workStartAt = BASE_HOUR;
  rawData.driverList.forEach(driver => {
    let key = driver.driverId ? `driver-${driver.driverId}` : UNBATCHED_COLUMN_KEY;

    let items: ITimelineBlock[] = [];
    items.push(genTimelineBlock({ option: 'leftPadding', driverId: driver.driverId ?? -1 }));
    // 시작 시간 계산 ( 운행시간이 7:00 부터면 앞에 6 * 60 * 7 px만큼 패딩 주기 )
    let driverStartTime = dayjs(`${rawData.performedDate} ${driver.workStartTime}:00`);
    let standardStartTime = dayjs(`${rawData.performedDate} ${workStartAt}:00:00`);

    let diffTime = Math.abs(standardStartTime.diff(driverStartTime, 'minute'));

    if (key !== UNBATCHED_COLUMN_KEY) {
      if (diffTime > 0) items.push(genTimelineBlock({ option: 'invisible', startTimeFixer: diffTime }));
      items.push(
        genTimelineBlock({
          option: 'start',
          timeSpent: 1,
          text: driver.vehicle?.startAddress,
        })
      );
    }

    // 각 블럭의 Width 계산을 위해 처리
    let lastTaskDate = driverStartTime;
    let lastTaskIndex = 1;
    driver.orderList.forEach(order => {
      if (order.type === 'order' || order.type === 'break' || order.type === 'end') {
        if (order.type === 'end' && !driver.vehicle?.endAddress) {
          return;
        }
        const waitingTime = order.route?.internal?.waitingTime ?? 0;
        let time = (order.route?.internal?.requiredTime || 0) / 60;
        let serviceTime = (order.serviceTime ?? 0) / 60;

        if (order.type === 'break') {
          serviceTime = time;
          time = 0;
        }

        // 유휴시간 있을 시 추가
        if (waitingTime) {
          time += waitingTime / 60;
        }

        if (key !== UNBATCHED_COLUMN_KEY) {
          if (SpacerType === 'byMinute') {
            for (var i = 0; i < time - 1; i++) {
              items.push(
                genTimelineBlock({
                  option: 'spacer',
                  index: lastTaskIndex,
                  timeSpent: 1,
                })
              );
            }

            if (time % 1 !== 0) {
              items.push(
                genTimelineBlock({
                  option: 'spacer',
                  index: lastTaskIndex,
                  timeSpent: time % 1,
                })
              );
            }
          } else if (SpacerType === 'byBlock') {
            if (time > 0) {
              items.push(
                genTimelineBlock({
                  option: 'spacer',
                  index: lastTaskIndex,
                  timeSpent: time,
                })
              );
            }
          }
          lastTaskDate = lastTaskDate.add(order.route?.internal?.requiredTime ?? 0, 's');
        }
        if (order.type === 'order') {
          items.push(
            genTimelineBlock({
              option: key === UNBATCHED_COLUMN_KEY ? 'mibaecha' : 'order',
              index: lastTaskIndex,
              timeSpent: serviceTime,
              originData: order,
              driverId: driver.driverId ?? 0,
              waitingTime,
            })
          );
        } else if (order.type === 'end') {
          items.push(
            genTimelineBlock({
              option: 'end',
              timeSpent: serviceTime,
              text: driver.vehicle?.endAddress,
              originData: order,
              waitingTime,
            })
          );
        } else
          items.push(
            genTimelineBlock({
              option: 'jumsim',
              index: lastTaskIndex,
              timeSpent: serviceTime,
              originData: order,
              waitingTime,
            })
          );

        lastTaskDate = lastTaskDate.add(order.serviceTime ?? 0, 's');
        if (order.type === 'order' || order.type === 'break') lastTaskIndex++;
      }
    });

    if (items.filter(x => x.type === 'spacer').length === 0)
      items.push(
        genTimelineBlock({
          option: 'spacer',
          timeSpent: 20,
        })
      );
    items.push(genTimelineBlock({ option: 'rightPadding' }));

    data.columns[`${key}`] = {
      id: key,
      vehicle: driver.vehicle ?? null,
      driverId: driver.driverId ?? null,
      items,
      properties: {
        editedItems: [],
        sortChanged: false,
        hiddenEdited: false,
      },
    };
    data.columnOrder.push(key);
  });

  return data;
}
