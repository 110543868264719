import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getVehicleInfo } from 'api';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import PopupYIndexFixer from './PopupYIndexFixer';
import { UseFormReturn } from 'react-hook-form';

import { ReactComponent as SpinLoader } from 'constants/icon/ellipse_spin_loader.svg';
import { SelectorContainer, SelectorItem } from 'components/Selector/style';

interface SkillPickerProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  methods: UseFormReturn<any>;

  searchValue?: string;
}

export const SkillPicker = ({ setIsOpen, name, methods, searchValue }: SkillPickerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [svResult, setSvResult] = useState<Array<any>>([]);

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  useEffect(() => {
    let da = vehicles?.vehicleList.filter(d => d.licenseNumber.includes(searchValue as string));
    setSvResult(da || []);
  }, [searchValue]);

  return (
    <SelectorContainer spacing={44} ref={targetRef}>
      <Stack align="start">
        {getVehicleListIsSuccess ? (
          searchValue === undefined ? (
            vehicles.vehicleList.length > 0 ? (
              vehicles.vehicleList.map((x, i) => (
                <SelectorItem
                  key={`${i}-${name}-${x.vehicleId}`}
                  onClick={() => {
                    setIsOpen(false);
                    methods.setValue(name, x.licenseNumber, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                >
                  <Stack spacing={8} direction={'row'}>
                    <Text styleName={'caption3'} color={'RG03'}>
                      {x.licenseNumber}
                    </Text>
                  </Stack>
                </SelectorItem>
              ))
            ) : (
              <Text styleName="caption3" color="RG04" sx={{ margin: '0 0 0 20px' }}>
                일치하는 차량이 없습니다.
              </Text>
            )
          ) : svResult.length > 0 ? (
            svResult.map((x, i) => (
              <SelectorItem
                key={`${i}-${name}-${x.vehicleId}`}
                onClick={() => {
                  setIsOpen(false);
                  methods.setValue(name, x.licenseNumber, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              >
                <Stack spacing={8} direction={'row'}>
                  <Text styleName={'caption3'} color={'RG03'}>
                    {x.licenseNumber}
                  </Text>
                </Stack>
              </SelectorItem>
            ))
          ) : (
            <Text styleName="caption3" color="RG04" sx={{ margin: '0 0 0 20px' }}>
              일치하는 차량이 없습니다.
            </Text>
          )
        ) : (
          <Stack padding={10}>
            <SpinLoader style={{ width: '16px', height: '16px' }} className="infinite_rotating" />
          </Stack>
        )}
      </Stack>
    </SelectorContainer>
  );
};
