import { DropdownItemType } from 'components/Dropdown/DropdownList';
import { Text } from '../Typography';
import { ItemContainer } from './style';

interface DropdownItemProps extends Omit<DropdownItemType, 'id'> {
  onClick?: () => void;
}

export const DropdownItem = ({ title, description, onClick }: DropdownItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      {title}
      {description && <Text styleName="caption2">{description}</Text>}
    </ItemContainer>
  );
};
