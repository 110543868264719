import type { Dispatch, SetStateAction } from 'react';

import { AlertModal } from 'components/Modal/AlertModal';
import { TERMS_OF_SERVICE_LIST } from 'auth/constants';
import {
  PrivateEmailValidationModal,
  ExceedRequestVerifyCodeModal,
  TermsOfServicesDetailModal,
} from 'auth/signUp/privateTypeForm/elements';
import { __switchSignUpModal, __updateSignUpForm } from 'auth/signUp/privateTypeForm/container';
interface IPrivateFormModalController {
  _dispatch: Dispatch<TSignUpAction>;
  loginAccount: string;
  _modalDispatch: Dispatch<SetStateAction<TSignUpPrivateTypeFormModalState>>;
  _modalState: TSignUpPrivateTypeFormModalState;
}

export const PrivateFormModalController = (props: IPrivateFormModalController) => {
  const { _modalState, _modalDispatch, _dispatch, loginAccount } = props;
  const { isOpen, type, params } = _modalState;
  const [_, _service, _private] = TERMS_OF_SERVICE_LIST;

  if (isOpen === false) return <></>;

  switch (type) {
    case 'EmailValidation':
      return (
        <PrivateEmailValidationModal
          isOpen={isOpen}
          loginAccount={loginAccount}
          successCallback={(loginAccount: string) => {
            __updateSignUpForm({ _dispatch, payload: { loginAccount } });
            __switchSignUpModal({ _modalDispatch, payload: 'off' });
          }}
          overCountWarningCallback={() =>
            __switchSignUpModal({ _modalDispatch, payload: { type: 'OverCountWarning', isOpen: true, params: {} } })
          }
          exceedCallback={() =>
            __switchSignUpModal({ _modalDispatch, payload: { type: 'ExceedRequest', isOpen: true, params: {} } })
          }
          closeCallback={() => __switchSignUpModal({ _modalDispatch, payload: 'off' })}
        />
      );
    //3회 초과 시 보여주는 에러
    case 'OverCountWarning':
      return (
        <AlertModal
          isOpen={isOpen}
          type="ERROR"
          messages={['이메일을 확인하고 인증을 재시도해 주세요.']}
          buttonName="확인"
          callback={() => __switchSignUpModal({ _modalDispatch, payload: 'off' })}
        />
      );

    case 'DetailTermOfService':
      return (
        <TermsOfServicesDetailModal
          isOpen={isOpen}
          callback={() => __switchSignUpModal({ _modalDispatch, payload: 'off' })}
          path={params.key}
        />
      );
    case 'ExceedRequest':
      __switchSignUpModal({ _modalDispatch, payload: 'off' });
      return <></>;

    default:
      return <></>;
  }
};
