import { Stack } from 'components/Stack';

const ImgContainer = ({ referrer }: { referrer: string }) => {
  return (
    <Stack justify="center" align="center" sx={{ height: '100%', background: '#19181a' }}>
      <img src={referrer} height="100%" alt="pod이미지" />
    </Stack>
  );
};

const ImgViewer = () => {
  const referrer = localStorage.getItem('img_referrer');

  if (referrer) {
    return <ImgContainer {...{ referrer }} />;
  } else return <span>이미지를 찾을 수 없습니다.</span>;
};

export default ImgViewer;
