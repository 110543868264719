import { Dispatch, SetStateAction, useEffect, Fragment } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useStore } from 'store';
import theme from 'constants/theme';
import Modal from '.';
import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { Button } from 'components/Button';

interface TosModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TosModal = ({ isOpen, setIsOpen }: TosModalProps) => {
  const closer = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={() => {
        setIsOpen(prev => !prev);
      }}
      padding={24}
      width={504}
      plain
    >
      <Stack name="tos-modal-content-area" spacing={40}>
        <Stack align="start">
          <Text styleName="title1" color="RG02">
            더 나은 서비스를 위해 약관을 개정했습니다.
          </Text>

          <Stack
            align="start"
            sx={{ marginTop: '40px', background: theme.colors.RC03_1, padding: '10px', borderRadius: '8px' }}
            spacing={4}
          >
            <Text styleName={'body1'} color={'RG02'}>
              약관 변경 안내
            </Text>
            <Stack align="start" direction="row" spacing={8}>
              <Text styleName={'body1'} color={'RG02'}>
                개정 대상
              </Text>
              <Text align="left" sx={{ flex: 1 }} styleName={'subheadline2'} color={'RG02'}>
                <Highlight
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => window.open('https://policy.roouty.io/policy_privacy', '_blank')}
                >
                  개인정보취급방침
                </Highlight>
              </Text>
            </Stack>

            <Stack align="start" direction="row" spacing={8}>
              <Text styleName={'body1'} color={'RG02'}>
                개정 내용
              </Text>
              <Text align="left" sx={{ flex: 1 }} styleName={'subheadline2'} color={'RG02'}>
                4. 개인정보 보존 및 이용 기간 : 내용 추가 <br />
                5. 법정대리인의 권리 : 신설 <br />
                6. 8세 이하의 아동 등의 보호의무자의 권리 : 신설
              </Text>
            </Stack>

            <Stack align="start" direction="row" spacing={8}>
              <Text styleName={'body1'} color={'RG02'}>
                개정 사유
              </Text>
              <Text align="left" sx={{ flex: 1 }} styleName={'subheadline2'} color={'RG02'}>
                위치정보사업자 정기 실태점검 중 개선 사항 발생
              </Text>
            </Stack>

            <Stack align="start" direction="row" spacing={8}>
              <Text styleName={'body1'} color={'RG02'}>
                시행 일자
              </Text>
              <Text align="left" sx={{ flex: 1 }} styleName={'subheadline2'} color={'RG02'}>
                2024년 7월 26일
              </Text>
            </Stack>
          </Stack>

          <Text styleName={'subheadline2'} color={'RG03'} align="start" sx={{ marginTop: '30px' }}>
            만약 약관에 동의하지 않으시는 경우에는 루티 홈페이지 1:1 문의를 통해 <br />
            탈퇴요청을 할 수 있습니다. 시행 일자까지 별도의 의사표시를 하지 않는 경우 <br />
            약관에 동의한 것으로 간주되며, 개정된 약관이 적용됩니다.
            <br />
          </Text>
        </Stack>

        <Button type={'button'} variant="primary" h={40} sx={{ width: '100%' }} onClick={closer}>
          <Text styleName="body1" color="RG00">
            확인
          </Text>
        </Button>
      </Stack>
    </Modal>
  );
};

export default TosModal;
