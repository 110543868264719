import { useSearchParams as useSearchParamsReactRouter } from 'react-router-dom';

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsReactRouter();

  return {
    getSearchParam: (parameter: string) => {
      return searchParams.get(parameter);
    },
    setSearchParam: (parameter: string, value: string) => {
      setSearchParams({ [parameter]: value });
    },
    deleteSearchParam: (parameter: string) => {
      searchParams.delete(parameter);

      setSearchParams(searchParams);
    },
  };
};
