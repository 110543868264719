import { PlanStatusUnion } from '../types/payment/union';

type volume_keys = '월간 경유지' | '1회 배차 가능 경유지';
const roouty_plan_features = [
  '배차 계획',
  '실시간 관제',
  '주행 결과 보고서',
  '배차 내역 다운로드',
  '자동 도착 처리',
  '배송완료 인증 사진',
  '주문 마스터데이터',
  'API 주문 연동',
  '센터별 관리 기능',
];

export interface IRooutyPlan {
  tier: TTier;
  name: PlanStatusUnion;
  primary: TThemeColor;
  pricing: number;
  volume: { [key in volume_keys]?: number | string };
  features: typeof roouty_plan_features;

  hasDim?: boolean;

  popular?: boolean;
}

const roouty_plans: Array<IRooutyPlan> = [
  {
    tier: 1,
    name: 'Free',
    primary: 'RC05',

    pricing: 0,

    volume: {
      '월간 경유지': 1000,
      '1회 배차 가능 경유지': 50,
    },
    features: Array.from(roouty_plan_features).filter((_, index) => [0, 1].includes(index)),
  },
  {
    tier: 5,
    name: 'Lite',
    primary: 'RC03',

    pricing: 20,

    volume: {
      '월간 경유지': 2000,
      '1회 배차 가능 경유지': 50,
    },
    features: Array.from(roouty_plan_features).filter((_, index) =>
      Array.from({ length: 7 }, (_, k) => k).includes(index)
    ),
  },
  {
    tier: 6,
    name: 'Standard',
    primary: 'RC10',

    pricing: 70,

    volume: {
      '월간 경유지': 8000,
      '1회 배차 가능 경유지': 100,
    },
    features: Array.from(roouty_plan_features).filter((_, index) =>
      Array.from({ length: 8 }, (_, k) => k).includes(index)
    ),
  },
  {
    tier: 7,
    name: 'Pro',
    primary: 'RC02',

    pricing: 150,

    volume: {
      '월간 경유지': 20000,
      '1회 배차 가능 경유지': 300,
    },
    features: roouty_plan_features,

    popular: true,
  },
  {
    tier: 8,
    name: 'Enterprise',
    primary: 'RC11',

    pricing: 300,

    volume: {
      '월간 경유지': '협의',
      '1회 배차 가능 경유지': '협의',
    },
    features: roouty_plan_features,
    hasDim: true,
  },
];

export { roouty_plans };

const subscribe_quota = {
  Free: {
    perOnce: 50,
    quota: 1000,
  },
  Lite: {
    perOnce: 50,
    quota: 2000,
  },
  Standard: {
    perOnce: 100,
    quota: 8000,
  },
  Pro: {
    perOnce: 300,
    quota: 20000,
  },
  Enterprise: {
    perOnce: 500,
    quota: -1,
  },
};

export { subscribe_quota };
