import type { Dispatch, SetStateAction } from 'react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';

import type { CellClassParams, ColDef, ITooltipParams } from 'ag-grid-community';

import { xlsxKeyConverter, xlsxKeyConverter_K } from 'util/xlsxKeyConverter';

import Modal from './index';
import Alt from 'components/Alt';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { UploadSummaryInfo } from './XlsxLoader';
import { CloseConfirmModal } from './OrderDetailModal';
import { Highlight, Text } from 'components/Typography';
import SpeechBubble from 'components/Popup/SpeechBubble';

import theme from 'constants/theme';
import { string_constants } from './XlsxLoader/constant';

import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IconArrowdown } from 'constants/icon/icon_arrow_down.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import SpinLoader from 'util/SpinLoader';

const masterDataUseCase: ScopeType[] = ['roouty', 'roouty_manual'];

const CustomErrorTooltip = (params: any) => {
  const erroredKey: string = params?.data?.erroredKey ?? '';
  const vfn: Set<string> = new Set([params?.colDef?.colId, params?.colDef?.field]);

  return vfn.has(erroredKey) ? <Alt children={params.data?.errorMessage} /> : <Fragment />;
};

export const ExcelUploadValidation = ({
  data,
  onCellValueChanged,

  isLoading,

  scope,

  register,

  isOpen,
  setIsOpen,

  setXlsxFormIsOpen,
}: {
  data: _ITemporaryExcel;
  onCellValueChanged: Function;

  isLoading?: boolean;

  scope: ScopeType;

  register: Function;

  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  setXlsxFormIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const gridRef = useRef<AgGridReact<_IRows>>(null);

  const rowData = useMemo(
    () =>
      data.rows.map((d, i) => {
        return {
          ...d,
          rowIndex: i + 1,
        };
      }),
    [data, isLoading]
  );
  const rowErrorDataOnly = useMemo(
    () => rowData.filter((d, i) => data.rows[i].rowStatus === 'validatingError'),
    [data.rows, rowData]
  );

  const columnDefs = useMemo<ColDef<_IRows>[]>(() => {
    const hideColumnKeys: Array<string> = ['rotation'];
    const disableEditColumnKeys: Array<string> = [];
    const disableSortColumnKeys: Array<string> = ['detail_address'];

    return [
      {
        field: 'rowIndex',
        headerName: '순번',
        unSortIcon: true,
        pinned: 'left',
        width: 74,
        cellStyle: { backgroundColor: theme.colors.RG07 },
      },
      {
        field: 'errorMessage',
        headerName: '오류 메세지',
        pinned: 'left',
        width: 160,

        valueFormatter: params => {
          return params.data?.erroredKey || params.data?.rowStatus?.includes('Error')
            ? xlsxKeyConverter_K(xlsxKeyConverter(params.data.erroredKey ?? '', scope)) + ' 오류'
            : '확인 완료';
        },
        cellStyle: params => {
          return params.data?.erroredKey || params.data?.rowStatus?.includes('Error')
            ? { color: theme.colors.RC04 }
            : { color: theme.colors.RC02 };
        },
      },

      ...data.headers
        .filter(key => !hideColumnKeys.includes(key))
        .map<ColDef>((key, i) => {
          const field = scope === 'roouty_driver' ? xlsxKeyConverter(key, scope) : key;
          return {
            field,
            colId: key,
            headerName: xlsxKeyConverter_K(xlsxKeyConverter(key, scope)),
            editable: true,
            sortable: !disableSortColumnKeys.includes(key),
            unSortIcon: !disableSortColumnKeys.includes(key),

            tooltipValueGetter: params => params,
            tooltipComponent: CustomErrorTooltip,

            cellStyle: (params: CellClassParams) => {
              return params.colDef.field === params.data?.erroredKey ||
                params.colDef.field === xlsxKeyConverter(params.data?.erroredKey, scope)
                ? {
                    color: theme.colors.RC04,
                    backgroundColor: theme.colors.RC04_2,
                  }
                : (params.data?.masterDataKey as string[] | undefined)?.includes(params.colDef.field ?? '')
                ? {
                    color: theme.colors.RG02,
                    backgroundColor: theme.colors.RC09_2,
                  }
                : null;
            },
          };
        }),
    ];
  }, [data, scope]);

  const {
    type: _c_type,
    fileCallName: _c_fileCallName,
    confirmMessage: _c_confirmMessage,
    noRowsTemplate: _c_noRowsTemplate,
    typeObjectCaseMarker: _c_typeObjectCaseMarker,
    registerActionString: _c_registerActionString,
  } = string_constants[scope];

  const [onlyErrorData, setOnlyErrorData] = useState<boolean>(false);
  const [closeConfirmIsOpen, setCloseConfirmIsOpen] = useState<boolean>(false);
  const [speechIsOpen, setSpeechIsOpen] = useState<boolean>(rowErrorDataOnly.length > 0);

  const closer = () => {
    setIsOpen(false);
    setXlsxFormIsOpen(false);
    return;
  };

  useEffect(() => {
    if (!gridRef.current) return;

    switch (isLoading) {
      case true:
        gridRef.current?.api?.showLoadingOverlay();
        break;
      default:
        gridRef.current?.api?.hideOverlay();
        break;
    }

    return () => {};
  }, [isLoading]);

  return (
    <Fragment>
      <Modal plain isModalOpen={isOpen} setIsModalOpen={setIsOpen} ds="strong" padding={24} width={1138}>
        <Stack align={'start'} sx={{ height: '100%', position: 'relative' }}>
          {speechIsOpen && (
            <SpeechBubble
              isOpen={speechIsOpen}
              setIsOpen={setSpeechIsOpen}
              bOffset={23}
              color="RC02"
              hc={false}
              sx={{ top: '120px', left: '240px', cursor: 'pointer', width: 'max-content' }}
            >
              <Text styleName="subheadline1" color="RG00" sx={{ textAlign: 'start' }}>
                오류가 발생한 셀을 클릭하면
                <br />
                바로 수정이 가능합니다.
              </Text>
            </SpeechBubble>
          )}

          <Stack
            name="modal-header"
            direction="row"
            justify="space-between"
            align="start"
            sx={{ height: '54px', margin: '0 0 40px 0' }}
          >
            {rowErrorDataOnly.length > 0 ? (
              <Text align="start" styleName={'title1'} color={'RG02'}>
                {_c_fileCallName}에 {rowErrorDataOnly.length} 건의 오류가 있습니다.
                <br />
                <Highlight styleName="subheadline2">
                  오류가 발생한 {_c_type + _c_typeObjectCaseMarker} 수정하고 업로드를 다시 시도해주세요.
                </Highlight>
              </Text>
            ) : (
              <Text align="start" styleName={'title1'} color={'RG02'}>
                {_c_fileCallName}을 정상 확인 완료 하였습니다.
              </Text>
            )}
          </Stack>

          <Stack direction="row" justify="space-between">
            <Stack direction="row" spacing={10} sx={{ width: 'fit-content' }}>
              <Button
                variant={onlyErrorData ? 'errored' : 'tertiary'}
                h={32}
                type="button"
                children={`오류 ${_c_type}만 보기`}
                icon={[IconError, { fill: theme.colors[onlyErrorData ? 'RC04' : 'RG04'], width: 16, height: 16 }]}
                onClick={() => setOnlyErrorData(prev => !prev)}
              />
              {masterDataUseCase.some(useCase => useCase === scope) ? (
                <Fragment>
                  <ColorCell ca={['RC09_1', 'RC09']} />
                  <Text color="RG03" styleName="subheadline2">
                    주문지 기준 정보에서 불러온 값
                  </Text>

                  <ColorCell ca={['RG00', 'RG06']} />
                  <Text color="RG03" styleName="subheadline2">
                    주문 추가 엑셀 파일에 입력한 값
                  </Text>
                </Fragment>
              ) : (
                <Fragment />
              )}
            </Stack>

            <Stack spacing={12} direction="row" sx={{ width: 'max-content' }}>
              <UploadSummaryBox>
                <UploadSummaryInfo
                  success={rowData.length - rowErrorDataOnly.length}
                  failure={rowErrorDataOnly.length}
                  string={['성공', '오류', '전체']}
                />
              </UploadSummaryBox>
              <Button
                variant="tertiary"
                h={32}
                icon={[IconArrowdown, { fill: theme.colors['RG04'], width: 16, height: 16 }]}
                children={'검증 결과 엑셀 다운로드'}
                disabled={!(rowErrorDataOnly.length > 0)}
                onClick={() => {
                  if (data?.erroredExcel) {
                    window.open(data.erroredExcel, 'window_opener', 'noopener,noreferrer')?.blur();
                  } else {
                    alert('업로드 실패 엑셀 파일을 다운로드 하는 중 문제가 생겼습니다.');
                  }
                }}
              />
            </Stack>
          </Stack>

          <Stack sx={{ height: 594, minHeight: 195 }}>
            <div className="ag-theme-roouty" style={{ width: '100%', height: '100%', margin: '12px 0 20px 0' }}>
              <AgGridReact
                onCellClicked={e => setSpeechIsOpen(false)}
                ref={gridRef}
                rowData={onlyErrorData ? rowErrorDataOnly : rowData}
                columnDefs={columnDefs}
                defaultColDef={{ sortable: true, suppressMovable: true }}
                onCellEditingStopped={e => {
                  onCellValueChanged(e);
                }}
                loadingOverlayComponent={() => {
                  return <SpinLoader />;
                }}
                noRowsOverlayComponent={() => {
                  return (
                    <pre>
                      <Text styleName="title2" color="RG04">
                        {_c_noRowsTemplate}
                      </Text>
                    </pre>
                  );
                }}
                tooltipHideDelay={20000}
                tooltipShowDelay={0}
                suppressScrollOnNewData
              ></AgGridReact>
            </div>
          </Stack>

          <Stack spacing={16} direction="row" justify="end" name="footer-area">
            <Button
              type="button"
              variant="tertiary"
              w={102}
              h={40}
              children={`취소`}
              onClick={() => setCloseConfirmIsOpen(true)}
            />

            <Button
              type="button"
              variant="primary"
              w={216}
              h={40}
              children={`${_c_type} ${_c_registerActionString}`}
              disabled={rowErrorDataOnly.length > 0}
              onClick={() => {
                register();
                setIsOpen(false);
              }}
            />
          </Stack>
        </Stack>
      </Modal>

      <CloseConfirmModal
        isOpen={closeConfirmIsOpen}
        setIsOpen={setCloseConfirmIsOpen}
        targetSetIsOpen={() => {}}
        icon={<IcWarning width={44} height={44} />}
        text={
          <Text styleName="body2">
            <Highlight color="RC04">{_c_confirmMessage?.h}</Highlight>
            <br />
            {_c_confirmMessage?.s}
          </Text>
        }
        btnFunctions={{ RBT: closer }}
        RBT={'확인'}
        LBT={'돌아가기'}
      />

      <iframe title="window_opener" style={{ width: 0, height: 0, display: 'none' }} name="window_opener" />
    </Fragment>
  );
};

const UploadSummaryBox = styled(Stack)`
  width: max-content;

  padding: 5px 10px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RG08};
`;

const ColorCell = styled.div<{ ca: [TThemeColor, TThemeColor] }>`
  width: 16px;
  height: 16px;

  margin-right: -4px;

  ${({ ca, theme }) => ({
    backgroundColor: theme.colors[ca[0]],
    border: `1px solid ${theme.colors[ca[1]]}`,
  })}
`;
