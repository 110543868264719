import { useQuery } from '@tanstack/react-query';
import { getControlRoute, getControlRouteHistory, getControlRouteLastLocation, getLocationTopicList } from 'api';
import Alt from 'components/Alt';
import { BoxContainer } from 'components/BoxContainer';
import { Button } from 'components/Button/legacy_index';

import Divider from 'components/Divider';
import Modal from 'components/Modal';
import OrderDetailModal, { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import PageContentWrapper from 'components/PageContentWrapper';
import Sidebar from 'components/SidebarLayout/Sidebar';
import MonitoringTable from 'components/Table/MonitoringRouteListTable';
import RouteDriverControlTable from 'components/Table/RouteDriverControlTable';
import { Tag } from 'components/Tag/new';
import { ReactComponent as IcArrowRight } from 'constants/icon/ic_arrowright.svg';

import { ReactComponent as IconClose } from 'constants/icon/ic_close.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import theme from 'constants/theme';

import {
  DriverIdUnSelected,
  IControlRouteDriverList,
  IControlRouteDriverOrderList,
  IHistoryList,
  ILocationMqOnStore,
  TControlDriverStatus,
  TOperationType,
  TRouteStatus,
} from 'constants/types';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useOverflowDetector } from 'react-detectable-overflow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getTotalReqTime } from 'util/getDriverSummary';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';
import { validateControlDriverStatus } from 'util/validate/validateStatus';
import { RouteControlMapV2 } from '../components/MapboxContainer/RouteControlMap';
import RouteControlNav from '../components/SidebarLayout/RouteControlNav';
import { Stack } from '../components/Stack';
import { Highlight, Text } from '../components/Typography';
import { useTracking } from '../hooks/store/useTracking';
import useMqtt from '../hooks/useMqtt';
import strings from '../util/Localization';

const interval: { [key in TRouteStatus]: number } = {
  activated: 300000,
  processing: 60000,
  completed: 0,
};
const RouteControlPage = () => {
  const params = useParams();
  const location = useLocation();
  const [propStates, setPropStates] = useState<{
    driverId: number;
    orderId: number;
    routeId: number | undefined;
    targetPerformedDate: string | undefined;
    initPageStatus: TRouteStatus;
  }>(
    location.state as {
      driverId: number;
      orderId: number;
      routeId: number;
      targetPerformedDate: string;
      initPageStatus: TRouteStatus;
    }
  );

  const [pageStatus, setPageStatus] = useState<TRouteStatus>(
    (params?.status as TRouteStatus) ?? propStates?.initPageStatus ?? 'processing'
  );

  // control api refetch interval
  const [apiInterval, setApiInterval] = useState<number>(interval['activated']);

  const [targetRouteId, setTargetRouteId] = useState<number>(propStates?.routeId ?? -1);
  const [cardTargetDriverId, setCardTargetDriverId] = useState<number>(propStates?.driverId ?? -1);
  const [cardTargetDriver, setCardTargetDriver] = useState<Array<IControlRouteDriverList>>([]);
  const [historyList, setHistoryList] = useState<Array<IHistoryList>>([]);
  const [processingStatusModalIsOpen, setProcessingStatusModalIsOpen] = useState<boolean>(false);
  const [endStatusModalIsOpen, setEndStatusModalIsOpen] = useState<boolean>(false);
  const [attributedDriverId, setAttributedDriverId] = useState<Array<number>>([]);
  const [target, setTarget] = useState<number>();
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);
  const [locationMqResponse, setLocationMqResponse] = useState<ILocationMqOnStore[]>([]);
  const [performedDate, setPerformedDate] = useState<string>(
    propStates?.targetPerformedDate ?? dayjs().format('YYYY-MM-DD')
  );
  const [tabStackProps, setTabStackProps] = useState<{ subject: 'driver' | 'history'; isOpen: boolean }>({
    subject: 'history',
    isOpen: false,
  });
  const [routeListTabIsOpen, setRouteListTabIsOpen] = useState<boolean>(true);
  const [selectedOrder, setSelectedOrder] = useState<IControlRouteDriverOrderList>();
  const [wideMap, setWideMap] = useState<boolean>(true);
  const [defaultCheckOrderIds, setDefaultCheckOrderIds] = useState<Array<number> | null>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [alt, setAlt] = useState<{ visible: boolean; content: ReactNode }>({ visible: false, content: '' });
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const navigate = useNavigate();

  const onClickTable = (order: IControlRouteDriverOrderList) => {
    const mint = selectedOrder === order;
    if (mint) {
      setSelectedOrder(undefined);
      onClickMarkerSelectTable(0);
    } else {
      setSelectedOrder(order);
      if (order.orderId) onClickMarkerSelectTable(order.orderId);
    }
  };

  const onClickMarkerSelectTable = (orderId: number) => {
    if (orderId > 0)
      setDefaultCheckOrderIds(prev => {
        // if (Array.isArray(prev)) return prev.concat(orderId);
        // else return [orderId];
        return [orderId];
      });
    else setDefaultCheckOrderIds(null);
  };

  const onClickMarkerShowDetail = (orderId: number) => {
    setOrderDetailModalOpen(true);
    setTarget(orderId);
  };

  const onClickPolylineOpenDriver = (driverId: number) => {
    setCardTargetDriverId(driverId);
    setTabStackProps({ subject: 'driver', isOpen: driverId !== DriverIdUnSelected });
  };

  const fetchErrorBoundary = () => {
    setTargetRouteId(-33);
    setLanding(true);
    // setTabStackProps({
    //   isOpen: true,
    //   subject: 'routeList',
    // });
    setRouteListTabIsOpen(true);
  };

  const clearBrowserHistory = () => {
    navigate(location.pathname, { replace: true });
  };

  const {
    data: controlRouteByIdData,
    isSuccess: controlRouteByIdDataIsSuccess,
    refetch: refetchControlRouteByIdData,
    isRefetching,
  } = useQuery(
    ['controlRouteById', targetRouteId],
    () => {
      setLanding(false);
      return getControlRoute(targetRouteId);
    },
    {
      retry: false,
      refetchInterval: apiInterval,
      refetchIntervalInBackground: true,
      enabled: targetRouteId > 0,

      onSuccess: res => {
        setApiInterval(interval[res.routeStatus]);

        navigate(location.pathname, { replace: true, state: { targetRouteId: res.routeId } });

        if (pageStatus !== res.routeStatus) {
          if (res.routeStatus === 'processing') {
            setProcessingStatusModalIsOpen(true);
            setTimeout(() => {
              setProcessingStatusModalIsOpen(false);
            }, 3000);
          } else if (res.routeStatus === 'completed') {
            setEndStatusModalIsOpen(true);
          } else {
            setProcessingStatusModalIsOpen(false);
            setEndStatusModalIsOpen(false);
          }
        }

        setPageStatus(res.routeStatus);
      },
      onError(err) {
        fetchErrorBoundary();
      },
    }
  );

  const {} = useQuery(['controlRouteLastLocation', targetRouteId], () => getControlRouteLastLocation(targetRouteId), {
    onSuccess: res => {
      let transToMqResponse: ILocationMqOnStore[] = res.data
        .filter(r => r.location)
        .map(r => {
          return {
            userId: r.userId.toString(),
            driverId: r.driverId.toString(),
            lastLocationDate: new Date('2023-01-01'),
            locationInfo: {
              userId: r.userId.toString(),
              driverId: r.driverId.toString(),
              location: r.location!,
            },
          };
        });
      setLocationMqResponse(transToMqResponse);
    },
    onError(err) {
      fetchErrorBoundary();
    },
    retry: false,
    refetchInterval: false,
    enabled: controlRouteByIdDataIsSuccess && targetRouteId > 0,
  });

  const {} = useQuery(['controlRouteHistory', targetRouteId], () => getControlRouteHistory(targetRouteId), {
    onSuccess: res => setHistoryList(res.historyList),
    onError(err) {
      fetchErrorBoundary();
    },
    retry: false,
    refetchInterval: apiInterval,
    refetchIntervalInBackground: true,
    enabled: controlRouteByIdDataIsSuccess && targetRouteId > 0,
  });
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('모니터링>배차목록');
  }, [setTitle]);

  useEffect(() => {
    if (cardTargetDriverId !== -1 && controlRouteByIdDataIsSuccess) {
      setCardTargetDriver(controlRouteByIdData.driverList.filter(d => d.driverId === cardTargetDriverId));
      // setCardTargetDriver(dummyData.driverList.filter(d => d.driverId === cardTargetDriverId));
    }
    setDefaultCheckOrderIds(null);
  }, [cardTargetDriverId, controlRouteByIdData?.driverList, controlRouteByIdDataIsSuccess]);

  useEffect(() => {
    setOrderDetailModalOpen(false);
  }, [tabStackProps]);

  useEffect(() => {
    // @ts-ignore
    if (location.state?.targetRouteId) {
      // @ts-ignore
      setTargetRouteId(location.state.targetRouteId);
    }
    if (location.search === '?landing') {
      setTargetRouteId(-33);
      setLanding(true);
    }
  }, [location]);

  // useEffect(() => {
  //   if (propStates?.targetPerformedDate === performedDate)
  //     setTabStackProps(prev => {
  //       return { subject: 'driver', isOpen: true };
  //     });
  //   else {
  //     setTabStackProps(prev => {
  //       return { ...prev, isOpen: false };
  //     });
  //   }
  //   setHistoryList([]);
  // }, [performedDate, targetRouteId]);

  useEffect(() => {
    setAttributedDriverId(controlRouteByIdData?.driverList.map(d => d.driverId) ?? []);
    return () => setAttributedDriverId([]);
  }, [controlRouteByIdData]);

  useEffect(() => {
    setPageStatus((params?.status ?? propStates?.initPageStatus ?? 'processing') as any);
  }, [params?.status]);

  useEffect(() => {
    setTabStackProps(prev => {
      return { ...prev, isOpen: false };
    });
  }, [targetRouteId]);

  const mapboxRef = useRef<HTMLDivElement>(null);

  const {
    client,
    setClient,
    isSub,
    setIsSub,
    connectStatus,
    setConnectStatus,
    mqttConnect,
    mqttSub,
    mqttUnSub,
    mqttPublish,
    mqttDisconnect,
  } = useMqtt({
    locationMqResponse,
    setLocationMqResponse,
    attributedDriverId: attributedDriverId,
  });

  // 관제 입/이탈 시에 자동으로 connect / disconnect 처리
  useEffect(() => {
    console.log(controlRouteByIdData);

    if (controlRouteByIdData?.routeId === targetRouteId && controlRouteByIdData?.routeStatus === 'processing')
      mqttConnect(process.env.REACT_APP_MQTT_HOST || 'localhost', {
        port: Number(process.env.REACT_APP_MQTT_PORT) || 61619,
        clientId: 'client_test_random' + Math.round(Math.random() * 1000),
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      });
    else {
      mqttUnSub({ topic: topics?.topicList ?? '' });
      mqttDisconnect();
    }

    return () => {
      mqttUnSub({ topic: topics?.topicList ?? '' });
      mqttDisconnect();
    };
  }, [targetRouteId, controlRouteByIdData]);

  const { data: topics } = useQuery(['topics'], getLocationTopicList, {});

  // mqtt 객체 connected 일 때 자동으로 topic 끌어와서 갱신
  useEffect(() => {
    if (connectStatus === 'Connected') {
      mqttSub({ topic: topics?.topicList ?? [], qos: 0 });
    }
    return () => {};
  }, [connectStatus, topics?.topicList]);

  const { routeStartTime, totalReqTime } = getTotalReqTime({
    performedDate,
    workStartTime: cardTargetDriver[0]?.workStartTime,
    lastOrderETA: cardTargetDriver[0]?.orderList.at(-1)?.route.eta ?? '00:00',
  });

  const driverStackRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dimRef = useRef<HTMLDivElement>(null);

  const closer = () => {
    setEndStatusModalIsOpen(false);
  };
  const navigateReport = () => {
    navigate('/manage/report');
  };

  // RP-1143
  const [landing, setLanding] = useState<boolean>(true);
  useEffect(() => {
    if ((location?.state, landing)) {
      setRouteListTabIsOpen(true);
      setTabStackProps(prev => {
        return { ...prev, isOpen: false };
      });
    } else {
      setRouteListTabIsOpen(false);
      if (cardTargetDriverId > 0) setTabStackProps({ isOpen: true, subject: 'driver' });
    }
  }, [landing]);

  useEffect(() => {
    if (tabStackProps.isOpen) return;
    else setWideMap(true);
  }, [tabStackProps.isOpen]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!containerRef.current?.contains(e.target) && dimRef.current?.contains(e.target) && !landing)
      setRouteListTabIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dimRef, containerRef, landing]);

  const { ref: vehicle_memo, overflow: vehicle_memo_overflow } = useOverflowDetector({});

  return (
    <Stack align={'flex-start'} direction={'row'} sx={{ height: '100%', width: '100%' }}>
      {!landing && (
        <Sidebar bg="RG08" width="288px" ds="strong" index={2} sx={{ minWidth: '288px' }} padding={'48px 0 0 0'}>
          <RouteControlNav
            {...{
              performedDate,
              targetRouteId,
              cardTargetDriverId,
              setPropStates,
              propStates,
              setPerformedDate,
              tabStackProps,
              setTabStackProps,
              setCardTargetDriverId,
              setTargetRouteId,
            }}
          />
        </Sidebar>
      )}
      <PageContentWrapper>
        <Stack direction="row">
          {useMemo(() => {
            const mapper: { [key in TOperationType]: { string: string; color: TThemeColor } } = {
              backup: { string: '용차', color: 'RC10' },
              regular: { string: '고정차', color: 'RC02' },
            };

            return (
              <Stack sx={{ width: 'fit-content', height: '100%' }} useVerticalScroll>
                <DriverStack
                  ref={driverStackRef}
                  align={'start'}
                  sx={{
                    background: 'white',
                    zIndex: 7,
                    width: `${
                      tabStackProps.isOpen
                        ? tabStackProps.subject === 'driver'
                          ? `calc(${wideMap ? '60' : '100'}vw - 288px)`
                          : '314px'
                        : '0px'
                    }`,
                    minWidth: `${
                      tabStackProps.isOpen ? (tabStackProps.subject === 'driver' ? '700px' : '314px') : '0px'
                    }`,
                    height: '100%',
                    padding: `0 0 48px`,
                  }}
                >
                  <Stack align="end">
                    <Stack justify="center" sx={{ height: 48, width: 50 }}>
                      <IconClose
                        width={12}
                        height={12}
                        onClick={() => {
                          setTabStackProps(prev => ({ ...prev, isOpen: false }));
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </Stack>
                  </Stack>
                  {tabStackProps.isOpen && tabStackProps.subject === 'driver' ? (
                    <BoxContainer useVerticalScroll align={'start'} justify={'start'}>
                      <Stack align={'end'} sx={{ height: '100%' }}>
                        <Stack spacing={40} sx={{ padding: '0 18px 0', overflow: 'hidden' }}>
                          <Stack justify="start" align="start" spacing={10}>
                            <Stack direction="row" spacing={8} sx={{ padding: '4px 4px 4px 0' }}>
                              <Text
                                styleName="title1"
                                color="RG02"
                                broken={false}
                                sx={{ whiteSpace: 'nowrap', marginRight: 6 }}
                              >
                                {cardTargetDriver[0]?.name?.slice(0, 20)}
                              </Text>
                              <Tag
                                text={mapper[cardTargetDriver[0]?.vehicle.operationType]?.string}
                                variant={'primary'}
                                height={20}
                                color={mapper[cardTargetDriver[0]?.vehicle.operationType]?.color}
                                sx={{
                                  padding: '2px 4px',
                                  whiteSpace: 'nowrap',
                                }}
                              />
                              <Divider vertical color="RG06" style={{ height: 12 }} />
                              <Text styleName="subheadline2" broken={false} sx={{ whiteSpace: 'nowrap' }}>
                                {cardTargetDriver[0]?.vehicle.model.name}
                              </Text>
                              <Divider vertical color="RG06" style={{ height: 12 }} />
                              <Text
                                styleName="subheadline2"
                                broken={false}
                                sx={{ whiteSpace: 'nowrap' }}
                              >{`용적량 : ${cardTargetDriver[0]?.vehicle.maxCapacity.toLocaleString('kr')}`}</Text>
                              <Divider vertical color="RG06" style={{ height: 12 }} />
                              <Text
                                ref={vehicle_memo}
                                styleName="subheadline2"
                                broken={false}
                                className="elipsis"
                                onMouseOver={e => {
                                  setAnchorPoint(e.currentTarget.getBoundingClientRect());
                                  setAlt({
                                    content: cardTargetDriver[0]?.vehicle?.memo ?? '',
                                    visible: true,
                                  });
                                }}
                                onMouseOut={() => {
                                  setAlt({
                                    content: '',
                                    visible: false,
                                  });
                                }}
                              >
                                {`메모 : ${cardTargetDriver[0]?.vehicle?.memo ?? ''}`}

                                {alt.visible && vehicle_memo_overflow && !!cardTargetDriver[0]?.vehicle?.memo && (
                                  <Alt
                                    style={{
                                      width: 400,
                                      whiteSpace: 'break-spaces',
                                      wordBreak: 'break-all',
                                      padding: '2px 4px',
                                      top: anchorPoint ? anchorPoint.top - 46 : 0,
                                      left: anchorPoint ? anchorPoint.width + anchorPoint.x - 400 : 0,
                                    }}
                                  >
                                    {alt.content}
                                  </Alt>
                                )}
                              </Text>
                            </Stack>

                            <Stack
                              direction="row"
                              spacing={8}
                              divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                            >
                              <Stack spacing={4} direction="row" sx={{ width: 'fit-content' }}>
                                {/* <IcMarker /> */}
                                <Text styleName="subheadline2" color="RG03">
                                  주문{' '}
                                  <Highlight color="RC02">
                                    {cardTargetDriver[0]?.orderList.filter(d => d.type === 'order').length}
                                  </Highlight>{' '}
                                  건
                                </Text>
                              </Stack>

                              <Text styleName="subheadline2" color="RG03">
                                {routeStartTime.format('HH:mm')}&nbsp;~&nbsp;
                                {routeStartTime.add(totalReqTime, 's').format('HH:mm')}
                              </Text>

                              <Text styleName={'subheadline2'} color={'RG03'}>
                                {Math.floor(Math.floor(totalReqTime / 60) / 60) !== 0
                                  ? `${Math.floor(Math.floor(totalReqTime / 60) / 60)} 시간 `
                                  : ``}
                                {Math.floor(Math.floor(totalReqTime / 60) % 60) !== 0
                                  ? `${Math.floor(Math.floor(totalReqTime / 60) % 60)} 분`
                                  : ``}
                                {Math.floor(Math.floor(totalReqTime / 60) / 60) === 0 &&
                                  Math.floor(Math.floor(totalReqTime / 60) % 60) === 0 &&
                                  '0 분'}
                              </Text>

                              {cardTargetDriver[0]?.orderList.filter(d => d.type === 'break').length ? (
                                <Stack spacing={8} direction="row" sx={{ width: 'fit-content' }}>
                                  <IcHasLunch />
                                  <Text styleName="subheadline2" color="RG03">
                                    {(() => {
                                      const breakOrder = cardTargetDriver[0]?.orderList.find(d => d.type === 'break');
                                      const requiredTime = breakOrder ? breakOrder.route.serviceTime ?? 0 : 0;
                                      const hours = Math.floor(requiredTime / 3600);
                                      const minutes = Math.floor((requiredTime % 3600) / 60);

                                      return hours > 0
                                        ? `${hours} 시간${minutes > 0 ? ` ${minutes} 분` : ''}`
                                        : `${minutes} 분`;
                                    })()}
                                  </Text>
                                </Stack>
                              ) : null}
                              <Text styleName="subheadline2" color="RG03">
                                {`${numberWithCommas(
                                  roundDecimal(
                                    cardTargetDriver[0]?.orderList.reduce(
                                      (x, a) => x + (a.route.requiredDistance || 0),
                                      0
                                    ) / 1000,
                                    2
                                  )
                                )?.replace('.00', '')} km`}
                              </Text>
                              <Text styleName="subheadline2" color="RG03">
                                <Stack
                                  direction="row"
                                  justify="center"
                                  sx={{
                                    backgroundColor: theme.colors.RC03,
                                    color: theme.colors.RG00,
                                    width: '40px',
                                    height: '16px',
                                    borderRadius: '4px',
                                    fontSize: '10px',
                                  }}
                                >
                                  {Math.floor(
                                    (cardTargetDriver[0]?.orderList.reduce((a, b) => a + (b?.capacity ?? 0), 0) /
                                      (cardTargetDriver[0]?.vehicle?.maxCapacity ?? 0)) *
                                      100
                                  ).toString()}
                                  %
                                </Stack>
                              </Text>
                            </Stack>
                          </Stack>
                          <Stack
                            direction="row"
                            sx={{
                              width: '100%',
                              position: 'absolute',
                              top: '70px',
                              right: '1000px',
                            }}
                          >
                            <IconPerson width={20} height={20} />
                            <IcHasLunch width={20} height={20} />
                            <IconDesiredTime width={20} height={20} />
                          </Stack>
                          <RouteDriverControlTable
                            data={cardTargetDriver[0]?.orderList}
                            defaultCheckOrderIds={
                              defaultCheckOrderIds
                                ? defaultCheckOrderIds
                                : cardTargetDriverId === propStates?.driverId
                                ? [propStates?.orderId]
                                : []
                            }
                            {...{
                              onClickTable,
                              cardTargetDriverId,
                              setTarget,
                              setOrderDetailModalOpen,
                              refetchControlRouteByIdData,
                              wideMap,
                              setWideMap,
                            }}
                          />
                        </Stack>
                      </Stack>
                    </BoxContainer>
                  ) : (
                    tabStackProps.isOpen &&
                    tabStackProps.subject === 'history' && (
                      <Stack align="start" spacing={30} sx={{ padding: '0 20px 48px', height: '100%' }}>
                        <Text styleName="title1" color="RG03">
                          {strings.주행히스토리}
                        </Text>

                        {historyList.length > 0 ? (
                          <BoxContainer useVerticalScroll align={'start'} justify={'start'}>
                            <Stack justify="start" align="start">
                              <Stack spacing={8} align="baseline">
                                {historyList.map(d =>
                                  d.comment === '도착지 이동' && d.type === 'completed' ? null : (
                                    <HistoryText
                                      obj={d}
                                      callback={v => {
                                        if (v.driverId && v.orderId) {
                                          onClickMarkerShowDetail(v.orderId);
                                          // onClickMarkerSelectTable(v.orderId);
                                          // onClickPolylineOpenDriver(v.driverId);
                                        }
                                      }}
                                    />
                                  )
                                )}
                              </Stack>
                            </Stack>
                          </BoxContainer>
                        ) : (
                          <BoxContainer align="center" sx={{ height: '100%' }}>
                            <Text styleName="title2" color="RG05">
                              {window.localStorage.getItem('serviceDomain') === 'roouty'
                                ? '주행 히스토리'
                                : '업무 히스토리'}
                              가 없습니다.
                            </Text>
                          </BoxContainer>
                        )}
                      </Stack>
                    )
                  )}
                </DriverStack>
              </Stack>
            );
          }, [
            alt.content,
            alt.visible,
            anchorPoint,
            cardTargetDriver,
            cardTargetDriverId,
            defaultCheckOrderIds,
            historyList,
            onClickTable,
            propStates?.driverId,
            propStates?.orderId,
            refetchControlRouteByIdData,
            routeStartTime,
            tabStackProps.isOpen,
            tabStackProps.subject,
            totalReqTime,
            wideMap,
          ])}

          {target && (
            <OrderDetailModal
              jtc={false}
              nomt
              noModi
              hideLoading={true}
              sx={
                wideMap
                  ? tabStackProps.subject === 'driver'
                    ? { marginLeft: `${(driverStackRef.current?.clientWidth ?? 0) - 288 - 128 + 20}px` }
                    : {}
                  : { marginLeft: `${(driverStackRef.current?.clientWidth ?? 0) / 2 - 144}px` }
              }
              style={tabStackProps.subject === 'driver' ? { justifyContent: 'flex-start' } : {}}
              {...{ target, isOpen: orderDetailModalOpen, setIsOpen: setOrderDetailModalOpen }}
            />
          )}

          <Stack
            sx={{
              width: `calc(100% - ${landing ? 0 : 288}px)`,
              height: '100%',
              flex: 1,
              position: 'absolute',
            }}
            ref={mapboxRef}
          >
            <RouteControlMapV2
              data={controlRouteByIdData}
              locationMqResponse={locationMqResponse}
              selectedDriver={
                tabStackProps.isOpen && tabStackProps.subject === 'driver' ? cardTargetDriverId : DriverIdUnSelected
              }
              isRefetching={isRefetching}
              showDetail={onClickMarkerShowDetail}
              selectTable={onClickMarkerSelectTable}
              selectedOrder={selectedOrder}
              selectDriver={onClickPolylineOpenDriver}
              wideMap={wideMap}
              setWideMap={setWideMap}
            />
          </Stack>
        </Stack>
      </PageContentWrapper>

      {wideMap && (
        <div
          ref={dimRef}
          style={{
            zIndex: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'fixed',
            right: 0,
            bottom: 0,
            width: `${routeListTabIsOpen ? '100%' : '0px'}`,
            height: 'calc(100% - 72px)',
            backgroundColor: '#565B6966',
          }}
        >
          <Stack
            ref={containerRef}
            direction="column"
            sx={{
              width: 'fit-content',
              height: '100%',
            }}
          >
            <Button
              variant={'default'}
              type={'button'}
              styleName={'body1'}
              color={'RG00'}
              width={134}
              height={40}
              sx={{
                top: '20px',
                right: '20px',
                position: 'absolute',

                borderRadius: '40px',
                boxShadow: '0px 2px 4px 0px #00000033',
              }}
              onClick={() => {
                controlRouteByIdData?.routeStatus &&
                  navigate(`/manage/control/${controlRouteByIdData.routeStatus}`, { replace: true });
                setRouteListTabIsOpen(true);
              }}
            >
              배차 목록 보기
            </Button>
            <DriverStack
              align={'start'}
              sx={{
                border: '1px solid #D8DDEA',
                background: 'white',
                zIndex: 4,
                width: `${routeListTabIsOpen ? '936px' : '0px'}`,
                minWidth: `${routeListTabIsOpen ? '936px' : '0px'}`,
                height: '100%',
              }}
            >
              {routeListTabIsOpen && !landing && (
                <Button
                  onClick={() => {
                    setRouteListTabIsOpen(false);
                  }}
                  variant={'default'}
                  type={'button'}
                  width={24}
                  height={24}
                  sx={{
                    borderRadius: '24px',
                    border: '1px solid #D8DDEA',
                    background: '#FFFFFF',
                    position: 'relative',
                    top: '20px',
                    right: '12px',
                    zIndex: 8,
                  }}
                >
                  <IcArrowRight
                    style={{
                      width: '10px',
                    }}
                  />
                </Button>
              )}
              <MonitoringTable
                {...{
                  isOpen: routeListTabIsOpen,
                  isLanding: landing,
                  pageStatus,
                  setPageStatus,
                  targetRouteId,
                  performedDate,
                  setPerformedDate,
                  rowSelection,
                  setRowSelection,
                  setTargetRouteId,
                  refetchControlRouteByIdData,
                  setRouteListTabIsOpen,
                }}
              />
            </DriverStack>
          </Stack>
        </div>
      )}
      <Modal
        isModalOpen={processingStatusModalIsOpen}
        setIsModalOpen={setProcessingStatusModalIsOpen}
        width={504}
        padding={24}
        isLoadingModal={undefined === 'loading'}
        loadingPlaceholder={
          <Text styleName="subheadline2" color="RG02">
            주행중으로 배차 상태가 변경되었습니다.
          </Text>
        }
        modalFooter={
          <Button
            fullWidth
            styleName="body2"
            color="RG03"
            variant={'second'}
            type={'button'}
            sx={{ padding: '8px 10px', margin: '40px 0 0 0' }}
            onClick={() => {
              setProcessingStatusModalIsOpen(false);
            }}
          >
            닫기
          </Button>
        }
      >
        <span
          style={{
            lineHeight: '100px',
            fontWeight: '700',
          }}
        >
          주행중으로 배차 상태가 변경되었습니다.
        </span>
      </Modal>

      <CloseConfirmModal
        isOpen={endStatusModalIsOpen}
        setIsOpen={setEndStatusModalIsOpen}
        targetSetIsOpen={undefined}
        text={
          <>
            <Text
              styleName={'caption1'}
              sx={{
                fontSize: '14px',
              }}
            >
              주행종료로 배차 상태가 변경되었습니다.
            </Text>
            <Text
              styleName={'caption3'}
              sx={{
                fontSize: '14px',
              }}
            >
              보고서 바로가기를 통해 주행 데이터를 확인할 수 있습니다.
            </Text>
          </>
        }
        ms={17}
        btnFunctions={{ LBT: closer, RBT: navigateReport }}
        RBT={'보고서 바로가기'}
        LBT={'확인'}
      />
    </Stack>
  );
};

export default RouteControlPage;

export function HistoryText({ obj, callback }: { obj: IHistoryList; callback?: (v: IHistoryList) => void }) {
  return (
    <Text
      styleName="caption2"
      color="RG03"
      sx={{ flexWrap: 'nowrap', padding: '2px' }}
      key={`${obj.routeIndex}-${obj.driverName}-${obj.createdAt}`}
    >
      {/* {dayjs(obj.createdAt).format(
        JSON.parse(process.env.REACT_APP_TB_ONLY as string) ? 'YY.MM.DD HH : mm : ss' : 'YY.MM.DD HH : mm'
      )} */}
      {dayjs(obj.createdAt).format('HH : mm ')}
      &nbsp;<Highlight color="RG02">{obj.driverName}</Highlight>&nbsp;
      {obj?.comment === '도착지 이동' ? (
        <Highlight color="RG02">
          {obj.type === 'processing' ? '차고지입고중' : obj.type === 'arrived' ? '차고지입고완료' : null}
        </Highlight>
      ) : (
        <>
          <Highlight
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
            }}
            color="RC02"
            onClick={() => {
              callback && callback(obj);
            }}
          >
            {obj.type === 'start' || obj.type === 'end' ? null : `${obj?.routeIndex}번째 ${strings.주문} `}
          </Highlight>
          <Highlight color="RG02">
            {obj.type === 'start' || obj.type === 'end' ? (
              <Highlight
                color="RC02"
                sx={{
                  fontWeight: 'bolder',
                }}
              >
                {validateControlDriverStatus(obj.type as TControlDriverStatus)}
              </Highlight>
            ) : (
              <>{validateControlDriverStatus(obj.type as TControlDriverStatus)}</>
            )}
          </Highlight>
        </>
      )}
    </Text>
  );
}

const DriverStack = styled(Stack)`
  width: 45vw;
  transition: all 0.3s ease-in;
`;
