import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';

type Variables = {
  coordinates: [number, number];
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchModifierMasterOrder>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.MODIFIER_MASTER_ORDER>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchModifierMasterOrder = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.put(endPoint, {
    json: variables,
  }).json();

const useModifierMasterOrder = <TContext = UseMutationGeneric['TData']>(
  id: number,
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.MODIFIER_MASTER_ORDER(id),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchModifierMasterOrder({
        variables: variables,
        ...MASTER_DATA_QUERIES.MODIFIER_MASTER_ORDER(id)[0],
      }),
    options
  );

export { fetchModifierMasterOrder, useModifierMasterOrder };
