import { MyPageSection } from '../common';
import { Stack } from '../../../components/Stack';
import { ReactComponent as IcCard } from 'constants/icon/ic_card_bg.svg';
import { ReactComponent as IcPerson } from 'constants/icon/ic_person_bg.svg';
import { ReactComponent as IcDocuments } from 'constants/icon/ic_documents_bg.svg';
import { useEffect, useState } from 'react';
import { PaymentMethod } from '../../../components/payment/paymentMethod';
import { PaymentHistoryTable } from '../../../components/Table/payment/PaymentHistoryTable';
import { PaymentReceipt } from './PaymentReceipt';
import { FetchPaymentInfoData, useGetPaymentInfo } from '../../../hooks/query/payment/useGetPaymentInfo';
import { usePatchPaymentManager } from '../../../hooks/query/payment/usePatchPaymentManager';
import { PaymentInfo } from '../../../components/payment/paymentInfo';
import { useSearchParams } from '../../../hooks/useSearchParams';
import QueryStatusModal from '../../../components/Modal/QueryStatusModal';
import { useQueryClient } from '@tanstack/react-query';
import { PAYMENT_QUERIES } from '../../../hooks/query/payment/payment.queries';
import { PaymentStatus } from '../../../components/Modal/warning/paymentStatus/PausedStatusModal';
import { useTracking } from '../../../hooks/store/useTracking';

export const PaymentManage = () => {
  const [receiptId, setReceiptId] = useState<number>(0);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isDisabledModifiedButton, setIsDisabledModifiedButton] = useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(true);
  const [paymentInfoData, setPaymentInfoData] = useState<Partial<FetchPaymentInfoData>>({});
  const { getSearchParam, deleteSearchParam } = useSearchParams();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [isPausedModalOpen, setIsPausedModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const [orderCode, setOrderCode] = useState<string>('');
  const { mutate: setPaymentManager, data: paymentManagerData, isSuccess } = usePatchPaymentManager();
  const { setTitle } = useTracking();
  const { data } = useGetPaymentInfo();

  useEffect(() => {
    setIsDisabledModifiedButton(false);
    setIsPausedModalOpen(true);
  }, []);

  useEffect(() => {
    if (receiptId) {
      setTitle('마이페이지>결제관리>결제내역 상세');
    } else {
      setTitle('마이페이지>결제관리');
    }
  }, [receiptId, setTitle]);

  useEffect(() => {
    if (isSuccess) {
      setIsModified(false);
      setOpenModal(true);
      setModalText('결제 정보 변경이 완료되었습니다.');

      queryClient.invalidateQueries({ queryKey: PAYMENT_QUERIES.GET_PAYMENT_INFO() });
    }
  }, [isSuccess, queryClient]);

  useEffect(() => {
    let hasName = false;
    let hasPhone = false;
    let hasEmail = false;

    if (paymentInfoData.name === undefined || !!paymentInfoData.name) {
      hasName = true;
    }

    if (
      paymentInfoData.phone === undefined ||
      !!paymentInfoData.phone ||
      paymentManagerData?.phone === paymentInfoData.phone
    ) {
      hasPhone = true;
    }

    if (paymentInfoData.email === undefined || !!paymentInfoData.email) {
      hasEmail = true;
    }

    setIsDisabledSaveButton(!(hasName && hasPhone && hasEmail));
  }, [isModified, paymentInfoData.email, paymentInfoData.name, paymentInfoData.phone]);

  useEffect(() => {
    const modalType = getSearchParam('modalType');
    const orderCode = window.localStorage.getItem('orderCode');

    if (modalType === 'changePayMethodSuccess') {
      setOpenModal(true);
      setModalText('결제 수단 변경이 완료되었습니다.');
      deleteSearchParam('modalType');
      queryClient.invalidateQueries({ queryKey: PAYMENT_QUERIES.GET_PAYMENT_METHOD() });
    }

    if (orderCode) {
      setOrderCode(orderCode);
    }
  }, [deleteSearchParam, getSearchParam, queryClient]);

  return receiptId ? (
    <PaymentReceipt receiptId={receiptId} setReceiptId={setReceiptId} />
  ) : (
    <>
      <Stack align="start" sx={{ gap: '40px' }}>
        <MyPageSection title="결제 수단 관리" icon={<IcCard width={28} height={28} />} useHeaderButton={false}>
          <PaymentMethod />
        </MyPageSection>
        {data?.name && (
          <MyPageSection
            title="결제 정보 관리"
            icon={<IcPerson width={28} height={28} />}
            isModified={isModified}
            setIsModified={setIsModified}
            disableModifiedModeButton={isDisabledModifiedButton}
            hasError={isDisabledSaveButton}
            hasDeleteButton={true}
            callback={() => {
              setPaymentManager({
                ...paymentInfoData,
              });
            }}
          >
            <PaymentInfo
              isModified={isModified}
              setPaymentInfoData={setPaymentInfoData}
              setIsDisabledSaveButton={setIsDisabledSaveButton}
            />
          </MyPageSection>
        )}
        <MyPageSection
          title="결제 내역"
          icon={<IcDocuments width={28} height={28} />}
          useHeaderButton={false}
          hasBorder={false}
        >
          <PaymentHistoryTable setReceiptId={setReceiptId} />
        </MyPageSection>
      </Stack>
      <QueryStatusModal status="success" isOpen={openModal} setIsOpen={setOpenModal} string={modalText} />

      <PaymentStatus isOpen={isPausedModalOpen} setIsOpen={setIsPausedModalOpen} />
    </>
  );
};
