import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { createTeam, isTeamNameDuplicated } from 'api';
import { ICreateTeamProps } from 'constants/types';

import { genLabel } from './SingleOrderForm';

import Modal from '.';
import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import { Button } from 'components/Button/legacy_index';
import { Highlight, Text } from 'components/Typography';
import { FormTextarea } from 'components/Table/TeamManagementTable';

import theme from 'constants/theme';
import GrayBox from 'pages/Setting/common/GrayBox';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';

import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcSuccessOutline } from 'constants/icon/ic_success_circle_outline.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcRemove } from 'constants/icon/input_remove.svg';
// import { monthly_billing_ranges, price_plan_contents as origin_price_plan_contents } from 'pages/Landing';
import styled from 'styled-components';

const AddTeamModal = ({
  isOpen,
  setIsOpen,
  onControl,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onControl: any;
}) => {
  const methods = useForm<ICreateTeamProps>({ mode: 'onSubmit' });
  const { handleSubmit } = methods;

  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);
  const [paymentPlanModalIsOpen, setPaymentPlanModalIsOpen] = useState<boolean>(false);

  const { mutate: mutateCreateTeam, isLoading } = useMutation(createTeam, {
    onSuccess: () => {
      setIsOpen(false);
      onControl();
    },
  });

  const initializer = useCallback(() => {
    methods.reset();
    setIsTermChecked(false);
  }, [methods]);

  useEffect(() => {
    initializer();
  }, [initializer, isOpen]);

  return (
    <Fragment>
      <Modal isLoadingModal={isLoading} plain width={540} isModalOpen={isOpen} setIsModalOpen={setIsOpen}>
        <OrderDetailModalHeader>
          <Stack direction="row" justify="space-between">
            <Text styleName="subheadline2">신규 팀 추가하기</Text>
            <IcClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </Stack>
        </OrderDetailModalHeader>
        <Divider color="RG06" />
        <form>
          <OrderDetailModalBody
            spacing={30}
            style={{
              padding: '30px 20px',
            }}
          >
            <Input
              label={genLabel({ text: '추가 일자' })}
              name="createdAt"
              field
              styleName={'subheadline2'}
              type="text"
              height={40}
              value={dayjs().format('YYYY.MM.DD')}
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              disabled={true}
              readOnly={true}
            />
            <Input
              label={genLabel({ text: '팀 이름', isRequiredStart: true })}
              name="name"
              placeholder={'20자 이내의 팀 이름을 입력해주세요.'}
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              field
              styleName={'subheadline2'}
              variant={'third'}
              type="text"
              height={40}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!Boolean(e.target.value) || e.target.value === '') {
                  methods.setError('name', { type: 'required', message: '' });
                } else
                  isTeamNameDuplicated(e.target.value).then(res => {
                    if (res.hasTeam) {
                      methods.setError('name', { type: 'duplicatedName', message: '중복된 팀 이름입니다.' });
                    } else methods.clearErrors('name');
                  });

                methods.setValue('name', e.target.value, { shouldDirty: true, shouldValidate: true });
              }}
              removeBtn={() => {
                methods.resetField('name');
              }}
              onClick={() => {
                // setReceivedDatePopupIsOpen(true);
              }}
              validation={{
                required: { value: true, message: '해당 필드는 필수값입니다.' },
                maxLength: { value: 20, message: '팀 이름은 한글/영문 20자 이내로 입력이 가능합니다.' },
                validate: (res: string) => {
                  return (
                    /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩ ]{0,20}/.test(res) ||
                    '팀 이름은 한글/영문 20자 이내로 입력이 가능합니다.'
                  );
                },
              }}
              errors={methods.formState?.errors?.name}
              hasError={Boolean(methods.formState?.errors?.name)}
            />

            <Stack spacing={4}>
              {genLabel({ text: '팀 메모' })}
              <FormTextarea
                {...methods.register('memo', {})}
                placeholder={'팀에 대한 메모를 적어주세요. 예시) 루티 물류센터 출발'}
                rows={14}
                cols={10}
                wrap="soft"
                maxLength={200}
                onKeyDown={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
                onKeyUp={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
              />
            </Stack>

            {/* <GrayBox spacing={8}>
              <Text styleName="subheadline1" color="RG03">
                {`팀 추가 시 확인해주세요.`}
              </Text>
              <Text styleName="subheadline2" color="RG03">
                {`1. 각 팀은 독립적으로 주문을 관리하고 배차를 계획합니다.`}
              </Text>
              <Text
                styleName="subheadline2"
                color="RG03"
              >{`2. 팀 추가 시 추가 일자를 기준으로 다음 결제일부터 청구됩니다.`}</Text>
              <Text
                styleName="subheadline2"
                color="RG03"
              >{`3. 팀을 추가 한 첫 번째 달 요금은 요금이 일할 계산되어 청구됩니다.`}</Text>
              <Text
                styleName="subheadline2"
                color="RG03"
              >{`4. 요금 내역은 [마이페이지 > 요금 명세서] 에서 확인하실 수 있습니다.`}</Text>
            </GrayBox>

            <Stack direction="row" justify="space-between">
              <Stack
                direction="row"
                spacing={10}
                onClick={() => {
                  setIsTermChecked(prev => !prev);
                }}
                style={{ width: 'max-content', cursor: 'pointer' }}
              >
                {isTermChecked ? (
                  <IcSuccess width={18} height={18} fill={theme.colors.RC02} />
                ) : (
                  <IcSuccessOutline width={18} height={18} />
                )}

                <Text styleName="body1" color="RG03">
                  팀 추가에 대한 변경 사항을 확인하였습니다.
                </Text>
              </Stack>
              <Button
                variant="RTB"
                type="button"
                height={24}
                width={88}
                styleName="caption1"
                onClick={() => {
                  setPaymentPlanModalIsOpen(true);
                }}
              >
                요금제 보기
              </Button>
            </Stack> */}
          </OrderDetailModalBody>
        </form>

        <Divider color="RG06" />
        <OrderDetailModalFooter>
          <Stack
            name="modal-footer"
            spacing={16}
            direction="row"
            align="center"
            justify="end"
            sx={{ padding: '2px 0 0 0' }}
          >
            <Button
              variant="seventh"
              type="button"
              width={102}
              height={40}
              styleName="body1"
              color="RG04"
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
            >
              취소
            </Button>
            <Button
              variant="default"
              type="button"
              width={216}
              height={40}
              styleName="body1"
              color="RG00"
              disabled={/*!isTermChecked ||*/ !methods.watch('name')}
              onClick={handleSubmit(res => {
                console.log(res);
                let contain = { ...res };
                if (contain.memo === '') delete contain.memo;

                mutateCreateTeam({ ...contain });
              })}
            >
              저장하기
            </Button>
          </Stack>
        </OrderDetailModalFooter>
      </Modal>
      {/* <PaymentPlanModal isOpen={paymentPlanModalIsOpen} setIsOpen={setPaymentPlanModalIsOpen} /> */}
    </Fragment>
  );
};

export default AddTeamModal;

// export const PaymentPlanModal = ({
//   isOpen,
//   setIsOpen,
// }: {
//   isOpen: boolean;
//   setIsOpen: Dispatch<SetStateAction<boolean>>;
// }) => {
//   const price_plan_contents = [
//     {
//       ...origin_price_plan_contents[0],
//       title: 'Monitoring Only',

//       theme: 'RG07',
//       point: 'RG03',

//       items: ['엑셀주문 업로드', '실시관 관제', 'API 주문 연동'],
//     },
//     {
//       ...origin_price_plan_contents[1],
//       title: 'Optimization Plan',

//       items: ['Monitoring Plan의 모든 기능 포함', '자동경로 생성', '실시간 교통정보 적용'],
//     },
//     {
//       ...origin_price_plan_contents[2],
//       title: 'Enterprise Plan',

//       items: ['모든 기능 기업맞춤형 가능', 'Optimization Plan의 모든 기능 포함', 'Monitoring Plan의 모든 기능 포함'],
//     },
//   ];

//   const PURB = {
//     route: (
//       <Text
//         styleName="caption2"
//         sx={{
//           position: 'absolute',
//           top: '64px',
//         }}
//       >
//         월간 배차 사용 횟수가
//         <br />
//         5,000건 이상일 경우
//       </Text>
//     ),
//     via: (
//       <Text
//         styleName="caption2"
//         sx={{
//           position: 'absolute',
//           top: '64px',
//         }}
//       >
//         월간 경유지 사용 횟수가
//         <br />
//         100,000건 이상일 경우
//       </Text>
//     ),
//   };

//   return (
//     <Modal bd plain ds="strong" isModalOpen={isOpen} setIsModalOpen={setIsOpen} width={890} height="622px" padding={20}>
//       <Stack spacing={20}>
//         <Stack justify="space-between" direction="row">
//           <Text styleName="subheadline2">루티 서비스 요금제 안내</Text>
//           <IcClose
//             style={{ cursor: 'pointer' }}
//             onClick={() => {
//               setIsOpen(false);
//             }}
//           />
//         </Stack>
//         <Stack direction="row" spacing={20} justify="center">
//           {price_plan_contents.map(content => {
//             return (
//               <PlanCard spacing={20} align="start" key={`card-${content.title}-plan`}>
//                 <PlanCardHeader color={content.point} bgColor={content.theme}>
//                   {content.title}
//                 </PlanCardHeader>
//                 <Text styleName="body2">
//                   월&nbsp;<Highlight styleName="body1">{content.price.toLocaleString()}&nbsp;원</Highlight>&nbsp;
//                   <Highlight styleName="caption2">(팀 요금)</Highlight>
//                 </Text>

//                 <Stack spacing={12} align="start">
//                   {content.items.map(item => {
//                     return (
//                       <Stack direction="row" spacing={10}>
//                         <IconSuccess fill={theme.colors.RC03} style={{ width: '14px', height: '14px' }} />
//                         <Text styleName="subheadline1">{item}</Text>
//                       </Stack>
//                     );
//                   })}
//                 </Stack>

//                 <Stack className="content" name="pricing" spacing={16} align="start" sx={{ marginBottom: 20 }}>
//                   {(Object.keys(monthly_billing_ranges) as Array<keyof typeof monthly_billing_ranges>).map(key => {
//                     const MBRC = monthly_billing_ranges[key];
//                     const sa = Array.isArray(content.PUBR[key]);

//                     return (
//                       <Stack spacing={6} align="start" sx={{ width: 'max-content' }}>
//                         <Text styleName="caption1">{MBRC.title}</Text>
//                         {sa ? (
//                           <Stack direction="row" spacing={6}>
//                             <CellTable>
//                               <tr>
//                                 <th>{MBRC.rangeKey}</th>
//                               </tr>
//                               {MBRC.range.map(range => {
//                                 return (
//                                   <tr>
//                                     <td>{range}</td>
//                                   </tr>
//                                 );
//                               })}
//                             </CellTable>
//                             <CellTable width="101px">
//                               <tr>
//                                 <th>{`단가 (원)`}</th>
//                               </tr>

//                               {
//                                 //@ts-ignore
//                                 content.PUBR[key].map(d => {
//                                   return (
//                                     <tr>
//                                       <td>{d}</td>
//                                     </tr>
//                                   );
//                                 })
//                               }
//                             </CellTable>
//                           </Stack>
//                         ) : (
//                           <Stack
//                             sx={{
//                               position: 'relative',
//                               width: 'max-content',
//                             }}
//                           >
//                             {PURB[key]}
//                             <Stack direction="row" spacing={6}>
//                               <CellTable pop>
//                                 <tr>
//                                   <th>{MBRC.rangeKey}</th>
//                                 </tr>
//                                 <tr>
//                                   <td />
//                                 </tr>
//                               </CellTable>
//                               <CellTable pop width="101px">
//                                 <tr>
//                                   <th>{`단가 (원)`}</th>
//                                 </tr>

//                                 <tr>
//                                   <td />
//                                 </tr>
//                               </CellTable>
//                             </Stack>
//                           </Stack>
//                         )}
//                       </Stack>
//                     );
//                   })}
//                 </Stack>
//               </PlanCard>
//             );
//           })}
//         </Stack>
//       </Stack>
//     </Modal>
//   );
// };

const PlanCard = styled(Stack)`
  border-radius: 8px;
  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG06}`,
  })}

  & > * {
    padding: 0 16px;
  }
`;

const PlanCardHeader = styled(Stack)<{ color: string; bgColor: string }>`
  align-items: start;

  padding: 10px 16px;
  border-radius: 8px 8px 0 0;

  ${({ theme, color, bgColor }) => ({
    color: theme.colors[color!],
    backgroundColor: bgColor.startsWith('RC') ? theme.colors[bgColor!] + '1A' : theme.colors[bgColor!],
  })}

  ${({ theme }) => theme.fontStyle.body1}
`;

const CellTable = styled.table<{ pop?: boolean; width?: string }>`
  ${({ theme, width }) => ({
    width: width ?? '131px',
    borderTop: `1px solid ${theme.colors['RG05']}`,
    borderBottom: `1px solid ${theme.colors['RG05']}`,

    '& > tr> td': {
      borderTop: `1px solid ${theme.colors['RG05']}`,
      borderBottom: `1px solid ${theme.colors['RG05']}`,
    },
  })};
  & > tr > td,
  th {
    text-align: start;
    border-collapse: collapse;
  }
  & > :first-child > th {
    padding: 2px 4px;
    background-color: ${({ theme }) => theme.colors['RG07']};
    ${({ theme }) => theme.fontStyle['caption1']}
  }
  & > :not(:first-child) > td {
    ${({ theme, pop }) =>
      pop
        ? {
            height: '120px',
          }
        : {
            padding: '4px',
            backgroundColor: theme.colors['RG00'],
          }}
  }
  ${({ theme }) => theme.fontStyle['caption2']};
`;
