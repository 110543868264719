import Layout from 'pages/Setting/Layout';
import { useEffect, useMemo, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { NewTable, SearchFilterbar } from 'pages/Setting/common';
import PageContentWrapper from 'components/PageContentWrapper';
import { useStore } from 'store';

import { ReactComponent as IcPlus } from 'constants/icon/ic_add_member.svg';
import {
  getManagerList,
  initializeManagerList,
  InitializeSelectedList,
  MANAGER_MENUS,
  ManagerHeaderModals,
  ManagerTableColumns,
  switchMode,
} from 'pages/Setting/organization/Manager';
import * as S from 'pages/Setting/common/style';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { managerContentReducer, managerContentState } from 'pages/Setting/organization/Manager/reducer';
import { INVITED_MANAGER_FILTER, INVOLVED_MANAGER_FILTER } from 'pages/Setting/organization/Manager/constants';
import {
  convertSearchField,
  loadingModalAlertText,
  updateModalType,
  updateSelectedRowList,
} from 'pages/Setting/organization/Manager/container';
import { useTracking } from '../../../../hooks/store/useTracking';

export const ManagerContent = () => {
  const { isLoading, userGrade } = useStore();

  const [_state, _dispatch] = useReducer(managerContentReducer, managerContentState);
  const { getValues, watch, setValue, register } = useForm<TManagerListQueryParams>({
    defaultValues: { searchItem: 'teamName', keyword: '', placeholder: '소속 팀을 입력해주세요.' },
  });

  const { INVOLVEDFORM, INVITEDFORM, anchorIsOpen, setAnchorIsOpen, anchorPoint } = ManagerTableColumns({
    _dispatch,
    _state,
  });
  const { mode, invitedManagerList, involvedManagerList, type } = _state;
  const tableConfig = mode === 'Involved' ? INVOLVEDFORM : INVITEDFORM;

  useEffect(() => {
    if ([invitedManagerList, involvedManagerList].every(Boolean) === false) {
      initializeManagerList({ _dispatch, mode });
    }

    InitializeSelectedList({ _dispatch, mode });
    getManagerList({ _dispatch, mode });
  }, [mode]);

  const isFetching = mode === 'Involved' ? involvedManagerList === null : invitedManagerList === null;

  const SELECT_OPTION = mode === 'Involved' ? INVOLVED_MANAGER_FILTER : INVITED_MANAGER_FILTER;

  const loadingAlertText = useMemo(() => {
    return loadingModalAlertText(type);
  }, [type]);
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('설정>매니저 관리');
  }, [setTitle]);

  return (
    <Layout
      attr="team"
      title={'매니저 관리'}
      desc={'매니저를 초대하고 매니저의 소속 팀을 변경할 수 있습니다.'}
      ts={
        userGrade === 1 && (
          <Button
            type="button"
            variant="primary"
            h={32}
            w={154}
            icon={[IcPlus, { fill: '#FFF' }]}
            onClick={() => {
              const pricing =
                localStorage.getItem('pricing') === null ? null : JSON.parse(localStorage.getItem('pricing')!);
              if (pricing === null || pricing?.id === 1) {
                return updateModalType({ type: 'TrialModeWarning', isOpen: true, _dispatch });
              }
              updateModalType({ type: 'InviteManager', isOpen: true, _dispatch });
            }}
          >
            매니저 초대하기
          </Button>
        )
      }
    >
      <LoadingModal text={loadingAlertText} isLoading={isLoading} />
      <Stack align={'flex-start'} direction={'row'}>
        <ManagerHeaderModals _state={_state} _dispatch={_dispatch} />
        <PageContentWrapper>
          <Stack align={'start'} justify={'start'}>
            <Stack align={'start'} sx={{ width: '100%' }}>
              <Stack>
                <Stack sx={{ marginBottom: '30px' }}>
                  <Stack direction={'row'}>
                    {MANAGER_MENUS.map((menu, index) => {
                      const { title, key } = menu;
                      if (userGrade !== 1 && menu.key === 'Invited') return null;
                      return (
                        <S.Tab
                          key={index}
                          index={index}
                          isActive={key === mode}
                          onClick={() => switchMode({ _dispatch, mode: key })}
                        >
                          {title}
                        </S.Tab>
                      );
                    })}
                  </Stack>

                  <SearchFilterbar
                    callback={() => {
                      const payload = getValues();
                      getManagerList({ _dispatch, mode, payload });
                    }}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    options={SELECT_OPTION}
                    defaultValue={SELECT_OPTION[0].value}
                    placeholder={watch('placeholder')}
                    placeholderConvertor={convertSearchField}
                  />
                </Stack>
              </Stack>
              <NewTable
                emptyDataMessage={mode === 'Invited' ? '불러올 데이터가 없습니다.' : undefined}
                setRowSelection={updateSelectedRowList({ _dispatch, mode })}
                data={invitedManagerList || involvedManagerList || []}
                tableRowCount={tableConfig.tableUnit}
                isFetching={isFetching}
                tableHeaderContent={userGrade === 1 ? tableConfig.headerContent : null}
                columns={tableConfig.columns}
                {...{ anchorIsOpen, setAnchorIsOpen, anchorPoint }}
              />
            </Stack>
          </Stack>
        </PageContentWrapper>
      </Stack>
    </Layout>
  );
};
