import type { NavigateFunction } from 'react-router-dom';
import { AlertModal } from 'components/Modal/AlertModal';

interface IInvalidEmailErrorModal {
  isOpen: boolean;
  navigate: NavigateFunction;
  title: string;
  messages: string[];
}

export const InvalidEmailErrorModal = (props: IInvalidEmailErrorModal) => {
  const { isOpen, navigate, title, messages } = props;
  return (
    <AlertModal
      isOpen={isOpen}
      type="WARNING"
      title={title}
      messages={messages}
      buttonName="확인"
      callback={() => {
        const path = '/';
        navigate(path);
      }}
    />
  );
};
