import cloneDeep from 'lodash.clonedeep';
import { calculateCurrrnStatus } from 'pages/Setting/organization/Manager/inviteManagerModal';
const inviteManagerInitialState: TInviteManagerInitialState = {
  inputedEmailList: null,
  inspectedEmailList: null,
  currentStatus: null,
  teamId: null,
  teamList: null,
};

const inviteManagerReducer = (state: TInviteManagerInitialState, action: TInviteManagerAction) => {
  const { type, payload } = action;
  const _state = cloneDeep(state);
  switch (type) {
    case 'UPDATE_INSPECTED_EMAIL_LIST': {
      if (_state.inspectedEmailList) {
        _state.inspectedEmailList = _state.inspectedEmailList.concat(payload);
        _state.currentStatus = calculateCurrrnStatus(_state.inspectedEmailList);
      } else {
        _state.inspectedEmailList = payload;
        _state.currentStatus = calculateCurrrnStatus(payload);
      }

      return _state;
    }
    case 'DELETE_INSPECTED_EMAIL_LIST': {
      if (_state.inspectedEmailList) {
        _state.inspectedEmailList.splice(payload, 1);
        _state.currentStatus = calculateCurrrnStatus(_state.inspectedEmailList);
      }
      return _state;
    }
    case 'EDIT_INSPECTED_EMAIL': {
      if (_state.inspectedEmailList) {
        _state.inspectedEmailList = _state.inspectedEmailList.map((loginAccount, idx) => {
          if (payload.index === idx) {
            return payload.loginAccount;
          }
          return loginAccount;
        });

        _state.currentStatus = calculateCurrrnStatus(_state.inspectedEmailList);
      }
      return _state;
    }
    case 'DELETE_ERROR_EMAILS':
      if (_state.inspectedEmailList) {
        _state.inspectedEmailList = _state.inspectedEmailList.filter(email => email.type === undefined);
        _state.currentStatus = calculateCurrrnStatus(_state.inspectedEmailList);
        if (_state.inspectedEmailList.length === 0) {
          _state.inspectedEmailList = null;
        }
      }
      return _state;

    case 'UPDATE_TEAM_LIST':
      _state.teamList = payload;
      return _state;

    case 'UPDATE_TEAM_ID':
      _state.teamId = payload;
      return _state;

    case 'INITIALIZE_INSPECTED_LIST':
      _state.inspectedEmailList = null;
      return _state;

    default:
      return _state;
  }
};

export { inviteManagerInitialState, inviteManagerReducer };
