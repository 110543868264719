import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { ModifiedMultiSkillsSection } from 'components/ModifiedSection/ModifiedMultiSkillsSection';
import DatePickerPopup from 'components/Popup/DatePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';

import { SkillPicker } from 'components/Popup/SkillPicker';
import { Stack } from 'components/Stack';

import { Swap } from 'components/Swap';

import { Text } from 'components/Typography';
import { priority_type, shipment_type } from 'constants/commons';
import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IconTrash } from 'constants/icon/ic_trash.svg';
import theme from 'constants/theme';
import dayjs from 'dayjs';
import useFormUtils from 'hooks/useFormUtils';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dateConverter from 'util/dateConverter';
import Modal from '.';
import { addSingleOrder, getVehicleInfo } from '../../api';
import { TUTORIAL_QUERIES } from '../../hooks/query/tutorial/tutorial.queries';
import strings from '../../util/Localization';
import { Input } from '../Input';
import WorkTimePopup from '../Popup/WorkTime';
import { DetailModiForm } from './OrderDetailModal';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';

interface SingleOrderFormProps {
  singleOrderFormIsOpen: boolean;
  setSingleOrderFormIsOpen: Dispatch<SetStateAction<boolean>>;
  updateFunc?: Function;
}

interface genLabelProps {
  text: string;
  isRequired?: boolean;
  isRequiredStart?: boolean;
  isOption?: boolean;
  sideText?: string;
  subText?: string;
  isClicked?: boolean;
}

export const removeProduct = (index: number, methods: UseFormReturn<any>, setter: Dispatch<SetStateAction<any>>) => {
  let productArr: Array<{
    name: string;
    code: string;
    quantity: number;
  }> = [...methods.getValues('product')];
  if (productArr.length !== 1) {
    productArr.splice(index, 1);
  }

  setter(productArr);
  methods.setValue('product', productArr, { shouldDirty: true, shouldValidate: true });
};

export function genLabel (props: genLabelProps) {
  return (
    <Stack spacing={4} align={'start'} direction={'row'}>
      <Text styleName={'caption2'} color={'RG03'}>
        {props.text}
      </Text>
      {props.isRequired && (
        <Text styleName={'caption3'} color={'RC04'}>
          필수
        </Text>
      )}
      {props.isRequiredStart && (
        <Text styleName={'caption3'} color={'RC04'}>
          *
        </Text>
      )}
      {props.isOption && (
        <Text styleName={'caption3'} color={'RG05'}>
          옵션
        </Text>
      )}
      {props.subText && (
        <Text styleName={'caption3'} color={'RG04'}>
          {props.subText}
        </Text>
      )}
      {/* 추후 리팩터링 필요 */}
      {props.sideText && (
        <Stack direction="row" justify="space-between">
          <Text styleName={'caption2'} color={'RG03'}>
            도착지 주소
          </Text>
          {/* <Button
            styleName={'caption3'}
            color={'#8F96A9'}
            variant={'default'}
            type={'button'}
            sx={{
              background: 'none',
              color: '#8F96A9',
              fontWeight: 500,
              fontSize: '12px',
              padding: '0',
              marginRight: '-490px',
            }}
          >
            출발지와 동일
          </Button> */}
        </Stack>
      )}
    </Stack>
  );
}

function SingleOrderForm ({ singleOrderFormIsOpen, setSingleOrderFormIsOpen, updateFunc }: SingleOrderFormProps) {
  const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onChange', shouldFocusError: true });
  const navigate = useNavigate();

  // Set Default Values

  const [startTimePickerPopupIsOpen, setStartTimePickerPopupIsOpen] = useState<boolean>(false);
  const [endTimePickerPopupIsOpen, setEndTimePickerPopupIsOpen] = useState<boolean>(false);
  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);
  const [receivedDatePopupIsOpen, setReceivedDatePopupIsOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [desiredTimeStartNan, setDesiredTimeStartNan] = useState<boolean>(true);
  const [desiredTimeEndNan, setDesiredTimeEndNan] = useState<boolean>(true);
  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [product, setProduct] = useState<Array<any>>([{ quantity: 1 }]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');

  const queryClient = useQueryClient();

  const handlePostcodeComplete = (data: any) => {
    let address = data.address || data.jibunAddress;
    methods.setValue('address', address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(false);
  };

  const {
    mutate: mutateAddSingleOrder,
    isLoading: addSingleOrderIsLoading,
    isSuccess: addSingleOrderIsSuccess,

    status: addSingleOrderStatus,
    reset: addSingleOrderReset,
  } = useMutation(addSingleOrder, {
    onSuccess: () => {
      if (updateFunc) updateFunc();

      queryClient.invalidateQueries({ queryKey: TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_ROUTE() });
    },
    onError: err => console.log(err),
  });

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const handleFormComplete = (data: any) => {
    if (data.desiredDate) data.desiredDate = dateConverter(data.desiredDate, 'YYYY-MM-DD');
    // 주문 접수일
    data.receivedDate = data.receivedDate
      ? dateConverter(data.receivedDate, 'YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD');

    // data.product.quantity = 1; 트버 버전은 항상 1로 고정  /  RT-974 에서 수정됨
    // RT-630 service_time은 옵션값, 값이 없을 시 60으로 고정

    // data.serviceTime = data?.serviceTime ? parseInt(data.serviceTime.replace(/[^0-9]/g, '')) * 60 : 60;
    data.serviceTime = data?.serviceTime ? data.serviceTime.toString().replace(/[^0-9]/g, '') * 60 : 60;

    // data.product.capacity = parseInt(data.product.capacity);
    data.packaging = parseInt(data.packaging) || 1;
    // after 혹은 before 둘 중 하나라도 있으면 반영해야함
    if (data.desiredTimeStart !== '' || data.desiredTimeEnd !== '') {
      if (data?.desiredTimeStart) {
        let key = data.desiredTimeStart?.split(' ')[0];
        let value = data.desiredTimeStart?.split(' ')[1];
        data.desiredTimeStart =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }
      if (data?.desiredTimeEnd) {
        let key = data.desiredTimeEnd?.split(' ')[0];
        let value = data.desiredTimeEnd?.split(' ')[1];

        data.desiredTimeEnd =
          key === '익일오전' ? `${parseInt(value.split(':')[0]) + 24}:${value.split(':')[1]}` : value;
      }
    } else {
      delete data.desiredHour;
    }

    if (
      (data.desiredTimeStart || methods.getValues('desiredTimeStart')) &&
      (data.desiredTimeEnd || methods.getValues('desiredTimeEnd'))
    ) {
      let start = dayjs(
        new Date(
          0,
          0,
          0,
          Number((data.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[0]),
          Number((data.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[1]),
          0,
        ),
      ).format('YYYY/MM/DD HH:mm');

      let end = dayjs(
        new Date(
          0,
          0,
          0,
          Number((data.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[0]),
          Number((data.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[1]),
          0,
        ),
      ).format('YYYY/MM/DD HH:mm');
      if (dayjs(start).isAfter(end)) {
        return methods.setError(
          'desiredTimeStart',
          {
            type: 'invalidDateRange',
            message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
          },
          { shouldFocus: true },
        );
      }
    }

    // 공백 삭제 FIXME
    if (data.receivedDate === '') delete data.receivedDate;
    if (data.consigneePhone === '') delete data.consigneePhone;
    else {
      data.consigneePhone = data.consigneePhone.replace(/[^0-9]/g, '');
    }
    if (data.consigneeNote === '') delete data.consigneeNote;
    if (data.address === '') delete data.address;
    if (data.detailAddress === '') delete data.detailAddress;

    if (data.desiredDate === '') delete data.desiredDate;
    if (data.desiredTimeStart === '') delete data.desiredTimeStart;
    if (data.desiredTimeEnd === '') delete data.desiredTimeEnd;
    if (data.serviceTime === '') delete data.serviceTime;
    if (data.serviceVehicle === '') delete data.serviceVehicle;

    data.product.forEach((d: any, i: number) => {
      if (d.name === '') delete data.product[i].name;
      if (d.code === '') delete data.product[i].code;
    });
    delete data.packaging;
    delete data.completedAt;
    delete data.driverName;

    // data.product = [data.product];

    data.skills = selectedSkillIds;

    console.log(data);

    mutateAddSingleOrder({ props: data });
  };

  const { ivnm, numberPatternOnChangeHandler } = useFormUtils(methods);

  useEffect(() => {
    setSkillSearchValue('');
    setProduct([{ quantity: 1 }]);
    methods.reset();

    methods.setValue(`product[${0}].quantity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });
    methods.setValue(`capacity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });
    methods.setValue('serviceTime', 1, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [singleOrderFormIsOpen]);

  useEffect(() => {
    methods.setValue(`product[${product.length - 1}].quantity`, 1, {
      shouldDirty: true,
      shouldValidate: true,
    });

    methods.setValue('shipmentType', shipment_type[0].value, {
      shouldDirty: true,
    });
  }, [product, methods]);

  const domain = window.localStorage.getItem('serviceDomain');

  // RP-675 아이템 추가 이후 삭제할 수 있도록 버튼 추가

  useEffect(() => {
    console.log(product);
  }, [product]);

  let today = dayjs();
  let todayFormat = today.format('YYYY.MM.DD');

  useEffect(() => {
    methods.setValue('receivedDate', todayFormat, {
      shouldDirty: true,
    });
  }, [singleOrderFormIsOpen]);

  return (
    <Modal
      title={strings.주문추가}
      isModalOpen={singleOrderFormIsOpen}
      setIsModalOpen={setSingleOrderFormIsOpen}
      isLoadingModal={addSingleOrderIsLoading}
      width={addSingleOrderIsLoading || addSingleOrderIsSuccess ? 504 : 496}
      height={'80vh'}
      nomt
      plain
      sx={{
        marginTop: 72,
      }}
    >
      {!addSingleOrderIsLoading &&
        (addSingleOrderIsSuccess ? (
          // RT-577/RT-606 추가
          <Stack padding={24} align="end">
            <IconClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSingleOrderFormIsOpen(false);
                addSingleOrderReset();
              }}
            />
            <Stack spacing={40}>
              <Stack spacing={30}>
                <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }}/>
                <Text styleName="subheadline3" color="RG04">
                  입력하신 {strings.모달_주문}이 이상 없이 추가 되었습니다.
                </Text>
              </Stack>
              <Button
                variant="default"
                type="reset"
                styleName="body2"
                color="RG00"
                fullWidth
                height={40}
                onClick={() => {
                  setSingleOrderFormIsOpen(false);
                  addSingleOrderReset();
                }}
              >
                닫기
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Fragment>
            <OrderDetailModalHeader>
              <Stack direction="row" justify="space-between">
                <Text styleName="subheadline2" color="RG03">
                  {strings.주문추가}
                </Text>
                <IconClose
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSingleOrderFormIsOpen(false);
                    addSingleOrderReset();
                  }}
                />
              </Stack>
            </OrderDetailModalHeader>
            {addSingleOrderStatus === 'idle' && <Divider color="RG06"/>}
            <OrderDetailModalBody useVerticalScroll>
              <Stack
                sx={{
                  maxHeight: 750,
                }}
              >
                <form>
                  <Stack name="content-area">
                    <DetailModiForm title={strings.주문정보}>
                      <Stack sx={{ position: 'relative' }}>
                        {receivedDatePopupIsOpen && (
                          <DatePickerPopup
                            setIsOpen={setReceivedDatePopupIsOpen}
                            setValue={methods.setValue}
                            initDate={dayjs().toString()}
                            name={'receivedDate'}
                            format="YYYY.MM.DD"
                            top="80px"
                            exclude={{ future: true }}
                          />
                        )}
                        <Input
                          label={genLabel({ text: strings.주문접수일, isRequiredStart: true })}
                          name="receivedDate"
                          placeholder={'YYYY.MM.DD'}
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setReceivedDatePopupIsOpen(true);
                          }}
                        />
                      </Stack>
                      {domain === 'roouty' && (
                        <Swap
                          padding={0}
                          gap={0}
                          name="shipmentType"
                          methods={methods}
                          label={genLabel({
                            text: '주문 유형',
                            subText: `${strings.주문} 1개당 배달 또는 수거만 설정 가능`,
                          })}
                          options={shipment_type.filter(d => d.visibleType)}
                        />
                      )}
                      <Swap
                        padding={0}
                        gap={0}
                        sx={{
                          paddingTop: '7px',
                        }}
                        name="priority"
                        methods={methods}
                        label={genLabel({
                          text: '배차 우선순위',
                          subText: `주문이 배차되어야 하는 우선 순위`,
                        })}
                        defaultIndex={1}
                        options={priority_type.filter(d => d.visibleType)}
                      />
                      <Stack sx={{ position: 'relative' }}>
                        {desiredDatePopupIsOpen && (
                          <PopupYIndexFixer>
                            <DatePickerPopup
                              setIsOpen={setDesiredDatePopupIsOpen}
                              setValue={methods.setValue}
                              initDate={
                                methods.getValues('desiredDate')
                                  ? dayjs(methods.getValues('desiredDate')).format('YYYY-MM-DD')
                                  : dayjs().toString()
                              }
                              name={'desiredDate'}
                              banPast
                              top="80px"
                              format="YYYY.MM.DD"
                            />
                          </PopupYIndexFixer>
                        )}
                        <Input
                          name="desiredDate"
                          label={genLabel({ text: strings.작업희망일 })}
                          placeholder="YYYY.MM.DD"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          height={40}
                          readOnly={true}
                          onClick={() => {
                            setDesiredDatePopupIsOpen(true);
                          }}
                        />
                      </Stack>
                      <Stack spacing={6}>
                        <Stack spacing={16} direction={'row'}>
                          <Stack sx={{ position: 'relative', flex: 2 }}>
                            {startTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={startTimePickerPopupIsOpen}
                                  setIsOpen={setStartTimePickerPopupIsOpen}
                                  methods={methods}
                                  name={'desiredTimeStart'}
                                  initial={'00:00'}
                                  init={methods.getValues('desiredTimeStart')}
                                  desired
                                  nm
                                  nanable
                                  nan={desiredTimeStartNan}
                                  setNan={setDesiredTimeStartNan}
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이후 })}
                              placeholder="00 : 00 (몇 시 부터)"
                              type="text"
                              name="desiredTimeStart"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setEndTimePickerPopupIsOpen(false);
                                setStartTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={Boolean(methods.formState.errors?.desiredTimeStart)}
                            />
                          </Stack>
                          <Stack align="end" sx={{ position: 'relative', flex: 2 }}>
                            {endTimePickerPopupIsOpen && (
                              <PopupYIndexFixer>
                                <MiniTimePicker
                                  isOpen={endTimePickerPopupIsOpen}
                                  setIsOpen={setEndTimePickerPopupIsOpen}
                                  methods={methods}
                                  name={'desiredTimeEnd'}
                                  initial={'00:00'}
                                  init={methods.getValues('desiredTimeEnd')}
                                  rSide
                                  desired
                                  nm
                                  nanable
                                  nan={desiredTimeEndNan}
                                  setNan={setDesiredTimeEndNan}
                                />
                              </PopupYIndexFixer>
                            )}
                            <Input
                              label={genLabel({ text: strings.희망시간이전 })}
                              placeholder="00 : 00 (몇 시 까지)"
                              type="text"
                              name="desiredTimeEnd"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              readOnly={true}
                              onClick={() => {
                                setStartTimePickerPopupIsOpen(false);
                                setEndTimePickerPopupIsOpen(true);
                              }}
                              field
                              hasError={Boolean(methods.formState.errors?.desiredTimeStart)}
                            />
                          </Stack>
                        </Stack>
                        {methods.formState.errors?.desiredTimeStart && (
                          <Text styleName="caption3" color="RC04">
                            {`${methods.formState.errors?.desiredTimeStart?.message}`}
                          </Text>
                        )}
                      </Stack>

                      <Stack spacing={6}>
                        <Stack direction="row" spacing={16} align={'end'} sx={{ position: 'relative' }}>
                          <Input
                            name="serviceTime"
                            label={genLabel({ text: '예상 ' + strings._작업 + ' 소요 시간', isRequiredStart: true })}
                            placeholder="숫자만 입력"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                            onChange={(e: any) => {
                              setWorkTimePopupIsOpen(false);
                              numberPatternOnChangeHandler(e, 'serviceTime');
                            }}
                            onClick={() => {
                              setWorkTimePopupIsOpen(true);
                            }}
                            validation={{
                              required: true,
                              validate: ivnm.greater_than_0,
                            }}
                            hasError={Boolean(methods.formState.errors?.serviceTime)}
                            // errors={methods.formState.errors?.skill}
                          />
                          <Stack sx={{ position: 'relative' }}>
                            {skillPopupIsOpen && (
                              <PopupYIndexFixer>
                                <div></div>
                              </PopupYIndexFixer>
                            )}
                            <Input
                              name="serviceVehicle"
                              label={genLabel({ text: `담당 드라이버 지정` })}
                              placeholder={`담당 드라이버 지정`}
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              height={40}
                              field
                              validation={{
                                validate: (res: any) =>
                                  !Boolean(res) ||
                                  (vehicles && vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                                  '존재하지 않는 차량입니다.',
                              }}
                              onChange={(e: any) => {
                                setSkillPopupIsOpen(true);
                                setSkillSearchValue(e.target.value);
                                methods.setValue('serviceVehicle', e.target.value, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                              }}
                              onClick={() => {
                                setSkillPopupIsOpen(true);
                              }}
                              hasError={Boolean(methods.formState.errors?.serviceVehicle)}
                              // errors={methods.formState.errors?.skill}
                            />
                            {skillPopupIsOpen && (
                              <SkillPicker
                                setIsOpen={setSkillPopupIsOpen}
                                methods={methods}
                                name={'serviceVehicle'}
                                searchValue={skillSearchValue}
                              />
                            )}
                          </Stack>

                          {workTimePopupIsOpen && (
                            <WorkTimePopup
                              setIsOpen={setWorkTimePopupIsOpen}
                              setValue={methods.setValue}
                              name={'serviceTime'}
                            />
                          )}
                        </Stack>
                        {methods.formState.errors?.serviceTime && (
                          <Stack align="start">
                            <Text styleName="caption3" color="RC04">
                              {`${methods.formState.errors?.serviceTime?.message}`}
                            </Text>
                          </Stack>
                        )}
                      </Stack>
                      <Stack spacing={24} divider={<Divider color="RG06" style={{ width: 'calc(100% + 48px)' }}/>}>
                        {methods.getValues('product')?.map(
                          (
                            d: {
                              quantity: number;
                              name: string;
                              code: string;
                            },
                            i: number,
                          ) => {
                            const isSingleProduct: boolean = product.length < 2;

                            return domain === 'roouty' ? (
                              <Stack spacing={12} key={`${d.name}-${i}-product-setting`}>
                                <Input
                                  rightArea={{
                                    rightContent: (
                                      <Button
                                        variant={'ItemRemoveButton'}
                                        type={'button'}
                                        disabled={isSingleProduct}
                                        onClick={() => {
                                          removeProduct(i, methods, setProduct);
                                        }}
                                        sx={{ position: 'relative', gap: 2, padding: '0 4px' }}
                                      >
                                        <IconTrash
                                          width={13}
                                          height={13}
                                          fill={theme.colors[isSingleProduct ? 'RG05' : 'RG04']}
                                        />
                                        <Text styleName={'caption2'} color={isSingleProduct ? 'RG04' : 'RG03'}>
                                          삭제
                                        </Text>
                                      </Button>
                                    ),
                                  }}
                                  name={`product[${i}].name`}
                                  label={genLabel({ text: strings.상품명 })}
                                  placeholder={strings.상품명 + '을 입력해주세요.'}
                                  register={methods.register}
                                  watch={methods.watch}
                                  reset={methods.reset}
                                  field
                                  height={40}
                                />

                                <Stack spacing={6}>
                                  <Stack direction="row" spacing={16}>
                                    <Input
                                      name={`product[${i}].quantity`}
                                      label={genLabel({ text: '아이템 수량', isRequiredStart: true })}
                                      placeholder="1"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      type="text"
                                      height={40}
                                      onChange={(e: any) => {
                                        numberPatternOnChangeHandler(e, `product[${i}].quantity`);
                                      }}
                                      validation={{
                                        required: true,
                                        validate: ivnm.greater_than_0,
                                      }}
                                      hasError={
                                        Array.isArray(methods.formState.errors.product) &&
                                        methods.formState?.errors?.product[i]?.quantity
                                      }
                                      sx={{ flex: 2 }}
                                    />
                                    <Input
                                      name={`product[${i}].code`}
                                      label={genLabel({ text: strings.상품코드 })}
                                      placeholder="예시) RT-123"
                                      register={methods.register}
                                      watch={methods.watch}
                                      reset={methods.reset}
                                      field
                                      height={40}
                                      sx={{ flex: 7 }}
                                    />
                                  </Stack>

                                  {Array.isArray(methods.formState.errors.product) &&
                                    methods.formState.errors.product[i]?.quantity?.message && (
                                      <Stack align="start">
                                        <Text styleName="caption3" color="RC04">
                                          {methods.formState?.errors?.product?.at(i)?.quantity.message}
                                        </Text>
                                      </Stack>
                                    )}
                                </Stack>
                              </Stack>
                            ) : (
                              <Stack spacing={16} align="end">
                                {product?.map((d, i) => {
                                  return (
                                    <Fragment key={`${d.name}-${i}-product-setting`}>
                                      <Stack direction="row" spacing={16} key={`${d.name}-${i}-product-setting`}>
                                        <Input
                                          name={`product[${i}].name`}
                                          label={genLabel({ text: strings.상품명 })}
                                          placeholder={strings.상품명 + '을 입력해주세요.'}
                                          register={methods.register}
                                          watch={methods.watch}
                                          reset={methods.reset}
                                          field
                                          height={40}
                                        />
                                        <Input
                                          name={`product[${i}].code`}
                                          label={genLabel({ text: strings.상품코드 })}
                                          placeholder="예시) RT-123"
                                          register={methods.register}
                                          watch={methods.watch}
                                          reset={methods.reset}
                                          field
                                          height={40}
                                        />
                                      </Stack>
                                    </Fragment>
                                  );
                                })}
                              </Stack>
                            );
                          },
                        )}
                      </Stack>
                      <Stack align="end">
                        <Button
                          variant={'RTB'}
                          type={'button'}
                          styleName="caption2"
                          width={100}
                          height={26}
                          disabled={product.length >= 10}
                          onClick={() => setProduct(prev => [...prev].concat({ quantity: 1 }))}
                        >
                          + 아이템 추가
                        </Button>
                      </Stack>

                      {methods.formState.errors?.serviceVehicle && (
                        <Stack align="end">
                          <Text styleName="caption3" color="RC04">
                            {`${methods.formState.errors?.serviceVehicle?.message}`}
                          </Text>
                        </Stack>
                      )}
                      <Input
                        name="capacity"
                        label={genLabel({ text: '예상 용적량', isRequiredStart: true })}
                        placeholder="1"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        onChange={(e: any) => {
                          numberPatternOnChangeHandler(e, `capacity`);
                        }}
                        validation={{
                          required: { value: true, message: '해당 필드는 필수값입니다.' },
                          validate: ivnm.greater_than_0,
                        }}
                        sx={{ flex: 1 }}
                        errors={methods.formState?.errors?.capacity}
                        hasError={Boolean(methods.formState?.errors?.capacity)}
                      />
                    </DetailModiForm>
                    <Divider color="RG06"/>
                    <ModifiedMultiSkillsSection isModified setSelectedSkillIds={setSelectedSkillIds}/>
                    <Divider color="RG06"/>
                    <DetailModiForm title="고객 정보">
                      <Stack direction="row" spacing={16}>
                        <Input
                          label={genLabel({ text: strings.고객명, isRequiredStart: true })}
                          name="consigneeName"
                          placeholder="예시) 홍길동"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{ required: true }}
                          hasError={Boolean(methods.formState?.errors?.consigneeName)}
                        />
                        <Input
                          name="consigneePhone"
                          label={genLabel({ text: '고객 연락처' })}
                          placeholder="000 - 0000 - 0000"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{
                            pattern: {
                              value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                            maxLength: {
                              value: 13,
                              message: '휴대폰 형식에 맞춰주세요.',
                            },
                          }}
                          hasError={Boolean(methods.formState?.errors?.consigneePhone)}
                          errors={methods.formState?.errors?.consigneePhone}
                        />
                      </Stack>
                      <Input
                        name="address"
                        label={genLabel({ text: '주소', isRequiredStart: true })}
                        placeholder="받는 분 주소를 입력하세요"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        validation={{ required: true }}
                        readOnly={true}
                        isInject={((methods.getValues('address') as string)?.length ?? 0) > 0}
                        removeBtn={(e: MouseEvent) => {
                          e.preventDefault();
                          methods.setValue('address', '');
                        }}
                        onFocus={() => {
                          setAddressPopupIsOpen(true);
                        }}
                        onClick={() => {
                          setAddressPopupIsOpen(true);
                        }}
                        hasError={Boolean(methods.formState?.errors?.address)}
                      />
                      <Input
                        name="detailAddress"
                        label={genLabel({ text: '상세주소' })}
                        placeholder="예시) 000동 000호"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                      <Input
                        name="consigneeNote"
                        label={genLabel({ text: strings.고객전달사항 })}
                        placeholder="예시) 문 앞에 전달"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                      />
                    </DetailModiForm>
                    <Divider color="RG06"/>
                    <DetailModiForm title={strings.주행_조사 + ' 정보'}>
                      <Stack direction="row" spacing={16}>
                        <Input
                          name="driverName"
                          label={genLabel({ text: strings.모달_드라이버 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                        <Input
                          name="routeId"
                          label={genLabel({ text: '경로 ID' })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                      </Stack>
                      <Stack direction="row" spacing={16}>
                        <Input
                          name="performedDate"
                          label={genLabel({ text: strings.주행일자 })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                        <Input
                          name="routeStatus"
                          label={genLabel({ text: '경로 상태' })}
                          placeholder="-"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          disabled
                          height={40}
                        />
                      </Stack>

                      <Stack spacing={8} align="start">
                        <Text styleName="caption2" color="RG03">
                          {strings.주행_조사} 이름
                        </Text>
                        <span
                          style={{
                            letterSpacing: '-0.02em',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            textAlign: 'start',

                            color: theme.colors.RG05,
                            backgroundColor: theme.colors.RG07,
                            borderRadius: '8px',
                            border: `1px solid ${theme.colors.RG07}`,
                            padding: '10px',

                            width: '100%',
                            height: '40px',

                            boxSizing: 'border-box',
                          }}
                        >
                          -
                        </span>
                      </Stack>
                    </DetailModiForm>
                    <Divider color="RG06"/>
                    <DetailModiForm title="기타 정보">
                      {['', '', '', '', ''].map((d: any, index: any) => {
                        return (
                          <Input
                            key={`note${index}`}
                            name={`note[${index}]`}
                            label={genLabel({ text: `비고${index + 1}` })}
                            placeholder={'-'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                          />
                        );
                      })}
                    </DetailModiForm>
                  </Stack>
                </form>
              </Stack>
            </OrderDetailModalBody>
            <Divider color="RG06"/>

            <OrderDetailModalFooter>
              <Button
                variant="fourth"
                type="button"
                height={40}
                width={124}
                styleName="body2"
                color="RG04"
                onClick={() => {
                  setSingleOrderFormIsOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                variant="default"
                type="submit"
                height={40}
                width={222}
                styleName="body2"
                color="RG00"
                onClick={methods.handleSubmit(handleFormComplete)}
              >
                추가하기
              </Button>
            </OrderDetailModalFooter>
          </Fragment>
        ))}

      <Modal
        isModalOpen={addressPopupIsOpen}
        setIsModalOpen={setAddressPopupIsOpen}
        title={'우편번호찾기'}
        width={532}
        ms={30}
        padding={20}
      >
        <DaumPostcodeEmbed onComplete={handlePostcodeComplete} style={{ height: '500px', marginBottom: '0' }}/>
      </Modal>
    </Modal>
  );
  // useMemo(
  //   () =>
  //
  //   ,
  //   [
  //     singleOrderFormIsOpen,
  //     addressPopupIsOpen,
  //     workTimePopupIsOpen,
  //     startTimePickerPopupIsOpen,
  //     endTimePickerPopupIsOpen,
  //     desiredDatePopupIsOpen,
  //   ]
  // );
}

export default SingleOrderForm;
