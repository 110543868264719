export const MASTER_DATA_QUERIES = {
  SCOPE: [{ scope: 'MASTER_DATA' }] as const,
  GET_MASTER_ORDER_LIST: ({ searchItem, keyword }: { searchItem: string; keyword: string }) => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'masterOrder',
      entity: 'get_master_order_list',
      endPoint: `masterOrder/list?searchItem=${searchItem}&keyword=${keyword}`,
    },
  ],
  DELETE_MASTER_ORDER: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'masterOrder',
      entity: 'delete_master_order',
      endPoint: `masterOrder/delete`,
    },
  ],
  DOWNLOAD_MASTER_ORDER: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'masterOrder',
      entity: 'download_master_order',
      endPoint: `masterOrder/download`,
    },
  ],
  MODIFIER_MASTER_ORDER: (id: number) => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'masterOrder',
      entity: 'modifier_master_order',
      endPoint: `masterOrder/${id}`,
    },
  ],

  GET_MULTI_SKILL_LIST: ({ searchItem, keyword }: { searchItem: string; keyword: string }) => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'get_multi_skill_list',
      endPoint: `skill/list?searchItem=${searchItem}&keyword=${keyword}`,
    },
  ],
  CHECK_EXIST_SKILL_NAME: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'check_exist_skill_name',
      endPoint: `skill/check/name`,
    },
  ],
  ADD_SKILL: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'add_skill',
      endPoint: 'skill',
    },
  ],
  DELETE_MULTI_SKILL: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'delete_multi_skill',
      endPoint: 'skill/delete',
    },
  ],
  MODIFY_SKILL: (id?: number) => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'delete_multi_skill',
      endPoint: `skill/${id}`,
    },
  ],
  GET_TEAM_SKILL_LIST: () => [
    {
      ...MASTER_DATA_QUERIES.SCOPE[0],
      group: 'multiSkill',
      entity: 'get_team_skill_list',
      endPoint: 'skill/list/team',
    },
  ],
};
