import {
    ColumnOrderState,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import OrderDetailModal from 'components/Modal/OrderDetailModal';
import {TDragablePickerOptions} from 'components/Popup/DragablePicker';
import {Tag, TTagStatusKeys} from 'components/Tag';
import {common_table_selection, shipment_type} from 'constants/commons';

import {ReactComponent as High} from 'constants/icon/ic_priority_high.svg';
import {ReactComponent as Low} from 'constants/icon/ic_priority_low.svg';
import {ReactComponent as Medium} from 'constants/icon/ic_priority_medium.svg';
import {IOrder, TOrderPriority, TOrderStatus, TRouteStatus} from 'constants/types';
import dayjs from 'dayjs';
import {createElement, Dispatch, Fragment, ReactNode, SetStateAction, useEffect, useMemo, useState} from 'react';
import strings from 'util/Localization';
import {
    validateControlOrderStatus,
    validateOrderPriority,
    validateOrderStatus,
    validateRouteStatus,
} from 'util/validate/validateStatus';
import TableComponent from '.';
import IndeterminateCheckbox, {HeaderIndeterminateCheckbox} from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

let orderManagementTable = createColumnHelper<IOrder>();

function OrderManagementTable({
                                  data,
                                  isFetching,
                                  setTableColumnOrderControlModalIsOpen,
                                  selectedOrder,
                                  setSelectedOrder,
                                  rowSelection,
                                  setRowSelection,
                                  setDeleteOrderModalOpen,
                                  setDelTarget,
                                  tableColumnSettingOptions,

                                  scContent,

                                  pagination,
                                  setPagination,
                                  pageStatus,

                                  manual = false,
                                  autoResetSelection = true,
                                  placeholder,
                                  canDetailModalModi,
                              }: {
    data: any;
    isFetching: boolean;
    setTableColumnOrderControlModalIsOpen: Dispatch<SetStateAction<boolean>>;
    selectedOrder: Array<IOrder>;
    setSelectedOrder: Dispatch<SetStateAction<Array<IOrder>>>;
    rowSelection: {};
    setRowSelection: Dispatch<SetStateAction<{}>>;

    setDeleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
    setDelTarget: Dispatch<SetStateAction<Array<number>>>;
    tableColumnSettingOptions: TDragablePickerOptions;

    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;

    scContent?: Array<ReactNode>;

    pageStatus: string | undefined;

    manual?: boolean;
    autoResetSelection?: boolean;
    placeholder?: ReactNode;
    canDetailModalModi?: boolean;
}) {
    const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);
    const [target, setTarget] = useState<number>();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = useState({});

    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

    // const [pagination, setPagination] = useState<PaginationState>({
    //   pageIndex: 0,
    //   pageSize: 10,
    // });

    function customSetRowSelection(value: any) {
        setRowSelection(value);
        if (Object.keys(value())[0]) {
            let orders: Array<any> = [];
            Object.keys(value()).forEach(s => {
                orders = [
                    ...orders,
                    ...instance
                        .getCoreRowModel()
                        .flatRows.filter(x => x.id === s)
                        .map(x => x.original),
                ];
            });
            orders = orders.filter(y => selectedOrder.filter(z => z.orderId === y.orderId).length === 0);
            setSelectedOrder([...selectedOrder, ...orders]);
        } else {
            let orders: Array<any> = selectedOrder;
            let selectedList = Object.keys(value(rowSelection));
            let unCheckedItem = instance
                .getSelectedRowModel()
                .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
            unCheckedItem.forEach(s => {
                orders = orders.filter(x => x.orderId !== s.original?.orderId);
            });
            setSelectedOrder(orders);
        }
    }

    useEffect(() => {
        let columnVisible: { [key: string]: boolean } = {};

        tableColumnSettingOptions.forEach(d => {
            d.hidden ? (columnVisible[`${d.key}`] = false) : (columnVisible[`${d.key}`] = d.isSelected);
        });
        setColumnVisibility(columnVisible);
        setColumnOrder(['select'].concat(tableColumnSettingOptions.map(d => `${d.key}`)));
    }, [tableColumnSettingOptions]);

    const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
    const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

    const columns = useMemo(
        () => [
            orderManagementTable.display({
                id: 'select',
                header: ({table}) => (
                    <HeaderIndeterminateCheckbox
                        {...{
                            checked: table.getIsAllPageRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllPageRowsSelectedHandler(),

                            anchorIsOpen,
                            setAnchorIsOpen,
                            setAnchorPoint,
                        }}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    />
                ),
                cell: ({row}) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    />
                ),
            }),
            orderManagementTable.accessor(row => row.orderCode, {
                id: 'orderCode',
                header: strings.주문 + 'ID',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => row.priority, {
                id: 'priority',
                header: '배차 우선순위',
                cell: info => {
                    let {key, name} = validateOrderPriority(info.getValue() as TOrderPriority) ?? {
                        key: 'key',
                        name: 'name'
                    };
                    return (
                        <div
                            key={key as TTagStatusKeys}
                            style={{
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                            }}
                        >
                            {name === '높음' ? <High width={14} height={14}/> : null}
                            {name === '보통' ? <Medium width={14} height={14}/> : null}
                            {name === '낮음' ? <Low width={14} height={14}/> : null}
                            <Tag
                                active={true}
                                styleName="caption2"
                                status={'done'}
                                sx={{
                                    width: '100%',
                                    maxWidth: '34px',
                                    minWidth: '34px',
                                    color: '#25282F',
                                    background: 'white',
                                    border: '1px solid #D8DDEA',
                                    borderRadius: '6px',
                                    marginRight: '0',
                                    marginLeft: '8px',
                                }}
                            >
                                {name}
                            </Tag>
                        </div>
                    );
                },
            }),
            orderManagementTable.accessor(row => row.status, {
                id: 'status',
                header: strings.주문 + ' 상태',
                cell: info => {
                    let {key, name} = validateOrderStatus(info.getValue() as TOrderStatus) ?? {
                        key: 'key',
                        name: 'name'
                    };

                    return (
                        <>
                            <div className="hoverShow">
                                <Tag active={true} styleName="caption2" status={key as TTagStatusKeys}
                                     sx={{width: '56px'}}>
                                    {name}
                                </Tag>
                            </div>
                            <Tag active={false} styleName="caption2" status={key as TTagStatusKeys}
                                 sx={{width: '56px'}}>
                                {name}
                            </Tag>
                        </>
                    );
                },
            }),
            orderManagementTable.accessor(row => row.status ?? '-', {
                id: 'controlOrderStatus',
                header: '작업 상태',
                cell: info => {
                    let {key, name} = validateControlOrderStatus(info.getValue() as TOrderStatus) ?? {
                        key: 'key',
                        name: 'name',
                    };

                    return (
                        <>
                            <div className="hoverShow">
                                <Tag active={true} styleName="caption2" status={key as TTagStatusKeys}
                                     sx={{width: '56px'}}>
                                    {name}
                                </Tag>
                            </div>
                            <Tag active={false} styleName="caption2" status={key as TTagStatusKeys}
                                 sx={{width: '56px'}}>
                                {name}
                            </Tag>
                        </>
                    );
                },
            }),
            orderManagementTable.accessor(row => (row.receivedDate ? dayjs(row.receivedDate).format('YYYY.MM.DD') : '-'), {
                id: 'receivedDate',
                header: strings.주문접수일,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.desiredDate ? dayjs(row.desiredDate).format('YYYY.MM.DD') : '-'), {
                id: 'desiredDate',
                header: strings.작업희망일,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => row.shipmentType, {
                id: 'shipmentType',
                header: '주문 유형',
                cell: info => {
                    let rowShipmentType: string = info.getValue();

                    return (
                        <>
                            <div className="hoverShow">
                                {createElement(shipment_type.find(d => d.value === rowShipmentType)?.icon['ff'] ?? 'div', {
                                    width: 20,
                                    height: 20,
                                })}
                            </div>
                            {createElement(shipment_type.find(d => d.value === rowShipmentType)?.icon['pf'] ?? 'div', {
                                width: 20,
                                height: 20,
                            })}
                        </>
                    );
                },
                footer: info => info.column.id,
            }),

            orderManagementTable.accessor(row => (row?.serviceVehicle ? row.serviceVehicle : '-'), {
                id: 'serviceVehicle',
                header: `담당 드라이버 지정`,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(
                row => (row?.skills && row.skills.length ? row.skills.map(skill => skill.name).join(', ') : '-'),
                {
                    id: 'skill',
                    header: `특수 조건`,
                    footer: info => info.column.id,
                },
            ),
            orderManagementTable.accessor(row => (row?.desiredTimeEnd ? row.desiredTimeEnd : '-'), {
                id: 'desiredTimeEnd',
                header: '희망 시간(이전)',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row?.desiredTimeStart ? row.desiredTimeStart : '-'), {
                id: 'desiredTimeStart',
                header: '희망 시간(이후)',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(
                // row => {
                //   if (row.product) {
                //     if (row.product.length >= 2) {
                //       if (row.product.find(d => Boolean(d.name)))
                //         return `${row.product.find(d => Boolean(d.name))?.name} 외 ${row.product.length - 1}건`;
                //       else return `${strings._상품} ${row.product.length}건`;
                //     } else return `${row.product[0].name ?? '-'}`;
                //   }
                //   return '-';
                // },
                row => {
                    if (row.product) {
                        if (row.product.length >= 2)
                            return `${row.product.find(d => d.name)?.name ?? `-`} 외 ${row.product.length - 1}건`;
                        else return `${row.product[0]?.name ?? `-`}`;
                        // else return `${row.product[0].name ?? `빈 ${strings._상품}명`}`;
                    }
                    return '-';
                },
                {
                    id: 'productName',
                    header: () => strings._상품 + '명',
                    footer: info => info.column.id,
                },
            ),
            orderManagementTable.accessor(
                row => {
                    if (row.product) {
                        if (row.product.length >= 2)
                            return `${row.product.find(d => d.code)?.code ?? `-`} 외 ${row.product.length - 1}건`;
                        else return `${row.product[0].code ?? `-`}`;
                        // else return `${row.product[0].code ?? `빈 ${strings._상품} 코드`}`;
                    }
                    return '-';
                },
                {
                    id: 'productCode',
                    header: () => strings._상품 + ' 코드',
                    footer: info => info.column.id,
                },
            ),
            orderManagementTable.accessor(row => (row.product.length > 0 ? row.product.length : '-'), {
                id: 'productQuantity',
                header: () => strings._상품 + ' 수량',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => row.capacity, {
                id: 'capacity',
                header: '예상 용적량',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.orderPod ? row.orderPod.capacity : '-'), {
                id: 'podCapacity',
                header: '실제 용적량',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.orderPod ? row.orderPod.comment : '-'), {
                id: 'podComment',
                header: '용적량 차이 사유',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => Math.floor(row.serviceTime / 60) + '분', {
                id: 'serviceTime',
                header: '예상 작업 소요 시간 (분)',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(
                row => (row.actualServiceTime ? Math.floor(row.actualServiceTime / 60) + '분' : '-'),
                {
                    id: 'actualServiceTime',
                    header: '실제 작업 소요 시간 (분)',
                    footer: info => info.column.id,
                },
            ),
            orderManagementTable.accessor(row => (row.orderPod?.orderPodFiles ? row.orderPod?.orderPodFiles.length : '-'), {
                id: 'podFilesCount',
                header: 'PoD 사진 개수',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.driverName ? row.driverName : '-'), {
                id: 'driverName',
                header: strings.드라이버,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(
                row => (row.completedAt ? dayjs(row.completedAt).format('YYYY.MM.DD HH:mm') : '-'),
                {
                    id: 'completedAt',
                    header: strings.완료일,
                    footer: info => info.column.id,
                },
            ),
            orderManagementTable.accessor(row => (row.completedComment ? row.completedComment : '-'), {
                id: 'completedComment',
                header: '완료 사유',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.route ? dayjs(row.route.performedDate).format('YYYY.MM.DD') : '-'), {
                id: 'routePerformedDate',
                header: strings.주행_조사 + ' 일자',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.route ? row.route.name : '-'), {
                id: 'routeName',
                header: strings.주행_조사 + ' 이름',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.skippedComment ? row.skippedComment : '-'), {
                id: 'skippedComment',
                header: '보류 사유',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.skippedAt ? dayjs(row.skippedAt).format('YYYY-MM-DD HH:mm') : '-'), {
                id: 'skippedAt',
                header: '보류 일시',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.skippedName ? row.skippedName : '-'), {
                id: 'skippedName',
                header: `보류 ${strings.주행_조사} 이름`,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.route ? row.route.routeCode : '-'), {
                id: 'routeId',
                header: '경로ID',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row?.route?.status ? row.route.status : '-'), {
                id: 'routeStatus',
                header: '경로 상태',
                cell: info => {
                    if (info.getValue() === '-') return info.getValue();
                    else {
                        const {key, name} = validateRouteStatus(info.getValue() as TRouteStatus) as {
                            key: TTagStatusKeys;
                            name: string;
                        };
                        return (
                            <Tag status={key} sx={{width: 56}}>
                                {name}
                            </Tag>
                        );
                    }
                },
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => row.consigneeName, {
                id: 'consigneeName',
                header: strings.고객명,
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.consigneePhone ? row.consigneePhone : '-'), {
                id: 'consigneePhone',
                header: '고객 연락처',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => row.address, {
                id: 'address',
                header: '주소',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row?.detailAddress ? row.detailAddress : '-'), {
                id: 'detailAddress',
                header: '상세 주소',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row?.consigneeNote ? row.consigneeNote : '-'), {
                id: 'consigneeNote',
                header: '고객전달사항',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.note?.at(0) ? row.note.at(0) : '-'), {
                id: 'note1',
                header: '비고 1',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.note?.at(1) ? row.note.at(1) : '-'), {
                id: 'note2',
                header: '비고 2',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.note?.at(2) ? row.note.at(2) : '-'), {
                id: 'note3',
                header: '비고 3',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.note?.at(3) ? row.note.at(3) : '-'), {
                id: 'note4',
                header: '비고 4',
                footer: info => info.column.id,
            }),
            orderManagementTable.accessor(row => (row.note?.at(4) ? row.note.at(4) : '-'), {
                id: 'note5',
                header: '비고 5',
                footer: info => info.column.id,
            }),
        ],
        [anchorIsOpen],
    );

    const instance = useReactTable({
        data,
        columns,
        state: {
            columnVisibility,
            columnOrder,
            sorting,
            rowSelection,
            pagination,
        },
        onPaginationChange: setPagination,
        getPaginationRowModel: getPaginationRowModel(),

        onColumnOrderChange: setColumnOrder,

        onSortingChange: setSorting,
        onRowSelectionChange: customSetRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),

        autoResetPageIndex: false,
    });

    // RT-749
    // useEffect(() => {
    //   refetch();
    // }, [orderDetailModalOpen]);

    // for auto selection
    useEffect(() => {
        if (!autoResetSelection)
            customSetRowSelection(() => {
                let origin: { [key: string | number]: boolean } = {};
                instance
                    .getCoreRowModel()
                    .flatRows.filter(x => selectedOrder.find(v => v.orderId === x.original.orderId))
                    .forEach(order => {
                        origin[order.id] = true;
                    });

                return origin;
            });
    }, []);

    useEffect(() => {
        if (!autoResetSelection) return;
        setRowSelection([]);
        setSelectedOrder([]);
    }, [data, setRowSelection, setSelectedOrder]);

    useEffect(() => {
        !manual && instance.resetPagination();
    }, [instance, manual, pageStatus]);

    useEffect(() => {
        console.log('wtf', manual);
    }, [manual]);

    return (
        <Fragment>
            <TableComponent<IOrder>
                sc={scContent}
                tableType="order"
                table={instance}
                pagination={pagination}
                setPagination={setPagination}
                onRowClick={(e, row) => {
                    // if (e.target !== e.currentTarget) return;
                    setTarget(row.original?.orderId as number);
                    setOrderDetailModalOpen(true);
                }}
                placeholder={placeholder}
                isDataFetching={isFetching}
                clearBottom
                columnOrderButton={{display: true, setter: setTableColumnOrderControlModalIsOpen}}
            />

            {anchorIsOpen && (
                <TableSelectManager options={common_table_selection} {...{instance, anchorPoint, setAnchorIsOpen}} />
            )}

            {target && (
                <OrderDetailModal
                    {...{
                        target,
                        isOpen: orderDetailModalOpen,
                        setIsOpen: setOrderDetailModalOpen,
                        noModi: canDetailModalModi
                    }}
                />
            )}
        </Fragment>
    );
}

export default OrderManagementTable;
