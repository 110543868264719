import styled from 'styled-components';
import { useRef } from 'react';
interface IToggle {
  setChecked: Function;
  leftColor: TThemeColor;
  leftBgColor: TThemeColor;
  circleColor: TThemeColor;
  isChecked: boolean;
  clear?: boolean;
}

export const Toggle = (props: IToggle) => {
  const { isChecked, setChecked, clear } = props;
  const toggleRef = useRef<HTMLInputElement>(null);

  if (clear && toggleRef.current) {
    toggleRef.current.checked = false;
  }

  return (
    <Wrapper>
      <CheckBox
        {...props}
        ref={toggleRef}
        defaultChecked={isChecked}
        onChange={() => {
          if (toggleRef.current) {
            setChecked(toggleRef.current.checked);
          }
        }}
        type="checkbox"
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 0;
  border-radius: 12px;
  height: 24px;
  width: 48px;
  background: ${({ theme }) => theme.colors.RG06};
`;

const CheckBox = styled.input<{
  leftColor: TThemeColor;
  leftBgColor: TThemeColor;
  circleColor: TThemeColor;
}>`
  appearance: none;
  z-index: 1;

  width: 48px;
  height: 24px;
  background: ${({ theme, leftBgColor }) => theme.colors[leftBgColor]};
  border-radius: 2em;

  ::before {
    position: absolute;
    padding-left: 1em;
    width: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme, leftBgColor }) => theme.colors[leftBgColor]};
    transition: all 0.2s ease-in-out;
  }
  /* 선택X 원 */
  ::after {
    position: relative;
    content: '';
    display: block;
    top: 2.5px;
    width: 18px;
    height: 18px;
    left: calc(48px - 44px);
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.RG00};

    /* 원 이동 트랜지션 */
    transition: all 0.2s ease-in-out;
  }
  &:checked {
    background: ${({ theme }) => theme.colors.RC02};
    /* 배경색 변경 트랜지션 */
    transition: all 0.2s ease-in-out;

    /* 선택 O 원 */
    ::after {
      content: '';
      z-index: 2;
      left: calc(48px - 22px);
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.RG00};
      position: relative;
    }
  }
`;
