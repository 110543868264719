export const PAYMENT_QUERIES = {
  SCOPE: [{ scope: 'PAYMENT' }] as const,
  GET_PAYMENT_HISTORY: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      group: 'successPayment',
      entity: 'get_payment_history',
      endPoint: 'payment/history',
    },
  ],
  GET_PAYMENT_INFO: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      group: 'successPayment',
      entity: 'get_payment_manager',
      endPoint: 'payment/info',
    },
  ],
  GET_PAYMENT_METHOD: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      group: 'successPayment',
      entity: 'get_payment_method',
      endPoint: 'payment/method',
    },
  ],
  PATCH_PAYMENT_MANAGER: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      entity: 'patch_payment_manager',
      endPoint: 'payment/manager',
    },
  ],
  SET_CHANGE_PAY_METHOD: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      entity: 'set_change_pay_method',
      endPoint: 'payment/method',
    },
  ],
  GET_RECEIPT: (id: number) => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      entity: 'get_receipt',
      endPoint: `payment/history/${id}/bill`,
    },
  ],
  GET_PAYMENT_MY: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      group: 'successPayment',
      entity: 'get_payment_my',
      endPoint: 'payment/my',
    },
  ],
  PAYMENT_CONFIRM: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      entity: 'payment_confirm',
      endPoint: `payment/confirm`,
    },
  ],
  SET_PAYMENT_VERIFY_MANAGER_INFO: () => [
    {
      ...PAYMENT_QUERIES.SCOPE[0],
      entity: 'set_payment_verify_manager_info',
      endPoint: 'payment/verify-manager-info',
    },
  ],
};
