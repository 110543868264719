import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { BoxContainer } from 'components/BoxContainer';

export const SwiperContainer = styled(BoxContainer)`
  width: 100%;
  height: fit-content;
  min-height: 130px;
  padding: 16px 6px;

  border-radius: 8px;
  ${({ theme }) => ({ background: theme.colors.RG08 })}
`;

export const CustomSwiper = styled(Swiper)<{ isNavigate: boolean }>``;

export const CustomSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 136px;
`;

export const SwiperSlideImg = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

export const SwiperNavButton = styled.button`
  cursor: pointer;
  background: none;
`;
