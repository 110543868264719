import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useTracking } from '../../hooks/store/useTracking';

const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { title } = useTracking();

  useEffect(() => {
    if (process.env['REACT_APP_GTM_CODE']) {
      ReactGA.initialize(`G-${process.env['REACT_APP_GTM_CODE']}`);
      setInitialized(true);
    }

    if (process.env['REACT_APP_GOOGLE_ADS_CODE']) {
      ReactGA.initialize(`AW-${process.env['REACT_APP_GOOGLE_ADS_CODE']}`);
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized && title) {
      ReactGA.send('pageview');
      ReactGA.send({ hitType: 'pageview', page: location.pathname, title: title });
    }
  }, [initialized, location, title]);
};

export default RouteChangeTracker;
