import { useState, type Dispatch, type ReactNode, type SetStateAction, useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import useInterval from 'hooks/useInterval';

import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';

import { ReactComponent as Error } from 'constants/icon/ic_fail_circle.svg';
import { ReactComponent as Success } from 'constants/icon/ic_success_circle.svg';
import { ReactComponent as Warning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin_with_background.svg';

import { isFalsy } from 'util/isFalsy';

interface IAlertModal {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  type: TAlertModalType;
  messages?: string[] | ReactNode;
  buttonName?: string;
  buttonGroup?: ReactNode;
  lineSpacing?: number;
  callback?: Function;
  title?: string;
  autoCloseTime?: number;
}

function alertIconType(type: TAlertModalType): ReactNode {
  switch (type) {
    case 'SUCCESS':
      return <Success width={44} height={44} />;
    case 'ERROR':
      return <Error width={44} height={44} />;
    case 'DELETE':
      return <IcBin width={54} height={54} />;
    case 'NONE':
      return null;
    default:
      return <Warning width={44} height={44} />;
  }
}

export const AlertModal = (props: IAlertModal) => {
  const {
    type,
    messages = ['다시 시도해주세요.'],
    buttonName = '처음으로 가기',
    buttonGroup,
    lineSpacing = 8,
    isOpen,
    callback,
    setIsOpen,
    title,
    autoCloseTime = 0,
  } = props;
  const ModalIcon = alertIconType(type);
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null) 

  function runAutoClose() { 
    setIsOpen && setIsOpen(false);
    callback && callback();
  }
  useEffect(() => {
    return () => {
      if(timeoutRef.current) clearTimeout(timeoutRef.current)
    } 
  }, []);

  useEffect(()=> { 
    if(isOpen && autoCloseTime) {
      timeoutRef.current = setTimeout(runAutoClose, autoCloseTime);
    }
    else if(timeoutRef.current) {
      clearTimeout(timeoutRef.current) 
    } 
  }, [isOpen])

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={() => {}} width={504} height={'100%'} plain ds="strong" zIndex={100}>
      <Stack justify="center" sx={{ padding: '44px 24px 24px' }}>
        {ModalIcon}
        <Stack justify="center" sx={{ marginTop: '30px' }}>
          {title && (
            <Text styleName="headline1" color="RG02" sx={{ marginBottom: '24px' }}>
              {title}
            </Text>
          )}
          {Array.isArray(messages)
            ? messages.map((message, index: number) => {
                const lineSpace = index === 0 ? '' : `${lineSpacing}px`;
                return (
                  <Text key={`AlertMessage_${index}`} styleName="body2" color="RG02" sx={{ marginTop: lineSpace }}>
                    {message}
                  </Text>
                );
              })
            : messages}
        </Stack>
        {buttonGroup || (
          <Button
            type="button"
            height={40}
            fullWidth
            variant="seventh"
            styleName="body1"
            color="RG3"
            sx={{ marginTop: '40px' }}
            onClick={() => {
              setIsOpen && setIsOpen(false);
              callback && callback();
            }}
          >
            {buttonName}
          </Button>
        )}
      </Stack>
    </Modal>
  );
};
