import { cloneElement, CSSProperties, Fragment, ReactElement, ReactNode, useRef, useState } from 'react';
import Alt from '.';

const AltWrapper = ({
  children,
  anchorDeactive,
  altInfo,
  anchorWeight = {
    top: 0,
    left: 0,
  },
  sx,
}: {
  children: ReactNode;
  anchorDeactive?: boolean;
  altInfo: string;
  anchorWeight?: {
    top?: number;
    left?: number;
  };
  sx?: CSSProperties;
}) => {
  const altRef = useRef<any>(null);
  const parentRef = useRef<any>(null);

  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();
  const [alt, setAlt] = useState<{ visible: boolean; content: string }>({ visible: false, content: '' });

  const { top: top_weight, left: left_weight } = anchorWeight;
  return (
    <Fragment>
      {cloneElement(children as ReactElement, {
        ref: parentRef,
        onMouseOver: (e: any) => {
          setAnchorPoint(e.currentTarget.getBoundingClientRect());
          setAlt({ content: altInfo, visible: true });
        },
        onMouseOut: () => {
          setAlt({ content: '', visible: false });
        },
      })}
      {anchorDeactive && alt.visible && (
        <Alt
          ref={altRef}
          style={{
            ...sx,
            top: top_weight,
            left: left_weight,
          }}
        >
          {alt.content}
        </Alt>
      )}
      {!anchorDeactive && alt.visible && (
        <Alt
          ref={altRef}
          style={{
            ...sx,
            top: anchorPoint
              ? anchorPoint.top - (+parentRef.current?.getBoundingClientRect().height ?? 0) + (top_weight ?? 0)
              : 0,
            left: anchorPoint
              ? anchorPoint.left - ((parentRef.current?.getBoundingClientRect().width ?? 0) + (left_weight ?? 0))
              : 0,
          }}
        >
          {alt.content}
        </Alt>
      )}
    </Fragment>
  );
};

export default AltWrapper;
