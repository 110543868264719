import { Dispatch, SetStateAction, ReactNode, CSSProperties } from 'react';

import Modal from '.';
import { Stack } from 'components/Stack';

import { Button } from 'components/Button/legacy_index';

interface ShiroiModalProps {
  children: ReactNode;

  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  width?: number;
  padding?: number;

  sx?: CSSProperties;

  footer?: string | ReactNode;
}

const ShiroiModal = ({ isOpen, setIsOpen, width = 503, padding = 24, sx, children, footer }: ShiroiModalProps) => {
  const footButton = (string: string) => {
    return (
      <Button
        styleName="body2"
        color="RG03"
        variant={'seventh'}
        type={'button'}
        fullWidth
        height={40}
        onClick={() => setIsOpen(false)}
      >
        {string}
      </Button>
    );
  };

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} {...{ width, padding, sx }}>
      <Stack>
        {children}
        {footer ? (typeof footer === 'string' ? footButton(footer) : footer) : footButton('확인')}
      </Stack>
    </Modal>
  );
};

export default ShiroiModal;
