import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useQueries } from '@tanstack/react-query';

import { getPaymentBoundaries, getUsageByBoundary } from 'api';
import { IGetTeamUsageByBoundary, ISelectorRequires } from 'constants/types';

import Tag from 'components/Tag/Tag';
import { Stack } from 'components/Stack';
import Divider from 'components/Divider';
import Progress from 'components/Progress';
import Reload from 'components/Button/Reload';
import { Highlight, Text } from 'components/Typography';
import Selector from 'components/Timeline/components/Selector';
import { CalendarIconContainer, CalendarInputField } from 'pages/Report/utils/ReportFilter';

import * as S from './common';

import { LoadingModal } from 'components/Modal/LoadingModal';
import { ReactComponent as IcTeam } from 'constants/icon/icon_team.svg';
import { ReactComponent as IcUsage } from 'constants/icon/icon_usage.svg';
import { ReactComponent as IcCalendar } from 'constants/icon/ic_calendar.svg';
import { ReactComponent as IcCaret } from 'constants/icon/ic_arrow_up_line.svg';
import { PlanStatusTag } from 'components/Tag/new/PlanStatus';
import { PlanStatusUnion } from 'types/payment/union';
import { useGetPaymentMy } from '../../../hooks/query/payment/useGetPaymentMy';

export type TBoundary = { startDateOfUse: string; endDateOfUse: string };

const format = 'YYYY.MM.DD';

const boundarySetter = (boundary: TBoundary) => {
  if (!boundary) return '';

  const { startDateOfUse: start, endDateOfUse: end } = boundary;
  return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`;
};

const Usage = () => {
  // logical vars
  const [usage, setUsage] = useState<[number, number | '무제한']>([0, 0]);
  const [boundary, setBoundary] = useState<TBoundary>({
    startDateOfUse: '',
    endDateOfUse: '',
  });
  const [teams, setTeams] = useState<IGetTeamUsageByBoundary['data']>([]);

  // ui vars
  const [arccordion, setArccordion] = useState<boolean>(false);

  const { refetch, data: paymentMyData } = useGetPaymentMy({ enabled: false });

  const queries = useQueries({
    queries: [
      { queryKey: ['payment-boundaries'], queryFn: getPaymentBoundaries, staleTime: Infinity },
      {
        queryKey: [boundary, 'usage'],
        queryFn: () => getUsageByBoundary({ boundary }),
        refetchOnWindowFocus: true,
        enabled: Boolean(boundary?.startDateOfUse),
      },
      {
        queryKey: [boundary, 'usage', 'team'],
        queryFn: () => getUsageByBoundary<'team'>({ boundary }, 'team'),
        refetchOnWindowFocus: true,
        enabled: Boolean(boundary?.startDateOfUse),
      },
    ],
  });

  const [boundaries, totalUsage, teamUsage] = queries;

  useEffect(() => {
    if (boundaries.isSuccess) {
      setBoundary(boundaries.data.data[0]);
    }
  }, [boundaries.data?.data, boundaries.isSuccess]);
  useEffect(() => {
    if (totalUsage.isSuccess) {
      setUsage([totalUsage.data.currentOrderAssigned, totalUsage.data.maxOrderAssigned ?? '무제한']);
    }
  }, [totalUsage?.isSuccess, totalUsage.data?.currentOrderAssigned, totalUsage.data?.maxOrderAssigned]);
  useEffect(() => {
    if (teamUsage.isSuccess) {
      setTeams(teamUsage.data.data);
      setArccordion(!Boolean(teamUsage.data.data.length));
    }
  }, [teamUsage.isSuccess, teamUsage.data?.data]);

  const [selectorOptions, setSelectorOptions] = useState<ISelectorRequires<TBoundary>[]>();
  useEffect(() => {
    if (boundaries.data) {
      setSelectorOptions(
        boundaries.data.data.map((item, index) => {
          const startDateOfUse = dayjs(item.startDateOfUse).format('YYYY.MM.DD');
          const endDateOfUse = dayjs(item.endDateOfUse).format('YYYY.MM.DD');

          return {
            key: `${startDateOfUse} ~ ${endDateOfUse}`,
            value: {
              startDateOfUse: item.startDateOfUse,
              endDateOfUse: item.endDateOfUse,
            },
          };
        })
      );
    }
  }, [boundaries.data, boundaries.data?.data]);

  const isActivation =
    dayjs().isBetween(boundary?.startDateOfUse, boundary?.endDateOfUse) && paymentMyData?.paymentStatus === 'activated';
  const ProgressStyleMapper: [TThemeColor, TThemeColor] = isActivation ? ['RC02', 'RC03_3'] : ['RG04', 'RG06'];
  const planStatus = paymentMyData?.name || 'Free';

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Stack spacing={20} align="start">
      <header style={{ width: '100%' }}>
        <Stack justify="space-between" direction="row">
          <Stack direction="row" sx={{ gap: '10px' }}>
            <IcUsage width={28} height={28} />
            <Text styleName="title1" color="RG02">
              이용기간별 사용량
            </Text>
            <PlanStatusTag text={planStatus as PlanStatusUnion} />
          </Stack>
          <Selector<TBoundary>
            parent={
              <CalendarInputField
                isActive={false}
                hasError={false}
                style={{
                  minWidth: 232,
                  justifyContent: 'start',
                  gap: 10,
                }}
              >
                <CalendarIconContainer isActive={false} hasError={false}>
                  <IcCalendar />
                </CalendarIconContainer>
                <Text styleName="subheadline2">{`${boundarySetter(boundary)}`}</Text>
              </CalendarInputField>
            }
            displayKey={undefined}
            options={selectorOptions}
            onClick={(e, v) => {
              const { value } = v;
              setBoundary(value);
            }}
            width={232}
            scrollCriteria={194}
            gap={10}
            anchor="right"
          />
        </Stack>
      </header>

      <S.BorderBox spacing={20} padding={20}>
        <Stack spacing={10} direction="row">
          <Text styleName="subheadline2">{dayjs(totalUsage.dataUpdatedAt).format('YYYY. MM. DD  HH:mm:ss 기준')}</Text>
          <Reload
            disabled={totalUsage.isFetching || !isActivation}
            refetching={totalUsage.isRefetching}
            onClick={() => {
              totalUsage.refetch();
            }}
          />
        </Stack>
        <Stack spacing={10}>
          <Stack spacing={10} direction="row" divider={<Divider color="RG06" vertical style={{ height: '14px' }} />}>
            <Text styleName="body1">총 누적 경유지 수</Text>
            <Text styleName="body2" color={isActivation ? 'RG02' : 'RG04'}>
              <Highlight styleName="body1">{usage[0].toLocaleString('kr')}</Highlight>
              {` / ${usage[1].toLocaleString('kr')}`}
            </Text>
          </Stack>
          <Stack spacing={4}>
            <Progress
              h={10}
              color={ProgressStyleMapper}
              progress={
                usage[1] === '무제한'
                  ? usage[0] >= 20000
                    ? 99
                    : (usage[0] / 20000) * 100
                  : (usage[0] / usage[1]) * 100
              }
            />
            <Stack direction="row" justify="space-between">
              <Text styleName="caption2" color="RG04">{`0 (${dayjs(boundary?.startDateOfUse).format(
                'YYYY.MM.DD'
              )})`}</Text>
              <Text styleName="caption2" color="RG04">{`(${dayjs(boundary?.endDateOfUse).format(
                'YYYY.MM.DD'
              )}) ${usage[1].toLocaleString('kr')}`}</Text>
            </Stack>
          </Stack>
        </Stack>
      </S.BorderBox>

      <S.BorderBox>
        <Stack padding={20} bg={'RC03_1'} direction="row" justify="space-between">
          <Stack direction="row" spacing={20}>
            <Text styleName="body1">
              <IcTeam style={{ marginRight: 10 }} />
              팀별 누적 경유지 수
            </Text>
            <Stack direction="row" spacing={8} sx={{ width: 'fit-content' }}>
              <Text styleName="body2">활성화된 팀</Text>
              <Text styleName="body1" color="RC02">
                {teams.filter(team => team.status === 'activated').length} 개
              </Text>
            </Stack>
          </Stack>
          <IcCaret
            width={12}
            height={12}
            style={{ padding: 4, cursor: 'pointer', rotate: arccordion ? '180deg' : '0deg' }}
            onClick={() => setArccordion(o => !o)}
          />
        </Stack>
        <Stack
          divider={<Divider color="RG06" />}
          sx={{
            height: arccordion ? 0 : teams.length * 64 + (teams.length - 1) + 'px',
            transition: 'all 0.3s',
            overflow: 'hidden',
          }}
        >
          {teams.map((team, index) => {
            const { name, status, count } = team;

            const active = status === 'activated';
            const tc = active ? 'RG02' : 'RG04';
            return (
              <Stack padding={20} bg="RC03_4" direction="row" key={`${name}${index}-${status}`}>
                <Text styleName="body2" color={tc} sx={{ flex: 3 }}>
                  {name}
                </Text>
                <Text styleName="body1" color={tc} sx={{ flex: 5 }}>
                  {count.toLocaleString('kr')}
                </Text>
                <Stack align="end" sx={{ flex: 1 }}>
                  {!active ? (
                    <Tag color={tc} h={24}>
                      삭제된 팀
                    </Tag>
                  ) : null}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </S.BorderBox>

      {queries.some(query => query.isFetching) && <LoadingModal isLoading />}
    </Stack>
  );
};

export default Usage;
