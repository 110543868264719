import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Stack } from 'components/Stack';
import { Tag, TTagStatusKeys } from 'components/Tag';
import { Text } from 'components/Typography';

import { shipment_type } from 'constants/commons';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import { ReactComponent as High } from 'constants/icon/ic_priority_high.svg';
import { ReactComponent as Low } from 'constants/icon/ic_priority_low.svg';
import { ReactComponent as Medium } from 'constants/icon/ic_priority_medium.svg';
import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';

import { ReactComponent as IcHub } from 'constants/icon/icon_hub.svg';
import { IEqualOptimizeResponsesDriversOrderList, TOrderPriority } from 'constants/types';
import dayjs from 'dayjs';
import { createElement, Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { validateOrderPriority } from 'util/validate/validateStatus';

import TableComponent from '.';
import IconPalette, { IconMarker } from './util/IconPalette';
import TableSelectManager from './util/TableSelectManager';

let routePlanConfirmTable = createColumnHelper<IEqualOptimizeResponsesDriversOrderList>();

function RoutePlanConfirmTable({
  data,
  disableOptions = {
    totalCount: true,
    selectCount: true,
    pagnation: true,
    pageSizeSelector: true,
  },

  iconGuide,
  modifying,
}: {
  data: Array<IEqualOptimizeResponsesDriversOrderList> | undefined;
  disableOptions?: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };

  iconGuide?: boolean;
  modifying?: boolean;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: disableOptions.pagnation ? 100000 : 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    orderId: false,
    rooutyEngineETA: JSON.parse(process.env.REACT_APP_TB_ONLY as string),
  });

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  const columns = useMemo(
    () => [
      routePlanConfirmTable.accessor(
        row => (row?.route?.internal?.routeIndex ? row.route?.internal?.routeIndex : null),
        {
          id: 'routeIndex',
          header: () => '순서',
          cell: info => {
            if (modifying) {
              return (
                <Text align="start" styleName={'caption1'} color={'RC02'}>
                  신규(N)
                </Text>
              );
            }
            return info.getValue() ?? '-';
          },
        }
      ),

      routePlanConfirmTable.accessor(row => row?.priority, {
        id: 'priority',
        header: '배차 우선순위',
        cell: info => {
          let { key, name } = validateOrderPriority(info.getValue() as TOrderPriority) ?? { key: 'key', name: 'name' };
          return info.row.original.type !== 'order' ? null : (
            <div
              key={key as TTagStatusKeys}
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              {name === '높음' ? <High width={14} height={14} /> : null}
              {name === '보통' ? <Medium width={14} height={14} /> : null}
              {name === '낮음' ? <Low width={14} height={14} /> : null}
              <Tag
                active={true}
                styleName="caption2"
                status={'done'}
                sx={{
                  width: '100%',
                  maxWidth: '34px',
                  minWidth: '34px',
                  color: '#25282F',
                  background: 'white',
                  border: '1px solid #D8DDEA',
                  borderRadius: '6px',
                  marginRight: '0',
                  marginLeft: '8px',
                }}
              >
                {name}
              </Tag>
            </div>
          );
        },
      }),

      routePlanConfirmTable.accessor(row => {
        return row?.serviceVehicle ?? null;
      }, {
        id: 'skill',
        header: () => '담당 드라이버 지정',
        cell: info => {
          return info.getValue() ? <IconMarker size={[20, 12.5]} icon={<IconTruck />} /> : null;
        },
        enableSorting: false,
      }),

      routePlanConfirmTable.accessor(
        row =>
          row?.route?.internal?.eta
            ? dayjs(row.route.internal?.eta).subtract(row.route.internal.requiredTime, 's').format('HH:mm')
            : null,
        {
          id: 'etd',
          header: () => '예상 출발 시간',
          cell: info => {
            if (modifying || info.row.original.type === 'break') {
              return '-';
            }
            return info.getValue() ?? '-';
          },
        }
      ),

      routePlanConfirmTable.accessor(row => row?.route?.internal?.requiredTime, {
        id: 'requiredTime',
        header: () => '예상 이동 시간',
        cell: info => {
          let requiredTime = info.getValue();
          let min = Math.floor((requiredTime as number) / 60) || 0;

          return info.row.original.type === 'break' ? '-' : `${min} 분`;
        },
      }),

      routePlanConfirmTable.accessor(
        row => (row?.route?.internal?.eta ? dayjs(row.route.internal?.eta).format('HH:mm') : null),
        {
          id: 'eta',
          header: '예상 도착 시간',
          cell: info => {
            if (modifying) {
              return '-';
            }
            return (
              <Stack direction="row" spacing={10}>
                {info.getValue() ?? '-'}
                {(info.row.original?.desiredTimeStart || info.row.original?.desiredTimeEnd) && (
                  <IconMarker size={[20, 12.5]} icon={<IconDesiredTime />} />
                )}
              </Stack>
            );
          },
        }
      ),

      routePlanConfirmTable.accessor(row => row?.route?.internal?.waitingTime, {
        id: 'waitingTime',
        header: () => '예상 유휴 시간',
        cell: info => {
          const serviceTime = info.getValue();
          if (!serviceTime) {
            return '-';
          }

          const min = Math.floor(serviceTime / 60);

          return info.row.original.type === 'end' ? '-' : `${min} 분`;
        },
      }),

      routePlanConfirmTable.accessor(row => (row?.serviceTime ? row.serviceTime : null), {
        id: 'serviceTime',
        header: () => '예상 작업 소요 시간',
        cell: info => {
          let serviceTime =
            info.row.original?.type === 'break' ? info.row.original.route?.internal?.requiredTime : info.getValue();
          let min = Math.floor((serviceTime as number) / 60);

          return info.row.original.type === 'end' ? '-' : `${min} 분`;
        },
      }),

      routePlanConfirmTable.accessor(
        row =>
          row?.route?.internal?.eta
            ? dayjs(row.route.internal?.eta)
                .add((row.type === 'break' ? row.route.internal.requiredTime : row?.serviceTime) ?? 0, 's')
                .add(row.route.internal.waitingTime ?? 0, 's')
                .format('HH:mm')
            : null,
        {
          id: 'ect',
          header: () => '예상 완료 시간',
          cell: info => {
            if (modifying) {
              return '-';
            }
            return info.getValue() ?? '-';
          },
        }
      ),

      routePlanConfirmTable.accessor(row => row?.shipmentType, {
        id: 'shipmentType',
        header: '주문 유형 ',
        cell: info => {
          let rowShipmentType = info.getValue();

          const mapper: { [key: string]: ReactElement } = {
            end: <IcHub />,
            break: <IcHasLunch />,
          };

          return (
            <IconMarker
              size={[20, 12.5]}
              icon={
                info.row.original.type !== 'order'
                  ? mapper[info.row.original.type]
                  : createElement(shipment_type.find(d => d.value === rowShipmentType)?.icon['ff'] ?? 'div', {})
              }
            />
          );
        },
        footer: info => info.column.id,
      }),

      routePlanConfirmTable.accessor(row => row?.consigneeName ?? null, {
        id: 'consigneeName',
        header: '고객명',
        cell: info => {
          const mapper: { [key: string]: string } = {
            end: '도착지',
            break: '휴게시간',
          };

          return info.row.original.type !== 'order' ? mapper[info.row.original.type] : info.getValue();
        },
      }),

      routePlanConfirmTable.accessor(row => (row.type === 'break' ? '휴게시간' : row?.address ?? '-'), {
        id: 'address',
        header: () => '주소',
      }),

      routePlanConfirmTable.accessor(row => row?.detailAddress ?? '-', {
        id: 'detailAddress',
        header: '상세주소',
      }),
    ],
    []
  );

  const instance = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    console.table(data);

    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [data]);

  const paletteContent: Array<{ icon: ReactElement; label: string }> = [
    {
      icon: <IconDesiredTime />,
      label: '작업 희망 시간',
    },
    {
      icon: <IconTruck />,
      label: '담당 드라이버 지정',
    },
    {
      icon: createElement(shipment_type[0].icon.ff, {}),
      label: '배달',
    },
    {
      icon: createElement(shipment_type[1].icon.ff, {}),
      label: '수거',
    },
    {
      icon: <IcHasLunch />,
      label: '휴게시간',
    },
    {
      icon: <IcHub />,
      label: '도착지',
    },
  ];

  return (
    <Fragment>
      <TableComponent
        table={instance}
        isDataFetching={false}
        clearBottom
        sc={
          iconGuide
            ? [
                <IconPalette
                  spacing={10}
                  size={[20, 12.5]}
                  direction="row"
                  icon={paletteContent.map(d => d.icon)}
                  label={paletteContent.map(d => d.label)}
                />,
              ]
            : undefined
        }
        {...{ pagination, setPagination, disableOptions }}
      />

      {anchorIsOpen && (
        <TableSelectManager
          options={[
            { key: 'toggleAllPageRowsSelected', value: '현재 페이지만 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
            { key: 'toggleAllRowsSelected', value: '선택 해제', optionValue: false },
          ]}
          {...{ instance, anchorPoint, setAnchorIsOpen }}
        />
      )}
    </Fragment>
  );
}

export default RoutePlanConfirmTable;
