import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

interface genLabelProps {
  text: string;
  isRequired?: boolean;
  isRequiredStart?: boolean;
  isOption?: boolean;
  subText?: string;
}

export default function genLabel(props: genLabelProps) {
  return (
    <Stack spacing={4} align={'start'} direction={'row'}>
      <Text styleName={'subheadline2'} color={'RG03'}>
        {props.text}
      </Text>
      {props.isRequired && (
        <Text styleName={'caption1'} color={'RC04'}>
          필수
        </Text>
      )}
      {props.isRequiredStart && (
        <Text styleName={'caption1'} color={'RC04'}>
          *
        </Text>
      )}
      {props.isOption && (
        <Text styleName={'caption1'} color={'RG05'}>
          옵션
        </Text>
      )}
      {props.subText && (
        <Text styleName={'caption1'} color={'RG04'}>
          {props.subText}
        </Text>
      )}
    </Stack>
  );
}
