import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FetchPaymentInfoData } from '../../../hooks/query/payment/useGetPaymentInfo';
import { ManagerInfoInput } from '../../Input/ManagerInfoInput';
import { validateLoginAccount } from '../../../util/validate/validateLoginAccount';
import { useCheckExistsManagerInfo } from '../../../hooks/query/exists/useCheckExistsManagerInfo';

export interface ChangeManagerNameProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setPaymentInfoData: Dispatch<SetStateAction<Partial<FetchPaymentInfoData>>>;
  setIsDisabledSaveButton: Dispatch<SetStateAction<boolean>>;
}

export const ChangeManagerEmail = ({
  email,
  setEmail,
  setPaymentInfoData,
  setIsDisabledSaveButton,
}: ChangeManagerNameProps) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    mutate: mutateCheckExistsManagerInfo,
    error: checkExistsManagerInfoError,
    isSuccess: isSuccessExistsManagerInfo,
  } = useCheckExistsManagerInfo();

  useEffect(() => {
    setPaymentInfoData(prev => ({
      ...prev,
      email: hasError ? '' : email,
    }));
  }, [hasError, email, setPaymentInfoData]);

  useEffect(() => {
    if (checkExistsManagerInfoError) {
      (async () => {
        const data = await checkExistsManagerInfoError.response.clone().json();

        setHasError(true);
        setErrorMessage(data.error.message);
      })();
    }
  }, [checkExistsManagerInfoError]);

  useEffect(() => {
    if (isSuccessExistsManagerInfo) {
      setHasError(false);
      setErrorMessage('');
    }
  }, [isSuccessExistsManagerInfo]);

  return (
    <ManagerInfoInput
      text={email}
      setText={setEmail}
      placeholder="결제 정보를 받을 이메일 주소를 입력해 주세요"
      isCheckedValidateAction={validateLoginAccount}
      hasError={hasError}
      setHasError={setHasError}
      errorMessage={errorMessage}
      onValidation={(validate, value) => {
        setHasError(!(value.length && validate));
        setIsDisabledSaveButton(!(value.length && validate));

        if (!value.length) {
          setErrorMessage('이메일은 필수입력 사항입니다.');
        } else if (!validate) {
          setErrorMessage('이메일 형식에 맞지 않습니다. 다시 입력해 주세요.');
        } else {
          setErrorMessage('');
          mutateCheckExistsManagerInfo({ email: value });
        }
      }}
    />
  );
};
