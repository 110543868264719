import { PAYMENT_QUERIES } from './payment.queries';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../../api/ky';

type FetchPaymentHistoryData = {
  paymentHistoryId: number;
  payMethod: string;
  startDateOfUse: string;
  endDateOfUse: string;
  billCode: string;
  actualAmount: number;
  paidDate: string;
  pricing: {
    name: string;
    interval: string;
  };
};

type FetchGetPaymentHistoryResponse = {
  data: FetchPaymentHistoryData[];
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetPaymentHistory>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof PAYMENT_QUERIES.GET_PAYMENT_HISTORY>;
};

const fetchGetPaymentHistory = async ({
  queryKey: [{ endPoint }],
}: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  (await API.get(endPoint).json<FetchGetPaymentHistoryResponse>()).data;

const useGetPaymentHistory = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    PAYMENT_QUERIES.GET_PAYMENT_HISTORY(),
    fetchGetPaymentHistory,
    options
  );

export { fetchGetPaymentHistory, useGetPaymentHistory };
export type { FetchPaymentHistoryData, FetchGetPaymentHistoryResponse };
