import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Text } from '../Typography';

import { Stack } from '../Stack';
import { UseFormSetValue } from 'react-hook-form';
import PopupYIndexFixer from './PopupYIndexFixer';
import { SelectItem } from 'components/Input';

interface WorkTimeSelectPopupProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setValue: UseFormSetValue<any>;
  name: string;
}

function WorkTimeSelectPopup({ setIsOpen, setValue, name }: WorkTimeSelectPopupProps) {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <PopupYIndexFixer>
      <Container ref={targetRef}>
        <Stack align="start">
          {[10, 20, 30, 60, 80, 100, 120, 150].map((x, i) => (
            <SelectItem
              key={`${i}-${name}-${x}`}
              onClick={() => {
                setValue(name, `${x}분`, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                setIsOpen(false);
              }}
            >
              <Text styleName="caption3" color="RG03">
                {x}분
              </Text>
            </SelectItem>
          ))}
        </Stack>
      </Container>
    </PopupYIndexFixer>
  );
}

export default WorkTimeSelectPopup;

const Container = styled.div`
  display: flex;
  padding: 17px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: 80px;
  z-index: 3;
  left: 0;
  max-height: 161px;
  ${({ theme }) => theme.scrollStyle.vertical};
`;
