import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import * as S from './style';

import { ReactComponent as ArrowLeft } from '../../constants/icon/ic_arrowleft20.svg';
import { ReactComponent as ArrowRight } from '../../constants/icon/ic_arrowright20.svg';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { getRouteListBoundary } from 'api';
import { IGetRouteListBoundaryResponse } from 'constants/types';
import theme from 'constants/theme';

export interface DateSelectorProps extends Partial<CalendarProps> {
  date: Date | null | undefined | [Date | null, Date | null];
  setDate: Dispatch<SetStateAction<any>>;
  customWidth?: string;

  exclude?: {
    future?: boolean;
    past?: boolean;
  };

  allowPastDate?: boolean;
  allowUncheck?: boolean;

  limite?: number;
  dayCheck?: boolean;
}

const currentDate = dayjs();
const leftPad = (value: number) => (value < 10 ? `0${value}` : value.toString());

export function DateSelector({
  exclude = { future: false },
  limite = 90,
  customWidth,
  ...props
}: DateSelectorProps): ReactElement {
  const [dayCheckRange, setDayCheckRange] = useState<string>(
    props.date
      ? typeof props.date === 'object'
        ? `${dayjs((props.date as Array<Date>)[0] ?? dayjs())
            .startOf('month')
            .format('YYYYMMDD')}-${dayjs((props.date as Array<Date>)[1] ?? dayjs())
            .endOf('month')
            .format('YYYYMMDD')}`
        : `${dayjs(props.date).startOf('month').format('YYYYMMDD')}-${dayjs(props.date)
            .endOf('month')
            .format('YYYYMMDD')}`
      : `${dayjs().format('YYYYMMDD')}-${dayjs().format('YYYYMMDD')}`
  );

  const DateSelectorConfiguration: CalendarProps = {
    ...props,
    locale: 'ko-KR',
    next2Label: null,
    nextLabel: <ArrowRight />,
    prev2Label: null,
    prevLabel: <ArrowLeft />,
    formatDay: (_locale, date) => date.getDate().toString(),
    formatMonthYear: (_locale, date) => `${date.getFullYear()}.${leftPad(date.getMonth() + 1)}`,
    minDetail: 'month',
    calendarType: 'US',
    // showNeighboringMonth: false,

    onActiveStartDateChange: ({ action, activeStartDate, value, view }) => {
      setDayCheckRange(
        `${dayjs(activeStartDate).startOf('month').format('YYYYMMDD')}-${dayjs(activeStartDate)
          .add(1, 'month')
          .endOf('month')
          .format('YYYYMMDD')}`
      );
    },
    onChange: (v: any, e: any) => {
      const setter = () => {
        if (props.selectRange) {
          // console.log(props.date, ' setter');
        }
        if (dayjs(v).isSame(dayjs(props.date?.toString()), 'day') && props.date) {
          if (props.allowUncheck) props.setDate(null);
        } else props.setDate(v);
      };
      // RT-816
      if (limite === -1) return setter();

      let message = `${limite}일 이내로 검색해주세요.`;
      if (dayjs(v).isAfter(dayjs().add(limite, 'day')) || dayjs(v).isBefore(dayjs().subtract(limite, 'day'))) {
        return alert(message);
      } else setter();
    },
  };

  // 요청일 구간(YYYYMMDD-YYYYMMDD)
  const { data: route_list_boundarys } = useQuery<IGetRouteListBoundaryResponse>(
    ['getRouteListBoundary', dayCheckRange],
    () => getRouteListBoundary({ performedDate: dayCheckRange }),
    {
      enabled: props.dayCheck === true && dayCheckRange !== null,

      onSuccess(res) {},
    }
  );

  return (
    <S.DateSelectorStyle
      customWidth={customWidth}
      dayCheck={props.dayCheck && route_list_boundarys && route_list_boundarys?.routeList.length > 0}
    >
      <Calendar
        {...DateSelectorConfiguration}
        value={props.date}
        maxDate={exclude.future ? currentDate.toDate() : undefined}
        minDate={props.allowPastDate ? undefined : currentDate.toDate()}
        tileContent={({ activeStartDate, date, view }) => {
          // console.log(dayjs(date).format('YYYY-MM-DD'), activeStartDate, view);

          const day_routes = route_list_boundarys?.routeList.find(
            d => d.performedDate === dayjs(date).format('YYYY-MM-DD')
          );
          const has_active_day_routes: boolean =
            (day_routes?.routeList.filter(d => d.status === 'activated' || d.status === 'processing') ?? []).length > 0;

          const rangeClassNameGenerator = () => {
            return (date.getDay() === 0 || date.getDay() === 6) &&
              (dayjs(date).startOf('month').format('YYYYMMDD') === dayjs(date).format('YYYYMMDD') ||
                dayjs(date).endOf('month').format('YYYYMMDD') === dayjs(date).format('YYYYMMDD'))
              ? ' range-man-both'
              : date.getDay() === 0 ||
                dayjs(date).startOf('month').format('YYYYMMDD') === dayjs(date).format('YYYYMMDD')
              ? ' range-man-start'
              : date.getDay() === 6 || dayjs(date).endOf('month').format('YYYYMMDD') === dayjs(date).format('YYYYMMDD')
              ? ' range-man-end'
              : '';
          };

          return (
            <>
              {props.dayCheck && day_routes && (
                <div
                  className="dot"
                  style={{
                    backgroundColor: theme.colors[has_active_day_routes ? 'RC03' : 'RG05'],
                  }}
                />
              )}
              <div className="background-man" />
              <div className={'range-man' + `${rangeClassNameGenerator()}`} />
              {!props.allowPastDate && dayjs().isAfter(dayjs(date).add(1, 'day')) ? (
                <div
                  className="react-calendar__month-view__days__day__disabled__past"
                  style={{ position: 'absolute', width: '100%', height: '20px', top: 0, left: 0 }}
                />
              ) : null}
            </>
          );
        }}
        // tileClassName={({ activeStartDate, date, view }) =>
        //   !props.allowPastDate && date ? 'react-calendar__month-view__days__day__disabled__past' : '...ast'
        // }
      />
      <S.DateSelectorTooltip
        anchorSelect=".react-calendar__month-view__days__day__disabled__past"
        place="right"
        offset={0}
        noArrow
        style={{}}
      >
        과거일은 선택이 불가합니다.
      </S.DateSelectorTooltip>
    </S.DateSelectorStyle>
  );
}
