import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface ITag {
  text: string;
  variant?: TTagVariant;
  borderColor?: TThemeColor;
  color?: TThemeColor;
  backgroundColor?: TThemeColor;
  styleName?: TForntStyle;
}

const StatusTag = (props: ComponentPropsWithoutRef<'button'> & ITag) => {
  const {
    text,
    variant = 'medium',
    color = 'RC02',
    borderColor = 'RC02',
    backgroundColor = 'RG08',
    styleName = 'tooltip3',

    ...rest
  } = props;
  return (
    <OwnerTag
      styleName={styleName}
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      tagType={variant}
      {...rest}
    >
      {text}
    </OwnerTag>
  );
};

export default StatusTag;

const tagStyle = {
  small: { height: '16px', padding: '0 4px', borderRadius: '2px' },
  medium: { height: '24px', padding: '2px 6px', borderRadius: '4px' },
  large: { height: '16px', padding: '4px 10px', borderRadius: '6px' },
};

const OwnerTag = styled.button<{
  tagType: 'small' | 'medium' | 'large';
  color: TThemeColor;
  borderColor: TThemeColor;
  backgroundColor: TThemeColor;
  styleName: TForntStyle;
}>`
  display: flex;
  align-items: center;
  height: ${({ tagType }) => tagStyle[tagType].height};

  padding: ${({ tagType }) => tagStyle[tagType].padding};
  border-radius: ${({ tagType }) => tagStyle[tagType].borderRadius};
  ${({ theme, color, borderColor, backgroundColor }) => ({
    color: theme.colors[color],
    backgroundColor: theme.colors[backgroundColor],
    border: `1px solid ${theme.colors[borderColor]}`,
  })}
  ${({ theme, styleName }) => theme.fontStyle[styleName]}

  white-space: nowrap;
`;
