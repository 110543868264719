import { InitialInfoFormProps } from './modifiableInfoForm.interface';
import { Text } from '../Typography';
import { Stack } from '../Stack';
import theme from '../../constants/theme';
import { useMemo } from 'react';

export const InitialForm = ({ data, justify }: InitialInfoFormProps) => (
  <>
    {useMemo(
      () =>
        data?.map((item, index) => (
          <Stack
            key={item.title}
            direction="row"
            sx={{
              gap: '20px',
              padding: '20px',
              borderBottom: data.length - 1 === index ? '' : `1px solid ${theme.colors.RG06}`,
              justifyContent: justify ?? 'start',
              ...item.sx,
            }}
          >
            <Text styleName={item?.styleName ?? 'body2'} color="RG02" sx={{ minWidth: '176px' }}>
              {item.title}
            </Text>
            <Text styleName={item?.styleName ?? 'body1'} color={item.contentColor ?? 'RG02'}>
              {item.content}
            </Text>
          </Stack>
        )),
      [data, justify]
    )}
  </>
);
