// declare DB name, table name, key name in here

export const IDB_Names = {
  user: {
    DB_Name: 'userDB2',
    version: 13,
    stores: [
      {
        name: 'users',
        id: { keyPath: 'IDB_id', autoIncrement: true },
        indices: [
          { name: 'userId', keyPath: 'userId', options: { unique: true } },
          { name: 'roleId', keyPath: 'roleId' },
          { name: 'name', keyPath: 'name' },
          { name: 'phone', keyPath: 'phone' },
          { name: 'email', keyPath: 'email' },

          // only driver
          { name: 'driverId', keyPath: 'driverId' },
          { name: 'driverInfo', keyPath: 'driverInfo' },
          // table column setting
          { name: 'orderManageTableColumnsSetting', keyPath: 'orderManageTableColumnsSetting' },
          // settings table column setting
          { name: 'InvolvedDriverTableColumnSetting', keyPath: 'InvolvedDriverTableColumnSetting' },
        ],
      },
    ],
  },
};
