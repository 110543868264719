import styled from 'styled-components';

import { Stack } from 'components/Stack';

const BorderBox = styled(Stack)`
  align-items: start;

  border-radius: 8px;
  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;

export { BorderBox };
