import {
  cloneElement,
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { Stack } from 'components/Stack';

import { ReactComponent as TriangleArrowDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ISelectorRequires } from 'constants/types';

interface ISelectorStyleProps {
  width: number;
  scrollCriteria?: number;

  gap?: number;
  extension?: number;

  fill?: boolean;
  outline?: boolean;
}

interface ISelectorProps<K> extends ISelectorStyleProps {
  displayKey: ReactNode;
  options?: Array<ISelectorRequires<K>>;
  onClick: (e: MouseEvent<HTMLElement>, v: ISelectorRequires<K>) => void;

  parent?: ReactElement;
  placeholder?: ReactNode;

  anchor?: 'left' | 'right';
  // ...rest
}

export const Selector = <V,>({
  parent,
  options,
  onClick,
  displayKey,

  placeholder,
  width,
  gap = 0,
  extension = 0,
  anchor = 'left',
  fill = true,
  outline = true,
  scrollCriteria,

  ...rest
}: ISelectorProps<
  V extends 'string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined'
    ? V
    : any
>) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const [pos, setPos] = useState<DOMRect | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closer = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current?.contains(e.target) && !parentRef.current?.contains(e.target)) closer();
  };

  const handleWindowResized = () => {
    if (!parentRef.current) return;
    setPos(parentRef.current.getBoundingClientRect());
  };

  useLayoutEffect(() => {
    document.getElementById('orderManagePage-scroll-container')?.addEventListener('scroll', closer);
    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleWindowResized);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleWindowResized);
    };
  }, []);
  useEffect(() => handleWindowResized(), [isOpen]);

  return (
    <Fragment>
      {parent ? (
        cloneElement(parent, {
          ref: parentRef,
          className: 'pointer drag-ban',
          onClick: (e: any) => setIsOpen(prev => !prev),
          ...{ isActive: isOpen, width, fill, outline },
        })
      ) : (
        <SelectorContainer
          className="drag-ban"
          direction="row"
          spacing={6}
          justify="space-between"
          ref={parentRef}
          onClick={e => setIsOpen(prev => !prev)}
          {...{ isActive: isOpen, width, fill, outline }}
          children={
            <Fragment>
              {options?.find(d => d.value === displayKey)?.key ?? placeholder ?? null}
              <TriangleArrowDown style={{ transform: isOpen ? 'rotate(0.5turn)' : 'rotate(1turn)' }} />
            </Fragment>
          }
        />
      )}

      {isOpen && (
        <SelectorOptionsList
          className="drag-ban"
          ref={targetRef}
          pos={{
            top: pos?.top ?? parentRef.current?.getBoundingClientRect().top ?? 0,
            left: pos?.left ?? parentRef.current?.getBoundingClientRect().left ?? 0,

            parent: {
              width: pos?.width ?? parentRef.current?.getBoundingClientRect().width ?? 0,
              height: pos?.height ?? parentRef.current?.getBoundingClientRect().height ?? 0,
            },
          }}
          {...{ width, gap, extension, anchor, scrollCriteria }}
        >
          {options?.map((d, i) => (
            <SelectorOptions
              isActive={d.value === displayKey}
              key={`selector-option-${i}-${d.value}`}
              onClick={e => {
                onClick(e, d);
                closer();
              }}
            >
              {d.key}
            </SelectorOptions>
          ))}
        </SelectorOptionsList>
      )}
    </Fragment>
  );
};

export default Selector;

const SelectorContainer = styled(Stack)<
  Pick<ISelectorStyleProps, 'width' | 'fill' | 'outline'> & { isActive?: boolean }
>`
  flex-shrink: 0;

  padding: 2px 10px;
  border-radius: 4px;

  ${({ theme, isActive, width, fill, outline }) => {
    const baseStyle = {
      minWidth: `${width}px`,
      maxWidth: 'max-content',

      color: theme.colors.RG03,

      backgroundColor: theme.colors.RG00,
      border: `1px solid ${outline ? theme.colors.RG06 : 'transparent'}`,

      '&>svg>path': {
        fill: theme.colors.RG04,
      },
    };
    const activeStyles = {
      ...baseStyle,

      color: theme.colors.RG02,

      backgroundColor: theme.colors[fill ? 'RC03_1' : 'RG00'],
      border: `1px solid ${outline ? theme.colors.RC03 : 'transparent'}`,
    };

    const styles = isActive ? { ...activeStyles } : { ...baseStyle };
    return {
      ...styles,
      ':hover': { ...activeStyles },
    };
  }};

  ${({ theme }) => theme.fontStyle.caption1}
`;

const SelectorOptionsList = styled(Stack)<
  { anchor: 'left' | 'right' } & Required<Pick<ISelectorStyleProps, 'width' | 'gap' | 'extension'>> &
    Pick<ISelectorStyleProps, 'scrollCriteria'> & {
      pos: { top: number; left: number; parent: { [key in 'width' | 'height']: number } };
    }
>`
  position: fixed;

  padding: 16px 0;
  border-radius: 6px;

  z-index: 5;
  ${({ width, pos, gap, extension, scrollCriteria, anchor }) => ({
    width: `${width + extension}px`,
    maxHeight: scrollCriteria ? `${scrollCriteria}px` : 'max-content',
    // + parent-height + gap
    top: pos.top + pos.parent.height + gap,
    left: pos.left - (anchor === 'right' ? width + extension - pos.parent.width : 0),
  })}

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,

    boxShadow: theme.shadows.normal,
  })}
  ${({ theme }) => theme.scrollStyle.vertical};
`;

const SelectorOptions = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;
  min-height: 32px;
  padding: 6px 20px;

  cursor: pointer;

  ${({ theme }) => ({})}

  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, isActive }) => (isActive ? theme.colors.RC03_1 : theme.colors.RG00)};
  ${({ theme }) => theme.fontStyle.caption2};

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};

    ${({ theme }) => theme.fontStyle.caption1};
  }
`;
