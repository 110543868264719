import { HTTPError } from 'ky';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { EXISTS_QUERIES } from './exists.queries';

type FetchCheckExistsManagerInfoResponse = {
  message: string;
};

type FetchCheckExistsManagerInfoError = IErrorResponse;

type Variables = {
  phone?: string;
  email?: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchCheckExistsManagerInfo>>;
  TError: HTTPError;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof EXISTS_QUERIES.CHECK_EXISTS_MANAGER_INFO>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchCheckExistsManagerInfo = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchCheckExistsManagerInfoResponse | FetchCheckExistsManagerInfoError>();

const useCheckExistsManagerInfo = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    EXISTS_QUERIES.CHECK_EXISTS_MANAGER_INFO(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchCheckExistsManagerInfo({
        variables: variables,
        ...EXISTS_QUERIES.CHECK_EXISTS_MANAGER_INFO()[0],
      }),
    options
  );

export { fetchCheckExistsManagerInfo, useCheckExistsManagerInfo };
export type { FetchCheckExistsManagerInfoResponse, FetchCheckExistsManagerInfoError };
