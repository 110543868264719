import styled from 'styled-components';

export const Auth = styled.div`
  width: auto;
  height: 100%;
  display: block;
  overflow: auto;
`;
export const Body = styled.div`
  display: flex;
  height: calc(100% + 72px);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.RC03_1};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 60px 20px;

  @media (max-width: 700px) {
    padding: 60px 20px 120px 20px;
  }
`;

export const FooterContents = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;

  gap: 16px;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1280px;

  @media (max-width: 720px) {
    gap: 100px;
    flex-direction: column-reverse;
  }
`;

export const Header = styled.div<{ bgColor: string; blur?: boolean; isMobile?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;

  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'start' : 'center')};

  width: 100%;
  height: 72px;
  width: auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor] ?? bgColor};

  ${({ blur }) =>
    blur
      ? {
          mixBlendMode: 'normal',
          opacity: '0.95',
          backdropFilter: 'blur(16px)',
        }
      : {}}
`;

export const HeaderItems = styled.div`
  display: flex;
  align-items: center;

  gap: 20px;
  margin: 35px;
  width: 100%;
  max-width: 1280px;

  @media screen and (max-width: 700px) {
    width: auto;
    gap: 150px;
    margin: 10px;
  }
`;

export const HeaderIcon = styled.div`
  width: 217px;
  padding-left: 20px;

  @media screen and (max-width: 700px) {
    width: auto;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: end;

  /* FIXME : &r230519 요금제 관련 섹션 요금제 기능 출시까지 미노출 
  justify-content: space-between; */

  width: 100%;
  padding-right: 26px;

  /*@media screen and (max-width: 1100px) {
    min-width: 200px;
    justify-content: space-between;
    display: flex; 
  }*/

  @media screen and (max-width: 700px) {
    display: none;
  }
`;
