import { ModifiedInfoFormProps } from './modifiableInfoForm.interface';
import { Text } from '../Typography';
import { Stack } from '../Stack';
import { useMemo } from 'react';

export const ModifiedForm = ({ data }: ModifiedInfoFormProps) => (
  <>
    {useMemo(
      () =>
        data?.map((item, index) => (
          <Stack
            key={item.title}
            direction="row"
            sx={{
              gap: '20px',
              padding: '20px',
              alignItems: 'baseline',
            }}
          >
            <Text styleName="body2" color="RG02" sx={{ minWidth: '176px' }}>
              {item.title}
            </Text>
            {item.modifiedComponent}
          </Stack>
        )),
      [data]
    )}
  </>
);
