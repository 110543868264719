import { ReactNode, CSSProperties, useEffect, useRef, useState, memo } from 'react';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { SelectorContainer, SelectorItem, SelectorDisplayBox } from './style';
import { isFalsy } from 'util/isFalsy';
interface SelectorProps {
  defaultValue?: any;
  placeholder?: string;
  icon?: ReactNode;
  disabledHover?: boolean;
  anchor?: 'top' | 'bottom';
  options: Array<{ key: string; value: any }>;
  onOptionsClick: (item: { key: string; value: any }) => void;
  fullWidth?: boolean;
  sx?: CSSProperties;
}

const Selector = ({
  anchor = 'bottom',
  defaultValue,
  options,
  onOptionsClick,
  icon,
  sx,
  fullWidth,
  placeholder,
  disabledHover = false,
}: SelectorProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [displayContent, setDisplayContent] = useState<string>('');

  useEffect(() => {
    if (isFalsy(defaultValue) && placeholder) {
      return setDisplayContent(placeholder);
    }

    setDisplayContent(defaultValue ? `${options.find(d => d.value === defaultValue)?.key}` : 'placeholder');
  }, [defaultValue, options]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);

  return (
    <Stack sx={{ flexBasis: 'content', position: 'relative' }}>
      <SelectorDisplayBox
        disabledHover={disabledHover}
        onClick={() => {
          setIsOpen(prev => !prev);
        }}
        ref={containerRef}
        style={sx}
      >
        <Text styleName="caption2" color="RG03">
          {displayContent}
        </Text>
        {icon && icon}
      </SelectorDisplayBox>
      {isOpen && (
        <SelectorContainer width={fullWidth === true ? '' : 216} spacing={4} ref={selectorRef} anchor={anchor}>
          <Stack align="start">
            {options.map((d, index) => {
              return (
                <SelectorItem
                  activated={displayContent === d.key}
                  key={`${d.key}-selector-${index}`}
                  onClick={() => {
                    onOptionsClick(d);
                    setDisplayContent(d.key);
                    setIsOpen(false);
                  }}
                >
                  {d.key}
                </SelectorItem>
              );
            })}
          </Stack>
        </SelectorContainer>
      )}
    </Stack>
  );
};

export default memo(Selector);
