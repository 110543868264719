import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as IcReload } from 'constants/icon/ic_reload.svg';

interface ReloadProps extends ComponentPropsWithoutRef<'button'> {
  refetching?: boolean;
}

const Reload = ({ onClick, refetching, ...props }: ReloadProps) => {
  return (
    <ReloadButton onClick={!refetching ? onClick : undefined} refetching={refetching} {...props}>
      <IcReload width={20} height={20} className={refetching ? 'infinite_rotating' : undefined} />
    </ReloadButton>
  );
};

export default Reload;

const ReloadButton = styled.button<ReloadProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 4px;

  ${({ theme, refetching }) => ({
    backgroundColor: theme.colors[refetching ? 'RG07' : 'RG00'],
    border: `1px solid ${theme.colors.RG06}`,
    cursor: refetching ? 'not-allowed' : 'pointer',

    ':hover': {
      backgroundColor: theme.colors.RG07,
    },

    ':disabled': {
      cursor: 'not-allowed',
      backgroundColor: theme.colors.RG07,
    },

    '& > svg > path': {
      stroke: theme.colors[refetching ? 'RC03' : 'RG04'],
    },
  })}
`;
