import { commonUris } from 'constants/uris';

export const FOOTER_PATH = {
  links: [
    { name: '회사 소개', path: commonUris.roouty },
    { name: '루티 이용약관', path: commonUris.tos },
    { name: '위치기반서비스 이용약관', path: commonUris.location },
    { name: '개인정보처리방침', path: commonUris.policy_privacy },
  ],
  question: { name: '문의 하기', path: commonUris.contact },
};
