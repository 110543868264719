import Modal from 'components/Modal';
import { Dispatch, SetStateAction } from 'react';
import { Text } from 'components/Typography';
import { Button } from 'components/Button/legacy_index';
import { Stack } from 'components/Stack';
import theme from 'constants/theme';

import * as S from 'auth/signUp/style';
interface IBusinessNumberModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<TBusinessNumberStateType>>;
  businessNumberList: TBusinessNumberList[];
}
export const BusinessNumberModal = (props: IBusinessNumberModal) => {
  const { isOpen, setIsOpen, businessNumberList } = props;

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={() => {}} height={'522'} plain ds="strong" sx={{ minWidth: '504px' }}>
      <S.BusinessNumberModalLayout>
        <Stack align="start">
          <Text styleName="body1" color="RC02">
            이미 가입된 사업자 등록 번호 입니다.
          </Text>
          <Text styleName="body2" color="RG02" sx={{ marginTop: '8px' }}>
            추가로 새로운 계정 생성을 계속 하시겠습니까?
          </Text>
        </Stack>
        <div style={{ display: 'flex', maxHeight: '228px' }}>
          <S.BusinessNumberModalTable>
            <table style={{ width: '100%', position: 'relative' }}>
              <thead
                style={{ position: 'sticky', top: 0, background: theme.colors.RG08, textAlign: 'left', height: '100%' }}
              >
                <tr>
                  <S.TableCell>
                    <Text styleName="subheadline1" color="RG02" sx={{ paddingLeft: '10px' }}>
                      계정 정보
                    </Text>
                  </S.TableCell>
                  <th>
                    <Text styleName="subheadline1" color="RG02" sx={{ paddingLeft: '10px' }}>
                      회사 이름
                    </Text>
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  textAlign: 'left',
                  maxHeight: '54px',
                }}
              >
                {businessNumberList.map(account => {
                  return (
                    <tr
                      key={account.name}
                      style={{
                        border: `1px solid ${theme.colors.RG06}`,
                        borderBottomStyle: 'solid',
                        borderLeftStyle: 'none',
                        borderRightStyle: 'none',
                        borderTopStyle: 'solid',
                      }}
                    >
                      <S.TableRow>
                        <Text styleName="caption2" sx={{ paddingLeft: '10px' }}>
                          {account.loginAccount}
                        </Text>
                      </S.TableRow>
                      <td>
                        <Text styleName="caption2" sx={{ paddingLeft: '10px' }}>
                          {account.name}
                        </Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </S.BusinessNumberModalTable>
        </div>
        <S.BusinessNumberModalButton style={{ gap: '10px' }}>
          <Button type="button" variant="seventh" fullWidth height={40} onClick={() => setIsOpen('reset')}>
            <Text styleName="subheadline2">닫기</Text>
          </Button>
          <Button type="button" variant="MRTB" fullWidth height={40} onClick={() => setIsOpen('success')}>
            확인
          </Button>
        </S.BusinessNumberModalButton>
      </S.BusinessNumberModalLayout>
    </Modal>
  );
};
