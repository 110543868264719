import React from 'react';

import { Stack } from 'components/Stack';
import Plans from './Plans';
import { Text } from 'components/Typography';

import { ReactComponent as IcPlans } from 'constants/icon/icon_plans.svg';

export const Plan = () => {
  return (
    <Stack sx={{ position: 'relative' }}>
      <Text styleName="title1" color="RG02" sx={{ position: 'absolute', top: 0, left: 0 }}>
        <IcPlans width={28} height={28} style={{ marginRight: 10 }} />
        요금제 관리
      </Text>

      <Plans cta />
      {/* <Plans wp={[Stack, { direction: 'row', spacing: 20, align:"start", justify:"space-between"  }]} /> */}
    </Stack>
  );
};
