import setupIndexedDB, { useIndexedDBStore } from 'hooks/useIndexedDB';

import STRINGS from 'util/Localization';
import { useQuery } from '@tanstack/react-query';
import { getAuthority, getMyInfo, shouldShowTutorial } from 'api';

import { useGNBStore, useSettingStore, useStore, useTutorialStore } from 'store';

import type { NavigateFunction } from 'react-router-dom';
import { IDB_Names } from 'constants/IDB';
import { IAuthority } from 'constants/types';
import { useGetPaymentMy } from '../../hooks/query/payment/useGetPaymentMy';
import { useEffect } from 'react';

interface IGetMyInfo {
  isSuccess: boolean;
  navigate: NavigateFunction;
  isStoredId?: boolean;
  useNav?: boolean;
  firstLanding?: boolean;
}

const UseQueryMyInfo = (props: IGetMyInfo) => {
  const { setUserGrade } = useStore();
  const { SETSETTINGOPEN, setShowFaqTooltip, setShowTutorialTooltip } = useGNBStore();
  const { SETCURRENT } = useSettingStore();
  const { add } = useIndexedDBStore(IDB_Names.user.stores[0].name);
  const { SETTUTORIALISOPEN } = useTutorialStore();

  const { isSuccess, navigate, isStoredId, useNav = true, firstLanding } = props;
  const { data: authorityInfo, status: getAuthorityStatus } = useQuery(['authorityInfo'], getAuthority, {
    refetchOnWindowFocus: false,
    enabled: isSuccess,
    onSuccess: res => storeAuthorityInfo(res),
  });
  const {
    data: paymentMyData,
    refetch: fetchGetPaymentMy,
    isSuccess: isSuccessGetPaymentMy,
  } = useGetPaymentMy({ enabled: false });

  const {
    data: shouldShowTutorialData,
    refetch: fetchShouldShowTutorial,
    isSuccess: isSuccessShouldShowTutorial,
  } = useQuery(['shouldShowTutorial'], shouldShowTutorial, {
    enabled: false,
    retry: 0,
  });

  const { refetch } = useQuery(['getMyInfo'], getMyInfo, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: async res => {
      add({ ...res });
      for await (const [key, value] of Object.entries(res)) {
        if(key === 'team') {
          localStorage.setItem(`${key}`, JSON.stringify(value));
        } else {
          localStorage.setItem(`${key}`, value as string);
        }
      }

      if (res.email) localStorage.setItem('currentEmail', res.email);
      if (isStoredId && res.email) {
        localStorage.setItem('loginAccount', res.email);
      }
      if (isStoredId === false) localStorage.removeItem('loginAccount');
      setUserGrade(res.roleId);

      if (paymentMyData?.paymentStatus === 'paused') {
        navigate('/mypage', { state: { head: 'payment' } });
      } else {
        if (firstLanding || !shouldShowTutorialData?.tutorialChecked) {
          SETCURRENT('driver');
          SETSETTINGOPEN(true);
          // setShowFaqTooltip(true);
          // setShowTutorialTooltip(true);

          setTimeout(() => {
            SETTUTORIALISOPEN(true);
          }, 500);
        }

        useNav && navigate(`/manage/order${authorityInfo?.pricing.pricingId === 2 ? 'manual' : 'auto'}`);
      }
    },
  });

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      fetchGetPaymentMy();
      fetchShouldShowTutorial();
    }
  }, [fetchGetPaymentMy, localStorage.getItem('accessToken')]);

  useEffect(() => {
    if (
      isSuccessShouldShowTutorial &&
      isSuccessGetPaymentMy &&
      paymentMyData?.paymentStatus &&
      (getAuthorityStatus === 'error' || getAuthorityStatus === 'success') &&
      isSuccess
    ) {
      refetch();
    }
  }, [
    getAuthorityStatus,
    isSuccess,
    isSuccessGetPaymentMy,
    isSuccessShouldShowTutorial,
    paymentMyData?.paymentStatus,
    refetch,
  ]);
};

interface IGetAuthority {
  isSuccess: boolean;
}

const UseQueryAuthority = (props: IGetAuthority) => {
  const { isSuccess } = props;
  useQuery(['authorityInfo'], getAuthority, {
    refetchOnWindowFocus: false,
    enabled: isSuccess,
    onSuccess: res => storeAuthorityInfo(res),
  });
};

export const storeToken = (response: TSignUpResponse) => {
  const { accessToken, refreshToken, accessTokenExpireAt, refreshTokenExpireAt } = response;
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);

  localStorage.setItem('ATE', accessTokenExpireAt);
  localStorage.setItem('RTE', refreshTokenExpireAt);

  setupIndexedDB({
    databaseName: 'geojsonDB_213',
    version: 2,
    stores: [
      {
        name: '행정구역',
        id: { keyPath: 'id', autoIncrement: true },
        indices: [
          { name: 'sgg', keyPath: 'sgg' },
          { name: 'dem', keyPath: 'dem' },
          { name: 'sido', keyPath: 'sido' },
        ],
      },
    ],
  }).then(() => console.log('init idb'));

  setupIndexedDB({
    databaseName: IDB_Names.user.DB_Name,
    version: IDB_Names.user.version,
    stores: IDB_Names.user.stores,
  })
    .then(() => {
      console.log('successfully set');
    })
    .catch(e => console.error('error / unsupported', e));
};

export const storeAuthorityInfo = (response: IAuthority) => {
  const { type, pricing } = response;
  localStorage.setItem(`serviceDomain`, type);
  localStorage.setItem(`pricing`, JSON.stringify({ id: pricing.pricingId, status: pricing.paymentStatus }));
  STRINGS.setLanguage(type);
};

export { UseQueryMyInfo, UseQueryAuthority };
