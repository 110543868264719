import { PAYMENT_QUERIES } from './payment.queries';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../../api/ky';

type FetchPaymentMethodResponse = {
  payType: string;
  payMethod: string;
  paidDate?: string;
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetPaymentMethod>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof PAYMENT_QUERIES.GET_PAYMENT_METHOD>;
};

const fetchGetPaymentMethod = async ({
  queryKey: [{ endPoint }],
}: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) => await API.get(endPoint).json<FetchPaymentMethodResponse>();

const useGetPaymentMethod = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    PAYMENT_QUERIES.GET_PAYMENT_METHOD(),
    fetchGetPaymentMethod,
    options
  );

export { fetchGetPaymentMethod, useGetPaymentMethod };
export type { FetchPaymentMethodResponse };
