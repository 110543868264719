import styled from 'styled-components';
import theme from '../../../../constants/theme';

export const EmptyPaymentMethod = styled.article`
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.RC03_4};
  cursor: pointer;
`;

export const AddPaymentMethod = styled.div`
  //position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
`;
