import { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AlertModal } from '../Modal/AlertModal';
import { useNotice } from '../../hooks/store/useNotice';
import { Notice, NoticeKeyUnionType } from '../../constants/notice';
import dayjs from 'dayjs';

interface NoticeProviderProps {
  children: ReactNode;
}

const NoticeProvider = ({ children }: NoticeProviderProps) => {
  const { noticeId } = useNotice();
  const [cookies, setCookie] = useCookies([noticeId]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [noticeContent, setNoticeContent] = useState<Array<string[] | ReactNode>>([]);
  const [noticeCount, setNoticeCount] = useState<number>(0);

  useEffect(() => {
    if (!noticeId) return;

    if (!cookies.hasOwnProperty(noticeId)) {
      const expireDate = dayjs().add(1, 'day');

      setCookie(noticeId, `${noticeId}_${expireDate.toString()}`, {
        expires: expireDate.toDate(),
      });

      setNoticeCount(prev => prev + 1);
      setNoticeContent(prev => [...prev, Notice[noticeId as NoticeKeyUnionType].content]);
    }

    if (!cookies.hasOwnProperty(noticeId)) {
      let lastNoticeKey = '';

      Object.keys(cookies).forEach(key => {
        if (Notice.hasOwnProperty(key)) {
          if (!lastNoticeKey) {
            lastNoticeKey = key;
          } else {
            if (dayjs(cookies[key.split('_')[1]]).isBefore(dayjs(cookies[lastNoticeKey.split('_')[1]]))) {
              lastNoticeKey = key;
            }
          }
        }
      });

      if (lastNoticeKey) {
        setNoticeCount(prev => prev + 1);
        setNoticeContent(prev => [...prev, Notice[lastNoticeKey as NoticeKeyUnionType].content]);
      }
    }
  }, [noticeId, cookies, setCookie]);

  useEffect(() => {
    if (noticeCount > 0) {
      setIsOpen(true);
    }
  }, [noticeCount]);

  return (
    <>
      {children}
      {isOpen &&
        Array(noticeCount)
          .fill(0)
          .map((_, index) => (
            <AlertModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              type="NONE"
              messages={noticeContent[index]}
              buttonName="확인"
            />
          ))}
    </>
  );
};

export { NoticeProvider };
