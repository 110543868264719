import { ReactNode, CSSProperties, useState, Fragment } from 'react';
import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import theme from 'constants/theme';
import * as S from 'pages/Setting/organization/Manager/inviteManagerModal/style';
import { ReactComponent as CloseIcon } from 'constants/icon/ic_close_16.svg';
import { EditableElement } from 'pages/Setting/organization/Manager/inviteManagerModal';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';

interface IChip {
  useCloseBtn?: boolean;
  borderColor?: TThemeColor;
  backgroundColor?: TThemeColor;
  sx?: CSSProperties;
  height?: string;
  TForntStyle?: TForntStyle;
  children: ReactNode;
  onClick?: () => void;
  cancelEvent?: () => void;
  onKeyUp?: (props: TInviteOnKeyUp) => void;
  status?: TChipStatus;
  id?: string;
  alertMessage?: string;
}

export const Chip = (props: IChip) => {
  const {
    children,
    useCloseBtn,
    borderColor,
    backgroundColor,
    sx,
    height,
    TForntStyle,
    onClick,
    cancelEvent,
    onKeyUp,
    alertMessage,
    status = 'SUCCESS',
    id,
  } = props;

  const cssSetting = { useCloseBtn, borderColor, backgroundColor, sx, height, TForntStyle };

  const statusCss = {
    fontColor: status === 'ERROR' ? ('RC04' as TThemeColor) : undefined,
    backgroundColor: status === 'ERROR' ? ('RC04_1' as TThemeColor) : undefined,
  };

  const [isOver, setIsOver] = useState<boolean>(false);

  return (
    <Stack align="start" sx={{ width: 'fit-content', position: 'relative', ...sx }}>
      <EditableElement onKeyUp={onKeyUp} id={id}>
        <S.ChipComponentCSS
          onMouseOver={() => alertMessage && setIsOver(true)}
          onMouseOut={() => alertMessage && setIsOver(false)}
          {...cssSetting}
          onClick={onClick}
          backgroundColor={statusCss.backgroundColor}
        >
          {status === 'ERROR' && <ErrorMarking style={{ marginRight: '4px' }} />}
          <Text styleName="subheadline2" id={`${id}_content`} color={statusCss.fontColor}>
            {children}
          </Text>
          <CloseIcon style={{ marginLeft: '6px', cursor: 'pointer' }} onClick={cancelEvent} />
        </S.ChipComponentCSS>
      </EditableElement>
      {alertMessage && (
        <Stack
          sx={{
            border: `1px solid ${theme.colors.RG06}`,
            width: 'fit-content',
            background: 'white',
            zIndex: 1,
            display: isOver ? 'block' : 'none',
            padding: '4px 6px',
            position: 'absolute',
            marginTop: '27px',
            minWidth: '150px',
            borderRadius: '2px',
          }}
        >
          <Text styleName="caption2" color="RC02">
            {alertMessage}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
