import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { ORDER_QUERIES } from 'hooks/query/order/order.data.queries';

type Variables = DefaultDownloadVariables<'orderIds'>;

interface UseMutationGeneric {
  TData: Awaited<ReturnType<typeof fetchDownloadOrder>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof ORDER_QUERIES.DOWNLOAD_ORDER>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
}

const fetchDownloadOrder = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).then(async response => ({
    data: await response.arrayBuffer(),
    title: decodeURIComponent(response.headers.get('Content-Disposition')?.split('filename=')[1] ?? ''),
  }));

const useDownloadOrder = <TContext = UseMutationGeneric['TData']> (
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >,
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    ORDER_QUERIES.DOWNLOAD_ORDER(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchDownloadOrder({
        variables: variables,
        ...ORDER_QUERIES.DOWNLOAD_ORDER()[0],
      }),
    options,
  );

export { fetchDownloadOrder, useDownloadOrder };
export type { Variables as DownloadOrderVariables };
