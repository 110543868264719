import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';

import { DateSelector } from '../DateSelector';
import dayjs from 'dayjs';
import PopupYIndexFixer from './PopupYIndexFixer';
import { UseFormSetValue } from 'react-hook-form';

interface DatePickerPopupProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  // setValue: (name: string, value: unknown, config?: Object) => void;
  setValue: UseFormSetValue<any>;
  initDate: string;
  name: string;
  format?: string;
  banPast?: boolean;
  top?: string;

  fullWidth?: boolean;

  allowUncheck?: boolean;

  exclude?: {
    future?: boolean;
    past?: boolean;
  };

  dayCheck?: boolean;
  rside?: boolean;
}

function DatePickerPopup({
  setIsOpen,
  setValue,
  name,
  initDate,
  format,
  banPast,
  top,
  fullWidth,
  allowUncheck = true,

  dayCheck,
  exclude,
  rside,
}: DatePickerPopupProps) {
  const currentDate = dayjs();

  // RT-1049 버그에 따른 date convert 부분 추가됨
  const parsedInitDate =
    initDate && initDate !== ''
      ? initDate.includes('GMT')
        ? dayjs(initDate).toDate()
        : dayjs(initDate, 'YYYY-MM-DD').toDate()
      : currentDate.add(1, 'day').toDate();
  const [date, setDate] = useState<Date | null>(parsedInitDate);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <PopupYIndexFixer>
      <DatePickerContainer top={top} ref={targetRef} fullWidth={fullWidth} rside={rside}>
        <Stack spacing={10}>
          <DateSelector {...{ date, setDate, allowUncheck, allowPastDate: !banPast, exclude, dayCheck }} />

          <Stack spacing={12} direction={'row'}>
            <Button
              type="button"
              variant="tertiary"
              fw
              h={24}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              닫기
            </Button>
            <Button
              type="button"
              variant="primary"
              fw
              h={24}
              onClick={() => {
                console.log('here', date, name, format);
                if (date === null)
                  setValue(name, '', {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                else
                  setValue(
                    name,
                    `${dayjs(date)
                      .locale('ko')
                      .format(format || 'YYYY.MM.DD')}`,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    }
                  );
                setIsOpen(false);
              }}
            >
              적용하기
            </Button>
          </Stack>
        </Stack>
      </DatePickerContainer>
    </PopupYIndexFixer>
  );
}

export default DatePickerPopup;

const DatePickerContainer = styled.div<{ top?: string; fullWidth?: boolean; rside?: boolean }>`
  display: flex;
  padding: 20px;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '250px')};

  ${({ rside }) => (rside ? { right: 0 } : { left: 0 })}

  position: absolute;
  top: ${({ top }) => (top ? top : '40px')};
  z-index: 3;
`;
