import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { PlainTag } from 'pages/Setting/RouteArea';
import React from 'react';
import { TArea } from 'types/table/cell/areas';

interface AreasCellProps {
  areas: TArea[];
}

const AreasCell = ({ areas }: AreasCellProps) => {
  if (areas) {
    const areaNames = areas.map(d => d.name);
    const tooLong = areaNames.find(x => x?.length && x.length > 15);

    return (
      <Stack spacing={6} direction="row" sx={{ width: 'max-content' }}>
        {tooLong ? (
          <>
            <PlainTag> {tooLong}</PlainTag>
            {areaNames.length - 1 >= 1 && (
              <Text styleName="caption2" color="RG02">
                외&nbsp;<Highlight color="RC02">{areaNames.length - 1}</Highlight>&nbsp;권역
              </Text>
            )}
          </>
        ) : (
          areaNames.map((x, i) => {
            console.log(x, i);
            return i < 2 ? (
              <PlainTag>{x}</PlainTag>
            ) : (
              areaNames.length - 1 === i && (
                <Text styleName="caption2" color="RG02">
                  외&nbsp;<Highlight color="RC02">{i - 1}</Highlight>&nbsp;권역
                </Text>
              )
            );
          })
        )}
      </Stack>
    );
  }

  return <>-</>;
};

export { AreasCell };
export type { AreasCellProps };
