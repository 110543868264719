export const xlsxKeyConverter = (rowKey: string, scope?: ScopeType) => {
  const mappedKeys: { [key: string]: string } = {
    client_order_key: 'clientKey',
    received_date: 'receivedDate',
    name: scope === 'roouty_driver' ? 'name' : 'consigneeName',
    contact: 'consigneePhone',
    address: 'address',
    detail_address: 'detailAddress',
    note: scope === 'roouty_driver' ? 'note' : 'consigneeNote',
    capacity: 'capacity',

    product_name: 'product_name',
    product_code: 'product_code',
    product_quantity: 'product_quantity',

    desiredTimeStart: 'desiredStartTime',
    desiredTimeEnd: 'desiredEndTime',
    desired_after_time: 'desiredStartTime',
    desired_before_time: 'desiredEndTime',

    service_type: 'shipmentType',
    rotation: 'rotation',
    service_duration: 'serviceTime',
    service_vehicle: 'serviceVehicle',
    desired_date: 'desiredDate',

    priority: 'priority',

    customerField1: 'customer_field_1',
    customerField2: 'customer_field_2',
    customerField3: 'customer_field_3',
    customerField4: 'customer_field_4',
    customerField5: 'customer_field_5',

    team_name: 'teamName',
    phone_number: 'phoneNumber',
    operation_type: 'operationType',
    model_name: 'modelName',
    max_capacity: 'maxCapacity',
    work_start_time: 'workStartTime',
    work_end_time: 'workEndTime',
    break_duration: 'breakDuration',
    break_start: 'breakStart',
    break_end: 'breakEnd',
    start_address: 'startAddress',
    start_detail_address: 'startDetailAddress',
    end_address: 'endAddress',
    end_detail_address: 'endDetailAddress',
  };

  return mappedKeys[rowKey] ?? rowKey;
};

export const xlsxKeyConverter_K = (rowKey: string) => {
  const mappedKeys: { [key: string]: string } = {
    clientKey: '업체 주문 번호',
    receivedDate: '주문 접수일',
    consigneeName: '고객명',
    consigneePhone: '고객 연락처',
    address: '주소',
    detailAddress: '상세주소',
    consigneeNote: '고객전달사항',
    capacity: '예상 용적량',

    product_name: '아이템명',
    product_code: '아이템 코드',
    product_quantity: '아이템 수량',

    desiredStartTime: '희망 시간(이후)',
    desiredEndTime: '희망 시간(이전)',

    shipmentType: '주문 유형',
    rotation: '회차 여부',
    serviceTime: '예상 작업 소요 시간 (분)',
    serviceVehicle: '담당 드라이버 지정',
    skill: '특수 조건',
    requirement: '특수 조건',
    desiredDate: '작업 희망일',

    priority: '배차 우선순위',

    customer_field_1: '비고1',
    customer_field_2: '비고2',
    customer_field_3: '비고3',
    customer_field_4: '비고4',
    customer_field_5: '비고5',

    longitude: '경도',
    latitude: '위도',

    teamName: '소속 팀',
    name: '이름',
    phoneNumber: '아이디(휴대폰 번호)',
    operationType: '운영 유형',
    modelName: '차종',
    maxCapacity: '최대 용적량',
    workStartTime: '주행 시작 시간',
    workEndTime: '주행 종료 시간',
    breakDuration: '휴게시간 (분)',
    breakStart: '휴게시간 범위 시작시간',
    breakEnd: '휴게시간 범위 종료시간',
    startAddress: '출발지 주소',
    startDetailAddress: '출발지 상세주소',
    endAddress: '도착지 주소',
    endDetailAddress: '도착지 상세주소',
    note: '메모',
  };

  return mappedKeys[rowKey];
};
