import { useState } from 'react';
import Modal from 'components/Modal';
import { useStore } from 'store';

import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';

import { PrivateVerificationCodeInput, PrivateFormLeftTime } from 'auth/signUp/privateTypeForm/elements';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { AlertModal } from 'components/Modal/AlertModal';
import { __rerequestVerifyCode } from 'auth/signUp/privateTypeForm/container';

import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';

import * as S from 'auth/signUp/style';

interface IPrivateEmailValidationModal {
  successCallback: Function;
  closeCallback: Function;
  overCountWarningCallback: Function;
  exceedCallback: Function;
  isOpen: boolean;
  loginAccount: string;
}

export const PrivateEmailValidationModal = (props: IPrivateEmailValidationModal) => {
  const { isOpen, loginAccount, exceedCallback, overCountWarningCallback, closeCallback, successCallback } = props;

  const { isLoading, setIsLoading } = useStore();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isOverCount, setIsOverCount] = useState<boolean>(false);

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={() => {}} width={504} height={'522'} plain ds="strong">
      <LoadingModal
        isLoading={isLoading}
        text={[`${loginAccount} 으로`, '인증 메일을 전송 중입니다. 잠시만 기다려 주세요.']}
      />
      <AlertModal
        isOpen={isSuccessModalOpen}
        type="SUCCESS"
        setIsOpen={setIsSuccessModalOpen}
        autoCloseTime={3000}
        messages={[`${loginAccount} 으로`, '보낸 인증 메일을 확인해주세요.']}
        buttonName="확인"
      />

      <S.PrivateEmailValidationModalCloseBtn>
        <IconClose style={{ cursor: 'pointer' }} onClick={() => closeCallback()} />
      </S.PrivateEmailValidationModalCloseBtn>
      <Stack align="start" sx={{ padding: '0px 24px 0px 24px' }}>
        <Text styleName="title2" color="RG02">
          이메일 인증 코드 입력
        </Text>
        <Text styleName="body1" color="RC02" sx={{ marginTop: '10px' }}>
          {loginAccount}
        </Text>
        <Text styleName="body2" color="RG02" sx={{ marginTop: '8px' }}>
          이메일로 전송된 6자리 인증 코드를 입력해주세요.
        </Text>
        <PrivateFormLeftTime resetTimer={isSuccessModalOpen} />
        <PrivateVerificationCodeInput
          reset={isSuccessModalOpen}
          successCallback={successCallback}
          loginAccount={loginAccount}
        />
        <S.PrivateEmailValidationModalReissueCode>
          <Text styleName="subheadline2" color="RG02">
            코드를 받지 못하셨나요?
          </Text>
          <Button
            type="button"
            variant="fifth"
            sx={{ marginLeft: '11px', border: 'none' }}
            onClick={() => {
              if (isOverCount) {
                return overCountWarningCallback();
              }
              setIsLoading(true);
              __rerequestVerifyCode(loginAccount, setIsSuccessModalOpen, exceedCallback, setIsOverCount, setIsLoading);
            }}
          >
            <Text styleName="subheadline1" color="RC02" sx={{ padding: '6px 6px' }}>
              인증 코드 재발송
            </Text>
          </Button>
        </S.PrivateEmailValidationModalReissueCode>

        <S.PrivateEmailValidationModalBottomCard>
          <S.PrivateEmailValidationModalBottomTextBox>
            <Text styleName="subheadline1" color="RG03">
              인증 코드 재전송을 해도 확인이 되지 않는다면,
            </Text>
            <Text styleName="subheadline2" color="RG03" sx={{ marginTop: '8px' }}>
              1. 스팸메일함을 확인해주시거나 메일함 용량을 정리해주세요.
            </Text>
            <Text styleName="subheadline2" color="RG03" sx={{ marginTop: '8px' }}>
              2. 메일 주소가 정확한지 확인하고 오류시 처음부터 다시 시도해주세요.
            </Text>
          </S.PrivateEmailValidationModalBottomTextBox>
        </S.PrivateEmailValidationModalBottomCard>
      </Stack>
    </Modal>
  );
};
