import { getMyInfo } from 'api';
import { getCheckCompananyName, getRequestIndustryType } from 'auth/signUp/api';
import {
  getMyAccountCompanyData,
  getMyAccountUserData,
  putUpdateMyAccountCompanyData,
  putUpdateMyAccountUserData,
} from 'pages/MyPage/Account/api';
import { COMPANY_DATA, USER_DATA } from 'pages/MyPage/Account/constants';
import type { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { FieldError, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { isTruthy } from 'util/isTruthy';
import { validatePhoneNumber } from 'util/validate/validatePhoneNumber';

export const keyConvertor = (key: string, type: 'userInfo' | 'companyInfo') => {
  if (type === 'userInfo') {
    if (key === 'team') {
      const { team } = USER_DATA;
      const _data = team as {
        name: string;
      };
      return _data.name;
    }
    return USER_DATA[key] as string;
  }
  return COMPANY_DATA[key];
};

export const _getUserInfo = async (props: { _dispatch: Dispatch<TMyPageAccountAction> }) => {
  const { _dispatch } = props;
  const [userInfo, companyInfo]: [
    PromiseSettledResult<TMyPageAccountUserResponse | TErrorResponse>,
    PromiseSettledResult<TMyPageAccountCompanyResponse | TErrorResponse>
  ] = await Promise.allSettled([getMyAccountUserData(), getMyAccountCompanyData()]);

  const _userInfo = userInfo as PromiseFulfilledResult<TMyPageAccountUserResponse>;
  const _companyInfo = companyInfo as PromiseFulfilledResult<TMyPageAccountCompanyResponse>;

  if (_userInfo?.value === undefined || _companyInfo?.value === undefined) {
    new Error('_getUserInfo 이슈발생');
  }
  _dispatch({ type: 'UPDATE_ACCOUNT_DATA', payload: _userInfo.value });
  _dispatch({ type: 'UPDATE_ACCOUNT_DATA', payload: _companyInfo.value });
};

export const _updateUserData = async (props: { _dispatch: Dispatch<TMyPageAccountAction> }) => {
  const { _dispatch } = props;
  const userInfo = await getMyAccountUserData();

  const _userInfo = userInfo as TMyPageAccountUserResponse;

  if (_userInfo?.loginAccount) {
    new Error('_getUserInfo 이슈발생');
  }
  _dispatch({ type: 'UPDATE_ACCOUNT_DATA', payload: _userInfo });
};

export const _updateCompanyData = async (props: { _dispatch: Dispatch<TMyPageAccountAction> }) => {
  const { _dispatch } = props;
  const companyData = await getMyAccountCompanyData();

  const _companyInfo = companyData as TMyPageAccountCompanyResponse;

  if (_companyInfo?.industryType) {
    new Error('_updateCompanyData 이슈발생');
  }
  _dispatch({ type: 'UPDATE_ACCOUNT_DATA', payload: _companyInfo });
};

export const _getRequestIndustryType = async (props: { _dispatch: Dispatch<TMyPageAccountAction> }) => {
  const { _dispatch } = props;
  const response = await getRequestIndustryType();
  const error = response as TErrorResponse;
  const _response = response as {
    industryTypeId: number;
    name: string;
    description?: string;
  }[];
  if (error.advice) return;

  if (typeof response === 'string' || response === undefined) return response;
  const _res = _response!.map(value => ({
    value: value.industryTypeId,
    key: value.name,
    extra: value.description,
  }));
  _dispatch({ type: 'UPDATE_INDUSTRY_LIST', payload: _res });
};

export const updateModalState = (props: {
  _dispatch: Dispatch<TMyPageAccountAction>;
  type: TAccountModalType;
  isOpen: boolean;
}) => {
  const { _dispatch, type, isOpen } = props;
  _dispatch({ type: 'UPDATE_MODAL', payload: { type, isOpen } });
};

export const inputCompanyName = async (props: {
  event?: KeyboardEvent<HTMLElement>;
  setError: UseFormSetError<TCompanyInfoHookForm>;
  clearErrors: UseFormClearErrors<TCompanyInfoHookForm>;
  name: string;
  currentName: string | undefined;
}) => {
  const { event, setError, clearErrors, name, currentName } = props;
  if (event && event.key !== 'Enter') return;
  if (isTruthy(name) === false) return setError('name', { message: '회사 이름은 필수입력 사항입니다.' });
  const userNameLength = name.length;
  if (userNameLength > 30) return setError('name', { message: '회사 이름은 30자 이내로 입력 가능합니다.' });

  const response = currentName !== name ? await getCheckCompananyName({ name }) : { hasCompany: false };
  const error = response as TErrorResponse;
  const _response = response as {
    hasCompany: boolean;
  };

  if (error.message) return;
  const { hasCompany } = _response;
  if (hasCompany) {
    return setError('name', { message: '이미 가입된 회사 이름 입니다.' });
  }
  clearErrors('name');

  if (error?.message) return clearErrors('name');
};

export const inputUserName = (props: {
  setError: UseFormSetError<TUserInfoHookForm>;
  clearErrors: UseFormClearErrors<TUserInfoHookForm>;
  error?: FieldError | undefined;
  name: string;
}) => {
  const { setError, clearErrors, name, error } = props;
  if (isTruthy(name) === false) return setError('name', { message: '이름은 필수입력 사항입니다.' });
  const userNameLength = name.length;
  if (userNameLength < 2 || userNameLength > 20)
    return setError('name', { message: '이름은  2~20자 사이여야 합니다.' });
  if (error?.message) return clearErrors('name');
};

export const inputUserPhone = (props: {
  setError: UseFormSetError<TUserInfoHookForm>;
  clearErrors: UseFormClearErrors<TUserInfoHookForm>;
  error?: FieldError | undefined;
  phone: string;
}) => {
  const { setError, clearErrors, phone, error } = props;
  if (isTruthy(phone) === false) return clearErrors('phone');
  const userNameLength = phone.replaceAll('-', '').length;

  if (!validatePhoneNumber(phone) || !(userNameLength > 9 && userNameLength < 12))
    return setError('phone', { message: '휴대폰 번호 형식에 맞게 입력해 주세요.' });
  if (error?.message) return clearErrors('phone');
};

export const _putUpdateMyAccountUserData = async (params: {
  name: string;
  phone: string;
  marketingConsent: boolean;
  setIsModified: Dispatch<SetStateAction<boolean>>;
  _dispatch: Dispatch<TMyPageAccountAction>;
}) => {
  const { name, phone, marketingConsent, setIsModified, _dispatch } = params;
  const response = await putUpdateMyAccountUserData({ name, phone, marketingConsent });

  if (response?.advice) {
    _dispatch({ type: 'UPDATE_MODAL', payload: { type: 'UserInfoFailed', isOpen: true } });
    return response.advice;
  }
  setIsModified(false);
  updateMyInfo();
  _dispatch({ type: 'UPDATE_MODAL', payload: { type: 'UserInfoSuccess', isOpen: true } });
};

export const getModalMessage = (type: TAccountModalType) => {
  switch (type) {
    case 'CompanyInfoLoading':
      return ['회사 정보 수정을 진행중입니다.', '잠시만 기다려주세요.'];
    case 'CompanyInfoSuccess':
      return ['회사 정보 수정을 완료하였습니다.'];
    case 'CompanyInfoFailed':
      return ['회사 정보 수정을 처리하지 못했습니다.'];
    case 'UserInfoLoading':
      return ['회원 정보 수정을 진행중입니다.', '잠시만 기다려주세요.'];
    case 'UserInfoSuccess':
      return ['회원 정보 수정을 완료하였습니다.'];
    case 'UserInfoFailed':
      return ['회원 정보 수정을 처리하지 못했습니다.'];
    default:
      return;
  }
};

export const setCompanyContent = (params: {
  key: 'licenseType' | 'name' | 'payment' | 'industryType';
  companyInfo: TMyPageAccountCompanyResponse | null;
  industryList?: TIndustryTypeList[] | null;
}) => {
  const { key, companyInfo, industryList } = params;
  if (companyInfo === null || industryList === null) return '';
  switch (key) {
    case 'industryType':
      return (
        industryList!.find(list => list.value === companyInfo.industryType.industryTypeId)?.key! +
        (industryList!.find(list => list.value === companyInfo.industryType.industryTypeId)?.extra
          ? ` (${industryList!.find(list => list.value === companyInfo.industryType.industryTypeId)?.extra ?? ''})`
          : '')
      );

    case 'licenseType':
      return companyInfo.licenseType === 'corporate' ? '사업자' : '일반';

    case 'name':
      return companyInfo!.name;
    case 'payment':
      return companyInfo?.payment.businessNumber!;
    default:
      throw new Error('이상키를 가져오고 있습니다.');
  }
};

export const updateMyInfo = async () => {
  const response = await getMyInfo();

  for (const [key, value] of Object.entries(response)) {
    localStorage.setItem(`${key}`, value as string);
  }
};

export const _putUpdateCompanyInfo = async (params: {
  payload: TCompanyInfoHookForm;
  setIsModified: Dispatch<SetStateAction<boolean>>;
  _dispatch: Dispatch<TMyPageAccountAction>;
}) => {
  const { payload, setIsModified, _dispatch } = params;

  let _payload: Partial<TPutUpdateCompanyDataRequest> = {};

  ['name', 'industryTypeId', 'businessNumber'].forEach(key => {
    const _key = key as keyof Omit<TCompanyInfoHookForm, 'licenseType'>;

    if (payload[_key]) {
      if (_key === 'businessNumber') {
        _payload = Object.assign({}, _payload, { payment: { [_key]: payload[_key] } });
      } else {
        _payload = Object.assign({}, _payload, { [_key]: payload[_key] });
      }
    }
  });

  const response = await putUpdateMyAccountCompanyData({ payload: _payload });
  const error = response as TErrorResponse;
  if (error?.message) {
    throw new Error('_putUpdateCompanyInfo 오류');
  }
  setIsModified(false);
  _dispatch({ type: 'UPDATE_MODAL', payload: { type: 'CompanyInfoSuccess', isOpen: true } });

  // 에러 발생 시
  //   setIsModified(true);
  //   _dispatch({ type: 'UPDATE_MODAL', payload: { type: 'CompanyInfoFailed', isOpen: true } });
};
