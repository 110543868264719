import { DropdownItemType, DropdownList } from 'components/Dropdown/DropdownList';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import * as S from 'pages/Setting/organization/Manager/inviteManagerModal/style';
import React, { cloneElement, CSSProperties, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { isFalsy } from 'util/isFalsy';

interface SelectorProps {
  defaultValue?: any;
  placeholder?: string;
  icon?: ReactNode;
  anchor?: 'top' | 'bottom';
  options: SelectorOptionsType[];
  onOptionsClick: (item: SelectorOptionsType) => void;
  fullWidth?: boolean;
  sx?: CSSProperties;
  selectorGap?: number;
  parent?: ReactElement;
  maxHeight?: number;
  showExtra?: boolean;
}

export const DropdownSelector = ({
  anchor = 'bottom',
  defaultValue,
  options,
  onOptionsClick,
  icon,
  sx,
  selectorGap,
  fullWidth,
  placeholder,
  parent,
  maxHeight,
  showExtra,
}: SelectorProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayContent, setDisplayContent] = useState<string>('');
  const [fixedHeight, setFixedHeight] = useState<number>(0);

  useEffect(() => {
    if (showExtra)
      setDisplayContent(
        defaultValue
          ? `${options.find(d => d.value === defaultValue)?.key} ${
              options.find(d => d.value === defaultValue)?.extra
                ? '(' + options.find(d => d.value === defaultValue)?.extra + ')'
                : ''
            }`
          : 'placeholder'
      );
    else setDisplayContent(defaultValue ? `${options.find(d => d.value === defaultValue)?.key}` : 'placeholder');
  }, []);

  useEffect(() => {
    if (!(selectorRef && selectorRef.current) || !isOpen) {
      setFixedHeight(0);
      return;
    }
    const divposition = selectorRef.current.getBoundingClientRect();
    if (divposition.y + divposition.height > window.innerHeight) {
      setFixedHeight(window.innerHeight - divposition.y - 20);
    }
  }, [isOpen, options]);

  useEffect(() => {
    if (isFalsy(defaultValue) && placeholder && isFalsy(displayContent)) {
      return setDisplayContent(placeholder);
    }
  }, [options]);

  useEffect(() => {
    if (displayContent) {
      setIsOpen(false);
    }
  }, [displayContent]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target))
      setTimeout(() => setIsOpen(false), 100);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);
  const style = Object.assign({}, fullWidth && { width: '100%' }, sx);
  return (
    <Stack sx={{ position: 'relative' }}>
      {parent ? (
        cloneElement(parent, {
          onClick: () => {
            setIsOpen(prev => !prev);
          },
          ref: containerRef,
          style: style,

          onKeyDown: (e: any) => {
            (e?.keyCode ?? -33) === 13 && setTimeout(() => setIsOpen(false), 100);
          },
        })
      ) : (
        <S.SelectorDisplayBox
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          ref={containerRef}
          style={style}
        >
          <Text
            sx={{ textAlign: 'initial', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            styleName="caption2"
            color="RG03"
          >
            {displayContent}
          </Text>
          {icon && icon}
        </S.SelectorDisplayBox>
      )}
      <Stack sx={{ position: 'relative' }} ref={selectorRef}>
        {isOpen && (
          <DropdownList
            data={options.map(item => ({ id: item.value, title: item.key, description: item.extra }))}
            onClick={(data: DropdownItemType) => {
              onOptionsClick([data].map(d => ({ key: d.title, value: d.id, extra: d.description }))[0]);

              if (showExtra) setDisplayContent(data.description ? `${data.title} (${data.description})` : data.title);
              else setDisplayContent(data.title);

              // setTimeout(() => setIsOpen(false), 100);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
