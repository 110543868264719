import { createColumnHelper } from '@tanstack/react-table';
import { Stack } from 'components/Stack';
import StatusTag from 'pages/Setting/common/StatusTag';

export const DriverAppColumns = () => {
  const createColumn = createColumnHelper<TDriverAppTable>();
  const tableConfig = {
    tableUnit: {
      totalCount: { text: '팀 수', unit: '개' },
    },
    columns: [
      createColumn.accessor(row => row.name, {
        id: 'name',
        header: '팀 이름',
        cell: info => (
          <Stack direction="row" spacing={6}>
            {info.row.original?.hasOwner && <StatusTag text="오너" variant="small" />}
            {info.getValue()}
          </Stack>
        ),
      }),
      createColumn.accessor(row => row.managerCount, {
        id: 'managerCount',
        header: '소속 매니저',
        cell: info => `${info.getValue()} 명`,
      }),
      createColumn.accessor(row => row.driverCount, {
        id: 'driverCount',
        header: '소속 드라이버',
        cell: info => `${info.getValue()} 명`,
      }),
      createColumn.accessor(row => row.geocoding, {
        id: 'geocoding',
        header: '자동 도착 처리',
        cell: info =>
          info.getValue() ? (
            <StatusTag text="사용중" styleName="caption1" />
          ) : (
            <StatusTag text="미사용" color="RG04" borderColor="RG04" styleName="caption1" />
          ),
      }),
      createColumn.accessor(row => row.podRequired, {
        id: 'podRequired',
        header: 'PoD(작업완료 인증 사진)',
        cell: info =>
          info.getValue() ? (
            <StatusTag text="사용중" styleName="caption1" />
          ) : (
            <StatusTag text="미사용" color="RG04" borderColor="RG04" styleName="caption1" />
          ),
      }),
      createColumn.accessor(row => row.stepFixed, {
        id: 'stepFixed',
        header: '드라이버 임의 순서 변경',
        cell: info =>
          info.getValue() ? (
            <StatusTag text="미사용" color="RG04" borderColor="RG04" styleName="caption1" />
          ) : (
            <StatusTag text="사용중" styleName="caption1" />
          ),
      }),
    ],
  };
  return [tableConfig];
};
