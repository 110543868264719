import styled, { css } from 'styled-components';
import { CardProps } from '.';

const CardBaseStyle = css<CardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: ${({ theme, ds }) => ds && theme.shadows[ds]};

  position: static;
  transition-property: width, height, right, bottom, position;
  transition: 0.3s;

  ${({ theme, padding, width }) => ({
    width: width,
    padding: `${padding}px`,
    backgroundColor: theme.colors.RG00,
  })}
`;

export const MenuCardOutline = styled.div`
  ${CardBaseStyle}

  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG07}`,
  })}
`;

export const PopupCardOutline = styled.div`
  ${CardBaseStyle}

  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;
