function dateConverter(data: string, format: string) {
  data = data.replaceAll(/[^0-9]/g, '');

  let year = data.slice(0, -4);
  let month = data.slice(-4, -2);
  let day = data.slice(-2);

  const isLargeY = year.length > 2 ? true : false;

  const library: { [key: string]: any } = {
    YYMMDD: `${isLargeY ? year.slice(-2) : year}${month}${day}`,
    'YY.MM.DD': `${isLargeY ? year.slice(-2) : year}.${month}.${day}`,
    'YY-MM-DD': `${isLargeY ? year.slice(-2) : year}-${month}-${day}`,
    YYYYMMDD: `${isLargeY ? year : `20${year}`}${month}${day}`,
    'YYYY.MM.DD': `${isLargeY ? year : `20${year}`}.${month}.${day}`,
    'YYYY-MM-DD': `${isLargeY ? year : `20${year}`}-${month}-${day}`,
  };

  return library[format.toUpperCase()];
}
export default dateConverter;
