import styled, { css } from 'styled-components';
import { IHighlightProps, TypographyProps } from '.';

const baseStyle = css<TypographyProps>`
  color: ${({ theme, color }) => (color ? (theme.colors[color] ? theme.colors[color] : color) : '#000000 ')};
  ${({ broken }) => broken && { 'white-space': 'break-spaces !important', 'word-break': 'break-all !important' }};

  text-align: ${({ align }) => align && align};
  text-decoration: ${({ decoration }) => decoration && decoration};
  text-decoration-color: ${({ theme, color }) =>
    color ? (theme.colors[color] ? theme.colors[color] : color) : '#000000 '};
  letter-spacing: -0.02em;

  word-spacing: ${({ ws }) => ws && ws};

  & > svg {
    vertical-align: middle;
  }

  ${({ theme, styleName }) => theme.fontStyle[styleName]}
`;

export const Typo = styled.p`
  ${baseStyle}
`;

export const Headline1 = styled.p`
  ${baseStyle}
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;
export const Headline2 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;
export const Headline3 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

export const Title1 = styled.p`
  ${baseStyle}
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
`;
export const Title2 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;
export const Title3 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`;
export const Body1 = styled.p`
  ${baseStyle}
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;
export const Body2 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
export const Body3 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const SubHeadline1 = styled.p`
  ${baseStyle}
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`;
export const SubHeadline2 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;
export const SubHeadline3 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const Caption1 = styled.p`
  ${baseStyle}
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`;
export const Caption2 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;
export const Caption3 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const Tooltip1 = styled.p`
  ${baseStyle}
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
`;

export const Tooltip2 = styled.p`
  ${baseStyle}
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
`;

export const Number1 = styled.p`
  ${baseStyle}
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  font-family: 'Noto Sans';
`;
export const Number2 = styled.p`
  ${baseStyle}
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Noto Sans';
`;
export const Number3 = styled.p`
  ${baseStyle}
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  font-family: 'Noto Sans';
`;
export const Number4 = styled.p`
  ${baseStyle}
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  font-family: 'Noto Sans';
`;

export const Number5 = styled.p`
  ${baseStyle};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Noto Sans';
`;

export const Error = styled.p`
  ${baseStyle};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #fa1c44;
`;

export const Highlight = styled.span<IHighlightProps>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : {})};

  & > svg {
    vertical-align: middle;
  }

  ${({ theme, styleName }) => (styleName ? theme.fontStyle[styleName] : {})};
`;
