import { Button } from 'components/Button/legacy_index';

import ComplexDoubleviewDateselector from 'components/ComplexDoubleviewDateselector';
import DragablePicker, { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { date_set_range_template } from 'constants/commons';
import { ReactComponent as IcArrowCountDown } from 'constants/icon/ic_arrow_count_down.svg';
import { ReactComponent as IcArrowCountUp } from 'constants/icon/ic_arrow_count_up.svg';
import { ReactComponent as IcCalendar } from 'constants/icon/ic_calendar.svg';

import theme from 'constants/theme';
import dayjs from 'dayjs';
import { ReportFilterContent } from 'pages/Report/utils/ReportFilterContent';
import React, { Dispatch, FocusEvent, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import { IReportQuery } from 'types/report/drive';

const ReportFilter = ({
                        type,
                        setFilter,

                        dimensionSelectorOptions,
                        setDimensionSelectorOptions,
                        infoMetricsSelectorOptions,
                        setInfoMetricsSelectorOptions,
                        metricsSelectorOptions,
                        setMetricsSelectorOptions,

                        orderReportDimensionMetrics,
                        setOrderReportDimensionMetrics,
                      }: {
  type: 'route' | 'order';
  setFilter: Dispatch<SetStateAction<IReportQuery | undefined>>;

  dimensionSelectorOptions: TDragablePickerOptions;
  setDimensionSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  infoMetricsSelectorOptions?: TDragablePickerOptions;
  setInfoMetricsSelectorOptions?: Dispatch<SetStateAction<TDragablePickerOptions>>;
  metricsSelectorOptions: TDragablePickerOptions;
  setMetricsSelectorOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;

  orderReportDimensionMetrics?: TDragablePickerOptions;
  setOrderReportDimensionMetrics?: Dispatch<SetStateAction<TDragablePickerOptions>>;
}) => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      keyword: '',
      startDate: date_set_range_template.month1.format('YYYY.MM.DD'),
      endDate: dayjs().format('YYYY.MM.DD'),
    },
  });

  const PeriodContent: Array<{
    key: string;
    name: string;
    setter: () => void;
  }> = [
    {
      key: 'daily',
      name: '일간',
      setter: () => {
        methods.setValue('startDate', dayjs().subtract(1, 'day').format('YYYY.MM.DD'), {
          shouldDirty: true,
          shouldValidate: true,
        });
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
    {
      key: 'weekly',
      name: '주간',
      setter: () => {
        methods.setValue('startDate', dayjs().subtract(7, 'day').format('YYYY.MM.DD'), {
          shouldDirty: true,
          shouldValidate: true,
        });
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
    {
      key: 'monthly',
      name: '월간',
      setter: () => {
        methods.setValue(
          'startDate',
          dayjs()
            .subtract(dayjs().daysInMonth() - 2, 'day')
            .format('YYYY.MM.DD'),
          { shouldDirty: true, shouldValidate: true },
        );
        methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
      },
    },
  ];

  const [CDDIsOpen, setCDDIsOpen] = useState<boolean>(false);
  const [performedDate, setPerformedDate] = useState<string>(
    `${methods.getValues('startDate').replaceAll('.', '')}-${methods.getValues('endDate').replaceAll('.', '')}`,
  );

  const [isOpenDimensionSelector, setIsOpenDimensionSelector] = useState<boolean>(false);
  const [isOpenInfoMetricsSelector, setIsOpenInfoMetricsSelector] = useState<boolean>(false);
  const [isOpenMetricsSelector, setIsOpenMetricsSelector] = useState<boolean>(false);

  const onSubmit = (res: any) => {
    console.log(res);
  };

  const CalendarInputOnFocus = (e: FocusEvent) => {
    setCDDIsOpen(true);
  };

  useEffect(() => {
    let dimension = new Set<string>();
    Object.values(dimensionSelectorOptions.filter(d => d.isSelected)).forEach(d => {
      if (`${d.key}`.includes('route')) dimension.add('route');
      else dimension.add(`${d.key}`);
    });

    const infoMetricsFilter = infoMetricsSelectorOptions
      ? {
        infoMetrics: Object.values(infoMetricsSelectorOptions.filter(d => d.isSelected))
          .map(d => d.key)
          .toString() as IReportQuery['infoMetrics'],
      }
      : {};

    setFilter({
      performedDate: performedDate,
      dimension: Array.from(dimension).join(',') as IReportQuery['dimension'],
      ...infoMetricsFilter,
      metrics: Object.values(metricsSelectorOptions.filter(d => d.isSelected))
        .map(d => d.key)
        .toString() as IReportQuery['metrics'],
    });
  }, [methods, dimensionSelectorOptions, infoMetricsSelectorOptions, metricsSelectorOptions, performedDate, setFilter]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack align="start" spacing={16}>
        <GrayBox justify="center" align="start">
          <Stack justify="start" align="flex-end" spacing={12} direction="row">
            <ReportFilterContent title="날짜 설정" altInfo="측정할 날짜를 설정합니다.">
              <Stack sx={{ position: 'relative' }}>
                <ComplexDoubleviewDateselector
                  inputReadOnly
                  template
                  summary
                  parent={
                    <CalendarInputField isActive={CDDIsOpen} hasError={false}>
                      <CalendarIconContainer isActive={CDDIsOpen} hasError={false}>
                        <IcCalendar/>
                      </CalendarIconContainer>
                      <input
                        readOnly
                        {...methods.register('startDate', {
                          minLength: {
                            value: 10,
                            message: '',
                          },
                          maxLength: {
                            value: 10,
                            message: '',
                          },
                          pattern: {
                            value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                            message: '',
                          },
                        })}
                        autoComplete="off"
                        onFocus={CalendarInputOnFocus}
                      />
                      {'-'}
                      <input
                        readOnly
                        {...methods.register('endDate', {
                          minLength: {
                            value: 10,
                            message: '',
                          },
                          maxLength: {
                            value: 10,
                            message: '',
                          },
                          pattern: {
                            value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                            message: '',
                          },
                        })}
                        autoComplete="off"
                        onFocus={CalendarInputOnFocus}
                      />
                    </CalendarInputField>
                  }
                  {...{
                    initDate: [
                      dayjs(methods.getValues('startDate')).toDate(),
                      dayjs(methods.getValues('endDate')).toDate(),
                    ],
                    methods,
                    setPerformedDate,
                    isOpen: CDDIsOpen,
                    setIsOpen: setCDDIsOpen,
                  }}
                />
              </Stack>
            </ReportFilterContent>
            <ReportFilterContent title="칼럼 설정" altInfo="측정하고자 하는 데이터를 설정합니다.">
              {type === 'order' ? (
                <Stack spacing={10} direction="row">
                  <DragablePicker
                    dragable
                    top="42px" // parent height + 10
                    height="607px"
                    parent={
                      <Button
                        variant={'RTB'}
                        type={'button'}
                        height={32}
                        onClick={() => setIsOpenDimensionSelector(prev => !prev)}
                      >
                        <Stack spacing={8} direction="row">
                          항목 선택
                          {isOpenDimensionSelector ? <IcArrowCountUp/> : <IcArrowCountDown/>}
                        </Stack>
                      </Button>
                    }
                    {...{
                      options: orderReportDimensionMetrics ?? [],
                      setOptions: setOrderReportDimensionMetrics ?? setDimensionSelectorOptions,
                      isOpen: isOpenDimensionSelector,
                      setIsOpen: setIsOpenDimensionSelector,
                    }}
                  />
                </Stack>
              ) : (
                <Stack spacing={10} direction="row">
                  <DragablePicker
                    dragable
                    top="42px" // parent height + 10
                    parent={
                      <Button
                        variant={'RTB'}
                        type={'button'}
                        bgColor={'RC10'}
                        height={32}
                        onClick={() => setIsOpenDimensionSelector(prev => !prev)}
                      >
                        <Stack spacing={8} direction="row">
                          측정 기준 선택
                          {isOpenDimensionSelector ? <IcArrowCountUp/> : <IcArrowCountDown/>}
                        </Stack>
                      </Button>
                    }
                    {...{
                      options: dimensionSelectorOptions,
                      setOptions: setDimensionSelectorOptions,
                      isOpen: isOpenDimensionSelector,
                      setIsOpen: setIsOpenDimensionSelector,
                    }}
                  />

                  {infoMetricsSelectorOptions && setInfoMetricsSelectorOptions && (
                    <DragablePicker
                      dragable
                      top="42px" // parent height + 10
                      parent={
                        <Button
                          variant={'custom'}
                          type={'button'}
                          custom={{
                            init: { color: 'RG02', bgColor: 'RG06', borderColor: 'RG06' },
                            hover: { color: 'RG02', bgColor: 'RG05', borderColor: 'RG05' },
                          }}
                          height={32}
                          onClick={() => setIsOpenInfoMetricsSelector(prev => !prev)}
                        >
                          <Stack spacing={8} direction="row" sx={{ padding: '4px 16px' }}>
                            <Text styleName="subheadline2" color="RG02">
                              정보 항목 선택
                            </Text>
                            {isOpenDimensionSelector ? <IcArrowCountUp/> : <IcArrowCountDown/>}
                          </Stack>
                        </Button>
                      }
                      {...{
                        options: infoMetricsSelectorOptions,
                        setOptions: setInfoMetricsSelectorOptions,
                        isOpen: isOpenInfoMetricsSelector,
                        setIsOpen: setIsOpenInfoMetricsSelector,
                      }}
                    />
                  )}

                  <DragablePicker
                    dragable
                    top="42px" // parent height + 10
                    height="607px"
                    parent={
                      <Button
                        variant={'RTB'}
                        type={'button'}
                        height={32}
                        onClick={() => setIsOpenMetricsSelector(prev => !prev)}
                      >
                        <Stack spacing={8} direction="row">
                          측정 항목 선택
                          {isOpenMetricsSelector ? <IcArrowCountUp/> : <IcArrowCountDown/>}
                        </Stack>
                      </Button>
                    }
                    {...{
                      options: metricsSelectorOptions,
                      setOptions: setMetricsSelectorOptions,
                      isOpen: isOpenMetricsSelector,
                      setIsOpen: setIsOpenMetricsSelector,
                    }}
                  />
                </Stack>
              )}
            </ReportFilterContent>
          </Stack>
        </GrayBox>
      </Stack>
    </form>
  );
};

export default ReportFilter;

const GrayBox = styled(Stack)`
  padding: 12px 16px;

  min-height: 80px;
  border-radius: 8px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG08,
  })}
`;

const FilterContentBox = styled(Stack)`
  width: fit-content;
    /* width: ${({ direction }) => (direction?.includes('row') ? '100%' : 'fit-content')}; */
`;

export const QueueSelectorWrapper = styled(Stack)`
  overflow: hidden;
  height: 32px;
  border-radius: 6px;
`;
export const QueueSelectorContainer = styled.div<{ len: number; selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 64px;

  ${({ len }) => ({
    width: `calc(100% / ${len})`,
  })}
  height: 100%;

  padding: 6px;
  white-space: nowrap;
  position: relative;

  ${({ selected }) =>
          selected
                  ? {
                    backgroundColor: theme.colors.RC03_1,
                    border: `1px solid ${theme.colors.RC03}`,
                    zIndex: 1,
                  }
                  : { backgroundColor: theme.colors.RG00, border: `1px solid ${theme.colors.RG06}` }}
  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:not(:last-child) {
    margin-right: -1px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  cursor: pointer;
`;

export const CalendarIconContainer = styled.div<{ isActive: boolean; hasError: boolean }>`
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, hasError, isActive }) =>
          hasError ? 'transparent' : isActive ? 'transparent' : theme.colors.RC03_1};
`;

export const CalendarInputField = styled.div<{ isActive: boolean; hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 270px;
  height: 32px;

  border-radius: 6px;
  color: ${({ theme, isActive }) => theme.colors[isActive ? 'RG03' : 'RG02']};
  background: ${({ theme, hasError, isActive }) => theme.colors[hasError ? 'RC04_1' : isActive ? 'RG05-1' : 'RG00']};

  border: 1px solid ${({ theme }) => theme.colors.RG06};

  ${({ theme }) => theme.fontStyle.caption2}
  & > input {
    width: 113px;
    padding: 6px 10px;

    background: none;
    text-align: center;
    color: ${({ theme, isActive }) => theme.colors[isActive ? 'RG03' : 'RG02']};
  }

  :hover {
    background: ${({ theme }) => theme.colors.RC03_1};

    & > ${CalendarIconContainer} {
      background: none !important;
    }
  }
`;

const FilterSearchbar = styled(Stack)`
  ${({ theme }) => theme.fontStyle.caption2}
  width: 345px;
  height: 38px;

  padding: 9px 16px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.RG06};

  & > input {
    width: 100%;

    ::placeholder {
      color: ${({ theme }) => theme.colors.RG05};
    }
  }

  & > svg {
    cursor: pointer;
  }

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.RC03};
  }
`;
