import styled from 'styled-components';
import { CSSProperties } from 'react';

export const DriverAppModalHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px;
`;

export const DriverAppModalBody = styled.section`
  padding: 30px 20px;
  width: 100%;
  max-height: calc(100vh - 220px);
  overflow: overlay;
`;

export const DriverAppModalFooter = styled.footer`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 20px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  justify-content: end;
`;

export const DriverAppModalBodyConfigBox = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: space-between;
  width: inherit;
`;
