import type { Dispatch, SetStateAction } from 'react';
import {
  getInvolvedManagerList,
  getTeamList,
  putChangeTeam,
  putInvitedManagerChangeTeam,
  getInvitedMangerList,
  putDeleteManager,
  putCancelInvitation,
  putInviteManager,
} from 'pages/Setting/organization/Manager';

const _getInvolvedManagerList = async (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  payload?: TManagerListQueryParams;
}) => {
  const { _dispatch, payload } = params;
  let param = {};

  if (payload) {
    const _payload = Object.assign({}, payload);
    delete _payload.placeholder;
    param = _payload;
  }

  const response = await getInvolvedManagerList(param);
  const error = response as TErrorResponse;
  const listResponse = response as TGetInvolvedManagerListResponse;
  if (error.advice) {
    return response;
  }
  const { data } = listResponse;
  return _dispatch({ type: 'UPDATE_MANGER_LIST', payload: { list: data, mode: 'Involved' } });
};

const _getInvitedManagerList = async (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  payload?: TManagerListQueryParams;
}) => {
  const { _dispatch, payload } = params;
  let param = {};

  if (payload) {
    const _payload = Object.assign({}, payload);
    delete _payload.placeholder;
    param = _payload;
  }

  const response = await getInvitedMangerList(param);
  if (response) {
    const { data } = response;
    return _dispatch({ type: 'UPDATE_MANGER_LIST', payload: { list: data, mode: 'Invited' } });
  }

  return response;
};

export const getManagerList = (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  payload?: TManagerListQueryParams;
  mode: TManagerMenu;
}) => {
  const { mode } = params;
  if (mode === 'Involved') {
    return _getInvolvedManagerList({ ...params });
  }
  return _getInvitedManagerList({ ...params });
};

export const switchMode = (params: { _dispatch: Dispatch<TMangerContentAction>; mode: TManagerMenu }) => {
  const { _dispatch, mode } = params;
  return _dispatch({ type: 'SWITCH_MODE', payload: mode });
};

export const convertSearchField = (type: TSearchItems) => {
  switch (type) {
    case 'loginAccount':
      return '이메일';
    case 'userName':
      return '이름';
    default:
      return '소속 팀';
  }
};

export const _getTeamList = async (_dispatch: Dispatch<SetStateAction<TTeamListState[] | null>>) => {
  const response = await getTeamList();

  const teamList = response as TGetTeamListResponse;
  if (teamList.data) {
    const { data } = teamList;
    return _dispatch(data);
  }

  return _dispatch(null);
};

export const updateSelectedRowList =
  (params: { _dispatch: Dispatch<TMangerContentAction>; mode: TManagerMenu }) =>
  (item: TInvolvedManagerListState | TInvitationManagerListState) => {
    const { _dispatch, mode } = params;
    return _dispatch({ type: 'UPDATE_SELECTED_LIST', payload: { item, mode } });
  };

export const updateModalType = (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  type: TMangerHeaderModalType;
  isOpen: boolean;
  response?: any;
}) => {
  const { _dispatch, type, isOpen, response } = params;

  _dispatch({ type: 'UPDATE_MODAL_TYPE', payload: { type, isOpen, response } });
};

export const _putChangeTeam = async (params: {
  teamId: number;
  list: number[];
  _dispatch: Dispatch<TMangerContentAction>;
  mode: TManagerMenu;
}) => {
  const { _dispatch, teamId, mode, list } = params;
  let payload = { teamId: teamId };
  let response;
  if (mode === 'Involved') {
    response = await putChangeTeam(Object.assign({}, payload, { userList: list }));
  } else {
    response = await putInvitedManagerChangeTeam(Object.assign({}, payload, { invitationList: list }));
  }

  if (response) {
    updateModalType({ type: 'ChangeTeamSuccess', isOpen: true, _dispatch });
    return true;
  }
  return response;
};

export const _putDeleteManager = async (params: { userList: number[]; _dispatch: Dispatch<TMangerContentAction> }) => {
  const { userList, _dispatch } = params;

  const response = await putDeleteManager({ userList });
  if (response) {
    updateModalType({ type: 'DeleteManagerSuccess', isOpen: true, _dispatch });
    return true;
  }
  return response;
};

export const initializeManagerList = (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  mode: TManagerMenu;
  useStrait?: boolean;
}) => {
  const { _dispatch, mode, useStrait = false } = params;
  let cleanUp: TManagerMenu = mode === 'Involved' ? 'Invited' : 'Involved';
  if (useStrait) {
    cleanUp = mode;
  }
  return _dispatch({ type: 'UPDATE_MANGER_LIST', payload: { list: null, mode: cleanUp } });
};

export const InitializeSelectedList = (params: { _dispatch: Dispatch<TMangerContentAction>; mode: TManagerMenu }) => {
  const { _dispatch, mode } = params;
  const cleanUp: TManagerMenu = mode === 'Involved' ? 'Invited' : 'Involved';
  return _dispatch({ type: 'UPDATE_SELECTED_LIST', payload: { item: null, mode: cleanUp } });
};

export const loadingModalAlertText = (type: TMangerHeaderModalType): string | string[] => {
  if (['InviteManager', 'ReinviteManager'].includes(type)) {
    return ['초대장을 발송 중 입니다.', '잠시만 기다려 주세요.'];
  }
  return [];
};

export const successModalAlertText = (type: TMangerHeaderModalType, props?: { [key: string]: any }): string[] => {
  switch (type) {
    case 'InviteManagerSuccess':
      const { totalCount, invitedCount } = props?.response;
      if (totalCount === invitedCount) {
        return [`매니저 ${invitedCount} 명 초대를 완료하였습니다.`];
      }
      return [
        `매니저 ${invitedCount} 명 초대를 완료하였습니다.`,
        `중복된 이메일 주소 ${totalCount - invitedCount}건 제외`,
      ];

    case 'ChangeTeamSuccess': {
      const { count } = props!;
      return [`선택한 ${count}명 매니저의 소속 팀을 변경 완료하였습니다.`];
    }
    case 'DeleteManagerSuccess':
      return [`매니저 삭제를 완료하였습니다.`];

    case 'CancelInvitationSuccess':
      return [`초대 취소를 완료하였습니다.`];
    case `ReinviteManagerSuccess`: {
      const { count } = props!;
      return [`매니저 ${count} 명에게 초대장 재전송을 완료하였습니다.`];
    }
    default:
      return ['완료되었습니다.'];
  }
};

export const _putCancelInvititation = async (params: {
  invitedSelectedList: TInvitationManagerListState[];
  _dispatch: Dispatch<TMangerContentAction>;
}) => {
  const { invitedSelectedList, _dispatch } = params;
  const invitationList = invitedSelectedList.map(list => list.invitationId);
  const response = await putCancelInvitation({ invitationList });
  if (response) {
    updateModalType({ type: 'CancelInvitationSuccess', isOpen: true, _dispatch });
  }
};

export const _putInviteManager = async (params: {
  _dispatch: Dispatch<TMangerContentAction>;
  invitedSelectedList: TInvitationManagerListState[];
  setIsLoading: (param: boolean) => void;
}) => {
  const { invitedSelectedList, _dispatch, setIsLoading } = params;

  const invitationList = invitedSelectedList?.map(list => list.invitationId);
  if (invitationList === undefined) return;

  const response = await putInviteManager({ invitationList });

  if (response === true) {
    setIsLoading(false);
    return updateModalType({ _dispatch, type: 'ReinviteManagerSuccess', isOpen: true });
  }
  setIsLoading(false);
  return updateModalType({ _dispatch, type: 'ReinviteManagerFailed', isOpen: true });
};
