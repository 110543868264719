// @ts-ignore
import { RGBAColor } from 'deck.gl';

let driverIdsArray: any = {};

export const ColorPalette = {
  default: [
    '#2182BF',
    '#A79CE1',
    '#EF932E',
    '#63A212',
    '#8E36B8',
    '#C06F9F',
    '#435990',
    '#9F872E',
    '#7EA8FF',
    '#E44590',
    '#F06D24',
    '#28CAED',
    '#BD63C4',
    '#566D38',
    '#42D86C',
    '#FF70BD',
    '#1C5843',
    '#8266BE',
    '#B17477',
    '#E58FB8',
    '#CF87C8',
    '#AB592B',
    '#BBB400',
    '#B1326E',
    '#00CA99',
    '#3CADEC',
    '#816646',
    '#706ECB',
    '#5ABB5E',
    '#C16161',
    '#519AC3',
    '#532776',
    '#3C823F',
    '#3898AD',
    '#6A5620',
    '#B95923',
    '#FF9671',
    '#7BE7DC',
    '#4F42D8',
    '#8F98E5',
    '#823C67',
    '#3B9C6D',
    '#EF738D',
    '#FFB423',
    '#6D89EC',
    '#5D459F',
    '#72D6DC',
    '#E76D59',
    '#AC5977',
    '#49C193',
  ],
  startColor: '#1C48CD',
  endColor: '#25282F',
  newColor: '#769DFF',
  selectedNewColor: '#406EE2',
  getColor: function (index: number) {
    // DriverId를 기본 키로 사용하지 않고, 각 DriverId마다 다른 색상값 순차적으로 부여
    if (!driverIdsArray[index]) {
      let currentIndex = Object.keys(driverIdsArray).length;
      driverIdsArray[index] = ColorPalette.default[currentIndex % ColorPalette.default.length];
    }
    return driverIdsArray[index];
  },
};

export function hexToRgb(hex: string, alpha: number = 255): RGBAColor {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), alpha] : [0, 0, 0, alpha];
}
