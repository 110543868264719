import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { Stack } from 'components/Stack';
import { SelectorContainer, SelectorItem } from 'components/Selector/style';

const TableSelectManager = ({
  options,
  instance,
  anchorPoint,
  setAnchorIsOpen,
}: {
  options: Array<{ key: string; value: string; optionValue?: boolean }>;
  instance: any;
  anchorPoint: DOMRect | { left: number; top: number } | undefined;
  setAnchorIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current?.contains(e.target) && !parentRef.current?.contains(e.target)) setAnchorIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef, parentRef]);

  return (
    <SelectorContainer
      spacing={10}
      sx={{
        width: 'fit-content',
        top: anchorPoint && anchorPoint.top - 26,
        left: anchorPoint && anchorPoint.left - 36,
      }}
      ref={parentRef}
    >
      <Stack>
        {options.map(d => (
          <SelectorItem
            key={`${d.key}-${d.value}-may-${d?.optionValue}`}
            onClick={() => {
              instance[d.key](d?.optionValue);
              setAnchorIsOpen(false);
            }}
            ref={targetRef}
          >
            {d.value}
          </SelectorItem>
        ))}
      </Stack>
    </SelectorContainer>
  );
};

export default TableSelectManager;
