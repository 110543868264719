import {
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Dispatch, useState, ReactNode, SetStateAction, useEffect, Fragment } from 'react';

import { NewTableComponent } from 'pages/Setting/common';
import { common_table_selection } from 'constants/commons';
import TableSelectManager from 'components/Table/util/TableSelectManager';

interface INewTable {
  data: any;
  isFetching: boolean;

  rowSelection?: {};
  setRowSelection?: Function;
  emptyDataMessage?: string;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  tableHeaderContent?: ReactNode;
  onRowClick?: Function;
  pageStatus?: string | undefined;
  columns: ColumnDef<any, any>[];
  manual?: boolean;
  autoResetSelection?: boolean;
  placeholder?: ReactNode;
  canDetailModalModi?: boolean;
  tableRowCount: TTableRowCounter;

  anchorIsOpen?: any;
  setAnchorIsOpen?: any;
  anchorPoint?: any;
}
export const NewTable = (props: INewTable) => {
  const {
    data,
    pagination,
    setRowSelection,
    setPagination,
    columns,
    tableHeaderContent,
    tableRowCount,
    isFetching,
    onRowClick,
    emptyDataMessage,
    anchorIsOpen,
    anchorPoint,
    setAnchorIsOpen,
  } = props;
  const defalutValue = { pageIndex: 0, pageSize: 10 };
  const [defaultPagination, setDefaultPagination] = useState<TPaginationState>({ pageIndex: 0, pageSize: 10 });
  const paginationConfig = pagination || defaultPagination;
  const instance = useReactTable({
    data,
    columns,
    state: {
      pagination: paginationConfig,
    },
    initialState: {},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    setDefaultPagination(defalutValue);
    instance.resetRowSelection(true);
  }, [isFetching]);

  return (
    <Fragment>
      <NewTableComponent
        emptyDataMessage={emptyDataMessage}
        tableHeaderContent={tableHeaderContent}
        tableRowCount={tableRowCount}
        table={instance}
        setRowSelection={setRowSelection}
        onRowClick={(e, row) => {
          onRowClick && onRowClick(row);
        }}
        pagination={pagination || defaultPagination}
        setPagination={setPagination || setDefaultPagination}
        placeholder={''}
        isDataFetching={isFetching}
        clearBottom
      />
      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}
    </Fragment>
  );
};
