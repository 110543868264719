import { PAYMENT_QUERIES } from './payment.queries';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type FetchPaymentManagerResponse = {
  name: string;
  phone: string;
  email: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchPatchPaymentManager>>;
  TError: Error;
  TVariable: Partial<FetchPaymentManagerResponse>;
  TMutationContext: ReturnType<typeof PAYMENT_QUERIES.PATCH_PAYMENT_MANAGER>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchPatchPaymentManager = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.patch(endPoint, {
    json: variables,
  }).json<FetchPaymentManagerResponse>();

const usePatchPaymentManager = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    PAYMENT_QUERIES.PATCH_PAYMENT_MANAGER(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchPatchPaymentManager({
        variables: variables,
        ...PAYMENT_QUERIES.PATCH_PAYMENT_MANAGER()[0],
      }),
    options
  );

export { fetchPatchPaymentManager, usePatchPaymentManager };
export type { FetchPaymentManagerResponse };
