import { TStyleName, TThemeColor } from './types';

const colors: { [key in TThemeColor]: string } = {
  RC01: '#1C48CD',
  RC02: '#2E66F6',
  RC03: '#769DFF',
  RC03_1: '#769DFF1A',
  RC03_2: '#769DFF0D',
  RC03_3: '#769DFF4D',
  RC03_4: '#769DFF05',
  RC04: '#FA1C44',
  RC04_1: '#FFEEF2',
  RC04_2: '#FA1C441A',
  RC04_3: '#FA1C444D',
  RC05: '#C5CFE7',
  RC06: '#FF7448',
  RC07: '#FFE3DA',
  RC08: '#FFF3EE',
  RC09: '#FFA217',
  RC09_1: '#FFF5E2',
  RC09_2: '#FFA2171A',
  RC10: '#13C097',
  RC10_1: '#E7F9F5',
  RC10_2: '#13C0971A',
  RC10_3: '#13C0974D',
  RC11: '#7975FF',
  RC11_1: '#7975FF26',
  RC12: '#4DDBD2',
  RC12_1: '#4DDBD233',
  RC13: '#00FFF080',
  RC14: '#92ABE8',
  RG00: '#ffffff',
  RG01: '#000000',
  RG01_1: '#0000001A',
  RG02: '#25282F',
  RG02_1: '#00000033',
  RG03: '#565B69',
  RG04: '#8F96A9',
  RG05: '#B2B8CC',
  RG05_1: '#B2B8CC33',
  RG06: '#D8DDEA',
  RG07: '#F0F3F9',
  RG08: '#F8F9FB',
  RG09: '#1A1E27',
};

const fontStyle: { [key in TStyleName]: string } = {
  headline1: `
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`,
  headline2: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  `,
  headline3: `
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  `,

  title1: `
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  `,
  title2: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  `,
  title3: `
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  `,

  body1: `
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  body2: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  body3: `
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,

  subheadline1: `
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  `,
  subheadline2: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  `,
  subheadline3: `
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  `,

  caption1: `
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  `,
  caption2: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  `,
  caption3: `
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  `,

  tooltip1: `
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  `,

  tooltip2: `
  letter-spacing: -0.02em;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
`,

  tooltip3: `
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
`,

  number1: `
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    font-family: 'Noto Sans';
  `,
  number2: `
    letter-spacing: -0.02em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Noto Sans';
  `,
  number3: `
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    font-family: 'Noto Sans';
  `,
  number4: `
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0;
    font-family: 'Noto Sans';
  `,
  number5: `
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Noto Sans';
  `,

  landing1: `
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 40px;
  line-height: 62px;
  `,
  landing2: `
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  `,
  landing3: `
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  `,
  landing4: `
  letter-spacing: -0.02em;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  `,
  landing5: `
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  `,
};

export const scrollBarStyle = (padding: number = 5.5, size: number = 18) => `
&::-webkit-scrollbar {
  width: ${size}px;
  height: ${size}px;
}

&::-webkit-scrollbar-thumb {
  border: ${padding}px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 33px;
  background-color: ${colors.RG06};
}
`;
const scrollStyle = {
  horizontal: `overflow-x: overlay;` + scrollBarStyle(),
  vertical: `overflow-y: overlay;` + scrollBarStyle(),
  both: `overflow: overlay;` + scrollBarStyle(),
};

const tableScrollStyle = {
  horizontal: `overflow-x: overlay;` + scrollBarStyle(3, 12),
  vertical: `overflow-y: overlay;` + scrollBarStyle(3, 12),
  both: `overflow: overlay;` + scrollBarStyle(3, 12),
};

const modalScrollStyle = {
  horizontal: `overflow-x: scroll;` + scrollBarStyle(9, 24),
  vertical: `overflow-y: scroll;` + scrollBarStyle(9, 24),
  both: `overflow: scroll;` + scrollBarStyle(9, 24),
};

const shadows = {
  weak: `0px 12px 26px rgba(16, 30, 115, 0.06);`,
  normal: '0px 6px 20px rgba(16, 30, 115, 0.1);',
  strong: '0px 8px 20px rgba(16, 30, 115, 0.1);',
};

const theme = {
  colors,
  fontStyle,
  shadows,
  scrollStyle,
  tableScrollStyle,
  modalScrollStyle,
};

export default theme;
