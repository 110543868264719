import { LayerProps, useMap } from 'react-map-gl';

export const clusterLayerProps: any = {
  filter: ['has', 'point_count'],
  type: 'circle',
  paint: {
    'circle-color': '#FFFFFF',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 3, 15, 4],
    'circle-stroke-width': 3,
    'circle-stroke-color': ['get', 'color'],
  },
};

export const unclusteredPointLayerProps: any = {
  filter: ['all', ['!', ['has', 'point_count']], ['>=', ['zoom'], 10]],
  type: 'circle',
  paint: {
    'circle-color': '#FFFFFF',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 3, 15, 4],
    'circle-stroke-width': 3,
    'circle-stroke-color': ['get', 'color'],
  },
};

export const unclusteredPointWzLayerProps: any = {
  filter: ['all', ['!', ['has', 'point_count']], ['<', ['zoom'], 10]],
  type: 'circle',
  paint: {
    'circle-color': '#FFFFFF',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 3, 15, 4],
    'circle-stroke-width': 3,
    'circle-stroke-color': ['get', 'color'],
  },
};

export const polylinesLayerProps: any = {
  type: 'line',
  paint: {
    'line-opacity': ['get', 'opacity'],
    'line-color': ['get', 'color'],
    'line-width': ['interpolate', ['linear'], ['zoom'], 10, ['+', ['get', 'width'], 0], 15, ['+', ['get', 'width'], 2]],
  },
};

export default function MapImage() {
  const { current: map } = useMap();
  if (!map?.hasImage('map-pin')) {
    map?.loadImage('/static/right-arrowhead.png', (error, image) => {
      if (error) throw error;
      if (!map?.hasImage('map-pin') && image) map.addImage('map-pin', image, { sdf: true });
    });
  }
  return null;
}

export const directionProps: LayerProps = {
  type: 'symbol',
  paint: {
    'icon-color': '#FFFFFF',
  },
  layout: {
    'symbol-spacing': 2,
    'symbol-placement': 'line',
    'icon-image': 'map-pin',
    'icon-padding': 0,
    'icon-size': 0.2,
    'icon-rotate': 0,
  },
};
