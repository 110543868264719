import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';

import { Text } from 'components/Typography';
import { Button } from 'components/Button/legacy_index';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';

import { ReactComponent as CircleCheckBox } from 'constants/icon/ic_success.svg';
import { ReactComponent as ArrowUp } from 'constants/icon/ic_arrow_up.svg';
import { __switchSignUpModal, __switchTerm, _switchOpen, switchCheck } from 'auth/signUp/privateTypeForm/container';

import * as S from 'auth/signUp/style';

interface ITermsOfServiceList {
  _modalDispatch: Dispatch<SetStateAction<TSignUpPrivateTypeFormModalState>>;
  _modalState: TSignUpPrivateTypeFormModalState;
  setError: UseFormSetError<TPrivateInfoType>;
  clearErrors: UseFormClearErrors<TPrivateInfoType>;
  errorMessage: string | undefined;

  setValue: UseFormSetValue<TPrivateInfoType>;

  termsOfService: TTermsOfServiceList[];
  callback: Function;
}

export const TermsOfServiceList = (props: ITermsOfServiceList) => {
  const { setError, clearErrors, errorMessage, setValue, _modalDispatch, _modalState, callback, termsOfService } =
    props;
  const terms = termsOfService!;
  const [isTermsDetailOpen, setIsTermsDetailOpen] = useState<boolean>(false);

  const [agreeAllOfTerms, Service, Private] = terms;
  const isHideErrorMessage = agreeAllOfTerms.checked || (Service.checked && Private.checked);

  useEffect(() => {
    if (isHideErrorMessage) {
      clearErrors('termsOfService');
      setValue('termsOfService', true);
      return;
    } else {
      setValue('termsOfService', false);
      isTermsDetailOpen &&
        setError('termsOfService', { message: '필수 약관 동의에 체크해 주세요. 미동의시 회원가입 진행이 불가합니다.' });
    }
  }, [isHideErrorMessage, isTermsDetailOpen]);

  return (
    <S.TermsOfServiceList>
      <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text
          styleName="subheadline2"
          sx={{ cursor: 'pointer', paddingRight: '350px' }}
          onClick={() => callback(__switchTerm({ payload: agreeAllOfTerms, termsOfService, setValue }))}
        >
          <CircleCheckBox
            fill={switchCheck(agreeAllOfTerms.checked)}
            width={16}
            height={16}
            style={{ marginRight: '10px' }}
          />
          {agreeAllOfTerms.text}
        </Text>

        <ArrowUp
          onClick={() => _switchOpen(isTermsDetailOpen, setIsTermsDetailOpen)}
          style={{
            paddingLeft: isTermsDetailOpen ? '98px' : '',
            paddingRight: isTermsDetailOpen ? '' : '98px',
            cursor: 'pointer',
            rotate: isTermsDetailOpen ? '' : '180deg',
          }}
        />
      </li>
      {isTermsDetailOpen &&
        terms
          .filter(term => term.key !== 'ALL')
          .map(term => {
            const isMarketing = term.key === 'MARKETING';
            const filledColor = switchCheck(term.checked);
            return (
              <li
                key={term.key}
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  styleName="subheadline2"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    callback(__switchTerm({ payload: term, termsOfService, setValue }));
                  }}
                >
                  <CircleCheckBox fill={filledColor} width={16} height={16} style={{ marginRight: '10px' }} />
                  {term.text}
                </Text>
                {isMarketing && (
                  <Text styleName="subheadline2" color="RC02">
                    {term.subText}
                  </Text>
                )}
                <Button
                  variant="second"
                  type="button"
                  onClick={() =>
                    __switchSignUpModal({
                      _modalDispatch,
                      payload: { type: 'DetailTermOfService', isOpen: true, params: { key: term.etc } },
                    })
                  }
                >
                  <Text styleName="caption2" color="RG02" sx={{ margin: 'auto 6px auto 6px' }}>
                    약관보기
                  </Text>
                </Button>
              </li>
            );
          })}
      {errorMessage && (
        <S.TermsOfServiceListError isTermsDetailOpen={isTermsDetailOpen}>
          <Text styleName="caption3" color="RC04">
            <ErrorMarking style={{ marginRight: '6px' }} />
            {errorMessage}
          </Text>
        </S.TermsOfServiceListError>
      )}
    </S.TermsOfServiceList>
  );
};
