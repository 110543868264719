import theme from 'constants/theme';
import styled from 'styled-components';
import { SidebarProps } from '.';

export const SidebarWrapper = styled.div<SidebarProps>`
  width: ${({ width }) => width && width};
  height: calc(100vh - 72px);
  /* max-width: ${({ width }) => width && width}; */

  /* box-shadow: ${({ theme, ds }) => ds && theme.shadows[ds]}; */

  padding: ${({ padding }) => (padding ? padding : `60px 0 0 0`)};

  border-radius: 0 8px 8px 0;

  background-color: ${({ theme, bg }) => bg && theme.colors[bg]};

  z-index: ${({ index }) => index && index};

  ${({ useVerticalScroll, theme }) => useVerticalScroll && theme.scrollStyle.vertical}
`;
