import { ReactComponent as SpinLoaderIcon } from 'constants/icon/ellipse_spin_loader.svg';
import { CSSProperties } from 'react';

interface SpinLoaderProps {
  width?: number;
  height?: number;

  sx?: CSSProperties;
}

const SpinLoader = ({ width, height, sx }: SpinLoaderProps) => {
  return (
    <SpinLoaderIcon
      style={{
        width: width ? `${width}px` : '44px',
        height: height ? `${height}px` : '44px',

        ...sx,
      }}
      className="infinite_rotating"
    />
  );
};

export default SpinLoader;
