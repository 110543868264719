export const REPORT_QUERIES = {
  SCOPE: [{ scope: 'REPORT' }] as const,
  DOWNLOAD_REPORT_ROUTE: () => [
    {
      ...REPORT_QUERIES.SCOPE[0],
      group: 'report',
      entity: 'download_report_route',
      endPoint: 'report/route/download',
    },
  ],
  DOWNLOAD_REPORT_DRIVER: () => [
    {
      ...REPORT_QUERIES.SCOPE[0],
      group: 'report',
      entity: 'download_report_driver',
      endPoint: 'report/driver/download',
    },
  ],
};
