import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { getOrderDetail, getVehicleInfo, setOrderDetail } from 'api';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import { ModifiedMultiSkillsSection } from 'components/ModifiedSection/ModifiedMultiSkillsSection';
import DatePickerPopup from 'components/Popup/DatePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';

import { SkillPicker } from 'components/Popup/SkillPicker';

import WorkTimePopup from 'components/Popup/WorkTime';
import { CustomNavItemStyle, CustomNavItemStyleProps } from 'components/SidebarLayout/SettingNav';
import { Stack } from 'components/Stack';
import { Swap } from 'components/Swap';
import Swiper from 'components/Swiper';
import InternalTable from 'components/Table/InternalTable';

import { Tag } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';
import { priority_type, shipment_type } from 'constants/commons';
import { ReactComponent as SpinLoader } from 'constants/icon/ellipse_spin_loader.svg';
import { ReactComponent as IcArrowUp } from 'constants/icon/ic_btn_arrow_up.svg';

import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IconTrash } from 'constants/icon/ic_trash.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';

import { ReactComponent as IconBoxFlat } from 'constants/icon/icon_box_flat.svg';
import { ReactComponent as IconCamera } from 'constants/icon/icon_camera.svg';
import theme from 'constants/theme';
import { ISkippedHistory, TS3 } from 'constants/types';

import dayjs from 'dayjs';
import useFormUtils from 'hooks/useFormUtils';
import useNavigateUtils from 'hooks/useNavigateUtils';
import useOrder from 'hooks/useOrder';
import React, {
  cloneElement,
  CSSProperties,
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useForm, useFormState } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import styled from 'styled-components';

import dateConverter from 'util/dateConverter';
import getDirtyValues from 'util/getDirtyValues';

import { validateOrderStatus, validateRouteStatus } from 'util/validate/validateStatus';

import Modal, { ModalProps } from '.';
import strings from '../../util/Localization';
import { AlertModal } from './AlertModal';
import { genLabel, removeProduct } from './SingleOrderForm';
import {
  DescriptionTable,
  DescriptionTd,
  DescriptionTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from './style';

const skippedHistoryTable = createColumnHelper<ISkippedHistory>();

const OrderDetailModal = ({
  target,
  isOpen,
  setIsOpen,
  noModi,
  bd,
  ds,
  nomt,
  sx,
  style,
  hideLoading,
  jtc = true,
}: {
  target: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  noModi?: boolean;
  ds?: 'weak' | 'normal' | 'strong';
  bd?: boolean;
  style?: CSSProperties;
  sx?: CSSProperties;
  nomt?: boolean;
  hideLoading?: boolean;
  jtc?: boolean;
}) => {
  const { getOrderListProps } = useStore();
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const { viewingOnControlPage } = useNavigateUtils();

  const queryClient = useQueryClient();

  const { refetchOrderList } = useOrder();
  const {
    data: targetDetails,
    isSuccess: targetDetailsIsSuccess,
    isFetching: targetDetailsIsLoading,
    refetch: refetchTargetDetails,
    // } = useQuery(['targetDetails', target], () => getOrderDetail({ target: 28253 }), {
  } = useQuery(['targetDetails', target], () => getOrderDetail({ target: target }), {
    onSuccess: data => {
      data.note = RenderDefaultNote(data.note || []);
      data.serviceTime = Math.floor((data?.serviceTime ?? 0) / 60);
      data.receivedDate = dayjs(data.receivedDate).format('YYYY.MM.DD');
    },
    refetchOnMount: true,
  });

  useEffect(() => {
    if (targetDetails) {
      console.log('targetDetails', targetDetails);
    }
  }, [targetDetails]);

  const {
    mutate: mutateSetOrderDetail,
    isSuccess: mutateSetOrderDetailIsSuccess,
    isError: mutateSetOrderDetailIsError,
  } = useMutation(setOrderDetail, {
    onSuccess: async () => {
      await refetchTargetDetails();
      setShouldRefetch(true);
    },
    onError: () => methods.reset(),
    onSettled: () => setIsModi(false),
  });

  useEffect(() => {
    if (mutateSetOrderDetailIsSuccess) {
      queryClient.invalidateQueries({ queryKey: ['targetDetails', target] });
    }
  }, [mutateSetOrderDetailIsSuccess]);

  const {
    data: vehicles,
    isSuccess: getVehicleListIsSuccess,
    refetch: refetchVehicle,
  } = useQuery(['vehicles'], getVehicleInfo, {});

  const domain = window.localStorage.getItem('serviceDomain');

  type TTabContent = 'basic' | 'processingHistory';
  const [tabsContent] = useState<
    Array<{
      key: TTabContent;
      value: string;
    }>
  >([
    {
      key: 'basic',
      value: strings.주문 + ' 기본 정보',
    },
    {
      key: 'processingHistory',
      value: strings.주문 + ' 처리 이력',
    },
  ]);
  const [currentTab, setCurrentTab] = useState<TTabContent>(tabsContent[0].key);

  const [closeConfirmModalIsOpen, setCloseConfirmModalIsOpen] = useState<boolean>(false);

  const [canModifying, setCanModifying] = useState<boolean>(false);
  const [isModi, setIsModi] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<boolean>(false);

  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [startTimePickerPopupIsOpen, setStartTimePickerPopupIsOpen] = useState<boolean>(false);
  const [endTimePickerPopupIsOpen, setEndTimePickerPopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);
  const [receivedDatePopupIsOpen, setReceivedDatePopupIsOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [skillSearchValue, setSkillSearchValue] = useState<string>('');

  const [desiredTimeStartNan, setDesiredTimeStartNan] = useState<boolean>(false);
  const [desiredTimeEndNan, setDesiredTimeEndNan] = useState<boolean>(false);
  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [modifyingFormIsFold, setModifyingFormIsFold] = useState<{
    consignee: boolean;
    task: boolean;
    product: boolean;
    notes: boolean;
    detail: boolean;
    pod: boolean;
  }>({
    consignee: false,
    task: false,
    product: false,
    notes: Boolean(targetDetails?.note?.at(0)) ? false : true,
    detail: false,
    pod: false,
  });

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({
    control,
  });
  const renderCounter = useRef(0);

  const { ivnm, numberPatternOnChangeHandler } = useFormUtils(methods);

  useEffect(() => {
    refetchTargetDetails();
    setCurrentTab(tabsContent[0].key);
    setShouldRefetch(false);
    setSkillSearchValue('');
    setIsModi(false);
    methods.reset();

    if (shouldRefetch) {
      refetchOrderList();
    }
  }, [isOpen, methods, shouldRefetch, tabsContent]);

  useEffect(() => {
    methods.setValue(
      'receivedDate',
      targetDetails?.receivedDate && dateConverter(targetDetails.receivedDate, 'YYYY.MM.DD'),
      {
        shouldValidate: true,
      }
    );
    methods.setValue('consigneeName', targetDetails?.consigneeName, { shouldValidate: true });
    methods.setValue(
      'consigneePhone',
      targetDetails?.consigneePhone?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/(\-{1,2})$/g, ''),
      { shouldValidate: true }
    );
    methods.setValue('consigneeNote', targetDetails?.consigneeNote, { shouldValidate: true });
    methods.setValue('address', targetDetails?.address, { shouldValidate: true });
    methods.setValue('detailAddress', targetDetails?.detailAddress, { shouldValidate: true });

    methods.setValue(
      'desiredDate',
      targetDetails?.desiredDate && dateConverter(targetDetails.desiredDate, 'YYYY.MM.DD'),
      {
        shouldValidate: true,
      }
    );
    methods.setValue('desiredTimeStart', targetDetails?.desiredTimeStart, { shouldValidate: true });
    methods.setValue('desiredTimeEnd', targetDetails?.desiredTimeEnd, { shouldValidate: true });
    methods.setValue('serviceTime', targetDetails?.serviceTime, { shouldValidate: true });
    methods.setValue('serviceVehicle', targetDetails?.serviceVehicle, { shouldValidate: true });

    methods.setValue('product', targetDetails?.product, { shouldValidate: true });
    methods.setValue('note', targetDetails?.note, {
      shouldValidate: true,
    });

    methods.setValue('capacity', targetDetails?.capacity && targetDetails.capacity, {
      shouldValidate: true,
    });

    methods.setValue('driver_name', targetDetails?.driverName, { shouldValidate: true });
    methods.setValue('routeId', targetDetails?.route?.routeCode, { shouldValidate: true });
    methods.setValue(
      'performedDate',
      targetDetails?.route?.performedDate && dayjs(targetDetails?.route?.performedDate).format('YYYY.MM.DD'),
      { shouldValidate: true }
    );
    methods.setValue('routeStatus', targetDetails?.route?.status && validateRouteStatus(targetDetails?.route.status), {
      shouldValidate: true,
    });

    methods.setValue('shipmentType', targetDetails?.shipmentType, { shouldValidate: true });

    methods.setValue(
      'skills',
      targetDetails?.skills.map(item => item.skillId)
    );

    setSelectedSkillIds(targetDetails?.skills.map(item => item.skillId) ?? []);

    setSkillSearchValue(targetDetails?.serviceVehicle || '');

    setCanModifying(targetDetails?.status === 'unassigned');
    setModifyingFormIsFold(prev => {
      return {
        ...prev,
        notes: Boolean(targetDetails?.note?.at(0)) ? false : true,
      };
    });
  }, [targetDetails, mutateSetOrderDetailIsError, isModi]);

  useEffect(() => {
    console.log('selectedSkillIds', selectedSkillIds);

    methods.setValue('skills', selectedSkillIds);
  }, [selectedSkillIds]);

  const [product, setProduct] = useState<Array<any>>([]);

  useEffect(() => {
    targetDetails && setProduct(targetDetails?.product);
  }, [targetDetails, targetDetailsIsSuccess, isOpen]);

  useEffect(() => {
    methods.setValue(`product[${product.length - 1}].quantity`,
    methods.getValues().product?.[product.length - 1]?.quantity ?? 1, {
      shouldValidate: true,
    });
  }, [product, methods]);

  useEffect(() => {
    console.log(product);
  }, [product]);

  const columns = useMemo(
    () => [
      skippedHistoryTable.accessor(row => dayjs(row.createdAt).format('YYYY.MM.DD HH:mm'), {
        id: 'createdAt',
        header: '일시',
        footer: info => info.column.id,
      }),
      skippedHistoryTable.display({
        id: 'status',
        header: '상태',
        cell: () => {
          return (
            <Tag status={'skipped'} styleName="tooltip1" outline sx={{ width: 45, minWidth: 45, height: 16 }}>
              보류
            </Tag>
          );
        },
        footer: info => info.column.id,

        enableSorting: false,
      }),
      skippedHistoryTable.accessor(row => row.name, {
        id: 'name',
        header: '주행이름',
        cell: info => {
          const { routeId, performedDate, driverId } = info.row.original;

          if (routeId)
            return (
              <Highlight>
                {info.getValue()}
                <Highlight
                  color="RC02"
                  onClick={() => {
                    targetDetails &&
                      viewingOnControlPage({
                        driverId: driverId,
                        orderId: targetDetails.orderId,
                        routeId,
                        targetPerformedDate: performedDate,
                      });
                  }}
                >
                  보러 가기
                </Highlight>
              </Highlight>
            );
          else return <div style={{ display: 'flex', justifyContent: 'start' }}>{`-`}</div>;
        },
        footer: info => info.column.id,
      }),
      skippedHistoryTable.accessor(row => row.comment, {
        id: 'comment',
        header: '사유',
        footer: info => info.column.id,

        enableSorting: false,
      }),
    ],
    [targetDetails]
  );

  if (targetDetailsIsSuccess && !targetDetailsIsLoading && targetDetails.status !== 'invisible') {
    const {
      orderId,
      orderCode,
      receivedDate,
      consigneeName,
      consigneePhone,
      consigneeNote,
      address,
      detailAddress,
      serviceVehicle,
      serviceTime,
      desiredDate,
      desiredTimeStart,
      desiredTimeEnd,
      note,
      status,
      route,
      capacity,
      completedAt,
      shipmentType,
      driverId,
      driverName,
      completedComment,
      orderPod,
      priority,
      actualServiceTime,
      skippedHistory,
      skills,
    } = targetDetails;

    const { key: statusKey, name: statusName } = validateOrderStatus(
      status as
        | 'unassigned'
        | 'scheduled'
        | 'postponed'
        | 'processing'
        | 'arrived'
        | 'completed'
        | 'skipped'
        | 'canceled'
        | 'deleted'
    );

    const isModifying = () => {
      return Object.keys(dirtyFields).length !== 0;
    };

    const handlePostcodeComplete = (data: any) => {
      let address = data.address || data.jibunAddress;
      methods.setValue('address', address, { shouldValidate: true, shouldDirty: true });
      setAddressPopupIsOpen(false);
    };

    const ModiOnSubmit = (data: any) => {
      let res: any = getDirtyValues(dirtyFields, data);

      console.log('res', res);
      console.log('RIDWO');

      if (res?.consigneePhone) res.consigneePhone = res?.consigneePhone?.replace(/[^0-9]/g, '');
      if (res?.receivedDate) res.receivedDate = dateConverter(res.receivedDate, 'YYYY-MM-DD');
      if (res?.desiredDate) res.desiredDate = dateConverter(res.desiredDate, 'YYYY-MM-DD');
      if (res?.serviceTime) {
        res.serviceTime = parseInt(res.serviceTime.replace(/[^0-9]/g, '')) * 60;
      }
      if (res?.service_Time === '') res.service_Time = 0;

      if (
        (res.desiredTimeStart || methods.getValues('desiredTimeStart')) &&
        (res.desiredTimeEnd || methods.getValues('desiredTimeEnd'))
      ) {
        let start = dayjs(
          new Date(
            0,
            0,
            0,
            Number((res.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[0]),
            Number((res.desiredTimeStart ?? methods.getValues('desiredTimeStart'))?.split(':')[1]),
            0
          )
        ).format('YYYY/MM/DD HH:mm');

        let end = dayjs(
          new Date(
            0,
            0,
            0,
            Number((res.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[0]),
            Number((res.desiredTimeEnd ?? methods.getValues('desiredTimeEnd'))?.split(':')[1]),
            0
          )
        ).format('YYYY/MM/DD HH:mm');
        if (dayjs(start).isAfter(end)) {
          return methods.setError(
            'desiredTimeStart',
            {
              type: 'invalidDateRange',
              message: '날짜의 범위가 잘못되었습니다. 다시한번 확인해 주세요',
            },
            { shouldFocus: true }
          );
        }
      }

      if (res.desiredTimeStart === '없음') res.desiredTimeStart = '';
      if (res.desiredTimeEnd === '없음') res.desiredTimeEnd = '';

      // RT-577/RT-617
      res?.product?.forEach((d: any) => {
        delete d.product_id;
      });

      res.skills = selectedSkillIds;

      if (!(Object.keys(res).length === 0)) {
        console.log(res);
        mutateSetOrderDetail({ target: target, ...res });
      }
      methods.reset({ dirtyFields: true });
      setIsModi(false);
    };

    const orderProcessingValidator = () => {
      const porcessingStatus = ['completed', 'canceled', 'skipped'];
      return porcessingStatus.includes(status) || Boolean(skippedHistory.length);
    };

    const orderViewTableRowRatio = () => (orderProcessingValidator() ? 372 : 480);

    const tab_contents: { [key in TTabContent]: ReactNode } = {
      basic: (
        <Stack name="basic-content-area">
          <DetailModiForm
            icon={<IconBoxFlat width={20} height={20} />}
            title={strings.주문정보}
            isFold={modifyingFormIsFold.product}
            sx={orderProcessingValidator() ? { padding: 20 } : {}}
          >
            <DescriptionTable>
              <tbody>
                <tr key={`$table-row-sum-product`}>
                  <DescriptionTh colSpan={120}>주문 접수일</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>
                    {receivedDate ? dateConverter(receivedDate, 'YYYY.MM.DD') : '-'}
                  </DescriptionTd>
                </tr>
                {domain !== 'survey' && (
                  <tr key={`$table-row-shipment-type`}>
                    <DescriptionTh colSpan={120}>{strings.주문} 유형</DescriptionTh>
                    <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                      {shipment_type.find(d => d.value === shipmentType)?.label}
                    </DescriptionTd>
                    <DescriptionTh colSpan={120}>배차 우선순위</DescriptionTh>
                    <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                      {priority === 'high'
                        ? '높음'
                        : priority === 'medium'
                        ? '보통'
                        : priority === 'low'
                        ? '낮음'
                        : '-'}
                    </DescriptionTd>
                  </tr>
                )}
                <tr>
                  <DescriptionTh colSpan={120}>{strings.작업희망일}</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>
                    {desiredDate ? dateConverter(desiredDate, 'YYYY.MM.DD') : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.희망시간이후}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                    {desiredTimeStart ? desiredTimeStart : '-'}
                  </DescriptionTd>
                  <DescriptionTh colSpan={120}>{strings.희망시간이전}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                    {desiredTimeEnd ? desiredTimeEnd : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>{'예상 ' + strings._작업 + ' 소요 시간'}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>{serviceTime}분</DescriptionTd>
                  <DescriptionTh colSpan={120}>{`담당 드라이버 지정`}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                    {serviceVehicle ? serviceVehicle : '-'}
                  </DescriptionTd>
                </tr>

                {product.map((d, i) => {
                  if (domain === 'roouty')
                    return (
                      <Fragment key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                        <tr>
                          <DescriptionTh colSpan={120}>{strings.상품명}</DescriptionTh>
                          <DescriptionTd colSpan={orderViewTableRowRatio()} elipsis>
                            {d.name ? d.name : '-'}
                          </DescriptionTd>
                        </tr>
                        <tr key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                          <DescriptionTh colSpan={120}>{strings._상품 + ' 수량'}</DescriptionTh>
                          <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>{d.quantity}</DescriptionTd>
                          <DescriptionTh colSpan={120}>{strings.상품코드}</DescriptionTh>
                          <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                            {d.code ? d.code : '-'}
                          </DescriptionTd>
                        </tr>
                      </Fragment>
                    );
                  else
                    return (
                      <Fragment>
                        <tr key={`${i}-table-row-${d.name ?? 'product' + i}`}>
                          <DescriptionTh colSpan={120}>{strings.상품명}</DescriptionTh>
                          <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                            {d.name ? d.name : '-'}
                          </DescriptionTd>
                          <DescriptionTh colSpan={120}>{strings.상품코드}</DescriptionTh>
                          <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                            {d.code ? d.code : '-'}
                          </DescriptionTd>
                        </tr>
                      </Fragment>
                    );
                })}
                <tr>
                  <DescriptionTh colSpan={120}>예상 용적량</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>{capacity ? capacity : '-'}</DescriptionTd>
                </tr>
              </tbody>
            </DescriptionTable>
          </DetailModiForm>
          <Divider
            color="RG06"
            style={orderProcessingValidator() ? { margin: '10px 0 0 0' } : { margin: '6px 0 0 0' }}
          />
          <ModifiedMultiSkillsSection
            isModified={isModi}
            selectedSkillIds={skills.map(item => item.skillId)}
            setSelectedSkillIds={setSelectedSkillIds}
          />
          <Divider
            color="RG06"
            style={orderProcessingValidator() ? { margin: '10px 0 0 0' } : { margin: '6px 0 0 0' }}
          />
          <DetailModiForm
            icon={<IconPerson width={20} height={20} />}
            title={'고객 정보'}
            isFold={modifyingFormIsFold.consignee}
            sx={orderProcessingValidator() ? { padding: 20 } : {}}
          >
            <DescriptionTable>
              <tbody>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.고객명}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>{consigneeName}</DescriptionTd>
                  <DescriptionTh colSpan={120}>고객 연락처</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2}>
                    {consigneePhone ? consigneePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1 - $2 - $3`) : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>주소</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>{address}</DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>상세주소</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>
                    {detailAddress ? detailAddress : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.고객전달사항}</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()}>
                    {consigneeNote ? consigneeNote : '없음'}
                  </DescriptionTd>
                </tr>
              </tbody>
            </DescriptionTable>
          </DetailModiForm>
          <Divider
            color="RG06"
            style={orderProcessingValidator() ? { margin: '10px 0 0 0' } : { margin: '6px 0 0 0' }}
          />
          <DetailModiForm
            title={strings.주행_조사 + ' 정보'}
            icon={<IconTruck width={20} height={20} />}
            isFold={modifyingFormIsFold.task}
            sx={orderProcessingValidator() ? { padding: 20 } : {}}
          >
            <DescriptionTable>
              <tbody>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.모달_드라이버}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2} disabled>
                    {driverName ? driverName : '-'}
                  </DescriptionTd>
                  <DescriptionTh colSpan={120}>{'경로 ID'}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2} disabled>
                    {route ? route.routeCode : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.주행일자}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2} disabled>
                    {route ? dayjs(route.performedDate).format('YYYY.MM.DD') : '-'}
                  </DescriptionTd>
                  <DescriptionTh colSpan={120}>{'경로 상태'}</DescriptionTh>
                  <DescriptionTd colSpan={(orderViewTableRowRatio() - 120) / 2} disabled>
                    {route?.status ? validateRouteStatus(route.status).name : '-'}
                  </DescriptionTd>
                </tr>
                <tr>
                  <DescriptionTh colSpan={120}>{strings.주행이름}</DescriptionTh>
                  <DescriptionTd colSpan={orderViewTableRowRatio()} disabled>
                    {route ? route?.name + ' ' : ''}
                    <span
                      onClick={() => {
                        if (jtc && route) {
                          viewingOnControlPage(
                            {
                              driverId: Number(driverId),
                              orderId: orderId,
                              routeId: route.routeId,
                              targetPerformedDate: route.performedDate,
                            },
                            route.status
                          );
                        }
                      }}
                      style={{
                        color: '#406EE2',
                        textDecoration: 'underline',
                        cursor: !jtc || !route ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {'보러 가기'}
                    </span>
                  </DescriptionTd>
                </tr>
              </tbody>
            </DescriptionTable>
          </DetailModiForm>
          <Divider
            color="RG06"
            style={orderProcessingValidator() ? { margin: '10px 0 0 0' } : { margin: '6px 0 0 0' }}
          />

          <DetailModiForm
            title={
              <Stack spacing={10} direction="row">
                <Text styleName="body1">기타 정보</Text>

                {note ? (
                  <Text styleName="subheadline2" color="RG03">
                    총{' '}
                    <Highlight styleName="subheadline1" color="RG02">
                      {
                        note.filter(d => {
                          return d !== '';
                        }).length
                      }
                    </Highlight>{' '}
                    개
                  </Text>
                ) : null}
              </Stack>
            }
            isFold={modifyingFormIsFold.notes}
            sx={orderProcessingValidator() ? { padding: 20 } : {}}
          >
            <DescriptionTable>
              <tbody>
                {note?.map((d: string, i: any) => {
                  return (
                    <tr key={`note[${i}]`}>
                      <DescriptionTh colSpan={120}>비고{i + 1}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>{d !== '' ? d : '-'}</DescriptionTd>
                    </tr>
                  );
                })}
              </tbody>
            </DescriptionTable>
          </DetailModiForm>
        </Stack>
      ),
      processingHistory: (
        <Stack name="processingHistory-content-area">
          {status !== 'skipped' && (
            <Fragment>
              <DetailModiForm
                icon={<IconBoxFlat width={20} height={20} />}
                title={strings._작업 + ' 완료 상세'}
                spacing={20}
                sx={{ padding: '20px' }}
                isFold={modifyingFormIsFold.detail}
              >
                <DescriptionTable>
                  <tbody>
                    <tr>
                      <DescriptionTh colSpan={120}>{'완료 보고'}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>
                        {completedComment ? <Highlight color="RC02">{completedComment}</Highlight> : '-'}
                      </DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'완료 일시'}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>
                        {completedAt ? dayjs(completedAt).format(`YYYY.MM.DD HH : mm`) : '-'}
                      </DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'실제 ' + strings._작업 + ' 소요 시간'}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>
                        {actualServiceTime ? (
                          <Text styleName="caption2" color="RG01" broken={false}>
                            {`${Math.floor(actualServiceTime / 60)
                              .toString()
                              .padStart(2, '0')} : ${Math.floor(actualServiceTime % 60)
                              .toString()
                              .padStart(2, '0')}`}

                            {
                              <Highlight color="RG04">
                                {` (예상 ${strings._작업} 소요 시간 
                              ${Math.floor((serviceTime ?? 0) / 60)
                                .toString()
                                .padStart(2, '0')}
                                : 
                             ${Math.floor((serviceTime ?? 0) % 60)
                               .toString()
                               .padStart(2, '0')})`}
                              </Highlight>
                            }
                          </Text>
                        ) : (
                          '-'
                        )}
                      </DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'실제 용적량'}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>
                        <Text styleName="caption2" color="RC02">
                          {orderPod?.capacity}
                          <Highlight color="RG04">{` (예상 용적량 : ${capacity})`}</Highlight>
                        </Text>
                      </DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'용적량 차이 사유'}</DescriptionTh>
                      <DescriptionTd colSpan={orderViewTableRowRatio()}>
                        {orderPod?.comment ? <Highlight color="RC02">{orderPod.comment}</Highlight> : '-'}
                      </DescriptionTd>
                    </tr>
                  </tbody>
                </DescriptionTable>
              </DetailModiForm>
              <Divider color="RG06" style={{ width: '100%' }} />
            </Fragment>
          )}

          {status !== 'skipped' && (
            <>
              {domain !== 'survey' && (
                <Fragment>
                  <DetailModiForm
                    icon={<IconCamera width={20} height={20} />}
                    spacing={20}
                    sx={{ padding: '20px' }}
                    isFold={modifyingFormIsFold.pod}
                    title={
                      <Stack direction="row">
                        <Text styleName="body1" color="RG02" sx={{ marginRight: '10px' }}>
                          PoD 사진
                        </Text>
                      </Stack>
                    }
                  >
                    <Swiper
                      type="img"
                      per={3}
                      spacing={16}
                      content={
                        orderPod?.orderPodFiles.map(d => {
                          return { thumb: d.s3url, origin: d.s3url, date: d.createdAt };
                        }) ?? []
                      }
                      placeholder="주문 완료한 사진이 없습니다."
                    />
                  </DetailModiForm>
                  <Divider color="RG06" style={{ width: '100%' }} />
                </Fragment>
              )}
            </>
          )}

          <DetailModiForm
            spacing={20}
            title={
              <Stack direction="row">
                <Text styleName="body1" color="RG02" sx={{ marginRight: '10px' }}>
                  보류 이력
                </Text>
                <Text styleName="subheadline2" color="RG03">
                  총
                </Text>
                <Text styleName="subheadline1" color="RG02">
                  {` ${skippedHistory.length ?? 0} `}
                </Text>
                <Text styleName="subheadline2" color="RG03">
                  건
                </Text>
              </Stack>
            }
            isFold={!Boolean(skippedHistory.length)}
            sx={{ padding: '20px' }}
          >
            <InternalTable data={skippedHistory} columns={columns} />
          </DetailModiForm>
        </Stack>
      ),
    };

    return (
      <Modal
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        plain
        width={isModi ? 496 : orderProcessingValidator() ? 662 : 646}
        {...{ bd, ds, nomt, sx, style }}
      >
        <Stack direction="row" sx={{ height: '100%' }}>
          {
            // is Completed action Order
            orderProcessingValidator() ? (
              <CustomNavContainer>
                {tabsContent.map(d => {
                  return (
                    <CustomNavItem
                      align="start"
                      isActive={currentTab === d.key}
                      onClick={() => {
                        setCurrentTab(d.key);
                      }}
                    >
                      {d.value}
                    </CustomNavItem>
                  );
                })}
              </CustomNavContainer>
            ) : null
          }
          <Stack sx={{ flex: 1, overflowX: 'hidden', height: '100%' }}>
            <OrderDetailModalHeader>
              <Stack direction="row" justify="space-between">
                <Text styleName="subheadline2" color="RG03">
                  {tabsContent.find(d => d.key === currentTab)?.value ?? ''}
                </Text>
                <IconClose
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </Stack>
              <Stack name="info-area" direction="row" justify="space-between" align="center">
                <Stack direction="row" spacing={10} divider={<Divider color="RG06" vertical />} sx={{ height: '16px' }}>
                  <Stack spacing={8} direction="row" sx={{ width: 'max-content' }}>
                    <Text styleName="caption2" color="RG03">
                      {strings.주문상태}
                    </Text>
                    <Tag
                      status={statusKey as 'done' | 'moving' | 'todo' | 'cancel' | 'preparing' | 'purple'}
                      styleName="caption1"
                      outline
                    >
                      {statusName}
                    </Tag>
                  </Stack>
                  <Text styleName="caption2" color="RG04">
                    {strings.주문 + 'ID : ' + orderCode}
                  </Text>
                </Stack>
              </Stack>
            </OrderDetailModalHeader>
            <Divider color="RG06" />
            <OrderDetailModalBody useVerticalScroll>
              <Stack
                sx={{
                  maxHeight: 750,
                  minHeight: currentTab === 'processingHistory' && Boolean(skippedHistory.length) ? 695 : 0,
                }}
              >
                {!isModi ? (
                  tab_contents[currentTab]
                ) : (
                  <Stack>
                    <Stack name="content-area">
                      <DetailModiForm
                        icon={<IconBoxFlat width={20} height={20} />}
                        title={strings.주문정보}
                        isFold={modifyingFormIsFold.product}
                      >
                        <Stack sx={{ position: 'relative' }}>
                          {receivedDatePopupIsOpen && (
                            <DatePickerPopup
                              setIsOpen={setReceivedDatePopupIsOpen}
                              setValue={methods.setValue}
                              initDate={dayjs().toString()}
                              name={'receivedDate'}
                              format="YYYY.MM.DD"
                              top="80px"
                              exclude={{ future: true }}
                            />
                          )}
                          <Input
                            label={genLabel({ text: strings.주문접수일, isRequiredStart: true })}
                            name="receivedDate"
                            placeholder={'YYYY.MM.DD'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            type="text"
                            height={40}
                            readOnly={true}
                            onClick={() => {
                              setReceivedDatePopupIsOpen(true);
                            }}
                            validation={{ required: { value: true, message: '해당 필드는 필수값입니다.' } }}
                            errors={methods.formState?.errors?.receivedDate}
                            hasError={Boolean(methods.formState?.errors?.receivedDate)}
                          />
                        </Stack>

                        {domain === 'roouty' && (
                          <Swap
                            className="order"
                            padding={0}
                            gap={0}
                            sx={{
                              paddingTop: '7px',
                            }}
                            renderCounter={renderCounter.current}
                            name="shipmentType"
                            methods={methods}
                            label={genLabel({
                              text: '주문 유형',
                              subText: `${strings.주문} 1개당 배달 또는 수거만 설정 가능`,
                            })}
                            defaultIndex={shipment_type.findIndex(d => d.value === shipmentType)}
                            options={shipment_type.filter(d => d.visibleType)}
                          />
                        )}
                        <Swap
                          className="order"
                          padding={0}
                          gap={0}
                          sx={{
                            paddingTop: '7px',
                          }}
                          renderCounter={renderCounter.current}
                          name="priority"
                          methods={methods}
                          label={genLabel({
                            text: '배차 우선순위',
                            subText: `주문이 배차되어야 하는 우선 순위`,
                          })}
                          defaultIndex={priority_type.findIndex(d => d.value === priority)}
                          options={priority_type.filter(d => d.visibleType)}
                        />
                        <Stack sx={{ position: 'relative' }}>
                          {desiredDatePopupIsOpen && (
                            <DatePickerPopup
                              setIsOpen={setDesiredDatePopupIsOpen}
                              setValue={methods.setValue}
                              initDate={
                                methods.getValues('desiredDate')
                                  ? dayjs(methods.getValues('desiredDate')).format('YYYY-MM-DD')
                                  : dayjs().toString()
                              }
                              name={'desiredDate'}
                              banPast
                              top="80px"
                              format="YYYY.MM.DD"
                            />
                          )}
                          <Input
                            name="desiredDate"
                            label={genLabel({ text: strings.작업희망일 })}
                            placeholder="YYYY.MM.DD"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                            readOnly={true}
                            onClick={() => {
                              setDesiredDatePopupIsOpen(true);
                            }}
                          />
                        </Stack>
                        <Stack spacing={6}>
                          <Stack spacing={16} direction={'row'}>
                            <Stack sx={{ position: 'relative', flex: 2 }}>
                              {startTimePickerPopupIsOpen && (
                                <PopupYIndexFixer>
                                  <MiniTimePicker
                                    methods={methods}
                                    isOpen={startTimePickerPopupIsOpen}
                                    setIsOpen={setStartTimePickerPopupIsOpen}
                                    name={'desiredTimeStart'}
                                    initial={'00:00'}
                                    init={methods.getValues('desiredTimeStart')}
                                    desired
                                    nm
                                    nanable
                                    nan={desiredTimeStartNan}
                                    setNan={setDesiredTimeStartNan}
                                  />
                                </PopupYIndexFixer>
                              )}
                              <Input
                                label={genLabel({ text: strings.희망시간이후 })}
                                placeholder="00 : 00 (몇 시 부터)"
                                type="text"
                                name="desiredTimeStart"
                                register={methods.register}
                                watch={methods.watch}
                                reset={methods.reset}
                                height={40}
                                readOnly={true}
                                onClick={() => {
                                  setEndTimePickerPopupIsOpen(false);
                                  setStartTimePickerPopupIsOpen(true);
                                }}
                                field
                                hasError={Boolean(methods.formState.errors?.desiredTimeStart)}
                              />
                            </Stack>
                            <Stack align="end" sx={{ position: 'relative', flex: 2 }}>
                              {endTimePickerPopupIsOpen && (
                                <>
                                  <PopupYIndexFixer>
                                    <MiniTimePicker
                                      methods={methods}
                                      isOpen={endTimePickerPopupIsOpen}
                                      setIsOpen={setEndTimePickerPopupIsOpen}
                                      name={'desiredTimeEnd'}
                                      initial={'00:00'}
                                      init={methods.getValues('desiredTimeEnd')}
                                      rSide
                                      desired
                                      nm
                                      nanable
                                      nan={desiredTimeEndNan}
                                      setNan={setDesiredTimeEndNan}
                                    />
                                  </PopupYIndexFixer>
                                </>
                              )}
                              <Input
                                label={genLabel({ text: strings.희망시간이전 })}
                                placeholder="00 : 00 (몇 시 까지)"
                                type="text"
                                name="desiredTimeEnd"
                                register={methods.register}
                                watch={methods.watch}
                                reset={methods.reset}
                                height={40}
                                readOnly={true}
                                onClick={() => {
                                  setStartTimePickerPopupIsOpen(false);
                                  setEndTimePickerPopupIsOpen(true);
                                }}
                                field
                                hasError={Boolean(methods.formState.errors?.desiredTimeStart)}
                              />
                            </Stack>
                          </Stack>
                          {methods.formState.errors?.desiredTimeStart && (
                            <Text styleName="caption3" color="RC04">
                              {`${methods.formState.errors?.desiredTimeStart?.message}`}
                            </Text>
                          )}
                        </Stack>

                        <Stack spacing={6}>
                          <Stack direction="row" spacing={16} align={'end'} sx={{ position: 'relative' }}>
                            <Input
                              name="serviceTime"
                              label={genLabel({ text: '예상 ' + strings._작업 + ' 소요 시간', isRequiredStart: true })}
                              placeholder="숫자만 입력"
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              field
                              height={40}
                              onChange={(e: any) => {
                                setWorkTimePopupIsOpen(false);
                                numberPatternOnChangeHandler(e, 'serviceTime');
                              }}
                              onClick={() => {
                                setWorkTimePopupIsOpen(true);
                              }}
                              validation={{
                                required: true,
                                validate: ivnm.greater_than_0,
                              }}
                              hasError={Boolean(methods.formState.errors?.serviceTime)}
                            />
                            <Stack sx={{ position: 'relative' }}>
                              {skillPopupIsOpen && (
                                <PopupYIndexFixer>
                                  <div></div>
                                </PopupYIndexFixer>
                              )}
                              <Input
                                name="serviceVehicle"
                                label={genLabel({ text: `담당 드라이버 지정` })}
                                placeholder={`담당 드라이버 지정`}
                                register={methods.register}
                                watch={methods.watch}
                                reset={methods.reset}
                                height={40}
                                field
                                validation={{
                                  validate: (res: any) =>
                                    !Boolean(res) ||
                                    (vehicles &&
                                      vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                                    '존재하지 않는 차량입니다.',
                                }}
                                onChange={(e: any) => {
                                  setSkillPopupIsOpen(true);
                                  setSkillSearchValue(e.target.value);
                                  methods.setValue('serviceVehicle', e.target.value, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                }}
                                onClick={() => {
                                  setSkillPopupIsOpen(true);
                                }}
                                hasError={Boolean(methods.formState.errors?.serviceVehicle)}
                                // errors={methods.formState.errors?.skill}
                              />
                              {skillPopupIsOpen && (
                                <SkillPicker
                                  setIsOpen={setSkillPopupIsOpen}
                                  methods={methods}
                                  name={'serviceVehicle'}
                                  searchValue={skillSearchValue}
                                />
                              )}
                            </Stack>

                            {workTimePopupIsOpen && (
                              <WorkTimePopup
                                setIsOpen={setWorkTimePopupIsOpen}
                                setValue={methods.setValue}
                                name={'serviceTime'}
                              />
                            )}
                          </Stack>

                          {methods.formState.errors?.serviceTime && (
                            <Stack align="start">
                              <Text styleName="caption3" color="RC04">
                                {`${methods.formState.errors?.serviceTime?.message}`}
                              </Text>
                            </Stack>
                          )}
                        </Stack>
                        <Stack spacing={24}>
                          {methods.getValues('product')?.map(
                            (
                              d: {
                                quantity: number;
                                name: string;
                                code: string;
                              },
                              i: number
                            ) => {
                              return domain === 'roouty' ? (
                                <Stack
                                  spacing={12}
                                  key={`${d.name}-${i}-product-setting`}
                                  sx={{ position: 'relative' }}
                                >
                                  {product.length <= 1 ? (
                                    <Button
                                      variant={'ItemRemoveButton'}
                                      type={'button'}
                                      disabled
                                      onClick={() => {
                                        removeProduct(i, methods, setProduct);
                                      }}
                                      sx={{
                                        minWidth: '44px',
                                        position: 'absolute',
                                        padding: '0px 4px',
                                        right: '0px',
                                        gap: '2px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <IconTrash width={13} height={13} fill="#B2B8CC" />
                                      <Text
                                        styleName={'caption2'}
                                        sx={{
                                          color: '#8F96A9',
                                        }}
                                      >
                                        삭제
                                      </Text>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant={'seventh'}
                                      type={'button'}
                                      removeButtonDisabled={true}
                                      onClick={() => {
                                        removeProduct(i, methods, setProduct);
                                      }}
                                      sx={{
                                        minWidth: '44px',
                                        position: 'absolute',
                                        padding: '0px 4px',
                                        right: '0px',
                                        gap: '2px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <IconTrash width={13} height={13} fill="#8F96A9" />
                                      <Text
                                        styleName={'caption2'}
                                        sx={{
                                          color: '#565B69',
                                        }}
                                      >
                                        삭제
                                      </Text>
                                    </Button>
                                  )}
                                  <Input
                                    name={`product[${i}].name`}
                                    label={genLabel({ text: strings.상품명 })}
                                    placeholder={strings.상품명 + '을 입력해주세요.'}
                                    register={methods.register}
                                    watch={methods.watch}
                                    reset={methods.reset}
                                    field
                                    height={40}
                                  />

                                  <Stack spacing={6}>
                                    <Stack direction="row" spacing={16}>
                                      <Input
                                        name={`product[${i}].quantity`}
                                        label={genLabel({ text: '아이템 수량', isRequiredStart: true })}
                                        placeholder="1"
                                        register={methods.register}
                                        watch={methods.watch}
                                        reset={methods.reset}
                                        field
                                        type="text"
                                        height={40}
                                        onChange={(e: any) => {
                                          numberPatternOnChangeHandler(e, `product[${i}].quantity`);
                                        }}
                                        validation={{
                                          required: true,
                                          validate: ivnm.greater_than_0,
                                        }}
                                        hasError={
                                          Array.isArray(methods.formState?.errors?.product) &&
                                          methods.formState?.errors?.product[i]?.quantity
                                        }
                                        sx={{ flex: 2 }}
                                      />
                                      <Input
                                        name={`product[${i}].code`}
                                        label={genLabel({ text: strings.상품코드 })}
                                        placeholder="예시) RT-123"
                                        register={methods.register}
                                        watch={methods.watch}
                                        reset={methods.reset}
                                        field
                                        height={40}
                                        sx={{ flex: 7 }}
                                      />
                                    </Stack>

                                    {Array.isArray(methods.formState?.errors?.product) &&
                                      methods.formState?.errors?.product[i]?.quantity.message && (
                                        <Stack align="start">
                                          <Text styleName="caption3" color="RC04">
                                            {methods.formState?.errors?.product[i]?.quantity.message}
                                          </Text>
                                        </Stack>
                                      )}
                                  </Stack>
                                </Stack>
                              ) : (
                                <Stack spacing={16} align="end">
                                  {product?.map((d, i) => {
                                    return (
                                      <Fragment key={`${d.name}-${i}-product-setting`}>
                                        <Stack direction="row" spacing={16} key={`${d.name}-${i}-product-setting`}>
                                          <Input
                                            name={`product[${i}].name`}
                                            label={genLabel({ text: strings.상품명 })}
                                            placeholder={strings.상품명 + '을 입력해주세요.'}
                                            register={methods.register}
                                            watch={methods.watch}
                                            reset={methods.reset}
                                            field
                                            height={40}
                                          />
                                          <Input
                                            name={`product[${i}].code`}
                                            label={genLabel({ text: strings.상품코드 })}
                                            placeholder="예시) RT-123"
                                            register={methods.register}
                                            watch={methods.watch}
                                            reset={methods.reset}
                                            field
                                            height={40}
                                          />
                                        </Stack>
                                      </Fragment>
                                    );
                                  })}
                                </Stack>
                              );
                            }
                          )}
                        </Stack>
                        <Stack align="end">
                          <Button
                            variant={'RTB'}
                            type={'button'}
                            styleName="caption2"
                            width={100}
                            height={26}
                            disabled={product.length >= 10}
                            onClick={() => {
                              setProduct(prev => [...prev].concat({ quantity: 1 }));
                              methods.setValue(`product[${product.length}].quantity`, 1, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }}
                          >
                            + 아이템 추가
                          </Button>
                        </Stack>

                        {methods.formState.errors?.serviceVehicle && (
                          <Stack align="end">
                            <Text styleName="caption3" color="RC04">
                              {`${methods.formState.errors?.serviceVehicle?.message}`}
                            </Text>
                          </Stack>
                        )}
                        <Input
                          name="capacity"
                          label={genLabel({ text: '예상 용적량', isRequiredStart: true })}
                          placeholder="1"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          onChange={(e: any) => {
                            numberPatternOnChangeHandler(e, `capacity`);
                          }}
                          validation={{
                            required: { value: true, message: '해당 필드는 필수값입니다.' },
                            validate: ivnm.greater_than_0,
                          }}
                          sx={{ flex: 1 }}
                          errors={methods.formState?.errors?.capacity}
                          hasError={Boolean(methods.formState?.errors?.capacity)}
                        />
                      </DetailModiForm>
                      <Divider color="RG06" />
                      <ModifiedMultiSkillsSection
                        isModified={isModi}
                        selectedSkillIds={skills.map(item => item.skillId)}
                        setSelectedSkillIds={setSelectedSkillIds}
                      />
                      <Divider color="RG06" />
                      <DetailModiForm
                        icon={<IconPerson width={20} height={20} />}
                        title="고객 정보"
                        isFold={modifyingFormIsFold.consignee}
                      >
                        <Stack direction="row" spacing={16}>
                          <Input
                            label={genLabel({ text: strings.고객명, isRequiredStart: true })}
                            name="consigneeName"
                            placeholder="예시) 홍길동"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            type="text"
                            height={40}
                            validation={{ required: true }}
                            hasError={Boolean(methods.formState?.errors?.consigneeName)}
                          />
                          <Input
                            name="consigneePhone"
                            label={genLabel({ text: '고객 연락처' })}
                            placeholder="000 - 0000 - 0000"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            type="text"
                            height={40}
                            validation={{
                              pattern: {
                                value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/,
                                message: '휴대폰 형식에 맞춰주세요.',
                              },
                              maxLength: {
                                value: 13,
                                message: '휴대폰 형식에 맞춰주세요.',
                              },
                            }}
                            hasError={Boolean(methods.formState?.errors?.consigneePhone)}
                            errors={methods.formState?.errors?.consigneePhone}
                          />
                        </Stack>
                        <Input
                          name="address"
                          label={genLabel({ text: '주소', isRequiredStart: true })}
                          placeholder="받는 분 주소를 입력하세요"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                          validation={{ required: true }}
                          readOnly={true}
                          isInject={((methods.getValues('address') as string)?.length ?? 0) > 0}
                          removeBtn={(e: MouseEvent) => {
                            e.preventDefault();
                            methods.setValue('address', '');
                          }}
                          onFocus={() => {
                            methods.clearErrors('address');
                            setAddressPopupIsOpen(true);
                          }}
                          onClick={() => {
                            setAddressPopupIsOpen(true);
                          }}
                          hasError={Boolean(methods.formState?.errors?.address)}
                        />
                        <Input
                          name="detailAddress"
                          label={genLabel({ text: '상세 주소' })}
                          placeholder="예시) 000동 000호"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                        />
                        <Input
                          name="consigneeNote"
                          label={genLabel({ text: strings.고객전달사항 })}
                          placeholder="예시) 문 앞에 전달"
                          register={methods.register}
                          watch={methods.watch}
                          reset={methods.reset}
                          field
                          type="text"
                          height={40}
                        />
                      </DetailModiForm>
                      <Divider color="RG06" />
                      <DetailModiForm
                        icon={<IconTruck width={20} height={20} />}
                        title={strings.주행_조사 + ' 정보'}
                        isFold={modifyingFormIsFold.task}
                      >
                        <Stack direction="row" spacing={16}>
                          <Input
                            name="driver_name"
                            label={genLabel({ text: strings.모달_드라이버 })}
                            placeholder="-"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            disabled
                            height={40}
                          />
                          <Input
                            name="routeId"
                            label={genLabel({ text: '경로 ID' })}
                            placeholder="-"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            disabled
                            height={40}
                          />
                        </Stack>

                        <Stack direction="row" spacing={16}>
                          <Input
                            name="performedDate"
                            label={genLabel({ text: strings.주행일자 })}
                            placeholder="-"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            disabled
                            height={40}
                          />
                          <Input
                            name="routeStatus"
                            label={genLabel({ text: '경로 상태' })}
                            placeholder="-"
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            disabled
                            height={40}
                          />
                        </Stack>

                        <Stack spacing={8} align="start">
                          <Text styleName="caption2" color="RG03">
                            {strings.주행이름}
                          </Text>
                          {route ? route?.name + ' ' : ''}
                          <Link
                            to={route ? `/manage/route/history/${route.routeId}` : ''}
                            state={route && { driverId: driverId, orderId: orderId }}
                            style={{
                              letterSpacing: '-0.02em',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'start',

                              color: '#406EE2',
                              textDecoration: 'underline',
                              cursor: !route ? 'not-allowed' : 'pointer',

                              backgroundColor: theme.colors.RG07,
                              borderRadius: '8px',
                              border: `1px solid ${theme.colors.RG07}`,
                              padding: '10px',

                              width: '100%',
                              height: '40px',

                              boxSizing: 'border-box',
                            }}
                          >
                            보러 가기
                          </Link>
                        </Stack>
                      </DetailModiForm>
                      <Divider color="RG06" />
                      <DetailModiForm
                        title={
                          <Stack spacing={10} direction="row">
                            <Text styleName="body1">기타 정보</Text>

                            {note ? (
                              <Text styleName="subheadline2" color="RG03">
                                총{' '}
                                <Highlight styleName="subheadline1" color="RG02">
                                  {
                                    note.filter(d => {
                                      return d !== '';
                                    }).length
                                  }
                                </Highlight>{' '}
                                개
                              </Text>
                            ) : null}
                          </Stack>
                        }
                        isFold={modifyingFormIsFold.notes}
                      >
                        {methods.getValues('note')?.map((d: any, index: any) => {
                          return (
                            <Input
                              key={`note[${index}]`}
                              name={`note[${index}]`}
                              label={genLabel({ text: `비고${index + 1}` })}
                              placeholder={'-'}
                              register={methods.register}
                              watch={methods.watch}
                              reset={methods.reset}
                              field
                              height={40}
                            />
                          );
                        })}
                      </DetailModiForm>
                    </Stack>

                    <Modal
                      isModalOpen={addressPopupIsOpen}
                      setIsModalOpen={setAddressPopupIsOpen}
                      title={'우편번호찾기'}
                      width={532}
                      ms={30}
                      padding={20}
                    >
                      <DaumPostcodeEmbed
                        onComplete={handlePostcodeComplete}
                        style={{ height: '500px', marginBottom: '0' }}
                      />
                    </Modal>
                    <CloseConfirmModal
                      isOpen={closeConfirmModalIsOpen}
                      setIsOpen={setCloseConfirmModalIsOpen}
                      targetSetIsOpen={setIsOpen}
                      text={
                        <p>
                          수정 사항이 저장되지 않았습니다.
                          <br />
                          수정하기을 종료하시겠습니까?
                        </p>
                      }
                      ms={17}
                      RBT={'이어서 수정하기'}
                      LBT={'종료'}
                    />
                  </Stack>
                )}
              </Stack>
            </OrderDetailModalBody>
            <Divider color="RG06" />
            <OrderDetailModalFooter>
              <Button
                variant="fourth"
                type="button"
                height={40}
                width={102}
                styleName="body2"
                color="RG04"
                onClick={() => {
                  if (isModifying()) setCloseConfirmModalIsOpen(true);
                  else setIsOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                variant="default"
                type="submit"
                height={40}
                width={216}
                styleName="body2"
                color="RG00"
                disabled={noModi || !canModifying}
                onClick={
                  isModi
                    ? handleSubmit(ModiOnSubmit)
                    : () => {
                        setIsModi(prev => !prev);
                      }
                }
              >
                {!isModi ? '수정하기' : '수정완료'}
              </Button>
            </OrderDetailModalFooter>
          </Stack>
        </Stack>
      </Modal>
    );
  } else if (targetDetailsIsSuccess && !targetDetailsIsLoading && targetDetails.status === 'invisible') {
    return (
      <AlertModal
        isOpen={isOpen}
        type="WARNING"
        messages={'삭제된 주문입니다.'}
        buttonName="확인"
        callback={() => {
          setIsOpen(false);
        }}
      />
    );
  } else if (hideLoading)
    return (
      <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} plain bd>
        <></>
      </Modal>
    );
  else
    return (
      <Modal
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        title={strings.모달_주문 + '상세 정보'}
        ms={36}
        padding={24}
        width={isModi ? 978 : 646}
        height={'80vh'}
        bd={bd}
        sx={sx}
      >
        <Stack spacing={30}>
          <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating" />
          <Text styleName="subheadline3" color="RG03">
            데이터를 불러오고 있습니다...
          </Text>
          <OrderDetailModalFooter>
            <Button
              variant="fourth"
              type="button"
              height={32}
              width={84}
              styleName="caption1"
              color="RG04"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              닫기
            </Button>
          </OrderDetailModalFooter>
        </Stack>
      </Modal>
    );
};

export default OrderDetailModal;

export const CloseConfirmModal = ({
  width,
  isOpen,
  setIsOpen,
  targetSetIsOpen,
  text,
  RBT,
  LBT,
  ms,

  icon,

  closeCallback,
  btnFunctions,

  ...props
}: {
  width?: number;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>> | TS3<boolean>;
  targetSetIsOpen?: Dispatch<SetStateAction<boolean>> | TS3<boolean>;

  text: string | ReactNode;
  RBT: string;
  LBT: string;

  icon?: ReactElement;

  closeCallback?: Function;
  btnFunctions?: {
    RBT?: Function;
    LBT?: Function;
  };
  ms?: number;
} & Partial<ModalProps>) => {
  return (
    <Modal
      plain
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      width={width ? width : 486}
      padding={24}
      closeCallbackFunc={closeCallback}
      ms={typeof ms === 'number' ? ms : 5}
      {...props}
    >
      <Stack name="delete-vehicle-modal">
        {icon && cloneElement(icon, {})}
        <Stack name="content-area" spacing={16} sx={{ padding: '30px 0 40px' }}>
          <Text styleName="subheadline3" color="RG03">
            {text}
          </Text>
        </Stack>

        <Stack direction="row" spacing={20}>
          <Button
            variant={'second'}
            type={'button'}
            fullWidth
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              btnFunctions?.LBT && btnFunctions.LBT();
              setIsOpen(false);
              targetSetIsOpen && targetSetIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG03">
              {LBT ? LBT : '취소'}
            </Text>
          </Button>
          <Button
            variant={'eighth'}
            type={'button'}
            fullWidth
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              btnFunctions?.RBT && btnFunctions.RBT();
              setIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG00">
              {RBT}
            </Text>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export const DetailModiForm = ({
  title,
  spacing,
  children,
  spread,
  isFold,
  scrollUnit,

  lock,
  rSide,
  sx,
  icon,
}: {
  icon?: ReactNode;
  title: string | ReactNode;
  spacing?: number;
  children: ReactNode;
  spread?: boolean;
  isFold?: boolean;
  scrollUnit?: boolean;

  lock?: boolean;
  rSide?: string | ReactNode;
  sx?: CSSProperties;
}) => {
  const [modifyingFormIsFold, setModifyingFormIsFold] = useState<boolean>(isFold ?? false);

  return (
    <Stack
      padding={20}
      align={'start'}
      sx={scrollUnit ? { ...sx, height: modifyingFormIsFold ? 'fit-content' : '100%' } : { ...sx }}
      useVerticalScroll={!modifyingFormIsFold && scrollUnit}
    >
      <Stack
        onClick={e => {
          if (spread || lock) return;
          else {
            setModifyingFormIsFold(prev => !prev);

            // FIXME need auto scroll action
            // e.currentTarget.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        direction="row"
        justify="space-between"
        align="start"
        sx={spread ? {} : { cursor: 'pointer' }}
        // sx={{ cursor: typeof isFold === 'boolean' ? 'pointer' : 'auto' }}
      >
        <Stack justify="start" direction="row" spacing={10}>
          {icon && icon}
          <Text styleName={'body1'}>{title}</Text>
        </Stack>
        <Stack spacing={16} direction="row" sx={{ width: 'fit-content' }}>
          {rSide && rSide}
          {spread ? null : (
            <IcArrowUp style={{ transform: modifyingFormIsFold ? 'rotate(0.5turn)' : 'rotate(1turn)' }} />
          )}
        </Stack>
      </Stack>
      <Stack
        spacing={spacing ?? 16}
        sx={{
          marginTop: modifyingFormIsFold ? 0 : spacing ?? 20,
          // 아이템이 4개 이상일 시 UI 깨지는 이슈 (@4YJ), 나중에 필요할지 몰라서 주석으로 했습니다.
          // maxHeight: modifyingFormIsFold ? '0px' : '100vh',
          minHeight: 'fit-content',
          transition: 'all .3s ease-out',
        }}
      >
        {!modifyingFormIsFold ? children : <div style={{ height: '300px', flex: 1 }} />}
      </Stack>
    </Stack>
  );
};

const RenderDefaultNote = (note: string[]) => {
  if (note && note.length < 5) {
    let dump = note;
    for (let index = note.length; index < 5; index++) {
      dump.push('');
    }

    return dump;
  } else return note;
};

const CustomNavContainer = styled(Stack)`
  min-width: 128px;
  max-width: 128px;
  height: 100%;
  padding: 24px 10px;
  background: ${({ theme }) => theme.colors.RG08};
`;

const CustomNavItem = styled(Stack)<CustomNavItemStyleProps>`
  ${CustomNavItemStyle};

  height: 32px;
  padding: 6px;
`;
