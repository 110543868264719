import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';

interface FetchDeleteMultiSkillResponse {}

type Variables = {
  skillIds: number[];
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchDeleteMultiSkill>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.DELETE_MULTI_SKILL>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchDeleteMultiSkill = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.put(endPoint, {
    json: variables,
  }).json<FetchDeleteMultiSkillResponse>();

const useDeleteMultiSkill = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.DELETE_MULTI_SKILL(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchDeleteMultiSkill({
        variables: variables,
        ...MASTER_DATA_QUERIES.DELETE_MULTI_SKILL()[0],
      }),
    options
  );

export { fetchDeleteMultiSkill, useDeleteMultiSkill };
export type { FetchDeleteMultiSkillResponse };
