const getDirtyValues = (dirtyFields: any, allValues: any): unknown[] | Record<string, unknown> => {
  // 변경된 data가 array일 경우 array 전체를 넣어야함
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [key, getDirtyValues(dirtyFields[key], allValues[key])])
  );
};

export default getDirtyValues;
