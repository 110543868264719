import { DownloadButton } from 'components/Button/DownloadButton';
import Modal from 'components/Modal';
import XlsxLoader from 'components/Modal/XlsxLoader';
import { ReactComponent as IcPlus } from 'constants/icon/ic_plus.svg';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button';
import { Stack } from '../../../../components/Stack';
import { MasterOrderTable } from '../../../../components/Table/masterData/MasterOrderTable';
import { IGetTeamListProps, ISelectorRequires } from '../../../../constants/types';
import { useDownloadMasterOrder } from '../../../../hooks/query/masterData/useDownloadMasterOrder';
import {
  GetMasterOrderListParams,
  useGetMasterOrderList,
} from '../../../../hooks/query/masterData/useGetMasterDataList';
import { IMasterOrderData } from '../../../../types/masterData/masterOrder';
import { FilterSearchbar } from '../../../OrderManagePage';
import GrayBox from '../../common/GrayBox';
import Layout from '../../Layout';

const filter_content: Array<ISelectorRequires<string> & { placeholder: string }> = [
  { value: 'serviceVehicle', key: '담당 드라이버', placeholder: '담당 드라이버를 입력해주세요' },
  { value: 'consigneeName', key: '고객명', placeholder: '고객명을 입력해주세요' },
  { value: 'address', key: '주소', placeholder: '주소를 입력해주세요' },
  { value: 'teamName', key: '소속 팀', placeholder: '소속 팀을 입력해주세요' },
  { value: 'skill', key: '특수 조건', placeholder: '특수 조건 이름을 입력해주세요' },
];

const MasterOrder = () => {
  const [params, setParams] = useState<GetMasterOrderListParams>({
    searchItem: 'consigneeName',
    keyword: '',
  });

  const { data: masterOrderListData, refetch } = useGetMasterOrderList(params);
  const [selectedMasterOrder, setSelectedMasterOrder] = useState<Array<IMasterOrderData>>([]);

  const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(false);

  const methods_default_values: IGetTeamListProps = {
    searchItem: filter_content[1].value,
    keyword: '',
  };
  const methods = useForm({ mode: 'onSubmit', defaultValues: { ...methods_default_values } });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (masterOrderListData) {
      setSelectedMasterOrder([]);
    }
  }, [masterOrderListData]);

  return (
    <Layout
      attr="masterOrder"
      title="주문지 관리"
      desc="주문지 마스터데이터를 등록하고 관리할 수 있습니다."
      ts={
        <Stack justify="end" direction="row" spacing={20}>
          <DownloadButton
            text="주문지"
            variables={{ masterOrderIds: selectedMasterOrder.map(item => item.masterOrderId) }}
            isDisabled={!selectedMasterOrder.length}
            extension="xlsx"
            useDownloadReactQueryHook={useDownloadMasterOrder}
          />
          <Button
            type="button"
            variant="primary"
            h={32}
            w={132}
            icon={[IcPlus, { fill: '#FFF' }]}
            onClick={() => {
              setXlsxFormIsOpen(true);
            }}
          >
            주문지 추가
          </Button>
        </Stack>
      }
    >
      <GrayBox direction="row" spacing={12}>
        <FilterSearchbar
          {...{
            init: 'consigneeName',
            methods,
            content: filter_content,
          }}
        />
        <Button
          type="button"
          variant="secondary"
          h={32}
          onClick={handleSubmit(valid => {
            console.log(valid, 'hihello');

            setParams({
              searchItem: valid.searchItem ?? '',
              keyword: valid.keyword ?? '',
            });
          })}
        >
          조회하기
        </Button>
      </GrayBox>
      <Stack sx={{ marginTop: 40 }}>
        <MasterOrderTable
          data={masterOrderListData?.data}
          selectedMasterOrder={selectedMasterOrder}
          setSelectedMasterOrder={setSelectedMasterOrder}
        />
      </Stack>

      <Modal padding={24} width={503} isModalOpen={xlsxFormIsOpen} setIsModalOpen={setXlsxFormIsOpen}>
        <XlsxLoader
          scope={'roouty_order_master'}
          setXlsxFormIsOpen={setXlsxFormIsOpen}
          registerCallbackFunction={() => {
            refetch();
          }}
        />
      </Modal>
    </Layout>
  );
};

export default MasterOrder;
