import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import useInterval from '../hooks/useInterval';
import theme from '../constants/theme';

export interface CountdownProps {
  count: number;
  setExpiredMessage: Dispatch<SetStateAction<string>>;
  timestamp: number;
  disabled?: boolean;
  setDisabled?: Dispatch<SetStateAction<boolean>>;
}

const TimerText = styled.span`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${theme.colors.RC02};
  width: 46px;
`;

export const Countdown = ({ count, setExpiredMessage, timestamp, disabled, setDisabled }: CountdownProps) => {
  const defaultMinutes = Math.floor(count / 60);
  const defaultSeconds = count - defaultMinutes * 60;
  const defaultMinutesString = defaultMinutes < 10 ? `0${defaultMinutes}` : defaultMinutes;
  const defaultSecondsString = defaultSeconds < 10 ? `0${defaultSeconds}` : defaultSeconds;
  const [timer, setTimer] = useState(`${defaultMinutesString}:${defaultSecondsString}`);
  const [countdownState, setCountdownState] = useState(1);
  const [delay, setDelay] = useState<number | null>(1000);

  useEffect(() => {
    setCountdownState(1);
    setTimer(`${defaultMinutesString}:${defaultSecondsString}`);
    setDelay(1000);
  }, [defaultMinutesString, defaultSecondsString, timestamp]);

  useEffect(() => {
    if (window.localStorage.countdownFinished) {
      setDelay(null);
    }
  }, [timer]);

  useEffect(() => {
    if (disabled) {
      setDelay(null);
    }
  }, [disabled]);

  useInterval(() => {
    const timeData = count - countdownState;

    const minutes = Math.floor(timeData / 60);
    const seconds = timeData - minutes * 60;
    const minutesString = minutes < 10 ? `0${minutes}` : minutes;
    const secondsString = seconds < 10 ? `0${seconds}` : seconds;
    setCountdownState(countdownState + 1);

    setTimer(`${minutesString}:${secondsString}`);

    if (count === countdownState) {
      setDelay(null);
      setExpiredMessage('인증 번호 입력 시간을 초과하였습니다.');
      setDisabled?.(true);
    }
  }, delay);

  return <TimerText id="countdown">{timer}</TimerText>;
};
