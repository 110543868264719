import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { chapters, useLandingChapter } from './chapters';
import { useGNBStore, useTutorialStore } from 'store';

import Modal from 'components/Modal';
import { Stack } from 'components/Stack';

import Progressbar from './Progressbar';

import { ReactComponent as IcLeft } from 'constants/icon/ic_arrow_left.svg';
import { ReactComponent as IcRight } from 'constants/icon/ic_arrow_right.svg';

import { Button } from 'components/Button';
import { Highlight, Text } from 'components/Typography';
import SpeechBubble from 'components/Popup/SpeechBubble';
import { CoachMarksModal } from '../Modal/CoachMarksModal';
import { AlertModal } from '../Modal/AlertModal';

SwiperCore.use([Navigation, Pagination]);

const Tutorial = () => {
  const { TUTORIALISOPEN, SETTUTORIALISOPEN } = useTutorialStore();
  const { setShowTutorialTooltip } = useGNBStore();

  const swiperRef = useRef<SwiperRef>(null);

  const paginationRef = useRef<HTMLSpanElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const cardRef = useRef<HTMLDivElement>(null);

  const [current, setCurrent] = useState<number>(0);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [success, setSuccess] = useState<Array<number>>([]);
  const [shown, setShown] = useState<Set<number>>(new Set());
  const [isOpenCoachMarksModal, setIsOpenCoachMarksModal] = useState<boolean>(false);
  const [coachMarksImage, setCoachMarksImage] = useState<string>('');
  const [hideButton, setHideButton] = useState<boolean>(false);
  const [alertModalProps, setAlertModalProps] = useState<ActionFunctionsReturn>({
    isOpen: false,
    messages: [''],
  });

  const [extended, setExtended] = useState<boolean>(true);
  const extendedStyle: CSSProperties | undefined = useMemo(() => {
    return extended
      ? { padding: 20, position: 'absolute', right: '50%', bottom: '50%', transform: 'translate(50%, 50%)' }
      : { padding: 20, position: 'absolute', right: 20, bottom: 20 };
  }, [extended]);

  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(true);

  const landingChapter = useLandingChapter();

  // React.useEffect(() => {
  //   console.log(extended, extendedStyle);
  //   console.log(cardRef.current?.getBoundingClientRect());
  // }, [extended]);

  const {
    name: content_name,
    desc: content_desc,
    slide: content_slide,
  } = useMemo(() => {
    return chapters[current];
  }, [current]);

  const isLastSlide = useMemo(() => {
    return currentSlide === content_slide.length - 1;
  }, [currentSlide, content_slide]);

  const isEnd = useMemo(() => {
    return isLastSlide && current === chapters.length - 1;
  }, [isLastSlide, current, chapters]);

  const slideActionKey = useMemo(() => {
    return content_slide[0]?.actionKey;
  }, [content_slide]);

  const slideCoachMarksImage = useMemo(() => {
    return content_slide[currentSlide]?.coachMarksImage;
  }, [content_slide, currentSlide]);

  useEffect(() => {
    setHideButton(content_slide[currentSlide]?.hideButton ?? false);
  }, [content_slide, currentSlide]);

  useEffect(() => {
    // clear initial values here
    setCurrent(0);
    setSuccess([]);
    setExtended(true);
    setShown(new Set());
  }, [TUTORIALISOPEN, setShowTutorialTooltip]);

  useEffect(() => {
    const con: boolean = !shown.has(current) && !extended;
    if (con) {
      setShown(prev => prev.add(current));
    }
    setTooltipIsOpen(con);
  }, [extended, shown]);

  useEffect(() => {
    swiperRef.current?.swiper.slideTo(0);
  }, [current]);

  return (
    <Wrapper {...{ extended, isOpen: TUTORIALISOPEN }}>
      <Progressbar {...{ chapters, setExtended, current, setCurrent, success }} />
      <Modal
        ref={cardRef}
        plain
        isModalOpen={true}
        setIsModalOpen={() => {}}
        bd={!extended}
        ds={extended ? undefined : 'strong'}
        sx={extendedStyle}
        cardOnClick={() => {
          !extended && setExtended(true);
        }}
      >
        <Stack align="start" className="container-48">
          <Text align="start" styleName="title1">
            {content_name}
            {extended && content_desc && <Highlight styleName="title2">{` - ${content_desc}`}</Highlight>}
          </Text>
        </Stack>
        <Stack spacing={16} direction="row" className="extended">
          <PageButton ref={prevRef} disabled={currentSlide === 0}>
            <IcLeft />
          </PageButton>
          <div className="swiper-container" style={{ width: 700, borderRadius: 8 }}>
            <Swiper
              ref={swiperRef}
              pagination={{
                el: paginationRef.current,
                type: 'fraction',
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onBeforeInit={swiper => {
                swiper.navigation.update();
              }}
              autoHeight
              slidesPerView={1}
              onSlideChange={swiper => setCurrentSlide(swiper.activeIndex)}
            >
              {content_slide.map(({ str, type, content }, index) => {
                return (
                  <SwiperSlide className="slide-wrapper" key={index}>
                    {typeof str === 'string' ? (
                      <Text align="start" styleName="body2">
                        {str}
                      </Text>
                    ) : (
                      str
                    )}
                    {type === 'video' ? (
                      <video
                        src={content}
                        autoPlay
                        muted
                        controls
                        className="mt-20 slide-item"
                        onPlay={e => (e.currentTarget.playbackRate = 0.75)}
                      />
                    ) : type === 'img' ? (
                      <img src={content} alt={`${current}-${index}-${type}`} className="mt-20 slide-item" />
                    ) : (
                      <div className="mt-20 slide-item">{content}</div>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <PageButton ref={nextRef} disabled={isLastSlide}>
            <IcRight />
          </PageButton>
        </Stack>

        <Stack
          name="footer"
          direction="row"
          justify="space-between"
          className={`container-48 mt-${extended ? 17 : 10}`}
        >
          <Text ref={paginationRef} styleName="caption1" sx={{ width: 'max-content', letterSpacing: '0.6rem' }} />
          {!hideButton && (
            <Button
              children={extended ? (isLastSlide ? (isEnd ? '닫기' : '완료') : '직접 해보기') : '크게 보기'}
              variant={'primary'}
              w={extended ? 100 : 'max-content'}
              h={extended ? 32 : 24}
              onClick={e => {
                e.stopPropagation();

                if (isLastSlide) {
                  if (isEnd) {
                    SETTUTORIALISOPEN(false);
                    setShowTutorialTooltip(true);
                    return;
                  } else {
                    setSuccess(prev => [...prev, current]);
                    setCurrent(prev => prev + 1);
                  }
                } else {
                  if (extended) {
                    if (currentSlide === 0 && slideActionKey) {
                      const actionFunctionsReturn = landingChapter[slideActionKey]();

                      if (actionFunctionsReturn) {
                        setAlertModalProps(actionFunctionsReturn);

                        return;
                      }
                    }

                    if (slideCoachMarksImage) {
                      setCoachMarksImage(slideCoachMarksImage);
                      setIsOpenCoachMarksModal(true);
                    }
                  }

                  setExtended(p => !p);
                }
              }}
            />
          )}
        </Stack>

        <Text styleName="caption2" className="mt-10 nuclear">
          튜토리얼을 계속 진행하고 싶으시면 여기를 클릭해 주세요!
        </Text>
      </Modal>

      <SpeechBubble
        isOpen={tooltipIsOpen}
        setIsOpen={setTooltipIsOpen}
        bOffset={97}
        color="RC02"
        hc
        sx={{
          zIndex: 28,
          right: 115,
          bottom: 157,
          cursor: 'pointer',
          width: 'max-content',

          opacity: tooltipIsOpen ? 1 : 0,
          transition: 'all 0.2s',
        }}
      >
        <Text styleName="subheadline1" color="RG00" sx={{ textAlign: 'start' }}>
          튜토리얼을 계속 진행하고 싶으시면
          <br />
          여기를 클릭해 주세요!
        </Text>
      </SpeechBubble>

      <CoachMarksModal image={coachMarksImage} isOpen={isOpenCoachMarksModal} setIsOpen={setIsOpenCoachMarksModal} />

      <AlertModal
        {...alertModalProps}
        type="WARNING"
        buttonName="확인"
        callback={() => {
          setAlertModalProps(prev => ({ ...prev, isOpen: false }));
        }}
      />
    </Wrapper>
  );
};

export default Tutorial;

const Wrapper = styled.div<{ extended: boolean; isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'visible' : 'none')};

  .container-48 {
    padding: ${({ extended }) => (extended ? '0px 48px' : '0px')};
  }

  .mt-6 {
    margin-top: 6px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-17 {
    margin-top: 17px;
  }

  .mt-20 {
    margin-top: 20px;
  }
  
  .slide-item {
    width: 100%;
    height: 372px !important;

    max-height: 372px !important;
    background-color: ${({ theme }) => theme.colors.RG06};

    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.RG06};

    object-fit: contain !important;
  }

  .extended {
    ${({ extended }) => ({
      marginTop: extended ? 20 : 0,
      width: extended ? 'auto' : 0,
      height: extended ? 'auto' : 0,
      visibility: extended ? 'visible' : 'hidden',
    })};
  }

  .nuclear {
    ${({ extended }) => ({
      width: !extended ? 'auto' : 0,
      height: !extended ? 'auto' : 0,
      visibility: !extended ? 'visible' : 'hidden',
    })};
  }
`;

const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.RG08};
  width: 32px;
  padding: 8px;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  cursor: pointer;

  svg > path {
    fill: ${({ theme }) => theme.colors.RG04};
  }

  transition: background 0.3s ease;

  ${({ disabled, theme }) =>
    disabled
      ? {
          cursor: 'not-allowed',
          'svg > path': {
            fill: theme.colors.RG06,
          },
        }
      : {
          ':hover': {
            'svg > path': {
              fill: theme.colors.RC03,
            },
            background: theme.colors.RC03_1,
          },
        }}
`;
