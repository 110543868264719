import styled from 'styled-components';

export const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;

  width: 132px;
  height: 176px;

  padding: 24px 10px 10px;

  border-radius: 8px;
  ${({ theme, isActive }) => ({
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${isActive ? theme.colors.RC02 : theme.colors.RG06}`,
  })}
`;
export const Graphic = styled.div`
  width: 100%;
  height: 100%;
`;
