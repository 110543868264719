import { Stack } from '../Stack';
import { Button } from '../Button';
import { AlertModal } from './AlertModal';
import { usePrompt } from '../../hooks/usePrompt';

interface BeforeUnloadModalProps {
  when: boolean;
  message: string[];
}

const BeforeUnloadModal = ({ when, message }: BeforeUnloadModalProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when, [], true);

  return (
    <AlertModal
      isOpen={showPrompt}
      type="NONE"
      messages={message}
      buttonGroup={
        <Stack direction="row" spacing={20} sx={{ marginTop: '40px' }}>
          <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={cancelNavigation}>
            취소
          </Button>
          <Button variant="primary" h={40} color="RC02" w="100%" onClick={confirmNavigation}>
            확인
          </Button>
        </Stack>
      }
    />
  );
};

export { BeforeUnloadModal };
export type { BeforeUnloadModalProps };
