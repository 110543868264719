import { Button } from 'components/Button';
import { AddSkillModal } from 'components/Modal/AddSkillModal';
import { Stack } from 'components/Stack';
import { MultiSkillTable } from 'components/Table/masterData/MultiSkillTable';
import { ReactComponent as IcPlus } from 'constants/icon/ic_plus.svg';

import { IGetTeamListProps, ISelectorRequires } from 'constants/types';
import { useGetMultiSkillList } from 'hooks/query/masterData/useGetMultiSkillList';
import { FilterSearchbar } from 'pages/OrderManagePage';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IMultiSkillData } from 'types/masterData/multiSkill';
import GrayBox from '../../common/GrayBox';
import Layout from '../../Layout';

const filter_content: Array<
  ISelectorRequires<string> & {
    placeholder: string;
  }
> = [
  { value: 'name', key: '조건 이름', placeholder: '조건 이름을 입력해주세요' },
  { value: 'driverName', key: '소속 드라이버', placeholder: '소속 드라이버를 입력해주세요' },
  { value: 'teamName', key: '소속 팀', placeholder: '소속 팀을 입력해주세요' },
];

const MultiSkill = () => {
  const [params, setParams] = useState({
    searchItem: 'name',
    keyword: '',
  });

  const { data: multiSkillListData } = useGetMultiSkillList(params);
  const [selectedMultiSkill, setSelectedMultiSkill] = useState<IMultiSkillData[]>([]);

  const [isOpenAddSkillModal, setIsOpenAddSkillModal] = useState<boolean>(false);

  const methods_default_values: IGetTeamListProps = {
    searchItem: filter_content[0].value,
    keyword: '',
  };
  const methods = useForm({ mode: 'onSubmit', defaultValues: { ...methods_default_values } });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (multiSkillListData) {
      setSelectedMultiSkill([]);
    }
  }, [multiSkillListData]);

  return (
    <Layout
      attr="multiSkill"
      title="특수 조건 관리"
      desc="드라이버와 주문의 특수 조건들을 등록하고 관리할 수 있습니다."
      ts={
        <Stack justify="end" direction="row" spacing={20}>
          <Button
            type="button"
            variant="primary"
            h={32}
            w={132}
            icon={[IcPlus, { fill: '#FFF' }]}
            onClick={() => {
              setIsOpenAddSkillModal(true);
            }}
          >
            조건 추가
          </Button>
        </Stack>
      }
    >
      <GrayBox direction="row" spacing={12}>
        <FilterSearchbar
          {...{
            init: 'consigneeName',
            methods,
            content: filter_content,
          }}
        />
        <Button
          type="button"
          variant="secondary"
          h={32}
          onClick={handleSubmit(valid => {
            console.log(valid, 'hihello');

            setParams({
              searchItem: valid.searchItem ?? '',
              keyword: valid.keyword ?? '',
            });
          })}
        >
          조회하기
        </Button>
      </GrayBox>
      <Stack sx={{ marginTop: 40 }}>
        <MultiSkillTable
          data={multiSkillListData?.data}
          selectedMultiSkill={selectedMultiSkill}
          setSelectedMultiSkill={setSelectedMultiSkill}
        />
      </Stack>

      <AddSkillModal isOpen={isOpenAddSkillModal} setIsOpen={setIsOpenAddSkillModal} />
    </Layout>
  );
};

export default MultiSkill;
