import { useQueryClient } from '@tanstack/react-query';
import {
  ColumnOrderState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { common_table_selection } from '../../../constants/commons';
import { IInvolvedDriver, LatLng } from '../../../constants/types';
import { useDeleteMasterOrder } from '../../../hooks/query/masterData/useDeleteMasterOrder';
import { useModifierMasterOrder } from '../../../hooks/query/masterData/useModifierMasterOrder';
import { IMasterOrderData } from '../../../types/masterData/masterOrder';
import { Button } from '../../Button';
import { AlertModal } from '../../Modal/AlertModal';
import PositionPickerModal from '../../Modal/PositionPickerModal';
import { Stack } from '../../Stack';
import { Text } from '../../Typography';
import TableComponent from '../index';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from '../util/IndeterminateCheckbox';
import TableSelectManager from '../util/TableSelectManager';

const columnHelper = createColumnHelper<IMasterOrderData>();

interface MasterOrderTableProps {
  data?: IMasterOrderData[];
  selectedMasterOrder: IMasterOrderData[];
  setSelectedMasterOrder: Dispatch<SetStateAction<IMasterOrderData[]>>;
}

const MasterOrderTable = ({ data = [], selectedMasterOrder, setSelectedMasterOrder }: MasterOrderTableProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();
  const [isOpenPositionPickerModal, setIsOpenPositionPickerModal] = useState<boolean>(false);
  const [initPosition, setInitPosition] = useState<LatLng>({ lat: 0, lng: 0 });
  const [position, setPosition] = useState<LatLng>({ lat: 0, lng: 0 });
  const [positionPickerName, setPositionPickerName] = useState<string>('');
  const [positionPickerAddress, setPositionPickerAddress] = useState<string>('');
  const [positionPickerDetailAddress, setPositionPickerDetailAddress] = useState<string>('');
  const [positionPickerId, setPositionPickerId] = useState<number>(0);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isSuccessDeleteMasterOrderModal, setIsSuccessDeleteMasterOrderModal] = useState<boolean>(false);
  const [isSuccessModifierModal, setIsSuccessModifierModal] = useState<boolean>(false);

  const { mutate: mutateDeleteMasterOrder, isSuccess: isSuccessDeleteMasterOrder } = useDeleteMasterOrder();
  const { mutate: mutateModifierMasterOrder, isSuccess: isSuccessModifierMasterOrder } =
    useModifierMasterOrder(positionPickerId);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (initPosition.lat !== 0 && initPosition.lng !== 0) {
      setIsOpenPositionPickerModal(true);
    }
  }, [initPosition]);

  useEffect(() => {
    if (position.lat !== 0 && position.lng !== 0) {
      mutateModifierMasterOrder({
        coordinates: [position.lng, position.lat],
      });

      queryClient.invalidateQueries({ queryKey: [{ entity: 'get_master_order_list' }] });
    }
  }, [mutateModifierMasterOrder, position]);

  useEffect(() => {
    if (isSuccessDeleteMasterOrder) {
      queryClient.invalidateQueries({ queryKey: [{ entity: 'get_master_order_list' }] }).then(() => {
        setIsOpenDeleteModal(false);
        setIsSuccessDeleteMasterOrderModal(true);
      });
    }
  }, [isSuccessDeleteMasterOrder, queryClient]);

  useEffect(() => {
    if (isSuccessModifierMasterOrder) {
      setIsSuccessModifierModal(true);
      queryClient.invalidateQueries({ queryKey: [{ entity: 'get_master_order_list' }] });
    }
  }, [isSuccessModifierMasterOrder]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),
      columnHelper.accessor(row => row, {
        id: 'changeCoordinates',
        header: '',
        enableSorting: false,
        cell: info => {
          return (
            <Button
              variant="secondary"
              h={24}
              type="button"
              color="RG00"
              sx={{ padding: '2xp 10px' }}
              onClick={() => {
                console.log('here', info.getValue());
                const { consigneeName, coordinate, address, detailAddress, masterOrderId } = info.getValue();

                setInitPosition({
                  lat: coordinate.coordinates[1],
                  lng: coordinate.coordinates[0],
                });
                setPositionPickerName(consigneeName);
                setPositionPickerAddress(address);
                setPositionPickerDetailAddress(detailAddress);
                setPositionPickerId(masterOrderId);
              }}
            >
              <Text styleName="caption1" color="RC02" sx={{ width: 'max-content' }}>
                좌표변경
              </Text>
            </Button>
          );
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.team.name, {
        id: 'teamName',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.consigneeName, {
        id: 'consigneeName',
        header: '고객명',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.consigneePhone, {
        id: 'consigneePhone',
        header: '고객 연락처',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.address, {
        id: 'address',
        header: '주소',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.detailAddress, {
        id: 'detailAddress',
        header: '상세주소',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => (row.isCustomCoordinate ? 'Y' : 'N'), {
        id: 'isCustomCoordinate',
        header: '좌표 변경 여부',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.skills?.map(skill => skill.name).join(', '), {
        id: 'skills',
        header: '특수 조건',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.serviceVehicle, {
        id: 'serviceVehicle',
        header: '담당 드라이버 지정',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.serviceTime, {
        id: 'serviceTime',
        header: '예상 작업 소요 시간 (분)',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.desiredTimeStart, {
        id: 'desiredTimeStart',
        header: '희망 시간 (이후)',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.desiredTimeEnd, {
        id: 'desiredTimeEnd',
        header: '희망 시간 (이전)',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.note[0], {
        id: 'note1',
        header: '비고1',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.note[1], {
        id: 'note2',
        header: '비고2',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.note[2], {
        id: 'note3',
        header: '비고3',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.note[3], {
        id: 'note4',
        header: '비고4',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.note[4], {
        id: 'note5',
        header: '비고5',
        footer: info => info.column.id,
      }),
    ],
    [anchorIsOpen]
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnOrder,
      pagination,
      rowSelection,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    sortDescFirst: true,
  });

  useEffect(() => {
    let t: any = {};
    selectedMasterOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.masterOrderId === x.masterOrderId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [instance, selectedMasterOrder, setRowSelection]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedMasterOrder.filter(z => z.masterOrderId === y.masterOrderId).length === 0);
      setSelectedMasterOrder([...selectedMasterOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedMasterOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.masterOrderId !== s.original?.masterOrderId);
      });
      setSelectedMasterOrder(orders);
    }
  }

  return (
    <>
      <TableComponent<IInvolvedDriver>
        placeholder={
          <Text styleName="title2" color="RG05" align="center">
            불러올 데이터가 없습니다.
            <br />
            주문지를 추가해 주세요.
          </Text>
        }
        table={instance}
        isDataFetching={false}
        tableType="masterOrder"
        clearBottom
        sc={[
          <Button
            children="주문지 삭제"
            variant={'tertiary'}
            h={32}
            icon={[IcBin, { width: 16, height: 16 }]}
            disabled={!selectedMasterOrder.length}
            onClick={() => {
              setIsOpenDeleteModal(true);
            }}
          />,
        ]}
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}

      <AlertModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        type="DELETE"
        messages={
          <Stack spacing={8} direction="column">
            <Text styleName="body2" color="RC04">
              선택하신 주문지 {selectedMasterOrder.length} 개를 삭제하시겠습니까?
            </Text>
            <Text styleName="body2" color="RG02">
              삭제 이후 복구는 불가하며 다시 추가할 수 있습니다.
            </Text>
          </Stack>
        }
        buttonGroup={
          <Stack direction="row" spacing={20} sx={{ marginTop: '40px' }}>
            <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={() => setIsOpenDeleteModal(false)}>
              취소
            </Button>
            <Button
              variant="primary"
              h={40}
              color="RC02"
              w="100%"
              onClick={() => {
                mutateDeleteMasterOrder({
                  masterOrderIds: selectedMasterOrder.map(item => {
                    return item.masterOrderId;
                  }),
                });
              }}
            >
              삭제
            </Button>
          </Stack>
        }
      />

      <AlertModal
        isOpen={isSuccessDeleteMasterOrderModal}
        setIsOpen={setIsSuccessDeleteMasterOrderModal}
        type="SUCCESS"
        messages="주문지 삭제를 완료하였습니다."
        buttonName="확인"
        autoCloseTime={3000}
      />

      <AlertModal
        isOpen={isSuccessModifierModal}
        setIsOpen={setIsSuccessModifierModal}
        type="SUCCESS"
        messages="주문지의 좌표가 변경되었습니다."
        buttonName="확인"
        autoCloseTime={3000}
      />

      <PositionPickerModal
        isOpen={isOpenPositionPickerModal}
        setIsOpen={setIsOpenPositionPickerModal}
        initPosition={initPosition}
        savePosition={setPosition}
        name={positionPickerName}
        address={positionPickerAddress}
        detailAddress={positionPickerDetailAddress}
      />
    </>
  );
};

export { MasterOrderTable };
