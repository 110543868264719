import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from 'components/Table//util/IndeterminateCheckbox';
import { createColumnHelper } from '@tanstack/react-table';
import { Dispatch, useState } from 'react';
import StatusTag from 'pages/Setting/common/StatusTag';

import { Button } from 'components/Button';
import { Stack } from 'components/Stack';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcExchange } from 'constants/icon/ic_exchange.svg';
import { ReactComponent as IcMail } from 'constants/icon/ic_mail.svg';

import dayjs from 'dayjs';
import { isFalsy } from 'util/isFalsy';
import { updateModalType } from 'pages/Setting/organization/Manager/container';
interface IManagerTableColumns {
  _state: TMangerContentState;
  _dispatch: Dispatch<TMangerContentAction>;
}

export const ManagerTableColumns = (props: IManagerTableColumns) => {
  const {
    _dispatch,
    _state: { involvedSelectedList, invitedSelectedList, mode },
  } = props;

  const columnHelper = createColumnHelper<any>();
  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const isButtonDisable = mode === 'Involved' ? isFalsy(involvedSelectedList) : isFalsy(invitedSelectedList);

  const INVOLVEDFORM = {
    tableUnit: {
      totalCount: { text: '소속 매니저', unit: '명' },
      eachCount: { text: '매니저', unit: '명' },
    },
    columns: [
      columnHelper.display({
        id: 'involved_checkBox',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: '이름',
        footer: info => info.column.id,
        cell: info => {
          return (
            <Stack direction="row" spacing={6}>
              {info.row.original.roleId === 1 && <StatusTag text="오너" variant="small" />}
              {info.getValue()}
            </Stack>
          );
        },
      }),
      columnHelper.accessor(row => row.loginAccount, {
        id: 'status',
        header: '아이디(이메일)',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.teamName, {
        id: 'involved',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => dayjs(row.createdAt).format('YYYY.MM.DD'), {
        id: 'createdAt',
        header: '소속 일자',
        footer: info => info.column.id,
      }),
    ],
    headerContent: (
      <Stack direction="row" sx={{ height: '32px', gap: '12px' }}>
        <Button
          children={'소속 팀 변경'}
          variant={'tertiary'}
          h={32}
          icon={[IcExchange, { width: 16, height: 16 }]}
          disabled={isButtonDisable}
          onClick={() => updateModalType({ _dispatch, isOpen: true, type: 'ChangeTeam' })}
        />

        <Button
          children={'매니저 삭제'}
          variant={'tertiary'}
          h={32}
          icon={[IcBin, { width: 16, height: 16 }]}
          disabled={isButtonDisable}
          onClick={() => {
            const isOwner = involvedSelectedList?.some(user => user.roleId === 1);
            if (isOwner) return updateModalType({ _dispatch, isOpen: true, type: 'PreventToDeleteOwner' }); //오너인 매니저는 삭제할 수 없습니다.

            updateModalType({ _dispatch, isOpen: true, type: 'DeleteManager' });
          }}
        />
      </Stack>
    ),
  };

  const INVITEDFORM = {
    tableUnit: {
      totalCount: { text: '초대한 매니저', unit: '명' },
      eachCount: { text: '매니저', unit: '명' },
    },
    columns: [
      columnHelper.display({
        id: 'invited_checkBox',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),

      columnHelper.accessor(row => dayjs(row.createdAt).format('YYYY.MM.DD'), {
        id: 'createdAt',
        header: '초대 일자',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => dayjs(row.expiredAt).format('YYYY.MM.DD'), {
        id: 'expiredAt',
        header: '만료 일자',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.loginAccount, {
        id: 'loginAccount',
        header: '아이디(이메일)',
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.teamName, {
        id: 'teamName',
        header: '소속 팀',
        footer: info => info.column.id,
      }),
    ],
    headerContent: (
      <Stack direction="row" sx={{ height: '32px', gap: '12px' }}>
        <Button
          children={'소속 팀 변경'}
          variant={'tertiary'}
          h={32}
          icon={[IcExchange, { width: 16, height: 16 }]}
          disabled={isButtonDisable}
          onClick={() => updateModalType({ _dispatch, isOpen: true, type: 'ChangeTeam' })}
        />
        <Button
          children={'초대장 재전송'}
          variant={'tertiary'}
          h={32}
          icon={[IcMail, { width: 16, height: 16 }]}
          disabled={isButtonDisable}
          onClick={() => updateModalType({ _dispatch, isOpen: true, type: 'ReinviteManager' })}
        />
        <Button
          children={'초대 취소'}
          variant={'tertiary'}
          h={32}
          icon={[IcBin, { width: 16, height: 16 }]}
          disabled={isButtonDisable}
          onClick={() => updateModalType({ _dispatch, isOpen: true, type: 'CancelInvitation' })}
        />
      </Stack>
    ),
  };

  return { INVOLVEDFORM, INVITEDFORM, anchorIsOpen, setAnchorIsOpen, anchorPoint };
};
