import styled from 'styled-components';

export const ResetPasswordTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  align-item: left;
`;

export const ResetPasswordFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 386px;
  width: 100%;
`;
