import { ReactNode } from 'react';
import styled from 'styled-components';
import { CtrRow } from '../style';

import { useDraggable } from '@dnd-kit/core';

function DraggableRow({ children, id }: { children: ReactNode; id: string }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  return (
    <DraggableWrapper ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </DraggableWrapper>
  );
}

export default DraggableRow;

const DraggableWrapper = styled(CtrRow)`
  cursor: grabbing;
`;
