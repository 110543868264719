import { TRouteStatus } from 'constants/types';
import { useNavigate } from 'react-router-dom';

const useNavigateUtils = () => {
  const navigate = useNavigate();

  const viewingOnControlPage = (
    state: {
      driverId: number | undefined;
      orderId: number;
      routeId: number;
      targetPerformedDate: string;
    },
    pageStatus?: TRouteStatus
  ) => {
    navigate(`/manage/control/${pageStatus ?? ''}`, { state });
  };
  return { viewingOnControlPage };
};

export default useNavigateUtils;
