import styled, { css } from 'styled-components';

import { TStyleName, TThemeColor } from 'constants/types';
import { TButtonH, TButtonVariants, ButtonProps } from 'components/Button/button.interface';

const styleMapper = new Map<
  TButtonVariants,
  {
    color: TThemeColor;
    bgColor: TThemeColor;
    bgColorHover: TThemeColor;
    borderColor?: TThemeColor;
  }
>([
  ['primary', { color: 'RG00', bgColor: 'RC02', bgColorHover: 'RC01' }],
  ['secondary', { color: 'RC02', bgColor: 'RG00', bgColorHover: 'RC03_3', borderColor: 'RC02' }],
  ['tertiary', { color: 'RG03', bgColor: 'RG00', bgColorHover: 'RG07', borderColor: 'RG06' }],
  ['errored', { color: 'RC04', bgColor: 'RC04_2', bgColorHover: 'RC04_2', borderColor: 'RC04_2' }],
]);

const sizeMapper = new Map<
  TButtonH,
  {
    radius: number;
    padding: number;
    styleName: TStyleName;
    gap: number;
  }
>([
  [40, { styleName: 'body1', padding: 8, radius: 8, gap: 8 }],
  [32, { styleName: 'subheadline1', padding: 5, radius: 6, gap: 8 }],
  [24, { styleName: 'caption1', padding: 2, radius: 4, gap: 4 }],
  [20, { styleName: 'caption1', padding: 0, radius: 2, gap: 4 }],
  [16, { styleName: 'tooltip3', padding: 0, radius: 2, gap: 4 }],
]);

const baseStyle = css<ButtonProps>`
  border-radius: 8px;
  transition: all 0.3s;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme, unit, variant, fw, w, h, r, reverse, activated }) => ({
    width: fw ? '100%' : w ? (typeof w === 'number' ? `${w}${unit}` : w) : 'auto',
    height: `${h}px`,

    flexDirection: reverse ? 'row-reverse' : 'row',

    gap: `${sizeMapper.get(h)!.gap}${unit}`,
    padding: `${sizeMapper.get(h)!.padding}${unit} ${20 >= h ? '6px' : '10px'}`,
    borderRadius: r ? `${r}${unit}` : `${sizeMapper.get(h)!.radius}px`,

    color: theme.colors[styleMapper.get(variant)!.color],
    backgroundColor: theme.colors[styleMapper.get(variant)![activated ? 'bgColorHover' : 'bgColor']],
    border:
      styleMapper.get(variant)?.borderColor && `1px solid ${theme.colors[styleMapper.get(variant)!.borderColor!]}`,
    ':hover': {
      backgroundColor: theme.colors[styleMapper.get(variant)!.bgColorHover],
    },
    ':disabled': {
      color: `${theme.colors.RG04} !important`,
      backgroundColor: `${theme.colors.RG07} !important`,
      borderColor: `${theme.colors.RG06} !important`,

      cursor: 'not-allowed',

      '& > svg path': {
        fill: `${theme.colors.RG04} !important`,
      },
    },
  })}
  ${({ theme, h }) => theme.fontStyle[sizeMapper.get(h)!.styleName]}
`;

const Button = styled.button`
  ${baseStyle}
`;
export { Button };
