import { ReactNode, CSSProperties } from 'react';
import { useDroppable } from '@dnd-kit/core';

function DroppableWrapper({ children, id, sx }: { children: ReactNode; id: string; sx?: CSSProperties }) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div ref={setNodeRef} style={sx}>
      {children}
    </div>
  );
}

export default DroppableWrapper;
