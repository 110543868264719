import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';

type FetchCheckExistSkillNameResponse = {
  isExisting: boolean;
};

type Variables = {
  name: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchCheckExistSkillName>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.CHECK_EXIST_SKILL_NAME>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchCheckExistSkillName = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.get(`${endPoint}`, {
    searchParams: {
      name: variables.name,
    },
  }).json<FetchCheckExistSkillNameResponse>();

const useCheckExistSkillName = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.CHECK_EXIST_SKILL_NAME(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchCheckExistSkillName({
        variables: variables,
        ...MASTER_DATA_QUERIES.CHECK_EXIST_SKILL_NAME()[0],
      }),
    options
  );

export { fetchCheckExistSkillName, useCheckExistSkillName };
export type { FetchCheckExistSkillNameResponse };
