import { Stack } from 'components/Stack';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { DropdownItem } from './item';
import { DropdownContainer } from './style';

type DropdownItemType = {
  id: number;
  title: string;
  description?: string;
};

interface DropdownListProps {
  data: DropdownItemType[];
  setSelectedItem?: Dispatch<SetStateAction<DropdownItemType[]>>;
  onClick?: (data: DropdownItemType) => void;
  searchValue?: string;
  emptyContent?: (data: DropdownListProps['data']) => ReactNode;
}

export const DropdownList = ({ data, setSelectedItem, onClick, searchValue, emptyContent }: DropdownListProps) => {
  const filteredData = searchValue ? data.filter(item => item.title.includes(searchValue)) : data;

  return (
    <DropdownContainer>
      {filteredData.length ? (
        filteredData.map(item => (
          <DropdownItem
            key={`${item.id}`}
            title={item.title}
            description={item.description}
            onClick={() => {
              setSelectedItem?.(prev => [...prev, item]);
              onClick?.(item);
            }}
          />
        ))
      ) : (
        <Stack>{emptyContent?.(filteredData)}</Stack>
      )}
    </DropdownContainer>
  );
};

export type { DropdownItemType, DropdownListProps };
