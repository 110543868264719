import type { Dispatch } from 'react';
import { isFalsy } from 'util/isFalsy';
import { getTeamList } from 'pages/Setting/organization/Manager';
import { postInviteManager, postInspeetInvitationEmails } from 'pages/Setting/organization/Manager';
import { updateModalType } from 'pages/Setting/organization/Manager/container';
export const deleteInputedEmailByIndex = (index: number, dispatch: Dispatch<TInviteManagerAction>) => {
  dispatch({ type: 'DELETE_INSPECTED_EMAIL_LIST', payload: index });
};

export const convertKeyName = (type: 'total' | 'error' | 'success'): { text: string; css: string } => {
  switch (type) {
    case 'error':
      return { text: '오류', css: 'RC04' };
    case 'success':
      return { text: '정상', css: 'RC02' };
    default:
      return { text: '전체', css: 'RG02' };
  }
};

export const convertErrorAlertMessage = (type: TInviteManagerQueryErrorType) => {
  switch (type) {
    case 'InvalidLoginAccount':
      return '이메일 형식을 확인해주세요.';
    case 'AlreadyInvited':
      return '이미 초대된 계정입니다.';
    default:
      return '이미 가입된 계정입니다.';
  }
};

function convertErrorMessage(type: TInviteManagerQueryErrorType) {
  switch (type) {
    case 'InvalidLoginAccount':
      return '형식 오류 이메일';
    case 'AlreadyInvited':
      return '이미 초대된 이메일';
    default:
      return '이미 등록된 이메일';
  }
}

export const calculateCurrrnStatus = (inputedEmailList: TInviteManagerQueryResponse[]) => {
  const totalLoginAccount = inputedEmailList.length;
  const invalidLoginAccount = inputedEmailList.filter(email => email.type);
  const CountedError = invalidLoginAccount.length;
  const successEmail = totalLoginAccount - CountedError;
  let errorForm: undefined | { [key in TInviteManagerQueryErrorType]: { message: string; count: number } };
  let errorDescription: TInviteManagerError[] | undefined;

  if (invalidLoginAccount) {
    errorForm = {
      AlreadyJoined: { message: convertErrorMessage('AlreadyJoined'), count: 0 },
      AlreadyInvited: { message: convertErrorMessage('AlreadyInvited'), count: 0 },
      InvalidLoginAccount: { message: convertErrorMessage('InvalidLoginAccount'), count: 0 },
    };

    invalidLoginAccount.forEach(error => {
      const _type = error.type!;
      errorForm![_type].count = errorForm![_type].count + 1;
    });
  }

  if (errorForm !== undefined) {
    errorDescription = Object.keys(errorForm)
      .filter(key => errorForm![key as TInviteManagerQueryErrorType].count !== 0)
      .map(key => ({ ...errorForm![key as TInviteManagerQueryErrorType], type: key as TInviteManagerQueryErrorType }));
  }

  return {
    total: totalLoginAccount,
    success: successEmail === 0 ? undefined : successEmail,
    error: CountedError,
    errorDescription: errorDescription,
  };
};

export const _deleteAllErrorEmails = (_dispatch: Dispatch<TInviteManagerAction>) => {
  _dispatch({ type: 'DELETE_ERROR_EMAILS' });
};

export const _getTeamList = async (params: { _dispatch: Dispatch<TInviteManagerAction> }) => {
  const { _dispatch } = params;
  const response = await getTeamList();
  const teamList = response as TGetTeamListResponse;
  if (teamList) {
    const { data } = teamList;
    return _dispatch({ type: 'UPDATE_TEAM_LIST', payload: data });
  }
  return response;
};

export const updateTeamId = (params: { _dispatch: Dispatch<TInviteManagerAction>; teamId: number }) => {
  const { _dispatch, teamId } = params;
  _dispatch({ type: 'UPDATE_TEAM_ID', payload: teamId });
};

export const _postInviteManager = async (params: {
  _dispatch: Dispatch<TInviteManagerAction>;
  _state: TInviteManagerInitialState;
  parentDispatch: Dispatch<TMangerContentAction>;
  setIsLoading: (param: boolean) => void;
}) => {
  const {
    _state: { teamId, inspectedEmailList },
    _dispatch,
    parentDispatch,
    setIsLoading,
  } = params;

  const userList = inspectedEmailList?.map(list => list.loginAccount);
  if (teamId === null || userList === undefined) return;

  const response = await postInviteManager({ teamId, userList });

  if (response) {
    setIsLoading(false);

    _dispatch({ type: 'INITIALIZE_INSPECTED_LIST' });
    updateModalType({ _dispatch: parentDispatch, type: 'InviteManagerSuccess', isOpen: true, response });
  }
};

export const _postInspeetLoginAccount = async (params: {
  loginAccount: string;
  _dispatch: Dispatch<TInviteManagerAction>;
  element: HTMLElement | null;
}) => {
  const { loginAccount, _dispatch, element } = params;
  const _loginAccount = loginAccount.trim().replace(',', '');

  if (isFalsy(_loginAccount)) return;

  const payload: string[] = _loginAccount.split('\n').filter(Boolean);

  const response = await postInspeetInvitationEmails({ userList: payload });
  const inpectedList = response as TInviteManagerQueryResponse[];
  if (element?.innerText) {
    element.innerText = element.innerText.replace(loginAccount, '');
  }

  if (inpectedList) {
    updateInspectedList({ _dispatch, list: inpectedList });
    return;
  }

  return response;
};

export const editInputedEmail = async (
  changedEmail: string,
  index: number,
  _dispatch: Dispatch<TInviteManagerAction>,
  element: HTMLElement | null
) => {
  if (isFalsy(changedEmail)) return;

  const _email = changedEmail.trim().replace(',', '');

  if (isFalsy(_email)) return;

  const refetchLoginAccount: string[] = [_email.split('\n').filter(Boolean).join('')];

  const response = await postInspeetInvitationEmails({ userList: refetchLoginAccount });
  const inpectedList = response as TInviteManagerQueryResponse[];
  if (inpectedList) {
    if (element?.childNodes[2]) {
      const target = element.childNodes[2] as Element;
      target.innerHTML = '';
    }

    const [loginAccount] = inpectedList;
    _dispatch({ type: 'EDIT_INSPECTED_EMAIL', payload: { index, loginAccount } });
    return;
  }
};

export const inspectParams = (params: { _state: TInviteManagerInitialState }): boolean => {
  const {
    _state: { inspectedEmailList, teamId },
  } = params;
  const hasFalsy = [inspectedEmailList, teamId].some(target => isFalsy(target));
  if (hasFalsy) return false;

  const hasError = inspectedEmailList!.some(list => list.type);
  if (hasError) return false;
  return true;
};

function updateInspectedList(props: {
  _dispatch: Dispatch<TInviteManagerAction>;
  list: TInviteManagerQueryResponse[];
}) {
  const { _dispatch, list } = props;
  _dispatch({ type: 'UPDATE_INSPECTED_EMAIL_LIST', payload: list });
}

export const initializeInspectedList = (_dispatch: Dispatch<TInviteManagerAction>) =>
  _dispatch({ type: 'INITIALIZE_INSPECTED_LIST' });
