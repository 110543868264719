import type { CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';
import { forwardRef } from 'react';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';
import theme from 'constants/theme';
import { BeforeUnloadModal } from '../../../components/Modal/BeforeUnloadModal';

interface IMyPageSidebar {
  icon?: ReactNode;
  title: string;
  isModified?: boolean;
  setIsModified?: Dispatch<SetStateAction<boolean>>;
  callback?: Function;
  children: ReactNode;
  useHeaderButton?: boolean;
  hasError?: boolean;
  sx?: CSSProperties;
  disableModifiedModeButton?: boolean;
  hasBorder?: boolean;
  hasDeleteButton?: boolean;
}

export const MyPageSection = forwardRef<HTMLElement, IMyPageSidebar>(
  (
    {
      icon,
      title,
      callback,
      children,
      useHeaderButton = true,
      isModified = false,
      setIsModified,
      hasError,
      sx,
      disableModifiedModeButton = false,
      hasBorder = true,
      hasDeleteButton = false,
    },
    ref
  ) => {
    return (
      <section
        ref={ref}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          ...sx,
        }}
      >
        <header style={{ width: 'inherit', marginBottom: '20px' }}>
          <Stack justify="space-between" direction="row">
            <Stack direction="row" sx={{ gap: '10px' }}>
              {icon && icon}
              <Text styleName="title1" color="RG02">
                {title}
              </Text>
            </Stack>
            {useHeaderButton && (
              <span style={{ height: 'inherit' }}>
                {isModified ? (
                  <Stack direction="row" sx={{ gap: '12px' }}>
                    <Button
                      children={'취소'}
                      variant={'tertiary'}
                      w={80}
                      h={32}
                      onClick={() => setIsModified && setIsModified(false)}
                    />

                    <Button
                      children={'변경사항 저장'}
                      variant={'primary'}
                      w={132}
                      h={32}
                      disabled={hasError ? hasError : false}
                      onClick={() => callback && callback()}
                    />
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={20}>
                    {/*{hasDeleteButton &&*/}
                    {/*  paymentMyData?.name !== 'trial' &&*/}
                    {/*  paymentMyData?.paymentStatus === 'activated' && (*/}
                    {/*    <Button*/}
                    {/*      variant="tertiary"*/}
                    {/*      h={32}*/}
                    {/*      icon={[IcBin, { width: 16, height: 16 }]}*/}
                    {/*      onClick={() => {*/}
                    {/*        console.log('delete');*/}
                    {/*      }}*/}
                    {/*      sx={{ width: 'max-content' }}*/}
                    {/*    >*/}
                    {/*      삭제*/}
                    {/*    </Button>*/}
                    {/*  )}*/}
                    <Button
                      variant={'tertiary'}
                      w={132}
                      h={32}
                      disabled={disableModifiedModeButton ? disableModifiedModeButton : false}
                      onClick={() => setIsModified && setIsModified(true)}
                    >
                      정보 수정
                    </Button>
                  </Stack>
                )}
              </span>
            )}
          </Stack>
        </header>
        <div
          style={{
            borderRadius: '8px',
            border: hasBorder ? `1px solid ${theme.colors.RG06}` : '',
            width: '100%',
          }}
        >
          {children}
        </div>

        <BeforeUnloadModal
          when={isModified}
          message={['입력중인 정보가 있습니다.', '새로운 정보를 저장하지 않으시겠습니까?']}
        />
      </section>
    );
  }
);
