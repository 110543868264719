import { CSSProperties, useState } from 'react';
import type { UseFormRegister, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';

import * as S from 'pages/Setting/common/style';

import Selector from 'components/Selector';
import { ReactComponent as IcRemove } from 'constants/icon/input_remove.svg';
import { ReactComponent as IcMagnifyingGlass } from 'constants/icon/ic_magnifyingglass.svg';
import { ReactComponent as IcArrowDropDown } from 'constants/icon/ic_arrow_dropdown.svg';
import { isFalsy } from 'util/isFalsy';

interface INewFilter {
  sx?: CSSProperties;
  placeholder?: string;
  register: UseFormRegister<TFilterType>;
  setValue: UseFormSetValue<TFilterType>;
  getValues: UseFormGetValues<TFilterType>;
  callback: () => void;
  placeholderConvertor?: Function;
  content?: Array<{ [key in 'key' | 'value']: any }>;
  defaultValue?: string;
  options: { key: string; value: string }[];
}

export const SearchFilterbar = (props: INewFilter) => {
  const { placeholder, sx, options, defaultValue, setValue, register, callback, getValues, placeholderConvertor } =
    props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <S.FilterBox padding={10} style={sx}>
      <Stack name="default-filter-content-area" direction="row" spacing={12}>
        <S.FilterSearchbarWrapper className="filter-searchbar-wrapper" direction="row">
          <Selector
            disabledHover={true}
            sx={{ marginLeft: '6px', width: '140px', height: '30px', border: 'none' }}
            onOptionsClick={item => {
              setValue('searchItem', item.value);
              placeholderConvertor && setValue('placeholder', `${placeholderConvertor(item.value)}을 입력해주세요.`);
            }}
            icon={<IcArrowDropDown />}
            fullWidth
            options={options}
            defaultValue={defaultValue}
          />

          <S.SearchbarInputContainer spacing={4} direction="row" justify="space-between" isFilled={true}>
            <IcMagnifyingGlass
              className="spyglass"
              style={{
                width: isFocus === false ? '16px' : '0px',
                height: '16px',
                transition: `all 0.4s`,
              }}
            />

            <input
              {...register('keyword')}
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={() => {
                isFalsy(getValues('keyword')) && setIsFocus(false);
              }}
              onKeyUp={event => {
                if (event.code === 'enter') {
                  return callback();
                }
              }}
              placeholder={placeholder ? placeholder : ''}
            />

            {isFocus && (
              <IcRemove
                tabIndex={-1}
                className="removeBtn"
                style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                onClick={() => {
                  setValue('keyword', '');
                  setIsFocus(false);
                }}
              />
            )}
          </S.SearchbarInputContainer>
        </S.FilterSearchbarWrapper>

        <Button
          type="submit"
          variant="secondary"
          onClick={event => {
            event.preventDefault();
            callback();
          }}
          h={32}
        >
          조회하기
        </Button>
      </Stack>
    </S.FilterBox>
  );
};
