import styled from 'styled-components';

export const SignInCardItems = styled.div`
  max-width: 414px;
  margin-bottom: 60px;
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignInCheckBox = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  text-align: left;
`;

export const SignInCardBottom = styled.div`
  display: flex;
  padding-top: 49px;
`;
