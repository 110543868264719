import { useQueryClient } from '@tanstack/react-query';
import { AlertModal } from 'components/Modal/AlertModal';
import { FormTextarea } from 'components/Table/TeamManagementTable';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';

import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { useAddSkill } from 'hooks/query/masterData/useAddSkill';
import { useCheckExistSkillName } from 'hooks/query/masterData/useCheckExistSkillName';
import { FetchModifySkillResponse, useModifySkill } from 'hooks/query/masterData/useModifySkill';
import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import Divider from '../Divider';
import { Input } from '../Input';
import { Stack } from '../Stack';
import { Text } from '../Typography';
import Modal from './index';
import { genLabel } from './SingleOrderForm';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';

interface AddSkillModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isModify?: boolean;
  skillId?: number;
  name?: string;
  description?: string;
}

const AddSkillModal = ({ isOpen, setIsOpen, isModify, skillId, name, description }: AddSkillModalProps) => {
  const methods = useForm<{ name: string; description?: string }>({
    mode: 'onSubmit',
    values: {
      name: name ?? '',
      description: description ?? '',
    },
  });
  const { handleSubmit, setError, clearErrors, register, watch, reset, formState } = methods;
  const { mutate: mutateAddSkill, isSuccess: isSuccessAddSkill } = useAddSkill();
  const { mutate: mutateCheckExistSkillName, data: checkExistSkillNameData } = useCheckExistSkillName();

  const {
    data: modifySkillResponse,
    mutate: mutateModifySkill,
    isSuccess: isSuccessModifySkill,
  } = useModifySkill(skillId);

  const [isOpenSuccessAddSkillModal, setIsOpenSuccessAddSkillModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (checkExistSkillNameData) {
      if (checkExistSkillNameData?.isExisting) {
        setError('name', { message: '중복된 조건 이름입니다.' });
      } else {
        mutateAddSkill({
          name: methods.getValues('name'),
          description: methods.getValues('description'),
        });
      }
    }
  }, [checkExistSkillNameData]);

  useEffect(() => {
    if (isSuccessAddSkill || (isSuccessModifySkill && (modifySkillResponse as FetchModifySkillResponse)?.skillId)) {
      queryClient.invalidateQueries({ queryKey: [{ entity: 'get_multi_skill_list' }] }).then(() => {
        setIsOpenSuccessAddSkillModal(true);
      });
    }
  }, [isSuccessAddSkill, isSuccessModifySkill]);

  useEffect(() => {
    if ((modifySkillResponse as IErrorResponse)?.name === 'DuplicatedData') {
      setError('name', { message: '중복된 조건 이름입니다.' });
    }
  }, [modifySkillResponse]);

  return (
    <Modal plain width={540} isModalOpen={isOpen} setIsModalOpen={setIsOpen}>
      <OrderDetailModalHeader>
        <Stack direction="row" justify="space-between">
          <Text styleName="subheadline2">{isModify ? '특수 조건 수정하기' : '신규 특수 조건 추가하기'}</Text>
          <IcClose
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
      </OrderDetailModalHeader>
      <Divider color="RG06" />
      <form onSubmit={() => false}>
        <OrderDetailModalBody
          spacing={30}
          style={{
            padding: '30px 20px',
          }}
        >
          <Input
            label={genLabel({ text: '조건 이름 이름', isRequiredStart: true })}
            name="name"
            placeholder="20자 이내의 특수 조건 이름을 입력해 주세요."
            register={register}
            watch={watch}
            reset={reset}
            field
            styleName="subheadline2"
            variant="third"
            type="text"
            height={40}
            onChange={() => {
              clearErrors('name');
            }}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                e.preventDefault();
              }
            }}
            removeBtn={() => {
              methods.setValue('name', '');
            }}
            validation={{
              required: { value: true, message: '조건 이름은 필수 입력사항입니다.' },
              maxLength: { value: 20, message: '조건 이름은 20자 이내로 입력이 가능합니다.' },
              validate: (res: string) => {
                if (res.indexOf(' ') !== -1) return '띄어쓰기(공백)을 삭제하신 뒤 다시 시도해주세요.';

                return /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9.\-_()]+$/.test(res) || '특수문자는 .-_()만 사용하실 수 있습니다.';
              },
            }}
            errors={formState?.errors?.name}
            hasError={Boolean(formState?.errors?.name)}
          />
          <Stack spacing={6} align="start">
            {genLabel({ text: '조건 설명' })}
            <FormTextarea
              {...methods.register('description', {
                maxLength: { value: 200, message: '200자 이내로 입력해주세요.' },
              })}
              hasError={Boolean(formState?.errors?.description)}
              placeholder="조건의 설명을 입력해 주세요. 예시) 냉장 저상차량만 가능"
              rows={14}
              cols={10}
              wrap="soft"
              onKeyDown={(e: { currentTarget: { style: { height: string }; scrollHeight: number } }) => {
                e.currentTarget.style.height = '1px';
                e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
              }}
              onKeyUp={(e: { currentTarget: { style: { height: string }; scrollHeight: number } }) => {
                e.currentTarget.style.height = '1px';
                e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
              }}
            />
            {Boolean(formState?.errors?.description) && (
              <Text styleName="caption3" color="RC04">
                <ErrorMarking style={{ marginRight: '6px' }} />
                {formState?.errors?.description?.message}
              </Text>
            )}
          </Stack>
        </OrderDetailModalBody>
      </form>

      <Divider color="RG06" />
      <OrderDetailModalFooter>
        <Stack
          name="modal-footer"
          spacing={16}
          direction="row"
          align="center"
          justify="end"
          sx={{ padding: '2px 0 0 0' }}
        >
          <Button
            variant="tertiary"
            type="button"
            h={40}
            sx={{ width: '102px' }}
            onClick={() => {
              reset();
              setIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG03">
              취소
            </Text>
          </Button>
          <Button
            variant="primary"
            type="button"
            h={40}
            sx={{ width: '216px' }}
            onClick={handleSubmit(res => {
              isModify ? mutateModifySkill(res) : mutateCheckExistSkillName({ name: res.name });
            })}
          >
            <Text styleName="body1" color="RG00">
              {isModify ? '저장' : '추가'}하기
            </Text>
          </Button>
        </Stack>
      </OrderDetailModalFooter>

      <AlertModal
        isOpen={isOpenSuccessAddSkillModal}
        setIsOpen={setIsOpenSuccessAddSkillModal}
        type="SUCCESS"
        messages={`특수 조건 ${isModify ? '수정' : '등록'}을 완료하였습니다.`}
        buttonName="확인"
        autoCloseTime={3000}
        callback={() => {
          reset();
          setIsOpen(false);
        }}
      />
    </Modal>
  );
};

export { AddSkillModal };
export type { AddSkillModalProps };
