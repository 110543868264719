import { useCallback } from 'react';

import debounce from 'lodash.debounce';
import { UseFormReturn } from 'react-hook-form';
import { validateName } from 'util/validate/validateName';
import { validatePhoneNumber } from 'util/validate/validatePhoneNumber';
import { validateLoginAccount } from 'util/validate/validateLoginAccount';

const useFormUtils = (methods: UseFormReturn<any>) => {
  const mapper = (func: Function, message: string) => (str: unknown) => func(str) || message;

  // {
  //   return ()()
  // }

  const ivnm = {
    // init_validation_n_messages
    required: (target?: string) => {
      return { value: true, message: `${target ?? '해당 필드는'} 필수 입력사항입니다.` };
    },
    greater_than_0: (res: string) => (/^[0-9]+$/.test(res) && parseInt(res) > 0) || '최소 1이상의 정수를 입력해주세요.',
    validName: mapper(validateName, '이름은  2~20자 사이여야 합니다.'),
    validEmail: mapper(validateLoginAccount, '이메일 형식에 맞지 않습니다. 다시 입력해 주세요.'),
    validPhone: mapper(validatePhoneNumber, '올바른 휴대폰 번호 형식이 아닙니다.'),
  };

  const numberPatternOnChangeHandler = useCallback(
    debounce(function (e, key: string) {
      methods.setValue(key, e.target.value.replaceAll(/\D/g, ''), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }, 500),
    []
  );

  return {
    ivnm,
    numberPatternOnChangeHandler,
  };
};

export default useFormUtils;
