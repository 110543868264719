import { type DropdownItemType, DropdownList } from 'components/Dropdown/DropdownList';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IcSkill } from 'constants/icon/ic_skill.svg';
import { useGetTeamSkillList } from 'hooks/query/masterData/useGetTeamSkillList';
import { PlainTag } from 'pages/Setting/RouteArea';
import React, {
  type CSSProperties,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { Input } from '../Input';
import genLabel from '../Modal/common/genLabel';
import ModalIconHeader from '../Modal/common/ModalIconHeader';
import { DetailModiForm } from '../Modal/OrderDetailModal';
import { Stack } from '../Stack';
import { Highlight, Text } from '../Typography';

interface ModifiedMultiSkillsSectionProps {
  teamId?: number;
  isModified: boolean;
  selectedSkillIds?: number[];
  setSelectedSkillIds: Dispatch<SetStateAction<number[]>>;
  disabledEmptyContainer?: boolean;
  label?: ReactNode;
  handleClickInput?: () => void;
}

const ModifiedMultiSkillsSection = ({
  teamId,
  isModified,
  selectedSkillIds = [],
  setSelectedSkillIds,
  disabledEmptyContainer,
  label,
  handleClickInput,
}: ModifiedMultiSkillsSectionProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<DropdownItemType[]>([]);
  const [dropdownData, setDropdownData] = useState<DropdownItemType[]>([]);
  const { mutate: mutateTeamSkillList, data } = useGetTeamSkillList();
  const teamSkillListData = useMemo<DropdownItemType[]>(
    () =>
      data?.data.map(item => ({
        id: item.skillId,
        title: item.name,
        description: item.description,
      })) || [],
    [data]
  );

  const methods = useForm<{
    skiilsId: string[];
  }>({
    defaultValues: {
      skiilsId: [''],
    },
  });

  useEffect(() => {
    setSelectedSkills([]);
    setDropdownData(teamSkillListData ?? []);

    mutateTeamSkillList({
      teamId,
      searchItem: 'name',
    });
  }, [teamId]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target)) {
      setSkillPopupIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectorRef]);

  useEffect(() => {
    if (teamSkillListData) {
      const dropdown: DropdownItemType[] = [];
      const selected: DropdownItemType[] = [];

      selectedSkillIds?.forEach(id => {
        selected.push(...teamSkillListData.filter(item => item.id === id));
      });

      teamSkillListData.forEach(item => {
        if (selectedSkillIds?.indexOf(item.id) === -1) {
          dropdown.push(item);
        }
      });

      setDropdownData(dropdown);
      setSelectedSkills(selected);
    }
  }, [teamSkillListData]);

  useEffect(() => {
    setSkillPopupIsOpen(false);
    setDropdownData(teamSkillListData?.filter(d => !selectedSkills.map(item => item.id).includes(d.id)) ?? []);
    setSelectedSkillIds(selectedSkills.map(item => item.id));
  }, [selectedSkills]);

  return (
    <DetailModiForm
      title={
        <Stack direction="row" spacing={10}>
          <ModalIconHeader title="특수 조건 정보" icon={<IcSkill />} />
          <Text styleName="subheadline2">
            총&nbsp;
            <Highlight styleName="subheadline1" sx={{ textWrap: 'nowrap' } as CSSProperties}>
              {selectedSkills.length ?? 0}
            </Highlight>
            &nbsp;개
          </Text>
        </Stack>
      }
    >
      {isModified ? (
        <Stack align="start" direction="column" spacing={24}>
          <Stack sx={{ position: 'relative' }}>
            <Input
              name="skiilsId"
              label={genLabel({ text: '조건 선택' })}
              placeholder="적용을 원하는 특수 조건을 입력해 보세요"
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              variant="third"
              sx={{ gap: '4px' }}
              field
              height={40}
              errors={methods.formState?.errors?.skiilsId}
              hasError={Boolean(methods.formState?.errors?.skiilsId)}
              onChange={(e: any) => {
                setSkillPopupIsOpen(true);
                setSkillSearchValue(e.target.value);
              }}
              onClick={() => {
                handleClickInput?.();
                setSkillPopupIsOpen(true);
              }}
              rightArea={{
                rightContent: label,
                rightFunc: () => {
                  setSelectedSkills([]);
                  setDropdownData(teamSkillListData ?? []);
                },
              }}
            />
            <Stack sx={{ position: 'relative' }} ref={selectorRef}>
              {skillPopupIsOpen && (
                <DropdownList
                  data={dropdownData}
                  setSelectedItem={setSelectedSkills}
                  searchValue={skillSearchValue}
                  emptyContent={data =>
                    !teamSkillListData.length || data.length ? (
                      <Text styleName="caption2" color="RG02" sx={{ padding: '90px 10px' }}>
                        등록된 특수 조건이 없습니다.
                        <br />
                        {'[설정 > 기준 정보 관리 > 특수 조건 관리] 에서 특수 조건을 추가해 주세요.'}
                      </Text>
                    ) : (
                      <Text styleName="caption2" color="RG02" sx={{ padding: '8px 10px' }}>
                        불러올 데이터가 없습니다.
                      </Text>
                    )
                  }
                />
              )}
            </Stack>
          </Stack>
          {!disabledEmptyContainer && (
            <Stack direction="column" spacing={12}>
              <Stack direction="row" spacing={12} justify="space-between">
                <Text styleName="subheadline2" color="RG02">
                  선택한 조건
                </Text>
                {Boolean(selectedSkills.length) && (
                  <Button
                    variant="tertiary"
                    h={20}
                    sx={{ padding: '0 6px', borderRadius: '4px' }}
                    onClick={() => {
                      setSelectedSkills([]);
                      setDropdownData(teamSkillListData ?? []);
                    }}
                  >
                    <IcBin fill="#8F96A9" width={12} height={12} />
                    <Text styleName="caption1" color="RG03">
                      전체 삭제
                    </Text>
                  </Button>
                )}
              </Stack>
              {selectedSkills.length ? (
                <Stack direction="row" sx={{ columnGap: '6px', rowGap: '12px', flexWrap: 'wrap' }}>
                  {selectedSkills.map(item => (
                    <PlainTag key={item.id} style={{ padding: '0 0 0 6px' }}>
                      <Stack direction="row">
                        <Text styleName="caption2" color="RG02">
                          {item.title}
                        </Text>
                        <IcClose
                          width={12}
                          height={12}
                          onClick={e => {
                            setSelectedSkills(prev => prev.filter(d => d.id !== item.id));
                          }}
                        />
                      </Stack>
                    </PlainTag>
                  ))}
                </Stack>
              ) : (
                <Stack align="start" bg="RG08" padding={8} sx={{ borderRadius: '8px' }}>
                  <Text styleName="caption2" color="RG03">
                    선택된 특수 조건이 없습니다.
                  </Text>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack direction="row" spacing={6} sx={{ flexWrap: 'wrap' }}>
          {selectedSkills.length ? (
            selectedSkills.map(item => (
              <PlainTag key={item.id} style={{ padding: '0 6px' }}>
                <Stack direction="row">{item.title}</Stack>
              </PlainTag>
            ))
          ) : (
            <Stack bg="RG08" padding={8} direction="row" sx={{ borderRadius: '8px' }}>
              <Text styleName="caption2" color="RG03">
                선택된 특수 조건이 없습니다. 수정하기를 클릭해 조건을 추가할 수 있습니다.
              </Text>
            </Stack>
          )}
        </Stack>
      )}
    </DetailModiForm>
  );
};

export { ModifiedMultiSkillsSection };
