import { Stack } from '../../../components/Stack';
import { ReactComponent as IcBack } from 'constants/icon/ic_arrow_back.svg';
import { ReactComponent as IcDown } from 'constants/icon/ic_arrowdown.svg';
import { ReactComponent as IcBilling } from 'constants/icon/ic_billing.svg';
import { Button } from '../../../components/Button';
import { MyPageSection } from '../common';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { InitialForm } from '../../../components/ModifiableInfoForm/InitialForm';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { InitialInfoForm } from '../../../components/ModifiableInfoForm/modifiableInfoForm.interface';
import { handleDownloadPdf } from '../../../util/handleDownloadPdf';
import { PaymentTypeTag } from '../../../components/Tag/new/PaymentType';
import { PlanStatusTag } from '../../../components/Tag/new/PlanStatus';
import { useGetReceipt } from '../../../hooks/query/payment/useGetReceipt';
import { numberWithCommas } from '../../../util/numberWithCommas';
import dayjs from 'dayjs';
import { PaymentTypeUnion, PlanStatusUnion } from '../../../types/payment/union';

interface PaymentReceiptProps {
  receiptId: number;
  setReceiptId: Dispatch<SetStateAction<number>>;
}

const Section = styled.section`
  background-color: #ffffff;
  border: 1px solid ${theme.colors.RG06};
  border-radius: 8px;
  width: 100%;
`;

export const PaymentReceipt = ({ receiptId, setReceiptId }: PaymentReceiptProps) => {
  const receiptRef = useRef<HTMLDivElement>(null);
  const { data: receiptData } = useGetReceipt(receiptId);

  const priceData = useCallback<() => InitialInfoForm[]>(() => {
    const baseData = [
      {
        title: '청구 요금 상세',
        content: `${numberWithCommas(receiptData?.pricing.actualAmount)}원`,
        styleName: 'body1',
        sx: { backgroundColor: theme.colors.RC03_1 },
      },
      {
        title: '요금제',
        content: (
          <Stack spacing={10} direction="row">
            <PlanStatusTag text={receiptData?.pricing.name as PlanStatusUnion} />
            <PaymentTypeTag text={receiptData?.pricing.interval as PaymentTypeUnion} />
          </Stack>
        ),
        styleName: 'body2',
      },
      {
        title: '기본 요금(부가세 포함)',
        content: `${numberWithCommas(receiptData?.pricing.cost)}원`,
        styleName: 'body2',
      },
      {
        title: '할인 금액',
        content: `-${numberWithCommas(receiptData?.pricing.discountAmount)}원`,
        styleName: 'body2',
        contentColor: '#EC6649',
      },
      {
        title: '선납한 금액',
        content: `-${numberWithCommas(receiptData?.pricing.paidAmount)}원`,
        styleName: 'body2',
        contentColor: '#EC6649',
      },
      {
        title: '원단위 절사',
        content: `- ${receiptData?.pricing.floorOfWon}원`,
        styleName: 'body2',
      },
      {
        title: '총금액',
        content: `${numberWithCommas(receiptData?.pricing.actualAmount)}원`,
        styleName: 'body1',
      },
    ];

    const discountAmount = receiptData?.pricing?.discountAmount;
    const paidAmount = receiptData?.pricing?.paidAmount;
    const floorOfWon = receiptData?.pricing.floorOfWon;

    return baseData.filter(
      item =>
        !(
          (!discountAmount && item.title === '할인 금액') ||
          (!paidAmount && item.title === '선납한 금액') ||
          (!floorOfWon && item.title === '원단위 절사')
        )
    ) as InitialInfoForm[];
  }, [receiptData]);

  const userData = useCallback<() => InitialInfoForm[]>(
    () => [
      {
        title: '고객 정보',
        content: '',
        styleName: 'body1',
        sx: { backgroundColor: theme.colors.RC03_1 },
      },
      {
        title: '고객사',
        content: receiptData?.company.name,
      },
      {
        title: '고객명',
        content: receiptData?.company.manager.name,
      },
      {
        title: '이용 기간',
        content: `${dayjs(receiptData?.company.startDateOfUse).format('YYYY.MM.DD')} ~ ${dayjs(
          receiptData?.company.endDateOfUse
        ).format('YYYY.MM.DD')}`,
      },
      {
        title: '결제일',
        content: dayjs(receiptData?.company.paidDate).format('YYYY.MM.DD'),
      },
      {
        title: '결제 수단',
        content: receiptData?.company.payMethod,
      },
      {
        title: '명세서 번호',
        content: receiptData?.company.billCode,
      },
    ],
    [receiptData]
  );

  const providerData = useCallback<() => InitialInfoForm[]>(
    () => [
      {
        title: '공급자',
        content: receiptData?.supplier.name,
      },
      {
        title: '공급자 사업자 번호',
        content: receiptData?.supplier.businessNumber,
      },
    ],
    [receiptData]
  );

  return (
    <Stack align="start" spacing={40}>
      <Stack direction="row" justify="space-between">
        <Stack
          direction="row"
          spacing={8}
          sx={{ width: 'initial', display: 'inline-flex', cursor: 'pointer' }}
          onClick={() => {
            setReceiptId(0);
          }}
        >
          <IcBack fill="#8F96A9" />
          이전 결제 목록으로
        </Stack>
        <Button
          variant="tertiary"
          h={40}
          icon={[IcDown, { width: 16, height: 16 }]}
          sx={{ flexShrink: 0 }}
          onClick={() => {
            handleDownloadPdf(receiptRef, `루티_요금명세서_${dayjs(receiptData?.company.paidDate).format('YYYYMMDD')}`);
          }}
        >
          명세서 PDF 다운받기
        </Button>
      </Stack>
      <MyPageSection
        ref={receiptRef}
        title="요금명세서"
        icon={<IcBilling width={28} height={28} />}
        useHeaderButton={false}
        hasBorder={false}
      >
        <Stack align="start" sx={{ gap: '40px' }}>
          <Section>
            <InitialForm justify="space-between" data={priceData()} />
          </Section>
          <Section>
            <InitialForm data={userData()} />
          </Section>
          <Section>
            <InitialForm data={providerData()} />
          </Section>
        </Stack>
      </MyPageSection>
    </Stack>
  );
};
