import { DOWNLOAD_EXTENSION_TYPE } from '../constants/downloadExtensionType';

interface DownloadToArrayBufferProps {
  data: ArrayBuffer;
  fileName: string;
  extension: DownloadExtension;
}

const useDownload = () => {
  const downloadToArrayBuffer = ({ data, fileName, extension }: DownloadToArrayBufferProps) => {
    const blob = new Blob([data], {
      type: DOWNLOAD_EXTENSION_TYPE[extension],
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return {
    downloadToArrayBuffer,
  };
};

export { useDownload };
export type { DownloadToArrayBufferProps };
