import { ReactElement } from 'react';
import styled from 'styled-components';
import { Text } from 'components/Typography';
import { ReactComponent as New } from '../../../constants/icon/ic_order_new.svg';

interface CellProps {
  edited: string[];
  isSelected?: boolean;
  onClick?: () => void;
}

const CellWrapper = styled.div<CellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  cursor: pointer;
  width: 100%;
  height: 100%;

  background: ${({ theme, isSelected }) => isSelected && theme.colors.RC03_3};
  :hover {
    background: ${({ theme, isSelected }) => !isSelected && theme.colors.RC03_1};
  }
`;

const EditedCell = styled.div<CellProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 24px;
  margin: 0 auto;
  height: 24px;
  padding: 0 2px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.RC02};
  border: 1px solid ${({ theme }) => theme.colors.RG00};
  & > svg {
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: calc(50% + 4px);
  }
`;

const EditedChip = styled.div<CellProps>`
  & > svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`;

export default function ({ ...props }: CellProps): ReactElement {
  return (
    <CellWrapper {...props} onClick={props.onClick}>
      {props.edited.length === 0 ? (
        <EditedChip {...props}>
          <New />
        </EditedChip>
      ) : (
        <EditedCell {...props}>
          <Text styleName="tooltip3" color="RG00">
            +{props.edited.length > 999 ? 999 : props.edited.length}
          </Text>
          <New />
        </EditedCell>
      )}
    </CellWrapper>
  );
}
