import { ReactNode } from 'react';
import { DndContext, DragOverlay, MouseSensor, useSensor } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import styled from 'styled-components';
import { Text } from 'components/Typography';
import { useStore } from 'store';
import strings from 'util/Localization';

function DraggableWrapper({ children }: { children: ReactNode }) {
  const { selectedOrderIds } = useStore();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  return (
    <DndContext sensors={[mouseSensor]} modifiers={[snapCenterToCursor]}>
      {children}

      <DragOverlay dropAnimation={null} style={{ width: '0px', height: '0px', pointerEvents: 'none' }}>
        <OrderMoveOverlay>
          <OrderMoveItem>
            <Text styleName={'caption1'} color={'RG03'}>
              {strings.주문} {selectedOrderIds?.length}개 옮기기
            </Text>
          </OrderMoveItem>
        </OrderMoveOverlay>
      </DragOverlay>
    </DndContext>
  );
}

export default DraggableWrapper;

const OrderMoveOverlay = styled.div`
  padding-top: 55px;
  padding-left: 3px;
`;
const OrderMoveItem = styled.div`
  background: #f0f3f9;
  border: 1px solid #d8ddea;
  box-shadow: 0 4px 8px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  width: 238px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
