import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { ROUTE_QUERIES } from 'hooks/query/route/route.queries';
import { RouteDownloadVariables } from 'types/route/download';

interface UseMutationGeneric {
  TData: Awaited<ReturnType<typeof fetchDownloadRoute>>;
  TError: Error;
  TVariable: RouteDownloadVariables;
  TMutationContext: ReturnType<typeof ROUTE_QUERIES.DOWNLOAD_ROUTE>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
}

const fetchDownloadRoute = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).then(async response => ({
    data: await response.arrayBuffer(),
    title: decodeURIComponent(response.headers.get('Content-Disposition')?.split('filename=')[1] ?? ''),
  }));

const useDownloadRoute = <TContext = UseMutationGeneric['TData']>(
  isIncludesItems?: boolean,
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    isIncludesItems ? ROUTE_QUERIES.DOWNLOAD_ROUTE_ITEM() : ROUTE_QUERIES.DOWNLOAD_ROUTE(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchDownloadRoute({
        variables: variables,
        ...(isIncludesItems ? ROUTE_QUERIES.DOWNLOAD_ROUTE_ITEM()[0] : ROUTE_QUERIES.DOWNLOAD_ROUTE()[0]),
      }),
    options
  );

export { fetchDownloadRoute, useDownloadRoute };
