import { CSSProperties, ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as IcArrowDown } from 'constants/icon/ic_arrowdown.svg';
import { TStyleName, TThemeColor } from 'constants/types';

interface PrevProps extends ComponentPropsWithRef<'button'> {
  string: string;

  color?: TThemeColor;
  styleName?: TStyleName;

  gap?: number;
  height?: string;

  sx?: CSSProperties;
}

const Prev = ({ string, sx, ...rest }: PrevProps) => {
  return (
    <Button style={sx} {...rest} type="button">
      <IcArrowDown width={12} height={12} style={{ rotate: '90deg' }} /> {string}
    </Button>
  );
};

export default Prev;

const Button = styled.button<Omit<PrevProps, 'string'>>`
  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;

  flex-direction: row;

  padding: 0 6px;
  border-radius: 6px;
  ${({ theme, gap, height, color }) => ({
    gap: gap ?? 4,

    height: height ?? 'auto',

    color: color ? theme.colors[color] : theme.colors.RG02,
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,

    ':hover': {
      backgroundColor: theme.colors.RG07,
    },
  })}

  cursor: pointer;
  transition: all 0.3s;

  ${({ styleName, theme }) => (styleName ? theme.fontStyle[styleName] : theme.fontStyle.caption2)}
`;
