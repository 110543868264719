import styled from 'styled-components';

import { Stack } from 'components/Stack';
import { Highlight, Text } from 'components/Typography';
import { Button } from 'components/Button';

import theme from 'constants/theme';
import { ReactComponent as IcArrow } from 'constants/icon/icon_arrow_right.svg';
import { useGNBStore, useSettingStore } from '../../store';
import { useNavigate } from 'react-router';
import { useGetCheckCanTutorialRoute } from '../../hooks/query/tutorial/useCheckCanTutorialRoute';
import { useGetCheckCanTutorialMonitoring } from '../../hooks/query/tutorial/useCheckCanTutorialMonitoring';
import { useEffect } from 'react';

const v_ext: string = '.mp4';
const i_ext: string = '.png';
const schema: string = 'https://roouty-tms.s3.ap-northeast-2.amazonaws.com/tutorial/';

const NodeGenerator = (features: Array<{ [key in 'title' | 'url']: string }>) => {
  const Container = styled(Stack)`
    height: 100%;
    border-radius: 8px;
    ${({ theme }) => ({ backgroundColor: theme.colors.RG00 })}
  `;

  return (
    <Container align="start" justify="start" spacing={20} padding={20}>
      <Text styleName="title1">다음의 링크에서 자세한 기능을 보실 수 있습니다.</Text>
      {features.map(({ title, url }) => (
        <Stack direction="row" spacing={10}>
          <Text styleName="body1">{title}</Text>

          <a href={url} target={'_blank'} key={`link_${title}`} rel="noreferrer">
            <Button
              reverse
              icon={[IcArrow, { width: 16, height: 16, fill: theme.colors.RC02 }]}
              children={'바로가기'}
              variant="secondary"
              w={'max-content'}
              h={32}
            />
          </a>
        </Stack>
      ))}
    </Container>
  );
};

const Str = ({ children }: { children: React.ReactNode }) => (
  <Text align="start" styleName="body2">
    {children}
  </Text>
);

const Bold1 = ({ children }: { children: React.ReactNode }) => (
  <Highlight styleName="body1" color={'RC02'}>
    {children}
  </Highlight>
);

const Bold2 = ({ children }: { children: React.ReactNode }) => (
  <Highlight styleName="body1" color={'RC06'}>
    {children}
  </Highlight>
);

const Bold3 = ({ children }: { children: React.ReactNode }) => (
  <Highlight styleName="body1" color={'RG01'}>
    {children}
  </Highlight>
);

const useLandingChapter: () => ActionFunctions = () => {
  const { SETSETTINGOPEN } = useGNBStore();
  const { SETCURRENT } = useSettingStore();
  const navigate = useNavigate();

  const { data: checkCanTutorialRoute, refetch: refetchGetCheckCanTutorialRoute } = useGetCheckCanTutorialRoute();
  const { data: checkCanTutorialMonitoring, refetch: refetchGetCheckCanTutorialMonitoring } =
    useGetCheckCanTutorialMonitoring();

  useEffect(() => {
    refetchGetCheckCanTutorialRoute();
    refetchGetCheckCanTutorialMonitoring();
  }, []);

  return {
    driver: () => {
      SETCURRENT('driver');
      SETSETTINGOPEN(true);
    },
    manageOrderAuto: () => {
      navigate('/manage/order/auto');
      SETSETTINGOPEN(false);
    },
    manageRouteOptimize: () => {
      // 3-1 튜토리얼 배차 가능 여부
      if (!checkCanTutorialRoute?.canRoute) {
        return {
          isOpen: true,
          messages: ['배차에 필요한 주문 또는 드라이버가 등록되지 않았습니다.', '확인 후 재시도해 주세요.'],
        };
      }

      navigate('/manage/route/optimize');
      SETSETTINGOPEN(false);
    },
    monitoring: () => {
      // 4-1 튜토리얼 모니터링 가능 여부

      if (!checkCanTutorialMonitoring?.canMonitoring) {
        return {
          isOpen: true,
          messages: ['조회할 경로가 없습니다. 확인 후 재시도해주세요.'],
        };
      }

      navigate('/manage/control');
      SETSETTINGOPEN(false);
    },
  };
};

const chapters: TChapters = [
  {
    name: '드라이버 추가',
    desc: '주문을 배차할 드라이버를 초대합니다.',
    slide: [
      {
        str: (
          <Str>
            한 건 초대부터 알아볼까요? 우측 상단의 <Bold1>⚙️설정 아이콘</Bold1>을 클릭하신 뒤{' '}
            <Bold2>[드라이버 관리]</Bold2> 페이지로 이동해주세요.
            <br />
            <Bold1>드라이버 초대하기</Bold1> 버튼을 클릭하고 <Bold3>한 건 직접 초대</Bold3> 옵션을 선택해주세요.
          </Str>
        ),
        type: 'video',
        content: schema + '1_1' + v_ext,
        actionKey: 'driver',
        coachMarksImage: schema + '1_1' + i_ext,
      },
      {
        str: (
          <Str>
            초대할 드라이버의 기본 정보를 입력하신 뒤 <Bold1>초대하기</Bold1> 버튼을 클릭하시면{' '}
            <Bold3>입력하신 휴대폰 번호로 초대장 전송됩니다.</Bold3>
          </Str>
        ),
        type: 'video',
        content: schema + '1_2' + v_ext,
        coachMarksImage: schema + '1_2' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold3>
              드라이버가 <Bold2>[루티 드라이버]</Bold2> 앱을 설치
            </Bold3>
            하여 <Bold3>올바른 휴대폰 번호로 회원가입</Bold3>을 완료하였다면 로그인 시 관리자님이 보내신 초대장을 받게
            됩니다.
            <br />
            <Bold3>
              드라이버가 초대장에서 <Bold1>수락</Bold1> 버튼을 클릭
            </Bold3>
            하면 드라이버 초대가 완료됩니다.
          </Str>
        ),
        type: 'img',
        content: schema + '1_3' + i_ext,
        hideButton: true,
      },
      {
        str: '',
        type: 'node',
        content: NodeGenerator([
          {
            title: '드라이버 여러 건 엑셀로 초대',
            url: 'https://www.notion.so/wemeetplace/c47f5bcc7ed54955b36c430131b07351',
          },
          {
            title: '드라이버 작업 권역 등록',
            url: 'https://www.notion.so/wemeetplace/7f63b7c1a0924481a96bea25752f3f17',
          },
          {
            title: '드라이버 자동 도착 처리, 작업 완료 인증 사진, 임의 순서 변경 설정',
            url: 'https://www.notion.so/wemeetplace/6e935de2e10249558d45566a25021634',
          },
        ]),
      },
    ],
  },

  {
    name: '주문 등록',
    desc: '배차할 주문을 등록합니다.',
    slide: [
      {
        str: (
          <Str>
            주문을 한 건 등록해보겠습니다. <Bold2>{`[주문 관리 > 자동 최적화 배차]`}</Bold2> 페이지로 이동해주세요.
            <br />
            <Bold1>주문 추가</Bold1> 버튼을 클릭한 뒤 <Bold1>한 건 직접 추가</Bold1> 옵션을 선택해주세요.
          </Str>
        ),
        type: 'video',
        content: schema + '2_1' + v_ext,
        actionKey: 'manageOrderAuto',
        coachMarksImage: schema + '2_1' + i_ext,
      },
      {
        str: (
          <Str>
            주문 정보를 입력하신 뒤 <Bold1>추가하기</Bold1> 버튼을 선택하시면 입력하신 정보로 주문이 추가됩니다.
          </Str>
        ),
        type: 'video',
        content: schema + '2_2' + v_ext,
        coachMarksImage: schema + '2_2' + i_ext,
      },
      {
        str: '',
        type: 'node',
        content: NodeGenerator([
          {
            title: '자동 최적화 주문 여러 건 엑셀로 추가',
            url: 'https://www.notion.so/wemeetplace/abdb3aed94de4004a5a43749f92e1e93',
          },
          {
            title: '자동 최적화 주문 수정',
            url: 'https://www.notion.so/wemeetplace/cc2accc4d94d437ab64419a0039137b9',
          },
          {
            title: '주문 검색',
            url: 'https://www.notion.so/wemeetplace/8715a4edf3c84e4fb80bf652f3113d64',
          },
          {
            title: '주문 조회 항목 설정',
            url: 'https://www.notion.so/wemeetplace/600df23635de49c38c986774c592554d',
          },
          {
            title: '수동 배차 주문 등록',
            url: 'https://www.notion.so/wemeetplace/ac2a29aafa624118830c414a048a2dce',
          },
        ]),
      },
    ],
  },

  {
    name: '배차계획 생성',
    desc: '신규 배차 계획을 생성합니다.',
    slide: [
      {
        str: (
          <Str>
            <Bold2>[배차 계획]</Bold2>에서 <Bold1>자동 최적화 배차</Bold1> 옵션을 선택해주세요.
            <br />
            <Bold3>배차 옵션</Bold3>을 설정하고 배차할 <Bold3>주문</Bold3>과 주문을 작업할 <Bold3>드라이버</Bold3>를
            선택하신 뒤 <Bold1>배차 결과 보기</Bold1> 버튼을 클릭하시면 경로가 생성됩니다.
          </Str>
        ),
        type: 'video',
        content: schema + '3_1' + v_ext,
        actionKey: 'manageRouteOptimize',
        coachMarksImage: schema + '3_1' + i_ext,
      },
      {
        str: (
          <Str>
            전체 경로 뿐 아니라 <Bold3>드라이버를 클릭</Bold3>하여 <Bold3>지도에서 드라이버의 개별 경로</Bold3>를
            확인합니다.
            <br />
            <Bold3>마우스 오른쪽 버튼을 클릭</Bold3>하시면 드라이버에게 배차된 <Bold3>주문을 표로 확인</Bold3>할 수
            있습니다.
            <br />
            <Bold3>배차 옵션별 배차 결과를 비교</Bold3>하고자 하는 경우 우측 상단 <Bold1>배차 옵션 설정</Bold1> 버튼을
            클릭하여 옵션을 변경한 뒤 경로를 재계산해주세요.
          </Str>
        ),
        type: 'video',
        content: schema + '3_2' + v_ext,
        coachMarksImage: schema + '3_2' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold3>경로를 수정</Bold3>하고 싶으신 경우에는{' '}
            <Bold3>변경할 주문을 클릭한 뒤 원하는 곳으로 끌어 이동</Bold3>해주세요.
            <br />
            <Bold1>자동 최적화</Bold1> 모드에서는 드라이버에게 재할당한 주문을 토대로{' '}
            <Bold3>해당 드라이버의 전체 경로를 다시 최적화</Bold3>합니다.
          </Str>
        ),
        type: 'video',
        content: schema + '3_3' + v_ext,
        coachMarksImage: schema + '3_3' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold1>임의 순서 변경</Bold1> 모드에서는 작업 희망 시간, 담당 드라이버 지정 여부, 배차 우선순위, 용적량을
            무시하고 <Bold3>주문을 원하는 곳에 배차</Bold3>할 수 있습니다.
            <br />
            단, 임의 순서 변경 시 <Bold3>최적화하지 않고 설정한 순서대로 경로를 생성</Bold3>합니다.
          </Str>
        ),
        type: 'video',
        content: schema + '3_4' + v_ext,
        coachMarksImage: schema + '3_4' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold1>임시결과</Bold1> 탭을 클릭하여 <Bold3>재계산한 경로를 비교</Bold3>할 수 있습니다. 가장 만족스러운
            결과 탭을 클릭하여 <Bold1>배차 계획 저장</Bold1> 버튼을 눌러 경로를 확정해주세요.
          </Str>
        ),
        type: 'video',
        content: schema + '3_5' + v_ext,
        coachMarksImage: schema + '3_5' + i_ext,
      },
      {
        str: '',
        type: 'node',
        content: NodeGenerator([
          {
            title: '자동 최적화 배차 계획 생성',
            url: 'https://www.notion.so/wemeetplace/13e953eccc164e8f85e3414465de9907',
          },
          {
            title: '자동 최적화 배차 계획 임시결과 확인',
            url: 'https://www.notion.so/wemeetplace/1939d9d002424a56b734cfd25f9d7097',
          },
          {
            title: '자동 최적화 배차 계획 임시결과 수정',
            url: 'https://www.notion.so/wemeetplace/e4236fdc55754efe8543bbb20ee5be2a',
          },
          {
            title: '수동 배차 주문 등록',
            url: 'https://www.notion.so/wemeetplace/ac2a29aafa624118830c414a048a2dce',
          },
          {
            title: '수동 배차 계획 생성',
            url: 'https://www.notion.so/wemeetplace/d8b7f0db7cb14dd496304ced4641172a',
          },
        ]),
      },
    ],
  },

  {
    name: '배차 조회',
    desc: '배차 현황을 조회합니다.',
    slide: [
      {
        str: (
          <Str>
            <Bold2>[모니터링]</Bold2> 페이지로 이동하여 배차 계획에서 저장한 <Bold3>경로를 확인</Bold3>합니다.
          </Str>
        ),
        type: 'video',
        content: schema + '4_1' + v_ext,
        actionKey: 'monitoring',
        coachMarksImage: schema + '4_1' + i_ext,
      },
      {
        str: (
          <Str>
            배차에 포함된 드라이버 중 <Bold3>한 명이라도 주행을 시작</Bold3>하면 경로가 <Bold3>주행중</Bold3> 상태로
            변경됩니다.
          </Str>
        ),
        type: 'video',
        content: schema + '4_2' + v_ext,
        coachMarksImage: schema + '4_2' + i_ext,
      },
      {
        str: (
          <Str>
            배차에 포함된 <Bold3>모든 드라이버가 주행을 마치면</Bold3> 경로가 <Bold3>주행종료</Bold3>로 변경됩니다.
          </Str>
        ),
        type: 'video',
        content: schema + '4_3' + v_ext,
        coachMarksImage: schema + '4_3' + i_ext,
      },
      {
        str: (
          <Str>
            주행종료가 된 경로의 <Bold3>주행 결과를 보고서에서 모아볼 수 있습니다.</Bold3> (유료 기능)
            <br />
            주행일, 드라이버 등 측정 기준을 선택하고 조회할 측정 항목을 선택하여 나만의 대시보드를 구성해보세요.
          </Str>
        ),
        type: 'video',
        content: schema + '4_4' + v_ext,
        coachMarksImage: schema + '4_4' + i_ext,
      },

      {
        str: '',
        type: 'node',
        content: NodeGenerator([
          {
            title: '배차 조회',
            url: 'https://www.notion.so/wemeetplace/8a6b0c8489df49f99112efa69f1882f1',
          },
          {
            title: '배차 취소',
            url: 'https://www.notion.so/wemeetplace/173061be9b7c444291a455922407df53',
          },
          {
            title: '강제 주행종료',
            url: 'https://www.notion.so/wemeetplace/1b07f4660cc643b690ae52421764ad54',
          },
          {
            title: '주행종료 주행 결과 다운로드',
            url: 'https://www.notion.so/wemeetplace/dc43016cdd7846f7902e0692c3d32ad9',
          },
          {
            title: '전체 보고서 확인',
            url: 'https://www.notion.so/wemeetplace/64aa87ace88245178562bedc38a10823',
          },
          {
            title: '보고서 다운로드',
            url: 'https://www.notion.so/wemeetplace/f71846e41f2449369a94718c3fc9d470',
          },
        ]),
      },
    ],
  },

  {
    name: '기타 주요 기능',
    slide: [
      {
        str: (
          <Str>
            우측 상단 계정 아이디를 클릭하시면 마이페이지로 이동할 수 있는 드롭다운이 나타납니다.
            <br />
            <Bold2>[기본 정보 관리]</Bold2>에서 <Bold3>계정의 기본 정보</Bold3>를 관리하고{' '}
            <Bold2>[결제 정보 관리]</Bold2>에서 <Bold3>요금제를 구독</Bold3>하고 <Bold3>사용량을 확인</Bold3>합니다.
          </Str>
        ),
        type: 'video',
        content: schema + '5_1' + v_ext,
        actionKey: 'manageOrderAuto',
        coachMarksImage: schema + '5_1' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold2>[팀 관리]</Bold2>에서 <Bold3>팀을 추가</Bold3>하고 <Bold3>팀 정보를 수정</Bold3>합니다. (유료 기능)
            <br />
            <Bold2>[매니저 관리]</Bold2>에서는 오너와 마찬가지로 팀을 운영할 <Bold3>매니저를 초대하고 관리</Bold3>
            합니다. (유료 기능)
          </Str>
        ),
        type: 'video',
        content: schema + '5_2' + v_ext,
        coachMarksImage: schema + '5_2' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold2>[권역 관리]</Bold2>에서는 드라이버가 주행할 <Bold3>권역을 생성하고 수정</Bold3>할 수 있습니다.
          </Str>
        ),
        type: 'video',
        content: schema + '5_3' + v_ext,
        coachMarksImage: schema + '5_3' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold2>[드라이버 앱 설정]</Bold2> 페이지에서 <Bold3>드라이버앱에 영향을 미치는 기능</Bold3>을 켜고 끌 수
            있습니다. (유료 기능)
          </Str>
        ),
        type: 'video',
        content: schema + '5_4' + v_ext,
        coachMarksImage: schema + '5_4' + i_ext,
      },
      {
        str: (
          <Str>
            <Bold1>물음표</Bold1> 버튼을 클릭하시면 <Bold2>[이용안내]</Bold2> 페이지로 이동합니다.
            <Bold2>[이용안내]</Bold2> 페이지에서 루티의 <Bold3>모든 기능 설명을 확인</Bold3>하실 수 있습니다.
          </Str>
        ),
        type: 'video',
        content: schema + '5_5' + v_ext,
        coachMarksImage: schema + '5_5' + i_ext,
      },
      {
        str: '',
        type: 'node',
        content: NodeGenerator([
          {
            title: '사용량 확인',
            url: 'https://www.notion.so/wemeetplace/9e5c55c5eb5446229a0fcc269a47c2cf',
          },
          {
            title: '요금제 업그레이드',
            url: 'https://www.notion.so/wemeetplace/c9a262fcef364b0da135df2db2d7ae85',
          },
          {
            title: '팀 생성',
            url: 'https://www.notion.so/wemeetplace/18382d733196423fa098f2fbaa5be42e',
          },
          {
            title: '매니저 초대',
            url: 'https://www.notion.so/wemeetplace/329ca00b79724180a44bf4fa98402089',
          },
          {
            title: '권역 등록',
            url: 'https://www.notion.so/wemeetplace/7f63b7c1a0924481a96bea25752f3f17',
          },
          {
            title: '드라이버 앱 설정',
            url: 'https://www.notion.so/wemeetplace/6e935de2e10249558d45566a25021634',
          },
        ]),
      },
    ],
  },

  // {
  //   name: '',
  //   desc: '',
  //   slide: [
  //     {
  //       str: '',
  //       type: 'video',
  //       content: schema + '3_1' + v_ext,
  //     },
  //     {
  //       str: '',
  //       type: 'video',
  //       content: schema + '3_2' + v_ext,
  //     },
  //     {
  //       str: '',
  //       type: 'video',
  //       content: schema + '3_3' + v_ext,
  //     },
  //     {
  //       str: '',
  //       type: 'video',
  //       content: schema + '3_4' + v_ext,
  //     },
  //     {
  //       str: '',
  //       type: 'video',
  //       content: schema + '3_5' + v_ext,
  //     },
  //     {
  //       str: '',
  //       type: 'node',
  //       content: NodeGenerator([
  //         {
  //           title: '',
  //           url: '',
  //         },
  //         {
  //           title: '',
  //           url: '',
  //         },
  //         {
  //           title: '',
  //           url: '',
  //         },
  //         {
  //           title: '',
  //           url: '',
  //         },
  //         {
  //           title: '',
  //           url: '',
  //         },
  //       ]),
  //     },
  //   ],
  // },
];

export { chapters, useLandingChapter };
