import cloneDeep from 'lodash.clonedeep';

const accountInitialState: TMyPageAccountOption = {
  modal: { type: '' as TAccountModalType, isOpen: false },
  userInfo: null,
  companyInfo: null,
  industryList: null,
};

const accountReducerAction = (state: TMyPageAccountOption, action: TMyPageAccountAction) => {
  const _state = cloneDeep(state);
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_ACCOUNT_DATA':
      const isUserInfo = payload.loginAccount;
      if (isUserInfo) {
        _state.userInfo = payload;
      } else {
        _state.companyInfo = payload;
      }
      return _state;
    case 'UPDATE_MODAL':
      _state.modal = payload;
      return _state;

    case 'INITIALIZE_STATE':
      return accountInitialState;
    case 'UPDATE_INDUSTRY_LIST':
      _state.industryList = payload;
      return _state;
    default:
      return _state;
  }
};

export { accountInitialState, accountReducerAction };
