import { Text } from 'components/Typography';
import * as S from 'components/Table/basicTableStyle';

interface IBasicTable {
  tableWidth?: string;
  tableConfig: { text: string; key: string; itemWidth?: string }[];
  data: { [key: string]: any }[];
}

export const BasicTable = (props: IBasicTable) => {
  const { tableConfig, data, tableWidth } = props;

  return (
    <div style={{ display: 'flex', maxWidth: tableWidth ? tableWidth : '500px' }}>
      <S.BasicTable>
        <table style={{ width: '100%' }}>
          <S.BasicTableHeader>
            <tr style={{ width: tableWidth ? tableWidth : '500px' }}>
              {tableConfig.map((head, index: number) => {
                return (
                  <S.BasicTableHeaderCell key={`${head.text}_${index}`} itemWidth={head.itemWidth}>
                    <Text styleName="caption1" color="RG03">
                      {head.text}
                    </Text>
                  </S.BasicTableHeaderCell>
                );
              })}
            </tr>
          </S.BasicTableHeader>

          <S.BasicTableBody>
            {data.map((table, index: number) => {
              return (
                <S.BasicTableRow key={`table_row_${index}`}>
                  {tableConfig.map((config, index: number) => {
                    const { key, itemWidth } = config;
                    return (
                      <S.BasicTableRowCell key={`table_row_cell_${index}`} itemWidth={itemWidth}>
                        {typeof table[key] === 'object' ? table[key] : <Text styleName="caption2">{table[key]}</Text>}
                      </S.BasicTableRowCell>
                    );
                  })}
                </S.BasicTableRow>
              );
            })}
          </S.BasicTableBody>
        </table>
      </S.BasicTable>
    </div>
  );
};
