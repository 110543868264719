import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';
import { IMultiSkillData } from 'types/masterData/multiSkill';

type FetchGetTeamSkillListResponse = {
  totalCount: number;
  data: IMultiSkillData[];
};

type Variables = {
  teamId?: number;
  searchItem: 'name';
  keyword?: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchGetTeamSkillList>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MASTER_DATA_QUERIES.GET_TEAM_SKILL_LIST>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchGetTeamSkillList = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) => {
  const searchParams = {
    teamId: variables.teamId,
    searchItem: variables.searchItem,
    keyword: variables.keyword || '',
  };

  if (!variables.teamId) {
    delete searchParams.teamId;
  }

  return await API.get(`${endPoint}`, {
    searchParams: searchParams as Variables,
  }).json<FetchGetTeamSkillListResponse>();
};

const useGetTeamSkillList = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MASTER_DATA_QUERIES.GET_TEAM_SKILL_LIST(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchGetTeamSkillList({
        variables: variables,
        ...MASTER_DATA_QUERIES.GET_TEAM_SKILL_LIST()[0],
      }),
    options
  );

export { fetchGetTeamSkillList, useGetTeamSkillList };
export type { FetchGetTeamSkillListResponse };
