import { Tag } from '../index';
import { ColorMapper } from '../initialTag.interface';
import { PlanStatusUnion } from '../../../../types/payment/union';

interface PlanStatusTagProps {
  text?: PlanStatusUnion;
}

const colorMapper: ColorMapper<PlanStatusUnion> = {
  Lite: {
    text: 'Lite',
    color: 'RG03',
  },
  Standard: {
    text: 'Standard',
    color: 'RC10',
  },
  Pro: {
    text: 'Pro',
    color: 'RC02',
  },
  Enterprise: {
    text: 'Enterprise',
    color: 'RC11',
  },
  Free: {
    text: 'Free',
    color: 'RG03',
  },
};

export const PlanStatusTag = ({ text, ...props }: PlanStatusTagProps) => {
  if (!text) return <></>;

  return <Tag height={24} variant="secondary" color={colorMapper[text].color} text={colorMapper[text].text} />;
};
