import {
  DriverIdUnSelected,
  IControlRouteDriverList,
  IControlRouteDriverOrderList,
  IEqualOptimizeResponses,
  IGetControlRouteResponses,
  ILocationMqOnStore,
} from '../../constants/types';
import { Marker } from 'react-map-gl';
import { DynamicSvg } from '../DynamicSvg';
import { DynamicSvgPresets } from '../DynamicSvg/Presets';
import { createElement, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ColorPalette } from '../../constants/colorPalette';
import { Stack } from '../Stack';
import { Text } from '../Typography';
import theme from '../../constants/theme';
import Divider from '../Divider';
import { Tag } from '../Tag';
import { shipment_type } from '../../constants/commons';
import { validateControlOrderStatus, validateOrderPriority } from '../../util/validate/validateStatus';
import { ReactComponent as IcStops } from '../../constants/icon/ic_stops.svg';
import { ReactComponent as ToolTipBottom } from '../../constants/icon/bg_tooltip_bottom.svg';
import { ReactComponent as ToolTipLeft } from '../../constants/icon/bg_tooltip_left.svg';
import dayjs from 'dayjs';

import { ReactComponent as MyLocationTemp } from '../../constants/icon/m_mylocation_temp.svg';
import { ReactComponent as MyLocation } from '../../constants/icon/m_mylocation.svg';
import { ReactComponent as Start } from '../../constants/icon/m_start.svg';
import { ReactComponent as End } from '../../constants/icon/m_end.svg';
import { ReactComponent as StartLarge } from '../../constants/icon/m_start_large.svg';
import { ReactComponent as EndLarge } from '../../constants/icon/m_end_large.svg';

import { ReactComponent as High } from 'constants/icon/ic_priority_high.svg';
import { ReactComponent as Medium } from 'constants/icon/ic_priority_medium.svg';
import { ReactComponent as Low } from 'constants/icon/ic_priority_low.svg';

export const StartEndLocationMarker = ({
  data,
  selectedDriver,
}: {
  data?: IGetControlRouteResponses | IEqualOptimizeResponses;
  selectedDriver: number;
}) => {
  if (data && data.driverList.length > 0) {
    let sCoordinates: [number, number][] = [];
    let eCoordinates: [number, number][] = [];

    let resultMarkers: JSX.Element[] = [];
    data.driverList.forEach(driver => {
      if (selectedDriver === DriverIdUnSelected || driver.driverId === selectedDriver) {
        if (driver.vehicle && driver.vehicle.startCoordinate)
          sCoordinates.push(driver.vehicle.startCoordinate.coordinates);
        if (driver.vehicle && driver.vehicle.endCoordinate) eCoordinates.push(driver.vehicle.endCoordinate.coordinates);
      }
    });

    eCoordinates.forEach((coord, index) => {
      resultMarkers.push(
        <Marker longitude={coord[0]} latitude={coord[1]} key={`marker-end-${index}-${coord[0]}=${coord[1]}`}>
          <MyLocationMarkerContainer>
            {selectedDriver === DriverIdUnSelected ? <End /> : <EndLarge />}
          </MyLocationMarkerContainer>
        </Marker>
      );
    });

    sCoordinates.forEach((coord, index) => {
      resultMarkers.push(
        <Marker longitude={coord[0]} latitude={coord[1]} key={`marker-start-${index}-${coord[0]}=${coord[1]}`}>
          <MyLocationMarkerContainer>
            {selectedDriver === DriverIdUnSelected ? <Start /> : <StartLarge />}
          </MyLocationMarkerContainer>
        </Marker>
      );
    });

    return resultMarkers;
  } else return null;
};

export interface ICoordIncludedOrder {
  orderInfo: IControlRouteDriverOrderList;
  driverInfo: IControlRouteDriverList;
  overlapped: IControlRouteDriverOrderList[];
  markerStatus: string;
}

export function parseCoordIncludedOrder({ data, selectedDriver }: { data: any; selectedDriver: number }) {
  let coordIncludedOrderList: ICoordIncludedOrder[] = [];

  data.driverList
    .filter((driver: any) => driver.driverId === selectedDriver)
    .forEach((driver: any) => {
      if (driver.orderList && driver.orderList.length > 0) {
        driver.orderList.forEach((order: any) => {
          if (order.coordinate && order.coordinate.coordinates) {
            let overlappedArr = coordIncludedOrderList.filter(x => {
              let bLoc = x.orderInfo.coordinate!.coordinates;
              let cLoc = order.coordinate!.coordinates;

              return bLoc[0] === cLoc[0] && bLoc[1] === cLoc[1];
            });
            let isOverlapped = overlappedArr.length > 0;

            if (!isOverlapped) {
              coordIncludedOrderList.push({
                orderInfo: order,
                driverInfo: driver,
                overlapped: [order],
                markerStatus: order.status ?? 'scheduled',
              });
            } else {
              overlappedArr[0].overlapped.push(order);

              let isCompleted = true;
              let isSkipped = false;
              let hasProcessing = false;
              overlappedArr[0].overlapped.forEach(olOrder => {
                if (olOrder.status === 'skipped') isSkipped = true;
                if (olOrder.status !== 'completed') isCompleted = false;
                if (olOrder.status === 'processing' || olOrder.status === 'scheduled') hasProcessing = true;
              });

              if (isSkipped && !hasProcessing) overlappedArr[0].markerStatus = 'skipped';
              else if (isCompleted) overlappedArr[0].markerStatus = 'completed';
              else overlappedArr[0].markerStatus = 'processing';
            }
          }
        });
      }
    });
  return coordIncludedOrderList;
}

export const MyLocationMarker = ({
  selectedDriver,
  locationMqResponse,
  showTag,
  data,
  selectDriver,
}: {
  selectedDriver: number;
  locationMqResponse: ILocationMqOnStore[];
  showTag: boolean;
  data?: IGetControlRouteResponses;
  selectDriver: (driverId: number) => void;
}) => {
  return locationMqResponse.map(x => {
    if (
      selectedDriver === DriverIdUnSelected ||
      (selectedDriver !== DriverIdUnSelected && parseInt(x.driverId) === selectedDriver)
    ) {
      let names = (data?.driverList || []).filter(d => d.driverId === parseInt(x.driverId));
      let status: 'old' | 'realtime' = 'old';

      if (x.lastLocationDate) {
        let seconds = (new Date().getTime() - x.lastLocationDate.getTime()) / 1000;
        if (seconds < 15) status = 'realtime';
      }

      return (
        <>
          <Marker
            longitude={x.locationInfo.location.coordinates[0]}
            latitude={x.locationInfo.location.coordinates[1]}
            rotation={x.bearing ?? 0}
          >
            <MyLocationMarkerContainer>
              {status === 'old' ? <MyLocationTemp /> : <MyLocation />}
            </MyLocationMarkerContainer>
          </Marker>
          {showTag && (
            <Marker
              longitude={x.locationInfo.location.coordinates[0]}
              latitude={x.locationInfo.location.coordinates[1]}
            >
              <div
                className="marker-driver"
                style={{ top: '-50px' }}
                onClick={() => {
                  if (x.driverId && parseInt(x.driverId)) selectDriver(parseInt(x.driverId));
                }}
              >
                <div className="marker-highlight" style={{ background: ColorPalette.getColor(parseInt(x.driverId)) }} />
                <Text styleName="caption2" color="RG02">
                  {names.length > 0 && names[0].name}
                </Text>
              </div>
            </Marker>
          )}
        </>
      );
    } else return null;
  });
};
export const OrdersMarker = ({
  data,
  selectedDriver,
  focusedMarker,
  setFocusedMarker,
  mapMoveTo,
  selectTable,
}: {
  data?: IGetControlRouteResponses;
  selectedDriver: number;
  focusedMarker: ICoordIncludedOrder | null;
  setFocusedMarker: Dispatch<SetStateAction<ICoordIncludedOrder | null>>;
  mapMoveTo: (pos: [number, number]) => void;
  selectTable: (orderId: number) => void;
}) => {
  if (data && data.driverList.length > 0 && selectedDriver !== DriverIdUnSelected) {
    // let markerInfo: IControlRouteDriverOrderList[] = [];
    let resultMarkers: JSX.Element[] = [];

    let coordIncludedOrderList = parseCoordIncludedOrder({ data, selectedDriver });

    coordIncludedOrderList.forEach(order => {
      let lastIndex = (order.overlapped.at(-1)?.route.routeIndex ?? -1).toString();

      resultMarkers.push(
        <Marker
          longitude={order.orderInfo.coordinate!.coordinates[0]}
          latitude={order.orderInfo.coordinate!.coordinates[1]}
          style={{
            zIndex:
              focusedMarker?.orderInfo.orderId === order.orderInfo.orderId
                ? 4
                : order.driverInfo.driverId === selectedDriver
                ? 3
                : 1,
          }}
          key={`order-marker-${order.orderInfo.orderId}`}
        >
          {focusedMarker?.orderInfo.orderId === order.orderInfo.orderId && (
            <MarkerContainer isLarge={true}>
              <DynamicSvg
                info={{
                  ...DynamicSvgPresets.orderSelected,
                  text: lastIndex,
                  borderColor: 'rgba(37, 40, 47, 0.2)',
                  chip: {
                    stacked: order.overlapped.length > 1,
                    completed: false,
                    skill: !!order.orderInfo.skill,
                    time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                    pickup: order.orderInfo.shipmentType === 'pickup',
                    delivery: false,
                  },
                }}
              />
            </MarkerContainer>
          )}
          {focusedMarker?.orderInfo.orderId !== order.orderInfo.orderId && (
            <MarkerContainer isLarge={false}>
              {(order.markerStatus === 'processing' ||
                order.markerStatus === 'scheduled' ||
                order.markerStatus === 'arrived') && (
                <DynamicSvg
                  useNewerOutline={true}
                  info={{
                    markerType: 'order',
                    markerColor: ColorPalette.getColor(order.driverInfo.driverId),
                    markerSize: 'normal',
                    borderColor: 'rgba(37, 40, 47, 0.2)',
                    text: lastIndex,
                    chip: {
                      stacked: order.overlapped.length > 1,
                      completed: false,
                      skill: !!order.orderInfo.skill,
                      time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                      pickup: order.orderInfo.shipmentType === 'pickup',
                      delivery: false,
                    },
                  }}
                />
              )}
              {order.markerStatus === 'skipped' && (
                <DynamicSvg
                  useNewerOutline={true}
                  info={{
                    ...DynamicSvgPresets.skip,
                    chip: {
                      stacked: order.overlapped.length > 1,
                    },
                  }}
                />
              )}
              {order.markerStatus === 'completed' && (
                <DynamicSvg
                  useNewerOutline={true}
                  info={{
                    markerType: 'order',
                    markerColor: '#FFFFFF',
                    markerSize: 'normal',
                    borderColor: ColorPalette.getColor(order.driverInfo.driverId),
                    textColor: ColorPalette.getColor(order.driverInfo.driverId),
                    text: lastIndex,
                    chip: {
                      stacked: order.overlapped.length > 1,
                    },
                  }}
                />
              )}
            </MarkerContainer>
          )}
          <MarkerClickListener
            onClick={e => {
              e.stopPropagation();
              // if (order.overlapped.length > 1) {
              if (focusedMarker?.orderInfo.orderId === order.orderInfo.orderId) {
                setFocusedMarker(null);
                selectTable(0);
              } else {
                if (order.orderInfo.orderId) selectTable(order.orderInfo.orderId);
                setFocusedMarker(order);
              }
              // }
              if (order.orderInfo.coordinate) mapMoveTo(order.orderInfo.coordinate.coordinates);
            }}
          />
        </Marker>
      );
    });

    return resultMarkers;
  } else return null;
};

export const FocusedMarkerInfoWindow = ({
  order,
  showDetail,
  selectTable,
}: {
  order: ICoordIncludedOrder | null;
  showDetail: (orderId: number) => void;
  selectTable: (orderId: number) => void;
}) => {
  if (!order) return null;

  return (
    <Marker
      longitude={order.orderInfo.coordinate!.coordinates[0]}
      latitude={order.orderInfo.coordinate!.coordinates[1]}
      style={{ zIndex: 4 }}
      onClick={e => {
        e.originalEvent.stopPropagation();
      }}
    >
      <MarkerContainer isLarge={false}>
        <ToolTipContainer>
          <Stack spacing={6} direction={'row'} sx={{ padding: '9px 9px 4px 9px' }}>
            <IcStops />
            <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
              {order?.orderInfo.address}
            </Text>
          </Stack>
          {order?.overlapped.map((item, index) => {
            let { key, name } = validateControlOrderStatus(item.status as any) ?? { key: 'key', name: 'name' };
            let priority = validateOrderPriority(item.priority as any) ?? { key: 'key', name: 'name' };
            return (
              <Stack
                key={`overlapped-${key + index}-marker-info`}
                sx={{
                  padding: '4px 8px 6px 8px',
                  background: index % 2 === 0 ? theme.colors.RG07 : theme.colors.RG00,
                  cursor: 'pointer',
                }}
                spacing={4}
                onClick={() => {
                  item && item.orderId && selectTable(item.orderId);
                }}
              >
                <Stack spacing={6} direction={'row'} align={'start'}>
                  <Text styleName={'tooltip1'} color={'RC02'} sx={{ fontFamily: 'Pretendard' }}>
                    {`${item.route && item.route.routeIndex}`.padStart(2, '0')}
                  </Text>
                  <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                    {item.detailAddress || '-'}
                  </Text>
                </Stack>
                <Stack
                  spacing={6}
                  divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                  align={'center'}
                  justify={'start'}
                  direction={'row'}
                >
                  <Stack align={'center'} direction={'row'} spacing={6} sx={{ width: 'fit-content' }}>
                    <Tag
                      outline
                      useTooltip
                      active={false}
                      styleName="tooltip1"
                      status={key as any}
                      sx={{
                        pointerEvents: 'none',
                        minWidth: '45px',
                        width: '45px',
                        padding: 0,
                        fontFamily: 'Pretendard',
                        borderRadius: '4px',
                      }}
                    >
                      {name}
                    </Tag>
                    {createElement(shipment_type.find(d => d.value === item.shipmentType)?.icon['ff'] ?? 'div', {
                      width: 12,
                      height: 12,
                    })}
                    {priority.name === '높음' ? <High width={12} height={12} /> : null}
                    {priority.name === '보통' ? <Medium width={14} height={12} /> : null}
                    {priority.name === '낮음' ? <Low width={12} height={12} /> : null}
                  </Stack>
                  <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                    도착예정 {item.route && dayjs(item.route.eta).format('HH : mm')}
                  </Text>

                  <Stack
                    sx={{
                      background: theme.colors.RG00,
                      border: `1px solid ${theme.colors.RG06}`,
                      width: '45px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      if (item && item.orderId) {
                        selectTable(item.orderId);
                        showDetail(item.orderId);
                      }
                    }}
                  >
                    <Text styleName={'tooltip1'} color={'RG03'} sx={{ fontFamily: 'Pretendard' }}>
                      상세보기
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </ToolTipContainer>
        <ToolTipBottom style={{ position: 'absolute', bottom: '69px', left: '-8px' }} />
      </MarkerContainer>
    </Marker>
  );
};

export const FocusedPolylineInfoWindow = ({
  feature,
  selectDriver,
}: {
  feature: any[] | null;
  selectDriver: (driverId: number) => void;
}) => {
  if (feature === null || feature.length === 0) return null;

  function getSortedArray(arr: any[]) {
    let sortedArr = arr.sort(function (a, b) {
      return a.properties.driverId > b.properties.driverId ? 1 : a.properties.driverId < b.properties.driverId ? -1 : 0;
    });
    return sortedArr;
  }

  return (
    <Marker
      longitude={feature[0].coord.lng}
      latitude={feature[0].coord.lat}
      style={{ zIndex: 4 }}
      onClick={e => {
        e.originalEvent.stopPropagation();
      }}
    >
      <MarkerContainer isLarge={false}>
        <PolyToolTipContainer>
          {[
            ...getSortedArray(feature.filter(x => x.properties.driverStatus === 'running')),
            ...getSortedArray(feature.filter(x => x.properties.driverStatus === 'completed')),
            ...getSortedArray(feature.filter(x => x.properties.driverStatus === 'waiting')),
          ].map((item, index) => {
            return (
              <Stack
                key={`overlapped-polyline-${index}-info`}
                sx={{
                  padding: '7px 11px 7px 11px',
                  background: index % 2 === 1 ? theme.colors.RG07 : theme.colors.RG00,
                  cursor: 'pointer',
                }}
                direction={'column'}
              >
                <Stack align={'center'} justify={'start'} direction={'row'}>
                  <div
                    style={{
                      width: '11.2px',
                      height: '11.2px',
                      marginRight: '6.4px',
                      borderRadius: '2px',
                      background: ColorPalette.getColor(item.properties.driverId),
                    }}
                  />
                  <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                    {item.properties.driverName}
                  </Text>
                  <div style={{ flex: 1 }} />
                  <Stack
                    sx={{
                      background: theme.colors.RC03_1,
                      width: '56px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      selectDriver(item.properties.driverId);
                    }}
                  >
                    <Text styleName={'tooltip1'} color={'RC02'} sx={{ fontFamily: 'Pretendard' }}>
                      자세히 보기
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </PolyToolTipContainer>
        <ToolTipLeft style={{ position: 'absolute', bottom: '-9px', left: '13px' }} />
      </MarkerContainer>
    </Marker>
  );
};
const MarkerContainer = styled.div<{ isLarge: boolean }>`
  width: 0;
  height: 0;
  position: relative;
  pointer-events: none;
  & > svg {
    position: absolute;
    bottom: 0;
    left: ${({ isLarge }) => (isLarge ? '-22px' : '-16.5px')};
  }
`;

const MyLocationMarkerContainer = styled.div`
  width: 0;
  height: 0;
  position: relative;
  pointer-events: none;
  & > svg {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
`;

const MarkerClickListener = styled.div`
  position: absolute;
  bottom: 0;
  left: -14px;
  width: 28px;
  height: 38px;
  cursor: pointer;
  z-index: 3;
  pointer-events: auto;
`;
const ToolTipContainer = styled(Stack)`
  background: white;
  position: absolute;
  bottom: 80px;
  min-width: max-content;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #d8ddea;
  box-shadow: 0 8px 20px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 8px;
  pointer-events: auto;
`;

const PolyToolTipContainer = styled(Stack)`
  background: white;
  position: absolute;
  left: 20px;
  transform: translateY(-13px);
  min-width: max-content;
  border: 1px solid #d8ddea;
  box-shadow: 0 8px 20px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 222px;
  pointer-events: auto;
`;
