import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useForm, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { Stack } from 'components/Stack';
import { validateBusinessNumber, validateInputBusinessNumber } from 'auth/signUp/corporateTypeForm/container';

import { Text } from 'components/Typography';
import { Input } from 'components/Input';
import { Button } from 'components/Button/legacy_index';
import { LoadingModal } from 'components/Modal/LoadingModal';

import { BusinessNumberModal } from 'auth/signUp/corporateTypeForm/BusinessNumberModal';
import { useStore } from 'store';
import { ReactComponent as SuccessMarking } from 'constants/icon/ic_success_alert_circle.svg';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { removeString } from 'auth/util';

import * as S from 'pages/MyPage/Account/style';

interface IBusinessNumberInput {
  updateCallback: Function;
  businessNumber: string;
  setError?: UseFormSetError<any>;
  clearErrors?: UseFormClearErrors<any>;
}

export const BusinessNumberInput = (props: IBusinessNumberInput) => {
  const { updateCallback, businessNumber, setError, clearErrors } = props;
  const { isLoading, setIsLoading } = useStore();

  const [response, setResponse] = useState<TBusinessNumberStateType>('');
  const [businessNumberList, setBusinessNumberList] = useState<TBusinessNumberList[]>([
    { loginAccount: 'empty', name: '' },
  ]);
  const [isActiveButton, setIsActiveButton] = useState(false);

  let defaultValues = { first: '', second: '', third: '' };
  if (businessNumber) {
    const [first, second, third] = businessNumber.split('-');
    defaultValues = { first: first, second: second, third: third };
  }

  const { register, getValues, setValue, reset } = useForm<TBusinessNumberInput>({ defaultValues });

  useEffect(() => {
    if (response === 'reset') {
      reset();
      setResponse('');
      setIsActiveButton(false);
    }

    if (response) {
      const { first, second, third } = getValues();
      const payload = `${first}-${second}-${third}`;
      setIsActiveButton(false);
      updateCallback(payload);
      if (response === 'success') {
        clearErrors && clearErrors();
      }

      return;
    }
  }, [response]);

  useEffect(() => {
    if (businessNumber) return;
    Object.keys(getValues()).forEach(key => setValue(key, ''));
  }, []);

  return (
    <Stack justify="center" sx={{ maxWidth: '508px' }}>
      <LoadingModal isLoading={isLoading} text={'잠시만 기다려 주세요.'} />
      <BusinessNumberModal
        isOpen={response === 'modal'}
        setIsOpen={setResponse}
        businessNumberList={businessNumberList}
      />

      <S.businessNumberInput>
        <Input
          maxLength="3"
          name="first"
          type="text"
          variant={'fifth'}
          register={register}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('first', removeString(event.target.value));
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              setIsActiveButton(false);
              setError && setError('businessNumber', { message: '사업자 등록번호 변경' });
            }

            const firstGroup = getValues().first;
            if (firstGroup === null) return;
            if (firstGroup.length === 3) {
              const secondInput = document.querySelector('input[name="second"]') as HTMLElement;
              secondInput.focus();
              validateInputBusinessNumber(getValues, setIsActiveButton, businessNumber);
            }
          }}
        />
        <Text styleName="subheadline2" color="RG04" sx={{ margin: 'auto 6.5px' }}>
          -
        </Text>
        <Input
          maxLength="2"
          name="second"
          type="text"
          variant={'fifth'}
          register={register}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('second', removeString(event.target.value));
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              setIsActiveButton(false);
              setError && setError('businessNumber', { message: '사업자 등록번호 변경' });
            }
            const secondGroup = getValues().second;
            if (secondGroup === null) return;
            if (secondGroup.length === 2) {
              const secondInput = document.querySelector('input[name="third"]') as HTMLElement;
              secondInput.focus();
              validateInputBusinessNumber(getValues, setIsActiveButton, businessNumber);
            }
          }}
        />
        <Text styleName="subheadline2" color="RG04" sx={{ margin: 'auto 6.5px' }}>
          -
        </Text>
        <Input
          name="third"
          type="text"
          maxLength="5"
          variant={'fifth'}
          register={register}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('third', removeString(event.target.value));
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              setIsActiveButton(false);
              setError && setError('businessNumber', { message: '사업자 등록번호 변경' });
            }

            const thirdGroup = getValues().third!;
            if (thirdGroup === null) return;
            if (thirdGroup.length === 5) {
              const thirdInput = document.querySelector('input[name="third"]') as HTMLElement;
              thirdInput.blur();
              validateInputBusinessNumber(getValues, setIsActiveButton, businessNumber);
              return;
            }
          }}
        />
        <Button
          styleName="body1"
          color="RG00"
          variant="default"
          type="button"
          height={48}
          sx={{ marginLeft: '20px', minWidth: '183px' }}
          disabled={isActiveButton === false}
          onClick={() => {
            setIsLoading(true);
            validateBusinessNumber(getValues, setResponse, setBusinessNumberList, setIsLoading, setError!);
          }}
        >
          사업자 등록 번호 중복 체크
        </Button>
      </S.businessNumberInput>

      {response === 'success' && (
        <Text styleName="caption3" color="RC02" sx={{ alignSelf: 'start', marginTop: '10px' }}>
          <SuccessMarking style={{ marginRight: '6px', width: '12px', height: '12px' }} />
          등록번호 확인을 완료하였습니다.
        </Text>
      )}
      {response === 'error' && (
        <Text styleName="caption3" color="RC04" sx={{ alignSelf: 'start', marginTop: '10px' }}>
          <ErrorMarking style={{ marginRight: '6px', width: '12px', height: '12px' }} />
          유효하지 않은 사업자 등록번호 입니다.
        </Text>
      )}
    </Stack>
  );
};
