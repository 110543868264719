import { useQuery } from '@tanstack/react-query';
import { getControlRouteListByDate, getOptimizeDriver } from 'api';
import { Button } from 'components/Button';
import Divider from 'components/Divider';

import { Stack } from 'components/Stack';
import DriverSelectTable from 'components/Table/RoutePlanSetup/DriverSelectTable';
import { Text } from 'components/Typography';

import { ReactComponent as IcFilter } from 'constants/icon/ic_filter.svg';
import theme from 'constants/theme';
import { IOptimizeDriverUserList, TOperationType } from 'constants/types';
import dayjs from 'dayjs';
import { FilterBox, FilterSearchbar } from 'pages/OrderManagePage';
import { QueueSelectorContainer, QueueSelectorWrapper } from 'pages/Report/utils/ReportFilter';
import { ReportFilterContent } from 'pages/Report/utils/ReportFilterContent';

import { FormValues } from 'pages/RoutePlanSetupPage';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

export type TFilterRouteStatus = undefined | 'activated' | 'processing' | 'completed' | 'none';
type TOperationExtendType = TOperationType | undefined;
type TSearchKey = 'driverName' | 'licenseNumber' | 'areaName' | 'skill';
type TAvailableFilterContent<T> = {
  key: string;
  value: T;
  isSelected: boolean;
};
export type TRoutePlanDriverSelectFilterFormValues = {
  routeStatus: TFilterRouteStatus;
  operationType: TOperationExtendType;
  keyword: string | undefined;
  searchItem: TSearchKey;
};

const DriverSelect = ({
  methods,
  selectedDrivers,
  setSelectedDrivers,
}: {
  methods: UseFormReturn<FormValues>;
  selectedDrivers: Array<{ driverId: number; vehicleId: number }>;
  setSelectedDrivers: Dispatch<SetStateAction<Array<{ driverId: number; vehicleId: number }>>>;
}) => {
  const filterFormDefaultValues: TRoutePlanDriverSelectFilterFormValues = {
    searchItem: 'driverName',
    keyword: '',
    routeStatus: undefined,
    operationType: undefined,
  };
  const filterMethods = useForm<TRoutePlanDriverSelectFilterFormValues>({
    mode: 'onSubmit',
    defaultValues: filterFormDefaultValues,
  });

  const [filterProps, setFilterProps] =
    useState<Partial<TRoutePlanDriverSelectFilterFormValues>>(filterFormDefaultValues);
  const [isFilterFolded, setIsFilterFolded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<IOptimizeDriverUserList>>([]);

  const [routeStatusFilter, setRouteStatusFilter] = useState<Array<TAvailableFilterContent<TFilterRouteStatus>>>([
    { key: '전체', value: undefined, isSelected: true },
    { key: '주행대기', value: 'activated', isSelected: false },
    { key: '주행중', value: 'processing', isSelected: false },
    { key: '주행종료', value: 'completed', isSelected: false },
    { key: '없음', value: 'none', isSelected: false },
  ]);
  const [operationTypeFilterContent, setOperationTypeFilterContent] = useState<
    Array<TAvailableFilterContent<TOperationExtendType>>
  >([
    {
      key: '전체',
      value: undefined,
      isSelected: true,
    },
    {
      key: '고정차',
      value: 'regular',
      isSelected: false,
    },
    {
      key: '용차',
      value: 'backup',
      isSelected: false,
    },
  ]);

  const searchKey: Array<{ key: string; value: TSearchKey; placeholder?: string }> = [
    {
      key: '드라이버',
      value: 'driverName',
      placeholder: '드라이버, 권역 등을 입력해 주세요',
    },
    {
      key: '권역',
      value: 'areaName',
      placeholder: '드라이버, 권역 등을 입력해 주세요',
    },
    {
      key: '특수 조건',
      value: 'skill',
      placeholder: '드라이버, 권역 등을 입력해 주세요',
    },
  ];

  const { isFetching } = useQuery(
    ['drivers', methods.getValues('performedDate'), filterProps],
    () => {
      let adsf: Partial<{
        routeStatus: any;
        searchItem: any;
        keyword: any;
        operationType: any;
      }> = {
        searchItem: filterProps.searchItem,
        keyword: filterProps.keyword,
        routeStatus: filterProps.routeStatus,
        operationType: filterProps.operationType,
      };

      if (!Boolean(adsf.keyword) || adsf.keyword === '') {
        delete adsf.searchItem;
        delete adsf.keyword;
      }

      // @ts-ignore
      Object.keys(adsf).map(key => (adsf[key] ? adsf[key] : delete adsf[key]));

      return getOptimizeDriver(dayjs(methods.getValues('performedDate')).format('YYYY-MM-DD'), adsf);
    },
    {
      onSuccess(res) {
        setTableData(res?.memberList ?? []);
      },

      enabled: Boolean(filterProps),
    }
  );
  const { data: dayRoutes } = useQuery([`${methods.getValues('performedDate')}dailyRoutes`], () =>
    getControlRouteListByDate(dayjs(methods.getValues('performedDate')).format('YYYY-MM-DD'))
  );

  const updateStatus = (name: string, content: Array<any>, index: number, setter: Function) => {
    let items = [...content];

    if (index === 0) {
      items[index].isSelected
        ? (items = items.map((d, index) => {
            return { ...d, isSelected: [false, true, ...new Array(content.length - 2).fill(false)][index] };
          }))
        : (items = items.map((d, index) => {
            return { ...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index] };
          }));

      setter(items);
    } else {
      items[index].isSelected = !items[index].isSelected;

      if (items.filter(x => x.isSelected).length === 0) return;

      let activated = items.slice(1, items.length).filter(x => x.isSelected).length;
      items[0].isSelected = activated === content.length - 1;

      if (items[0].isSelected) {
        for (let i = 1; i < items.length; i++)
          items = items.map((d, index) => {
            return { ...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index] };
          });
      }

      setter(items);
    }

    filterMethods.setValue(
      name as any,
      items
        .filter(d => d.isSelected)
        .map(d => d.value)
        .toString(),
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Stack spacing={8} align="start">
        <Text styleName="title1" color="RG02">
          드라이버 선택
        </Text>
        <Text styleName="caption2" color="RG03">
          배차할 드라이버를 체크하여 선택합니다.
        </Text>
      </Stack>
      <FilterBox
        padding={10}
        style={{
          borderRadius: 8,
          margin: '40px 0 30px 0',
          border: `1px solid ${theme.colors.RG07}`,
        }}
      >
        <Stack name="default-filter-content-area" direction="row" spacing={10}>
          <FilterSearchbar {...{ methods: filterMethods, content: searchKey }} />

          <Button
            type="button"
            variant="tertiary"
            h={32}
            activated={!isFilterFolded}
            onClick={() => setIsFilterFolded(prev => !prev)}
            icon={[IcFilter, { width: 16, height: 16 }]}
          >
            상세 필터
          </Button>
          <Button
            type="submit"
            variant="secondary"
            h={32}
            onClick={filterMethods.handleSubmit(res => {
              if (isFilterFolded) setIsFilterFolded(prev => !prev);

              setFilterProps(res);
            })}
            sx={{ padding: '0 16px' }}
          >
            조회하기
          </Button>
        </Stack>
        <Stack
          name="sub-filter-wrapper"
          sx={{
            margin: isFilterFolded ? '0' : '10px 0 0 0',
            minHeight: 'fit-content',
            maxHeight: isFilterFolded ? '0px' : '197px',
            transition: 'all .6s',
          }}
        >
          <Stack
            name="sub-filter-area"
            spacing={10}
            divider={<Divider color="RG06" />}
            align="start"
            sx={{
              opacity: isFilterFolded ? '0%' : '100%',
              visibility: isFilterFolded ? 'hidden' : 'visible',
              transition: 'opacity .5s ease',
            }}
          >
            <Fragment />
            <Stack name="filter-etc-area" spacing={20} align="start">
              <ReportFilterContent title="주행 상태" altInfo={` `} anchor="bottom" direction="row" gap={30}>
                <QueueSelectorWrapper direction={'row'} sx={{ width: '448px' }}>
                  {routeStatusFilter.map((d, index) => {
                    return (
                      <QueueSelectorContainer
                        key={d.key}
                        selected={d.isSelected}
                        onClick={() => {
                          updateStatus('routeStatus', routeStatusFilter, index, setRouteStatusFilter);
                        }}
                        len={7}
                      >
                        <Text
                          styleName={'caption2'}
                          color={
                            (filterMethods.getValues('routeStatus')?.includes(`${d.value}`) &&
                              !routeStatusFilter.find(k => k.key === '전체')?.isSelected) ||
                            (d.key === '전체' && d.isSelected)
                              ? 'RC02'
                              : 'RG02'
                          }
                        >
                          {d.key}
                        </Text>
                      </QueueSelectorContainer>
                    );
                  })}
                </QueueSelectorWrapper>
              </ReportFilterContent>
              <ReportFilterContent title="운영 유형" altInfo={` `} anchor="bottom" direction="row" gap={30}>
                <QueueSelectorWrapper direction={'row'} sx={{ width: '448px' }}>
                  {operationTypeFilterContent.map((d, index) => {
                    return (
                      <QueueSelectorContainer
                        key={d.key}
                        selected={d.isSelected}
                        onClick={() => {
                          updateStatus(
                            'operationType',
                            operationTypeFilterContent,
                            index,
                            setOperationTypeFilterContent
                          );
                        }}
                        len={7}
                      >
                        <Text
                          styleName={'caption2'}
                          color={
                            filterMethods
                              .getValues('operationType')
                              ?.includes(d.value as TOperationExtendType as string) ||
                            (d.key === '전체' && d.isSelected)
                              ? 'RC02'
                              : 'RG02'
                          }
                        >
                          {d.key}
                        </Text>
                      </QueueSelectorContainer>
                    );
                  })}
                </QueueSelectorWrapper>
              </ReportFilterContent>
            </Stack>
          </Stack>
        </Stack>
      </FilterBox>
      <DriverSelectTable data={tableData} {...{ methods, isFetching, selectedDrivers, setSelectedDrivers }} />
    </div>
  );
};

export default DriverSelect;
