import { Outlet } from 'react-router-dom';

import * as S from 'Layout/style';
import { Header, Footer } from 'Layout/elements';

interface IAuthProps {
  disableHeader?: boolean;
}

export const Layout = (props: IAuthProps) => {
  return (
    <S.Auth>
      {props.disableHeader || <Header />}
      <S.Body>
        <Outlet />
      </S.Body>
      <Footer />
    </S.Auth>
  );
};
