import { useMutation, useQuery } from '@tanstack/react-query';
import { getVehicleInfo, setBatchOrders } from 'api';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import { ModifiedMultiSkillsSection } from 'components/ModifiedSection/ModifiedMultiSkillsSection';
import DatePickerPopup from 'components/Popup/DatePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { SkillPicker } from 'components/Popup/SkillPicker';
import WorkTimeSelectPopup from 'components/Popup/WorkTimeSelect';
import { Stack } from 'components/Stack';
import { Swap } from 'components/Swap';
import { Highlight, Text } from 'components/Typography';
import { priority_type, shipment_type } from 'constants/commons';
import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';

import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';
import { IsetBatchOrdersProps } from 'constants/types';

import dayjs from 'dayjs';
import { useGetTeamSkillList } from 'hooks/query/masterData/useGetTeamSkillList';
import debounce from 'lodash.debounce';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import dateConverter from 'util/dateConverter';
import getDirtyValues from 'util/getDirtyValues';

import Modal from '.';
import strings from '../../util/Localization';
import { DetailModiForm } from './OrderDetailModal';

import { genLabel } from './SingleOrderForm';
import {
  BatchOrdersDetailTable,
  BatchOrdersDetailTableContainer,
  BatchOrdersDetailTd,
  BatchOrdersDetailTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from './style';
import MiniTimePicker from 'components/Popup/MiniTimePicker';

interface IForm {
  shipmentType: string;
  serviceTime: string;
  desiredDate: string;
  desiredTimeStart: string;
  desiredTimeEnd: string;
  serviceVehicle: string;
  priority: string;
  skills: number[];
}

const BatchOrdersModifyingModal = ({
  targetOrderList,
  batchOrdersModifyingModalIsOpen,
  setBatchOrdersModifyingModalIsOpen,
  refetcher,
}: {
  targetOrderList: Array<number>;
  batchOrdersModifyingModalIsOpen: boolean;
  setBatchOrdersModifyingModalIsOpen: Dispatch<SetStateAction<boolean>>;

  refetcher: Function;
}) => {
  const domain = window.localStorage.getItem('serviceDomain');

  const methods = useForm<IForm>({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      shipmentType: '',
      serviceTime: '',
      desiredDate: '',
      serviceVehicle: '',
      priority: '',
      skills: [] as number[],
    },
  });
  const { handleSubmit, control } = methods;
  const { dirtyFields } = useFormState({
    control,
  });

  const [initializer, setInitializer] = useState<{
    [key: string]: boolean;
  }>({
    desiredDate: false,
    serviceTime: false,
    serviceVehicle: false,
    skills: false,
  });

  const [modifyingConfirm, setModifyingConfirm] = useState<boolean>(false);
  const [modifiedContents, setModifiedContents] = useState<any[]>([]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [workTimePopupIsOpen, setWorkTimePopupIsOpen] = useState<boolean>(false);
  const [desiredDatePopupIsOpen, setDesiredDatePopupIsOpen] = useState<boolean>(false);
  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [startTimePickerPopupIsOpen, setStartTimePickerPopupIsOpen] = useState<boolean>(false);
  const [endTimePickerPopupIsOpen, setEndTimePickerPopupIsOpen] = useState<boolean>(false);

  const { data: vehicles } = useQuery(['vehicles'], getVehicleInfo, {});
  const { mutate: mutateTeamSkillList, data: teamSkillListData } = useGetTeamSkillList();

  const { mutate: mutateSetBatchOrders } = useMutation(setBatchOrders, {
    onSuccess: () => {
      refetcher();
    },
  });

  useEffect(() => {
    mutateTeamSkillList({ searchItem: 'name' });
  }, []);

  useEffect(() => {
    if (selectedSkillIds.length) {
      methods.setValue('skills', selectedSkillIds, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedSkillIds]);

  const onSubmit = (data: IsetBatchOrdersProps) => {
    let res = getDirtyValues(dirtyFields, data) as IsetBatchOrdersProps;

    const inits: {
      [key: string]: any;
    } = {};
    modifiedContents.forEach(d =>
      Object.entries(d).forEach(x => {
        if (x[1] === '설정값 삭제') {
          if (x[0] === '작업소요시간(분)') inits[valueConverter(x[0])] = 60;
          else if (x[0] === '특수 조건') inits[valueConverter(x[0])] = [];
          else inits[valueConverter(x[0])] = '';
        } else return;
      })
    );

    if (Object.keys(res).length || Object.keys(inits).length) {
      mutateSetBatchOrders({
        ...res,
        serviceTime: res.serviceTime && parseInt((res.serviceTime as string).replace(/\D/g, '')) * 60,
        desiredDate: res.desiredDate && dateConverter(res?.desiredDate, 'YYYY-MM-DD'),
        orderList: targetOrderList,
        ...inits,
      });
    }

    setBatchOrdersModifyingModalIsOpen(false);
  };

  function valueConverter(value: string): string {
    if (value === 'serviceVehicle') return `담당 드라이버 지정`;
    if (value === 'skills') return '특수 조건';
    if (value === 'desiredDate') return strings.작업희망일;
    if (value === 'desiredTimeStart') return strings.희망시간이후;
    if (value === 'desiredTimeEnd') return strings.희망시간이전;
    if (value === 'serviceTime') return '예상 작업 소요 시간';
    if (value === 'shipmentType') return strings.주문 + '유형';
    if (value === 'priority') return '배차 우선순위';

    if (value === `담당 드라이버 지정`) return 'serviceVehicle';
    if (value === '특수 조건') return 'skills';
    if (value === strings.작업희망일) return 'desiredDate';
    if (value === strings.희망시간이후) return 'desiredTimeStart';
    if (value === strings.희망시간이전) return 'desiredTimeEnd';
    if (value === strings.작업희망일) return 'desiredDate';
    if (value === '예상 작업 소요 시간') return 'serviceTime';
    if (value === strings.주문 + '유형') return 'shipmentType';
    if (value === '배차 우선순위') return 'priority';

    if (value === 'high') return '높음';
    if (value === 'medium') return '보통';
    if (value === 'low') return '낮음';
    else return value;
  }

  const serviceTimeOnCahnge = useCallback(
    debounce(function (e) {
      if (e.target.value !== '' && /[0-9]/g.test(e.target.value))
        methods.setValue('serviceTime', e.target.value.replaceAll(/\D/g, '').concat('분'), {
          shouldDirty: true,
          shouldValidate: true,
        });
      else
        methods.setValue('serviceTime', '', {
          shouldDirty: true,
          shouldValidate: true,
        });
    }, 500),
    []
  );

  const onClickHandler = (data: any) => {
    let dirtyValues: any = getDirtyValues(dirtyFields, { ...data });

    if (Object.values(dirtyValues).filter(d => Boolean(d)).length > 0 || Object.values(initializer).find(d => d)) {
      let keys = Object.keys(dirtyValues).map(d => {
        return valueConverter(d);
      });

      if (Object.keys(dirtyValues).includes('note')) {
        dirtyValues.note
          .filter((d: any) => Boolean(d))
          .forEach((d: any, index: number) => keys.push(`비고 ${index + 1}`));
      }

      if (dirtyValues?.desiredDate)
        if (dayjs(dateConverter(dirtyValues.desiredDate, 'YYYY-MM-DD')).isBefore(dayjs().subtract(1, 'day'))) {
          return methods.setError(
            'desiredDate',
            {
              type: 'invalidDateRange',
              message: '날짜의 범위가 잘못되었습니다. 다시 한번 확인해 주세요',
            },
            { shouldFocus: true }
          );
        }

      if (dirtyValues?.shipmentType) {
        dirtyValues.shipmentType = shipment_type.find(d => d.value === dirtyValues?.shipmentType)?.label;
      }

      let res = Object.entries(dirtyValues)
        .filter(d => Boolean(d[1]))
        .map(d => {
          let r = { [valueConverter(d[0])]: d[1] };
          return r;
        })
        .concat(
          Object.entries(initializer)
            .filter(d => d[1])
            .map(d => {
              let r = { [valueConverter(d[0])]: '설정값 삭제' };
              return r;
            })
        );

      const valueSet = new Set();
      res?.forEach(obj => {
        for (let key in obj) {
          if (obj[key] === '설정값 삭제') {
            valueSet.add(key);
          }
        }
      });

      setModifiedContents(
        res?.filter(obj => {
          const key = Object.keys(obj)[0];
          return obj[key] === '설정값 삭제' || !valueSet.has(key);
        })
      );

      setModifyingConfirm(true);
    } else setBatchOrdersModifyingModalIsOpen(false);
  };

  useEffect(() => {
    methods.reset();

    setInitializer({
      desiredDate: false,
      serviceTime: false,
      serviceVehicle: false,
      skills: false,
    });

    if (domain === 'roouty')
      methods.setValue('shipmentType', shipment_type[0].value, {
        shouldDirty: true,
      });
    setModifyingConfirm(false);
  }, [methods, batchOrdersModifyingModalIsOpen, domain]);

  const InitializeButton = (key: keyof IForm) => {
    return (
      <Stack
        spacing={4}
        direction="row"
        onClick={() => {
          setInitializer(prev => {
            if (!prev[key]) {
              methods.setValue(key, '');
            }

            return { ...prev, [key]: !prev[key] };
          });
        }}
        sx={{
          userSelect: 'none',
        }}
      >
        <IcSuccess width={12} height={12} fill={theme.colors[initializer[key] ? 'RC02' : 'RG05']} />
        <Text styleName="caption2" color={initializer[key] ? 'RC02' : 'RG04'}>
          설정값 삭제
        </Text>
      </Stack>
    );
  };

  return (
    <Modal
      isModalOpen={batchOrdersModifyingModalIsOpen}
      setIsModalOpen={setBatchOrdersModifyingModalIsOpen}
      plain
      width={modifyingConfirm ? 503 : 496}
      ms={0}
      // ms={modifyingConfirm ? 0 : 30}
    >
      {modifyingConfirm ? (
        <Fragment>
          <Stack direction="row" justify="end" sx={{ padding: '24px 24px 0 24px' }}>
            <IconClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setBatchOrdersModifyingModalIsOpen(false);
              }}
            />
          </Stack>
          <Stack sx={{ padding: '0 24px' }}>
            <Text styleName="subheadline2" color="RG03" sx={{ padding: '30px 0 40px' }}>
              <Highlight color="RC04">
                선택하신 {strings.모달_주문} {targetOrderList.length} 건을 일괄수정 하시겠습니까?
              </Highlight>
              <br />
              일괄 변경에서 선택한 {strings.모달_주문} 정보가 모두 아래와 같이 변경됩니다.
              <br />
              일괄 변경 이후에는 변경 전 정보로 되돌릴 수 없습니다.
            </Text>

            <BatchOrdersDetailTableContainer>
              <BatchOrdersDetailTable>
                <thead>
                  <tr>
                    <BatchOrdersDetailTh colSpan={125}>변경항목</BatchOrdersDetailTh>
                    <BatchOrdersDetailTh colSpan={331}>변경내용</BatchOrdersDetailTh>
                  </tr>
                </thead>
                <tbody>
                  {modifiedContents.map((d, index) => {
                    if (Object.entries(d)[0][0] === 'note') {
                      return (Object.entries(d)[0][1] as Array<string>).map((c: string, index: number) => {
                        return Boolean(c) ? (
                          <tr>
                            <BatchOrdersDetailTd disabled colSpan={125}>
                              비고{index + 1}
                            </BatchOrdersDetailTd>
                            <BatchOrdersDetailTd disabled colSpan={331}>
                              {c}
                            </BatchOrdersDetailTd>
                          </tr>
                        ) : null;
                      });
                    } else if (Object.entries(d)[0][0] === '특수 조건' || Object.entries(d)[0][0] === 'skills') {
                      const [title, item] = Object.entries(d)[0];

                      return (
                        <tr>
                          <BatchOrdersDetailTd disabled colSpan={125}>
                            {title}
                          </BatchOrdersDetailTd>
                          <BatchOrdersDetailTd disabled colSpan={331}>
                            {item === '설정값 삭제'
                              ? item
                              : (item as number[])
                                  ?.map(
                                    skillId => teamSkillListData?.data.find(skill => skill.skillId === skillId)?.name
                                  )
                                  ?.join(', ')}
                          </BatchOrdersDetailTd>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <BatchOrdersDetailTd disabled colSpan={125}>
                            {`${Object.entries(d)[0][0]}`}
                          </BatchOrdersDetailTd>
                          <BatchOrdersDetailTd disabled colSpan={331}>
                            {valueConverter(`${Object.entries(d)[0][1]}`)}
                          </BatchOrdersDetailTd>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </BatchOrdersDetailTable>
            </BatchOrdersDetailTableContainer>

            <Text styleName="subheadline2" sx={{ padding: '30px 0 40px' }}>
              변경하려는 내용이 맞으면 확인을 눌러주세요.
            </Text>
          </Stack>

          <Stack direction="row" spacing={16} sx={{ padding: '0 24px 24px' }}>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                setModifyingConfirm(false);
              }}
            >
              <Text styleName="body2" color="RG04">
                취소
              </Text>
            </Button>
            <Button
              variant={'default'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={handleSubmit(onSubmit)}
            >
              <Text styleName="body2" color="RG00">
                확인
              </Text>
            </Button>
          </Stack>
        </Fragment>
      ) : (
        <Fragment>
          <OrderDetailModalHeader>
            <Stack direction="row" justify="space-between">
              <Text styleName="subheadline2" color="RG03">
                주문정보 일괄 수정
              </Text>
              <IconClose
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setBatchOrdersModifyingModalIsOpen(false);
                }}
              />
            </Stack>
          </OrderDetailModalHeader>
          <Divider color="RG06" />
          <OrderDetailModalBody useVerticalScroll sx={{ width: '100%' }}>
            <Stack
              sx={{
                maxHeight: 750,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack name="content-area">
                  <DetailModiForm title={strings.주문정보}>
                    {domain === 'roouty' && (
                      <Swap
                        allowUncheck
                        defaultIndex={-3}
                        name="shipmentType"
                        methods={methods}
                        label={genLabel({
                          text: '주문 유형',
                          subText: `${strings.주문} 1개당 배달 또는 수거만 설정 가능`,
                        })}
                        sx={{ flex: 2 }}
                        maxWidth={446}
                        options={shipment_type.filter(d => d.visibleType)}
                      />
                    )}
                    <Swap
                      padding={0}
                      gap={0}
                      sx={{
                        paddingTop: '7px',
                      }}
                      name="priority"
                      methods={methods}
                      label={genLabel({
                        text: '배차 우선순위',
                        subText: `주문이 배차되어야 하는 우선 순위`,
                      })}
                      defaultIndex={-3}
                      options={priority_type.filter(d => d.visibleType)}
                    />
                    <Stack sx={{ position: 'relative' }}>
                      <Input
                        label={strings.작업희망일}
                        name="desiredDate"
                        placeholder={'YYYY.MM.DD'}
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        readOnly
                        onClick={() => {
                          setDesiredDatePopupIsOpen(true);
                          if (initializer.desiredDate) {
                            setInitializer({
                              ...initializer,
                              desiredDate: false,
                            });
                          }
                        }}
                        onChange={() => {
                          setDesiredDatePopupIsOpen(false);
                        }}
                        rightArea={{
                          rightContent: InitializeButton('desiredDate'),
                        }}
                        errors={methods.formState.errors.desiredDate}
                        hasError={Boolean(methods.formState.errors.desiredDate)}
                      />
                      {desiredDatePopupIsOpen && (
                        <DatePickerPopup
                          setIsOpen={setDesiredDatePopupIsOpen}
                          setValue={methods.setValue}
                          initDate={
                            methods.getValues('desiredDate')
                              ? dayjs(methods.getValues('desiredDate')).format('YYYY-MM-DD')
                              : dayjs().toString()
                          }
                          name={'desiredDate'}
                          banPast
                          top="80px"
                          format="YYYY.MM.DD"
                        />
                      )}
                    </Stack>
                    <Stack sx={{ position: 'relative' }}>
                      {startTimePickerPopupIsOpen && (
                        <PopupYIndexFixer>
                          <MiniTimePicker
                            methods={methods}
                            isOpen={startTimePickerPopupIsOpen}
                            setIsOpen={setStartTimePickerPopupIsOpen}
                            name={'desiredTimeStart'}
                            initial={'00:00'}
                            init={methods.getValues('desiredTimeStart')}
                            desired
                            nm
                          />
                        </PopupYIndexFixer>
                      )}
                      <Input
                        label={genLabel({ text: strings.희망시간이후 })}
                        placeholder="몇 시부터 배차를 원하는지 입력해 주세요"
                        type="text"
                        name="desiredTimeStart"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        height={40}
                        readOnly={true}
                        onClick={() => {
                          setStartTimePickerPopupIsOpen(true);
                          if (initializer.desiredTimeStart) {
                            setInitializer({
                              ...initializer,
                              desiredTimeStart: false,
                            });
                          }
                        }}
                        rightArea={{
                          rightContent: InitializeButton('desiredTimeStart'),
                        }}
                        field
                        errors={methods.formState.errors.desiredTimeStart}
                        hasError={Boolean(methods.formState.errors.desiredTimeStart)}
                      />
                    </Stack>
                    <Stack sx={{ position: 'relative' }}>
                      {endTimePickerPopupIsOpen && (
                        <PopupYIndexFixer>
                          <MiniTimePicker
                            methods={methods}
                            isOpen={endTimePickerPopupIsOpen}
                            setIsOpen={setEndTimePickerPopupIsOpen}
                            name={'desiredTimeEnd'}
                            initial={'00:00'}
                            init={methods.getValues('desiredTimeEnd')}
                            desired
                            nm
                          />
                        </PopupYIndexFixer>
                      )}
                      <Input
                        label={genLabel({ text: strings.희망시간이전 })}
                        placeholder="몇 시까지 배차를 원하는지 입력해 주세요"
                        type="text"
                        name="desiredTimeEnd"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        height={40}
                        readOnly={true}
                        onClick={() => {
                          setEndTimePickerPopupIsOpen(true);
                          if (initializer.desiredTimeEnd) {
                            setInitializer({
                              ...initializer,
                              desiredTimeEnd: false,
                            });
                          }
                        }}
                        rightArea={{
                          rightContent: InitializeButton('desiredTimeEnd'),
                        }}
                        field
                        errors={methods.formState.errors.desiredTimeEnd}
                        hasError={Boolean(methods.formState.errors.desiredTimeEnd)}
                      />
                    </Stack>
                    <Stack sx={{ position: 'relative' }}>
                      <Input
                        label={genLabel({ text: '예상 ' + strings._작업 + ' 소요 시간', isRequired: false })}
                        name="serviceTime"
                        placeholder="숫자만 입력해 주세요"
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        errors={methods.formState.errors?.serviceTime}
                        hasError={Boolean(methods.formState.errors?.serviceTime)}
                        type="text"
                        height={40}
                        onChange={(e: any) => {
                          setWorkTimePopupIsOpen(false);
                          serviceTimeOnCahnge(e);
                        }}
                        onClick={() => {
                          setWorkTimePopupIsOpen(true);
                          if (initializer.serviceTime) {
                            setInitializer({
                              ...initializer,
                              serviceTime: false,
                            });
                          }
                        }}
                      />
                      {workTimePopupIsOpen && (
                        <WorkTimeSelectPopup
                          setIsOpen={setWorkTimePopupIsOpen}
                          setValue={methods.setValue}
                          name={'serviceTime'}
                        />
                      )}
                    </Stack>

                    <Stack spacing={4} sx={{ position: 'relative' }}>
                      {skillPopupIsOpen && (
                        <PopupYIndexFixer>
                          <div></div>
                        </PopupYIndexFixer>
                      )}
                      <Input
                        name="serviceVehicle"
                        label={`담당 드라이버 지정`}
                        placeholder={'담당 드라이버를 지정해 주세요'}
                        register={methods.register}
                        watch={methods.watch}
                        reset={methods.reset}
                        field
                        type="text"
                        height={40}
                        rightArea={{
                          rightContent: InitializeButton('serviceVehicle'),
                        }}
                        validation={{
                          validate: (res: any) =>
                            !Boolean(res) ||
                            (vehicles && vehicles.vehicleList.filter(d => d.licenseNumber === res).length > 0) ||
                            '존재하지 않는 차량입니다.',
                        }}
                        onChange={(e: any) => {
                          setSkillPopupIsOpen(true);
                          setSkillSearchValue(e.target.value);
                          methods.setValue('serviceVehicle', e.target.value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                        onClick={() => {
                          setSkillPopupIsOpen(true);
                          if (initializer.serviceVehicle) {
                            setInitializer({
                              ...initializer,
                              serviceVehicle: false,
                            });
                          }
                        }}
                        hasError={Boolean(methods.formState.errors.serviceVehicle)}
                      />

                      {skillPopupIsOpen && (
                        <SkillPicker
                          setIsOpen={setSkillPopupIsOpen}
                          methods={methods}
                          name={'serviceVehicle'}
                          searchValue={skillSearchValue}
                        />
                      )}

                      {methods.formState.errors?.serviceVehicle && (
                        <Stack align="start">
                          <Text styleName="caption3" color="RC04">
                            {`${methods.formState.errors?.serviceVehicle?.message}`}
                          </Text>
                        </Stack>
                      )}
                    </Stack>
                  </DetailModiForm>
                  <Divider color="RG06" />
                  <ModifiedMultiSkillsSection
                    isModified
                    setSelectedSkillIds={setSelectedSkillIds}
                    disabledEmptyContainer={!selectedSkillIds.length}
                    label={InitializeButton('skills')}
                    handleClickInput={() => {
                      if (initializer.skills) {
                        setInitializer(prev => ({
                          ...prev,
                          skills: false,
                        }));
                      }
                    }}
                  />
                  <Divider color="RG06" />
                  <DetailModiForm title="기타 정보" isFold>
                    <Stack spacing={10}>
                      {['', '', '', '', ''].map((d: any, index: any) => {
                        return (
                          <Input
                            key={`note[${index}]`}
                            name={`note[${index}]`}
                            label={`비고${index + 1}`}
                            // rightArea={{
                            //   rightContent: InitializeButton(`note[${index}]`),
                            // }}
                            placeholder={'-'}
                            register={methods.register}
                            watch={methods.watch}
                            reset={methods.reset}
                            field
                            height={40}
                          />
                        );
                      })}
                    </Stack>
                  </DetailModiForm>
                  <Divider color="RG06" />
                </Stack>
              </form>
            </Stack>
          </OrderDetailModalBody>
          <OrderDetailModalFooter>
            <Stack
              justify="end"
              name="batch-orders-modifying-modal-footer"
              spacing={16}
              direction="row"
              align="center"
              sx={{ padding: '2px 0 0 0' }}
            >
              <Button
                variant="fourth"
                type="button"
                height={40}
                width={102}
                styleName="body2"
                color="RG04"
                onClick={() => {
                  setBatchOrdersModifyingModalIsOpen(false);
                }}
              >
                취소
              </Button>
              <Button
                variant="eighth"
                type="button"
                width={216}
                height={40}
                styleName="body2"
                color="RG00"
                onClick={handleSubmit(onClickHandler)}
              >
                수정하기
              </Button>
            </Stack>
          </OrderDetailModalFooter>
        </Fragment>
      )}
    </Modal>
  );
};

export default BatchOrdersModifyingModal;
