import { Dispatch, Fragment, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { Input } from 'components/Input';
import { Button } from 'components/Button/legacy_index';
import { useStore } from 'store';

import { TermsOfServiceList } from 'auth/signUp/privateTypeForm/elements';
import { InputLabel, InputPassword } from 'auth/common';
import { validateLoginAccount } from 'util/validate/validateLoginAccount';
import { switchSignUpMode } from 'auth/signUp/container';
import {
  __checkSignupForm,
  __rerequestVerifyCode,
  __signUp,
  __switchSignUpModal,
  __updateSignUpForm,
  _validateLoginAccount,
  updatePrivateFormState,
  validateName,
} from 'auth/signUp/privateTypeForm/container';
import { PrivateFormModalController } from 'auth/signUp/privateTypeForm/PrivateFormModalController';
import { isFalsy } from 'util/isFalsy';

import * as CS from 'auth/style';
import * as S from 'auth/signUp/style';
import { PhoneNumberInput } from '../corporateTypeForm/PhoneNumberInput';
import { useTracking } from '../../../hooks/store/useTracking';

interface IPrivateTypeForm {
  _state: TSignUpState;
  _dispatch: Dispatch<TSignUpAction>;
  setWelcomePageMode: Dispatch<SetStateAction<TWelcomePageType>>;
}

export const PrivateTypeForm = (props: IPrivateTypeForm) => {
  const { _dispatch, _state, setWelcomePageMode } = props;
  const { signUpForm } = _state;
  const { isLoading, setIsLoading } = useStore();
  const [_modalState, _modalDispatch] = useState({
    type: '' as TSignUpPrivateTypeFormModalType,
    isOpen: false,
    params: {},
  });

  const [isDisabledVerifyCodeBtn, setIsDisabledVerifyCodeBtn] = useState<boolean>(true);
  const [isOverCount, setIsOverCount] = useState<boolean>(false);

  const {
    register,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<TPrivateInfoType>();
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('회원가입>개인정보 입력');
  }, [setTitle]);

  useEffect(() => {
    Object.keys(getValues()).forEach(key => {
      setValue(key, signUpForm[key]);
    });

    if (signUpForm.termsOfService) {
      const [agreeAllOfTerms, Service, Private] = signUpForm.termsOfService!;
      const isHideErrorMessage = agreeAllOfTerms.checked || (Service.checked && Private.checked);
      setValue('termsOfService', isHideErrorMessage);
    }
  }, []);

  useEffect(() => {
    if (isDisabledVerifyCodeBtn === false) {
      setIsDisabledVerifyCodeBtn(true);
    }
  }, [signUpForm.loginAccount]);

  const isDisabledSignUpBtn = __checkSignupForm({ getValues, errors, loginAccount: signUpForm.loginAccount });

  return (
    <Fragment>
      <LoadingModal
        isLoading={isLoading}
        text={[`${getValues('loginAccount')} 으로`, '인증 메일을 전송 중입니다. 잠시만 기다려 주세요.']}
      />
      <PrivateFormModalController
        loginAccount={getValues('loginAccount')}
        _dispatch={_dispatch}
        _modalDispatch={_modalDispatch}
        _modalState={_modalState}
      />

      <CS.InputLabel>
        <InputLabel label="이름" />
        <S.InputSection>
          <Input
            name="name"
            placeholder="국문 또는 영문 이름을 입력해주세요. 예시) 홍길동"
            variant="fifth"
            register={register}
            onBlur={() => {
              const { name } = getValues();
              if (isFalsy(name)) return setError('name', { message: '이름은 필수 입력사항 입니다.' });
              if (validateName(name) === false) {
                return setError('name', { message: '이름은  2~20자 사이여야 합니다.' });
              }
              clearErrors('name');
            }}
            hasError={errors.name}
            errors={errors.name}
          />
        </S.InputSection>
      </CS.InputLabel>

      <CS.InputLabel>
        <CS.PasswordInputLabel>
          <InputLabel label="이메일" />
        </CS.PasswordInputLabel>
        <S.InputSection>
          <Input
            name="loginAccount"
            placeholder="사용하고 있는 이메일 주소를 입력해주세요."
            variant="fifth"
            register={register}
            onBlur={() => {
              const { loginAccount } = getValues();
              if (isFalsy(loginAccount)) return setError('loginAccount', { message: '이메일은 필수 입력사항 입니다.' });
              if (validateLoginAccount(loginAccount) === false) {
                return setError('loginAccount', { message: '이메일 형식에 맞지 않습니다. 다시 입력해 주세요.' });
              }
            }}
            onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
              if (signUpForm.loginAccount && ['Backspace', 'Delete'].includes(event.key)) {
                __updateSignUpForm({ _dispatch, payload: { loginAccount: '' } });
              }

              const { loginAccount } = getValues();
              if (validateLoginAccount(loginAccount) && isFalsy(signUpForm.loginAccount)) {
                return _validateLoginAccount(loginAccount, setError, clearErrors, setIsDisabledVerifyCodeBtn);
              }

              clearErrors('loginAccount');
              setIsDisabledVerifyCodeBtn(true);
            }}
            hasError={errors.loginAccount}
            errors={errors.loginAccount}
          />

          <Button
            styleName="body1"
            color="RG00"
            variant="default"
            type="button"
            height={48}
            sx={{ marginLeft: '20px', minWidth: '197px' }}
            disabled={isDisabledVerifyCodeBtn}
            onClick={() => {
              const payload = getValues('loginAccount');
              if (isOverCount) {
                return __switchSignUpModal({
                  _modalDispatch,
                  payload: { type: 'ExceedRequest', isOpen: true, params: {} },
                });
              }
              setIsLoading(true);

              __rerequestVerifyCode(
                payload,
                () =>
                  __switchSignUpModal({
                    _modalDispatch,
                    payload: { type: 'EmailValidation', isOpen: true, params: {} },
                  }),
                () =>
                  __switchSignUpModal({ _modalDispatch, payload: { type: 'ExceedRequest', isOpen: true, params: {} } }),
                setIsOverCount,
                setIsLoading
              );
            }}
          >
            이메일 인증 코드 발송
          </Button>
        </S.InputSection>
      </CS.InputLabel>

      <InputPassword
        register={register}
        getValues={getValues}
        setValue={setValue}
        disableAutoFill={true}
        label="비밀번호 설정"
        placeholder="사용할 비밀번호를 설정해 주세요. 영문, 숫자 또는 특수문자 중 2개 이상 포함 8 - 20자 이하"
        required={true}
        sx={{ marginTop: '8px' }}
        password={{ message: errors.password }}
        clearErrors={clearErrors}
        setError={setError}
        onKeyUp={() => {
          __updateSignUpForm({ _dispatch, payload: { password: getValues('password') } });
        }}
      />

      <PhoneNumberInput
        _dispatch={_dispatch}
        _state={_state}
        setError={setError}
        clearErrors={clearErrors}
        setPhoneNumber={setValue}
        errors={errors.phone}
      />

      <TermsOfServiceList
        _modalState={_modalState}
        _modalDispatch={_modalDispatch}
        termsOfService={signUpForm.termsOfService!}
        setValue={setValue}
        errorMessage={errors.termsOfService?.message}
        setError={setError}
        clearErrors={clearErrors}
        callback={(termsOfService: TTermsOfServiceList[]) => {
          __updateSignUpForm({ _dispatch, payload: { termsOfService } });
        }}
      />

      <S.CorperateTyoeButton>
        <Button
          styleName="body1"
          color="RG03"
          variant="seventh"
          type="button"
          height={48}
          fullWidth
          onClick={() => {
            updatePrivateFormState({ getValues, _dispatch });
            switchSignUpMode({ _dispatch, mode: 'CORPORATE' });
          }}
        >
          이전 단계로 이동
        </Button>
        <Button
          styleName="body1"
          color="RG00"
          variant="default"
          type="button"
          height={48}
          fullWidth
          sx={{ marginLeft: '20px' }}
          disabled={isDisabledSignUpBtn}
          onClick={() => {
            setIsLoading(true);
            __signUp({ signUpForm, getValues, setIsLoading, setWelcomePageMode });
          }}
        >
          가입 신청 완료
        </Button>
      </S.CorperateTyoeButton>
    </Fragment>
  );
};
