import Modal from 'components/Modal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Text } from 'components/Typography';
import { Button } from 'components/Button/legacy_index';
import { Stack } from 'components/Stack';
import theme from 'constants/theme';
import { _putSetDriverAppList, switchOption } from 'pages/Setting/resource/DriverApp/container';

import { Toggle } from 'pages/Setting/common/Toggle';
import * as S from 'pages/Setting/resource/DriverApp/style';
import { ReactComponent as CloseIcon } from 'constants/icon/ic_close_16.svg';
import StatusTag from 'pages/Setting/common/StatusTag';

interface IDriverAppConfigModal {
  isOpen: boolean;
  setModalType: Dispatch<SetStateAction<TDriverAppModal>>;
  selectedItem: TDriverAppTable | null;
}

export const DriverAppConfigModal = (props: IDriverAppConfigModal) => {
  const initialState = { podRequired: false, stepFixed: true, geocoding: false, clear: false };
  const [isChecked, setIsChecked] = useState<TDriverSettingConfigQuery>(initialState);
  const { isOpen, setModalType, selectedItem } = props;

  useEffect(() => {
    const selectedData = {
      geocoding: selectedItem!.geocoding,
      podRequired: selectedItem!.podRequired,
      stepFixed: selectedItem!.stepFixed,
    };
    switchOption({ _dispatch: setIsChecked, _state: selectedData });
    return () => {
      setIsChecked(initialState);
    };
  }, []);

  const { geocoding, name, podRequired, stepFixed, hasOwner, routeProcessing } = selectedItem!;

  useEffect(() => {
    if (isChecked.clear) {
      setIsChecked(initialState);
    }
  }, [isChecked.clear]);

  return (
    <Modal width={540} plain isModalOpen={isOpen} setIsModalOpen={() => {}}>
      <S.DriverAppModalHeader>
        <Text styleName="subheadline2" color="RG02">
          드라이버 앱 관리
        </Text>
        <CloseIcon
          cursor="pointer"
          onClick={() => {
            setModalType({ type: '', isOpen: false });
          }}
        />
      </S.DriverAppModalHeader>
      <S.DriverAppModalBody>
        <Stack justify="space-between" direction="row" align="center" sx={{ marginBottom: '20px' }}>
          <Text styleName="title2" color="RG02">
            팀의 드라이버 앱 설정과 권한을 관리합니다
          </Text>
          <Button
            type="button"
            variant="seventh"
            height={24}
            sx={{ padding: '2px 4px' }}
            styleName="caption1"
            color="RG03"
            onClick={() => {
              switchOption({ _dispatch: setIsChecked, _state: isChecked, option: 'reset' });
            }}
          >
            초기화
          </Button>
        </Stack>
        <S.DriverAppModalBodyConfigBox>
          <Stack direction="row" sx={{ borderBottom: `1px solid ${theme.colors.RG06}`, padding: '20px 20px' }}>
            {hasOwner && <StatusTag text="오너" />}
            <Text styleName="body2" color="RG03" sx={{ paddingLeft: '6px' }}>
              {name}
            </Text>
          </Stack>
          <Stack sx={{ borderBottom: `1px solid ${theme.colors.RG06}`, padding: '20px 20px' }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Text styleName="body2" color="RG03">
                자동 도착 처리
              </Text>

              <Toggle
                isChecked={geocoding}
                leftBgColor="RG05"
                leftColor="RC02"
                circleColor="RG06"
                clear={isChecked.clear}
                setChecked={() => switchOption({ _dispatch: setIsChecked, _state: isChecked, option: 'geo' })}
              />
            </Stack>
            <Text styleName="caption2" color="RG03" sx={{ alignSelf: 'start', marginTop: '10px' }}>
              활성화 시 방문지 도착을 자동으로 처리합니다.
            </Text>
          </Stack>
          <Stack sx={{ borderBottom: `1px solid ${theme.colors.RG06}`, padding: '20px 20px' }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Text styleName="body2" color="RG03">
                PoD(작업완료 인증 사진)
              </Text>

              <Toggle
                isChecked={podRequired}
                leftBgColor="RG05"
                leftColor="RC02"
                circleColor="RG06"
                clear={isChecked.clear}
                setChecked={() => switchOption({ _dispatch: setIsChecked, _state: isChecked, option: 'pod' })}
              />
            </Stack>
            <Text styleName="caption2" color="RG03" sx={{ alignSelf: 'start', marginTop: '10px' }}>
              활성화 시 작업 완료 인증 사진 첨부 필수로 설정합니다.
            </Text>
          </Stack>
          <Stack sx={{ padding: '20px 20px' }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Text styleName="body2" color="RG03">
                드라이버 임의 순서 변경
              </Text>
              <Toggle
                isChecked={stepFixed === false}
                leftBgColor="RG05"
                leftColor="RC02"
                circleColor="RG06"
                clear={isChecked.clear}
                setChecked={(toggleOn: boolean) => {
                  const _isChecked = Object.assign({}, isChecked, { stepFixed: !toggleOn });
                  switchOption({ _dispatch: setIsChecked, _state: _isChecked, option: 'step' });
                }}
              />
            </Stack>
            <Text styleName="caption2" color="RG03" sx={{ alignSelf: 'start', marginTop: '10px' }}>
              활성화 시 드라이버 임의 작업 순서 변경을 허용합니다.
            </Text>
          </Stack>
        </S.DriverAppModalBodyConfigBox>
      </S.DriverAppModalBody>
      <S.DriverAppModalFooter>
        <Button
          variant="seventh"
          height={40}
          width={102}
          type="button"
          styleName="body1"
          color="RH03"
          onClick={() => {
            setModalType({ type: '', isOpen: false });
          }}
        >
          취소
        </Button>
        <Button
          variant="MRTB"
          height={40}
          width={216}
          type="button"
          onClick={() => {
            const { teamId } = selectedItem!;
            _putSetDriverAppList({ payload: isChecked, teamId, _dispatch: setModalType });
          }}
        >
          <Text styleName="body1" color="RH03">
            저장하기
          </Text>
        </Button>
      </S.DriverAppModalFooter>
    </Modal>
  );
};
