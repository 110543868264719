import { TAccountStatus, TControlDriverStatus, TOrderPriority, TOrderStatus, TRouteStatus } from 'constants/types';
import strings from '../Localization';

export function validateOrderStatus(data: TOrderStatus) {
  const status = {
    unassigned: { key: 'todo', name: strings.미배차 },
    scheduled: { key: 'done', name: strings.배차완료 },
    postponed: { key: 'done', name: strings.배차완료 },
    processing: { key: 'moving', name: strings.배송중 },
    arrived: { key: 'moving', name: strings.배송중 },
    completed: { key: 'purple', name: strings.배송완료 },
    skipped: { key: 'skipped', name: '보류' },
    canceled: { key: 'cancel', name: '취소' },
    deleted: { key: 'cancel', name: '취소' },
  };

  return status[data];
}

export function validateOrderPriority(data: TOrderPriority) {
  const status = {
    high: { key: 'high', name: '높음' },
    medium: { key: 'medium', name: '보통' },
    low: { key: 'low', name: '낮음' },
  };

  return status[data];
}

export function validateAccountStatus(data: TAccountStatus) {
  const status = {
    invited: { key: 'todo', name: '초대됨' },
    accepted: { key: 'moving', name: '초대완료' },
    activated: { key: 'done', name: '활성' },
    deactivated: { key: 'skipped', name: '정지' },
  };

  return status[data];
}

export function validateControlDriverStatus(data: TControlDriverStatus) {
  const status = {
    start: '주행시작',
    processing: '이동중',
    postponed: '재작업대기',
    arrived: '작업중',
    completed: '작업완료',
    end: '주행종료',
    skipped: '보류',
  };

  return status[data];
}

export function validateRouteStatus(data: TRouteStatus) {
  const status = {
    activated: { key: 'done', name: `${strings.주행_조사}대기` },
    processing: { key: 'moving', name: `${strings.주행_조사}중` },
    completed: { key: 'purple', name: `${strings.주행_조사}종료` },

    canceled: { key: 'cancel', name: `${strings.주행_조사}취소` },
  };

  return status[data];
}

export function validateControlOrderStatus(data: TOrderStatus) {
  const status = {
    unassigned: { key: 'todo', name: '' },
    scheduled: { key: 'done', name: '작업대기' },
    postponed: { key: 'done', name: '재작업대기' },
    processing: { key: 'moving', name: '이동중' },
    arrived: { key: 'moving', name: '작업중' },
    completed: { key: 'purple', name: '작업완료' },
    skipped: { key: 'skipped', name: '보류' },
    canceled: { key: 'cancel', name: '' },
    deleted: { key: 'cancel', name: '' },
  };

  return status[data];
}
