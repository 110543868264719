import { useQuery } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { cancelRoute, getRouteList, routeForceDelete } from 'api';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import ComplexDoubleviewDateselector from 'components/ComplexDoubleviewDateselector';
import Divider from 'components/Divider';

import DeactiveModal from 'components/Modal/DeactiveModal';
import { RouteDownloadModal } from 'components/Modal/download/RouteDownloadModal';
import { UpgradeGuideModal } from 'components/Modal/warning/paymentPlan/UpgradeGuideModal';
import { Stack } from 'components/Stack';
import { Tag } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';
import { common_table_selection, date_set_range_template, route_list_content_by_status } from 'constants/commons';
import { ReactComponent as Active } from 'constants/icon/ic_active.svg';
import { ReactComponent as IcDown } from 'constants/icon/ic_arrowdown.svg';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcCalendar } from 'constants/icon/ic_calendar.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';
import { IGetRouteListResponses, IRouteList, IRouteListContent, TPeriodContent } from 'constants/types';
import dayjs from 'dayjs';
import orderby from 'lodash.orderby';

import { FilterSearchbar, Tab } from 'pages/OrderManagePage';
import {
  CalendarIconContainer,
  CalendarInputField,
  QueueSelectorContainer,
  QueueSelectorWrapper,
} from 'pages/Report/utils/ReportFilter';
import { ReportFilterContent } from 'pages/Report/utils/ReportFilterContent';

import React, { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RouteDownloadVariables } from 'types/route/download';
import { RouteStatusUnion } from 'types/route/union';
import strings from 'util/Localization';
import TableComponent from '.';
import { useGetPaymentMy } from '../../hooks/query/payment/useGetPaymentMy';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

let routeListTable = createColumnHelper<IRouteList>();

function MonitoringTable({
  targetRouteId,
  setTargetRouteId,
  rowSelection,
  setRowSelection,
  setPerformedDate,
  refetchControlRouteByIdData,

  isOpen,

  isLanding,
  pageStatus,
  setPageStatus,
  setRouteListTabIsOpen,
}: {
  targetRouteId: number;
  setTargetRouteId: Dispatch<SetStateAction<number>>;
  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;
  setPerformedDate: Dispatch<SetStateAction<string>>;
  refetchControlRouteByIdData: Function;

  isLanding: boolean;
  isOpen: boolean;
  pageStatus: RouteStatusUnion;
  setPageStatus: Dispatch<SetStateAction<RouteStatusUnion>>;
  setRouteListTabIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  type TrouteSearchContent = 'routeCode' | 'name' | 'driver' | 'createdBy';
  const content: Array<{ key: string; value: TrouteSearchContent }> = [
    {
      key: '경로 ID',
      value: 'routeCode',
    },
    {
      key: '주행 이름',
      value: 'name',
    },
    {
      key: '드라이버',
      value: 'driver',
    },
    {
      key: '배차 담당자',
      value: 'createdBy',
    },
  ];

  const periodInitializer = () => {
    setPeriod(PeriodContent[1].value);
    PeriodContent[1].setter();
  };

  type FormValues = {
    searchItem?: TrouteSearchContent;
    startDate?: string;
    endDate?: string;
    keyword?: string;
  };
  const form_default: FormValues = { searchItem: content[0].value, keyword: '' };
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: { ...form_default },
  });

  const onSubmit = (res: FormValues) => {
    console.log(res);
    let props: { [key: string]: any } = {};

    if (res.keyword && res.keyword !== '' && res.searchItem) props[res.searchItem] = res.keyword;
    if (res.startDate && res.endDate) {
      props['performedDate'] =
        pageStatus === 'completed'
          ? // YYYYMMDD-YYYYMMDD
            `${res.startDate}-${res.endDate}`.replaceAll('.', '')
          : undefined;
    }

    console.log(props);
    setGetRouteListProps(props);
  };

  const navigate = useNavigate();

  const [pageContent, setPageContent] = useState<IRouteListContent>(route_list_content_by_status[pageStatus]);
  const [selectedOrder, setSelectedOrder] = useState<Array<IRouteList>>([]);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState<boolean>(false);
  const [routeForceDeleteModalIsOpen, setRouteForceDeleteModalIsOpen] = useState<boolean>(false);
  const [routeForceDeleteAgreement, setRouteForceDeleteAgreement] = useState<boolean>(false);
  const [routeForceDeleteIsSuccess, setRouteForceDeleteIsSuccess] = useState<boolean>(false);
  const [orderDownloadModalIsOpen, setOrderDownloadModalIsOpen] = useState<boolean>(false);
  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  useEffect(() => {
    methods.reset({ ...form_default });
    periodInitializer();
    setSelectedOrder([]);
    onSubmit(methods.getValues());
    setPageContent(route_list_content_by_status[pageStatus]);
  }, [isOpen, pageStatus]);

  // 테이블 필터링 나중에 columnVisible로 관리 ㄱㄱ
  useEffect(() => {
    instance?.getAllColumns().forEach(column => {
      if (column.id === 'progress') {
        column.toggleVisibility(pageStatus === 'processing');
      } else if (column.id === 'skippedOrderCount') {
        column.toggleVisibility(pageStatus === 'completed');
      }
      // if (column.id === 'select') {
      //   column.toggleVisibility(pageStatus !== 'processing');
      // }
    });
  }, [pageContent, pageStatus]);

  const columns = useMemo(
    () => [
      routeListTable.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),

              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
            onClick={e => {
              e.stopPropagation();
              console.log('click');
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
              console.log('click');
            }}
          />
        ),
        enableSorting: false,
      }),
      routeListTable.accessor(row => row?.totalProcessedOrderCount, {
        id: 'progress',
        header: '진행률',
        cell: ({ row }) => {
          const totalOrderCount = row.original.totalOrderCount;
          const totalProcessedOrderCount = row.original.totalProcessedOrderCount;
          const progress = Math.floor((totalProcessedOrderCount / totalOrderCount) * 100);
          return (
            <>
              {progress > 0 ? (
                <Tag
                  status={'progress'}
                  active={true}
                  sx={{
                    width: '100%',
                    minWidth: '44px',
                    maxWidth: '44px',
                    height: '26px',
                  }}
                >
                  {progress}%
                </Tag>
              ) : (
                <Tag
                  status={'progress'}
                  active={false}
                  sx={{
                    width: '100%',
                    minWidth: '44px',
                    maxWidth: '44px',
                    height: '26px',
                  }}
                >
                  {progress}%
                </Tag>
              )}
            </>
          );
        },
      }),
      routeListTable.accessor(row => row?.createdAt, {
        id: 'createdAt',
        header: '배차 일자',
        cell: ({ row }) => {
          return <>{row.original.createdAt.slice(0, 10).replace(/-/g, '.')}</>;
        },
      }),
      routeListTable.accessor(row => row?.performedDate, {
        id: 'performedDate',
        header: '주행 일자',
        cell: ({ row }) => {
          return <>{row.original.performedDate.slice(0, 10).replace(/-/g, '.')}</>;
        },
      }),
      routeListTable.accessor(row => row?.routeCode ?? '-', {
        id: 'routeId',
        header: '경로ID',
      }),
      routeListTable.accessor(row => row?.name, {
        id: 'name',
        header: '주행 이름',
      }),
      routeListTable.accessor(row => row?.totalOrderCount, {
        id: 'totalOrderCount',
        header: '총 주문',
      }),
      routeListTable.accessor(row => row?.totalSkippedOrderCount, {
        id: 'skippedOrderCount',
        header: '보류 주문',
      }),
      routeListTable.accessor(row => row?.totalDriverCount, {
        id: 'totalDriverCount',
        header: '총 드라이버',
      }),
      routeListTable.accessor(row => row?.createdBy, {
        id: 'createdBy',
        header: '배차 담당자',
      }),
    ],
    []
  );

  const [pricing_id, setPricing_id] = useState<number>(JSON.parse(localStorage.getItem('pricing') as string)?.id ?? 1);
  const [ingDelRoute, setIngDelRoute] = useState<boolean>(false);
  const [getRouteListProps, setGetRouteListProps] = useState<{
    name?: string;
    createdBy?: string;
    driver?: string;
    routeCode?: number;
    perfomedDate?: string; // YYYYMMDD-YYYYMMDD
  }>({});

  const [orderedRouteList, setOrderedRouteList] = useState<Array<IRouteList>>([]);
  const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);

  const { data: paymentMyData } = useGetPaymentMy({
    refetchOnWindowFocus: true,
  });

  const {
    data: getRouteListResponse,
    isFetching,
    refetch: refetchGetRouteList,
  } = useQuery<IGetRouteListResponses>(
    ['getRouteList', pageStatus, getRouteListProps],
    () => {
      return getRouteList({ status: pageStatus, ...getRouteListProps });
    },
    {
      onSuccess: res => {
        setOrderedRouteList(orderby(res.routeList, [initSort[pageStatus]], ['desc']) ?? []);
      },
    }
  );

  // useEffect(() => {
  //   let res = selectedOrder.map(d => d.original.routeId);
  //   setDelTarget(res);
  // }, [selectedOrder]);

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedOrder.filter(z => z.routeId === y.routeId).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.routeId !== s.original?.routeId);
      });
      setSelectedOrder(orders);
    }
  }

  const selectedOrderPromiseMapper = (
    promise: Function,
    setter: Dispatch<SetStateAction<boolean>>,
    customCallback?: Function
  ) => {
    setIngDelRoute(true);

    const promises = selectedOrder.map(async d => {
      return await promise(d.routeId);
    });

    Promise.all(promises)
      .then(res => {
        setRouteForceDeleteIsSuccess(true);
      })
      .catch(err => {
        customCallback && customCallback(err);
      })
      .finally(() => {
        if (customCallback) {
        } else {
          refetchControlRouteByIdData();
          refetchGetRouteList();

          setSelectedOrder([]);
          setter(false);
        }
        setIngDelRoute(false);
      });
  };
  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.routeId === x.routeId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [selectedOrder, setRowSelection]);

  const initSort: { [key in typeof pageStatus]: 'performedDate' | 'createdAt' } = {
    processing: 'performedDate',
    activated: 'createdAt',
    completed: 'performedDate',
  };

  const instance = useReactTable({
    data: orderedRouteList,
    columns,
    state: {
      sorting,
      rowSelection,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
  });

  const excelDownloadButton = useMemo(() => {
    return (
      <Button
        children={'엑셀로 다운 받기'}
        variant={'tertiary'}
        h={32}
        icon={[IcDown, { width: 16, height: 16, fillOpacity: 0 }]}
        disabled={!(Object.keys(rowSelection).length > 0)}
        onClick={() => {
          if (paymentMyData?.name === 'Free') {
            setIsOpenUpgradeGuideModal(true);

            return;
          }

          setOrderDownloadModalIsOpen(true);
        }}
      />
    );
  }, [rowSelection, pricing_id, paymentMyData?.name]);

  const sc_content: { [key in keyof typeof route_list_content_by_status]: Array<ReactElement> } = {
    processing: [
      excelDownloadButton,
      <Button
        children={'강제 주행종료'}
        variant={'tertiary'}
        h={32}
        disabled={!(Object.keys(rowSelection).length > 0)}
        onClick={() => {
          console.log(selectedOrder);
          if (selectedOrder.length <= 0) return;
          else setRouteForceDeleteModalIsOpen(true);
        }}
      />,
    ],
    activated: [
      excelDownloadButton,
      <Button
        children={`${strings.배차취소}`}
        variant={'tertiary'}
        h={32}
        icon={[IcBin, { width: 16, height: 16 }]}
        disabled={!(Object.keys(rowSelection).length > 0)}
        onClick={() => {
          console.log(selectedOrder);
          if (selectedOrder.length <= 0) return;
          else setDeleteOrderModalOpen(true);
        }}
      />,
    ],
    completed: [excelDownloadButton],
  };

  const [CDDIsOpen, setCDDIsOpen] = useState<boolean>(false);

  const [period, setPeriod] = useState<string>('last_1week');
  const PeriodContent: Array<TPeriodContent> = useMemo(
    () => [
      {
        value: 'today',
        key: '오늘',
        keyDate: date_set_range_template.today.format('YYYY.MM.DD'),
        setter: () => {
          methods.setValue('startDate', date_set_range_template.today.format('YYYY.MM.DD'), {
            shouldDirty: true,
            shouldValidate: true,
          });
          methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
        },
        periodDate: `${date_set_range_template.today.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
      },
      {
        value: 'last_1week',
        key: '1주일',
        keyDate: date_set_range_template.week.format('YYYY.MM.DD'),
        setter: () => {
          methods.setValue('startDate', date_set_range_template.week.format('YYYY.MM.DD'), {
            shouldDirty: true,
            shouldValidate: true,
          });
          methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
        },
        periodDate: `${date_set_range_template.week.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
      },
      {
        value: 'last_1month',
        key: '1개월',
        keyDate: date_set_range_template.month1.format('YYYY.MM.DD'),
        setter: () => {
          methods.setValue('startDate', date_set_range_template.month1.format('YYYY.MM.DD'), {
            shouldDirty: true,
            shouldValidate: true,
          });
          methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
        },
        periodDate: `${date_set_range_template.month1.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
      },
      {
        value: 'last_3month',
        key: '3개월',
        keyDate: date_set_range_template.month3.format('YYYY.MM.DD'),
        setter: () => {
          methods.setValue('startDate', date_set_range_template.month3.format('YYYY.MM.DD'), {
            shouldDirty: true,
            shouldValidate: true,
          });
          methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), { shouldDirty: true, shouldValidate: true });
        },
        periodDate: `${date_set_range_template.month3.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
      },
    ],
    [methods]
  );

  useEffect(() => {
    PeriodContent.forEach(d => {
      if (d.periodDate === `${methods.getValues('startDate')}-${methods.getValues('endDate')}`) {
        d.setter();
        setPeriod(d.value);
      } else return;
    });
  }, [period, PeriodContent, methods.getValues('startDate'), methods.getValues('endDate')]);

  return (
    <Fragment>
      <Stack
        useVerticalScroll
        sx={{
          zIndex: 1,
          width: '936px',
          height: '100%',
          padding: `${isLanding ? 48 : 24}px 20px 20px 20px`,
          backgroundColor: '#fff',
        }}
      >
        <Stack
          direction="column"
          justify="flex-start"
          align="center"
          sx={{
            width: '100%',
          }}
        >
          <Text
            styleName="title1"
            sx={{
              width: '100%',
            }}
          >
            {pageContent?.title}
          </Text>
          <Text
            styleName="caption2"
            sx={{
              width: '100%',
              marginTop: '8px',
              marginBottom: '30px',
            }}
          >
            {pageContent?.desc}
          </Text>
        </Stack>
        <Stack name="order-page-tap-by-status" direction={'row'}>
          {Object.values(route_list_content_by_status)
            .splice(0, 6)
            .map((d, i) => {
              return (
                <Tab
                  index={i}
                  key={i}
                  isActive={pageStatus === Object.keys(route_list_content_by_status)[i]}
                  onClick={() => {
                    navigate(`../control/${Object.keys(route_list_content_by_status)[i]}`);
                  }}
                >
                  {d.title}
                </Tab>
              );
            })}
        </Stack>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack
            name="sub-filter-wrapper"
            align="start"
            sx={{
              minHeight: '72px',
              transition: 'all .6s',
              backgroundColor: theme.colors.RG08,
              borderRadius: '0 8px 8px 8px',
              padding: '20px',
              marginBottom: '20px',
            }}
            spacing={20}
          >
            <Stack direction="row">
              <FilterSearchbar {...{ methods, content }} />
              <Button
                type={'submit'}
                children={'조회하기'}
                variant={'primary'}
                w={'fit-content'}
                h={32}
                sx={{ marginLeft: '16px' }}
              />
            </Stack>

            {pageStatus === 'completed' && (
              <>
                <Divider color="RG06" />
                <ReportFilterContent
                  title="조회 기간"
                  altInfo={`배차 목록을 조회할 기간을 설정합니다.`}
                  anchor="bottom"
                  direction="row"
                  gap={20}
                >
                  <Stack name="date-range-filter-area" direction="row" spacing={16} sx={{ flex: 1 }}>
                    <QueueSelectorWrapper direction={'row'}>
                      {PeriodContent.map(d => {
                        return (
                          <QueueSelectorContainer
                            key={d.key}
                            selected={period === d.value}
                            onClick={() => {
                              setPeriod(d.value);
                              PeriodContent.find(x => x.key === d.key)?.setter();
                            }}
                            len={PeriodContent.length}
                          >
                            <Text styleName={'caption2'} color={period === d.value ? 'RC02' : 'RG02'}>
                              {d.key}
                            </Text>
                          </QueueSelectorContainer>
                        );
                      })}
                    </QueueSelectorWrapper>

                    <ComplexDoubleviewDateselector
                      dayCheck
                      summary
                      setPeriod={setPeriod}
                      // initializer={periodInitializer}
                      parent={
                        <CalendarInputField isActive={CDDIsOpen} hasError={false}>
                          <CalendarIconContainer isActive={CDDIsOpen} hasError={false}>
                            <IcCalendar />
                          </CalendarIconContainer>
                          <input
                            readOnly
                            {...methods.register('startDate', {
                              minLength: {
                                value: 10,
                                message: '',
                              },
                              maxLength: {
                                value: 10,
                                message: '',
                              },
                              pattern: {
                                value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                                message: '',
                              },
                            })}
                            autoComplete="off"
                            onFocus={e => setCDDIsOpen(true)}
                          />
                          {'-'}
                          <input
                            readOnly
                            {...methods.register('endDate', {
                              minLength: {
                                value: 10,
                                message: '',
                              },
                              maxLength: {
                                value: 10,
                                message: '',
                              },
                              pattern: {
                                value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                                message: '',
                              },
                            })}
                            autoComplete="off"
                            onFocus={e => setCDDIsOpen(true)}
                          />
                        </CalendarInputField>
                      }
                      {...{
                        initDate: [
                          dayjs(methods.getValues('startDate')).toDate(),
                          dayjs(methods.getValues('endDate')).toDate(),
                        ],
                        methods,
                        isOpen: CDDIsOpen,
                        setIsOpen: setCDDIsOpen,
                      }}
                    />
                  </Stack>
                </ReportFilterContent>
              </>
            )}
          </Stack>
        </form>

        <TableComponent<IRouteList>
          tableType={`route_${pageStatus}`}
          table={instance}
          pagination={pagination}
          setPagination={setPagination}
          isDataFetching={isFetching}
          onRowClick={(e, row) => {
            if (targetRouteId !== row.original.routeId) {
              setPerformedDate(row.original.performedDate);
              setTargetRouteId(row.original.routeId);
            }
            setRouteListTabIsOpen(false);
          }}
          clearBottom
          sc={sc_content[pageStatus]}
          disableOptions={{
            totalCount: false,
            selectCount: false,
            // selectCount: pageStatus === 'processing',
            pagnation: false,
            pageSizeSelector: false,
          }}
        />
        {anchorIsOpen && (
          <TableSelectManager
            options={common_table_selection}
            {...{
              instance,
              anchorPoint: anchorPoint,
              setAnchorIsOpen,
            }}
          />
        )}
      </Stack>

      <DeactiveModal
        plain
        isOpen={deleteOrderModalOpen}
        setIsOpen={setDeleteOrderModalOpen}
        ms={0}
        loading
        isLoading={ingDelRoute}
        icon={<IcWarning width={44} height={44} style={{ marginTop: '20px' }} />}
        text={
          <Stack spacing={8}>
            <Text color="RC04" styleName="body2">
              {selectedOrder.length} 건의 배차를 취소하시겠습니까?
            </Text>
            <Text styleName="body2">
              <Highlight color="RC02">
                배차에 포함된 자동배차 주문은 미배차로,
                <br />
                수동배차 주문은 취소로 상태가 변경됩니다.
                <br />
              </Highlight>
              배차에 포함된 주문은 [ 주문관리 ] 에서 확인하실 수 있습니다.
              <br />
              취소된 배차는 복구할 수 없습니다.
            </Text>
          </Stack>
        }
        highlight={null}
        returnFunc={() => {
          selectedOrderPromiseMapper(cancelRoute, setDeleteOrderModalOpen);
        }}
        RBT={'확인'}
        LBT={'취소'}
        reverse
      />

      <DeactiveModal
        isOpen={routeForceDeleteModalIsOpen}
        setIsOpen={setRouteForceDeleteModalIsOpen}
        ms={0}
        cp={'14px 0 40px 0'}
        loading
        isLoading={ingDelRoute}
        lock={!routeForceDeleteAgreement}
        isSuccess={routeForceDeleteIsSuccess}
        success={
          <Fragment>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline2">{selectedOrder.length.toString()} 건의 배차가 주행종료 처리되었습니다.</Text>

            <Button
              children={'닫기'}
              variant={'tertiary'}
              h={40}
              sx={{ width: '100%' }}
              onClick={() => {
                refetchControlRouteByIdData();
                refetchGetRouteList();

                setSelectedOrder([]);
                setIngDelRoute(false);

                setRouteForceDeleteAgreement(false);
                setRouteForceDeleteIsSuccess(false);
                setRouteForceDeleteModalIsOpen(false);
              }}
            />
          </Fragment>
        }
        text={
          <Stack spacing={30}>
            <Stack spacing={10}>
              <Text styleName="title2">
                선택한 배차 <Highlight color="title1">{selectedOrder.length}</Highlight> 건을 주행종료 처리하시겠습니까?
              </Text>
              <Text styleName="subheadline2" color="RC04">
                주행일이 오늘인 배차에 포함된 드라이버는
                <br />
                주행종료 처리가 되어도 오늘 다시 배차할 수 없습니다.
              </Text>
            </Stack>

            <Stack spacing={10} align="start">
              <Stack
                align="start"
                spacing={8}
                padding={16}
                sx={{ borderRadius: 8, backgroundColor: theme.colors.RG08 }}
              >
                <Text styleName="caption2">{`- 강제 주행종료 처리한 배차를 주행중 상태로 되돌릴 수 없습니다.`}</Text>
                <Text
                  styleName="caption2"
                  sx={{
                    letterSpacing: '-0.04em',
                  }}
                >{`- 배차에 포함된 '작업대기', '이동중', '작업중' 상태의 주문이 모두 '보류' 상태로 변경됩니다.`}</Text>
                <Text styleName="caption2">{`- 보류 처리한 주문은 "주문관리 > 보류 주문 관리" 페이지에서 확인 가능합니다.`}</Text>
                <Text styleName="caption2">{`- 수동 배차한 주문은 보류 상태가 될 시 재배차 할 수 없습니다.`}</Text>
                <Text styleName="caption2">{`- 주행종료 처리 즉시 드라이버의 실시간 위치 수집을 종료합니다.`}</Text>
              </Stack>

              <Stack
                spacing={8}
                direction="row"
                onClick={e => {
                  setRouteForceDeleteAgreement(prev => !prev);
                }}
                sx={{
                  width: 'max-content',
                  cursor: 'pointer',
                }}
              >
                <Active fill={theme.colors[routeForceDeleteAgreement ? 'RC02' : 'RG05']} width={16} height={16} />
                <Text styleName="caption2" color="RG03">{`상기 내용을 확인했습니다.`}</Text>
              </Stack>
            </Stack>
          </Stack>
        }
        closeCallbackFunc={() => {
          setRouteForceDeleteAgreement(false);
        }}
        highlight={null}
        returnFunc={() => {
          setRouteForceDeleteAgreement(false);
          selectedOrderPromiseMapper(routeForceDelete, setRouteForceDeleteModalIsOpen, (response?: AxiosError) => {
            if (response?.name === 'AxiosError') {
              setRouteForceDeleteModalIsOpen(false);
            }
            setIngDelRoute(false);
          });
        }}
        LBT={'취소'}
        RBT={'강제 주행종료'}
        reverse
      />

      <RouteDownloadModal
        isOpen={orderDownloadModalIsOpen}
        setIsOpen={setOrderDownloadModalIsOpen}
        variables={
          {
            routeIds: selectedOrder.map(item => item.routeId),
            status: pageStatus,
          } as unknown as RouteDownloadVariables
        }
        count={selectedOrder.length}
        text="다운로드 받으실 파일을 선택해주세요."
        extension="xlsx"
        title={'asdf'}
      />

      <UpgradeGuideModal
        isOpen={isOpenUpgradeGuideModal}
        setIsOpen={setIsOpenUpgradeGuideModal}
        upgradeGuidePlan={['Lite', 'Standard', 'Pro', 'Enterprise']}
        text="요금제로 업그레이드 하시면 해당 기능을 사용하실 수 있습니다."
      />
    </Fragment>
  );
}

export default MonitoringTable;
