import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const CancelToken = axios.CancelToken;
export let canceler: any;

export const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const request = async ({ ...options }: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => response.data;

  return await baseAPI({
    ...options,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      canceler = c;
    }),
  }).then(onSuccess);
};
