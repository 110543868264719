import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import { PageButton, PageListBox } from './style';

import { Stack } from 'components/Stack';

import { ReactComponent as IcLeft } from 'constants/icon/ic_arrow_left.svg';
import { ReactComponent as IcRight } from 'constants/icon/ic_arrow_right.svg';
import { ReactComponent as IcDLeft } from 'constants/icon/ic_two_arrow_left.svg';
import { ReactComponent as IcDRight } from 'constants/icon/ic_two_arrow_right.svg';

interface IPaginationProps {
  pageCount: number;
  current: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

const Pagination = ({ pageCount, current, setPagination }: IPaginationProps) => {
  const range = useMemo(() => {
    return Array.from({ length: pageCount }, (v, i) => i);
  }, [pageCount]);
  const [pageGroupIndex, setPageGroupIndex] = useState<Array<number>>([0, 5]);

  // reset pageGroup
  useEffect(() => {
    if (current) return;
    setPageGroupIndex([0, 5]);
  }, [current, range]);

  return (
    <Stack direction="row" name="page-select" spacing={10} sx={{ flexBasis: 'content' }}>
      <PageButton
        type="button"
        onClick={() => {
          setPageGroupIndex(prev => {
            setPagination(pagination => {
              return { ...pagination, pageIndex: prev[0] - 1 };
            });

            return [prev[0] - 5, prev[0]];
          });
        }}
        disabled={pageGroupIndex[0] - 5 < 0}
      >
        <IcDLeft />
      </PageButton>

      <PageButton
        type="button"
        onClick={() => {
          setPagination(prev => {
            return { ...prev, pageIndex: current - 1 };
          });

          if (pageGroupIndex[0] >= current) {
            setPageGroupIndex(prev => [prev[0] - 5, prev[0]]);
          }
        }}
        disabled={current - 1 < 0}
      >
        <IcLeft />
      </PageButton>

      {range.slice(...pageGroupIndex).map(d => {
        return (
          <PageListBox
            key={`pageListBox-${d}-Selector`}
            active={d === current}
            onClick={() => {
              setPagination(prev => {
                return { ...prev, pageIndex: d };
              });
            }}
          >
            {d + 1}
          </PageListBox>
        );
      })}
      <PageButton
        type="button"
        onClick={() => {
          setPagination(prev => {
            return { ...prev, pageIndex: current + 1 };
          });
          if (pageGroupIndex[1] <= current + 1) {
            setPageGroupIndex(prev => [prev[1], prev[1] + 5]);
          }
        }}
        disabled={current + 1 >= pageCount}
      >
        <IcRight />
      </PageButton>

      <PageButton
        type="button"
        onClick={() => {
          setPageGroupIndex(prev => {
            setPagination(pagination => {
              return { ...pagination, pageIndex: prev[1] };
            });

            return [prev[1], prev[1] + 5];
          });
        }}
        disabled={range[range.length - 1] < pageGroupIndex[1] || range.length < 1}
      >
        <IcDRight />
      </PageButton>
    </Stack>
  );
};

export default memo(Pagination);
