import { TStyleName, TThemeColor } from 'constants/types';
import { forwardRef, ReactNode, MouseEvent, CSSProperties, Fragment, ReactElement, ComponentPropsWithRef } from 'react';
import * as S from './legacy_style';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  children: ReactNode;

  fullWidth?: boolean;

  activated?: boolean;
  disabled?: boolean;

  width?: number;
  height?: number;

  removeButtonDisabled?: boolean;

  variant:
    | 'default'
    | 'icon'
    | 'second'
    | 'third'
    | 'fourth'
    | 'fifth'
    | 'sixth'
    | 'seventh'
    | 'eighth'
    | 'RTB'
    | 'MRTB'
    | 'custom'
    | 'ItemRemoveButton';
  type: 'button' | 'submit' | 'reset';

  color?: TThemeColor | string;
  bgColor?: TThemeColor | string;

  sx?: CSSProperties;

  ds?: 'weak' | 'normal' | 'strong';
  onClick?: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;

  styleName?: TStyleName;

  custom?: {
    init?: {
      color?: string;
      bgColor?: string;
      borderColor?: string;
    };
    hover?: {
      color?: string;
      bgColor?: string;
      borderColor?: string;
    };
    disabled?: {
      color?: string;
      bgColor?: string;
      borderColor?: string;
    };
  };
}

const MappedStyled = {
  default: S.Button,
  custom: S.CustomButton,
  icon: S.IconButton,
  second: S.SecondButton,
  third: S.ThirdButton,
  fourth: S.FourthButton,
  fifth: S.FifthButton,
  sixth: S.SixthButton,
  seventh: S.SeventhButton,
  eighth: S.EighthButton,
  RTB: S.RTButton,
  MRTB: S.MRTButton,
  ItemRemoveButton: S.ItemRemoveButton,
};

export const Button = forwardRef<any, ButtonProps>(({ sx, children, ...props }, ref): ReactElement => {
  const MappedStyledComponent = MappedStyled[props.variant];

  if (Object.keys(MappedStyled).includes(props.variant))
    return (
      <MappedStyledComponent style={sx} {...props} ref={ref}>
        {children}
      </MappedStyledComponent>
    );
  else return <Fragment />;
});
