export const hoveredFillLayer: any = {
  type: 'fill',
  paint: {
    'fill-color': '#EC6649',
    'fill-opacity': 0.25,
  },
};

export const hoveredLineLayer: any = {
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#EC6649',
  },
};

export const selectedFillLayer: any = {
  type: 'fill',
  paint: {
    'fill-color': '#769DFF',
    'fill-opacity': 0.3,
  },
};

export const selectedLineLayer: any = {
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#406EE2',
  },
};

export const transparentFillLayer: any = {
  type: 'fill',
  paint: { 'fill-opacity': 0 },
};
