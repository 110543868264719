import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useMemo } from 'react';
import { TS3 } from 'constants/types';

import Modal from '.';
import { Text } from 'components/Typography';
import { Button } from 'components/Button';
import { Stack } from 'components/Stack';

import { ReactComponent as IcError } from 'constants/icon/ic_error_alert_circle.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import theme from 'constants/theme';
import useInterval from 'hooks/useInterval';
import SpinLoader from 'util/SpinLoader';
import { ButtonProps } from 'components/Button/button.interface';

export type TQueryStatus = 'error' | 'warning' | 'success' | 'loading';
export interface QueryStatusModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>> | TS3<boolean>;
  status: TQueryStatus;
  string?: string | ReactNode;
  contnet?: ReactNode;

  action?: Function;
  callback?: Function;

  autoClose?: number;

  buttonStyle?: Partial<ButtonProps>;
}

const default_content_by_status: {
  [key in TQueryStatus]: {
    text: string;
    icon: ReactElement;
  };
} = {
  warning: { text: '요청을 완료하지 못하였습니다.', icon: <IcWarning width={44} height={44} /> },
  error: { text: '요청을 완료하지 못하였습니다.', icon: <IcError width={44} height={44} /> },
  success: { text: '요청을 완료했습니다.', icon: <IcSuccess width={44} height={44} fill={theme.colors.RC02} /> },
  loading: { text: '잠시만 기다려 주세요.', icon: <SpinLoader width={44} height={44} /> },
};

const QueryStatusModal = ({
  isOpen,
  setIsOpen,
  status,
  string,
  contnet,
  autoClose = 0,
  action = () => {},
  callback = () => {},
  buttonStyle,
}: QueryStatusModalProps) => {
  const ac = useMemo<number>(() => (isOpen ? autoClose : 0), [autoClose, isOpen]);
  const flag = useMemo(() => ac === 0, [ac]);

  useInterval(
    () => {
      setIsOpen(false);
      callback();
    },
    ac,
    flag
  );

  return (
    <Modal plain isModalOpen={isOpen} setIsModalOpen={setIsOpen} width={504} padding={24}>
      {contnet ? (
        contnet
      ) : (
        <Stack spacing={30} sx={{ padding: '20px  0 40px' }}>
          {default_content_by_status[status].icon}
          <pre>
            <Text styleName="body2">{string ?? default_content_by_status[status].text}</Text>
          </pre>
        </Stack>
      )}
      {status !== 'loading' && (
        <Button
          type="button"
          variant="tertiary"
          fw
          h={40}
          {...buttonStyle}
          onClick={() => {
            action();
            setIsOpen(false);
            callback();
          }}
        >
          확인
        </Button>
      )}
    </Modal>
  );
};

export default QueryStatusModal;
