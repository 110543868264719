import { Dispatch, SetStateAction, useEffect, useMemo, useState, Fragment } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Modal from '.';
import Divider from 'components/Divider';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';

import { ReactComponent as IconClose } from 'constants/icon/btn_close.svg';
import { ReactComponent as IconDragHandle } from 'constants/icon/ic_drag_handle2.svg';
import { ReactComponent as IconFileUploadCircle } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';
import { reorderBySelection } from 'util/reorderBySelection';
import cloneDeep from 'lodash.clonedeep';

const TableColumnOrderControlModal = ({
  isOpen = false,
  setIsOpen,

  options,
  setOptions,

  initial,
  banList = [],
  setColumnOrderInIDB,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  options: TDragablePickerOptions;
  setOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;

  initial: any[];
  banList?: Array<string>;
  setColumnOrderInIDB?: (memory: TDragablePickerOptions) => void;
}) => {
  const [memory, _setMemory] = useState<TDragablePickerOptions>(options);

  const setMemory = (v: TDragablePickerOptions) => {
    _setMemory(
      v.map(d => {
        return { ...d, hidden: banList.includes(`${d.key}`) };
      })
    );
  };

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list).filter(x => !banList.includes(`${x.key}`));

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;

    setMemory(reorder(memory, source.index, destination.index));
  };

  const modalCloser = (canceler?: boolean) => {
    setIsOpen(false);
    if (canceler) setMemory([...options]);
  };

  const confirmColumnOrder = () => {
    setOptions(memory);
    setColumnOrderInIDB && setColumnOrderInIDB(memory);

    // close modal
    modalCloser();
  };

  const resetColumnOrder = () => {
    setMemory(reorderBySelection(initial));
  };

  useEffect(() => {
    console.log(options, 'options');

    setMemory(options);
  }, [options]);

  return (
    <Modal
      title=""
      isModalOpen={isOpen}
      setIsModalOpen={() => {}}
      // isLoadingModal={addSingleOrderIsLoading}
      width={302}
      height={'80vh'}
      bd
      nomt
      plain
      ds="strong"
    >
      <Fragment>
        <Stack padding={20}>
          <Stack direction="row" justify="space-between">
            <Text styleName="caption2" color="RG02">
              표에 조회할 항목을 선택합니다.
            </Text>
            <IconClose style={{ cursor: 'pointer' }} onClick={() => modalCloser(true)} />
          </Stack>
        </Stack>
        <Divider color="RG06" />
        <Stack useVerticalScroll>
          <Stack
            sx={{
              maxHeight: 366,
            }}
          >
            {useMemo(() => {
              return (
                <Stack name="content-area" padding={20}>
                  <DragDropContext
                    onDragStart={() => {
                      console.log('drag on start');
                    }}
                    onDragEnd={handleDragEnd}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}
                        >
                          {memory
                            .filter(d => !d.hidden)
                            .map((item, index) => (
                              <Draggable key={item.key} draggableId={`${item.key}`} index={index}>
                                {(provided, snapshot) => (
                                  <DraggableColumnListItemWrapper
                                    key={`${item.name}-driver-picker-item`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    onClick={() => {
                                      const data = cloneDeep(memory);
                                      let target = data.find(d => d.key === item.key);
                                      if (!target) return;
                                      target.isSelected = !item.isSelected;
                                      setMemory(data);
                                    }}
                                    isChecked={item.isSelected}
                                    isDragging={snapshot.isDragging}
                                  >
                                    <Stack justify="space-between" direction="row" className="item-wrapper">
                                      <Stack spacing={8} direction={'row'}>
                                        <IconFileUploadCircle
                                          fill={item.isSelected ? theme.colors.RC02 : theme.colors.RG05}
                                          width={14}
                                          height={14}
                                        />
                                        <Text styleName={item.isSelected ? 'caption1' : 'caption2'} color={'RG02'}>
                                          {item.name}
                                        </Text>
                                      </Stack>
                                      <div {...provided.dragHandleProps}>
                                        <IconDragHandle
                                          width={12}
                                          height={12}
                                          fill={snapshot.isDragging ? theme.colors.RG03 : theme.colors.RG05}
                                        />
                                      </div>
                                    </Stack>
                                  </DraggableColumnListItemWrapper>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              );
            }, [memory])}
          </Stack>
        </Stack>
        <Divider color="RG06" />
        <Stack justify="space-between" direction="row" spacing={12} padding={24}>
          <Button
            variant="seventh"
            type="button"
            height={26}
            width={45}
            styleName="caption2"
            color="RG03"
            onClick={resetColumnOrder}
          >
            초기화
          </Button>
          <Stack direction="row" spacing={12} sx={{ width: 'fit-content' }}>
            <Button
              variant="seventh"
              type="button"
              height={26}
              width={60}
              styleName="caption2"
              color="RG03"
              onClick={() => modalCloser(true)}
            >
              닫기
            </Button>
            <Button
              variant="default"
              type="submit"
              height={26}
              width={80}
              styleName="caption1"
              color="RG00"
              onClick={confirmColumnOrder}
            >
              저장하기
            </Button>
          </Stack>
        </Stack>
      </Fragment>
    </Modal>
  );
};

export default TableColumnOrderControlModal;

const DraggableColumnListItemWrapper = styled(Stack)<{ isDragging: boolean; isChecked: boolean }>`
  justify-content: center;

  width: 100%;
  min-height: 32px;

  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};

  & > .item-wrapper {
    padding: 5px 11px 5px 9px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
    border: ${({ theme }) => `1px solid ${theme.colors.RC03_1}`};
  }

  ${({ theme, isChecked }) =>
    isChecked && {
      background: theme.colors.RC03_1,
      border: `1px solid ${theme.colors.RC03_1}`,
    }}

  ${({ theme, isDragging }) =>
    isDragging && {
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RC03}`,

      '& > .item-wrapper': {
        background: theme.colors.RC03_1,
        borderRadius: '4px',
      },
    }};

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
