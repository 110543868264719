import { TS3 } from 'constants/types';
import type { TSettingAttrs } from 'types/setting/union';
import { create } from 'zustand';

interface ISettingStore {
  isFolded: boolean;
  setIsFolded: TS3<boolean>;
  current: TSettingAttrs;
  setCurrent: TS3<TSettingAttrs>;
  isFatalCorrectionsOnPost: boolean;
  setIsFatalCorrectionsOnPost: TS3<boolean>;
}

export const useSetting = create<ISettingStore>(set => ({
  isFolded: false,
  setIsFolded: prop => {
    if (typeof prop === 'function') set(state => ({ isFolded: prop(state.isFolded) }));
    else set({ isFolded: prop });
  },
  current: 'team',
  setCurrent: prop => {
    if (typeof prop === 'function') set(state => ({ current: prop(state.current) }));
    else set({ current: prop });
  },
  isFatalCorrectionsOnPost: false,
  setIsFatalCorrectionsOnPost: prop => {
    if (typeof prop === 'function') set(state => ({ isFatalCorrectionsOnPost: prop(state.isFatalCorrectionsOnPost) }));
    else set({ isFatalCorrectionsOnPost: prop });
  },
}));
