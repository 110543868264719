export const EXISTS_QUERIES = {
  SCOPE: [{ scope: 'EXISTS' }] as const,
  CHECK_EXISTS_MANAGER_INFO: () => [
    {
      ...EXISTS_QUERIES.SCOPE[0],
      entity: 'check_exists_manager_info',
      endPoint: `payment/check-exists-manager-info`,
    },
  ],
};
