import { Fragment, Dispatch } from 'react';
import { AlertModal } from 'components/Modal/AlertModal';
import { LoadingModal } from 'components/Modal/LoadingModal';

import { ValidateEmail } from 'auth';
import { updateModalState, getModalMessage, _updateUserData, _updateCompanyData } from 'pages/MyPage/Account/container';
import Modal from 'components/Modal';

interface IMyPageAccountModal {
  _dispatch: Dispatch<TMyPageAccountAction>;
  _state: TMyPageAccountOption;
}

export const MyPageAccountModals = (props: IMyPageAccountModal) => {
  const { _state, _dispatch } = props;
  const {
    modal: { type, isOpen },
  } = _state;

  if (isOpen === false) return <Fragment />;

  if (type.includes('Success')) {
    return (
      <AlertModal
        type="SUCCESS"
        isOpen={isOpen}
        callback={() => {
          if (type === 'UserInfoSuccess') {
            _updateUserData({ _dispatch });
          }
          if (type === 'CompanyInfoSuccess') {
            _updateCompanyData({ _dispatch });
          }
          updateModalState({ _dispatch, type: '', isOpen: false });
        }}
        autoCloseTime={3000}
        messages={getModalMessage(type)}
        buttonName="확인"
      />
    );
  }
  if (type.includes('Failed')) {
    return (
      <AlertModal
        type="ERROR"
        isOpen={isOpen}
        callback={() => updateModalState({ _dispatch, type: '', isOpen: false })}
        autoCloseTime={3000}
        messages={getModalMessage(type)}
        buttonName="확인"
      />
    );
  }
  if (type.includes('Loading')) {
    return <LoadingModal isLoading={isOpen} text={getModalMessage(type)} />;
  }
  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={() => {}} plain ds="strong">
      <ValidateEmail
        preInputLoginAccount={_state?.userInfo?.loginAccount}
        isUsedByModal={true}
        closeContent={() => updateModalState({ _dispatch, type: '', isOpen: false })}
        successCallback={() => {
          setTimeout(() => {
            updateModalState({ _dispatch, type: '', isOpen: false });
          }, 3000);
        }}
      />
    </Modal>
  );
};
