import styled from 'styled-components';

export const ChangeTeamModalLayout = styled.div`
  width: 100%;
  padding: 44px 20px 0px;
`;

export const ChangeTeamModalHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px;
`;

export const ChangeTeamModalTable = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  max-height: 216px;
  margin-left: auto;
  margin-right: auto;
`;

export const ChangeTeamModalFooter = styled.footer`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 20px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  justify-content: end;
`;

export const ManagerSelectorItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 6px;
  align-items: center;
  padding-top: 6px;
`;

export const TableCell = styled.th<{ itemWidth?: string }>`
  // display: flex;
  // height: 54px;
  width: ${({ itemWidth }) => (itemWidth ? itemWidth : 'auto')};
  padding-left: 10px;
  // align-items: center;
`;

export const TableRow = styled.td<{ itemWidth?: string }>`
  width: ${({ itemWidth }) => (itemWidth ? itemWidth : 'auto')};
  padding-left: 10px;
`;
