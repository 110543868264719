import styled from 'styled-components';

type IColor = {
  color: string;
};
interface ProgressProps extends IColor {
  /** radius */
  h?: number;
  /** radius */
  r?: number;
}

const hrCalc = (h: number, r?: number) => {
  let _h = h;
  let _r = r ?? h / 2;

  return {
    height: `${_h}px`,
    borderRadius: `${_r ?? _h / 2}px`,
  };
};

export const ProgressBackground = styled.div<ProgressProps>`
  background-color: ${({ theme, color }) => theme.colors[color] ?? color};

  position: relative;
  width: 100%;

  ${({ h, r }) => ({
    ...hrCalc(h ?? 4, r),
  })}
`;

export const Progress = styled.div<
  ProgressProps & {
    value: number;
  }
>`
  background-color: ${({ theme, color }) => theme.colors[color] ?? color};
  position: absolute;

  max-width: 100%;
  width: ${({ value }) => `${value}%`};
  height: 100%;

  transition: width 0.2s ease-out;

  ${({ h, r, value }) => ({
    ...hrCalc(h ?? 4, r),

    minWidth: `${value ? h ?? 4 : 0}px`,
  })}
`;
