import { SelectContainer, SelectItem } from 'components/Input';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';

import { ReactComponent as IconDragHandle } from 'constants/icon/ic_drag_handle2.svg';
import { ReactComponent as IconFileUploadCircle } from 'constants/icon/ic_success.svg';

import theme from 'constants/theme';
import cloneDeep from 'lodash.clonedeep';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import styled from 'styled-components';

export type TDragablePickerOptions = Array<{
  name: string;
  key: string | number;
  isSelected: boolean;
  default?: boolean;
  hidden?: boolean;
}>;

interface DragablePickerProps {
  parent: ReactNode;
  dragable?: boolean;

  top?: string;
  width?: string;
  height?: string;

  options: TDragablePickerOptions;
  setOptions: Dispatch<SetStateAction<TDragablePickerOptions>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DragablePicker = ({
  parent,
  dragable,

  top,
  width = '238px',
  height,

  options,
  setOptions,
  isOpen,
  setIsOpen,
}: DragablePickerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;

    setOptions(reorder(options, source.index, destination.index));
  };

  return (
    <Stack ref={targetRef} align="start" sx={{ width: 'fit-content', position: 'relative' }}>
      {parent}

      {isOpen && (
        <DragableSelectContainer {...{ width, top, height }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0 20px', gap: '10px' }}
                >
                  {options
                    .filter(item => !item.hidden)
                    .map((item, index) => (
                      <Draggable key={item.key} draggableId={`${item.key}`} index={index}>
                        {(provided, snapshot) => (
                          <DraggableColumnListItemWrapper
                            key={`${item.name}-driver-picker-item`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onClick={e => {
                              if (item.default) {
                                e.preventDefault();
                                e.stopPropagation();
                                return;
                              }

                              const data = cloneDeep(options);
                              let target = data.find(d => d.key === item.key);
                              if (!target) return;

                              target.isSelected = !item.isSelected;

                              setOptions(data);
                            }}
                            isChecked={item.isSelected}
                            isDragging={snapshot.isDragging}
                          >
                            <Stack justify="space-between" direction="row" className="item-wrapper">
                              <Stack spacing={8} direction={'row'}>
                                <IconFileUploadCircle
                                  fill={item.isSelected ? theme.colors.RC02 : theme.colors.RG05}
                                  width={14}
                                  height={14}
                                />
                                <Text styleName={item.isSelected ? 'caption1' : 'caption2'} color={'RG02'}>
                                  {item.name}
                                </Text>
                              </Stack>
                              <div {...provided.dragHandleProps}>
                                <IconDragHandle
                                  width={12}
                                  height={12}
                                  fill={snapshot.isDragging ? theme.colors.RG03 : theme.colors.RG05}
                                />
                              </div>
                            </Stack>
                          </DraggableColumnListItemWrapper>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DragableSelectContainer>
      )}
    </Stack>
  );
};

export default DragablePicker;

const DragableSelectContainer = styled(SelectContainer)``;

export const DargableSelectItem = styled(SelectItem)<{ isDragging: boolean }>`
  justify-content: start;
  padding: 0 20px 0 12px;
  background: ${({ theme, isDragging }) => isDragging && theme.colors.RC03_1} !important;
`;

const DraggableColumnListItemWrapper = styled(Stack)<{ isDragging: boolean; isChecked: boolean }>`
  justify-content: center;

  width: 100%;
  min-height: 32px;

  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};

  & > .item-wrapper {
    padding: 5px 11px 5px 9px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
    border: ${({ theme }) => `1px solid ${theme.colors.RC03_1}`};
  }

  ${({ theme, isChecked }) =>
    isChecked && {
      background: theme.colors.RC03_1,
      border: `1px solid ${theme.colors.RC03_1}`,
    }}

  ${({ theme, isDragging }) =>
    isDragging && {
      background: theme.colors.RG00,
      border: `1px solid ${theme.colors.RC03}`,

      '& > .item-wrapper': {
        background: theme.colors.RC03_1,
        borderRadius: '4px',
      },
    }};

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
