import { Stack } from 'components/Stack';
import { useEffect, useReducer } from 'react';
import { Text } from 'components/Typography';
import { MyPageSection } from 'pages/MyPage/common';
import theme from 'constants/theme';

import { ReactComponent as IcPersonWithoutBg } from 'constants/icon/ic_person_no_color.svg';
import { CompanyInfo, MyPageAccountModals, UserInfo } from 'pages/MyPage/Account';
import { accountInitialState, accountReducerAction } from 'pages/MyPage/Account/reducer';
import { _getRequestIndustryType, _getUserInfo } from 'pages/MyPage/Account/container';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { useStore } from 'store';
import { useTracking } from '../../../hooks/store/useTracking';

export const MyPageAccount = () => {
  const { userGrade } = useStore();
  const [_state, _dispatch] = useReducer(accountReducerAction, accountInitialState);
  const { userInfo, companyInfo } = _state;
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('마이페이지>계정정보관리');
  }, [setTitle]);

  const isLoading = [userInfo, companyInfo].every(Boolean) === false;

  useEffect(() => {
    _getUserInfo({ _dispatch });
    _getRequestIndustryType({ _dispatch });
  }, []);

  if (isLoading) return <LoadingModal isLoading={isLoading} />;

  return (
    <Stack align="start" sx={{ gap: '40px' }}>
      <MyPageAccountModals _dispatch={_dispatch} _state={_state} />
      <UserInfo _dispatch={_dispatch} _state={_state} />
      <CompanyInfo _dispatch={_dispatch} _state={_state} />
      {userGrade === 1 && (
        <MyPageSection
          sx={{ minHeight: '250px' }}
          useHeaderButton={false}
          title="계정 상태 관리"
          icon={
            <Stack
              justify="center"
              sx={{ background: theme.colors.RG07, borderRadius: '3px', width: '28px', height: '28px' }}
            >
              <IcPersonWithoutBg width={18} height={18} style={{ fill: theme.colors.RG05 }} />
            </Stack>
          }
        >
          <Stack justify="space-between" direction="row" style={{ gap: '20px', padding: '20px 20px' }}>
            <Text styleName="body2" color="RG02">
              탈퇴를 원하시면 support@wemeetmobility.com 으로 요청해주시기 바랍니다.
            </Text>
          </Stack>
        </MyPageSection>
      )}
    </Stack>
  );
};
