import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';

const DeactiveModal = ({
  icon,
  plain,
  isOpen,
  setIsOpen,
  target,
  targetName,
  text,
  highlight,
  returnFunc,
  RBT,
  LBT,
  ms,
  reverse,
  single,
  headline,
  closeCallbackFunc,
  loading,
  isLoading,
  isSuccess,
  success,
  cp,
  lock,
}: {
  icon?: ReactNode;
  plain?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  targetName?: string;
  target?: string;
  headline?: string;
  text: string | ReactNode;
  highlight: string | ReactNode;
  returnFunc: any;
  RBT: string;
  LBT?: string;
  ms?: number;
  reverse?: boolean;
  single?: boolean;
  closeCallbackFunc?: any;
  loading?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  success?: React.ReactNode;

  /** content area padding */
  cp?: string | number;
  /** disable RBT */
  lock?: boolean;
}) => {
  const closer = () => {
    closeCallbackFunc?.();
    setIsOpen(false);
  };
  return (
    <Modal
      plain={plain}
      isLoadingModal={isLoading}
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      width={504}
      padding={24}
      ms={ms !== undefined ? ms : 5}
    >
      {loading && isSuccess && success ? (
        success
      ) : loading && isLoading ? null : (
        <Stack name="delete-vehicle-modal">
          {icon && icon}
          <Stack name="content-area" spacing={16} sx={{ padding: cp ?? '30px 0 40px 0' }}>
            <Stack spacing={10}>
              {headline && (
                <Text styleName="title2" color="RG02">
                  {headline}
                </Text>
              )}
              <Stack name="text-area" direction={reverse ? 'column-reverse' : 'column'}>
                <Text styleName="subheadline2" color="RG03" sx={{ whiteSpace: 'pre-wrap' }}>
                  {text}
                </Text>
                <Text styleName="subheadline2" color="RC04" sx={{ whiteSpace: 'pre-wrap' }}>
                  {highlight}
                </Text>
              </Stack>
            </Stack>
            {targetName ? (
              <Text styleName="subheadline2" color="RG04">
                {targetName}
              </Text>
            ) : (
              target && (
                <Text styleName="subheadline2" color="RG04">
                  {target}
                </Text>
              )
            )}
          </Stack>
          {single ? (
            <Stack>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  returnFunc({ ...{ target } });

                  !loading && closer();
                }}
              >
                <Text styleName="body2" color="RG00">
                  {RBT}
                </Text>
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" spacing={16}>
              <Button
                variant={'second'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  closer();
                }}
              >
                <Text styleName="body1" color="RG03">
                  {LBT ? LBT : '취소'}
                </Text>
              </Button>
              <Button
                disabled={lock}
                styleName="body1"
                variant={'custom'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                custom={{
                  init: {
                    color: 'RG00',
                    bgColor: 'RC02',
                  },
                  hover: {
                    color: 'RG00',
                    bgColor: 'RC01',
                  },
                  disabled: {
                    color: 'RG04',
                    bgColor: 'RG07',
                  },
                }}
                onClick={() => {
                  returnFunc({ ...{ target } });
                  !loading && closer();
                }}
              >
                {RBT}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Modal>
  );
};

export default DeactiveModal;
