import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, UseFormReturn } from 'react-hook-form';

import { csAlert, getAreaList, hasOptimizedDriver as hasOptimizedDriver_query, runEqualOptimize } from 'api';
import { IOrder, IOrderManagementContent, TDistAreaType, TEqualizeBy } from 'constants/types';

import Divider from 'components/Divider';
import { Button } from 'components/Button/legacy_index';
import { Button as NButton } from 'components/Button';
import { Stack } from '../components/Stack';
import { Highlight, Text } from '../components/Typography';
import PageContentWrapper from 'components/PageContentWrapper';
import PlanOptions from './RoutePlanSetup/PlanOptions';
import OrderSelect from './RoutePlanSetup/OrderSelect';
import DriverSelect from './RoutePlanSetup/DriverSelect';
import { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import { useLocation, useNavigate } from 'react-router';
import { removeAllTemporaryOptimizeDataList } from 'util/sessionStorageManager';
import { useGNBStore, useSettingStore, useStore } from 'store';
import RunOptimizeModal from 'components/Modal/RunOptimizeModal';
import Modal from 'components/Modal';
import { ReactComponent as IcError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';

import { ReactComponent as IcCheck } from 'constants/icon/ic_check.svg';
import theme from 'constants/theme';
import { route_origin_session_key } from 'constants/commons';
import { PaginationState } from '@tanstack/react-table';
import { PlanStatusTag } from 'components/Tag/new/PlanStatus';
import { subscribe_quota } from 'constants/subscribePlans';
import { PlanStatusUnion } from 'types/payment/union';
import { useTracking } from '../hooks/store/useTracking';
import HasOptimizedDriverWarningModal from 'components/Modal/HasOptimizedDriverWarningModal';

export type FormValues = {
  distAreaType: TDistAreaType; // 권역배차 여부
  // `none` 혹은 생략 : 기존 무권역 배차
  // `dedicated` : 고정 권역 배차
  // `flexible` : 유연 권역 배차
  equalizeBy: TEqualizeBy; // 균등 조건
  // `worktime` 혹은 생략 : 기존 시간 균등 배차
  // `workload` : 업무량 균등 배차

  name: string; // 최적화 이름
  performedDate: string; // 최적화 일자 ( YYYY-MM-DD )
  performedTime: string; // 최적화 시간 ( HH:mm )
  workEndTime: string; // 작업 종료 시간 ( HH:mm )
  orderList: Array<IOrder>; // 주문 id 목록
  driverList: Array<{ driverId: number; vehicleId: number }>; // 드라이버 목록;
};

// for side & optizmodal
export const keyConverter = ({ key }: { key: TDistAreaType | TEqualizeBy }): string => {
  // console.log(key);

  const mapper: { [key in TDistAreaType | TEqualizeBy]: string } = {
    none: '권역 없이 배차',
    dedicated: '기본 권역 배차',
    flexible: '유연한 권역 배차',
    worktime: '업무 시간',
    workload: '주문 개수',
  };
  return mapper[key as TDistAreaType | TEqualizeBy];
};

const StepNavigation = ({
  methods,
  currentStepIndex,
  setCurrentStepIndex,
  setPlanOptionErrorCallbackModalIsOpen,
}: {
  methods: UseFormReturn<FormValues>;
  currentStepIndex: number;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
  setPlanOptionErrorCallbackModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const active: { [key in keyof typeof stepContents]: boolean } = {
    0: Boolean(methods.getValues('name')) && methods.getValues('name') !== '',
    1: methods.getValues('orderList').length >= 1,
    2: methods.getValues('driverList').length >= 1,
  };

  const planOptionHasError = (): boolean => {
    return (
      Boolean(methods.formState.errors.name) ||
      Boolean(methods.formState.errors.performedDate) ||
      Boolean(methods.formState.errors.performedTime) ||
      Boolean(methods.formState.errors.workEndTime)
    );
  };

  return (
    <StepNavigationContainer spacing={30} divider={<Divider color="RG06" />}>
      <Stack name="steps" spacing={20}>
        {Object.values(stepContents).map((d, i) => {
          const isSet = active[i];
          const isActive = i === currentStepIndex;
          return (
            <StepItem
              key={`step-item-${i}`}
              direction="row"
              justify="space-between"
              isSet={isSet}
              isActive={isActive}
              onClick={e => {
                if (planOptionHasError()) setPlanOptionErrorCallbackModalIsOpen(true);
                else setCurrentStepIndex(i);
              }}
            >
              <Stack direction="row" spacing={10}>
                <StepItemIndex>{i + 1}</StepItemIndex> {d.title}
              </Stack>
              {isSet && <IcCheck width={20} height={20} />}
            </StepItem>
          );
        })}
      </Stack>
      <Stack name="summary" spacing={20} divider={<Divider color="RG06" />}>
        <Stack name="summary-perform-options" spacing={16} align="start">
          <Text styleName="subheadline1" color="RG02">
            {methods.watch('name')}
          </Text>
          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              주행 일자
            </Text>
            <Text styleName="body1" color="RG02">
              {methods.watch('performedDate')}
            </Text>
          </SpaceBetween>
          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              주행 시작 시간 지정
            </Text>
            <Text styleName="body1" color="RG02">
              {methods.watch('performedTime')}
            </Text>
          </SpaceBetween>
          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              주행 종료 시간 지정
            </Text>
            <Text styleName="body1" color="RG02">
              {methods.watch('workEndTime')}
            </Text>
          </SpaceBetween>
        </Stack>
        <Stack name="summary-route-options" spacing={16}>
          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              권역 배차
            </Text>
            <Text styleName="subheadline1" color="RG02">
              {methods.watch('distAreaType')
                ? keyConverter({ key: methods.watch('distAreaType') as TDistAreaType })
                : '-'}
            </Text>
          </SpaceBetween>

          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              균등 배차
            </Text>
            <Text styleName="subheadline1" color="RG02">
              {methods.watch('equalizeBy') ? keyConverter({ key: methods.watch('equalizeBy') as TEqualizeBy }) : '-'}
            </Text>
          </SpaceBetween>
        </Stack>
        <Stack name="summary-driver" spacing={16}>
          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              선택된 주문
            </Text>
            <Text styleName="body1" color="RG02">
              {methods.watch('orderList').length}
              <Highlight styleName="subheadline2">&nbsp;건</Highlight>
            </Text>
          </SpaceBetween>

          <SpaceBetween>
            <Text styleName="subheadline2" color="RG02">
              선택된 드라이버
            </Text>
            <Text styleName="body1" color="RG02">
              {methods.watch('driverList').length}
              <Highlight styleName="subheadline2">&nbsp;명</Highlight>
            </Text>
          </SpaceBetween>
        </Stack>
      </Stack>
      <Stack name="btns" spacing={10}>
        {currentStepIndex > 0 && (
          <NButton type="button" variant="tertiary" fw h={40} onClick={e => setCurrentStepIndex(prev => prev - 1)}>
            이전으로 이동
          </NButton>
        )}
        {currentStepIndex + 1 !== Object.keys(stepContents).length && (
          <NButton
            type="button"
            variant="secondary"
            fw
            h={40}
            disabled={planOptionHasError()}
            onClick={e => setCurrentStepIndex(prev => prev + 1)}
          >
            다음으로 이동
          </NButton>
        )}
        <NButton
          variant="primary"
          type={'submit'}
          fw
          h={40}
          disabled={
            !(
              methods.getValues('orderList').length >= 1 &&
              methods.getValues('driverList').length >= 1 &&
              Boolean(methods.getValues('name')) &&
              methods.getValues('name') !== ''
            )
          }
        >
          배차 결과 보기
        </NButton>
      </Stack>
    </StepNavigationContainer>
  );
};

const stepContents: {
  [key: number]: {
    title: string;
    content: (props: any) => ReactNode;
  };
} = {
  0: { title: '배차 옵션 설정', content: props => <PlanOptions methods={props.methods} /> },
  1: {
    title: '주문 선택',
    content: props => (
      <OrderSelect
        initFilter={props.initFilter}
        initOrders={props.initOrders}
        methods={props.methods}
        pagination={props.pagination}
        setPagination={props.setPagination}
        selectedOrder={props.selectedOrder}
        setSelectedOrder={props.setSelectedOrder}
        setCurrentStepIndex={props.setCurrentStepIndex}
      />
    ),
  },
  2: {
    title: '드라이버 선택',
    content: props => (
      <DriverSelect
        methods={props.methods}
        selectedDrivers={props.selectedDrivers}
        setSelectedDrivers={props.setSelectedDrivers}
      />
    ),
  },
};
export type TPlanMap = {
  grade: number;
  key: PlanStatusUnion;
};

const RoutePlanSetupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryStates: {
    index: number | Array<number>;
    orders: Array<IOrder>;
    getProps: Pick<IOrderManagementContent, 'filter'>;
  } | null = location.state as any;
  const defaultDate = dayjs();
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      performedDate: dayjs(defaultDate).format('YYYY.MM.DD'),
      performedTime: '없음',
      workEndTime: '없음',
      equalizeBy: 'worktime',
      distAreaType: 'none',
      driverList: [],
      orderList: [],
    },
  });

  const { setTemporaryOptimizeData, removeTemporaryOptimizeData } = useStore();

  const { SETSETTINGOPEN, SETTINGOPEN } = useGNBStore();
  const { SETCURRENT } = useSettingStore();

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [completedPercent, setCompletedPercent] = useState<number>(0);
  const [noAreaModalIsOpen, setNoAreaModalIsOpen] = useState<boolean>(false);
  const [runEqualOptimizeProgressModal, setRunEqualOptimizeProgressModal] = useState<boolean>(false);
  const [subscriptionLimitModal, setSubscriptionLimitModal] = useState<boolean>(false);
  const [subscriptionLimitReason, setSubscriptionLimitReason] = useState<'quota' | 'peronce'>('peronce');
  const [planOptionErrorCallbackModalIsOpen, setPlanOptionErrorCallbackModalIsOpen] = useState<boolean>(false);
  const [hasOptimizedDriver, setHasOptimizedDriver] = useState<boolean>(false);

  const navigator = useNavigate();

  const { id: pricing_id, status: pricing_status } = JSON.parse(localStorage.getItem('pricing')!);

  const gradeMap = new Map([
    [1, { grade: 1, e: 'owner', k: '오너' }],
    [2, { grade: 2, e: 'manager', k: '매니저', tagStyle: { color: 'RC10', borderColor: 'RC10' } }],
  ]);

  const planMap = new Map<number, TPlanMap>([
    [1, { grade: 1, key: 'Free' }],
    [5, { grade: 5, key: 'Lite' }],
    [6, { grade: 6, key: 'Standard' }],
    [7, { grade: 7, key: 'Pro' }],
    [8, { grade: 8, key: 'Enterprise' }],
    [9, { grade: 5, key: 'Lite' }],
    [10, { grade: 6, key: 'Standard' }],
    [11, { grade: 7, key: 'Pro' }],
    [12, { grade: 8, key: 'Enterprise' }],
  ]);

  const { data: settedAreaList, refetch: refetchSettedAreaList } = useQuery(
    ['getSettedAreaList'],
    () => getAreaList({}),
    {}
  );
  const { mutate: mutateHasOptimizedDriver } = useMutation(
    (res: FormValues) =>
      hasOptimizedDriver_query(
        dayjs(res.performedDate).format('YYYY-MM-DD'),
        res.driverList.map(d => d.driverId)
      ),
    {
      onSuccess(data, variables, context) {
        data.isOptimized ? setHasOptimizedDriver(true) : onSubmit(variables);
      },
    }
  );
  const {
    data: mutateRunEqualOptimizeResponses,
    mutate: mutateRunEqualOptimize,
    status: mutateRunEqualOptimizeStatus,
  } = useMutation(runEqualOptimize, {
    onMutate: data => {
      removeAllTemporaryOptimizeDataList();

      removeTemporaryOptimizeData();
      setCompletedPercent(0);
      setRunEqualOptimizeProgressModal(true);
    },
    onError: (e: any) => {
      let eName = e.response?.data.error.name;

      if (eName === 'UsageLimit') {
        setSubscriptionLimitModal(true);
        setSubscriptionLimitReason('quota');
        return;
      } else if (eName === 'RequestOrderLimit') {
        setSubscriptionLimitModal(true);
        setSubscriptionLimitReason('peronce');
        return;
      }

      if ((e.response?.status as number) >= 500) {
        let confirmAction = window.confirm('에러 정보를 개발팀에 공유?');
        if (confirmAction) {
          csAlert({
            historyId: e?.response?.data?.error?.advice,
            uri: e.response?.config.url,
            type: 'algorithmError',
          });
        } else {
          //
        }
      }
      // window.prompt(
      //   `균등 배차 에러 \n\n에러 historyId : ${e?.response?.data?.error?.advice}\n에러 코드 : ${e?.response?.data?.error?.number}\n\n\n 아래 historyId 를 복사하세요`,
      //   e?.response?.data?.error?.advice
      // );
    },
    onSuccess: (res, { props, params }) => {
      console.log('success');

      setTimeout(() => {
        let key = `${Date.now()}`;
        setTemporaryOptimizeData({
          key: key,
          data: res,
          name: methods.getValues('name'),
          originName: methods.getValues('name'),

          routeOptions: { ...params },
        });

        sessionStorage.setItem(
          route_origin_session_key,
          JSON.stringify({
            ...props,
            name: methods.getValues('name'),
          })
        );
        methods.reset();
        navigate(`../confirm/${key}`, { replace: true });
      }, 50);
    },
    onSettled: (data, error, variables, context) => {
      setRunEqualOptimizeProgressModal(false);
    },
  });
  const { setTitle } = useTracking();

  useEffect(() => {
    switch (currentStepIndex) {
      case 0:
        setTitle('배차계획>자동 최적화 배차>배차옵션설정');
        break;
      case 1:
        setTitle('배차계획>자동 최적화 배차>주문선택');
        break;
      case 2:
        setTitle('배차계획>자동 최적화 배차>드라이버선택');
        break;
      default:
        break;
    }
  }, [setTitle, currentStepIndex]);

  const onSubmit = (res: FormValues) => {
    console.log(res, 'res on submit');

    mutateRunEqualOptimize({
      props: {
        performedDate: dayjs(res.performedDate).format('YYYY-MM-DD'),
        performedTime: res.performedTime === '없음' ? undefined : res.performedTime,
        workEndTime: res.workEndTime === '없음' ? undefined : res.workEndTime,
        orderList: res.orderList.map(d => d.orderId),
        driverList: res.driverList.map(d => {
          return {
            driverId: d.driverId,
            // @ts-ignore
            vehicleId: d.vehicle?.vehicleId ?? d.vehicleId,
          };
        }),
      },
      params: {
        distAreaType: res.distAreaType,
        equalizeBy: res.equalizeBy,
      },
      config: {},
    });
  };
  const onError = (err: any) => {
    console.log(err);
  };

  useEffect(() => {
    if (methods.watch('distAreaType') === 'none') return;

    async function refetch() {
      await refetchSettedAreaList();
      if (settedAreaList && settedAreaList?.areaList.length > 0) return;
      else {
        setNoAreaModalIsOpen(true);
        methods.setValue('distAreaType', 'none', { shouldDirty: true });
      }
    }

    refetch();
  }, [methods.watch('distAreaType'), settedAreaList]);

  useEffect(() => {
    refetchSettedAreaList();
  }, [SETTINGOPEN]);

  const pagination_default = {
    pageIndex: 0,
    pageSize: 10,
  };
  const [pagination, setPagination] = useState<PaginationState>(pagination_default);

  const [selectedDrivers, setSelectedDrivers] = useState<Array<{ driverId: number; vehicleId: number }>>(
    methods.getValues('driverList')
  );
  const [selectedOrder, setSelectedOrder] = useState<Array<IOrder>>(methods.getValues('orderList'));

  useEffect(() => {
    methods.setValue('orderList', selectedOrder, { shouldDirty: true });
  }, [selectedOrder]);
  useEffect(() => {
    methods.setValue('driverList', selectedDrivers, { shouldDirty: true });
  }, [selectedDrivers]);

  useEffect(() => {}, []);

  // clear route state
  useEffect(() => {
    if (queryStates?.orders) setSelectedOrder(queryStates.orders);
    const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!) ?? null;
    if (origin) {
      setSelectedOrder(
        origin?.orderList.map((d: number) => {
          return { orderId: d };
        }) ?? []
      );

      setSelectedDrivers(origin?.driverList ?? []);

      if (origin?.performedTime) {
        methods.setValue('performedTime', origin?.performedTime, { shouldDirty: true });
      }
      if (origin?.workEndTime) {
        methods.setValue('workEndTime', origin?.workEndTime, { shouldDirty: true });
      }
      methods.setValue('name', origin?.name, { shouldDirty: true });
      methods.setValue('performedDate', origin?.performedDate?.replaceAll('-', '.'), { shouldDirty: true });
    }
    sessionStorage.clear();
    navigate(location.pathname, { replace: true });
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (!queryStates?.index) return;
    typeof queryStates?.index === 'number'
      ? setCurrentStepIndex(queryStates?.index)
      : queryStates.index.forEach(idx => {
          setCurrentStepIndex(idx);
        });
  }, [queryStates?.index]);

  return (
    <PageContentWrapper>
      {/* {useMemo(() => {
        return (

        );
      }, [ currentStepIndex, methods.getValues(), queryStates?.orders])} */}

      <form onSubmit={methods.handleSubmit(res => mutateHasOptimizedDriver(res), onError)}>
        <Stack
          justify="start"
          align="start"
          padding={48}
          spacing={20}
          direction="row"
          useVerticalScroll
          useHorizontalScroll
          className="drag-ban"
          sx={{ height: '100%' }}
        >
          <StepNavigation
            {...{ methods, currentStepIndex, setCurrentStepIndex, setPlanOptionErrorCallbackModalIsOpen }}
          />

          <Stack sx={{ minWidth: 1200 }}>
            {stepContents[currentStepIndex].content({
              initOrders: queryStates?.orders,
              initFilter: queryStates?.getProps,
              ...{
                methods,
                pagination,
                setPagination,
                selectedDrivers,
                setSelectedDrivers,
                selectedOrder,
                setSelectedOrder,
                setCurrentStepIndex,
              },
            })}
          </Stack>
        </Stack>
      </form>

      <CloseConfirmModal
        btnFunctions={{
          RBT: () => {
            SETCURRENT('area');
            SETSETTINGOPEN(true);
          },
        }}
        width={503}
        isOpen={noAreaModalIsOpen}
        setIsOpen={setNoAreaModalIsOpen}
        targetSetIsOpen={() => {}}
        text={
          <Stack spacing={10}>
            <Text color="RG02" styleName="title2">
              저장된 권역이 없습니다.
            </Text>
            <Text color="RG02" styleName="subheadline2">
              권역을 추가하신 뒤 다시 시도해주세요.
            </Text>
          </Stack>
        }
        RBT={'권역 설정 하기'}
        LBT={'닫기'}
      />

      {runEqualOptimizeProgressModal && (
        <RunOptimizeModal
          flag={mutateRunEqualOptimizeStatus === 'success' || mutateRunEqualOptimizeStatus === 'error'}
          weight={methods.getValues('orderList').length}
          {...{
            info: {
              name: methods.getValues('name'),
              date: methods.getValues('performedDate'),
              time: methods.getValues('performedTime'),
              workEndTime: methods.getValues('workEndTime'),
              totalDriver: `${methods.getValues('driverList').length} 명`,
              totalOrder: `${methods.getValues('orderList').length} 개`,
              distAreaType: keyConverter({ key: methods.getValues('distAreaType') }),
              equalizeBy: keyConverter({ key: methods.getValues('equalizeBy') }),
            },

            completedPercent,
            setCompletedPercent,
            runEqualOptimizeProgressModal,
            setRunEqualOptimizeProgressModal,
          }}
        />
      )}

      <Modal isModalOpen={subscriptionLimitModal} setIsModalOpen={setSubscriptionLimitModal} padding={24} width={503}>
        <Stack>
          <Stack>
            <IcWarning width={44} height={44} />
            <Text
              styleName="body1"
              color="RC04"
              sx={{
                padding: '30px 0 8px 0',
              }}
            >
              {subscriptionLimitReason === 'peronce'
                ? '1회당 배차 가능한 경유지 수를 초과하였습니다.'
                : '배차 가능한 누적 경유지 수를 초과하였습니다.'}
            </Text>
            <Stack justify="center" direction="row" spacing={8} sx={{ marginBottom: '40px' }}>
              {pricing_id && <PlanStatusTag text={planMap.get(pricing_id)?.key} />}

              <Text styleName="body2" color="RG02">
                {subscriptionLimitReason === 'peronce'
                  ? subscribe_quota[planMap.get(pricing_id)?.key ?? 'Free'].perOnce
                  : subscribe_quota[planMap.get(pricing_id)?.key ?? 'Free'].quota}
                개 제한
              </Text>
            </Stack>
          </Stack>
          <NButton
            type={'button'}
            variant="primary"
            h={40}
            sx={{ width: '100%' }}
            onClick={() => {
              navigator('/mypage', { state: { head: 'usage-and-plan' } });
            }}
          >
            <Text styleName="body1" color="RG00">
              요금제 업그레이드 하기
            </Text>
          </NButton>
          <NButton
            type={'button'}
            variant="tertiary"
            h={40}
            sx={{ width: '100%', marginTop: '20px' }}
            onClick={() => {
              setSubscriptionLimitModal(false);
            }}
          >
            <Text styleName="body1" color="RG03">
              확인
            </Text>
          </NButton>
        </Stack>
      </Modal>

      <Modal
        isModalOpen={planOptionErrorCallbackModalIsOpen}
        setIsModalOpen={setPlanOptionErrorCallbackModalIsOpen}
        padding={24}
        width={503}
      >
        <Stack>
          <Stack>
            <IcError width={44} height={44} fill={theme.colors.RC04} />
            <Text
              styleName="subheadline2"
              color="RG02"
              sx={{
                padding: '30px 0 40px 0',
              }}
            >
              배차 이름 또는 주행 일자에 오류가 있습니다.
              <br />
              확인 후 다시 시도해주세요.
            </Text>
          </Stack>
          <Button
            variant={'second'}
            type={'button'}
            fullWidth
            sx={{ padding: '8px 10px' }}
            onClick={() => {
              setPlanOptionErrorCallbackModalIsOpen(false);
            }}
          >
            <Text styleName="body2" color="RG03">
              확인
            </Text>
          </Button>
        </Stack>
      </Modal>

      <HasOptimizedDriverWarningModal
        isOpen={hasOptimizedDriver}
        setIsOpen={setHasOptimizedDriver}
        onClickFunc={methods.handleSubmit(res => {
          console.log(res, 'fioaejiaaf');
          onSubmit(res);
        }, onError)}
      />
    </PageContentWrapper>
  );
};

export default RoutePlanSetupPage;

const StepNavigationContainer = styled(Stack)`
  width: 288px;
  min-width: 288px;
  height: max-content;
  padding: 20px;
  border-radius: 8px;
  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
`;

const StepItemIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  ${({ theme }) => ({
    color: theme.colors.RG00,
    backgroundColor: theme.colors.RG05,
  })}
  ${({ theme }) => theme.fontStyle.caption1}
`;

const StepItem = styled(Stack)<{ isSet?: boolean; isActive?: boolean }>`
  padding: 9px 10px;
  border-radius: 8px;

  ${({ theme, isActive, isSet }) => ({
    color: isActive || isSet ? theme.colors.RC02 : theme.colors.RG02,
    backgroundColor: isActive ? theme.colors.RC03_1 : theme.colors.RG00,
  })}
  & > * > ${StepItemIndex} {
    ${({ theme, isActive, isSet }) => ({
      backgroundColor: isActive || isSet ? theme.colors.RC02 : theme.colors.RG05,
    })}
  }

  cursor: pointer;
  ${({ theme, isActive }) => theme.fontStyle[isActive ? 'subheadline1' : 'subheadline2']}
`;

const SpaceBetween = styled(Stack)`
  flex-direction: row;
  gap: 10px;

  & > * {
    :first-child {
      flex: 1;
    }
  }
`;
