import TableComponent from '../index';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Button } from '../../Button';
import { Stack } from '../../Stack';
import { FetchPaymentHistoryData, useGetPaymentHistory } from '../../../hooks/query/payment/useGetPaymentHistory';
import dayjs from 'dayjs';
import { numberWithCommas } from '../../../util/numberWithCommas';
import { PaymentTypeUnion, PlanStatusUnion } from '../../../types/payment/union';
import { PlanStatusTag } from '../../Tag/new/PlanStatus';
import { PaymentTypeTag } from '../../Tag/new/PaymentType';

interface PaymentHistoryTableProps {
  setReceiptId: Dispatch<SetStateAction<number>>;
}

dayjs.locale('ko');

const paymentHistoryTable = createColumnHelper<FetchPaymentHistoryData>();

export const PaymentHistoryTable = ({ setReceiptId }: PaymentHistoryTableProps) => {
  const { data: paymentHistory = [] } = useGetPaymentHistory();
  const [sorting, setSorting] = useState<SortingState>([{ id: 'status', desc: false }]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      paymentHistoryTable.accessor(row => row.startDateOfUse, {
        id: 'date',
        header: () => '이용 기간',
        enableSorting: true,
        enableMultiSort: true,
        cell: ({ row }) => {
          const startDate = dayjs(row.original.startDateOfUse).format('YYYY.MM.DD');
          const endDate = dayjs(row.original.endDateOfUse).format('YYYY.MM.DD');

          return `${startDate} ~ ${endDate}`;
        },
      }),
      paymentHistoryTable.accessor(row => row.pricing, {
        id: 'itemType',
        header: () => '요금제',
        enableSorting: true,
        enableMultiSort: true,
        cell: ({ row }) => {
          const { name, interval } = row.original.pricing;
          return (
            <Stack spacing={20} direction="row">
              <PlanStatusTag text={name as PlanStatusUnion} />
              <PaymentTypeTag text={interval as PaymentTypeUnion} />
            </Stack>
          );
        },
      }),
      paymentHistoryTable.accessor(row => `${numberWithCommas(row.actualAmount)}원`, {
        id: 'price',
        header: () => '결제 금액(부가세 포함)',
        enableSorting: true,
        enableMultiSort: true,
      }),
      paymentHistoryTable.accessor(row => row.payMethod, {
        id: 'payMethod',
        header: () => '결제 수단',
        enableSorting: true,
        enableMultiSort: true,
      }),
      paymentHistoryTable.accessor(row => row.pricing, {
        id: 'date',
        header: () => '결제일',
        enableSorting: true,
        enableMultiSort: true,
        cell: ({ row }) => dayjs(row.original.paidDate).format('YYYY.MM.DD'),
      }),
      paymentHistoryTable.display({
        id: 'receipt',
        header: () => '요금명세서',
        enableSorting: true,
        enableMultiSort: true,
        cell: ({ row }) => (
          <Button
            variant="tertiary"
            h={24}
            onClick={() => {
              setReceiptId(row.original.paymentHistoryId);
              window.setTimeout(() => window.scrollTo(0, 0), 0);
            }}
          >
            상세보기
          </Button>
        ),
      }),
    ],
    [setReceiptId]
  );

  const instance = useReactTable({
    data: paymentHistory,
    columns,
    state: {
      sorting,
      pagination,
    },
    initialState: {},
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableComponent
      table={instance}
      isDataFetching={false} /* TODO:: reactQuery > isFetching 연결 */
      clearBottom
      disableOptions={{
        totalCount: true,
        selectCount: true,
        pageSizeSelector: true,
      }}
      {...{ pagination, setPagination }}
    />
  );
};
