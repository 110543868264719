import { TStyleName } from 'constants/types';
import { ComponentPropsWithRef, CSSProperties, forwardRef, ComponentPropsWithoutRef, ReactElement } from 'react';
import * as S from './style';

export interface TypographyProps extends ComponentPropsWithRef<'p'> {
  /**
   * 텍스트 색  */
  color?: string;
  ws?: string;
  /**
   * 텍스트 스타일 */
  styleName: TStyleName | 'error';

  /**
   * 텍스트 공백 설정 */
  broken?: boolean;

  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'justify-all' | 'match-parent';

  decoration?: 'underline';
  sx?: CSSProperties;
}
export interface IHighlightProps
  extends ComponentPropsWithoutRef<'span'>,
    Partial<Pick<TypographyProps, 'color' | 'styleName' | 'broken' | 'sx'>> {}

export const Text = forwardRef<any, TypographyProps>(({ broken = true, children, sx, ...props }, ref): ReactElement => {
  if (props.styleName === 'error')
    return (
      <S.Error {...{ broken }} {...props} style={sx} ref={ref}>
        {children}
      </S.Error>
    );
  else
    return (
      <S.Typo {...{ broken }} {...props} style={sx} ref={ref}>
        {children}
      </S.Typo>
    );
});

export const Highlight = ({ broken = true, sx, children, ...props }: IHighlightProps) => {
  return (
    <S.Highlight {...{ broken }} {...props} style={sx}>
      {children}
    </S.Highlight>
  );
};
