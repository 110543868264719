export const MANAGER_MENUS: { title: string; key: TManagerMenu }[] = [
  { title: '소속 매니저', key: 'Involved' },
  { title: '초대한 매니저', key: 'Invited' },
];

export const INVOLVED_MANAGER_FILTER: { key: string; value: TManagerFilter }[] = [
  { key: '소속 팀', value: 'teamName' },
  { key: '이름', value: 'userName' },
  { key: '이메일', value: 'loginAccount' },
];

export const INVITED_MANAGER_FILTER: { key: string; value: TManagerFilter }[] = [
  { key: '소속 팀', value: 'teamName' },
  { key: '이메일', value: 'loginAccount' },
];

export const INVOLVED_MANGER = [
  { text: '이름', key: 'name', itemWidth: '100px' },
  { text: '소속 팀', key: 'teamName', itemWidth: '40%' },
  { text: '이메일', key: 'loginAccount', itemWidth: '40%' },
];
export const INVITED_MANGER = [
  { text: '소속 팀', key: 'teamName', itemWidth: '250px' },
  { text: '이메일', key: 'loginAccount', itemWidth: '50%' },
];
