import { ResetPassword, SignIn, SignUp, SignUpManager, ValidateEmail } from 'auth';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { Layout } from 'Layout/Layout';
import ImgViewer from 'pages/ImgViewer';

import Landing from 'pages/Landing';
import ManualRoutingPage from 'pages/ManualRoutingPage';

import { MyPage } from 'pages/MyPage';
import NoMatch from 'pages/NoMatch';
import OrderManagePage from 'pages/OrderManagePage';
import { RouteReport } from 'pages/Report/route';

import RouteControlPage from 'pages/RouteControlPage';
import RoutePlanLandingPage from 'pages/RoutePlanLandingPage';
import RoutePlanSetupPage from 'pages/RoutePlanSetupPage';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useRoutes } from 'react-router-dom';

import GradeAuthorization from 'util/GradeAuthorization';
import strings from 'util/Localization';

import GNB from './components/GNB';
import RoutePlanConfirm from './pages/RoutePlanConfirm';

const ConfigRouters = () => {
  const { data: paymentMyData, refetch: fetchGetPaymentMy } = useGetPaymentMy({ enabled: false });

  strings.setLanguage((window.localStorage.getItem('serviceDomain') as string) ?? 'roouty');

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      fetchGetPaymentMy();
    }
  }, [fetchGetPaymentMy]);

  useEffect(() => {
    if (paymentMyData?.paymentStatus === 'paused') {
      navigate('/mypage', { state: { head: 'payment' } });

      return;
    }
  }, [paymentMyData?.paymentStatus, navigate]);

  const { pathname } = useLocation();
  let authToken = localStorage.getItem('accessToken');
  const isSitemapPath = pathname.includes('sitemap');

  return useRoutes([
    {
      path: '/resetpassword/:key',
      element: authToken ? <GNB /> : <Layout />,
      children: [{ path: '', element: <ResetPassword /> }],
    },
    {
      path: '/',
      element: authToken || isSitemapPath ? <GNB /> : <Navigate to={'/landing'} />,
      children: [
        {
          path: 'manage',
          children: [
            {
              path: 'route',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <RoutePlanLandingPage />,
                },
                {
                  path: 'optimize',
                  element: <RoutePlanSetupPage />,
                },
                {
                  path: 'manual',
                  element: <ManualRoutingPage />,
                },
                {
                  path: 'confirm/:key',
                  element: <RoutePlanConfirm />,
                },

                // { path: '', element: <Navigate to={'history'} /> },
              ],
            },
            {
              path: 'control',
              element: (
                <GradeAuthorization level={2}>
                  <RouteControlPage />
                </GradeAuthorization>
              ),
            },
            {
              path: 'control/:status',
              element: (
                <GradeAuthorization level={2}>
                  <RouteControlPage />
                </GradeAuthorization>
              ),
            },
            {
              path: 'order',
              children: [
                {
                  path: 'auto', // manual or auto
                  element:
                    JSON.parse(localStorage.getItem('pricing') as string)?.id === 2 ? (
                      <Navigate to="../manual/all" />
                    ) : (
                      <OrderManagePage type="auto" />
                    ),

                  children: [
                    {
                      path: ':status',
                      element: <OrderManagePage type="auto" />,
                    },
                  ],
                },
                {
                  path: 'manual', // manual or auto
                  element: <OrderManagePage type="manual" />,

                  children: [
                    {
                      path: ':status',
                      element: <OrderManagePage type="manual" />,
                    },
                  ],
                },
                { path: '', element: <Navigate to="auto" /> },
              ],
            },
            {
              path: 'report',
              children: [
                {
                  path: 'route',
                  element: (
                    <RouteReport
                      title="전체 주행 기록"
                      description="전체 주행 기록을 관리하고 현황을 확인합니다."
                      type="route"
                    />
                  ),
                },
                {
                  path: 'route/driver',
                  element: (
                    <RouteReport
                      title="드라이버별 주행 기록"
                      description="드라이버별 주행 기록을 관리하고 현황을 확인합니다."
                      type="driver"
                    />
                  ),
                },
                // {
                //   path: 'order',
                //   element: <Order />,
                // },

                { path: '', element: <Navigate to="driving" /> },
              ],
            },
            { path: '', element: <Navigate to={'order'} /> },
          ],
        },
        { path: 'resetpassword/:key', element: <ResetPassword /> },
        { path: 'mypage', element: <MyPage /> },
        { path: '/', element: <Navigate to={'manage/order'} /> },

        // {
        //   path: 'setting',

        //   element: (
        //     <GradeAuthorization level={2} pass={[4]}>
        //       <SettingNav />
        //     </GradeAuthorization>
        //   ),
        //   children: [
        //     {
        //       path: 'account',
        //       element: (
        //         <GradeAuthorization level={2} pass={[4]}>
        //           <Account />
        //         </GradeAuthorization>
        //       ),
        //     },
        //     {
        //       path: 'company',
        //       element: (
        //         <GradeAuthorization level={2} pass={[4]}>
        //           <Company />
        //         </GradeAuthorization>
        //       ),
        //     },
        //     {
        //       path: 'members',
        //       element: (
        //         <GradeAuthorization level={2} pass={[4]}>
        //           <Member />
        //         </GradeAuthorization>
        //       ),
        //     },
        //     {
        //       path: 'vehicles',
        //       element: (
        //         <GradeAuthorization level={2}>
        //           <Vehicle />
        //         </GradeAuthorization>
        //       ),
        //     },

        //     {
        //       path: 'area',
        //       element: (
        //         <GradeAuthorization level={2}>
        //           <RouteArea />
        //         </GradeAuthorization>
        //       ),
        //     },

        //     { path: '', element: <Navigate to="account" /> },
        //   ],
        // },
      ],
    },

    { path: '/landing', element: <Landing /> },

    {
      path: '/',
      element: <Layout disableHeader={pathname.includes('signup')} />,
      children: [
        // { path: 'signin', element: authToken ? <Navigate to={'/'} /> : <SignIn /> },
        { path: 'signin', element: <SignIn /> },

        // RP-1941
        {
          path: 'signup',
          element: <SignUp />,
        },
        { path: 'resetpassword', element: authToken ? <Navigate to={'/'} /> : <ValidateEmail /> },

        { path: 'invite/:key', element: authToken ? <Navigate to={'/'} /> : <SignUpManager /> },
      ],
    },

    { path: '/imgViewer', element: <ImgViewer /> },
    { path: '*', element: <NoMatch /> },
  ]);
};

export default ConfigRouters;
