import { ComponentPropsWithRef, ReactNode, forwardRef } from 'react';

import * as S from './style';

interface AltProps extends ComponentPropsWithRef<'div'> {
  children: ReactNode;
}

const Alt = forwardRef<any, AltProps>(({ children, ...props }, ref): JSX.Element => {
  return (
    <S.Alt {...ref} style={props.style}>
      {children}
    </S.Alt>
  );
});

export default Alt;
