import { Dispatch, SetStateAction, useEffect, ReactNode, Fragment } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IGetOrderListResponses } from 'constants/types';
import { getOrderList, skipOrders } from 'api';

import { useStore } from 'store';
import theme from 'constants/theme';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Highlight, Text } from 'components/Typography';

import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import strings from 'util/Localization';
import useOrder from 'hooks/useOrder';

interface SkipModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  target: Array<number>;
  setTarget: Dispatch<SetStateAction<Array<number>>>;

  noCancelTrigger: boolean;

  refetcher: Function;

  text?: string | ReactNode;
}

const SkipModal = ({ isOpen, setIsOpen, target, setTarget, noCancelTrigger, refetcher, text }: SkipModalProps) => {
  const { getOrderListProps } = useStore();

  const { refetchOrderList } = useOrder();
  const {
    mutate: mutateSkipOrder,
    isLoading: deleteUnassignedOrderIsLoading,
    isSuccess: deleteUnassignedOrderIsSuccess,
    isError: deleteUnassignedOrderIsError,
    reset,
  } = useMutation(skipOrders, {
    onSuccess: () => {
      setTarget([]);
      refetchOrderList();
      refetcher();
    },
  });

  useEffect(() => {
    console.log(noCancelTrigger);

    reset();
  }, [isOpen, reset]);

  const closer = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={() => {
        setIsOpen(prev => !prev);
      }}
      padding={24}
      isLoadingModal={deleteUnassignedOrderIsLoading}
      width={504}
      ms={deleteUnassignedOrderIsSuccess ? 0 : 30}
    >
      {!deleteUnassignedOrderIsLoading &&
        (deleteUnassignedOrderIsSuccess ? (
          <Fragment>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline3" color="RG04" sx={{ margin: '0 0 10px 0' }}>
              {strings.주문상태}가 '보류'로 변경되었습니다.
            </Text>
            <Button
              variant={'default'}
              type={'reset'}
              styleName="body2"
              color="RG00"
              fullWidth
              sx={{ padding: '6px 0' }}
              onClick={closer}
            >
              닫기
            </Button>
          </Fragment>
        ) : deleteUnassignedOrderIsError || noCancelTrigger ? (
          <Stack name="skip-modal-content-area" spacing={33}>
            <Stack spacing={10}>
              <Text styleName="subheadline2" color="RC04">
                ‘{strings.배차완료}’ 또는 ‘{strings.주행_조사}중’ 상태인 {strings.모달_주문}만 ‘보류’로 변경할 수
                있습니다.
              </Text>
              <Text styleName="subheadline3" color="RG03">
                다른 상태의 {strings.모달_주문}을 제외하고 다시 시도해주세요.
              </Text>
            </Stack>
            <Button variant={'second'} type={'button'} fullWidth sx={{ padding: '8px 10px' }} onClick={closer}>
              <Text styleName="body2" color="RG04">
                닫기
              </Text>
            </Button>
          </Stack>
        ) : (
          <Stack name="skip-modal-content-area" spacing={40}>
            {text ? (
              text
            ) : (
              <pre>
                <Text styleName="subheadline2" color="RG02">
                  <Highlight color="RC04">{`선택하신 ${strings.모달_주문} ${target.length} 건을 ‘보류’ 로 변경하시겠습니까?\n`}</Highlight>
                  {`보류 상태의  ${strings.모달_주문}은 ‘${strings.미배차}’ 또는 ‘취소’ 로 변경 가능합니다.\n상태 변경 시 ${strings.기사}님께 업데이트 됩니다.`}
                </Text>
              </pre>
            )}
            <Stack direction="row" spacing={16}>
              <Button variant={'second'} type={'button'} fullWidth sx={{ padding: '8px 10px' }} onClick={closer}>
                <Text styleName="body2" color="RG04">
                  취소
                </Text>
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  mutateSkipOrder(target);
                }}
              >
                <Text styleName="body1" color="RG00">
                  변경하기
                </Text>
              </Button>
            </Stack>
          </Stack>
        ))}
    </Modal>
  );
};

export default SkipModal;
