import { ITimelineBlock } from 'constants/types';
import { memo, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';
import Item from './Item';
import { Row } from './Row';
import { TMod } from 'pages/RoutePlanConfirm';

export const ItemList = memo(function ItemList({
  column,
  index,
  width,
  listGlobRef,
  headerRef,
  fnShowOrderDetail,
  fnSaveToClipboard,
  fnLoadFromClipboard,
  fnOnCellClicked,
  selectedCell,
  clipboard,
  mod,
  startEndChipSelected,
  setStartEndChipSelected,
}: {
  column: { id: string; items: ITimelineBlock[] };
  index: number;
  width: number;
  listGlobRef: any;
  headerRef: any;
  fnShowOrderDetail: (orderId: number) => void;
  fnSaveToClipboard: (orderId: number) => void;
  fnLoadFromClipboard: (draggableId: string) => void;
  fnOnCellClicked: (orderId: number) => void;
  selectedCell: number[];
  clipboard: ITimelineBlock | undefined;
  mod?: TMod;
  startEndChipSelected: string;
  setStartEndChipSelected: Dispatch<SetStateAction<string>>;
}) {
  useLayoutEffect(() => {
    const list: any = listGlobRef?.current[index];
    if (list) list.scrollTo(0);
  }, [index]);

  const getItemSize = (index: number) => {
    return column.items.length > index ? column.items[index].width : 0;
  };

  listGlobRef?.current[index]?.resetAfterIndex(0);
  return (
    <Droppable
      droppableId={column.id}
      direction="horizontal"
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item provided={provided} isDragging={snapshot.isDragging} item={column.items[rubric.source.index]} />
      )}
    >
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder ? column.items.length + 1 : column.items.length;

        return (
          <CellWrapper
            height={54}
            itemCount={itemCount}
            itemSize={getItemSize}
            width={width}
            layout="horizontal"
            className={column.id}
            outerRef={provided.innerRef}
            itemData={{
              data: column.items,
              fnShowOrderDetail,
              fnSaveToClipboard,
              fnLoadFromClipboard,
              fnOnCellClicked,
              selectedCell,
              clipboard,
              mod,
              startEndChipSelected,
              setStartEndChipSelected,
            }}
            ref={el => (listGlobRef.current[index] = el)}
            onScroll={e => { 
              let maxScrollWidth = headerRef?.current.scrollWidth;
              let offset = e.scrollOffset > maxScrollWidth ? maxScrollWidth : e.scrollOffset;
              if (!e.scrollUpdateWasRequested) headerRef?.current.scrollTo(offset, 0);
            }}
          >
            {Row}
          </CellWrapper>
        );
      }}
    </Droppable>
  );
});

const CellWrapper = styled(VariableSizeList)`
  ::-webkit-scrollbar {
    display: none;
  }
  overscroll-behavior: none;
`;
