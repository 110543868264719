import type { Dispatch, SetStateAction } from 'react';
import { getRequestIndustryType, postSignUpManager, getCheckManagerId } from 'auth/signUp/api';
import { storeToken } from 'auth/common';

export const requestIndustryType = async (_dispatch: Dispatch<TSignUpAction>) => {
  const response = await getRequestIndustryType();
  const error = response as TErrorResponse;
  const _response = response as { industryTypeId: number; name: string; description?: string }[];
  if (error.advice) return;
  const _res = _response.map(value => ({ value: value.industryTypeId, key: value.name, extra: value.description }));
  _dispatch({ type: 'UPDATE_INDUSTRY_LIST', payload: _res });
};

export const _postSignUpManager = async (
  props: TManagerSignUpQuery,
  setIsLoading: (isLoading: boolean) => void,
  setTarget: Dispatch<SetStateAction<string | null>>,
  setWelcomePageMode: Dispatch<SetStateAction<TWelcomePageType>>
) => {
  const response = await postSignUpManager({ ...props });
  const error = response as TErrorResponse;

  if (error?.message) return error;
  setIsLoading(false);

  // @ts-ignore
  if (response) setTarget(response.loginAccount);

  return setWelcomePageMode('Manager');
};

export const _getCheckManagerId = async (
  invitationKey: string | undefined,
  setIsInvalidEmail: Dispatch<SetStateAction<boolean>>
) => {
  if (invitationKey === undefined) return setIsInvalidEmail(true);
  const response = await getCheckManagerId(invitationKey);
  const error = response as TErrorResponse;
  if (error?.message) {
    return setIsInvalidEmail(true);
  }
};

export const switchSignUpMode = (params: { _dispatch: Dispatch<TSignUpAction>; mode: TSignUpType }) => {
  const { _dispatch, mode } = params;
  _dispatch({ type: 'SWITCH_SIGN_UP_MODE', payload: mode });
};
