import styled from 'styled-components';
import theme from 'constants/theme';

interface PlusIconProps {
  bgColor?: string;
  iconColor?: string;
  size?: number;
}

export const PlusIcon = styled.div<PlusIconProps>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${({ bgColor }) => bgColor || theme.colors.RC02};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(${({ size }) => (size || 40) / 40});

  ::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: ${({ iconColor }) => iconColor || '#ffffff'};
  }

  ::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 20px;
    background-color: ${({ iconColor }) => iconColor || '#ffffff'};
  }
`;
