import Modal from 'components/Modal';
import { Button } from 'components/Button/legacy_index';
import { ReactComponent as IconClose } from 'constants/icon/ic_close_16.svg';
import * as S from 'auth/signUp/style';

interface ITermsOfServicesDetailModal {
  isOpen: boolean;
  callback: Function;
  path: string;
}

export const TermsOfServicesDetailModal = (props: ITermsOfServicesDetailModal) => {
  const { isOpen, callback, path } = props;

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={() => {}}
      sx={{ width: '1062px', maxHeight: '917px' }}
      plain
      ds="strong"
    >
      <S.TermsOfServicesDetailModalCancelIcon>
        <IconClose style={{ cursor: 'pointer' }} onClick={() => callback()} />
      </S.TermsOfServicesDetailModalCancelIcon>

      <div style={{ width: '900px', height: '769px', overflow: 'hidden' }}>
        <iframe src={path} width="100%" height="769" title="약관 자세히보기" />
      </div>
      <div style={{ width: 'calc(100% - 48px)', paddingTop: '40px', paddingBottom: '24px' }}>
        <Button height={40} type="button" variant="seventh" fullWidth onClick={() => callback()}>
          확인
        </Button>
      </div>
    </Modal>
  );
};
