import { Countdown, CountdownProps } from '../../util/Countdown';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { Input } from './index';
import { ReactComponent as SuccessMarking } from 'constants/icon/ic_success_alert_circle.svg';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import { Text } from 'components/Typography';
import { Button } from '../Button';
import { useSendManagerAuthCode } from '../../hooks/query/managerAuthCode/useSendManagerAuthCode';
import { useVerifyManagerAuthCode } from '../../hooks/query/managerAuthCode/useVerifyManagerAuthCode';
import { FetchPaymentInfoData } from '../../hooks/query/payment/useGetPaymentInfo';
import { ClientAuthCodeLimitModal } from '../Modal/warning/authCodeLimit/ClientAuthCodeLimitModal';

interface CountdownInputProps extends Omit<CountdownProps, 'setExpiredMessage'> {
  phone: string;
  setTimestamp: Dispatch<SetStateAction<number>>;
  setPaymentInfoData: Dispatch<SetStateAction<Partial<FetchPaymentInfoData>>>;
  setIsSuccessVerify: Dispatch<SetStateAction<boolean>>;
  isClear: boolean;
  setIsClear: Dispatch<SetStateAction<boolean>>;
}

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 4px;
`;

const Section = styled.section`
  display: flex;
  align-items: center;
`;

export const AuthNumberInput = ({
  phone,
  timestamp,
  setTimestamp,
  setPaymentInfoData,
  setIsSuccessVerify,
  isClear,
  setIsClear,
  ...props
}: CountdownInputProps) => {
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [inputInfo, setInputInfo] = useState('');
  const [reSendCount, setReSendCount] = useState(0);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isShowVerifiedInfo, setIsShowVerifiedInfo] = useState(false);

  const {
    mutate: sendManagerAuthCode,
    isSuccess: isSuccessSendManagerAuthCode,
    error: sendManagerAuthCodeError,
  } = useSendManagerAuthCode();
  const {
    mutate: verifyManagerAuthCode,
    data: verifyAuthCodeData,
    isSuccess: isSuccessVerifyAuthCode,
  } = useVerifyManagerAuthCode();

  useEffect(() => {
    (async () => {
      if (sendManagerAuthCodeError) {
        const data = await sendManagerAuthCodeError.response.clone().json();
        setShowErrorAlert(data.error.name === 'RequestLimit');
      }
    })();
  }, [sendManagerAuthCodeError]);

  useEffect(() => {
    if (isSuccessSendManagerAuthCode) {
      setTimestamp(Date.now());
    }
  }, [isSuccessSendManagerAuthCode, setTimestamp]);

  useEffect(() => {
    if (verifyAuthCodeData?.verified) {
      setIsSuccessVerify(true);
      setDisabled(true);

      setPaymentInfoData(prev => ({
        ...prev,
        phone: phone.replaceAll('-', ''),
      }));
    }
  }, [verifyAuthCodeData?.verified]);

  useEffect(() => {
    if (isClear) {
      setValue('');
      // setIsShowVerifiedInfo(false);
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    if (isSuccessVerifyAuthCode) {
      setIsShowVerifiedInfo(true);
    }
  }, [isSuccessVerifyAuthCode]);

  return (
    <Article>
      <Section style={{ columnGap: '10px' }}>
        <Input
          placeholder="인증 번호 입력"
          height={40}
          sx={{ width: '143px' }}
          value={value}
          disabled={disabled}
          // hasError={isErrorSendManagerAuthCode || isErrorVerifyManagerAuthCode}
          // errors={{ message: inputInfo }}
          style={{ border: `1px solid ${theme.colors.RC03}` }}
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length <= 6) {
              setValue(e.target.value);
            }

            if (e.target.value.length === 6) {
              verifyManagerAuthCode({ phone: phone.replaceAll('-', ''), code: e.target.value });
            }
          }}
        />
        <Countdown
          timestamp={timestamp}
          setExpiredMessage={setInputInfo}
          disabled={disabled}
          setDisabled={setDisabled}
          {...props}
        />
        <Button
          variant="secondary"
          h={40}
          disabled={verifyAuthCodeData?.verified}
          onClick={e => {
            setIsShowVerifiedInfo(false);
            setValue('');

            if (reSendCount < 3) {
              setReSendCount(prev => prev + 1);

              sendManagerAuthCode({
                phone: phone.replaceAll('-', ''),
              });
            } else {
              setShowErrorAlert(true);
            }
          }}
        >
          재발송
        </Button>
      </Section>

      {isShowVerifiedInfo && (
        // {isSuccessVerifyAuthCode && (
        <Section style={{ columnGap: '6px' }}>
          {verifyAuthCodeData?.verified ? (
            <SuccessMarking width={12} height={12} />
          ) : (
            <ErrorMarking width={12} height={12} />
          )}
          <Text styleName="caption2" color={verifyAuthCodeData?.verified ? 'RC02' : 'RC04'}>
            {verifyAuthCodeData?.verified ? '인증번호가 일치합니다.' : '인증번호가 일치하지 않습니다.'}
          </Text>
        </Section>
      )}

      {showErrorAlert && <ClientAuthCodeLimitModal isOpen={showErrorAlert} setIsOpen={setShowErrorAlert} />}
    </Article>
  );
};
