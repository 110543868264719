import { Dispatch, SetStateAction, useRef } from 'react';
import MarkerDefault from 'constants/icon/marker_positionpicker.png';
import { LatLng } from 'constants/types';
// @ts-ignore
import NaverMap, { Marker } from 'react-naver-map';

interface PositionPickerProps {
  position: LatLng;
  setPosition: Dispatch<SetStateAction<LatLng>>;
}

export const PositionPicker = ({ position, setPosition }: PositionPickerProps) => {
  const markerRef = useRef<any>(null);
  const mapRef = useRef<any>(null);
  function updateMarker() {
    if (markerRef && markerRef.current) {
      let pos = markerRef.current.marker.position;
      setPosition({ lat: pos.y, lng: pos.x });
      console.log('updated');
    }
  }
  function registerEvents() {
    setTimeout(() => {
      if (markerRef && markerRef.current) {
        markerRef.current.marker.setDraggable(true);
        if (mapRef && mapRef.current) {
          mapRef.current.naver.maps.Event.addListener(markerRef.current.marker, 'dragend', updateMarker);
          console.log('Registered');
        }
      }
    }, 500);
  }

  return (
    <NaverMap
      clientId="anydnrdo3l"
      ncp
      style={{ width: '100%', height: '100%' }}
      initialPosition={{ lat: position.lat, lng: position.lng }}
      initialZoom={20}
      ref={mapRef}
      onInit={registerEvents}
    >
      <Marker
        ref={markerRef}
        lat={position.lat}
        lng={position.lng}
        icon={{
          url: MarkerDefault,
          size: { width: 28, height: 38 },
          scaledSize: { width: 28, height: 38 },
          anchor: { x: 14, y: 38 },
        }}
      />
    </NaverMap>
  );
};
