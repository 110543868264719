import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { deleteTeamById, getTeamDetailById, modifyingTeamById } from 'api';
import { Button } from 'components/Button';
import Divider from 'components/Divider';
import { Input } from 'components/Input';

import Modal from 'components/Modal';
import genLabel from 'components/Modal/common/genLabel';
import { CloseConfirmModal, DetailModiForm } from 'components/Modal/OrderDetailModal';
import {
  DescriptionTable,
  DescriptionTd,
  DescriptionTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from 'components/Modal/style';

import { Stack } from 'components/Stack';
import { Tag, TTagStatusKeys } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';
import { common_table_selection } from 'constants/commons';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcSuccessOutline } from 'constants/icon/ic_success_circle_outline.svg';
import theme from 'constants/theme';
import {
  IGetTeam,
  IModifyingTeamByIdProps,
  ITeamDriver,
  ITeamManager,
  TAccountStatus,
  TOperationType,
  TS3,
} from 'constants/types';
import dayjs from 'dayjs';
import GrayBox from 'pages/Setting/common/GrayBox';

import StatusTag from 'pages/Setting/common/StatusTag';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { JSX } from 'react/jsx-runtime';
import { useStore } from 'store';
import styled from 'styled-components';
import type { TSettingAttrs } from 'types/setting/union';

import TableComponent from '.';
import InternalTable from './InternalTable';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';
import IntrinsicElements = JSX.IntrinsicElements;

const columnHelper = createColumnHelper<IGetTeam>();
const managerColumnHelper = createColumnHelper<ITeamManager>();
const driverColumnHelper = createColumnHelper<ITeamDriver>();

type TDetailFormTitle = { title: string; side?: TSideLabel };
const DetailFormTitle = ({ title, side }: TDetailFormTitle) => {
  return (
    <Stack direction="row" spacing={10}>
      <Text styleName="body2">{title}</Text>
      {side && <SideLabel {...side} />}
    </Stack>
  );
};

type TSideLabel = { unit: string; total: number; uniq: number; uniqStr: string };

const SideLabel = ({ unit, total, uniq, uniqStr }: { unit: string; total: number; uniq: number; uniqStr: string }) => {
  return (
    <Stack
      direction="row"
      spacing={6}
      divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      sx={{ width: 'max-content' }}
    >
      <Text styleName="subheadline2">
        총&nbsp;<Highlight styleName="subheadline1">{total}</Highlight>&nbsp;{unit}
      </Text>
      <Text styleName="subheadline2">
        {uniqStr}&nbsp;
        <Highlight styleName="subheadline1" color="RC02">
          {uniq}
        </Highlight>
        &nbsp;{unit}
      </Text>
    </Stack>
  );
};
const TeamManagementTable = ({
  data,
  onControl,
  navTo,
  teamTotal,
  fatalCorrectionDetectorWithFence,
}: {
  data: Array<IGetTeam>;
  onControl: any;
  navTo: TS3<TSettingAttrs>;
  teamTotal: number;
  fatalCorrectionDetectorWithFence: Function;
}) => {
  const { userGrade } = useStore();

  // const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);

  const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);
  const [detailModalIsModi, setDetailModalIsModi] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<number>(-33);

  const [deleteErrorModal, setDeleteErrorModal] = useState<string | null>(null);
  const [deleteModalStep, setDeleteModalStep] = useState<boolean>(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [deleteModalConfirm, setDeleteModalConfirm] = useState<boolean>(false);
  const [teamNeedModal, setTeamNeedModal] = useState<boolean>(false);

  const delMethods = useForm<{
    nameCheck: string;
  }>({
    mode: 'all',
    defaultValues: {
      nameCheck: '',
    },
  });

  const {
    data: detailById,
    isFetching,
    refetch: refetchDetail,
  } = useQuery(['teamDetailById', targetId], () => getTeamDetailById({ teamId: targetId }), {
    enabled: targetId > 0,
  });
  const { mutate: mutateModifyingTeamById, isLoading: isModiLoading } = useMutation(modifyingTeamById, {
    onSuccess: () => {
      onControl('정보 수정을 완료하였습니다.');
      refetchDetail();
      setDetailModalIsOpen(false);
    },
  });
  const { mutate: mutateDeleteTeamById, isLoading: mutateDeleteTeamByIdIsLoading } = useMutation(deleteTeamById, {
    onSuccess: () => {
      fatalCorrectionDetectorWithFence();
      onControl('팀 삭제를 완료하였습니다.');
      setDeleteModalIsOpen(false);
      setDetailModalIsOpen(false);
    },
  });

  const teamModifyingDefaultValue = useMemo<Omit<IModifyingTeamByIdProps, 'teamId'>>(() => {
    return { name: detailById?.name, memo: detailById?.memo };
  }, [detailById]);
  const methods = useForm<Omit<IModifyingTeamByIdProps, 'teamId'>>({
    mode: 'onSubmit',
    defaultValues: { ...teamModifyingDefaultValue },
  });
  const { handleSubmit } = methods;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    select: false,
  });

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: '팀 이름',
        cell: info => {
          const { hasOwner } = info.row.original;
          return (
            <Stack direction="row" spacing={6}>
              {hasOwner && <StatusTag text="오너" variant="small" />}
              {info.getValue()}
            </Stack>
          );
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.managerCount, {
        id: 'managerCount',
        header: '소속 매니저',
        cell: info => {
          return `${info.getValue()} 명`;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.driverCount, {
        id: 'driverCount',
        header: '소속 드라이버',
        cell: info => {
          return `${info.getValue()} 명`;
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: '추가 일자',
        cell: info => dayjs(info.getValue()).format('YYYY.MM.DD'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor(row => row?.memo ?? '', {
        id: 'memo',
        header: '팀 메모',
        footer: info => info.column.id,
      }),
    ],
    [anchorIsOpen]
  );

  const managerColumns = useMemo(
    () => [
      managerColumnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: '팀 소속 일자',
        cell: info => {
          return dayjs(info.getValue()).format('YYYY.MM.DD');
        },
        footer: info => info.column.id,
        enableSorting: false,
      }),
      managerColumnHelper.accessor(row => row.name, {
        id: 'name',
        header: '이름',
        cell: info => {
          // isOwner?
          return info.getValue();
        },
        footer: info => info.column.id,
      }),
      managerColumnHelper.accessor(row => row.teamCount, {
        id: 'teamCount',
        header: '소속팀 수',
        footer: info => info.column.id,
        enableSorting: false,
      }),
      managerColumnHelper.accessor(row => row.loginAccount, {
        id: 'loginAccount',
        header: '아이디(이메일)',
        footer: info => info.column.id,
        enableSorting: false,
      }),
    ],
    []
  );

  const driverColumns = useMemo(
    () => [
      driverColumnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: '팀 소속 일자',
        cell: info => {
          return dayjs(info.getValue()).format('YYYY.MM.DD');
        },
        footer: info => info.column.id,
        enableSorting: false,
      }),
      driverColumnHelper.accessor(row => row.name, {
        id: 'name',
        header: '이름',
        footer: info => info.column.id,
      }),
      driverColumnHelper.accessor(row => row.status, {
        id: 'status',
        header: '상태',
        cell: info => {
          if (info.getValue()) {
            const mapper: { [key in TAccountStatus]: ReactNode } = {
              invited: null,
              accepted: null,
              activated: <StatusTag styleName="caption1" text={'활성'} />,
              deactivated: <StatusTag styleName="caption1" text={'비활성'} color="RG04" borderColor="RG04" />,
            };
            return mapper[info.getValue()];
          } else return null;
        },
        footer: info => info.column.id,
      }),
      driverColumnHelper.accessor(row => row.operationType, {
        id: 'operationType',
        header: '운영 유형',
        cell: info => {
          const mapper: { [key in TOperationType]: { string: string; key: TTagStatusKeys } } = {
            backup: { string: '용차', key: 'moving' },
            regular: { string: '고정차', key: 'done' },
          };

          return info.getValue() ? (
            <Tag active status={mapper[info.getValue()].key} sx={{ width: 45, height: 24 }} fit>
              {mapper[info.getValue()].string}
            </Tag>
          ) : null;
        },
        footer: info => info.column.id,
      }),
      driverColumnHelper.accessor(row => row.loginAccount, {
        id: 'loginAccount',
        header: '아이디(휴대폰 번호)',
        footer: info => info.column.id,
        enableSorting: false,
      }),
    ],
    []
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    initialState: {},
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),

    sortDescFirst: true,
  });

  useEffect(() => {
    methods.reset();
    setDetailModalIsModi(false);
  }, [detailModalIsOpen, methods]);

  useEffect(() => {
    delMethods.reset();
    setDeleteModalConfirm(false);
    setDeleteModalStep(false);
  }, [deleteModalIsOpen, delMethods]);

  return (
    <React.Fragment>
      <TableComponent<IGetTeam>
        table={instance}
        isDataFetching={false}
        disableOptions={{
          pageSizeSelector: false,
          pagnation: false,
          totalCount: false,
          selectCount: true,
        }}
        tableType="team"
        clearBottom
        onRowClick={(e, row) => {
          setTargetId(row.original.teamId);
          setDetailModalIsOpen(true);
        }}
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}

      <Modal
        isLoadingModal={isFetching || isModiLoading}
        plain
        width={540}
        isModalOpen={detailModalIsOpen}
        setIsModalOpen={setDetailModalIsOpen}
      >
        <OrderDetailModalHeader>
          <Stack direction="row" justify="space-between">
            <Text styleName="subheadline2">팀 상세 정보</Text>
            <IcClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setDetailModalIsOpen(false);
              }}
            />
          </Stack>
        </OrderDetailModalHeader>
        <Divider color="RG06" />
        <Stack
          useVerticalScroll
          sx={{
            maxHeight: 'calc(100vh - 296px)',
          }}
        >
          {!detailModalIsModi ? (
            <OrderDetailModalBody>
              <DetailModiForm title={<DetailFormTitle title="팀 기본 정보" />}>
                <Stack spacing={10} align="end">
                  <DescriptionTable
                    style={{
                      borderTop: `1px solid ${theme.colors.RG03}`,
                    }}
                  >
                    <tbody>
                      <tr>
                        <DescriptionTh colSpan={120}>{'팀 이름'}</DescriptionTh>
                        <DescriptionTd colSpan={380}>
                          <Stack spacing={6} direction="row">
                            {instance
                              .getCoreRowModel()
                              .rows.find(
                                row => row.original.teamId === detailById?.teamId && row.original.hasOwner
                              ) && <StatusTag text="오너" variant="small" />}
                            <Highlight color="RG01">{detailById?.name}</Highlight>
                          </Stack>
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh colSpan={120}>{'추가 일자'}</DescriptionTh>
                        <DescriptionTd colSpan={380}>
                          <Highlight color="RG01">
                            {detailById?.createdAt && dayjs(detailById?.createdAt).format('YYYY.MM.DD')}
                          </Highlight>
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh colSpan={120}>{'소속 매니저'}</DescriptionTh>
                        <DescriptionTd colSpan={130}>
                          <Highlight color="RG01">{detailById?.managers.length} 명</Highlight>
                        </DescriptionTd>
                        <DescriptionTh colSpan={120}>{'소속 드라이버'}</DescriptionTh>
                        <DescriptionTd colSpan={130}>
                          <Highlight color="RG01">{detailById?.drivers.length} 명</Highlight>
                        </DescriptionTd>
                      </tr>
                      <tr>
                        <DescriptionTh colSpan={120}>{'팀 메모'}</DescriptionTh>
                        <DescriptionTd colSpan={380} broken>
                          <Highlight color="RG01">{detailById?.memo ?? '-'}</Highlight>
                        </DescriptionTd>
                      </tr>
                    </tbody>
                  </DescriptionTable>

                  <Button
                    variant="tertiary"
                    type="button"
                    h={24}
                    color="RG03"
                    icon={[IcBin, { width: 12, height: 12 }]}
                    onClick={() => {
                      if (teamTotal === 1) {
                        setTeamNeedModal(true);
                      } else if (
                        instance
                          .getCoreRowModel()
                          .rows.find(row => row.original.teamId === detailById?.teamId && row.original.hasOwner)
                      ) {
                        if ((detailById?.managers?.length ?? 0) > 0) {
                          console.log('has owner & manager');
                          setDeleteErrorModal('팀을 삭제하기 전 오너와 속한 매니저들의 소속 팀을 이동해야합니다.');
                        } else {
                          console.log('has owner');
                          setDeleteErrorModal('팀을 삭제하기 전 오너의 소속 팀을 이동해야합니다.');
                        }
                      } else if ((detailById?.managers?.length ?? 0) > 0) {
                        console.log('has manager');
                        setDeleteErrorModal('팀을 삭제하기 전 소속 팀 매니저를 이동해야합니다.');
                      } else {
                        setDetailModalIsOpen(false);
                        setDeleteModalIsOpen(true);
                      }
                    }}
                    sx={userGrade === 1 ? { opacity: 1 } : { opacity: 0, pointerEvents: 'none' }}
                  >
                    팀 삭제
                  </Button>
                </Stack>
              </DetailModiForm>
              <Divider color="RG06" />
              <DetailModiForm
                title={
                  <DetailFormTitle
                    title="소속 매니저 정보"
                    side={{
                      total: (detailById?.managers ?? []).length,
                      uniq: (detailById?.invitedManagers ?? []).length,
                      unit: '명',
                      uniqStr: '인증대기',
                    }}
                  />
                }
              >
                <Stack>
                  <Divider color="RG03" />
                  <InternalTable<any>
                    data={detailById?.managers ?? []}
                    columns={managerColumns}
                    placeholder={
                      <Text styleName="caption2">
                        소속 매니저가 없습니다.
                        <br />
                        {`[조직 관리 > 매니저관리] 에서 설정할 수 있습니다.`}
                      </Text>
                    }
                  />
                </Stack>
              </DetailModiForm>
              <Divider color="RG06" />
              <DetailModiForm
                title={
                  <DetailFormTitle
                    title="소속 드라이버 정보"
                    side={{
                      total: (detailById?.drivers ?? []).length,
                      uniq: (detailById?.invitedDrivers ?? []).length,
                      unit: '명',
                      uniqStr: '승인대기',
                    }}
                  />
                }
              >
                <Stack>
                  <Divider color="RG03" />
                  <InternalTable<any>
                    data={detailById?.drivers ?? []}
                    columns={driverColumns}
                    placeholder={
                      <Text styleName="caption2">
                        소속 드라이버가 없습니다.
                        <br />
                        {`[리소스 관리 > 드라이버 관리] 에서 설정할 수 있습니다.`}
                      </Text>
                    }
                  />
                </Stack>
              </DetailModiForm>
            </OrderDetailModalBody>
          ) : (
            <form>
              <OrderDetailModalBody
                spacing={30}
                style={{
                  padding: '30px 20px',
                }}
              >
                <Input
                  label={genLabel({ text: '추가 일자' })}
                  name="createdAt"
                  field
                  styleName={'subheadline2'}
                  type="text"
                  height={40}
                  value={detailById?.createdAt && dayjs(detailById?.createdAt).format('YYYY.MM.DD')}
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  disabled={true}
                  readOnly={true}
                />
                <Input
                  label={genLabel({ text: '팀 이름', isRequiredStart: true })}
                  name="name"
                  placeholder={detailById?.name}
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  field
                  styleName={'subheadline2'}
                  variant={'third'}
                  type="text"
                  height={40}
                  defaultValue={detailById?.name}
                  removeBtn={() => {
                    methods.resetField('name');
                  }}
                  onClick={() => {
                    // setReceivedDatePopupIsOpen(true);
                  }}
                  validation={{
                    required: { value: true, message: '해당 필드는 필수값입니다.' },
                    maxLength: { value: 20, message: '팀 이름은 한글/영문 20자 이내로 입력이 가능합니다.' },
                    validate: (res: string) => {
                      return (
                        /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩ ]{0,20}/.test(res) ||
                        '팀 이름은 한글/영문 20자 이내로 입력이 가능합니다.'
                      );
                    },
                  }}
                  errors={methods.formState?.errors?.name}
                  hasError={Boolean(methods.formState?.errors?.name)}
                />

                <Stack spacing={4}>
                  {genLabel({ text: '팀 메모' })}
                  <FormTextarea
                    {...methods.register('memo', {})}
                    placeholder={'팀에 대한 메모를 적어주세요. 예시) 루티 물류센터 출발'}
                    rows={14}
                    cols={10}
                    wrap="soft"
                    defaultValue={detailById?.memo}
                    maxLength={200}
                    onKeyDown={(e: { currentTarget: { style: { height: string }; scrollHeight: number } }) => {
                      e.currentTarget.style.height = '1px';
                      e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                    }}
                    onKeyUp={(e: { currentTarget: { style: { height: string }; scrollHeight: number } }) => {
                      e.currentTarget.style.height = '1px';
                      e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                    }}
                  />
                </Stack>
              </OrderDetailModalBody>
            </form>
          )}
        </Stack>
        <Divider color="RG06" />
        <OrderDetailModalFooter>
          {!detailModalIsModi ? (
            <Stack
              name="modal-footer"
              spacing={16}
              direction="row"
              align="center"
              justify="end"
              sx={{ padding: '2px 0 0 0' }}
            >
              <Button
                variant="tertiary"
                type="button"
                w={102}
                h={40}
                color="RG04"
                onClick={() => {
                  setDetailModalIsOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                variant="primary"
                w={216}
                h={40}
                disabled={userGrade > 1}
                onClick={() => {
                  setDetailModalIsModi(true);
                }}
              >
                수정하기
              </Button>
            </Stack>
          ) : (
            <Stack
              name="modal-footer"
              spacing={16}
              direction="row"
              align="center"
              justify="end"
              sx={{ padding: '2px 0 0 0' }}
            >
              <Button
                variant="tertiary"
                w={102}
                h={40}
                color="RG04"
                onClick={() => {
                  methods.reset();
                  setDetailModalIsModi(false);
                }}
              >
                취소
              </Button>
              <Button
                variant="primary"
                w={216}
                h={40}
                onClick={handleSubmit(res => {
                  console.log(res);
                  const { memo, name } = res;

                  mutateModifyingTeamById({ teamId: targetId, memo: memo, name: name });
                })}
              >
                저장하기
              </Button>
            </Stack>
          )}
        </OrderDetailModalFooter>
      </Modal>
      <Modal
        width={504}
        plain
        isModalOpen={deleteModalIsOpen}
        setIsModalOpen={setDeleteModalIsOpen}
        padding={24}
        isLoadingModal={mutateDeleteTeamByIdIsLoading}
      >
        <Stack spacing={30} sx={{ marginTop: 20 }}>
          {!deleteModalStep ? (
            <>
              <Stack spacing={10} align="start">
                <Text styleName="title2">팀을 삭제하시겠습니까?</Text>
                <Text styleName="body2" color="RC04" align="start">
                  팀 삭제 시 복구는 불가하며 소속된 매니저와 드라이버는 모두 삭제됩니다.
                  <br />
                  팀을 삭제하기 전 안내 사항을 확인해 주세요.
                </Text>
              </Stack>
              <GrayBox sx={{ marginTop: 10 }}>
                <Stack align="start" spacing={8}>
                  <Text styleName="subheadline1" color="RG03">
                    팀을 삭제하기 전 아래의 안내사항을 확인해주세요.
                  </Text>
                  {[
                    '삭제한 팀은 복구할 수 없습니다.',
                    '팀을 삭제할 경우 팀에 소속된 드라이버와 연결이 끊어집니다.',
                    '삭제한 팀의 기존 주행정보는 [보고서] 페이지에서 확인하실 수 있습니다.',
                  ].map((string, index) => {
                    return (
                      <pre>
                        <Text styleName="subheadline2" color="RG03" align="start">
                          {index + 1}. {string}
                        </Text>
                      </pre>
                    );
                  })}
                </Stack>
              </GrayBox>
              <Stack
                direction="row"
                spacing={10}
                onClick={() => {
                  setDeleteModalConfirm(prev => !prev);
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                {deleteModalConfirm ? (
                  <IcSuccess width={18} height={18} fill={theme.colors['RC02']} />
                ) : (
                  <IcSuccessOutline width={18} height={18} />
                )}
                <Text styleName="body1" color="RG03">
                  팀 삭제 안내사항을 모두 확인했습니다.
                </Text>
              </Stack>
              <Stack direction="row" spacing={20}>
                <Button
                  variant="tertiary"
                  fw
                  h={40}
                  color="RG04"
                  onClick={() => {
                    setDeleteModalIsOpen(false);
                    setDetailModalIsOpen(true);
                  }}
                >
                  취소
                </Button>
                <Button
                  disabled={!deleteModalConfirm}
                  variant="primary"
                  fw
                  h={40}
                  onClick={() => {
                    setDeleteModalStep(true);
                  }}
                >
                  다음
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Stack spacing={10} align="start">
                <Text styleName="title2">삭제 정보 확인</Text>
                <Text styleName="body2" color="RC04" align="start">
                  삭제되는 정보를 확인하신 후 삭제할 팀 이름을 아래 입력해주세요.
                </Text>
              </Stack>
              <DescriptionTable
                style={{
                  marginTop: 10,
                  borderTop: `1px solid ${theme.colors.RG03}`,
                }}
              >
                <tbody>
                  <tr>
                    <DescriptionTh colSpan={120}>{'팀 이름'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>
                      <Stack spacing={6} direction="row">
                        {instance
                          .getCoreRowModel()
                          .rows.find(row => row.original.teamId === detailById?.teamId && row.original.hasOwner) && (
                          <StatusTag text="오너" variant="small" />
                        )}
                        <Highlight color="RG01">{detailById?.name}</Highlight>
                      </Stack>
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'추가 일자'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>
                      <Highlight color="RG01">
                        {detailById?.createdAt && dayjs(detailById?.createdAt).format('YYYY.MM.DD')}
                      </Highlight>
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'소속 매니저'}</DescriptionTh>
                    <DescriptionTd colSpan={130}>
                      <Highlight color="RG01">{detailById?.managers.length} 명</Highlight>
                    </DescriptionTd>
                    <DescriptionTh colSpan={120}>{'소속 드라이버'}</DescriptionTh>
                    <DescriptionTd colSpan={130}>
                      <Highlight color="RG01">{detailById?.drivers.length} 명</Highlight>
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'팀 메모'}</DescriptionTh>
                    <DescriptionTd colSpan={380} broken>
                      <Highlight color="RG01">{detailById?.memo ?? '-'}</Highlight>
                    </DescriptionTd>
                  </tr>
                </tbody>
              </DescriptionTable>
              <Input
                name="nameCheck"
                label={genLabel({ text: '삭제할 팀 이름을 입력해주세요.' })}
                placeholder={detailById?.name}
                register={delMethods.register}
                watch={delMethods.watch}
                reset={delMethods.reset}
                field
                height={40}
                style={{
                  color: 'red',
                }}
              />
              <Stack direction="row" spacing={20}>
                <Button
                  variant="tertiary"
                  fw
                  h={40}
                  color="RG04"
                  onClick={() => {
                    setDeleteModalIsOpen(false);
                    setDetailModalIsOpen(true);
                  }}
                >
                  취소
                </Button>
                <Button
                  disabled={!(delMethods.watch('nameCheck') === detailById?.name)}
                  variant="primary"
                  fw
                  h={40}
                  color="RG00"
                  onClick={() => {
                    detailById && mutateDeleteTeamById(detailById?.teamId);
                  }}
                >
                  영구 삭제
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Modal>

      <CloseConfirmModal
        width={504}
        isOpen={typeof deleteErrorModal === 'string'}
        setIsOpen={(v: any) => {
          setDeleteErrorModal(null);
        }}
        targetSetIsOpen={() => {}}
        icon={<IcWarning width={44} height={44} />}
        text={
          <Stack spacing={8}>
            <Text styleName="body2" color="RC04">
              {deleteErrorModal}
            </Text>
            <Text styleName="body2">매니저 관리로 이동 하시겠습니까?</Text>
            <Text styleName="body2">{`소속 팀 변경은 [매니저 관리 > 소속 매니저] 에서 하실 수 있습니다.`}</Text>
          </Stack>
        }
        btnFunctions={{
          LBT: () => {
            setDetailModalIsOpen(false);
          },
          RBT: () => {
            navTo('manager');
          },
        }}
        RBT={'매니저 관리로 이동'}
        LBT={'닫기'}
      />

      <Modal width={504} isModalOpen={teamNeedModal} setIsModalOpen={setTeamNeedModal} padding={24}>
        <Stack>
          <IcWarning width={44} height={44} />
          <Text styleName="body2" color="RG02" sx={{ padding: '30px 0 40px' }}>
            <Highlight color="RC04">팀을 삭제할 수 없습니다.</Highlight>
            <br />
            팀은 최소 1 개 이상 존재해야 합니다.
          </Text>
          <Button variant="tertiary" fw h={40} onClick={() => setTeamNeedModal(false)}>
            확인
          </Button>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

export default TeamManagementTable;

export const FormTextarea = styled.textarea<
  IntrinsicElements['textarea'] & {
    hasError?: boolean;
    useFieldFont?: boolean;
  }
>`
  width: 100%;
  height: 40px;

  padding: 9px 10px 0 10px;
  border-radius: 10px;

  resize: none;
  overflow: hidden;

  ${({ theme, hasError }) => ({
    '::placeholder': {
      color: theme.colors.RG04,
    },

    color: hasError ? theme.colors.RC04 : theme.colors.RG02,
    backgroundColor: hasError ? theme.colors.RC04_1 : theme.colors.RG00,
    border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RG06}`,
  })}

  ${({ theme, useFieldFont }) => (useFieldFont ? theme.fontStyle.caption2 : theme.fontStyle.subheadline2)}
`;
