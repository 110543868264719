import { Dispatch, SetStateAction, useEffect, Fragment, memo, ReactNode, useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';

import { setOrderStatus } from 'api';
import { TChangeableOrderStatus } from 'constants/types';

import strings from 'util/Localization';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Highlight, Text } from 'components/Typography';

import theme from 'constants/theme';
import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { useLocation } from 'react-router-dom';

interface OrderStatusChangeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  targets: Array<number>;
  setTargets: Dispatch<SetStateAction<Array<number>>>;

  targetStatus: TChangeableOrderStatus;
  noCancelTrigger: boolean;

  refetcher: Function;
}

const OrderStatusChangeModal = ({
  isOpen,
  setIsOpen,
  targets,
  setTargets,
  noCancelTrigger,
  targetStatus,
  refetcher,
}: OrderStatusChangeModalProps) => {
  const location = useLocation();

  const isManual = useMemo<boolean>(() => {
    return location.pathname.split('/').includes('manual');
  }, [location.pathname]);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const {
    mutate: mutateSetOrderStatus,
    isLoading: mutateSetOrderStatusIsLoading,
    isSuccess: mutateSetOrderStatusIsSuccess,
    isError: mutateSetOrderStatusIsError,
    error: mutateSetOrderStatusErrorResponse,
    reset,
  } = useMutation(
    ({
      targetStatus,
      targetOrderIds,
    }: {
      targetStatus: TChangeableOrderStatus;
      targetOrderIds: Array<number>;
      comment?: boolean;
    }) => setOrderStatus(targetStatus, targetOrderIds, targetStatus === 'skip'),
    {
      onSuccess: () => {
        setTargets([]);
        refetcher();

        let closeTimer = setTimeout(() => {
          setIsOpen(false);
        }, 3000);

        setTimer(closeTimer);
      },
      onError: () => {
        setTargets([]);
        refetcher();
      },
    }
  );

  useEffect(() => {
    reset();
  }, [noCancelTrigger, reset]);

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const target_status_K: { [key in TChangeableOrderStatus]: string } = {
    skip: '보류',
    delete: '취소',
    unassign: '미배차',
    clear: '영구 삭제',
  };

  const mapped_comements_by_status: {
    status_k: string;
    comment: {
      [key in TChangeableOrderStatus]: {
        success: string;
        error: string;
        confirm: {
          icon?: ReactNode;
          h: string;
          string: string | React.ReactElement;
        };
      };
    };
  } = {
    status_k: target_status_K[targetStatus],
    comment: {
      skip: {
        success: `${strings.모달_주문} 보류 변경을 완료하였습니다.`,
        error: `${strings.모달_주문} 보류를 처리하지 못하였습니다.`,
        confirm: {
          icon: <IconWarning width={44} height={44} style={{ marginTop: 20 }} />,
          h: `‘보류’ 로 변경하시겠습니까?`,
          string: (
            <>
              {isManual
                ? `보류 상태의 수동배차 주문은 ‘취소’ 로 변경 가능합니다.`
                : `보류 상태의 주문은 ‘미배차’ 또는 ‘취소’ 로 변경 가능합니다.`}
              <br />
              상태 변경 시 담당 기사님께 업데이트 됩니다.
            </>
          ),
        },
      },
      delete: {
        success: `${strings.모달_주문} 취소를 완료하였습니다.`,
        error: `${strings.모달_주문} 취소를 처리하지 못하였습니다.`,
        confirm: {
          icon: <IconWarning width={44} height={44} style={{ marginTop: 20 }} />,
          h: `취소하시겠습니까?`,
          string: `취소 이후 복구는 불가하며 신규 ${strings.모달_주문} 추가는 가능합니다. `,
        },
      },
      unassign: {
        success: `${strings.모달_주문}을 미배차로 변경 완료하였습니다.`,
        error: `${strings.모달_주문}을 미배차로 변경 처리하지 못하였습니다.`,
        confirm: {
          string: `변경 이후 ${strings.미배차주문관리}에서 확인 하실 수 있습니다.`,
          h: `미배차로 변경 하시겠습니까?`,
        },
      },
      clear: {
        success: `주문 삭제를 완료하였습니다.`,
        error: `주문 영구 삭제를 처리하지 못하였습니다.`,
        confirm: {
          icon: <IconError width={44} height={44} style={{ marginTop: 20, fill: theme.colors.RC04 }} />,
          string: `주문 정보는 영구 삭제되며, 복구가 불가합니다.`,
          h: `삭제하시겠습니까? `,
        },
      },
    },
  };

  return (
    <Modal
      plain
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      padding={24}
      isLoadingModal={mutateSetOrderStatusIsLoading}
      loadingPlaceholder={`주문 상태를 변경하고 있습니다.`}
      width={503}
      ms={30}
    >
      {!mutateSetOrderStatusIsLoading &&
        (mutateSetOrderStatusIsSuccess ? (
          <Stack>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="body2" color="RG02" sx={{ padding: '30px 0 40px 0' }}>
              {mapped_comements_by_status.comment[targetStatus].success}
            </Text>
            <Button
              variant={'second'}
              type={'reset'}
              styleName="body2"
              color="RG02"
              fullWidth
              sx={{ padding: '6px 0' }}
              onClick={() => {
                if (timer) clearTimeout(timer);
                setIsOpen(false);
              }}
            >
              닫기
            </Button>
          </Stack>
        ) : mutateSetOrderStatusIsError || noCancelTrigger ? (
          <Stack name="delete-vehicle-modal">
            <IconError fill={theme.colors.RC04} style={{ width: '44px', height: '44px' }} />
            <Stack sx={{ padding: 'padding : 30px 0 40px 0' }}>
              {((mutateSetOrderStatusErrorResponse as any).response.data.error as IErrorResponse)?.statusCode ===
              500 ? (
                <Fragment>
                  <Text styleName="body2" color="RC04">
                    {`서버에서 처리 중 알 수 없는 에러가 발생했습니다.`}
                  </Text>
                  <Text styleName="body2" color="RG03">
                    잠시 후에 다시 시도해주세요.
                  </Text>
                </Fragment>
              ) : (
                <Text styleName="body2" color="RG02" sx={{ padding: '30px 0 40px 0' }}>
                  {mapped_comements_by_status.comment[targetStatus].error}
                </Text>
              )}
            </Stack>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                if (timer) clearTimeout(timer);
                setIsOpen(false);
              }}
            >
              <Text styleName="body2" color="RG04">
                닫기
              </Text>
            </Button>
          </Stack>
        ) : (
          <Stack name="delete-vehicle-modal">
            {mapped_comements_by_status.comment[targetStatus].confirm?.icon ?? null}
            <Stack sx={{ padding: '30px 0 40px' }}>
              <Text styleName="body2" color="RG02">
                <Highlight color="RC04">
                  {`선택하신 ${strings.모달_주문} ${targets.length ? targets.length : 1} 건을 ${
                    mapped_comements_by_status.comment[targetStatus].confirm.h
                  }`}
                </Highlight>
                <br />
                {mapped_comements_by_status.comment[targetStatus].confirm.string}
              </Text>
            </Stack>
            <Stack direction="row" spacing={16}>
              <Button
                styleName="body1"
                color="RG03"
                variant={'second'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                취소
              </Button>
              <Button
                styleName="body1"
                color="RG02"
                variant={'eighth'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  mutateSetOrderStatus({ targetStatus: targetStatus, targetOrderIds: targets });
                }}
              >
                확인
              </Button>
            </Stack>
          </Stack>
        ))}
    </Modal>
  );
};

export default memo(OrderStatusChangeModal);
