import { History, Transition } from 'history';
import { ContextType, useCallback, useContext, useEffect, useState } from 'react';
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
interface CustomBlocker {
  (tx: Transition, flag?: Array<string>): void;
}
function useBlocker(blocker: CustomBlocker, when = true, exception?: Array<string>) {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. T O D O: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx, exception);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}

//  showPrompt - modal on/off, when - prompt on/off
export function usePrompt(when: boolean, exception?: Array<string>, replace?: boolean) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  useEffect(() => {
    const initializer = () => {
      setLastLocation(null);
      setConfirmedNavigation(false);
    };

    console.log(location.pathname);
    initializer();

    return initializer;
  }, [confirmedNavigation, location.pathname]);

  const handleBlockedNavigation = useCallback<CustomBlocker>(
    (tx, flag) => {
      if (!confirmedNavigation && tx.location.pathname !== location.pathname) {
        setLastLocation(tx);
        if (flag?.find(url => tx.location.pathname.startsWith(url)) !== undefined) {
          confirmNavigation();
          return true;
        } else {
          setShowPrompt(true);
          return false;
        }
      } else return true;
    },
    [confirmedNavigation, location.pathname]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname, { replace, state: lastLocation.location.state });
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when, exception);

  return { showPrompt, confirmNavigation, cancelNavigation };
}

export function useLeave(callback: Function) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [when, setWhen] = useState<boolean>(true);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);

  const handleBlockedNavigation = useCallback(
    (tx: Transition) => {
      if (tx.location.pathname !== location.pathname) {
        setLastLocation(tx);

        if (!tx.location.pathname.includes('/manage/route/confirm/')) callback();
        setWhen(false);

        return false;
      }
      return true;
    },
    [callback, location.pathname]
  );

  useEffect(() => {
    if (!when && lastLocation) {
      navigate(lastLocation.location.pathname);
      setWhen(true);
    }
  }, [params, callback, lastLocation, location, navigate, when]);
  useBlocker(handleBlockedNavigation, when);
}
