import styled from 'styled-components';
import { BoxContainerProps } from '.';

export const BoxContainer = styled.div<BoxContainerProps>`
  display: flex;
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-content: ${({ justify }) => (justify ? justify : 'center')};

  width: 100%;

  background-color: ${({ theme, bg }) => bg && theme.colors[bg]};
  ${({ theme, useVerticalScroll }) => useVerticalScroll && theme.scrollStyle.vertical}
  ${({ theme, useHorizontalScroll }) => useHorizontalScroll && theme.scrollStyle.horizontal}
  ${({ theme, useBothScroll }) => useBothScroll && theme.scrollStyle.both}
`;
