import { Tag } from '../index';
import { ColorMapper } from '../initialTag.interface';
import { PaymentTypeUnion } from '../../../../types/payment/union';

interface PaymentTypeTagProps {
  text?: PaymentTypeUnion;
}

const colorMapper: ColorMapper<PaymentTypeUnion> = {
  monthly: {
    text: '월간',
    color: 'RC10',
  },
  yearly: {
    text: '연간',
    color: 'RC02',
  },
};

export const PaymentTypeTag = ({ text }: PaymentTypeTagProps) => {
  if (!text) return <></>;

  return <Tag height={24} variant="secondary" color={colorMapper[text].color} text={colorMapper[text].text} />;
};
