import {useQuery, useQueryClient} from '@tanstack/react-query';
import {PaginationState} from '@tanstack/react-table';

import {getNoticeTos, getOrderList} from 'api';
import {Button} from 'components/Button';
import {DownloadButton} from 'components/Button/DownloadButton';
import {Button as LegacyButton} from 'components/Button/legacy_index';
import ComplexDoubleviewDateselector from 'components/ComplexDoubleviewDateselector';
import Divider from 'components/Divider';

import Modal from 'components/Modal';
import BatchOrdersModifyingModal from 'components/Modal/BatchOrdersModifyingModal';
import CancelOrderModal from 'components/Modal/CancelOrderModal';
import {CloseConfirmModal} from 'components/Modal/OrderDetailModal';
import OrderStatusChangeModal from 'components/Modal/OrderStatusChangeModal';
import SingleOrderForm from 'components/Modal/SingleOrderForm';
import SkipModal from 'components/Modal/SkipModal';
import TableColumnOrderControlModal from 'components/Modal/TableColumnOrderControlModal';
import TosModal from 'components/Modal/TosModal';
import XlsxLoader from 'components/Modal/XlsxLoader';
import PageContentWrapper from 'components/PageContentWrapper';
import {TDragablePickerOptions} from 'components/Popup/DragablePicker';

import Selector from 'components/Selector';
import {Stack, StackProps} from 'components/Stack';
import OrderManagementTable from 'components/Table/OrderManagementTable';
import {Selector as Selector2} from 'components/Timeline/components/Selector';
import {Highlight, Text} from 'components/Typography';

import {
    date_set_range_template,
    order_management_content_by_status,
    order_management_table_column_content,
    order_management_table_column_content_default_settings,
    order_management_table_column_content_hide_options,
} from 'constants/commons';
import {ReactComponent as IcFillout} from 'constants/icon/fill_out.svg';
import {ReactComponent as IcArrowDropDown} from 'constants/icon/ic_arrow_dropdown.svg';
import {ReactComponent as IcCalendar} from 'constants/icon/ic_calendar.svg';
import {ReactComponent as IcExchange} from 'constants/icon/ic_exchange.svg';
import {ReactComponent as IcWarning} from 'constants/icon/ic_file_upload_warning.svg';
import {ReactComponent as IcFilter} from 'constants/icon/ic_filter.svg';
import {ReactComponent as IcMagnifyingGlass} from 'constants/icon/ic_magnifyingglass.svg';

import {ReactComponent as IcPlus} from 'constants/icon/ic_plus.svg';
import {ReactComponent as IcXlsx} from 'constants/icon/ic_xlsx.svg';

import {ReactComponent as IcRemove} from 'constants/icon/input_remove.svg';
import {IDB_Names} from 'constants/IDB';
import {
    IGetOrderList,
    IGetOrderListResponses,
    IOrder,
    IOrderManagementContent,
    ISelectorRequires,
    TChangeableOrderStatus,
    TOrderManagementStatus,
    TPeriodContent,
} from 'constants/types';
import dayjs from 'dayjs';
import {DownloadOrderVariables, useDownloadOrder} from 'hooks/query/order/useDownloadOrder';
import setupIndexedDB, {useIndexedDBStore} from 'hooks/useIndexedDB';
import {usePrompt} from 'hooks/usePrompt';
import React, {
    CSSProperties,
    Dispatch,
    Fragment,
    MouseEvent,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {SubmitHandler, useForm, UseFormReturn, useFormState} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {useStore} from 'store';
import styled from 'styled-components';
import {UpgradeGuideModal} from '../components/Modal/warning/paymentPlan/UpgradeGuideModal';
import {ReactComponent as IcBin} from '../constants/icon/icon_bin.svg';
import {ReactComponent as IcCancle} from '../constants/icon/icon_cancle.svg';
import {TUTORIAL_QUERIES} from '../hooks/query/tutorial/tutorial.queries';
import {useTracking} from '../hooks/store/useTracking';

import strings from '../util/Localization';
import {
    CalendarIconContainer,
    CalendarInputField,
    QueueSelectorContainer,
    QueueSelectorWrapper,
} from './Report/utils/ReportFilter';
import {ReportFilterContent} from './Report/utils/ReportFilterContent';

export const FilterSearchbar = ({
                                    init,
                                    methods,
                                    content,
                                }: {
    init?: any;
    methods: UseFormReturn<any>;
    content: Array<ISelectorRequires<string> & { placeholder?: string }>;
}) => {
    const selectorRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);

    const [displayContent, setDisplayContent] = useState<string>(
        content.find(d => d.value === init)?.key ?? content[0]?.key,
    );

    const handleClickOutside = (e: any) => {
        // @ts-ignore
        if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setSelectIsOpen(false);
    };
    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, selectorRef]);

    useEffect(() => {
        if (!init) methods.reset();
    }, []);
    useEffect(() => {
        setDisplayContent(content.find(d => d.value === init)?.key ?? content[0]?.key);
    }, [content]);

    return (
        <FilterSearchbarWrapper className="filter-searchbar-wrapper" direction="row">
            <Selector2
                displayKey={methods.getValues('searchItem')}
                width={90}
                extension={126}
                scrollCriteria={32 * 6 + 2}
                gap={13}
                outline={false}
                fill={false}
                onClick={(e, v) => {
                    setDisplayContent(v.key);
                    methods.setValue('searchItem', v.value);
                }}
                options={content}
                placeholder={content[0].key}
            />

            {/* <Stack sx={{ flexBasis: 'content', position: 'relative' }}>
        <Stack
          spacing={4}
          direction="row"
          justify="space-between"
          ref={containerRef}
          onClick={() => setSelectIsOpen(prev => !prev)}
          className="search-item-select-wrapper"
        >
          <Text styleName="caption2" color="RG02" className="search-item-select">
            {displayContent}
          </Text>
          <IcArrowDropDown width={16} height={16} />
        </Stack>

        {selectIsOpen && (
          <SelectorContainer width={216} ref={selectorRef}>
            {content.map((d, index) => (
              <SelectorItem
                activated={d.value === methods.getValues('searchItem')}
                key={`${d.key}-selector-${index}`}
                onClick={() => {
                  setDisplayContent(d.key);
                  methods.setValue('searchItem', d.value);
                  setSelectIsOpen(false);
                }}
              >
                {d.key}
              </SelectorItem>
            ))}
          </SelectorContainer>
        )}
      </Stack> */}

            <SearchbarInputContainer
                spacing={4}
                direction="row"
                justify="space-between"
                isFilled={methods.watch('keyword') && methods.watch('keyword') !== ''}
            >
                <IcMagnifyingGlass
                    className="spyglass"
                    width={16}
                    height={16}
                    onClick={() => {
                        //need to add form action
                    }}
                />
                <input
                    {...methods.register('keyword', {})}
                    autoComplete="off"
                    placeholder={
                        content?.find(d => d.key === displayContent)?.placeholder ?? '주문번호, 고객명, 드라이버 등을 입력해주세요'
                    }
                />
                {methods.watch('keyword')?.length > 0 && (
                    <IcRemove
                        tabIndex={-1}
                        className="removeBtn"
                        width={16}
                        height={16}
                        onClick={() => {
                            methods.resetField('keyword');
                        }}
                    />
                )}
            </SearchbarInputContainer>
        </FilterSearchbarWrapper>
    );
};

export const OrderManagePageFilter = ({
                                          type,
                                          pageContent,
                                          pageStatus,

                                          setPagination,
                                          onSubmitCallback = () => {
                                          },
                                          sx,

                                          setFilterProps,
                                          initFilter,
                                      }: {
    type: TPageType;
    pageContent: IOrderManagementContent;
    pageStatus: TOrderManagementStatus | undefined;

    setPagination: Dispatch<SetStateAction<PaginationState>>;
    onSubmitCallback?: (v?: Partial<IGetOrderList>) => void;
    sx?: CSSProperties;

    setFilterProps?: Dispatch<SetStateAction<any>>;
    initFilter?: IGetOrderList;
}) => {
    type FormValues = {
        keyword?: string;
        searchItem?: string;

        orderStatus?: string;
        routeStatus?: string;
        shipmentType?: string;
        priority?: string;
        rangeKey?: string; //'receivedDate' | 'desiredDate' | 'completedDate' | 'performedDate' like as searchItem.key
        startDate?: string;
        endDate?: string;
    };

    const {getOrderListProps, setGetOrderListProps} = useStore();
    const methods_default_Values: FormValues = useMemo(() => {
        const initRangeKey = initFilter ? Object.keys(initFilter).find(d => d.includes('Date')) : undefined;
        const initFilterDate = initFilter ? initFilter[initRangeKey as keyof typeof initFilter] : undefined;

        return {
            keyword: initFilter?.keyword ? initFilter?.keyword : undefined,
            searchItem: initFilter?.searchItem ? initFilter?.searchItem : pageContent.filter.searchItem[0].value,

            orderStatus:
                pageStatus === 'scheduled'
                    ? 'scheduled,processing'
                    : initFilter?.orderStatus
                        ? initFilter?.orderStatus
                        : pageStatus ?? 'all',
            routeStatus: initFilter?.routeStatus
                ? initFilter?.routeStatus
                : pageContent.filter.routeStatus
                    ? pageContent.filter.routeStatus[0].value
                    : undefined,
            shipmentType: initFilter?.shipmentType ? initFilter?.shipmentType : pageContent.filter.shipmentType[0].value,
            priority: initFilter?.priority
                ? initFilter?.priority
                : pageContent.filter.priorityStatus
                    ? pageContent.filter.priorityStatus[0].value
                    : undefined,
            rangeKey: initRangeKey ? initRangeKey : pageContent.filter.rangeFilter.categoary[0].value, //'receivedDate' | 'desiredDate' | 'completedDate' | 'performedDate' like as searchItem.key
            startDate: initFilterDate ? dayjs(initFilterDate?.split('-')[0]).format('YYYY.MM.DD') : undefined,
            endDate: initFilterDate ? dayjs(initFilterDate?.split('-')[1]).format('YYYY.MM.DD') : undefined,
        };
    }, [
        initFilter,
        pageContent.filter.priorityStatus,
        pageContent.filter.rangeFilter.categoary,
        pageContent.filter.routeStatus,
        pageContent.filter.searchItem,
        pageContent.filter.shipmentType,
        pageStatus,
    ]);
    const methods = useForm<FormValues>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: methods_default_Values,
    });

    const {control, handleSubmit} = methods;
    const {dirtyFields} = useFormState({control});

    const [isFilterFolded, setIsFilterFolded] = useState<boolean>(false);

    const [shipmentTypeFilter, setShipmentTypeFilter] = useState<
        Array<{
            label: string;
            value: string;
            icon?: any;
            visibleType: boolean;
            isSelected: boolean;
        }>
    >([...pageContent.filter.shipmentType]);
    const [routeStatusFilter, setRouteStatusFilter] = useState<Array<any>>([...(pageContent.filter.routeStatus ?? [])]);
    const [orderStatusFilter, setOrderStatusFilter] = useState<Array<any>>(
        pageContent.filter.orderStatus?.map((d, index) => {
            if (pageContent.filter.orderStatus) {
                if (index === 0) {
                    return {...d, isSelected: true};
                } else return {...d, isSelected: !pageContent.filter.orderStatus[0].isSelected};
            }
        }) ?? [],
    );
    const [priorityFilter, setPriorityFilter] = useState<Array<any>>([...(pageContent.filter.priorityStatus ?? [])]);

    const [CDDIsOpen, setCDDIsOpen] = useState<boolean>(false);

    const [period, setPeriod] = useState<string>('today');
    const PeriodContent: Array<TPeriodContent> = useMemo(
        () => [
            {
                value: 'today',
                key: '오늘',
                keyDate: date_set_range_template.today.format('YYYY.MM.DD'),
                setter: () => {
                    methods.setValue('startDate', date_set_range_template.today.format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                    methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                },
                periodDate: `${date_set_range_template.today.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
            },
            {
                value: 'last_1week',
                key: '1주일',
                keyDate: date_set_range_template.week.format('YYYY.MM.DD'),
                setter: () => {
                    methods.setValue('startDate', date_set_range_template.week.format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                    methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                },
                periodDate: `${date_set_range_template.week.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
            },
            {
                value: 'last_1month',
                key: '1개월',
                keyDate: date_set_range_template.month1.format('YYYY.MM.DD'),
                setter: () => {
                    methods.setValue('startDate', date_set_range_template.month1.format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                    methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                },
                periodDate: `${date_set_range_template.month1.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
            },
            {
                value: 'last_3month',
                key: '3개월',
                keyDate: date_set_range_template.month3.format('YYYY.MM.DD'),
                setter: () => {
                    methods.setValue('startDate', date_set_range_template.month3.format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                    methods.setValue('endDate', dayjs().format('YYYY.MM.DD'), {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                },
                periodDate: `${date_set_range_template.month3.format('YYYY.MM.DD')}-${dayjs().format('YYYY.MM.DD')}`,
            },
        ],
        [methods],
    );

    useEffect(() => {
        PeriodContent.forEach(d => {
            if (d.periodDate === `${methods.getValues('startDate')}-${methods.getValues('endDate')}`) {
                d.setter();
                setPeriod(d.value);
            } else return;
        });
    }, [period, PeriodContent, methods.getValues('startDate'), methods.getValues('endDate')]);

    const updateStatus = (name: string, content: Array<any>, index: number, setter: Function) => {
        let items = [...content];

        if (index === 0) {
            items[index].isSelected
                ? (items = items.map((d, index) => {
                    return {...d, isSelected: [false, true, ...new Array(content.length - 2).fill(false)][index]};
                }))
                : (items = items.map((d, index) => {
                    return {...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index]};
                }));

            setter(items);
        } else {
            const dupper: Array<any> = [...items.slice(1)];
            if (dupper.filter(x => x.isSelected).length === 1 && items[index].isSelected) return;

            items[index].isSelected = !items[index].isSelected;

            if (items.filter(x => x.isSelected).length === 0) return;

            let activated = items.slice(1, items.length).filter(x => x.isSelected).length;
            items[0].isSelected = activated === content.length - 1;

            if (items[0].isSelected) {
                for (let i = 1; i < items.length; i++)
                    items = items.map((d, index) => {
                        return {...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index]};
                    });
            }

            setter(items);
        }

        methods.setValue(
            name as any,
            items
                .filter(d => d.isSelected)
                .map(d => d.value)
                .toString(),
            {
                shouldDirty: true,
                shouldValidate: true,
            },
        );
    };

    const onSubmit: SubmitHandler<FormValues> = values => {
        // console.log(values);

        let res: Partial<IGetOrderList> = {...getOrderListProps};
        // if (isFilterFolded) {
        //   const { keyword, searchItem } = values;

        //   if (keyword === '') {
        //     delete res['keyword'];
        //     delete res['searchItem'];

        //     setGetOrderListProps(res);
        //   } else {
        //     res = { ...res, ...{ keyword, searchItem } };
        //     setGetOrderListProps(res);
        //   }
        // } else {
        const {keyword, searchItem, startDate, endDate, rangeKey, orderStatus, routeStatus, shipmentType, priority} =
            values;

        const dateRange = `${(startDate ?? '19700101').replaceAll(/[^0-9]/g, '')}-${(
            endDate ?? dayjs().format('YYYYMMDD')
        ).replaceAll(/[^0-9]/g, '')}`;

        // res['receivedDate'] = dateRange;
        if (rangeKey) {
            ['receivedDate', 'desiredDate', 'completedDate', 'performedDate'].forEach(
                d => (res[d as keyof IGetOrderList] = undefined),
            );
            res[rangeKey as keyof IGetOrderList] = dateRange;
        }

        if (keyword === '') {
            delete res['keyword'];
            delete res['searchItem'];
        } else {
            res = {...res, ...{keyword, searchItem}};
        }
        res = {...res, orderStatus, routeStatus, shipmentType, priority};

        let validator: Partial<IGetOrderList> = {};

        Object.keys(res).forEach(key => {
            if (!Boolean(res[key as keyof IGetOrderList])) return;
            else validator[key as keyof IGetOrderList] = res[key as keyof IGetOrderList];
        });

        res = {...validator};
        setGetOrderListProps(res);

        onSubmitCallback(res);
        // }
    };

    useEffect(() => {
        console.log(methods_default_Values, 'defaults');

        methods.reset({...methods_default_Values});

        const PeriodTarget = initFilter
            ? PeriodContent.find(d => d.keyDate === methods_default_Values.startDate)
            : PeriodContent[1];

        if (PeriodTarget) {
            PeriodTarget.setter();
            setPeriod(PeriodTarget.value);
        } else {
            setPeriod('');
        }

        if (initFilter) {
            setOrderStatusFilter(
                pageContent.filter.orderStatus?.map((d, index) => {
                    return methods_default_Values.orderStatus === d.value ||
                    methods_default_Values.orderStatus?.includes(d.value) ||
                    methods_default_Values.orderStatus?.trim()?.split(',').includes(d.value)
                        ? {...d, isSelected: true}
                        : {...d, isSelected: false};
                }) ?? [],
            );

            methods_default_Values.shipmentType?.split(',').length === pageContent.filter.shipmentType.length - 1
                ? setShipmentTypeFilter(prev =>
                    prev.map(d => (d.label === '전체' ? {...d, isSelected: true} : {...d, isSelected: false})),
                )
                : setShipmentTypeFilter(prev =>
                    prev.map(d =>
                        methods_default_Values.shipmentType?.split(',').includes(d.value)
                            ? {...d, isSelected: true}
                            : {...d, isSelected: false},
                    ),
                );

            methods_default_Values?.routeStatus?.split(',').length === (pageContent.filter?.routeStatus?.length ?? 0) - 1
                ? setRouteStatusFilter(
                    (pageContent?.filter?.routeStatus ?? []).map(d =>
                        d.key === '전체' ? {...d, isSelected: true} : {...d, isSelected: false},
                    ),
                )
                : setRouteStatusFilter(
                    (pageContent?.filter?.routeStatus ?? []).map(d =>
                        methods_default_Values.routeStatus?.split(',').includes(d.value)
                            ? {...d, isSelected: true}
                            : {...d, isSelected: false},
                    ),
                );

            console.log(pageContent.filter?.priorityStatus);

            methods_default_Values?.priority?.split(',').length === (pageContent.filter?.priorityStatus?.length ?? 0) - 1
                ? setPriorityFilter(
                    (pageContent?.filter?.priorityStatus ?? []).map(d =>
                        d.key === '전체' ? {...d, isSelected: true} : {...d, isSelected: false},
                    ),
                )
                : setPriorityFilter(
                    (pageContent?.filter?.priorityStatus ?? []).map(d =>
                        methods_default_Values.priority?.split(',').includes(d.value)
                            ? {...d, isSelected: true}
                            : {...d, isSelected: false},
                    ),
                );
        } else {
            setOrderStatusFilter(
                pageContent.filter.orderStatus?.map((d, index) => {
                    if (pageContent.filter.orderStatus) {
                        if (index === 0) {
                            return {...d, isSelected: true};
                        } else return {...d, isSelected: !pageContent.filter.orderStatus[0].isSelected};
                    }
                }) ?? [],
            );
            setShipmentTypeFilter(prev =>
                prev.map(d => (d.label === '전체' ? {...d, isSelected: true} : {...d, isSelected: false})),
            );

            setRouteStatusFilter(
                (pageContent?.filter?.routeStatus ?? []).map(d =>
                    d.key === '전체' ? {...d, isSelected: true} : {...d, isSelected: false},
                ),
            );

            setPriorityFilter(
                (pageContent?.filter?.priorityStatus ?? []).map(d =>
                    d.key === '전체' ? {...d, isSelected: true} : {...d, isSelected: false},
                ),
            );

            setGetOrderListProps({
                // ...getOrderListProps,
                receivedDate: `${methods.getValues('startDate')}-${methods.getValues('endDate')}`.replaceAll('.', ''),
                orderStatus:
                    (pageStatus as TOrderManagementStatus) === 'scheduled'
                        ? 'scheduled,processing'
                        : (pageStatus as TOrderManagementStatus) ?? 'all',
            });
        }
    }, [initFilter, methods, pageContent]);

    useEffect(() => {
        if (initFilter) {
            setGetOrderListProps(initFilter);
        }
    }, [initFilter, setGetOrderListProps]);

    useEffect(() => {
        setFilterProps &&
        setFilterProps((prev: any) => {
            return {...prev, [pageStatus as TOrderManagementStatus]: getOrderListProps};
        });
        // if (pageStatus !== 'unassigned') return;
        // if (pageStatus === 'unassigned' && setFilterProps) {
        //   setFilterProps((prev: any) => {
        //     return { ...prev, unassigned: getOrderListProps };
        //   });
        // }
    }, [getOrderListProps, pageStatus, setFilterProps]);

    return (
        <FilterBox padding={10} style={sx}>
            <Stack name="default-filter-content-area" direction="row" spacing={20}>
                <FilterSearchbar init={initFilter?.searchItem} {...{methods, content: pageContent.filter.searchItem}} />

                <Button
                    type="button"
                    variant="tertiary"
                    h={32}
                    activated={!isFilterFolded}
                    icon={[IcFilter, {width: 16, height: 16}]}
                    onClick={() => setIsFilterFolded(prev => !prev)}
                >
                    상세 필터
                </Button>
                <Button
                    variant="secondary"
                    h={32}
                    onClick={e => {
                        if (isFilterFolded) setIsFilterFolded(prev => !prev);

                        handleSubmit(onSubmit)(e);
                        setPagination(prev => {
                            return {...prev, pageIndex: 0};
                        });
                    }}
                    sx={{padding: '0 16px'}}
                >
                    조회하기
                </Button>
            </Stack>
            <Stack
                name="sub-filter-wrapper"
                sx={{
                    margin: isFilterFolded ? '0' : '10px 0 0 0',
                    minHeight: 'fit-content',
                    maxHeight: isFilterFolded ? '0px' : '270px',
                    transition: 'all .6s',
                }}
            >
                <Stack
                    name="sub-filter-area"
                    spacing={10}
                    divider={<Divider color="RG07"/>}
                    align="start"
                    sx={{
                        opacity: isFilterFolded ? '0%' : '100%',
                        visibility: isFilterFolded ? 'hidden' : 'visible',
                        transition: 'opacity .5s ease',
                    }}
                >
                    <ReportFilterContent
                        title="조회 기간"
                        altInfo={`주문을 조회할 기간을 설정합니다.`}
                        anchor="bottom"
                        direction="row"
                        gap={40}
                    >
                        <Stack name="date-range-filter-area" direction="row" spacing={16} sx={{flex: 1}}>
                            <Selector
                                defaultValue={methods.getValues('rangeKey') ?? pageContent.filter.rangeFilter.categoary[0].value}
                                options={pageContent.filter.rangeFilter.categoary.filter(d =>
                                    type === 'manual' ? d.value !== 'desiredDate' : d,
                                )}
                                onOptionsClick={item => {
                                    methods.setValue('rangeKey', item.value, {});
                                }}
                                icon={<IcArrowDropDown/>}
                                sx={{width: '216px', height: '32px'}}
                            />

                            <QueueSelectorWrapper direction={'row'}>
                                {PeriodContent.map(d => {
                                    return (
                                        <QueueSelectorContainer
                                            key={d.key}
                                            selected={period === d.value}
                                            onClick={() => {
                                                setPeriod(d.value);
                                                PeriodContent.find(x => x.key === d.key)?.setter();
                                            }}
                                            len={PeriodContent.length}
                                        >
                                            <Text styleName={'caption2'} color={period === d.value ? 'RC02' : 'RG02'}>
                                                {d.key}
                                            </Text>
                                        </QueueSelectorContainer>
                                    );
                                })}
                            </QueueSelectorWrapper>

                            <ComplexDoubleviewDateselector
                                inputReadOnly
                                setPeriod={setPeriod}
                                // initializer={() => {
                                //   setPeriod(PeriodContent[1].value);
                                //   PeriodContent[1].setter();
                                // }}
                                parent={
                                    <CalendarInputField isActive={CDDIsOpen} hasError={false}>
                                        <CalendarIconContainer isActive={CDDIsOpen} hasError={false}>
                                            <IcCalendar/>
                                        </CalendarIconContainer>
                                        <input
                                            readOnly
                                            {...methods.register('startDate', {
                                                minLength: {
                                                    value: 10,
                                                    message: '',
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: '',
                                                },
                                                pattern: {
                                                    value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                                                    message: '',
                                                },
                                            })}
                                            autoComplete="off"
                                            onFocus={e => setCDDIsOpen(true)}
                                        />
                                        {'-'}
                                        <input
                                            readOnly
                                            {...methods.register('endDate', {
                                                minLength: {
                                                    value: 10,
                                                    message: '',
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: '',
                                                },
                                                pattern: {
                                                    value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                                                    message: '',
                                                },
                                            })}
                                            autoComplete="off"
                                            onFocus={e => setCDDIsOpen(true)}
                                        />
                                    </CalendarInputField>
                                }
                                {...{
                                    initDate: [
                                        dayjs(methods.getValues('startDate')).toDate(),
                                        dayjs(methods.getValues('endDate')).toDate(),
                                    ],
                                    methods,
                                    isOpen: CDDIsOpen,
                                    setIsOpen: setCDDIsOpen,
                                }}
                            />
                        </Stack>
                    </ReportFilterContent>

                    <Stack name="filter-etc-area" spacing={20} align="start">
                        {pageContent.filter?.orderStatus && (
                            <ReportFilterContent
                                title={pageStatus === 'scheduled' ? '작업 상태' : '주문 상태'}
                                altInfo={`${pageStatus === 'scheduled' ? '작업 상태' : '주문 상태'} 필터입니다.`}
                                anchor="bottom"
                                direction="row"
                                gap={20}
                            >
                                <QueueSelectorWrapper direction={'row'} sx={{width: '448px', marginLeft: '22px'}}>
                                    {orderStatusFilter.map((d, index) => {
                                        return (
                                            <QueueSelectorContainer
                                                key={d.key}
                                                selected={d.isSelected}
                                                onClick={() => {
                                                    updateStatus('orderStatus', orderStatusFilter, index, setOrderStatusFilter);
                                                }}
                                                len={7}
                                            >
                                                <Text
                                                    styleName={'caption2'}
                                                    color={period === d.value || (d.key === '전체' && d.isSelected) ? 'RC02' : 'RG02'}
                                                >
                                                    {d.key}
                                                </Text>
                                            </QueueSelectorContainer>
                                        );
                                    })}
                                </QueueSelectorWrapper>
                            </ReportFilterContent>
                        )}
                        <ReportFilterContent
                            title="주문 유형"
                            altInfo={`주문 유형 필터입니다.`}
                            anchor="bottom"
                            direction="row"
                            gap={20}
                        >
                            <QueueSelectorWrapper direction={'row'} sx={{width: '448px', marginLeft: '22px'}}>
                                {shipmentTypeFilter.map((d, index) => {
                                    return (
                                        <QueueSelectorContainer
                                            key={d.label}
                                            selected={d.isSelected}
                                            onClick={() => {
                                                updateStatus('shipmentType', shipmentTypeFilter, index, setShipmentTypeFilter);
                                            }}
                                            len={7}
                                        >
                                            <Text
                                                styleName={'caption2'}
                                                color={period === d.value || (d.label === '전체' && d.isSelected) ? 'RC02' : 'RG02'}
                                            >
                                                {d.label}
                                            </Text>
                                        </QueueSelectorContainer>
                                    );
                                })}
                            </QueueSelectorWrapper>
                        </ReportFilterContent>

                        {type === 'auto' && (
                            <ReportFilterContent
                                title="배차 우선순위"
                                altInfo={`배차 우선순위 필터입니다.`}
                                anchor="bottom"
                                direction="row"
                                gap={20}
                            >
                                <QueueSelectorWrapper direction={'row'} sx={{width: '448px'}}>
                                    {priorityFilter.map((d, index) => {
                                        return (
                                            <QueueSelectorContainer
                                                key={d.key}
                                                selected={d.isSelected}
                                                onClick={() => {
                                                    updateStatus('priority', priorityFilter, index, setPriorityFilter);
                                                }}
                                                len={7}
                                            >
                                                <Text
                                                    styleName={'caption2'}
                                                    color={period === d.value || (d.key === '전체' && d.isSelected) ? 'RC02' : 'RG02'}
                                                >
                                                    {d.key}
                                                </Text>
                                            </QueueSelectorContainer>
                                        );
                                    })}
                                </QueueSelectorWrapper>
                            </ReportFilterContent>
                        )}

                        {pageContent.filter?.routeStatus && (
                            <ReportFilterContent
                                title="경로 상태"
                                altInfo={`경로 상태 필터입니다.`}
                                anchor="bottom"
                                direction="row"
                                gap={20}
                            >
                                <QueueSelectorWrapper direction={'row'} sx={{width: '448px', marginLeft: '22px'}}>
                                    {routeStatusFilter.map((d, index) => {
                                        return (
                                            <QueueSelectorContainer
                                                key={d.key}
                                                selected={d.isSelected}
                                                onClick={() => {
                                                    updateStatus('routeStatus', routeStatusFilter, index, setRouteStatusFilter);
                                                }}
                                                len={7}
                                            >
                                                <Text
                                                    styleName={'caption2'}
                                                    color={period === d.value || (d.key === '전체' && d.isSelected) ? 'RC02' : 'RG02'}
                                                >
                                                    {d.key}
                                                </Text>
                                            </QueueSelectorContainer>
                                        );
                                    })}
                                </QueueSelectorWrapper>
                            </ReportFilterContent>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </FilterBox>
    );
};

type TPageType = 'auto' | 'manual';
const OrderManagePage = ({type}: { type: TPageType }) => {
    const navigate = useNavigate();
    const pageStatus: string | undefined = useParams()?.status ?? (type === 'manual' ? 'all' : 'unassigned');

    const PageTypeMapper: {
        [key in TPageType]: {
            k: string;
        };
    } = {
        auto: {k: '자동 배차'},
        manual: {k: '수동 배차'},
    };
    const manualBanList = useMemo<Array<string>>(
        () => (type === 'manual' ? ['desiredDate', 'desiredTimeEnd', 'desiredTimeStart', 'priority', 'skill'] : []),
        [type],
    );

    const {getOrderListProps, setGetOrderListProps, resetGetOrderListProps} = useStore();

    const [orderStatusChangeTargetStatus, setOrderStatusChangeTargetStatus] = useState<TChangeableOrderStatus | null>(
        null,
    );

    const [pricing_id, setPricing_id] = useState<number>(JSON.parse(localStorage.getItem('pricing') as string)?.id ?? 1);
    const [tosModalIsOpen, setTosModalIsOpen] = useState<boolean>(false);
    const [autoRouteConfirmModalIsOpen, setAutoRouteConfirmModalIsOpen] = useState<boolean>(false);
    const [orderStatusChangeModalIsOpen, setOrderStatusChangeModalIsOpen] = useState<boolean>(false);

    const [addOrderModal, setAddOrderModal] = useState<boolean>(false);
    const [batchOrdersModifyingModalIsOpen, setBatchOrdersModifyingModalIsOpen] = useState<boolean>(false);
    const [singleOrderFormIsOpen, setSingleOrderFormIsOpen] = useState<boolean>(false);
    const [skipModalIsOpen, setSkipModalIsOpen] = useState<boolean>(false);
    const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState<boolean>(false);
    const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(false);
    const [callbackModalOpen, setCallbackModalOpen] = useState<boolean>(false);
    const [TableColumnOrderControlModalIsOpen, setTableColumnOrderControlModalIsOpen] = useState<boolean>(false);

    const [noCancelTrigger, setNoCancelTrigger] = useState<boolean>(false);
    const [noEditPopup, setNoEditPopup] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<Array<IOrder>>([]);
    const [delTarget, setDelTarget] = useState<Array<number>>([]);

    const [rowSelection, setRowSelection] = useState({});

    const [pageContent, setPageContent] = useState<IOrderManagementContent>(
        order_management_content_by_status(type)[(pageStatus as TOrderManagementStatus) ?? 'all'],
    );

    const [tableColumnSettingOptions, setTableColumnSettingOptions] = useState<TDragablePickerOptions>(
        pageContent.tableSetting.columns.filter(d => {
            return type === 'manual' ? !manualBanList.includes(`${d.key}`) : d;
        }),
    );
    const {setTitle} = useTracking();
    const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);

    const excelUploaderRef = useRef<_IExcelUploaderRef>(null);

    useEffect(() => {
        const titleText = type === 'auto' ? '자동 최적화' : '수동';

        setTitle(`주문관리>${titleText} 배차`);
    }, [setTitle, type]);

    useEffect(() => {
        setPageContent(order_management_content_by_status(type)[(pageStatus as TOrderManagementStatus) ?? 'all']);
        // setGetOrderListProps({
        //   ...getOrderListProps,
        //   orderStatus:
        //     (pageStatus as TOrderManagementStatus) === 'scheduled'
        //       ? 'scheduled,processing'
        //       : (pageStatus as TOrderManagementStatus) ?? 'all',
        // });
    }, [pageStatus]);
    useEffect(() => {
        setTableColumnSettingOptions(pageContent.tableSetting.columns);
    }, [pageContent]);

    // caching querys what key includes getOrderList
    const queryClient = useQueryClient();
    queryClient.setQueryDefaults(['getOrderList', getOrderListProps], {
        // staleTime: 300000,
        enabled: Boolean(getOrderListProps),
    });

    const {data: noticeTos} = useQuery(['noticeTos'], getNoticeTos, {
        onSuccess: res => {
            if (res.isVisible) setTosModalIsOpen(true);
        },
    });

    const {
        data: getOrderListResponses,
        isFetching: getOrderListResponsesIsFetching,
        isSuccess: getOrderListResponsesIsSuccess,
        refetch: refetchOrderList,
    } = useQuery<IGetOrderListResponses>(
        ['getOrderList', getOrderListProps],
        () => getOrderList({...getOrderListProps, orderStatus: getOrderListProps.orderStatus ?? pageStatus}, type),
        {},
    );

    const pagination_default = {
        pageIndex: 0,
        pageSize: 10,
    };
    const [pagination, setPagination] = useState<PaginationState>(pagination_default);

    const updateFunc = ({keepCurrentPage = false}: { keepCurrentPage?: boolean }) => {
        refetchOrderList();
        console.log(keepCurrentPage);
        if (keepCurrentPage) return;
        else setPagination(pagination_default);
    };

    const hasSelectedOrder = (): boolean => selectedOrder.length <= 0;

    useEffect(() => {
        let res = selectedOrder.map(d => d.orderId);
        setDelTarget(res);

        if (selectedOrder.filter(d => d.status !== 'unassigned' && d.status !== 'skipped').length) setNoCancelTrigger(true);
        else setNoCancelTrigger(false);
    }, [selectedOrder]);

    type TcallbackType = 'no_order' | 'no_order_route';
    const callback_message_by_type: { [key in TcallbackType]: string } = {
        no_order: `최소 1개 이상의 ${strings.모달_주문}을 선택 해주세요`,
        no_order_route: `배차하려는 주문을 선택 후 사용해주세요.`,
    };

    const [callbackType, setCallbackType] = useState<TcallbackType>('no_order');

    const {showPrompt, confirmNavigation, cancelNavigation} = usePrompt(
        excelUploaderRef.current ? excelUploaderRef.current.isFailedExcel : false,
    );

    const orderDeleteButton = () => {
        return (
            <Button
                type="button"
                variant="tertiary"
                h={32}
                icon={IcCancle}
                disabled={hasSelectedOrder()}
                onClick={() => {
                    if (hasSelectedOrder()) {
                        setCallbackType('no_order');
                        return setCallbackModalOpen(true);
                    } else orderStatusChangeModalOpener('delete');
                }}
            >
                {strings.주문취소}
            </Button>
        );
    };

    const orderStatusChangeModalOpener = (targetStatus: TChangeableOrderStatus) => {
        setOrderStatusChangeModalIsOpen(true);
        setOrderStatusChangeTargetStatus(targetStatus);
    };

    type TStatusChangeButtonOptions = 'unassigned' | 'skipped';
    const StatusChangeButtonOptions = (changeTo: TStatusChangeButtonOptions) => {
        const content_mapper: {
            [key in TStatusChangeButtonOptions]: {
                name: string;
                disable: boolean;
                onClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
            };
        } = {
            unassigned: {
                name: '미배차로 변경',
                disable: hasSelectedOrder(),
                onClick(e) {
                    if (hasSelectedOrder()) {
                        setCallbackType('no_order');
                        return setCallbackModalOpen(true);
                    } else orderStatusChangeModalOpener('unassign');
                },
            },
            skipped: {
                name: '보류로 변경',
                disable: delTarget.length <= 0,
                onClick(e) {
                    if (delTarget.length <= 0) {
                        setCallbackType('no_order');
                        return setCallbackModalOpen(true);
                    } else orderStatusChangeModalOpener('skip');
                },
            },
        };

        return (
            <Button
                type="button"
                variant="tertiary"
                h={32}
                icon={[IcExchange, {width: 16, height: 16}]}
                disabled={content_mapper[changeTo].disable}
                onClick={e => {
                    content_mapper[changeTo].onClick(e);
                }}
            >
                {content_mapper[changeTo].name}
            </Button>
        );
    };

    const sc_content: {
        [key: string]: Array<ReactNode>;
    } = {
        //   - all: 전체 상태 (default)
        // - unassigned: 미배차
        // - scheduled: 배차완료
        // - processing: 처리중(processing, arrived)
        // - completed: 처리완료
        // - skipped: 보류
        // - deleted: 취소
        unassigned: [
            <LegacyButton
                type="button"
                variant="RTB"
                styleName="subheadline1"
                height={32}
                onClick={() => {
                    if (hasSelectedOrder()) {
                        setCallbackType('no_order_route');
                        return setCallbackModalOpen(true);
                    }

                    setAutoRouteConfirmModalIsOpen(true);
                }}
                sx={{minWidth: 'fit-content', padding: '0 10px'}}
            >
                선택한 주문으로 자동 최적화 배차
            </LegacyButton>,
            orderDeleteButton(),
            <Button
                type="button"
                variant="tertiary"
                h={32}
                disabled={hasSelectedOrder()}
                onClick={() => {
                    if (hasSelectedOrder()) {
                        setCallbackType('no_order');
                        return setCallbackModalOpen(true);
                    }
                    if (selectedOrder.filter(d => d.status !== 'unassigned').length > 0) return setNoEditPopup(true);
                    setBatchOrdersModifyingModalIsOpen(true);
                }}
            >
                주문정보 일괄 수정
            </Button>,
        ],
        scheduled: [StatusChangeButtonOptions('skipped')],
        skipped: type === 'manual' ? [orderDeleteButton()] : [orderDeleteButton(), StatusChangeButtonOptions('unassigned')],
        deleted: [
            <Button
                type="button"
                variant="tertiary"
                h={32}
                icon={IcBin}
                disabled={hasSelectedOrder()}
                onClick={() => {
                    if (hasSelectedOrder()) {
                        setCallbackType('no_order');
                        return setCallbackModalOpen(true);
                    } else orderStatusChangeModalOpener('clear');
                }}
            >
                주문 삭제
            </Button>,
        ],
    };

    const {update, getOneByKey} = useIndexedDBStore(IDB_Names.user.stores[0].name);

    const setColumnOrderInIDB = async (memory: TDragablePickerOptions) => {
        let IDB_CurrentUser = (await getOneByKey('userId', parseInt(window.localStorage.getItem('userId') ?? '-33'))) as {
            [key: string]: any;
        };

        if (!IDB_CurrentUser) {
            return;
        } else {
            IDB_CurrentUser.orderManageTableColumnsSetting = {
                ...IDB_CurrentUser.orderManageTableColumnsSetting,
                [pageStatus ?? 'all']: memory,
            };

            update({...IDB_CurrentUser});
        }
    };

    const getIDBColumn = async () => {
        const targetUserId = window.localStorage.getItem('userId');
        if (!targetUserId) return;
        else {
            let res = await (getOneByKey('userId', parseInt(targetUserId)) as any);

            const updater = (res?.orderManageTableColumnsSetting[pageStatus ?? 'all'] as TDragablePickerOptions)?.map(
                (d, i) => {
                    if (d.name !== pageContent.tableSetting.columns.find(x => x.key === d.key)?.name) {
                        return {
                            ...d,
                            name: pageContent.tableSetting.columns.find(x => x.key === d.key)?.name,
                        };
                    } else {
                        return d;
                    }
                },
            );

            console.log(updater, 'updater');

            if (updater) setTableColumnSettingOptions(updater as TDragablePickerOptions);
        }
    };

    useEffect(() => {
        setupIndexedDB({
            databaseName: IDB_Names.user.DB_Name,
            version: IDB_Names.user.version,
            stores: IDB_Names.user.stores,
        })
            .then(() => {
                console.log('successfully set');
            })
            .catch(e => console.error('error / unsupported', e));
    }, []);

    useEffect(() => {
        getIDBColumn();
    }, [pageStatus]);

    const [filterProps, setFilterProps] = useState<{
        [key in TOrderManagementStatus]: Pick<IOrderManagementContent, 'filter'>;
    }>({
        all: {filter: order_management_content_by_status(type).all.filter},
        completed: {filter: order_management_content_by_status(type).completed.filter},
        deleted: {filter: order_management_content_by_status(type).deleted.filter},
        priority: {filter: order_management_content_by_status(type).priority.filter},
        scheduled: {filter: order_management_content_by_status(type).scheduled.filter},
        skipped: {filter: order_management_content_by_status(type).skipped.filter},
        unassigned: {filter: order_management_content_by_status(type).unassigned.filter},
    });

    useEffect(() => {
        console.log('filterProps by status', pageStatus, filterProps[pageStatus as TOrderManagementStatus]);
    }, [filterProps, pageStatus]);

    const [initialFilterMapper, setInitialFilterMapper] = useState<{ [key in TOrderManagementStatus]?: IGetOrderList }>(
        {},
    );
    useEffect(() => {
        setInitialFilterMapper(prev => {
            return {
                ...prev,
                [pageStatus]: getOrderListProps,
                // [getOrderListProps.orderStatus!]: getOrderListProps,
            };
        });
    }, [getOrderListProps]);

    return (
        <Fragment>
            <Stack align={'flex-start'} direction={'row'}>
                <PageContentWrapper>
                    <Stack
                        sx={{padding: '48px 48px 100px', minWidth: '1100px'}}
                        align={'start'}
                        justify={'start'}
                        name="orderManagePage"
                        useVerticalScroll
                    >
                        <Stack align={'start'} sx={{width: '100%'}}>
                            <Stack name="header-wrapper">
                                <Stack
                                    name="header-area"
                                    justify="space-between"
                                    direction="row"
                                    align="start"
                                    sx={{marginBottom: '40px'}}
                                >
                                    <Stack spacing={8} align={'start'}>
                                        <Stack
                                            spacing={8}
                                            direction="row"
                                            divider={<Divider color="RG06" vertical style={{height: 16}}/>}
                                        >
                                            <Text styleName={'title2'} color={'RC02'}>
                                                {PageTypeMapper[type].k}
                                            </Text>
                                            <Text styleName={'title1'}>{pageContent.title}</Text>
                                        </Stack>

                                        <Text styleName={'caption2'}>{pageContent.desc}</Text>
                                    </Stack>

                                    <Stack justify="end" direction="row" spacing={20}>
                                        <DownloadButton<DownloadOrderVariables>
                                            text="주문"
                                            variables={{orderIds: selectedOrder.map(item => item.orderId)}}
                                            isDisabled={!selectedOrder.length}
                                            extension="xlsx"
                                            useDownloadReactQueryHook={useDownloadOrder}
                                        />
                                        {type !== 'manual' && (
                                            <Button
                                                type="button"
                                                variant="primary"
                                                h={32}
                                                w={154}
                                                icon={IcPlus}
                                                onClick={() => {
                                                    setAddOrderModal(true);
                                                }}
                                            >
                                                주문 추가
                                            </Button>
                                        )}
                                    </Stack>
                                </Stack>
                                <Stack name="tab-and-filter" sx={{marginBottom: '30px'}}>
                                    <Stack name="order-page-tap-by-status" direction={'row'}>
                                        {Object.values(order_management_content_by_status(type))
                                            .filter(d => d.status !== '우선순위')
                                            .filter(d => (type === 'manual' ? d.status !== '미배차' : d))
                                            .map((d, i) => {
                                                return (
                                                    <Tab
                                                        index={i}
                                                        isActive={
                                                            d.status ===
                                                            order_management_content_by_status(type)[pageStatus as TOrderManagementStatus].status
                                                        }
                                                        onClick={() => {
                                                            navigate(
                                                                `${
                                                                    (
                                                                        Object.entries(order_management_content_by_status(type)).find(item => {
                                                                            const [k, v] = item;
                                                                            return v.status === d.status;
                                                                        }) as any
                                                                    )[0]
                                                                }`,
                                                            );
                                                        }}
                                                    >
                                                        {d.title}
                                                    </Tab>
                                                );
                                            })}
                                    </Stack>

                                    {useMemo(() => {
                                        return (
                                            <OrderManagePageFilter
                                                initFilter={initialFilterMapper[pageStatus as TOrderManagementStatus]}
                                                {...{
                                                    type,
                                                    setPagination,
                                                    pageContent,
                                                    pageStatus: pageStatus as TOrderManagementStatus,
                                                    setFilterProps,
                                                }}
                                            />
                                        );
                                    }, [pageContent, pageStatus, type])}
                                </Stack>
                            </Stack>
                            <OrderManagementTable
                                data={getOrderListResponses?.orderList ?? []}
                                isFetching={getOrderListResponsesIsFetching}
                                scContent={sc_content[pageStatus as string]}
                                pageStatus={pageStatus}
                                {...{
                                    setTableColumnOrderControlModalIsOpen,
                                    setSelectedOrder,
                                    setDeleteOrderModalOpen,
                                    setDelTarget,
                                    selectedOrder,
                                    rowSelection,
                                    setRowSelection,
                                    tableColumnSettingOptions,

                                    pagination,
                                    setPagination,
                                }}
                            />
                        </Stack>
                    </Stack>
                </PageContentWrapper>
            </Stack>

            <SingleOrderForm
                {...{
                    singleOrderFormIsOpen,
                    setSingleOrderFormIsOpen,
                    updateFunc: () => updateFunc({keepCurrentPage: false}),
                }}
            />
            <Modal
                padding={24}
                width={503}
                isModalOpen={xlsxFormIsOpen}
                setIsModalOpen={setXlsxFormIsOpen}
                confirm={{
                    value: showPrompt,
                    message: (
                        <Text styleName="subheadline2" color="RG02">
                            <Highlight color="RC04">업로드 실패 엑셀을 다운로드 하지 않았습니다.</Highlight>
                            <br/>
                            다운로드를 하시겠습니까?
                        </Text>
                    ),
                    LBT: '닫기',
                    RBT: '확인',
                }}
            >
                <XlsxLoader
                    ref={excelUploaderRef}
                    {...{
                        setXlsxFormIsOpen,
                        registerCallbackFunction: () => {
                            updateFunc({keepCurrentPage: false});
                            navigate('/manage/order/unassigned', {replace: true});

                            queryClient.invalidateQueries({queryKey: TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_ROUTE()});
                        },
                    }}
                />
            </Modal>

            <Modal isModalOpen={showPrompt} setIsModalOpen={() => {
            }} width={486} padding={24} ms={5}>
                <Stack name="delete-vehicle-modal">
                    <Stack name="content-area" spacing={16} sx={{padding: '30px 0 40px'}}>
                        <Text styleName="subheadline2" color="RG02">
                            <Highlight color="RC04">업로드 실패 엑셀을 다운로드 하지 않았습니다.</Highlight>
                            <br/>
                            다운로드를 하시겠습니까?
                        </Text>
                    </Stack>

                    <Stack direction="row" spacing={16}>
                        <Button
                            type="button"
                            variant="tertiary"
                            h={40}
                            fw
                            onClick={() => {
                                confirmNavigation();
                            }}
                        >
                            닫기
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            h={40}
                            fw
                            onClick={() => {
                                cancelNavigation();
                            }}
                        >
                            확인
                        </Button>
                    </Stack>
                </Stack>
            </Modal>

            <CancelOrderModal
                {...{deleteOrderModalOpen, setDeleteOrderModalOpen, delTarget, setDelTarget, noCancelTrigger}}
            />

            <BatchOrdersModifyingModal
                refetcher={() => updateFunc({keepCurrentPage: true})}
                {...{
                    targetOrderList: selectedOrder.map(d => d.orderId),
                    batchOrdersModifyingModalIsOpen,
                    setBatchOrdersModifyingModalIsOpen,
                }}
            />
            <Modal isModalOpen={noEditPopup} setIsModalOpen={setNoEditPopup} padding={24} width={503}>
                <Stack>
                    <Stack sx={{padding: '40px 0'}}>
                        <Text styleName="subheadline2" color="RC04">
                            ‘{strings.미배차}’ 상태인 {strings.모달_주문}만 수정이 가능합니다.
                        </Text>
                        <Text styleName="subheadline3" color="RG03">
                            다른 상태의 {strings.모달_주문}을 제외하고 다시 시도해주세요.
                        </Text>
                    </Stack>
                    <LegacyButton
                        variant="fourth"
                        type="button"
                        height={40}
                        fullWidth
                        styleName="body2"
                        color="RG04"
                        onClick={() => {
                            setNoEditPopup(false);
                        }}
                    >
                        닫기
                    </LegacyButton>
                </Stack>
            </Modal>

            <Modal isModalOpen={callbackModalOpen} setIsModalOpen={setCallbackModalOpen} padding={24} width={503}>
                <Stack>
                    <Stack>
                        {callbackType !== 'no_order_route' && <IcWarning width={44} height={44}/>}
                        <Text
                            styleName="subheadline2"
                            color="RG02"
                            sx={{
                                padding: '30px 0 40px 0',
                            }}
                        >
                            {callback_message_by_type[callbackType]}
                        </Text>
                    </Stack>
                    <Button
                        type="button"
                        variant="tertiary"
                        h={40}
                        fw
                        onClick={() => {
                            setCallbackModalOpen(false);
                        }}
                    >
                        확인
                    </Button>
                </Stack>
            </Modal>

            <Modal
                title={''}
                isModalOpen={addOrderModal}
                setIsModalOpen={setAddOrderModal}
                ds="strong"
                padding={24}
                // ms={48}
                width={701}
            >
                <Stack>
                    <Text styleName="title2" color="RG02" sx={{padding: '30px 0 40px'}}>
                        주문 추가 방식을 선택해주세요.
                    </Text>
                    <Stack
                        direction="row"
                        spacing={24}
                        justify="space-between"
                        divider={<Divider vertical/>}
                        sx={{height: '184px'}}
                    >
                        <BorderBox
                            onClick={() => {
                                setAddOrderModal(false);
                                setXlsxFormIsOpen(true);
                            }}
                            hover={{bg: 'RG08', border: 'RC10'}}
                        >
                            <Stack spacing={12}>
                                <CircleBox width={64} bg="RC10_1">
                                    <IcXlsx/>
                                </CircleBox>
                                <Text styleName="body2" color="RG02">
                                    여러 건 엑셀로 추가
                                </Text>
                            </Stack>
                        </BorderBox>

                        <BorderBox
                            onClick={() => {
                                setAddOrderModal(false);
                                setSingleOrderFormIsOpen(true);
                            }}
                            hover={{bg: 'RG08', border: 'RC03'}}
                        >
                            <Stack spacing={12}>
                                <CircleBox width={64} bg="RC03_1">
                                    <IcFillout/>
                                </CircleBox>

                                <Text styleName="body2" color="RG02">
                                    한 건 직접 추가
                                </Text>
                            </Stack>
                        </BorderBox>
                    </Stack>
                </Stack>
            </Modal>
            <TosModal isOpen={tosModalIsOpen} setIsOpen={setTosModalIsOpen}/>
            <CloseConfirmModal
                isOpen={autoRouteConfirmModalIsOpen}
                setIsOpen={setAutoRouteConfirmModalIsOpen}
                targetSetIsOpen={() => {
                }}
                text={
                    <Text styleName="subheadline2" color="RG02">
                        선택한 주문으로 자동 최적화 배차 계획을 시작합니다.
                        <br/>
                        확인을 클릭하면 배차 계획 페이지로 이동합니다.
                    </Text>
                }
                btnFunctions={{
                    RBT: () => {
                        sessionStorage.clear();
                        navigate('/manage/route/optimize', {
                            state: {
                                index: 1,
                                orders: selectedOrder,
                                getProps: filterProps.unassigned,
                            },
                        });
                    },
                }}
                RBT={'확인'}
                LBT={'취소'}
            />

            <SkipModal
                isOpen={skipModalIsOpen}
                setIsOpen={setSkipModalIsOpen}
                target={delTarget}
                setTarget={setDelTarget}
                refetcher={() => updateFunc({keepCurrentPage: false})}
                noCancelTrigger={false}
            />

            {orderStatusChangeTargetStatus && (
                <OrderStatusChangeModal
                    targetStatus={orderStatusChangeTargetStatus}
                    isOpen={orderStatusChangeModalIsOpen}
                    setIsOpen={setOrderStatusChangeModalIsOpen}
                    targets={delTarget}
                    setTargets={setDelTarget}
                    noCancelTrigger={false}
                    refetcher={() => updateFunc({keepCurrentPage: true})}
                />
            )}

            <TableColumnOrderControlModal
                isOpen={TableColumnOrderControlModalIsOpen}
                setIsOpen={setTableColumnOrderControlModalIsOpen}
                options={tableColumnSettingOptions}
                setOptions={setTableColumnSettingOptions}
                banList={manualBanList.concat(
                    order_management_table_column_content_hide_options[pageStatus as TOrderManagementStatus],
                )}
                initial={order_management_table_column_content.map(d =>
                    order_management_table_column_content_default_settings[
                    (pageStatus as TOrderManagementStatus) ?? 'all'
                        ].includes(d.key as string)
                        ? {...d, isSelected: true}
                        : {...d},
                )}
                setColumnOrderInIDB={setColumnOrderInIDB}
            />

            <UpgradeGuideModal
                isOpen={isOpenUpgradeGuideModal}
                setIsOpen={setIsOpenUpgradeGuideModal}
                upgradeGuidePlan={['Lite', 'Standard', 'Pro', 'Enterprise']}
                text="요금제로 업그레이드 하시면 해당 기능을 사용하실 수 있습니다."
            />
        </Fragment>
    );
};

export default OrderManagePage;

export const FilterBox = styled.form<StackProps>`
  width: 100%;
  display: flex;
  align-items: center;
  ${({direction, spacing, padding, align, justify}) => ({
    flexDirection: direction || 'column',
    gap: spacing || 0,
    padding: padding || 0,
    alignItems: align || 'center',
    justifyContent: justify || 'start',
  })}
  background: ${({theme}) => theme.colors.RG08};
  border-radius: 0 8px 8px;
`;

export const Tab = styled.div<{ isActive: boolean; index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  padding: 6px 10px;
  margin-left: ${({isActive, index}) => index > 0 && !isActive && '-1px'};
  box-sizing: border-box;
  // RT-762
  color: ${({isActive, theme}) => theme.colors[isActive ? 'RC02' : 'RG02']};
  background-color: ${({isActive, theme}) => theme.colors[isActive ? 'RG08' : 'RG00']};
  border-radius: 8px 8px 0px 0px;
  border: ${({theme}) => `1px solid ${theme.colors.RG07}`};
  cursor: pointer;
  ${({theme, isActive}) => theme.fontStyle[isActive ? 'caption1' : 'caption2']}
`;

export const SearchbarInputContainer = styled(Stack)<{ isFilled: boolean }>`
  padding: 6px 16px 6px 10px;

  & > input {
    width: 100%;
    background: transparent;

    ::placeholder {
      color: ${({theme}) => theme.colors.RG04};
    }
  }

  /* & > svg {
      cursor: pointer;
    } */

  & > .removeBtn {
    display: none;
    cursor: pointer;
    margin-right: -8px;
  }

  & > .spyglass > path {
    stroke: ${({theme}) => theme.colors.RG04};
  }

  :focus-within {
    & > .spyglass {
      width: 0;
      transition: all 0.4s;
    }

    & > .removeBtn {
      display: block;
    }
  }

  ${({isFilled}) =>
          isFilled && {
            '& > .spyglass': {
              width: 0,
              transition: 'all 0.4s',
            },
          }}
`;

export const FilterSearchbarWrapper = styled(Stack)<{ width?: string }>`
  ${({theme}) => theme.fontStyle.caption2}
  width: 502px;
  height: 32px;

  .search-item-select-wrapper {
    width: 90px;
    margin: 0 0 0 16px;
    cursor: pointer;
  }

  .search-item-select {
    color: ${({theme}) => theme.colors.RG04};
  }

  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.RG06};
  background-color: ${({theme}) => theme.colors.RG00};

  :hover {
    border: 1px solid ${({theme}) => theme.colors.RC03};

    .search-item-select {
      color: ${({theme}) => theme.colors.RG02};
    }
  }

  :focus-within {
    border: 1px solid ${({theme}) => theme.colors.RC03};

    & > input {
      ::placeholder {
        color: ${({theme}) => theme.colors.RG00};
      }
    }
  }

  transition: all 0.2s;

  & > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const BorderBox = styled.div<{
    hover: {
        bg: string;
        border: string;
    };
}>`
  width: 100%;
  height: 184px;
  padding: 42px 20px;
  border-radius: 8px;
  border: ${({theme}) => `1px solid ${theme.colors.RG06}`};
  cursor: pointer;

  :hover {
    border: ${({theme, hover}) => `1px solid ${theme.colors[hover.border]}`};
    background: ${({theme, hover}) => theme.colors[hover.bg]};
  }

  transition: all 0.5s;
`;

const CircleBox = styled.div<{
    width: number;
    bg: string;
    opacity?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme, width, bg}) => ({
    width: width,
    height: width,
    background: theme.colors[bg],
    borderRadius: `calc(${width}px / 2)`,
  })};
`;
