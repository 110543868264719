import SettingNavigation from 'components/SidebarLayout/SettingNavigation';

import { Stack } from 'components/Stack';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_bd.svg';
import { TS3 } from 'constants/types';
import { useSetting } from 'hooks/store/useSetting';
import { ManagerContent } from 'pages/Setting/organization/Manager';
import { DriverApp } from 'pages/Setting/resource/DriverApp/DriverApp';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { TSettingAttrs } from 'types/setting/union';
import MasterOrder from './masterData/MasterOrder';
import MultiSkill from './masterData/MultiSkill';

import Team from './organization/Team';
import Driver from './resource/Driver';
import RouteArea from './RouteArea';

const Setting = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: TS3<boolean> }) => {
  const { current, setCurrent, setIsFatalCorrectionsOnPost } = useSetting();

  const fatalCorrectionDetectorWithFence = useCallback(() => {
    const pathname = window.location.pathname;
    const uri = '/manage/route/confirm/';
    const fence = pathname.includes(uri);

    if (!fence) return;

    setIsFatalCorrectionsOnPost(true);
  }, [window.location.pathname, setIsFatalCorrectionsOnPost]);

  const setting_contents: { [key in TSettingAttrs]: React.ReactElement } = {
    team: <Team {...{ navTo: setCurrent, fatalCorrectionDetectorWithFence }} />,
    manager: <ManagerContent />,
    driver: <Driver {...{ setSettingIsOpen: setIsOpen, fatalCorrectionDetectorWithFence }} />,

    app: <DriverApp />,
    area: <RouteArea {...{ fatalCorrectionDetectorWithFence }} />,

    masterOrder: <MasterOrder />,
    multiSkill: <MultiSkill />,
  };
  const wrapper_min_width = '1192px';
  const wrapper_width = useMemo<string>(() => {
    return current === 'area' ? '84vw' : '62vw';
  }, [current]);

  return (
    <SettingWrapper
      direction="row"
      align="start"
      onClick={e => {
        e.stopPropagation();
      }}
      width={wrapper_width}
      minWidth={wrapper_min_width}
      settingIsOpen={isOpen}
    >
      {useMemo(() => {
        return (
          <IcClose
            onClick={e => {
              e.stopPropagation();
              setIsOpen(p => !p);
            }}
            style={{
              visibility: isOpen ? 'visible' : 'hidden',
              cursor: 'pointer',
              position: 'absolute',
              right: `max(${wrapper_min_width},${wrapper_width})`,
            }}
          />
        );
      }, [isOpen, setIsOpen, wrapper_width])}
      <SettingNavigation {...{ current: current, setCurrent: setCurrent }} />
      <Stack style={{ height: '100%' }} useVerticalScroll useHorizontalScroll>
        {setting_contents[current]}
      </Stack>
    </SettingWrapper>
  );
};

export default Setting;

const SettingWrapper = styled(Stack)<{ width: string; minWidth: string; settingIsOpen: boolean }>`
  ${({ theme, width, minWidth, settingIsOpen }) => ({
    borderTop: `1px solid ${theme.colors.RG06}`,

    display: 'flex',
    width: width,
    minWidth: minWidth,
    height: 'calc(100vh - 72px)',
    background: 'white',
    transform: settingIsOpen ? 'none' : `translateX(max(${width},${minWidth}))`,

    cursor: 'default',
  })}
`;
