import { useState, ReactNode, ComponentPropsWithRef } from 'react';
import * as S from './style';

import { Stack } from 'components/Stack';

import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';

interface GraphicCardProps extends ComponentPropsWithRef<'div'> {
  graphic: {
    origin: ReactNode;
    evented?: ReactNode;
  };

  spacing?: number;
  check?: boolean;
  isActive?: boolean;

  children?: ReactNode;
}

const GraphicCard = ({ graphic, check, children, spacing = 10, ...rest }: GraphicCardProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <S.Container onMouseEnter={e => setIsHover(true)} onMouseLeave={e => setIsHover(false)} {...rest}>
      {check && (
        <IcSuccess
          width={16}
          height={16}
          fill={theme.colors[rest.isActive ? 'RC02' : 'RG05']}
          style={{ position: 'absolute', top: 10, right: 10 }}
        />
      )}
      <Stack
        spacing={spacing}
        //  sx={{ width: '112px', padding: '0 13px' }}
      >
        {isHover || rest?.isActive ? graphic.evented ?? graphic.origin : graphic.origin}
        {children ? children : null}
      </Stack>
    </S.Container>
  );
};

export default GraphicCard;
