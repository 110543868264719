export const ORDER_QUERIES = {
  SCOPE: [{ scope: 'ORDER' }] as const,
  DOWNLOAD_ORDER: () => [
    {
      ...ORDER_QUERIES.SCOPE[0],
      group: 'order',
      entity: 'download_order',
      endPoint: `order/download`,
    },
  ],
};
