import { Children, ComponentPropsWithRef, CSSProperties, forwardRef, Fragment, ReactNode } from 'react';
import * as S from './style';

export interface StackProps extends ComponentPropsWithRef<'div'> {
  children: ReactNode;

  /**  flex-direction 에 대한 방향 스타일 속성값입니다*/
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';

  divider?: ReactNode;

  /** children 요소 사이의 여백 값으로 예제에선 number를 사용합니다*/
  spacing?: string | number;

  /** Stack 컴포넌트 박스에 대한 padding 값입니다*/
  padding?: number;

  bg?: TThemeColor;

  justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';

  align?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | 'baseline'
    | 'stretch';

  /** CSS override를 위한 props*/
  sx?: CSSProperties;

  name?: string;

  useHorizontalScroll?: boolean;
  useVerticalScroll?: boolean;
}

export const Stack = forwardRef<any, StackProps>(({ sx, children, divider, ...props }, ref) => {
  let countofChilds = Children.count(children);

  return (
    <S.Stack
      id={
        (props.useHorizontalScroll || props.useVerticalScroll) && typeof props.name === 'string'
          ? `${props.name}-scroll-container`
          : props.name
      }
      style={sx}
      {...props}
      key={props.name}
      ref={ref}
    >
      {Children.map(children, (child, index) => {
        return (
          <Fragment>
            {child}
            {child && index < countofChilds - 1 && divider}
          </Fragment>
        );
      })}
    </S.Stack>
  );
});
