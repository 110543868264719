import { create } from 'zustand';

interface UseNoticeProps {
  noticeId: string;
  setNoticeId: (noticeId: string) => void;
}

const useNotice = create<UseNoticeProps>(set => ({
  noticeId: '',
  setNoticeId: noticeId => set({ noticeId }),
}));

export { useNotice };
export type { UseNoticeProps };
