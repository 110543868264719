import { Fragment, useMemo, useState, SetStateAction, Dispatch, createElement, ReactElement } from 'react';
import {
  PaginationState,
  getCoreRowModel,
  getPaginationRowModel,
  createColumnHelper,
  useReactTable,
} from '@tanstack/react-table';
import {
  IManualExcelRouteRequestOrder,
  IManualExcelRouteRequestResponseList,
  ImanualExcelValidateOrderList,
  ImanualExcelValidateVehicleList,
} from 'constants/types';

import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';
import { ReactComponent as IconPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcHub } from 'constants/icon/icon_hub.svg';

import TableComponent from '.';
import { Stack } from 'components/Stack';
import { RowDirectionContainer } from './style';
import { common_table_selection, shipment_type } from 'constants/commons';
import IconPalette, { IconMarker } from './util/IconPalette';
import TableSelectManager from './util/TableSelectManager';

let manualRouteTable = createColumnHelper<IManualExcelRouteRequestOrder>();

const ManualRouteTable = ({
  data,
  setManualExcelValidatedData,
  targetId,
  onSelect,
}: {
  data: Array<IManualExcelRouteRequestResponseList>;
  setManualExcelValidatedData: Dispatch<SetStateAction<Array<IManualExcelRouteRequestResponseList>>>;
  targetId: number;
  onSelect: (id: string) => void;
}) => {
  const domain = window.localStorage.getItem('serviceDomain');

  const [dndTooltipIsOpen, setDndTooltipIsOpen] = useState<boolean>(true);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnVisibility, setColumnVisibility] = useState({});

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const paletteContent: Array<{ icon: ReactElement; label: string }> = [
    {
      icon: createElement(shipment_type[0].icon.ff, {}),
      label: '배달',
    },
    {
      icon: createElement(shipment_type[1].icon.ff, {}),
      label: '수거',
    },
    {
      icon: <IcHub />,
      label: '도착지',
    },
  ];
  const columns = useMemo(
    () => [
      // 작업 희망/ 소요시간 추가
      // manualRouteTable.accessor(row => row., {
      //   id: 'serviceTime',
      //   header: '작업소요시간',
      //   footer: info => info.column.id,
      // }),
      manualRouteTable.accessor(row => 1, {
        id: 'index',
        header: '순서',
        cell: info => info.row.index + 1,
        footer: info => info.column.id,
      }),
      manualRouteTable.accessor(row => row?.shipmentType, {
        id: 'types',
        header: '주문 유형',
        cell: info => {
          const rowShipmentType = info.getValue();
          return (
            <IconMarker
              size={[20, 12.5]}
              icon={createElement(shipment_type.find(d => d.value === rowShipmentType)?.icon['ff'] ?? 'div', {})}
            />
          );
        },
        footer: info => info.column.id,
      }),
      manualRouteTable.accessor(row => row.consigneeName, {
        id: 'consigneeName',
        header: '고객명',
        footer: info => info.column.id,
      }),
      manualRouteTable.accessor(row => (row?.address ? row.address : '-'), {
        id: 'address',
        header: () => '주소',
        footer: info => info.column.id,
      }),
      manualRouteTable.accessor(row => row.detailAddress, {
        id: 'detailAddress',
        header: '상세 주소',
        footer: info => info.column.id,
      }),
      manualRouteTable.accessor(
        row => {
          if (row.product) {
            if (row.product.length >= 2) return `${row.product[0].name ?? ''} 외 ${row.product.length - 1}건`;
            else return `${row.product[0]?.name ?? ''}`;
          }
          return '-';
        },
        {
          id: 'product',
          header: '아이템',
          footer: info => info.column.id,
        }
      ),
    ],
    [domain]
  );

  const instance = useReactTable({
    data: Array.from(data.find(d => d.vehicle.vehicleId === targetId)?.orderList ?? []),
    columns,
    state: {
      pagination,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const reorderData = (startIndex: number, endIndex: number) => {
    const newData = [...data];
    const [movedRow] = newData.find(d => d.vehicle.vehicleId === targetId)!.orderList.splice(startIndex, 1);
    newData.find(d => d.vehicle.vehicleId === targetId)!.orderList.splice(endIndex, 0, movedRow);

    console.log(movedRow);
    console.log(newData);

    setManualExcelValidatedData([...newData]);
  };

  return (
    <Fragment>
      <TableComponent<ImanualExcelValidateOrderList>
        onRowClick={(e, row) => {
          row.original.mId && onSelect(row.original.mId);
        }}
        sc={[
          <IconPalette
            spacing={10}
            size={[20, 12.5]}
            direction="row"
            icon={paletteContent.map(d => d.icon)}
            label={paletteContent.map(d => d.label)}
          />,
        ]}
        sortDrag={{
          dragFunc: {
            handleDragStart: () => {
              console.log('drag on start');

              setDndTooltipIsOpen(false);
            },
            handleDragEnd: result => {
              console.log(result);
              const { source, destination } = result;
              if (!destination) return;
              reorderData(source.index, destination.index);
            },
          },
        }}
        dndTooltip={{
          isOpen: dndTooltipIsOpen,
          setIsOpen: setDndTooltipIsOpen,
          height: 52,
          content: `주문의 순서를 수동으로 바꿀 수 있습니다.`,
        }}
        clearBottom
        table={instance}
        isDataFetching={false}
        disableOptions={{ selectCount: true, totalCount: true }}
        {...{ pagination, setPagination }}
      />
      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}
    </Fragment>
  );
};

export default ManualRouteTable;
