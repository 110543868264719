import { flexRender, PaginationState, Row, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import Pagination from 'components/Pagination';

import SpeechBubble from 'components/Popup/SpeechBubble';

import Selector from 'components/Selector';

import { Stack } from 'components/Stack';

import * as S from 'components/Table/style';
import DraggableRow from 'components/Table/util/DraggableRow';
import { Text } from 'components/Typography';
import { ReactComponent as IconSortDesc } from 'constants/icon/btn_table_sort_down.svg';
import { ReactComponent as IconSortAsc } from 'constants/icon/btn_table_sort_up.svg';

import {
  ReactComponent as IconSortDefault,
  ReactComponent as IcArrowDF,
} from 'constants/icon/ic_arrow_double_faced.svg';
import { ReactComponent as IconCellDragHandle } from 'constants/icon/ic_drag_handle.svg';

import theme from 'constants/theme';
import * as C from 'pages/Setting/common/style';
import { Dispatch, MouseEvent, ReactNode, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface ITable {
  table: Table<any>;
  isDataFetching: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  placeholder?: ReactNode | string;
  setRowSelection?: Function;
  draggableRow?: boolean;
  disableOptions?: {
    totalCount?: boolean;
    selectCount?: boolean;
    pagnation?: boolean;
    pageSizeSelector?: boolean;
  };
  tableHeaderContent?: ReactNode;
  dndTooltip?: {
    isOpen: boolean;
    content: string;
    height: number;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
  tableRowCount: TTableRowCounter;

  onRowClick?: (e: MouseEvent<HTMLElement>, row: Row<any>) => void;
  sortDrag?: {
    dragFunc: {
      handleDragStart?: () => void;
      handleDragEnd: (result: any) => void;
    };
  };
  tableHeight?: number;
  tableRowHeight?: number;
  clearBottom?: boolean;
  emptyDataMessage?: string;
  hoverSelect?: boolean;
}

export const NewTableComponent = (props: ITable) => {
  const {
    table,
    isDataFetching,
    pagination,
    setRowSelection,
    setPagination,
    placeholder,
    draggableRow,
    emptyDataMessage,
    onRowClick,
    disableOptions = {
      totalCount: false,
      selectCount: false,
      pagnation: false,
      pageSizeSelector: false,
    },
    sortDrag,

    dndTooltip,
    tableRowCount: { totalCount, eachCount },
    clearBottom = false,

    tableHeight,
    tableRowHeight,
    tableHeaderContent,
    hoverSelect,
  } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: i => rows[i].index,
    overscan: pagination.pageSize,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const hasSelectColumn = () => {
    return Boolean(
      table.getAllColumns().find((data: any) => {
        if (data.accessorFn === undefined && data.id?.includes('group')) {
          return data.columns.find((column: any) => column.id === 'select');
        }
        return data.id === 'select';
      })
    );
  };

  /*FIXME: 공용컴포넌트로 변경하기 위해서는 특정 컴포넌트에서만 요구되는 기능은 이곳에 적용하면 안됌 (지울 것)
		기본적으로 제공되어야 하는 데이터는 테이블 컴포넌트에서 부모 컴포넌트로 올려줘야 함
		ex) 체크박스에 체크된 row 개수
	 */

  useEffect(() => {
    if (table.getSelectedRowModel()) {
      const item = table.getSelectedRowModel().rows.map(row => row.original);
      setRowSelection && setRowSelection(item);
    }
  }, [table.getSelectedRowModel().rows.length]);

  const paginationSelectorOptions = useMemo(
    () => [
      { key: '10개씩 보기', value: 10 },
      { key: '20개씩 보기', value: 20 },
      { key: '30개씩 보기', value: 30 },
      { key: '40개씩 보기', value: 40 },
      { key: '50개씩 보기', value: 50 },
    ],
    []
  );
  const totalCountStyle = eachCount
    ? { borderLeft: '1px solid #D8DDEA', paddingLeft: '10px', lineHeight: '12px' }
    : { lineHeight: '12px' };
  return (
    <S.TableWrapper height={tableHeight}>
      <Stack
        justify="space-between"
        direction="row"
        sx={{
          marginBottom:
            (hasSelectColumn() && (!disableOptions.selectCount || !disableOptions.totalCount)) ||
            (!disableOptions.pageSizeSelector && disableOptions.selectCount && disableOptions.totalCount)
              ? '16px'
              : '0px',
        }}
      >
        {clearBottom && (
          <Stack justify="space-around" direction="row">
            <Stack direction="row" spacing={12} sx={{ flex: 1, height: '32px', marginBottom: '12px' }}>
              <C.TableHeader>
                {eachCount && (
                  <Text
                    styleName="caption2"
                    color={table.getSelectedRowModel().rows.length > 0 ? 'RC02' : 'RG03'}
                    sx={{ minWidth: 'fit-content' }}
                  >
                    {`선택된 ${eachCount.text} : ${table.getSelectedRowModel().rows.length} ${eachCount.unit}`}
                  </Text>
                )}
                {totalCount && (
                  <Text
                    styleName="caption2"
                    color={table.getSelectedRowModel().rows.length > 0 ? 'RC02' : 'RG03'}
                    sx={totalCountStyle}
                  >
                    {`총 ${totalCount.text} : ${table
                      .getCoreRowModel()
                      .rows.filter(
                        (d: any) =>
                          d.original?.type !== 'end' && d.original?.type !== 'start' && d.original?.type !== 'break'
                      )
                      .length.toString()} ${totalCount.unit}`}
                  </Text>
                )}
              </C.TableHeader>
              {!disableOptions.pageSizeSelector && (
                <Selector
                  sx={{ height: '32px' }}
                  anchor="bottom"
                  defaultValue={pagination.pageSize}
                  options={paginationSelectorOptions}
                  onOptionsClick={item =>
                    setPagination(prev => {
                      return { ...prev, pageIndex: 0, pageSize: item.value };
                    })
                  }
                  icon={<IcArrowDF fill={theme.colors.RG04} />}
                />
              )}
            </Stack>
            <Stack direction="row" spacing={12} sx={{ width: 'fit-content', marginBottom: '12px' }}>
              {tableHeaderContent}
            </Stack>
          </Stack>
        )}
      </Stack>

      <S.TableScrollable ref={tableContainerRef} isEmpty={table.getCoreRowModel().rows.length === 0 || isDataFetching}>
        <S.CTable rowHeight={tableRowHeight}>
          <thead>
            {table.getHeaderGroups().map((headerGroup: any) => {
              return (
                <S.CtrColumn
                  key={headerGroup.id}
                  style={
                    headerGroup.headers?.find((d: any) => d.id?.includes('group_inv_')) !== undefined
                      ? {
                          display: 'none',
                        }
                      : {}
                  }
                >
                  {headerGroup.headers.map((header: any, index: number) => {
                    let headerType: string = header.column.parent?.id?.split('_')?.at(-1) ?? 'undifined';

                    const colorShift: { [key: string]: string } = {
                      dimension: theme.colors.RC10_2,
                      metrics: theme.colors.RC03_1,
                      undifined: 'none',
                    };

                    return (
                      <S.Cth
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ backgroundColor: colorShift[headerType] }}
                      >
                        {header.isPlaceholder ? null : (
                          <S.SortContainer
                            justify="start"
                            {...{
                              className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getCanSort() && (
                              <S.SortBox>
                                {{
                                  asc: <IconSortAsc width={10} height={10} />,
                                  desc: <IconSortDesc width={10} height={10} />,
                                }[header.column.getIsSorted() as string] ?? (
                                  <IconSortDefault fill={theme.colors.RG04} width={10} height={10} />
                                )}
                              </S.SortBox>
                            )}
                          </S.SortContainer>
                        )}
                      </S.Cth>
                    );
                  })}
                </S.CtrColumn>
              );
            })}
          </thead>

          {isDataFetching === false && (
            <DragDropContext onDragStart={() => {}} onDragEnd={() => {}}>
              <Droppable droppableId="table-body" isDropDisabled>
                {(provided, snapshot) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {paddingTop > 0 && (
                      <S.CtrRow>
                        <S.Ctd style={{ height: `${paddingTop}px` }} />
                      </S.CtrRow>
                    )}
                    {virtualRows.map(virtualRow => {
                      if (draggableRow)
                        return (
                          <DraggableRow
                            key={table.getRowModel().rows[virtualRow.index].id}
                            id={`${table.getRowModel().rows[virtualRow.index].original?.orderId || '-1'}`}
                          >
                            {table
                              .getRowModel()
                              .rows[virtualRow.index].getVisibleCells()
                              .map((cell: any) => {
                                if (cell === undefined) {
                                  console.error(cell, 'cell throw error');
                                }

                                return (
                                  <S.Ctd key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </S.Ctd>
                                );
                              })}
                          </DraggableRow>
                        );

                      return (
                        <Draggable
                          draggableId={table.getRowModel().rows[virtualRow.index].id}
                          key={table.getRowModel().rows[virtualRow.index].id}
                          index={parseInt(table.getRowModel().rows[virtualRow.index].id)}
                        >
                          {(provided, snapshot) => {
                            return (
                              <S.CtrRow
                                ref={provided.innerRef}
                                isDragging={snapshot.isDragging}
                                key={table.getRowModel().rows[virtualRow.index].id}
                                style={onRowClick ? { cursor: 'pointer' } : {}}
                                onClick={(e: MouseEvent<HTMLElement>) => {
                                  onRowClick && onRowClick(e, table.getRowModel().rows[virtualRow.index]);
                                }}
                                hoverSelect={hoverSelect && table.getRowModel().rows[virtualRow.index].getIsSelected()}
                                isSelectedRow={table.getRowModel().rows[virtualRow.index].getIsSelected()}
                                {...provided.draggableProps}
                              >
                                {sortDrag && (
                                  <S.Ctd key={'drag-handle'} {...provided.dragHandleProps}>
                                    <IconCellDragHandle />
                                  </S.Ctd>
                                )}
                                {table
                                  .getRowModel()
                                  .rows[virtualRow.index].getVisibleCells()
                                  .map((cell: any) => {
                                    if (cell === undefined) {
                                      console.error(cell, 'cell throw error');
                                    }

                                    return (
                                      <S.Ctd key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </S.Ctd>
                                    );
                                  })}
                              </S.CtrRow>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    {paddingBottom > 0 && (
                      <S.CtrRow>
                        <S.Ctd style={{ height: `${paddingBottom}px` }} />
                      </S.CtrRow>
                    )}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </S.CTable>
        {isDataFetching && (
          <S.TableStateBody align="center" height={tableHeight}>
            <Text styleName="title2" color="RG05">
              테이블 정보를 불러오고 있습니다...
            </Text>
          </S.TableStateBody>
        )}
        {!isDataFetching && table.getCoreRowModel().rows.length === 0 && (
          <S.TableStateBody align="center" height={tableHeight}>
            {placeholder ? (
              typeof placeholder === 'string' ? (
                <Text styleName="title2" color="RG05">
                  {placeholder}
                </Text>
              ) : (
                placeholder
              )
            ) : (
              <Text styleName="title2" color="RG05">
                {emptyDataMessage ? emptyDataMessage : '불러올 데이터가 없습니다'}
              </Text>
            )}
          </S.TableStateBody>
        )}
      </S.TableScrollable>

      <Stack direction="row" spacing={20} sx={{ margin: '16px 0 0 0' }}>
        <Stack direction="row" spacing={30} sx={{ flexBasis: 'content' }} name="pagination-area">
          {!disableOptions.pageSizeSelector && !clearBottom && (
            <Selector
              anchor="top"
              defaultValue={pagination.pageSize}
              options={paginationSelectorOptions}
              onOptionsClick={item =>
                setPagination(prev => {
                  return { ...prev, pageIndex: 0, pageSize: item.value };
                })
              }
              icon={<IcArrowDF fill={theme.colors.RG04} />}
            />
          )}
          {!disableOptions.pagnation && (
            <Pagination
              pageCount={table.getPageCount()}
              current={table.getState().pagination.pageIndex}
              {...{ setPagination }}
            />
          )}
        </Stack>
      </Stack>

      {dndTooltip && dndTooltip?.isOpen && rows.length > 0 && (
        <SpeechBubble
          color="RC02"
          bOffset={22}
          isOpen={dndTooltip.isOpen}
          setIsOpen={dndTooltip.setIsOpen}
          sx={{ top: `${dndTooltip.height + (virtualRows.length > 2 ? 80 : 16)}px` }}
        >
          <Text styleName="caption1" color="RG00">
            <pre>{dndTooltip?.content}</pre>
          </Text>
        </SpeechBubble>
      )}
    </S.TableWrapper>
  );
};
