import styled from 'styled-components';

export const SignUpLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SignUpCardLayout = styled.div`
  width: 630px;
  margin-top: 48px;
  margin-bottom: 126px;
`;

export const SignUpTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const SignUpBody = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: left;
`;

export const SignUpHeaderButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SignUpBottom = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 41px;
`;

export const InputLable = styled.div`
  display: flex;
`;

export const InputSection = styled.div`
  display: flex;
  margin-bottom: auto;
  width: 100%;
  margin-top: 8px;
  align-items: start;
`;

export const TermsOfServiceList = styled.ul`
  list-style: none;
  margin-top: 40px;
`;

export const TermsOfServiceListError = styled.div<{ isTermsDetailOpen: boolean }>`
  display: flex;
  justfy-content: start;
  margin-top: ${({ isTermsDetailOpen }) => (isTermsDetailOpen ? '40px;' : '6px')};
`;

export const PrivateEmailValidationModalLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;
`;

export const PrivateEmailValidationModalCloseBtn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: end;
  padding-top: 26px;
  padding-right: 30px;
`;

export const PrivateEmailValidationModalLeftTime = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const PrivateEmailValidationModalReissueCode = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const PrivateEmailValidationModalBottomCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  background: ${({ theme }) => theme.colors.RG08};
  margin-top: 45px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const PrivateEmailValidationModalBottomTextBox = styled.div`
  margin: 10px 10px;
`;

export const VarificationCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const VarificationCodeBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
`;

export const VarificationCodeDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 10px;
  font-weight: 700;
  font-size: 24px;

  /* &:hover {
    border: 1px solid ${({ theme }) => theme.colors.RC02};
  } */
`;

export const VarificationCodeInput = styled.input`
  position: absolute;
  width: 443px;
  height: 62px;
  background: none;
  letter-spacing: 50px;
  font-size: 28px;
  color: rgba(0, 0, 0, 0);
  caret-color: white;
`;

export const CorperateTyoeButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const businessNumberInput = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const WelcomePageLayout = styled.div`
  display: flex;
  width: 100%;
`;

export const WelcomePageSideImg = styled.div`
  display: flex;
  justify-content: end;
`;

export const WelcomePageBottomTextBox = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BusinessNumberModalLayout = styled.div`
  width: 100%;
  padding: 44px 24px 24px 24px;
`;

export const BusinessNumberModalButton = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
`;

export const TableCell = styled.th`
  display: flex;
  height: 54px;
  align-items: center;
`;

export const TableRow = styled.td`
  display: flex;
  height: 54px;
  align-items: center;
`;

export const BusinessNumberModalTable = styled.div`
  width: 100%;
  margin-top: 20px;
  max-height: 216px;
  margin-left: auto;
  margin-right: auto;
  max-width: 456px;

  ${({ theme }) => theme.scrollStyle.vertical};
`;

export const SignUpHeader = styled.div<{ background: string }>`
  width: 305px;
  height: 40px;
  display: flex;
  background: ${({ theme, background }) => theme.colors[background]};
  justify-content: start;
  border-radius: 8px;
  align-items: center;
`;

export const TermsOfServicesDetailModalCancelIcon = styled.div`
  align-self: end;
  margin-right: 20px;
  margin-top: 20px;
`;
