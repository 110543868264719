import styled, { css } from 'styled-components';
import { TColor, TStyleName } from 'constants/types';

import { Stack } from 'components/Stack/style';

import HeroImg from 'constants/graphics/roouty_landing_hero.png';

export const Container = styled.div`
  width: calc(100% + 18px);
  height: 100%;

  margin-right: -18px;
  ${({ theme }) => theme.scrollStyle.horizontal}

  & > section {
    padding: 0 20px;
  }
`;

export const SectionBassStyle = css`
  width: 100%;

  .plan-card-wrapper {
    flex-wrap: wrap;
  }
`;

export const HeroContainer = styled.section`
  ${SectionBassStyle}

  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.RC02};
`;

export const HeroWrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: 1280px;
  height: 1080px;
  /* height: 100%; */

  overflow: hidden;
  background-image: url(${HeroImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 700px) {
    background-image: none;

    height: 980px;
  }

  & > .mobile_hero_img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 544px;
    aspect-ratio: 1;
    background-size: auto;
  }
`;

export const HeroContent = styled(Stack)`
  position: absolute;
  bottom: 474px;

  z-index: 3;

  padding: 0 20px;

  @media only screen and (max-width: 700px) {
    top: calc(72px + 40px);
  }
`;

export const DetailContainer1 = styled.section`
  ${SectionBassStyle}

  scroll-margin-top: 60px !important;

  padding-top: 60px !important;
  padding-bottom: 60px !important;
`;
export const DetailContainer2 = styled.section`
  ${SectionBassStyle}
  scroll-margin-top: 70px !important;

  padding-top: 70px !important;
  padding-bottom: 100px !important;

  max-width: 1320px;
  margin: 0 auto;
`;

export const CTAContainer = styled.section`
  ${SectionBassStyle}

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 111px 20px 124px !important;
  max-height: max-content;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RC03_1,
  })}

  @media screen and (max-width: 700px) {
    padding: 60px 20px !important;
  }
`;

export const PlanCard = styled.div<{ color: string; width?: string; height: string; emphasis?: boolean }>`
  width: 413px;
  max-width: 413px;
  min-width: 315px;
  border-radius: 8px 8px 0 0;
  ${({ width }) => width && { width: width, 'max-width': width, 'min-width': width }}
  ${({ height }) => ({ height: height })} 

  position: relative;
  & > .linear {
    position: absolute;
    width: 100%;
    height: 15px;

    border-radius: 8px 8px 0 0;
    background-color: ${({ theme, color }) => color && (theme?.colors[color] ?? color)};
  }
  & > .content {
    width: 100%;
    height: 100%;
    padding: 30px 24px;
    border-radius: 8px;
    ${({ emphasis, theme }) => ({
      border: `1px solid ${theme.colors.RG06}`,
      borderTop: 'none',

      backgroundColor: theme.colors[emphasis ? 'RC03_1' : 'RG00'],
    })}
  }
`;

export const PlanCardTag = styled.div<{ styleName: TStyleName; color: string; bgColor: string }>`
  padding: 3px 6px;
  border-radius: 8px;

  color: ${({ theme, color }) => theme.colors[color] ?? color};
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor] ?? bgColor};
  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]};
`;

export const CellTable = styled.table<{ pop?: boolean }>`
  width: 175px;
  flex: 1;
  ${({ theme }) => ({
    borderTop: `1px solid ${theme.colors['RG05']}`,
    borderBottom: `1px solid ${theme.colors['RG05']}`,

    '& > tr> td': {
      borderTop: `1px solid ${theme.colors['RG05']}`,
      borderBottom: `1px solid ${theme.colors['RG05']}`,
    },
  })};
  & > tr > td,
  th {
    text-align: start;
    border-collapse: collapse;
  }
  & > :first-child > th {
    padding: 6px 4px;
    background-color: ${({ theme }) => theme.colors['RG07']};
    ${({ theme }) => theme.fontStyle['body1']}
  }
  & > :not(:first-child) > td {
    ${({ theme, pop }) =>
      pop
        ? {
            height: '132px',
          }
        : {
            padding: '4px',
            backgroundColor: theme.colors['RG00'],
          }}
  }
  ${({ theme }) => theme.fontStyle['body2']}
`;
export const Button = styled.button<{
  width?: number;
  fullWidth?: boolean;
  styleName?: TStyleName;

  bgColor: TColor | string;
  color?: TColor | string;
  borderColor?: TColor | string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;

  ${({ theme, width, fullWidth }) => ({
    width: fullWidth ? '100%' : width ? `${width}px` : 'auto',
    height: '44px',
  })}

  color: ${({ theme, color }) => theme.colors[color ? color : 'RG00']};
  background-color: ${({ theme, bgColor }) => bgColor && (theme?.colors[bgColor] ?? bgColor)};
  border: ${({ theme, borderColor }) => borderColor && `1px solid ${theme?.colors[borderColor] ?? borderColor}`};

  cursor: pointer;
  ${({ styleName, theme }) => styleName && theme.fontStyle[styleName]};
`;
