import { AuthLayout } from 'auth/AuthLayout';
import { Input } from 'components/Input';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';

import { useForm } from 'react-hook-form';
import { ChangeEvent, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { sendVerifyLoginAccount } from 'auth/resetPassword/container';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { useStore } from 'store';

import { ReactComponent as Lock } from 'constants/icon/ic_lock.svg';
import { ReactComponent as Email } from 'constants/icon/ic_email.svg';
import { validateLoginAccount } from 'util/validate/validateLoginAccount';
import { isTruthy } from 'util/isTruthy';

import * as CS from 'auth/style';
import * as S from 'auth/resetPassword/style';
import { Stack } from 'components/Stack';
import { getValidateLoginAccount } from 'auth/signUp/api';

// 비밀번호 재설정을 모달 안에서 사용하는 경우, 필요한 액션
interface IValidateEmailModalConfig {
  successCallback?: Function;
  closeContent?: Function;
  isUsedByModal?: boolean;
  preInputLoginAccount?: string;
}

export const ValidateEmail = (props: IValidateEmailModalConfig) => {
  const { successCallback, closeContent, isUsedByModal = false } = props;
  const navigator = useNavigate();
  const { isLoading, setIsLoading } = useStore();
  const {
    register,
    setError,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<TValidateEmailType>({ mode: 'onChange' });
  const [isValidAccount, setIsValidAccount] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<boolean>(false);

  useEffect(() => {
    if (isValidAccount) {
      successCallback && successCallback();
    }
  }, [isValidAccount]);

  useEffect(() => {
    if (props.preInputLoginAccount) {
      setValue('loginAccount', props.preInputLoginAccount);
      setIsLoading(true);
      sendVerifyLoginAccount(setError, getValues, setIsValidAccount, setIsLoading, {
        1129: () => {
          closeContent && closeContent();
        },
      });
    }
  }, []);

  return (
    <AuthLayout cardWidth={props.preInputLoginAccount && isLoading ? 1 : 0}>
      <LoadingModal isLoading={isLoading} text={['인증 메일을 전송 중입니다. 잠시만 기다려 주세요.']} />
      {props.preInputLoginAccount && isLoading ? (
        <></>
      ) : (
        <CS.SignInResetPasswordLayout>
          {isValidAccount ? (
            <S.ResetPasswordFormLayout>
              <Email />
              <Text styleName="headline1" color="RG02" sx={{ marginTop: '24px' }}>
                비밀번호 재설정을 위한 메일을
              </Text>
              <Text styleName="headline1" color="RG02">
                발송 완료하였습니다.
              </Text>
              <Text styleName="body2" color="RG03" sx={{ marginTop: '24px' }}>
                이메일을 확인하시고 비밀번호 재설정을 진행해 주세요.
              </Text>
            </S.ResetPasswordFormLayout>
          ) : (
            <Fragment>
              <S.ResetPasswordTitle>
                <Lock />
                <Text styleName="headline1" color="RG02" sx={{ marginTop: '24px' }}>
                  비밀번호 재설정
                </Text>

                <Text styleName="body2" color="RG03" sx={{ marginTop: '24px' }}>
                  가입하신 이메일 계정을 입력해주세요.
                </Text>
                <Text styleName="body2" color="RG03" sx={{ marginTop: '6px' }}>
                  비밀번호 재설정을 위한 인증메일을 보내드립니다.
                </Text>
              </S.ResetPasswordTitle>
              <CS.InputLabel>
                <Text styleName="subheadline2" color="RG02">
                  아이디 (이메일)
                </Text>
                <Input
                  sx={{ marginTop: '4px' }}
                  name="loginAccount"
                  placeholder="사용하고 있는 이메일 주소를 입력해주세요."
                  type="loginAccount"
                  variant="fourth"
                  register={register}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue('loginAccount', event.target.value);
                    const { loginAccount } = getValues();

                    if (validateLoginAccount(loginAccount) === false) {
                      setError('loginAccount', {
                        message: '이메일 형식에 맞지 않습니다.',
                      });
                      return setActiveButton(false);
                    }
                    if (loginAccount) {
                      getValidateLoginAccount({ loginAccount }).then(response => {
                        const _response = response as { hasAccount: boolean };

                        if (!_response.hasAccount) {
                          setActiveButton(false);
                          return setError('loginAccount', { message: '존재하지 않는 아이디입니다.' });
                        }
                        setActiveButton(true);
                        clearErrors('loginAccount');
                      });
                    }

                    setActiveButton(true);
                    clearErrors('loginAccount');
                  }}
                  onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      setIsLoading(true);
                      sendVerifyLoginAccount(setError, getValues, setIsValidAccount, setIsLoading);
                    }
                  }}
                  hasError={errors.loginAccount}
                  errors={errors.loginAccount}
                />
              </CS.InputLabel>

              <Stack sx={{ marginTop: errors.loginAccount ? '14px' : '40px', gap: '10px' }}>
                <Button
                  styleName="body2"
                  color="RG00"
                  variant="default"
                  type="button"
                  height={48}
                  fullWidth
                  disabled={activeButton === false || isTruthy(errors.loginAccount)}
                  onClick={() => {
                    setIsLoading(true);
                    sendVerifyLoginAccount(setError, getValues, setIsValidAccount, setIsLoading, {
                      1129: () => {
                        closeContent && closeContent();
                      },
                    });
                  }}
                >
                  인증메일 발송
                </Button>
                <Button
                  styleName="body2"
                  color="RG00"
                  variant="fourth"
                  type="button"
                  height={48}
                  fullWidth
                  onClick={() => {
                    closeContent && closeContent();
                    if (isUsedByModal === false) {
                      navigator('/signin');
                    }
                  }}
                >
                  취소
                </Button>
              </Stack>
            </Fragment>
          )}
        </CS.SignInResetPasswordLayout>
      )}
    </AuthLayout>
  );
};
