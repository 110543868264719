import styled, { css } from 'styled-components';
import { Stack } from '../Stack';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 26px;
  height: 72px;
  background: ${({ theme }) => theme.colors.RG00};
  z-index: 25;

  border-bottom: ${({ theme }) => `1px solid ${theme.colors.RG06}`};

  .navDefaultStyle,
  .navActiveStyle {
    padding: 4px 10px;
    border-radius: 6px;
    min-width: fit-content;
    color: ${({ theme }) => theme.colors.RG03};

    ${({ theme }) => theme.fontStyle.subheadline1}
  }

  .navActiveStyle {
    color: ${({ theme }) => theme.colors.RC02} !important;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.RG04};
    cursor: not-allowed;
  }
`;

export const LogoArea = styled.div`
  width: 100%;
  min-width: 214px;
  max-width: 320px;
  display: flex;
  flex: 3;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    min-width: fit-content;
  }
`;

export const NavArea = styled(Stack)`
  width: 100%;
  min-width: fit-content;
  flex: 10;

  justify-content: space-between;
`;

const IconBaseStyle = css`
  width: 20px;
  height: 20px;

  fill: ${({ theme }) => theme.colors.RG04};

  :hover {
    fill: ${({ theme }) => theme.colors.RC03};
  }

  transition: all 0.2s;

  flex-shrink: 0;
`;
export const IconArea = styled(Stack)`
  .isOnActive {
    ${IconBaseStyle};
    fill: ${({ theme }) => theme.colors.RC03};
  }
  .isInActive {
    ${IconBaseStyle};
  }
`;

export const IconProfileWrapper = styled(Stack)<{ isActive?: boolean }>`
  width: max-content;

  padding: 6px 8px;
  border-radius: 6px;

  gap: 8px;
  flex-direction: row;

  cursor: pointer;

  ${({ theme, isActive }) => ({
    backgroundColor: isActive ? theme.colors.RC03_1 : 'transparent',
  })}
  ${({ theme }) => theme.fontStyle['caption2']};

  & > svg {
    width: 20px;
    height: 20px;

    ${({ theme, isActive }) => ({
      fill: theme.colors[isActive ? 'RC03' : 'RG04'],
    })}
  }
`;

// export const ProfileArea = styled(Stack)<{ isActive: boolean }>`
//   width: fit-content;
//   height: 36px;
//   background: ${({ theme }) => theme.colors.RC03_1};
//   padding: 0 8px;
//   opacity: 0.77;
//   border-radius: 6px;
//   cursor: pointer;
//   transition: all 0.2s;

//   ${({ isActive, theme }) => isActive && { opacity: 1, background: theme.colors.RC03_3 }}
//   &:hover {
//     opacity: 1;
//     background: ${({ theme }) => theme.colors.RC03_3};
//   }
//   & > svg {
//     width: 20px;
//     height: 20px;
//   }
// `;

export const ProfileIcon = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const IConAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
`;

export const PageContentWrapper = styled.div`
  padding: 0;
`;
