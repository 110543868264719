import { AlertModal } from '../AlertModal';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Text } from '../../Typography';
import { Stack } from '../../Stack';

export interface WarningModalProps {
  closeTimer?: number;
  title?: ReactNode;
  description: ReactNode | string[];
  buttonName?: string;
  buttonGroup?: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  callback?: any;
}

export const WarningModal = ({
  closeTimer,
  title,
  description,
  buttonName = '확인',
  buttonGroup,
  isOpen,
  setIsOpen,
  callback = () => {},
}: WarningModalProps) => {
  return (
    <AlertModal
      isOpen={isOpen}
      type="WARNING"
      autoCloseTime={closeTimer}
      messages={
        <Stack spacing={8}>
          {title}
          {Array.isArray(description)
            ? description.map(item => (
                <Text key={item} styleName="body2" color="RC02">
                  {item}
                </Text>
              ))
            : description}
        </Stack>
      }
      buttonName={buttonName}
      buttonGroup={buttonGroup}
      callback={() => {
        callback();
        setIsOpen(false);
      }}
    />
  );
};
