import { Suspense } from 'react';

import * as S from './style';
import { Text } from 'components/Typography';
import { ReactComponent as IconXlsx } from 'constants/icon/ic_xlsx.svg';
import Progress from 'components/Progress';

export interface ProgressBarProps {
  //* kinda file name */
  label?: string;
  progress: number;
  //* for progressbar icon */
  type?: 'xlsx';

  spacing?: number;
  isError?: boolean;
}

// const MappedIcon = {
//   xlsx: IconXlsx,
// };

const ProgressBar = ({ label, progress, type, spacing, isError }: ProgressBarProps) => {
  // const MappedIconComponent = MappedIcon[type];
  return (
    <Suspense fallback={<div></div>}>
      <S.ProgressBarContainer>
        {type && <IconXlsx />}
        <S.ProgressContainer {...{ spacing }}>
          {label ? (
            <S.ProgressLabel>
              <Text styleName="caption3" color="RG02" sx={{ textAlign: 'start' }}>
                {label}
              </Text>
              <Text styleName="caption1" color="RG03">
                {progress}%
              </Text>
            </S.ProgressLabel>
          ) : (
            <S.ProgressPlainLabel>
              <Text styleName="caption1" color="RG03">
                {progress}%
              </Text>
            </S.ProgressPlainLabel>
          )}
          <Progress progress={progress} color={[isError ? 'RC04' : 'RC03', 'RG06']} />
        </S.ProgressContainer>
      </S.ProgressBarContainer>
    </Suspense>
  );
};

export default ProgressBar;
