import { baseAPI } from 'api/instance';
import { apiErrorHandler } from 'api';

export const getInvolvedManagerList = async (
  props: Omit<TManagerListQueryParams, 'placeholder'>
): Promise<TGetInvolvedManagerListResponse | TErrorResponse> => {
  try {
    const { keyword } = props;
    let URL = '/member/list/manager';
    const token = window.localStorage.getItem('accessToken') || '';
    const { data } = await baseAPI({ url: URL, method: 'GET', headers: { token }, params: keyword ? props : {} });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const getTeamList = async (): Promise<TGetTeamListResponse | TErrorResponse> => {
  try {
    const URL = '/team/list';
    const token = window.localStorage.getItem('accessToken') || '';
    const { data } = await baseAPI({ url: URL, method: 'GET', headers: { token } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putChangeTeam = async (props: { teamId: number; userList: number[] }) => {
  try {
    const { teamId, userList } = props;
    const URL = `/member/move/manager/${teamId}`;
    const token = window.localStorage.getItem('accessToken') || '';
    return await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { userList: userList } });
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putInvitedManagerChangeTeam = async (props: { teamId: number; invitationList: number[] }) => {
  try {
    const { teamId, invitationList } = props;
    const URL = `/member/move/manager/invited/${teamId}`;
    const token = window.localStorage.getItem('accessToken') || '';
    return await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { invitationList: invitationList } });
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const getInvitedMangerList = async (props: Omit<TManagerListQueryParams, 'placeholder'>) => {
  const { searchItem, keyword } = props;
  try {
    let URL = '/member/list/manager/invited';
    if (props.keyword) {
      URL = URL + `?searchItem=${searchItem}&keyword=${keyword}`;
    }
    const token = window.localStorage.getItem('accessToken') || '';
    const { data } = await baseAPI({ url: URL, method: 'GET', headers: { token } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putDeleteManager = async (props: { userList: number[] }) => {
  try {
    const URL = '/member/delete/manager';
    const token = window.localStorage.getItem('accessToken') || '';
    const { data } = await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { ...props } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const postInviteManager = async (
  props: TInviteManagerQueryParams
): Promise<TInviteManagerResponse | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/invite/manager';

    const { data } = await baseAPI({ url: URL, method: 'POST', headers: { token }, data: { ...props } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putInviteManager = async (props: TInvitationQuery): Promise<boolean | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/resend/manager';

    await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { ...props } });
    return true;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const postInspeetInvitationEmails = async (
  props: Omit<TInviteManagerQueryParams, 'teamId'>
): Promise<TInviteManagerQueryResponse[] | TErrorResponse> => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/validate/manager';
    const {
      data: { data },
    } = await baseAPI({ url: URL, method: 'POST', headers: { token }, data: { ...props } });
    return data;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};

export const putCancelInvitation = async (props: TInvitationQuery) => {
  try {
    const token = window.localStorage.getItem('accessToken') || '';
    const URL = '/member/cancel/manager';
    await baseAPI({ url: URL, method: 'PUT', headers: { token }, data: { ...props } });
    return true;
  } catch (err) {
    const error = apiErrorHandler(err);
    return error;
  }
};
