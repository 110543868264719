import type { ReactNode } from 'react';
import { Card } from 'components/Card';
import { useLocation } from 'react-router-dom';
import * as S from 'auth/style';

interface IAuthProps {
  children: ReactNode;
  cardWidth?: number;
}

export const AuthLayout = (props: IAuthProps) => {
  const token = window.localStorage.getItem('accessToken') || '';
  const { pathname } = useLocation();

  const isSignIn = token && pathname.includes('resetpassword');

  return isSignIn ? (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 72px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        variant="menu"
        width={props.cardWidth || 632}
        padding={0}
        sx={{
          display: 'flex',
          boxShadow: '0 8px 20px rgba(16, 30, 115, 0.1)',
          borderRadius: '16px',
        }}
      >
        <S.CardItems>{props.children}</S.CardItems>
      </Card>
    </div>
  ) : (
    <Card
      variant="menu"
      width={props.cardWidth || 632}
      padding={0}
      sx={{
        display: 'flex',
        boxShadow: '0 8px 20px rgba(16, 30, 115, 0.1)',
        borderRadius: '16px',
        transition: 'none',
      }}
    >
      <S.CardItems>{props.children}</S.CardItems>
    </Card>
  );
};
