import type { Dispatch, SetStateAction } from 'react';
import { getCheckBusinessNumber, getCheckCompananyName, getValidateBusinessNumber } from 'auth/signUp/api';

import type {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

import { isFalsy } from 'util/isFalsy';

export const validateInputBusinessNumber = (
  getValues: UseFormGetValues<TBusinessNumberInput>,
  setIsActive: Dispatch<SetStateAction<boolean>>,
  inputNumbers: string,
  isSignUp: boolean = false
) => {
  const values = Object.values(getValues());
  if (values.some(value => value === null)) return;
  const _businessNumber = values.reduce((pre, cur) => pre! + cur!, '');

  if (isSignUp === false && inputNumbers && _businessNumber === inputNumbers.replaceAll('-', '')) return;

  if (values.every(Boolean) && _businessNumber?.length === 10) {
    setIsActive(true);
    return;
  }
};

export const validateInputPhoneNumber = (
  getValues: UseFormGetValues<TBusinessNumberInput>,
  inputNumbers: string,
  isSignUp: boolean = false
) => {
  const values = Object.values(getValues());
  if (values.some(value => value === null)) return;
  const _businessNumber = values.reduce((pre, cur) => pre! + cur!, '');

  if (isSignUp === false && inputNumbers && _businessNumber === inputNumbers.replaceAll('-', '')) return;
};

export const updateCompananyName = async (params: {
  companyName: string | null;
  setError: UseFormSetError<TSignUpForm>;
  clearErrors: UseFormClearErrors<TSignUpForm>;
}) => {
  const { companyName, setError, clearErrors } = params;

  if (companyName === null) return setError('companyName', { message: '회사 이름은 필수 입력사항 입니다.' });
  if (companyName!.length > 30) return setError('companyName', { message: '회사 이름은 30자 이내로 입력 가능합니다.' });

  const response = await getCheckCompananyName({ name: companyName });
  const error = response as TErrorResponse;
  const _response = response as { hasCompany: boolean };

  if (error.message) return;
  const { hasCompany } = _response;
  if (hasCompany) {
    return setError('companyName', { message: '이미 가입된 회사 이름 입니다.' });
  }
  clearErrors('companyName');
};

export const validateBusinessNumber = async (
  getValues: UseFormGetValues<TBusinessNumberInput>,
  setResponse: Dispatch<SetStateAction<TBusinessNumberStateType>>,
  setBusinessNumberList: Dispatch<SetStateAction<TBusinessNumberList[]>>,
  setIsLoading: (isLoading: boolean) => void,
  setError: UseFormSetError<TSignUpForm>
) => {
  const { first, second, third } = getValues();
  const payload = { businessNumber: `${first}-${second}-${third}` };
  const isExistedCompany = await getValidateBusinessNumber(payload);
  const _isExsitedCompanyError = isExistedCompany as TErrorResponse;
  const _isExsitedCompany = isExistedCompany as { isExisting: boolean };

  if (_isExsitedCompanyError?.message) {
    setIsLoading(false);
    return alert('서버에러 발생 다시 시도해주세요.');
  }

  if (_isExsitedCompany?.isExisting === false) {
    setIsLoading(false);
    return setResponse('error');
  }

  const response = await getCheckBusinessNumber(payload);

  const error = response as TErrorResponse;
  const _response = response as TGetCheckBusinessNumberResponse;

  if (error?.message) {
    setIsLoading(false);
    alert('서버측 에러 발생 다시 시도해주세요.');
  }
  setIsLoading(false);
  const { companyList } = _response;

  if (isFalsy(companyList)) {
    return setResponse('success');
  }
  setIsLoading(false);
  setBusinessNumberList(companyList);
  return setResponse('modal');
};

export const _updateBusinessNumber = (params: {
  businessNumber: string;
  setBusinessNumber: UseFormSetValue<TSignUpForm>;
  successCallback?: Function;
}) => {
  const { businessNumber, setBusinessNumber, successCallback } = params;
  setBusinessNumber('businessNumber', businessNumber);
  successCallback && successCallback();
};

export const updateCorperateInfo = (params: {
  _dispatch: Dispatch<TSignUpAction>;
  getValues: UseFormGetValues<TSignUpForm>;
}) => {
  const { getValues, _dispatch } = params;
  const { companyName, businessNumber, industryType } = getValues();

  _dispatch({ type: 'UPDATE_SIGN_UP_FORM', payload: [{ companyName }, { businessNumber }, { industryType }] });
};

export const _updatePhoneNumber = (params: {
  phone: string;
  setPhoneNumber: UseFormSetValue<TPrivateInfoType>;
  successCallback?: Function;
}) => {
  const { phone, setPhoneNumber, successCallback } = params;
  setPhoneNumber('phone', phone);
  successCallback && successCallback();
};

export const isFilledCorporateForm = (
  getValues: UseFormGetValues<TSignUpForm>,
  errors: FieldErrors<TSignUpForm>,
  setIsDisabled: Dispatch<SetStateAction<boolean>>
) => {
  const { companyName, industryType } = getValues();

  const isFilled = [companyName, industryType].some(value => isFalsy(value));
  const hasError = ['companyName', 'businessNumber', 'industryType'].some(key => {
    if (errors[key]?.message) {
      return true;
    }
    return false;
  });

  setIsDisabled(isFilled || hasError);
};

export const _resetBusinessNumberInput = (params: {
  setResponse: Dispatch<SetStateAction<TBusinessNumberStateType>>;
  setIsActiveButton: Dispatch<SetStateAction<boolean>>;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setResponse, setIsActiveButton, setIsDisabled } = params;
  setIsActiveButton(false);
  setIsDisabled(true);
  setResponse('');
};

export const _resetPhoneNumberInput = (params: { setResponse: Dispatch<SetStateAction<TPhoneNumberStateType>> }) => {
  const { setResponse } = params;
  setResponse('');
};
