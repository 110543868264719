import styled from 'styled-components';

export const BasicTable = styled.div`
  width: 100%;
  margin-top: 20px;
  max-height: 216px;
  margin-left: auto;
  margin-right: auto;
`;

export const BasicTableLayout = styled.div`
  width: 100%;
  padding: 44px 20px 0px;
`;

export const BasicTableHeader = styled.thead`
  background: ${({ theme }) => theme.colors.RG08};
  text-align: left;
  height: 54px;
  display: flex;
  align-items: center;
  width: inherit;
  border-top: 1px solid;
`;

export const BasicTableBody = styled.tbody`
  text-align: left;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  max-width: 500px;
`;

export const BasicFooter = styled.footer`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 20px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  justify-content: end;
`;

export const ManagerSelectorItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 6px;
  align-items: center;
  padding-top: 6px;
`;

export const BasicTableHeaderCell = styled.th<{ itemWidth?: string }>`
  width: ${({ itemWidth }) => (itemWidth ? itemWidth : 'auto')};
  padding-left: 10px;
`;

export const BasicTableRow = styled.tr`
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};
  border-bottom-style: solid;
  border-left-style: none;
  border-right-style: none;
  width: 500px;
  height: 54px;
  display: flex;
  align-items: center;
`;

export const BasicTableRowCell = styled.td<{ itemWidth?: string }>`
  width: ${({ itemWidth }) => (itemWidth ? itemWidth : 'auto')};
  padding-left: 10px;
`;
