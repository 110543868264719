import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Theme from '../../constants/theme';

const Section = styled.section`
  width: 100vw;
  height: 100vh;
  background-color: ${() => Theme.colors.RG01};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const CoachMarksImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  object-fit: contain;
`;

interface CoachMarksModalProps {
  image: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CoachMarksModal = ({ image, isOpen, setIsOpen }: CoachMarksModalProps) => {
  if (!image) {
    return <></>;
  }

  return isOpen ? (
    // <S.Modal
    //   zIndex={9999}
    //   onClick={() => {
    //     setIsOpen(false);
    //   }}
    // >
    // </S.Modal>
    <Section
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <CoachMarksImage src={image} alt="coach marks" />
    </Section>
  ) : (
    <></>
  );
};

export { CoachMarksModal };
export type { CoachMarksModalProps };
