import styled from 'styled-components';

export const ComplexDoubleviewDateselectorContainer = styled.div<{
  top?: string;
  fullWidth?: boolean;
  template?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: ${({ fullWidth, template }) => (fullWidth ? '100%' : template ? '584px' : '469px')};

  position: absolute;
  left: 0;
  top: ${({ top }) => (top ? top : '40px')};
  z-index: 3;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.RG00};
  box-shadow: ${({ theme }) => theme.shadows.strong};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
`;

export const Summary = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 251px;
  height: 32px;
  padding: 6px 18px;

  border-radius: 6px;
  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, hasError }) => (hasError ? theme.colors.RC04_1 : theme.colors.RC03_1)};

  :focus-within {
    ${({ theme, hasError }) => ({
      border: `1px solid ${hasError ? theme.colors.RC04 : theme.colors.RC03}`,
    })}
  }
  & > input {
    width: 67px;

    background: none;
    text-align: center;
    ${({ theme }) => theme.fontStyle.caption2}
  }
`;
