import { useForm } from 'react-hook-form';
import { Fragment, useState, KeyboardEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputPassword } from 'auth/common';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { sendNewAccount, checkUrlKey } from 'auth/resetPassword/container';

import { InvalidEmailErrorModal } from 'auth/resetPassword/InvalidEmailErrorModal';
import { useStore } from 'store';

import { replacePathname } from 'util/replacePathname';
import { validatePassword } from 'auth/util';
import { AuthLayout } from 'auth/AuthLayout';
import { Text } from 'components/Typography';
import { isTruthy } from 'util/isTruthy';

import { Button } from 'components/Button/legacy_index';
import * as S from 'auth/resetPassword/style';
import * as CS from 'auth/style';
import { ReactComponent as Lock } from 'constants/icon/ic_lock.svg';
import { ReactComponent as CheckCircle } from 'constants/icon/ic_check_circle.svg';

export const ResetPassword = () => {
  const { isLoading, setIsLoading } = useStore();
  const {
    register,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm<TValidatePasswordType>({ mode: 'onChange' });
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);
  const [isValidVerifycode, setIsValidVerifyCode] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const routeId = useParams().key;

  useEffect(() => {
    Promise.resolve(checkUrlKey(routeId!)).then(response => {
      if (response === false) {
        setIsValidVerifyCode(true);
      }
    });

    return () => {
      setIsActiveButton(false);
    };
  }, []);

  return (
    <AuthLayout>
      <InvalidEmailErrorModal
        title="인증 링크가 만료되었습니다."
        messages={[
          '새로운 인증 링크를 받으려면 비밀번호 찾기를 다시 시도해 주세요.',
          '또는 새로운 인증 메일을 확인해 주세요.',
        ]}
        isOpen={isValidVerifycode}
        navigate={navigate}
      />

      <LoadingModal isLoading={isLoading} />
      <CS.SignInResetPasswordLayout>
        <S.ResetPasswordFormLayout>
          {isApplied ? (
            <Fragment>
              <CheckCircle />
              <Text styleName="headline1" color="RG02" sx={{ marginTop: '29px' }}>
                비밀번호 재설정을 완료하였습니다!
              </Text>

              <Text styleName="body2" color="RG03" sx={{ marginTop: '10px' }}>
                새로 설정한 비밀번호로 로그인 후 이용해 주세요.
              </Text>
              <Button
                styleName="body1"
                variant="MRTB"
                type="button"
                height={48}
                fullWidth
                sx={{ marginTop: '40px', backgroundColor: '#2E66F6' }}
                onClick={() => {
                  localStorage.clear();
                  navigate(replacePathname(pathname, '/signin', pathname));
                }}
              >
                로그인하기
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <S.ResetPasswordTitle>
                <Lock />
                <Text styleName="headline1" color="RG02" sx={{ marginTop: '24px' }}>
                  비밀번호 재설정
                </Text>
                <Text styleName="body2" color="RG02" sx={{ marginTop: '24px', marginBottom: '16px' }}>
                  새로운 비밀번호를 설정해 주세요.
                </Text>
              </S.ResetPasswordTitle>

              <InputPassword
                register={register}
                setValue={setValue}
                getValues={getValues}
                useValidation={true}
                disableAutoFill={true}
                label="새로운 비밀번호"
                placeholder="영문, 숫자 또는 특수문자 중 2개 이상 포함 8자 이상"
                sx={{ marginTop: '8px' }}
                password={{ message: errors.password }}
                clearErrors={clearErrors}
                setError={setError}
                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
                  const { password } = getValues();

                  if (validatePassword(password) === false) {
                    if (event.key === 'Enter') {
                      return setError('password', {
                        message:
                          '비밀번호는 총 8자 이상 64자 이하,  영문 / 숫자 / 특수문자(공백 포함) 중 2개 이상을 포함하여야 합니다. ',
                      });
                    }
                    return setIsActiveButton(false);
                  }

                  if (event.key === 'Enter') {
                    setIsLoading(true);
                    sendNewAccount(routeId!, setError, getValues, setIsApplied, setIsLoading);
                  }
                  setIsActiveButton(true);
                  clearErrors('password');
                }}
              />
              <div style={{ marginTop: '40px', width: '100%' }}>
                <Button
                  styleName="body2"
                  color="RG00"
                  variant="default"
                  type="button"
                  height={48}
                  fullWidth
                  disabled={isActiveButton === false || isTruthy(errors.password)}
                  onClick={() => {
                    setIsLoading(true);
                    sendNewAccount(routeId!, setError, getValues, setIsApplied, setIsLoading);
                  }}
                >
                  확인
                </Button>
              </div>
            </Fragment>
          )}
        </S.ResetPasswordFormLayout>
      </CS.SignInResetPasswordLayout>
    </AuthLayout>
  );
};
