import { QueryStatusModalProps } from 'components/Modal/QueryStatusModal';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import type { CSSProperties, ReactNode } from 'react';
import { IMultiSkillData } from 'types/masterData/multiSkill';
import { PaymentTypeUnion, PlanStatusUnion } from 'types/payment/union';

export interface ISelectorRequires<T> {
  key: string;
  value: T;
}

// STORE STATE SETTER
export type TS3<T> = (state: T | ((state?: T) => T)) => void;
export type TQueryStatusModalSetterProps = {
  open: boolean;
  props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>;
};

export type TTableSelectManagerOptions = Array<{
  key: string;
  value: string;
  optionValue: boolean;
}>;

export type TOperationType = 'regular' | 'backup';
export type TShipmentType = 'delivery' | 'pickup' | 'rotation';
export type TOrderStatus =
  | 'unassigned'
  | 'scheduled'
  | 'postponed'
  | 'processing'
  | 'arrived'
  | 'completed'
  | 'skipped'
  | 'canceled'
  | 'deleted';
export type TAccountStatus = 'invited' | 'accepted' | 'activated' | 'deactivated';
export type TControlDriverStatus = 'processing' | 'arrived' | 'completed' | 'end' | 'skipped' | 'postponed';
export type TRouteStatus = 'activated' | 'completed' | 'processing';
export type TOrderPriority = 'high' | 'medium' | 'low';
export type TChangeableOrderStatus = 'skip' | 'unassign' | 'delete' | 'clear';
export type TDistAreaType = 'none' | 'dedicated' | 'flexible';
export type TEqualizeBy = 'worktime' | 'workload';
export type TTimelineVariants =
  | 'leftPadding'
  | 'start'
  | 'invisible'
  | 'spacer'
  | 'order'
  | 'mibaecha'
  | 'jumsim'
  | 'end'
  | 'rightPadding';
export type TActionKeys = 'free-trial' | 'contact';

export type TColor = TThemeColor | string;
export type TThemeColor =
  | 'RC01'
  | 'RC02'
  | 'RC03'
  | 'RC03_1'
  | 'RC03_2'
  | 'RC03_3'
  | 'RC03_4'
  | 'RC04'
  | 'RC04_1'
  | 'RC04_2'
  | 'RC04_3'
  | 'RC05'
  | 'RC06'
  | 'RC07'
  | 'RC08'
  | 'RC09'
  | 'RC09_1'
  | 'RC09_2'
  | 'RC10'
  | 'RC10_1'
  | 'RC10_2'
  | 'RC10_3'
  | 'RC11'
  | 'RC11_1'
  | 'RC12'
  | 'RC12_1'
  | 'RC13'
  | 'RC14'
  | 'RG00'
  | 'RG01'
  | 'RG01_1'
  | 'RG02'
  | 'RG02_1'
  | 'RG03'
  | 'RG04'
  | 'RG05'
  | 'RG05_1'
  | 'RG06'
  | 'RG07'
  | 'RG08'
  | 'RG09';

export type TStyleName =
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'subheadline1'
  | 'subheadline2'
  | 'subheadline3'
  | 'caption1'
  | 'caption2'
  | 'caption3'
  | 'tooltip1'
  | 'tooltip2'
  | 'tooltip3'
  | 'number1'
  | 'number2'
  | 'number3'
  | 'number4'
  | 'number5'
  | 'landing1'
  | 'landing2'
  | 'landing3'
  | 'landing4'
  | 'landing5';

export type TPeriodContent = {
  key: string;
  value: string;
  keyDate: string;
  setter: () => void;
  periodDate: string;
};
export type ITeamStatus = 'activated' | 'deleted';
export type IPaymentStatus = 'activated';
export type IDomainTypes = 'roouty' | 'survey';
export type TAreaFormActions = 'add' | 'detail' | 'modi';

export type PricingTier = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const UnBatchedDriverId = -1;
export const DriverIdUnSelected = -3;
export const UnBatchedRouteIndex = -2;

export interface IParams {
  [key: string]: string;
}

export interface ISearch {
  searchItem: string;
  keyword: string;
}

export interface IAuthority {
  type: IDomainTypes;
  pricing: {
    pricingId: number;
    paymentStatus: IPaymentStatus;
  };
}

export interface IGetPaymentInfo {
  pricingId: number;
  status: IPaymentStatus;
  name: string;
  startedAt: string;
  expiredAt: string;
}

export interface ILoginSubmitForm {
  loginAccount: string;
  password: string;
}

export interface ILoginQuery extends ILoginSubmitForm {
  loginType: 'pc' | 'mobile';
}

export interface ILoginResponses {
  refreshToken: string;
  accessToken: string;

  accessTokenExpireAt: string;
  refreshTokenExpireAt: string;
}

export interface ISignupForm {
  email: string; // 생성하려는 owner 계정의 이메일
  companyName: string; // 생성하려는 회사의 이름
}

export interface IGetMyAuthInfoResponses {
  _id: string;
  email: string;
  name: string;
  contact: string;
  createdAt: string;
}

export interface IGetAccessTokenResponses {
  accessToken: string;
}

export interface IAddOrder {
  orderList: [
    {
      clientOrderId: string;
      consignee: {
        name: string;
        address: string;
        detail: string;
        phone: string;
        note: string;
      };
      task: {
        desiredDate: Date;
        desiredHour: {
          before: string;
          after: string;
        };
        serviceTime: number;
        skillId: string;
      };
      product: [
        {
          code: string;
          name: string;
          quantity: number;
          capacity: number;
          packaging: number;
        }
      ];
      option: [string];
      note: [string];
      orderDate: Date;
    }
  ];
}

export interface IUnassignedOrdersList {
  order_id: number;
  received_date: string;
  desired_date?: string;
  desired_time_start?: string;
  desired_time_end?: string;
  consignee_name: string;
  shipment_type: string;
  skill?: string;
  address: string;
  detail_address?: string;
  coordinate: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export interface IGetUnassignedOrdersResponses {
  order_list: IUnassignedOrdersList[];
}

export interface IOrderList {
  _id: string;
  clientOrderId: string;
  consignee: {
    name: string;
    address: string;
    detail: string;
    phone: string;
    note: string;
  };
  location: {
    coordinates: [number, number];
  };
  task: {
    desiredDate: Date;
    desiredHour: {
      before: string;
      after: string;
    };
    serviceTime: number;
    skillId: string;
  };
  product: [
    {
      code: string;
      name: string;
      quantity: number;
      capacity: number;
      packaging: number;
    }
  ];
  orderDate: Date;
  createdAt: Date;
}

export interface IGetMemberList {
  grade: number | string;
}

export interface IDriverListResponse {
  driverId?: number;
  name?: string;
  workStartTime: string;
  hasLunch: boolean;
  vehicle: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    startCoordinate: {
      type: string;
      coordinates: [number, number];
    };
    endCoordinate: {
      type: string;
      coordinates: [number, number];
    };
  };
  orderList: {
    orderId?: number;
    type: string;
    receivedDate?: string;
    address?: string;
    coordinate?: {
      type: string;
      coordinates: [number, number];
    };
    desiredDate?: string;
    desiredTimeStart?: string;
    desiredTimeEnd?: string;
    serviceTime?: number;
    skill?: string;
    route: {
      internal?: {
        routeIndex?: number;
        requiredDistance?: number;
        requiredTime?: number;
        eta?: string;
        polyline?: string;
      };
      external?: {
        routeIndex?: number;
        requiredDistance?: number;
        requiredTime?: number;
        eta?: string;
        polyline?: string;
      };
    };
  }[];
}

export interface ITimelineData {
  columns: {
    [key: string]: {
      id: string;
      vehicle: unknown | null;
      driverId: number | null;
      items: ITimelineBlock[];
      properties: {
        editedItems: string[];
        sortChanged: boolean;
        hiddenEdited: boolean;
      };
    };
  };
  columnOrder: string[];
}

export interface ITimelineBlock {
  id: string;
  text?: string;
  color?: string;
  type: TTimelineVariants;
  width: number;
  originWidth: number;
  fixedSize?: boolean;
  preventDragging?: boolean;
  visible: boolean;
  originData?: IEqualOptimizeResponsesDriversOrderList;
  waitingTime?: number;
  multiplier?: number;
  driverId?: number;
}

export interface IOptimizedOrderList {
  _id: string;
  clientOrderId: string;
  consignee: {
    name: string;
    address: string;
    detail: string;
    phone: string;
    note: string;
  };
  location: {
    coordinates: [number, number];
  };
  task: {
    desiredDate: Date;
    desiredHour: {
      before: string;
      after: string;
    };
    serviceTime: number;
    skillId: string;
  };
  product: [
    {
      code: string;
      name: string;
      capacity: number;
      quantity: number;
      packaging: number;
    }
  ];
  orderDate: Date;
  transport: {
    driverId: string;
    driverName: string;
    routeIndex: number;
    internal: {
      requiredDistance: number;
      requiredTime: number;
      eta: string;
      polyline: string;
    };
    external: {
      requiredDistance: number;
      requiredTime: number;
      eta: string;
      polyline: string;
    };
  };
  type: string;
}

export interface IRunOptimize {
  date: string;
  orderList: any;
  driverList: any;
}

// @4YJ
export interface IRunOptimizeEqualResponses {
  performedDate: string;
  performedTime?: string; // (HH:mm)
  workEndTime?: string; // (HH:mm)
  driverList: IEqualOptimizeResponsesDriver[];
}

export interface IDeleteOrder {
  orderList: string[];
}

export interface IFieldContent {
  //common props
  name: string;
  type: string;
  sx?: CSSProperties;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  validation?: object;
  onClick?: () => void;

  //input props
  height?: number;
  placeholder?: string;
  label?: string | ReactNode;
  rightArea?: {
    rightContent: string | ReactNode;
    rightFunc: () => void;
  };

  //select props
  options?: {
    key: string;
    value: string | boolean | number;
  }[];
}

export interface IField {
  title: string;
  RTB?: string;

  fieldContents: IFieldContent[];

  useFormProps: any;
  onSubmit: any;

  // FIXME : subFunction

  subFunction?: any;

  isModi?: boolean;
  setIsModi?: any;
  complex?: boolean;
}

export interface IGetMyInfoResponses {
  userId: number;

  email?: string; // 유저 이메일
  name: string; // 유저 이름
  phone?: string; // 유저 전화번호

  roleId: 1 | 2 | 3 | 4;

  driverInfo?: {
    workStartTime: string;
    vehicle?: {
      licenseNumber: string;
      startAddress: string;
      endAddress?: string;
    };
  };
}

export interface ISetMyInfo {
  name?: string; // 유저 이름
  phone?: string; // -를 제외한 string 전화번호
}

export interface IDriverUserList {
  driverId: number; // 드라이버 id
  userId: number;
  areas: {
    areaId: string;
  }[];
  name: string; // 유저 이름
  status: string; // 해당 계정의 상태
  phone?: string; // 유저 전화번호
  workStartTime: string;
  vehicle?: {
    vehicleId?: number; // 차량 id
    licenseNumber?: string; // 차량번호
    operationType?: TOperationType;
    maxCapacity?: number;
  };
  invitedAt: Date; // 초대 날짜
  acceptedAt?: Date; //초대 수락 날짜

  hasLunch: boolean; // 휴게시간 설정 여부

  skills: Pick<IMultiSkillData, 'skillId' | 'name'>[];
}

export interface IOptimizeDriverUserList
  extends Pick<IDriverUserList, 'driverId' | 'name' | 'workStartTime' | 'vehicle' | 'areas' | 'skills'> {
  routeStatus: 'scheduled' | 'processing' | 'completed' | 'none';
  routes: [
    {
      routeId: number;
      name: string;
      status: string;
    }
  ];
  completedCount: number;
  scheduledCount: number;
}

export interface IgetDriverInfoResponses<T> {
  memberList: Array<T>;
}

export interface ISetDriverInfo {
  target: number; // 드라이버의 유저 id

  name?: string; // 드라이버 이름
  vehicleId?: number; // 차량 id
  workStartTime?: string; // 근무(작업) 시작 시간
  hasLunch?: boolean; // 휴게시간 설정 여부
}

export interface IDeactivateMember {
  target: number; //비활성화 시킬 멤버의 유저 id
}

export interface IAdminUserList {
  userId: number; // 유저 id
  name: string; // 유저 이름
  email?: string; // 이메일
  phone?: string; // 연락처
  status: string; // 해당 계정의 상태
  invitedAt: Date; // 초대 날짜
  acceptedAt?: Date; // 초대 수락 날짜
}

export interface IGetAdminInfoResponses {
  userList: IAdminUserList[];
}

export interface ISetAdminInfo {
  target: number; // 관리자의 유저 id

  name?: string; // 유저 이름
  phone?: string; // -를 제외한 string 전화번호
}

export interface IGetCompanyInfoResponses {
  companyId: number;
  name: string;
  payment: {
    businessNumber?: string;
    name?: string;
    contact?: string;
    email?: string;
    taxEmail?: string;
  };
  pricing: {
    name: string;
    vehicleCount: number;
    maxVehicleCount: number;
    driverCount: number;
    maxDriverCount: number;
    startedAt: string;
    expiredAt: string;
  };
  setting: {
    workStartTime: string;
    hasLunch: number;
    stepFixed: boolean;
    lunchPossibleStart: string;
    lunchPossibleEnd: string;
    lunchDuration: number;
    unit?: string;
    startAddress?: string;
    endAddress?: string;
  };
}

export interface ISetCompanyInfo {
  name?: string;
  payment?: {
    businessNumber?: string;
    name?: string;
    contact?: string;
    email?: string;
    taxEmail?: string;
  };
  setting?: {
    workStartTime?: string;
    hasLunch?: boolean;
    lunchPossibleStart?: string;
    lunchPossibleEnd?: string;
    lunchDuration?: number;
    unit?: string;
    startAddress?: string;
    endAddress?: string;
  };
}

export interface IGetNoticeTos {
  isVisible: boolean;
}

export interface IGetVehicleModelResponses {
  vehicleModelList: {
    vehicleModelId: number;
    name: string;
  }[];
}

export interface IVehicleList {
  vehicleId: number; // 차량 id
  licenseNumber: string; // 차량번호
  maxCapacity: number;
  model: {
    // 차종정보
    name?: string; // 차종

    // model_id?: number; // 차종 id
    // max_capacity?: number; // 최대 용적
  };
  memo?: string; // 메모
  startAddress: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
  driverNames?: string; // 운전자 이름
  startDetailAddress?: string; // 출발지 상세 주소
  endDetailAddress?: string; // 도착지 상세 주소
  operationType?: string; // 차량 운행 타입
  // 변경된 운전자 조회
  driverVehicles?: {
    driverVehicleId: number;
    driver: {
      name: string;
    };
  }[];
}

export interface IAddVehicle {
  licenseNumber: string; // 차량번호
  vehicleModelId: number; // 차종 ID
  maxCapacity: number; // 최대 용적수량
  memo?: string; // 메모
  startAddress: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
  driverNames?: string; // 운전자 이름
  startDetailAddress?: string; // 출발지 상세 주소
  endDetailAddress?: string; // 도착지 상세 주소
  operationType?: string; // 차량 운행 타입
}

export interface IGetVehicleInfo {
  vehicleList: IVehicleList[];
}

export interface ISetVehicleInfo {
  target: string; // 차량 id

  licenseNumber?: string; // 차량번호
  vehicleModelId?: number;
  maxCapacity?: number; // 최대 용적수량
  memo?: string; // 메모
  startAddress?: string; // 출발지 주소
  endAddress?: string; // 도착지 주소
}

export interface IDeleteVehicleInfo {
  target: string; // 차량 id
}

export interface IDeleteUnassignedOrder {
  orderList: number[]; // 취소하려는 미배차 주문의 order_id
}

// TODO: IGetDownloadOrderList 배차 관제 이후 수정필요
export interface IGetDownloadOrderList {
  order_id?: string; // 주문 id를 ,(콤마) 로 구분하여 전송
  status: string; //all (전체, default 값) | unassigned (배차 대기, 미배차) | scheduled (배차 완료, 배송 대기) | processing (배송 중) | completed (배송 완료) | canceled (취소)
  received_date: string; // 주문 접수일 YYYMMDD-YYYYMMDD
  desired_date?: string; //작업 희망일 YYYYMMDD-YYYYMMDD
  completed_date?: string; // 배송 완료일 YYYYMMDD-YYYYMMDD
  driver_id: string; // 드라이버 id를 ,(콤마) 로 구분하여 전송
  keyword?: string; // 검색할 고객명에 대한 키워드
  sort_by?: string; //
  sort_method?: string; // desc, asc
}

export interface IGetDownloadOrderListResponses {
  orderList: [
    {
      status: string;
      received_date: string;
      consignee_name: string;
      consignee_phone: string;
      consignee_note: string;
      address: string;
      detail_address: string;
      product: [
        {
          code: string;
          name: string;
          capacity: number;
          quantity: number;
        }
      ];
      shipment_type: string;
      desired_date: string;
      desired_time_start: string;
      desired_time_end: string;
      service_time: number;
      skill: string;
      note: string[];
      route: {
        driver_name: string;
        performed_date: string;
        completed_date: string;
        route_index: number;
        eta: string;
        required_time: number;
      };
    }
  ];
}

export interface IGetOrderList extends Partial<ISearch> {
  receivedDate?: string;

  orderStatus?: string;
  routeStatus?: string;
  shipmentType?: string;
  desiredDate?: string;
  completedDate?: string;
  performedDate?: string;
  priority?: string;
}

export interface IOrder {
  orderId: number;
  orderCode: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  serviceVehicle?: string;
  skills?: Pick<IMultiSkillData, 'skillId' | 'name'>[];
  serviceTime: number;
  actualServiceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: [string];
  shipmentType: string;
  status: string;
  completedAt?: string;
  driverName?: string;
  completedComment?: string;
  skippedComment?: string;
  skippedAt?: string;
  skippedName?: string;
  priority?: string;
  route?: {
    routeId: number;
    routeCode: string;
    name: string;
    performedDate: string;
    status: string;
  };
  orderPod?: {
    capacity: number;
    comment: string;
    orderPodFiles: [
      {
        s3url?: string;
        createdAt?: string;
      }
    ];
  } | null;
  product: {
    code: string;
    name: string;
    quantity: number;
  }[];
}

export interface IGetOrderListResponses {
  // totalCount: number;
  // page: number;
  // pagePerCount: number;
  orderList: IOrder[];
}

export interface IGetOrderDetail {
  target: number; // order_id
}

export interface ISkippedHistory {
  driverId: number;
  routeId: number;
  name: string;
  performedDate: string;
  comment: string;
  createdAt: string;
}

export interface IGetOrderDetailResponses {
  orderId: number;
  orderCode: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  serviceVehicle?: string;
  serviceTime?: number;
  actualServiceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note: string[];
  shipmentType?: string;
  status: string;
  completedAt?: string;
  driverId?: string;
  driverName?: string;
  completedComment?: string;
  skippedHistory: Array<ISkippedHistory>;
  route?: {
    orderRouteId: number;
    routeId: number;
    routeCode: string;
    name: string;
    performedDate: string;
    status: TRouteStatus;
  };
  orderPod?: {
    capacity?: number;
    comment?: string;
    orderPodFiles: {
      s3url: string;
      createdAt: string;
    }[];
  };
  product: {
    code?: string;
    name?: string;
    quantity: number;
  }[];
  priority: string;
  skills: Pick<IMultiSkillData, 'skillId' | 'name'>[];
}

export interface ISetOrderDetail {
  target: number; // order_id

  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: string[];
  product: [
    {
      code?: string;
      name?: string;
      quantity?: number;
    }
  ];
}

export interface IAddSingleOrder {
  orderList: IAddSingleOrderAttr[];
}

export interface IAddSingleOrderAttr {
  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: string[];
  product: {
    code?: string;
    name?: string;
    quantity?: number;
  }[];
}

export interface IGetUnassignedOrders {
  received_date: string;
  desired_date?: string;
  shipment_type?: string;
  allow_desired_date_null: boolean;
}

export interface IRouteList {
  routeId: number;
  routeCode: string;
  name: string;
  status: string;
  performedDate: string;
  totalOrderCount: number;
  totalDriverCount: number;
  createdBy: string;
  createdAt: string;
  totalProcessedOrderCount: number;
  totalSkippedOrderCount: number;
}

export interface IGetRouteListResponses {
  routeList: IRouteList[];
}

export interface IEqualOptimize {
  performedDate: string;
  performedTime?: string;
  workEndTime?: string; // (HH:mm)
  orderList: number[];
  driverList: {
    driverId: number;
    vehicleId: number;
  }[];
}

export interface IEqualOptimizeParams {
  distAreaType?: TDistAreaType;
  //   - `none` 혹은 생략 : 기존 무권역 배차
  // - `dedicated` : 고정 권역 배차
  // - `flexible` : 유연 권역 배차
  equalizeBy?: TEqualizeBy;
  //   - `worktime` 혹은 생략 : 기존 시간 균등 배차
  // - `workload` : 업무량 균등 배차
}

export interface IEqualOptimizeResponsesOnStore {
  key: string;
  name: string;
  originName: string;
  data: IEqualOptimizeResponses;

  disable?: boolean;
  genIndex: number;
  routeOptions: IEqualOptimizeParams;
}

export interface ILocationMqOnStore {
  userId: string;
  driverId: string;
  bearing?: number;
  lastLocationDate?: Date;
  locationInfo: {
    os?: string;
    collectedTime?: string;
    userId: string;
    driverId: string;
    location: {
      type: string;
      coordinates: [number, number];
    };
  };
}

// @4YJ
export interface IEqualOptimizeResponsesDriversOrderList {
  orderId?: number;
  type: string;
  capacity?: number;
  receivedDate?: string;
  consigneeName?: string;
  priority?: TOrderPriority;
  address?: string;
  detailAddress?: string;
  shipmentType?: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  status?: string;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  skill?: string;
  serviceTime?: number;
  originDriverId?: number;
  serviceVehicle?: string;
  route: {
    internal: {
      routeIndex: number;
      requiredDistance?: number;
      requiredTime: number;
      eta?: string;
      polyline?: string;
      waitingTime?: number;
    };
    external: {
      routeIndex: number;
      requiredDistance?: number;
      requiredTime: number;
      eta?: string;
      polyline?: string;
      waitingTime?: number;
    };
  };
}

// @4YJ
export interface IEqualOptimizeResponsesDriver {
  driverId?: number | null;
  name?: string | null;
  workStartTime: string;
  hasLunch: boolean;
  status?: string;
  vehicle?: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    maxCapacity?: number;
    operationType?: TOperationType;
    startCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
  };
  orderList: IEqualOptimizeResponsesDriversOrderList[];
}

// export interface IEqualOptimizeResponses {
//   performedDate: string;
//   driverList: IEqualOptimizeResponsesDriver[];
// }
export interface IEqualOptimizeResponses extends IRunOptimizeEqualResponses {}

export interface IGetRouteDetailResponsesOrderList {
  orderId?: number;
  type: string;
  address?: string;
  detailAddress?: string;
  shipmentType: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  skill?: string;
  route: {
    routeIndex?: number;
    requiredDistance?: number;
    requiredTime?: number;
    eta?: string;
    polyline?: string;
  };
}

export interface IGetRouteDetailResponsesDriver {
  driverId?: number;
  name?: string;
  workStartTime: string;
  hasLunch: boolean;
  vehicle?: {
    vehicleId?: number;
    startAddress?: string;
    endAddress?: string;
    startCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };
  };
  orderList: IGetRouteDetailResponsesOrderList[];
}

export interface IGetRouteDetailResponses {
  routeId: number;
  name: string;
  performedDate: string;
  driverList: IGetRouteDetailResponsesDriver[];
}

export interface ICancelRouteOrder {
  driverId: number;
  orderList: number[];
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface IInviteMember {
  loginAccount: string;
  name: string;
}

export interface IRunRecalculateOptimize {
  performedDate: string; // YYYY-MM-DD
  performedTime?: string; // HH:mm
  workEndTime?: string; // (HH:mm)
  driverList: Array<{
    driverId: number;
    vehicle: {
      vehicleId: number;
    };
    orderList: [
      {
        orderId: number;
        type?: string;
      }
    ];
  }>;
}

export interface IRunRecalculateOptimizeResponses {
  performedDate: string;
  driverList: [
    {
      driverId?: number;
      name?: string;
      workStartTime: string;
      hasLunch: boolean;
      vehicle: {
        vehicleId?: number;
        startAddress?: string;
        endAddress?: string;
        startCoordinate?: {
          type: 'Point';
          coordinates: [number, number];
        };
        endCoordinate?: {
          type: 'Point';
          coordinates: [number, number];
        };
      };
      orderList: Array<IEqualOptimizeResponsesDriversOrderList>;
    }
  ];
}

export interface IRegisterDriverList {
  driverId: number;
  workStartTime: string; // (HH:mm)
  vehicle: {
    vehicleId: number;
  };
  orderList: Array<{
    orderId?: number;
    type: string;
    route: {
      internal: {
        routeIndex: number;
        requiredDistance?: number;
        requiredTime: number;
        eta?: string;
        polyline?: string;
      };
      external: {
        routeIndex: number;
        requiredDistance?: number;
        requiredTime: number;
        eta?: string;
        polyline?: string;
      };
    };
  }>;
}

export interface IRegisterRoute {
  name: string;
  performedDate: string;
  performedTime?: string; // HH:mm
  workEndTime?: string; // (HH:mm)
  driverList: Array<IRegisterDriverList>;
}

export interface IOrderListDownload {
  orderList: number[]; // 다운로드 하려는 orderId 의 배열
  sortBy?: string; // 정렬기준 // status: 상태 (default) | received_date: 주문 접수일 | desired_date: 작업 희망일 | completed_date: 배송 완료일 | driver_id: 드라이버 id | consignee_name: 고객명 | address: 주소 | product_name: 상품명
  sortMethod?: string; // 정렬 기준 // desc | asc (default)
}

export interface IRouteOrderListFormat {
  name: string;
  createdAt: string;
  orderList: [
    {
      orderId?: number;
      shipmentType: string;
      receivedDate?: string;
      consigneeName?: string;
      consigneePhone?: string;
      consigneeNote?: string;
      address?: string;
      detailAddress?: string;
      skill?: string;
      desiredDate?: string;
      capacity: number;
      serviceTime?: number;
      desiredTimeStart?: string;
      desiredTimeEnd?: string;
      note?: string[];
      product?: {
        code?: string;
        name?: string;
        quantity?: number;
      }[];
      driverName: string;
      performedDate: string;
      routeIndex: number | '-';
      eta?: string;
      requiredTime?: number;
    }
  ];
}

export interface IOrderListFormat {
  orderId?: number;

  shipmentType: string;
  clientKey: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  serviceTime: number;
  skill?: string;
  note?: string[];
  status: string;
  product: [
    {
      code?: string;
      name?: string;
      quantity: number;
    }
  ];
}

export interface IOrderListDownloadResponses {
  orderList: IOrderListFormat[];
}

export interface IUpsertOrder {
  orderList: IOrderListFormat[];
}

export interface IControlRouteList {
  routeId: number;
  name: string;
  status: string; // route status
  performedDate: string;
}

export interface IControlRouteDriverList {
  driverId: number;
  name: string;
  workStartTime: string;
  status: string;
  vehicle: {
    vehicleId: number;
    startAddress: string;
    endAddress?: string;
    startCoordinate: {
      type: 'Point';
      coordinates: [number, number];
    };
    endCoordinate?: {
      type: 'Point';
      coordinates: [number, number];
    };

    memo?: string;
    operationType: TOperationType;
    maxCapacity: number;
    model: {
      vehicleModelId: Number;
      name: string;
    };
  };
  orderList: IControlRouteDriverOrderList[];
}

export interface IControlRouteDriverOrderList {
  capacity?: number;
  orderId?: number;
  originDriverId?: number;
  isSkipped?: boolean;
  status: string;
  priority?: string;
  type: string;
  consigneeName?: string;
  address?: string;
  detailAddress?: string;
  shipmentType?: string;
  coordinate?: {
    type: 'Point';
    coordinates: [number, number];
  };
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  completedAt?: string;
  skill?: string;
  route: {
    routeIndex: number;
    requiredDistance: number;
    actualDistance?: number;
    polyline?: string;
    estimatedStartTime?: string;
    actualStartTime?: string;
    requiredTime?: number;
    actualTime?: number;
    eta?: string;
    ata?: string;
    serviceTime?: number;
    actualServiceTime?: number;
    estimatedCompletionTime?: string;
    actualCompletionTime?: string;
    waitingTime?: number;
    internal?: {
      routeIndex?: number;
      eta?: number;
    };
  };
}

export interface IGetControlRouteResponses {
  routeId: number;
  routeName: string;
  routeStatus: TRouteStatus;
  routeCode: string;
  routeShortCode: string;
  historyList: [
    {
      orderId?: number;
      driverId?: number;
      comment?: string;
      type: string;
      createdBy: string;
      createdAt: string;
    }
  ];
  orderStatus: {
    completed: number;
    scheduled: number;
    skipped: number;
    totalOrder: number;
  };
  driverList: IControlRouteDriverList[];
}

export interface IHistoryList {
  orderId?: number;
  orderAddress: string;
  driverId?: number;
  routeIndex: number;
  createdBy?: string;
  driverName: string;
  comment?: string;
  type: string;
  createdAt: string;
}

export interface ILastLocation {
  driverId: number;
  userId: number;
  location?: {
    type: string;
    coordinates: [number, number];
  };
}

interface IGetControlRouteListByDateRoutes {
  count: number;
  data: Array<IControlRouteList>;
}

export interface IGetControlRouteListByDateResponses {
  equalTimeRoutes: IGetControlRouteListByDateRoutes;
  manualRoutes: IGetControlRouteListByDateRoutes;
}

export interface IsetBatchOrders extends IsetBatchOrdersProps {
  // 배차 대기 상태 주문만 수정이 가능합니다.
  // only unassigned order can be modified
  orderList: number[]; // 주문 ID 목록
}

export interface IsetBatchOrdersProps {
  serviceVehicle?: string;
  desiredDate?: string;
  serviceTime?: number | string;
  note?: string[];
  skills?: number[];
}

export interface IGetInvestigatorListResponses {
  memberList: IInvestigator[];
}

export interface IInvestigator {
  driverId: number;
  name: string;
  status: string;
  phone?: string;
  workStartTime: string;
  vehicle?: {
    vehicleId: number;
    startAddress: string;
    endAddress: string;
  };
  userId: number;
  hasLunch: boolean;
  invitedAt: Date;
  acceptedAt?: Date;
}

export interface ISetInvestigatorProps {
  target: string | number;
  name?: string;
  workStartTime?: string;
  startAddress?: string;
  endAddress?: string;
  hasLunch?: boolean;
}

export interface IGetLocationTopicList {
  topicList: string[];
}

export interface IManualExcelRouteRequestResponse {
  vehicleList: Array<IManualExcelRouteRequestResponseList>;
}

export interface IManualExcelRouteRequestResponseList {
  vehicle: {
    vehicleId: number;
    licenseNumber: string;
  };
  orderList: Array<IManualExcelRouteRequestOrder>;
}

export interface IManualExcelRouteRequestOrder {
  mId?: string;
  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  address: string;
  detailAddress?: string;
  capacity: number;
  skill: string;
  serviceTime: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: string;
  coordinate: {
    type: 'Point';
    coordinates: [number, number];
  };

  shipmentType: string;
  priority: string;
  product: [
    {
      code?: string;
      name?: string;
      quantity?: number;
    }
  ];
}

export interface ImanualExcelValidateResponses {
  vehicleList: ImanualExcelValidateVehicleList[];
}

export interface ImanualExcelValidateVehicleList {
  vehicle: {
    vehicleId: number;
    licenseNumber: string;
  };
  orderList: ImanualExcelValidateOrderList[];
}

export interface ImanualExcelValidateOrderList {
  mId?: string;
  clientKey?: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone?: string;
  consigneeNote?: string;
  coordinate: {
    type: 'Point';
    coordinates: [number, number];
  };
  address: string;
  detailAddress?: string;
  capacity: number;
  shipmentType?: string;
  skill?: string;
  serviceTime?: number;
  desiredDate?: string;
  desiredTimeStart?: string;
  desiredTimeEnd?: string;
  note?: [string];
  product: [
    {
      code?: string;
      name?: string;
      quantity?: number;
    }
  ];
}

export interface ImanualRouting extends ImanualExcelValidateResponses {
  performedDate: string;
  name: string;
}

export type TOrderManagementStatus =
  | 'all'
  | 'unassigned'
  | 'scheduled'
  | 'completed'
  | 'skipped'
  | 'deleted'
  | 'priority';
export type TOrderManagementStatus_K = '전체' | '미배차' | '배차완료' | '처리완료' | '보류' | '취소' | '우선순위';

type TOrderManagementDateRangeFilterOptions = 'receivedDate' | 'desiredDate' | 'completedDate' | 'performedDate';
export type IOrderManagementContent = {
  title: string;
  status: TOrderManagementStatus_K;
  desc: string;
  filter: {
    searchItem: Array<{ [key in 'key' | 'value']: any }>;
    rangeFilter: {
      categoary: Array<{
        key: string;
        value: TOrderManagementDateRangeFilterOptions;
      }>;
    };
    shipmentType: Array<{
      label: string;
      value: string;
      icon?: any;
      visibleType: boolean;
      isSelected: boolean;
    }>;

    orderStatus?: Array<{ [key in 'key' | 'value' | 'isSelected']: any }>;
    routeStatus?: Array<{ [key in 'key' | 'value' | 'isSelected']: any }>;
    priorityStatus?: Array<{ [key in 'key' | 'value' | 'isSelected']: any }>;
  };
  tableSetting: {
    columns: TDragablePickerOptions;
  };
};
export type IRouteListContent = {
  title: string;
  routeId: string;
  desc: string;
  filter: {
    searchItem: Array<{ [key in 'key' | 'value']: any }>;
  };
  tableSetting: {
    columns: TDragablePickerOptions;
  };
};

export type IRouteListContentByStatus = {
  [key in TRouteStatus]: IRouteListContent;
};

export type IOrderManagementContentByStatus = {
  [key in TOrderManagementStatus]: IOrderManagementContent;
};

export type ITemporaryExcelOrders = Array<{
  orderStatus:
    | `new`
    | `validating`
    | `validatingError`
    | `validated`
    | `registering`
    | `registeringError`
    | `registered`;
  orderCode: string;
  orderId: number;
  orderShortCode: string;
  errorCode?: string;
  errorMessage?: string;
  erroredKey?: string; // error header key
  clientKey: string;
  receivedDate: string;
  consigneeName: string;
  consigneePhone: string;
  consigneeNote: string;
  address: string;
  detailAddress: string;
  coordinate: number[];
  capacity: number;
  skill: string;
  serviceTime: number;
  desiredDate: string; // '2023-01-11'
  desiredTimeStart: string; // '10:00'
  desiredTimeEnd: string; // '18:00'
  note: string[] | string;
  shipmentType: TShipmentType;
  product: {
    code: string;
    name: string;
    quantity: number;
  }[];

  rowIndexes: Array<number>;
}>;

export type TAsyncExcelUploadStatus =
  | `new`
  | 'reading'
  | `grouping`
  | `groupingError`
  | `groupped`
  | `validating`
  | `validatingError` // 완료인데  오류가 있음
  | `validated` // 검증 완료
  | `registering`
  | `registeringError`
  | `registered`
  | `readingError`; // 포멧 에러처리인가봄 도큐에 없음

export interface IMTemporaryExcelRows {
  rowStatus: string;
  errored_key?: string;
  error_message?: string;
  name: string;
  contact: string;
  address: string;
  note: string;
  capacity: string;
  serviceTime: number;
  client_order_key: string;
  received_date: string;
  detail_address: string;
  product_name: string;
  product_code: string;
  product_quantity: string;
  service_type: string;
  service_duration?: string;
  vehicleId: number;
  service_vehicle: string;
  customer_field_1: string;
  customer_field_2: string;
  customer_field_3: string;
  customer_field_4: string;
  customer_field_5: string;
}

export interface IDrvierInvitationTemporaryExcelRows {
  temporaryInvitationDriverRowId: number;
  temporaryInvitationDriverId: number;
  status: TAsyncExcelUploadStatus;
  teamId: number;
  errorCode: string | null;
  erroredKey: string | null;
  errorMessage: string | null;
  teamName: string;
  name: string;
  phoneNumber: string;
  modelName: string;
  workStartTime: string | null;
  breakDuration: string;
  breakRange: string | null;
  breakStart: string | null;
  breakEnd: string | null;
  note: string;
  maxCapacity: number;
  operationType: string;
  startAddress: string;
  startDetailAddress: string;
  startCoordinate: [number, number];
  endAddress: string;
  endDetailAddress: string;
  endCoordinate: [number, number];
  createdAt: Date;
}

export interface IDriverInvitationTemporaryExcel {
  temporaryInvitationDriverId: number;
  companyId: number;
  userId: number;
  status: TAsyncExcelUploadStatus;
  rows: Array<IDrvierInvitationTemporaryExcelRows>;
  s3Url: string;
  erroredExcel: string | null;
  errorMessage: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITemporaryExcel {
  _id: string;
  companyId: number;
  status: TAsyncExcelUploadStatus;
  //   new : 신규 단계
  // grouping : 주문 묶음 처리중
  // groupingError : 주문 묶음 처리 에러
  // groupped : 주문 묶음 완료
  // validating : 주문 검증중
  // validatingError : 주문 검증 에러
  // validated : 주문 검증 완료
  // registering : 주문 등록중
  // registeringError : 주문 등록 에러
  // registered : 주문 등록 완료
  headers: Array<string>;

  //   'client_order_key',
  //   'received_date',
  //   'name',
  //   'contact',
  //   'address',
  //   'detail_address',
  //   'note',
  //   'capacity',
  //   'product_name',
  //   'product_code',
  //   'product_quantity',
  //   'service_type',
  //   'service_duration',
  //   'service_vehicle',
  //   'desired_date',
  //   'desired_after_time',
  //   'desired_before_time',
  //   'customer_field_1',
  //   'customer_field_2',
  //   'customer_field_3',
  //   'customer_field_4',
  //   'customer_field_5'

  rows: Array<any>;
  // [[
  //   'roouty_00001',
  //   '2022-11-28',
  //   '강루티',
  //   '010-1234-5678',
  //   '서울 마포구 백범로31길 21',
  //   '101동 615호',
  //   '도착하면 전화주세요.',
  //   '1',
  //   '산지직송 벌꿀',
  //   'F1102',
  //   '1',
  //   '배달',
  //   '1',
  //   '82가 1234',
  //   '2023-01-01',
  //   '15:30',
  //   '18:00',
  //   '현관 비밀번호 *1234',
  //   '배송코드 1004',
  //   '',
  //   '',
  //   ''
  // ]];
  orders: ITemporaryExcelOrders;
  createdAt: Date; // stirng 2023-01-11T03:02:30.202Z
  updatedAt: Date;

  errorMessage?: string; // not for users

  s3Url: string; // s3://
  userId: number;

  erroredExcel?: string; // file URL
}

export interface IRunOptimizeFixedArea {
  performedDate: string;
  orderList: number[];
  driverList: {
    driverId: number;
    vehicleId: number;
  }[];
}

export interface IRunOptimizeFixedAreaResponse extends IRunOptimizeEqualResponses {}

export interface IGetAreaListProps extends Partial<ISearch> {}

export interface IGetAreaListResponse {
  areaList: {
    areaId: number;
    name: string;
    district?: [string];
    polygon: {
      type: 'MultiPolygon';
      coordinates: [[[[number, number]]]];
    };
    areaDrivers: [
      {
        driverId: number;
        driverName: string;
        vehicleId?: number;
        vehicleName?: string;
        vehicleOperationType: TOperationType;
      }
    ];
  }[];
}

export interface IAreaProps {
  name: string;
  district: string[];
  driverList?: number[];
  polygon?: [[[[number, number]]]];
}

export interface IAreaResponse {
  areaId: number;
}

export interface IGetRouteListBoundary {
  performedDate: string; // 요청일 구간(YYYYMMDD-YYYYMMDD)
  status?: TRouteStatus | 'canceled'; // 경로 상태
}

export interface IGetRouteListBoundaryResponse {
  routeList: Array<{
    performedDate: 'string'; // YYYY-MM-DD
    routeList: Array<{
      routeId: number;
      status: TRouteStatus | 'canceled'; // 경로 상태
    }>;
  }>;
}

export interface ITeam {
  teamId: number;
  name: string;
  memo?: string;
}

export interface ICreateTeamProps extends Omit<ITeam, 'teamId'> {
  userList?: Array<number>;
}

export interface IGetTeamListProps extends Partial<ISearch> {}

export interface IGetTeam extends ITeam {
  hasOwner: boolean;
  managerCount: number;
  driverCount: number;
  createdAt: Date;
}

export interface IGetTeamListResponse {
  // data: Array<
  //   {
  //     name: string;
  //     managerCount: number;
  //     driverCount: number;
  //     memo?: string;
  //     createdAt: Date;
  //   } & Pick<ITeam, 'memo'>
  // >;
  data: Array<IGetTeam>;
}

export interface ITeamManager {
  name: string;
  userId: number;
  loginAccount: string;
  teamCount: number;
  createdAt: Date;
}

export interface ITeamDriver {
  driverId: number;
  name: string;
  loginAccount: string;
  operationType: TOperationType;
  status: TAccountStatus;
  createdAt: Date;
}

export interface IGetTeamDetailById extends ITeam {
  invitedManagers: Array<{
    invitationId: number;
    loginAccount: string;
  }>;
  invitedDrivers: Array<{
    invitationId: number;
    loginAccount: string;
  }>;
  createdAt: number;
  managers: Array<ITeamManager>;
  drivers: Array<ITeamDriver>;
}

export interface IModifyingTeamByIdProps extends Pick<ITeam, 'teamId'>, Partial<Omit<ITeam, 'teamId'>> {}

interface IDriverBasics {
  teamId: number;
  teamName: string;
  name: string;
  loginAccount: string;
  operationType: TOperationType;
  createdAt: string;
  skills: Pick<IMultiSkillData, 'skillId' | 'name'>[];
}

export interface IInvolvedDriver extends IDriverBasics {
  driverId: number;
  status: TAccountStatus;
  model: {
    vehicleModelId: number;
    name: string;
  };
  maxCapacity: number;
  workStartTime: string;
  workEndTime: string;
  memo?: string;
  area: [
    {
      areaId: number;
      name: string;
    }
  ];
  driverBreak?: {
    startTime: string;
    endTime: string;
    duration: number;
  };
  startAddress: string;
  startDetailAddress: string;
  endAddress: string;
  endDetailAddress: string;
}

export interface IInvitedDriver extends IDriverBasics {
  modelName: string;
  expiredAt: string;
  invitationId: number;
}

export interface IGetDriverListProps extends Partial<ISearch> {
  operationType?: string;
  status?: string;
}

export interface IGetDriverListResponse<T> {
  data: Array<T>;
}

export type TUsageCount = number;

export interface IMonthlyUsage {
  count: TUsageCount;
}

export interface IGetTeamUsageByBoundary {
  data: Array<{
    name: string;
    count: number;
    status: ITeamStatus;
  }>;
}

export interface IGetTotalUsageByBoundary {
  currentOrderAssigned: number;
  maxOrderAssigned?: number;
}

export interface IGetPaymentBoundaries {
  data: Array<{
    startDateOfUse: string;
    endDateOfUse: string;
  }>;
}

export interface IPaymentActionProps {
  plan: Omit<PlanStatusUnion, 'Free'>;
  interval: PaymentTypeUnion;
  successUrl?: string;
  errorUrl?: string;
  cancelUrl?: string;
}
