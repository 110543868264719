import * as S from 'Layout/style';
import { Button } from 'pages/Landing/style';
import { Text } from 'components/Typography';
import theme from 'constants/theme';
import { TColor } from 'constants/types';

import { ReactComponent as Logo } from 'roouty_logo.svg';
import { NavLink } from 'react-router-dom';
import { useLandingActions } from 'pages/Landing/actions';

type THeaderTheme = 'primary' | 'white';
export const Header = ({
  headerTheme = 'white',
  isMobile = false,
}: {
  headerTheme?: THeaderTheme;
  isMobile?: boolean;
}) => {
  /** [ bg, logo, text , buttonBg, buttonText] */
  const header_theme: { [key in THeaderTheme]: Array<TColor | string> } = {
    primary: ['RC02', 'RG00', 'RG00', '#001750', 'RG00'],
    white: ['RG00', 'RG04', 'RG01', 'RC02', 'RG00'],
  };

  const { onClicks } = useLandingActions({ device: isMobile ? 'mobile' : 'pc' });

  return (
    <S.Header bgColor={header_theme[headerTheme][0]} blur={headerTheme === 'primary'} isMobile={isMobile}>
      <S.HeaderItems>
        <NavLink to={'/'}>
          <S.HeaderIcon>
            <Logo
              width={100}
              height={20}
              //@ts-ignore
              fill={theme.colors[header_theme[headerTheme][1]] ?? header_theme[headerTheme][1]}
              style={{
                flexShrink: 0,
                cursor: 'pointer',
              }}
            />
          </S.HeaderIcon>
        </NavLink>
        {isMobile ? null : (
          <>
            <NavLink to={'/landing/#info'}>
              <Text
                broken={false}
                styleName="subheadline1"
                color={header_theme[headerTheme][2]}
                className="fee"
                sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              >
                요금제 안내
              </Text>
            </NavLink>

            <S.HeaderText>
              <NavLink to={'/signin'}>
                <Text styleName="subheadline1" color={header_theme[headerTheme][2]} sx={{ cursor: 'pointer' }}>
                  로그인
                </Text>
              </NavLink>
            </S.HeaderText>
            <Button
              width={196}
              color={header_theme[headerTheme][4]}
              bgColor={header_theme[headerTheme][3]}
              styleName="subheadline1"
              style={{ height: '36px', borderRadius: '6px', flexShrink: 0 }}
              onClick={onClicks['free-trial']}
            >
              무료로 체험하기
            </Button>
          </>
        )}
      </S.HeaderItems>
    </S.Header>
  );
};
