import React, { Fragment, useMemo, useRef, useState } from 'react';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { Text } from 'components/Typography';

import * as S from '../common';
import theme from 'constants/theme';

import { ReactComponent as IcCaret } from 'constants/icon/ic_arrow_up_line.svg';
import { ReactComponent as CircleCheckBox } from 'constants/icon/ic_success.svg';
import { useUnsubscribeCancel } from 'hooks/query/payment/unsubscribe/useUnsubscribeCancel';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import PaymentPlanModal, { TPaymentPlanActions } from './PaymentPlanModal';
import { IRooutyPlan, roouty_plans } from 'constants/subscribePlans';
import { useQuery } from '@tanstack/react-query';
import { getMyInfo } from '../../../../api';
import QueryStatusModal from 'components/Modal/QueryStatusModal';

export const Unsubscribe = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [isFolded, seIisFolded] = useState<boolean>(true);
  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: paymentMyData } = useGetPaymentMy();
  const { refetch: unsubscribeCancel, isSuccess: isSuccessUnsubscribeCancel } = useUnsubscribeCancel({
    enabled: false,
    onSuccess() {
      setOpenModal(true);
    },
  });

  const { data: myInfo } = useQuery(['getMyInfo'], getMyInfo, {
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  const [paymentTarget, setPaymentTarget] = useState<(IRooutyPlan & { action: TPaymentPlanActions }) | null>(null);

  const disableUnsubscribe = useMemo<boolean>(() => myInfo?.roleId !== 1, [myInfo?.roleId]);

  return paymentMyData?.name !== 'Free' ? (
    <Fragment>
      <S.BorderBox>
        <Stack padding={20} direction="row" justify="space-between">
          <Text styleName="body2" color={disableUnsubscribe ? 'RG04' : 'RG02'} sx={{ flex: 1 }}>
            Free 요금제로 다운그레이드
          </Text>

          {paymentMyData?.cancellation ? (
            <Stack direction="row" justify="space-between" sx={{ flex: 3 }}>
              <Text styleName="body2" color="RC02">{`결제 예정일 ${dayjs(paymentMyData?.cancellation?.dueDate).format(
                'YYYY.MM.DD'
              )}`}</Text>
              <Button
                children="다운그레이드 취소"
                variant="primary"
                h={32}
                onClick={() => {
                  unsubscribeCancel();
                }}
              />
            </Stack>
          ) : (
            <Text
              styleName="caption1"
              color={disableUnsubscribe ? 'RG04' : 'RG03'}
              onClick={() => {
                if (disableUnsubscribe) return;
                seIisFolded(o => !o);
              }}
              sx={{ cursor: disableUnsubscribe ? 'not-allowed' : 'pointer' }}
            >
              {isFolded ? '자세히 보기' : '접기'}
              <IcCaret
                width={12}
                height={12}
                style={{
                  padding: 4,
                  rotate: isFolded ? '180deg' : '0deg',
                  marginLeft: 6,
                }}
              />
            </Text>
          )}
        </Stack>

        <Stack
          sx={{
            height: isFolded || paymentMyData?.cancellation ? 0 : 128 + 72 + 'px',
            transition: 'all 0.3s',
            overflow: 'hidden',
          }}
        >
          <Stack
            spacing={8}
            padding={20}
            align="start"
            sx={{
              border: `1px solid ${theme.colors.RG06}`,
              borderLeft: 'none',
              borderRight: 'none',
            }}
          >
            <Text styleName="body1" onClick={() => setAgreement(agreement => !agreement)} sx={{ cursor: 'pointer' }}>
              <CircleCheckBox
                fill={agreement ? theme.colors.RC02 : theme.colors.RG05}
                width={16}
                height={16}
                style={{ marginRight: 8 }}
              />
              Free 요금제로 다운그레이드
            </Text>
            <Text styleName="body2" sx={{ marginLeft: 24 }}>{`${paymentMyData?.name} 요금제 기능이 ${dayjs(
              paymentMyData?.expiredAt
            ).format('YYYY.MM.DD')} 에 종료됩니다.`}</Text>
          </Stack>

          <Stack spacing={20} padding={20} justify="end" direction="row">
            <Button
              children={'취소'}
              variant={'tertiary'}
              w={102}
              h={32}
              onClick={() => {
                seIisFolded(true);
                setAgreement(false);
              }}
            />
            <Button
              children="Free 요금제로 다운그레이드"
              variant="primary"
              w={216}
              h={32}
              disabled={!agreement}
              onClick={() => {
                setPaymentTarget({
                  ...roouty_plans.find(item => item.name === 'Free')!,
                  action: 'unsubscribe',
                });
                setUnsubscribeModalIsOpen(true);
              }}
            />
          </Stack>
        </Stack>
      </S.BorderBox>
      <div ref={containerRef} style={{ visibility: 'hidden' }} />

      {unsubscribeModalIsOpen && paymentTarget && (
        <PaymentPlanModal paymentType={'monthly'} {...{ paymentTarget, setPaymentTarget }} />
      )}

      {/* <UnsubscribeModal {...{ isOpen: unsubscribeModalIsOpen, setIsOpen: setUnsubscribeModalIsOpen }} />

     */}

      <QueryStatusModal
        status="success"
        string="Free 요금제 다운그레이드 신청이 취소되었습니다."
        isOpen={openModal}
        setIsOpen={setOpenModal}
        callback={() => {
          navigate(0);
        }}
      />
    </Fragment>
  ) : (
    <></>
  );
};

export default Unsubscribe;
