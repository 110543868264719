import type { Dispatch, SetStateAction } from 'react';
import type { UseFormSetError, UseFormGetValues } from 'react-hook-form';
import { postSendVerifyLoginAccount, putSendNewPassword, getCheckUrlKey } from 'auth/resetPassword/api';

export const sendVerifyLoginAccount = async (
  setErrors: UseFormSetError<TValidateEmailType>,
  getValues: UseFormGetValues<TValidateEmailType>,
  setIsValidAccount: Dispatch<SetStateAction<boolean>>,
  setIsLoading: (isLoading: boolean) => void,
  codeFailer?: {
    [key in TErrorCode]?: Function;
  }
) => {
  const payload = getValues();

  const response = await postSendVerifyLoginAccount(payload);
  const error = response as TErrorResponse;

  if (typeof codeFailer?.[error?.number] === 'function') {
    setIsLoading(false);
    return codeFailer[error.number]!();
  }
  if (error?.message) {
    setIsLoading(false);
    return setErrors('loginAccount', { message: '존재하지 않는 아이디입니다' });
  }
  setIsLoading(false);
  return setIsValidAccount(true);
};

export const sendNewAccount = async (
  urlKey: string,
  setErrors: UseFormSetError<TValidatePasswordType>,
  getValues: UseFormGetValues<TValidatePasswordType>,
  setIsApplied: Dispatch<SetStateAction<boolean>>,
  setIsLoading: (isLoading: boolean) => void
) => {
  const payload = Object.assign({}, getValues(), { urlKey: urlKey });
  const response = await putSendNewPassword(payload);
  const error = response as TErrorResponse;

  if (error?.message) {
    setIsLoading(false);
    return setErrors('password', { message: error.message });
  }
  setIsLoading(false);
  return setIsApplied(true);
};

export const checkUrlKey = async (urlKey: string) => {
  const response = await getCheckUrlKey(urlKey);

  if (typeof response === 'string') {
    return false;
  }

  return true;
};
