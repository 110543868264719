import { ReactElement } from 'react';
import styled from 'styled-components';

interface CellProps {}

const CellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 24px;
  margin-top: 15px;

  :hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: ${({ theme }) => theme.colors.RC05};
  }
`;

export default function ({ ...props }: CellProps): ReactElement {
  return <CellWrapper />;
}
