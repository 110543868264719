import { useQuery } from '@tanstack/react-query';

import { getControlRoute, getControlRouteHistory } from 'api';
import { ArcElement, Chart as ChartJS } from 'chart.js';

import Alt from 'components/Alt';

import Divider from 'components/Divider';
import { Stack } from 'components/Stack';
import { Stack as StackStyle } from 'components/Stack/style';
import { Highlight, Text } from 'components/Typography';

import { ColorPalette } from 'constants/colorPalette';
import { ReactComponent as SpinLoader } from 'constants/icon/ellipse_spin_loader.svg';
import { ReactComponent as IcArrowUpLine } from 'constants/icon/ic_arrow_up.svg';
import { ReactComponent as IcArrowRight } from 'constants/icon/ic_arrowright20.svg';
import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import { ReactComponent as IcMarker } from 'constants/icon/ic_mini_marker.svg';
import { ReactComponent as IcReload } from 'constants/icon/ic_reload.svg';

import { ReactComponent as IcTimer } from 'constants/icon/ic_timer.svg';

import theme from 'constants/theme';
import { IControlRouteDriverList, IGetControlRouteResponses, IHistoryList, TRouteStatus } from 'constants/types';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { HistoryText } from 'pages/RouteControlPage';
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { getTotalReqTime } from 'util/getDriverSummary';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';
import { useTracking } from '../../hooks/store/useTracking';
import strings from '../../util/Localization';

ChartJS.register(ArcElement);

dayjs.locale('ko');
dayjs.extend(utc);
dayjs.extend(isBetween);

interface RouteControlNavProps {
  performedDate: string;
  tabStackProps: { subject: 'driver' | 'history' | 'routeList'; isOpen: boolean };
  setPerformedDate: Dispatch<SetStateAction<string>>;
  setTabStackProps: Dispatch<SetStateAction<{ subject: 'driver' | 'history'; isOpen: boolean }>>;
  cardTargetDriverId: number;
  setCardTargetDriverId: Dispatch<SetStateAction<number>>;
  targetRouteId: number;
  setTargetRouteId: Dispatch<SetStateAction<number>>;

  propStates:
    | undefined
    | {
    driverId: number;
    orderId: number;
    routeId: number | undefined;
    targetPerformedDate: string | undefined;
    initPageStatus: TRouteStatus;
  };
  setPropStates: Dispatch<
    SetStateAction<{
      driverId: number;
      orderId: number;
      routeId: number | undefined;
      targetPerformedDate: string | undefined;
      initPageStatus: TRouteStatus;
    }>
  >;
}

const RouteControlNav = ({
                           performedDate,
                           setPerformedDate,
                           tabStackProps,
                           setTabStackProps,
                           cardTargetDriverId,
                           setCardTargetDriverId,
                           targetRouteId,
                           setTargetRouteId,
                           propStates,
                           setPropStates,
                         }: RouteControlNavProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [controlRouteData, setControlRouteDate] = useState<IGetControlRouteResponses | undefined>();

  const [historyList, setHistoryList] = useState<Array<IHistoryList>>([]);
  const [currentRouteName, setCurrentRouteName] = useState<string | undefined>();

  const [routedDrivers, setRoutedDrivers] = useState<{
    runningDrivers: Array<IControlRouteDriverList>;
    completedDrivers: Array<IControlRouteDriverList>;
    waitingDrivers: Array<IControlRouteDriverList>;
  }>({
    runningDrivers: [],
    completedDrivers: [],
    waitingDrivers: [],
  });

  const [accordion, setAccordion] = useState<{ [key: number]: boolean }>({ 0: false, 1: false, 2: false });
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const controlOrderStatus = [
    { k: '완료', e: 'completed' },
    { k: '예정', e: 'scheduled' },
    { k: '보류', e: 'skipped' },
    { k: '전체', e: 'totalOrder' },
  ];

  const [alt, setAlt] = useState<{ visible: boolean; content: string }>({ visible: false, content: '' });
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const {
    dataUpdatedAt: controlRouteByIdUpdatedAt,
    isFetching: controlRouteByIdIsFetching,
    isLoading: controlRouteByIdIsLoading,
    refetch: controlRouteByIdRefetch,
  } = useQuery(['controlRouteById', targetRouteId], () => getControlRoute(targetRouteId), {
    onSuccess: res => {
      setControlRouteDate(res);
    },
    refetchIntervalInBackground: true,
    enabled: targetRouteId > 0,
  });

  const { refetch: controlRouteHistoryRefetch } = useQuery(
    ['controlRouteHistory', targetRouteId],
    () => getControlRouteHistory(targetRouteId),
    {
      onSuccess: res => setHistoryList(res.historyList),
      refetchIntervalInBackground: true,
      enabled: targetRouteId > 0,
    },
  );
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('모니터링>경로 상세');
  }, [setTitle]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!dropdownRef.current?.contains(e.target)) setShowDropdown(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (controlRouteData === undefined) {
      setRoutedDrivers({
        runningDrivers: [],
        completedDrivers: [],
        waitingDrivers: [],
      });
    } else {
      setRoutedDrivers({
        runningDrivers: controlRouteData.driverList.filter(d => d.status === 'running'),
        completedDrivers: controlRouteData.driverList.filter(d => d.status === 'completed'),
        waitingDrivers: controlRouteData.driverList.filter(d => d.status === 'waiting'),
      });
    }
  }, [controlRouteData, performedDate]);

  useEffect(() => {
    setHistoryList([]);
  }, [performedDate]);

  const summaryChartData = useMemo(() => {
    return {
      datasets: [
        {
          borderWidth: 0,
          data: [
            controlRouteData?.orderStatus.scheduled ?? 1,
            controlRouteData?.orderStatus.completed ?? 0,
            controlRouteData?.orderStatus.skipped ?? 0,
          ],
          backgroundColor: [theme.colors.RC03_1, theme.colors.RC03, theme.colors.RG05],
        },
      ],
    };
  }, [controlRouteData]);

  const summaryChartOption = useMemo(() => {
    return {
      cutout: 35,
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
    };
  }, []);

  const forceRouteRefetch = () => {
    if (targetRouteId <= 0) return;
    controlRouteByIdRefetch();
    controlRouteHistoryRefetch();
  };

  useEffect(() => {
    setCurrentRouteName(undefined);
  }, [performedDate]);

  const currentDriverCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentDriverCardRef.current === null) return;
    else {
      console.log(currentDriverCardRef.current, 'ref');
      currentDriverCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [cardTargetDriverId, currentDriverCardRef.current]);

  return (
    <Stack spacing={20} sx={{ height: '100%', padding: '0 20px 48px 30px', overflowX: 'hidden' }} useVerticalScroll>
      <Stack name="perform-date" spacing={20} align="start">
        <Text styleName="body1" color={'RG02'}>
          {controlRouteData?.routeName ?? '-'}
        </Text>
        <Stack spacing={10}>
          <Stack direction="row">
            <Text styleName="caption2" color="RG03" sx={{ flex: 1 }}>
              {strings.주행일자}
            </Text>
            <Text styleName="caption2" color="RG03">
              {!controlRouteData || controlRouteByIdIsLoading ? '-' : dayjs(performedDate).format('YYYY.MM.DD (ddd)')}
            </Text>
          </Stack>
          <Stack direction="row">
            <Text styleName="caption2" color="RG03" sx={{ flex: 1 }}>
              경로ID
            </Text>
            <Text styleName="caption2" color="RG03">
              {!controlRouteData?.routeCode || controlRouteByIdIsLoading ? '-' : controlRouteData.routeCode}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Divider
        color="RG06"
        style={{
          width: `calc(100% + 50px )`,
          marginRight: '10px',
        }}
      />
      {controlRouteByIdIsFetching ? (
        <SpinLoader style={{ width: '44px', height: '44px' }} className="infinite_rotating"/>
      ) : (
        <>
          <Stack name="perform-situation" spacing={12}>
            <Stack direction="row" justify="space-between" align="center">
              <Stack
                spacing={10}
                direction="row"
                // divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                sx={{ width: 'fit-content' }}
              >
                <Text styleName="caption2" color="RG03">
                  {strings.주행현황}
                </Text>

                <Text styleName="caption2" color="RG04" broken={false}>
                  {targetRouteId > 0
                    ? `  ${
                      controlRouteByIdUpdatedAt
                        ? dayjs(controlRouteByIdUpdatedAt).utcOffset('+0900').format('HH : mm : ss')
                        : dayjs().utcOffset('+0900').format('HH : mm : ss')
                    }
                  기준`
                    : '-'}
                </Text>
              </Stack>
              <Stack
                direction="row"
                justify="center"
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '4px',
                  border: '1px solid #D8DDEA',
                  cursor: targetRouteId > 0 ? 'pointer' : 'not-allowed',
                }}
              >
                <IcReload
                  style={{
                    width: '15px',
                    height: '15px',
                  }}
                  onClick={forceRouteRefetch}
                />
              </Stack>
            </Stack>

            <GrayBox padding="14px">
              <Stack spacing={22} direction="row">
                <ProgressArea>
                  <Stack
                    justify="center"
                    align="center"
                    direction="row"
                    sx={{
                      width: 'fit-content',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: `translate(-50%, -50%)`,
                    }}
                  >
                    <Text styleName="body1" color="RG02">
                      {controlRouteData !== undefined
                        ? Math.floor(
                        ((controlRouteData.orderStatus.completed + controlRouteData.orderStatus.skipped) /
                          controlRouteData.orderStatus.totalOrder) *
                        100,
                      ) || 0
                        : 0}
                      <Highlight styleName="caption1" color="RG03">
                        %
                      </Highlight>
                    </Text>
                  </Stack>
                  <Doughnut data={summaryChartData} options={summaryChartOption}/>
                </ProgressArea>
                <Stack name="text-info-area" spacing={8} sx={{ flex: 1 }}>
                  {controlOrderStatus.map((d, index) => (
                    <Stack
                      key={`text-info-${index}`}
                      spacing={8}
                      direction="row"
                      divider={<Divider color="RG06" vertical style={{ height: '12px' }}/>}
                    >
                      <Text styleName="caption1" color="RG03">
                        {controlOrderStatus[index].k}
                      </Text>
                      <Text styleName="caption1" color="RG02" sx={{ flex: 1, justifyContent: 'end' }}>
                        {controlRouteData
                          ? controlRouteData.orderStatus[
                            controlOrderStatus[index].e as keyof typeof controlRouteData.orderStatus
                            ].toLocaleString()
                          : 0}{' '}
                        <Highlight styleName="caption2" color="RG02">
                          건
                        </Highlight>
                      </Text>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </GrayBox>
          </Stack>

          <PerformSituationHistory
            spacing={4}
            onClick={() => {
              setTabStackProps(prev => ({
                ...prev,
                subject: 'history',
                isOpen: prev.subject !== 'history' && prev.isOpen ? prev.isOpen : !prev.isOpen,
              }));
            }}
          >
            <Stack justify="space-between" direction="row">
              <Text styleName="caption2" color="RG03">
                {strings.주행히스토리}
              </Text>
              <IcArrowRight/>
            </Stack>
            <GrayBox>
              {historyList.length > 0 ? (
                <HistoryText obj={historyList[0]}/>
              ) : (
                <Text styleName="caption2" color="RG03">
                  {strings.주행_조사}중인 {strings.드라이버}이(가) 없습니다
                </Text>
              )}
            </GrayBox>
          </PerformSituationHistory>

          <Stack
            // useVerticalScroll
            spacing={20}
            sx={{
              marginRight: '-20px',
              width: 'calc(100% + 20px)',
              paddingRight: '20px',
            }}
          >
            {Object.values(routedDrivers).map((d, index) => {
              let driverListStatus = {
                '0': strings.주행_조사 + '중',
                '1': '완료',
                '2': '대기중',
              };

              return d.length ? (
                <Stack
                  key={`${driverListStatus[index.toString() as keyof typeof driverListStatus]}-drivers`}
                  name={`${driverListStatus[index.toString() as keyof typeof driverListStatus]}-drivers`}
                  spacing={4}
                >
                  <Stack
                    direction="row"
                    justify="space-between"
                    sx={{ padding: '10px 0' }}
                    className="pointer"
                    onClick={() => {
                      console.log(accordion, 'be');
                      setAccordion(prev => {
                        return { ...prev, [index]: !prev[index] };
                      });
                      console.log(accordion, 'af');
                    }}
                  >
                    <Stack
                      spacing={10}
                      direction="row"
                      sx={{ width: 'max-content' }}
                      divider={<Divider color="RG06" vertical style={{ height: '12px' }}/>}
                    >
                      <Text styleName="caption2" color="RG03">
                        {strings.드라이버}
                      </Text>

                      <Text styleName="caption2" color={index === 0 ? 'RC02' : 'RG04'}>
                        {`${driverListStatus[index.toString() as keyof typeof driverListStatus]} ( ${d.length} 명 )`}
                      </Text>
                    </Stack>
                    <IcArrowUpLine style={{ rotate: accordion[index] ? '180deg' : '' }}/>
                  </Stack>

                  <Stack
                    spacing={10}
                    sx={{
                      opacity: accordion[index] ? 0 : 1,
                      height: accordion[index] ? 0 : 'auto',
                      overflowY: 'hidden',
                      minHeight: 'fit-content',
                      transition: 'all .3s ease-out',
                    }}
                  >
                    {d.map(x => {
                      const { routeStartTime, totalReqTime } = getTotalReqTime({
                        performedDate,
                        workStartTime: x.workStartTime,
                        lastOrderETA: x.orderList.at(-1)?.route.eta ?? '00:00',
                      });

                      // let routeStartTime = dayjs(
                      //   new Date(
                      //     Number(performedDate.split('.')[0]),
                      //     Number(performedDate.split('.')[1]) - 1,
                      //     Number(performedDate.split('.')[2]),
                      //     Number(x.workStartTime.split(':')[0]),
                      //     Number(x.workStartTime.split(':')[1]),
                      //     0
                      //   )
                      // ).utcOffset('+0900');

                      let lunch = x.orderList.find(d => d.type === 'break');
                      let lunchIndex = x.orderList.findIndex(d => d.type === 'break');

                      let isLunch = lunch
                        ? lunchIndex > 0 &&
                        x.status === 'running' &&
                        (x.orderList.at(lunchIndex - 1)?.status === 'completed' ||
                          x.orderList.at(lunchIndex - 1)?.status === 'skipped') &&
                        x.orderList.at(lunchIndex + 1)?.status === 'scheduled'
                        : //   dayjs().isBetween(
                          //     dayjs(lunch?.route.eta).toDate(),
                          //     dayjs(lunch?.route.eta).add(lunch?.route?.requiredTime ?? 0, 's')
                          //   ) &&
                          //   x.orderList.at(lunchIndex + 1)?.status === 'scheduled') ||
                          // (dayjs().isBefore(
                          //   dayjs(lunch?.route.eta)
                          //     .add(lunch?.route?.requiredTime ?? 0, 's')
                          //     .toDate()
                          // ) &&
                          //   x.orderList.at(lunchIndex - 1)?.status === 'completed' &&
                          //   x.orderList.at(lunchIndex + 1)?.status === 'scheduled')
                        false;

                      let processingOrder = x.orderList.filter(y => y.status === 'processing')[0];
                      // let totalReqTime = x.orderList.reduce((y, z) => y + (z.route.requiredTime || 0), 0);

                      // let totalReqTime = Math.abs(
                      //   routeStartTime.diff(
                      //     dayjs(x.orderList.at(-1)?.route.eta).add(x.orderList.at(-1)?.route.requiredTime ?? 0, 's'),
                      //     's'
                      //   )
                      // );

                      let totalReqDistance = numberWithCommas(roundDecimal(
                        x.orderList
                          .reduce((y, z) => y + (z.route.requiredDistance || 0), 0)
                        / 1000, 2,
                      ))?.replace('.00', '');

                      return (
                        <DriverItem
                          key={`driverItem-${x.driverId}-${x.name}`}
                          isActive={
                            tabStackProps.subject === 'driver' &&
                            x.driverId === cardTargetDriverId &&
                            tabStackProps.isOpen
                              ? true
                              : false
                          }
                          ref={x.driverId === cardTargetDriverId ? currentDriverCardRef : null}
                          onClick={e => {
                            if (
                              x.driverId !== cardTargetDriverId ||
                              (tabStackProps.subject !== 'driver' && tabStackProps.isOpen)
                            ) {
                              setCardTargetDriverId(x.driverId);
                              setTabStackProps(prev => ({ ...prev, subject: 'driver', isOpen: true }));
                            } else setTabStackProps(prev => ({ ...prev, subject: 'driver', isOpen: !prev.isOpen }));
                          }}
                          spacing={6}
                        >
                          <Stack direction="row" spacing={4}>
                            <DriverColorBox color={ColorPalette.getColor(x.driverId)}/>
                            <Text styleName="subheadline1" color="RG02" sx={{ flex: 3, height: 20 }}>
                              {x.name}
                            </Text>
                            {/* <IcArrowRight viewBox="-5 0 20 20" /> */}
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={6}
                            divider={<Divider color="RG06" vertical style={{ height: '12px' }}/>}
                          >
                            <Stack spacing={4} direction="row" sx={{ width: 'fit-content' }}>
                              <IcMarker/>
                              <Text styleName="caption2" color="RG02">
                                {x.orderList.filter(y => y.type === 'order').length}
                              </Text>
                            </Stack>

                            <Stack spacing={6} direction="row" sx={{ width: 'fit-content' }}>
                              <Text styleName="caption2" color="RG02">
                                <>
                                  {routeStartTime.format('HH:mm')}&nbsp;-&nbsp;
                                  {routeStartTime.add(totalReqTime, 's').format('HH:mm')}
                                </>
                              </Text>

                              {x.orderList.filter(d => d.type === 'break').length ? <IcHasLunch/> : null}
                            </Stack>

                            <Text styleName="caption2" color="RG02">
                              {`${totalReqDistance} km`}
                            </Text>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={8}
                            align="center"
                            justify="space-between"
                            // sx={{
                            //   flexWrap: 'wrap',
                            //   padding: '-2px 0 0 0',
                            // }}
                          >
                            <Stack
                              direction="row"
                              spacing={4}
                              onMouseOver={e => {
                                setAnchorPoint(e.currentTarget.getBoundingClientRect());
                                setAlt({
                                  content:
                                    (isLunch
                                        ? '휴게시간'
                                        : processingOrder?.address ??
                                        (x.orderList.filter(y => y.status === 'completed').length > 0
                                          ? x.orderList.filter(y => y.status === 'completed').at(-1)!.address
                                          : x.orderList[0].type === 'break'
                                            ? '휴게시간'
                                            : x.orderList[0].address)
                                    )?.toString() ?? '',
                                  visible: true,
                                });
                              }}
                              onMouseOut={() => {
                                setAlt({ content: '', visible: false });
                              }}
                              sx={{
                                width: 'fit-content',

                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <Text styleName="caption2" color="RC02" broken={false}>
                                {isLunch
                                  ? lunch?.route.routeIndex.toString().padStart(2, '0')
                                  : processingOrder?.route?.routeIndex.toString().padStart(2, '0') ??
                                  (x.orderList.filter(y => y.status === 'completed').length > 0
                                    ? x.orderList
                                      .filter(y => y.status === 'completed')
                                      .at(-1)!
                                      .route.routeIndex.toString()
                                      .padStart(2, '0')
                                    : x.orderList[0].route.routeIndex.toString().padStart(2, '0'))}
                              </Text>
                              <Text
                                broken={false}
                                styleName="tooltip1"
                                color="RG03"
                                sx={{
                                  width: 'fit-content',
                                  maxWidth: '199px',

                                  display: 'block',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {isLunch
                                  ? '휴게시간'
                                  : processingOrder?.address ??
                                  (x.orderList.filter(y => y.status === 'completed').length > 0
                                    ? x.orderList.filter(y => y.status === 'completed').at(-1)!.address
                                    : x.orderList[0].type === 'break'
                                      ? '휴게시간'
                                      : x.orderList[0].address)}
                              </Text>
                            </Stack>

                            <DriverItemEtaTag spacing={4} direction="row" align="center">
                              <IcTimer width={12} height={12}/>
                              <Text styleName="tooltip1" color="RG03" broken={false}>
                                {dayjs(
                                  isLunch
                                    ? dayjs(lunch?.route.eta).add(lunch?.route?.requiredTime ?? 0, 's')
                                    : processingOrder?.route?.eta ??
                                    (x.orderList.filter(y => y.status === 'completed').length > 0
                                      ? x.orderList.filter(y => y.status === 'completed').at(-1)!.route.eta
                                      : x.orderList[0].route.eta),
                                ).format('HH : mm')}
                              </Text>
                            </DriverItemEtaTag>
                          </Stack>
                        </DriverItem>
                      );
                    })}
                  </Stack>
                </Stack>
              ) : null;
            })}
          </Stack>
        </>
      )}

      {alt.visible && (
        <Alt
          style={{
            top: anchorPoint ? anchorPoint.top - 50 : 0,
            left: anchorPoint ? anchorPoint.left - 3 : 0,
          }}
        >
          {alt.content}
        </Alt>
      )}
    </Stack>
  );
};

export default memo(RouteControlNav);

const DriverColorBox = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  margin: 3px;
  border-radius: 2px;
  background-color: '#449FD2';

  background-color: ${({ color }) => color};
`;

const DriverItemEtaTag = styled(Stack)`
  flex-shrink: 0;

  width: 60px;
  padding: 2px 6px;
  border-radius: 6px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG07,
  })}
`;

const DriverItem = styled(StackStyle)<{ isActive: boolean }>`
  padding: 10px;

  background: ${({ theme, isActive }) => (isActive ? theme.colors.RC03_1 : theme.colors.RG00)};
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.RC03 : theme.colors.RG06)};
  border-radius: 8px;
  cursor: pointer;

  ${DriverItemEtaTag} {
    background-color: ${({ theme, isActive }) => isActive && theme.colors.RC03_3};

    & > svg {
      fill: ${({ theme, isActive }) => (isActive ? theme.colors.RC03 : theme.colors.RG05)};
    }
  }

  &:hover {
    ${DriverItemEtaTag} {
      background-color: ${({ theme }) => theme.colors.RC03_3};

      & > svg {
        fill: ${({ theme }) => theme.colors.RC03};
      }
    }

    background: ${({ theme }) => theme.colors.RC03_1};
  }
`;

const GrayBox = styled.div<{ padding?: string }>`
  display: flex;
  width: 100%;

  padding: ${({ padding }) => (padding ? padding : '10px')};

  border-radius: 8px;
  min-height: 40px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG07,
  })}

  transition: background 0.2s ease-in-out;
`;

const ProgressArea = styled.div`
  position: relative;
  display: flex;
  width: 94px;
  height: 94px;

  justify-content: end;
  align-items: center;
  flex-direction: column;

  flex: 1;
`;

const PerformSituationHistory = styled(StackStyle)`
  cursor: pointer;

  :hover {
    ${GrayBox} {
      background-color: ${({ theme }) => theme.colors.RC03_3};
    }
  }
`;
