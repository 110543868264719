import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from 'api/ky';
import { MASTER_DATA_QUERIES } from 'hooks/query/masterData/masterData.queries';
import { HTTPError } from 'ky';
import { IMasterOrderData } from 'types/masterData/masterOrder';

type FetchMasterOrderListResponse = {
  totalCount: number;
  data: IMasterOrderData[];
};

type GetMasterOrderListParams = {
  searchItem: string;
  keyword: string;
};

type UseQueryGeneric = {
  TQueryFnData: FetchMasterOrderListResponse;
  TError: HTTPError;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof MASTER_DATA_QUERIES.GET_MASTER_ORDER_LIST>;
};

const fetchMasterOrderList = async ({ queryKey: [{ endPoint }] }: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchMasterOrderListResponse>();

const useGetMasterOrderList = <QueryReturnType = UseQueryGeneric['TData']>(
  params: GetMasterOrderListParams,
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    MASTER_DATA_QUERIES.GET_MASTER_ORDER_LIST(params),
    fetchMasterOrderList,
    options
  );

export { fetchMasterOrderList, useGetMasterOrderList };
export type { FetchMasterOrderListResponse, GetMasterOrderListParams };
