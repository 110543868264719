import { ChangeEvent, KeyboardEvent, useState, Dispatch, useEffect } from 'react';
import { useForm, UseFormSetValue, UseFormSetError, UseFormClearErrors, FieldError } from 'react-hook-form';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';
import {
  validateInputPhoneNumber,
  _updatePhoneNumber,
  _resetPhoneNumberInput,
} from 'auth/signUp/corporateTypeForm/container';

import { Text } from 'components/Typography';
import { Input } from 'components/Input';

import { InputLabel } from 'auth/common';
import { removeString } from 'auth/util';

import * as CS from 'auth/style';
import * as S from 'auth/signUp/style';
import { isTruthy } from 'util/isTruthy';

interface IPhoneNumberInput {
  _state: TSignUpState;
  _dispatch: Dispatch<TSignUpAction>;
  setError: UseFormSetError<TPrivateInfoType>;
  clearErrors: UseFormClearErrors<TPrivateInfoType>;
  setPhoneNumber: UseFormSetValue<TPrivateInfoType>;
  successCallback?: Function;
  errors?: FieldError;
}

export const PhoneNumberInput = (props: IPhoneNumberInput) => {
  const {
    _state: {
      signUpForm: { phone },
    },
    setError,
    clearErrors,
    setPhoneNumber,
    successCallback,
    errors,
  } = props;

  const [response, setResponse] = useState<TPhoneNumberStateType>('');

  let defaultValues = { first: '', second: '', third: '' };
  if (phone) {
    const [first, second, third] = phone.split('-');
    defaultValues = { first: first, second: second, third: third };
  }

  const { register, getValues, setValue } = useForm<TPhoneNumberInput>({ defaultValues, mode: 'all' });

  const onChangeHandler = () => {
    const { first, second, third } = getValues();
    const payload = `${first}${second}${third}`;

    if (payload === '') {
      clearErrors('phone');
      setValue('first', '', { shouldValidate: true });
      setValue('second', '', { shouldValidate: true });
      setValue('third', '', { shouldValidate: true });
    } else {
      _updatePhoneNumber({ phone: payload, setPhoneNumber, successCallback });

      !((getValues().second.length === 3 && payload.length === 10) || payload.length === 11)
        ? setError('phone', { message: '휴대폰 번호 형식에 맞게 입력해 주세요.' })
        : clearErrors('phone');
    }
  };

  return (
    <CS.InputLabel>
      <InputLabel label="휴대폰 번호" useMarking={false} />

      <S.businessNumberInput>
        <Input
          maxLength="3"
          name="first"
          type="text"
          variant="fifth"
          register={register}
          hasError={isTruthy(errors)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('first', removeString(event.target.value));
            onChangeHandler();
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              _resetPhoneNumberInput({ setResponse });
            }

            const firstGroup = getValues().first;
            if (firstGroup === null) return;
            if (firstGroup.length === 3) {
              const secondInput = document.querySelector('input[name="second"]') as HTMLElement;
              secondInput.focus();
              validateInputPhoneNumber(getValues, phone!, true);
            }
          }}
        />
        <Text styleName="subheadline2" color="RG04" sx={{ margin: 'auto 6.5px' }}>
          -
        </Text>
        <Input
          maxLength="4"
          name="second"
          type="text"
          variant="fifth"
          register={register}
          hasError={isTruthy(errors)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('second', removeString(event.target.value));
            onChangeHandler();
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              _resetPhoneNumberInput({ setResponse });
            }
            const secondGroup = getValues().second;
            if (secondGroup === null) return;
            if (secondGroup.length === 4) {
              const secondInput = document.querySelector('input[name="third"]') as HTMLElement;
              secondInput.focus();
              validateInputPhoneNumber(getValues, phone!, true);
            }
          }}
        />
        <Text styleName="subheadline2" color="RG04" sx={{ margin: 'auto 6.5px' }}>
          -
        </Text>
        <Input
          name="third"
          type="text"
          maxLength="4"
          variant="fifth"
          hasError={isTruthy(errors)}
          register={register}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue('third', removeString(event.target.value));
            onChangeHandler();
          }}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (['Backspace', 'Delete'].includes(event.key)) {
              _resetPhoneNumberInput({ setResponse });
            }
            const thirdGroup = getValues().third!;
            if (thirdGroup === null) return;
            if (thirdGroup.length === 4) {
              const thirdInput = document.querySelector('input[name="third"]') as HTMLElement;
              thirdInput.blur();
              validateInputPhoneNumber(getValues, phone!, true);
              return;
            }
          }}
        />
        <div style={{ marginLeft: '20px', width: '100%', minWidth: '305px' }} />
      </S.businessNumberInput>

      {isTruthy(errors) && (
        <Text styleName="caption3" color="RC04" sx={{ alignSelf: 'start', marginTop: '10px' }}>
          <ErrorMarking style={{ marginRight: '6px', width: '12px', height: '12px' }} />
          {errors?.message ?? null}
        </Text>
      )}
    </CS.InputLabel>
  );
};
