import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from 'components/Button/legacy_index';
import SkipModal from 'components/Modal/SkipModal';
import { Tag } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';

import { shipment_type } from 'constants/commons';

import { ReactComponent as IcHasLunch } from 'constants/icon/ic_lunchtime.svg';
import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';

import { ReactComponent as IcHub } from 'constants/icon/icon_hub.svg';
import { IControlRouteDriverOrderList, TOrderStatus } from 'constants/types';

import dayjs from 'dayjs';
import {
  createElement,
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import strings from 'util/Localization';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';
import { validateControlOrderStatus } from 'util/validate/validateStatus';

import TableComponent from '.';
import { ReactComponent as IcNarrow } from '../../constants/icon/ic_arrow_downright.svg';
import { ReactComponent as IcWide } from '../../constants/icon/ic_arrow_upleft.svg';
import { TableDeatilButton } from './style';

import IconPalette, { IconMarker } from './util/IconPalette';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';

let routeDriverControlTable = createColumnHelper<IControlRouteDriverOrderList>();

function RouteDriverControlTable({
                                   data,
                                   setTarget,
                                   cardTargetDriverId,
                                   setOrderDetailModalOpen,
                                   refetchControlRouteByIdData,
                                   defaultCheckOrderIds,
                                   onClickTable,
                                   wideMap,
                                   setWideMap,
                                 }: {
  data: IControlRouteDriverOrderList[] | undefined;
  cardTargetDriverId: number;
  setTarget: Dispatch<SetStateAction<number | undefined>>;
  setOrderDetailModalOpen: Dispatch<SetStateAction<boolean>>;
  refetchControlRouteByIdData: Function;
  defaultCheckOrderIds?: Array<number>;
  onClickTable: (order: IControlRouteDriverOrderList) => void;
  wideMap: boolean;
  setWideMap: Dispatch<SetStateAction<boolean>>;
}) {
  let domain = window.localStorage.getItem('serviceDomain');

  const [selectedOrder, setSelectedOrder] = useState<Array<any>>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  function customSetRowSelection(value: any) {
    if (Object.keys(value() ?? {})[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });

      orders = orders
        .filter(y => y.orderId)
        .filter(y => selectedOrder.filter(z => z.orderId === y.orderId).length === 0);
      setSelectedOrder([...selectedOrder, ...orders]);
    } else {
      let orders: Array<any> = selectedOrder;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.orderId !== s.original?.orderId);
      });
      setSelectedOrder(orders);
    }
  }

  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState<boolean>(false);
  const [delTarget, setDelTarget] = useState<Array<number>>([]);
  const [noCancelTrigger, setNoCancelTrigger] = useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({
    sorter: false,
    hover_select: false,
  });
  const [sorting, setSorting] = useState<SortingState>([
    // { id: 'sorter', desc: true }
  ]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const dateCompare = (v: Array<string | undefined>) => {
    const estimated = v[0] && dayjs(v[0]).format('HH:mm');
    const actual = v[1] && dayjs(v[1]).format('HH:mm');

    const gap = estimated && actual ? dayjs(v[0]).diff(v[1], 'm') * -1 : 0;

    return { estimated, actual, gap };
  };
  const timeCompare = (v: Array<number | undefined>) => {
    const estimated = typeof v[0] === 'number' ? Math.round(v[0] / 60) : undefined;
    const actual = typeof v[1] === 'number' ? Math.round(v[1] / 60) : undefined;

    const gap = estimated && actual ? (estimated - actual) * -1 : 0;

    return { estimated: estimated?.toString(), actual: actual?.toString(), gap };
  };

  const CompareCell = ({
                         estimated,
                         actual,
                         gap,
                         decorator,
                         glitter,
                       }: {
    estimated: ReactNode;
    actual: ReactNode;
    gap: number;
    decorator?: string;
    glitter?: ReactNode;
  }) => {
    return (
      <Text
        sx={{ display: 'flex', alignItems: 'center' }}
        styleName="caption2"
        color={actual ? 'RG03' : 'RG02'}
        broken={false}
      >
        {estimated ?? '-'}
        {estimated && decorator && ` ${decorator}`}
        {glitter && (
          <>
            &nbsp;
            {glitter}
          </>
        )}
        <Highlight color="RG02" styleName="caption1">
          &nbsp;/&nbsp;
          {actual ?? '-'}
          {actual && decorator && ` ${decorator}`}
        </Highlight>
        {gap > 0 && <Highlight color="RC04">{` +${gap}`}</Highlight>}
      </Text>
    );
  };

  const columns = useMemo(
    () => [
      routeDriverControlTable.accessor(row => defaultCheckOrderIds?.includes(row?.orderId as number), {
        id: 'sorter',
        header: 'sortingColumn',
        enableSorting: true,
        sortDescFirst: true,
        enableMultiSort: true,
        // sortingFn: (x, y, z) => {
        //   return defaultCheckOrderIds?.includes(Number(x.original?.orderId)) &&
        //     x.getIsSelected() &&
        //     selectedOrder.filter(d => !defaultCheckOrderIds?.includes(d.orderId)).length < 1
        //     ? 1
        //     : 0;
        // },
      }),

      routeDriverControlTable.display({
        id: 'hover_select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked:
                table
                  .getCoreRowModel()
                  .rows.filter(d => d.original?.type === 'order')
                  .every(d => d.getIsSelected() === true) &&
                table.getCoreRowModel().rows.filter(d => d.original?.type === 'order').length > 0,
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),

              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
          />
        ),
        cell: ({ row }) =>
          row.original?.type === 'order' && (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        enableSorting: false,
      }),

      routeDriverControlTable.accessor(row => row.route.routeIndex, {
        id: 'routeIndex',
        header: () => '순서',
        sortingFn: 'basic',
        enableSorting: true,
        enableMultiSort: true,
      }),
      routeDriverControlTable.accessor(row => row.status, {
        id: 'status',
        header: '작업 상태',
        cell: info => {
          if (info.row.original?.type === 'break' || info.row.original?.type === 'end') return null;

          let status = info.getValue();
          if (Boolean(status)) {
            let { key, name } = validateControlOrderStatus(status as TOrderStatus);

            const isHoverSelectRow = info.table
              .getCoreRowModel()
              .rows.find(d => d.id === info.row.id)
              ?.getIsSelected();

            return (
              <>
                <div className="hoverShow">
                  <Tag
                    active={true}
                    styleName="caption1"
                    status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
                    sx={{ width: '56px' }}
                  >
                    {name}
                  </Tag>
                </div>
                <Tag
                  active={isHoverSelectRow}
                  styleName="caption1"
                  status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
                  sx={{ width: '56px' }}
                >
                  {name}
                </Tag>
              </>
            );
            // return status === 'scheduled' || status === 'processing' || status === 'arrived' ? (
            //   <Menu
            //     menuButton={
            //       <div style={{ cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
            //         <div className="hoverShow">
            //           <Tag
            //             active={true}
            //             styleName="caption1"
            //             status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
            //             sx={{ width: '56px' }}
            //           >
            //             {name}
            //           </Tag>
            //         </div>
            //         <Tag
            //           active={isHoverSelectRow}
            //           styleName="caption1"
            //           status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
            //           sx={{ width: '56px' }}
            //         >
            //           {name}
            //         </Tag>
            //       </div>
            //     }
            //     offsetY={10}
            //     menuClassName={'table-menu'}
            //   >
            //     <MenuItem>
            //       <DetailSelectBoxButton
            //         onClick={e => {
            //           e.stopPropagation();

            //           setDelTarget([info.row.original!.orderId as number]);
            //           setDeleteOrderModalOpen(true);
            //         }}
            //       >
            //         보류
            //       </DetailSelectBoxButton>
            //     </MenuItem>
            //   </Menu>
            // ) : (
            //   <>
            //     <div className="hoverShow">
            //       <Tag
            //         active={true}
            //         styleName="caption1"
            //         status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
            //         sx={{ width: '56px' }}
            //       >
            //         {name}
            //       </Tag>
            //     </div>
            //     <Tag
            //       active={isHoverSelectRow}
            //       styleName="caption1"
            //       status={key as 'todo' | 'done' | 'moving' | 'purple' | 'cancel' | 'preparing'}
            //       sx={{ width: '56px' }}
            //     >
            //       {name}
            //     </Tag>
            //   </>
            // );
          } else return null;
        },
        enableSorting: false,
      }),
      routeDriverControlTable.accessor(row => [row.route?.estimatedStartTime, row.route?.actualStartTime], {
        id: 'actualStartTime',
        header: () => (
          <>
            출발 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info => (info.row.original.type === 'break' ? '-' : CompareCell(dateCompare(info.getValue()))),
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) =>
          dayjs(a.original.route?.actualStartTime).isBefore(dayjs(b.original.route?.actualStartTime)) ? -1 : 1,
      }),
      routeDriverControlTable.accessor(row => [row.route?.requiredTime, row.route?.actualTime], {
        id: 'requiredTime',
        header: () => (
          <>
            이동 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info =>
          info.row.original.type === 'break' ? '-' : CompareCell({ ...timeCompare(info.getValue()), decorator: '분' }),
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) => (a.original.route.actualTime || 0) - (b.original.route.actualTime || 0),
      }),
      routeDriverControlTable.accessor(row => [row.route?.eta, row.route?.ata], {
        id: 'eta',
        header: () => (
          <>
            도착 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info => {
          const hasDesired = Boolean(info.row.original.desiredTimeStart || info.row.original.desiredTimeEnd);
          return CompareCell(
            // info.row.original.type === 'break'
            //   ? { estimated: undefined, actual: undefined, gap: 0 }
            //   : {
            //       ...dateCompare(info.getValue()),
            //       glitter: hasDesired ? <IconMarker size={[20, 12.5]} icon={<IconDesiredTime />} /> : null,
            //     }
            {
              ...dateCompare(info.getValue()),
              glitter: hasDesired ? <IconMarker size={[14, 8.75]} icon={<IconDesiredTime/>}/> : null,
            },
          );
        },
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) => (dayjs(a.original.route?.ata).isBefore(dayjs(b.original.route?.ata)) ? -1 : 1),
      }),
      routeDriverControlTable.accessor(row => row?.route.waitingTime, {
        id: 'waitingTime',
        header: () => (
          <>
            유휴 <Highlight color="RG03">(예상)</Highlight>
          </>
        ),
        cell: info => {
          const serviceTime = info.getValue();
          if (!serviceTime) {
            return '-';
          }

          const min = Math.floor(serviceTime / 60);

          return info.row.original.type === 'end' ? '-' : `${min} 분`;
        },
        enableSorting: true,
        enableMultiSort: true,
      }),
      routeDriverControlTable.accessor(row => [row.route?.serviceTime, row.route?.actualServiceTime], {
        id: 'serviceTime',
        header: () => (
          <>
            작업 소요 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info => {
          const prop = timeCompare(info.getValue());

          const type = info.row.original.type;
          const mapper: { [key: string]: ReactNode } = {
            order: CompareCell({ ...prop, decorator: '분' }),
            break: <Text styleName="caption2">{prop.estimated} 분</Text>,
            end: '-',
          };
          return mapper[type];
        },
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) => (a.original.route.actualServiceTime || 0) - (b.original.route.actualServiceTime || 0),
      }),
      routeDriverControlTable.accessor(row => [row.route?.estimatedCompletionTime, row.route?.actualCompletionTime], {
        id: 'completedAt',
        header: () => (
          <>
            완료 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info => CompareCell(dateCompare(info.getValue())),
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) =>
          dayjs(a.original.route?.actualCompletionTime).isBefore(dayjs(b.original.route?.actualCompletionTime))
            ? -1
            : 1,
      }),

      routeDriverControlTable.accessor(row => [row.route?.requiredDistance, row.route?.actualDistance], {
        id: 'distance',
        header: () => (
          <>
            거리 <Highlight color="RG03">(예상/실제)</Highlight>
          </>
        ),
        cell: info => {
          if (info.row.original.type === 'break') return '-';

          return CompareCell({
            estimated: numberWithCommas(roundDecimal(Number((info.getValue()[0] || 0) / 1000), 2))?.replace('.00', '').toLocaleString(),
            actual: info.getValue()[1]
              ? numberWithCommas(roundDecimal(Number((info.getValue()[1] || 0) / 1000), 2))?.replace('.00', '').toLocaleString()
              : info.getValue()[1]?.toLocaleString(),
            gap: 0,
            decorator: 'km',
          });
        },
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) => (a.original.route.actualDistance || 0) - (b.original.route.actualDistance || 0),
      }),

      routeDriverControlTable.accessor(row => row?.shipmentType, {
        id: 'types',
        header: '주문 유형',
        cell: info => {
          const type = info.row.original.type;
          let rowShipmentType = info.getValue();

          const mapper: { [key: string]: ReactElement } = {
            end: <IcHub/>,
            break: <IcHasLunch/>,
          };

          // if (type === 'end') return null;

          return (
            <IconMarker
              size={[20, 12.5]}
              icon={
                type !== 'order' ? (
                  <>{mapper[type]}</>
                ) : (
                  createElement(shipment_type.find(d => d.value === rowShipmentType)?.icon['ff'] ?? 'div', {})
                )
              }
            />
          );
        },
        footer: info => info.column.id,
      }),

      routeDriverControlTable.accessor(row => row?.consigneeName, {
        id: '고객명',
        cell: info => {
          const type = info.row.original.type;
          const mapper: { [key: string]: ReactNode } = {
            order: info.getValue(),
            break: '휴게시간',
            end: '도착지',
          };
          return mapper[type];
        },
        footer: info => info.column.id,
      }),

      routeDriverControlTable.accessor(row => row.address, {
        id: 'address',
        header: () => '주소',
        cell: info => {
          const type = info.row.original.type;
          const mapper: { [key: string]: ReactNode } = {
            order: info.getValue(),
            break: '-',
            end: info.getValue(),
          };
          return mapper[type];
        },
        enableSorting: true,
        enableMultiSort: true,
        footer: info => info.column.id,
      }),
      routeDriverControlTable.accessor(row => row?.detailAddress ?? '-', {
        id: 'detailAddress',
        header: () => '상세주소',
        enableSorting: true,
        enableMultiSort: true,
        footer: info => info.column.id,
      }),
      routeDriverControlTable.display({
        id: 'details',
        header: () => strings.주문상세,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableDeatilButton
              deactive={row.original?.type !== 'order'}
              onClick={() => {
                if (row.original?.type === 'order') {
                  row.original?.orderId && setTarget(row.original.orderId);
                  setOrderDetailModalOpen(true);
                }
              }}
            >
              ︙
            </TableDeatilButton>
          </div>
        ),
      }),
    ],
    [anchorIsOpen, defaultCheckOrderIds, domain, setOrderDetailModalOpen, setTarget],
  );

  const instance = useReactTable({
    data: data?.filter(d => !(d.type === 'end' && !Boolean(d.address))) || [],
    columns,
    state: {
      sorting,
      pagination,
      columnVisibility,
      rowSelection,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: customSetRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    if (defaultCheckOrderIds) {
      // defaultCheckOrderIds.forEach(x =>
      //   instance
      //     .getCoreRowModel()
      //     .rows.filter(d => {
      //       return d.original?.orderId === x;
      //     })
      //     .forEach(d => d.toggleSelected(true))
      // );

      // instance
      //   .getCoreRowModel()
      //   .rows.filter(d => d.original.orderId === defaultCheckOrderIds[0])
      //   ?.forEach(d => d.toggleSelected(true));

      // instance.setSorting([{ id: 'sorter', desc: true }]);

      // temp
      const targetIndex = instance
        .getCoreRowModel()
        .rows.filter(d => {
          return defaultCheckOrderIds.includes(d.original?.orderId ?? -1);
        })
        .at(0)?.index;
      const TargetPageIndex: number = Math.floor((targetIndex ?? -1) / pagination.pageSize);

      setRowSelection({ [targetIndex as number]: true });
      setPagination(prev => {
        return { ...prev, pageIndex: TargetPageIndex > -1 ? TargetPageIndex : prev.pageIndex };
      });
    }
  }, [defaultCheckOrderIds, instance, pagination.pageSize]);

  useEffect(() => {
    if (defaultCheckOrderIds && defaultCheckOrderIds?.length > 0) return;
    else setSelectedOrder([]);
  }, [defaultCheckOrderIds]);

  useEffect(() => {
    let t: any = {};
    selectedOrder.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.orderId === x.orderId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);
  }, [instance, selectedOrder, setRowSelection]);

  useEffect(() => {
    setDeleteOrderModalOpen(false);
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, [cardTargetDriverId]);

  const paletteContent: Array<{ icon: ReactElement; label: string }> = [
    {
      icon: <IcHasLunch/>,
      label: '휴게시간',
    },
    {
      icon: createElement(shipment_type[0].icon.ff, {}),
      label: '배달',
    },
    {
      icon: createElement(shipment_type[1].icon.ff, {}),
      label: '수거',
    },
    {
      icon: <IcHub/>,
      label: '도착지',
    },
  ];

  return (
    <Fragment>
      <TableComponent<IControlRouteDriverOrderList>
        clearBottom
        hoverSelect
        useFullHeight
        table={instance}
        isDataFetching={false}
        sc={[
          <IconPalette
            spacing={10}
            size={[20, 12.5]}
            direction="row"
            icon={paletteContent.map(d => d.icon)}
            label={paletteContent.map(d => d.label)}
          />,
          <Button
            variant={'seventh'}
            type={'button'}
            width={24}
            height={24}
            styleName="caption1"
            color="RG03"
            sx={{ padding: 4 }}
            onClick={e => {
              setWideMap(prev => !prev);
            }}
          >
            {wideMap ? <IcWide width={16} height={16}/> : <IcNarrow width={16} height={16}/>}
          </Button>,
        ]}
        onRowClick={(e, r) => {
          onClickTable(r.original);
        }}
        disableOptions={{
          totalCount: true,
          selectCount: true,
          pagnation: false,
          pageSizeSelector: false,
        }}
        {...{ pagination, setPagination }}
      />

      <SkipModal
        {...{
          isOpen: deleteOrderModalOpen,
          setIsOpen: setDeleteOrderModalOpen,
          target: delTarget,
          setTarget: setDelTarget,
          noCancelTrigger,
          refetcher: refetchControlRouteByIdData,
        }}
      />
    </Fragment>
  );
}

export default RouteDriverControlTable;
