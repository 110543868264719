import React, { Dispatch, SetStateAction } from 'react';

import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import { AlertModal } from 'components/Modal/AlertModal';

const HasOptimizedDriverWarningModal = ({
  isOpen,
  setIsOpen,
  onClickFunc,
}: {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void | Dispatch<SetStateAction<boolean>>;
  onClickFunc: Function;
}) => {
  return (
    <AlertModal
      isOpen={isOpen}
      type={'NONE'}
      messages={['주행중 또는 주행대기 상태인 드라이버가 선택되었습니다.', '배차를 계속 하시겠습니까?']}
      lineSpacing={8}
      buttonGroup={
        <Stack direction="row" spacing={20} sx={{ marginTop: '40px' }}>
          <Button
            variant="tertiary"
            h={40}
            color="RC02"
            w="100%"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            variant="primary"
            h={40}
            color="RG00"
            w="100%"
            onClick={e => {
              setIsOpen(false);
              onClickFunc();
            }}
          >
            확인
          </Button>
        </Stack>
      }
    />
  );
};

export default HasOptimizedDriverWarningModal;
