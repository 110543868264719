import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMyInfo } from '../../../../api';
import { WarningModal } from '../index';
import { Text } from '../../../Typography';
import { Button } from '../../../Button';
import { Stack } from '../../../Stack';
import { useGetPaymentMy } from '../../../../hooks/query/payment/useGetPaymentMy';

export interface PaymentStatusProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const PaymentStatus = ({ isOpen, setIsOpen }: PaymentStatusProps) => {
  const { data: myInfo } = useQuery(['getMyInfo'], getMyInfo);
  const { data: paymentMyData } = useGetPaymentMy();

  if (!myInfo?.roleId || paymentMyData?.paymentStatus !== 'paused') return <></>;

  return (
    <WarningModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      description={
        <>
          <Text styleName="body1" color="RC04">
            회원님의 계정은 현재 일시정지 상태입니다.
          </Text>
          <Text styleName="body2" color="RG02">
            잔액 부족 또는 카드 만료 등의 이유로 인해
          </Text>
          <Text styleName="body2" color="RG02">
            최근 요금이 결제되지 않았습니다.
          </Text>
          {myInfo?.roleId === 1 ? (
            <>
              <Text styleName="body2" color="RG02">
                루티를 계속 이용하시려면 결제를 다시 시도하거나
              </Text>
              <Text styleName="body2" color="RG02">
                결제 정보를 업데이트해 주세요.
              </Text>
            </>
          ) : (
            <Text styleName="body2" color="RG02">
              루티를 계속 이용하시려면 오너에게 결제를 요청해주세요
            </Text>
          )}
        </>
      }
      buttonGroup={
        myInfo?.roleId === 1 ? (
          <Stack spacing={20} sx={{ marginTop: '40px' }}>
            <Button variant="primary" h={40} color="RC02" w="100%">
              결제 재시도
            </Button>
            <Button variant="tertiary" h={40} color="RG00" w="100%">
              결제 정보 업데이트 하기
            </Button>
            <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={() => setIsOpen(false)}>
              닫기
            </Button>
          </Stack>
        ) : (
          <Stack spacing={20} sx={{ marginTop: '40px' }}>
            <Button variant="tertiary" h={40} color="RG00" w="100%" onClick={() => setIsOpen(false)}>
              확인
            </Button>
          </Stack>
        )
      }
    />
  );
};
