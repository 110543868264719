import React, { useEffect, useLayoutEffect } from 'react';
import { useAPIStore } from 'store';
import { useNavigate } from 'react-router-dom';

import { baseAPI } from 'api/instance';
import { TQueryStatusModalSetterProps } from 'constants/types';

import { logout } from 'api';
import { Highlight } from 'components/Typography';
import axios, { AxiosRequestConfig } from 'axios';
import { Stack } from 'components/Stack';

type TRequestCb = (token: string) => void;

let isRefreshing = false;
let failedQueue: TRequestCb[] = [];

const queueTokenRefresh = (cb: TRequestCb) => {
  failedQueue.push(cb);
};

const onRefresh = (token: string) => {
  failedQueue.map(cb => cb(token));
};

const useAxiosInstance = () => {
  const navigate = useNavigate();
  const { SETAPIALERT, CLEARAPIALERT } = useAPIStore();
  const API_DEF_ERRORS: { [key in TErrorCode | 4 | 5]?: TQueryStatusModalSetterProps } = {
    // 사용량 초과 에러: 1120, TrialUsageLimit
    1120: {
      open: true,
      props: {
        status: 'warning',
        string: (
          <>
            <Highlight color="RC04" styleName="body1">
              무료 체험 가능한 배차 차량 대수 (200 대) 를 초과하였습니다.
            </Highlight>
            {`\n사용량은 [ 페이지 상단 > 프로필 ] 에서 확인하실 수 있습니다.`}
          </>
        ),
        action: () => {
          navigate('/manage/route', { replace: true });
          CLEARAPIALERT();
        },
      },
    },
    // 요금제 만료 에러: 1206, ExpiredPlan
    1206: {
      open: true,
      props: {
        status: 'warning',
        string: (
          <>
            <Highlight color="RC04" styleName="body1">
              무료 체험 가능한 기간이 만료되어 이용이 불가합니다.
            </Highlight>
            {`\n사용 문의가 필요하시면 [메인 하단 > 문의하기] 를 이용해 주세요.`}
          </>
        ),
        action: () => {
          CLEARAPIALERT();
          // signout();
          navigate('/mypage', { replace: true, state: { head: 'account' } });
        },
      },
    },
    // 이메일 전송 제한 초과: 1129, RequestLimitForEmail
    1129: {
      open: true,
      props: {
        status: 'warning',
        string: (
          <Stack spacing={8}>
            일일 인증 횟수를 초과하였습니다. 24시간 이후에 다시 시도해 주세요.
            <Highlight color="RC02"> {`이메일 인증 코드 발송은 24시간 이내에 15회 입니다.`}</Highlight>
            <Highlight color="RC02">{`이메일을 정확하게 입력했는지 확인해주세요.`}</Highlight>
          </Stack>
        ),
        action: () => {
          CLEARAPIALERT();
        },
      },
    },

    // 5xx Error
    5: {
      open: true,
      props: {
        status: 'warning',
        string: `오류가 발생했습니다.\n이 오류가 반복되면 관리자에게 문의해 주세요.`,
      },
    },
  };

  useLayoutEffect(() => {
    baseAPI.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem('accessToken');
        if (token) config.headers!['token'] = token;
        return config;
      },
      err => Promise.reject(err)
    );

    baseAPI.interceptors.response.use(
      response => response,
      async error => {
        const originRequest = error.config;

        const { status } = error.response;
        const { number, message, name, advice, statusCode } = error.response.data.error as IErrorResponse;

        if (status === 401 && originRequest.url !== '/auth/signin') {
          const getAccessToken: AxiosRequestConfig = {
            url: `${process.env.REACT_APP_SERVER_URL}/auth/getAccessToken`,
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              token: `${window.localStorage.getItem('refreshToken')}`,
            },
          };

          const _retry_originRequest = new Promise(resolve => {
            queueTokenRefresh((token: string) => {
              if (originRequest.headers) {
                originRequest.headers['token'] = token;
              }
              resolve(axios(originRequest));
            });
          });

          if (!isRefreshing) {
            isRefreshing = true;

            axios(getAccessToken)
              .then(res => {
                const { accessToken } = res.data;
                if (originRequest.headers) {
                  localStorage.setItem('accessToken', accessToken);

                  originRequest.headers!['token'] = accessToken;
                  originRequest._retry = true;

                  onRefresh(accessToken);
                }
              })
              .catch(_error => {
                logout();
                return Promise.reject(_error);
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return _retry_originRequest;
        } else {
          if (error.response.data.error?.name === 'UpgradeNotPossible') alert(error.response.data.error?.advice);
          else if (
            !(
              (error.config.url.includes('/member/update/driver/') &&
                error.response.data.error?.advice === 'Some drivers have activated or processing route.') ||
              (error.config.url.includes('/auth/signin') && error.response.status === 401) ||
              (error.config.url.includes('/area') && advice === '이미 사용 중인 권역 이름입니다.') ||
              error.config.url.includes('/auth/signup/check/') ||
              error.config.url.includes('/auth/check/') ||
              error.config.url.includes('/member/password/check/') ||
              error.config.url.includes('/member/invite/check/') ||
              error.config.url.includes('/member/password/reset/') ||
              error.config.url.includes('/vehicle') ||
              error.config.url.includes('/route/control/') ||
              (error.response.data.error?.advice === '초대 가능한 최대 숫자를 초과하였습니다.' &&
                error.config.url.includes('/member/invite-resend/driver')) ||
              (error.response.data.error?.name === 'DuplicatedUser' && error.config.url.includes('/auth/signup')) ||
              (error.response.data.error?.advice === '주문이 모두 미배차입니다.' &&
                error.config.url.endWith('/route/register'))
            )
          )
            if (API_DEF_ERRORS[number]) SETAPIALERT(API_DEF_ERRORS[number]!);
            else if (status >= 500) SETAPIALERT(API_DEF_ERRORS[5]!);
            else if (status >= 400 && name !== 'NoData' && !error.config.url.startsWith('/team/setting/')) {
              if (name === 'UsageLimit' || name === 'RequestOrderLimit' || name === 'NotActivatedUser')
                return Promise.reject(error);
              alert(message);
            }
        }
        return Promise.reject(error);
      }
    );
    return () => {};
  }, []);

  return {};
};

export default useAxiosInstance;
