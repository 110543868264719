import { DropdownSelector } from 'components/Dropdown/DropdownSelector';
import { Input } from 'components/Input';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcArrowDropDown } from 'constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as IcCompany } from 'constants/icon/ic_company_bg.svg';
import theme from 'constants/theme';
import { BusinessNumberInput } from 'pages/MyPage/Account';
import {
  _putUpdateCompanyInfo,
  inputCompanyName,
  keyConvertor,
  setCompanyContent,
} from 'pages/MyPage/Account/container';
import { MyPageSection } from 'pages/MyPage/common';
import { Dispatch, KeyboardEvent, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { isTruthy } from 'util/isTruthy';

interface IUserInfo {
  _dispatch: Dispatch<TMyPageAccountAction>;
  _state: TMyPageAccountOption;
}

export const CompanyInfo = (props: IUserInfo) => {
  const { userGrade } = useStore();
  const { _state, _dispatch } = props;
  const { companyInfo, industryList } = _state;
  const [isModified, setIsModified] = useState<boolean>(false);
  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TCompanyInfoHookForm>({});

  useEffect(() => {
    setValue('name', companyInfo?.name!);
    setValue('industryTypeId', companyInfo?.industryType.industryTypeId!);
    clearErrors();
  }, [isModified]);

  const COMPANY_DATA_KEY = ['name', 'payment', 'industryType'] as const;

  const ModifiedModeForm: { [key: string]: Function } = {
    name: () => (
      <Input
        removeBtn={() => setValue('name', '')}
        onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
          const { name } = getValues();
          inputCompanyName({ event, setError, clearErrors, name, currentName: companyInfo?.name });
        }}
        onBlur={() => {
          const { name } = getValues();
          inputCompanyName({ setError, clearErrors, name, currentName: companyInfo?.name });
        }}
        variant="fifth"
        register={register}
        name="name"
        hasError={isTruthy(errors.name?.message)}
        errors={errors.name}
      ></Input>
    ),
    payment: () => (
      <BusinessNumberInput
        updateCallback={(businessNumber: string) => {
          setValue('businessNumber', businessNumber);
        }}
        businessNumber={companyInfo?.payment.businessNumber!}
        setError={setError}
        clearErrors={clearErrors}
      />
    ),
    industryType: () => (
      <DropdownSelector
        selectorGap={30}
        sx={{ height: '48px' }}
        anchor="bottom"
        defaultValue={companyInfo!.industryType?.industryTypeId}
        fullWidth
        options={industryList || []}
        showExtra
        onOptionsClick={item => setValue('industryTypeId', item.value)}
        icon={<IcArrowDropDown />}
      />
    ),
  };

  return (
    <MyPageSection
      hasError={[errors.name?.message, errors.businessNumber?.message].some(Boolean)}
      disableModifiedModeButton={userGrade !== 1}
      title="회사 정보"
      icon={<IcCompany width={28} height={28} />}
      isModified={isModified}
      setIsModified={setIsModified}
      callback={() => _putUpdateCompanyInfo({ payload: getValues(), setIsModified, _dispatch })}
    >
      {COMPANY_DATA_KEY.map((key, index: number) => {
        const borderStyle =
          index === COMPANY_DATA_KEY.length - 1 || isModified ? '' : { borderBottom: `1px solid ${theme.colors.RG06}` };
        const content = setCompanyContent({ key, companyInfo, industryList });

        return (
          <Stack key={key} direction="row" style={{ gap: '20px', padding: '20px 20px', ...borderStyle }}>
            <Text styleName="body2" color="RG02" sx={{ minWidth: '176px' }}>
              {keyConvertor(key, 'companyInfo')}
            </Text>
            {isModified ? (
              ModifiedModeForm[key]()
            ) : (
              <Text styleName="body1" color="RG02">
                {content}
              </Text>
            )}
          </Stack>
        );
      })}
    </MyPageSection>
  );
};
