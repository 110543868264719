import { InitialTagProps } from './initialTag.interface';
import { InitialTag } from './style';

export const Tag = ({ text, sx, ...props }: InitialTagProps) => {
  return (
    <InitialTag {...props} style={sx}>
      {text}
    </InitialTag>
  );
};
