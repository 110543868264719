import {
  ColumnOrderState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { AreasCell } from 'components/Table/cell/areas';
import { OperationTypeTag } from 'components/Tag/new/OperationTypeTag';
import { Highlight } from 'components/Typography';
import { common_table_selection } from 'constants/commons';
import cloneDeep from 'lodash.clonedeep';
import StatusTag from 'pages/Setting/common/StatusTag';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  IReportResponses,
  IReportResponsesList,
  IRouteReportDimensionRoute,
  IRouteReportInfoMetrics,
  IRouteReportMetrics,
} from 'types/report/drive';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';
import TableComponent from '.';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from './util/IndeterminateCheckbox';
import TableSelectManager from './util/TableSelectManager';

let routeReportTable =
  createColumnHelper<IReportResponsesList<IRouteReportDimensionRoute, IRouteReportMetrics, IRouteReportInfoMetrics>>();

interface RouteReportTableProps {
  isFetching: boolean;
  reportData: IReportResponses<IRouteReportDimensionRoute, IRouteReportMetrics> | undefined;

  dimensionSelectorOptions: TDragablePickerOptions;
  infoMetricsSelectorOptions?: TDragablePickerOptions;
  metricsSelectorOptions: TDragablePickerOptions;

  rowSelection: {};
  setRowSelection: Dispatch<SetStateAction<{}>>;
}

const RouteReportTable = ({
                            isFetching,
                            reportData,

                            dimensionSelectorOptions,
                            infoMetricsSelectorOptions,
                            metricsSelectorOptions,

                            rowSelection,
                            setRowSelection,
                          }: RouteReportTableProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const columns = useMemo(
    () => [
      routeReportTable.group({
        header: 'dimension',
        id: 'group_inv_dimension',
        columns: [
          routeReportTable.display({
            id: 'select',
            header: ({ table }) => (
              <HeaderIndeterminateCheckbox
                {...{
                  checked: table.getIsAllPageRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllPageRowsSelectedHandler(),

                  anchorIsOpen,
                  setAnchorIsOpen,
                  setAnchorPoint,
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            ),
            enableSorting: false,
          }),
          routeReportTable.accessor(row => row.dimension.route?.routeCode ?? '-', {
            id: 'route_id',
            header: dimensionSelectorOptions.find(d => d.key === 'route_id')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.dimension.team.name ?? '-', {
            id: 'team',
            header: dimensionSelectorOptions.find(d => d.key === 'team')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.dimension.driver?.name ?? '-', {
            id: 'driver',
            header: dimensionSelectorOptions.find(d => d.key === 'driver')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.dimension.performedDate ?? '-', {
            id: 'performedDate',
            header: dimensionSelectorOptions.find(d => d.key === 'performedDate')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.dimension.route?.name ?? '-', {
            id: 'route_name',
            header: dimensionSelectorOptions.find(d => d.key === 'route_name')?.name,
            footer: info => info.column.id,
          }),
        ],
      }),

      routeReportTable.group({
        header: 'infoMetrics',
        id: 'group_infoMetrics',
        columns: infoMetricsSelectorOptions
          ? [
            routeReportTable.accessor(row => row.infoMetrics?.status, {
              id: 'status',
              header: infoMetricsSelectorOptions.find(d => d.key === 'status')?.name,
              cell: info => {
                const value: 'activated' | 'deactivated' | 'deleted' = info.getValue();

                if (value) {
                  const mapper = {
                    activated: <StatusTag styleName="caption1" text="활성"/>,
                    deactivated: <StatusTag styleName="caption1" text="비활성" color="RG04" borderColor="RG04"/>,
                    deleted: <StatusTag styleName="caption1" text="삭제" color="RG04" borderColor="RG04"/>,
                  };
                  return mapper[value];
                } else return '-';
              },
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(row => row.infoMetrics?.phone ?? '-', {
              id: 'phone',
              header: infoMetricsSelectorOptions.find(d => d.key === 'phone')?.name,
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(row => row.infoMetrics?.operationType, {
              id: 'operationType',
              header: infoMetricsSelectorOptions.find(d => d.key === 'operationType')?.name,
              cell: info => (info.getValue() ? <OperationTypeTag type={info.getValue()}/> : '-'),
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(
              row => (row.infoMetrics?.maxCapacity ? numberWithCommas(row.infoMetrics?.maxCapacity) : '-'),
              {
                id: 'maxCapacity',
                header: infoMetricsSelectorOptions.find(d => d.key === 'maxCapacity')?.name,
                footer: info => info.column.id,
              },
            ),
            routeReportTable.accessor(row => row.infoMetrics?.areas, {
              id: 'areas',
              header: infoMetricsSelectorOptions.find(d => d.key === 'areas')?.name,
              cell: info => (info.getValue()?.length ? <AreasCell areas={info.getValue()}/> : '-'),
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(
              row => (row.infoMetrics?.breakDuration ? `${row.infoMetrics?.breakDuration / 60}분` : '-'),
              {
                id: 'breakDuration',
                header: infoMetricsSelectorOptions.find(d => d.key === 'breakDuration')?.name,
                footer: info => info.column.id,
              },
            ),
            routeReportTable.accessor(row => row.infoMetrics?.startAddress ?? '-', {
              id: 'startAddress',
              header: infoMetricsSelectorOptions.find(d => d.key === 'startAddress')?.name,
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(row => row.infoMetrics?.endAddress ?? '-', {
              id: 'endAddress',
              header: infoMetricsSelectorOptions.find(d => d.key === 'endAddress')?.name,
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(row => row.infoMetrics?.skills?.map(skill => skill.name).join(', ') ?? '-', {
              id: 'skills',
              header: infoMetricsSelectorOptions.find(d => d.key === 'skills')?.name,
              footer: info => info.column.id,
            }),
            routeReportTable.accessor(row => row.infoMetrics?.memo ?? '-', {
              id: 'memo',
              header: infoMetricsSelectorOptions.find(d => d.key === 'memo')?.name,
              footer: info => info.column.id,
            }),
          ]
          : [],
      }),

      routeReportTable.group({
        header: 'metrics',
        id: 'group_metrics',
        columns: [
          routeReportTable.accessor(row => row.metrics.estimatedTime, {
            id: 'estimatedTime',
            header: metricsSelectorOptions.find(d => d.key === 'estimatedTime')?.name,
            cell: info => `${Math.round(info.getValue() / 60)} 분`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.actualTime, {
            id: 'actualTime',
            header: metricsSelectorOptions.find(d => d.key === 'actualTime')?.name,
            cell: info => <Highlight color="RC02">{`${Math.round(info.getValue() / 60)} 분`}</Highlight>,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.estimatedWaitingTime, {
            id: 'estimatedWaitingTime',
            header: metricsSelectorOptions.find(d => d.key === 'estimatedWaitingTime')?.name,
            cell: info => `${Math.round(info.getValue() / 60)} 분`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.estimatedDistance, {
            id: 'estimatedDistance',
            // header: metricsSelectorOptions.find(d => d.key === 'estimatedDistance')?.name,
            header: metricsSelectorOptions.find(d => d.key === 'estimatedDistance')?.name,
            cell: info => `${numberWithCommas(roundDecimal(Number(info.getValue() / 1000), 2))} km`.replace('.00', ''),

            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.actualDistance, {
            id: 'actualDistance',
            header: metricsSelectorOptions.find(d => d.key === 'actualDistance')?.name,
            cell: info => (
              <Highlight
                color="RC02">{`${numberWithCommas(roundDecimal(Number(info.getValue() / 1000), 2))} km`.replace('.00', '')}</Highlight>
            ),
            footer: info => info.column.id,
          }),

          routeReportTable.accessor(row => row.metrics.totalServiceTime, {
            id: 'totalServiceTime',
            header: metricsSelectorOptions.find(d => d.key === 'totalServiceTime')?.name,
            cell: info => `${Math.round(info.getValue() / 60)} 분`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.actualServiceTime, {
            id: 'actualServiceTime',
            header: metricsSelectorOptions.find(d => d.key === 'actualServiceTime')?.name,
            cell: info => <Highlight color="RC02">{`${Math.round(info.getValue() / 60)} 분`}</Highlight>,
            footer: info => info.column.id,
          }),

          routeReportTable.accessor(row => row.metrics.totalCapacity, {
            id: 'totalCapacity',
            header: metricsSelectorOptions.find(d => d.key === 'totalCapacity')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.actualCapacity, {
            id: 'actualCapacity',
            header: metricsSelectorOptions.find(d => d.key === 'actualCapacity')?.name,
            cell: info => <Highlight color="RC02">{info.getValue()}</Highlight>,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.totalOrder, {
            id: 'totalOrder',
            header: metricsSelectorOptions.find(d => d.key === 'totalOrder')?.name,
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.completedOrder, {
            id: 'completedOrder',
            header: metricsSelectorOptions.find(d => d.key === 'completedOrder')?.name,
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics.skippedOrder, {
            id: 'skippedOrder',
            header: metricsSelectorOptions.find(d => d.key === 'skippedOrder')?.name,
            cell: info => `${info.getValue()} 개`,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics?.itemQuantity ?? '-', {
            id: 'itemQuantity',
            header: metricsSelectorOptions.find(d => d.key === 'itemQuantity')?.name,
            footer: info => info.column.id,
          }),
          routeReportTable.accessor(row => row.metrics?.routeCount ?? '-', {
            id: 'routeCount',
            header: metricsSelectorOptions.find(d => d.key === 'routeCount')?.name,
            footer: info => info.column.id,
          }),
        ],
      }),
    ],
    [infoMetricsSelectorOptions],
  );

  const instance = useReactTable({
    data: reportData?.routeList ?? [],
    columns,
    state: {
      pagination,
      rowSelection,
      columnVisibility,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    let columnVisible: { [key: string]: boolean } = {};

    const infoMetricsSelected = cloneDeep(infoMetricsSelectorOptions);

    infoMetricsSelected?.forEach(d => {
      columnVisible[`${d.key}`] = infoMetricsSelectorOptions ? d.isSelected : false;
    });

    const combineData = infoMetricsSelected
      ? infoMetricsSelected.concat(metricsSelectorOptions)
      : metricsSelectorOptions;

    const combined = dimensionSelectorOptions.concat(combineData);

    combined.forEach(d => {
      columnVisible[`${d.key}`] = d.isSelected;
    });

    // infoMetricsSelectorOptions가 없으면 해당컬럼 invisible 상태로 변경
    columnVisible[`group_infoMetrics`] = infoMetricsSelectorOptions !== undefined;

    setColumnVisibility(columnVisible);
    setColumnOrder(['select'].concat(combined.map(d => `${d.key}`)));
  }, [dimensionSelectorOptions, infoMetricsSelectorOptions, metricsSelectorOptions]);

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, []);

  return (
    <Fragment>
      <TableComponent
        report
        table={instance}
        isDataFetching={isFetching}
        {...{ pagination, setPagination }}
        placeholder="불러올 주행 기록 리포트가 없습니다."
        clearBottom
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}
    </Fragment>
  );
};

export default RouteReportTable;
