import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcApp } from 'constants/icon/ic_app_setting.svg';
import { ReactComponent as IcData } from 'constants/icon/ic_data.svg';
import { ReactComponent as IcSkill } from 'constants/icon/ic_skill.svg';
import { ReactComponent as IcTruck } from 'constants/icon/ic_truck.svg';

//icons
import { ReactComponent as IcTeam } from 'constants/icon/icon_team.svg';
import { createElement, FunctionComponent, ReactNode, SVGProps } from 'react';
import type { TSettingAttrs } from 'types/setting/union';
// 설정 > 하위 요소들  ( 팀, 매니저, 드라이버, 권역, 드라이버앱 )
type TLayoutProps = {
  children: ReactNode;

  /** setting menu type  */
  attr: TSettingAttrs;

  /** tnd-title  */
  title: ReactNode | string;
  /** tnd-desc */
  desc: ReactNode | string;

  /** tnd-side-button */
  ts?: ReactNode;
};
const Layout = ({ ...props }: TLayoutProps) => {
  const icon_mapper: { [key in TSettingAttrs]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
    team: IcTeam,
    manager: IcTeam,
    driver: IcTruck,
    area: IcTeam,
    app: IcApp,
    masterOrder: IcData,
    multiSkill: IcSkill,
  };

  const ITS = (t: any): boolean => {
    return typeof t === 'string';
  };
  return (
    <Stack className="setting-layout-TnD" align="start" sx={{ padding: '50px 24px 20px 20px' }}>
      <Stack className="setting-layout-title setting-layout-sc" direction="row" justify="space-between">
        <Stack spacing={10} direction="row" sx={{ width: 'max-content' }}>
          {createElement(icon_mapper[props.attr], {
            width: 24,
            height: 24,
          })}
          {ITS(props.title) ? (
            <Text styleName="title1" sx={{ width: 'max-content' }}>
              {props.title}
            </Text>
          ) : (
            props.title
          )}
        </Stack>
        {props?.ts}
      </Stack>
      <Stack className="setting-layout-desc" align="start" sx={{ marginTop: '10px' }}>
        {ITS(props.desc) ? <Text styleName="caption2">{props.desc}</Text> : props.desc}
      </Stack>
      <Stack align="start" sx={{ marginTop: '30px' }}>
        {props.children}
      </Stack>
    </Stack>
  );
};

export default Layout;
