import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLandingActions } from './actions';

import * as S from './style';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { Footer, Header } from 'Layout/elements';

import { Plans } from 'pages/MyPage/UsageAndPlan';
import { useTracking } from '../../hooks/store/useTracking';
import { useMediaQuery } from 'react-responsive';
import heroImg from 'constants/graphics/roouty_landing_hero.png';

const Landing = () => {
  const { pathname, hash, key } = useLocation();
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('TMS메인');
  }, [setTitle]);

  // scroll in to
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const target = document.getElementById(id);
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const isMobile = useMediaQuery({ maxWidth: 700 });
  const { onClicks } = useLandingActions({ device: isMobile ? 'mobile' : 'pc' });

  return (
    <S.Container>
      <Header headerTheme="primary" isMobile={isMobile} />
      <S.HeroContainer>
        <S.HeroWrapper>
          <S.HeroContent name="hero-content-area" align={isMobile ? 'center' : 'start'} spacing={22}>
            <Stack spacing={16} align={isMobile ? 'center' : 'start'} sx={{ width: 'max-content' }}>
              {isMobile ? (
                <>
                  <Text styleName="landing5" color="RG00" align="center">
                    모든 이동을 위한
                    <br />
                    배차 솔루션
                    <br />
                    루티와 시작하세요.
                  </Text>
                  <Text styleName="body1" color="RG00" align="center">
                    ROOUTY는 물류, 배달, 방문 등에
                    <br />
                    필요한 차량 관리 업무를 전반적으로 제어하는
                    <br />
                    AI 기반의 솔루션입니다.
                  </Text>
                </>
              ) : (
                <>
                  <Text styleName="landing1" color="RG00">
                    모든 이동을 위한 배차 솔루션
                    <br />
                    루티와 시작하세요.
                  </Text>
                  <Text styleName="landing2" color="RG00">
                    ROOUTY는 물류, 배달, 방문 등에 필요한 차량 관리 업무를
                    <br />
                    전반적으로 제어하는 AI 기반의 솔루션입니다.
                  </Text>
                </>
              )}
            </Stack>
            <Stack name="button-area" direction={isMobile ? 'column' : 'row'} spacing={20} sx={{ flexWrap: 'wrap' }}>
              <S.Button styleName="body1" width={194} bgColor="#001750" onClick={onClicks['free-trial']}>
                무료로 체험하기
              </S.Button>
              <S.Button
                styleName="body1"
                width={194}
                bgColor="transparent"
                borderColor="RG00"
                onClick={onClicks['contact']}
              >
                사용 문의하기
              </S.Button>
            </Stack>
          </S.HeroContent>
          {isMobile && <img className="mobile_hero_img" src={heroImg} alt="roouty_landing_img" />}
        </S.HeroWrapper>
      </S.HeroContainer>

      <S.DetailContainer2 id={'info'}>
        <Stack spacing={30}>
          <Text styleName={isMobile ? 'headline1' : 'landing1'}>요금제 안내</Text>
          <S.Button
            styleName="body1"
            width={196}
            bgColor="RC02"
            onClick={onClicks['free-trial']}
            children={'무료로 체험하기'}
            style={{
              width: `calc(100% / ${isMobile ? 1 : 2})`,
            }}
          />
          <Text styleName={isMobile ? 'body1' : 'landing3'} align="center">
            {`루티의 다양한 기능을 무료로 경험해보세요.\n최대 20% 까지 운영 비용을 절감할 수 있습니다.`}
          </Text>
          <Plans
            isUsedLanding
            isMobile={isMobile}
            wp={[
              Stack,
              {
                spacing: 20,
                align: 'start',
                justify: 'space-between',
                direction: 'row',

                sx: {
                  height: isMobile ? '526px' : '723px',
                },

                useHorizontalScroll: true,
              },
            ]}
          />
        </Stack>
      </S.DetailContainer2>

      <S.CTAContainer>
        <Stack className="CTA-content-area" spacing={isMobile ? 24 : 40}>
          <Stack spacing={16}>
            {isMobile ? (
              <>
                <Text styleName="headline1" color="RC02" align="center">
                  루티를 무료로 체험해보세요.
                  <br />
                  최대 20% 까지 운영 비용을 절감할 수 있습니다.
                </Text>
                <Text styleName="body1" color="RG01" align="center">
                  루티의 다양한 기능을 무료로 경험해보세요.
                  <br />
                  배송, 출동부터 방문 서비스까지
                  <br />
                  이동에 필요한 모든 기능을 제공합니다.
                </Text>
              </>
            ) : (
              <>
                <Text styleName="landing1" color="RC02" align="center">
                  루티를 무료로 체험해보세요.
                  <br />
                  최대 20% 까지 운영 비용을 절감할 수 있습니다.
                </Text>
                <Text styleName="landing2" color="RG01" align="center">
                  루티의 다양한 기능을 무료로 경험해보세요.
                  <br />
                  배송, 출동부터 방문 서비스까지 이동에 필요한 모든 기능을 제공합니다.
                </Text>
              </>
            )}
          </Stack>
          <Stack
            name="button-area"
            direction={isMobile ? 'column' : 'row'}
            justify="center"
            spacing={isMobile ? 12 : 20}
          >
            <S.Button styleName="body1" width={194} bgColor="RC02" onClick={onClicks['free-trial']}>
              무료로 체험하기
            </S.Button>
            <S.Button
              styleName="body1"
              width={194}
              color="RC02"
              bgColor="RG00"
              borderColor="RC02"
              onClick={onClicks['contact']}
            >
              사용 문의하기
            </S.Button>
          </Stack>
        </Stack>
      </S.CTAContainer>
      <Footer isMobile={isMobile} />
    </S.Container>
  );
};

export default Landing;
