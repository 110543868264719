import cloneDeep from 'lodash.clonedeep';

const managerContentState: TMangerContentState = {
  invitedManagerList: null,
  involvedManagerList: null,
  isOpen: false,
  invitedSelectedList: null,
  involvedSelectedList: null,
  type: '',
  mode: 'Involved',
  response: null,
};

const managerContentReducer = (state: TMangerContentState, action: TMangerContentAction) => {
  const { type, payload } = action;
  const _state = cloneDeep(state);
  switch (type) {
    case 'SWITCH_MODE':
      _state.mode = payload;
      return _state;
    case 'UPDATE_MANGER_LIST': {
      const { mode, list } = payload;
      if (mode === 'Involved') {
        _state.involvedManagerList = list;
      } else {
        _state.invitedManagerList = list;
      }
      return _state;
    }
    case 'UPDATE_MODAL_TYPE':
      const { isOpen, type, response } = payload;
      _state.type = type;
      _state.isOpen = isOpen;
      if (response) _state.response = response;
      return _state;
    case 'UPDATE_SELECTED_LIST':
      const { mode, item } = payload;
      if (mode === 'Involved') {
        _state.involvedSelectedList = item;
      } else {
        _state.invitedSelectedList = item;
      }
      return _state;

    default:
      return _state;
  }
};

export { managerContentState, managerContentReducer };
