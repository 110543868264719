import { ReactComponent as IcArrowDropDown } from '../../constants/icon/ic_arrow_dropdown.svg';
import { IDistrictSelector, TDistrict } from 'pages/Setting/RouteArea';
import { Stack } from 'components/Stack';
import { ReactNode, Dispatch, SetStateAction } from 'react';
import { Text } from 'components/Typography';
import styled from 'styled-components';

const AreaPicker = ({
  display,
  placeholder,
  setter,
  depth,
}: {
  display: string;
  placeholder: ReactNode | string;
  setter: Dispatch<SetStateAction<IDistrictSelector>>;
  depth: TDistrict;
}) => {
  return (
    <AreaSelector
      direction="row"
      justify="space-between"
      onClick={() =>
        setter(prev => {
          let isBeforeDepthSelected = depth === 'sido';
          if (depth === 'sgg' && prev.displayName.sido) {
            isBeforeDepthSelected = true;
          } else if (depth === 'dem' && prev.displayName.sgg) {
            isBeforeDepthSelected = true;
          }
          if (!isBeforeDepthSelected) return prev;
          return { ...prev, isOpen: !prev.isOpen || (prev.isOpen && depth !== prev.depth), depth: depth };
        })
      }
    >
      <div style={{ width: '100%' }}>
        {display !== '' ? (
          <Text styleName="caption1" color="RG02">
            {display}
          </Text>
        ) : (
          placeholder
        )}
      </div>
      <IcArrowDropDown />
    </AreaSelector>
  );
};

export default AreaPicker;

const AreaSelector = styled(Stack)<{ isActive?: boolean }>`
  width: fit-content;
  min-width: 100px;
  height: 32px;

  padding: 6px 10px;
  border-radius: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  ${({ theme }) => ({
    color: theme.colors.RG06,
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
    ':hover': {
      color: theme.colors.RG02,
      backgroundColor: theme.colors.RC03_1,
      border: `1px solid ${theme.colors.RC03}`,
    },
  })}

  transition: all .2s
`;
