import { Dispatch, useMemo } from 'react';
import Modal from 'components/Modal';

import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { AlertModal } from 'components/Modal/AlertModal';
import { getManagerList } from 'pages/Setting/organization/Manager/container';
import { useStore } from 'store';

import { ReactComponent as Warning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as ICEmail } from 'constants/icon/ic_email.svg';
import { ReactComponent as ICBin } from 'constants/icon/ic_bin_with_background.svg';
import { ChangeTeamModal } from 'pages/Setting/organization/Manager/ChangeTeamModal';
import {
  updateModalType,
  _putDeleteManager,
  successModalAlertText,
  _putCancelInvititation,
  initializeManagerList,
  switchMode,
  _putInviteManager,
} from 'pages/Setting/organization/Manager/container';
import { InviteManagerModal } from 'pages/Setting/organization/Manager/inviteManagerModal';

interface IManagerHeaderModals {
  _dispatch: Dispatch<TMangerContentAction>;
  _state: TMangerContentState;
  count: number;
}

function setModalType(params: IManagerHeaderModals & { setIsLoading?: (param: boolean) => void }): TModalReturn {
  const { _state, _dispatch, count, setIsLoading } = params;
  const { type, invitedSelectedList } = _state;
  if (type === 'ReinviteManager' && invitedSelectedList && setIsLoading) {
    return {
      icon: <ICEmail width={54} height={54} />,
      messages: [
        `선택하신 매니저 ${count} 명에게 초대장을 재전송하시겠습니까?`,
        '처음 초대 시 입력한 정보로 초대됩니다.',
      ],
      buttons: (
        <Button
          type="button"
          height={40}
          fullWidth
          variant="MRTB"
          styleName="body1"
          color="RG00"
          sx={{ minWidth: '100%' }}
          // TODO: 초대장 재전송 API 연동
          onClick={() => {
            setIsLoading(true);
            _putInviteManager({ _dispatch, invitedSelectedList, setIsLoading });
          }}
        >
          초대장 재전송
        </Button>
      ),
    };
  }
  if (type === 'CancelInvitation' && invitedSelectedList) {
    return {
      icon: <Warning width={54} height={54} />,
      messages: [
        `선택하신 매니저 ${count} 명의 초대를 취소하시겠습니까?`,
        '초대 취소 이후에도 다시 초대할 수 있습니다.',
      ],
      buttons: (
        <Button
          type="button"
          height={40}
          fullWidth
          variant="MRTB"
          styleName="body1"
          color="RG00"
          sx={{ minWidth: '100%' }}
          onClick={() => _putCancelInvititation({ invitedSelectedList, _dispatch })}
        >
          초대 취소
        </Button>
      ),
    };
  }

  return {
    icon: <ICBin width={54} height={54} />,
    messages: [`선택하신 매니저 ${count} 명을 삭제하시겠습니까?`, '삭제 이후 복구는 불가하며 다시 초대할 수 있습니다.'],
    buttons: (
      <Button
        type="button"
        height={40}
        fullWidth
        variant="MRTB"
        styleName="body1"
        color="RG00"
        sx={{ minWidth: '100%' }}
        // TODO: 매니저 삭제 API 연동
        onClick={() => {
          const list = _state.involvedSelectedList || _state.invitedSelectedList;
          const userList: number[] = list?.map(user => {
            if (_state.mode === 'Involved') {
              const type = user as TInvolvedManagerListState;
              return type.userId;
            }
            const type = user as TInvitationManagerListState;
            return type.invitationId;
          })!;

          _putDeleteManager({ userList, _dispatch });
        }}
      >
        삭제
      </Button>
    ),
  };
}

export const ManagerHeaderModals = (props: Omit<IManagerHeaderModals, 'count'>) => {
  const { _dispatch, _state } = props;
  const { setIsLoading } = useStore();

  const { type, isOpen, invitedSelectedList, involvedSelectedList, mode, response } = _state;
  const count = invitedSelectedList?.length || involvedSelectedList?.length || 0;
  const { icon, messages, buttons } = setModalType({ _state, _dispatch, count, setIsLoading });

  const successAlertText = useMemo(() => {
    return successModalAlertText(type, { count, response });
  }, [type]);

  if (_state.type === 'ChangeTeam') {
    return <ChangeTeamModal {...props} />;
  }

  if (_state.type === 'InviteManager') {
    return <InviteManagerModal isOpen={isOpen} parentDispatch={_dispatch} />;
  }

  if (_state.type === 'PreventToDeleteOwner') {
    return (
      <AlertModal
        type="ERROR"
        isOpen={isOpen}
        messages={['오너인 매니저는 삭제할 수 없습니다.']}
        buttonName="확인"
        callback={() => {
          updateModalType({ type: '', isOpen: false, _dispatch, response: null });
        }}
      />
    );
  }
  if (_state.type === 'ReinviteManagerFailed') {
    return (
      <AlertModal
        type="ERROR"
        isOpen={isOpen}
        messages={['일일 재전송 횟수를 초과하였습니다.', '24시간 이후에 다시 시도해 주세요.']}
        autoCloseTime={3000}
        buttonName="확인"
        callback={() => {
          updateModalType({ type: '', isOpen: false, _dispatch, response: null });
        }}
      />
    );
  }

  if (_state.type === 'TrialModeWarning') {
    return (
      <AlertModal
        type="WARNING"
        isOpen={isOpen}
        messages={['요금제를 구독하시면 매니저를 초대하실 수 있습니다.']}
        buttonName="확인"
        callback={() => {
          updateModalType({ type: '', isOpen: false, _dispatch, response: null });
        }}
      />
    );
  }

  if (_state.type.includes('Success')) {
    const isInviteManagerSuccessType = _state.type === 'InviteManagerSuccess';
    return (
      <AlertModal
        type="SUCCESS"
        isOpen={isOpen}
        messages={successAlertText}
        buttonName="확인"
        autoCloseTime={isInviteManagerSuccessType ? 0 : 3000}
        callback={() => {
          initializeManagerList({ _dispatch, mode, useStrait: true });
          if (mode === 'Involved' && isInviteManagerSuccessType) {
            switchMode({ _dispatch, mode: 'Invited' });
          } else {
            getManagerList({ _dispatch, mode });
          }

          updateModalType({ type: '', isOpen: false, _dispatch, response: null });
        }}
      />
    );
  }

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={() => {}} width={504} plain ds="strong">
      <Stack justify="center" sx={{ padding: '44px 24px 24px' }}>
        {icon && icon}
        <Stack justify="center" sx={{ marginTop: '30px' }}>
          {messages.map((message, index: number) => {
            const lineSpace = index === 0 ? '' : `8px`;
            const textColor = ['CancelInvitation', 'DeleteManager'].includes(type) && index === 0 ? 'RC04' : 'RG02';
            return (
              <Text key={`AlertMessage_${index}`} styleName="body2" color={textColor} sx={{ marginTop: lineSpace }}>
                {message}
              </Text>
            );
          })}
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: '20px', width: '212px', paddingTop: '40px' }} justify="space-around">
          <Button
            type="button"
            height={40}
            fullWidth
            variant="seventh"
            styleName="body1"
            color="RG03"
            sx={{ minWidth: '100%' }}
            onClick={() => updateModalType({ _dispatch, isOpen: false, type: '' })}
          >
            취소
          </Button>
          {buttons}
        </Stack>
      </Stack>
    </Modal>
  );
};
