import styled from 'styled-components';
import { TStyleName } from '../../../constants/types';
import { InitialTagHeight, InitialTagProps } from './initialTag.interface';

type SizeMapper = {
  radius: number;
  padding: number;
  styleName: TStyleName;
  gap: number;
};

const sizeMapper = new Map<InitialTagHeight, SizeMapper>([
  [24, { styleName: 'caption1', padding: 2, radius: 4, gap: 4 }],
  [20, { styleName: 'caption1', padding: 0, radius: 2, gap: 4 }],
  [16, { styleName: 'tooltip3', padding: 0, radius: 2, gap: 4 }],
]);

export const InitialTag = styled.div<Omit<InitialTagProps, 'text'>>`
  display: flex;
  align-items: center;

  padding: 2px 10px;
  border-radius: 4px;

  white-space: nowrap;

  ${({ variant, theme, color }) => ({
    border: `1px solid ${theme.colors[color]}`,
    color: variant === 'primary' ? theme.colors['RG00'] : theme.colors[color],
    backgroundColor: variant === 'primary' ? theme.colors[color] : theme.colors['RG00'],
  })}

  ${({ theme, height }) => theme.fontStyle[sizeMapper.get(height)!.styleName]}

  pointer-events: none;
`;
