import { useQuery } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { getAreaList } from 'api';

import { Stack } from 'components/Stack';
import { Tag } from 'components/Tag/new';
import { Highlight, Text } from 'components/Typography';
import { common_table_selection } from 'constants/commons';
import theme from 'constants/theme';
import { IOptimizeDriverUserList, TOperationType } from 'constants/types';
import { FormValues } from 'pages/RoutePlanSetupPage';

import { PlainTag } from 'pages/Setting/RouteArea';
import { CSSProperties, Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import TableComponent from '..';
import { TFilterRouteStatus } from '../../../pages/RoutePlanSetup/DriverSelect';
import IndeterminateCheckbox, { HeaderIndeterminateCheckbox } from '../util/IndeterminateCheckbox';
import TableSelectManager from '../util/TableSelectManager';

let routePlanSetupDriverSelectTable = createColumnHelper<IOptimizeDriverUserList>();

const DriverSelectTable = ({
  data,
  methods,
  isFetching,
  selectedDrivers,
  setSelectedDrivers,
}: {
  data: Array<IOptimizeDriverUserList>;
  methods: UseFormReturn<FormValues>;
  isFetching: boolean;
  selectedDrivers: Array<{ driverId: number; vehicleId: number }>;
  setSelectedDrivers: Dispatch<SetStateAction<Array<{ driverId: number; vehicleId: number }>>>;
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    // header key : value (boolean)
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const [anchorIsOpen, setAnchorIsOpen] = useState<boolean>(false);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();

  const { data: settedAreaList } = useQuery(['getSettedAreaList'], () => getAreaList({}), {});

  function customSetRowSelection(value: any) {
    if (Object.keys(value())[0]) {
      let orders: Array<any> = [];
      Object.keys(value()).forEach(s => {
        orders = [
          ...orders,
          ...instance
            .getCoreRowModel()
            .flatRows.filter(x => x.id === s)
            .map(x => x.original),
        ];
      });
      orders = orders.filter(y => selectedDrivers.filter(z => z.driverId === y.driverId).length === 0);
      setSelectedDrivers([...selectedDrivers, ...orders]);
    } else {
      let orders: Array<any> = selectedDrivers;
      let selectedList = Object.keys(value(rowSelection));
      let unCheckedItem = instance
        .getSelectedRowModel()
        .flatRows.filter(x => selectedList.filter(d => d === x.id).length === 0);
      unCheckedItem.forEach(s => {
        orders = orders.filter(x => x.driverId !== s.original?.driverId);
      });
      setSelectedDrivers(orders);
    }
  }

  const columns = useMemo(
    () => [
      routePlanSetupDriverSelectTable.display({
        id: 'select',
        header: ({ table }) => (
          <HeaderIndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),

              anchorIsOpen,
              setAnchorIsOpen,
              setAnchorPoint,
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ),
        enableSorting: false,
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.name, {
        id: 'driverName',
        header: '드라이버',
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.routeStatus, {
        id: 'routeStatus',
        header: '주행 상태',
        cell: info => {
          const mapper: {
            [key in Exclude<TFilterRouteStatus | 'scheduled', undefined>]: { string: string; color: TThemeColor };
          } = {
            scheduled: { string: '주행대기', color: 'RC02' }, // fix hot
            activated: { string: '주행대기', color: 'RC02' },
            processing: { string: '주행중', color: 'RC10' },
            completed: { string: '주행종료', color: 'RC11' },
            none: { string: '없음', color: 'RG06' },
          };
          let styleSheet: CSSProperties = { width: 56, justifyContent: 'center' };

          if (info.getValue() === 'none') styleSheet['color'] = theme.colors.RG03;

          return info.getValue() ? (
            <Tag
              text={mapper[info.getValue() as Exclude<TFilterRouteStatus, undefined>].string}
              variant={'primary'}
              height={24}
              color={mapper[info.getValue() as Exclude<TFilterRouteStatus, undefined>].color}
              sx={styleSheet}
            />
          ) : null;
        },
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.completedCount, {
        id: 'completedCount',
        header: '주행종료 건수',
        cell: info => (
          <Text styleName="caption2">
            <Highlight styleName="caption1" color="RC02">
              {info.getValue()}
            </Highlight>
            건
          </Text>
        ),
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.scheduledCount, {
        id: 'scheduledCount',
        header: '주행대기 건수',
        cell: info => (
          <Text styleName="caption2">
            <Highlight styleName="caption1" color="RC02">
              {info.getValue()}
            </Highlight>
            건
          </Text>
        ),
      }),
      routePlanSetupDriverSelectTable.accessor(row => row?.vehicle?.operationType, {
        id: 'operationType',
        header: '운영 유형',
        cell: info => {
          const mapper: { [key in TOperationType]: { string: string; color: TThemeColor } } = {
            backup: { string: '용차', color: 'RC10' },
            regular: { string: '고정차', color: 'RC02' },
          };

          return info.getValue() ? (
            <Tag
              text={mapper[info.getValue() as TOperationType].string}
              variant={'primary'}
              height={24}
              color={mapper[info.getValue() as TOperationType].color}
              sx={{ width: 45, justifyContent: 'center' }}
            />
          ) : null;
        },
      }),
      routePlanSetupDriverSelectTable.accessor(row => (row.vehicle?.maxCapacity ?? 0).toLocaleString(), {
        id: 'maxCapacity',
        header: '최대 용적량',
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.skills?.map(skill => skill.name).join(', '), {
        id: 'skills',
        header: '특수 조건',
      }),
      routePlanSetupDriverSelectTable.accessor(row => row.areas, {
        id: 'areas',
        header: '담당 권역',
        cell: info => {
          const areaNames = info
            .getValue()
            .map(d => settedAreaList?.areaList.find(x => x.areaId === parseInt(d.areaId))?.name);
          const tooLong = areaNames.find(x => x?.length && x.length > 15);

          return (
            <Stack spacing={6} direction="row" sx={{ width: 'max-content' }}>
              {tooLong ? (
                <>
                  <PlainTag> {tooLong}</PlainTag>
                  {areaNames.length - 1 >= 1 && (
                    <Text styleName="caption2" color="RG02">
                      외&nbsp;<Highlight color="RC02">{areaNames.length - 1}</Highlight>&nbsp;권역
                    </Text>
                  )}
                </>
              ) : (
                areaNames.map((x, i) => {
                  return i < 2 ? (
                    <PlainTag>{x}</PlainTag>
                  ) : (
                    areaNames.length - 1 === i && (
                      <Text styleName="caption2" color="RG02">
                        외&nbsp;<Highlight color="RC02">{i - 1}</Highlight>&nbsp;권역
                      </Text>
                    )
                  );
                })
              )}
            </Stack>
          );
        },
      }),
    ],
    [settedAreaList]
  );

  const instance = useReactTable({
    data,
    columns,
    state: {
      pagination,
      rowSelection,
      columnVisibility,
      sorting,
    },
    initialState: {},
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: customSetRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    let t: any = {};
    selectedDrivers.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.driverId === x.driverId);
      filtered.length > 0 && (t[`${filtered[0].id}`] = true);
    });
    setRowSelection(t);

    // console.log('t : ', t);
    // console.log('selectedDrivers : ', selectedDrivers);
    // console.log('CoreRowModel : ', instance.getCoreRowModel());
  }, [instance.getCoreRowModel(), selectedDrivers, setRowSelection]);

  useEffect(() => {
    let fl: Array<{
      driverId: number;
      vehicleId: number;
    }> = [];

    selectedDrivers.forEach(x => {
      let filtered = instance.getCoreRowModel().flatRows.filter(d => d.original?.driverId === x.driverId);
      filtered.length > 0 &&
        fl.push({
          driverId: filtered[0].original.driverId,
          vehicleId: filtered[0].original.vehicle?.vehicleId ?? -33,
        });
    });
    instance.getCoreRowModel().flatRows.length > 0 && setSelectedDrivers(fl);

    return () => {};
  }, [instance.getCoreRowModel()]);

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, pageIndex: 0 };
    });

    return () => {
      setPagination(prev => {
        return { ...prev, pageIndex: 0 };
      });
    };
  }, []);

  return (
    <Fragment>
      <TableComponent
        table={instance}
        isDataFetching={isFetching}
        placeholder={
          <Stack spacing={16}>
            <Text styleName="title2" color="RG04" align="center">
              등록된 드라이버가 없습니다.
              <br />
              [설정 &gt; 드라이버 관리]에서 드라이버를 초대해주세요.
            </Text>
          </Stack>
        }
        onRowClick={(e, row) => {}}
        // disableOptions={{
        //   totalCount: true,
        //   selectCount: true,
        //   pagnation: true,
        //   pageSizeSelector: true,
        // }}
        clearBottom
        tableType="driver"
        {...{ pagination, setPagination }}
      />

      {anchorIsOpen && (
        <TableSelectManager options={common_table_selection} {...{ instance, anchorPoint, setAnchorIsOpen }} />
      )}
    </Fragment>
  );
};

export default DriverSelectTable;
