import { useMutation, useQueryClient } from '@tanstack/react-query';
import { csAlert, isRouteNameDuplicated, registerRoute, runEqualOptimize, runRecalculateOptimize } from 'api';
import { Button } from 'components/Button/legacy_index';

import Divider from 'components/Divider';
import { CapacityTag } from 'components/DriverSummaryInformation';
import GraphicCard from 'components/GraphicCard';
import { Input } from 'components/Input';
import { RoutePlanConfirmMapV2 } from 'components/MapboxContainer/RoutePlanConfirmMap';
import Modal from 'components/Modal';
import OrderDetailModal, { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import QueryStatusModal from 'components/Modal/QueryStatusModal';
import RunOptimizeModal from 'components/Modal/RunOptimizeModal';
import PageContentWrapper from 'components/PageContentWrapper';
import { IconMarker } from 'components/Table/util/IconPalette';
import Timeline from 'components/Timeline';
import { timelineDataParser } from 'components/Timeline/TimelineDataParser';
import { is_temp_units_exceeded, route_origin_session_key } from 'constants/commons';
import UnbatchedSolutions from 'constants/graphics/img_unbatched_solutions.svg';
import { ReactComponent as IcFoldButton } from 'constants/icon/btn_open_hide.svg';
import { ReactComponent as IcError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcPencil } from 'constants/icon/Ic_pencil.svg';
import { ReactComponent as IconDesiredTime } from 'constants/icon/ic_time.svg';
import { ReactComponent as IconTruck } from 'constants/icon/ic_truck.svg';

import theme from 'constants/theme';
import { useSetting } from 'hooks/store/useSetting';
import { usePrompt } from 'hooks/usePrompt';
import clonedeep from 'lodash.clonedeep';
import throttle from 'lodash.throttle';
import {
  ChangeEvent,
  ComponentPropsWithRef,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getTotalReqTimeSameDate } from 'util/getDriverSummary';
import { numberWithCommas } from 'util/numberWithCommas';
import { roundDecimal } from 'util/roundDemical';
import {
  getLastGenIndex,
  getTemporaryOptimizeDataList,
  removeAllTemporaryOptimizeDataList,
} from 'util/sessionStorageManager';
import { Stack } from '../components/Stack';

import { Highlight, Text } from '../components/Typography';
import { ReactComponent as IcClose } from '../constants/icon/ic_close.svg';
import {
  DriverIdUnSelected,
  IEqualOptimizeParams,
  IEqualOptimizeResponsesDriversOrderList,
  IRegisterDriverList,
  ITimelineBlock,
  ITimelineData,
  TDistAreaType,
  TEqualizeBy,
} from '../constants/types';
import { TUTORIAL_QUERIES } from '../hooks/query/tutorial/tutorial.queries';
import { useTracking } from '../hooks/store/useTracking';
import { useGNBStore, useStore } from '../store';
import { ReportFilterContent } from './Report/utils/ReportFilterContent';
import { Topbar } from './RoutePlanConfirm/Topbar';
import { planOptionsSection } from './RoutePlanSetup/PlanOptions';
import { FormValues, keyConverter } from './RoutePlanSetupPage';

type TCallbackIcons = 'war' | 'err';
export type TCallbackConstructor<T> = {
  content: ReactNode;
  callback?: Function;
  icon?: T;
} | null;

export type TMod = 'auto' | 'manual';

export interface MovedOrder {
  id: number;
  targetDriver: number;
  fromDriver: number;
  originDriver: number;
}

export interface ISelectedCellFunctions {
  selectedCell: number[];
  setSelectedCell: Dispatch<SetStateAction<number[]>>;
  selectedCellGroup: string;
  setSelectedCellGroup: Dispatch<SetStateAction<string>>;
}

interface ConfirmFormValues extends Partial<FormValues> {
  name?: string;
}

const ManualModGuide = () => {
  type TManualModGuid = { title: string; content: ReactNode; desc: string };
  const ManualModGuides: Array<TManualModGuid> = [
    {
      title: '업무 시간',
      content: (
        <Stack
          direction="row"
          spacing={6}
          divider={<Divider color="RG06" vertical style={{ height: 12 }} />}
          sx={{
            width: 'fit-content',

            padding: '0 4px',
            borderRadius: 2,
            backgroundColor: '#fff',
            border: '1px solid #D8DDEA',
          }}
        >
          <Text styleName="caption2">
            주문 <Highlight styleName="caption1">100</Highlight> 건
          </Text>

          <Text styleName="caption2">
            <Highlight styleName="caption1">10</Highlight> 시간
            <Highlight styleName="caption1">30</Highlight> 분
          </Text>

          <Text styleName="caption2">
            <Highlight styleName="caption1">100.9</Highlight> km
          </Text>
        </Stack>
      ),
      desc: '드라이버의 경로가 업무 시간보다 길어지지 않게 주의해주세요.',
    },
    {
      title: '차량의 용적량',
      content: (
        <CapacityTag capacity={101}>
          <Text styleName="tooltip1" color="RG00">
            <Highlight styleName="tooltip3">{101}</Highlight> %
          </Text>
        </CapacityTag>
      ),
      desc: '드라이버의 차량 용적량을 초과하지 않게 주의해주세요.',
    },
    {
      title: '담당 드라이버 지정',
      content: <IconMarker size={[24, 15]} icon={<IconTruck fill={theme.colors.RC01} />} />,
      desc: '담당 드라이버가 지정된 주문은 이동 시 주의해주세요.',
    },
    {
      title: '작업희망시간',
      content: <IconMarker size={[24, 15]} icon={<IconDesiredTime />} />,
      desc: '희망시간이 있는 주문의 경우,  희망시간 이전/이후 도착 시간을 주의해주세요.',
    },
    {
      title: '배차 우선순위',
      content: (
        <Stack sx={{ width: 'fit-content' }}>
          <div
            style={{
              width: 6,
              height: 6,
              borderRadius: 3,
              backgroundColor: '#FA1C44',
              border: '1px solid #fff',
            }}
          />
          <Stack
            align="center"
            justify="center"
            sx={{ width: 24, height: 24, backgroundColor: '#449FD2', borderRadius: 4, border: '1px solid #fff' }}
          >
            <Text styleName="caption1" color="RG00">
              1
            </Text>
          </Stack>
        </Stack>
      ),
      desc: '우선순위가 높은 주문은 이동 시 주의해주세요.',
    },
  ];

  const ListContainer = ({ title, content, desc }: TManualModGuid) => {
    return (
      <Stack align="start" spacing={4}>
        <Text styleName="subheadline2" color="RG02" align="start">
          {title}
        </Text>
        {content}
        <Text styleName="subheadline2" color="RC01" align="start">
          {desc}
        </Text>
      </Stack>
    );
  };

  return (
    <Stack
      padding={16}
      spacing={16}
      align="start"
      sx={{
        background: theme.colors.RC03_1,
        borderRadius: 8,
      }}
    >
      <Text styleName="subheadline2" color="RG02" align="start">
        임의 주문순서 변경 시 다음을 고려해주세요.
      </Text>

      {ManualModGuides.map((d, i) => (
        <ListContainer title={`${i + 1}. ${d.title}`} desc={d.desc} content={d.content} />
      ))}
    </Stack>
  );
};

const IcButtonWrapper = styled.button<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;

  cursor: pointer;

  transition: background-color 0.3s;
  ${({ theme, size }) => ({
    width: `${size}px`,
    height: `${size}px`,

    borderRadius: `calc(${size}px / 2)`,

    ':hover': {
      backgroundColor: theme.colors.RC03_3,
    },
  })}
`;

const TempListArea = ({
  children,
  reloadTemporaryOptimizedData,
}: {
  children: ReactNode;
  reloadTemporaryOptimizedData: Function;
}) => {
  const IcButton = ({ icon, size, ...rest }: { icon: ReactNode; size: number } & ComponentPropsWithRef<'button'>) => {
    return (
      <IcButtonWrapper {...rest} {...{ size }}>
        {icon}
      </IcButtonWrapper>
    );
  };
  const { temporaryOptimizeData } = useStore();

  const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!);

  const methods = useForm<{
    name: string;
  }>({
    mode: 'all',
    defaultValues: { name: origin?.name },
  });

  const [nameModifying, setNameModifying] = useState<boolean>(false);
  const [isFold, setIsFold] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);

  const setUnModifying = (reloader?: boolean) => {
    reloader && reloadTemporaryOptimizedData();
    setNameModifying(false);
  };

  const handleClickOutside = (e: any) => {
    if (!parentRef.current?.contains(e.target)) setUnModifying();
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [parentRef]);

  return (
    <Stack align={'start'} sx={{ padding: '24px 20px' }}>
      <Stack
        spacing={20}
        direction="row"
        justify="space-between"
        align="center"
        sx={{ cursor: 'pointer', height: '32px' }}
      >
        {nameModifying ? (
          <Stack spacing={10} direction="row" ref={parentRef}>
            <Input
              name="name"
              placeholder="-"
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              field
              height={32}
              onMouseOver={() => {
                methods.setFocus('name', { shouldSelect: true });
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!Boolean(e.target.value) || e.target.value === '') {
                  methods.setError('name', { type: 'required', message: '' });
                } else
                  isRouteNameDuplicated(e.target.value).then(res => {
                    if (res.isDuplicated) {
                      methods.setError('name', { type: 'duplicatedName', message: '중복된 주행 이름입니다.' });
                    } else methods.clearErrors('name');
                  });
              }}
              validation={{
                required: true,
              }}
              hasError={Boolean(methods.formState.errors?.name)}
            />
            <Button
              type="button"
              variant="default"
              styleName="caption1"
              color="RG00"
              width={76}
              height={32}
              disabled={Boolean(methods.formState.errors.name)}
              onClick={() => {
                // temporaryOptimizeData &&
                //   setTemporaryOptimizeData({ ...temporaryOptimizeData, name: methods.getValues().name });

                sessionStorage.setItem(
                  route_origin_session_key,
                  JSON.stringify({
                    ...origin,
                    name: methods.getValues().name,
                  })
                );
                setUnModifying(Boolean(temporaryOptimizeData));
              }}
            >
              저장하기
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2} direction="row" ref={parentRef}>
            <Text styleName={'body1'} color={'RG02'}>
              {origin?.name}
            </Text>
            <IcButton
              size={24}
              icon={<IcPencil width={20} height={20} />}
              onClick={e => {
                e.preventDefault();
                setNameModifying(true);
                methods.setValue('name', origin?.name ?? '', { shouldValidate: true });
              }}
            />
          </Stack>
        )}
        <Stack spacing={20} direction="row" justify="flex-end">
          <Text
            styleName="caption2"
            color="RG03"
            broken={false}
            sx={{ whiteSpace: 'nowrap', padding: '2px 8px', backgroundColor: theme.colors.RC03_2 }}
          >
            주행일 {origin.performedDate.replaceAll('-', '.')}
          </Text>
          <IcFoldButton
            style={{ transform: isFold ? 'rotate(0.5turn)' : 'rotate(1turn)' }}
            onClick={e => {
              setIsFold(prev => !prev);
            }}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={16}
        sx={{
          marginTop: isFold ? 0 : 24,
          minHeight: 'fit-content',
          transition: 'all .3s ease-out',
        }}
      >
        {!isFold ? children : <div style={{ height: '300px', flex: 1 }} />}
      </Stack>
    </Stack>
  );
};

const RoutePlanConfirm = () => {
  // useLeave(removeAllTemporaryOptimizeDataList);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { SETTINGOPEN } = useGNBStore();
  const { isFatalCorrectionsOnPost, setIsFatalCorrectionsOnPost } = useSetting();

  //removeTemporaryOptimizeDataList
  const { temporaryOptimizeData, loadTemporaryOptimizeData, setTemporaryOptimizeData } = useStore();
  const [temporaryOptimizeKeyList, setTemporaryOptimizeKeyList] = useState(getTemporaryOptimizeDataList());

  const [selectedCell, setSelectedCell] = useState<number[]>([]);
  const [selectedCellGroup, setSelectedCellGroup] = useState<string>('');

  const default_form_values: any = {
    distAreaType: temporaryOptimizeData?.routeOptions.distAreaType,
    equalizeBy: temporaryOptimizeData?.routeOptions.equalizeBy,
  };
  const methods = useForm<any>({
    mode: 'all',
    defaultValues: { ...default_form_values },
  });

  const queryClient = useQueryClient();

  const options_methods = useForm<IEqualOptimizeParams>({
    mode: 'onChange',
    defaultValues: { ...temporaryOptimizeData?.routeOptions },
  });

  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState<boolean>(false);
  const [target, setTarget] = useState<number>();

  const [completedPercent, setCompletedPercent] = useState<number>(0);
  const [runEqualOptimizeProgressModal, setRunEqualOptimizeProgressModal] = useState<boolean>(false);

  const { mutate: mutateRunEqualOptimize, status: mutateRunEqualOptimizeStatus } = useMutation(runEqualOptimize, {
    mutationKey: ['runEqualOptimize'],
    onMutate: data => {
      setCompletedPercent(0);
      setRunEqualOptimizeProgressModal(true);
    },
    onError: (e: any) => {
      if ((e.response?.status as number) >= 500) {
        let confirmAction = window.confirm('에러 정보를 개발팀에 공유?');
        if (confirmAction) {
          csAlert({
            historyId: e?.response?.data?.error?.advice,
            uri: e.response?.config.url,
            type: 'algorithmError',
          });
        } else {
          //
        }
      }
    },
    onSuccess: (res, { props, params }) => {
      const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!);
      setRunEqualOptimizeProgressModal(false);
      setTimeout(() => {
        let key = `${Date.now()}`;
        setTemporaryOptimizeData({
          key: key,
          data: res,
          name: origin?.name ?? '',
          originName: origin?.name ?? '',

          routeOptions: { ...params },
        });

        sessionStorage.setItem(route_origin_session_key, JSON.stringify({ ...props, name: origin.name }));

        setTemporaryOptimizeKeyList(getTemporaryOptimizeDataList());
        navigate(`../confirm/${key}`, { replace: true });
      }, 50);
    },
    onSettled: (data, error, variables, context) => {
      setMibaechas(0);
      setRunEqualOptimizeProgressModal(false);
    },
  });

  const counter = useRef<number>(0);
  // Back to Option
  const [BTOConfirmModalIsOpen, setBTOConfirmModalIsOpen] = useState<boolean>(false);
  const [routeRegisterConfirmModalIsOpen, setRouteRegisterConfirmModalIsOpen] = useState<boolean>(false);
  const [manualModConfirmModalIsOpen, setManualModConfirmModalIsOpen] = useState<boolean>(false);
  const [routeOptionsChangeModalIsOpen, setRouteOptionsChangeModalIsOpen] = useState<boolean>(false);
  const [temporaryOrderDeleteConfirmModalIsOpen, setTemporaryOrderDeleteConfirmModalIsOpen] = useState<boolean>(false);
  const [modChangeWithEditOrdersConfirmModalIsOpen, setModChangeWithEditOrdersConfirmModalIsOpen] =
    useState<boolean>(false);
  const [timelineResetHistoryConfirmModalIsOpen, setTimelineResetHistoryConfirmModalIsOpen] = useState<boolean>(false);
  const [leaveConfirmModalIsOpen, setLeaveConfirmModalIsOpen] = useState<boolean>(true);

  const [temporaryOrderDeleteTargetKey, setTemporaryOrderDeleteTargetKey] = useState<string | null>(null);

  const [timelineWidth, setTimelineWidth] = useState(508);
  const [timelineData, setTimelineData] = useState<ITimelineData>();

  const [selectedDriver, setSelectedDriver] = useState<number>(DriverIdUnSelected);
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(
    leaveConfirmModalIsOpen,
    ['/manage/route/confirm/'],
    true
  );
  const [mibaechas, setMibaechas] = useState<number>(0);
  const [isUnbatchedSolutionsOpen, setIsUnbatchedSolutionsOpen] = useState<boolean>(false);

  const { mutate: mutateRegisterRoute, isLoading: mutateRegisterRouteIsLoading } = useMutation(registerRoute, {
    onSuccess: res => {
      queryClient.invalidateQueries({ queryKey: TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_MONITORING() });

      alert('배차 계획 저장 완료');
      navigate('/manage/control', { replace: true, state: { initPageStatus: 'activated' } });
      confirmNavigation();
    },
    onError: error => {
      alert('배차 가능한 주문이 없습니다');
    },
  });
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('배차계획>자동 최적화 배차>임시결과');
  }, [setTitle]);

  useEffect(() => {
    if (!timelineData || runEqualOptimizeProgressModal) return;

    const n: number = timelineData.columns?.['driver-unbatched'].items?.filter(d => d.type === 'mibaecha').length;

    if (mibaechas === 0) setIsUnbatchedSolutionsOpen(n > 0 ? true : false);
    setMibaechas(n);
  }, [mibaechas, timelineData, runEqualOptimizeProgressModal]);

  const onRegisterRoute = throttle(() => {
    if (mutateRegisterRouteIsLoading || !temporaryOptimizeData) return;

    const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!);
    const reqDrivers: Array<IRegisterDriverList> = temporaryOptimizeData.data.driverList
      .filter(d => d.driverId)
      .map(d => {
        return {
          driverId: d.driverId ?? -33,
          workStartTime: d.workStartTime,
          vehicle: { vehicleId: d.vehicle?.vehicleId ?? -33 },
          orderList: d.orderList.map(d => {
            return {
              orderId: d.orderId,
              type: d.type,
              route: d.route,
            };
          }),
        };
      });

    mutateRegisterRoute({
      name: origin.name,
      performedDate: origin.performedDate,
      performedTime: origin?.performedTime,
      driverList: reqDrivers,
    });
  }, 1000);

  const reloadTemporaryOptimizedData = () => {
    if (params.key)
      loadTemporaryOptimizeData({
        key: params.key,
      });
  };

  const [callbackModalIsOpen, setCallbackModalIsOpen] = useState<boolean>(false);
  const [changeTempListModalIsOpen, setChangeTempListModalIsOpen] = useState<boolean>(false);
  const [changeTempListKey, setChangeTempListKey] = useState<string>('');

  const callback_icon_mapper: { [key in TCallbackIcons]: ReactElement } = {
    war: <IcWarning width={24} height={24} />,
    err: <IcError fill={theme.colors.RC04} width={24} height={24} />,
  };

  const [callback_message_by_type, _callback_message_by_type] = useState<TCallbackConstructor<TCallbackIcons>>(null);

  const [mod, setMod] = useState<TMod>('auto');
  const [maximized, setMaximized] = useState<boolean>(false);
  const { removeTemporaryOptimizeData } = useStore();
  const { mutate: mutateRunRecalculateOptimize } = useMutation(runRecalculateOptimize, {
    mutationKey: ['mutateRunRecalculateOptimize'],
    onMutate: () => {
      setCompletedPercent(0);
      setRunEqualOptimizeProgressModal(true);
    },
    onSuccess: res => {
      setCompletedPercent(100);
      setRunEqualOptimizeProgressModal(false);

      if (temporaryOptimizeData) {
        let beforeTemporaryData = clonedeep(temporaryOptimizeData);
        beforeTemporaryData.data.driverList = beforeTemporaryData.data.driverList.map(d => {
          let changedDriver = res.driverList.filter(x => x.driverId === d.driverId),
            hasChangedDriver = changedDriver.length > 0;

          if (hasChangedDriver && changedDriver[0]) {
            d.orderList = changedDriver[0].orderList;
            d.hasLunch = changedDriver[0].hasLunch;
          }

          return d;
        });

        beforeTemporaryData.data.driverList = beforeTemporaryData.data.driverList.map(d => {
          if (d.driverId === null) {
            let allOrders: IEqualOptimizeResponsesDriversOrderList[] = [];
            temporaryOptimizeData.data.driverList.forEach(n =>
              n.orderList.forEach(m => {
                // delete m.route;
                allOrders.push(m);
              })
            );
            d.orderList = allOrders.filter(z => {
              let isAllocated = false;
              beforeTemporaryData.data.driverList
                .filter(n => n.driverId)
                .forEach(o => {
                  o.orderList.forEach(k => {
                    if (k.orderId === z.orderId) isAllocated = true;
                  });
                });
              return !isAllocated;
            });
          }
          return d;
        });

        let key = `${Date.now()}`;
        setTemporaryOptimizeData({
          key: key,
          name: beforeTemporaryData.originName + `_(${getTemporaryOptimizeDataList().length + 1})`,
          originName: beforeTemporaryData.originName,
          data: beforeTemporaryData.data,

          routeOptions: options_methods.getValues(),
        });

        setTemporaryOptimizeKeyList(getTemporaryOptimizeDataList());
        navigate(`../confirm/${key}`, { replace: true });
      }
    },
    onError: err => console.log(err),
    onSettled: (data, error, variables, context) => setMibaechas(0),
  });

  const [editedDrivers, setEditedDrivers] = useState<
    Array<{
      driverId: number;
      vehicle: {
        vehicleId: number;
      };
      orderList: [
        {
          orderId: number;
          type?: string;
        }
      ];
    }>
  >([]);

  // recalc data setter
  useEffect(() => {
    let resArr: Array<{
      driverId: number;
      vehicle: {
        vehicleId: number;
      };
      orderList: [
        {
          orderId: number;
          type?: string;
        }
      ];
    }> = [];
    Object.keys(timelineData?.columns ?? {}).map(key => {
      const driverId = timelineData?.columns[key].driverId;
      const properties = timelineData?.columns[key].properties;
      const items: any = timelineData?.columns[key].items ?? [];
      const vehicle: any = timelineData?.columns[key].vehicle;
      if (driverId) {
        if ((properties?.editedItems?.length ?? 0) > 0 || properties?.sortChanged || properties?.hiddenEdited) {
          let orderList = items
            .filter(
              (d: ITimelineBlock) =>
                d.type === 'end' || d.type === 'order' || d.type === 'jumsim' || d.type === 'mibaecha'
            )
            .map((d: ITimelineBlock) => {
              let res: any = { type: d.type === 'jumsim' ? 'break' : d.type === 'mibaecha' ? 'order' : d.type };
              if (d.originData?.orderId) res.orderId = d.originData.orderId;
              return res;
            });

          resArr.push({
            driverId,
            vehicle: { vehicleId: vehicle?.vehicleId ?? -33 },
            orderList,
          });
        }
      }
    });

    setEditedDrivers(resArr);
  }, [timelineData]);

  const editedOrders = useMemo(() => {
    let r: string[] = [];
    if (timelineData?.columns) {
      Object.values(timelineData.columns).forEach(col => r.push(...col.properties.editedItems));
    }
    return r;
  }, [timelineData]);

  // MARK : Route 이동시 Temporary 데이터 업데이트 및 State 초기화
  useEffect(() => {
    if (params.key)
      loadTemporaryOptimizeData({
        key: params.key,
      });
    return () => {};
  }, [loadTemporaryOptimizeData, params, location.pathname]);

  useEffect(() => {
    if (temporaryOptimizeData?.data) {
      let parsedData = timelineDataParser(temporaryOptimizeData.data);
      setTimelineData(parsedData);
    }
  }, [temporaryOptimizeData?.data]);

  useEffect(() => {
    if (temporaryOptimizeData?.routeOptions)
      Object.keys(temporaryOptimizeData.routeOptions).forEach(key => {
        options_methods.setValue(
          key as keyof IEqualOptimizeParams,
          temporaryOptimizeData.routeOptions[key as keyof IEqualOptimizeParams] as any,
          {
            shouldValidate: true,
          }
        );
      });
  }, [options_methods, temporaryOptimizeData]);

  const summary = useMemo<
    Array<{
      title: string;
      content: ReactNode;
    }>
  >(() => {
    const edited = editedDrivers.length >= 1;
    const origin = JSON.parse(sessionStorage.getItem(route_origin_session_key)!);
    if (temporaryOptimizeData?.data) {
      const totalTime = temporaryOptimizeData.data.driverList
        .filter(d => d.driverId)
        .reduce(
          (a, b) =>
            a +
            getTotalReqTimeSameDate({
              performedDate: origin.performedDate,
              firstOrderETA: b.orderList.at(0)?.route?.internal?.eta ?? '',
              workStartTime: b.workStartTime,
              lastOrderETA: b.orderList.at(-1)?.route?.internal?.eta ?? '',
            }),
          0
        );
      return [
        {
          title: '총 배차된 주문',
          content: (
            <Text styleName="subheadline1" color="RG02">
              <Highlight color={edited ? 'RG04' : 'RG02'}>
                {temporaryOptimizeData.data.driverList
                  .filter(d => !(d.name === null && d.driverId === null))
                  .reduce((a, b) => a + b.orderList.filter(d => d.type === 'order').length, 0)}
              </Highlight>
              &nbsp;/&nbsp;
              {temporaryOptimizeData.data.driverList.reduce(
                (a, b) => a + b.orderList.filter(d => d.type === 'order').length,
                0
              )}
              <Highlight styleName="tooltip1"> 건</Highlight>
            </Text>
          ),
        },
        {
          title: '총 배차된 드라이버',
          content: (
            <Text styleName="subheadline1" color="RG02">
              <Highlight color={edited ? 'RG04' : 'RG02'}>
                {
                  temporaryOptimizeData.data.driverList.filter(d => d.driverId).filter(d => d.orderList.length > 0)
                    .length
                }
              </Highlight>
              &nbsp;/&nbsp;
              {temporaryOptimizeData.data.driverList.filter(d => d.driverId).length}
              <Highlight styleName="tooltip1"> 명</Highlight>
            </Text>
          ),
        },
        {
          title: '총 예상 작업시간',
          content: (
            <Text styleName="subheadline1" color="RG02">
              {Math.floor(totalTime / 60 / 60) > 0 && (
                <>
                  <Highlight color={edited ? 'RG04' : 'RG02'}>{Math.floor(totalTime / 60 / 60)}</Highlight>
                  <Highlight styleName="tooltip1"> 시간</Highlight>&nbsp;
                </>
              )}

              <Highlight color={edited ? 'RG04' : 'RG02'}> {Math.floor((totalTime / 60) % 60)}</Highlight>
              <Highlight styleName="tooltip1"> 분</Highlight>
            </Text>
          ),
        },

        {
          title: '총 예상 이동 거리',
          content: (
            <Text styleName="subheadline1" color="RG02">
              <Highlight color={edited ? 'RG04' : 'RG02'}>
                {numberWithCommas(
                  roundDecimal(
                    temporaryOptimizeData.data.driverList
                      .filter(d => d.driverId)
                      .reduce(
                        (a, b) => a + b.orderList.reduce((c, d) => c + (d.route?.internal?.requiredDistance ?? 0), 0),
                        0
                      ) / 1000,
                    2
                  )
                )?.replace('.00', '')}
              </Highlight>
              <Highlight styleName="tooltip1"> km</Highlight>
            </Text>
          ),
        },

        {
          title: '총 예상 용적량',
          content: (
            <Text styleName="subheadline1" color="RG02">
              <Highlight color={edited ? 'RG04' : 'RG02'}>
                {isFinite(
                  temporaryOptimizeData.data.driverList
                    .filter(d => d.driverId)
                    .reduce((a, b) => a + b.orderList.reduce((c, d) => c + (d?.capacity ?? 0), 0), 0) /
                    temporaryOptimizeData.data.driverList
                      .filter(d => d.driverId)
                      .reduce((a, b) => a + (b?.vehicle?.maxCapacity ?? 0), 0)
                )
                  ? Math.round(
                      (temporaryOptimizeData.data.driverList
                        .filter(d => d.driverId)
                        .reduce((a, b) => a + b.orderList.reduce((c, d) => c + (d?.capacity ?? 0), 0), 0) /
                        temporaryOptimizeData.data.driverList
                          .filter(d => d.driverId)
                          .reduce((a, b) => a + (b?.vehicle?.maxCapacity ?? 0), 0)) *
                        100
                    )
                  : 0}
              </Highlight>
              <Highlight styleName="tooltip1"> %</Highlight>
            </Text>
          ),
        },
      ];
    } else return [];
  }, [editedDrivers.length, temporaryOptimizeData?.data]);

  if (temporaryOptimizeData)
    return (
      <PageContentWrapper>
        <Stack>
          <Topbar
            {...{
              mod,
              editedOrders,
              editedDrivers,
              options_methods,
              setBTOConfirmModalIsOpen,
              setRouteOptionsChangeModalIsOpen,
              setRouteRegisterConfirmModalIsOpen,
            }}
            recalc={() => {
              mutateRunRecalculateOptimize({
                props: {
                  performedDate: JSON.parse(sessionStorage.getItem(route_origin_session_key) as string).performedDate,
                  performedTime: JSON.parse(sessionStorage.getItem(route_origin_session_key)!)?.performedTime,
                  driverList: editedDrivers,
                },
                manualRoute: mod === 'manual',
              });
            }}
          />
          <Stack name="main-content-area" align={'start'} direction={'row'} sx={{ height: 'calc( 100% - 60px )' }}>
            <Stack
              name="main-content"
              sx={{ minWidth: 720, height: '100%' }}
              divider={<Divider color="RG06" style={{ minHeight: '1px' }} />}
            >
              <TempListArea {...{ reloadTemporaryOptimizedData }}>
                <Stack>
                  <Stack justify="start" direction="row">
                    {Array.from({ length: 5 }, (_, index) => temporaryOptimizeKeyList[index]).map((d, i) => {
                      const sessionInitKey = 'temp_';
                      const hash = location.pathname.split('/').at(-1);

                      const item = JSON.parse(sessionStorage.getItem(temporaryOptimizeKeyList[i]?.key ?? '')!);

                      return (
                        <Tab
                          index={i}
                          origin={item?.genIndex === 0}
                          isEmpty={d === undefined}
                          isActive={temporaryOptimizeKeyList[i]?.key === sessionInitKey + hash}
                          onClick={e => {
                            if (d === undefined || temporaryOptimizeKeyList[i]?.key === sessionInitKey + hash) return;
                            else {
                              const headingBy = `../confirm/${temporaryOptimizeKeyList[i]?.key.replace(
                                sessionInitKey,
                                ''
                              )}`;

                              if (editedDrivers.length > 0) {
                                setChangeTempListKey(headingBy);
                                setChangeTempListModalIsOpen(true);
                              } else {
                                navigate(headingBy, {
                                  replace: true,
                                });
                              }
                            }
                          }}
                        >
                          <Stack className="erind" direction="row" justify="center" spacing={8}>
                            {item?.genIndex === 0 ? `최적화 결과` : `임시결과`}&nbsp; (
                            {typeof item?.genIndex === 'number'
                              ? item.genIndex + 1
                              : sessionStorage.length < 3
                              ? i + 1
                              : i + temporaryOptimizeKeyList.length * -1 + (getLastGenIndex() + 2)}
                            )
                            <Stack
                              className="canceler"
                              justify="center"
                              sx={{ width: 14, height: 14 }}
                              onClick={e => {
                                e.stopPropagation();
                                setTemporaryOrderDeleteTargetKey(temporaryOptimizeKeyList[i].key);
                                setTemporaryOrderDeleteConfirmModalIsOpen(true);
                              }}
                            >
                              <IcClose width={6} height={6} />
                            </Stack>
                          </Stack>
                        </Tab>
                      );
                    })}
                  </Stack>
                  <TabContent>
                    <TabContentSummary>
                      <Stack
                        spacing={10}
                        direction="row"
                        divider={<Divider vertical color="RG06" />}
                        sx={{ height: 40 }}
                      >
                        {summary.map(d => {
                          return (
                            <Stack spacing={6} align="start" sx={{ width: 100 }}>
                              <Text styleName="tooltip1" color="RG02">
                                {d.title}
                              </Text>
                              {d.content}
                            </Stack>
                          );
                        })}
                      </Stack>
                    </TabContentSummary>
                    <TabContentOption>
                      <ReportFilterContent
                        title="배차 옵션"
                        altInfo={`계산 시 선택된 배차 옵션 값입니다.`}
                        anchor="bottom"
                        direction="row"
                        gap={55}
                        styleName="tooltip1"
                      >
                        <Stack spacing={10} justify="start" direction="row">
                          {Object.keys(temporaryOptimizeData.routeOptions)
                            .reverse()
                            .map(key => {
                              const currentOptions =
                                temporaryOptimizeData.routeOptions[key as keyof IEqualOptimizeParams];

                              const OptionskeyConverter = ({ key }: { key: TDistAreaType | TEqualizeBy }): string => {
                                const mapper: { [key in TDistAreaType | TEqualizeBy]: string } = {
                                  none: '권역 없이 배차',
                                  dedicated: '기본 권역 배차',
                                  flexible: '유연한 권역 배차',
                                  worktime: '균등 업무 시간',
                                  workload: '균등 주문 개수',
                                };
                                return mapper[key as TDistAreaType | TEqualizeBy];
                              };

                              return (
                                <OptionsTag disabled={mod === 'manual'}>
                                  {OptionskeyConverter({ key: currentOptions as NonNullable<typeof currentOptions> })}
                                </OptionsTag>
                              );
                            })}
                        </Stack>
                      </ReportFilterContent>
                    </TabContentOption>
                  </TabContent>
                </Stack>
              </TempListArea>

              <Stack name="timeline-area" sx={{ padding: '30px 0px 48px 20px', flex: 1, overflow: 'hidden' }}>
                {timelineData && (
                  <Timeline
                    selectedDriver={selectedDriver}
                    setSelectedDriver={setSelectedDriver}
                    selectedCellFunctions={{ selectedCell, selectedCellGroup, setSelectedCell, setSelectedCellGroup }}
                    width={timelineWidth}
                    driverData={temporaryOptimizeData.data.driverList}
                    timelineData={timelineData}
                    setTimelineData={setTimelineData}
                    maximized={maximized}
                    setMaxmized={setMaximized}
                    showDetail={(orderId: number) => {
                      setTarget(orderId);
                      setOrderDetailModalOpen(true);
                    }}
                    resetHistory={(e, callback = () => {}) => {
                      _callback_message_by_type({
                        icon: 'war',
                        callback,
                        content: (
                          <>
                            초기화를 누르면 주문 이동 내역이 삭제되며
                            <br />
                            삭제된 내역은 복구할 수 없습니다.
                          </>
                        ),
                      });
                      setTimelineResetHistoryConfirmModalIsOpen(true);
                    }}
                    callbackConstructor={(e, content) => {
                      if (content) {
                        _callback_message_by_type({
                          ...content,
                        });
                        setCallbackModalIsOpen(true);
                      }
                    }}
                    {...{
                      mod,
                      setMod: (v: TMod) => {
                        if (editedDrivers.length > 0) setModChangeWithEditOrdersConfirmModalIsOpen(true);
                        else if (v === 'manual') setManualModConfirmModalIsOpen(true);
                        else setMod(v);
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>

            {!maximized && (
              <Stack sx={{ minWidth: '1px', height: '100%' }}>
                <RoutePlanConfirmMapV2
                  mod={mod}
                  data={temporaryOptimizeData?.data}
                  timelineData={timelineData}
                  showDetail={(orderId: number) => {
                    setTarget(orderId);
                    setOrderDetailModalOpen(true);
                  }}
                  setSelectedCellGroup={setSelectedCellGroup}
                  selectedDriver={selectedDriver}
                  setSelectedDriver={setSelectedDriver}
                  selectedOrder={selectedCell}
                  setSelectedOrder={setSelectedCell}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        {target && (
          <OrderDetailModal
            {...{
              target,
              noModi: true,
              isOpen: orderDetailModalOpen,
              setIsOpen: setOrderDetailModalOpen,
            }}
          />
        )}

        <Modal
          plain
          isModalOpen={routeOptionsChangeModalIsOpen}
          setIsModalOpen={setRouteOptionsChangeModalIsOpen}
          width={476}
        >
          <Stack divider={<Divider color="RG06" />} sx={{ overflow: 'hidden' }}>
            <Stack name="header" direction="row" justify="space-between" spacing={16} padding={24}>
              <Text styleName="subheadline2" color="RG04">
                배차 옵션 설정 변경
              </Text>
              <button
                onClick={() => {
                  setRouteOptionsChangeModalIsOpen(false);
                }}
                style={{ width: 24, height: 24, background: 'none', cursor: 'pointer' }}
              >
                <IcClose width={10} height={10} />
              </button>
            </Stack>
            <Stack useVerticalScroll sx={{ flex: 1 }}>
              {planOptionsSection
                .filter(d => d.options)
                .map(d => {
                  return (
                    <Stack spacing={20} padding={24}>
                      <Stack spacing={6} align="start">
                        <Text styleName="body1" color="RG02">
                          {d.title}
                        </Text>
                        <Text styleName="caption2" color="RG03">
                          {d.desc}
                        </Text>
                      </Stack>

                      <Stack spacing={16} align="start" direction="row">
                        {d.options?.map(option => {
                          return (
                            <GraphicCard
                              key={`${d.key}-${option.key}-graphic-card`}
                              graphic={option.graphic}
                              onClick={e => {
                                if (options_methods.watch(d.key as keyof IEqualOptimizeParams) === option.key) {
                                  return;
                                  // methods.setValue('distAreaType', undefined, { shouldDirty: true });
                                } else
                                  options_methods.setValue(d.key as keyof IEqualOptimizeParams, option.key, {
                                    shouldDirty: true,
                                  });
                              }}
                              spacing={8}
                              check
                              isActive={options_methods.watch(d.key as keyof IEqualOptimizeParams) === option.key}
                            >
                              {option.body}
                            </GraphicCard>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
            </Stack>
            <Stack name="footer" direction="row" justify="end" spacing={16} padding={24}>
              <Button
                type="button"
                variant="seventh"
                width={102}
                height={40}
                styleName="body2"
                color="RG03"
                onClick={() => {
                  setRouteOptionsChangeModalIsOpen(false);
                }}
              >
                닫기
              </Button>
              <Button
                type="button"
                variant="default"
                width={216}
                height={40}
                styleName="body1"
                color="RG00"
                disabled={
                  JSON.stringify(temporaryOptimizeData.routeOptions) === JSON.stringify(options_methods.getValues()) ||
                  is_temp_units_exceeded()
                }
                onClick={options_methods.handleSubmit(res => {
                  for (let i = 0; i < sessionStorage.length; i++) {
                    let key = sessionStorage.key(i);
                    if (key && key.startsWith('temp_') && !JSON.parse(sessionStorage.getItem(key)!).disable) {
                      const target = JSON.parse(sessionStorage.getItem(key) as string);

                      if (JSON.stringify(target?.routeOptions) === JSON.stringify(res)) {
                        _callback_message_by_type({
                          icon: 'war',
                          content: (
                            <>
                              선택한 배차 옵션은 이미 다른 임시결과에 존재합니다.
                              <br />
                              다른 옵션을 선택해주세요.
                            </>
                          ),
                        });
                        setCallbackModalIsOpen(true);

                        return;
                      }
                    }
                  }

                  // 겹치는 설정 없을 때
                  mutateRunEqualOptimize({
                    props: {
                      performedDate: JSON.parse(sessionStorage.getItem(route_origin_session_key)!).performedDate,
                      performedTime: JSON.parse(sessionStorage.getItem(route_origin_session_key)!).performedTime,
                      workEndTime: JSON.parse(sessionStorage.getItem(route_origin_session_key)!).workEndTime,
                      driverList: JSON.parse(sessionStorage.getItem(route_origin_session_key)!).driverList,
                      orderList: JSON.parse(sessionStorage.getItem(route_origin_session_key)!).orderList,
                    },
                    params: { ...options_methods.getValues() },
                    config: {},
                  });

                  setRouteOptionsChangeModalIsOpen(false);
                })}
              >
                재계산
              </Button>
            </Stack>
          </Stack>
        </Modal>

        <RunOptimizeModal
          flag={mutateRunEqualOptimizeStatus === 'success' || mutateRunEqualOptimizeStatus === 'error'}
          weight={JSON.parse(sessionStorage.getItem(route_origin_session_key) as string)?.orderList?.length ?? 0}
          {...{
            info: {
              name: temporaryOptimizeData?.name,
              date: JSON.parse(sessionStorage.getItem(route_origin_session_key) as string)?.performedDate,
              time: '',
              totalDriver: `${
                JSON.parse(sessionStorage.getItem(route_origin_session_key) as string)?.driverList?.length
              } 명`,
              totalOrder: `${
                JSON.parse(sessionStorage.getItem(route_origin_session_key) as string)?.orderList?.length
              } 개`,
              distAreaType: keyConverter({ key: options_methods.getValues('distAreaType') as TDistAreaType }),
              equalizeBy: keyConverter({ key: options_methods.getValues('equalizeBy') as TEqualizeBy }),
            },

            completedPercent,
            setCompletedPercent,
            runEqualOptimizeProgressModal,
            setRunEqualOptimizeProgressModal,
          }}
        />

        <CloseConfirmModal
          isOpen={changeTempListModalIsOpen}
          setIsOpen={setChangeTempListModalIsOpen}
          text={
            <Stack spacing={30}>
              {callback_icon_mapper['war']}
              <Stack spacing={0}>
                <Text styleName="subheadline2" color="RC04">
                  주문 순서 변경을 취소하시겠습니까?
                </Text>
                <Text styleName="subheadline2" color="RG02">
                  취소 이후 주문 순서 변경 내용이 삭제되며 되돌릴 수 없습니다.
                </Text>
              </Stack>
            </Stack>
          }
          RBT={'네'}
          LBT={'아니오'}
          btnFunctions={{
            RBT: () => {
              setChangeTempListModalIsOpen(false);
              navigate(changeTempListKey, {
                replace: true,
              });
            },
          }}
        />

        <Modal
          isModalOpen={callbackModalIsOpen}
          setIsModalOpen={setCallbackModalIsOpen}
          padding={24}
          width={503}
          closeCallbackFunc={() => {
            _callback_message_by_type(null);
          }}
        >
          <Stack>
            <Stack>
              {callback_message_by_type?.icon && callback_icon_mapper[callback_message_by_type.icon]}
              <Text
                styleName="subheadline2"
                color="RG02"
                sx={{
                  padding: '30px 0 40px 0',
                }}
              >
                {callback_message_by_type?.content}
              </Text>
            </Stack>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              styleName="body2"
              color="RG03"
              onClick={() => {
                if (callback_message_by_type?.callback) callback_message_by_type.callback();
                _callback_message_by_type(null);
                setCallbackModalIsOpen(false);
              }}
              sx={{ padding: '8px 10px' }}
            >
              확인
            </Button>
          </Stack>
        </Modal>
        <CloseConfirmModal
          isOpen={manualModConfirmModalIsOpen}
          setIsOpen={setManualModConfirmModalIsOpen}
          text={
            <Stack spacing={30}>
              <Stack spacing={10}>
                <Text styleName="title2" color="RG02">
                  임의 주문순서 변경을 시작하시겠습니까?
                </Text>
                <Text styleName="subheadline2" color="RC04">
                  임의 주문 순서 변경 시, 배차옵션을 고려한
                  <br />
                  최적화 엔진이 작동하지 않습니다.
                </Text>
              </Stack>
              <Stack sx={{ maxHeight: 'calc(100vh - 400px)' }} useVerticalScroll>
                <ManualModGuide />
              </Stack>
            </Stack>
          }
          RBT={'확인'}
          LBT={'취소'}
          btnFunctions={{
            RBT: () => setMod('manual'),
          }}
        />
        <CloseConfirmModal
          width={504}
          ms={0}
          isOpen={BTOConfirmModalIsOpen}
          setIsOpen={setBTOConfirmModalIsOpen}
          text={
            <Stack spacing={10}>
              <Text styleName="title2" color="RG02">
                배차 설정을 다시 하시겠습니까?
                <br />
                설정으로 돌아가면 배차결과가 삭제됩니다.
              </Text>
              <Text styleName="subheadline2" color="RC01">
                배차결과를 저장하고 싶다면,
                <br />
                우측상단의 '배차 계획 저장' 버튼을 눌러주세요
              </Text>
            </Stack>
          }
          RBT={'확인'}
          LBT={'취소'}
          btnFunctions={{
            RBT: () => {
              navigate('/manage/route/optimize', { replace: true });
              confirmNavigation();
            },
          }}
        />
        <CloseConfirmModal
          icon={<IcWarning width={24} height={24} />}
          width={504}
          ms={0}
          isOpen={timelineResetHistoryConfirmModalIsOpen}
          setIsOpen={setTimelineResetHistoryConfirmModalIsOpen}
          text={
            <Text styleName="subheadline2" color="RG02">
              {callback_message_by_type?.content}
            </Text>
          }
          RBT={'확인'}
          LBT={'취소'}
          btnFunctions={{
            RBT: () => {
              if (callback_message_by_type?.callback) callback_message_by_type?.callback();
            },
          }}
        />
        <CloseConfirmModal
          width={504}
          ms={0}
          isOpen={routeRegisterConfirmModalIsOpen}
          setIsOpen={setRouteRegisterConfirmModalIsOpen}
          text={
            <Stack spacing={10}>
              <Text styleName="title2">
                배차 계획을 저장하고
                <br />
                드라이버에게 전달하시겠습니까?
              </Text>

              <Text styleName="subheadline2">
                드라이버에게 배차 계획을 전달한 후
                <br />
                모니터링 의 '주행대기'에서 배차 취소가 가능합니다.
              </Text>
            </Stack>
          }
          RBT={'드라이버에게 전달하기'}
          LBT={'돌아가기'}
          btnFunctions={{
            RBT: () => {
              onRegisterRoute();
            },
          }}
        />
        <CloseConfirmModal
          icon={<IcError fill={theme.colors.RC04} width={24} height={24} />}
          width={504}
          ms={0}
          isOpen={temporaryOrderDeleteConfirmModalIsOpen}
          setIsOpen={setTemporaryOrderDeleteConfirmModalIsOpen}
          text={
            <Stack spacing={10}>
              <Text styleName="subheadline2">
                임시결과&nbsp;(
                {temporaryOrderDeleteTargetKey
                  ? JSON.parse(sessionStorage.getItem(temporaryOrderDeleteTargetKey)!).genIndex + 1
                  : 'n'}
                )&nbsp;를 삭제하시겠습니까?
                <br />
                <Highlight color={'RC04'}>삭제한 임시결과는 되돌릴 수 없으니 주의해주세요.</Highlight>
              </Text>
            </Stack>
          }
          RBT={'삭제'}
          LBT={'취소'}
          btnFunctions={{
            RBT: () => {
              if (temporaryOrderDeleteTargetKey === null) return;

              const item = JSON.parse(sessionStorage.getItem(temporaryOrderDeleteTargetKey)!);

              setTemporaryOptimizeData({
                ...item,
                disable: true,
              });

              setTemporaryOrderDeleteTargetKey(null);
              setTemporaryOptimizeKeyList(getTemporaryOptimizeDataList());
              navigate(`../confirm/${getTemporaryOptimizeDataList().at(0)?.key.replace('temp_', '')}`, {
                replace: true,
              });
            },
          }}
        />

        <CloseConfirmModal
          icon={<IcWarning width={24} height={24} />}
          width={504}
          ms={0}
          isOpen={modChangeWithEditOrdersConfirmModalIsOpen}
          setIsOpen={setModChangeWithEditOrdersConfirmModalIsOpen}
          text={
            <Stack spacing={10}>
              <Text styleName="subheadline2">
                <Highlight color={'RC04'}> 주문 순서 변경을 취소하시겠습니까?</Highlight>
                <br />
                취소 이후 주문 순서 변경 내용이 삭제되며 되돌릴 수 없습니다.
              </Text>
            </Stack>
          }
          RBT={'네'}
          LBT={'아니오'}
          btnFunctions={{
            RBT: () => {
              const mapper: { [key in TMod]: TMod } = {
                auto: 'manual',
                manual: 'auto',
              };
              setMod(mapper[mod]);

              let parsedData = timelineDataParser(temporaryOptimizeData.data);
              setTimelineData(parsedData);
            },
          }}
        />

        <CloseConfirmModal
          width={504}
          ms={0}
          isOpen={showPrompt}
          setIsOpen={() => {}}
          text={
            <Stack spacing={10}>
              <Text styleName="title2">배차를 확정을 하지 않고 나가시겠습니까?</Text>
              <Text styleName="subheadline2">
                <Highlight color={'RC04'}> 페이지를 벗어나면 배차 계획은 저장되지 않습니다.</Highlight>
                <br />
                이후 주문들은 ‘미배차' 상태로 유지됩니다.
              </Text>
            </Stack>
          }
          RBT={'확인'}
          LBT={'취소'}
          closeCallback={() => {
            cancelNavigation();
          }}
          btnFunctions={{
            RBT: () => {
              removeTemporaryOptimizeData();
              removeAllTemporaryOptimizeDataList();
              confirmNavigation();
            },
            LBT: () => {
              cancelNavigation();
            },
          }}
        />

        <QueryStatusModal
          isOpen={isUnbatchedSolutionsOpen}
          setIsOpen={setIsUnbatchedSolutionsOpen}
          status={'warning'}
          string={
            <Stack spacing={40}>
              <Text styleName="body2">{`미배차 주문이 있습니다.\n미배차는 아래와 같은 사유 등으로 인하여 발생합니다.\n자세한 설명은 이용안내를 참고해 주세요.`}</Text>
              <ImageWrapper useVerticalScroll>
                <img src={UnbatchedSolutions} alt="UnbatchedSolutions" />
              </ImageWrapper>
            </Stack>
          }
        />

        <QueryStatusModal
          isOpen={!SETTINGOPEN && isFatalCorrectionsOnPost}
          setIsOpen={setIsFatalCorrectionsOnPost}
          status={'warning'}
          string={
            <Stack spacing={8}>
              <Text styleName="body2">설정이 변경 되었습니다.</Text>
              <Text styleName="body2">변경된 정보로 다시 배차를 진행하기 위해 배차 설정으로 돌아갑니다.</Text>
            </Stack>
          }
          action={() => {
            navigate('/manage/route/optimize', { replace: true, state: { index: [0, 2] } });
            confirmNavigation();
            queryClient.refetchQueries({ queryKey: ['drivers'], type: 'active' });
          }}
        />
      </PageContentWrapper>
    );
  else return <></>;
};

export default RoutePlanConfirm;

const Tab = styled.div<{ origin?: boolean; isActive?: boolean; isEmpty: boolean; index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 124px;
  height: 32px;
  border-radius: 8px 8px 0px 0px;

  bottom: -1px;

  ${({ index }) => ({
    ':nth-child(n+0)': {
      left: `${index * -1}px`,
    },
  })}

  ${({ isActive, isEmpty, origin, theme }) => {
    const styles = isActive
      ? {
          zIndex: 2,
          border: `1px solid ${theme.colors.RC03}`,
          backgroundColor: theme.colors[origin ? 'RC03_2' : 'RG00'],

          '&>.erind': {
            color: theme.colors.RC02,
          },
        }
      : {
          zIndex: 1,
          border: `1px solid ${theme.colors.RG06}`,
          backgroundColor: theme.colors[isEmpty ? 'RG08' : 'RG00'],

          '&>.erind': {
            color: theme.colors.RG04,
          },
        };

    return { ...styles, cursor: isEmpty ? 'not-allowed' : 'pointer' };
  }};

  & > .erind {
    ${({ theme }) => theme.fontStyle.caption1}
    & > .canceler {
      display: none;
      border-radius: 2px;
      ${({ theme }) => ({
        backgroundColor: theme.colors.RG08,
      })}
    }
  }

  ${({ isEmpty, origin }) => ({
    ':hover': !isEmpty
      ? {
          border: `1px solid ${theme.colors.RC03}`,
          '&>.erind': {
            '&>.canceler': {
              display: !origin ? 'flex' : 'none',
            },
          },
        }
      : {},
  })}
`;

const TabContentSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RC03_2,
  })}
`;
const TabContentOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
  })}
`;
const TabContent = styled(Stack)`
  ${TabContentSummary} {
    padding: 20px 16px;
    border-radius: 0 8px 0 0;
  }

  ${TabContentOption} {
    padding: 10px 16px;
    border-radius: 0 0 8px 8px;
  }

  z-index: 3;
  border-radius: 0 8px 8px 8px;

  & > ${TabContentSummary}, ${TabContentOption} {
    width: 100%;
  }

  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RC03}`,
  })}
`;

const OptionsTag = styled(Stack)<{ disabled?: boolean }>`
  align-items: center;
  justify-content: center;

  width: max-content;
  height: 20px;

  padding: 2px 10px;
  border-radius: 4px;
  ${({ theme, disabled }) => ({
    color: theme.colors[disabled ? 'RG05' : 'RC02'],
    backgroundColor: theme.colors[disabled ? 'RG07' : 'RC03_1'],
    border: `1px solid ${theme.colors[disabled ? 'RG06' : 'RC03_3']}`,
  })}

  ${({ theme }) => theme.fontStyle.tooltip3}
`;

const ImageWrapper = styled(Stack)`
  height: 100%;
  @media screen and (max-height: 900px) {
    height: 350px;
  }
  @media screen and (max-height: 800px) {
    height: 300px;
  }
  @media screen and (max-height: 750px) {
    height: 250px;
  }
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 680px) {
    height: 150px;
  }
  @media screen and (max-height: 600px) {
    height: 130px;
  }
  @media screen and (max-height: 570px) {
    height: 64px;
  }
`;
