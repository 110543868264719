import { Fragment, ReactNode, CSSProperties, ReactElement, ComponentPropsWithRef, forwardRef } from 'react';
import * as S from './style';

export interface CardProps extends ComponentPropsWithRef<'div'> {
  children: ReactNode;
  variant: 'menu' | 'popup';
  ds?: 'weak' | 'normal' | 'strong';

  width: number | undefined;
  padding: number;

  sx?: CSSProperties;
}

const MappedStyled = {
  menu: S.MenuCardOutline,
  popup: S.PopupCardOutline,
};

export const Card = forwardRef<any, CardProps>(({ sx, children, ...props }, ref): ReactElement => {
  const MappedStyledComponent = MappedStyled[props.variant];

  if (Object.keys(MappedStyled).includes(props.variant))
    return (
      <MappedStyledComponent ref={ref} style={sx} {...props}>
        {children}
      </MappedStyledComponent>
    );
  else return <Fragment />;
});
