import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { BoxContainer } from 'components/BoxContainer';
import { Text } from '../components/Typography';
import { Stack } from '../components/Stack';
import { Button } from '../components/Button';
import PageContentWrapper from 'components/PageContentWrapper';

import strings from '../util/Localization';
import { useStore } from 'store';
import { useGetPaymentMy } from '../hooks/query/payment/useGetPaymentMy';
import { useTracking } from '../hooks/store/useTracking';
import { PlanCard } from './MyPage/UsageAndPlan';

const RoutePlanLandingPage = () => {
  const [pricing_id, setPricing_id] = useState<number>(1);
  const { isSuccess, data } = useGetPaymentMy({
    refetchOnWindowFocus: true,
  });
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('배차계획>배차계획 선택');
  }, [setTitle]);

  useEffect(() => {
    if (isSuccess && data) {
      setPricing_id(data.pricingId);
    }
  }, [isSuccess, data]);

  return (
    <Fragment>
      <Stack align={'flex-start'} direction={'row'}>
        <PageContentWrapper>
          <BoxContainer sx={{ padding: '48px 21px' }} align={'start'} justify={'start'} useVerticalScroll={true}>
            <Stack align={'start'} sx={{ margin: '0 auto', maxWidth: '1280px' }}>
              <Stack name="header-area" spacing={6} align={'start'}>
                <Text styleName={'title1'} color={'RG02'}>
                  {`${strings.배차} 계획 선택`}
                </Text>

                <Text styleName={'caption2'} color={'RG03'}>
                  배차 옵션을 설정하고 배차할 주문과 드라이버를 선택하여 배차를 계획합니다.
                </Text>
              </Stack>
              <Stack direction="row" spacing={20} sx={{ margin: '35px 0 100px 0' }}>
                {pricing_id !== 2 && (
                  <Controller
                    liner={['RC02', 'RC02']}
                    target="optimize"
                    tip="Roouty Optimization"
                    title="자동 최적화 배차"
                    desc={'루티 엔진이 주문과 드라이버에 맞춰\n가장 최적의 배차를 제공합니다.'}
                  />
                )}
                <Controller
                  liner={['RG06']}
                  target="manual"
                  tip="Manual"
                  title="수동 배차"
                  desc={'루티 엔진을 사용하지 않고 지정한 순서와\n드라이버로 배차를 실행합니다.'}
                />
              </Stack>
            </Stack>
          </BoxContainer>
        </PageContentWrapper>
      </Stack>
    </Fragment>
  );
};

export default RoutePlanLandingPage;

type ControllerTarget = 'optimize' | 'manual';
const Controller = ({
  tip,
  title,
  desc,
  liner,
  target,
}: {
  tip: string;
  title: string;
  desc: string;
  liner: TThemeColor[];
  target: ControllerTarget;
}) => {
  const navigate = useNavigate();
  const target_location: { [key in ControllerTarget]: string } = {
    manual: 'manual',
    optimize: 'optimize',
  };

  const { resetGetOrderListProps } = useStore();
  return (
    <PlanCard color={liner[0]} width="630px" height="100%" sx={{ alignItems: 'start', padding: '40px 20px 20px 20px' }}>
      <Stack spacing={20} align="start">
        <Text styleName="body1" color={liner[1] ?? 'RG03'}>
          {tip}
        </Text>
        <Text styleName="headline1" color="RG01">
          {title}
        </Text>
      </Stack>
      <ControllerPre>{desc}</ControllerPre>
      <Button
        type="button"
        variant="primary"
        fw
        h={40}
        onClick={e => {
          sessionStorage.clear();
          if (target === 'optimize') resetGetOrderListProps();
          navigate(`${target_location[target]}`);
        }}
      >
        시작하기
      </Button>
    </PlanCard>
  );
};

const ControllerPre = styled.pre`
  padding: 10px 0 40px 0;

  ${({ theme }) => ({
    color: theme.colors.RG03,
  })}
  ${({ theme }) => theme.fontStyle.body1}
`;
