import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { getVehicleModel, modifyingDriverById } from 'api';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Input, Select } from 'components/Input';
import BreakRangePicker from 'components/Popup/BreakRangePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { VehicleModelPicker } from 'components/Popup/VehicleModelPicker';
import { Stack } from 'components/Stack';
import { Swap } from 'components/Swap';
import InternalTable from 'components/Table/InternalTable';
import { FormTextarea } from 'components/Table/TeamManagementTable';
import { Tag, TTagStatusKeys } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IcError } from 'constants/icon/ic_error_alert_circle.svg';

import { ReactComponent as IcFlag } from 'constants/icon/ic_flag.svg';
import { ReactComponent as Iclocation } from 'constants/icon/ic_location.svg';
import { ReactComponent as IcPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcSuccessOutline } from 'constants/icon/ic_success_circle_outline.svg';
import { ReactComponent as IcTruck } from 'constants/icon/ic_truck.svg';
import theme from 'constants/theme';
import { IInvolvedDriver, TAccountStatus, TOperationType } from 'constants/types';
import dayjs from 'dayjs';
import StatusTag from 'pages/Setting/common/StatusTag';
import { driver_operationType, driver_status } from 'pages/Setting/resource/Driver';
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useForm } from 'react-hook-form';
import { IMultiSkillData } from 'types/masterData/multiSkill';

import Modal from '.';
import { ModifiedMultiSkillsSection } from '../ModifiedSection/ModifiedMultiSkillsSection';
import genLabel from './common/genLabel';
import ModalIconHeader from './common/ModalIconHeader';
import { DetailModiForm } from './OrderDetailModal';
import { QueryStatusModalProps } from './QueryStatusModal';
import {
  DescriptionTable,
  DescriptionTd,
  DescriptionTh,
  OrderDetailModalBody,
  OrderDetailModalFooter,
  OrderDetailModalHeader,
} from './style';

const areaColumnHelper = createColumnHelper<{
  areaId: number;
  name: string;
}>();

interface DriverDetailModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  target: IInvolvedDriver;
  onControl: (
    open: boolean,
    props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>,
  ) => void;

  setProcessingDriverAlertModalIsOpen: Dispatch<SetStateAction<boolean>>;
}

const targetOperationTypeTagMapper: { [key in TOperationType]: { string: string; key: TTagStatusKeys } } = {
  backup: { string: '용차', key: 'moving' },
  regular: { string: '고정차', key: 'done' },
};

const DriverDetailModal = ({
                             isOpen,
                             setIsOpen,
                             target,
                             onControl,
                             setProcessingDriverAlertModalIsOpen,
                           }: DriverDetailModalProps) => {
  const defaultValues = useMemo(() => {
    return {
      name: target.name,
      memo: target?.memo,
      operationType: target.operationType,
      status: target.status,
      modelId: target.model.name,
      maxCapacity: target.maxCapacity,
      workStartTime: target.workStartTime,
      workEndTime: target.workEndTime,
      breakDuration: target?.driverBreak?.duration ? Math.floor(target.driverBreak.duration / 60) : 0,
      breakStartTime: target?.driverBreak?.startTime,
      breakEndTime: target?.driverBreak?.endTime,
      startAddress: target.startAddress,
      startDetailAddress: target?.startDetailAddress,
      endAddress: target?.endAddress,
      endDetailAddress: target?.endDetailAddress,

      breakRange: `${target?.driverBreak?.startTime ?? ''}${
        !target?.driverBreak?.startTime && !target?.driverBreak?.endTime ? '' : '~'
      }${target?.driverBreak?.endTime ?? ''}`,

      skills: target.skills || [],
    };
  }, [target]);

  const methods = useForm<{
    name: string;
    memo: string;
    operationType: TOperationType;
    status: TAccountStatus;
    modelId: string;
    maxCapacity: number;
    workStartTime: string;
    workEndTime?: string;
    breakDuration: number;
    breakStartTime: string;
    breakEndTime: string;
    startAddress: string;
    startDetailAddress: string;
    endAddress: string;
    endDetailAddress: string;

    breakRange?: string;
    skills: Pick<IMultiSkillData, 'skillId' | 'name'>[];
  }>({ defaultValues: { ...defaultValues } });

  const areaColumn = useMemo(
    () => [
      areaColumnHelper.accessor(row => row.name, {
        id: 'areaName',
        header: '권역 이름',
        footer: info => info.column.id,
      }),
    ],
    [],
  );
  const [isModi, setIsModi] = useState<boolean>(false);
  const [driverDeactivateModalIsOpen, setDriverDeactivateModalIsOpen] = useState<boolean>(false);
  const [sameAsStart, setSameAsStart] = useState<boolean>(false);
  const [workStartTimeTimePickerisOpen, setWorkStartTimeTimePickerisOpen] = useState<boolean>(false);
  const [workEndTimeTimePickerisOpen, setWorkEndTimeTimePickerisOpen] = useState<boolean>(false);
  const [breakRangePickerIsOpen, setBreakRangePickerIsOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<{ isOpen: boolean; target: string }>({
    isOpen: false,
    target: '',
  });
  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const renderCounter = useRef(0);

  const driverStatusTagMapper: { [key in TAccountStatus]: ReactNode } = {
    invited: null,
    accepted: null,
    activated: <StatusTag styleName="caption1" text={'활성'}/>,
    deactivated: <StatusTag styleName="caption1" text={'비활성'} color="RG04" borderColor="RG04"/>,
  };

  const { data: vehicleModel } = useQuery(['vehicleModel'], getVehicleModel, {});
  const queryClient = useQueryClient();

  const [workEndTimeNan, setWorkEndTimeNan] = useState<boolean>(true);

  useEffect(() => {
    if (workEndTimeNan) {
      methods.setValue('workEndTime', '');
    }
  }, [workEndTimeNan]);

  const handlePostcodeComplete = (data: any, name: string) => {
    let address = data.address || data.jibunAddress;
    methods.setValue(name as any, address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(prev => {
      return { ...prev, isOpen: false };
    });
  };

  const { mutate: mutateModifyingDriverStatusById, isLoading: mutateModifyingDriverStatusByIdIsLoading } = useMutation(
    (status: TAccountStatus) => modifyingDriverById({ driverId: target.driverId, status }),
    {
      onMutate: () => {
        setIsOpen(false);
        onControl(true, {
          status: 'loading',
          contnet: (
            <Text styleName="body2">
              {target.name} 드라이버를 비활성화로 변경 중입니다.
              <br/>
              잠시만 기다려 주세요.
            </Text>
          ),
        });
      },
      onSuccess: () => {
        onControl(true, { status: 'success', string: `${target.name} 드라이버를 비활성 상태로 변경 완료하였습니다.` });
      },
      onError: (err: any) => {
        const error = err?.response?.data?.error as TErrorResponse;
        if (error.name === 'ProcessingData') {
          onControl(false, { status: 'error' });
          setProcessingDriverAlertModalIsOpen(true);
        } else
          onControl(true, {
            status: 'error',
            string: `${target.name} 드라이버를 비활성화로 변경하지 못하였습니다.`,
            callback: () => {
              setIsOpen(true);
              setIsModi(true);
            },
          });
      },
    },
  );

  const {
    mutate: mutateModifyingDriverById,
    isLoading: modifyingDriverByIdIsLoading,
    isSuccess: modifyingDriverByIdIsSuccess,
  } = useMutation(modifyingDriverById, {
    onSuccess: () => {
      onControl(true, {
        status: 'success',
        string: '드라이버의 정보 수정이 완료되었습니다.\n변경된 정보는 다음 배차 계획부터 적용됩니다.',
      });
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (modifyingDriverByIdIsSuccess) {
      queryClient.invalidateQueries({ queryKey: ['driverList'] });
    }
  }, [modifyingDriverByIdIsSuccess]);

  useEffect(() => {
    methods.reset({ ...defaultValues });
    setAddressPopupIsOpen({ isOpen: false, target: '' });
    setIsModi(false);
    setSameAsStart(false);
  }, [isOpen, methods]);

  useEffect(() => {
    if (!methods.getValues('workStartTime') || !methods.getValues('workEndTime')) return;
    if (!(+methods.getValues('workStartTime').replace(':', '') > +methods.getValues('workEndTime')!.replace(':', ''))) {
      methods.clearErrors('workStartTime');
      methods.clearErrors('workEndTime');
    }
  }, [methods.getValues('workStartTime'), methods.getValues('workEndTime')]);

  useEffect(() => {
    if (methods.getValues('breakDuration') === 0)
      methods.setValue('breakRange', '', { shouldValidate: true, shouldDirty: true });
  }, [methods.getValues('breakDuration')]);

  useEffect(() => {
    setWorkEndTimeNan(!target.workEndTime);
  }, [target]);

  useEffect(() => {
    if (sameAsStart) {
      methods.setValue('endAddress', methods.getValues('startAddress'), {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('endDetailAddress', methods.getValues('startDetailAddress'), {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      methods.setValue('endAddress', target.endAddress, { shouldDirty: true, shouldValidate: true });
      methods.setValue('endDetailAddress', target.endDetailAddress, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [methods, sameAsStart, target, methods.watch('startAddress'), methods.watch('startDetailAddress')]);

  return (
    <Fragment>
      <Modal
        isLoadingModal={modifyingDriverByIdIsLoading || mutateModifyingDriverStatusByIdIsLoading}
        plain
        width={540}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
      >
        <OrderDetailModalHeader>
          <Stack direction="row" justify="space-between">
            <Text styleName="subheadline2" color="RG03">
              드라이버 상세 정보
            </Text>
            <IcClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </Stack>
        </OrderDetailModalHeader>
        <Divider color="RG06"/>
        <OrderDetailModalBody useVerticalScroll>
          {!isModi ? (
            <div style={{ width: '100%', maxHeight: 784 }}>
              <DetailModiForm
                title={<ModalIconHeader title="드라이버 기본 정보" icon={<IcPerson width={20} height={20}/>}/>}
              >
                <DescriptionTable
                  style={{
                    borderTop: `1px solid ${theme.colors.RG03}`,
                  }}
                >
                  <tbody>
                  <tr>
                    <DescriptionTh colSpan={120}>{'초대 일자'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>
                      {target.createdAt ? dayjs(target.createdAt).format('YYYY-MM-DD') : null}
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'소속 팀'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>{target.teamName}</DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'상태'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>
                      {target.status ? driverStatusTagMapper[target.status] : null}
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'이름'}</DescriptionTh>
                    <DescriptionTd colSpan={130}>{target.name}</DescriptionTd>
                    <DescriptionTh colSpan={120}>{'아이디 (휴대폰 번호)'}</DescriptionTh>
                    <DescriptionTd colSpan={130}>
                      {target.loginAccount
                        ? target.loginAccount
                          .replace(/[^0-9]/g, '')
                          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : null}
                    </DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'메모'}</DescriptionTh>
                    <DescriptionTd colSpan={380} broken>
                      {target.memo ?? null}
                    </DescriptionTd>
                  </tr>
                  </tbody>
                </DescriptionTable>
              </DetailModiForm>
              <Divider color="RG06"/>
              <DetailModiForm title={<ModalIconHeader title="차량 · 주행 정보" icon={<IcTruck/>}/>}>
                <DescriptionTable
                  style={{
                    borderTop: `1px solid ${theme.colors.RG03}`,
                  }}
                >
                  <tbody>
                    <tr>
                      <DescriptionTh colSpan={120}>{'운영 유형'}</DescriptionTh>
                      <DescriptionTd colSpan={380}>
                        {target.operationType ? (
                          <Tag
                            active
                            status={targetOperationTypeTagMapper[target.operationType].key}
                            sx={{ width: 45, height: 24 }}
                            fit
                          >
                            {targetOperationTypeTagMapper[target.operationType].string}
                          </Tag>
                        ) : null}
                      </DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'차종'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>{target.model.name}</DescriptionTd>
                      <DescriptionTh colSpan={120}>{'최대 용적량'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>{target.maxCapacity}</DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'주행 시작 시간'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>{target.workStartTime}</DescriptionTd>
                      <DescriptionTh colSpan={120}>{'주행 종료 시간'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>{target.workEndTime ?? '없음'}</DescriptionTd>
                    </tr>
                    <tr>
                      <DescriptionTh colSpan={120}>{'휴게 시간'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>
                        {target?.driverBreak?.duration ? `${Math.floor(target.driverBreak.duration / 60)} 분` : '없음'}
                      </DescriptionTd>
                      <DescriptionTh colSpan={120}>{'휴게 시간 구간'}</DescriptionTh>
                      <DescriptionTd
                        colSpan={130}
                        disabled={
                          !target?.driverBreak?.startTime &&
                          !target?.driverBreak?.endTime &&
                          !target?.driverBreak?.duration
                        }
                      >{`${target?.driverBreak?.startTime ?? ''}${
                        !target?.driverBreak?.startTime && !target?.driverBreak?.endTime ? '없음' : '~'
                      }${target?.driverBreak?.endTime ?? ''}`}</DescriptionTd>
                      {/* <DescriptionTh colSpan={120}>{'휴게 시간 구간 (이전)'}</DescriptionTh>
                      <DescriptionTd colSpan={130}>{target?.driverBreak?.endTime ?? null}</DescriptionTd> */}
                  </tr>
                  </tbody>
                </DescriptionTable>
              </DetailModiForm>
              <Divider color="RG06"/>
              <ModifiedMultiSkillsSection
                teamId={target.teamId}
                isModified={isModi}
                selectedSkillIds={target.skills.map(item => item.skillId)}
                setSelectedSkillIds={setSelectedSkillIds}
              />
              <Divider color="RG06"/>
              <DetailModiForm
                title={
                  <Stack direction="row" spacing={10}>
                    <ModalIconHeader title="담당 권역 정보" icon={<Iclocation/>}/>
                    <Text styleName="subheadline2">
                      총&nbsp;<Highlight styleName="subheadline1">{target.area?.length ?? 0}</Highlight>&nbsp;개
                    </Text>
                  </Stack>
                }
              >
                <InternalTable<any>
                  data={target.area ?? []}
                  columns={areaColumn}
                  placeCell={5}
                  placeholder={
                    <Text styleName="caption2">
                      담당하고 있는 권역이 없습니다.
                      <br/>
                      {`[리소스 관리 > 권역 관리] 에서 설정할 수 있습니다.`}
                    </Text>
                  }
                />
              </DetailModiForm>
              <Divider color="RG06"/>
              <DetailModiForm title={<ModalIconHeader title="출 · 도착지 정보" icon={<IcFlag/>}/>}>
                <DescriptionTable
                  style={{
                    borderTop: `1px solid ${theme.colors.RG03}`,
                  }}
                >
                  <tbody>
                  <tr>
                    <DescriptionTh colSpan={120}>{'출발지 주소'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>{target.startAddress}</DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'출발지 상세 주소'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>{target.startDetailAddress}</DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'도착지 주소'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>{target.endAddress}</DescriptionTd>
                  </tr>
                  <tr>
                    <DescriptionTh colSpan={120}>{'도착지 상세 주소'}</DescriptionTh>
                    <DescriptionTd colSpan={380}>{target.endDetailAddress}</DescriptionTd>
                  </tr>
                  </tbody>
                </DescriptionTable>
              </DetailModiForm>
            </div>
          ) : (
            <form
              onSubmit={methods.handleSubmit(res => {
                console.log(res);
              })}
              style={{ maxHeight: 784 }}
            >
              <DetailModiForm
                title={<ModalIconHeader title="드라이버 기본 정보" icon={<IcPerson width={20} height={20}/>}/>}
              >
                <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }}/>
                <Input
                  name="trash"
                  label={genLabel({ text: '초대 일자', isRequiredStart: true })}
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  field
                  height={40}
                  readOnly
                  disabled
                  value={dayjs(target.createdAt).format('YYYY.MM.DD')}
                />
                <Input
                  name="trash"
                  label={genLabel({ text: '소속 팀', isRequiredStart: true })}
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  field
                  height={40}
                  variant="fourth"
                  readOnly
                  disabled
                  value={target.teamName}
                />
                <Swap
                  className="order"
                  padding={0}
                  gap={0}
                  sx={{
                    paddingTop: '7px',
                  }}
                  renderCounter={renderCounter.current}
                  name="status"
                  methods={methods}
                  label={genLabel({
                    text: '상태',
                    isRequiredStart: true,
                  })}
                  watch={methods.watch('status')}
                  defaultIndex={driver_status.findIndex(d => d.value === methods.getValues('status'))}
                  options={driver_status}
                  onClick={(e, current, isActive) => {
                    if (!isActive && current === 'deactivated') {
                      setDriverDeactivateModalIsOpen(true);
                    }
                  }}
                />
                <Stack direction="row" spacing={16}>
                  <Input
                    name="name"
                    label={genLabel({ text: '이름', isRequiredStart: true })}
                    placeholder="예시) 홍길동"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    validation={{
                      required: true,
                      minLength: { value: 2, message: '드라이버 이름은 한글/영문 2~20자 입력이 가능합니다.' },
                      maxLength: { value: 20, message: '드라이버 이름은 한글/영문 2~20자 입력이 가능합니다.' },
                    }}
                    removeBtn={() => {
                      methods.setValue('name', '');
                    }}
                    variant="third"
                    field
                    height={40}
                    errors={methods.formState?.errors?.name}
                    hasError={Boolean(methods.formState?.errors?.name)}
                  />
                  <Input
                    name="trash"
                    label={genLabel({ text: '아이디 (휴대폰 번호)', isRequiredStart: true })}
                    placeholder="000 - 0000 - 0000"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    variant="fourth"
                    field
                    height={40}
                    readOnly
                    disabled
                    value={target.loginAccount}
                  />
                </Stack>
                <Stack spacing={4}>
                  {genLabel({ text: '메모' })}
                  <FormTextarea
                    {...methods.register('memo', {})}
                    placeholder={'예시) 매주 월요일 휴무'}
                    rows={14}
                    cols={10}
                    useFieldFont={true}
                    wrap="soft"
                    maxLength={200}
                    onKeyDown={e => {
                      e.currentTarget.style.height = '1px';
                      e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                    }}
                    onKeyUp={e => {
                      e.currentTarget.style.height = '1px';
                      e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                    }}
                  />
                </Stack>
              </DetailModiForm>
              <Divider color="RG06"/>
              <DetailModiForm title={<ModalIconHeader title="차량 · 주행 정보" icon={<IcTruck/>}/>}>
                <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }}/>
                <Swap
                  className="order"
                  padding={0}
                  gap={0}
                  sx={{
                    paddingTop: '7px',
                  }}
                  renderCounter={renderCounter.current}
                  name="operationType"
                  methods={methods}
                  label={genLabel({
                    text: '운영 유형',
                    isRequiredStart: true,
                  })}
                  defaultIndex={driver_operationType.findIndex(d => d.value === methods.getValues('operationType'))}
                  options={driver_operationType}
                />
                <Stack align="start" direction="row" spacing={16}>
                  <Stack sx={{ position: 'relative' }}>
                    {skillPopupIsOpen && (
                      <PopupYIndexFixer>
                        <div></div>
                      </PopupYIndexFixer>
                    )}
                    <Input
                      name="modelId"
                      label={genLabel({ text: '차종', isRequiredStart: true })}
                      placeholder="차종을 선택해 주세요"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      variant="third"
                      field
                      height={40}
                      errors={methods.formState?.errors?.modelId}
                      hasError={Boolean(methods.formState?.errors?.modelId)}
                      validation={{
                        required: true,
                        validate: (res: any) =>
                          !Boolean(res) ||
                          (vehicleModel && vehicleModel.vehicleModelList.filter(d => d.name === res).length > 0) ||
                          '존재하지 않는 차량입니다.',
                      }}
                      onChange={(e: any) => {
                        setSkillPopupIsOpen(true);
                        setSkillSearchValue(e.target.value);

                        vehicleModel?.vehicleModelList &&
                        methods.setValue(
                          'modelId',
                          vehicleModel.vehicleModelList.find(d => d.name === e.target.value)!.name,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          },
                        );
                      }}
                      onClick={() => {
                        setSkillPopupIsOpen(true);
                      }}
                    />

                    {skillPopupIsOpen && (
                      <VehicleModelPicker
                        setIsOpen={setSkillPopupIsOpen}
                        methods={methods}
                        name={'modelId'}
                        searchValue={skillSearchValue}
                      />
                    )}
                  </Stack>
                  <Input
                    name="maxCapacity"
                    label={genLabel({ text: '최대 용적량', isRequiredStart: true })}
                    placeholder="정수만 입력해 주세요. 예시) 1000"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    validation={{
                      required: true,
                      validate: (res: string) => {
                        return (Number(res) > 0 && Number.isInteger(Number(res))) || '1 이상의 정수만 입력해 주세요.';
                      },
                    }}
                    variant="third"
                    field
                    height={40}
                    errors={methods.formState?.errors?.maxCapacity}
                    hasError={Boolean(methods.formState?.errors?.maxCapacity)}
                  />
                </Stack>

                <Stack direction="row" spacing={16} align="start">
                  <Stack sx={{ position: 'relative' }}>
                    {(workStartTimeTimePickerisOpen || workEndTimeTimePickerisOpen) && (
                      <PopupYIndexFixer>
                        <div/>
                      </PopupYIndexFixer>
                    )}
                    <Input
                      name="workStartTime"
                      label={genLabel({ text: '주행 시작 시간', isRequiredStart: true })}
                      placeholder="09 : 00"
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      validation={{
                        required: true,
                        validate: (res: string) => {
                          if (methods.getValues('workEndTime')) {
                            if (
                              +(methods.getValues('workStartTime') ?? '').replace(':', '') >
                              +(methods.getValues('workEndTime') ?? '').replace(':', '')
                            ) {
                              return '주행 시작 시간이 주행 종료 시간보다 늦을 수 없습니다.';
                            }
                          }

                          return;
                        },
                      }}
                      variant="third"
                      field
                      readOnly
                      height={40}
                      onClick={() => {
                        setWorkStartTimeTimePickerisOpen(prev => !prev);
                      }}
                      errors={methods.formState?.errors?.workStartTime}
                      hasError={Boolean(methods.formState?.errors?.workStartTime)}
                    />
                    {workStartTimeTimePickerisOpen && (
                      <MiniTimePicker
                        initial={methods.getValues('workStartTime')}
                        init={methods.getValues('workStartTime')}
                        isOpen={workStartTimeTimePickerisOpen}
                        setIsOpen={setWorkStartTimeTimePickerisOpen}
                        methods={methods}
                        name={'workStartTime'}
                        rSide
                        nm
                        clearable
                      />
                    )}
                  </Stack>
                  <Stack sx={{ position: 'relative' }}>
                    {workEndTimeTimePickerisOpen && (
                      <PopupYIndexFixer>
                        <div />
                      </PopupYIndexFixer>
                    )}
                    <Input
                      name="workEndTime"
                      label={genLabel({ text: '주행 종료 시간', isRequiredStart: false })}
                      placeholder="없음"
                      isPlaceholderDefault={true}
                      register={methods.register}
                      watch={methods.watch}
                      reset={methods.reset}
                      readOnly
                      validation={{
                        validate: (res: string) => {
                          if (!methods.getValues('workStartTime') || !methods.getValues('workEndTime')) return;
                          if (
                            +(methods.getValues('workStartTime') ?? '').replace(':', '') >
                            +(methods.getValues('workEndTime') ?? '').replace(':', '')
                          )
                            return '주행 종료 시간이 주행 시작 시간을 앞설 수 없습니다.';
                        },
                      }}
                      variant="third"
                      field
                      height={40}
                      onClick={() => {
                        setWorkEndTimeTimePickerisOpen(prev => !prev);
                      }}
                      errors={methods.formState?.errors?.workEndTime}
                      hasError={Boolean(methods.formState?.errors?.workEndTime)}
                    />
                    {workEndTimeTimePickerisOpen && (
                      <MiniTimePicker
                        initial={
                          methods.getValues('workEndTime') && methods.getValues('workEndTime') !== '없음'
                            ? methods.getValues('workEndTime')
                            : '00:00'
                        }
                        init={methods.getValues('workEndTime')}
                        isOpen={workEndTimeTimePickerisOpen}
                        setIsOpen={setWorkEndTimeTimePickerisOpen}
                        methods={methods}
                        name={'workEndTime'}
                        rSide
                        nm
                        clearOnNan
                        nanable
                        nan={workEndTimeNan}
                        setNan={setWorkEndTimeNan}
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack sx={{ position: 'relative' }}>
                  <Select
                    options={[
                      { key: '없음', value: 0 },
                      { key: '30 분', value: 30 },
                      { key: '60 분', value: 60 },
                      { key: '90 분', value: 90 },
                      { key: '120 분', value: 120 },
                    ]}
                    defaultValue={methods.getValues('breakDuration')}
                    setValue={methods.setValue}
                    name="breakDuration"
                    label={genLabel({ text: '휴게 시간', isRequiredStart: true })}
                    placeholder="없음"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    validation={{ required: true }}
                    variant="third"
                    field
                    height={40}
                    errors={methods.formState?.errors?.breakDuration}
                    hasError={Boolean(methods.formState?.errors?.breakDuration)}
                  />
                </Stack>
                <Stack sx={{ position: 'relative' }}>
                  {breakRangePickerIsOpen && (
                    <PopupYIndexFixer>
                      <div></div>
                    </PopupYIndexFixer>
                  )}
                  <Input
                    disabled={methods.watch('breakDuration') === 0}
                    name="breakRange"
                    placeholder="휴게 시간 구간을 입력해 주세요"
                    label={genLabel({ text: '휴게 시간 구간' })}
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    variant={methods.watch('breakDuration') === 0 ? 'fourth' : 'third'}
                    field
                    height={40}
                    readOnly
                    validation={{
                      validate: {
                        startTimeCannotBeAheadOfWorkTime: (res: string) => {
                          var range = res
                            ?.replaceAll('오전', '')
                            ?.replaceAll('오후', '')
                            ?.replaceAll(' ', '')
                            ?.split(`~`);
                          const breakStart = range?.at(0);
                          const breakEnd = range?.at(1);

                          const sv = dayjs(breakStart, 'HH:mm')
                            ? dayjs(breakStart, 'HH:mm').isBefore(dayjs(methods.getValues('workStartTime'), 'HH:mm'))
                            : true;
                          const ev = dayjs(breakEnd, 'HH:mm')
                            ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(methods.getValues('workStartTime'), 'HH:mm'))
                            : true;

                          console.log(sv, ev);

                          return !(sv || ev) || '휴게 시작시간이 근무시작 시간보다 앞설 수 없습니다.';
                        },
                        endTimeCannotBeAheadOrSame: (res: string) => {
                          var range = res
                            ?.replaceAll('오전', '')
                            ?.replaceAll('오후', '')
                            ?.replaceAll(' ', '')
                            ?.split(`~`);
                          const breakStart = range?.at(0);
                          const breakEnd = range?.at(1);

                          const sv =
                            dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                              ? dayjs(breakEnd, 'HH:mm').isSame(dayjs(breakStart, 'HH:mm'))
                              : true;
                          const ev =
                            dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                              ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(breakStart, 'HH:mm'))
                              : true;

                          console.log(sv, ev);

                          return !(sv || ev) || '휴게 종료 시간을 주행 시작 시간보다 앞서서 설정할 수 없습니다.';
                        },
                        startTime_endTime_range_not_matched: (res: string) => {
                          var range = res
                            ?.replaceAll('오전', '')
                            ?.replaceAll('오후', '')
                            ?.replaceAll(' ', '')
                            ?.split(`~`);
                          const breakStart = range?.at(0);
                          const breakEnd = range?.at(1);

                          if (!breakStart && !breakEnd) return;

                          const sv =
                            dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                              ? isNaN(dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm')) ||
                              dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm') + 1 >
                              (methods.getValues('breakDuration') ?? 0)
                              : false;

                          return sv || '휴게 시간 구간은 휴게 시간보다 길어야 합니다.';
                        },
                      },
                    }}
                    onClick={() => {
                      setBreakRangePickerIsOpen(prev => !prev);
                    }}
                    hasError={Boolean(methods.formState?.errors?.breakRange)}
                  />
                  {breakRangePickerIsOpen && (
                    <BreakRangePicker
                      name="breakRange"
                      isOpen={breakRangePickerIsOpen}
                      setIsOpen={setBreakRangePickerIsOpen}
                      {...{ methods }}
                    />
                  )}
                </Stack>
              </DetailModiForm>
              <Divider color="RG06"/>
              <ModifiedMultiSkillsSection
                teamId={target.teamId}
                isModified={isModi}
                selectedSkillIds={target.skills.map(item => item.skillId)}
                setSelectedSkillIds={setSelectedSkillIds}
              />
              <Divider color="RG06"/>
              <DetailModiForm
                title={
                  <Stack direction="row" spacing={10}>
                    <ModalIconHeader title="담당 권역 정보" icon={<Iclocation/>}/>
                    <Text styleName="subheadline2">
                      총&nbsp;<Highlight styleName="subheadline1">{target.area?.length ?? 0}</Highlight>&nbsp;개
                    </Text>
                  </Stack>
                }
              >
                <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }}/>
                <InternalTable<any>
                  data={target.area ?? []}
                  columns={areaColumn}
                  placeCell={5}
                  placeholder={
                    <Text styleName="caption2">
                      담당하고 있는 권역이 없습니다.
                      <br/>
                      {`[리소스 관리 > 권역 관리] 에서 설정할 수 있습니다.`}
                    </Text>
                  }
                />
              </DetailModiForm>
              <Divider color="RG06"/>
              <DetailModiForm title={<ModalIconHeader title="출 · 도착지 정보" icon={<IcFlag/>}/>}>
                <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }}/>
                <Stack spacing={12}>
                  <Input
                    name="startAddress"
                    label={genLabel({ text: '출발지 주소', isRequiredStart: true })}
                    placeholder="주소를 입력하세요"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    field
                    type="text"
                    variant="third"
                    height={40}
                    validation={{ required: true }}
                    readOnly={true}
                    isInject={((methods.getValues('startAddress') as string)?.length ?? 0) > 0}
                    removeBtn={(e: MouseEvent) => {
                      e.preventDefault();
                      methods.setValue('startAddress', '');
                    }}
                    onFocus={() => {
                      methods.clearErrors('startAddress');
                      setAddressPopupIsOpen(prev => {
                        return { target: 'startAddress', isOpen: true };
                      });
                    }}
                    onClick={() => {
                      setAddressPopupIsOpen(prev => {
                        return { target: 'startAddress', isOpen: true };
                      });
                    }}
                    hasError={Boolean(methods.formState?.errors?.startAddress)}
                  />
                  <Input
                    disabled={!methods.getValues('startAddress')}
                    name="startDetailAddress"
                    placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    field
                    type="text"
                    variant={!methods.getValues('startAddress') ? 'fourth' : 'third'}
                    height={40}
                    hasError={Boolean(methods.formState?.errors?.startDetailAddress)}
                  />
                </Stack>

                <Stack spacing={12}>
                  <Input
                    disabled={sameAsStart}
                    name="endAddress"
                    label={genLabel({ text: '도착지 주소' })}
                    rightArea={{
                      rightContent: (
                        <Stack
                          spacing={4}
                          direction="row"
                          sx={{ width: 'max-content' }}
                          onClick={() => {
                            setSameAsStart(prev => !prev);
                          }}
                        >
                          {sameAsStart ? (
                            <IcSuccess width={12} height={12} fill={theme.colors['RC02']}/>
                          ) : (
                            <IcSuccessOutline width={12} height={12}/>
                          )}
                          <Text styleName="caption1" color={sameAsStart ? 'RC02' : 'RG04'}>
                            출발지와 동일
                          </Text>
                        </Stack>
                      ),
                    }}
                    placeholder="주소를 입력하세요"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    field
                    type="text"
                    variant={sameAsStart ? 'fourth' : 'third'}
                    height={40}
                    readOnly={true}
                    isInject={((methods.getValues('endAddress') as string)?.length ?? 0) > 0}
                    removeBtn={(e: MouseEvent) => {
                      e.preventDefault();
                      methods.setValue('endAddress', '');
                    }}
                    onFocus={() => {
                      methods.clearErrors('endAddress');
                      setAddressPopupIsOpen(prev => {
                        return { target: 'endAddress', isOpen: true };
                      });
                    }}
                    onClick={() => {
                      setAddressPopupIsOpen(prev => {
                        return { target: 'endAddress', isOpen: true };
                      });
                    }}
                    hasError={Boolean(methods.formState?.errors?.endAddress)}
                  />
                  <Input
                    disabled={!methods.getValues('endAddress') || sameAsStart}
                    name="endDetailAddress"
                    placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    field
                    type="text"
                    variant={!methods.getValues('endAddress') || sameAsStart ? 'fourth' : 'third'}
                    height={40}
                    hasError={Boolean(methods.formState?.errors?.endAddress)}
                  />
                </Stack>

                <Modal
                  isModalOpen={addressPopupIsOpen.isOpen}
                  setIsModalOpen={() => {
                    setAddressPopupIsOpen(prev => {
                      return { ...prev, isOpen: false };
                    });
                  }}
                  title={'우편번호찾기'}
                  width={532}
                  ms={30}
                  padding={20}
                >
                  <DaumPostcodeEmbed
                    onComplete={data => handlePostcodeComplete(data, addressPopupIsOpen.target)}
                    style={{ height: '500px', marginBottom: '0' }}
                  />
                </Modal>
              </DetailModiForm>
            </form>
          )}
        </OrderDetailModalBody>
        <Divider color="RG06"/>
        <OrderDetailModalFooter>
          <Stack
            name="modal-footer"
            spacing={16}
            direction="row"
            align="center"
            justify="end"
            sx={{ padding: '2px 0 0 0' }}
          >
            <Button
              variant="seventh"
              type="button"
              width={102}
              height={40}
              styleName="body1"
              color="RG03"
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
            >
              닫기
            </Button>
            {isModi ? (
              <Button
                variant="default"
                type="button"
                width={216}
                height={40}
                styleName="body1"
                color="RG00"
                onClick={methods.handleSubmit(res => {
                  if (methods.formState.dirtyFields) {
                    const br = res.breakRange
                      ?.replaceAll('오전', '')
                      ?.replaceAll('오후', '')
                      ?.replaceAll(' ', '')
                      ?.split('~');
                    if (br) {
                      res.breakStartTime = br[0];
                      res.breakEndTime = br[1];
                    }
                    delete res.breakRange;
                    if (isNaN(Number(res.modelId)) && vehicleModel) {
                      res.modelId = `${
                        vehicleModel.vehicleModelList.find(d => d.name === methods.getValues('modelId'))!.vehicleModelId
                      }`;
                    }

                    const { modelId, breakDuration, ...rest } = res;
                    mutateModifyingDriverById({
                      ...rest,
                      workEndTime: res.workEndTime || '',
                      driverId: target.driverId,
                      breakDuration: breakDuration * 60,
                      modelId: parseInt(modelId),
                      skills: selectedSkillIds,
                    });
                  } else {
                    setIsModi(prev => !prev);
                  }
                })}
              >
                저장하기
              </Button>
            ) : (
              <Button
                variant="default"
                type="button"
                width={216}
                height={40}
                styleName="body1"
                color="RG00"
                onClick={() => {
                  setIsModi(prev => !prev);
                }}
              >
                수정하기
              </Button>
            )}
          </Stack>
        </OrderDetailModalFooter>
      </Modal>
      <Modal
        width={504}
        plain
        isModalOpen={driverDeactivateModalIsOpen}
        setIsModalOpen={setDriverDeactivateModalIsOpen}
        padding={24}
        bd
        ds="strong"
      >
        <Stack spacing={30} sx={{ marginTop: 20 }}>
          <IcError width={44} height={44}/>
          <Text styleName="body2">
            <Highlight color="RC02">드라이버를 정말 비활성화 하시겠습니까?</Highlight>
            <br/>
            <Highlight styleName="body1">비활성 상태의 드라이버는 배차할 수 없습니다.</Highlight>
            <br/>
            비활성화 이후 다시 활성 상태로 변경하실 수 있습니다.
          </Text>
          <Stack direction="row" spacing={20} sx={{ marginTop: 10 }}>
            <Button
              variant="seventh"
              type="button"
              fullWidth
              height={40}
              styleName="body1"
              color="RG04"
              onClick={() => {
                methods.setValue('status', 'activated', { shouldDirty: true });
                setDriverDeactivateModalIsOpen(false);
              }}
            >
              취소
            </Button>
            <Button
              variant="default"
              type="button"
              fullWidth
              height={40}
              styleName="body1"
              color="RG00"
              onClick={() => {
                setDriverDeactivateModalIsOpen(false);
                mutateModifyingDriverStatusById(methods.getValues('status'));
              }}
            >
              확인
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Fragment>
  );
};

export default DriverDetailModal;
