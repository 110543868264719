export interface TemporaryOptimizedList {
  key: string;
  name: string;
}

export function getActiveSessions(): number {
  const sessions = Array.from({ length: sessionStorage.length }, (_, index) => {
    if (sessionStorage.key(index)?.startsWith('temp_')) {
      const raw = JSON.parse(sessionStorage.getItem(sessionStorage.key(index)!)!);
      return !raw.disable;
    } else return false;
  }).filter(d => d).length;

  return sessions;
}

export function getLastGenIndex(): number {
  let list: number[] = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith('temp_')) list.push(JSON.parse(sessionStorage.getItem(key)!).genIndex);
  }

  return Math.max.apply(null, list);
}

export function getTemporaryOptimizeDataList() {
  let list: TemporaryOptimizedList[] = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith('temp_') && !JSON.parse(sessionStorage.getItem(key)!).disable)
      list.push({
        key,
        name: JSON.parse(sessionStorage.getItem(key)!).name,
      });
  }
  list.sort((a, b) => {
    return parseInt(b.key.replace('temp_', '')) - parseInt(a.key.replace('temp_', ''));
  });
  return list.reverse();
}

export function removeTemporaryOptimizeDataList(dateKey: string) {
  for (let i = 0; i < sessionStorage.length; i++) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith(`temp_${dateKey}`)) sessionStorage.removeItem(key);
  }
}
export function removeAllTemporaryOptimizeDataList() {
  sessionStorage.clear();

  // sessionStorage.removeItem(route_origin_session_key);
  // for (let i = 0; i < sessionStorage.length; i++) {
  //   let key = sessionStorage.key(i);
  //   if (key && key.startsWith('temp_')) sessionStorage.removeItem(key);
  // }
}
