import { LoadingModal } from 'components/Modal/LoadingModal';
import { useDownloadRoute } from 'hooks/query/route/useDownloadRoute';
import { useDownload } from 'hooks/useDownload';
import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { RouteDownloadVariables } from 'types/route/download';
import { ReactComponent as IconRouteDownload } from '../../../constants/icon/ic_route_download.svg';
import { ReactComponent as IconRouteItemDownload } from '../../../constants/icon/ic_route_item_download.svg';
import { Stack } from '../../Stack';
import { Text } from '../../Typography';
import Modal from '../index';

export interface RouteDownloadModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  count: number;
  variables: RouteDownloadVariables;
  extension: DownloadExtension;
}

export const RouteDownloadModal = ({
  title,
  text,
  isOpen,
  setIsOpen,
  count,
  variables,
  extension,
}: RouteDownloadModalProps) => {
  const { downloadToArrayBuffer } = useDownload();

  const {
    mutate: mutateDownloadRoute,
    data: downloadRouteData,
    isLoading: isLoadingDownloadRoute,
    isSuccess: isSuccessDownloadRoute,
  } = useDownloadRoute();
  const {
    mutate: mutateDownloadRouteItem,
    data: downloadRouteItemData,
    isLoading: isLoadingDownloadRouteItem,
    isSuccess: isSuccessDownloadRouteItem,
  } = useDownloadRoute(true);

  useEffect(() => {
    if (isSuccessDownloadRoute && downloadRouteData?.data) {
      downloadToArrayBuffer({
        data: downloadRouteData.data,
        fileName: downloadRouteData.title ?? '',
        extension: extension,
      });
    }
  }, [isSuccessDownloadRoute, downloadRouteData]);

  useEffect(() => {
    if (isSuccessDownloadRouteItem && downloadRouteItemData?.data) {
      downloadToArrayBuffer({
        data: downloadRouteItemData.data,
        fileName: downloadRouteItemData.title ?? '',
        extension: extension,
      });
    }
  }, [isSuccessDownloadRouteItem, downloadRouteItemData]);

  return (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} ds="strong" padding={24} width={701}>
      <Stack>
        <Stack direction="column" spacing={8} sx={{ padding: '20px 0' }}>
          <Text styleName="title2" color="RG02">
            {count}개의 주행이 다운로드 됩니다.
          </Text>
          <Text styleName="subheadline2" color="RG03">
            아이템 항목 포함 여부를 선택하여 다운로드 하실 수 있습니다.
          </Text>
        </Stack>
        <Stack direction="row" spacing={16} justify="space-between">
          <BorderBox
            onClick={() => {
              mutateDownloadRoute(variables);
            }}
          >
            <Frame title="아이템 항목 제외" />
          </BorderBox>

          <BorderBox
            onClick={() => {
              mutateDownloadRouteItem(variables);
            }}
          >
            <Frame title="아이템 항목 포함" isIncludesItems />
          </BorderBox>
        </Stack>
      </Stack>
      <LoadingModal isLoading={isLoadingDownloadRoute || isLoadingDownloadRouteItem} />
    </Modal>
  );
};

const IconBackground = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.RC10_2};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BorderBox = styled.div`
  width: 100%;

  padding: 40px 20px 42px;

  background: ${({ theme }) => theme.colors.RG00};
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};

  cursor: pointer;

  :hover {
    border: ${({ theme }) => `1px solid ${theme.colors.RC10}`};
    background: ${({ theme }) => theme.colors.RG08};
  }

  transition: all 0.5s;
`;

const Frame = ({ title, isIncludesItems }: { title: string; isIncludesItems?: boolean }) => {
  return (
    <Stack spacing={12}>
      <IconBackground>{isIncludesItems ? <IconRouteItemDownload /> : <IconRouteDownload />}</IconBackground>
      <Text styleName="body2" color="RG02">
        {title}
      </Text>
    </Stack>
  );
};
