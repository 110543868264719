export const USER_DATA: { [key: string]: string | Object } = {
  loginAccount: '아이디 (이메일)',
  password: '비밀번호',
  teamName: '소속 팀',
  name: '이름',
  phone: '휴대폰번호',
  marketing: '마케팅 정보 수신',
};

export const COMPANY_DATA: { [key: string]: string } = {
  name: '회사 이름',
  payment: '사업자 등록 번호',
  industryType: '산업 분야',
};
