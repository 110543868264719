import { useMutation, useQuery } from '@tanstack/react-query';

import { cancelDriverInvitation, deleteDriver, getDriverList, getInvitedDriverList, resendDriverInvite } from 'api';
import { Button } from 'components/Button';
import Divider from 'components/Divider';
import Modal from 'components/Modal';
import AddDriverModal from 'components/Modal/AddDriverModal';
import { CloseConfirmModal } from 'components/Modal/OrderDetailModal';
import QueryStatusModal, { QueryStatusModalProps } from 'components/Modal/QueryStatusModal';
import XlsxLoader from 'components/Modal/XlsxLoader';

import { Stack } from 'components/Stack';
import { OptionType } from 'components/Swap';

import InvitedDriverManagementTable from 'components/Table/InvitedDriverManagementTable';
import InvolvedDriverManagementTable from 'components/Table/InvolvedDriverManagementTable';
import { Text } from 'components/Typography';
import { ReactComponent as IcFillout } from 'constants/icon/fill_out.svg';
import { ReactComponent as IcPlus } from 'constants/icon/ic_add_member.svg';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';
import { ReactComponent as IcFilter } from 'constants/icon/ic_filter.svg';
import { ReactComponent as IcXlsx } from 'constants/icon/ic_xlsx.svg';

import { ReactComponent as IcMail } from 'constants/icon/icon_mail.svg';
import { IGetDriverListProps, ISelectorRequires, TAccountStatus, TOperationType, TS3 } from 'constants/types';
import { useTracking } from 'hooks/store/useTracking';
import { FilterSearchbar } from 'pages/OrderManagePage';
import { QueueSelectorContainer, QueueSelectorWrapper } from 'pages/Report/utils/ReportFilter';
import { ReportFilterContent } from 'pages/Report/utils/ReportFilterContent';
import GrayBox from 'pages/Setting/common/GrayBox';

import * as S from 'pages/Setting/common/style';
import Layout from 'pages/Setting/Layout';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export type TDriverSettingActions = 'deleteDriver' | 'deleteDriverError' | 'resend' | 'cancelInvite';
type TMenu = 'Involved' | 'Invited';
export const driver_menu: {
  title: string;
  key: TMenu;
}[] = [
  { title: '소속 드라이버', key: 'Involved' },
  { title: '초대한 드라이버', key: 'Invited' },
];

export const driver_operationType: Array<OptionType<TOperationType>> = [
  {
    label: '고정차',
    value: 'regular',
  },
  {
    label: '용차',
    value: 'backup',
  },
];
export const driver_status: Array<OptionType<TAccountStatus>> = [
  {
    label: '활성',
    value: 'activated',
  },
  {
    label: '비활성',
    value: 'deactivated',
  },
];

const filter_content: {
  [key in TMenu]: Array<
    ISelectorRequires<string> & {
      placeholder: string;
    }
  >;
} = {
  Invited: [
    { value: 'teamName', key: '소속 팀', placeholder: '팀 이름을 입력해주세요' },
    { value: 'driverName', key: '이름', placeholder: '이름을 입력해주세요' },
    { value: 'loginAccount', key: '아이디(휴대폰 번호)', placeholder: '아이디(휴대폰 번호)를 입력해주세요' },
    { value: 'skill', key: '특수 조건', placeholder: '특수 조건을 입력해주세요' },
  ],
  Involved: [
    { value: 'teamName', key: '소속 팀', placeholder: '팀 이름을 입력해주세요' },
    { value: 'driverName', key: '이름', placeholder: '이름을 입력해주세요' },
    { value: 'loginAccount', key: '아이디(휴대폰 번호)', placeholder: '아이디(휴대폰 번호)를 입력해주세요' },
    { value: 'areaName', key: '권역', placeholder: '권역 이름을 입력해주세요' },
    { value: 'skill', key: '특수 조건', placeholder: '특수 조건을 입력해주세요' },
  ],
};
const Driver = ({
  setSettingIsOpen,
  fatalCorrectionDetectorWithFence,
}: {
  setSettingIsOpen: TS3<boolean>;
  fatalCorrectionDetectorWithFence: Function;
}) => {
  const navigate = useNavigate();

  const [tab, setTab] = useState<TMenu>('Involved');
  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
  const [addFormIsOpen, setAddFormIsOpen] = useState<boolean>(false);
  const [isFilterFolded, setIsFilterFolded] = useState<boolean>(false);
  const [xlsxFormIsOpen, setXlsxFormIsOpen] = useState<boolean>(false);
  const [processingDriverAlertModalIsOpen, setProcessingDriverAlertModalIsOpen] = useState<boolean>(false);

  const [selectedDriver, setSelectedDriver] = useState<Array<any>>([]);
  const [filter, setFilter] = useState<IGetDriverListProps>({});

  const [callback_type, setCallback_type] = useState<TDriverSettingActions>('deleteDriver');
  const [closeConfirmModalIsOpen, setCloseConfirmModalIsOpen] = useState<boolean>(false);
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('설정>드라이버 관리');
  }, [setTitle]);

  const action_confirm_content: {
    [key in TDriverSettingActions]: {
      text: ReactNode;
      LTB: string;
      RTB: string;
      btnFunctions?: {
        RBT?: Function;
        LBT?: Function;
      };
    };
  } = {
    deleteDriver: {
      text: (
        <Stack spacing={30}>
          <Circle color="RG07" size={54}>
            <IcBin width={30} height={30} />
          </Circle>
          <Stack spacing={8}>
            <Text styleName="body1" color="RC04">
              선택하신 드라이버 {selectedDriver.length} 명을 삭제하시겠습니까?
            </Text>
            <Text styleName="body1">삭제 이후 복구는 불가하며 다시 초대할 수 있습니다.</Text>
          </Stack>
        </Stack>
      ),
      LTB: '취소',
      RTB: '삭제',
      btnFunctions: {
        RBT: () => {
          mutatedeleteDriver(selectedDriver.map(d => d.driverId));
        },
      },
    },
    cancelInvite: {
      text: (
        <Stack spacing={30}>
          <IcWarning width={44} height={44} />
          <Stack spacing={8}>
            <Text styleName="body1" color="RC04">
              선택하신 드라이버 {selectedDriver.length} 명의 초대를 취소하시겠습니까?
            </Text>
            <Text styleName="body1">초대 취소 이후에도 다시 초대할 수 있습니다.</Text>
          </Stack>
        </Stack>
      ),
      LTB: '취소',
      RTB: '초대 취소',
      btnFunctions: {
        RBT: () => {
          mutateCancelDriverInvite(selectedDriver.map(d => d.invitationId));
        },
      },
    },
    deleteDriverError: {
      text: '',
      LTB: '',
      RTB: '',
    },
    resend: {
      text: (
        <Stack spacing={30}>
          <Circle color={'RC03_1'} size={72}>
            <IcMail width={44} height={44} />
          </Circle>
          <Stack spacing={8}>
            <Text styleName="body1">선택하신 드라이버 {selectedDriver.length} 명에게 초대장을 재전송하시겠습니까?</Text>
            <Text styleName="body1">처음 초대 시 입력한 정보로 초대됩니다.</Text>
          </Stack>
        </Stack>
      ),
      LTB: '취소',
      RTB: '초대장 재전송',
      btnFunctions: {
        RBT: () => {
          mutateResendDriverInvite(selectedDriver.map(d => d.invitationId));
        },
      },
    },
  };
  const methods = useForm<IGetDriverListProps>({
    mode: 'onSubmit',
    defaultValues: {
      keyword: '',
      searchItem: filter_content[tab][0].value,
    },
  });

  useEffect(() => {
    methods.reset();
    setFilter({});
    setSelectedDriver([]);
  }, [methods, tab]);

  const { data: invDriverList, refetch: invDriverListRefetch } = useQuery(
    ['invDriverList', filter],
    () => getInvitedDriverList(filter),
    {
      onSuccess: res => {
        console.log(res);
      },
      enabled: tab === 'Invited' && methods.getValues('searchItem') !== 'areaName',
    }
  );
  // 설정 > 드라이버 관리 > 드라이버 조회
  const { data: driverList, refetch: driverListRefetch } = useQuery(
    ['driverList', filter],
    () => getDriverList(filter),
    {
      onSuccess: res => {
        console.log(res);
      },
      enabled: tab === 'Involved',
    }
  );

  const callbackModalSetter = (action: TDriverSettingActions) => {
    setCallback_type(action);
    setCloseConfirmModalIsOpen(true);
  };

  const TableRenderer = useMemo<{ [key in TMenu]: ReactElement }>(() => {
    return {
      Invited: (
        <InvitedDriverManagementTable
          data={invDriverList?.data ?? []}
          {...{
            selectedDriver,
            setSelectedDriver,
            callbackModalSetter,
          }}
        />
      ),
      Involved: (
        <InvolvedDriverManagementTable
          data={driverList?.data ?? []}
          {...{
            onControl: (
              open: boolean,
              props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>
            ) => {
              queryStatusUpdate(open, props);
              fatalCorrectionDetectorWithFence(true);

              driverListRefetch();
              setSelectedDriver([]);
            },
            selectedDriver,
            setSelectedDriver,
            callbackModalSetter,

            setProcessingDriverAlertModalIsOpen,
          }}
        />
      ),
    };
  }, [callback_type, driverList?.data, invDriverList?.data, selectedDriver]);

  const updateStatus = (name: string, content: Array<any>, index: number, setter: Function) => {
    let items = [...content];

    if (index === 0) {
      items[index].isSelected
        ? (items = items.map((d, index) => {
            return { ...d, isSelected: [false, true, ...new Array(content.length - 2).fill(false)][index] };
          }))
        : (items = items.map((d, index) => {
            return { ...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index] };
          }));

      setter(items);
    } else {
      const dupper: Array<any> = [...items.slice(1)];
      if (dupper.filter(x => x.isSelected).length === 1 && items[index].isSelected) return;

      items[index].isSelected = !items[index].isSelected;

      if (items.filter(x => x.isSelected).length === 0) return;

      let activated = items.slice(1, items.length).filter(x => x.isSelected).length;
      items[0].isSelected = activated === content.length - 1;

      if (items[0].isSelected) {
        for (let i = 1; i < items.length; i++)
          items = items.map((d, index) => {
            return { ...d, isSelected: [true, ...new Array(content.length - 1).fill(false)][index] };
          });
      }

      setter(items);
    }

    methods.setValue(
      name as any,
      items
        .filter(d => d.isSelected)
        .map(d => d.value)
        .toString(),
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  const [invitedOperationFilter, setInvitedOperationFilter] = useState<
    Array<{ [key in 'key' | 'value' | 'isSelected']: any }>
  >([
    { key: '전체', value: '', isSelected: true },
    { key: '고정차', value: 'regular', isSelected: false },
    { key: '용차', value: 'backup', isSelected: false },
  ]);
  const [involvedOperationFilter, setInvolvedOperationFilter] = useState<
    Array<{ [key in 'key' | 'value' | 'isSelected']: any }>
  >([
    { key: '전체', value: '', isSelected: true },
    { key: '고정차', value: 'regular', isSelected: false },
    { key: '용차', value: 'backup', isSelected: false },
  ]);
  const [driverStatusFilter, setDriverStatusFilter] = useState<Array<{ [key in 'key' | 'value' | 'isSelected']: any }>>(
    [
      { key: '전체', value: '', isSelected: true },
      { key: '활성', value: 'activated', isSelected: false },
      { key: '비활성', value: 'deactivated', isSelected: false },
    ]
  );

  const { mutate: mutatedeleteDriver, isLoading: mutatedeleteDriverIsLoading } = useMutation(deleteDriver, {
    onSuccess: () => {
      queryStatusUpdate(true, {
        status: 'success',
        string: `드라이버 삭제를 완료하였습니다.`,
      });
      fatalCorrectionDetectorWithFence();
      driverListRefetch();
      setSelectedDriver([]);
    },
    onError: (err: any) => {
      const error = err?.response?.data?.error as TErrorResponse;
      if (error.name === 'ProcessingData') setProcessingDriverAlertModalIsOpen(true);
      else queryStatusUpdate(true, { status: 'error', string: '드라이버 삭제를 완료하지 못하였습니다.' });
    },
  });
  const { mutate: mutateCancelDriverInvite, isLoading: mutateCancelDriverInviteIsLoading } = useMutation(
    cancelDriverInvitation,
    {
      onSuccess: () => {
        queryStatusUpdate(true, {
          status: 'success',
          string: `드라이버 ${selectedDriver.length} 명의 초대 취소를 완료하였습니다.`,
        });
        invDriverListRefetch();
        setSelectedDriver([]);
      },
      onError: () => {
        queryStatusUpdate(true, { status: 'error', string: '초대 취소를 완료하지 못하였습니다.' });
      },
    }
  );
  const { mutate: mutateResendDriverInvite, isLoading: mutateResendDriverInviteIsLoading } = useMutation(
    resendDriverInvite,
    {
      onSuccess: () => {
        queryStatusUpdate(true, {
          status: 'success',
          string: `드라이버 ${selectedDriver.length} 명에게 초대장 재전송을 완료하였습니다.`,
        });
        invDriverListRefetch();
        setSelectedDriver([]);
      },
      onError: () => {
        queryStatusUpdate(true, { status: 'error', string: '초대장 재전송을 완료하지 못하였습니다.' });
      },
    }
  );

  const [queryStatusModalIsOpen, setQueryStatusModalIsOpen] = useState<boolean>(false);
  const [queryStatusModalContent, setQueryStatusModalContent] = useState<
    Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'> | undefined
  >();

  const queryStatusUpdate = (
    open: boolean,
    props: Pick<QueryStatusModalProps, 'status' | 'string' | 'contnet' | 'action' | 'callback'>
  ) => {
    setQueryStatusModalContent({ ...props });
    setQueryStatusModalIsOpen(open);
  };

  const tabFilterContents = useMemo<{ [key in TMenu]: ReactNode }>(() => {
    return {
      Invited: (
        <Stack
          name="sub-filter-wrapper"
          sx={{
            margin: isFilterFolded ? '0' : '20px 0 0 0',
            minHeight: 'fit-content',
            maxHeight: isFilterFolded ? '0px' : '270px',
            transition: 'all .6s',
          }}
        >
          <Stack
            name="sub-filter-area"
            spacing={20}
            divider={<Divider color="RG06" />}
            align="start"
            sx={{
              opacity: isFilterFolded ? '0%' : '100%',
              visibility: isFilterFolded ? 'hidden' : 'visible',
              transition: 'opacity .5s ease',
            }}
          >
            <ReportFilterContent
              title="운영 유형"
              altInfo={`운영 유형 필터입니다.`}
              anchor="bottom"
              direction="row"
              gap={20}
            >
              <QueueSelectorWrapper direction={'row'} sx={{ width: '448px', marginLeft: '22px' }}>
                {invitedOperationFilter.map((d, index) => {
                  return (
                    <QueueSelectorContainer
                      key={d.key}
                      selected={d.isSelected}
                      onClick={() => {
                        updateStatus('operationType', invitedOperationFilter, index, setInvitedOperationFilter);
                      }}
                      len={7}
                    >
                      <Text styleName={'caption2'} color={methods.watch('operationType') === d.value ? 'RC02' : 'RG02'}>
                        {d.key}
                      </Text>
                    </QueueSelectorContainer>
                  );
                })}
              </QueueSelectorWrapper>
            </ReportFilterContent>
          </Stack>
        </Stack>
      ),
      Involved: (
        <Stack
          name="sub-filter-wrapper"
          sx={{
            margin: isFilterFolded ? '0' : '20px 0 0 0',
            minHeight: 'fit-content',
            maxHeight: isFilterFolded ? '0px' : '270px',
            transition: 'all .6s',
          }}
        >
          <Stack
            name="sub-filter-area"
            spacing={20}
            align="start"
            sx={{
              opacity: isFilterFolded ? '0%' : '100%',
              visibility: isFilterFolded ? 'hidden' : 'visible',
              transition: 'opacity .5s ease',
            }}
          >
            <ReportFilterContent
              title="운영 유형"
              altInfo={`운영 유형 필터입니다.`}
              anchor="bottom"
              direction="row"
              gap={20}
            >
              <QueueSelectorWrapper direction={'row'} sx={{ width: '448px', marginLeft: '22px' }}>
                {involvedOperationFilter.map((d, index) => {
                  return (
                    <QueueSelectorContainer
                      key={d.key}
                      selected={d.isSelected}
                      onClick={() => {
                        updateStatus('operationType', involvedOperationFilter, index, setInvolvedOperationFilter);
                      }}
                      len={7}
                    >
                      <Text styleName={'caption2'} color={methods.watch('operationType') === d.value ? 'RC02' : 'RG02'}>
                        {d.key}
                      </Text>
                    </QueueSelectorContainer>
                  );
                })}
              </QueueSelectorWrapper>
            </ReportFilterContent>
            <ReportFilterContent
              title="활성 상태"
              altInfo={`활성 상태 필터입니다.`}
              anchor="bottom"
              direction="row"
              gap={20}
            >
              <QueueSelectorWrapper direction={'row'} sx={{ width: '448px', marginLeft: '22px' }}>
                {driverStatusFilter.map((d, index) => {
                  return (
                    <QueueSelectorContainer
                      key={d.key}
                      selected={d.isSelected}
                      onClick={() => {
                        updateStatus('status', driverStatusFilter, index, setDriverStatusFilter);
                      }}
                      len={7}
                    >
                      <Text styleName={'caption2'} color={methods.watch('status') === d.value ? 'RC02' : 'RG02'}>
                        {d.key}
                      </Text>
                    </QueueSelectorContainer>
                  );
                })}
              </QueueSelectorWrapper>
            </ReportFilterContent>
          </Stack>
        </Stack>
      ),
    };
  }, [isFilterFolded, invitedOperationFilter, involvedOperationFilter, driverStatusFilter, methods, updateStatus]);

  return (
    <Layout
      attr="driver"
      title={'드라이버 관리'}
      desc={'드라이버를 초대하고 관리할 수 있습니다.'}
      ts={
        <Button
          type="button"
          variant="primary"
          h={32}
          w={154}
          icon={[IcPlus, { fill: '#FFF' }]}
          onClick={() => {
            setAddModalIsOpen(true);
          }}
        >
          드라이버 초대하기
        </Button>
      }
    >
      <Stack spacing={40}>
        <Stack>
          <Stack direction="row">
            {driver_menu.map((menu, index) => {
              const { title, key } = menu;
              return (
                <S.Tab key={index} index={index} isActive={key === tab} onClick={() => setTab(key)}>
                  {title}
                </S.Tab>
              );
            })}
          </Stack>
          <GrayBox sx={{ borderRadius: '0px 12px 12px' }}>
            <Stack direction="row" spacing={12}>
              <FilterSearchbar
                {...{
                  methods,
                  content: filter_content[tab],
                }}
              />
              <Button
                type="button"
                variant="tertiary"
                h={32}
                icon={[IcFilter, { width: 16, height: 16 }]}
                activated={!isFilterFolded}
                onClick={() => setIsFilterFolded(prev => !prev)}
              >
                상세 필터
              </Button>
              <Button
                type="button"
                variant="secondary"
                h={32}
                onClick={methods.handleSubmit(valid => {
                  let res = { ...valid };
                  if (!res.keyword) {
                    delete res.keyword;
                    delete res.searchItem;
                  }
                  if (!res.operationType) delete res.operationType;
                  if (!res.status) delete res.status;

                  setFilter({ ...res });
                })}
              >
                조회하기
              </Button>
            </Stack>

            {tabFilterContents[tab]}
          </GrayBox>
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: 40 }}>{TableRenderer[tab]}</Stack>

      <Modal
        title={''}
        isModalOpen={addModalIsOpen}
        setIsModalOpen={setAddModalIsOpen}
        ds="strong"
        padding={24}
        width={504}
      >
        <Stack>
          <Text styleName="title2" color="RG02" sx={{ padding: '20px 0 40px' }}>
            드라이버 초대 방식을 선택해주세요.
          </Text>
          <Stack direction="row" spacing={16} justify="space-between" sx={{ height: '144px' }}>
            <BorderBox
              onClick={() => {
                setAddModalIsOpen(false);
                setXlsxFormIsOpen(true);
              }}
              hover={{ bg: 'RG08', border: 'RC10' }}
            >
              <Stack spacing={12}>
                <IcXlsx />
                <Text styleName="body1" color="RG02">
                  여러 건 엑셀로 초대
                </Text>
              </Stack>
            </BorderBox>

            <BorderBox
              onClick={() => {
                setAddModalIsOpen(false);
                setAddFormIsOpen(true);
              }}
              hover={{ bg: 'RG08', border: 'RC03' }}
            >
              <Stack spacing={12}>
                <IcFillout />
                <Text styleName="body1" color="RG02">
                  한 건 직접 초대
                </Text>
              </Stack>
            </BorderBox>
          </Stack>
        </Stack>
      </Modal>

      <AddDriverModal<TOperationType>
        isOpen={addFormIsOpen}
        setIsOpen={setAddFormIsOpen}
        {...{
          driver_operationType,
          queryStatusUpdate,
          updateFunc: () => {
            setTab('Invited');
            invDriverListRefetch();
          },
        }}
      />

      {queryStatusModalContent && (
        <QueryStatusModal
          autoClose={3000}
          {...{
            ...queryStatusModalContent,
            isOpen: queryStatusModalIsOpen,
            setIsOpen: setQueryStatusModalIsOpen,
          }}
        />
      )}

      <CloseConfirmModal
        plain
        width={504}
        isLoadingModal={mutatedeleteDriverIsLoading}
        isOpen={closeConfirmModalIsOpen}
        setIsOpen={setCloseConfirmModalIsOpen}
        text={action_confirm_content[callback_type].text}
        RBT={action_confirm_content[callback_type].RTB}
        LBT={action_confirm_content[callback_type].LTB}
        btnFunctions={action_confirm_content[callback_type].btnFunctions}
      />

      <Modal
        padding={24}
        width={503}
        isModalOpen={xlsxFormIsOpen}
        setIsModalOpen={setXlsxFormIsOpen}
        // confirm={{
        //   value: (!isDownloadedErrorFile && isXlsxError) || showPrompt,
        //   message: (
        //     <Text styleName="subheadline2" color="RG02">
        //       <Highlight color="RC04">업로드 실패 엑셀을 다운로드 하지 않았습니다.</Highlight>
        //       <br />
        //       다운로드를 하시겠습니까?
        //     </Text>
        //   ),
        //   LBT: '닫기',
        //   RBT: '확인',
        // }}
      >
        <XlsxLoader
          scope="roouty_driver"
          {...{
            setXlsxFormIsOpen,
            registerCallbackFunction: () => {
              setTab('Invited');
              invDriverListRefetch();
            },
          }}
        />
      </Modal>

      <CloseConfirmModal
        width={504}
        isOpen={processingDriverAlertModalIsOpen}
        setIsOpen={setProcessingDriverAlertModalIsOpen}
        targetSetIsOpen={() => {}}
        icon={<IcWarning width={44} height={44} />}
        text={
          <Stack spacing={8}>
            <Text styleName="body1" color="RC02">
              선택한 드라이버에게 배차된 주행이 있습니다.
            </Text>
            <Text styleName="body1" color="RC04">
              먼저 배차취소를 하시거나 작업을 완료한 뒤 다시 시도해주세요.
            </Text>
            <Text styleName="body2">{`배차된 주행은 [모니터링] 에서 확인할 수 있습니다.`}</Text>
          </Stack>
        }
        btnFunctions={{
          RBT: () => {
            setSettingIsOpen(false);
            navigate('/manage/control?landing');
          },
        }}
        RBT={'모니터링으로 이동'}
        LBT={'닫기'}
      />
    </Layout>
  );
};

export default Driver;

const BorderBox = styled(Stack)<{
  hover: {
    bg: string;
    border: string;
  };
}>`
  justify-content: center;
  width: 100%;
  height: 144px;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.RG06}`};
  cursor: pointer;

  :hover {
    border: ${({ theme, hover }) => `1px solid ${theme.colors[hover.border]}`};
    background: ${({ theme, hover }) => theme.colors[hover.bg]};
  }

  transition: all 0.5s;
`;

const Circle = styled.div<{
  color: TThemeColor;
  size: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, color, size }) => ({
    backgroundColor: theme.colors[color],
    width: size,
    height: size,

    borderRadius: `calc(${size}px / 2)`,
  })}
`;
