import Modal from 'components/Modal';

import { Text } from 'components/Typography';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { ReactComponent as Warning } from 'constants/icon/ic_file_upload_warning.svg';
import { useNavigate } from 'react-router-dom';

interface IExceedRequestVerifyCodeModal {
  isModalOpen: boolean;
  callback: Function;
}

export const ExceedRequestVerifyCodeModal = (props: IExceedRequestVerifyCodeModal) => {
  const { isModalOpen, callback } = props;
  const navigate = useNavigate();
  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={() => {}} width={504} height={'100%'} plain ds="strong">
      <Stack justify="center" sx={{ padding: '44px 24px 24px' }}>
        <Warning />
        <Stack justify="center" sx={{ marginTop: '30px' }}>
          <Text styleName="body2" color="RG02">
            일일 인증 횟수를 초과하였습니다. 24시간 이후에 다시 시도해 주세요.
          </Text>
          <Text styleName="body2" color="RC02" sx={{ marginTop: '8px' }}>
            이메일 인증 코드 발송은 24시간 이내에 15회 입니다.
          </Text>
          <Text styleName="body2" color="RC02" sx={{ marginTop: '8px' }}>
            이메일을 정확하게 입력했는지 확인해주세요.
          </Text>
        </Stack>
        <Button
          type="button"
          height={40}
          fullWidth
          variant="seventh"
          styleName="body1"
          color="RG3"
          sx={{ marginTop: '40px' }}
          onClick={() => {
            callback();
            navigate('/landing', { replace: true });
          }}
        >
          확인
        </Button>
      </Stack>
    </Modal>
  );
};
