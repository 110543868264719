import styled from 'styled-components';

const PlanCard = styled.div<{ color: string; width?: string; height: string; emphasis?: boolean }>`
  width: 413px;
  max-width: 413px;
  min-width: 315px;
  border-radius: 8px 8px 0 0;
  ${({ width }) => width && { width: width, 'max-width': width, 'min-width': width }}
  ${({ height }) => ({ height: height })} 

  position: relative;
  & > .linear {
    position: absolute;
    width: 100%;
    height: 16px;

    border-radius: 8px 8px 0 0;
    background-color: ${({ theme, color }) => color && (theme?.colors[color] ?? color)};
  }
  & > .content {
    width: 100%;
    height: 100%;
    padding: 30px 24px;
    border-radius: 8px;
    ${({ emphasis, theme }) => ({
      border: `1px solid ${theme.colors.RG06}`,
      borderTop: 'none',

      backgroundColor: theme.colors[emphasis ? 'RC03_1' : 'RG00'],
    })}
  }
`;

export { PlanCard };
