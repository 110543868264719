import { PAYMENT_QUERIES } from './payment.queries';
import { API } from '../../../api/ky';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PaymentStatusUnion, PaymentTypeUnion, PlanStatusUnion } from '../../../types/payment/union';

type FetchGetPaymentMyResponse = {
  pricingId: number;
  name: PlanStatusUnion;
  interval: PaymentTypeUnion;
  startedAt: string;
  expiredAt: string;
  paymentStatus: PaymentStatusUnion;
  cancellation?: {
    pricingId: number;
    name: string;
    dueDate: string;
    createdAt: string;
  };
  downgrade?: {
    pricingId: number;
    name: string;
    dueDate: string;
    createdAt: string;
  };
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetPaymentMy>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof PAYMENT_QUERIES.GET_PAYMENT_MY>;
};

const fetchGetPaymentMy = async ({ queryKey: [{ endPoint }] }: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchGetPaymentMyResponse>();

const useGetPaymentMy = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    PAYMENT_QUERIES.GET_PAYMENT_MY(),
    fetchGetPaymentMy,
    options
  );

export { fetchGetPaymentMy, useGetPaymentMy };
export type { FetchGetPaymentMyResponse };
