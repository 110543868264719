import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';

import { ReactComponent as IcRemove } from 'constants/icon/input_remove.svg';
import {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  FocusEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import * as S from './style';

export interface InputProps {
  name: string;
  label?: string | ReactNode;
  placeholder: string;
  validation?: object;
  sx?: CSSProperties;

  onClick?: () => void;
  height?: number;
  hasError?: boolean;
  errors: object;

  disabled?: boolean;
  rightArea?: string | ReactNode;
  field?: boolean;
  autoComplete?: 'on' | 'off';
  variant?: 'default' | 'second' | 'third' | 'fourth' | 'useDisabled';
  xAxisPadding?: number;
  bottomLabel?: string | ReactNode;
  removeBtn?: () => void;
}

export interface SelectProps {
  field?: boolean;
  height?: number;

  label?: string | ReactNode;
  rightArea?: string | ReactNode;
}

function updateEventHandler(
  request: { [key in keyof TInputComponentType]?: boolean },
  eventHandler: TInputComponentType,
  setEventHandler: Dispatch<SetStateAction<TInputComponentType>>
) {
  const _state = Object.assign({}, eventHandler, request);
  setEventHandler(_state);
}

export const Input = ({
  reset,
  watch,
  validation,
  register,
  name,
  sx,
  label,
  rightArea,
  errors,
  bottomLabel,
  autoComplete,
  isInject,
  ...props
}: any): ReactElement => {
  const registerField = register?.(name, validation) || {};
  const initialState: TInputComponentType = { isFocus: false, mouseOver: false, isInject: false };
  const [eventHandler, setEventHandler] = useState<TInputComponentType>(initialState);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    updateEventHandler({ isInject }, eventHandler, setEventHandler);
  }, [isInject]);

  useEffect(() => {
    console.log('name', name, props.hasError);
  }, [name, props.hasError]);

  return (
    <S.InputWrapper spacing={6} style={sx}>
      {(label || rightArea) && (
        <S.LabelArea {...{ rightArea, label }}>
          {label && <S.Label htmlFor={name}>{label}</S.Label>}
          {rightArea && (
            <S.RightArea contentType={typeof rightArea.rightContent} onClick={rightArea.rightFunc}>
              {rightArea.rightContent}
            </S.RightArea>
          )}
        </S.LabelArea>
      )}
      <S.InputAdornment>
        <S.Input
          {...registerField}
          ref={(event: HTMLInputElement) => {
            const { ref } = registerField;
            ref?.(event);
            inputRef.current = event;
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            registerField?.onChange?.(e);
            props?.onChange && props.onChange(e);
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            if (eventHandler.isFocus) {
              const request = { isFocus: false };
              updateEventHandler(request, eventHandler, setEventHandler);
            }
            registerField?.onBlur?.(e);
            props?.onBlur && props.onBlur(e);
          }}
          onFocus={() => {
            if (eventHandler.isFocus === false) {
              const request = { isFocus: true };

              updateEventHandler(request, eventHandler, setEventHandler);
            }
          }}
          {...props}
          autoComplete={autoComplete ? autoComplete : 'off'}
          spellCheck="false"
        />
        {props?.removeBtn && (
          <IcRemove
            width={20}
            height={20}
            onMouseOver={() => {
              if (eventHandler.isFocus) {
                const request = { mouseOver: true };
                updateEventHandler(request, eventHandler, setEventHandler);
              }
            }}
            onMouseOut={() => {
              if (eventHandler.mouseOver) {
                const request = { mouseOver: false };
                updateEventHandler(request, eventHandler, setEventHandler);
              }
            }}
            onClick={e => {
              props?.removeBtn(e);
              !isInject && inputRef.current!.focus();
            }}
            style={{
              cursor: 'pointer',
              visibility: Object.values(eventHandler).every(value => value === false) ? 'hidden' : 'visible',
            }}
          />
        )}
      </S.InputAdornment>
      {props?.hasError && errors?.message ? (
        <Text styleName="caption3" color="RC04" sx={{ fontSize: '10px', display: 'flex', alignItems: 'center' }}>
          <ErrorMarking style={{ marginRight: '6px' }} />
          {errors?.message}
        </Text>
      ) : !props?.hasError && bottomLabel ? (
        <Text styleName="caption3" color="RG04">
          {bottomLabel}
        </Text>
      ) : (
        <></>
      )}
    </S.InputWrapper>
  );
};

export const Select = ({
  register,
  setValue,
  options,
  name,
  rightArea,
  label,
  defaultValue,
  selectBottomLabel,
  combobox,
  ...props
}: any): ReactElement => {
  const [key, setKey] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setKey(options.filter((d: { [key: string]: any }) => d.value === JSON.parse(defaultValue))[0]?.key);
    }
    return () => {};
  }, []);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef?.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  return (
    <S.InputWrapper>
      {isOpen && (
        <PopupYIndexFixer>
          <div></div>
        </PopupYIndexFixer>
      )}
      <S.LabelArea {...{ rightArea, label }}>
        {label && <S.Label htmlFor={name}>{label}</S.Label>}
        {rightArea && <S.RightArea onClick={rightArea.rightFunc}>{rightArea.rightContent}</S.RightArea>}
      </S.LabelArea>
      <Stack sx={{ width: '100%', position: 'relative', marginTop: '6px' }}>
        <S.Input {...register(name)} {...props} style={{ display: 'none' }} />

        <S.InputAdornment>
          <S.Input
            {...props}
            readOnly={!combobox}
            value={key}
            onChange={(e: any) => setKey(e.target.value)}
            autoComplete="off"
            spellCheck="false"
            onClick={() => {
              setIsOpen(prev => !prev);
            }}
          />
          {props?.removeBtn && (
            <IcRemove
              width={25}
              height={25}
              style={{
                zIndex: 0,
              }}
              onClick={() => {
                setKey('');
                setValue(name, props?.removeValue, { shouldValidate: true, shouldDirty: true });
              }}
            />
          )}
        </S.InputAdornment>
        {isOpen && (
          <SelectContainer ref={targetRef}>
            <Stack>
              {(options as Array<any>)
                .filter((d: { value: string; key: string }) => (combobox ? d.key.includes(key) : true))
                .map((d: { value: string; key: string }) => (
                  <SelectItem
                    key={`${d.key}-select-item`}
                    onClick={() => {
                      setKey(d.key);
                      setValue(name, d.value, { shouldValidate: true, shouldDirty: true });
                      setIsOpen(false);
                    }}
                  >
                    <Stack spacing={8} direction="row-reverse">
                      <Text styleName={'caption3'} color={'RG03'}>
                        {d.key}
                      </Text>
                    </Stack>
                  </SelectItem>
                ))}
              {selectBottomLabel && <S.SelectBottomLabel>{selectBottomLabel}</S.SelectBottomLabel>}
            </Stack>
          </SelectContainer>
        )}
      </Stack>
    </S.InputWrapper>
  );
};

export const SelectContainer = styled.div<{ width?: string; top?: string; height?: string }>`
  display: flex;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  top: ${({ top }) => (top ? top : '50px')};
  z-index: 3;
  left: 0;

  width: ${({ width }) => (width ? width : '100%')};

  max-height: ${({ height }) => (height ? height : 'auto')};
  ${({ theme }) => theme.scrollStyle.vertical};
`;

export const SelectItem = styled.div`
  width: 100%;
  min-height: 32px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
  }
`;
