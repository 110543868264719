import { createElement, Dispatch, SetStateAction } from 'react';
import { DriverIdUnSelected, IEqualOptimizeResponses } from '../../constants/types';
import { Marker } from 'react-map-gl';
import { DynamicSvg } from '../DynamicSvg';
import { DynamicSvgPresets } from '../DynamicSvg/Presets';

import styled from 'styled-components';
import { ColorPalette } from '../../constants/colorPalette';
import { Stack } from '../Stack';
import { Text } from '../Typography';
import theme from '../../constants/theme';
import Divider from '../Divider';
import { Tag } from '../Tag';
import { shipment_type } from '../../constants/commons';
import { ReactComponent as IcStops } from '../../constants/icon/ic_stops.svg';
import { ReactComponent as ToolTipBottom } from '../../constants/icon/bg_tooltip_bottom.svg';
import dayjs from 'dayjs';

import { ICoordIncludedOrder } from './RouteControlWidgets+JSX';
import { validateControlOrderStatus, validateOrderPriority } from '../../util/validate/validateStatus';
import { ReactComponent as High } from 'constants/icon/ic_priority_high.svg';
import { ReactComponent as Medium } from 'constants/icon/ic_priority_medium.svg';
import { ReactComponent as Low } from 'constants/icon/ic_priority_low.svg';
import { ReactComponent as ToolTipLeft } from '../../constants/icon/bg_tooltip_left.svg';
import { UNBATCHED_COLUMN_KEY } from 'components/Timeline';

const UNSELECTED_OPACITY = 0.2;

export const StartEndLocationMarker = ({
  data,
  selectedDriver,
}: {
  data?: IEqualOptimizeResponses;
  selectedDriver: number;
}) => {
  if (data && data.driverList.length > 0) {
    let sCoordinates: [number, number][] = [];
    let sOpacity: number[] = [];
    let eCoordinates: [number, number][] = [];
    let eOpacity: number[] = [];

    let resultMarkers: JSX.Element[] = [];
    data.driverList.forEach(driver => {
      if (driver.vehicle && driver.vehicle.startCoordinate) {
        sCoordinates.push(driver.vehicle.startCoordinate.coordinates);
        if (driver.driverId === selectedDriver || selectedDriver === DriverIdUnSelected) sOpacity.push(1);
        else sOpacity.push(UNSELECTED_OPACITY);
      }
      if (driver.vehicle && driver.vehicle.endCoordinate) {
        eCoordinates.push(driver.vehicle.endCoordinate.coordinates);
        if (driver.driverId === selectedDriver || selectedDriver === DriverIdUnSelected) eOpacity.push(1);
        else eOpacity.push(UNSELECTED_OPACITY);
      }
    });

    eCoordinates.forEach((coord, index) => {
      resultMarkers.push(
        <Marker longitude={coord[0]} latitude={coord[1]} key={`marker-end-${index}-${coord[0]}=${coord[1]}`}>
          <MarkerContainer isLarge={false} style={{ opacity: eOpacity[index] }}>
            <DynamicSvg info={DynamicSvgPresets.end} useNewerOutline={true} />
          </MarkerContainer>
        </Marker>
      );
    });

    sCoordinates.forEach((coord, index) => {
      resultMarkers.push(
        <Marker longitude={coord[0]} latitude={coord[1]} key={`marker-start-${index}-${coord[0]}=${coord[1]}`}>
          <MarkerContainer isLarge={false} style={{ opacity: sOpacity[index] }}>
            <DynamicSvg info={DynamicSvgPresets.start} useNewerOutline={true} />
          </MarkerContainer>
        </Marker>
      );
    });

    return resultMarkers;
  } else return null;
};

export function parseCoordIncludedEditedOrder({ data, editedOrders }: { data: any; editedOrders: number[] }) {
  let coordIncludedOrderList: ICoordIncludedOrder[] = [];

  data.driverList.forEach((driver: any) => {
    if (driver.orderList && driver.orderList.length > 0) {
      driver.orderList.forEach((order: any) => {
        if (order.coordinate && order.coordinate.coordinates) {
          let overlappedArr = coordIncludedOrderList.filter(x => {
            let bLoc = x.orderInfo.coordinate!.coordinates;
            let cLoc = order.coordinate!.coordinates;

            return bLoc[0] === cLoc[0] && bLoc[1] === cLoc[1];
          });
          let isOverlapped = overlappedArr.length > 0;
          if (!isOverlapped && editedOrders.includes(order.orderId)) {
            coordIncludedOrderList.push({
              orderInfo: order,
              driverInfo: driver,
              overlapped: [order],
              markerStatus: '',
            });
          } else if (editedOrders.includes(order.orderId)) {
            overlappedArr[0].overlapped.push(order);
          }
        }
      });
    }
  });
  return coordIncludedOrderList;
}

export function parseCoordIncludedOrder({ data }: { data: any }) {
  let coordIncludedOrderList: ICoordIncludedOrder[] = [];

  data.driverList.forEach((driver: any) => {
    if (driver.orderList && driver.orderList.length > 0) {
      driver.orderList.forEach((order: any) => {
        if (order.coordinate && order.coordinate.coordinates) {
          let overlappedArr = coordIncludedOrderList.filter(x => {
            let bLoc = x.orderInfo.coordinate!.coordinates;
            let cLoc = order.coordinate!.coordinates;

            return bLoc[0] === cLoc[0] && bLoc[1] === cLoc[1];
          });
          let isOverlapped = overlappedArr.length > 0;

          if (!isOverlapped) {
            coordIncludedOrderList.push({
              orderInfo: order,
              driverInfo: driver,
              overlapped: [order],
              markerStatus: '',
            });
          } else {
            overlappedArr[0].overlapped.push(order);
          }
        }
      });
    }
  });
  return coordIncludedOrderList;
}

export const OrdersMarker = ({
  data,
  editedOrder,
  focusedMarker,
  setFocusedMarker,
  selectedDriver,
  mapMoveTo,
  setSelectedOrder,
  setSelectedDriverByOrderId,
}: {
  data?: IEqualOptimizeResponses;
  editedOrder: { orderId: number; key: string }[];
  focusedMarker: ICoordIncludedOrder[];
  setFocusedMarker: Dispatch<SetStateAction<ICoordIncludedOrder[]>>;
  selectedDriver: number;
  mapMoveTo: (pos: [number, number]) => void;
  setSelectedOrder: Dispatch<SetStateAction<number[]>>;
  setSelectedDriverByOrderId: (orderId: number) => void;
}) => {
  if (data && data.driverList.length > 0) {
    let resultMarkers: JSX.Element[] = [];

    let coordIncludedOrderList = parseCoordIncludedOrder({ data });
    coordIncludedOrderList.forEach(order => {
      let focusedMarkerOrderIds = focusedMarker.map(x => x.orderInfo.orderId ?? -1);
      let isFocused = focusedMarkerOrderIds.includes(order.orderInfo.orderId ?? -33);
      let isSelected = selectedDriver === order.driverInfo.driverId;
      let opacity = selectedDriver === DriverIdUnSelected ? 1 : isSelected ? 1 : UNSELECTED_OPACITY;
      let isEdited = false;
      order.overlapped.forEach(x => {
        if (editedOrder.map(x => x.orderId).includes(x.orderId ?? -33)) isEdited = true;
      });
      // let lastIndex = (order.overlapped.at(-1)?.route?.internal?.routeIndex ?? '').toString();

      let finalDriverId = order.driverInfo.driverId;
      let lastIndex = `${order.orderInfo.route?.internal?.routeIndex ?? ''}`;
      if (order.overlapped.length > 1) {
        if (selectedDriver !== DriverIdUnSelected) {
          let gs = order.overlapped.filter(x => selectedDriver === x.originDriverId);
          if (gs.length > 0) {
            lastIndex = gs.at(0)?.route?.internal?.routeIndex + '';
            finalDriverId = gs.at(0)?.originDriverId || finalDriverId;
            if (gs.at(0)?.originDriverId) {
              opacity = 1;
            }
          }
        }
      }
      // console.log(focusedMarker.at(0)?.orderInfo.route.internal?.routeIndex);

      if (isEdited) {
        let key: string[] = [];
        order.overlapped.forEach(x => {
          let f = editedOrder.filter(y => y.orderId === x.orderId);
          if (f.length > 0) key.push(f[0].key);
        });
        let parsedKey = key.map(x => {
          if (x === UNBATCHED_COLUMN_KEY) return -44;
          else return parseInt(x.split('-')[1]);
        });

        let opacity =
          selectedDriver === DriverIdUnSelected
            ? 1
            : parsedKey.includes(selectedDriver)
            ? 1
            : isFocused
            ? 1
            : UNSELECTED_OPACITY;
        resultMarkers.push(
          <Marker
            longitude={order.orderInfo.coordinate!.coordinates[0]}
            latitude={order.orderInfo.coordinate!.coordinates[1]}
            style={{
              zIndex: isFocused ? 5 : isSelected ? 4 : 1,
            }}
            key={`order-edited-marker-${order.orderInfo.orderId}`}
          >
            <MarkerContainer isLarge={true} style={{ opacity }}>
              {!key.includes(UNBATCHED_COLUMN_KEY) && (
                <DynamicSvg
                  info={{
                    ...DynamicSvgPresets.orderSelected,
                    text: 'N',
                    borderColor: 'rgba(37, 40, 47, 0.2)',
                    chip: {
                      stacked: order.overlapped.length > 1,
                      completed: false,
                      skill: !!order.orderInfo.skill,
                      time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                      pickup: order.orderInfo.shipmentType === 'pickup',
                      delivery: false,
                    },
                  }}
                />
              )}
              {key.includes(UNBATCHED_COLUMN_KEY) && (
                <DynamicSvg
                  info={{
                    markerType: 'waiting',
                    markerColor: '#769DFF',
                    markerSize: 'large',
                    borderColor: 'white',
                    chip: {
                      stacked: order.overlapped.length > 1,
                      completed: false,
                      skill: !!order.orderInfo.skill,
                      time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                      pickup: order.orderInfo.shipmentType === 'pickup',
                      delivery: false,
                    },
                  }}
                />
              )}
            </MarkerContainer>

            <MarkerClickListener
              onClick={e => {
                e.stopPropagation();
                if (isFocused) {
                  setFocusedMarker([]);
                  setSelectedOrder([]);

                  setSelectedDriverByOrderId(-1);
                } else {
                  if (order.orderInfo.orderId) {
                    let orders = order.overlapped.filter(x => x.orderId).map(x => x.orderId!);
                    setSelectedDriverByOrderId(order.orderInfo.orderId);
                    setSelectedOrder(orders);
                  }
                  setFocusedMarker([]);
                }
                if (order.orderInfo.coordinate) mapMoveTo(order.orderInfo.coordinate.coordinates);
              }}
            />
          </Marker>
        );
      } else {
        resultMarkers.push(
          <Marker
            longitude={order.orderInfo.coordinate!.coordinates[0]}
            latitude={order.orderInfo.coordinate!.coordinates[1]}
            style={{
              zIndex: isFocused ? 5 : isSelected ? 4 : 1,
            }}
            key={`order-marker-${order.orderInfo.orderId}`}
          >
            {isFocused && (
              <MarkerContainer isLarge={true}>
                {order.driverInfo.driverId && lastIndex !== '' && (
                  <DynamicSvg
                    info={{
                      ...DynamicSvgPresets.orderSelected,
                      text: lastIndex,
                      borderColor: 'rgba(37, 40, 47, 0.2)',
                      chip: {
                        stacked: order.overlapped.length > 1,
                        completed: false,
                        skill: !!order.orderInfo.skill,
                        time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                        pickup: order.orderInfo.shipmentType === 'pickup',
                        delivery: false,
                      },
                    }}
                  />
                )}

                {(!order.driverInfo.driverId || lastIndex == '') && (
                  <DynamicSvg
                    info={{
                      markerType: 'waiting',
                      markerColor: '#769DFF',
                      markerSize: 'large',
                      borderColor: 'white',
                      chip: {
                        stacked: order.overlapped.length > 1,
                        completed: false,
                        skill: !!order.orderInfo.skill,
                        time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                        pickup: order.orderInfo.shipmentType === 'pickup',
                        delivery: false,
                      },
                    }}
                  />
                )}
              </MarkerContainer>
            )}
            {!isFocused && (
              <MarkerContainer isLarge={false} style={{ opacity }}>
                {order.driverInfo.driverId && lastIndex !== '' && (
                  <DynamicSvg
                    useNewerOutline={true}
                    info={{
                      markerType: 'order',
                      markerColor: ColorPalette.getColor(finalDriverId),
                      markerSize: 'normal',
                      borderColor: 'rgba(37, 40, 47, 0.2)',
                      text: lastIndex,
                      chip: {
                        stacked: order.overlapped.length > 1,
                        completed: false,
                        skill: !!order.orderInfo.skill,
                        time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                        pickup: order.orderInfo.shipmentType === 'pickup',
                        delivery: false,
                      },
                    }}
                  />
                )}

                {(!order.driverInfo.driverId || lastIndex === '') && (
                  <DynamicSvg
                    useNewerOutline={true}
                    info={{
                      markerType: 'waiting',
                      markerColor: 'white',
                      markerSize: 'normal',
                      borderColor: '#B2B8CC',
                      chip: {
                        stacked: order.overlapped.length > 1,
                        completed: false,
                        skill: !!order.orderInfo.skill,
                        time: !!(order.orderInfo.desiredTimeEnd || order.orderInfo.desiredTimeStart),
                        pickup: order.orderInfo.shipmentType === 'pickup',
                        delivery: false,
                      },
                    }}
                  />
                )}
              </MarkerContainer>
            )}
            <MarkerClickListener
              onClick={e => {
                e.stopPropagation();
                if (isFocused) {
                  setFocusedMarker([]);
                  setSelectedOrder([]);
                  setSelectedDriverByOrderId(-1);
                } else {
                  if (order.orderInfo.orderId) {
                    let orders = order.overlapped.filter(x => x.orderId).map(x => x.orderId!);
                    setSelectedDriverByOrderId(order.orderInfo.orderId);
                    setSelectedOrder(orders);
                  }
                  setFocusedMarker([]);
                }
                if (order.orderInfo.coordinate) mapMoveTo(order.orderInfo.coordinate.coordinates);
              }}
            />
          </Marker>
        );
      }
    });

    return resultMarkers;
  } else return null;
};

export const FocusedMarkerInfoWindow = ({
  order,
  showDetail,
  setSelectedOrder,
}: {
  order: ICoordIncludedOrder[];
  showDetail?: (orderId: number) => void;
  setSelectedOrder: (orderId: number) => void;
}) => {
  if (!order) return null;

  return order.map(orderItem => {
    return (
      <Marker
        longitude={orderItem.orderInfo.coordinate!.coordinates[0]}
        latitude={orderItem.orderInfo.coordinate!.coordinates[1]}
        style={{ zIndex: 4 }}
        onClick={e => {
          e.originalEvent.stopPropagation();
        }}
      >
        <MarkerContainer isLarge={false}>
          <ToolTipContainer>
            <Stack spacing={6} direction={'row'} sx={{ padding: '9px 9px 4px 9px' }}>
              <IcStops />
              <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                {orderItem?.orderInfo.address}
              </Text>
            </Stack>
            {orderItem?.overlapped.map((item, index) => {
              let { key, name } = validateControlOrderStatus(item.status as any) ?? { key: 'key', name: 'name' };
              let priority = validateOrderPriority(item.priority as any) ?? { key: 'key', name: 'name' };
              let routeIndex = (item.route && item.route.internal?.routeIndex) ?? 0;
              if (item.originDriverId === 0) routeIndex = 0;
              return (
                <Stack
                  key={`overlapped-${key + index}-marker-info`}
                  sx={{
                    padding: '4px 8px 6px 8px',
                    background: index % 2 === 0 ? theme.colors.RG07 : theme.colors.RG00,
                    cursor: 'pointer',
                  }}
                  spacing={4}
                  onClick={() => {
                    item && item.orderId && setSelectedOrder(item.orderId);
                  }}
                >
                  <Stack spacing={6} direction={'row'} align={'start'}>
                    <Text styleName={'tooltip1'} color={'RC02'} sx={{ fontFamily: 'Pretendard' }}>
                      {`${routeIndex}`.padStart(2, '0')}
                    </Text>
                    <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                      {item.detailAddress || '-'}
                    </Text>
                  </Stack>
                  <Stack
                    spacing={6}
                    divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                    align={'center'}
                    justify={'start'}
                    direction={'row'}
                  >
                    <Stack align={'center'} direction={'row'} spacing={6} sx={{ width: 'fit-content' }}>
                      <Tag
                        outline
                        active={false}
                        styleName="tooltip1"
                        status={key as any}
                        sx={{
                          pointerEvents: 'none',
                          minWidth: '45px',
                          width: '45px',
                          padding: 0,
                          fontFamily: 'Pretendard',
                          borderRadius: '4px',
                        }}
                      >
                        {name}
                      </Tag>
                      {createElement(shipment_type.find(d => d.value === item.shipmentType)?.icon['ff'] ?? 'div', {
                        width: 12,
                        height: 12,
                      })}
                      {priority.name === '높음' ? <High width={12} height={12} /> : null}
                      {priority.name === '보통' ? <Medium width={14} height={12} /> : null}
                      {priority.name === '낮음' ? <Low width={12} height={12} /> : null}
                    </Stack>
                    <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                      도착예정 {item.route && dayjs(item.route?.internal?.eta).format('HH : mm')}
                    </Text>

                    <Stack
                      sx={{
                        background: theme.colors.RG00,
                        border: `1px solid ${theme.colors.RG06}`,
                        width: '45px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        if (item && item.orderId) {
                          showDetail?.(item.orderId);
                          setSelectedOrder(item.orderId);
                        }
                      }}
                    >
                      <Text styleName={'tooltip1'} color={'RG03'} sx={{ fontFamily: 'Pretendard' }}>
                        상세보기
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </ToolTipContainer>
          <ToolTipBottom style={{ position: 'absolute', bottom: '69px', left: '-8px' }} />
        </MarkerContainer>
      </Marker>
    );
  });
};

export const FocusedPolylineInfoWindow = ({
  feature,
  setFeature,
  selectDriver,
}: {
  feature: any[] | null;
  setFeature: Dispatch<SetStateAction<any>>;
  selectDriver: (driverId: number) => void;
}) => {
  if (feature === null || feature.length === 0) return null;
  return (
    <Marker
      longitude={feature[0].coord.lng}
      latitude={feature[0].coord.lat}
      style={{ zIndex: 4 }}
      onClick={e => {
        e.originalEvent.stopPropagation();
      }}
    >
      <MarkerContainer isLarge={false}>
        <PolyToolTipContainer>
          {feature.map((item, index) => {
            return (
              <Stack
                key={`overlapped-polyline-${index}-info`}
                sx={{
                  padding: '7px 11px 7px 11px',
                  background: index % 2 === 1 ? theme.colors.RG07 : theme.colors.RG00,
                  cursor: 'pointer',
                }}
                direction={'column'}
              >
                <Stack align={'center'} justify={'start'} direction={'row'}>
                  <div
                    style={{
                      width: '11.2px',
                      height: '11.2px',
                      marginRight: '6.4px',
                      borderRadius: '2px',
                      background: ColorPalette.getColor(item.properties.driverId),
                    }}
                  />
                  <Text styleName={'tooltip1'} color={'RG02'} sx={{ fontFamily: 'Pretendard' }}>
                    {item.properties.driverName}
                  </Text>
                  <div style={{ flex: 1 }} />
                  <Stack
                    sx={{
                      background: theme.colors.RC03_1,
                      width: '56px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setFeature(null);
                      selectDriver(item.properties.driverId);
                    }}
                  >
                    <Text styleName={'tooltip1'} color={'RC02'} sx={{ fontFamily: 'Pretendard' }}>
                      자세히 보기
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </PolyToolTipContainer>
        <ToolTipLeft style={{ position: 'absolute', bottom: '-9px', left: '13px' }} />
      </MarkerContainer>
    </Marker>
  );
};

const MarkerContainer = styled.div<{ isLarge: boolean }>`
  width: 0;
  height: 0;
  position: relative;
  pointer-events: none;
  & > svg {
    position: absolute;
    bottom: 0;
    left: ${({ isLarge }) => (isLarge ? '-22px' : '-16.5px')};
  }
`;

const MyLocationMarkerContainer = styled.div`
  width: 0;
  height: 0;
  position: relative;
  pointer-events: none;
  & > svg {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
`;

const MarkerClickListener = styled.div`
  position: absolute;
  bottom: 0;
  left: -14px;
  width: 28px;
  height: 38px;
  cursor: pointer;
  z-index: 3;
  pointer-events: auto;
`;
const ToolTipContainer = styled(Stack)`
  background: white;
  position: absolute;
  bottom: 80px;
  min-width: max-content;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #d8ddea;
  box-shadow: 0 8px 20px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 8px;
  pointer-events: auto;
`;

const PolyToolTipContainer = styled(Stack)`
  background: white;
  position: absolute;
  left: 20px;
  transform: translateY(-13px);
  min-width: max-content;
  border: 1px solid #d8ddea;
  box-shadow: 0 8px 20px rgba(16, 30, 115, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 222px;
  pointer-events: auto;
`;
