import { PAYMENT_QUERIES } from './payment.queries';
import { HTTPError } from 'ky';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { IPaymentActionProps } from '../../../constants/types';

type FetchPaymentVerifyManagerInfoResponse = {
  paymentUrl: string;
  orderCode: string;
};

type Variables = {
  phone: string;
  name: string;
  email: string;
  code: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchSetPaymentVerifyManagerInfo>>;
  TError: HTTPError;
  TVariable: Variables & IPaymentActionProps;
  TMutationContext: ReturnType<typeof PAYMENT_QUERIES.SET_PAYMENT_VERIFY_MANAGER_INFO>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchSetPaymentVerifyManagerInfo = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchPaymentVerifyManagerInfoResponse>();

const useSetPaymentVerifyManagerInfo = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    PAYMENT_QUERIES.SET_PAYMENT_VERIFY_MANAGER_INFO(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchSetPaymentVerifyManagerInfo({
        variables: variables,
        ...PAYMENT_QUERIES.SET_PAYMENT_VERIFY_MANAGER_INFO()[0],
      }),
    options
  );

export { fetchSetPaymentVerifyManagerInfo, useSetPaymentVerifyManagerInfo };
export type { FetchPaymentVerifyManagerInfoResponse, Variables };
