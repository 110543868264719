export const TUTORIAL_QUERIES = {
  SCOPE: [{ scope: 'MANAGER_AUTH_CODE' }] as const,
  CHECK_CAN_TUTORIAL_MONITORING: () => [
    {
      ...TUTORIAL_QUERIES.SCOPE[0],
      entity: 'check_can_tutorial_monitoring',
      endPoint: `auth/tutorial/monitoring`,
    },
  ],
  CHECK_CAN_TUTORIAL_ROUTE: () => [
    {
      ...TUTORIAL_QUERIES.SCOPE[0],
      entity: 'check_can_tutorial_route',
      endPoint: `auth/tutorial/route`,
    },
  ],
};
