import { Suspense } from 'react';
import * as S from './style';
import { TColor } from 'constants/types';

export interface ProgressProps {
  color: TColor | [TColor, TColor];
  progress: number;

  /** radius */
  h?: number;
  /** radius */
  r?: number;
}

const Progress = ({ progress, color, h, r }: ProgressProps) => {
  const styleMapper = {
    color: typeof color === 'string' ? color : color[0],
    bgColor: typeof color === 'string' ? color : color[1],
  };
  return (
    <Suspense fallback={<div></div>}>
      <S.ProgressBackground color={styleMapper.bgColor} {...{ h, r }}>
        <S.Progress value={progress} color={styleMapper.color} {...{ h, r }} />
      </S.ProgressBackground>
    </Suspense>
  );
};

export default Progress;
