import { Layer, Map, MapRef, Source } from 'react-map-gl';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { polylinesLayerProps } from './RouteControlLayers';
import { DriverIdUnSelected, IEqualOptimizeResponses, ITimelineData } from '../../constants/types';
import polyline from 'google-polyline';

import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import { MapConfig } from './util/config';
import {
  FocusedMarkerInfoWindow,
  FocusedPolylineInfoWindow,
  OrdersMarker,
  parseCoordIncludedOrder,
  StartEndLocationMarker,
} from './RoutePlanConfirmWidgets+JSX';
import { ReactComponent as FullScreen } from '../../constants/icon/map_btn_fullscreen.svg';
import styled from 'styled-components';
import { ICoordIncludedOrder } from './RouteControlWidgets+JSX';
import { generatePolylineFocused, generatePolylineOptimize } from './RoutePlanConfirmWidgets+Layer';
import { rbdIndexFinder, UNBATCHED_COLUMN_KEY } from 'components/Timeline';
import { TMod } from 'pages/RoutePlanConfirm';

interface RoutePlanConfirmMapProps {
  mod: TMod;
  setSelectedCellGroup: Dispatch<SetStateAction<string>>;
  data?: IEqualOptimizeResponses;
  timelineData?: ITimelineData;
  showDetail?: (orderId: number) => void;
  setSelectedDriver: Dispatch<SetStateAction<number>>;
  selectedDriver: number;
  setSelectedOrder: Dispatch<SetStateAction<number[]>>;
  selectedOrder: number[];
}

export const RoutePlanConfirmMapV2 = ({
  mod,
  setSelectedCellGroup,
  data,
  timelineData,
  showDetail,
  selectedDriver,
  setSelectedDriver,
  setSelectedOrder,
  selectedOrder,
}: RoutePlanConfirmMapProps) => {
  const [polylinesData, setPolylinesData] = useState<any>({ id: 'polyline', type: 'FeatureCollection', features: [] });
  const [cursor, setCursor] = useState<string>('default');
  const [focusedMarker, setFocusedMarker] = useState<ICoordIncludedOrder[]>([]);
  const [editedOrder, setEditedOrder] = useState<{ orderId: number; key: string }[]>([]);

  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [polylineInfoWindow, setPolylineInfoWindow] = useState<any[] | null>(null);
  const [selectedPolylinesData, setSelectedPolylinesData] = useState<any>({
    id: 'polyline',
    type: 'FeatureCollection',
    features: [],
  });
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    console.log('polylinesData', polylinesData);
  }, [polylinesData]);

  useEffect(() => {
    let editedOrders: { orderId: number; key: string }[] = [];
    timelineData?.columnOrder.forEach(key => {
      let items = timelineData.columns[key].properties.editedItems;
      if (items.length > 0) {
        items.forEach(did => {
          const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, draggableId: did });
          if (columnKey !== '') {
            let id = timelineData.columns[columnKey].items[columnIndex].originData?.orderId;
            if (id)
              editedOrders.push({
                orderId: id,
                key: columnKey,
              });
          }
        });
      }
    });
    setEditedOrder(editedOrders);
  }, [timelineData]);

  useEffect(() => {
    if (selectedOrder && selectedOrder.length > 0 && data) {
      let coordIncludedOrders = parseCoordIncludedOrder({ data });
      let focusTarget = coordIncludedOrders.filter(
        cOrder => cOrder.overlapped.filter(item => selectedOrder.includes(item.orderId ?? -33)).length > 0
      );
      if (focusTarget.length > 0) {
        setFocusedMarker(focusTarget);
        setIsAnimating(true);

        setTimeout(() => {
          if (focusTarget.length > 1) {
            let bounds: any = null;
            focusTarget.forEach(t => {
              if (t.overlapped.length > 0 && t.overlapped[0].coordinate) {
                let coord = t.overlapped[0].coordinate?.coordinates;
                if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
                else bounds.extend(coord);
              }
            });
            if (bounds)
              mapRef.current?.fitBounds(bounds, {
                padding: {
                  top: 200,
                  left: MapConfig.MAP_FIT_PADDING,
                  right: MapConfig.MAP_FIT_PADDING,
                  bottom: MapConfig.MAP_FIT_PADDING,
                },
                duration: 300,
              });
          } else {
            if (focusTarget[0].overlapped.length > 0 && focusTarget[0].overlapped[0].coordinate)
              mapMoveTo(focusTarget[0].overlapped[0].coordinate?.coordinates);
          }
          setIsAnimating(false);
        }, 100);
      }
    } else {
      setFocusedMarker([]);
    }
  }, [selectedOrder]);

  useEffect(() => {
    resetFocus();
    if (data) {
      generatePolylineOptimize({ data, setter: setPolylinesData });
    } else {
      setPolylinesData(null);
    }
    waitForMapLoading();
  }, [data]);

  function waitForMapLoading(depth = 0) {
    if (depth > 100) return;
    if (mapRef && mapRef.current) {
      updateBoundsToFit();
      return;
    }

    setTimeout(() => {
      waitForMapLoading(depth + 1);
    }, 20);
  }

  function resetFocus() {
    setSelectedCellGroup('');
    setSelectedOrder([]);
    setFocusedMarker([]);
    setPolylineInfoWindow(null);
  }

  function updateSelectedDriver() {
    if (data && selectedDriver !== DriverIdUnSelected) {
      generatePolylineFocused({ data, selectedDriver, setter: setSelectedPolylinesData });
    }

    if (data && !isAnimating) {
      updateBoundsToFit();
    }
  }

  useEffect(() => {
    updateSelectedDriver();
  }, [selectedDriver, mod]);

  return (
    <MapBoxContainer>
      <Map
        ref={mapRef}
        localFontFamily={MapConfig.MAP_FONT_FAMILY}
        localIdeographFontFamily={MapConfig.MAP_FONT_FAMILY}
        mapboxAccessToken={MapConfig.MAP_ACCESS_TOKEN}
        mapStyle={MapConfig.MAP_STYLE}
        doubleClickZoom={false}
        cursor={cursor}
        onMouseEnter={() => {
          setCursor('pointer');
        }}
        onMouseLeave={() => {
          setCursor('default');
        }}
        onClick={e => {
          resetFocus();
          let selectedItems: mapboxgl.MapboxGeoJSONFeature[] = [];
          e.features?.forEach(feat => {
            if (feat.layer.id === 'lineLayer' && feat.properties && feat.properties.width === 8) {
              let combinedFeat: any = feat;
              combinedFeat.coord = e.lngLat;
              // @ts-ignore
              selectedItems.push(feat);
            } else if (feat.layer.id === 'lineLayerSelected' && feat.properties && feat.properties.width === 5) {
              let combinedFeat: any = feat;
              combinedFeat.coord = e.lngLat;
              // @ts-ignore
              selectedItems.push(feat);
            }
          });
          if (selectedItems.length === 1 && selectedItems[0].properties) {
            let driverId = selectedItems[0].properties.driverId;
            if (selectedDriver === driverId) setSelectedDriver(DriverIdUnSelected);
            else setSelectedDriver(selectedItems[0].properties.driverId);
          } else {
            if (selectedDriver === DriverIdUnSelected) setPolylineInfoWindow(selectedItems);
            else setSelectedDriver(DriverIdUnSelected);
          }
        }}
        interactiveLayerIds={['lineLayer', 'lineLayerSelected']}
        maxBounds={MapConfig.MAP_BOUNDS_ZOOMED as LngLatBoundsLike}
      >
        {StartEndLocationMarker({ data, selectedDriver })}
        {OrdersMarker({
          data,
          editedOrder,
          focusedMarker,
          setSelectedOrder,
          selectedDriver,
          setFocusedMarker,
          mapMoveTo,
          setSelectedDriverByOrderId: (orderId: number) => {
            if (orderId < 1) {
              setSelectedDriver(DriverIdUnSelected);
              return;
            }
            if (timelineData) {
              const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, orderId });
              if (columnKey !== '') {
                setIsAnimating(true);

                setSelectedDriver(
                  columnKey === UNBATCHED_COLUMN_KEY ? DriverIdUnSelected : parseInt(columnKey.split('-')[1])
                );
              }
            }
          },
        })}
        {FocusedMarkerInfoWindow({
          order: focusedMarker,
          showDetail,
          setSelectedOrder: (orderId: number) => {
            if (timelineData) {
              const { columnKey, columnIndex } = rbdIndexFinder({ timelineData, orderId });
              if (columnKey !== '') {
                setSelectedDriver(
                  columnKey === UNBATCHED_COLUMN_KEY ? DriverIdUnSelected : parseInt(columnKey.split('-')[1])
                );
              }
            }
            setSelectedOrder([orderId]);
          },
        })}
        {FocusedPolylineInfoWindow({
          feature: polylineInfoWindow,
          setFeature: setPolylineInfoWindow,
          selectDriver: setSelectedDriver,
        })}
        {polylinesData && (
          <Source id="polyline-src" type="geojson" data={polylinesData}>
            <Layer
              id={'lineLayer'}
              {...polylinesLayerProps}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
                visibility: selectedDriver === DriverIdUnSelected ? 'visible' : 'none',
              }}
            />
          </Source>
        )}
        {selectedPolylinesData && (
          <Source id="polyline-selected-src" type="geojson" data={selectedPolylinesData}>
            <Layer
              id={'lineLayerSelected'}
              type={'line'}
              paint={{
                'line-opacity': ['get', 'opacity'],
                'line-color': ['get', 'color'],
                'line-width': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  10,
                  ['+', ['get', 'width'], 0],
                  15,
                  ['+', ['get', 'width'], 2],
                ],
              }}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
                visibility: selectedDriver !== DriverIdUnSelected ? 'visible' : 'none',
              }}
            />
          </Source>
        )}
      </Map>

      <div
        id={'upside'}
        onClick={() => {
          mapRef?.current?.getMap().getContainer().requestFullscreen();
        }}
      >
        <FullScreen />
      </div>
    </MapBoxContainer>
  );

  function mapMoveTo(pos: [number, number]) {
    if (mapRef && mapRef.current) {
      try {
        mapRef.current.easeTo({
          center: pos,
          duration: 700,
        });
      } catch (e) {
        mapRef.current.easeTo({ center: pos, duration: 300 });
      }
    }
  }

  function updateBoundsToFit() {
    let bounds: any = null;

    data?.driverList.forEach(x => {
      if (selectedDriver === x.driverId || selectedDriver === DriverIdUnSelected) {
        if (x.vehicle && x.vehicle.startCoordinate) {
          let coord = x.vehicle.startCoordinate.coordinates;
          if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
          bounds.extend(coord);
        }
        if (x.vehicle && x.vehicle.endCoordinate) {
          let coord = x.vehicle.endCoordinate.coordinates;
          if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
          bounds.extend(coord);
        }

        x.orderList.forEach(o => {
          if (o.coordinate) {
            let coord = o.coordinate.coordinates;
            if (!bounds) bounds = new mapboxgl.LngLatBounds(coord, coord);
            bounds.extend(coord);
          }

          if (o.route && o.route?.internal?.polyline && bounds) {
            let decodedLine = polyline.decode(o.route.internal.polyline);
            decodedLine.forEach(y => bounds.extend(y));
          }
        });

        if (selectedDriver !== DriverIdUnSelected && selectedDriver === x.driverId)
          editedOrder.forEach(e => {
            if (`driver-${x.driverId}` === e.key) {
              let coordIncludedOrders = parseCoordIncludedOrder({ data });
              let focusTarget = coordIncludedOrders.filter(
                cOrder => cOrder.overlapped.filter(item => item.orderId === e.orderId).length > 0
              );
              focusTarget.forEach(f => {
                let coords = f.orderInfo?.coordinate?.coordinates;
                if (coords) {
                  if (!bounds) bounds = new mapboxgl.LngLatBounds(coords, coords);
                  bounds.extend(coords);
                }
              });
            }
          });
      }
    });
    if (mapRef && mapRef.current && bounds && !isAnimating) {
      setTimeout(() => {
        try {
          mapRef.current?.fitBounds(bounds, {
            padding: {
              top: MapConfig.MAP_FIT_PADDING,
              left: MapConfig.MAP_FIT_PADDING,
              right: MapConfig.MAP_FIT_PADDING,
              bottom: MapConfig.MAP_FIT_PADDING,
            },
            duration: 700,
          });
        } catch (e) {
          mapRef.current?.fitBounds(bounds, { duration: 300 });
        }
      }, 40);
    }
  }
};

const MapBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & > #upside {
    position: absolute;
    right: 3px;
    top: 5px;
    cursor: pointer;
    z-index: 5;
  }

  & > #downside {
    position: absolute;
    right: 3px;
    top: 37px;
    cursor: pointer;
    z-index: 5;
  }
`;
