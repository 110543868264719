import { useMutation, useQuery } from '@tanstack/react-query';
import * as turf from '@turf/turf';
import { addArea, deleteArea, editArea, getAreaList, getDriverListOnSameTeam } from 'api';
import { request } from 'api/instance';
import { Button as NButton } from 'components/Button';
import { Button } from 'components/Button/legacy_index';
import Prev from 'components/Button/Prev';
import { Card } from 'components/Card';

import Divider from 'components/Divider';
import { Input } from 'components/Input';
import SettingAreaMap from 'components/MapboxContainer/SettingAreaMap';
import Modal from 'components/Modal';
import { CloseConfirmModal, DetailModiForm } from 'components/Modal/OrderDetailModal';

import ShiroiModal from 'components/Modal/ShiroiModal';
import ProgressBar from 'components/ProgressBar';
import { SelectorContainer, SelectorItem } from 'components/Selector/style';
import { Stack } from 'components/Stack';
import { Tag } from 'components/Tag';
import { Highlight, Text } from 'components/Typography';

import { operation_type } from 'constants/commons';
import { ReactComponent as IcAdd } from 'constants/icon/ic_add.svg';
import { ReactComponent as IcArrowDropDown } from 'constants/icon/ic_arrow_dropdown.svg';

import { ReactComponent as IcArrow } from 'constants/icon/ic_arrowright20.svg';
import { ReactComponent as IcBin } from 'constants/icon/ic_bin.svg';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';
import { ReactComponent as IcMagnifyingGlass } from 'constants/icon/ic_magnifyingglass.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcSuccessOutline } from 'constants/icon/ic_success_circle_outline.svg';
import { ReactComponent as IcRegion } from 'constants/icon/icon_Region.svg';

import theme from 'constants/theme';
import { IAreaProps, IGetAreaListResponse, IInvolvedDriver, TAreaFormActions, TOperationType } from 'constants/types';
import { useTracking } from 'hooks/store/useTracking';
import setupIndexedDB, { useIndexedDBStore } from 'hooks/useIndexedDB';
import useInterval from 'hooks/useInterval';

import find from 'lodash.find';
import remove from 'lodash.remove';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useForm, UseFormReturn, useFormState } from 'react-hook-form';
import { MapRef } from 'react-map-gl';

import styled from 'styled-components';
import * as topojson from 'topojson-client';

export type TDistrict = 'sido' | 'sgg' | 'dem';

export interface IDistrictSelector {
  displayName: { [key in TDistrict]: string };
  depth: TDistrict;
  isOpen: boolean;
}

export const Searchbar = ({
  methods,
  content,
}: {
  methods: UseFormReturn<any>;
  content: Array<{ [key in 'key' | 'value' | 'placeholder']: any }>;
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);

  const [displayContent, setDisplayContent] = useState<string>(
    content.find(d => d.value === methods.getValues('searchItem'))?.key
  );

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setSelectIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);

  useEffect(() => {
    methods.resetField('keyword');
  }, [displayContent, methods]);

  return (
    <FilterSearchbarWrapper className="filter-searchbar-wrapper" direction="row">
      <Stack sx={{ flexBasis: 'content', position: 'relative' }}>
        <Stack
          spacing={4}
          direction="row"
          justify="space-between"
          ref={containerRef}
          onClick={() => setSelectIsOpen(prev => !prev)}
          className="search-item-select-wrapper"
        >
          <Text styleName="caption2" color="RG02" className="search-item-select">
            {displayContent}
          </Text>
          <IcArrowDropDown width={16} height={16} />
        </Stack>

        {selectIsOpen && (
          <SelectorContainer width={86} ref={selectorRef}>
            {content.map((d, index) => (
              <SelectorItem
                activated={d.value === methods.getValues('searchItem')}
                key={`${d.key}-selector-${index}`}
                onClick={() => {
                  setDisplayContent(d.key);
                  methods.setValue('searchItem', d.value);
                  setSelectIsOpen(false);
                }}
              >
                {d.key}
              </SelectorItem>
            ))}
          </SelectorContainer>
        )}
      </Stack>

      <SearchbarInputContainer
        spacing={4}
        direction="row"
        justify="space-between"
        isFilled={methods.watch('keyword') && methods.watch('keyword') !== ''}
      >
        <input
          {...methods.register('keyword', {})}
          autoComplete="off"
          placeholder={`${content.find(d => d.value === methods.getValues('searchItem'))?.placeholder}`}
        />

        <IcMagnifyingGlass width={16} height={16} />
      </SearchbarInputContainer>
    </FilterSearchbarWrapper>
  );
};

const RouteArea = ({ fatalCorrectionDetectorWithFence }: { fatalCorrectionDetectorWithFence: Function }) => {
  const addFormMethods = useForm<{
    name: string;
    district: Array<string>;
    driverList: Array<Partial<IDrivers>>;
    polygon?: Array<Array<Array<[number, number]>>>;

    driver_search_keyword?: string;
  }>({
    mode: 'onSubmit',
  });
  const areaFormMethods = useForm<{
    sido: { [key: string]: any };
    sgg: { [key: string]: any };
    dem: { [key: string]: any };
  }>({
    mode: 'onSubmit',
    defaultValues: {
      sido: {
        mainCode: '',
      },
      sgg: {
        mainCode: '',
      },
      dem: {
        mainCode: '',
      },
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      searchItem: 'driverName',
      keyword: '',
    },
  });
  const mapRef = useRef<MapRef>(null);

  const { control, handleSubmit } = methods;
  const { dirtyFields } = useFormState({ control });

  const [areaUnvalidateModal, setAreaUnvalidateModal] = useState<boolean>(false);

  const [savedAreaList, setSavedAreaList] = useState<IGetAreaListResponse>();
  const [refetchTarget, setRefetchTarget] = useState<number | null>(null);

  const refetchHandler = (areaList: IGetAreaListResponse['areaList']) => {
    let updateTarget = areaList.find(d => d.areaId === refetchTarget);

    if (updateTarget) {
      setTargetAreaDetails({
        areaName: updateTarget.name,
        areaId: updateTarget.areaId,
        areaDrivers: updateTarget.areaDrivers,
        district: updateTarget.district ?? [],
      });
    }
    setRouteAreaFormView(prev => {
      return { ...prev, action: 'detail' };
    });

    // methods.reset();
    // addFormMethods.reset();

    setRefetchTarget(null);
  };

  const {
    isLoading: isGetSettedAreaListIsLoading,
    refetch: refetchAreaList,
    isRefetching,
  } = useQuery(
    ['getSettedAreaList', methods.getValues()],
    () => {
      if (Boolean(methods.getValues('keyword'))) return getAreaList({ ...methods.getValues() });
      else return getAreaList({});
    },
    {
      onSuccess(data) {
        setSavedAreaList(data);
        refetchHandler(data.areaList);
      },
    }
  );

  const { mutate: mutateAddArea } = useMutation(addArea, {
    onSuccess: async () => {
      await refetchAreaList();
      setRouteAreaFormView(prev => {
        return { ...prev, visible: false };
      });
      methods.reset();
      addFormMethods.reset();

      fatalCorrectionDetectorWithFence();
    },
    onError: (error: any) => {
      const err: IErrorResponse = error.response.data.error;

      if (err.advice === '이미 사용 중인 권역 이름입니다.') {
        addFormMethods.setError(
          'name',
          {
            type: 'duplicatedName',
            message: err.advice,
          },
          { shouldFocus: true }
        );
      }
    },
  });
  const { mutate: mutateEditArea } = useMutation(editArea, {
    onSuccess: async res => {
      setRefetchTarget(res.areaId);

      await refetchAreaList();
      fatalCorrectionDetectorWithFence();
    },

    onError: (error: any) => {
      const err: IErrorResponse = error.response.data.error;

      if (err.advice === '이미 사용 중인 권역 이름입니다.') {
        addFormMethods.setError(
          'name',
          {
            type: 'duplicatedName',
            message: err.advice,
          },
          { shouldFocus: true }
        );
      }
    },
  });
  const { mutate: mutateDeleteArea } = useMutation(deleteArea, {
    onSuccess: async () => {
      methods.reset();
      await refetchAreaList();
      setRouteAreaFormView(prev => {
        return { ...prev, visible: false };
      });

      fatalCorrectionDetectorWithFence();
    },
  });
  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('설정>권역 관리');
  }, [setTitle]);

  // IndexedDB
  const { getAll, deleteAll, add } = useIndexedDBStore('행정구역_TOPO');

  // loading flag
  const [isLoading, setIsLoading] = useState(false);

  // 마우스 호버 이벤트 감지용 레이어 (invisible)
  const [listenerSource, setListenerSource] = useState<any>({
    dem: { type: 'FeatureCollection', features: [] },
    sgg: { type: 'FeatureCollection', features: [] },
    sido: { type: 'FeatureCollection', features: [] },
  });

  const [geo, setGeo] = useState<
    Array<{
      geometry: { coordinates: Array<Array<[number, number]>>; type: 'MultiPolygon' };
      properties: any;
    }>
  >([]);

  const loadGeojson = async () => {
    if (isLoading) return;
    setCompletedPercent(0);
    setIsLoading(true);

    await request({
      url: '/area/district/url',
    }).then(async (res: { urls: { [key in 'emd' | 'sido' | 'sgg']: string }; version: string }) => {
      await setupIndexedDB({
        databaseName: 'topoJsonDB1',
        version: 1,
        stores: [
          {
            name: '행정구역_TOPO',
            id: { keyPath: 'id' },
            indices: [
              { name: 'sgg', keyPath: 'sgg' },
              { name: 'emd', keyPath: 'emd' },
              { name: 'sido', keyPath: 'sido' },
            ],
          },
        ],
      });

      let dbData = await getAll();

      let emd: any = {};
      let sgg: any = {};
      let sido: any = {};

      if (dbData && dbData.length > 0 && (dbData[0] as any).id === res.version) {
        emd = (dbData[0] as any).emd;
        sgg = (dbData[0] as any).sgg;
        sido = (dbData[0] as any).sido;
      } else {
        await deleteAll();
        emd = await (await fetch(res.urls.emd)).json();
        sgg = await (await fetch(res.urls.sgg)).json();
        sido = await (await fetch(res.urls.sido)).json();

        let rawSource = {
          id: res.version,
          emd,
          sgg,
          sido,
        };
        await add(rawSource);
      }
      let emdKey = Object.keys(emd.objects)[0];
      let sggKey = Object.keys(sgg.objects)[0];
      let sidoKey = Object.keys(sido.objects)[0];
      let emdFeature = topojson.feature(emd, emd.objects[emdKey]);
      let sggFeature = topojson.feature(sgg, sgg.objects[sggKey]);
      let sidoFeature = topojson.feature(sido, sido.objects[sidoKey]);
      let listenerSource = {
        dem: emdFeature,
        sgg: sggFeature,
        sido: sidoFeature,
      };
      setListenerSource(listenerSource);
      console.log(listenerSource);
      let obj = (emdFeature as any).features
        ?.concat((sggFeature as any).features)
        .concat((sidoFeature as any).features);
      setGeo(obj);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadGeojson();
  }, []);

  const [layerSelected, setLayerSelected] = useState<any>([]);

  const searchContent: Array<{ [key in 'key' | 'value' | 'placeholder']: any }> = [
    {
      key: '드라이버',
      value: 'driverName',
      placeholder: '드라이버를 검색하세요',
    },
    {
      key: '행정구역',
      value: 'districtName',
      placeholder: '행정구역을 검색하세요.',
    },
    {
      key: '권역이름',
      value: 'areaName',
      placeholder: '설정한 권역이름을 검색하세요.',
    },
  ];

  const [routeAreaFormView, setRouteAreaFormView] = useState<{ visible: boolean; action: TAreaFormActions }>({
    action: 'detail',
    visible: false,
  });

  const RAFV_closer = (props: any) => {
    setDriverSelectorIsOpen(false);
    setHoverLikeCollection(null);
    setRouteAreaFormView(prev => {
      return { ...prev, visible: false };
    });
  };

  interface IDrivers extends IInvolvedDriver {
    isSelected: boolean;
    visibility: boolean;
    vehicleOperationType?: TOperationType;
  }

  const [drivers, setDrivers] = useState<Array<IDrivers>>([]);
  const [selectedDrivers, setSelectedDrivers] = useState<Array<IDrivers>>([]);
  const [driverSelectorIsOpen, setDriverSelectorIsOpen] = useState<boolean>(false);
  const {} = useQuery(['drivers'], getDriverListOnSameTeam, {
    onSuccess(res) {
      setDrivers(
        res.data
          .filter(d => d.status === 'activated')
          .map(d => {
            return { ...d, isSelected: false, visibility: true };
          })
      );
    },
  });

  useEffect(() => {
    setSelectedDrivers(drivers.filter(d => d.isSelected));
  }, [addFormMethods, drivers]);

  useEffect(() => {
    if (!driverSelectorIsOpen) {
      setDriverSelectorIsOpen(false);
      setNADO(false);
    }
  }, [driverSelectorIsOpen]);

  const driverFilterHandler = () => {
    const data = [...drivers];
    let target = data;
    if (!target) return;

    let string: string = addFormMethods.getValues('driver_search_keyword') ?? '';

    if (!string || string === '') {
      target.forEach(y => (y.visibility = true));
    } else {
      target.forEach(y => (y.visibility = y.name.includes(string)));
    }
    setDrivers(data);
  };
  const [NADO, setNADO] = useState<boolean>(false);

  const driverSelectCloser = () => {
    setDrivers(prev => {
      return prev.map(x => {
        return {
          ...x,
          isSelected: false,
        };
      });
    });
    setDriverSelectorIsOpen(false);
  };

  const fitboundsSearchbarCleaner = () => {
    areaFormMethods.reset();
    setDistrictSelector(prev => {
      return {
        ...prev,
        displayName: {
          dem: '',
          sgg: '',
          sido: '',
        },
      };
    });
  };

  const AreaAddFormUI = () => {
    return (
      <form style={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} divider={<Divider color="RG06" />}>
          <Stack align="end" sx={{ padding: '24px 20px 20px 20px' }}>
            <IcClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setHoverLikeCollection(null);

                const action = routeAreaFormView.action;
                if (action === 'add') return RAFV_closer(null);
                if (action === 'modi')
                  return setRouteAreaFormView(prev => {
                    return { ...prev, action: 'detail' };
                  });
              }}
            />
            <Text color="RG02" styleName="body1" sx={{ width: '100%' }}>
              {routeAreaFormView.action === 'add'
                ? '권역 추가하기'
                : routeAreaFormView.action === 'modi' && '권역 수정하기'}
            </Text>
          </Stack>
          <DetailModiForm
            title={
              <Text styleName="body2" color="RG02">
                권역 이름
              </Text>
            }
            spacing={6}
            spread
            isFold={false}
            sx={{ padding: '20px' }}
          >
            <Input
              name="name"
              placeholder="권역 이름을 지정할 수 있습니다. 예시) 서초 A - 1 "
              register={addFormMethods.register}
              watch={addFormMethods.watch}
              reset={addFormMethods.reset}
              field
              type="text"
              height={40}
              sx={{ flex: 1 }}
              validation={{
                required: { value: true, message: '권역 이름을 지정 해주세요.' },
                maxLength: {
                  value: 20,
                  message: '20자 이내로 입력 해 주세요.',
                },
              }}
              hasError={Boolean(addFormMethods.formState?.errors?.name)}
              errors={addFormMethods.formState?.errors?.name}
            />
          </DetailModiForm>
          <Stack sx={{ height: '100%' }} useVerticalScroll>
            <Stack sx={{ maxHeight: '671px' }} divider={<Divider color="RG06" />}>
              <DetailModiForm
                title={
                  <Text styleName="body2" color="RG02">
                    선택된 구역{' '}
                    <Highlight color="RG04">{`( 총 ${addFormMethods.watch('district')?.length ?? 0} 개 )`}</Highlight>
                  </Text>
                }
                spacing={20}
                isFold={false}
                sx={{ padding: '20px' }}
              >
                {addFormMethods.watch('district')?.length > 0 ? (
                  <Stack spacing={20} justify="center" align="end">
                    <NButton
                      type="button"
                      children="구역 전체 삭제"
                      variant="tertiary"
                      icon={[IcBin, { width: 12, height: 12 }]}
                      h={24}
                      onClick={() => setLayerSelected([])}
                    />
                    <Stack direction="row" spacing={6} sx={{ flexWrap: 'wrap' }}>
                      {addFormMethods.watch('district').map((x: string) => (
                        <PlainTag
                          style={{ padding: '0 0 0 6px' }}
                          onClick={() => {
                            let filteredGeo = geo.filter(y => x === y.properties.mainCode);
                            let src = {
                              type: 'FeatureCollection',
                              features: filteredGeo,
                            };

                            let fitGeo = geo.filter(y =>
                              addFormMethods.watch('district')?.includes(y.properties.mainCode)
                            );
                            let fit = {
                              type: 'FeatureCollection',
                              features: fitGeo,
                            };
                            let bbox = turf.bbox(fit);

                            hoverLikeOverlayHandler({
                              id: filteredGeo.at(0)?.properties.mainCode,
                              name: filteredGeo.at(0)?.properties.mainName,
                              source: src,
                            });

                            areaSearchbarActionHandler(filteredGeo.at(0));

                            // @ts-ignore
                            mapRef.current?.fitBounds(bbox, { padding: 20 });

                            // fitboundsSearchbarCleaner();
                          }}
                        >
                          <Stack direction="row">
                            {
                              find(geo, function (o: any) {
                                return o.properties.mainCode === x;
                              })?.properties.mainName
                            }
                            <IcClose
                              width={20}
                              height={20}
                              onClick={e => {
                                e.stopPropagation();
                                let res = remove(addFormMethods.watch('district'), function (n) {
                                  return n !== x;
                                });

                                setLayerSelected(res);
                              }}
                            />
                          </Stack>
                        </PlainTag>
                      ))}
                    </Stack>
                  </Stack>
                ) : (
                  <NonSelectDefaultBox>
                    우측 지도에서 추가하고 싶은 구역을 눌러 추가할 수 있습니다.
                    <br />
                    시/도 단위, 구/군 단위, 읍/면/동 단위로 선택할 수 있습니다.
                  </NonSelectDefaultBox>
                )}
              </DetailModiForm>

              <DetailModiForm
                title={
                  <Text styleName="body2" color="RG02">
                    담당 드라이버{' '}
                    <Highlight color="RG04">{`( 총 ${
                      addFormMethods.getValues('driverList')?.length ?? 0
                    } 명 )`}</Highlight>
                  </Text>
                }
                spacing={20}
                isFold={false}
                sx={{ padding: '20px' }}
              >
                <Stack spacing={10} align="end">
                  <Stack sx={{ position: 'relative' }}>
                    <SearchSection style={{ width: 'calc(100% + 40px)' }}>
                      <FilterSearchbarWrapper className="filter-searchbar-wrapper" direction="row">
                        <SearchbarInputContainer
                          isFilled={false}
                          spacing={10}
                          direction="row"
                          justify="space-between"
                          sx={{ padding: '8px 10px' }}
                        >
                          <input
                            {...addFormMethods.register('driver_search_keyword', {})}
                            autoComplete="off"
                            style={{ width: '100%' }}
                            placeholder={`담당할 드라이버를 검색하세요`}
                            onFocus={() => {
                              setDriverSelectorIsOpen(true);
                            }}
                            onKeyDown={e => {
                              if (!(e.key === 'Enter')) return;
                              driverFilterHandler();
                            }}
                          />
                          <button
                            type="button"
                            style={{ display: 'flex', background: 'none', cursor: 'pointer' }}
                            onClick={driverFilterHandler}
                          >
                            <IcMagnifyingGlass width={16} height={16} />
                          </button>
                        </SearchbarInputContainer>
                      </FilterSearchbarWrapper>
                    </SearchSection>
                    {driverSelectorIsOpen && (
                      <Card
                        variant={'popup'}
                        width={334}
                        padding={0}
                        sx={{ margin: '54px 0 0 0', position: 'absolute' }}
                      >
                        <Stack divider={<Divider color="RG06" />}>
                          <Stack sx={{ padding: '16px 20px' }}>
                            <Stack
                              justify="end"
                              spacing={4}
                              direction="row"
                              onClick={() => {
                                setNADO(prev => !prev);
                              }}
                              sx={{
                                userSelect: 'none',
                              }}
                            >
                              {NADO ? (
                                <IcSuccess width={12} height={12} fill={theme.colors['RC02']} />
                              ) : (
                                <IcSuccessOutline width={12} height={12} />
                              )}
                              <Text styleName="caption1" color={'RG02'}>
                                권역 없는 드라이버만 보기
                              </Text>
                            </Stack>
                          </Stack>
                          <Stack sx={{ padding: '16px 0' }} useVerticalScroll>
                            <Stack sx={{ height: '280px' }}>
                              {drivers
                                ?.filter(d => d.name.includes(addFormMethods.watch('driver_search_keyword') ?? ''))
                                ?.filter(d => (NADO ? !Boolean(d.area.length) : d))
                                ?.filter(
                                  d =>
                                    !addFormMethods
                                      .getValues('driverList')
                                      ?.map(y => y.driverId)
                                      ?.includes(d.driverId)
                                ).length > 0 ? (
                                drivers
                                  ?.filter(d => d.name.includes(addFormMethods.watch('driver_search_keyword') ?? ''))
                                  ?.filter(d => (NADO ? !Boolean(d.area.length) : d))
                                  ?.filter(
                                    d =>
                                      !addFormMethods
                                        .getValues('driverList')
                                        ?.map(y => y.driverId)
                                        ?.includes(d.driverId)
                                  )
                                  ?.map(d => {
                                    const { label, status } = {
                                      ...(operation_type.find(y => y.value === d.operationType) ?? operation_type[0]),
                                    };

                                    return (
                                      <Stack
                                        spacing={8}
                                        direction={'row'}
                                        onClick={() => {
                                          const data = [...drivers];
                                          let target = data.find(x => x.driverId === d.driverId);
                                          if (!target) return;
                                          target.isSelected = !d.isSelected;

                                          setDrivers(data);
                                        }}
                                        sx={
                                          d.isSelected
                                            ? {
                                                backgroundColor: theme.colors.RC03_1,
                                                padding: '10px 20px',
                                              }
                                            : { padding: '10px 20px' }
                                        }
                                      >
                                        {d.isSelected ? (
                                          <IcSuccess width={16} height={16} fill={theme.colors['RC02']} />
                                        ) : (
                                          <IcSuccessOutline width={16} height={16} />
                                        )}
                                        <Tag active fit status={status} styleName="tooltip2">
                                          {label}
                                        </Tag>
                                        <Text styleName={d.isSelected ? 'caption1' : 'caption2'} color={'RG02'}>
                                          {d.name}
                                        </Text>
                                      </Stack>
                                    );
                                  })
                              ) : (
                                <Stack justify="center" sx={{ height: 'inherit' }}>
                                  <Text styleName="subheadline2" color="RG04">
                                    활성 상태의 드라이버가 없습니다.
                                    <br />
                                    {`[설정 > 드라이버 관리]에서 초대하실 수 있습니다.`}
                                  </Text>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                          <Stack padding={20} spacing={12} direction="row">
                            <Button
                              variant={'seventh'}
                              type={'button'}
                              styleName="caption1"
                              fullWidth
                              height={24}
                              onClick={() => {
                                driverSelectCloser();
                              }}
                            >
                              취소
                            </Button>

                            <Button
                              variant={'default'}
                              type={'button'}
                              fullWidth
                              height={24}
                              styleName="caption1"
                              color="RG00"
                              onClick={() => {
                                addFormMethods.setValue(
                                  'driverList',
                                  addFormMethods.getValues()?.driverList
                                    ? addFormMethods.getValues().driverList.concat(drivers.filter(d => d.isSelected))
                                    : drivers.filter(d => d.isSelected),
                                  { shouldDirty: true }
                                );

                                driverSelectCloser();
                              }}
                            >
                              추가하기
                            </Button>
                          </Stack>
                        </Stack>
                      </Card>
                    )}
                  </Stack>

                  {addFormMethods?.getValues('driverList')?.length > 0 && (
                    <NButton
                      type="button"
                      children="드라이버 전체 삭제"
                      variant="tertiary"
                      icon={[IcBin, { width: 12, height: 12 }]}
                      h={24}
                      onClick={() => {
                        addFormMethods.setValue('driverList', [], { shouldDirty: true });
                        setDrivers(prev => prev.map(y => ({ ...y, isSelected: false })));
                      }}
                    />
                  )}

                  {addFormMethods.getValues('driverList')?.map(x => {
                    const { label, status } = {
                      ...(operation_type.find(y => y.value === x.vehicleOperationType) ?? operation_type[0]),
                    };

                    return (
                      <DriverListItem direction="row" spacing={6}>
                        <Tag active fit status={status} styleName="tooltip2">
                          {label}
                        </Tag>
                        <Text styleName="subheadline2" color="RG02" sx={{ flex: 1 }}>
                          {x.name}
                        </Text>

                        <Button
                          variant={'seventh'}
                          type={'button'}
                          styleName={'caption2'}
                          onClick={() => {
                            let res = remove(addFormMethods.getValues('driverList'), function (n) {
                              return n.driverId !== x.driverId;
                            });
                            addFormMethods.setValue('driverList', res, { shouldDirty: true });

                            setDrivers(prev =>
                              prev.map(y => (y.driverId === x.driverId ? { ...y, isSelected: false } : y))
                            );
                          }}
                        >
                          <Stack justify="center" direction="row" spacing={2} sx={{ padding: '2px 4px' }}>
                            <IcBin width={16} height={16} />
                            삭제
                          </Stack>
                        </Button>
                      </DriverListItem>
                    );
                  })}
                </Stack>
              </DetailModiForm>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={16} sx={{ padding: '24px 20px' }}>
            <Button variant={'seventh'} type={'button'} styleName="body2" width={84} height={40} onClick={RAFV_closer}>
              취소
            </Button>

            <Button
              variant={'default'}
              type="button"
              width={234}
              height={40}
              styleName="body1"
              color="RG00"
              onClick={addFormMethods.handleSubmit(res => {
                if (res.district.length < 1) return setAreaUnvalidateModal(true);

                let geoPolygon: Array<any> = [];
                let obj = { ...res, driverList: res.driverList?.map(d => d!.driverId) };
                delete obj.driver_search_keyword;

                geo
                  .filter(d => obj.district.includes(d.properties.mainCode))
                  .forEach(d => {
                    // console.log(d.geometry.coordinates);
                    geoPolygon.push(d.geometry.coordinates);
                  });
                // obj.polygon = geoPolygon;

                const data: IAreaProps = { ...(obj as IAreaProps) };

                if (routeAreaFormView.action === 'add') {
                  mutateAddArea(data);
                }
                if (routeAreaFormView.action === 'modi' && targetAreaDetails) {
                  mutateEditArea({ props: data, areaId: targetAreaDetails.areaId });
                }
              })}
            >
              저장하기
            </Button>
          </Stack>
        </Stack>
      </form>
    );
  };

  const [districtSelector, setDistrictSelector] = useState<IDistrictSelector>({
    displayName: {
      sido: '',
      sgg: '',
      dem: '',
    },
    depth: 'sido',
    isOpen: false,
  });

  const areaSearchbarActionHandler = (value: any) => {
    const code: string = value.properties.mainCode;
    let type = code.length === 2 ? 'sido' : code.length === 5 ? 'sgg' : 'dem';
    areaFormMethods.reset();

    areaFormMethods.setValue(
      'sido',
      geo.filter(d => d.properties.mainCode === value.properties.sidoCode).at(0)?.properties,
      {
        shouldDirty: true,
      }
    );

    if (type === 'dem' || type === 'sgg') {
      areaFormMethods.setValue(
        'sgg',
        geo.filter(d => d.properties.mainCode === value.properties.sggCode).at(0)?.properties,
        { shouldDirty: true }
      );
    }
    if (type === 'dem') {
      areaFormMethods.setValue(
        'dem',
        geo.filter(d => d.properties.mainCode === value.properties.emdCode).at(0)?.properties,
        {
          shouldDirty: true,
        }
      );
    }

    if (type === 'sido') {
      areaFormMethods.resetField('sgg');
      areaFormMethods.resetField('dem');
    }
    if (type === 'sgg') {
      areaFormMethods.resetField('dem');
    }

    setDistrictSelector(prev => {
      return {
        depth: type as TDistrict,
        displayName: {
          dem: areaFormMethods.getValues()?.dem?.mainName
            ? areaFormMethods.getValues().dem.mainName.split(' ').at(-1)
            : '',
          sgg: areaFormMethods.getValues()?.sgg?.mainName
            ? areaFormMethods.getValues().sgg.mainName.split(' ').at(-1)
            : '',
          sido: areaFormMethods.getValues()?.sido?.mainName
            ? areaFormMethods.getValues().sido.mainName.split(' ').at(-1)
            : '',
        },
        isOpen: prev.isOpen,
      };
    });
  };

  const routeAreaFormViewContent: { [key in TAreaFormActions]: <T>(props?: T) => ReactNode } = {
    add: props => AreaAddFormUI(),
    detail: props => (
      <Stack sx={{ height: '100%' }} divider={<Divider color="RG06" />}>
        <Stack align="end" sx={{ padding: '24px 20px 20px 20px' }}>
          <Prev string="이전 목록으로" onClick={RAFV_closer} />

          <Text color="RC02" styleName="title1" sx={{ width: '100%' }}>
            {targetAreaDetails?.areaName}
          </Text>
        </Stack>
        <Stack sx={{ height: '100%' }} useVerticalScroll>
          <Stack sx={{ maxHeight: '671px' }} divider={<Divider color="RG06" />}>
            <DetailModiForm
              title={
                <Text styleName="body2" color="RG02">
                  선택된 구역{' '}
                  <Highlight color="RG04">{`( 총 ${targetAreaDetails?.district.length ?? 0} 개 )`}</Highlight>
                </Text>
              }
              spacing={20}
              isFold={false}
            >
              <Stack direction="row" spacing={6} sx={{ flexWrap: 'wrap' }}>
                {targetAreaDetails?.district.map(x => {
                  let filteredGeo = geo.filter(y => x === y.properties.mainCode);
                  // console.log(filteredGeo);
                  let src = {
                    type: 'FeatureCollection',
                    features: filteredGeo,
                  };

                  let fitGeo = geo.filter(y => addFormMethods.watch('district')?.includes(y.properties.mainCode));
                  let fit = {
                    type: 'FeatureCollection',
                    features: fitGeo,
                  };
                  let bbox = turf.bbox(fit);

                  return (
                    <PlainTag
                      onClick={() => {
                        hoverLikeOverlayHandler({
                          id: filteredGeo.at(0)?.properties.mainCode,
                          name: filteredGeo.at(0)?.properties.mainName,
                          source: src,
                        });

                        areaSearchbarActionHandler(filteredGeo.at(0));

                        //@ts-ignore
                        mapRef.current?.fitBounds(bbox, { padding: 20 });
                      }}
                    >
                      {
                        find(geo, function (o: any) {
                          return o.properties.mainCode === x;
                        })?.properties.mainName
                      }
                    </PlainTag>
                  );
                })}
              </Stack>
            </DetailModiForm>

            <DetailModiForm
              title={
                <Text styleName="body2" color="RG02">
                  담당 드라이버{' '}
                  <Highlight color="RG04">{`( 총 ${targetAreaDetails?.areaDrivers.length ?? 0} 명 )`}</Highlight>
                </Text>
              }
              spacing={20}
              isFold={false}
            >
              <Stack spacing={10}>
                {targetAreaDetails?.areaDrivers.map(x => {
                  const { label, status } =
                    operation_type[operation_type.findIndex(d => d.value === x.vehicleOperationType)];

                  return (
                    <DriverListItem direction="row" spacing={6}>
                      <Tag active fit status={status} styleName="tooltip2">
                        {label}
                      </Tag>
                      <Text styleName="subheadline2" color="RG02">
                        {x.driverName}
                      </Text>
                    </DriverListItem>
                  );
                })}
              </Stack>
            </DetailModiForm>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={16} sx={{ padding: '24px 20px' }}>
          <Button
            variant={'seventh'}
            type={'button'}
            styleName="body2"
            width={86}
            height={40}
            onClick={() => {
              setAreaDelConfirm(true);
            }}
          >
            <Stack justify="center" direction="row" spacing={8}>
              <IcBin width={16} height={16} />
              삭제
            </Stack>
          </Button>

          <Button
            variant={'default'}
            type={'button'}
            width={226}
            height={40}
            styleName="body1"
            color="RG00"
            onClick={() => {
              addFormMethods.setValue('name', `${targetAreaDetails?.areaName}`, { shouldDirty: true });
              addFormMethods.setValue('district', targetAreaDetails?.district ?? [], { shouldDirty: true });
              addFormMethods.setValue(
                'driverList',
                targetAreaDetails?.areaDrivers.map(d => {
                  return {
                    name: d.driverName,
                    driverId: d.driverId,
                    vehicle: {
                      vehicleId: d.vehicleId,
                      licenseNumber: d.vehicleName,
                    },
                    vehicleOperationType: d.vehicleOperationType,
                  };
                }) ?? [],
                { shouldDirty: true }
              );
              setRouteAreaFormView(prev => {
                return { action: 'modi', visible: true };
              });
            }}
          >
            수정하기
          </Button>
        </Stack>
      </Stack>
    ),
    modi: props => AreaAddFormUI(),
  };

  useEffect(() => {
    if (!routeAreaFormView.visible) return setLayerSelected([]);
    else if (routeAreaFormView.action === 'detail') return setLayerSelected(targetAreaDetails?.district ?? []);
  }, [routeAreaFormView]);

  const [targetAreaDetails, setTargetAreaDetails] = useState<{
    areaName: string;
    district: Array<any>;
    areaId: number;
    areaDrivers: Array<{
      driverId: number;
      driverName: string;
      vehicleId?: number;
      vehicleName?: string;
      vehicleOperationType: TOperationType;
    }>;
  }>();

  const [areaDelConfirm, setAreaDelConfirm] = useState<boolean>(false);

  const [hoverLikeCollection, setHoverLikeCollection] = useState<any>();
  const hoverLikeOverlayHandler = (props: any) => {
    console.log(props);
    if (hoverLikeCollection && hoverLikeCollection.id === props.id) return;
    else {
      setHoverLikeCollection({ name: props.mainName, id: props.id, source: props.source });
    }
  };

  const [completedPercent, setCompletedPercent] = useState<number>(0);

  useInterval(
    () => {
      let nextPercent = completedPercent;
      nextPercent += Math.floor(Math.random() * 35);
      setCompletedPercent(nextPercent > 100 ? 100 : nextPercent);
    },
    100,
    !isLoading
  );

  return (
    <Fragment>
      {isLoading ? (
        <>
          <Modal plain width={504} isModalOpen={isLoading} setIsModalOpen={() => {}}>
            <Stack sx={{ width: '100%', padding: '0 80px' }}>
              <Text styleName="title2" color="RG02" sx={{ padding: '52px 28px' }}>
                지역 정보를 불러오고 있습니다.
                <br />
                잠시만 기다려주세요.
              </Text>
              <div style={{ width: '100%' }}>
                <ProgressBar progress={completedPercent} spacing={8} />
              </div>

              <Text styleName="caption3" color="RC04" sx={{ padding: '30px 40px' }}>
                다른 페이지로 이동 또는 새로고침 시
                <br />
                불러오기가 중단되오니 유의하시기 바랍니다.
              </Text>
            </Stack>
          </Modal>
          <Stack direction="row" align="start" sx={{ height: '100%' }}>
            <Fragment>
              <Stack
                sx={{
                  width: routeAreaFormView.visible ? 0 : 374,
                  minWidth: routeAreaFormView.visible ? 0 : 374,
                  height: '100%',
                  paddingTop: '20px',

                  overflow: 'hidden',
                }}
                divider={<Divider color="RG06" />}
              >
                <Fragment>
                  <Stack align="start" sx={{ padding: '20px' }} spacing={6}>
                    <Stack direction="row" spacing={10}>
                      <IcRegion width={24} height={24} />
                      <Text color="RG03" styleName="title1">
                        권역 등록·관리
                      </Text>
                    </Stack>
                    <Text color="RG03" styleName="caption2">
                      배차에 적용할 권역을 등록하고 드라이버를 지정할 수 있습니다.
                    </Text>
                  </Stack>
                  <form
                    onSubmit={handleSubmit(res => {
                      console.log(res);
                    })}
                  >
                    <SearchSection>
                      <Searchbar {...{ methods, content: searchContent }} />
                    </SearchSection>
                  </form>
                  <Stack
                    spacing={6}
                    direction="row"
                    sx={{ padding: '16px 20px' }}
                    divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                  >
                    <Text color="RG03" styleName="caption2">
                      권역 목록
                    </Text>
                    <Text color="RG03" styleName="caption2">
                      총 권역 <Highlight color="RC02">{savedAreaList?.areaList.length ?? 0}</Highlight> 개
                    </Text>
                  </Stack>
                </Fragment>
                <Stack name="content-area-area-lists" sx={{ padding: '16px 20px', height: '100%' }} useVerticalScroll>
                  {savedAreaList && savedAreaList.areaList.length > 0 && !isGetSettedAreaListIsLoading ? (
                    <Stack sx={{ maxHeight: 642 }} spacing={10}>
                      {savedAreaList?.areaList.map(d => {
                        //

                        let filteredGeo = geo.filter(y => d.district?.includes(y.properties.mainCode));
                        // console.log(filteredGeo);
                        let src = {
                          type: 'FeatureCollection',
                          features: filteredGeo,
                        };

                        let bbox = turf.bbox(src);

                        return (
                          <AreaCard
                            padding={10}
                            spacing={6}
                            onMouseEnter={() => {
                              setLayerSelected(d.district ?? []);
                              // @ts-ignore
                              mapRef.current?.fitBounds(bbox, { padding: 20, maxZoom: 10 });

                              fitboundsSearchbarCleaner();
                            }}
                            onClick={() => {
                              setTargetAreaDetails({
                                areaName: d.name,
                                areaId: d.areaId,
                                areaDrivers: d.areaDrivers,
                                district: d.district ?? [],
                              });
                              setRouteAreaFormView({ action: 'detail', visible: true });

                              setLayerSelected(d.district ?? []);

                              // @ts-ignore
                              mapRef.current?.fitBounds(bbox, { padding: 20 });

                              fitboundsSearchbarCleaner();
                            }}
                          >
                            <Stack name="area-card-header" direction="row" justify="space-between">
                              <Text styleName="subheadline1" color="RG02">
                                {d.name}
                              </Text>
                              <IcArrow
                                viewBox="0 0 10 20"
                                style={{
                                  stroke: theme.colors.RG05,
                                }}
                              />
                            </Stack>
                            <Stack
                              name="area-card-drivers-sum"
                              direction="row"
                              spacing={6}
                              divider={<Divider vertical color="RG06" style={{ height: '12px' }} />}
                            >
                              {d.areaDrivers.map((x, i) => {
                                const visibleLen: number = 2;
                                const drviersLen: number = d.areaDrivers.length;
                                if (drviersLen > visibleLen)
                                  return (
                                    i < visibleLen &&
                                    (i === visibleLen - 1 ? (
                                      <Text color="RG02" styleName="caption2">
                                        외 <Highlight color="RC02">{drviersLen - (visibleLen - 1)}</Highlight> 명
                                      </Text>
                                    ) : (
                                      <Text color="RG02" styleName="caption2">
                                        {x.driverName}
                                      </Text>
                                    ))
                                  );
                                else
                                  return (
                                    <Text color="RG02" styleName="caption2">
                                      {x.driverName}
                                    </Text>
                                  );
                              })}
                            </Stack>
                            <Stack direction="row" name="area-card-region-info" spacing={6}>
                              {d.district?.map((x, i) => {
                                const visibleLen: number = 1;
                                const districtLen: number = d.district?.length ?? 0;
                                if (districtLen > visibleLen) {
                                  if (i < visibleLen + 1)
                                    return i === visibleLen ? (
                                      <Text styleName="caption2">
                                        외 <Highlight color="RC02">{districtLen - visibleLen}</Highlight> 구역
                                      </Text>
                                    ) : (
                                      <PlainTag>
                                        {
                                          find(geo, function (o: any) {
                                            return o.properties.mainCode === x;
                                          })?.properties.mainName
                                        }
                                      </PlainTag>
                                    );
                                  else return null;
                                } else
                                  return (
                                    <PlainTag>
                                      {
                                        find(geo, function (o: any) {
                                          return o.properties.mainCode === x;
                                        })?.properties.mainName
                                      }
                                    </PlainTag>
                                  );
                              })}
                            </Stack>
                          </AreaCard>
                        );
                      })}
                    </Stack>
                  ) : (
                    <Stack justify="center" sx={{ overflow: 'auto', height: '100%' }}>
                      <Text color="RG04" styleName="title2">
                        {isGetSettedAreaListIsLoading ? '등록된 권역을 불러오고 있습니다...' : '등록된 권역이 없습니다'}
                      </Text>
                    </Stack>
                  )}
                </Stack>

                <Stack sx={{ padding: '24px 20px', margin: '0 0 48px 0' }}>
                  <Button
                    variant={'default'}
                    type={'button'}
                    fullWidth
                    height={40}
                    styleName="body1"
                    color="RG00"
                    onClick={() => {
                      addFormMethods.reset();
                      setLayerSelected([]);
                      setRouteAreaFormView(prev => {
                        return { action: 'add', visible: true };
                      });
                    }}
                  >
                    <IcAdd style={{ marginRight: '8px' }} /> 권역 추가
                  </Button>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: routeAreaFormView.visible ? 374 : 0,
                  minWidth: routeAreaFormView.visible ? 374 : 0,
                  height: '100%',

                  overflow: 'hidden',
                  visibility: routeAreaFormView.visible ? 'visible' : 'hidden',
                }}
                divider={<Divider color="RG06" />}
              >
                {routeAreaFormViewContent[routeAreaFormView.action]<any>()}
              </Stack>
            </Fragment>
            <SettingAreaMap
              ref={mapRef}
              {...{
                methods: addFormMethods,
                areaFormMethods: areaFormMethods,
                layerSelected,
                setLayerSelected,
                routeAreaFormView,
                hoverLikeCollection,
                setHoverLikeCollection,
                districtSelector,
                setDistrictSelector,
                listenerSource,
                isLoading,
              }}
            />
          </Stack>
        </>
      ) : (
        <Stack direction="row" align="start" sx={{ height: '100%' }}>
          <Fragment>
            <Stack
              sx={{
                width: routeAreaFormView.visible ? 0 : 374,
                minWidth: routeAreaFormView.visible ? 0 : 374,
                height: '100%',

                overflow: 'hidden',
                paddingTop: '20px',
              }}
              divider={<Divider color="RG06" />}
            >
              <Fragment>
                <Stack align="start" sx={{ padding: '20px' }} spacing={6}>
                  <Stack direction="row" spacing={10}>
                    <IcRegion width={24} height={24} />
                    <Text color="RG03" styleName="title1">
                      권역 등록·관리
                    </Text>
                  </Stack>
                  <Text color="RG03" styleName="caption2">
                    배차에 적용할 권역을 등록하고 드라이버를 지정할 수 있습니다.
                  </Text>
                </Stack>
                <form
                  onSubmit={handleSubmit(res => {
                    console.log(res);
                  })}
                >
                  <SearchSection>
                    <Searchbar {...{ methods, content: searchContent }} />
                  </SearchSection>
                </form>
                <Stack
                  spacing={6}
                  direction="row"
                  sx={{ padding: '16px 20px' }}
                  divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                >
                  <Text color="RG03" styleName="caption2">
                    권역 목록
                  </Text>
                  <Text color="RG03" styleName="caption2">
                    총 권역 <Highlight color="RC02">{savedAreaList?.areaList.length ?? 0}</Highlight> 개
                  </Text>
                </Stack>
              </Fragment>
              <Stack name="content-area-area-lists" sx={{ padding: '16px 20px', height: '100%' }} useVerticalScroll>
                {savedAreaList && savedAreaList.areaList.length > 0 && !isGetSettedAreaListIsLoading ? (
                  <Stack sx={{ maxHeight: 642 }} spacing={10}>
                    {savedAreaList?.areaList.map(d => {
                      //

                      let filteredGeo = geo.filter(y => d.district?.includes(y.properties.mainCode));
                      // console.log(filteredGeo);
                      let src = {
                        type: 'FeatureCollection',
                        features: filteredGeo,
                      };

                      let bbox = turf.bbox(src);

                      return (
                        <AreaCard
                          padding={10}
                          spacing={6}
                          onMouseEnter={() => {
                            setLayerSelected(d.district ?? []);
                            // @ts-ignore
                            mapRef.current?.fitBounds(bbox, { padding: 20, maxZoom: 10 });

                            fitboundsSearchbarCleaner();
                          }}
                          onClick={() => {
                            setTargetAreaDetails({
                              areaName: d.name,
                              areaId: d.areaId,
                              areaDrivers: d.areaDrivers,
                              district: d.district ?? [],
                            });
                            setRouteAreaFormView({ action: 'detail', visible: true });

                            setLayerSelected(d.district ?? []);

                            // @ts-ignore
                            mapRef.current?.fitBounds(bbox, { padding: 20 });

                            fitboundsSearchbarCleaner();
                          }}
                        >
                          <Stack name="area-card-header" direction="row" justify="space-between">
                            <Text styleName="subheadline1" color="RG02">
                              {d.name}
                            </Text>
                            <IcArrow
                              viewBox="0 0 10 20"
                              style={{
                                stroke: theme.colors.RG05,
                              }}
                            />
                          </Stack>
                          <Stack
                            name="area-card-drivers-sum"
                            direction="row"
                            spacing={6}
                            divider={<Divider vertical color="RG06" style={{ height: '12px' }} />}
                          >
                            {d.areaDrivers.map((x, i) => {
                              const visibleLen: number = 2;
                              const drviersLen: number = d.areaDrivers.length;
                              if (drviersLen > visibleLen)
                                return (
                                  i < visibleLen &&
                                  (i === visibleLen - 1 ? (
                                    <Text color="RG02" styleName="caption2">
                                      외 <Highlight color="RC02">{drviersLen - (visibleLen - 1)}</Highlight> 명
                                    </Text>
                                  ) : (
                                    <Text color="RG02" styleName="caption2">
                                      {x.driverName}
                                    </Text>
                                  ))
                                );
                              else
                                return (
                                  <Text color="RG02" styleName="caption2">
                                    {x.driverName}
                                  </Text>
                                );
                            })}
                          </Stack>
                          <Stack direction="row" name="area-card-region-info" spacing={6}>
                            {d.district?.map((x, i) => {
                              const visibleLen: number = 1;
                              const districtLen: number = d.district?.length ?? 0;
                              if (districtLen > visibleLen) {
                                if (i < visibleLen + 1)
                                  return i === visibleLen ? (
                                    <Text styleName="caption2">
                                      외 <Highlight color="RC02">{districtLen - visibleLen}</Highlight> 구역
                                    </Text>
                                  ) : (
                                    <PlainTag>
                                      {
                                        find(geo, function (o: any) {
                                          return o.properties.mainCode === x;
                                        })?.properties.mainName
                                      }
                                    </PlainTag>
                                  );
                                else return null;
                              } else
                                return (
                                  <PlainTag>
                                    {
                                      find(geo, function (o: any) {
                                        return o.properties.mainCode === x;
                                      })?.properties.mainName
                                    }
                                  </PlainTag>
                                );
                            })}
                          </Stack>
                        </AreaCard>
                      );
                    })}
                  </Stack>
                ) : (
                  <Stack justify="center" sx={{ overflow: 'auto', height: '100%' }}>
                    <Text color="RG04" styleName="title2">
                      {isGetSettedAreaListIsLoading ? '등록된 권역을 불러오고 있습니다...' : '등록된 권역이 없습니다'}
                    </Text>
                  </Stack>
                )}
              </Stack>

              <Stack sx={{ padding: '24px 20px', margin: '0 0 48px 0' }}>
                <Button
                  variant={'default'}
                  type={'button'}
                  fullWidth
                  height={40}
                  styleName="body1"
                  color="RG00"
                  onClick={() => {
                    addFormMethods.reset();
                    setLayerSelected([]);
                    setRouteAreaFormView(prev => {
                      return { action: 'add', visible: true };
                    });
                  }}
                >
                  <IcAdd style={{ marginRight: '8px' }} /> 권역 추가
                </Button>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: routeAreaFormView.visible ? 374 : 0,
                minWidth: routeAreaFormView.visible ? 374 : 0,
                height: '100%',

                overflow: 'hidden',
                visibility: routeAreaFormView.visible ? 'visible' : 'hidden',
              }}
              divider={<Divider color="RG06" />}
            >
              {routeAreaFormViewContent[routeAreaFormView.action]<any>()}
            </Stack>
          </Fragment>
          <SettingAreaMap
            ref={mapRef}
            {...{
              methods: addFormMethods,
              areaFormMethods: areaFormMethods,
              layerSelected,
              setLayerSelected,
              routeAreaFormView,
              hoverLikeCollection,
              setHoverLikeCollection,
              districtSelector,
              setDistrictSelector,
              listenerSource,
              isLoading,
              geo,
            }}
          />
        </Stack>
      )}

      <CloseConfirmModal
        isOpen={areaDelConfirm}
        setIsOpen={setAreaDelConfirm}
        targetSetIsOpen={() => {}}
        btnFunctions={{
          RBT: () => {
            if (targetAreaDetails) mutateDeleteArea(targetAreaDetails?.areaId);
          },
        }}
        text={
          <Stack spacing={10}>
            <Text styleName="title2" color="RG02">
              권역을 삭제하시겠습니까?
            </Text>
            <Text styleName="subheadline2" color="RC04">
              삭제한 권역 정보는 되돌릴 수 없습니다.
            </Text>
          </Stack>
        }
        RBT={'권역 삭제'}
        LBT={'취소'}
      />

      <ShiroiModal isOpen={areaUnvalidateModal} setIsOpen={setAreaUnvalidateModal} footer="돌아가기">
        <Text styleName="subheadline2" color="RG02" sx={{ padding: '30px 0 40px' }}>
          선택된 구역이 없습니다.
          <br />
          권역에 포함시킬 행정구역을 선택해주세요.
        </Text>
      </ShiroiModal>
    </Fragment>
  );
};

export default RouteArea;

const AreaCard = styled(Stack)`
  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
  :hover {
    ${({ theme }) => ({
      borderColor: theme.colors.RC03,
      backgroundColor: theme.colors.RC03_1,
    })}
  }

  cursor: pointer;
  border-radius: 8px;
`;

const SearchSection = styled(Stack)`
  height: 56px;
  padding: 12px 19px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG08,
  })}
`;

export const PlainTag = styled.div`
  padding: 0 6px;
  border-radius: 6px;
  ${({ theme }) => ({
    color: theme.colors.RG02,
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,

    ':hover': {
      backgroundColor: theme.colors.RG07,
    },
  })}

  cursor: pointer;
  transition: all 0.3s;
  ${({ theme }) => theme.fontStyle.caption2}
`;

const SearchbarInputContainer = styled(Stack)<{ isFilled: boolean }>`
  padding: 6px 10px;

  & > input {
    width: 100%;
    background: transparent;

    ::placeholder {
      color: ${({ theme }) => theme.colors.RG04};
    }
  }

  /* & > svg {
      cursor: pointer;
    } */

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.RG04};
  }

  ${({ isFilled }) =>
    isFilled && {
      '& > svg': {
        width: 0,

        transition: 'all 0.4s',
      },
    }}
`;

const FilterSearchbarWrapper = styled(Stack)<{ width?: string }>`
  ${({ theme }) => theme.fontStyle.caption2}
  height: 32px;

  .search-item-select-wrapper {
    width: 70px;
    margin: 0 0 0 10px;

    cursor: pointer;
  }

  .search-item-select {
    color: ${({ theme }) => theme.colors.RG04};
  }

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  background-color: ${({ theme }) => theme.colors.RG00};

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.RC03};

    .search-item-select {
      color: ${({ theme }) => theme.colors.RG02};
    }
  }

  :focus-within {
    border: 1px solid ${({ theme }) => theme.colors.RC03};

    & > input {
      ::placeholder {
        color: ${({ theme }) => theme.colors.RG00};
      }
    }
  }

  transition: all 0.2s;

  & > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const DriverListItem = styled(Stack)`
  padding: 8px;
  border-radius: 8px;
  ${({ theme }) => ({
    border: `1px solid ${theme.colors.RG06}`,

    ':hover': {
      backgroundColor: theme.colors.RC03_1,
      border: `1px solid ${theme.colors.RC03}`,
    },
  })}

  cursor: pointer;
  transition: all 0.3s;
`;
const NonSelectDefaultBox = styled(Stack)`
  ${({ theme }) => ({
    color: theme.colors.RC02,
    backgroundColor: theme.colors.RC03_1,
  })}

  padding: 8px;
  border-radius: 8px;
  ${({ theme }) => theme.fontStyle.caption2}
`;
