import { CSSProperties, ReactElement, ReactNode } from 'react';
import * as S from './style';

export interface BoxContainerProps {
  children: ReactNode;

  name?: string;

  bg?: string;
  justify?: 'space-between' | 'start' | 'end' | 'space-around';
  align?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | 'baseline';
  sx?: CSSProperties;
  useVerticalScroll?: boolean;
  useHorizontalScroll?: boolean;
  useBothScroll?: boolean;
}

export function BoxContainer({ children, sx, ...props }: BoxContainerProps): ReactElement {
  return (
    <S.BoxContainer style={sx} {...props}>
      {children}
    </S.BoxContainer>
  );
}
