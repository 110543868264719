// export const xlsxUri = {
//   roouty:
//     'https://roouty-tms.s3.ap-northeast-2.amazonaws.com/%EB%A3%A8%ED%8B%B0_%EC%A3%BC%EB%AC%B8%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D_20221201.xlsx',
//   survey:
//     'https://roouty-tms.s3.ap-northeast-2.amazonaws.com/%E1%84%85%E1%85%AE%E1%84%90%E1%85%B5_%E1%84%8C%E1%85%A9%E1%84%89%E1%85%A1%E1%84%83%E1%85%A2%E1%84%89%E1%85%A1%E1%86%BC%E1%84%83%E1%85%B3%E1%86%BC%E1%84%85%E1%85%A9%E1%86%A8+%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8_20221215.xlsx',
//   manual:
//     'https://roouty-tms.s3.ap-northeast-2.amazonaws.com/%EC%88%98%EB%8F%99%EB%B0%B0%EC%B0%A8_%EB%A3%A8%ED%8B%B0_%EC%A3%BC%EB%AC%B8%EB%93%B1%EB%A1%9D_%EC%96%91%EC%8B%9D_20221216.xlsx',
// };

export const commonUris = {
  FAQ: 'https://www.notion.so/wemeetplace/Roouty-f7620d77391b4482bdddb14801d3940f',
  tos: 'https://policy.roouty.io/tos',
  location: 'https://policy.roouty.io/location',
  policy_privacy: 'https://policy.roouty.io/policy_privacy',

  roouty: 'https://www.roouty.com/',
  contact: 'https://forms.gle/hHWj1obWES2XKkwk9',
};
