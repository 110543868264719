import {BoxContainer} from 'components/BoxContainer/style';

import theme from 'constants/theme';
import {TThemeColor} from 'constants/types';
import styled from 'styled-components';

interface Style {
    value?: string;
    height?: number;
}

export const OperationType = styled.button<{ bgColor?: TThemeColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  gap: 10px;
  width: 36px;
  height: 20px;
  border-radius: 4px;

  text-align: center;

  ${({theme, bgColor}) => ({
    color: theme.colors.RG00,
    backgroundColor: bgColor ? theme.colors[bgColor] : '#406ee2',
  })}

  ${({theme}) => theme.fontStyle.tooltip3}
`;

export const TableWrapper = styled.div<Style>`
  width: 100%;
  /* overflow-x: scroll; */
  max-height: ${({height}) => (height ? `${height}px` : '100%')};
  display: flex;
  flex-direction: column;

  position: relative;

  /* gap: 16px; */

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

    /* ${({theme}) => theme.tableScrollStyle.vertical} */
`;

export const TableScrollableDense = styled.div`
  ${({theme}) => theme.tableScrollStyle.both};

  max-height: max-content;
`;

export const TableScrollable = styled.div<{ isEmpty?: boolean; useFullHeight?: boolean }>`
  ${({theme, isEmpty}) => !isEmpty && theme.tableScrollStyle.both};
  min-height: ${({isEmpty}) => (isEmpty ? '56px' : 'max-content')};
  max-height: ${({useFullHeight}) => (useFullHeight ? 'max-content' : '596px')};
`;

export const CTable = styled.table<{ rowHeight?: number }>`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    :first-child {
      padding: 0px 15px 0 20px;
    }

    :last-child {
      padding: 0px 10px 0 15px;
    }

    padding: 0px 10px;
    height: ${({rowHeight}) => (rowHeight ? rowHeight + 'px' : '54px')};

    & > input {
      display: block;
    }

    vertical-align: middle;
    white-space: nowrap;

      /* border-left: 1px solid ${({theme}) => theme.colors.RG06}; */
  }

  th:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  th:after {
    bottom: -1px;
    border-bottom: 1px solid ${({theme}) => theme.colors.RG06};
  }

  thead > tr {
    position: sticky;
    top: 0px;
    z-index: 1;
  }

  thead > tr:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 1px;
    background: ${({theme}) => theme.colors.RG06};
  }
`;
const Ctr = styled.tr`
  text-align: start;
  border-bottom: 1px solid ${({theme}) => theme.colors.RG06};
`;

export const CtrRow = styled(Ctr)<{ isSelectedRow?: boolean; isDragging?: boolean; hoverSelect?: boolean }>`
  ${({theme}) => theme.fontStyle.caption2}
  color: ${({theme}) => theme.colors.RG02};

  ${({isSelectedRow, hoverSelect}) => {
    if (hoverSelect) {
      return {
        background: theme.colors.RC03_1,
      };
    } else if (isSelectedRow) {
      return {background: theme.colors.RG08};
    }
  }}
  :last-child {
    border-bottom: none;
  }

  background: ${({isDragging}) => (isDragging ? theme.colors.RC03_1 : '')};

  :hover {
    ${({theme}) => theme.fontStyle.caption1}
    ${({theme}) => ({
      background: theme.colors.RC03_1,
    })}
    line-height: 20px;
  }

  td > .hoverShow,
  td > div > .hoverShow,
  td > div > div > .hoverShow {
    opacity: 0;
    position: absolute;

    svg {
      display: block;
    }
  }

  :hover > td > .hoverShow,
  :hover > td > div > .hoverShow,
  :hover > td > div > div > .hoverShow {
    opacity: 1;

    svg {
      display: block;
    }
  }
`;

export const CtrColumn = styled(Ctr)`
  ${({theme}) => ({
    background: theme.colors.RG00,
  })}
`;

export const Cth = styled.th`
  ${({theme}) => theme.fontStyle.caption1}
  text-align: start;
  background-color: ${({theme}) => theme.colors.RG07};
`;

export const Ctd = styled.td`
  position: relative;

  div:not(.hoverShow) {
    position: relative;
  }
`;

export const TableFooter = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: end;

  gap: 34px;
`;

export const FooterButtonBox = styled.div`
  display: flex;
  gap: 10px;

  & > button {
    ${({theme}) => theme.fontStyle.caption2}
    width: 32px;
    height: 32px;
    color: ${({theme}) => theme.colors.RG04};
    background-color: ${({theme}) => theme.colors.RG08};

    border-radius: 6px;

    :hover {
      ${({theme}) => ({
        color: theme.colors.RC02,
        backgroundColor: theme.colors.RC03_1,
      })}
    }

    :disabled {
      ${({theme}) => ({
        color: theme.colors.RG04,
        backgroundColor: theme.colors.RG08,
        cursor: 'not-allowed',
      })}
    }
  }
`;

export const FooterInfo = styled.div`
  ${({theme}) => theme.fontStyle.caption2}

  display: flex;
  align-items: center;

  gap: 10px;
`;

export const TotalInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;

  background: ${({theme}) => theme.colors.RG07};
  border-radius: 8px;
  width: 100%;

  padding: 13px 16px;
  width: 162px;
  height: 72px;

  overflow: hidden;
`;

export const TableDeatilButton = styled.button<{ deactive?: boolean }>`
  transition: background-color 0.3s ease-in-out;

  width: 32px;
  height: 32px;

  border-radius: 16px;

  background-color: transparent;

  ${({deactive, theme}) =>
          deactive
                  ? {
                    cursor: 'not-allowed',
                  }
                  : {
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: theme.colors.RC03_3,
                    },
                  }};
`;

export const DetailSelectBox = styled.div`
  ${({theme}) => theme.shadows.strong}
  position: absolute;
  box-sizing: border-box;

  margin: 10px 0 0 0;
  padding: 16px 0;

  width: 100px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.colors.RG06};

  background-color: ${({theme}) => theme.colors.RG00};
`;

export const DetailSelectBoxButton = styled.button`
  ${({theme}) => theme.fontStyle.caption2};
  background-color: ${({theme}) => theme.colors.RG00};
  width: 100%;
  min-height: 32px;
  padding: 6px 10px;

  text-align: start;
  cursor: pointer;

  :hover {
    ${({theme}) => theme.fontStyle.caption1};
    background-color: ${({theme}) => theme.colors.RC03_1};
  }
`;

// table pagenation button style
export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.RG08};
  width: 28px;
  height: 28px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    svg > #path1 {
      stroke: ${({theme}) => theme.colors.RC02};
    }

    background: ${({theme}) => theme.colors.RC03_1};
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const GridColumn = styled.div`
  display: grid;
  /* grid-template-columns: repeat(4, minmax(0, 1fr)); */
  /* grid-auto-columns: 1fr; */
  grid-auto-flow: column dense;
  gap: 20px;
`;

export const RowDirectionContainer = styled.div<{ some?: boolean }>`
  display: flex;
  width: min-content;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-width: 40px;
  max-width: 40px;
  height: 100%;
`;

export const TableStateBody = styled(BoxContainer)<{ height?: number }>`
  height: calc(100% - 90px);
  ${({height}) => ({
    minHeight: height ? `calc(${height + 'px'} - 72px)` : `calc(594px - 54px)`,
  })}

  border-bottom: 1px solid ${({theme}) => theme.colors.RG06};
`;

export const SortBox = styled(BoxContainer)`
  width: 16px;
  height: 16px;

  border-radius: 8px;
`;

export const SortContainer = styled(BoxContainer)`
  gap: 6px;
  cursor: pointer;

  :hover > ${SortBox} {
    transition: all 0.2s;
    background-color: ${({theme}) => theme.colors.RG01_1};
  }
`;
