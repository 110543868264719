import { useQuery } from '@tanstack/react-query';
import { getTeamList } from 'api';
import { Button } from 'components/Button';
import { Button as LegacyButton } from 'components/Button/legacy_index';

import Modal from 'components/Modal';

import AddTeamModal from 'components/Modal/AddTeamModal';
import QueryStatusModal from 'components/Modal/QueryStatusModal';
import { UpgradeGuideModal } from 'components/Modal/warning/paymentPlan/UpgradeGuideModal';
import { Stack } from 'components/Stack';
import TeamManagementTable from 'components/Table/TeamManagementTable';
import { Text } from 'components/Typography';
import { ReactComponent as IcError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IcWarning } from 'constants/icon/ic_file_upload_warning.svg';

import { ReactComponent as IcPlus } from 'constants/icon/ic_plus.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';
import { IGetTeamListProps, IGetTeamListResponse, IPaymentStatus, ISelectorRequires, TS3 } from 'constants/types';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { useTracking } from 'hooks/store/useTracking';
import { FilterSearchbar } from 'pages/OrderManagePage';
import { TCallbackConstructor } from 'pages/RoutePlanConfirm';
import GrayBox from 'pages/Setting/common/GrayBox';

import Layout from 'pages/Setting/Layout';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStore } from 'store';
import { PlanStatusUnion } from 'types/payment/union';
import type { TSettingAttrs } from 'types/setting/union';

type TCallbackIcons = 'suc' | 'war' | 'err';
type TGuideModalData = {
  upgradeGuidePlan: PlanStatusUnion[];
  text:
    | '요금제로 업그레이드 하시면 팀을 추가하실 수 있습니다.'
    | '요금제로 다운그레이드 신청 상태에는 팀을 추가할 수 없습니다.\n다운그레이드 신청을 취소하고 다시 시도해 주세요.';
};

const callback_icon_mapper: { [key in TCallbackIcons]: ReactElement } = {
  suc: <IcSuccess width={44} height={44} fill={theme.colors.RC02} />,
  war: <IcWarning width={44} height={44} />,
  err: <IcError width={44} height={44} />,
};
const filter_content: Array<ISelectorRequires<string> & { placeholder: string }> = [
  { value: 'name', key: '팀 이름', placeholder: '팀 이름을 입력해주세요' },
  { value: 'managerName', key: '매니저명', placeholder: '매니저명을 입력해주세요' },
  { value: 'driverName', key: '드라이버명', placeholder: '드라이버명을 입력해주세요' },
  { value: 'memo', key: '메모', placeholder: '메모를 입력해주세요' },
];

const Team = ({
  navTo,
  fatalCorrectionDetectorWithFence,
}: {
  navTo: TS3<TSettingAttrs>;
  fatalCorrectionDetectorWithFence: Function;
}) => {
  const { userGrade } = useStore();
  const methods_default_values: IGetTeamListProps = {
    searchItem: filter_content[0].value,
    keyword: '',
  };
  const methods = useForm({ mode: 'onSubmit', defaultValues: { ...methods_default_values } });
  const { handleSubmit } = methods;

  const { data, refetch } = useQuery<IGetTeamListResponse>(
    // ['getTeamList', methods.getValues()],
    ['getTeamList'],
    () => {
      return methods.getValues('keyword') !== '' ? getTeamList({ ...methods.getValues() }) : getTeamList({});
    },
    {
      onSuccess: res => {
        console.log(res, 'test_console');
      },
    }
  );
  const { data: TOTAL_TEAM_LIST, refetch: TOTAL_TEAM_LIST_REFETCH } = useQuery<IGetTeamListResponse>(
    ['getTeamList_TOTAL_TEAM'],
    () => getTeamList({}),
    {}
  );

  const { data: paymentMyData } = useGetPaymentMy();

  const [callbackModalIsOpen, setCallbackModalIsOpen] = useState<boolean>(false);
  const [callback_message_by_type, _callback_message_by_type] = useState<TCallbackConstructor<TCallbackIcons>>(null);
  const [addTeamModalIsOpen, setAddTeamModalIsOpen] = useState<boolean>(false);
  const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);
  const [guideModalData, setGuideModalData] = useState<TGuideModalData>({
    upgradeGuidePlan: ['Pro', 'Enterprise'],
    text: '요금제로 업그레이드 하시면 팀을 추가하실 수 있습니다.',
  });

  const { setTitle } = useTracking();

  useEffect(() => {
    setTitle('설정>팀 관리');
  }, [setTitle]);

  return (
    <Layout
      attr="team"
      title={'팀 관리'}
      desc={'팀을 추가하고 관리할 수 있습니다.'}
      ts={
        userGrade > 1 ? null : (
          <Button
            type="button"
            variant="primary"
            h={32}
            w={132}
            icon={[IcPlus, { fill: '#FFF' }]}
            onClick={() => {
              if (
                (paymentMyData?.downgrade &&
                  (paymentMyData?.downgrade.name === 'Free' ||
                    paymentMyData?.downgrade.name === 'Lite' ||
                    paymentMyData?.downgrade.name === 'Standard')) ||
                paymentMyData?.cancellation
              ) {
                setGuideModalData({
                  upgradeGuidePlan: ['Free', 'Lite', 'Standard'],
                  text: '요금제로 다운그레이드 신청 상태에는 팀을 추가할 수 없습니다.\n다운그레이드 신청을 취소하고 다시 시도해 주세요.',
                });
                setIsOpenUpgradeGuideModal(true);

                return;
              }

              if (!(paymentMyData?.name === 'Pro' || paymentMyData?.name === 'Enterprise')) {
                setGuideModalData({
                  upgradeGuidePlan: ['Pro', 'Enterprise'],
                  text: '요금제로 업그레이드 하시면 팀을 추가하실 수 있습니다.',
                });
                setIsOpenUpgradeGuideModal(true);

                return;
              }

              // trial 상태의 계정
              if (
                (JSON.parse(window.localStorage.getItem('pricing')!) as { id: number; status: IPaymentStatus }).id === 1
              ) {
                _callback_message_by_type({
                  icon: 'war',
                  content: <Text styleName="body2">요금제를 구독하시면 팀을 추가하실 수 있습니다.</Text>,
                });
                setCallbackModalIsOpen(true);
              } // 요금이 활성화 된 계정상태
              else {
                setAddTeamModalIsOpen(true);
              }
            }}
          >
            팀 추가하기
          </Button>
        )
      }
    >
      <Stack spacing={40}>
        <GrayBox direction="row" spacing={12}>
          <FilterSearchbar
            {...{
              methods,
              content: filter_content,
            }}
          />
          <Button
            type="button"
            variant="secondary"
            h={32}
            onClick={handleSubmit(valid => {
              console.log(valid, 'test_console');
              refetch();
              TOTAL_TEAM_LIST_REFETCH();
            })}
          >
            조회하기
          </Button>
        </GrayBox>
        <TeamManagementTable
          teamTotal={TOTAL_TEAM_LIST?.data.length ?? 0}
          data={data?.data ?? []}
          {...{
            navTo,
            onControl: (string: string) => {
              refetch();
              TOTAL_TEAM_LIST_REFETCH();
              _callback_message_by_type({
                icon: 'suc',
                content: string,
              });
              setCallbackModalIsOpen(true);
            },
            fatalCorrectionDetectorWithFence,
          }}
        />

        <AddTeamModal
          isOpen={addTeamModalIsOpen}
          setIsOpen={setAddTeamModalIsOpen}
          {...{
            onControl: () => {
              refetch();
              TOTAL_TEAM_LIST_REFETCH();
              _callback_message_by_type({
                icon: 'suc',
                content: '팀 추가를 완료하였습니다.',
              });
              setCallbackModalIsOpen(true);
            },
          }}
        />

        <QueryStatusModal
          autoClose={3000}
          status={'success'}
          string={callback_message_by_type?.content as string}
          {...{ isOpen: callbackModalIsOpen, setIsOpen: setCallbackModalIsOpen }}
        />

        <Modal
          isModalOpen={callbackModalIsOpen}
          setIsModalOpen={setCallbackModalIsOpen}
          padding={24}
          width={503}
          closeCallbackFunc={() => {
            _callback_message_by_type(null);
          }}
        >
          <Stack>
            <Stack>
              {callback_message_by_type?.icon && callback_icon_mapper[callback_message_by_type.icon]}
              <Text
                styleName="subheadline2"
                color="RG02"
                sx={{
                  padding: '30px 0 40px 0',
                }}
              >
                {callback_message_by_type?.content}
              </Text>
            </Stack>
            <LegacyButton
              variant={'second'}
              type={'button'}
              fullWidth
              styleName="body2"
              color="RG03"
              onClick={() => {
                if (callback_message_by_type?.callback) callback_message_by_type.callback();
                _callback_message_by_type(null);
                setCallbackModalIsOpen(false);
              }}
              sx={{ padding: '8px 10px' }}
            >
              확인
            </LegacyButton>
          </Stack>
        </Modal>
      </Stack>
      <UpgradeGuideModal
        isOpen={isOpenUpgradeGuideModal}
        setIsOpen={setIsOpenUpgradeGuideModal}
        upgradeGuidePlan={guideModalData.upgradeGuidePlan}
        text={guideModalData.text}
      />
    </Layout>
  );
};

export default Team;
