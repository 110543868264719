import html2canvas from 'html2canvas';
import { RefObject } from 'react';
import { jsPDF } from 'jspdf';

// fileName 예시: 루티_요금명세서_20230822.pdf
export const handleDownloadPdf = (ref: RefObject<HTMLElement>, fileName: string, margin: number = 4) => {
  const element = ref.current;

  if (!element) return;

  const pdf = new jsPDF('p', 'pt', 'a4', true);

  html2canvas(element).then(canvas => {
    const fileData = canvas.toDataURL('image/png');

    const fileProperties = pdf.getImageProperties(fileData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (fileProperties.height * pdfWidth) / fileProperties.width;

    pdf.addImage(fileData, 'PNG', margin, margin, pdfWidth - margin * 2, pdfHeight, '', 'FAST');
    pdf.save(`${fileName}.pdf`);
  });
};
