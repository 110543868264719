import { ReactNode, CSSProperties, JSXElementConstructor, ReactElement, Fragment } from 'react';
import * as S from './style';

export type TTagStatusKeys =
  | 'done'
  | 'cancel'
  | 'todo'
  | 'moving'
  | 'preparing'
  | 'skipped'
  | 'purple'
  | 'etaGood'
  | 'etaCaution'
  | 'etaWarning'
  | 'driving1'
  | 'driving2'
  | 'driving3'
  | 'progress';
export interface TagProps {
  children: string | ReactNode;
  disable?: boolean;
  active?: boolean;
  fit?: boolean;

  useTooltip?: boolean;
  outline?: boolean;

  status: TTagStatusKeys;
  styleName?:
    | 'headline1'
    | 'headline2'
    | 'headline3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subheadline1'
    | 'subheadline2'
    | 'subheadline3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'tooltip1'
    | 'tooltip2'
    | 'number1'
    | 'number2'
    | 'number3'
    | 'number4'
    | 'number5';

  sx?: CSSProperties;
}

const MappedStyled: { [key in TTagStatusKeys]: JSXElementConstructor<any> } = {
  done: S.Done,
  cancel: S.cancel,
  todo: S.Todo,
  moving: S.Moving,
  preparing: S.Preparing,
  purple: S.Purple,
  skipped: S.Skipped,
  etaGood: S.EtaGood,
  etaCaution: S.EtaCaution,
  etaWarning: S.EtaWarning,
  driving1: S.Driver1,
  driving2: S.Driver2,
  driving3: S.Driver3,
  progress: S.Progress,
};

const MappedStyledTooltip: { [key in TTagStatusKeys]: JSXElementConstructor<any> } = {
  done: S.DoneTooltip,
  cancel: S.cancel,
  todo: S.Todo,
  moving: S.MovingTooltip,
  preparing: S.Preparing,
  purple: S.PurpleTooltip,
  skipped: S.SkippedTooltip,
  etaGood: S.EtaGood,
  etaCaution: S.EtaCaution,
  etaWarning: S.EtaWarning,
  driving1: S.Driver1,
  driving2: S.Driver2,
  driving3: S.Driver3,
  progress: S.Progress,
};

export function Tag({ sx, children, ...props }: TagProps): ReactElement {
  const MappedStyledComponent = props.useTooltip ? MappedStyledTooltip[props.status] : MappedStyled[props.status];

  if (Object.keys(MappedStyled).includes(props.status))
    return (
      <MappedStyledComponent {...props} style={sx}>
        {children}
      </MappedStyledComponent>
    );
  else return <Fragment />;
}
