import { AuthLayout } from 'auth/AuthLayout';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';

import { useForm } from 'react-hook-form';
import { Fragment, useEffect, useState } from 'react';

import { _getCheckManagerId, _postSignUpManager } from 'auth/signUp/container';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { useStore } from 'store';
import { useParams, useNavigate } from 'react-router-dom';
import { InvalidEmailErrorModal } from 'auth/resetPassword/InvalidEmailErrorModal';

import { InputLabel, InputPassword, InputUserName, UseQueryAuthority, UseQueryMyInfo, storeToken } from 'auth/common';
import { ReactComponent as ErrorMarking } from 'constants/icon/ic_error_alert_circle.svg';

import { ReactComponent as CircleCheckBox } from 'constants/icon/ic_success.svg';
import { ReactComponent as ArrowUp } from 'constants/icon/ic_arrow_up.svg';
import { WelcomePage } from 'auth/signUp/WelcomePage';

import * as CS from 'auth/style';
import * as S from 'auth/resetPassword/style';

import * as CHECK from 'auth/signUp/style';
import { Stack } from 'components/Stack';
import theme from 'constants/theme';
import { login } from 'api';
import { useMutation } from '@tanstack/react-query';

export const SignUpManager = () => {
  const { isLoading, setIsLoading } = useStore();
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
  const [welcomePageMode, setWelcomePageMode] = useState<TWelcomePageType>('');
  const navigate = useNavigate();
  const invitationKey = useParams().key;
  const [isTermsDetailOpen, setIsTermsDetailOpen] = useState<boolean>(false);
  const [termError, setTermError] = useState<boolean>(false);

  const [target, setTarget] = useState<string | null>(null);

  const [checkbox, setCheckbox] = useState<{ a: boolean; b: boolean }>({ a: false, b: false });

  useEffect(() => {
    _getCheckManagerId(invitationKey, setIsInvalidEmail);
    return () => {
      setWelcomePageMode('');
    };
  }, []);

  const {
    register,
    getValues,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm<TManagerSignUpQuery>();

  const isDisalbed =
    [errors.name, errors.password].some(Boolean) ||
    [getValues('name'), getValues('password')].every(Boolean) === false ||
    (checkbox.a && checkbox.b) === false;

  if (welcomePageMode === 'Manager') {
    return (
      <WelcomePage
        accountInfo={{ loginAccount: target!, password: getValues('password') }}
        welcomePageMode={welcomePageMode}
        setWelcomePageMode={setWelcomePageMode}
      />
    );
  }

  return (
    <AuthLayout>
      <CS.SignInResetPasswordLayout>
        <InvalidEmailErrorModal
          title="유효기간이 만료된 초대 링크입니다."
          messages={['새로운 인증 링크를 받으려면', '회사 관리자에게 문의해 주세요.']}
          isOpen={isInvalidEmail}
          navigate={navigate}
        />
        <LoadingModal isLoading={isLoading} text={'메일을 인증 중입니다. 잠시만 기다려 주세요.'} />
        <Fragment>
          <S.ResetPasswordTitle>
            <Text styleName="headline1" color="RG02" sx={{ marginTop: '24px' }}>
              이메일을 인증하고 가입을 완료해 주세요.
            </Text>
          </S.ResetPasswordTitle>
          <InputLabel label="이름" useMarking={true} sx={{ alignSelf: 'start', marginTop: '40px' }} />
          <InputUserName
            sx={{ marginTop: '8px' }}
            placeholder="국문 또는 영문 이름을 입력해주세요. 예시) 홍길동"
            register={register}
            error={errors.name}
            setError={setError}
            clearErrors={clearErrors}
            getValues={getValues}
            setValue={setValue}
          />

          <InputPassword
            sx={{ marginTop: '8px' }}
            label="비밀번호 설정"
            disableAutoFill={true}
            password={{ message: errors.password }}
            placeholder="영문, 숫자 또는 특수문자 중 2개 이상 포함 8자 이상"
            required={true}
            register={register}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            getValues={getValues}
          />

          <Stack align="start" sx={{ marginTop: '40px' }} spacing={20}>
            <Stack justify="space-between" direction="row">
              <Text
                styleName="subheadline2"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  let isAllCheck = checkbox.a && checkbox.b;
                  setCheckbox({ a: !isAllCheck, b: !isAllCheck });
                }}
              >
                <CircleCheckBox
                  fill={checkbox.a && checkbox.b ? theme.colors.RC02 : theme.colors.RG05}
                  width={16}
                  height={16}
                  style={{ marginRight: '10px' }}
                />
                전체 약관에 동의합니다.
              </Text>

              <ArrowUp
                onClick={() => setIsTermsDetailOpen(!isTermsDetailOpen)}
                style={{
                  cursor: 'pointer',
                  rotate: isTermsDetailOpen ? '180deg' : '',
                }}
              />
            </Stack>
            {isTermsDetailOpen && (
              <Stack justify="space-between" direction="row">
                <Text
                  styleName="subheadline2"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCheckbox({ a: !checkbox.a, b: checkbox.b });
                  }}
                >
                  <CircleCheckBox
                    fill={checkbox.a ? theme.colors.RC02 : theme.colors.RG05}
                    width={16}
                    height={16}
                    style={{ marginRight: '10px' }}
                  />
                  (필수) 서비스 이용 약관
                </Text>
                <Button
                  variant="second"
                  type="button"
                  onClick={() => {
                    window.open('https://policy.roouty.io/tos', '_blank');
                  }}
                >
                  <Text styleName="caption2" color="RG02" sx={{ margin: 'auto 6px auto 6px' }}>
                    약관보기
                  </Text>
                </Button>
              </Stack>
            )}
            {isTermsDetailOpen && (
              <Stack justify="space-between" direction="row">
                <Text
                  styleName="subheadline2"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCheckbox({ a: checkbox.a, b: !checkbox.b });
                  }}
                >
                  <CircleCheckBox
                    fill={checkbox.b ? theme.colors.RC02 : theme.colors.RG05}
                    width={16}
                    height={16}
                    style={{ marginRight: '10px' }}
                  />
                  (필수) 개인정보처리방침
                </Text>
                <Button
                  variant="second"
                  type="button"
                  onClick={() => {
                    window.open('https://policy.roouty.io/policy_privacy ', '_blank');
                  }}
                >
                  <Text styleName="caption2" color="RG02" sx={{ margin: 'auto 6px auto 6px' }}>
                    약관보기
                  </Text>
                </Button>
              </Stack>
            )}
            {termError && (
              <Stack align="start" sx={{ marginTop: '20px' }}>
                <Text styleName="caption3" color="RC04">
                  <ErrorMarking style={{ marginRight: '6px' }} />
                  필수 약관 동의에 체크해 주세요. 미동의시 회원가입 진행이 불가합니다.
                </Text>
              </Stack>
            )}
          </Stack>

          <Stack sx={{ marginTop: '40px', gap: '10px' }}>
            <Button
              styleName="body2"
              color="RG00"
              variant="default"
              type="button"
              height={48}
              fullWidth
              disabled={isDisalbed}
              onClick={() => {
                if (checkbox.a && checkbox.b) {
                  setIsLoading(true);
                  const { password, name } = getValues();
                  _postSignUpManager(
                    { invitationKey: invitationKey!, password, name },
                    setIsLoading,
                    setTarget,
                    setWelcomePageMode
                  );
                } else {
                  setTermError(true);
                }
              }}
            >
              인증 및 가입 완료
            </Button>
          </Stack>
        </Fragment>
      </CS.SignInResetPasswordLayout>
    </AuthLayout>
  );
};
