import { useRef, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import sleep from 'util/sleep';
import { ExcelFunctions } from 'api';

import { string_constants } from 'components/Modal/XlsxLoader/constant';

import type { AxiosError } from 'axios';
import type { CellValueChangedEvent } from 'ag-grid-community';
import type { TQueryStatusModalSetterProps } from 'constants/types';
import type { DragEvent, ChangeEvent, Dispatch, SetStateAction } from 'react';

const FORCE_ERROR_ID: number = -33;
const SUCCESS_PERCENT: number = 100;

function useExcel(params: IUseExcel, registerCallback?: CallbackFunctionType) {
  // temporary
  const [temporaryId, setTemporaryId] = useState<number | null>(null);

  // cor
  // const _controller = useState<boolean>(true); // for owner
  const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false); // child controller

  // status vars
  const [primaryError, setPrimaryError] = useState<boolean>(false);
  const [isProgressing, setIsProgressing] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const [queryStatusProps, setQueryStatusProps] = useState<TQueryStatusModalSetterProps>({
    open: false,
    props: {
      status: 'loading',
    },
  });

  // files
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [completedPercent, setCompletedPercent] = useState<number>(0);
  const [file, setFile] = useState<_ITemporaryExcel | null>(null);

  const [failedExcel, setFailedExcel] = useState<string | null>(null);

  // inner vars
  const _shouldRefetch = useState<boolean>(true);
  const _targetRowIndex = useState<number | null>(null);

  const { scope } = params;
  const { queryCallbackStrings: _c_queryCallbackStrings } = string_constants[scope];

  const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) loadFile(files);
  };
  const onFileDropped = (files: FileList | null, event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (files && files.length > 0) loadFile(files);
  };
  const onTargetClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };
  const loadFile = async (files: FileList) => {
    const formData = new FormData();
    formData.append('excel', files[0]);

    setPrimaryError(false);

    setFailedExcel(null);
    setCompletedPercent(0);
    setUploadedFileName(files[0].name);

    init_mutatation(formData);
  };

  const { init, fetch, set, register } = ExcelFunctions[scope];

  const { mutateAsync: init_mutatation, status: init_status } = useMutation([{ endPoint: _getEndPoint(init) }], init, {
    onSuccess(data, variables, context) {
      for (const [key, value] of Object.entries(data)) {
        if (key.match(/\w*temporary\w*id\b/gim)) setTemporaryId(value);
      }
      _shouldRefetch[1](true);
    },
  });
  const { refetch } = useQuery([{ endPoint: _getEndPoint(fetch) }], {
    queryFn: () => (!temporaryId ? null : fetch(temporaryId)),
    onSuccess(data) {
      if (!data) return;

      if (data.status === 'registered') {
        // register 의 완료 시점
        setIsRegistering(false);
        setQueryStatusProps({
          open: true,
          props: {
            status: 'success',
            callback: () => {
              registerCallback && registerCallback(data);
            },
            string: _c_queryCallbackStrings?.success,
          },
        });
        console.log('excel data is confirmed, it means your data successfully inserted in DB');
        return refetchInitializer();
      }

      if (data.status.includes('Error')) {
        refetchInitializer();
        if (!(data.status === 'validatingError')) {
          setPrimaryError(true);
        }
        if (data.errorMessage === 'No data received') {
          // setCompletedPercent(100);
          setPrimaryError(true);
        }

        if (isRegistering) {
          setIsRegistering(false);
          setQueryStatusProps({
            open: true,
            props: {
              status: 'error',
              string: _c_queryCallbackStrings?.error,
            },
          });
        }
        return console.error(data.errorMessage, data.errorMessage === 'No data received');
      } else if (data.status.includes('ing') || data.status === 'new') {
        setPrimaryError(false);
        setIsProgressing(true);

        console.log(data);
        return console.log('temporary data validate & upload action is progressing');
      } else if (data.status === 'validated') {
        if (!isRegistering) {
          refetchInitializer();
          console.log('temporary data is successfully registered');
        }
      } else {
        // status
      }
    },
    onSettled(data, error) {
      if (data) {
        // pass percent calc logics, in file reading case
        if (data.status === 'reading' || data.status === 'new') return setCompletedPercent(1);

        let currentPercent =
          (data.rows.filter(
            d => d.rowStatus === 'validated' || d.rowStatus === 'registered' || d.rowStatus.includes('Error')
          ).length /
            data.rows.length) *
          100;

        if (completedPercent + currentPercent >= SUCCESS_PERCENT) {
          setCompletedPercent(SUCCESS_PERCENT);
        }

        if (currentPercent >= SUCCESS_PERCENT && !previewIsOpen) {
          setCompletedPercent(parseFloat(`99.${Math.floor(Math.random() * 98) + 1}`));

          sleep(1000).then(() => {
            setCompletedPercent(SUCCESS_PERCENT);
            setPreviewIsOpen(true);
          });
        } else
          setCompletedPercent(
            isNaN(currentPercent) || currentPercent < completedPercent
              ? parseFloat((completedPercent + Math.random() * 0.8117).toFixed(2))
              : parseFloat(currentPercent.toFixed(2))
          );

        if (scope === 'roouty_manual' && data.status === 'validated') {
          sleep(1000).then(() => {
            setCompletedPercent(SUCCESS_PERCENT);
            setPreviewIsOpen(true);
          });
        }
      }

      setFile(data ?? null);
    },
    refetchInterval: 1500,
    enabled: !!temporaryId && _shouldRefetch[0],
  });
  const { mutateAsync: set_mutatation, status: set_status } = useMutation([{ endPoint: _getEndPoint(set) }], set, {
    onSuccess() {
      refetch();
    },
  });
  const { mutateAsync: register_mutatation, status: register_status } = useMutation(
    [{ endPoint: _getEndPoint(register) }],
    register,
    {
      onMutate() {
        setIsRegistering(true);
      },
      onSuccess(data) {
        if (scope === 'roouty_manual' || (data as any)?.status === 'registered') {
          refetchInitializer();
          setIsRegistering(false);
          setQueryStatusProps({
            open: true,
            props: {
              status: 'success',
              callback: () => {
                registerCallback && registerCallback(data);
              },
              string: _c_queryCallbackStrings?.success,
            },
          });
        } else {
          _shouldRefetch[1](true);
          console.log('is requested', data);
        }
      },
      onError(_error: AxiosError<{ error: IErrorResponse }>) {
        refetchInitializer();
        setIsRegistering(false);
        setQueryStatusProps({
          open: true,
          props: {
            status: 'error',
            string: _c_queryCallbackStrings?.error,
          },
        });

        const error = _error.response?.data.error;
        if (!error) return;

        const { statusCode, advice } = error;
        if (statusCode === 400 && typeof advice === 'string') {
          setFailedExcel(`${advice}`.startsWith('https://') ? advice : null);
        }
      },
    }
  );

  // ag
  function onCellValueChanged(target: CellValueChangedEvent) {
    if (temporaryId === null || typeof target.rowIndex !== 'number') return;
    const conditions = [
      'product_name',
      'product_code',
      'product_quantity',

      // 'customer_field_1',
      // 'customer_field_2',
      // 'customer_field_3',
      // 'customer_field_4',
      // 'customer_field_5',
    ];
    _targetRowIndex[1](target.data.rowIndex - 1);

    let res = target.data;
    Object.keys(res).forEach(d => {
      if (conditions.includes(d)) delete res[d];
    });

    const editParams: Parameters<typeof set>[0] = {
      _id: temporaryId,
      _rowIndex: target.data.rowIndex - 1,
      data: {
        ...res,
        [`${target.colDef.field}`]: target.value,
      },
    };
    set_mutatation(editParams);
  }

  // utility
  function queryStatusModalCloser() {
    setQueryStatusProps(p => {
      return {
        open: false,
        props: { ...p.props },
      };
    });
  }
  function refetchInitializer() {
    _shouldRefetch[1](false);
    setIsProgressing(false);
  }
  function _getEndPoint(fn: Function): string {
    type _RT = Omit<Parameters<typeof set>[0], 'data'>;
    const replacements: { [key in keyof _RT]: string } = {
      _id: `${temporaryId}`,
      _rowIndex: `${_targetRowIndex[0]}`,
    };

    const s = fn
      .toString()
      .replace(/\s/gim, '')
      .match(/(?<=url:)(.*?)(?=(,|\}\)))/gim);

    return s
      ? s[0].replace(/['|"|`]/gim, '').replace(/\${(_id|_rowIndex)}/g, (match, p1: keyof _RT) => replacements[p1])
      : '';
  }
  // conditions
  const isError = useMemo<boolean>(() => {
    return primaryError || init_status === 'error';
  }, [init_status, primaryError]);

  const isLoading = useMemo<boolean>(() => {
    // file uploading
    return (
      init_status === 'loading' ||
      (!isError &&
        init_status === 'success' &&
        ((scope === 'roouty_manual' && register_status === 'loading') ||
          isProgressing ||
          completedPercent < SUCCESS_PERCENT))
    );
  }, [init_status, isError, scope, register_status, isProgressing, completedPercent]);
  const editRowIsLoading = useMemo<boolean>(() => set_status === 'loading', [set_status]);

  return {
    //test
    // _controller,

    // valid exports
    file,
    onCellValueChanged,
    register: () => register_mutatation(temporaryId ?? FORCE_ERROR_ID),

    previewIsOpen,
    setPreviewIsOpen,

    failedExcel: [failedExcel, setFailedExcel] as [typeof failedExcel, Dispatch<SetStateAction<typeof failedExcel>>],

    condition: {
      isError,
      isLoading,
      isRegistering,
      editRowIsLoading,
      progressData: completedPercent,
    },
    file_container: {
      name: uploadedFileName,
      input: {
        ref: fileInputRef,
        onChange: onFileInputChange,
      },
      drop: {
        onDrop: onFileDropped,
        onTargetClick: onTargetClick,
      },
    },
    queryStatusModalProps: {
      isOpen: queryStatusProps.open,
      setIsOpen: queryStatusModalCloser,
      ...queryStatusProps.props,
    },
  };
}

export default useExcel;

interface IUseExcel {
  scope: ScopeType;
}
