import Alt from 'components/Alt';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcInfo } from 'constants/icon/ic_info.svg';
import theme from 'constants/theme';
import { TColor, TStyleName } from 'constants/types';
import { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

type TAltAnchor = 'top' | 'bottom';

export const ReportFilterContent = ({
  title,
  altInfo,
  children,

  styleName = 'caption2',
  color = 'RG02',
  gap = 4,
  anchor = 'top',
  direction,
}: {
  title?: string;
  altInfo?: string;
  children: ReactNode;

  styleName?: TStyleName;
  color?: TColor;

  gap?: number;
  anchor?: TAltAnchor;
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
}) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  const [anchorPoint, setAnchorPoint] = useState<DOMRect>();
  const [alt, setAlt] = useState<{ visible: boolean; content: string }>({ visible: false, content: '' });

  const setted_anchor_point: { [key in TAltAnchor]: any } = {
    top: 110,
    bottom: 54,
  };

  return (
    <FilterContentBox
      align={direction?.includes('row') ? 'center' : 'start'}
      spacing={gap}
      direction={direction ?? 'column'}
    >
      {title && altInfo && (
        <Stack name="filter-content-header" spacing={6} direction="row" sx={{ width: 'max-content' }}>
          <Text styleName={styleName} color={color} ref={titleRef} broken={false} sx={{ whiteSpace: 'nowrap' }}>
            {title}
          </Text>
          <IcInfo
            width={14}
            height={14}
            fill={theme.colors.RG05}
            onMouseOver={e => {
              setAnchorPoint(e.currentTarget.getBoundingClientRect());
              setAlt({ content: altInfo, visible: true });
            }}
            onMouseOut={() => {
              setAlt({ content: '', visible: false });
            }}
          />
        </Stack>
      )}
      {children}

      {alt.visible && (
        <Alt
          style={{
            top: anchorPoint ? anchorPoint.top - setted_anchor_point[anchor] : 0,
            left: anchorPoint ? anchorPoint.left - ((titleRef.current?.getBoundingClientRect().width ?? 0) + 6) : 0,
          }}
        >
          {alt.content}
        </Alt>
      )}
    </FilterContentBox>
  );
};

const FilterContentBox = styled(Stack)`
  width: fit-content;
  /* width: ${({ direction }) => (direction?.includes('row') ? '100%' : 'fit-content')}; */
`;
