import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChangePaymentMethod } from 'components/ModifiableInfoForm/modifiable/ChangePaymentMethod';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { PaymentMethodEmpty } from './empty';
import { InitialForm } from '../../ModifiableInfoForm/InitialForm';
import { useGetPaymentMethod } from '../../../hooks/query/payment/useGetPaymentMethod';
import PaymentAuthModal from '../../../pages/MyPage/UsageAndPlan/Plan/PaymentAuth/PaymentAuthModal';
import { usePatchPaymentManager } from '../../../hooks/query/payment/usePatchPaymentManager';
import { useSetChangePayMethod } from '../../../hooks/query/payment/useSetChangePayMethod';
import { useGetPaymentInfo } from '../../../hooks/query/payment/useGetPaymentInfo';

const Section = styled.section`
  background-color: #ffffff;
  border-radius: inherit;
`;

dayjs.locale('ko');

export const PaymentMethod = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: paymentMethodData } = useGetPaymentMethod();
  const { data: paymentInfoData } = useGetPaymentInfo();
  const { mutate: setPaymentManager, isSuccess: isSuccessSetPaymentManager } = usePatchPaymentManager();
  const { mutate: setChangePayMethod, data: addPayMethodData } = useSetChangePayMethod();

  useEffect(() => {
    if (isSuccessSetPaymentManager) {
      setChangePayMethod({
        successUrl: `/mypage/?modalType=changePayMethodSuccess`,
      });
    }
  }, [isSuccessSetPaymentManager]);

  useEffect(() => {
    if (addPayMethodData) {
      window.localStorage.setItem('orderCode', addPayMethodData?.orderCode);

      window.location.href = addPayMethodData.paymentUrl;
    }
  }, [addPayMethodData]);

  const data = useCallback(
    () => [
      {
        title: '결제 수단',
        content: paymentMethodData?.payMethod ? (
          <ChangePaymentMethod content={`${paymentMethodData?.payType} ${paymentMethodData?.payMethod}`} gap={20} />
        ) : (
          '-'
        ),
      },
      {
        title: '결제 예정일',
        content: paymentMethodData?.paidDate ? dayjs(paymentMethodData?.paidDate).format('YYYY.MM.DD') : '-',
      },
    ],
    [paymentMethodData]
  );

  return (
    <Section>
      {!paymentInfoData?.name ? (
        <>
          <PaymentMethodEmpty
            onClick={() => {
              alert('[사용량 및 요금제 관리]에서 요금제를 결제하시면 결제 정보가 등록됩니다.');

              // setIsOpen(true);
            }}
          />
          <PaymentAuthModal
            authModal={isOpen}
            setAuthModal={setIsOpen}
            callback={data => {
              if (data) {
                setPaymentManager(data);
              }
            }}
          />
        </>
      ) : (
        <InitialForm data={data()} />
      )}
    </Section>
  );
};
