import { useQueryClient } from '@tanstack/react-query';

const useOrder = () => {
  const queryClient = useQueryClient();

  const refetchOrderList = () => queryClient.refetchQueries({ queryKey: ['getOrderList'], type: 'active' });

  return { refetchOrderList };
};

export default useOrder;
