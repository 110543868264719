import { Tooltip } from 'react-tooltip';
import styled, { css } from 'styled-components';
import bgCalendar from '../../constants/background/bg_calendar_number20.svg';
import bgCalendarLighter from '../../constants/background/bg_calendar_number20_lighter.svg';
import bgCalendarToday from '../../constants/background/bg_calendar_today.svg';

export const DateSelectorTooltip = styled(Tooltip)`
  z-index: 30 !important;
  padding: 2px 6px !important;

  border-radius: 2px !important;
  color: ${({ theme }) => theme.colors.RC02} !important;
  border: 1px solid ${({ theme }) => theme.colors.RG06} !important;
  background-color: ${({ theme }) => theme.colors.RG00} !important;

  ${({ theme }) => theme.fontStyle.caption2}
`;

export const DateSelectorStyle = styled.div<{ customWidth?: string; dayCheck?: boolean }>`
  .react-calendar {
    background: white;
    width: ${({ customWidth }) => customWidth || '200px'};

    -webkit-user-select: none;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar__navigation {
    ${({ theme }) => theme.fontStyle.subheadline2};
    display: flex;
    height: 22px;
    /* margin-top: 10px; */
  }
  .react-calendar__navigation__label__divider {
    visibility: hidden;
  }
  .react-calendar__navigation__label {
    display: flex;
    justify-content: space-around;
  }
  .react-calendar__navigation__label__labelText--from,
  .react-calendar__navigation__label__labelText--to {
    color: ${({ theme }) => theme.colors.RG03};
  }
  .react-calendar__navigation button {
    width: 30px;
    padding: 0 5px;
    background: none;
  }

  .react-calendar__month-view__weekdays__weekday,
  .react-calendar__month-view__days__day {
    ${({ theme }) => theme.fontStyle.caption2};
    color: ${({ theme }) => theme.colors.RG02};
    height: 20px;
    background: white;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__month-view__weekdays__weekday {
    margin-top: 20px;
    margin-bottom: 10px;

    ${({ theme }) => theme.fontStyle.caption2};
    color: ${({ theme }) => theme.colors.RG03};
  }

  .react-calendar__month-view__days__day > abbr {
    /* padding-top: 1px; */
    display: block;

    z-index: 2;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    pointer-events: none !important;
    /* color: ${({ theme }) => theme.colors.RG04} !important; */

    & > * {
      display: none !important;
    }
  }

  .react-calendar__month-view__days__day {
    ${({ dayCheck }) => ({
      marginBottom: dayCheck ? '0px' : '10px',
    })}

    cursor: pointer;
    :disabled {
      pointer-events: none;
    }
  }

  .react-calendar__month-view__weekdays__weekday:nth-child(1),
  .react-calendar__month-view__days__day:nth-child(7n - 6) {
    color: ${({ theme }) => theme.colors.RC04};
  }

  .react-calendar__tile {
    position: relative;
  }

  /* .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  } */
  .react-calendar__tile--range {
    & > .range-man-both {
      width: 20px !important;
      border-radius: 20px !important;
    }
    & > .range-man-start {
      margin-left: 8px !important;
      border-radius: 20px 0 0 20px !important;
    }

    & > .range-man-end {
      margin-right: 8px !important;
      border-radius: 0 20px 20px 0 !important;
    }
  }

  .react-calendar__tile--active:enabled {
    & > .range-man {
      background: ${({ theme }) => theme.colors.RC03_1};
    }
    color: ${({ theme }) => theme.colors.RC02};
  }

  .react-calendar__navigation__arrow :hover {
    cursor: pointer;

    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.RG07};

    transition: background 0.2s ease-in-out;
  }

  .react-calendar__viewContainer {
    display: grid;
    grid-auto-flow: column dense;
    column-gap: 30px;
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    color: ${({ theme }) => theme.colors.RG00} !important;
  }

  .react-calendar__tile--rangeStart > .background-man,
  .react-calendar__tile--rangeEnd > .background-man {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    position: relative;
  }

  .react-calendar__tile--rangeStart > .range-man {
    background: linear-gradient(90deg, transparent 50%, ${({ theme }) => theme.colors.RC03_1} 50%) !important;
  }
  .react-calendar__tile--rangeEnd > .range-man {
    background: linear-gradient(90deg, ${({ theme }) => theme.colors.RC03_1} 50%, transparent 50%) !important;
  }
  .react-calendar__tile--rangeStart > .background-man {
    background-image: url(${bgCalendar}) !important;
  }
  .react-calendar__tile--rangeEnd > .background-man {
    background-image: url(${bgCalendar}) !important;
  }

  .react-calendar__tile--rangeBothEnds > .range-man {
    width: 20px !important;
    border-radius: 20px !important;
  }

  .react-calendar__tile--rangeBothEnds > .background-man {
    background-image: url(${bgCalendar}) !important;
  }

  .react-calendar__tile:hover {
    color: ${({ theme }) => theme.colors.RC02};
  }

  .react-calendar__tile:hover > .background-man {
    background-image: url(${bgCalendarLighter});
    background-position: center;
    background-repeat: no-repeat;
  }

  .react-calendar__tile--now > .background-man {
    background-image: url(${bgCalendarToday});
    background-position: center;
    background-repeat: no-repeat;
  }

  .react-calendar__month-view__days__day:disabled {
    pointer-events: all;
    background: none !important;
    color: ${({ theme }) => theme.colors.RG06} !important;
  }

  .react-calendar__tile > .background-man {
    width: 20px;
    height: 20px;
    position: absolute;
  }

  .react-calendar__tile > .range-man {
    width: 100%;
    height: 20px;
    position: absolute;
  }

  ${({ theme, dayCheck }) =>
    dayCheck
      ? {
          '.react-calendar__tile > .dot': {
            height: '4px',
            width: '4px',
            backgroundColor: theme.colors.RC03,
            borderRadius: '50%',
            position: 'absolute',
            bottom: 0,
          },

          '.react-calendar__month-view__days__day': {
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }
      : {}};

  /* .react-calendar--selectRange .react-calendar__tile--hover {
    color: ${({ theme }) => theme.colors.RC02};
    background-color: ${({ theme }) => theme.colors.RC03_1};
  } */

  /* react-calendar__tile
    react-calendar__tile--active
    react-calendar__tile--range
    react-calendar__month-view__days__day */

  /* .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  } 
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  } */
`;
