import { Fragment, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { FileDrop } from 'react-file-drop';

import useExcel from 'hooks/useExcel';

import type { Dispatch, SetStateAction } from 'react';

import theme from 'constants/theme';
import { string_constants } from './constant';

import Divider from '../../Divider';
import SpinLoader from 'util/SpinLoader';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import QueryStatusModal from '../QueryStatusModal';
import { Highlight, Text } from 'components/Typography';
import { ExcelUploadValidation } from 'components/Modal/ExcelUploadValidation';

import './style.css';

import { ReactComponent as IconError } from 'constants/icon/ic_error.svg';
import { ReactComponent as IconArrowdown } from 'constants/icon/ic_arrowdown.svg';
import { ReactComponent as Ic_FileUpload } from 'constants/icon/ic_fileupload.svg';
import { ReactComponent as IconWarning } from 'constants/icon/ic_file_upload_warning.svg';

const TemplateDownloadContentArea = ({ scope }: { scope: ScopeType }) => {
  const template_request_url = `${process.env.REACT_APP_SERVER_URL}/excel/template/download?scope=${scope}`;
  const { templateName: _c_templateName } = string_constants[scope];

  return (
    <PaddingBox spacing={4}>
      <Text styleName="subheadline2" color="RG03">
        루티 엑셀 양식이 없으신가요? 아래 버튼을 눌러 다운 받아주세요.
      </Text>

      <a target="window_opener" rel="noopener noreferrer" href={template_request_url}>
        <Text styleName="subheadline1" color="RC02" sx={{ textDecoration: 'underline' }}>
          {`${_c_templateName} 엑셀 양식 다운로드`}
        </Text>
      </a>
    </PaddingBox>
  );
};

const FileDragArea = ({ container }: { container: any }) => {
  return (
    <Fragment>
      <input
        type="file"
        {...container.input}
        onClick={e => {
          e.currentTarget.value = '';
        }}
        style={{ display: 'none' }}
      />
      <div className="filedrop-container">
        <FileDrop {...container.drop}>
          <Ic_FileUpload />
          <Text styleName="subheadline2" color="RG03">
            여기에 파일을 끌어다주세요
          </Text>
          <div className="filedrop-mypc">
            <Text styleName="caption2" color="RG03">
              내 PC에서 찾아보기
            </Text>
          </div>
        </FileDrop>
      </div>
    </Fragment>
  );
};

const XlsxLoader = forwardRef<
  _IExcelUploaderRef,
  {
    scope?: ScopeType;
    registerCallbackFunction?: CallbackFunctionType;
    setXlsxFormIsOpen: Dispatch<SetStateAction<boolean>>;
  }
>(({ scope = 'roouty', registerCallbackFunction, setXlsxFormIsOpen }, excelRef) => {
  const {
    type: _c_type,
    idleTitle: _c_idleTitle,
    uploadErrorDescriptions: _c_uploadErrorDescriptions,
  } = string_constants[scope];

  const {
    onCellValueChanged,

    previewIsOpen,
    setPreviewIsOpen,

    file,
    file_container,
    condition,
    failedExcel,

    register,
    queryStatusModalProps,
  } = useExcel({ scope }, (data: any) => {
    registerCallbackFunction && registerCallbackFunction(data);
    setXlsxFormIsOpen(false);
  });

  useImperativeHandle(
    excelRef,
    () => {
      return {
        isFailedExcel: !!failedExcel[0],
      };
    },
    [failedExcel]
  );

  return (
    <Fragment>
      <iframe title="window_opener" name="window_opener" style={{ width: 0, height: 0, display: 'none' }} />

      {file && (file.status === 'validated' || file.status === 'validatingError') && !queryStatusModalProps.isOpen && (
        <ExcelUploadValidation
          data={file}
          onCellValueChanged={onCellValueChanged}
          scope={scope}
          register={register}
          isLoading={condition.editRowIsLoading}
          // Preview Modal Condition Controll
          isOpen={previewIsOpen}
          setIsOpen={setPreviewIsOpen}
          setXlsxFormIsOpen={setXlsxFormIsOpen} // owner closer
        />
      )}

      <Stack direction={'row'} spacing={24} divider={<Divider vertical={true} style={{ height: '278px' }} />}>
        {queryStatusModalProps.isOpen ? null : condition.isRegistering ? (
          <Stack>
            <SpinLoader sx={{ width: '44px', height: '44px' }} />
            <Fragment>
              <Stack spacing={8} sx={{ padding: '30px 0 40px' }}>
                <Text styleName="title2" color="RG02">
                  {_c_type} 목록을 업로드 중입니다.
                </Text>
                <Text styleName="body2" color="RG02">
                  잠시만 기다려주세요.
                </Text>
                <Text styleName="body2" color="RG02">
                  페이지를 벗어나면 등록이 취소됩니다.
                </Text>
              </Stack>
              <UploadSummaryBox justify="center" direction="row" spacing={16}>
                <Stack
                  spacing={8}
                  direction="row"
                  sx={{ width: 'max-content' }}
                  divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                >
                  <Text styleName="subheadline2" color="RC02">
                    업로드 완료
                  </Text>
                  <Text styleName="subheadline2" color="RC02">
                    <Highlight styleName="subheadline1">
                      {file?.rows.filter(d => d.rowStatus === 'registered').length ?? 0}
                    </Highlight>
                    &nbsp;건
                  </Text>
                </Stack>
                <Stack
                  spacing={8}
                  direction="row"
                  sx={{ width: 'max-content' }}
                  divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
                >
                  <Text styleName="subheadline2" color="RG02">
                    전체
                  </Text>
                  <Text styleName="subheadline2" color="RG02">
                    <Highlight styleName="subheadline1">{file?.rows.length ?? 0}</Highlight>
                    &nbsp;건
                  </Text>
                </Stack>
              </UploadSummaryBox>
            </Fragment>
          </Stack>
        ) : (
          <Stack>
            {condition.isLoading && !failedExcel[0] ? (
              <Fragment>
                <Text styleName="title2" color="RG02">
                  {_c_type} 목록을 업로드 중입니다.
                  <br />
                  잠시만 기다려주세요.
                </Text>
                <Stack
                  name="progress-area"
                  direction="row"
                  spacing={20}
                  justify="space-evenly"
                  sx={{ padding: '30px 0' }}
                >
                  <ProgressBar
                    label={file_container.name}
                    progress={condition.progressData}
                    type={'xlsx'}
                    isError={condition.isError || !!failedExcel[0]}
                  />
                </Stack>

                <TemplateDownloadContentArea scope={scope} />
              </Fragment>
            ) : !!failedExcel[0] ? (
              <Fragment>
                <PaddingBox>
                  <Stack spacing={12}>
                    <IconWarning width={44} height={44} />
                    <Text styleName="title2" color="RG02">
                      파일에 일부 데이터 오류가 있습니다.
                    </Text>
                    <Text styleName="subheadline2" color="RG03">
                      업로드 실패한 엑셀 파일을 다운로드해 수정 후,
                      <br />
                      다시 업로드 해주시길 바랍니다.
                    </Text>
                  </Stack>
                </PaddingBox>
                <Stack spacing={20}>
                  <UploadSummaryBox spacing={20}>
                    <Button
                      type="button"
                      variant="tertiary"
                      h={32}
                      w={180}
                      icon={[IconArrowdown, {}]}
                      children={'업로드 실패 엑셀 다운로드'}
                      onClick={() => {
                        if (typeof failedExcel[0] === 'string') {
                          window.open(failedExcel[0], '', 'noopener,noreferrer')?.blur();
                          failedExcel[1](null);
                        } else {
                          alert('업로드 실패 엑셀 파일을 다운로드 하는 중 문제가 생겼습니다.');
                        }
                      }}
                    ></Button>
                  </UploadSummaryBox>
                  <UploadWarningBox padding="16px">
                    <Stack spacing={16} align="start">
                      <Text styleName="subheadline2" color="RG02">
                        업로드 실패 엑셀 사용방법은 아래와 같습니다.
                      </Text>
                      <Text styleName="subheadline2" color="RG02" align="start">
                        1. 업로드 실패 엑셀파일을 다운로드해 주세요.
                        <br />
                        2. 다운한 파일에서 실패 사유를 확인 후 수정하여 다시 업로드해 주세요.
                      </Text>
                      <Text styleName="subheadline2" color="RC04" align="start">
                        <Highlight styleName="subheadline1">주의사항</Highlight>
                        <br />
                        이미 업로드한 주문까지 같이 업로드할 경우 주문 중복으로
                        <br />
                        오류가 발생할 수 있습니다.
                        <br />
                        반드시 업로드 실패 엑셀파일을 다운로드해 수정해 주시길 바랍니다.
                      </Text>
                    </Stack>
                  </UploadWarningBox>
                </Stack>
              </Fragment>
            ) : condition.isError ? (
              <Fragment>
                <PaddingBox>
                  <Stack spacing={12}>
                    <IconError fill={theme.colors.RC04} width={44} height={44} />
                    <Text styleName="title2" color="RG02">
                      잘못된 파일 형식이거나 오류가 있습니다.
                      <br />
                      아래 주의사항을 읽어보시고 다시 시도해주세요.
                    </Text>
                  </Stack>
                </PaddingBox>

                <Stack spacing={16}>
                  <UploadWarningBox padding="20px 8px">
                    {(_c_uploadErrorDescriptions as string[]).map(desc => (
                      <li>{`• ${desc}`}</li>
                    ))}
                  </UploadWarningBox>

                  <FileDragArea container={file_container} />
                </Stack>
                <TemplateDownloadContentArea scope={scope} />
              </Fragment>
            ) : (
              <Fragment>
                <PaddingBox>
                  <Stack spacing={4}>
                    <Text styleName="title2" color="RG02">
                      {_c_idleTitle}을
                      <br />
                      업로드해주세요.
                    </Text>
                  </Stack>
                </PaddingBox>

                <FileDragArea container={file_container} />
                <TemplateDownloadContentArea scope={scope} />
              </Fragment>
            )}
          </Stack>
        )}
      </Stack>

      <QueryStatusModal autoClose={3000} {...queryStatusModalProps} />
    </Fragment>
  );
});

export default XlsxLoader;

export const UploadSummaryInfo = ({
  failure,
  success,
  string = ['성공', '실패', '전체'],
}: {
  failure: number;
  success: number;
  string?: string[];
}) => {
  const total = failure + success;
  return (
    <Stack spacing={16} justify="center" direction="row">
      <Stack
        name="success-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RC02">
          {string[0]}
        </Text>
        <Text styleName="subheadline2" color="RC02">
          {success} 건
        </Text>
      </Stack>
      <Stack
        name="failure-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RC04">
          {string[1]}
        </Text>
        <Text styleName="subheadline2" color="RC04">
          {failure} 건
        </Text>
      </Stack>
      <Stack
        name="total-order-count"
        spacing={8}
        direction="row"
        sx={{ flexBasis: 'max-content' }}
        divider={<Divider color="RG06" vertical style={{ height: '12px' }} />}
      >
        <Text styleName="subheadline2" color="RG04">
          {string[2]}
        </Text>
        <Text styleName="subheadline2" color="RG04">
          {total} 건
        </Text>
      </Stack>
    </Stack>
  );
};

const PaddingBox = styled(Stack)`
  padding: 30px 0 40px;
`;

const UploadWarningBox = styled.ul<{ padding: string }>`
  padding: ${({ padding }) => padding};

  width: 100%;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RC04_1};

  li {
    ${({ theme }) => theme.fontStyle.subheadline2}
    text-align: start;
    color: ${({ theme }) => theme.colors.RG02};
  }
`;
const UploadSummaryBox = styled(Stack)`
  padding: 20px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.RG08};
`;
