import { Dispatch, SetStateAction, useEffect, Fragment } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IGetOrderListResponses } from 'constants/types';
import { deleteUnassignedOrder, getOrderList } from 'api';

import { useStore } from 'store';
import theme from 'constants/theme';

import Modal from '.';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';

import { ReactComponent as IconSuccess } from 'constants/icon/ic_success.svg';
import strings from 'util/Localization';
import useOrder from 'hooks/useOrder';

interface CancelOrderModalProps {
  deleteOrderModalOpen: boolean;
  setDeleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;

  delTarget: Array<number>;
  setDelTarget: Dispatch<SetStateAction<Array<number>>>;

  noCancelTrigger: boolean;
}

const CancelOrderModal = ({
  deleteOrderModalOpen,
  setDeleteOrderModalOpen,
  delTarget,
  setDelTarget,
  noCancelTrigger,
}: CancelOrderModalProps) => {
  const { getOrderListProps } = useStore();

  const { refetchOrderList } = useOrder();
  const {
    mutate: mutateDeleteUnassignedOrder,
    isLoading: deleteUnassignedOrderIsLoading,
    isSuccess: deleteUnassignedOrderIsSuccess,
    isError: deleteUnassignedOrderIsError,
    reset,
  } = useMutation(deleteUnassignedOrder, {
    onSuccess: () => {
      setDelTarget([]);
      refetchOrderList();
    },
  });

  useEffect(() => {
    reset();
  }, [noCancelTrigger, reset]);

  useEffect(() => {
    reset();
  }, [deleteOrderModalOpen, reset]);

  return (
    <Modal
      // title="주문 상태 변경"
      isModalOpen={deleteOrderModalOpen}
      setIsModalOpen={setDeleteOrderModalOpen}
      padding={24}
      isLoadingModal={deleteUnassignedOrderIsLoading}
      width={503}
      // width={deleteUnassignedOrderIsLoading || deleteUnassignedOrderIsSuccess ? 424 : 976}
      ms={30}
    >
      {!deleteUnassignedOrderIsLoading &&
        (deleteUnassignedOrderIsSuccess ? (
          <Fragment>
            <IconSuccess fill={theme.colors.RC02} style={{ width: '44px', height: '44px' }} />
            <Text styleName="subheadline3" color="RG04">
              {strings.주문상태}가 '취소'로 변경되었습니다.
            </Text>
            <Button
              variant={'default'}
              type={'reset'}
              styleName="body2"
              color="RG00"
              fullWidth
              sx={{ padding: '6px 0' }}
              onClick={() => {
                setDeleteOrderModalOpen(false);
              }}
            >
              닫기
            </Button>
          </Fragment>
        ) : deleteUnassignedOrderIsError || noCancelTrigger ? (
          <Stack name="delete-vehicle-modal" spacing={33}>
            <Stack>
              <Text styleName="subheadline2" color="RC04">
                ‘{strings.미배차}’ 또는 ‘보류’ 상태인 {strings.모달_주문}만 ‘취소’로 변경할 수 있습니다.
              </Text>
              <Text styleName="subheadline3" color="RG03">
                다른 상태의 {strings.모달_주문}을 제외하고 다시 시도해주세요.
              </Text>
            </Stack>
            <Button
              variant={'second'}
              type={'button'}
              fullWidth
              sx={{ padding: '8px 10px' }}
              onClick={() => {
                setDeleteOrderModalOpen(false);
              }}
            >
              <Text styleName="body2" color="RG04">
                닫기
              </Text>
            </Button>
          </Stack>
        ) : (
          <Stack name="delete-vehicle-modal" spacing={33}>
            <Stack spacing={8}>
              <Text styleName="subheadline2" color="RC04">
                {`${strings.선택된주문} ${delTarget.length ? delTarget.length : 1}건의 ${strings.주문상태}를`}
                <br />
                ‘취소’로 변경하시겠습니까?
              </Text>
              <Text styleName="subheadline3" color="RG02">
                취소 상태가 되면 {strings.대상주문}은 더이상 다른 상태로 변경할 수 없습니다.
              </Text>
            </Stack>
            <Stack direction="row" spacing={16}>
              <Button
                variant={'second'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  setDeleteOrderModalOpen(false);
                }}
              >
                <Text styleName="body2" color="RG04">
                  취소
                </Text>
              </Button>
              <Button
                variant={'default'}
                type={'button'}
                fullWidth
                sx={{ padding: '8px 10px' }}
                onClick={() => {
                  mutateDeleteUnassignedOrder({ orderList: delTarget });
                }}
              >
                <Text styleName="body2" color="RG00">
                  변경하기
                </Text>
              </Button>
            </Stack>
          </Stack>
        ))}
    </Modal>
  );
};

export default CancelOrderModal;
