import { ITimelineBlock } from 'constants/types';
import { memo, Dispatch, SetStateAction } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { areEqual } from 'react-window';
import Item from './Item';
import { TMod } from 'pages/RoutePlanConfirm';

export const Row = memo(function Row(props: any) {
  const { data: items, index, style } = props;
  const item: ITimelineBlock = items.data[index];
  const fnSaveToClipboard: (orderId: number) => void = items.fnSaveToClipboard;
  const fnShowOrderDetail: (orderId: number) => void = items.fnShowOrderDetail;
  const fnLoadFromClipboard: (draggableId: string) => void = items.fnLoadFromClipboard;
  const fnOnCellClicked: (orderId: number) => void = items.fnOnCellClicked;
  const clipboard: ITimelineBlock | undefined = items.clipboard;
  const selectedCell: number[] = items.selectedCell;
  const mod: TMod | undefined = items.mod;
  const startEndChipSelected: string = items.startEndChipSelected;
  const setStartEndChipSelected: Dispatch<SetStateAction<string>> = items.setStartEndChipSelected;
  if (!item) {
    return null;
  }

  return (
    <Draggable
      draggableId={item.id}
      index={index}
      key={item.id}
      isDragDisabled={clipboard !== undefined || item.preventDragging}
    >
      {provided => (
        <Item
          provided={provided}
          item={item}
          style={style}
          fnSaveToClipboard={fnSaveToClipboard}
          fnShowOrderDetail={fnShowOrderDetail}
          fnLoadFromClipboard={fnLoadFromClipboard}
          fnOnCellClicked={fnOnCellClicked}
          selectedCell={selectedCell}
          clipboard={clipboard}
          mod={mod}
          startEndChipSelected={startEndChipSelected}
          setStartEndChipSelected={setStartEndChipSelected}
        />
      )}
    </Draggable>
  );
}, areEqual);
