import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FetchPaymentInfoData, useGetPaymentInfo } from '../../../hooks/query/payment/useGetPaymentInfo';
import { ChangePhoneNumber } from '../../ModifiableInfoForm/modifiable/ChangePhoneNumber';
import { ModifiableInfoForm } from '../../ModifiableInfoForm';
import styled from 'styled-components';
import { ChangeManagerName } from 'components/ModifiableInfoForm/modifiable/ChangeManagerName';
import { ChangeManagerEmail } from 'components/ModifiableInfoForm/modifiable/ChangeManagerEmail';

export interface PaymentInfoProps {
  isModified: boolean;
  setPaymentInfoData: Dispatch<SetStateAction<Partial<FetchPaymentInfoData>>>;
  setIsDisabledSaveButton: Dispatch<SetStateAction<boolean>>;
}

const Section = styled.section`
  background-color: #ffffff;
  border-radius: inherit;
`;

export const PaymentInfo = ({ isModified, setPaymentInfoData, setIsDisabledSaveButton }: PaymentInfoProps) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const { data: paymentInfoData } = useGetPaymentInfo();

  useEffect(() => {
    if (isModified && paymentInfoData) {
      setName(paymentInfoData.name);
      setPhone(paymentInfoData.phone?.replace(/[^0-9]/g, '')?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
      setEmail(paymentInfoData.email);
    }
  }, [paymentInfoData, isModified]);

  const data = useCallback(
    () => [
      {
        title: '결제 담당자 이름',
        content: paymentInfoData?.name,
        modifiedComponent: (
          <ChangeManagerName
            name={name}
            setName={setName}
            setPaymentInfoData={setPaymentInfoData}
            setIsDisabledSaveButton={setIsDisabledSaveButton}
          />
        ),
      },
      {
        title: '결제 담당자 휴대폰 번호',
        content: paymentInfoData?.phone?.replace(/[^0-9]/g, '')?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
        modifiedComponent: (
          <ChangePhoneNumber
            phone={phone}
            setPhone={setPhone}
            setPaymentInfoData={setPaymentInfoData}
            setIsDisabledSaveButton={setIsDisabledSaveButton}
          />
        ),
      },
      {
        title: '결제 담당자 이메일',
        content: paymentInfoData?.email,
        modifiedComponent: (
          <ChangeManagerEmail
            email={email}
            setEmail={setEmail}
            setPaymentInfoData={setPaymentInfoData}
            setIsDisabledSaveButton={setIsDisabledSaveButton}
          />
        ),
      },
    ],
    [paymentInfoData, setPaymentInfoData, email, name, phone]
  );

  return (
    <Section>
      <ModifiableInfoForm isModified={isModified} data={data()} />
    </Section>
  );
};
