import React, { forwardRef, ReactElement, ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

interface ITagProps extends ComponentPropsWithRef<'div'> {
  h?: number | string;
  color?: TThemeColor;
  borderColor?: TThemeColor;
}

const Tag = forwardRef<any, ITagProps>((props, ref): ReactElement => {
  return (
    <TagComponent ref={ref} {...props}>
      {props.children}
    </TagComponent>
  );
});

export default Tag;

const TagComponent = styled.div<{ color?: TThemeColor; borderColor?: TThemeColor; h?: number | string }>`
  display: flex;
  align-items: center;

  padding: 2px 10px;
  border-radius: 4px;

  ${({ theme, color, borderColor, h }) => ({
    color: theme.colors[color ?? 'RC02'],
    border: `1px solid ${theme.colors[borderColor ? borderColor : color ?? 'RC02']}`,
    backgroundColor: theme.colors.RG00,

    height: h ?? 'auto',
  })}

  ${({ theme }) => theme.fontStyle.caption1};

  pointer-events: none;
`;
