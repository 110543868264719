import { Dispatch, SetStateAction, useEffect, useRef, useState, ReactNode } from 'react';

import dayjs from 'dayjs';
import WeekDay from 'dayjs/plugin/weekday';
import { useForm, UseFormReturn } from 'react-hook-form';

import Divider from 'components/Divider';
import { Stack } from 'components/Stack';
import { Button } from 'components/Button/legacy_index';
import { Text } from 'components/Typography';
import { DateSelector } from 'components/DateSelector';

import { ComplexDoubleviewDateselectorContainer, Summary } from './style';
import { date_set_range_template } from 'constants/commons';
import throttle from 'lodash.throttle';

dayjs.extend(WeekDay);
const dateFormat = 'YYYY.MM.DD';
const selectedTemplateButtonInit = 'defaults';

export type Tdate = Date | null | undefined | [Date | null, Date | null];
export interface ComplexDoubleviewDateselectorProps {
  parent: ReactNode;

  limited?: boolean;

  summary?: boolean;
  template?: boolean;
  initDate?: Tdate;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;

  methods: UseFormReturn<any>;

  setPerformedDate?: Dispatch<SetStateAction<string>>;
  initializer?: Function;

  dayCheck?: boolean;
  inputReadOnly?: boolean;
  setPeriod?: Dispatch<SetStateAction<string>>;
}

const ComplexDoubleviewDateselector = ({
  parent,

  limited,

  summary,
  template,
  initDate,
  isOpen,
  setIsOpen,
  methods,
  setPerformedDate,
  inputReadOnly,
  initializer,
  setPeriod,
  dayCheck,
}: ComplexDoubleviewDateselectorProps) => {
  type Tinner = 'start' | 'end';
  const innerMethods = useForm<{ [key in Tinner]: string | null }>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: { start: null, end: null },
  });

  const [date, setDate] = useState<Tdate>(initDate ?? null);
  const [selectedTemplateButton, setSelectedTemplateButton] = useState<string>(selectedTemplateButtonInit);

  useEffect(() => {
    setDate(initDate ?? null);
  }, [initDate, isOpen]);

  const templates: Array<{ title: string; func: Function }> = [
    {
      title: '오늘',
      func: () => {
        setDate(date_set_range_template.today.toDate());
      },
    },
    {
      title: '어제',
      func: () => {
        setDate(date_set_range_template.yesterday.toDate());
      },
    },
    {
      title: '이번 주',
      func: () => {
        const this_sunday = dayjs().weekday(-7).add(7, 'day').toDate();
        const this_saturday = dayjs(this_sunday).add(6, 'day').toDate();
        setDate([this_sunday, this_saturday]);
      },
    },
    {
      title: '최근 7일',
      // func: () => {
      //   let last_sunday = dayjs().subtract(7, 'day').toDate();
      //   // let last_sunday = dayjs().weekday(-7).toDate();
      //   let last_saturday = dayjs(last_sunday).add(6, 'day').toDate();
      //   setDate([last_sunday, last_saturday]);
      // },
      func: () => {
        const last = dayjs().subtract(7, 'day').toDate();
        setDate([last, date_set_range_template.today.toDate()]);
      },
    },
    {
      title: '최근 30일',
      // func: () => {
      //   // let last_month_start = dayjs()
      //   //   .subtract(1, 'month')
      //   //   .subtract(dayjs().date() - 1, 'day')
      //   //   .toDate();

      //   // let last_month_end = dayjs(last_month_start)
      //   //   .add(dayjs().daysInMonth() - 2, 'day')
      //   //   .toDate();

      //   let last_month_start = dayjs().subtract(30, 'day').toDate();
      //   let last_month_end = dayjs(last_month_start).add(29, 'day').toDate();

      //   setDate([last_month_start, last_month_end]);
      // },
      func: () => {
        const last = dayjs().subtract(30, 'day').toDate();
        setDate([last, date_set_range_template.today.toDate()]);
      },
    },
  ];

  const CDDReset = () => {
    initializer && initializer();

    setDate(
      // initDate ??
      //  [
      //   dayjs()
      //     .subtract(dayjs().daysInMonth() - 2, 'day')
      //     .toDate(),
      //   dayjs().toDate(),
      // ]
      [date_set_range_template.week.toDate(), dayjs().toDate()]
    );
    innerMethods.setValue('start', methods.getValues('startDate'), {
      shouldDirty: true,
      shouldValidate: true,
    });
    innerMethods.setValue('end', methods.getValues('endDate'), {
      shouldDirty: true,
      shouldValidate: true,
    });
    setSelectedTemplateButton(selectedTemplateButtonInit);
  };

  const onSubmit = (formData: unknown) => {
    if (formData && typeof formData === 'object') formData = Object.values(formData);

    setPerformedDate &&
      setPerformedDate(`${innerMethods.getValues('start')}-${innerMethods.getValues('end')}`.replaceAll('.', ''));
    setPeriod && setPeriod('');
    methods.setValue('startDate', innerMethods.getValues('start'));
    methods.setValue('endDate', innerMethods.getValues('end'));
    setIsOpen(false);
  };

  useEffect(() => {
    innerMethods.setValue(
      'start',
      date ? (Array.isArray(date) ? dayjs(date[0]).format(dateFormat) : dayjs(date).format(dateFormat)) : '',
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    innerMethods.setValue(
      'end',
      date ? (Array.isArray(date) ? dayjs(date[1] ?? date[0]).format(dateFormat) : dayjs(date).format(dateFormat)) : '',
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  }, [date, initDate, innerMethods]);

  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);

  const calendarOnChange = throttle((event: any, index: number, key: Tinner) => {
    if (event.target.value.length >= 8) {
      innerMethods.setValue(
        key,
        event.target.value
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{4})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/, `$1.$2.$3`),
        { shouldDirty: true, shouldValidate: true }
      );

      setDate(prev => {
        let daf = prev;

        Array.isArray(daf) &&
          daf.splice(
            index,
            1,
            dayjs(
              event.target.value
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{4})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/, `$1.$2.$3`)
            ).toDate()
          );

        return daf;
      });
    } else if (event.target.value.length === 6) {
      innerMethods.setValue(
        key,
        event.target.value
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{2})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/, '20' + `$1.$2.$3`),
        { shouldDirty: true, shouldValidate: true }
      );

      setDate(prev => {
        let daf = prev;

        Array.isArray(daf) &&
          daf.splice(
            index,
            1,
            dayjs(
              event.target.value
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{2})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/, '20' + `$1.$2.$3`)
            ).toDate()
          );

        return daf;
      });
    }
  }, 1000);

  return (
    <Stack sx={{ position: 'relative' }} ref={containerRef}>
      {parent}

      {isOpen && (
        <ComplexDoubleviewDateselectorContainer {...{ template }} ref={selectorRef}>
          <form onSubmit={innerMethods.handleSubmit(onSubmit)}>
            <Stack name="detailCalendar-body" align="start" spacing={14} padding={20}>
              <Summary
                hasError={Boolean(innerMethods.formState.errors.start || innerMethods.formState.errors.end)}
                style={{
                  display: summary ? 'flex' : 'none',
                }}
              >
                <input
                  readOnly={inputReadOnly}
                  {...innerMethods.register('start', {
                    required: true,
                    minLength: {
                      value: 10,
                      message: '',
                    },
                    maxLength: {
                      value: 10,
                      message: '',
                    },
                    pattern: {
                      value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                      message: '',
                    },
                    onChange(event) {
                      calendarOnChange(event, 0, 'start');
                    },
                  })}
                  autoComplete="off"
                />
                <Text styleName="caption2" color="RG02">
                  -
                </Text>
                <input
                  readOnly={inputReadOnly}
                  {...innerMethods.register('end', {
                    required: true,
                    minLength: {
                      value: 10,
                      message: '',
                    },
                    maxLength: {
                      value: 10,
                      message: '',
                    },
                    pattern: {
                      value: /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/,
                      message: '',
                    },
                    onChange(event) {
                      calendarOnChange(event, 1, 'end');
                    },
                  })}
                  autoComplete="off"
                />
              </Summary>

              <Stack spacing={30} direction="row">
                {template && (
                  <Stack name="template-buttons" spacing={13} sx={{ width: '84px' }}>
                    {templates.map(d => {
                      return (
                        <Button
                          key={d.title}
                          fullWidth
                          variant={selectedTemplateButton === d.title ? 'third' : 'second'}
                          type={'button'}
                          styleName="caption2"
                          sx={{ padding: '7px 10px' }}
                          onClick={() => {
                            d.func();
                            setSelectedTemplateButton(d.title);
                          }}
                        >
                          {d.title}
                        </Button>
                      );
                    })}
                  </Stack>
                )}

                {limited ? (
                  <DateSelector
                    showDoubleView
                    selectRange
                    allowPastDate
                    allowPartialRange
                    returnValue="range"
                    showFixedNumberOfWeeks={false}
                    customWidth="430px"
                    {...{ date, setDate, dayCheck }}
                  />
                ) : (
                  <DateSelector
                    limite={-1}
                    showDoubleView
                    selectRange
                    allowPastDate
                    allowPartialRange
                    returnValue="range"
                    showFixedNumberOfWeeks={false}
                    customWidth="430px"
                    {...{ date, setDate, dayCheck }}
                  />
                )}
              </Stack>
            </Stack>
            <Divider color="RG06" />
            <Stack name="detailCalendar-footer" direction="row" justify="space-between" padding={20}>
              <Button
                variant={'second'}
                type={'button'}
                styleName="caption2"
                color="RG03"
                sx={{ padding: '3px 6px' }}
                onClick={() => {
                  CDDReset();
                }}
              >
                초기화
              </Button>

              <Stack direction="row" spacing={20} sx={{ width: '320px' }}>
                <Button
                  variant="second"
                  type="button"
                  styleName="caption2"
                  color="RG03"
                  height={26}
                  sx={{ flex: 1 }}
                  onClick={() => {
                    CDDReset();
                    setIsOpen(false);
                  }}
                >
                  닫기
                </Button>
                <Button
                  variant="default"
                  type="button"
                  styleName="caption1"
                  color="RG00"
                  height={26}
                  sx={{ flex: 2 }}
                  onClick={innerMethods.handleSubmit(onSubmit)}
                >
                  적용하기
                </Button>
              </Stack>
            </Stack>
          </form>
        </ComplexDoubleviewDateselectorContainer>
      )}
    </Stack>
  );
};

export default ComplexDoubleviewDateselector;
