import cloneDeep from 'lodash.clonedeep';
import { SIGN_UP_INIT } from 'auth/constants';

const signUpInitialState: TSignUpState = {
  signUpMode: 'CORPORATE',
  welcomePageType: '',
  signUpForm: SIGN_UP_INIT,
  industryTypeList: [{ key: '', value: 1 }],
};

const signUpReducerAction = (state: TSignUpState, action: TSignUpAction) => {
  const _state = cloneDeep(state);
  const { type, payload } = action;
  switch (type) {
    case 'SWITCH_SIGN_UP_MODE':
      _state.signUpMode = payload;
      return _state;
    case 'UPDATE_INDUSTRY_LIST':
      _state.industryTypeList = payload;
      return _state;
    case 'UPDATE_SIGN_UP_FORM':
      if (Array.isArray(payload) === false) throw new Error('payload를 배열로 보내주세요.');
      const _payload = payload as TSignUpForm[];

      _payload.forEach(request => {
        const [[key, value]] = Object.entries(request);

        _state.signUpForm[key] = value;
      });
      return _state;
    default:
      return _state;
  }
};

export { signUpInitialState, signUpReducerAction };
