import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { ReactComponent as IcDeliveryFF } from 'constants/icon/ic_delivery_face_fill.svg';
import { ReactComponent as IcDeliveryPF } from 'constants/icon/ic_delivery_path_fill.svg';
import { ReactComponent as IcPickupFF } from 'constants/icon/ic_pickup_face_fill.svg';
import { ReactComponent as IcPickupPF } from 'constants/icon/ic_pickup_path_fill.svg';
import { ReactComponent as IcrotationFF } from 'constants/icon/ic_rotation_face_fill.svg';
import { ReactComponent as IcrotationPF } from 'constants/icon/ic_rotation_path_fill.svg';
import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent, SVGProps } from 'react';
import strings from 'util/Localization';

import { reorderBySelection } from 'util/reorderBySelection';
import { getActiveSessions } from 'util/sessionStorageManager';
import {
  IOrderManagementContentByStatus,
  IRouteListContentByStatus,
  TOperationType,
  TOrderManagementStatus,
  TRouteStatus,
  TShipmentType,
  TTableSelectManagerOptions,
} from './types';

strings.setLanguage(localStorage.getItem('serviceDomain') ?? 'roouty');

const route_origin_session_key = 'route_origin';
const temp_units_allowed: number = 5;
const is_temp_units_exceeded = (): boolean => getActiveSessions() >= temp_units_allowed;

const date_set_range_template: {
  // [key in 'today' | 'week' | 'month1' | 'month3']: [number, ManipulateType];
  [key in 'today' | 'yesterday' | 'week' | 'month1' | 'month3']: Dayjs;
} = {
  // today: [1, 'day'],
  // week: [1, 'week'],
  // month1: [1, 'month'],
  // month3: [3, 'month'],
  today: dayjs(),
  yesterday: dayjs().subtract(1, 'day'),
  week: dayjs().subtract(1, 'week').add(1, 'day'),
  month1: dayjs().subtract(1, 'month').add(1, 'day'),
  month3: dayjs().subtract(3, 'month').add(1, 'day'),
};

const common_table_selection: TTableSelectManagerOptions = [
  { key: 'toggleAllRowsSelected', value: '전체 선택', optionValue: true },
  { key: 'toggleAllRowsSelected', value: '전체 선택 해제', optionValue: false },
];

const operation_type: Array<{
  label: string;
  value: TOperationType;
  status: 'done' | 'moving';
  visibleType: boolean;
}> = [
  {
    label: '고정차',
    value: 'regular',

    status: 'done',
    visibleType: true,
  },
  {
    label: '용차',
    value: 'backup',

    status: 'moving',
    visibleType: true,
  },
];

const shipment_type: Array<{
  label: string;
  value: TShipmentType;
  icon: {
    [key in 'pf' | 'ff']: FunctionComponent<SVGProps<SVGSVGElement>>;
  };
  visibleType: boolean;
}> = [
  {
    label: '배달',
    value: 'delivery',
    icon: {
      pf: IcDeliveryPF,
      ff: IcDeliveryFF,
    },
    visibleType: true,
  },
  {
    label: '수거',
    value: 'pickup',
    icon: {
      pf: IcPickupPF,
      ff: IcPickupFF,
    },
    visibleType: true,
  },

  {
    label: '회차',
    value: 'rotation',
    icon: {
      pf: IcrotationPF,
      ff: IcrotationFF,
    },
    visibleType: false,
  },
];

const priority_type = [
  {
    label: '높음',
    value: 'high',
    visibleType: true,
  },
  {
    label: '보통',
    value: 'medium',
    visibleType: true,
  },
  {
    label: '낮음',
    value: 'low',
    visibleType: true,
  },
];

const statusFilter = [
  {
    name: '전체',
    id: 'all',
  },
  {
    name: strings.미배차,
    id: 'unassigned',
  },
  {
    name: strings.배차완료,
    id: 'scheduled',
  },
  {
    name: strings.배송중,
    id: 'processing',
    disabled: true,
  },
  {
    name: strings.배송완료,
    id: 'completed',
    disabled: true,
  },
  {
    name: '보류',
    id: 'skipped',
    disabled: true,
  },
  {
    name: '취소',
    id: 'canceled',
  },
];

const shipmentFilter = [
  {
    name: '전체',
    id: 'all',
  },
  {
    name: shipment_type[0].label,
    id: shipment_type[0].value,
  },
  {
    name: shipment_type[1].label,
    id: shipment_type[1].value,
  },
];

const route_searchItem_content: { [key in TRouteStatus]: Array<{ [key in 'key' | 'value']: any }> } = {
  processing: [],
  activated: [],
  completed: [],
};

const order_searchItem_content: { [key in TOrderManagementStatus]: Array<{ [key in 'key' | 'value']: any }> } = {
  all: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },
    {
      key: '드라이버',
      value: 'driverName',
    },
    {
      key: '주행 이름',
      value: 'routeName',
    },

    {
      key: '경로 ID',
      value: 'routeCode',
    },
    {
      key: '고객명',
      value: 'consigneeName',
    },
    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  unassigned: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },

    {
      key: '고객명',
      value: 'consigneeName',
    },

    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  scheduled: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },
    {
      key: '드라이버',
      value: 'driverName',
    },
    {
      key: '주행 이름',
      value: 'routeName',
    },

    {
      key: '경로 ID',
      value: 'routeCode',
    },
    {
      key: '고객명',
      value: 'consigneeName',
    },
    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  completed: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },
    {
      key: '드라이버',
      value: 'driverName',
    },
    {
      key: '주행 이름',
      value: 'routeName',
    },

    {
      key: '경로 ID',
      value: 'routeCode',
    },
    {
      key: '고객명',
      value: 'consigneeName',
    },
    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  skipped: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },
    {
      key: '드라이버',
      value: 'driverName',
    },
    {
      key: '고객명',
      value: 'consigneeName',
    },
    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  deleted: [
    {
      key: '주문 ID',
      value: 'orderCode',
    },

    {
      key: '담당 드라이버 지정',
      value: 'serviceVehicle',
    },
    {
      key: '아이템명',
      value: 'productName',
    },

    {
      key: '고객명',
      value: 'consigneeName',
    },
    {
      key: '주소',
      value: 'address',
    },
    {
      key: '특수 조건',
      value: 'skill',
    },
  ],
  priority: [
    {
      key: '배차 우선순위',
      value: 'priority',
    },
  ],
};

const route_list_table_column_content: TDragablePickerOptions = [
  { key: 'progress', name: '진행률', isSelected: false, default: true },
  { key: 'createdAt', name: '배차 일자', isSelected: false, default: true },
  { key: 'performedDate', name: '주행 일자', isSelected: false, default: true },
  { key: 'routeId', name: '경로ID', isSelected: false, default: true },
  { key: 'name', name: '주행 이름', isSelected: false, default: true },
  { key: 'totalOrderCount', name: '총 주문', isSelected: false, default: true },
  { key: 'totalDriverCount', name: '총 드라이버', isSelected: false, default: true },
  { key: 'createdBy', name: '배차 담당자', isSelected: false, default: true },
];
const route_list_table_column_content_default: { [key in TRouteStatus]: Array<string> } = {
  processing: [
    'progress',
    'createdAt',
    'performedDate',
    'routeId',
    'name',
    'totalOrderCount',
    'totalDriverCount',
    'createdBy',
  ],
  completed: ['createdAt', 'performedDate', 'routeId', 'name', 'totalOrderCount', 'totalDriverCount', 'createdBy'],
  activated: ['createdAt', 'performedDate', 'routeId', 'name', 'totalOrderCount', 'totalDriverCount', 'createdBy'],
};

// 조회항목 설정에서 각 메뉴별로 보여지지 말아야 할 항목값 세팅
export const order_management_table_column_content_hide_options: {
  [key in TOrderManagementStatus]: Array<string>;
} = {
  all: ['controlOrderStatus'],
  unassigned: ['controlOrderStatus', 'podFilesCount'],
  scheduled: ['status', 'podFilesCount'],
  completed: ['controlOrderStatus'],
  skipped: ['controlOrderStatus', 'podFilesCount'],
  deleted: ['controlOrderStatus', 'podFilesCount'],
  priority: [],
};

const order_management_table_column_content: TDragablePickerOptions = [
  { key: 'orderCode', name: strings.주문 + 'ID', isSelected: false },
  { key: 'status', name: strings.주문 + '상태', isSelected: false },
  { key: 'controlOrderStatus', name: '작업 상태', isSelected: false },
  { key: 'receivedDate', name: strings.주문접수일, isSelected: false },
  { key: 'desiredDate', name: strings.작업희망일, isSelected: false },
  { key: 'shipmentType', name: '주문 유형', isSelected: false },
  { key: 'priority', name: '배차 우선순위', isSelected: false },
  { key: 'serviceVehicle', name: `담당 드라이버 지정`, isSelected: false },
  { key: 'desiredTimeEnd', name: '희망 시간(이전)', isSelected: false },
  { key: 'desiredTimeStart', name: '희망 시간(이후)', isSelected: false },
  { key: 'productName', name: strings._상품 + '명', isSelected: false },
  { key: 'productCode', name: strings._상품 + ' 코드', isSelected: false },
  { key: 'productQuantity', name: strings._상품 + ' 수량', isSelected: false },
  { key: 'capacity', name: '예상 용적량', isSelected: false },
  { key: 'skill', name: '특수 조건', isSelected: false },
  { key: 'podCapacity', name: '실제 용적량', isSelected: false },
  { key: 'podComment', name: '용적량 차이 사유', isSelected: false },
  { key: 'serviceTime', name: '예상 작업 소요 시간 (분)', isSelected: false },
  { key: 'actualServiceTime', name: '실제 작업 소요 시간 (분)', isSelected: false },
  { key: 'podFilesCount', name: 'PoD 사진 개수', isSelected: false },
  { key: 'driverName', name: strings.드라이버, isSelected: false },
  { key: 'completedAt', name: strings.완료일, isSelected: false },
  { key: 'completedComment', name: '완료 사유', isSelected: false },
  { key: 'routePerformedDate', name: strings.주행_조사 + ' 일자', isSelected: false },
  { key: 'routeName', name: strings.주행_조사 + ' 이름', isSelected: false },
  { key: 'skippedComment', name: '보류 사유', isSelected: false },
  { key: 'skippedAt', name: '보류 일시', isSelected: false },
  { key: 'skippedName', name: `보류 ${strings.주행_조사} 이름`, isSelected: false },
  { key: 'routeId', name: '경로ID', isSelected: false },
  { key: 'routeStatus', name: '경로 상태', isSelected: false },
  { key: 'consigneeName', name: strings.고객명, isSelected: false },
  { key: 'consigneePhone', name: '고객 연락처', isSelected: false },
  { key: 'address', name: '주소', isSelected: false },
  { key: 'detailAddress', name: '상세 주소', isSelected: false },
  { key: 'consigneeNote', name: '고객전달사항', isSelected: false },
  { key: 'note1', name: '비고1', isSelected: false },
  { key: 'note2', name: '비고2', isSelected: false },
  { key: 'note3', name: '비고3', isSelected: false },
  { key: 'note4', name: '비고4', isSelected: false },
  { key: 'note5', name: '비고5', isSelected: false },
]
  .map(d => {
    if (d.key.includes('pod') && localStorage.getItem('serviceDomain') === 'survey') {
      return { ...d, hidden: true };
    } else return d;
  })
  .map(d =>
    order_management_table_column_content_hide_options.all.includes(d.key as string)
      ? {
          ...d,
          hidden: true,
        }
      : { ...d }
  );

const setting_involved_driver_management_table_column_content: TDragablePickerOptions = [
  { key: 'teamName', name: '소속 팀', isSelected: true },
  { key: 'name', name: '이름', isSelected: true },
  { key: 'status', name: '상태', isSelected: true },
  { key: 'loginAccount', name: '아이디(휴대폰번호)', isSelected: true },
  { key: 'operationType', name: '운영 유형', isSelected: true },
  { key: 'createdAt', name: '활성 일자', isSelected: true },
  { key: 'memo', name: '메모', isSelected: false },
  { key: 'modelName', name: '차종', isSelected: true },
  { key: 'maxCapacity', name: '최대 용적량', isSelected: true },
  { key: 'area', name: '권역', isSelected: true },
  { key: 'skill', name: '특수 조건', isSelected: true },
  { key: 'workStartTime', name: '주행 시작 시간', isSelected: true },
  { key: 'workEndTime', name: '주행 종료 시간', isSelected: true },
  { key: 'breakDuration', name: '휴게시간 (분)', isSelected: true },
  { key: 'breakRange', name: '휴게시간 범위', isSelected: false },
  { key: 'startAddress', name: '출발지 주소', isSelected: true },
  { key: 'startDetailAddress', name: '출발지 상세주소', isSelected: false },
  { key: 'endAddress', name: '도착지 주소', isSelected: true },
  { key: 'endDetailAddress', name: '도착지 상세주소', isSelected: false },
];
// 주석 : 각 메뉴별 조회항목 기본값 세팅
const order_management_table_column_content_default_settings: { [key in TOrderManagementStatus]: Array<string> } = {
  all: [
    'orderCode',
    'status',
    'receivedDate',
    'productName',
    'driverName',
    'completedAt',
    'routeStatus',
    'consigneeName',
    'address',
    'detailAddress',
  ],
  unassigned: ['orderCode', 'receivedDate', 'productName', 'consigneeName', 'address', 'detailAddress'],
  scheduled: [
    'orderCode',
    'controlOrderStatus',
    'receivedDate',
    'productName',
    'driverName',
    'controlOrderStatus',
    'routePerformedDate',
    'routeName',
    'routeStatus',
    'consigneeName',
    'address',
    'detailAddress',
  ],
  completed: [
    'orderCode',
    'receivedDate',
    'productName',
    'capacity',
    'podCapacity',
    'driverName',
    'completedAt',
    'completedComment',
    'routePerformedDate',
    'routeName',
    'consigneeName',
    'address',
    'detailAddress',
  ],
  skipped: [
    'orderCode',
    'receivedDate',
    'productName',
    'skippedComment',
    'skippedAt',
    'consigneeName',
    'address',
    'detailAddress',
  ],
  deleted: ['orderCode', 'receivedDate', 'productName', 'consigneeName', 'address', 'detailAddress'],
  priority: ['high', 'medium', 'low'],
};

const order_management_shipment_type_content = [
  {
    label: '전체',
    value: 'pickup,delivery',
    visibleType: true,
    isSelected: true,
  },
].concat(
  shipment_type
    .filter(d => d.visibleType)
    .map(d => {
      return { ...d, isSelected: false };
    })
);

const order_management_route_status_content = {
  all: [
    { key: '전체', value: 'activated,processing,completed,none', isSelected: true },
    { key: '주행대기', value: 'activated', isSelected: false },
    { key: '주행중', value: 'processing', isSelected: false },
    { key: '주행종료', value: 'completed', isSelected: false },
    { key: '없음', value: 'none', isSelected: false },
  ],
  scheduled: [
    { key: '전체', value: 'activated,processing,completed', isSelected: true },
    { key: '주행대기', value: 'activated', isSelected: false },
    { key: '주행중', value: 'processing', isSelected: false },
  ],
};

const order_management_priority_status = {
  all: [
    { key: '전체', value: 'high,medium,low', isSelected: true },
    { key: '높음', value: 'high', isSelected: false },
    { key: '보통', value: 'medium', isSelected: false },
    { key: '낮음', value: 'low', isSelected: false },
  ],
};

const route_list_content_by_status: IRouteListContentByStatus = {
  processing: {
    routeId: '1234567',
    title: '주행중',
    desc: '주행중인 배차 목록을 조회하고 주행 중인 배차 주문을 실시간 모니터링을 할 수 있습니다.',
    filter: {
      searchItem: route_searchItem_content.processing,
    },
    tableSetting: {
      columns: reorderBySelection(
        route_list_table_column_content.map(d =>
          route_list_table_column_content_default.processing.includes(d.key as string)
            ? { ...d, isSelected: true }
            : { ...d }
        )
      ),
    },
  },
  activated: {
    routeId: '324234324',
    title: '주행대기',
    desc: '주행대기인 배차 목록을 조회하고 배차를 취소할 수 있습니다.',
    filter: {
      searchItem: route_searchItem_content.activated,
    },
    tableSetting: {
      columns: reorderBySelection(
        route_list_table_column_content.map(d =>
          route_list_table_column_content_default.activated.includes(d.key as string)
            ? { ...d, isSelected: true }
            : { ...d }
        )
      ),
    },
  },
  completed: {
    routeId: '23423123',
    title: '주행종료',
    desc: '주행종료인 배차 목록을 조회하고 주행 히스토리를 볼 수 있습니다.',
    filter: {
      searchItem: route_searchItem_content.completed,
    },
    tableSetting: {
      columns: reorderBySelection(
        route_list_table_column_content.map(d =>
          route_list_table_column_content_default.completed.includes(d.key as string)
            ? { ...d, isSelected: true }
            : { ...d }
        )
      ),
    },
  },
};

const order_management_content_by_status = (type: 'auto' | 'manual'): IOrderManagementContentByStatus => {
  return {
    all: {
      title: '전체 주문 조회',
      status: '전체',
      desc: `전체 ${strings.모달_주문}을 조회하고 현황을 확인하는 페이지입니다.`,
      filter: {
        searchItem: order_searchItem_content.all,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
            { key: '작업 완료 일시', value: 'completedDate' },
            { key: '주행 일자', value: 'performedDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,

        orderStatus:
          type === 'auto'
            ? [
                { key: '전체', value: 'all', isSelected: true },
                { key: '미배차', value: 'unassigned', isSelected: false },
                { key: '배차완료', value: 'scheduled', isSelected: false },
                { key: '처리중', value: 'processing,arrived', isSelected: false },
                { key: '처리완료', value: 'completed', isSelected: false },
                { key: '보류', value: 'skipped', isSelected: false },
                { key: '취소', value: 'deleted', isSelected: false },
              ]
            : [
                { key: '전체', value: 'all', isSelected: true },
                { key: '배차완료', value: 'scheduled', isSelected: false },
                { key: '처리중', value: 'processing,arrived', isSelected: false },
                { key: '처리완료', value: 'completed', isSelected: false },
                { key: '보류', value: 'skipped', isSelected: false },
                { key: '취소', value: 'deleted', isSelected: false },
              ],
        routeStatus: order_management_route_status_content.all,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.all.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.all.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
        ),
      },
    },
    unassigned: {
      title: '미배차 주문 관리',
      status: '미배차',
      desc: `${strings.미배차주문}을 관리하고 현황을 확인하는 페이지입니다.`,
      filter: {
        searchItem: order_searchItem_content.unassigned,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.unassigned.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.unassigned.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
        ),
      },
    },
    scheduled: {
      title: '배차완료 주문 관리 ',
      status: '배차완료',
      desc: `${strings.배차완료} 및 처리중 상태의 ${strings.모달_주문}을 관리하고 현황을 확인하는 페이지 입니다.`,
      filter: {
        searchItem: order_searchItem_content.scheduled,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
            { key: '주행 일자', value: 'performedDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,

        orderStatus: [
          { key: '전체', value: 'scheduled,processing,arrived', isSelected: true },
          { key: '작업대기', value: 'scheduled', isSelected: false },
          { key: '이동중', value: 'processing', isSelected: false },
          { key: '작업중', value: 'arrived', isSelected: false },
        ],
        routeStatus: order_management_route_status_content.all,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.scheduled.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.scheduled.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
            .map(d => (d.key === 'controlOrderStatus' ? { ...d, hidden: false } : { ...d }))
        ),
      },
    },
    completed: {
      title: '처리완료 주문 조회',
      status: '처리완료',
      desc: `처리완료 ${strings.모달_주문}을 조회하고 현황을 확인하는 페이지입니다.`,
      filter: {
        searchItem: order_searchItem_content.completed,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
            { key: '작업 완료 일시', value: 'completedDate' },
            { key: '주행 일자', value: 'performedDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.completed.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.completed.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
        ),
      },
    },
    skipped: {
      title: '보류 주문 관리',
      status: '보류',
      desc: `보류 ${strings.모달_주문}을 관리하고 현황을 확인하는 페이지입니다.`,
      filter: {
        searchItem: order_searchItem_content.skipped,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.skipped.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.skipped.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
        ),
      },
    },
    deleted: {
      title: '취소 주문 조회',
      status: '취소',
      desc: `취소 ${strings.모달_주문}을 조회하고 현황을 확인하는 페이지입니다.`,
      filter: {
        searchItem: order_searchItem_content.deleted,
        rangeFilter: {
          categoary: [
            { key: '주문 접수일', value: 'receivedDate' },
            { key: '작업 희망일', value: 'desiredDate' },
          ],
        },
        shipmentType: order_management_shipment_type_content,
        priorityStatus: order_management_priority_status.all,
      },
      tableSetting: {
        columns: reorderBySelection(
          order_management_table_column_content
            .map(d =>
              order_management_table_column_content_default_settings.deleted.includes(d.key as string)
                ? { ...d, isSelected: true }
                : { ...d }
            )
            .map(d =>
              order_management_table_column_content_hide_options.deleted.includes(d.key as string)
                ? { ...d, hidden: true }
                : { ...d }
            )
        ),
      },
    },
    priority: {
      title: '',
      status: '우선순위',
      desc: '',
      filter: {
        searchItem: [],
        rangeFilter: {
          categoary: [],
        },
        shipmentType: [],
        orderStatus: undefined,
        routeStatus: undefined,
        priorityStatus: undefined,
      },
      tableSetting: {
        columns: [],
      },
    },
  };
};

export {
  common_table_selection,
  is_temp_units_exceeded,
  route_origin_session_key,
  statusFilter,
  shipment_type,
  operation_type,
  shipmentFilter,
  date_set_range_template,
  order_management_content_by_status,
  order_management_table_column_content,
  order_management_table_column_content_default_settings,
  route_list_content_by_status,
  priority_type,
  route_list_table_column_content,
  route_list_table_column_content_default,
  setting_involved_driver_management_table_column_content,
};
