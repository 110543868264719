import { MANAGER_AUTH_CODE_QUERIES } from './managerAuthCode.queries';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type FetchVerifyManagerAuthCodeResponse = {
  verified: boolean;
};

type Variables = {
  phone: string;
  code: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchVerifyManagerAuthCode>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof MANAGER_AUTH_CODE_QUERIES.VERIFY_MANAGER_AUTH_CODE>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchVerifyManagerAuthCode = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.post(endPoint, {
    json: variables,
  }).json<FetchVerifyManagerAuthCodeResponse>();

const useVerifyManagerAuthCode = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    MANAGER_AUTH_CODE_QUERIES.VERIFY_MANAGER_AUTH_CODE(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchVerifyManagerAuthCode({
        variables: variables,
        ...MANAGER_AUTH_CODE_QUERIES.VERIFY_MANAGER_AUTH_CODE()[0],
      }),
    options
  );

export { fetchVerifyManagerAuthCode, useVerifyManagerAuthCode };
export type { FetchVerifyManagerAuthCodeResponse };
