import { useQuery } from '@tanstack/react-query';

import { getRouteReport } from 'api';
import { DownloadButton } from 'components/Button/DownloadButton';
import PageContentWrapper from 'components/PageContentWrapper';
import { TDragablePickerOptions } from 'components/Popup/DragablePicker';
import { Stack } from 'components/Stack';
import RouteReportTable from 'components/Table/RouteReportTable';

import { Text } from 'components/Typography';
import {
  dimension_options_default,
  dimension_options_driver,
  info_metrics_options_default,
  metrics_options,
} from 'constants/report';
import { useDownloadReportDriver } from 'hooks/query/report/useDownloadReportDriver';
import { useDownloadReportRoute } from 'hooks/query/report/useDownloadReportRoute';
import { useTracking } from 'hooks/store/useTracking';
import ReportFilter from 'pages/Report/utils/ReportFilter';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IReportQuery, ReportDownloadVariables } from 'types/report/drive';

interface RouteReportProps {
  title: string;
  description: string;
  type: 'route' | 'driver'; // 데이터 없으면 전체 주행 기록
}

const RouteReport = ({ title, description, type }: RouteReportProps) => {
  const [filter, setFilter] = useState<IReportQuery | undefined>();
  const [dimensionSelectorOptions, setDimensionSelectorOptions] = useState<TDragablePickerOptions>(
    getLocalStorage('dimensionSelectorOptions') ||
      (type === 'driver' ? dimension_options_driver : dimension_options_default)
  );
  const [infoMetricsSelectorOptions, setInfoMetricsSelectorOptions] = useState<TDragablePickerOptions>(
    getLocalStorage('infoMetricsSelectorOptions') || info_metrics_options_default
  );
  const [metricsSelectorOptions, setMetricsSelectorOptions] = useState<TDragablePickerOptions>(
    getLocalStorage('metricsSelectorOptions') || metrics_options['route']
  );

  useEffect(() => {
    updateLocalStorage('metricsSelectorOptions', metricsSelectorOptions);
  }, [metricsSelectorOptions]);

  useEffect(() => {
    updateLocalStorage('dimensionSelectorOptions', dimensionSelectorOptions);
  }, [dimensionSelectorOptions]);

  useEffect(() => {
    updateLocalStorage('infoMetricsSelectorOptions', infoMetricsSelectorOptions);
  }, [infoMetricsSelectorOptions]);

  function updateLocalStorage(key: string, data: any) {
    localStorage.setItem(`report_${type}_${key}`, JSON.stringify(data));
  }

  function getLocalStorage(key: string): any | null {
    if (localStorage.getItem(`report_${type}_${key}`)) {
      return JSON.parse(localStorage.getItem(`report_${type}_${key}`) ?? '');
    }
    return null;
  }

  const [rowSelection, setRowSelection] = useState({});

  const { data, isLoading } = useQuery(
    ['getRouteReport', filter],
    () => filter && getRouteReport(type, { ...filter }),
    {
      enabled: filter !== undefined,
    }
  );

  const { setTitle } = useTracking();

  useEffect(() => {
    setRowSelection({});
  }, [filter]);

  useEffect(() => {
    if (getLocalStorage('metricsSelectorOptions')) {
      if (type === 'driver') setMetricsSelectorOptions(getLocalStorage('metricsSelectorOptions'));
      else {
        setMetricsSelectorOptions(
          getLocalStorage('metricsSelectorOptions')!
            .map((item: { key: string }) =>
              item.key === 'routeCount'
                ? {
                    ...item,
                    isSelected: false,
                    hidden: true,
                  }
                : item
            )
            .sort((a: { default: any }, b: any) => (a.default ? -1 : 1))
        );
      }
    } else
      setMetricsSelectorOptions(prev =>
        type === 'driver'
          ? metrics_options['route']
          : prev
              .map(item =>
                item.key === 'routeCount'
                  ? {
                      ...item,
                      isSelected: false,
                      hidden: true,
                    }
                  : item
              )
              .sort((a, b) => (a.default ? -1 : 1))
      );

    setDimensionSelectorOptions(
      getLocalStorage('dimensionSelectorOptions')
        ? getLocalStorage('dimensionSelectorOptions').sort((a: { default: any }, b: any) => (a.default ? -1 : 1))
        : type === 'driver'
        ? dimension_options_driver
        : dimension_options_default
    );
    if (getLocalStorage('infoMetricsSelectorOptions'))
      setInfoMetricsSelectorOptions(getLocalStorage('infoMetricsSelectorOptions'));
  }, [type]);

  useEffect(() => {
    setTitle(`보고서>${title.replace(/ /g, '')}`);
  }, [setTitle, title]);

  return (
    <PageContentWrapper>
      <Stack useHorizontalScroll align="start" sx={{ padding: '48px 20px' }}>
        <Stack direction="row" justify="space-between" align="start">
          <Stack spacing={8} direction="column" align="start">
            <Text styleName="title1" color="RG03">
              {title}
            </Text>
            <Text styleName="caption3" color="RG02" sx={{ marginBottom: 30 }}>
              {description}
            </Text>
          </Stack>
          <DownloadButton<ReportDownloadVariables>
            text="엑셀로"
            isDisabled={!Object.keys(rowSelection).length}
            variables={
              {
                data: data?.routeList
                  .filter((item, index) => {
                    let isSelected = false;

                    Object.keys(rowSelection).forEach(key => {
                      if (parseInt(key) === index) {
                        isSelected = true;
                      }
                    });

                    return isSelected;
                  })
                  .map(item => item.dimension) as ReportDownloadVariables['data'],
                ...filter,
              } as ReportDownloadVariables
            }
            extension="xlsx"
            useDownloadReactQueryHook={type === 'route' ? useDownloadReportRoute : useDownloadReportDriver}
          />
        </Stack>
        <Stack spacing={12} align="start" sx={{ width: '100%', minWidth: '1293px' }}>
          <ReportFilter
            type="route"
            infoMetricsSelectorOptions={type !== 'route' ? infoMetricsSelectorOptions : undefined}
            setInfoMetricsSelectorOptions={type !== 'route' ? setInfoMetricsSelectorOptions : undefined}
            {...{
              filter,
              setFilter,
              dimensionSelectorOptions,
              setDimensionSelectorOptions,
              metricsSelectorOptions,
              setMetricsSelectorOptions,
            }}
          />
          <Splitter>
            <RouteReportTable
              reportData={data}
              isFetching={isLoading}
              dimensionSelectorOptions={dimensionSelectorOptions}
              infoMetricsSelectorOptions={type !== 'route' ? infoMetricsSelectorOptions : undefined}
              metricsSelectorOptions={metricsSelectorOptions}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
            />
          </Splitter>
        </Stack>
      </Stack>
    </PageContentWrapper>
  );
};

export { RouteReport };

export const Splitter = styled.div`
  width: 100%;
  max-height: 686px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
`;
