import * as turf from '@turf/turf';
import AreaPicker from 'components/AreaPicker';
import { Button } from 'components/Button/legacy_index';
import {
  hoveredFillLayer,
  hoveredLineLayer,
  selectedFillLayer,
  selectedLineLayer,
  transparentFillLayer,
} from 'components/MapboxContainer/AreaOptimizeLayer';
import { MapConfig } from 'components/MapboxContainer/util/config';
import { Text } from 'components/Typography';
import { TAreaFormActions } from 'constants/types';
import { useSetting } from 'hooks/store/useSetting';
import throttle from 'lodash.throttle';
import { IDistrictSelector, TDistrict } from 'pages/Setting/RouteArea';
import { Dispatch, forwardRef, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Map, { Layer, MapRef, Source } from 'react-map-gl';
import styled from 'styled-components';

const SettingAreaMap = forwardRef<
  MapRef,
  {
    methods: UseFormReturn<any>;
    areaFormMethods: UseFormReturn<any>;
    layerSelected: any;
    setLayerSelected: any;
    routeAreaFormView: { action: TAreaFormActions; visible: boolean };
    hoverLikeCollection: any;
    setHoverLikeCollection: any;
    districtSelector: IDistrictSelector;
    setDistrictSelector: Dispatch<SetStateAction<IDistrictSelector>>;
    listenerSource: any;
    isLoading: boolean;
  }
>(
  (
    {
      methods,
      areaFormMethods,
      layerSelected,
      setLayerSelected,
      routeAreaFormView,
      hoverLikeCollection,
      setHoverLikeCollection,
      districtSelector,
      setDistrictSelector,
      listenerSource,
      isLoading,
    },
    mapRef
  ) => {
    // 마우스 호버 디스플레잉 레이어
    const [hoveredCollection, setHoveredCollection] = useState<any>();

    const { isFolded } = useSetting();

    // 마우스 클릭(셀렉션) 디스플레잉 레이어
    const [layerSelectedSource, setLayerSelectedSource] = useState<any>({ type: 'FeatureCollection', features: [] });

    // 지도 줌 레벨
    const [zoom, setZoom] = useState(0);
    const [pointerXy, setPointerXy] = useState<{ x: number; y: number } | null>(null);

    // 줌 레벨에 따라 동읍면 시군구 선택 영역이 다름
    const selectionRangeByZoomLevel = (zoomLevel: number) => {
      return zoomLevel > 11 ? 'dem' : zoomLevel > 9 ? 'sgg' : 'sido';
    };

    // MouseMove 이벤트 쓰로틀링
    const setPointerThrottled = useMemo(() => {
      const throttled = throttle(e => {
        setPointerXy({ x: e.originalEvent.pageX, y: e.originalEvent.pageY });
      }, 3);

      return (e: any) => {
        return throttled(e);
      };
    }, []);

    const fitToBounds = ({ type, code }: { type: 'dem' | 'sgg' | 'sido'; code: string }) => {
      let idx = listenerSource[type].features.findIndex((x: any) => x.properties.mainCode === code);
      if (idx >= 0) {
        let src = {
          type: 'FeatureCollection',
          features: [listenerSource[type].features[idx]],
        };
        let bbox = turf.bbox(src);
        // @ts-ignore
        mapRef.current?.fitBounds(bbox, { padding: 20 });
      }
    };

    useEffect(() => {
      // @ts-ignore
      mapRef.current?.resize();
    }, [isFolded]);

    useEffect(() => {
      // mainCode가 2자리면 시/도
      // 5자리는 시군구 나머지는 동읍면
      methods.setValue('district', layerSelected, { shouldDirty: true });

      let features: any[] = [];
      layerSelected.forEach((code: string) => {
        let type = code.length === 2 ? 'sido' : code.length === 5 ? 'sgg' : 'dem';
        let index = listenerSource[type].features.findIndex((x: any) => x.properties.mainCode === code);
        if (index >= 0) features.push(listenerSource[type].features[index]);
      });
      setLayerSelectedSource({ type: 'FeatureCollection', features });
    }, [layerSelected, listenerSource, methods]);

    const processHover = ({
      type,
      props,
      like = false,
    }: {
      type: 'dem' | 'sgg' | 'sido' | 'none';
      props?: any;
      like?: boolean;
    }) => {
      if (type === 'none') {
        setHoveredCollection(null);
      } else if (hoveredCollection && hoveredCollection.id === props.mainCode) return;
      else {
        let idx = listenerSource[type].features.findIndex((x: any) => x.properties.mainCode === props.mainCode);
        if (idx >= 0) {
          let src = { type: 'FeatureCollection', features: [listenerSource[type].features[idx]] };

          if (like) setHoverLikeCollection({ name: props.mainName, id: props.mainCode, source: src });
          else setHoveredCollection({ name: props.mainName, id: props.mainCode, source: src });
        }
      }
    };

    const district_contents: { [key in TDistrict]: Array<any> } = {
      sido: listenerSource.sido.features,
      // sgg: listenerSource.sgg.features.filter((x: any) => x.properties.mainCode.startsWith(sidoSelectBox?.mainCode)),
      // dem: listenerSource.dem.features.filter((x: any) => x.properties.mainCode.startsWith(sggSelectBox?.mainCode)),
      sgg: listenerSource.sgg.features
        .filter(
          (x: any) =>
            areaFormMethods.getValues().sido?.mainCode !== '' &&
            x.properties.mainCode.startsWith(areaFormMethods.getValues().sido?.mainCode)
        )
        .sort((a: { properties: { mainName: number } }, b: { properties: { mainName: number } }) => {
          if (a.properties.mainName < b.properties.mainName) return -1;
          if (a.properties.mainName > b.properties.mainName) return 1;
          return 0;
        }),
      dem: listenerSource.dem.features
        .filter(
          (x: any) =>
            areaFormMethods.getValues().sgg?.mainCode !== '' &&
            x.properties.mainCode.startsWith(areaFormMethods.getValues().sgg?.mainCode)
        )
        .sort((a: { properties: { mainName: number } }, b: { properties: { mainName: number } }) => {
          if (a.properties.mainName < b.properties.mainName) return -1;
          if (a.properties.mainName > b.properties.mainName) return 1;
          return 0;
        }),
    };

    return (
      <MapBoxContainer>
        <Map
          localFontFamily={MapConfig.MAP_FONT_FAMILY}
          localIdeographFontFamily={MapConfig.MAP_FONT_FAMILY}
          mapboxAccessToken={MapConfig.MAP_ACCESS_TOKEN}
          mapStyle={MapConfig.MAP_STYLE}
          interactiveLayerIds={['demLayer', 'sggLayer', 'sidoLayer']}
          doubleClickZoom={false}
          onClick={e => {
            if (!routeAreaFormView.visible || routeAreaFormView.action === 'detail') return;
            if (e.features?.length) {
              e.features.forEach((f: any) => {
                let props = f.properties;

                let type = f.properties.emdCode ? 'dem' : f.properties.sggCode ? 'sgg' : 'sido';
                if (type === selectionRangeByZoomLevel(zoom)) {
                  if (layerSelected.includes(props.mainCode)) {
                    setLayerSelected(layerSelected.filter((x: string) => x !== props.mainCode));
                  } else {
                    const lenner: { [key in 'sido' | 'sgg' | 'dem']: number } = {
                      sido: 0,
                      sgg: 2,
                      dem: 5,
                    };

                    let res = [
                      ...layerSelected
                        .filter((x: string) => {
                          if (type === 'dem') {
                            return !(
                              x.length < props.mainCode.length &&
                              (x.startsWith(props.mainCode.slice(0, lenner[type as 'sido' | 'sgg' | 'dem'])) ||
                                (x.length === 2 && x.startsWith(props.mainCode.slice(0, 2))))
                            );
                          } else {
                            return !(
                              x.length < props.mainCode.length &&
                              x.startsWith(props.mainCode.slice(0, lenner[type as 'sido' | 'sgg' | 'dem']))
                            );
                          }
                        })
                        .filter((x: string) => !(x.length > props.mainCode.length && x.startsWith(props.mainCode))),
                      props.mainCode,
                    ];
                    if (res.length > 20) return alert('구역은 최대 20개까지 선택할 수 있습니다.');
                    setLayerSelected(res);
                  }
                  console.log(layerSelected);
                }
              });
            }
          }}
          onMouseOut={() => {
            setPointerXy(null);
            processHover({ type: 'none' });
          }}
          onMouseMove={e => {
            setPointerThrottled(e);

            if (e.features?.length) {
              e.features.forEach((f: any) => {
                let props = f.properties;
                let type = f.properties.emdCode ? 'dem' : f.properties.sggCode ? 'sgg' : 'sido';
                if (type === selectionRangeByZoomLevel(zoom)) processHover({ type, props });
              });

              setHoverLikeCollection(null);
            } else {
              processHover({ type: 'none' });
            }
          }}
          onDragStart={() => {
            setPointerXy(null);
            areaFormMethods.reset();
            setDistrictSelector({
              displayName: {
                sido: '',
                sgg: '',
                dem: '',
              },
              depth: 'sido',
              isOpen: false,
            });
          }}
          onZoom={ev => {
            setZoom(ev.target.getZoom());
          }}
          ref={mapRef}
        >
          {/*뻘겋게 나오는 외부 이벤트 처리용 레이어 */}
          {hoverLikeCollection && (
            <Source id={`hoverLikeSource`} type="geojson" data={hoverLikeCollection.source}>
              <Layer id={`hoverLikeLayer1`} {...hoveredFillLayer} />
              <Layer id={`hoverLikeLayer2`} {...hoveredLineLayer} />
            </Source>
          )}

          {/*마우스 올리면 뻘겋게 나오는 레이어 */}
          {hoveredCollection &&
            routeAreaFormView.visible &&
            (routeAreaFormView.action === 'add' || routeAreaFormView.action === 'modi') && (
              <Source id={`hoveredSource`} type="geojson" data={hoveredCollection.source}>
                <Layer id={`hoveredLayer1`} {...hoveredFillLayer} />
                <Layer id={`hoveredLayer2`} {...hoveredLineLayer} />
              </Source>
            )}
          {/*클릭하면 퍼렇게 나오는 레이어 */}
          <Source id={`layerSelectedSource`} type="geojson" data={layerSelectedSource}>
            <Layer id={`layerSelectedLayer`} {...selectedFillLayer} />
            <Layer id={`layerSelectedLayer2`} {...selectedLineLayer} />
          </Source>
          {/* Hover / Click 시 선택을 하기위해 가상으로 띄워놓은 레이어 */}
          <Source id={`demSource`} type="geojson" data={listenerSource.dem}>
            <Layer id={`demLayer`} {...transparentFillLayer} />
          </Source>
          <Source id={`sggSource`} type="geojson" data={listenerSource.sgg}>
            <Layer id={`sggLayer`} {...transparentFillLayer} />
          </Source>
          <Source id={`sidoSource`} type="geojson" data={listenerSource.sido}>
            <Layer id={`sidoLayer`} {...transparentFillLayer} />
          </Source>
          {/*마우스 따라댕기는 Info Overlay*/}
          {pointerXy &&
            hoveredCollection &&
            routeAreaFormView.visible &&
            (routeAreaFormView.action === 'add' || routeAreaFormView.action === 'modi') && (
              <OverlayToolTip style={{ transform: `translate(${pointerXy.x + 15}px, ${pointerXy.y - 6}px)` }}>
                <Text styleName={'caption2'} color={'RG02'}>
                  {hoveredCollection.name}
                </Text>
              </OverlayToolTip>
            )}
        </Map>
        <SearchBar
          className="drag-ban"
          onSubmit={areaFormMethods.handleSubmit(res => {
            if (res.dem.mainCode && res.dem.mainCode !== '') {
              fitToBounds({ type: 'dem', code: res.dem.mainCode });
              processHover({ type: 'dem', props: res.dem, like: true });
            } else if (res.sgg.mainCode && res.sgg.mainCode !== '') {
              fitToBounds({ type: 'sgg', code: res.sgg.mainCode });
              processHover({ type: 'sgg', props: res.sgg, like: true });
            } else if (res.sido.mainCode && res.sido.mainCode !== '') {
              fitToBounds({ type: 'sido', code: res.sido.mainCode });
              processHover({ type: 'sido', props: res.sido, like: true });
            } else {
            }
          })}
        >
          <Text styleName="subheadline1" color="RG02">
            지역 검색
          </Text>
          <AreaPicker
            display={districtSelector.displayName.sido}
            placeholder={'시/도'}
            depth="sido"
            setter={setDistrictSelector}
          />
          <AreaPicker
            display={districtSelector.displayName.sgg}
            placeholder={'시/군/구'}
            depth="sgg"
            setter={setDistrictSelector}
          />
          <AreaPicker
            display={districtSelector.displayName.dem}
            placeholder={'읍/면/동'}
            depth="dem"
            setter={setDistrictSelector}
          />

          <Button
            variant={'default'}
            type={'submit'}
            width={82}
            height={32}
            styleName="caption1"
            color="RG00"
            onClick={() =>
              setDistrictSelector(prev => {
                return { ...prev, isOpen: false };
              })
            }
          >
            검색
          </Button>

          {districtSelector.isOpen && (
            <AreaPickerItemGrid>
              {Array.from(district_contents[districtSelector.depth as TDistrict])
                .concat(
                  Array.from(
                    {
                      length:
                        4 - (district_contents[districtSelector.depth as TDistrict].length % 4) === 4
                          ? 0
                          : 4 - (district_contents[districtSelector.depth as TDistrict].length % 4),
                    },
                    x => {
                      return {
                        empty: true,
                      };
                    }
                  )
                )
                .map((x: any, i: number) => {
                  if (x?.empty) {
                    return (
                      <Text onClick={() => {}} styleName="caption2">
                        {''}
                      </Text>
                    );
                  }
                  return (
                    <Text
                      onClick={() => {
                        if (districtSelector.depth === 'sido') {
                          areaFormMethods.reset();
                          setDistrictSelector(prev => {
                            return {
                              ...prev,
                              displayName: {
                                sido: '',
                                sgg: '',
                                dem: '',
                              },
                            };
                          });
                        }
                        if (districtSelector.depth === 'sgg') {
                          areaFormMethods.setValue(
                            'dem',
                            {
                              ...areaFormMethods.getValues().dem,
                              mainCode: '',
                            },
                            { shouldDirty: true }
                          );
                          setDistrictSelector(prev => {
                            return {
                              ...prev,
                              displayName: {
                                ...prev.displayName,
                                sgg: '',
                                dem: '',
                              },
                            };
                          });
                        }
                        if (districtSelector.depth === 'dem') {
                        }
                        areaFormMethods.setValue(districtSelector.depth, x.properties, { shouldDirty: true });
                        setDistrictSelector(prev => {
                          return {
                            ...prev,
                            displayName: {
                              ...prev?.displayName,
                              [districtSelector.depth]: x.properties.mainName.split(' ').at(-1),
                            },
                          };
                        });
                      }}
                      styleName="caption2"
                    >
                      {x.properties.mainName?.split(' ').at(-1)}
                    </Text>
                  );
                })}
            </AreaPickerItemGrid>
          )}
        </SearchBar>
      </MapBoxContainer>
    );
  }
);

export default SettingAreaMap;

const OverlayToolTip = styled.div`
  position: fixed;
  width: max-content;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 6px;
  ${({ theme }) => ({
    background: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,
  })}
  border-radius: 8px
`;

const SearchBar = styled.form`
  position: absolute;
  top: 30px;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;

  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;

  gap: 10px;

  width: max-content;
  min-width: 494px;
  height: 52px;

  /* gap: 10px; */
  padding: 10px;

  border-radius: 8px;

  ${({ theme }) => ({
    boxShadow: theme.shadows.normal,
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG04}`,
  })}
`;
const MapBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & > #upside {
    position: absolute;
    right: 3px;
    top: 5px;
    cursor: pointer;
    z-index: 5;
  }

  & > #downside {
    position: absolute;
    right: 3px;
    top: 37px;
    cursor: pointer;
    z-index: 5;
  }
`;

const AreaPickerItemGrid = styled.div`
  position: absolute;

  top: 60px;
  left: 50%;
  transform: translate(-50%);

  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);

  width: 100%;
  border-radius: 8px;

  ${({ theme }) => ({
    backgroundColor: theme.colors.RG00,
    borderTop: `1px solid ${theme.colors.RG06}`,
    borderLeft: `1px solid ${theme.colors.RG06}`,

    border: `1px solid ${theme.colors.RG04}`,
  })}
  & > p {
    ${({ theme }) => ({
      padding: '6px 10px',
      borderBottom: `1px solid ${theme.colors.RG06}`,
      borderRight: `1px solid ${theme.colors.RG06}`,

      ':hover': {
        fontWeight: 700,
        backgroundColor: theme.colors.RC03_1,
      },
    })}
  }
`;
