import { PAYMENT_QUERIES } from './payment.queries';
import { API } from '../../../api/ky';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type FetchSetChangePayMethodResponse = {
  paymentUrl: string;
  orderCode: string;
};

type Variables = {
  successUrl?: string;
  // errorUrl?: string;/**/
  cancelUrl?: string;
};

type UseMutationGeneric = {
  TData: Awaited<ReturnType<typeof fetchSetChangePayMethod>>;
  TError: Error;
  TVariable: Variables;
  TMutationContext: ReturnType<typeof PAYMENT_QUERIES.SET_CHANGE_PAY_METHOD>[0] & {
    variables: UseMutationGeneric['TVariable'];
  };
};

const fetchSetChangePayMethod = async ({ endPoint, variables }: UseMutationGeneric['TMutationContext']) =>
  await API.put(endPoint, {
    json: variables,
  }).json<FetchSetChangePayMethodResponse>();

const useSetChangePayMethod = <TContext = UseMutationGeneric['TData']>(
  options?: UseMutationOptions<
    UseMutationGeneric['TData'],
    UseMutationGeneric['TError'],
    UseMutationGeneric['TVariable'],
    TContext
  >
) =>
  useMutation<UseMutationGeneric['TData'], UseMutationGeneric['TError'], UseMutationGeneric['TVariable'], TContext>(
    PAYMENT_QUERIES.SET_CHANGE_PAY_METHOD(),
    (variables: UseMutationGeneric['TVariable']) =>
      fetchSetChangePayMethod({
        variables: variables,
        ...PAYMENT_QUERIES.SET_CHANGE_PAY_METHOD()[0],
      }),
    options
  );

export { fetchSetChangePayMethod, useSetChangePayMethod };
export type { FetchSetChangePayMethodResponse };
