import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTeamList, getVehicleModel, inviteSingleDriver } from 'api';
import { Button } from 'components/Button/legacy_index';
import Divider from 'components/Divider';
import { Input } from 'components/Input';
import { Select } from 'components/Input/index';
import { ModifiedMultiSkillsSection } from 'components/ModifiedSection/ModifiedMultiSkillsSection';
import BreakRangePicker from 'components/Popup/BreakRangePicker';
import MiniTimePicker from 'components/Popup/MiniTimePicker';
import PopupYIndexFixer from 'components/Popup/PopupYIndexFixer';
import { VehicleModelPicker } from 'components/Popup/VehicleModelPicker';
import { Stack } from 'components/Stack';
import { OptionType, Swap } from 'components/Swap';
import { FormTextarea } from 'components/Table/TeamManagementTable';
import { Text } from 'components/Typography';

import { ReactComponent as ArrowDropDownIcon } from 'constants/icon/ic_arrow_dropdown.svg';
import { ReactComponent as IcClose } from 'constants/icon/ic_close_16.svg';

import { ReactComponent as IcFlag } from 'constants/icon/ic_flag.svg';
import { ReactComponent as IcPerson } from 'constants/icon/ic_person.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import { ReactComponent as IcSuccessOutline } from 'constants/icon/ic_success_circle_outline.svg';
import { ReactComponent as IcTruck } from 'constants/icon/ic_truck.svg';
import theme from 'constants/theme';
import { TOperationType } from 'constants/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { NewSelector } from 'pages/Setting/common';
import { SelectItemConvertor } from 'pages/Setting/organization/Manager';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useForm } from 'react-hook-form';

import Modal from '.';
import genLabel from './common/genLabel';
import ModalIconHeader from './common/ModalIconHeader';
import { DetailModiForm } from './OrderDetailModal';
import { TQueryStatus } from './QueryStatusModal';
import { OrderDetailModalBody, OrderDetailModalFooter, OrderDetailModalHeader } from './style';

dayjs.extend(customParseFormat);

interface AddDriverModalProps<DO> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  updateFunc: Function;
  driver_operationType: Array<OptionType<DO>>;
  queryStatusUpdate: (
    open: boolean,
    props: { status: TQueryStatus; string?: string; content?: ReactNode; callback?: Function }
  ) => void;
}

const AddDriverModal = <DO,>({
  isOpen,
  setIsOpen,
  driver_operationType,
  queryStatusUpdate,
  updateFunc,
}: AddDriverModalProps<DO>) => {
  const renderCounter = useRef(0);
  const methods = useForm<{
    trash?: { [key: string | number | symbol]: any };
    team: { name: string };
    driver: {
      name: string;
      phoneNumber: string;

      workStartTime: string;
      workEndTime?: string;
      breakDuration?: number;
      breakRange?: string | undefined;
      breakStart?: string | undefined;
      breakEnd?: string;

      note?: string;
    };
    vehicle: {
      maxCapacity: number;
      modelName: string;

      startAddress: string;
      startDetailAddress?: string;
      endAddress?: string;
      endDetailAddress?: string;
      operationType?: TOperationType;
    };
    skills: number[];
  }>({
    mode: 'onChange',
    defaultValues: {
      driver: {
        breakDuration: 0,
        workStartTime: '09:00',
        workEndTime: '없음',
      },
      vehicle: {
        operationType: 'regular',
      },
    },
  });
  const queryClient = useQueryClient();
  const [teamList, setTeamList] = useState<Array<{ key: ReactNode; value: any; extra: ReactNode }>>([]);

  const [sameAsStart, setSameAsStart] = useState<boolean>(false);
  const [breakRangePickerIsOpen, setBreakRangePickerIsOpen] = useState<boolean>(false);
  const [workStartTimeTimePickerisOpen, setWorkStartTimeTimePickerisOpen] = useState<boolean>(false);
  const [workEndTimeTimePickerisOpen, setWorkEndTimeTimePickerisOpen] = useState<boolean>(false);
  const [skillPopupIsOpen, setSkillPopupIsOpen] = useState<boolean>(false);
  const [addressPopupIsOpen, setAddressPopupIsOpen] = useState<{ isOpen: boolean; target: string }>({
    isOpen: false,
    target: '',
  });
  const [selectedSkillIds, setSelectedSkillIds] = useState<number[]>([]);

  const [skillSearchValue, setSkillSearchValue] = useState<string>('');
  const [teamSearchValue, setTeamSearchValue] = useState<string>('');
  const [selectedTeamId, setSelectedTeamId] = useState<number>();

  const [nan, setNan] = useState<boolean>(true);
  const { data: vehicleModel } = useQuery(['vehicleModel'], getVehicleModel, {});

  const {} = useQuery(['teamList'], () => getTeamList({}), {
    onSuccess: res => {
      setTeamList(
        SelectItemConvertor({
          list: (res?.data as any) ?? [],
        })
      );
    },
  });

  const {
    mutate: mutateInviteDriver,
    isLoading,
    isSuccess,
  } = useMutation(inviteSingleDriver, {
    onSuccess: res => {
      setIsOpen(false);
      queryStatusUpdate(true, {
        status: 'success',
        string: '드라이버 초대를 완료하였습니다.',
        callback: () => {
          console.log('이런');
          updateFunc();
        },
      });
    },

    onError: (err: any) => {
      const error = err?.response?.data?.error;
      setIsOpen(false);
      if (error.name === 'AlreadyBelongsTeamDriver') {
        queryStatusUpdate(true, {
          status: 'warning',
          string: `이미 같은 팀에 소속된 휴대폰 번호입니다.\n[소속 드라이버]에서 확인하실 수 있습니다.`,
        });
      } else if (error.name === 'AlreadyInvitedDriver') {
        queryStatusUpdate(true, {
          status: 'warning',
          string: `이미 초대된 아이디(휴대폰 번호) 입니다.\n[초대한 드라이버]에서 확인하실 수 있습니다.`,
        });
      } else {
        queryStatusUpdate(true, { status: 'error', string: '드라이버 초대를 완료하지 못하였습니다.' });
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['driverList'] });
    }
  }, [isSuccess]);

  const handlePostcodeComplete = (data: any, name: string) => {
    let address = data.address || data.jibunAddress;
    methods.setValue(name as any, address, { shouldValidate: true, shouldDirty: true });
    setAddressPopupIsOpen(prev => {
      return { ...prev, isOpen: false };
    });
  };

  useEffect(() => {
    methods.reset();
    setSameAsStart(false);
  }, [isOpen]);

  useEffect(() => {
    if (methods.getValues('driver.breakDuration') === 0)
      methods.setValue('driver.breakRange', '', { shouldValidate: true, shouldDirty: true });
  }, [methods.getValues('driver.breakDuration')]);

  useEffect(() => {
    if (!methods.getValues('driver.workStartTime') || !methods.getValues('driver.workEndTime')) return;
    if (
      !(
        +methods.getValues('driver.workStartTime').replace(':', '') >
        +methods.getValues('driver.workEndTime')!.replace(':', '')
      )
    ) {
      methods.clearErrors('driver.workStartTime');
      methods.clearErrors('driver.workEndTime');
    }
  }, [methods.getValues('driver.workStartTime'), methods.getValues('driver.workEndTime')]);

  useEffect(() => {
    if (sameAsStart) {
      methods.setValue('vehicle.endAddress', methods.getValues('vehicle.startAddress'), {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('vehicle.endDetailAddress', methods.getValues('vehicle.startDetailAddress'), {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      methods.setValue('vehicle.endAddress', '', { shouldDirty: true, shouldValidate: true });
      methods.setValue('vehicle.endDetailAddress', '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [methods, sameAsStart, methods.watch('vehicle.startAddress'), methods.watch('vehicle.startDetailAddress')]);

  return (
    <Modal isLoadingModal={isLoading} plain width={540} isModalOpen={isOpen} setIsModalOpen={setIsOpen}>
      <OrderDetailModalHeader>
        <Stack direction="row" justify="space-between">
          <Text styleName="subheadline2" color="RG03">
            드라이버 초대하기
          </Text>
          <IcClose
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
      </OrderDetailModalHeader>
      <Divider color="RG06" />
      <OrderDetailModalBody useVerticalScroll>
        <form
          onSubmit={methods.handleSubmit(res => {
            console.log(res);
          })}
          style={{ maxHeight: 784 }}
        >
          <DetailModiForm
            title={<ModalIconHeader title="드라이버 기본 정보" icon={<IcPerson width={20} height={20} />} />}
          >
            <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }} />
            <Input
              name="trash.inviteDate"
              label={genLabel({ text: '초대 일자', isRequiredStart: true })}
              register={methods.register}
              watch={methods.watch}
              reset={methods.reset}
              field
              height={40}
              readOnly
              disabled
              value={dayjs().format('YYYY.MM.DD')}
            />

            <NewSelector
              parent={
                <Input
                  name="team.name"
                  label={genLabel({ text: '소속 팀', isRequiredStart: true })}
                  placeholder="소속팀을 검색하세요."
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  field
                  height={40}
                  variant="third"
                  validation={{
                    required: { value: true, message: '해당 필드는 필수값입니다.' },
                    validate: (res: string) => {
                      return teamList.find(d => d.key === res) || '팀을 찾을 수 없습니다.';
                    },
                  }}
                  errors={methods.formState?.errors?.team?.name}
                  hasError={Boolean(methods.formState?.errors?.team?.name)}
                  onChange={(e: any) => {
                    setTeamSearchValue(e.target.value);
                  }}
                />
              }
              selectorGap={40}
              placeholder="팀을 선택해주세요."
              options={(teamList || []).filter(x => (x.key as string).includes(teamSearchValue))}
              onOptionsClick={item => {
                methods.setValue('team.name', item.key, { shouldDirty: true, shouldValidate: true });
                setSelectedTeamId(item.value);
              }}
              icon={<ArrowDropDownIcon />}
            />
            <Stack direction="row" spacing={16} align={'start'}>
              <Input
                name="driver.name"
                label={genLabel({ text: '이름', isRequiredStart: true })}
                placeholder="예시) 홍길동"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{
                  required: { value: true, message: '해당 필드는 필수값입니다.' },
                  minLength: { value: 2, message: '드라이버 이름은 한글/영문 2~20자 입력이 가능합니다.' },
                  maxLength: { value: 20, message: '드라이버 이름은 한글/영문 2~20자 입력이 가능합니다.' },
                }}
                variant="third"
                field
                height={40}
                removeBtn={() => {
                  methods.resetField('driver.name');
                }}
                errors={methods.formState?.errors?.driver?.name}
                hasError={Boolean(methods.formState?.errors?.driver?.name)}
              />
              <Input
                name="driver.phoneNumber"
                label={genLabel({ text: '아이디 (휴대폰 번호)', isRequiredStart: true })}
                placeholder="000 - 0000 - 0000"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{
                  required: { value: true, message: '해당 필드는 필수값입니다.' },
                  minLength: {
                    value: 10,
                    message: '유효한 연락처 형식이 아닙니다.',
                  },
                  maxLength: {
                    value: 13,
                    message: '유효한 연락처 형식이 아닙니다.',
                  },
                  pattern: {
                    value: /^[0-9\-]+$/g,
                    message: '숫자만 입력 가능합니다.',
                  },
                }}
                variant="third"
                field
                height={40}
                errors={methods.formState?.errors?.driver?.phoneNumber}
                hasError={Boolean(methods.formState?.errors?.driver?.phoneNumber)}
              />
            </Stack>

            <Stack spacing={4}>
              {genLabel({ text: '메모' })}
              <FormTextarea
                {...methods.register('driver.note', {})}
                placeholder={'예시) 매주 월요일 휴무'}
                rows={14}
                useFieldFont={true}
                cols={10}
                wrap="soft"
                maxLength={200}
                onKeyDown={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
                onKeyUp={e => {
                  e.currentTarget.style.height = '1px';
                  e.currentTarget.style.height = 12 + e.currentTarget.scrollHeight + 'px';
                }}
              />
            </Stack>
          </DetailModiForm>
          <Divider color="RG06" />
          <DetailModiForm title={<ModalIconHeader title="차량 · 주행 정보" icon={<IcTruck />} />}>
            <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }} />
            <Swap
              className="order"
              padding={0}
              gap={0}
              sx={{
                paddingTop: '7px',
              }}
              renderCounter={renderCounter.current}
              name="vehicle.operationType"
              methods={methods}
              label={genLabel({
                text: '운영 유형',
                isRequiredStart: true,
              })}
              defaultIndex={driver_operationType.findIndex(d => d.value === methods.getValues('vehicle.operationType'))}
              options={driver_operationType}
            />
            <Stack align="end" spacing={2}>
              <Stack direction="row" spacing={16} align={'start'}>
                <Stack sx={{ position: 'relative' }}>
                  <Input
                    name="vehicle.modelName"
                    label={genLabel({ text: '차종', isRequiredStart: true })}
                    placeholder="차종을 선택해 주세요"
                    register={methods.register}
                    watch={methods.watch}
                    reset={methods.reset}
                    variant="third"
                    field
                    height={40}
                    errors={methods.formState?.errors?.vehicle?.modelName}
                    hasError={Boolean(methods.formState?.errors?.vehicle?.modelName)}
                    validation={{
                      required: { value: true, message: '해당 필드는 필수값입니다.' },
                      validate: (res: any) =>
                        !Boolean(res) ||
                        (vehicleModel && vehicleModel?.vehicleModelList.filter(d => d.name === res).length > 0) ||
                        '존재하지 않는 차량입니다.',
                    }}
                    onChange={(e: any) => {
                      setSkillPopupIsOpen(true);
                      setSkillSearchValue(e.target.value);
                      methods.setValue('vehicle.modelName', e.target.value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    onClick={() => {
                      setSkillPopupIsOpen(true);
                    }}
                  />
                  {skillPopupIsOpen && (
                    <VehicleModelPicker
                      setIsOpen={setSkillPopupIsOpen}
                      methods={methods}
                      name={'vehicle.modelName'}
                      searchValue={skillSearchValue}
                    />
                  )}
                </Stack>
                <Input
                  name="vehicle.maxCapacity"
                  label={genLabel({ text: '최대 용적량', isRequiredStart: true })}
                  placeholder="정수만 입력해 주세요. 예시) 1000"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  validation={{
                    required: { value: true, message: '해당 필드는 필수값입니다.' },
                    validate: (res: string) => {
                      return (Number(res) > 0 && Number.isInteger(Number(res))) || '1 이상의 정수만 입력해 주세요.';
                    },
                  }}
                  variant="third"
                  field
                  height={40}
                  hasError={Boolean(methods.formState?.errors?.vehicle?.maxCapacity)}
                  errors={methods.formState.errors?.vehicle?.maxCapacity}
                />
              </Stack>
            </Stack>

            <Stack direction="row" spacing={16} align="start">
              <Stack sx={{ position: 'relative' }}>
                {(workStartTimeTimePickerisOpen || workEndTimeTimePickerisOpen) && (
                  <PopupYIndexFixer>
                    <div />
                  </PopupYIndexFixer>
                )}
                <Input
                  name="driver.workStartTime"
                  label={genLabel({ text: '주행 시작 시간', isRequiredStart: true })}
                  placeholder="09 : 00"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  validation={{
                    required: true,
                    validate: (res: string) => {
                      if (methods.getValues('driver.workEndTime')) {
                        if (
                          +(methods.getValues('driver.workStartTime') ?? '').replace(':', '') >
                          +(methods.getValues('driver.workEndTime') ?? '').replace(':', '')
                        ) {
                          return '주행 시작 시간이 주행 종료 시간보다 늦을 수 없습니다.';
                        }
                      }

                      return;
                    },
                  }}
                  variant="third"
                  field
                  height={40}
                  readOnly
                  onClick={() => {
                    setWorkStartTimeTimePickerisOpen(prev => !prev);
                  }}
                  errors={methods.formState?.errors?.driver?.workStartTime}
                  hasError={Boolean(methods.formState?.errors?.driver?.workStartTime)}
                />
                {workStartTimeTimePickerisOpen && (
                  <MiniTimePicker
                    initial={methods.getValues('driver.workStartTime') || '09:00'}
                    init={methods.getValues('driver.workStartTime')}
                    isOpen={workStartTimeTimePickerisOpen}
                    setIsOpen={setWorkStartTimeTimePickerisOpen}
                    methods={methods}
                    name={'driver.workStartTime'}
                    rSide
                    nm
                  />
                )}
              </Stack>

              <Stack sx={{ position: 'relative' }}>
                <Input
                  name="driver.workEndTime"
                  label={genLabel({ text: '주행 종료 시간', isRequiredStart: false })}
                  placeholder="없음"
                  register={methods.register}
                  watch={methods.watch}
                  reset={methods.reset}
                  variant="third"
                  field
                  readOnly
                  height={40}
                  onClick={() => {
                    setWorkEndTimeTimePickerisOpen(prev => !prev);
                  }}
                  validation={{
                    validate: (res: string) => {
                      if (!methods.getValues('driver.workStartTime') || !methods.getValues('driver.workEndTime'))
                        return;
                      if (
                        +(methods.getValues('driver.workStartTime') ?? '').replace(':', '') >
                        +(methods.getValues('driver.workEndTime') ?? '').replace(':', '')
                      )
                        return '주행 종료 시간이 주행 시작 시간을 앞설 수 없습니다.';
                    },
                  }}
                  errors={methods.formState?.errors?.driver?.workEndTime}
                  hasError={Boolean(methods.formState?.errors?.driver?.workEndTime)}
                />
                {workEndTimeTimePickerisOpen && (
                  <MiniTimePicker
                    initial={
                      methods.getValues('driver.workEndTime') && methods.getValues('driver.workEndTime') !== '없음'
                        ? methods.getValues('driver.workEndTime')
                        : '00:00'
                    }
                    init={methods.getValues('driver.workEndTime')}
                    isOpen={workEndTimeTimePickerisOpen}
                    setIsOpen={setWorkEndTimeTimePickerisOpen}
                    methods={methods}
                    name={'driver.workEndTime'}
                    rSide
                    nm
                    nanable
                    clearOnNan
                    nan={nan}
                    setNan={setNan}
                  />
                )}
              </Stack>
            </Stack>
            <Stack sx={{ position: 'relative' }}>
              <Select
                options={[
                  { key: '없음', value: 0 },
                  { key: '30 분', value: 30 },
                  { key: '60 분', value: 60 },
                  { key: '90 분', value: 90 },
                  { key: '120 분', value: 120 },
                ]}
                defaultValue={0}
                setValue={methods.setValue}
                name="driver.breakDuration"
                label={genLabel({ text: '휴게 시간', isRequiredStart: true })}
                placeholder="없음"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{ required: true }}
                variant="third"
                field
                height={40}
                errors={methods.formState?.errors?.driver?.breakDuration}
                hasError={Boolean(methods.formState?.errors?.driver?.breakDuration)}
              />
            </Stack>
            <Stack sx={{ position: 'relative' }}>
              <Input
                disabled={methods.watch('driver.breakDuration') === 0}
                name="driver.breakRange"
                label={genLabel({ text: '휴게 시간 구간' })}
                placeholder="휴게 시간 구간을 입력해 주세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                validation={{
                  validate: {
                    startTimeCannotBeAheadOfWorkTime: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      const sv = dayjs(breakStart, 'HH:mm')
                        ? dayjs(breakStart, 'HH:mm').isBefore(dayjs(methods.getValues('driver.workStartTime'), 'HH:mm'))
                        : true;
                      const ev = dayjs(breakEnd, 'HH:mm')
                        ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(methods.getValues('driver.workStartTime'), 'HH:mm'))
                        : true;

                      console.log(sv, ev);

                      return !(sv || ev) || '휴게 시작시간이 근무시작 시간보다 앞설 수 없습니다.';
                    },
                    endTimeCannotBeAheadOrSame: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      const sv =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? dayjs(breakEnd, 'HH:mm').isSame(dayjs(breakStart, 'HH:mm'))
                          : true;
                      const ev =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? dayjs(breakEnd, 'HH:mm').isBefore(dayjs(breakStart, 'HH:mm'))
                          : true;

                      console.log(sv, ev);

                      return !(sv || ev) || '휴게 종료 시간을 주행 시작 시간보다 앞서서 설정할 수 없습니다.';
                    },
                    startTime_endTime_range_not_matched: (res: string) => {
                      var range = res?.replaceAll('오전', '')?.replaceAll('오후', '')?.replaceAll(' ', '')?.split(`~`);
                      const breakStart = range?.at(0);
                      const breakEnd = range?.at(1);

                      if (!breakStart && !breakEnd) return;

                      const sv =
                        dayjs(breakStart, 'HH:mm') && dayjs(breakEnd, 'HH:mm')
                          ? isNaN(dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm')) ||
                            dayjs(breakEnd, 'HH:mm').diff(dayjs(breakStart, 'HH:mm'), 'm') + 1 >
                              (methods.getValues('driver.breakDuration') ?? 0)
                          : false;

                      return sv || '휴게 시간 구간은 휴게 시간보다 길어야 합니다.';
                    },
                  },
                }}
                variant={methods.watch('driver.breakDuration') === 0 ? 'fourth' : 'third'}
                field
                height={40}
                readOnly
                onClick={() => {
                  setBreakRangePickerIsOpen(prev => !prev);
                }}
                hasError={Boolean(methods.formState?.errors?.driver?.breakRange)}
              />
              {breakRangePickerIsOpen && (
                <PopupYIndexFixer>
                  <BreakRangePicker
                    name="driver.breakRange"
                    isOpen={breakRangePickerIsOpen}
                    setIsOpen={setBreakRangePickerIsOpen}
                    {...{ methods }}
                  />
                </PopupYIndexFixer>
              )}
            </Stack>
          </DetailModiForm>
          <Divider color="RG06" />
          <ModifiedMultiSkillsSection isModified setSelectedSkillIds={setSelectedSkillIds} teamId={selectedTeamId} />
          <Divider color="RG06" />
          <DetailModiForm title={<ModalIconHeader title="출 · 도착지 정보" icon={<IcFlag />} />}>
            <Divider color="RG06" style={{ width: 'calc(100% + 40px)' }} />
            <Stack spacing={12}>
              <Input
                name="vehicle.startAddress"
                label={genLabel({ text: '출발지 주소', isRequiredStart: true })}
                placeholder="주소를 입력하세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant="third"
                height={40}
                validation={{
                  required: { value: true, message: '해당 필드는 필수값입니다.' },
                }}
                readOnly={true}
                isInject={((methods.getValues('vehicle.startAddress') as string)?.length ?? 0) > 0}
                removeBtn={(e: MouseEvent) => {
                  e.preventDefault();
                  methods.setValue('vehicle.startAddress', '');
                }}
                onFocus={() => {
                  methods.clearErrors('vehicle.startAddress');
                  setAddressPopupIsOpen(prev => {
                    return { target: 'vehicle.startAddress', isOpen: true };
                  });
                }}
                onClick={() => {
                  setAddressPopupIsOpen(prev => {
                    return { target: 'vehicle.startAddress', isOpen: true };
                  });
                }}
                hasError={Boolean(methods.formState?.errors?.vehicle?.startAddress)}
              />
              <Input
                disabled={!methods.getValues('vehicle.startAddress')}
                name="vehicle.startDetailAddress"
                placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant={!methods.getValues('vehicle.startAddress') ? 'fourth' : 'third'}
                height={40}
                hasError={Boolean(methods.formState?.errors?.vehicle?.startDetailAddress)}
              />
            </Stack>

            <Stack spacing={12}>
              <Input
                disabled={sameAsStart}
                name="vehicle.endAddress"
                label={genLabel({ text: '도착지 주소' })}
                rightArea={{
                  rightContent: (
                    <Stack
                      spacing={4}
                      direction="row"
                      sx={{ width: 'max-content' }}
                      onClick={() => {
                        setSameAsStart(prev => !prev);
                      }}
                    >
                      {sameAsStart ? (
                        <IcSuccess width={12} height={12} fill={theme.colors['RC02']} />
                      ) : (
                        <IcSuccessOutline width={12} height={12} />
                      )}
                      <Text styleName="caption1" color={sameAsStart ? 'RC02' : 'RG04'}>
                        출발지와 동일
                      </Text>
                    </Stack>
                  ),
                }}
                placeholder="주소를 입력하세요"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant={sameAsStart ? 'fourth' : 'third'}
                height={40}
                readOnly={true}
                isInject={((methods.getValues('vehicle.endAddress') as string)?.length ?? 0) > 0}
                removeBtn={(e: MouseEvent) => {
                  e.preventDefault();
                  methods.setValue('vehicle.endAddress', '');
                }}
                onFocus={() => {
                  methods.clearErrors('vehicle.endAddress');
                  setAddressPopupIsOpen(prev => {
                    return { target: 'vehicle.endAddress', isOpen: true };
                  });
                }}
                onClick={() => {
                  setAddressPopupIsOpen(prev => {
                    return { target: 'vehicle.endAddress', isOpen: true };
                  });
                }}
                hasError={Boolean(methods.formState?.errors?.vehicle?.endAddress)}
              />
              <Input
                disabled={!methods.getValues('vehicle.endAddress') || sameAsStart}
                name="vehicle.endDetailAddress"
                placeholder="상세주소를 입력해 주세요. 예시) 101동 101호"
                register={methods.register}
                watch={methods.watch}
                reset={methods.reset}
                field
                type="text"
                variant={!methods.getValues('vehicle.endAddress') || sameAsStart ? 'fourth' : 'third'}
                height={40}
                hasError={Boolean(methods.formState?.errors?.vehicle?.endDetailAddress)}
              />
            </Stack>

            <Modal
              isModalOpen={addressPopupIsOpen.isOpen}
              setIsModalOpen={() => {
                setAddressPopupIsOpen(prev => {
                  return { ...prev, isOpen: false };
                });
              }}
              title={'우편번호찾기'}
              width={532}
              ms={30}
              padding={20}
            >
              <DaumPostcodeEmbed
                onComplete={data => handlePostcodeComplete(data, addressPopupIsOpen.target)}
                style={{ height: '500px', marginBottom: '0' }}
              />
            </Modal>
          </DetailModiForm>
        </form>
      </OrderDetailModalBody>
      <Divider color="RG06" />
      <OrderDetailModalFooter>
        <Stack
          name="modal-footer"
          spacing={16}
          direction="row"
          align="center"
          justify="end"
          sx={{ padding: '2px 0 0 0' }}
        >
          <Button
            variant="seventh"
            type="button"
            width={102}
            height={40}
            styleName="body1"
            color="RG03"
            onClick={() => {
              methods.reset();
              setIsOpen(false);
            }}
          >
            닫기
          </Button>
          <Button
            variant="default"
            type="button"
            width={216}
            height={40}
            styleName="body1"
            color="RG00"
            onClick={methods.handleSubmit(res => {
              console.log(res);
              delete res.trash;
              // delete res.operationType;
              if (typeof res.driver.breakDuration !== 'number' || res.driver.breakDuration === 0)
                delete res.driver.breakDuration;
              else {
                res.driver.breakDuration = res.driver.breakDuration * 60;
              }
              if (res.driver.note === '') delete res.driver.note;
              if (res.driver.breakRange === '') delete res.driver.breakRange;
              if (!res.driver.workEndTime) delete res.driver.workEndTime;
              else {
                let range = res.driver.breakRange
                  ?.replaceAll('오전', '')
                  ?.replaceAll('오후', '')
                  ?.replaceAll(' ', '')
                  ?.split(`~`);
                res.driver.breakStart = range?.at(0);
                res.driver.breakEnd = range?.at(1);
                if (!res.driver.breakStart) delete res.driver.breakStart;
                if (!res.driver.breakEnd) delete res.driver.breakEnd;
                delete res.driver.breakRange;
              }
              if (res.vehicle.endAddress === '') {
                delete res.vehicle.endAddress;
              }
              if (res.vehicle.startDetailAddress === '') {
                delete res.vehicle.startDetailAddress;
              }
              if (res.vehicle.endDetailAddress === '') {
                delete res.vehicle.endDetailAddress;
              }
              if (res.driver.phoneNumber !== '' && res.driver.phoneNumber) {
                res.driver.phoneNumber = res.driver.phoneNumber?.replaceAll('-', '');
              }

              if (res.driver.workEndTime === '없음') {
                delete res.driver.workEndTime;
              }

              res.skills = selectedSkillIds;

              mutateInviteDriver(res);
            })}
          >
            초대하기
          </Button>
        </Stack>
      </OrderDetailModalFooter>
    </Modal>
  );
};

export default AddDriverModal;
