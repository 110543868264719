import {
  IGetControlRouteResponses,
  IEqualOptimizeResponses,
  IControlRouteDriverOrderList,
} from '../../constants/types';
import type { Dispatch, SetStateAction } from 'react';
import polyline from 'google-polyline';
import { ColorPalette } from '../../constants/colorPalette';

export function generatePolyline({
  data,
  setter,
  selectedOrder,
}: {
  data: IGetControlRouteResponses;
  setter: Dispatch<SetStateAction<any>>;
  selectedOrder?: IControlRouteDriverOrderList;
}) {
  let baseGeoJsonStructure: any = { id: 'polyline', type: 'FeatureCollection', features: [] };

  data.driverList.forEach(driver => {
    let polys: any[] = [];
    let completedPolyArr: any[] = [];

    let selectedPolys: any[] = [];
    let completedSelectedPolyArr: any[] = [];

    driver.orderList.forEach(order => {
      if (order.route && order.route.polyline) {
        let decodedLine = polyline.decode(order.route.polyline);
        decodedLine.forEach(line => polys.push(line));
        if (order.status === 'completed' || order.status === 'skipped') completedPolyArr.push(decodedLine);

        if (selectedOrder && order.orderId === selectedOrder.orderId) {
          decodedLine.forEach(line => selectedPolys.push(line));
          if (order.status === 'completed' || order.status === 'skipped') completedSelectedPolyArr.push(decodedLine);
        }
      }
    });

    let focused = selectedPolys.length > 0;

    let polysList = [
      { layer: polys, opacity: focused ? 0.3 : 1 },
      { layer: selectedPolys, opacity: 1 },
    ];
    let completedPolysList = [
      { layer: completedPolyArr, opacity: focused ? 0.3 : 1 },
      { layer: completedSelectedPolyArr, opacity: 1 },
    ];

    polysList.forEach(p => {
      let featureBase = {
        geometry: {
          coordinates: p.layer,
          type: 'LineString',
        },
        properties: {
          color: '#FFFFFF',
          width: 8,
          opacity: p.opacity,
          driverId: driver.driverId,
          driverName: driver.name,
          driverStatus: driver.status,
        },
        type: 'Feature',
      };
      baseGeoJsonStructure.features.push(featureBase);

      let feature = {
        geometry: {
          coordinates: p.layer,
          type: 'LineString',
        },
        properties: {
          color: ColorPalette.getColor(driver.driverId || 0),
          width: 5,
          driverId: driver.driverId,
          opacity: p.opacity,
        },
        type: 'Feature',
      };
      baseGeoJsonStructure.features.push(feature);
    });

    completedPolysList.forEach(p => {
      p.layer.forEach(data => {
        let featureCompleted = {
          geometry: {
            coordinates: data,
            type: 'LineString',
          },
          properties: {
            color: '#8F96A9',
            width: 5,
            driverId: driver.driverId,
            opacity: p.opacity,
          },
          type: 'Feature',
        };
        baseGeoJsonStructure.features.push(featureCompleted);
      });
    });
  });

  setter(baseGeoJsonStructure);
}

export function generateMarker({
  data,
  setter,
}: {
  data: IGetControlRouteResponses | IEqualOptimizeResponses;
  setter: Dispatch<SetStateAction<any>>;
}) {
  let geojsons: any[] = [];
  data.driverList.forEach(driver => {
    let baseGeoJsonStructure: any = {
      id: `marker-driver-${driver.driverId}`,
      type: 'FeatureCollection',
      features: [],
    };
    driver.orderList.forEach(order => {
      if (order.coordinate && order.coordinate.coordinates) {
        let feature = {
          type: 'Feature',
          properties: {
            color: ColorPalette.getColor(driver.driverId || 0),
          },
          geometry: {
            type: 'Point',
            coordinates: order.coordinate.coordinates,
          },
        };
        baseGeoJsonStructure.features.push(feature);
      }
    });
    geojsons.push(baseGeoJsonStructure);
  });

  setter(geojsons);
}

export function generateMarker2({
  data,
  setter,
}: {
  data: IGetControlRouteResponses | IEqualOptimizeResponses;
  setter: Dispatch<SetStateAction<any>>;
}) {
  let baseGeoJsonStructure: any = {
    id: `marker-driver-`,
    type: 'FeatureCollection',
    features: [],
  };
  data.driverList.forEach(driver => {
    driver.orderList.forEach(order => {
      if (order.coordinate && order.coordinate.coordinates) {
        let feature = {
          type: 'Feature',
          properties: {
            color: order.status === 'completed' ? '#8F96A9' : ColorPalette.getColor(driver.driverId || 0),
            statusWeight: order.status === 'complete' ? 1 : -10000,
          },
          geometry: {
            type: 'Point',
            coordinates: order.coordinate.coordinates,
          },
        };
        baseGeoJsonStructure.features.push(feature);
      }
    });
  });

  setter([baseGeoJsonStructure]);
}
