import styled, { css } from 'styled-components';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { Tag } from 'components/Tag';

import { ReactComponent as TutorialBanner } from 'constants/graphics/img_banner_tutorial.svg';
import strings from '../../util/Localization';
import { commonUris } from 'constants/uris';

const SettingNav = () => {
  let history = useLocation();

  return (
    <Stack direction="row" align="start">
      <SidebarWrapper>
        <Stack name="sidebar-content-wrapper" spacing={24}>
          <Stack
            spacing={33}
            // divider={<Divider color="RG06" style={{ margin: '-16px 0' }} />}
          >
            <Stack spacing={16} align="start" name="normal-setting-section">
              <Text styleName="caption2" color="RG04" sx={{ padding: '0 0 0 10px' }}>
                일반 설정
              </Text>

              {window.localStorage.getItem('serviceDomain') === 'roouty' ? (
                <Stack align="start" spacing={8}>
                  <CustomNavLink to={'account'}>계정 정보</CustomNavLink>
                  <CustomNavLink to={'company'}>회사 정보</CustomNavLink>
                  <CustomNavLink to={'members'}>{strings.멤버관리}</CustomNavLink>
                  <CustomNavLink to={'vehicles'}>차량 관리</CustomNavLink>
                  <CustomNavLink to={'area'}>권역 설정</CustomNavLink>
                </Stack>
              ) : (
                <Stack align="start" spacing={8}>
                  <CustomNavLink to={'account'}>계정 정보</CustomNavLink>
                  <CustomNavLink to={'company'}>회사 정보</CustomNavLink>
                  <CustomNavLink to={'members'}>{strings.멤버관리}</CustomNavLink>
                </Stack>
              )}
            </Stack>

            <Stack spacing={16} align="start" name="normal-setting-section">
              <Text styleName="caption2" color="RG04" sx={{ padding: '0 0 0 10px' }}>
                고객 안내 설정
              </Text>
              <Stack align="start" spacing={8}>
                <CustomNavLink to={`${history.pathname}/`} style={{ cursor: 'not-allowed' }}>
                  <Stack direction="row" justify="space-between">
                    <p>안내 메시지</p>
                    <Tag status="preparing" styleName="tooltip1" fit>
                      준비중
                    </Tag>
                  </Stack>
                </CustomNavLink>
              </Stack>
            </Stack>
          </Stack>
          <div
            //bottom content padding box
            style={{ paddingBottom: '48px' }}
          >
            <a href={commonUris.FAQ} target={'_blank'}>
              <TutorialBanner />
            </a>
          </div>
        </Stack>
      </SidebarWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Stack>
  );
};

export default SettingNav;

export const SidebarWrapper = styled.div`
  display: flex;
  width: 288px;
  min-width: 288px;

  height: calc(100vh - 72px);

  padding: 48px 20px 0 30px;
  background: ${({ theme }) => theme.colors.RG08};

  z-index: 5;
  box-sizing: border-box;

  ${({ theme }) => theme.scrollStyle.vertical}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;

  height: calc(100vh - 72px);
  overflow: hidden;
`;

export interface CustomNavItemStyleProps {
  isActive?: boolean;
  disabled?: boolean;
}

export const CustomNavItemStyle = css<CustomNavItemStyleProps>`
  ${({ theme }) => theme.fontStyle.caption2}
  width: 100%;

  padding: 8px 10px;
  border-radius: 8px;

  color: ${({ theme }) => theme.colors.RG02};
  background-color: transparent;

  ${({ disabled }) =>
    disabled && {
      cursor: 'not-allowed',
    }}

  ${({ isActive, theme }) => isActive && theme.fontStyle.caption1};
  ${({ isActive, theme }) =>
    isActive && {
      color: theme.colors.RC02,
      backgroundColor: 'rgba(118, 157, 255, 0.3)',
    }}
  &.active {
    ${({ theme }) => theme.fontStyle.caption1}
    color: ${({ theme }) => theme.colors.RC02};
    background-color: rgba(118, 157, 255, 0.3);
  }

  cursor: pointer;
`;

export const CustomNavLink = styled(NavLink)<CustomNavItemStyleProps>`
  ${CustomNavItemStyle}
`;
