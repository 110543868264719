import { create } from 'zustand';

interface UseTrackingProps {
  title: string;
  setTitle: (title: string) => void;
}

const useTracking = create<UseTrackingProps>(set => ({
  title: '',
  setTitle: title => set({ title }),
}));

export { useTracking };
export type { UseTrackingProps };
