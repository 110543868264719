import { useQueryClient } from '@tanstack/react-query';
import AltWrapper from 'components/Alt/AltWrapper';
import MyPagePopup from 'components/Popup/MyPagePopup';
import { Stack } from 'components/Stack';
import { ReactComponent as Ic_Help } from 'constants/icon/ic_help.svg';
import { ReactComponent as IcProfile } from 'constants/icon/ic_profile.svg';
import { ReactComponent as Ic_setting } from 'constants/icon/ic_setting.svg';
import { ReactComponent as IcSmileIllust } from 'constants/icon/ic_smile_illust.svg';
import { ReactComponent as IcTutorial } from 'constants/icon/icon_tutorial.svg';
import theme from 'constants/theme';
import { commonUris } from 'constants/uris';
import dayjs from 'dayjs';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import Setting from 'pages/Setting';
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ReactComponent as Logo } from 'roouty_logo.svg';

import { useGNBStore, useStore, useTutorialStore } from 'store';
import styled from 'styled-components';

import GradeSelector from 'util/GradeSelector';
import { UpgradeGuideModal } from '../Modal/warning/paymentPlan/UpgradeGuideModal';
import { Text } from '../Typography';

import * as S from './style';

const GNB = () => {
  const location = useLocation();
  const { userGrade } = useStore();
  const {
    SETTINGOPEN,
    SETSETTINGOPEN,
    showFaqTooltip,
    setShowFaqTooltip,
    showTutorialTooltip,
    setShowTutorialTooltip,
  } = useGNBStore();

  const authToken = useMemo(() => localStorage.getItem('accessToken'), []);

  const [pricing_id, setPricing_id] = useState<number>(JSON.parse(localStorage.getItem('pricing') as string)?.id ?? 1);
  const [freeTrailTerm, setFreeTrailTerm] = useState<number>(0);

  const { isSuccess, data } = useGetPaymentMy({
    refetchOnWindowFocus: true,
  });

  const { TUTORIALISOPEN, SETTUTORIALISOPEN } = useTutorialStore();

  const gnbAction = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>, fn?: Function, ect?: boolean) => {
    if (freeTrailTerm === 0 && pricing_id === 1 && !ect) return e.preventDefault();
    SETSETTINGOPEN(false);
    fn && fn();
  };

  const orderManagementContent: Array<{
    path: string;
    label: string;
    disabled?: boolean;
    preset?: 'hover' | 'gray';
  }> = useMemo(
    () => [
      {
        label: '자동 최적화 배차',
        path: '/manage/order/auto',
        disabled: pricing_id === 2,
        preset: pricing_id === 2 ? 'gray' : location.pathname === '/manage/order/auto' ? 'hover' : undefined,
      },
      {
        label: '수동 배차',
        path: '/manage/order/manual',
        preset: pricing_id === 2 ? 'gray' : location.pathname === '/manage/order/manual' ? 'hover' : undefined,
      },
    ],
    [pricing_id, location]
  );

  const reportContent: Array<{
    path: string;
    label: string;
    disabled?: boolean;
    preset?: 'hover' | 'gray';
  }> = useMemo(
    () => [
      {
        label: '전체 주행 기록',
        path: '/manage/report/route',
        disabled: pricing_id === 2,
        preset: pricing_id === 2 ? 'gray' : location.pathname === '/manage/report/route' ? 'hover' : undefined,
      },
      {
        label: '드라이버별 주행 기록',
        path: '/manage/report/route/driver',
        preset: pricing_id === 2 ? 'gray' : location.pathname === '/manage/report/route/driver' ? 'hover' : undefined,
      },
    ],
    [pricing_id, location]
  );

  const GNBContent: {
    routes: Array<{ id?: string; path: string; title: string; disabled?: boolean }>;
    iconRoutes: Array<{
      icon: any;
      tooltipId?: string;
      path?: string;
      external?: boolean;
      target?: '_blank' | '_self' | '_parent' | '_top';
      click?: any;
      activeKey?: boolean;
      alt?: {
        content: string;
        anchorWeight?: {
          top?: number;
          left?: number;
        };
      };
    }>;
  } = useMemo(() => {
    return {
      routes: [
        { path: 'manage/route', title: '배차 계획', disabled: freeTrailTerm === 0 && pricing_id === 1 },
        { path: 'manage/control?landing', title: '모니터링', disabled: freeTrailTerm === 0 && pricing_id === 1 },
        { id: 'gnbReport', path: 'manage/report', title: '보고서', disabled: freeTrailTerm === 0 && pricing_id === 1 },
        { path: 'manage/', title: '정산', disabled: true },
      ],
      iconRoutes: [
        {
          icon: IcTutorial,
          click: () => {
            SETTUTORIALISOPEN(true);
          },
          tooltipId: 'tutorialtooltip',
          activeKey: TUTORIALISOPEN,
          alt: {
            content: '튜토리얼',
            anchorWeight: {
              top: 44,
              left: -4,
            },
          },
        },
        {
          icon: Ic_Help,
          external: true,
          target: '_blank',
          path: commonUris.FAQ,
          tooltipId: 'faqtooltip',
          alt: {
            content: '이용안내',
            anchorWeight: {
              top: 48,
              left: -4,
            },
          },
        },
        {
          icon: Ic_setting,
          // path: 'setting',
          click: () => {
            if (freeTrailTerm === 0 && pricing_id === 1) return;
            userGrade < 3
              ? SETSETTINGOPEN(prev => !prev)
              : alert('페이지에 접근할 수 없습니다.\n접근 권한이 없습니다.');
          },
          activeKey: SETTINGOPEN,
          alt: {
            content: '설정',
            anchorWeight: {
              top: 44,
              left: -14,
            },
          },
        },
      ],
    };
  }, [SETSETTINGOPEN, SETTINGOPEN, SETTUTORIALISOPEN, TUTORIALISOPEN, freeTrailTerm, pricing_id, userGrade]);
  const { routes, iconRoutes } = GNBContent;

  const handleDisabledNav = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    alert('해당 페이지는 준비중입니다.');
  };

  const [orderManageTooltipIsOpen, setOrderManageTooltipIsOpen] = useState<boolean>(false);
  const [isOpenReportTooltip, setIsOpenReportTooltip] = useState<boolean>(false);
  const [myPagePopupIsOpen, setMyPagePopupIsOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isOpenSignUpFaqInfo, setIsOpenSignUpFaqInfo] = useState(showFaqTooltip);
  const [signupfaqpop, setsignupfaqpop] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const [tutorialpop, settutorialpop] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const target = document.getElementById('faqtooltip');
  //   if (target === null) return;
  //   else {
  //     setsignupfaqpop({
  //       left: target.getBoundingClientRect().left,
  //       top: target.getBoundingClientRect().top,
  //     });
  //     window.addEventListener('resize', () => {
  //       setsignupfaqpop({
  //         left: target.getBoundingClientRect().left,
  //         top: target.getBoundingClientRect().top,
  //       });
  //     });
  //   }

  //   return () => {
  //     window.removeEventListener('resize', () => {
  //       setsignupfaqpop({
  //         left: target.getBoundingClientRect().left,
  //         top: target.getBoundingClientRect().top,
  //       });
  //     });
  //   };
  // }, []);
  useEffect(() => {
    const target = document.getElementById('tutorialtooltip');
    const offset: number = 12;
    if (target === null) return;
    else {
      settutorialpop({
        left: target.getBoundingClientRect().left - offset,
        top: target.getBoundingClientRect().top,
      });
      window.addEventListener('resize', () => {
        settutorialpop({
          left: target.getBoundingClientRect().left - offset,
          top: target.getBoundingClientRect().top,
        });
      });
    }

    return () => {
      window.removeEventListener('resize', () => {
        settutorialpop({
          left: target.getBoundingClientRect().left - offset,
          top: target.getBoundingClientRect().top,
        });
      });
    };
  }, [TUTORIALISOPEN]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!targetRef.current?.contains(e.target) && !parentRef.current?.contains(e.target)) setMyPagePopupIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!SETTINGOPEN) queryClient.refetchQueries({ type: 'active' });
  }, [queryClient, SETTINGOPEN]);

  useEffect(() => {
    if (!isSuccess) return;

    const term = dayjs(data.expiredAt).diff(dayjs(), 'second');
    if (
      data.pricingId !== pricing_id ||
      data.paymentStatus !== JSON.parse(localStorage.getItem('pricing') as string)?.status
    ) {
      localStorage.setItem('pricing', JSON.stringify({ id: data.pricingId, status: data.paymentStatus }));
      setPricing_id(data.pricingId);
    }
    if (term <= 0) setFreeTrailTerm(0);
    else setFreeTrailTerm(term);
  }, [isSuccess, data, pricing_id]);

  return (
    <>
      <S.Header>
        <S.LogoArea>
          <Link
            to={'/manage/order/auto'}
            onClick={e => {
              if (data?.paymentStatus === 'paused') e.preventDefault();

              gnbAction(e);
            }}
          >
            <Logo fill={theme.colors.RG04} />
          </Link>
        </S.LogoArea>
        <S.NavArea direction="row">
          <Stack spacing={16} direction="row" sx={{ textAlign: 'center' }}>
            {data?.paymentStatus !== 'paused' && (
              <>
                <Tooltip
                  isOpen={orderManageTooltipIsOpen}
                  setIsOpen={setOrderManageTooltipIsOpen}
                  openOnClick
                  clickable
                  noArrow
                  offset={32}
                  anchorSelect={freeTrailTerm === 0 && pricing_id === 1 ? '' : '#gnb_order_management'}
                >
                  {orderManagementContent.map(content => {
                    return (
                      <TooltipItem
                        to={content.path}
                        disabled={content.disabled}
                        aria-disabled={content.disabled}
                        preset={content.preset === 'gray' ? content.preset : isHovering ? undefined : content.preset}
                        onClick={e => {
                          if (data?.paymentStatus === 'paused') e.preventDefault();

                          gnbAction(e, () =>
                            content.disabled ? e.preventDefault() : setOrderManageTooltipIsOpen(false)
                          );
                        }}
                        onMouseEnter={() => {
                          if (content.preset !== 'gray') setIsHovering(true);
                        }}
                      >
                        {content.label}
                      </TooltipItem>
                    );
                  })}
                </Tooltip>
                <Tooltip
                  isOpen={isOpenReportTooltip}
                  setIsOpen={setIsOpenReportTooltip}
                  openOnClick
                  clickable
                  noArrow
                  offset={32}
                  anchorSelect={freeTrailTerm === 0 && pricing_id === 1 ? '' : '#gnbReport'}
                >
                  {reportContent.map(content => {
                    return (
                      <TooltipItem
                        end={true}
                        to={content.path}
                        disabled={content.disabled}
                        aria-disabled={content.disabled}
                        preset={content.preset === 'gray' ? content.preset : isHovering ? undefined : content.preset}
                        onClick={e => {
                          if (data?.paymentStatus === 'paused') e.preventDefault();

                          gnbAction(e, () => (content.disabled ? e.preventDefault() : setIsOpenReportTooltip(false)));
                        }}
                        onMouseEnter={() => {
                          if (content.preset !== 'gray') setIsHovering(true);
                        }}
                      >
                        {content.label}
                      </TooltipItem>
                    );
                  })}
                </Tooltip>
              </>
            )}
            <NavLink
              id="gnb_order_management"
              to="/manage/order"
              onClick={e => {
                if (data?.paymentStatus === 'paused') e.preventDefault();

                gnbAction(e, () => {
                  e.preventDefault();
                  setIsHovering(false);
                });
              }}
              className={({ isActive }) =>
                `${!orderManageTooltipIsOpen && isActive ? 'navActiveStyle' : 'navDefaultStyle'} ${
                  freeTrailTerm === 0 && pricing_id === 1 ? 'disabled' : ''
                }`
              }
            >
              주문 관리
            </NavLink>
            {routes.map((d, i) => {
              return (
                <NavLink
                  key={`gnb_${i}`}
                  id={d.id}
                  to={d.path}
                  className={({ isActive }) =>
                    `${!orderManageTooltipIsOpen && isActive ? 'navActiveStyle' : 'navDefaultStyle'} ${
                      d.disabled ? 'disabled' : ''
                    }`
                  }
                  onClick={e => {
                    if (d.title === '보고서' && data?.name === 'Free') {
                      setIsOpenUpgradeGuideModal(true);
                      e.preventDefault();
                    }

                    if (d.title === '보고서') {
                      return gnbAction(e, () => {
                        e.preventDefault();
                        setIsHovering(false);
                      });
                    }

                    gnbAction(e, () => {
                      d.disabled && handleDisabledNav(e);
                    });
                  }}
                >
                  {d.title}
                </NavLink>
              );
            })}
          </Stack>

          <WBStack spacing={10} direction="row">
            <Text styleName="subheadline1" color="RG01">
              사용방법 문의
            </Text>
            <BStack>1533 - 0441</BStack>
          </WBStack>
        </S.NavArea>

        <S.IConAreaWrapper>
          <S.IconArea spacing={20} align={'center'} direction="row" justify="end">
            {iconRoutes.map((d, i) =>
              d.path ? (
                d.external ? (
                  <AltWrapper
                    altInfo={`${d.alt?.content}`}
                    anchorWeight={{ ...d.alt?.anchorWeight }}
                    sx={{ padding: '3px 6px' }}
                  >
                    <a
                      id={d.tooltipId}
                      href={d.path}
                      target={d.target}
                      key={`link_${i}`}
                      onClick={e => {
                        if (i === 0) {
                          setIsOpenSignUpFaqInfo(false);
                          setShowFaqTooltip(false);
                        }

                        gnbAction(e);
                      }}
                    >
                      <d.icon
                        width={20}
                        height={20}
                        className={location.pathname.split('/')[1] === d.path ? 'isOnActive' : 'isInActive'}
                      />
                    </a>
                  </AltWrapper>
                ) : (
                  <AltWrapper
                    altInfo={`${d.alt?.content}`}
                    anchorWeight={{ ...d.alt?.anchorWeight }}
                    sx={{ padding: '3px 6px' }}
                  >
                    <Link
                      to={d.path}
                      target={d.target}
                      key={`link_${i}`}
                      onClick={e => {
                        gnbAction(e);
                      }}
                    >
                      <d.icon
                        width={20}
                        height={20}
                        className={location.pathname.split('/')[1] === d.path ? 'isOnActive' : 'isInActive'}
                      />
                    </Link>
                  </AltWrapper>
                )
              ) : d.click ? (
                <AltWrapper
                  altInfo={`${d.alt?.content}`}
                  anchorWeight={{ ...d.alt?.anchorWeight }}
                  sx={{ padding: '3px 6px' }}
                >
                  <div id={d.tooltipId}>
                    <d.icon
                      key={`link_${i}`}
                      onMouseOver={() => {
                        if (d.tooltipId === 'tutorialtooltip') setShowTutorialTooltip(false);
                      }}
                      onClick={() => {
                        d?.click && d.click();
                      }}
                      style={{ cursor: 'pointer' }}
                      className={d?.activeKey ? 'isOnActive' : 'isInActive'}
                    />
                  </div>
                </AltWrapper>
              ) : (
                <d.icon key={`link_${i}`} style={{ cursor: 'not-allowed' }} />
              )
            )}
            <S.IconProfileWrapper
              ref={parentRef}
              isActive={Boolean(authToken)}
              onClick={e => {
                gnbAction(e, () => setMyPagePopupIsOpen(prev => !prev), true);
              }}
            >
              <IcProfile width={20} height={20} />
              {localStorage.getItem('currentEmail')}
            </S.IconProfileWrapper>
          </S.IconArea>
        </S.IConAreaWrapper>
      </S.Header>
      {myPagePopupIsOpen && <MyPagePopup {...{ setMyPagePopupIsOpen, ref: targetRef }} />}
      <S.PageContentWrapper style={{ position: 'relative' }}>
        <Outlet />

        <Srtb
          adsf={SETTINGOPEN}
          onClick={e => {
            SETSETTINGOPEN(p => !p);
            // setSETTINGOPEN(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'end',
              transition: 'transform 0.5s linear',
              // transform: SETTINGOPEN ? 'none' : 'translateX( 50vw )',
            }}
          >
            <Setting {...{ isOpen: SETTINGOPEN, setIsOpen: SETSETTINGOPEN }} />
          </div>
        </Srtb>
        {JSON.parse(process.env.REACT_APP_TB_ONLY as string) && <GradeSelector />}
      </S.PageContentWrapper>
      {/*{isOpenSignUpFaqInfo ? (*/}
      {/*  <FaqTooltip*/}
      {/*    pos={signupfaqpop}*/}
      {/*    id={'faq-tooltip-popup'}*/}
      {/*    className="pointer"*/}
      {/*    direction="row"*/}
      {/*    spacing={10}*/}
      {/*    onClick={() => {*/}
      {/*      setIsOpenSignUpFaqInfo(false);*/}
      {/*      setShowFaqTooltip(false);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <IcSmileIllust />*/}
      {/*    <Text styleName="subheadline1" color="RG00" className="drag-ban">*/}
      {/*      처음이신가요? 이용 안내를 눌러 사용법을 알아보세요.*/}
      {/*    </Text>*/}
      {/*  </FaqTooltip>*/}
      {/*) : null}*/}

      {showTutorialTooltip && !TUTORIALISOPEN ? (
        <FaqTooltip
          pos={tutorialpop}
          id={'tutorial-tooltip-popup'}
          className="pointer"
          direction="row"
          spacing={10}
          onClick={() => {
            if (!TUTORIALISOPEN) {
              setShowTutorialTooltip(false);
            }
          }}
          onMouseLeave={() => {
            if (!TUTORIALISOPEN) {
              setShowTutorialTooltip(false);
            }
          }}
        >
          <IcSmileIllust />
          <Text styleName="subheadline1" color="RG00" className="drag-ban">
            이 버튼을 클릭하여 튜토리얼을 다시 시작할 수 있습니다.
          </Text>
        </FaqTooltip>
      ) : null}

      <UpgradeGuideModal
        isOpen={isOpenUpgradeGuideModal}
        setIsOpen={setIsOpenUpgradeGuideModal}
        upgradeGuidePlan={['Lite', 'Standard', 'Pro', 'Enterprise']}
        text="요금제로 업그레이드 하시면 해당 기능을 사용하실 수 있습니다."
      />
    </>
  );
};

export default GNB;

export const Srtb = styled.div<{ adsf: boolean }>`
  position: fixed; /* Stay in place */
  z-index: 24;
  margin-top: 72px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Enable scroll if needed */
  transition: background-color 0.5s;

  pointer-events: ${({ adsf }) => (adsf ? 'auto' : 'none')};
  background-color: ${({ theme, adsf }) => (adsf ? `${theme.colors.RG03}66` : 'transperent')};
`;

const Tooltip = styled(ReactTooltip)`
  opacity: 1;
  padding: 16px 0;

  border-radius: 8px;
  ${({ theme }) => ({
    color: theme.colors.RG02,
    backgroundColor: theme.colors.RG00,
    border: `1px solid ${theme.colors.RG06}`,

    boxShadow: theme.shadows.normal,
  })}
  ${({ theme }) => theme.scrollStyle.vertical};

  cursor: pointer;

  & > .active {
    ${({ theme }) => ({
      backgroundColor: `${theme.colors.RC03_1}`,
    })}

    ${({ theme }) => theme.fontStyle.caption1}
  }
`;

const TooltipItem = styled(NavLink)<{ disabled?: boolean; preset?: 'hover' | 'gray' }>`
  display: flex;
  justify-content: start;
  align-items: center;

  padding: 6px 10px;

  ${({ theme, disabled, preset }) => ({
    color: preset === 'gray' ? theme.colors.RG04 : theme.colors.RG02,
    cursor: disabled ? 'not-allowed' : 'pointer',
    ':hover': {
      backgroundColor: !disabled && theme.colors.RC03_1,
    },

    backgroundColor: preset === 'hover' ? theme.colors.RC03_1 : '',
  })}
  ${({ theme }) => theme.fontStyle.caption2}
`;

const FaqTooltip = styled(Stack)<{ pos: { left: number; top: number } }>`
  z-index: 999;

  position: absolute;
  width: max-content;

  border-radius: 8px;
  padding: 10px;

  ${({ theme, pos: { left, top } }) => ({
    left: left - 295,
    top: top + 30,
    backgroundColor: theme.colors.RC02,
  })}
  ::after {
    content: '';
    position: absolute;

    /* border-radius: 4px;
    border-bottom: 16px solid ${({ theme }) => theme.colors.RC02};
    border-left: 12px solid transparent;
    border-right: 12px solid transparent; */

    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.RC02};

    transform: rotate(315deg);
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%);

    top: -5px;
    right: 24px;
  }
`;

const WBStack = styled(Stack)`
  justify-content: right;
  width: calc(100% - 30px);
  margin-right: 30px;

  @media only screen and (max-width: 990px) {
    display: none;
  }
`;

const BStack = styled(Stack)`
  padding: 5px 0px;

  border-radius: 6px;
  width: fit-content;
  ${({ theme }) => ({
    color: theme.colors.RC02,
    // backgroundColor: theme.colors.RC03_1,
  })};

  ${({ theme }) => theme.fontStyle.subheadline1}
`;
