import { Input } from 'components/Input';
import { isTruthy } from 'util/isTruthy';
import type {
  FieldError,
  UseFormRegister,
  UseFormSetError,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import type { KeyboardEvent, CSSProperties } from 'react';

function inputUserName(props: {
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  error?: FieldError | undefined;
  name: string;
}) {
  const { setError, clearErrors, name, error } = props;
  if (isTruthy(name) === false) return setError('name', { message: '이름은 필수입력 사항입니다.' });
  const userNameLength = name.length;
  if (userNameLength < 2 || userNameLength > 21)
    return setError('name', { message: '이름은  2~20자 사이여야 합니다.' });
  if (error?.message) return clearErrors('name');
}

interface IInputUserName {
  register: UseFormRegister<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  error: FieldError | undefined;
  placeholder?: string;
  sx?: CSSProperties;
}

export const InputUserName = (props: IInputUserName) => {
  const { error, register, clearErrors, setError, getValues, setValue, placeholder, sx } = props;

  return (
    <Input
      name="name"
      type="text"
      sx={sx}
      hasError={error?.message}
      errors={{ message: error?.message }}
      removeBtn={() => setValue('name', '')}
      placeholder={placeholder ? placeholder : ''}
      onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
          const { name } = getValues();
          inputUserName({ setError, clearErrors, error, name });
        }
      }}
      onBlur={() => {
        const { name } = getValues();
        inputUserName({ setError, clearErrors, error, name });
      }}
      onMouseOut={() => {
        const { name } = getValues();
        inputUserName({ setError, clearErrors, error, name });
      }}
      variant="default"
      register={register}
    />
  );
};
