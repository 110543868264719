import styled from 'styled-components';
import { CSSProperties } from 'react';

export const InviteManagerModalHeader = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.RG06};
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px;
`;

export const InviteManagerModalBody = styled.section`
  padding: 30px 20px;
  width: 100%;
  max-height: calc(100vh - 220px);

  @media only screen and (max-height: 720px) {
    overflow: overlay;
  }
`;

export const InviteManagerModalFooter = styled.footer`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 20px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.RG06};
  justify-content: end;
`;

export const InviteManageSelectorItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 6px;
  align-items: center;
  padding-top: 6px;
`;

export const SelectorContainer = styled.div<{
  width?: number | string;
  spacing?: number;
  sx?: CSSProperties;
  anchor?: 'top' | 'bottom';
  maxHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  box-shadow: ${({ theme }) => theme.shadows.normal};
  border-radius: 8px;
  position: absolute;
  z-index: 3;
  left: 0;

  width: ${({ width }) => (width ? `${width}px` : '100%')};

  height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '')};
  ${({ theme }) => theme.scrollStyle.vertical};

  ${({ anchor, spacing }) =>
    anchor === 'top'
      ? {
          bottom: `${(spacing ?? 0) + 36}px`,
        }
      : {
          top: `${(spacing ?? 0) + 36}px`,
        }}

  ${({ sx }) => sx && { ...sx }}
`;

export const SelectorItem = styled.div<{ activated?: boolean }>`
  width: 100%;
  min-height: 32px;
  padding: 6px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: start;
  color: ${({ theme }) => theme.colors.RG02};
  background: ${({ theme, activated }) => (activated ? theme.colors.RC03_1 : theme.colors.RG00)};
  ${({ theme }) => theme.fontStyle.caption2};

  &:hover {
    background: ${({ theme }) => theme.colors.RC03_1};
    ${({ theme }) => theme.fontStyle.caption1};
  }
`;

export const SelectorDisplayBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  gap: 8px;

  background: ${({ theme }) => theme.colors.RG00};
  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 8px;

  width: fit-content;
  padding: 7px 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.RG07};
  }
`;

export const InviteManagerModalInputFrame = styled.div`
  height: 180px;
  width: 100%;
  overflow: overlay;
  padding-bottom: 10px;
  margin-top: 4px;

  border: 1px solid ${({ theme }) => theme.colors.RG06};
  border-radius: 8px;
  &:focus-within,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.RC03};
  }
`;

export const InviteManagerModalInputLayout = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding-top: 10px;
`;

export const InviteManagerModalInput = styled.div<{ hasInvitedMail: boolean }>`
  resize: none;
  height: 24px;
  text-align: left;
  width: fit-content;
  align-content: flex-start;
  align-items: center;
  display: flex;

  :empty::before {
    content: ${({ hasInvitedMail }) => (hasInvitedMail ? '' : 'attr(data-text)')};
    color: ${({ theme }) => theme.colors.RG04};
    font-style: ${({ theme }) => theme.fontStyle.subheadline2};
  }
`;

export const InviteManagerModalDisplayChips = styled.div`
  display: flex;
  padding-left: 10px;
  width: 360px;
  gap: 10px;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ChipComponentCSS = styled.span<{
  borderColor?: TThemeColor;
  backgroundColor?: TThemeColor;
  height?: string;
  fontStyle?: TForntStyle;
  fontColor?: TThemeColor;
}>`
  border: ${({ borderColor, theme }) => (borderColor ? `1px solid ${theme.colors[borderColor]}` : '')};
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.RC03_1};
  color: ${({ fontColor, theme }) => (fontColor ? theme.colors[fontColor] : theme.colors.RG02)};
  border-radius: 4px;
  height: ${({ height }) => (height ? height : '24px')};
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding: 6px 6px;
  cursor: pointer;
  ${({ theme, fontStyle }) => (fontStyle ? theme.fontStyle[fontStyle] : theme.fontStyle.subheadline2)}
`;

export const statusChip = styled.div<{ bgColor?: TThemeColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  gap: 10px;
  width: 36px;
  height: 20px;
  border-radius: 4px;

  text-align: center;

  ${({ theme, bgColor }) => ({
    color: theme.colors.RG00,
    backgroundColor: bgColor ? theme.colors[bgColor] : '#406ee2',
  })}

  ${({ theme }) => theme.fontStyle.tooltip3}
`;
