import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '.';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { Button } from 'components/Button';
import { PositionPicker } from 'components/PositionPicker';
import { LatLng } from 'constants/types';

interface PositionPickerModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initPosition: LatLng;
  name: string;
  savePosition: (position: LatLng) => void;
  address: string;
  detailAddress: string;
}

const PositionPickerModal = ({
  isOpen,
  setIsOpen,
  initPosition,
  savePosition,
  name,
  address,
  detailAddress,
}: PositionPickerModalProps) => {
  const [position, setPosition] = useState(initPosition);

  useEffect(() => {
    setPosition(initPosition);
  }, [initPosition]);

  useEffect(() => {
    console.log('position', position);
  }, [position]);

  return (
    <Modal
      isModalOpen={isOpen}
      setIsModalOpen={() => {
        setIsOpen(prev => !prev);
      }}
      width={720}
      plain
    >
      <Stack name="d-content-area" sx={{height: '652px'}}>
        <Stack spacing={8} align="start" padding={24}>
          <Text styleName="title2" color="RG02">
            마커를 이동하여 좌표를 직접 수정할 수 있습니다.
          </Text>
          {name && 
          <Text styleName="body1" color="RG02">
            {name}
          </Text>
          }
         
          <Stack align="start">
            <Text styleName="body2" color="RG02">
              {address}
            </Text>
            <Text styleName="body2" color="RG03">
              {detailAddress || '-'}
            </Text>
          </Stack>
        </Stack>
        <Stack style={{ flex:1 }}>
          <PositionPicker position={position} setPosition={setPosition} />
        </Stack>
        <Stack direction="row" padding={24} spacing={20}>
          <Button
            type={'button'}
            variant="tertiary"
            h={40}
            sx={{ flex: 1 }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG03">
              취소
            </Text>
          </Button>
          <Button
            type={'button'}
            variant="primary"
            h={40}
            sx={{ flex: 1 }}
            onClick={() => {
              savePosition(position);
              setIsOpen(false);
            }}
          >
            <Text styleName="body1" color="RG00">
              저장하기
            </Text>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default PositionPickerModal;
