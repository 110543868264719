import { UseMutationResult } from '@tanstack/react-query/src/types';
import { Button } from 'components/Button/index';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { UpgradeGuideModal } from 'components/Modal/warning/paymentPlan/UpgradeGuideModal';
import { ReactComponent as IcDown } from 'constants/icon/ic_arrowdown.svg';
import { useGetPaymentMy } from 'hooks/query/payment/useGetPaymentMy';
import { useDownload } from 'hooks/useDownload';
import React, { useEffect, useState } from 'react';

interface DownloadButtonProps<T> {
  text: string;
  isDisabled: boolean;
  variables: T;
  extension: DownloadExtension;
  useDownloadReactQueryHook: () => UseMutationResult<Awaited<ReturnType<(...args: any) => any>>, unknown, T>;
}

const DownloadButton = <T,>({
  text,
  isDisabled,
  variables,
  extension,
  useDownloadReactQueryHook,
}: DownloadButtonProps<T>) => {
  const { downloadToArrayBuffer } = useDownload();
  const [isOpenUpgradeGuideModal, setIsOpenUpgradeGuideModal] = useState<boolean>(false);
  const {
    mutate: mutateDownload,
    data: downloadData,
    isLoading: isLoadingDownload,
    isSuccess: isSuccessDownload,
  } = useDownloadReactQueryHook();

  const { data: paymentMyData } = useGetPaymentMy();

  useEffect(() => {
    if (isSuccessDownload && downloadData?.data) {
      downloadToArrayBuffer({
        data: downloadData.data,
        fileName: downloadData.title ?? '',
        extension: extension,
      });
    }
  }, [isSuccessDownload, downloadData]);

  return (
    <>
      <Button
        type="button"
        variant="tertiary"
        h={32}
        w={126}
        icon={IcDown}
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) return;

          if (paymentMyData?.name === 'Free') {
            setIsOpenUpgradeGuideModal(true);

            return;
          }

          mutateDownload(variables);
        }}
        sx={{ textWrap: 'nowrap' }}
      >
        {`${text} 다운로드`}
      </Button>
      <LoadingModal isLoading={isLoadingDownload} />
      <UpgradeGuideModal
        isOpen={isOpenUpgradeGuideModal}
        setIsOpen={setIsOpenUpgradeGuideModal}
        upgradeGuidePlan={['Lite', 'Standard', 'Pro', 'Enterprise']}
        text="요금제로 업그레이드 하시면 해당 기능을 사용하실 수 있습니다."
      />
    </>
  );
};

export { DownloadButton };
