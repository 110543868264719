import { TDragablePickerOptions } from 'components/Popup/DragablePicker';

const dimension_options_default: TDragablePickerOptions = [
  {
    name: '주행 일자',
    key: 'performedDate',
    isSelected: true,
  },
  {
    name: '드라이버',
    key: 'driver',
    isSelected: true,
  },
  {
    name: '경로ID',
    key: 'route_id',
    isSelected: false,
  },
  {
    name: '주행 이름',
    key: 'route_name',
    isSelected: false,
  },
  {
    name: '팀',
    key: 'team',
    isSelected: false,
  },
];

const dimension_options_driver: TDragablePickerOptions = [
  {
    name: '드라이버',
    key: 'driver',
    isSelected: true,
    default: true
  },
  {
    name: '주행 일자',
    key: 'performedDate',
    isSelected: false,
  },
  {
    name: '경로ID',
    key: 'route_id',
    isSelected: false,
  },
  {
    name: '주행 이름',
    key: 'route_name',
    isSelected: false,
  },
  {
    name: '팀',
    key: 'team',
    isSelected: false,
  },
];

const info_metrics_options_default: TDragablePickerOptions = [
  {
    name: '상태',
    key: 'status',
    isSelected: true,
  },
  {
    name: '아이디(휴대폰 번호)',
    key: 'phone',
    isSelected: false,
  },
  {
    name: '운영 유형',
    key: 'operationType',
    isSelected: true,
  },
  {
    name: '최대 용적량',
    key: 'maxCapacity',
    isSelected: true,
  },
  {
    name: '담당 권역',
    key: 'areas',
    isSelected: false,
  },
  {
    name: '휴게시간 (분)',
    key: 'breakDuration',
    isSelected: false,
  },
  {
    name: '출발지 주소',
    key: 'startAddress',
    isSelected: false,
  },
  {
    name: '도착지 주소',
    key: 'endAddress',
    isSelected: false,
  },
  {
    name: '특수 조건',
    key: 'skills',
    isSelected: false,
  },
  {
    name: '메모',
    key: 'memo',
    isSelected: false,
  },
];

const metrics_options: {
  route: TDragablePickerOptions;
  order: TDragablePickerOptions;
} = {
  route: [
    {
      name: '예상 이동 거리 (km)',
      key: 'estimatedDistance',
      isSelected: true,
    },
    {
      name: '실제 이동 거리 (km)',
      key: 'actualDistance',
      isSelected: true,
    },
    {
      name: '예상 이동 시간 (분)',
      key: 'estimatedTime',
      isSelected: true,
    },
    {
      name: '실제 이동 시간 (분)',
      key: 'actualTime',
      isSelected: true,
    },
    {
      name: '예상 유휴 시간 (분)',
      key: 'estimatedWaitingTime',
      isSelected: true,
    },
    { key: 'totalServiceTime', name: '예상 작업 소요 시간 (분)', isSelected: false },
    { key: 'actualServiceTime', name: '실제 작업 소요 시간 (분)', isSelected: false },
    {
      name: '예상 용적량',
      key: 'totalCapacity',
      isSelected: true,
    },
    {
      name: '실제 용적량',
      key: 'actualCapacity',
      isSelected: true,
    },
    {
      name: '주문 집계 (개)',
      key: 'totalOrder',
      isSelected: true,
    },
    {
      name: '완료 주문 집계 (개)',
      key: 'completedOrder',
      isSelected: false,
    },
    {
      name: '보류 주문 집계 (개)',
      key: 'skippedOrder',
      isSelected: false,
    },
    {
      name: '아이템 수',
      key: 'itemQuantity',
      isSelected: false,
    },
    {
      name: '주행 횟수',
      key: 'routeCount',
      isSelected: false,
    },
  ],
  order: [
    {
      name: '주문ID',
      key: 'orderCode',
      isSelected: true,
    },

    {
      name: '주문 추가 일자',
      key: 'createdAt',
      isSelected: false,
    },
    {
      name: '주문 유형',
      key: 'shipmentType',
      isSelected: false,
    },

    {
      name: '주소',
      key: 'address',
      isSelected: true,
    },

    {
      name: '상세주소',
      key: 'detailAddress',
      isSelected: true,
    },

    {
      name: '상태',
      key: 'status',
      isSelected: true,
    },

    {
      name: '완료/보류 사유',
      key: 'historyComment',
      isSelected: false,
    },
    {
      name: '고객명',
      key: 'consigneeName',
      isSelected: true,
    },
    {
      name: '작업 소요 시간 (분)',
      key: 'serviceTime',
      isSelected: false,
    },
    {
      name: '총 용적량',
      key: 'capacity',
      isSelected: true,
    },
    {
      name: '실 용적량',
      key: 'actualCapacity',
      isSelected: false,
    },
    {
      name: '실 용적량 차이 사유',
      key: 'capacityComment',
      isSelected: false,
    },
    {
      name: 'PoD 접수 시간 (시:분)',
      key: 'podCreatedAt',
      isSelected: false,
    },
    { name: 'PoD 이미지 (url)', key: 'podUrl', isSelected: false },
  ],
};

export { dimension_options_default, dimension_options_driver, info_metrics_options_default, metrics_options };
