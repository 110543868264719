import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import * as S from 'pages/Setting/organization/Manager/inviteManagerModal/style';
import { cloneElement, CSSProperties, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { isFalsy } from 'util/isFalsy';

interface SelectorProps {
  defaultValue?: any;
  placeholder?: string;
  icon?: ReactNode;
  anchor?: 'top' | 'bottom';
  options: SelectorOptionsType[];
  onOptionsClick: (item: SelectorOptionsType) => void;
  fullWidth?: boolean;
  sx?: CSSProperties;
  selectorGap?: number;
  parent?: ReactElement;
  maxHeight?: number;
  showExtra?: boolean;
}

export const NewSelector = ({
  anchor = 'bottom',
  defaultValue,
  options,
  onOptionsClick,
  icon,
  sx,
  selectorGap,
  fullWidth,
  placeholder,
  parent,
  maxHeight,
  showExtra,
}: SelectorProps) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayContent, setDisplayContent] = useState<string>('');
  const [fixedHeight, setFixedHeight] = useState<number>(0);

  useEffect(() => {
    if (showExtra)
      setDisplayContent(
        defaultValue
          ? `${options.find(d => d.value === defaultValue)?.key} ${
              options.find(d => d.value === defaultValue)?.extra
                ? '(' + options.find(d => d.value === defaultValue)?.extra + ')'
                : ''
            }`
          : 'placeholder'
      );
    else setDisplayContent(defaultValue ? `${options.find(d => d.value === defaultValue)?.key}` : 'placeholder');
  }, []);

  useEffect(() => {
    if (!(selectorRef && selectorRef.current) || !isOpen) {
      setFixedHeight(0);
      return;
    }
    const divposition = selectorRef.current.getBoundingClientRect();
    if (divposition.y + divposition.height > window.innerHeight) {
      setFixedHeight(window.innerHeight - divposition.y - 20);
    }
  }, [isOpen, options]);

  useEffect(() => {
    if (isFalsy(defaultValue) && placeholder && isFalsy(displayContent)) {
      return setDisplayContent(placeholder);
    }
  }, [options]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!selectorRef.current?.contains(e.target) && !containerRef.current?.contains(e.target)) setIsOpen(false);
  };
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, selectorRef]);
  const style = Object.assign({}, fullWidth && { width: '100%' }, sx);
  return (
    <Stack sx={{ position: 'relative' }}>
      {parent ? (
        cloneElement(parent, {
          onClick: () => {
            setIsOpen(prev => !prev);
          },
          ref: containerRef,
          style: style,

          onKeyDown: (e: any) => {
            (e?.keyCode ?? -33) === 13 && setIsOpen(false);
          },
        })
      ) : (
        <S.SelectorDisplayBox
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          ref={containerRef}
          style={style}
        >
          <Text
            sx={{ textAlign: 'initial', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            styleName="caption2"
            color="RG03"
          >
            {displayContent}
          </Text>
          {icon && icon}
        </S.SelectorDisplayBox>
      )}
      {isOpen && (
        <S.SelectorContainer
          spacing={selectorGap ? selectorGap : 10}
          ref={selectorRef}
          anchor={anchor}
          maxHeight={fixedHeight > 0 ? fixedHeight : maxHeight}
        >
          <Stack align="start">
            {options.length === 0 && (
              <S.SelectorItem>
                <Text sx={{ textAlign: 'initial' }} styleName="subheadline2" color="RG04">
                  일치하는 결과가 없습니다.
                </Text>
              </S.SelectorItem>
            )}
            {options.map((row, index) => {
              return (
                <S.SelectorItem
                  key={`${row.key}-selector-${index}`}
                  onClick={() => {
                    onOptionsClick(row);

                    if (showExtra) setDisplayContent(row.extra ? `${row.key} (${row.extra})` : row.key);
                    else setDisplayContent(row.key);

                    setIsOpen(false);
                  }}
                >
                  <Text sx={{ textAlign: 'initial' }} styleName="subheadline2" color="RG02">
                    {row.key}
                  </Text>
                  {row.extra && row.extra}
                </S.SelectorItem>
              );
            })}
          </Stack>
        </S.SelectorContainer>
      )}
    </Stack>
  );
};
