import { useEffect, useMemo, useState } from 'react';
import { MyPageAccount } from 'pages/MyPage/Account/MyPageAccount';
import { PaymentManage } from 'pages/MyPage/Payment/PaymentManage';
import * as S from 'pages/MyPage/style';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Stack } from 'components/Stack';

import { MyPageSidebar } from 'pages/MyPage';

import UsageAndPlan from './UsageAndPlan/UsageAndPlan';
import { useStore } from '../../store';

const MYPAGE_COMPONENTS: {
  [key: string]: {
    label: string;
    contents: { [key in TMyPageComponents]?: TMyPageSidebarType };
  };
} = {
  basic: {
    label: '기본 정보 관리',
    contents: {
      // history: { component: <div />, label: '이용내역', key: 'history' },
      // bill: { component: <div />, label: '요금명세서', key: 'bill' },
      // fee: { component: <div />, label: '요금제', key: 'fee' },
      //   payment: { component: <div />, label: '계정수단', key: 'payment' },
      account: { component: <MyPageAccount />, label: '계정 정보 관리', key: 'account' },
      //   notification: { component: <div />, label: '알림내역', key: 'notification' },
    },
  },
  payment: {
    label: '결제 정보 관리',
    contents: {
      'usage-and-plan': { component: <UsageAndPlan />, label: '사용량 및 요금제 관리', key: 'usage-and-plan' },
      payment: {
        component: <PaymentManage />,
        label: '결제 관리',
        key: 'payment',
      },
    },
  },
};

export const MyPage = () => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const params_head = searchParams.get('head') as TMyPageComponents | null;

  const state = useLocation().state as { head: TMyPageComponents } | null;
  const [currentComponent, setCurrentComponent] = useState<TMyPageComponents>(state?.head ?? 'account');

  useEffect(() => {
    setCurrentComponent(prev => {
      return state?.head ?? params_head ?? prev;
    });
    return setCurrentComponent(prev => prev);
  }, [params_head, state]);

  const component = useMemo(() => {
    const myPageContents = Object.values(MYPAGE_COMPONENTS)
      .map(item => item.contents)
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    if (searchParams.get('paymentStatus') && searchParams.get('actionType')) {
      window.localStorage.setItem('paymentStatus', searchParams.get('paymentStatus') || '');
      window.localStorage.setItem('actionType', searchParams.get('actionType') || '');
    }

    navigator('/mypage', { state: { head: myPageContents[currentComponent]?.key } });

    return myPageContents[currentComponent]!.component;
  }, [currentComponent]);

  return (
    <div style={{ display: 'block', height: '100vh', width: '100%', overflow: 'auto' }}>
      <Stack align="center" sx={{ width: 'inherit', height: '100%' }}>
        <S.MyPageLayout>
          <MyPageSidebar
            sx={{ margin: '40px 0 100px 0', alignItems: 'center', height: '100vh' }}
            componentList={MYPAGE_COMPONENTS}
            currentComponent={currentComponent}
            setCurrentComponent={setCurrentComponent}
          />
          <Stack align="start" sx={{ height: '100%', width: '100%', padding: '40px 0 80px 0' }}>
            {component}
          </Stack>
        </S.MyPageLayout>
      </Stack>
    </div>
  );
};
