import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { Text } from 'components/Typography';
import Cell from './Cell';
import Tooltip from './Tooltip';
import { Dispatch, SetStateAction, useState } from 'react';
import Clipboard from './Clipboard';
import { ITimelineBlock } from 'constants/types';
import PasteableCell from './PasteableCell';
import { TMod } from 'pages/RoutePlanConfirm';

function getDndCombinedStyle({
  draggableStyle,
  virtualStyle,
  item,
}: {
  draggableStyle: any;
  virtualStyle: any;
  item?: ITimelineBlock;
}) {
  if (!item) {
    return { ...virtualStyle, ...draggableStyle };
  }
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
    width: item.width,
  };
  // if (!item.visible) combined.pointerEvents = 'none';
  combined.transition = '0.001s';

  if (item.type === 'jumsim') {
    combined.cursor = 'not-allowed';
  }
  return combined;
}

export default function ({
  item,
  style,
  provided,
  isDragging,
  fnShowOrderDetail,
  fnSaveToClipboard,
  fnLoadFromClipboard,
  fnOnCellClicked,
  clipboard,
  selectedCell,
  mod,
  startEndChipSelected,
  setStartEndChipSelected,
}: {
  item?: ITimelineBlock;
  style?: any;
  provided?: any;
  isDragging?: boolean;
  fnShowOrderDetail?: (orderId: number) => void;
  fnSaveToClipboard?: (orderId: number) => void;
  fnLoadFromClipboard?: (draggableId: string) => void;
  fnOnCellClicked?: (orderId: number) => void;
  clipboard?: ITimelineBlock;
  selectedCell?: number[];
  mod?: TMod;
  startEndChipSelected?: string;
  setStartEndChipSelected?: Dispatch<SetStateAction<string>>;
}) {
  const [visible, setVisible] = useState(false);
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getDndCombinedStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        item,
      })}
    >
      {item && item.type === 'spacer' && clipboard !== undefined && (
        <Tippy
          content={<Tooltip variant={item.type} />}
          placement={'bottom-start'}
          duration={0}
          followCursor={true}
          plugins={[followCursor]}
        >
          <span
            onClick={() => {
              fnLoadFromClipboard?.(item.id);
            }}
          >
            <PasteableCell />
          </span>
        </Tippy>
      )}
      {item && item.visible && (
        <Tippy
          content={
            <Clipboard
              orderId={item.originData?.orderId ?? -1}
              setIsOpen={setVisible}
              fnSaveToClipboard={mod === 'auto' ? undefined : fnSaveToClipboard}
              fnShowOrderDetail={fnShowOrderDetail}
            />
          }
          visible={(item.type == 'order' || item.type === 'mibaecha') && visible}
          placement={'bottom-start'}
          duration={0}
        >
          <Tippy
            content={
              item.type !== 'jumsim' && <Tooltip variant={item.type} origin={item.originData} text={item.text} />
            }
            placement={'bottom-start'}
            duration={0}
            followCursor={true}
            plugins={[followCursor]}
          >
            <span
              onClick={() => {
                if (item.type === 'order' || item.type === 'mibaecha')
                  fnOnCellClicked?.(item.originData?.orderId ?? -1);
                else if (item.type === 'start' || item.type === 'end') {
                  setStartEndChipSelected?.(startEndChipSelected === item.id ? 'reset' : item.id);
                }
              }}
              onContextMenu={e => {
                e.preventDefault();

                // if (item.type === 'order' || item.type === 'mibaecha')
                //   fnOnCellClicked?.(item.originData?.orderId ?? -1);
                setVisible(true);
              }}
            >
              <Cell
                variant={item.type}
                isDragging={isDragging}
                isSelected={selectedCell?.includes(item.originData?.orderId ?? -3) || item.id === startEndChipSelected}
                color={item.color}
                isPriority={item.originData?.priority === 'high'}
                waitingTime={(item.waitingTime ?? 0) * 6}
                multiplier={item.multiplier ?? 1}
              >
                <Text styleName="caption1" color="RG00">
                  {item.width > `${item.text}`.length * 10 ? item.text : ''}
                </Text>
              </Cell>
            </span>
          </Tippy>
        </Tippy>
      )}
    </div>
  );
}
