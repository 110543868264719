import { Button } from 'components/Button/legacy_index';
import { Input } from 'components/Input';
import { Stack } from 'components/Stack';
import { Text } from 'components/Typography';
import { ReactComponent as IcPerson } from 'constants/icon/ic_person_bg.svg';
import { ReactComponent as IcSuccess } from 'constants/icon/ic_success.svg';
import theme from 'constants/theme';

import {
  _putUpdateMyAccountUserData,
  inputUserName,
  inputUserPhone,
  keyConvertor,
  updateModalState,
} from 'pages/MyPage/Account/container';

import { MyPageSection } from 'pages/MyPage/common';
import React, { Dispatch, KeyboardEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isTruthy } from 'util/isTruthy';

interface IUserInfo {
  _dispatch: Dispatch<TMyPageAccountAction>;
  _state: TMyPageAccountOption;
}

export const UserInfo = (props: IUserInfo) => {
  const { _state, _dispatch } = props;
  const { userInfo } = _state;
  const [isModified, setIsModified] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(Boolean(userInfo?.marketingConsent));

  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TUserInfoHookForm>();

  useEffect(() => {
    setValue('name', userInfo?.name!);
    setValue('phone', userInfo?.phone!);
    clearErrors();
  }, [isModified]);

  const userInfoKeys = Object.keys(userInfo!).filter(key => ['email', 'password', 'teamName'].includes(key));

  const ModifiedModeForm: {
    [key: string]: Function;
  } = {
    loginAccount: (content: string) => (
      <Input variant="fourth" register={register} name="email" disabled value={content}></Input>
    ),
    password: () => (
      <Stack spacing={12} direction={'row'}>
        <Text styleName="body2" color="RG02">
          비밀번호 재설정을 위해선 이메일 인증이 필요합니다.
        </Text>
        <Button
          variant="RTB"
          type="button"
          styleName="body1"
          onClick={() => updateModalState({ _dispatch, type: 'ValidteEmail', isOpen: true })}
        >
          인증이메일 발송
        </Button>
      </Stack>
    ),
    teamName: (teamName: string) => (
      <Input variant="fourth" register={register} disabled name="teamName" value={teamName} />
    ),
    name: () => (
      <Input
        name="name"
        removeBtn={() => setValue('name', '')}
        hasError={isTruthy(errors.name?.message)}
        errors={errors.name}
        onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
          inputUserName({ setError, clearErrors, error: errors.name, name: getValues('name') });
        }}
        onMouseOut={() => {
          inputUserName({ setError, clearErrors, error: errors.name, name: getValues('name') });
        }}
        variant="fifth"
        register={register}
      />
    ),
    phone: () => (
      <Input
        name="phone"
        removeBtn={() => setValue('phone', '')}
        hasError={isTruthy(errors.phone?.message)}
        errors={errors.phone}
        onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
          inputUserPhone({ setError, clearErrors, error: errors.phone, phone: getValues('phone') });
        }}
        onMouseOut={() => {
          inputUserPhone({ setError, clearErrors, error: errors.phone, phone: getValues('phone') });
        }}
        variant="fifth"
        register={register}
      />
    ),
    marketing: () => (
      <Stack
        spacing={4}
        direction="row"
        onClick={() => {
          setMarketingConsent(prev => !prev);
        }}
        sx={{
          userSelect: 'none',
        }}
      >
        <IcSuccess width={16} height={16} fill={theme.colors[marketingConsent ? 'RC02' : 'RG05']} />
        <Text styleName="body1" color={marketingConsent ? 'RC02' : 'RG04'}>
          동의
        </Text>
      </Stack>
    ),
  };

  const PreviewValue = (key: string, content: string) => {
    if (key === 'password') return '********';
    else if (key === 'phone' && content !== '') {
      return content.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1 - $2 - $3');
    } else if (key === 'marketing') {
      return marketingConsent ? '동의' : '비동의';
    }
    return content;
  };

  return (
    <MyPageSection
      title="회원 정보"
      icon={<IcPerson width={28} height={28} />}
      isModified={isModified}
      setIsModified={setIsModified}
      hasError={isTruthy(errors.name?.message) || isTruthy(errors.phone?.message)}
      callback={() => {
        updateModalState({ _dispatch, type: 'UserInfoLoading', isOpen: true });
        _putUpdateMyAccountUserData({
          _dispatch,
          name: getValues('name'),
          phone: getValues('phone')?.replaceAll('-', ''),
          marketingConsent,
          setIsModified,
        });
      }}
    >
      {['loginAccount', 'password', 'teamName', 'name', 'phone', 'marketing'].map((key, index: number) => {
        const borderStyle =
          index === userInfoKeys.length - 1 || isModified ? '' : { borderTop: `1px solid ${theme.colors.RG06}` };
        const content =
          key === 'teamName' ? userInfo?.team.teamName : userInfo![key as keyof Omit<TUserInfoHookForm, 'teamName'>];

        return (
          <Stack key={key} direction="row" style={{ gap: '20px', padding: '20px 20px', ...borderStyle }}>
            <Text styleName="body2" color="RG02" sx={{ minWidth: '176px' }}>
              {keyConvertor(key, 'userInfo')}
            </Text>
            {isModified ? (
              ModifiedModeForm[key](content)
            ) : (
              <Text styleName="body1" color="RG02">
                {PreviewValue(key, content || '')}
              </Text>
            )}
          </Stack>
        );
      })}
    </MyPageSection>
  );
};
