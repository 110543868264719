import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API } from '../../../api/ky';
import { TUTORIAL_QUERIES } from './tutorial.queries';

type FetchCheckCanTutorialRouteResponse = {
  canRoute: boolean;
};

type UseQueryGeneric = {
  TQueryFnData: Awaited<ReturnType<typeof fetchGetCheckCanTutorialRoute>>;
  TError: Error;
  TData: UseQueryGeneric['TQueryFnData'];
  TQueryKey: ReturnType<typeof TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_ROUTE>;
};

const fetchGetCheckCanTutorialRoute = async ({
  queryKey: [{ endPoint }],
}: QueryFunctionContext<UseQueryGeneric['TQueryKey']>) =>
  await API.get(endPoint).json<FetchCheckCanTutorialRouteResponse>();

const useGetCheckCanTutorialRoute = <QueryReturnType = UseQueryGeneric['TData']>(
  options?: Omit<
    UseQueryOptions<
      UseQueryGeneric['TQueryFnData'],
      UseQueryGeneric['TError'],
      QueryReturnType,
      UseQueryGeneric['TQueryKey']
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery<UseQueryGeneric['TQueryFnData'], UseQueryGeneric['TError'], QueryReturnType, UseQueryGeneric['TQueryKey']>(
    TUTORIAL_QUERIES.CHECK_CAN_TUTORIAL_ROUTE(),
    fetchGetCheckCanTutorialRoute,
    options
  );

export { fetchGetCheckCanTutorialRoute, useGetCheckCanTutorialRoute };
export type { FetchCheckCanTutorialRouteResponse };
