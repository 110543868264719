import { WarningModal } from '../index';
import { Dispatch, SetStateAction } from 'react';

interface DailyAuthCodeLimitModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  callback?: any;
}

export const DailyAuthCodeLimitModal = ({ isOpen, setIsOpen, callback }: DailyAuthCodeLimitModalProps) => (
  <WarningModal
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    // closeTimer={3000}
    title="일일 인증 횟수를 초과하였습니다. 24시간 이후에 다시 시도해 주세요."
    description={['휴대폰 인증 코드 발송은 24시간 이내에 15회 입니다.', '번호를 정확하게 입력했는지 확인해주세요.']}
    callback={callback}
  />
);
